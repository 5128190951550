<template>
  <v-card flat>
    <v-form v-model="isValid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Public HTTP URL"
            v-model.trim="publicUrl"
            hint="This URL is the published URL of Convy on internet/intranet. By default Convy will use the local server address"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Company Name"
            v-model.trim="company.name"
            :rules="[requiredField, companyNameValidator]"
            prepend-inner-icon="mdi-domain"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <TimeZonePicker :item="company.timezone" />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-btn
        :disabled="!isValid"
        :loading="loadingSaveBtn"
        color="success"
        @click="saveConfiguration"
      >
        Save
      </v-btn>
      <v-btn text @click="$emit('back')"> Back </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import TimeZonePicker from "@/components/other/TimeZonePicker";

export default {
  name: "CompanyCreation",
  props: ["publicUrl", "company"],
  components: {
    TimeZonePicker,
  },
  data() {
    return {
      isValid: false,
      loadingSaveBtn: false,
    };
  },
  computed: {
    getAvailableRoles() {
      return Object.values(this.$store.getters.getUserRoles);
    },
  },
  methods: {
    ...fieldValidators,
    saveConfiguration() {
      this.loadingSaveBtn = true;
      this.$emit("save");
    },
  },
};
</script>
