<template>
  <v-card flat :style="calcHeight" class="pt-6">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="deleteItem()" />
    <v-alert v-if="configuration.global_variables.length == 0" class="mt-4" type="warning">
      Global variables have not yet been added
    </v-alert>
    <v-data-table
      v-else
      id="globalVariables"
      :key="'gvdt' + rerender"
      must-sort
      :height="dataTableHeight"
      :headers="headers"
      :items="configuration.global_variables"
      class="elevation-1"
      hide-default-footer
      hide-default-header
      disable-pagination
      fixed-header
    >
      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index" :class="header.class" :style="header.width ? header.width : ''">
              <b v-show="!header.deleteAll">{{ header.text }}</b>
              <span v-show="header.deleteAll">
                <v-btn
                  v-show="configuration.global_variables && configuration.global_variables.length > 1"
                  small
                  text
                  color="error"
                  class="hidden-sm-and-down ml-n6 px-0"
                  @click.prevent="showConfirmDelete('all', -1)"
                >
                  <b>Delete All</b>
                </v-btn>
                <v-icon class="hidden-md-and-up ma-o pa-0" size="24">delete_forever</v-icon>
              </span>
            </th>
          </tr>
        </thead>
      </template>
      <template #body="{ items }">
        <draggable v-model="configuration.global_variables" tag="tbody" handle=".sortHandle">
          <tr v-for="(item, index) in items" :key="'tr' + index">
            <td class="hidden-sm-and-down text-start">
              <v-btn small text style="cursor: move" icon class="sortHandle ma-0 pa-0">
                <v-icon>mdi-cursor-move</v-icon>
              </v-btn>
            </td>
            <td class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.key }}</span>
                </template>
                <span>{{ item.key }}</span>
              </v-tooltip>
            </td>
            <td style="max-width: 40vh" class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.value }}</span>
                </template>
                <span>{{ item.value }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-icon size="24" @click="editItem(item, index)">
                edit
              </v-icon>
            </td>

            <td>
              <v-icon size="24" @click="showConfirmDelete(item, index)">
                delete
              </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-row justify="end">
        <v-col xs="12">
          <v-dialog v-model="editDialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn small color="primary" @click="resetNewGlobalVariable" v-on="on">
                <v-icon left>
                  mdi-plus
                </v-icon>New Global Variable
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">Global Variable</span>
              </v-card-title>
              <perfect-scrollbar ref="ps" class="psGlobalVariableModal" :options="$store.getters.getDefaultScrollProps">
                <v-card-text>
                  <v-form ref="editDialogForm" v-model="isNewItemValid">
                    <v-textarea
                      v-model.trim="newGlobalVariable.variableKey"
                      :autocomplete="$store.getters.disableAutocomplete"
                      label="Key"
                      rows="1"
                      auto-grow
                      :rules="[requiredField, isVariableField, isUniqueGlobalVariable]"
                    />
                    <v-textarea
                      ref="itemValue"
                      v-model.trim="newGlobalVariable.variableValue"
                      :autocomplete="$store.getters.disableAutocomplete"
                      label="Value"
                      rows="1"
                      :rules="[requiredField]"
                      @input="checkValue"
                    >
                      <template slot="append">
                        <v-flex xs1>
                          <v-tooltip right>
                            <template #activator="{ on }">
                              <v-icon :disabled="newGlobalVariable.variableValue == ''" :color="colorValidation" v-on="on">
                                check_circle
                              </v-icon>
                            </template>
                            <span>{{ messageValidation }}</span>
                          </v-tooltip>
                        </v-flex>
                      </template>
                    </v-textarea>
                  </v-form>
                </v-card-text>
              </perfect-scrollbar>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="4" class="px-2">
                    <v-btn block outlined color="success" :disabled="!isNewItemValid" @click="saveNewItem(false)">
                      <v-icon left>
                        mdi-floppy
                      </v-icon>Save
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="px-2">
                    <v-btn
                      block
                      outlined
                      color="primary"
                      :disabled="!isNewItemValid"
                      @click="saveAndAddAnotherNewItem"
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>Add More
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="px-2">
                    <v-btn block outlined color="error" @click="closeDialog">
                      <v-icon left>
                        mdi-close
                      </v-icon>Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import ConfirmDialog from "../../../components/other/ConfirmationDialog";
import draggable from "vuedraggable";
import XRegExp from "xregexp";

export default {
  name: "GlobalVariables",
  components: {
    ConfirmDialog,
    draggable,
  },
  props: ["configuration"],
  data() {
    return {
      rerender: 0,
      dataTableHeight: 50,
      headers: [
        {
          text: "",
          value: "drag",
          sortable: false,
          class: "hidden-sm-and-down drag",
          width: "width:80px",
        },
        {
          text: "Key",
          value: "",
          sortable: false,
        },
        { text: "Value", value: "", sortable: false },
        { text: "", value: "edit", sortable: false, width: "width:100px" },
        {
          text: "",
          value: "delete",
          sortable: false,
          deleteAll: true,
          width: "width:140px",
        },
      ],
      newGlobalVariable: {
        variableKey: "",
        variableValue: "",
      },
      isNewItemValid: false,
      placeholderPattern: ".*%[\\pL0-9]+[\\pL0-9\\-_\\\\]*%.*",
      colorValidation: "",
      messageValidation: "",
      showConfirm: false,
      editDialog: false,
      editedKey: null,
      itemToDelete: null,
      deleteMessage: "",
    };
  },
  computed: {
    calcHeight() {
      this.rerender;
      let elements = 1;
      if (this.configuration.global_variables) elements = this.configuration.global_variables.length;
      let h = 0;
      if (elements === 0) {
        h = 180;
      } else {
        h = 200 + elements * 25;
        this.setDataTableHeight(h - 70);
        if (h > 460) {
          h = 460;
          this.setDataTableHeight(h - 80);
        }
      }
      return "height:" + h + "px;!important";
    },
  },
  mounted() {
    this.rerender++;
  },
  methods: {
    ...fieldValidators,
    getUpdatedGlobalVariables: function () {
      return this.configuration.global_variables;
    },
    setDataTableHeight(val) {
      this.dataTableHeight = val;
    },
    checkValue() {
      if (this.newGlobalVariable.variableValue !== "") {
        let regexp = new XRegExp(this.placeholderPattern, "gi");
        let res = regexp.exec(this.newGlobalVariable.variableValue.replace(/["']/g, ""));
        if (res) {
          this.colorValidation = "grey";
          this.messageValidation = "Can not evaluate text because contains a variable";
          return;
        }

        // Controllo numeri, booleani e stringhe e gli object
        try {
          let result = eval(this.newGlobalVariable.variableValue);
          this.colorValidation = "success";
          if (result == null) {
            this.messageValidation = "Validated as NULL";
          } else {
            switch (typeof result) {
              case "string":
                this.messageValidation = "Validated as STRING";
                break;
              case "number":
                this.messageValidation = "Validated as NUMBER";
                break;
              case "boolean":
                this.messageValidation = "Validated as BOOLEAN";
                break;
              case "object":
                if (Array.isArray(result)) this.messageValidation = "Validated as Array";
                else this.messageValidation = "Validated as OBJECT";
            }
          }
        } catch (error) {
          // Controllo i JSON
          try {
            eval(JSON.parse(this.newGlobalVariable.variableValue));
            this.colorValidation = "success";
            this.messageValidation = "Validated as JSON";
          } catch (e) {
            this.colorValidation = "error";
            this.messageValidation = "Invalid value: " + error;
          }
        }
      }
    },
    closeDialog() {
      this.editDialog = false;
      this.showConfirm = false;
      this.editedKey = null;
      this.resetNewGlobalVariable();
    },
    deleteItem() {
      if (this.itemToDelete == -1) {
        this.configuration.global_variables = [];
      } else {
        this.configuration.global_variables.splice(this.itemToDelete, 1);
      }
      this.showConfirm = false;
      this.rerender++;
    },
    editItem(item, index) {
      this.editedKey = index;
      this.newGlobalVariable = {
        variableKey: item.key,
        variableValue: item.value,
      };
      this.editDialog = true;
      this.rerender++;
      this.checkValue();
    },
    isUniqueGlobalVariable() {
      const res = this.configuration.global_variables.find((obj, index) => {
        return obj.key == this.newGlobalVariable.variableKey && index != this.editedKey;
      });
      if (res) {
        return "Global variables keys must be unique";
      }
      return true;
    },
    resetNewGlobalVariable() {
      this.newGlobalVariable = {
        variableKey: "",
        variableValue: "",
      };
      if (this.$refs.editDialogForm) {
        this.$refs.editDialogForm.resetValidation();
      }
      this.rerender++;
    },
    saveAndAddAnotherNewItem() {
      this.saveNewItem(true);
    },
    saveNewItem(skipCloseModal) {
      if (this.isNewItemValid) {
        let newVar = {
          key: this.newGlobalVariable.variableKey,
          value: this.newGlobalVariable.variableValue,
        };
        if (this.editedKey != null && this.editedKey >= 0) {
          this.configuration.global_variables[this.editedKey] = newVar;
        } else {
          this.configuration.global_variables.push(newVar);

          if (this.configuration.global_variables.length > 1) {
            this.scrollToBottom;
          }
        }
        this.editedKey = null;
        if (!skipCloseModal) {
          this.closeDialog();
        } else {
          this.resetNewGlobalVariable();
        }
        this.rerender++;
      }
    },
    showConfirmDelete(item, index) {
      this.deleteMessage = null;
      if (item === "all") {
        this.itemToDelete = index;
        this.deleteMessage = "You will delete all global variables";
      } else {
        this.itemToDelete = index;
        this.deleteMessage = "You will delete " + item.key;
      }
      this.showConfirm = true;
    },
    scrollToBottom() {
      let wrapper = this.$refs["psGlobalVariables"];
      this.$vuetify.goTo(".formFiledRow:last-of-type", { container: wrapper });
    },
  },
};
</script>
