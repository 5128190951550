<template>
  <v-dialog v-model="showDiaglog" max-width="500" persistent>
    <v-card>
      <v-card-title class="primary white--text">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form ref="inputForm" v-model="form">
          <v-text-field
            v-model.trim="inputBoxValue"
            :label="inputLabel"
            class="mt-4"
            :rules="[requiredField, validateFlowName]"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" class="px-2">
            <v-btn
              block
              outlined
              color="success"
              :disabled="!form"
              @click.prevent="sendConfirmation"
            >
              <v-icon left>
                mdi-check
              </v-icon>{{ submitButtonLabel }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="px-2">
            <v-btn
              block
              outlined
              color="error"
              @click.prevent="$emit('dialogDeny')"
            >
              <v-icon left>
                mdi-close
              </v-icon>{{ cancelButtonLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";

export default {
  name: "InputTextDialog",
  props: {
    showDiaglog: Boolean,
    title: {
      type: String,
      default: "New Flow Name"
    },
    inputLabel: {
      type: String,
      default: "Flow Name"
    },
    submitButtonLabel: {
      type: String,
      default: "Clone"
    },
    cancelButtonLabel: {
      type: String,
      default: "Cancel"
    }
  },
  data: function() {
    return {
      form: false,

      inputBoxValue: ""
    };
  },
  methods: {
    ...fieldValidators,
    sendConfirmation: function() {
      this.$emit("dialogSubmit", this.inputBoxValue);
      setTimeout(() => {
        this.inputBoxValue = "";
        this.$refs.inputForm.resetValidation();
      }, 500);
    }
  }
};
</script>
