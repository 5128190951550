import store from "@/store";

export default {
  getTemplateInfo,
  getHeaderType,
  assignHeaderText,
  getCareWindowTemplate
};

function getTemplateInfo(item) {
  let templates = store.getters.getWhatsappTemplates;
  let templateSelected = {};
  if (templates && Object.keys(templates).length > 0 && templates[item.group]) {
    for (let template of templates[item.group]) {
      if (
        template.name == item.cleanName &&
        template.language === item.language
      ) {
        templateSelected = template;
        break;
      }
    }
  }
  if (
    Object.keys(templateSelected).length === 0 &&
    templates &&
    Object.keys(templates).length > 0
  ) {
    let whatsappNumbers = Object.keys(templates);
    if (!whatsappNumbers.includes(item.group)) {
      whatsappNumbers.forEach(number => {
        templates[number].forEach(el => {
          if (item.name === el.name + " (" + el.language + ")") {
            item.group = number;
            templateSelected = el;
          }
        });
      });
    }
  }
  return templateSelected;
}
function getHeaderType(templateSelected) {
  if (
    templateSelected.structure &&
    templateSelected.structure.header &&
    templateSelected.structure.header.format
  ) {
    return templateSelected.structure.header.format;
  } else if (templateSelected.header && templateSelected.header.format) {
    return templateSelected.header.format;
  } else {
    return "None";
  }
}
function assignHeaderText(templateSelected) {
  if (templateSelected.header && templateSelected.header.format == "TEXT") {
    return templateSelected.header.text;
  }
  if (
    templateSelected.structure &&
    templateSelected.structure.header &&
    templateSelected.structure.header.format == "TEXT"
  ) {
    return templateSelected.structure.header.text;
  }
  return "";
}

function getCareWindowTemplate(cwEnabled, template, cwValue) {
  if (cwEnabled && template) {
    if (!cwValue.template.value) {
      //arrivo da inactivity che aveva una struttura ad cazzum
      cwValue.template = {
        value: cwValue.template
      };
    }
    return {
      value: {
        enabled: {
          value: cwEnabled
        },
        template: {
          templateName: {
            value: cwValue.template.value
          },
          headerType: {
            valueSelected:
              template &&
              template.structure &&
              template.structure.header &&
              template.structure.header.format === "TEXT"
                ? template.structure.header.text
                : "None"
          },
          headerSettings: {
            value: {
              url: "",
              documentName: "",
              latitude: "",
              longitude: ""
            }
          },
          conditionPhoneNumber: {
            value: 0
          },
          phoneNumber: {
            value: {
              multiple: [],
              dynamic: ""
            }
          },
          language: {
            value: cwValue.template.value.language //meglio mettere quella che c'era prima per non spaccare il giro
          },
          buttons: {
            value: []
          },
          responseVariable: {
            value: ""
          },
          body: {
            value: cwValue.template.value.body.indexOf("{{1}}")
              ? ["%lastOutputText%"]
              : [] // se ho un input, almeno quello deve essere con il lastoutputtext
          }
        }
      }
    };
  }
  if (!cwEnabled && !template) {
    return {
      value: {
        enabled: {
          value: false
        },
        template: {
          templateName: {
            value: ""
          },
          headerType: {
            valueSelected: ""
          },
          conditionPhoneNumber: {
            value: 0
          },
          phoneNumber: {
            value: {
              multiple: [],
              dynamic: ""
            }
          },
          headerSettings: {
            value: {
              url: "",
              documentName: "",
              latitude: "",
              longitude: ""
            }
          },
          language: {
            value: "%lang%" //di default mettiamo la nostra variabile
          },
          buttons: {
            value: []
          },
          responseVariable: {
            value: ""
          },
          body: {
            value: []
          }
        }
      }
    };
  }
}
