<template>
  <v-container id="profile" fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Profile"
      @input="
        searchString = $event;
        forceRerender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="noQueueCreated" cols="12">
        <v-alert v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" type="warning" icon="mdi-alert">
          <v-row>
            <v-col cols="12">
              To create a profile you first need a queue: go to the Queues page or using the shortcut button below:
            </v-col>
            <v-col cols="12" class="pt-4" align="center" justify="center">
              <router-link class="white--text headline" to="/queues" style="text-decoration: none">
                <v-btn small color="white" class="primary--text">
                  <v-icon x-small left>
                    mdi-arrow-right
                  </v-icon> to the Queues page
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert v-else type="warning">
          No Profiles configured
        </v-alert>
      </v-col>

      <v-col v-else-if="showWarning && !newConfig && getProfilesList.length === 0 && searchString == ''" cols="12">
        <v-alert type="warning">
          No Profiles configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && !newConfig && getProfilesList.length === 0 && searchString != ''" cols="12">
        <v-alert type="warning">
          No Profile match your search
        </v-alert>
      </v-col>

      <!-- Expansion per nuovo elemento -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Profile
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <ProfileForm :agentDesktopList="agentDesktop" :queuesList="queues" :profilesList="profiles" :profileDetail="newProfile" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(profile, id) in getProfilesList" :key="'profile_' + id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col md="2" sm="2" lg="2" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ profile.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="4" sm="4" lg="5" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>UUID</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ profile.uuid }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="1" sm="1" lg="1" class="hidden-sm-and-down text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Queues</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip
                          v-if="profile.routing.queues"
                          :class="profile.routing.queues.length === queues.length ? 'text-uppercase' : 'queue-global-chip'"
                          :color="profile.routing.queues.length === queues.length ? 'primary queue-number-chip' : 'success'"
                          small
                        >
                          {{ profile.routing.queues.length === queues.length ? "All" : profile.routing.queues.length }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="2" sm="2" lg="2" class="hidden-sm-and-down text-center text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Agent Desktop</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ getUsedAgentDesktop(profile) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="3" sm="3" lg="2" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title>{{ profile.updated_at | formatVerboseDateTime }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <ProfileForm
              :key="forceRerender + id + '_sr'"
              :agentDesktopList="agentDesktop"
              :queuesList="queues"
              :profilesList="profiles"
              :profileDetail="profile"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredProfiles.length > 0"
      :items="filtredProfiles.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRerender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton v-if="!noQueueCreated && hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" name="Profile" @addNewItem="addNewProfile" />
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import ProfileForm from "@/components/humanAgents/ProfileForm";
import scroller from "../../helpers/scrollToTop";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
import Pagination from "../../components/other/Pagination";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "Profiles",
  components: {
    ProfileForm,
    ResultSnackbar,
    SearchBar,
    AddNewItemButton,
    Pagination,
  },
  data() {
    return {
      searchString: "",
      expansionPanelCreation: null,
      newConfig: false,
      profiles: [],
      queues: [],
      agentDesktop: [],
      forceRerender: 0,
      expansionPanel: null,
      newProfile: null,
      noQueueCreated: false,
      showWarning: false,
      filtredProfiles: [],
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
    };
  },
  computed: {
    ...spacing,
    getProfilesList() {
      this.forceRerender;
      let filterData = this.filterData();
      this.scrollToTop();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("wcs")) {
      this.$router.push("/");
    }
    EventBus.$on(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, this.profileCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_PROFILE_FAIL, this.profileCreatedFail);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_PROFILE_CREATION, this.cancelProfileCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_PROFILE_SUCCESS, this.profileDeleted);
    EventBus.$on(this.$store.getters.getEvents.DELETE_PROFILE_FAIL, this.failDeleteProfile);
    EventBus.$on(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
    try {
      const queueResult = await this.$http.get("/human-agents/queue");
      this.queues = queueResult.data;
      if (this.queues.length == 0) {
        this.noQueueCreated = true;
      }
      const profileResult = await this.$http.get("/human-agents/profile");
      this.profiles = profileResult.data;

      const agentDesktop = await this.$http.get("/agent-ui/list");
      this.agentDesktop = agentDesktop.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, this.profileCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_PROFILE_FAIL, this.profileCreatedFail);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_PROFILE_CREATION, this.cancelProfileCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_PROFILE_SUCCESS, this.profileDeleted);
    EventBus.$off(this.$store.getters.getEvents.DELETE_PROFILE_FAIL, this.failDeleteProfile);
    EventBus.$off(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,
    getUsedAgentDesktop(profile) {
      if (profile?.interface?.uuid) {
        const agentDesktop = this.agentDesktop.find((agentDesktop) => agentDesktop.uuid === profile.interface.uuid);
        if (agentDesktop) {
          return agentDesktop.name;
        }
      } else {
        const agentDesktopDefault = this.agentDesktop.find((agentDesktop) => agentDesktop.default);
        if (agentDesktopDefault) {
          return `Default (${agentDesktopDefault.name})`;
        } else {
          return "System";
        }
      }
    },
    profileCreated(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      const index = this.profiles.findIndex((p) => p.uuid === obj.editProfile.uuid);
      if (index < 0) {
        //Siamo in creazione devo fare una push nel mio array che poi verrà ordinato dalla computed
        this.profiles.push(obj.editProfile);
      } else {
        //Siamo in edit aggiorno quello che ho trovato
        this.profiles[index] = merge({}, obj.editProfile);
      }
      this.closeAllPanels();
      this.forceRerender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    profileCreatedFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    cancelProfileCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    profileDeleted(obj) {
      const index = this.profiles.findIndex((p) => p.uuid === obj.profile);
      if (index > -1) {
        this.profiles.splice(index, 1);
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        this.forceRerender++;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    failDeleteProfile(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels: function () {
      this.cancelProfileCreation();
      this.expansionPanel = null;
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewProfile() {
      this.newConfig = true;
      if (!this.newProfile) {
        this.newProfile = {
          name: "",
          routing: {
            concurrentTask: 1,
            concurrentWebcallbackTask: 0,
            concurrentEmailTask: 0,
            queues: [],
          },
          interface: {
            uuid: "",
            properties: {},
          },
        };
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    onEriCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    filterQueues(profile) {
      let res = false;
      for (const queue of this.queues) {
        if (profile.routing.queues.includes(queue.eri)) {
          if (queue.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1) {
            res = true;
          }
        }
      }
      return res;
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    filterData() {
      let result = [].concat(this.profiles);
      //Filtro per la ricerca
      this.filtredProfiles = result
        .filter(
          (profile) =>
            profile.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
            profile.eri.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
            this.getUsedAgentDesktop(profile).toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
            this.filterQueues(profile),
        )
        //Sorting per un campo
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      return this.filtredProfiles;
    },
  },
};
</script>
<style>
#profile .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#profile .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
