<template>
  <span>
    <v-data-table
      :headers="headersInitialVariables"
      :items="getInitialVariables"
      :items-per-page="10"
      dense
      disable-pagination
      style="width:100%"
      hide-default-footer
    >
      <template #headers="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text" />
        </tr>
      </template>
      <template #body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.key"
            @mouseover="selectItem(item)"
            @mouseleave="unSelectItem()"
          >
            <td>{{ item.key }}</td>
            <td class="text-truncate">
              <v-edit-dialog :return-value.sync="item.value">
                <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndUp">
                  <template #activator="{ on }">
                    <div
                      class="px-0 mx-0"
                      :class="$vuetify.breakpoint.xsOnly ? 'text-truncate' : ''"
                      :style="
                        $vuetify.breakpoint.xsOnly
                          ? 'max-width:100px'
                          : 'max-width:130px'
                      "
                      v-on="on"
                    >
                      {{ item.value }}
                    </div>
                  </template>
                  <span>{{ item.value }}</span>
                </v-tooltip>

                <template #input>
                  <v-text-field
                    v-model.trim="item.value"
                    label="Edit and press enter to confirm"
                    single-line
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                    @change="editItem(item.key, item.value)"
                    @keydown.enter.prevent="preventSave"
                  />
                </template>
              </v-edit-dialog>
            </td>
            <td style="width:50px">
              <div style="width:50px">
                <v-btn v-show="item === selectedItem" icon x-small class="pt-0">
                  <v-icon @click="deleteInitialVariable(item)">delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" sm="12" md="12" lg="4" class="d-flex pt-12">
        <v-dialog v-model="dialogNewItem" max-width="500px">
          <template #activator="{ on }">
            <v-btn
              color="primary"
              v-on="on"
            ><v-icon left>mdi-plus</v-icon> Add new variable</v-btn>
          </template>
          <v-card>
            <v-card-title class="headline primary white--text">Add new initial variable
            </v-card-title>
            <v-card-text>
              <v-form ref="initialVariablesForm" v-model="form.isValid">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="newInitialvariable.key"
                        label="Key"
                        :rules="[requiredField]"
                        :error-messages="invalidVariable"
                        :autocomplete="$store.getters.disableAutocomplete"
                        validate-on-blur
                        @change="deleteErrorMessage"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="newInitialvariable.value"
                        label="Value"
                        :rules="[requiredField]"
                        :autocomplete="$store.getters.disableAutocomplete"
                        validate-on-blur
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row no-gutters>
                <v-col cols="6" class="px-2">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="addNewVariable(newInitialvariable)"
                  ><v-icon left>mdi-floppy</v-icon>Save</v-btn>
                </v-col>
                <v-col cols="6" class="px-2">
                  <v-btn
                    outlined
                    block
                    color="error"
                    @click="close"
                  ><v-icon left>mdi-close</v-icon>Cancel</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import fieldValidators from "../../helpers/fieldValidators";
import spacing from "../../helpers/spacing";
export default {
  name: "InitialVariables",
  props: {
    "script": {
      type: Object,
      required: true
    },
    "initialVariables": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        isValid: false
      },
      isValidInlineChange: false,
      selectedItem: false,
      uniqueVariable: false,
      invalidVariable: null,
      dialogNewItem: false,
      forceRerender: 0,
      newInitialvariable: {
        key: "",
        value: ""
      },
      headersInitialVariables: [
        { text: "Initial Variable", value: "key" },
        { text: "Value", value: "value" },
        {
          text: "",
          value: "delete",
          align: "center",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...spacing,
    getInitialVariables: function() {
      this.forceRerender;
      return this.initialVariables;
    }
  },
  watch: {
    dialogNewItem(val) {
      val || this.close();
    }
  },
  methods: {
    ...fieldValidators,
    editItem: function(key, value) {
      this.isValidInlineChange = false;
      if (value) {
        this.isValidInlineChange = true;
        this.addItem(key, value);
      }
    },
    // this method prevents the dialog from closing during the INLINE change
    preventSave(event) {
      if (!this.isValidInlineChange) {
        event.stopImmediatePropagation();
      }
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    },
    close: function() {
      this.dialogNewItem = false;
      this.newInitialvariable.key = "";
      this.newInitialvariable.value = "";
      this.$refs.initialVariablesForm.resetValidation();
      this.invalidVariable = null;
    },
    addNewVariable: function(variable) {
      this.$refs.initialVariablesForm.validate();
      if (this.form.isValid) {
        this.checkKey(variable.key);
        if (!this.notUnique) {
          this.initialVariables.push(variable);
          this.forceRerender++;
          this.$emit("update:initialVariables", this.initialVariables);
        } else {
          this.invalidVariable =
            "This key is already used. Please choose another one.";
        }
        this.notUnique = false;
      }
    },
    checkKey(key) {
      for (let i = 0; i < this.initialVariables.length; i++) {
        if (this.initialVariables[i].key == key) {
          this.notUnique = true;
        }
      }
    },
    deleteErrorMessage() {
      this.invalidVariable = null;
    },
    deleteInitialVariable(variable) {
      for (let i = 0; i < this.initialVariables.length; i++) {
        if (this.initialVariables[i].key == variable.key) {
          this.initialVariables.splice(i, 1);
        }
      }
      this.forceRerender++;
      this.$emit("update:initialVariables", this.initialVariables);
    }
  }
};
</script>
