<template>
  <v-expansion-panels class="my-6 mx-0">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span>
          <v-icon class="mb-1" color="primary">mdi-bullhorn-outline</v-icon>
          <span class="ml-4">Outbound campaign</span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0 pb-4">
          <v-col cols="12" class="py-2">
            <v-switch v-model="parameters.outboundParams.enabled" label="Enable Outbound" color="primary" />
          </v-col>
          <v-col v-if="parameters.outboundParams.enabled" cols="6" class="py-2">
            <v-text-field
              v-model.trim="parameters.outboundParams.campaignName"
              prepend-inner-icon="mdi-card-bulleted"
              label="Campaign name"
              type="text"
              :rules="[requiredField, simpleValidatorNameWithSpaces, isOutboundCampaignNameUnique(connectors, parameters)]"
            />
          </v-col>

          <v-col v-if="parameters.outboundParams.enabled" cols="12" md="6" class="py-2" :class="getFormColMargin">
            <v-autocomplete
              v-model="parameters.outboundParams.outboundBot"
              label="Outbound Bot"
              :items="botListItems"
              :return-object="false"
              :rules="[requiredField]"
            />
          </v-col>

          <v-col v-if="parameters.outboundParams.enabled" cols="6" class="py-2">
            <v-autocomplete
              v-model="parameters.outboundParams.enabledProfiles"
              prepend-inner-icon="mdi-drama-masks"
              :items="profileListItems"
              :rules="[]"
              multiple
              small-chips
              :label="selectedProfileLabel"
              hint="You can add the profiles to limit the campaign to only the enabled profiles, otherwise by leaving this field blank the campaign will be enabled on all the profiles."
              persistent-hint
            />
          </v-col>

          <v-col v-if="parameters.outboundParams.enabled && connectorType === 'whatsapp'" cols="6" class="py-2" :class="getFormColMargin">
            <v-autocomplete
              v-model="parameters.outboundParams.outboundWhatsappTemplate"
              label="Template"
              :items="whatsappTemplatesItems"
              :return-object="false"
              :rules="[requiredField]"
            />
          </v-col>
          <!-- serve per avere il template sotto al campo di scelta del template stesso -->
          <v-col
            v-if="parameters.outboundParams.enabled && connectorType === 'whatsapp' && parameters.outboundParams.outboundWhatsappTemplate"
            cols="6"
            class="py-2"
            :class="getFormColMargin"
          />
          <v-col
            v-if="parameters.outboundParams.enabled && connectorType === 'whatsapp' && parameters.outboundParams.outboundWhatsappTemplate"
            cols="6"
            class="py-2"
            :class="getFormColMargin"
          >
            <code v-if="parameters.outboundParams.outboundWhatsappTemplate?.body" class="align-self-center text-center pt-1 pb-1">{{
              parameters.outboundParams.outboundWhatsappTemplate?.body
            }}</code>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
export default {
  name: "OutboundParameters",
  props: ["parameters", "profiles", "bots", "whatsappTemplates", "connectorType", "connectors"],
  data() {
    return {};
  },
  computed: {
    profileListItems() {
      let result = [];
      result = this.profiles.map((profile) => {
        return { text: profile.name, value: profile.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    botListItems() {
      let result = [];
      for (const bot in this.bots) {
        if (this.bots[bot].isOutbound) {
          result.push({ text: this.bots[bot].name, value: this.bots[bot].bot_id });
        }
      }

      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    whatsappTemplatesItems() {
      let templates = this.whatsappTemplates;
      let list = [];
      if (!templates) {
        return list;
      }
      const account = this.parameters?.details?.wa_account;
      if (templates[account]) {
        for (let template of templates[account]) {
          const templateType = template.structure ? template.structure.type : template.type;
          let body = template.structure ? template.structure.body : template.body;
          if (templateType === "TEXT") {
            list.push({
              text: template.name + " (" + template.language + ")",
              value: {
                name: template.name + " (" + template.language + ")",
                cleanName: template.name,
                group: account,
                body: body,
                language: template.language,
              },
            });
          }
        }
      }
      return list.sort((a, b) => (a?.text > b?.text ? 1 : b?.text > a?.text ? -1 : 0));
    },
    selectedProfileLabel() {
      if (this.parameters.outboundParams.enabledProfiles.length === 0) {
        return "Campaign enabled on all profiles";
      } else {
        return "Campaign enabled on these profiles";
      }
    },
  },
  mounted() {},
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
