<template>
  <v-container class="mt-5" fluid>
    <div class="subtitle-1 mb-2">Agents will always appear available to chat.</div>
  </v-container>
</template>

<script>
export default {
  name: "Always",
};
</script>

<style scoped></style>
