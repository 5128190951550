<template>
  <v-container id="attachedVariablesPage" fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Handover parameters"
      @input="
        searchString = $event;
        forceRender++;
        pageNumber = 1;
      "
    />

    <v-col class="px-7" cols="12">
      <v-alert type="info" icon="mdi-information" color="primary" class="mx-1">
        <v-row class="no-gutters">
          <v-col cols="12">
            Define data variables group that will be sent to the agent, after a "ConvyAI Agent" handover type.
          </v-col>
        </v-row>
        <v-row class="no-gutters">
          <v-col cols="12">
            The information will be visible in the "Customer Info" section of "ConvyAI Agent Desktop" interface.
          </v-col>
        </v-row>
      </v-alert>
    </v-col>

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getAttachedVariablesList.length == 0 && !newConfig && searchString == ''" cols="12">
        <v-alert type="warning">
          No Handover parameters configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getAttachedVariablesList.length == 0 && !newConfig && searchString != ''" cols="12">
        <v-alert type="warning">
          No Handover parameters match your search
        </v-alert>
      </v-col>
      <!-- Panel for new item -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Handover parameters
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <AttachedVariableForm :queuesList="queues" :attachedVariablesList="attachedVariablesList" :attachedVariableSetting="newConfig" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel to list configuration -->

      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(attachedVariableSetting, id) in getAttachedVariablesList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col class="text-no-wrap" cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Group Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ attachedVariableSetting.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate" style="max-width: 600px" cols="7" md="6">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Content</v-list-item-subtitle>
                      <v-list-item-title> {{ attachedVariableSetting.content.length }} variables </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-center hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Available on</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip
                          v-if="attachedVariableSetting.availability && attachedVariableSetting.availability.length > 0"
                          class="queue-global-chip"
                          color="success"
                          small
                        >
                          {{ attachedVariableSetting.availability.length }}
                        </v-chip>
                        <v-chip v-else color="primary queue-number-chip" small class="text-uppercase">
                          Global
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-md-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="attachedVariableSetting.lastUpdate">
                        {{ attachedVariableSetting.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <AttachedVariableForm
              :key="forceRender + id + '_sc'"
              :queuesList="queues"
              :attachedVariablesList="attachedVariablesList"
              :attachedVariableSetting="attachedVariableSetting"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredAttachedVariable.length > 1"
      :items="filtredAttachedVariable.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRender++;
      "
      @updatePageSize="updatePageSize($event)"
    />
    <AddNewItemButton v-if="!isCCMDisableProfile" name="Handover parameters group" @addNewItem="addNewConfig" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import AttachedVariableForm from "../../components/humanAgents/AttachedVariableForm";
import Pagination from "../../components/other/Pagination";

export default {
  name: "AttachedVariables",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    AttachedVariableForm,
    Pagination,
  },
  data() {
    return {
      expansionPanelCreation: null,
      expansionPanel: null,
      attachedVariablesList: null,
      forceRender: 0,
      searchString: "",
      //Data bind per creazione nuova ocnfigurazione
      newConfig: null,
      showWarning: false,
      queues: [],
      filtredAttachedVariable: [],
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
    };
  },
  computed: {
    ...spacing,
    getAttachedVariablesList() {
      this.forceRender;
      let filterData = this.filterData();
      this.scrollToTop();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    isCCMDisableProfile() {
      const user = this.$store.getters.getUser;
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.queues && this.queues.length === 0) {
        return true;
      }
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER && this.queues && this.queues.length === 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_ATTACHED_VARIABLES_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES, this.editAttachedVariable);
    EventBus.$on(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES, this.deleteAttachedVariable);
    EventBus.$on(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES_FAIL, this.editAttachedVariableFail);
    EventBus.$on(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES_FAIL, this.deleteAttachedVariableFail);
    //Load Configurations
    let response = null;
    try {
      response = await this.$http.get("/human-agents/queue");
      this.queues = response.data;
      this.queues = this.queues.sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });
    } finally {
      response = null;
    }
    try {
      const result = await this.$http.get("/human-agents/attached-variable");
      this.attachedVariablesList = result.data;
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail to load Attached Variables`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_ATTACHED_VARIABLES_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES, this.editAttachedVariable);
    EventBus.$off(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES, this.deleteAttachedVariable);
    EventBus.$off(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES_FAIL, this.editAttachedVariableFail);
    EventBus.$off(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES_FAIL, this.deleteAttachedVariableFail);
  },
  methods: {
    ...scroller,
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewConfig() {
      if (!this.newConfig) {
        this.newConfig = {
          eri: null,
          label: "",
          content: [],
          availability: [],
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
    },
    editAttachedVariable(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      this.closeAllPanels();
      this.attachedVariablesList[obj.editConfig.label] = obj.editConfig;
      this.forceRender++;
    },
    editAttachedVariableFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteAttachedVariable(obj) {
      if (this.attachedVariablesList[obj.label]) {
        delete this.attachedVariablesList[obj.label];
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRender++;
        }, 100);
      }
    },
    deleteAttachedVariableFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    filterData() {
      this.attachedVariablesList
        ? (this.filtredAttachedVariable = Object.values(this.attachedVariablesList)
            //Filtro per la ricerca
            .filter(
              (attachedVariables) =>
                attachedVariables.label.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
                attachedVariables.label.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
            )
            //Sorting per un campo
            .sort((a, b) => {
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }))
        : (this.filtredAttachedVariable = []);
      return this.filtredAttachedVariable;
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
  },
};
</script>

<style>
#attachedVariablesPage .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#attachedVariablesPage .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
