<template>
  <v-container fluid class="my-0 pa-0">
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="getFirstItemOfSelectedLanguage"
          :autocomplete="$store.getters.disableAutocomplete"
          rows="1"
          :label="customLabel"
          :disabled="disabledFlag"
          :error-messages="errorMessage"
          auto-grow
          class="ma-0 pa-0"
          :rules="computedRules"
        >
          <v-tooltip slot="append-outer" left>
            <template #activator="{ on }">
              <v-btn
                tabindex="-1"
                icon
                rounded
                text
                class="ma-0 float-right"
                v-on="on"
                @click.stop="openMultilanguageDrawer"
              >
                <v-icon>playlist_add</v-icon>
              </v-btn>
            </template>
            <span>Add more</span>
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" class="pr-2" style="width:100%; text-align:right;">
        <flag
          v-for="(flag, fIndex) in getList"
          :key="fIndex"
          :iso="flag"
          class="mr-1"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
export default {
  name: "MultiLanguageItem",
  components: {},
  props: [
    "itemObj",
    "itemName",
    "required",
    "advancedValidation",
    "customLabel",
    "errorMessage",
    "disabledFlag"
  ],
  data() {
    return {};
  },
  computed: {
    computedRules() {
      let rules = this.required ? [this.requiredField] : [];
      if (this.advancedValidation) {
        rules = rules.concat(this.advancedValidation);
      }
      return rules;
    },
    langSelect() {
      return this.$store.getters
        .getFlagFromLanguageCode(this.$store.getters.getSelectedLang)
        .flag.toLowerCase();
    },
    langName() {
      return this.$store.getters.getFlagFromLanguageCode(
        this.$store.getters.getSelectedLang
      ).name;
    },
    getFirstItemOfSelectedLanguage: {
      get() {
        let selectedLangCode = this.$store.getters.getSelectedLang;
        if (this.itemObj && this.itemObj.length > 0) {
          let firstItemLang = this.itemObj[0].code;
          if (firstItemLang != selectedLangCode) {
            this.sortItemObj(selectedLangCode);
          }
          return this.itemObj[0].value;
        }
        return "";
      },
      set(newValue) {
        if (!this.itemObj) {
          this.itemObj = {};
        }
        if (this.itemObj.length == 0) {
          let selectedLangCode = this.$store.getters.getSelectedLang;
          this.itemObj[0] = {
            code: selectedLangCode,
            flag: this.langSelect,
            label: this.langName,
            value: newValue
          };
        } else {
          this.itemObj[0].value = newValue;
        }
      }
    },
    getList: {
      get() {
        let uniqueFlags = [];
        for (let i = 0; i < this.itemObj.length; i++) {
          let flag = this.itemObj[i].flag.toLowerCase();
          if (this.itemObj[i].value && uniqueFlags.indexOf(flag) == -1) {
            uniqueFlags.push(flag);
          }
        }
        return uniqueFlags;
      }
    }
  },
  methods: {
    ...fieldValidators,
    openMultilanguageDrawer() {
      this.$emit("openOverlay", this.itemObj, false);
    },
    sortItemObj(selectedLangCode) {
      this.itemObj.sort((a, b) => {
        if (a.code == selectedLangCode && b.code != selectedLangCode) {
          return -1;
        }
        if (a.code != selectedLangCode && b.code == selectedLangCode) {
          return 1;
        }
        return 0;
      });
    }
  }
};
</script>

<style>
.translationRow .addButton {
  cursor: pointer;
}
</style>
