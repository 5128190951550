<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent" style="height: 460px !important">
      <WidgetToolbar
        :title="wallboard.label"
        buttons="['flip']"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @onChangeView="onChangeView"
        @reloadData="reloadWidgetData"
      />
      <v-row v-if="options.xAxis.data.length === 0" class="no-data-wrap">
        <v-col cols="1" />
        <v-col cols="10">
          <v-alert type="warning" icon="mdi-alert">
            <h4>No data available</h4>
          </v-alert>
        </v-col>
        <v-col cols="1" />
      </v-row>
      <span v-if="options.xAxis.data.length > 0" @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-chart
          v-if="selectedView === 'chart'"
          ref="agentCapacity"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart"
          :option="options"
        />
        <div v-else-if="selectedView === 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="username"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            sort-by="username"
            must-sort
            dense
          >
            <template #top>
              <v-row no-gutters>
                <v-col cols="7">
                  <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
                </v-col>
                <v-col cols="4">
                  <v-select v-model="agentStatus" :items="agentStatusLists" label="Filter by agent status" clearable @change="filterByAgentStatus" />
                </v-col>
              </v-row>
            </template>
            <template #item.status="{ item }">
              <v-icon :color="getStatusColor(item.status)">mdi-circle-medium</v-icon>
            </template>
            <template #item.chat="{ item }"> {{ item.chat }}/{{ item.maxChat }} </template>
            <template #item.email="{ item }"> {{ item.email }}/{{ item.maxEmail }} </template>
            <template #item.capacity="{ item }"> {{ item.capacity }}% </template>
          </v-data-table>
        </div>
        <v-container v-else fluid class="chart">
          <v-data-iterator
            :items="getCardList"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            hide-default-footer
            :search="search"
            :sort-by="sortByModel"
            :sort-desc="sortDesc"
          >
            <template #default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.username" :cols="getCols">
                  <v-card style="overflow: auto" height="260px" elevation="3">
                    <v-card-title class="subheading font-weight-bold headingCard white--text" style="font-size: 16px; height: 35px; padding-top: 0px">
                      {{ item.username }}
                    </v-card-title>

                    <v-divider />

                    <v-list dense style="font-size: 12px">
                      <v-list-item>
                        <v-list-item-content>Capacity:</v-list-item-content>
                        <v-list-item-content class="align-end"> {{ item.capacity }}% </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Chat:</v-list-item-content>
                        <v-list-item-content class="align-end"> {{ item.load.loadChat }} / {{ item.load.maxChat }} </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Email:</v-list-item-content>
                        <v-list-item-content class="align-end"> {{ item.load.loadEmail }} / {{ item.load.maxEmail }} </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Status:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.status }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Last Activity:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ formatLastActivity(item) }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Profile:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.profileName }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template #no-data>
              <div style="width: 100%; text-align: center">No data available</div>
            </template>

            <template #header>
              <v-toolbar dense color="white" class="mb-4 elevation-1">
                <v-text-field
                  v-model="search"
                  dense
                  clearable
                  flat
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  class="agentCapacityToolbarInput mr-2"
                  label="Search"
                />
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-select
                    v-model="sortBy"
                    dense
                    flat
                    hide-details
                    :items="keys"
                    prepend-inner-icon="mdi-magnify"
                    class="agentCapacityToolbarInput mr-2"
                    label="Sort by"
                  />
                  <v-select
                    v-model="agentStatus"
                    dense
                    :items="agentStatusLists"
                    label="Filter by status"
                    flat
                    hide-details
                  />
                  <v-spacer />
                  <v-btn-toggle v-model="sortDesc" class="ml-2" mandatory dense>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          dense
                          depressed
                          small
                          color="primary"
                          :value="false"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="white--text">mdi-arrow-up</v-icon>
                        </v-btn>
                      </template>
                      <span>Sort asc</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          dense
                          depressed
                          small
                          color="primary"
                          :value="true"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="white--text">mdi-arrow-down</v-icon>
                        </v-btn>
                      </template>
                      <span>Sort desc</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
            </template>

            <template #footer>
              <v-row v-if="getCardList > 0" class="mt-6 mr-4">
                <v-spacer />
                <span class="mr-4 grey--text mt-1"> Page {{ page }} of {{ numberOfPages }} </span>
                <v-btn dark color="primary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn dark color="primary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </span>
    </v-card>
  </v-col>
</template>
<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import moment from "moment";
import EventBus from "../../../event-bus";

export default {
  name: "AgentCapacity",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "profiles": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewTable: {
        search: "",
        headers: [
          {
            text: "Agent",
            align: "start",
            sortable: true,
            value: "username",
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: "Profile",
            align: "start",
            sortable: true,
            value: "profile",
          },
          {
            text: "Last Activity",
            align: "start",
            sortable: true,
            value: "lastActivity",
          },
          {
            text: "Capacity",
            align: "start",
            sortable: true,
            value: "capacity",
          },
          {
            text: "Chat",
            align: "start",
            sortable: true,
            value: "chat",
          },
          {
            text: "Email",
            align: "start",
            sortable: true,
            value: "email",
          },
        ],
        data: [],
        originalData: [],
      },
      agentStatusLists: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Ready",
          value: "READY",
        },
        {
          text: "Not ready",
          value: "NOT_READY",
        },
      ],
      info: "This widget shows the current agent capacity in percentage. Click on the Detail button on the right for having more info about logged in agent.",
      page: 1,
      reloadWidgetDataInterval: null,
      rawData: [],
      search: "",
      filter: {},
      sortDesc: false,
      sortBy: "Username",
      keys: ["Capacity", "Chat", "Email", "Last Activity", "Profile", "Status", "Username"],
      options: {
        tooltip: {
          trigger: "axis",
          show: true,
          formatter: (params, ticket, callback) => {
            const agent = this.rawData.find((a) => (a.username = params.name));
            if (agent) {
              callback(
                ticket,
                `Capacity ${params.data.raw.capacity}%<br/>Chat: ${params.data.raw.load.loadChat}/${params.data.raw.load.maxChat}<br/>Email: ${
                  params.data.raw.load.loadEmail
                }/${params.data.raw.load.maxEmail}<br/>Status: ${params.data.raw.status}<br/>Last Activity: ${
                  params.data.raw.lastActivity > 0 ? moment(params.data.raw.lastActivity).local().format("DD MMM YYYY, HH:mm:ss") : "N/A"
                }`,
              );
            }
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            inside: true,
            rotate: 90,
            color: this.$vuetify.theme.dark ? "white" : "white",
            fontSize: 16,
            textShadowColor: "rgba(0, 0, 0, 0.9)",
            textShadowOffsetX: "1",
            textShadowOffsetY: "1",
            textShadowBlur: "2",
          },
          z: 10000,
          data: [
            //"matteo.bruni"
          ],
        },
        yAxis: {
          type: "value",
          name: "Percentage %",
          max: 100,
        },
        series: [
          {
            data: [
              // {
              //   value: 80,
              //   itemStyle: {
              //     color: "#FF4500",
              //   },
              // },
            ],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
            },
          },
        ],
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
      agentStatus: "",
    };
  },
  computed: {
    sortByModel() {
      return this.sortBy.toLowerCase();
    },
    itemsPerPage() {
      return this.wallboard.resize ? 6 : 3;
    },
    numberOfPages() {
      return Math.ceil(this.rawData.length / this.itemsPerPage);
    },
    getCols() {
      switch (this.rawData.length) {
        case 1: {
          return this.wallboard.resize ? "6" : "12";
        }
        case 2: {
          return "6";
        }
        case 3: {
          return this.wallboard.resize ? "4" : "4";
        }
        case 4: {
          return this.wallboard.resize ? "3" : "4";
        }
        default: {
          return this.wallboard.resize ? "2" : "4";
        }
      }
    },
    getCardList() {
      if (this.agentStatus && this.agentStatus !== "all") {
        return this.rawData.filter((el) => {
          return el.status === this.agentStatus;
        });
      }
      return this.rawData;
    },
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    getStatusColor(status) {
      if (status === "READY") {
        return "success";
      }
      if (status === "NOT_READY") {
        return "error";
      }
      return "secondary";
    },
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    formatLastActivity(item) {
      return item.lastActivity > 0 ? moment(item.lastActivity).local().format("HH:mm:ss") : "N/A";
    },
    async loadWidgetData() {
      const result = await this.$httpAuth.get("/dashboard/wallboard/agent-capacity");
      this.rawData = [];
      this.viewTable.originalData = [];
      this.options.xAxis.data = [];
      this.options.series[0].data = [];
      for (const agentERI in result.data) {
        const raw = result.data[agentERI];
        this.options.xAxis.data.push(raw.username);

        //const maxAV = raw.load.maxAV || 0;
        //const loadAV = raw.load.loadAV || 0;
        //Non va conteggiata la capacity a/v nella capacity perchè è un sottoinsieme della chat
        const maxCapacity = raw.load.maxChat + raw.load.maxEmail;
        const usedCapacity = raw.load.loadChat + raw.load.loadEmail;
        let value = 0;
        if (maxCapacity > 0) {
          value = Math.round((usedCapacity * 100) / maxCapacity);
        }
        const color = this.getCapacityColor(value);

        raw["last activity"] = raw.lastActivity;
        raw.capacity = value;
        raw.chat = raw.load.loadChat;
        raw.webcallback = raw.load.loadWebcallback;
        raw.email = raw.load.loadEmail;
        raw.audiovideo = raw.load.loadAV;
        this.rawData.push(raw);
        this.options.series[0].data.push({
          value,
          raw,
          itemStyle: {
            color,
          },
        });
        this.viewTable.originalData.push({
          username: raw.username,
          status: raw.status,
          profile: raw.profileName,
          lastActivity: raw.lastActivity > 0 ? moment(raw.lastActivity).local().format("HH:mm:ss") : "N/A",
          capacity: Math.round((((raw.load.loadChat + raw.load.loadEmail) * 100) / maxCapacity) * 100) / 100,
          chat: raw.load.loadChat,
          email: raw.load.loadEmail,
          maxChat: raw.load.maxChat,
          maxEmail: raw.load.maxEmail,
        });
      }
      this.filterByAgentStatus();
      if (this.$refs["agentCapacity"]) {
        this.$refs["agentCapacity"].setOption(this.options);
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    filterByAgentStatus() {
      this.viewTable.data = [];
      if (this.agentStatus && this.agentStatus !== "all") {
        const result = this.viewTable.originalData.filter((el) => {
          return el.status === this.agentStatus;
        });
        this.viewTable.data = result;
      } else {
        this.viewTable.data = [...this.viewTable.originalData];
      }
    },
  },
};
</script>

<style>
.chart {
  height: 420px;
}
</style>
<style lang="scss" scoped>
.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 25px !important;
}
.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 2px !important;
}
.no-data-wrap {
  height: 354px;
  align-items: center;
  align-content: center;
  text-align: center;
}
</style>
