<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
        <v-text-field readonly disabled label="Webhook" v-model="editedConnector.webhookUrl" prepend-inner-icon="mdi-webhook" :rules="[requiredField]" />
        <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
      </v-col>
    </v-row>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Username"
          v-model.trim="editedConnector.details.username"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          @keyup="calclAuthToken"
          @change="onLeaveField"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Password"
          v-model.trim="editedConnector.details.password"
          prepend-inner-icon="mdi-lock"
          :append-icon="showSecretToken ? 'visibility' : 'visibility_off'"
          :type="showSecretToken ? 'text' : 'password'"
          @click:append="showSecretToken = !showSecretToken"
          autocomplete="new-password"
          @keyup="calclAuthToken"
          @change="onLeaveField"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Account Number"
          v-model.trim="editedConnector.details.wa_account"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
          @change="onLeaveField"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Auth Token"
          v-model.trim="editedConnector.details.auth_token"
          prepend-inner-icon="mdi-lock"
          autocomplete="new-password"
          :disabled="true"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="API URL"
          v-model.trim="editedConnector.details.api_url"
          prepend-inner-icon="mdi-web"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";
import EventBus from "../../../event-bus";

export default {
  name: "WhatsAppInfoBipConf",
  props: ["editedConnector", "connectors"],
  components: { CopyToClipboard },
  data() {
    return {
      showSecretToken: false,
    };
  },
  mounted() {
    this.editedConnector.type = "whatsapp";
    if (!this.editedConnector.uuid) {
      this.editedConnector.details.api_url = "https://26jnm.api.infobip.com";
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    calclAuthToken() {
      this.editedConnector.details.auth_token = btoa(this.editedConnector.details.username + ":" + this.editedConnector.details.password);
    },
    async onLeaveField() {
      if (this.editedConnector.details.username && this.editedConnector.details.password && this.editedConnector.details.wa_account) {
        let whatsappTemplates = {};
        try {
          let templates = await this.$http.post("/whatsapp-template-test", this.editedConnector);
          whatsappTemplates = templates?.data || {};
        } catch (e) {
          whatsappTemplates = {};
        }
        EventBus.$emit(this.$store.getters.getEvents.WHATSAPP_TEMPLATES_TEST_CONFIGURATION, whatsappTemplates);
      }
    },
  },
};
</script>
