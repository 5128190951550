<template>
  <v-form v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteUserConfirm" @dialogDeny="showConfirm = false" />
      <v-row :class="getFormColMargin" class="my-4">
        <v-col cols="6" :class="getFormColMargin">
          <v-btn-toggle v-if="isEdit" tile group>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-switch v-model="getUserDisableFlag" color="primary" label="Enabled" v-on="on" @change="onChangeDisabledFlag" />
              </template>
              <span>Enable user to access ConvyAdmin</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!-- Riga solo per la creazione con username e generazione password -->
      <v-row v-if="!isEdit" :class="getFormRowMargin" align="center">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedUser.authentication.credentials.email"
            label="Email"
            :rules="[requiredField, isValidEmail]"
            prepend-inner-icon="email"
            autofocus
            :autocomplete="$store.getters.disableAutocomplete"
            validate-on-blur
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-checkbox v-model="autoGeneratePassword" hide-details color="primary" :class="getMarginForCheckbox" label="Auto generate strong password" />
        </v-col>
      </v-row>

      <!-- Riga solo per la creazione password -->
      <v-row v-if="!isEdit && !autoGeneratePassword" :class="getFormRowMargin" align="center">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedUser.authentication.credentials.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[
              requiredField,
              isValidPassword,
              isUsernameInPassword(editedUser.authentication.credentials.username, editedUser.authentication.credentials.password),
              arePasswordsEqual(editedUser.authentication.credentials.password, retypePassword),
            ]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn
                depressed
                icon
                text
                :disabled="editedUser.authentication.credentials.password && editedUser.authentication.credentials.password.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="retypePassword"
            label="Retype Password"
            :type="showPasswordRetype ? 'text' : 'password'"
            :rules="[requiredField, arePasswordsEqual(editedUser.authentication.credentials.password, retypePassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="retypePassword && retypePassword.length < 1" tabindex="-1">
                <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Riga nome e cognome utente -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedUser.info.name"
            label="First Name"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedUser.info.surname"
            label="Last Name"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>
      <!-- Riga ruolo e companies -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedUser.roles[$store.getters.getProducts.CONVYAI].role"
            :items="getAvailableRoles"
            :rules="[requiredField]"
            label="Role"
            prepend-inner-icon="mdi-clipboard-account"
            validate-on-blur
            @change="isProfileFilterEnabled = true"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-if="editedUser.roles[$store.getters.getProducts.CONVYAI].role !== $store.getters.getRoles.ROOT"
            v-model="getSelectedCompany"
            :style="getAutocompleteMargin"
            :items="$store.getters.getCompaniesList"
            chips
            item-text="name"
            item-value="uuid"
            :return-object="false"
            label="Companies"
            multiple
            :rules="[requiredField]"
            prepend-inner-icon="mdi-city"
            validate-on-blur
            @change="changeCompanySelected"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.uuid }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <!-- Riga associazione dei profili per ContactCenterManager -->
      <v-row v-if="isEditedUserACCM" :class="getFormRowMargin">
        <v-col cols="12" sm="2" :class="getFormColMargin">
          <v-switch
            v-model="isProfileFilterEnabled"
            label="Enable profile assignment"
            :disabled="editedUser.roles[$store.getters.getProducts.CONVYAI].role == $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER"
            @change="addCompanyResourceToUser"
          />
        </v-col>
        <v-col cols="12" sm="10" :class="getFormColMargin">
          <v-autocomplete
            v-if="isProfileFilterEnabled"
            v-model="selectedOwnedProfiles"
            :items="profilesListItems"
            :label="selectedOwnedProfiles.length > 0 ? 'Profiles accessible to the user' : 'All the profiles are accessible to the user'"
            hint="Add the profiles the user can access. If left empty all the profiles will be accessible."
            persistent-hint
            prepend-inner-icon="mdi-expand-all"
            multiple
            small-chips
            clearable
          >
            <template #selection="{ item }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-chip close @click:close="removeSelectedProfile(item)" v-on="on">
                    <span>{{ item.text }}</span>
                  </v-chip>
                </template>
                <span>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.tenantName }} ({{ item.tenant }})</v-list-item-subtitle>
                  </v-list-item-content>
                </span>
              </v-tooltip>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.tenantName }} ({{ item.tenant }})</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <!-- Riga Lingua, Password Expiration-->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedUser.info.language"
            :items="$store.getters.getUserLanguages"
            :rules="[requiredField]"
            label="Language"
            prepend-inner-icon="mdi-earth"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedUser.authentication.credentials.expiration_months"
            :items="$store.getters.getExpirationPasswordChoice"
            label="Password Expiration"
            prepend-inner-icon="mdi-lock"
            validate-on-blur
            :return-object="false"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>
      <v-row v-if="showResetPassword">
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.adminPassword"
            label="Superadmin Password"
            :hint="$store.getters.getUsername + ' password'"
            :type="showAdminPsw ? 'text' : 'password'"
            :rules="[requiredField, isValidPassword]"
            prepend-inner-icon="mdi-clipboard-account"
            :autocomplete="$store.getters.disableAutocomplete"
            validate-on-blur
            :error-messages="customErrorMessage"
          >
            <template slot="append">
              <v-btn small depressed icon :disabled="resetPassword.adminPassword.length < 1" tabindex="-1">
                <v-icon v-show="!showAdminPsw" tabindex="-1" @click="showAdminPsw = !showAdminPsw">
                  visibility_off
                </v-icon>
                <v-icon v-show="showAdminPsw" tabindex="-1" @click="showAdminPsw = !showAdminPsw">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.newUserPassword"
            label="User new password"
            :type="showPswToReset ? 'text' : 'password'"
            :rules="[requiredField, isValidPassword, isUsernameInPassword(user.authentication.credentials.email, resetPassword.newUserPassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
            validate-on-blur
            :error-messages="customErrorMessage"
          >
            <template slot="append">
              <v-btn small depressed icon :disabled="resetPassword.newUserPassword.length < 1" tabindex="-1">
                <v-icon v-show="!showPswToReset" tabindex="-1" @click="showPswToReset = !showPswToReset">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPswToReset" tabindex="-1" @click="showPswToReset = !showPswToReset">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.confirmNewPassword"
            label="Repeat user new password"
            :type="showConfirmPassword ? 'text' : 'password'"
            :rules="[requiredField, confirmPassword(resetPassword.newUserPassword, resetPassword.confirmNewPassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
            validate-on-blur
            :error-messages="customErrorMessage"
            :error="!resetPasswordsEqual"
          >
            <template slot="append">
              <v-btn small depressed icon :disabled="resetPassword.confirmNewPassword.length < 1" tabindex="-1">
                <v-icon v-show="!showConfirmPassword" tabindex="-1" @click="showConfirmPassword = !showConfirmPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showConfirmPassword" tabindex="-1" @click="showConfirmPassword = !showConfirmPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <!-- Riga Actions -->
      <v-row>
        <v-col cols="12">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="submitUser"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn
            v-if="hasRequiredRole($store.getters.getRoles.ADMIN) && isEdit"
            :class="getButtonMargin"
            color="primary"
            @click="showResetPassword = !showResetPassword"
          >
            <v-icon left>
              mdi-lock
            </v-icon>
            <span v-if="showResetPassword">Hide Password</span>
            <span v-else>Change Password</span>
          </v-btn>
          <v-btn
            v-if="hasRequiredRole($store.getters.getRoles.ADMIN) && isEdit && hasPasswordExpired"
            :class="getButtonMargin"
            color="primary"
            :loading="form.sendingEmail"
            @click="resendWelcomeEmail"
          >
            <v-icon left>
              mdi-email-send
            </v-icon>
            <span>Resend welcome email</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.deleteLoading"
            :disabled="isEdit && (!form.isValid || form.submitLoading)"
            @click.prevent="deleteUser"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import store from "../../store";

export default {
  name: "UserForm",
  components: {
    ConfirmDialog,
  },
  props: {
    "user": {
      type: Object,
      required: true
    },
    "hasPasswordExpired": {
      type: Boolean,
      required: true
    },
    "profiles": {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "User " + this.user.authentication.credentials.email + " will be deleted!",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.user.authentication.credentials.email !== "",
      //Flag gestione della form
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
        sendingEmail: false,
      },
      //Campi di utility
      showPassword: false,
      showPasswordRetype: false,
      showAdminPsw: false,
      showPswToReset: false,
      showConfirmPassword: false,
      showResetPassword: false,
      customErrorMessage: null,
      //Campi dedicati alla creazione utente
      retypePassword: "",
      autoGeneratePassword: true,
      isProfileFilterEnabled: false,
      selectedOwnedProfiles: [],
      //Campi condivisi da creazione e edit utente
      editedUser: {
        _id: this.user._id,
        eri: this.user.eri,
        disabled: this.user.disabled,
        deleted: this.user.deleted,
        authentication: {
          type: "basic",
          credentials: {
            username: this.user.authentication.credentials.username,
            email: this.user.authentication.credentials.email,
            password: this.user.authentication.credentials.password,
            expiration_months: this.user.authentication.credentials.expiration_months,
            expiration: this.user.authentication.credentials.expiration,
            realm: this.user.authentication.credentials.realm || null,
          },
        },
        info: this.user.info,
        roles: {
          [this.$store.getters.getProducts.CONVYAI]: {
            role: this.user.roles[this.$store.getters.getProducts.CONVYAI].role,
            resources: this.user.roles[this.$store.getters.getProducts.CONVYAI].resources,
          },
        },
      },
      // Campi dedicati al reset password
      resetPassword: {
        adminPassword: "",
        newUserPassword: "",
        confirmNewPassword: "",
      },
      queuesSelected: [],
    };
  },
  computed: {
    ...spacing,
    isEditedUserACCM() {
      return (
        this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role === this.$store.getters.getRoles.CONTACTCENTERMANAGER ||
        this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role === this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER
      );
    },
    profilesListItems() {
      let result = [];
      const selectedCompaniesUUID = Object.keys(this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources).map(
        (tenantERI) => tenantERI.split(":")[2],
      );
      //Di tutti i profili (di ogni company che vedo) devo prendere solo quelle delle tenant selezionate per l'utente in edit
      result = this.profiles
        .filter((profile) => {
          const tenant = profile.eri.split(":")[2];
          if (selectedCompaniesUUID.includes(tenant)) {
            const tenantName = this.$store.getters.getCompanyNameByUuid(tenant);
            return tenantName != null;
          }
          return false;
        })
        .map((profile) => {
          const tenant = profile.eri.split(":")[2];
          const tenantName = this.$store.getters.getCompanyNameByUuid(tenant);
          return { text: profile.name, value: profile.eri, tenant, tenantName };
        });
      result.sort((a, b) => {
        if (a.tenantName.toLowerCase() > b.tenantName.toLowerCase()) {
          return 1;
        }
        if (a.tenantName.toLowerCase() < b.tenantName.toLowerCase()) {
          return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return result;
    },
    getAvailableRoles() {
      let found = false;
      const userRole = store.getters.getUserRole;
      const roles = Object.values(this.$store.state.enums.roles);
      let list = [];
      roles.forEach((role) => {
        if (role === userRole) {
          found = true;
        }
        if (found && role !== store.getters.enums.roles.CONTACTCENTERAGENT) {
          list.push({
            text: this.$store.state.enums.rolesLabel[role],
            value: role,
          });
        }
      });
      return list;
    },
    resetPasswordsEqual() {
      return this.resetPassword.newUserPassword === this.resetPassword.confirmNewPassword;
    },
    getSelectedCompany: {
      get() {
        const resources = Object.keys(this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources);
        const userCompaniesList = resources.map((res) => res.split(":")[2]);
        //controllo se la company è ancora presente
        let result = [];
        const companiesList = this.$store.getters.getCompaniesList;
        for (let company of userCompaniesList) {
          let list = companiesList.find((el) => el.uuid === company);
          if (list) {
            result.push(list);
          }
        }
        return result;
      },
      set() {
        const resources = Object.keys(this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources);
        const userCompaniesList = resources.map((res) => res.split(":")[2]);
        return userCompaniesList;
      },
    },
    getUserDisableFlag: {
      get() {
        return !this.editedUser.disabled;
      },
      set() {
        return !this.editedUser.disabled;
      },
    },
  },
  mounted() {
    const resources = this.user.roles[this.$store.getters.getProducts.CONVYAI].resources;
    for (const tenantERI in resources) {
      const tenantConf = resources[tenantERI];
      if (tenantConf.owned_profiles) {
        this.isProfileFilterEnabled = true;
        if (tenantConf.owned_profiles.length > 0) {
          this.selectedOwnedProfiles = this.selectedOwnedProfiles.concat(tenantConf.owned_profiles);
        }
      }
    }
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    removeSelectedProfile(item) {
      const index = this.selectedOwnedProfiles.indexOf(item.value);
      if (index >= 0) {
        this.selectedOwnedProfiles.splice(index, 1);
      }
    },
    addCompanyResourceToUser() {
      const product = this.$store.getters.getProducts.CONVYAI;
      if (this.isProfileFilterEnabled) {
        if (!this.editedUser.roles[product].resources[product + ":" + this.$store.getters.getSelectedCompany]) {
          this.editedUser.roles[product].resources[product + ":" + this.$store.getters.getSelectedCompany] = {};
        }
        this.editedUser.roles[product].resources[product + ":" + this.$store.getters.getSelectedCompany].owned_profiles = [];
      } else {
        delete this.editedUser.roles[product].resources[product + ":" + this.$store.getters.getSelectedCompany].owned_profiles;
      }
    },
    onChangeDisabledFlag(val) {
      this.editedUser.disabled = !val;
    },
    changeCompanySelected(listSelected) {
      if (!this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources) {
        this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources = {};
      }
      listSelected.forEach((idCompany) => {
        if (!this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources[`${this.$store.getters.getProducts.CONVYAI}:${idCompany}`]) {
          this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources[`${this.$store.getters.getProducts.CONVYAI}:${idCompany}`] = {};
        }
      });
      const allCompanies = Object.keys(this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources);
      allCompanies.forEach((tenantERI) => {
        const tenant = tenantERI.split(":")[2];
        if (!listSelected.includes(tenant)) {
          delete this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources[`${this.$store.getters.getProducts.CONVYAI}:${tenant}`];
          this.selectedOwnedProfiles = this.selectedOwnedProfiles.filter((profile) => profile.indexOf(tenantERI) !== 0);
        }
      });
    },
    deleteUser() {
      if (this.user.authentication.credentials.username) {
        this.showConfirm = true;
      } else {
        //Caso di delete su utente in fase di creazione
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_USER_CREATION);
      }
    },
    async onDeleteUserConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/user/" + this.user._id);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_USER, {
          username: this.user.authentication.credentials.username,
        });
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_USER_FAIL, {
          username: this.user.authentication.credentials.username,
          message: "Fail to delete user " + this.user.authentication.credentials.username,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async submitUser() {
      if (this.form.isValid) {
        if (this.isEdit || this.editedUser.authentication.credentials.password === this.retypePassword || this.autoGeneratePassword) {
          this.form.submitLoading = true;
          try {
            const response = await this.sendRequest();
            if (this.editedUser._id) {
              response.data._id = this.editedUser._id;
            }
            //Avviso la navbar di aggiornarsi
            if (this.showResetPassword) {
              await this.sendChangePasswordRequest();
              EventBus.$emit(this.$store.getters.getEvents.RESET_USER_PASSWORD, {
                user: response.data,
              });
            } else {
              EventBus.$emit(this.$store.getters.getEvents.EDIT_USER, {
                user: response.data,
              });
            }
          } catch (err) {
            if (err.response.status === 409) {
              EventBus.$emit(this.$store.getters.getEvents.EDIT_USER_FAIL, {
                message: "User " + this.editedUser.authentication.credentials.email + " already exist!",
              });
            } else {
              EventBus.$emit(this.$store.getters.getEvents.EDIT_USER_FAIL, {
                message: "Fail to edit " + this.editedUser.authentication.credentials.email,
              });
            }
          } finally {
            this.form.submitLoading = false;
            this.showResetPassword = false;
            this.resetPassword.confirmNewPassword = "";
            this.resetPassword.adminPassword = "";
            this.resetPassword.newUserPassword = "";
          }
        } else {
          this.customErrorMessage = "Passwords doesn't match";
          EventBus.$emit(this.$store.getters.getEvents.EDIT_USER_FAIL, {
            user: this.editedUser,
            message: "Passwords doesn't match",
          });
        }
      }
    },
    async sendChangePasswordRequest() {
      return this.$http.patch("/user/password-reset/" + this.editedUser._id, {
        adminPassword: this.resetPassword.adminPassword,
        newPassword: this.resetPassword.newUserPassword,
      });
    },
    async sendRequest() {
      //Solo se non c'è lo username, ci copio la mail (creazione standard da admin)
      //in questo modo se con le API vengono creati utenti con username != da email non li modifico
      if (!this.editedUser.authentication.credentials.username) {
        this.editedUser.authentication.credentials.username = this.editedUser.authentication.credentials.email;
      }

      const role = this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role;
      if (role && role.indexOf("Root") !== -1) {
        for (const tenant in this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources) {
          delete this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources[tenant];
        }
      }

      const product = this.$store.getters.getProducts.CONVYAI;
      if (
        this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role !== this.$store.getters.getRoles.CONTACTCENTERMANAGER &&
        this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role !== this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER
      ) {
        for (const tenantERI in this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources) {
          delete this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources[tenantERI].owned_profiles;
        }
      } else {
        //Resetto tutti gli owned profiles
        for (const tenantERI in this.editedUser.roles[product].resources) {
          delete this.editedUser.roles[product].resources[tenantERI].owned_profiles;
        }
        if (this.isProfileFilterEnabled) {
          if (this.selectedOwnedProfiles.length > 0) {
            //Se uno o più sono selezionati setto precisamente quelli
            for (const profileERI of this.selectedOwnedProfiles) {
              const tenantERI = product + ":" + profileERI.split(":")[2];
              if (!this.editedUser.roles[product].resources[tenantERI]) {
                this.editedUser.roles[product].resources[tenantERI] = {};
              }
              if (!this.editedUser.roles[product].resources[tenantERI].owned_profiles) {
                this.editedUser.roles[product].resources[tenantERI].owned_profiles = [];
              }
              if (!this.editedUser.roles[product].resources[tenantERI].owned_profiles.includes(profileERI)) {
                this.editedUser.roles[product].resources[tenantERI].owned_profiles.push(profileERI);
              }
            }
          } else {
            //Se nessuno è selezionato li setto tutti
            for (const tenantERI in this.editedUser.roles[product].resources) {
              this.editedUser.roles[product].resources[tenantERI].owned_profiles = [];
            }
          }
        }
      }

      //Metodo che fa la richiesta HTTP in base al flag di edit
      if (this.isEdit) {
        if (this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].role === this.$store.getters.getRoles.ROOT) {
          this.editedUser.roles[this.$store.getters.getProducts.CONVYAI].resources = {};
        }
        return this.$http.put("/user/" + this.editedUser._id, this.editedUser);
      }
      if (this.autoGeneratePassword) {
        this.editedUser.authentication.credentials.expiration = new Date(0); //EPOC
      } else {
        delete this.editedUser.authentication.credentials.expiration;
      }
      delete this.editedUser._id;
      delete this.editedUser.eri;
      delete this.editedUser.disabled;
      delete this.editedUser.deleted;
      return this.$http.post("/user", this.editedUser);
    },
    async resendWelcomeEmail() {
      this.form.sendingEmail = true;
      try {
        await this.$http.post("/user/resend-welcome-email/" + this.editedUser._id, this.editedUser);
        EventBus.$emit(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL, {
          message: "Email successfully sent to the user: " + this.editedUser.authentication.credentials.email,
          success: true,
        });
      } catch {
        EventBus.$emit(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL_FAIL, {
          message: "Error while sending the email to the user: " + this.editedUser.authentication.credentials.email,
          success: false,
        });
      } finally {
        this.form.sendingEmail = false;
      }
    },
  },
};
</script>
