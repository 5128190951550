<template>
  <v-form v-model="form.isValid" :class="getCreationFormMargin">
    <v-row :class="getCreationContainerMargin">
      <v-col cols="12" md="5">
        <!-- Riga Nome -->
        <v-row no-gutters>
          <v-text-field
            v-model.trim="scheduleEdit.name"
            label="Schedule Name"
            :disabled="isEdit"
            :rules="[requiredField, simpleAlphaNumString, uniqueName]"
            prepend-inner-icon="mdi-card-bulleted"
            :validate-on-blur="!isEdit"
          />
        </v-row>
        <!-- Riga selezione wathbots -->
        <v-row>
          <v-col cols="12">
            <header>Select Schedule Timing</header>
            <!-- Select tipo di schedule -->
            <v-radio-group v-model="scheduleEdit.type" row>
              <v-radio label="Daily" value="daily" />
              <v-radio label="Weekly" value="weekly" />
              <v-radio label="Monthly" value="monthly" />
            </v-radio-group>
            <v-select
              v-if="scheduleEdit.type == 'weekly'"
              v-model="scheduleEdit.days"
              :rules="[requiredField]"
              validate-on-blur
              :items="days"
              label="Select Days"
              multiple
              chips
              hint="What are the days that Schedule Watchbot must run"
              persistent-hint
            />
            <v-text-field
              v-if="scheduleEdit.type == 'monthly'"
              v-model="scheduleEdit.dayNumber"
              type="number"
              min="1"
              max="31"
              :rules="[validateDayNumber, requiredField]"
              :validate-on-blur="!isEdit"
              label="Select Which Day"
              hint="Select the number of the day on which the scheduler must start"
            />
            <!-- Time picker -->
            <v-dialog ref="dialog" v-model="timePicker" persistent width="290px">
              <template #activator="{ on }">
                <v-text-field
                  v-model="scheduleEdit.time"
                  label="Time Schedule Starts"
                  prepend-inner-icon="access_time"
                  :rules="[requiredField]"
                  readonly
                  v-on="on"
                  @click.prevent="backupTime"
                />
              </template>
              <v-time-picker
                v-if="timePicker"
                v-model="scheduleEdit.time"
                scrollable
                format="24hr"
                header-color="primary"
                full-width
              >
                <v-spacer />
                <v-row no-gutters>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="success" @click="timePicker = false">
                      <v-icon left>
                        mdi-floppy
                      </v-icon>Save
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="error" @click="restoreTime">
                      <v-icon left>
                        mdi-close
                      </v-icon>Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" offset-md="1">
        <span class="heading">Select Watchbots</span>
        <v-chip-group v-model="scheduleEdit.watchbotList" active-class="primary" multiple column>
          <v-chip v-for="watchbot in watchbots" :key="watchbot.name" :value="watchbot.name" filter label>
            {{ watchbot.name }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <!-- Riga actions -->
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="loading"
            :disabled="!form.isValid || !watchbotSelectionIsValid || scheduleEdit.watchbotList.length == 0"
            @click.prevent="saveSchedule"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn
            v-if="isEdit"
            :class="getButtonMargin"
            color="primary"
            :loading="loading"
            :disabled="!form.isValid || !watchbotSelectionIsValid || scheduleEdit.watchbotList.length == 0"
            @click.prevent="testSchedule"
          >
            <v-icon left>
              mdi-test-tube
            </v-icon>
            Test
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="loading" @click.prevent="deleteScheduleCreation">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-form>
</template>
<script>
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import merge from "deepmerge";

export default {
  name: "ScheduledWatchbotForm",
  components: {},
  props: ["watchbots", "schedule", "schedules", "loading"],
  data() {
    return {
      // form validation
      scheduleEdit: {},
      tempName: "",
      form: {
        isValid: false,
      },
      isEdit: this.schedule.name !== "",
      timePicker: false,
      tmpTime: null,
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    };
  },
  computed: {
    ...spacing,
    watchbotSelectionIsValid() {
      return this.scheduleEdit.watchbotList ? this.scheduleEdit.watchbotList.length > 0 : false;
    },
  },
  mounted() {
    this.scheduleEdit = merge({}, this.schedule);
    this.tempName = this.schedule.name;
  },
  methods: {
    ...fieldValidators,
    deleteScheduleCreation() {
      if (this.isEdit) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_WATCHBOT_SCHEDULE, this.scheduleEdit.name);
      } else {
        //Caso di delete su utente in fase di creazione
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_WATCHBOT_SCHEDULE_CREATION);
      }
    },
    backupTime() {
      this.tmpTime = this.scheduleEdit.time;
    },
    restoreTime() {
      this.scheduleEdit.time = this.tmpTime;
      this.timePicker = false;
    },
    async testSchedule() {
      try {
        await this.$http.post("/watchbot-scheduler/test/" + this.scheduleEdit.name);
        EventBus.$emit(this.$store.getters.getEvents.TEST_WATCHBOT_SCHEDULE, {
          msg: "Launched scheduled watchbot test " + this.scheduleEdit.name,
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.TEST_WATCHBOT_SCHEDULE, {
          msg: `Fail to launch scheduled watchbot test ${this.scheduleEdit.name}. You can test one scheduled watchbot configuration at the time`,
          error: true,
        });
      }
    },
    saveSchedule() {
      if (!this.isEdit) {
        EventBus.$emit(this.$store.getters.getEvents.CREATE_WATCHBOT_SCHEDULE, this.scheduleEdit);
      } else {
        EventBus.$emit(this.$store.getters.getEvents.UPDATE_WATCHBOT_SCHEDULE, this.scheduleEdit);
      }
    },
    uniqueName(name) {
      if (name && !this.isEdit) {
        for (let i = 0; i < this.schedules.length; i++) {
          if (name.toLowerCase() === this.schedules[i].name.toLowerCase()) {
            return "Schedule name already exists";
          }
        }
        return true;
      } else if (name && this.isEdit) {
        for (let i = 0; i < this.schedules.length; i++) {
          if (name.toLowerCase() === this.schedules[i].name.toLowerCase() && name.toLowerCase() !== this.tempName.toLowerCase()) {
            return "Schedule name already exists";
          }
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
