<template>
  <v-container fluid>
    <ConfirmationDialog
      :showConfirm="confirmDialog"
      message="Are you sure to delete Rainbow's configuration?"
      @dialogDeny="confirmDialog = false"
      @dialogConfirm="cancelRainbowConf"
    />
    <ResultSnackbar ref="resultSnackbar" />
    <v-row justify="center" :class="getMainRowMarginNoSearchbar">
      <v-expansion-panels v-model="panel" popout multiple :class="getMarginNewCard">
        <v-expansion-panel readonly>
          <v-expansion-panel-header hide-actions>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <v-avatar tile :size="getAvatarSize">
                  <img alt="Avatar" :src="$store.getters.getLogoByType('rainbow')">
                </v-avatar>
              </v-col>
              <v-col cols="8">
                Rainbow Connector
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="rainbowForm" v-model="form.isValid" :class="getCreationFormMargin">
              <v-row :class="getFormRowMargin">
                <v-col cols="12" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="rainbow.host"
                    label="Hostname"
                    prepend-inner-icon="mdi-account-card-details"
                    autofocus
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>
              </v-row>
              <v-row :class="getFormRowMargin">
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="rainbow.login"
                    label="Login"
                    prepend-inner-icon="email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>

                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="rainbow.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-inner-icon="lock"
                    autocomplete="new-password"
                    :error-messages="customErrorMessage"
                    :rules="[requiredField]"
                  >
                    <template slot="append">
                      <v-btn depressed icon text :disabled="rainbow.password && rainbow.password.length < 1" tabindex="-1">
                        <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row :class="getFormRowMargin" align="center">
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="rainbow.appID"
                    label="AppId"
                    prepend-inner-icon="mdi-application"
                    autofocus
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="rainbow.appSecret"
                    label="App Secret"
                    :type="showAppSecret ? 'text' : 'password'"
                    prepend-inner-icon="lock"
                    autocomplete="new-password"
                    :error-messages="customErrorMessage"
                    :rules="[requiredField]"
                  >
                    <template slot="append">
                      <v-btn depressed icon text :disabled="rainbow.appSecret && rainbow.appSecret.length < 1" tabindex="-1">
                        <v-icon v-show="!showAppSecret" tabindex="-1" @click="showAppSecret = !showAppSecret">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showAppSecret" tabindex="-1" @click="showAppSecret = !showAppSecret">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- Riga Actions -->
              <v-row :class="getFormRowMargin">
                <v-col>
                  <v-btn
                    :class="getButtonMargin"
                    color="success"
                    :loading="form.submitLoading"
                    :disabled="!form.isValid"
                    @click.prevent="saveRainbowIntegration"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Save</span>
                  </v-btn>
                  <v-btn v-if="checkButton" :class="getButtonMargin" color="error" :disabled="checkCancel" @click.prevent="resetRainbowConfiguration">
                    <v-icon left>
                      mdi-close
                    </v-icon>
                    <span>Cancel</span>
                  </v-btn>
                  <v-btn
                    v-else
                    :class="getButtonMargin"
                    color="error"
                    :loading="form.deleteLoading"
                    :disabled="!form.isValid || form.submitLoading"
                    @click.prevent="confirmDialog = true"
                  >
                    <v-icon left>
                      mdi-delete
                    </v-icon>
                    <span>Delete</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "../../event-bus";
import ConfirmationDialog from "../../components/other/ConfirmationDialog";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
export default {
  name: "RainbowIntegration",
  components: {
    ResultSnackbar,
    ConfirmationDialog,
  },
  data: () => ({
    confirmDialog: false,
    panel: [0],
    customErrorMessage: null,
    form: {
      isValid: false,
      submitLoading: false,
      deleteLoading: false,
    },
    rainbow: {
      host: "",
      login: "",
      password: "",
      appID: "",
    },
    showPassword: false,
    showAppSecret: false,
    testingConnection: false,
    checkButton: false,
    forcererender: 0,
  }),
  computed: {
    ...spacing,
    checkCancel() {
      this.forcererender;
      return this.form.submitLoading || (this.rainbow.host === "" && this.rainbow.login === "" && this.rainbow.password === "" && this.rainbow.appID === "");
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("rainbow")) {
      this.$router.push("/");
    }
    try {
      const result = await this.$http.get("/rainbow");
      this.rainbow = merge(this.rainbow, result.data);
    } catch (err) {
      this.checkButton = true;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    resetRainbowConfiguration() {
      this.rainbow = {
        host: "",
        login: "",
        password: "",
        appID: "",
      };
      this.$refs.rainbowForm.resetValidation();
    },
    async cancelRainbowConf() {
      this.rainbow = {
        host: "",
        login: "",
        password: "",
        appID: "",
      };
      try {
        await this.$http.delete("/rainbow");
        this.$refs.resultSnackbar.showSuccess("Rainbow configuration deleted!");
      } catch (err) {
        this.$refs.resultSnackbar.showError("Fail to delete Rainbow configuration!");
      } finally {
        this.$refs.rainbowForm.resetValidation();
        this.confirmDialog = false;
        this.checkButton = true;
        this.forcererender;
      }
    },
    async saveRainbowIntegration() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        try {
          const response = await this.$http.post("/rainbow", this.rainbow);
          this.rainbow = response.data;
          this.$refs.resultSnackbar.showSuccess("Rainbow configuration saved!");
          this.checkButton = false;
        } catch (err) {
          this.$refs.resultSnackbar.showError("Fail to save Rainbow configuration!");
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
  },
};
</script>
