<template>
  <v-container fluid>
    <!-- dialog cancellazione entità -->
    <ConfirmationDialog :showConfirm="confirmDialog" :message="confirmMessage" @dialogDeny="confirmDialog = false" @dialogConfirm="confirmDeleteEntity" />
    <!-- dialog creazione/edit intenti -->
    <v-dialog v-model="editDialog" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Intent</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formNewIntent" v-model="intentFormValid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="newItem.key"
                  :rules="[requiredField, validateIntentName]"
                  label="Label"
                  :error-messages="checkIfIntentAlreadyExists"
                  :autocomplete="$store.getters.disableAutocomplete"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="newItem.value"
                  :error-messages="checkIfValuelreadyExists"
                  :rules="[requiredField]"
                  label="Value"
                  :autocomplete="$store.getters.disableAutocomplete"
                />
              </v-col>
              <v-col v-if="engineType == 'COGITO'" cols="12">
                <v-switch v-model="newItem.enabled" color="primary" label="Enabled for disambiguation questions" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions :class="getFormRowMargin">
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn :class="getButtonMargin" color="success" :disabled="!intentFormValid" @click="saveIntent">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
            <v-btn v-if="editedIndex == -1" :class="getButtonMargin" color="primary" :disabled="!intentFormValid" @click="addMoreIntent">
              <v-icon left>
                mdi-plus
              </v-icon>Add More
            </v-btn>
            <v-btn :class="getButtonMargin" color="error" @click="closeEditDialog">
              <v-icon left>
                mdi-close
              </v-icon>Discard
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog cancellazione intenti -->
    <ConfirmationDialog
      :showConfirm="deleteDialog"
      :message="'Intent ' + selectedIntentName + ' will be deleted permanently'"
      @dialogDeny="closeDeleteDialog"
      @dialogConfirm="confirmDeleteIntent"
    />
    <v-tabs v-model="tab" background-color="transparent" color="primary" grow @change="searchString = ''">
      <v-tab>Intents</v-tab>
      <v-tab>Entities</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-2 mx-2" style="background: transparent; width: 100% !important; height: 67vh">
      <v-tab-item>
        <v-toolbar flat>
          <v-btn small color="primary" :to="'/semanticEngines?engineName=' + engineName">
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            <span>back</span>
          </v-btn>
          <v-spacer />
          <v-row align="center">
            <v-col offset="3" cols="9">
              <v-text-field
                v-model="searchString"
                dense
                width="200px"
                hide-details
                outlined
                clearable
                label="Search..."
                prepend-inner-icon="search"
                @input="$emit('input', $event ? $event : '')"
              />
            </v-col>
          </v-row>
        </v-toolbar>
        <v-simple-table fixed-header :height="calcDatatableHeight">
          <template #default>
            <thead>
              <tr>
                <th style="width: 5%" class="text-left" />
                <th style="width: 20%" class="text-left">
                  Label
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        small
                        color="primary"
                        class="ml-4"
                        @click="sortIntents('key')"
                        v-on="on"
                      >
                        <v-icon small>
                          mdi-sort-alphabetical-descending
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Sort Alphabetically</span>
                  </v-tooltip>
                </th>
                <th style="width: 55%" class="text-left">
                  Value
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        small
                        color="primary"
                        class="ml-4"
                        @click="sortIntents('value')"
                        v-on="on"
                      >
                        <v-icon small>
                          mdi-sort-alphabetical-descending
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Sort Alphabetically</span>
                  </v-tooltip>
                </th>
                <th v-if="engineType == 'COGITO'" style="width: 10%" class="text-left">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <span v-on="on">Disambiguation</span>
                    </template>
                    <span> If disabled this intent will not be displayed as Menu option and will not be used as possible disambiguation menu entry </span>
                  </v-tooltip>
                </th>
                <th style="width: 10%" class="text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <draggable v-model="datasetIntentsCopy" tag="tbody" handle=".sortHandle">
              <tr v-for="(intent, index) in getFilteredDatasetIntents" :key="index">
                <td class="hidden-sm-and-down text-start">
                  <v-btn
                    x-small
                    text
                    color="primary"
                    style="cursor: move"
                    icon
                    class="sortHandle ma-0 pa-0"
                  >
                    <v-icon>mdi-cursor-move</v-icon>
                  </v-btn>
                </td>
                <td style="max-width: 250px">
                  {{ intent.key }}
                </td>
                <td style="max-width: 250px">
                  {{ intent.value }}
                </td>
                <td v-if="engineType == 'COGITO'" align="center">
                  <v-icon v-if="intent.enabled">
                    mdi-check-underline
                  </v-icon>
                  <v-icon v-else>
                    mdi-close
                  </v-icon>
                </td>
                <td>
                  <v-row no-gutters>
                    <v-icon class="mr-4" @click="openEditDialog(intent)">
                      edit
                    </v-icon>
                    <v-icon @click="deleteIntent(intent)">
                      delete
                    </v-icon>
                  </v-row>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
        <v-row no-gutters class="mt-3">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="mb-n8" color="primary" small v-on="on" @click="openEditDialog(null)">
                <v-icon left>
                  mdi-plus
                </v-icon>Add Intent
              </v-btn>
            </template>
            <span>Add new Intent</span>
          </v-tooltip>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar flat>
          <v-btn small color="primary" :to="'/semanticEngines?engineName=' + engineName">
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            <span>back</span>
          </v-btn>
        </v-toolbar>

        <div class="title mt-3">
          Type and press Enter to add custom Entities
        </div>
        <v-text-field
          v-model.trim="newEntity"
          prepend-inner-icon="mdi-plus"
          class="my-2"
          label="New Entity"
          :autocomplete="$store.getters.disableAutocomplete"
          @keypress.enter.stop="addNewEntity"
        />
        <v-chip
          v-for="(entity, index) in getEntityList"
          :key="index"
          :close="standardEntities.indexOf(entity) == -1"
          class="ma-2"
          color="primary"
          @click:close="deleteEntity(entity)"
        >
          {{ entity }}
        </v-chip>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import ConfirmationDialog from "../../other/ConfirmationDialog";
import EventBus from "../../../event-bus";
import draggable from "vuedraggable";
import merge from "deepmerge";

export default {
  name: "GenericEngine",
  components: {
    ConfirmationDialog,
    draggable,
  },
  props: ["semanticEngine", "datasetIntents", "engineName", "originalIntents"],
  data() {
    return {
      engineType: this.$route.query.engineType,
      //v-model del tab navigator
      tab: 0,
      cardHeight: 0,
      searchString: "",

      datasetIntentsCopy: [],
      entityToDelete: null,
      confirmDialog: false,
      confirmMessage: "",
      editDialog: false,
      deleteDialog: false,
      editedIndex: -1,
      newItem: {
        key: "",
        value: "",
        enabled: true,
      },
      intentFormValid: false,
      deleteIntentIndex: -1,
      selectedIntentName: "",
      newEntity: "",
      forceRerender: 0,
      standardEntities: ["TEXT"],
    };
  },
  computed: {
    ...spacing,
    calcDatatableHeight() {
      return this.cardHeight - 330;
    },
    getFilteredDatasetIntents() {
      if (this.searchString == null) {
        return Object.values(this.datasetIntentsCopy);
      }
      return (
        Object.values(this.datasetIntentsCopy)
          //Filtro per la ricerca
          .filter(
            (intent) =>
              intent.key.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
              intent.value.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
          )
      );
    },
    getEntityList() {
      this.forceRerender;
      if (this.semanticEngine != null) {
        return (
          Object.values(this.semanticEngine.engines[this.engineName].types)
            //Sorting per un campo
            .sort((a, b) => {
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              return 0;
            })
        );
      } else {
        return [];
      }
    },
    checkIfIntentAlreadyExists() {
      for (let i = 0; i < this.datasetIntentsCopy.length; i++) {
        if (this.datasetIntentsCopy[i].key.toLowerCase() == this.newItem.key.toLowerCase().trim() && i != this.editedIndex) {
          return "Label Already Exist";
        }
      }
      return "";
    },
    checkIfValuelreadyExists() {
      for (let i = 0; i < this.datasetIntentsCopy.length; i++) {
        if (this.datasetIntentsCopy[i].value.toLowerCase().trim() == this.newItem.value.toLowerCase() && i != this.editedIndex) {
          return "Value Already Exist";
        }
      }
      return "";
    },
  },
  mounted() {
    let card = document.querySelector(".semanticEngineMainCard");
    if (card) {
      this.cardHeight = card.offsetHeight;
    }
    window.addEventListener("resize", this.onResizeScreen);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeScreen);
  },
  methods: {
    ...fieldValidators,
    onResizeScreen() {
      let card = document.querySelector(".semanticEngineMainCard");
      if (card) {
        this.cardHeight = card.offsetHeight;
      }
    },
    setDataset() {
      this.datasetIntentsCopy = merge({}, this.datasetIntents);
    },
    openEditDialog(intent) {
      if (intent) {
        this.selectedIntentName = intent.key;
        this.editedIndex = this.datasetIntentsCopy.indexOf(intent);
        this.newItem = Object.assign({}, intent);
      } else {
        this.resetNewIntents();
        if (this.$refs.formNewIntent) {
          this.$refs.formNewIntent.resetValidation();
        }
      }
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.resetNewIntents();
      this.editedIndex = -1;
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    resetNewIntents() {
      this.newItem = merge(
        {},
        {
          key: "",
          value: "",
          enabled: true,
        },
      );
    },
    addMoreIntent() {
      // Possibile sono in creation
      this.newItem.value = this.newItem.value.trim();
      this.newItem.key = this.newItem.key.trim();

      this.datasetIntentsCopy.push(this.newItem);
      if (this.$refs["formNewIntent"]) {
        this.$refs["formNewIntent"].resetValidation();
      }
      this.resetNewIntents();
    },
    saveIntent() {
      this.newItem.value = this.newItem.value.trim();
      this.newItem.key = this.newItem.key.trim();
      if (this.editedIndex > -1) {
        // sono in edit
        this.originalIntents[this.datasetIntentsCopy[this.editedIndex].value] = {
          originalKey: this.datasetIntentsCopy[this.editedIndex].key,
          originalValue: this.datasetIntentsCopy[this.editedIndex].value,
          newDataKey: this.newItem.key,
          newDataValue: this.newItem.value,
          enabled: this.newItem.enabled,
        };
        Object.assign(this.datasetIntentsCopy[this.editedIndex], this.newItem);
      } else {
        // sono in creation
        this.datasetIntentsCopy.push(this.newItem);
      }
      this.closeEditDialog();
    },
    deleteIntent(intent) {
      this.selectedIntentName = intent.key;
      this.deleteIntentIndex = this.datasetIntentsCopy.indexOf(intent);
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.deleteDialog = true;
    },
    confirmDeleteIntent() {
      if (this.deleteIntentIndex != -1) {
        delete this.semanticEngine.engines[this.engineName].intents[this.datasetIntentsCopy[this.deleteIntentIndex].key];
        this.datasetIntentsCopy.splice(this.deleteIntentIndex, 1);
        this.deleteIntentIndex = -1;
      }
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.deleteDialog = false;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.deleteIntentIndex = -1;
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    addNewEntity() {
      //Rimuove i caratteri non printabili. Serve perchè quando si fa copia/incolla da lex tira dentro un carattere non printabile
      const val = this.newEntity
        .toString()
        //ho commentato perchè rimuove le lettere accentate e non ha senso
        //.replace(/[^\x20-\x7E]/g, "")
        .trim();
      if (val !== "" && !this.semanticEngine.engines[this.engineName].types[val]) {
        this.semanticEngine.engines[this.engineName].types[val] = val;
        this.semanticEngine.entity_types[val] = {
          type: val === "TEXT" ? "basic" : "semantic",
        };
        this.newEntity = "";
        this.forceRerender++;
      }
    },
    deleteEntity(entity) {
      this.entityToDelete = entity;
      this.confirmDialog = true;
      this.confirmMessage = "Entity " + this.entityToDelete + " will be deleted permanently";
    },
    confirmDeleteEntity() {
      delete this.semanticEngine.engines[this.engineName].types[this.entityToDelete];
      delete this.semanticEngine.entity_types[this.entityToDelete];
      this.entityToDelete = null;
      this.confirmDialog = false;
      this.forceRerender++;
    },

    // metodi per l'rdinamento degli intenti
    getSubLevel(string) {
      let level = string.split("|")[0].split(".");
      return level;
    },
    sortIntents(column) {
      this.datasetIntentsCopy.sort((a, b) => {
        let levelA = this.getSubLevel(a[column]);
        let levelB = this.getSubLevel(b[column]);
        if (!(a[column].includes("|") && b[column].includes("|"))) {
          if (a[column].toLowerCase().indexOf("/") > 0 && b[column].toLowerCase().indexOf("/") > 0) {
            if (a[column].substr(0, a[column].indexOf("/")) > b[column].substr(0, b[column].indexOf("/"))) {
              return 1;
            }
            if (a[column].substr(0, a[column].indexOf("/")) < b[column].substr(0, b[column].indexOf("/"))) {
              return -1;
            }
            return 0;
          } else {
            if (a[column].toLowerCase() > b[column].toLowerCase()) {
              return 1;
            }
            if (a[column].toLowerCase() < b[column].toLowerCase()) {
              return -1;
            }
            return 0;
          }
        } else {
          let lengthMin = levelA.length < levelB.length ? levelA.length : levelB.length;
          for (let i = 0; i < lengthMin; i++) {
            if (!(i + 1 == lengthMin)) {
              if (levelA[i] < levelB[i]) {
                return -1;
              } else if (levelA[i] > levelB[i]) {
                return 1;
              }
            } else {
              if (levelA[i] < levelB[i]) {
                return -1;
              } else if (levelA[i] > levelB[i]) {
                return 1;
              } else {
                if (levelA.length < levelB.length) {
                  return -1;
                } else {
                  return 1;
                }
              }
            }
          }
        }
      });
    },
    getDatasetIntentsUpdated() {
      return this.datasetIntentsCopy;
    },
  },
};
</script>
