import moment from "moment";

//I filtri sulle date, oltre a formattarle le spostano da UTC alla timezone del browser

export default {
  removeUselessDecimal: (value) => {
    return value.toString().replace(".00", "");
  },
  formatDate: (value) => {
    if (value && value !== "-") {
      let offset = moment().utcOffset();
      return moment.utc(String(value)).utcOffset(offset).format("MM/DD/YYYY");
    }
    return value;
  },
  formatDateTime: (value) => {
    if (value && value !== "-") {
      let offset = moment().utcOffset();
      return moment.utc(String(value)).utcOffset(offset).format("MM/DD/YYYY HH:mm");
    }
    return value;
  },
  formatDateTimeSeconds: (value) => {
    if (value && value !== "-") {
      let offset = moment().utcOffset();
      return moment.utc(String(value)).utcOffset(offset).format("MM/DD/YYYY HH:mm:ss");
    }
    return value;
  },
  formatVerboseDateTime: (value) => {
    if (value && value !== "-") {
      let startFromat = null; // se rimane null allora la stringa é in fomrato iso e la converto senza cambiare formato iniziale
      if (moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        startFromat = "YYYY-MM-DD HH:mm:ss";
      } else if (moment(value, "DD-MM-YYYY HH:mm:ss", true).isValid()) {
        startFromat = "DD-MM-YYYY HH:mm:ss";
      }
      let offset = moment().utcOffset();
      return moment.utc(value, startFromat).utcOffset(offset).format("DD MMM YYYY, HH:mm:ss");
    }
    return value;
  },
  formatVerboseDateUnassignedFlow: (value) => {
    //prendo il flusso modificato di recente e uso quella data per mostrare il last update degli unassigned flows
    let flowsList = value.flowsList;
    let bot = value.bot;
    let maxDate = {
      time: 0,
      string: "",
    };
    if (bot && bot.flows && bot.flows.length > 0) {
      let flows = bot.flows;
      for (let key in flows) {
        let f = flows[key];
        if (flowsList && flowsList[f.flow] && flowsList[f.flow][f.version]) {
          let flow = flowsList[f.flow][f.version];
          let date = new Date(flow.lastUpdate).getTime();
          if (date > maxDate.time) {
            maxDate.time = date;
            maxDate.string = flow.lastUpdate;
          }
        }
      }
    }
    if (maxDate.time > 0) {
      return maxDate.string;
    }
    return "-";
  },
  formatVerboseDateTimeCriteria: (value) => {
    if (value && value !== "-") {
      return moment(value, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, HH:mm:ss");
    }
    return value;
  },
  formatVerboseDateTimeForActivity: (value) => {
    if (value && value !== "-") {
      let offset = moment().utcOffset();
      return moment
        .utc(String(new Date(value).toISOString()))
        .utcOffset(offset)
        .format("DD MMM YYYY, HH:mm:ss");
    }
    return value;
  },
  formatVerboseDateTimeFromUtc: (value) => {
    if (value && value !== "-") {
      return moment(value).local().format("DD MMM YYYY, HH:mm:ss");
    }
    return value;
  },
  formatContact: (contact) => {
    const formattedContact = {
      history: contact.content,
      navigation: contact.result,
      userInfo: contact.data,
      calls: contact.calls,
      recordings: contact.recordings,
      surveys: contact.surveys,
      contactResults: contact.contactResults,
    };
    delete contact.data;
    delete contact.result;
    delete contact.content;
    formattedContact.options = contact;
    return formattedContact;
  },
};
