<!-- eslint-disable prettier/prettier -->
<template>
  <v-form ref="createIndexingForm" v-model="form.isValid" :class="getCreationFormMargin" :disabled="shouldFormBeDisabled()">
    <v-container fluid>
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteIndexingConfirm" @dialogDeny="showConfirm = false" />

      <!-- uuid & name -->
      <v-row>
        <v-col v-if="isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.uuid"
            label="UUID"
            prepend-inner-icon="mdi-code-braces"
            :autocomplete="$store.getters.disableAutocomplete"
            readonly
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.name"
            label="Name"
            :rules="[requiredField, charsGreaterThanAndLessThan(editedIndexing.name, 3, 64), validateIndexName, isIndexUnique(IndexingsList, editedIndexing)]"
            prepend-inner-icon="mdi-card-bulleted"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>

      <!-- inputs -->
      <v-subheader class="mt-6 mb-0">
        Input Documents
      </v-subheader>
      <v-row>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedIndexing.knowledge_base_configuration.type"
            label="Input Source"
            :items="inputDocumentsType"
            prepend-inner-icon="mdi-source-branch"
            :autocomplete="$store.getters.disableAutocomplete"
            :return-object="false"
            @change="onChangeInputSource"
          />
        </v-col>
      </v-row>

      <!-- AWS s3 configs -->
      <v-row v-if="!isCrawlerSelected">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.knowledge_base_configuration.url"
            label="AWS S3 Bucket URL"
            prepend-inner-icon="mdi-web"
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-model="editedIndexing.knowledge_base_configuration.region"
            :items="$store.getters.getAwsRegionCode"
            label="Region"
            :rules="[requiredField]"
            prepend-inner-icon="vpn_lock"
            :return-object="false"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.knowledge_base_configuration.key"
            label="AWS Access Key"
            :rules="[requiredField]"
            prepend-inner-icon="lock"
            :autocomplete="$store.getters.disableAutocomplete"
            class="my-3"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.knowledge_base_configuration.secret"
            :type="showPassword ? 'text' : 'password'"
            label="AWS Secret Key"
            :rules="[requiredField]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn
                depressed
                icon
                text
                :disabled="!editedIndexing.knowledge_base_configuration.secret || editedIndexing.knowledge_base_configuration.secret.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- CRAWLER configs-->
      <v-row v-if="isCrawlerSelected">
        <!-- crawler configs -->
        <v-col v-if="isCrawlerSelected" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedIndexing.knowledge_base_configuration.url"
            label="Website URL"
            :rules="[requiredField, isURL]"
            prepend-inner-icon="mdi-web"
            :autocomplete="$store.getters.disableAutocomplete"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>This represents the starting url from which the crawler starts to crawl your website. Note that, if you let the crawler follows robots.txt and sitemaps rules, this field will be used to generate the url that points to your robots.txt. Be sure that, from the provided url, it is possibile to reach your robots.txt. If no sitemaps are found, the crawler starts from the url configured as fallback.</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="isCrawlerSelected" cols="12" sm="6" :class="getFormColMargin">
          <v-switch v-model="editedIndexing.knowledge_base_configuration.website_rules" color="primary">
            <template #label>
              <v-col cols="12">
                Adopt robots.txt and sitemaps crawler rules
                <v-tooltip max-width="400" color="primary" left>
                  <template #activator="{ on }">
                    <v-btn style="margin-right: 0 !important; right: 0 !important; position: absolute;" class="mt-n2" text icon v-on="on">
                      <v-icon color="primary">
                        mdi-information-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>If this option is enabled, the crawler will use the instructions shown through the robots.txt file and related sitemaps for indexing the website pages.</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-switch>
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-radio-group v-model="editedIndexing.knowledge_base_configuration.subdomains" mandatory dense>
            <template #label>
              <div>Choose if you want to crawl only domains or also subdomains</div>
            </template>
            <v-radio class="flex" value="domains">
              <template #label>
                <div class="flex">
                  Crawl host <strong class="text-success">domains only</strong>
                </div>
              </template>
            </v-radio>
            <v-radio value="subdomains">
              <v-radio class="flex" value="domains">
                <template #label>
                  <div class="flex">
                    Crawl host <strong class="text-success">domains only</strong>
                  </div>
                </template>
              </v-radio>
              <template #label>
                <div>Crawl host domains and their <strong class="text-primary">subdomains only</strong></div>
              </template>
            </v-radio>
            <template #append>
              <v-tooltip max-width="400" color="primary" left>
                <template #activator="{ on }">
                  <v-btn class="mt-n2" icon text v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>If the first options is selected only linked pages in the source domain are crawled: subdomains and linked pages for other domains are ignored. If the second option is selected, all linked pages in the subdomains under the source domain are crawled.</span>
              </v-tooltip>
            </template>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-radio-group v-model="editedIndexing.knowledge_base_configuration.browser" mandatory dense>
            <template #label>
              <div>Choose if you want to crawl a static or dynamic website</div>
            </template>
            <v-radio value="static">
              <template #label>
                <div class="flex">
                  <strong class="text-success">Static</strong> website
                </div>
              </template>
            </v-radio>
            <v-radio value="dynamic">
              <template #label>
                <div class="flex">
                  <strong class="text-success">Dynamic</strong> website
                </div>
              </template>
            </v-radio>
            <template #append>
              <v-tooltip max-width="400" color="primary" left>
                <template #activator="{ on }">
                  <v-btn class="mt-n2" icon text v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>It is general recommended to select "Dynamic website" if you want to crawl a website in which the content is strongly dependent by javascript functions. "Dynamic website" is slower than "Static website" crawler but it is more suitable for content loaded dynamically.</span>
              </v-tooltip>
            </template>
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">          
          <v-text-field
            v-model.number="editedIndexing.knowledge_base_configuration.max_link"
            label="Maximum Links/Page"
            type="number"
            :rules="[requiredField, positiveNumber, numberInRange(editedIndexing.knowledge_base_configuration.max_link, 0, 50)]"
            prepend-inner-icon="mdi-numeric-9-box-multiple-outline"
            :autocomplete="$store.getters.disableAutocomplete"
            min="0"
            max="50"
            @keypress="onlyNumber"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>The maximum number of links crawled per page. Links are crawled in order of appearance.</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">          
          <v-text-field
            v-model.number="editedIndexing.knowledge_base_configuration.max_size"
            label="Max Size (Mb)"
            type="number"
            :rules="[requiredField, positiveNumber, numberInRange(editedIndexing.knowledge_base_configuration.max_size, 1, 10)]"
            :autocomplete="$store.getters.disableAutocomplete"
            prepend-inner-icon="mdi-check-network-outline"
            min="1"
            max="10"
            @keypress="onlyNumber"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>The maximum web page or attachment size to crawl. Files larger than this value are skipped when crawling.</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-combobox
            v-model.trim="editedIndexing.knowledge_base_configuration.include_path"
            class="mt-1"
            hide-selected
            placeholder="Press enter to insert each pattern"
            label="Include Path"
            multiple
            small-chips
            deletable-chips
            prepend-inner-icon="mdi-magnify-plus-outline"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Specify one or more regexp patterns in order to include only certain URLs. Note that if a pattern is present, all other URLs that do not match the pattern are automatically excluded by the crawler.</span>
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" md="6">
          <v-combobox
            v-model.trim="editedIndexing.knowledge_base_configuration.exclude_path"
            class="mt-1"
            hide-selected
            placeholder="Press enter to insert each pattern"
            label="Exclude Path"
            multiple
            small-chips
            deletable-chips
            prepend-inner-icon="mdi-magnify-remove-outline"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Specify one or more regexp patterns in order to exclude only certain URLs. Note that if a pattern is present, all other URLs that do not match the pattern are automatically included by the crawler.</span>
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" md="6">
          <v-combobox
            v-model.trim="editedIndexing.knowledge_base_configuration.excluded_selectors"
            class="mt-1"
            hide-selected
            placeholder="Press enter to insert each selector"
            label="Exclude HTML Selectors"
            multiple
            small-chips
            deletable-chips
            prepend-inner-icon="mdi-select-compare"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Specify one or more HTML selectors in order to instruct the crawler to exclude, from text extraction, the page sections identified by the indicated selectors.</span>
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.number="editedIndexing.knowledge_base_configuration.timeout"
            label="Timeout (sec)"
            type="number"
            :rules="[requiredField, positiveNumber, greaterThanZero]"
            prepend-inner-icon="mdi-timer-outline"
            min="0"
            max="1000"
            @keypress="onlyNumber"
          >
            <template #append>
              <v-tooltip max-width="300" color="primary" left>
                <template #activator="{ on }">
                  <v-btn :disabled="shouldFormBeDisabled()" text icon v-on="on">
                    <v-icon color="primary">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Maximum waiting time for a web page to be loaded. If a page took more than the configured timeout, the request to that page is canceled.</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- generativeai -->
      <v-subheader class="mt-6 mb-0">
        GenerativeAI
      </v-subheader>
      <v-row>
        <v-col cols="12" md="6" :class="getFormColMargin">
          <v-select
            v-model="editedIndexing.openai_configuration.key"
            :rules="[requiredField]"
            label="API Key"
            prepend-inner-icon="lock"
            :autocomplete="$store.getters.disableAutocomplete"
            :items="getOpenAiKeys"
            :return-object="false"
            @change="updateAdvancedParameters"
          />
        </v-col>
      </v-row>

      <!-- index -->
      <v-subheader class="mt-6 mb-0">
        Vector Database
      </v-subheader>
      <v-row>
        <v-col cols="12" :class="getFormColMargin">
          <v-text-field
            v-model="editedIndexing.database_configuration.index"
            :rules="[requiredField, simpleIndexNameString]"
            label="Index Name"
            prepend-inner-icon="mdi-database"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>

      <!-- advanced configuration -->
      <v-expansion-panels class="my-6 mx-0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon color="primary">mdi-tune</v-icon>
              <span class="ml-4">Advanced parameters</span>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0">
              <!-- <v-col cols="12" sm="6" class="py-0">
                <v-text-field label="Split Text Chars" v-model="editedIndexing.params.slit_text_chars" />
              </v-col> -->
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field v-model="editedIndexing.params.token_per_chunk" label="Token per Chunk" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field v-model="editedIndexing.params.token_overlap" label="Token Overlap" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field v-model="editedIndexing.params.encoding_model" label="Encoding Model" @change="editedIndexing.params.completion_engine = editedIndexing.params.encoding_model" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field v-model="editedIndexing.params.embedding_engine" label="Embedding Engine" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Buttons -->
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" class="">
          <!-- save-update -->
          <v-btn :class="getButtonMargin" color="success" :loading="form.submitLoading" :disabled="isDisabledSave" @click.prevent="saveIndexing">
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <!-- cancel-delete -->
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isDisabledDelete" @click.prevent="deleteIndexing">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
          <v-btn v-if="isEdit && isStartableState()" :disabled="loadingTest || !editedIndexing.openai_configuration.key" :class="getButtonMargin" color="primary" @click.prevent="startIndexing">
            <v-icon left>
              mdi-run-fast
            </v-icon>
            <span>Start Indexing</span>
          </v-btn>
          <v-btn v-if="isEdit && isRunningState()" :class="getButtonMargin" color="error" @click.prevent="stopIndexing">
            <v-icon left>
              mdi-cancel
            </v-icon>
            <span>Stop Indexing</span>
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            color="warning"
            :class="getButtonMargin"
            :loading="loadingTest"
            :style="$vuetify.breakpoint.width >= 1153 ? 'position:relative; float:right' : 'position:relative; float:left'"
            @click.prevent="testConfiguration"
          >
            <v-icon small class="mr-2">
              mdi-check
            </v-icon>
            Test configuration
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="statusObj">
        <v-col cols="12" class="text-center">
          <!-- <v-chip small color="success">Status: {{ statusObj.status }}</v-chip> -->
          <!-- status: {{ statusObj.status }}, <span v-if="statusObj.start">started at: {{ new Date(statusObj.start).toISOString() }}</span>     -->
          <v-progress-linear
            v-if="statusObj.status == 'RUNNING'"
            color="primary"
            indeterminate
            rounded
            height="6"
            class="mt-3 mb-1"
          />
          <hr v-if="statusObj.status != 'RUNNING'" style="border: 1px solid #2f999e" class="mt-3 mb-2">
          <div style="width: 100%">
            <v-chip small class="mr-2 pa-3 pl-0">
              <v-icon v-if="statusObj.status != 'RUNNING'" :color="getColorByStatus">
                mdi-circle-medium
              </v-icon>
              <v-icon v-else :color="getColorByStatus" size="16" class="ml-1 mr-1">
                mdi-play
              </v-icon>

              {{ statusObj.status }}
            </v-chip>
            <span v-if="statusObj.status == 'RUNNING'" style="font-size: 75%">
              <span v-if="statusObj.data"> Document {{ statusObj.data?.current }} of {{ statusObj.data?.document }} | </span>started at
              {{ new Date(statusObj.start).toISOString() | formatVerboseDateTime }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "@/event-bus";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import merge from "deepmerge";
import Vue from "vue";

export default {
  name: "IndexingForm",
  components: {
    ConfirmDialog,
  },
  props: ["IndexingSetting", "IndexingsList", "statusObj", "openAiConfig"],
  data() {
    return {
      crawlSubdomains: ["domains", "subdomains"],
      showConfirm: false,
      showPassword: false,
      awsRegion: [],
      deleteMessage: "Indexing " + this.IndexingSetting.name + " will be deleted!",
      isEdit: this.IndexingSetting.name !== "",
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      rerender: 0,
      editedIndexing: {
        eri: null,
        uuid: null,
        name: this.IndexingSetting.name,
        knowledge_base_configuration: this.IndexingSetting.knowledge_base_configuration,
        openai_configuration: this.IndexingSetting.openai_configuration,
        database_configuration: this.IndexingSetting.database_configuration,
        params: this.IndexingSetting.params,
      },
      loadingTest: false,
    };
  },
  computed: {
    ...spacing,
    getOpenAiKeys() {
      return this.openAiConfig
        .map((m) => {
          return {
            text: m.name,
            value: m.uuid,
          };
        })
        .sort((a, b) => {
          if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          }
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    inputDocumentsType() {
      return [
        { text: "AWS S3", value: "AWS_S3" },
        { text: "Website Crawler", value: "CRAWLER" },
      ];
    },
    isCrawlerSelected() {
      this.rerender;
      return this.editedIndexing.knowledge_base_configuration.type === "CRAWLER";
    },
    isDisabledSave() {
      if (!this.editedIndexing.openai_configuration.key) {
        return true;
      }
      if (!this.isEdit || (this.isEdit && this.isStartableState())) {
        if (!this.form.isValid || this.form.deleteLoading) {
          return true;
        }
        return false;
      }
      return true;
    },
    isDisabledDelete() {
      if (!this.isEdit || (this.isEdit && this.isDeletableState())) {
        if (this.isEdit && this.form.submitLoading) {
          return true;
        }
        return false;
      }
      return true;
    },
    getColorByStatus() {
      if (this.statusObj.status == "READY") {
        return "blue";
      } else if (this.statusObj.status == "COMPLETED") {
        return "green";
      } else if (this.statusObj.status == "RUNNING") {
        return "orange";
      } else if (this.statusObj.status == "CANCELED") {
        return "red";
      } else return "grey";
    },
  },
  mounted() {
    this.editedIndexing = merge(this.editedIndexing, this.IndexingSetting);
    if (!this.editedIndexing.knowledge_base_configuration.type) {
      this.editedIndexing.knowledge_base_configuration.type = this.inputDocumentsType[0].value;
    }
    //questo serve perchè il merge tra due array duplica le entry
    if (this.editedIndexing.knowledge_base_configuration.type == this.inputDocumentsType[1].value) {
      this.editedIndexing.knowledge_base_configuration.exclude_path = this.IndexingSetting.knowledge_base_configuration.exclude_path || [];
      this.editedIndexing.knowledge_base_configuration.include_path = this.IndexingSetting.knowledge_base_configuration.include_path || [];
      this.editedIndexing.knowledge_base_configuration.excluded_selectors = this.IndexingSetting.knowledge_base_configuration.excluded_selectors || [];
    }
    this.editedIndexing.params.completion_engine = this.editedIndexing.params.encoding_model;
    if (this.$refs.createIndexingForm) this.$refs.createIndexingForm.resetValidation();
  },
  methods: {
    ...fieldValidators,
    updateAdvancedParameters(e) {
      const conf = this.openAiConfig.find((conf) => conf.uuid == e);
      if (conf?.type == "OpenAI" && this.editedIndexing.params.encoding_model.indexOf("anthropic") !== -1) {
        this.editedIndexing.params.encoding_model = "gpt-4o";
        this.editedIndexing.params.embedding_engine = "text-embedding-3-small";
      } else if (conf?.type == "Bedrock" && this.editedIndexing.params.encoding_model.indexOf("gpt") !== -1) {
        this.editedIndexing.params.encoding_model = "anthropic.claude-v2";
        this.editedIndexing.params.embedding_engine = "cohere.embed-multilingual-v3";
      }
      this.editedIndexing.params.completion_engine = this.editedIndexing.params.encoding_model;
    },
    async testConfiguration() {
      this.loadingTest = true;
      try {
        const response = await this.$httpAuth.post(`/knowledge-base/indexing/test`, this.editedIndexing);
        if (response.data) {
          if (response.data.result.engine.status === "OK" && response.data.result.source.status === "OK" && response.data.result.vectors.status === "OK") {
            EventBus.$emit(this.$store.getters.getEvents.INDEXING_START, {
              message: "Test of " + this.editedIndexing.name + " configuration done successfully",
            });
          } else {
            EventBus.$emit(this.$store.getters.getEvents.INDEXING_START_FAIL, {
              message: [
                `Something wrong with ${this.editedIndexing.name} test`,
                `- Generative AI: ${response.data.result.engine.status}`,
                `- Input Documents: ${response.data.result.source.status}`,
                `- Index: ${response.data.result.vectors.status}`,
              ],
            });
          }
        }
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.INDEXING_START_FAIL, {
          message: "Something wrong with " + this.editedIndexing.name + " test",
        });
      } finally {
        setTimeout(() => (this.loadingTest = false), 15000);
      }
    },
    onChangeInputSource(val) {
      this.rerender++;
      if (val === this.inputDocumentsType[1].value) {
        if (!this.editedIndexing.knowledge_base_configuration.max_link) {
          Vue.set(this.editedIndexing.knowledge_base_configuration, "max_link", 5);
        }
        if (!this.editedIndexing.knowledge_base_configuration.max_size) {
          Vue.set(this.editedIndexing.knowledge_base_configuration, "max_size", 10);
        }
        if (!this.editedIndexing.knowledge_base_configuration.timeout) {
          Vue.set(this.editedIndexing.knowledge_base_configuration, "timeout", 30);
        }
      }
    },
    shouldFormBeDisabled() {
      if (this.statusObj) {
        if (this.statusObj.status == "RUNNING" || this.statusObj.status == "COMPLETED") {
          return true;
        }
      }
      return false;
    },
    isStartableState() {
      return this.statusObj?.status === "READY" || this.statusObj?.status === "CANCELED" || this.statusObj?.status === "COMPLETED";
    },
    isDeletableState() {
      return (
        this.statusObj?.status === "N/A" ||
        this.statusObj?.status === "READY" ||
        this.statusObj?.status === "CANCELED" ||
        this.statusObj?.status === "COMPLETED"
      );
    },
    isRunningState() {
      return this.statusObj?.status === "RUNNING";
    },
    async startIndexing() {
      try {
        await this.$httpAuth.get(`/knowledge-base/indexing/start/${this.editedIndexing.uuid}`);
        EventBus.$emit(this.$store.getters.getEvents.INDEXING_START, {
          message: "Indexing of " + this.editedIndexing.name + " started",
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.INDEXING_START_FAIL, {
          message: "Indexing of " + this.editedIndexing.name + " can not be started",
        });
      }
    },
    async stopIndexing() {
      try {
        await this.$httpAuth.get(`/knowledge-base/indexing/stop/${this.editedIndexing.uuid}`);
        EventBus.$emit(this.$store.getters.getEvents.INDEXING_STOP, {
          message: "Indexing of " + this.editedIndexing.name + " stopped",
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.INDEXING_STOP_FAIL, {
          message: "Indexing of " + this.editedIndexing.name + " can not be stopped",
        });
      }
    },
    async saveIndexing() {
      if (this.form.isValid) {
        try {
          this.form.isSubmitted = true;
          this.form.submitLoading = true;
          const result = await this.$http.post(`/knowledge-base/indexing`, this.editedIndexing);
          this.editedIndexing = result.data;
          EventBus.$emit(this.$store.getters.getEvents.EDIT_INDEXING, {
            editConfig: this.editedIndexing,
            message: "Indexing " + this.editedIndexing.name + " " + (this.isEdit ? " updated" : " created"),
          });
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_INDEXING_FAIL, {
            editConfig: this.editedIndexing,
            message: `Error ${this.isEdit ? "updating" : " creating"} indexing batch ${this.editedIndexing.name}`,
          });
        } finally {
          this.form.isSubmitted = false;
          this.form.submitLoading = false;
        }
      }
    },
    deleteIndexing() {
      if (this.IndexingSetting.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_INDEXING_CREATION);
      }
    },
    async onDeleteIndexingConfirm() {
      this.showConfirm = true;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete(`/knowledge-base/indexing/${this.editedIndexing.uuid}`);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_INDEXING, {
          label: this.editedIndexing.name,
          editConfig: this.editedIndexing,
          message: `Indexing ${this.editedIndexing.name} Deleted!`,
        });
        this.showConfirm = false;
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_INDEXING_FAIL, {
          message: `Fail to delete indexing ${this.editedIndexing.name}`,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
  },
};
</script>
