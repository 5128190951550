<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon
        v-clipboard="() => data"
        v-clipboard:success="clipboardSuccessHandler"
        :disabled="disabled"
        class="ml-2"
        :class="notopmargin ? '' : 'mt-n2'"
        style="cursor: pointer"
        v-bind="attrs"
        @click="() => {}"
        v-on="on"
      >
        mdi-content-copy
      </v-icon>
    </template>
    <span>Copy to clipboard</span>
  </v-tooltip>
</template>

<script>
import EventBus from "../../event-bus";

export default {
  name: "CopyToClipboard",
  props: ["data", "message", "notopmargin", "disabled"],
  data() {
    return {};
  },
  methods: {
    clipboardSuccessHandler() {
      EventBus.$emit(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.message);
    },
  },
};
</script>
