<template>
  <v-container fluid>
    <v-dialog v-model="dialogDetail" max-width="700px" class="activityDatatableScrollbar">
      <v-card>
        <v-card-title>
          <div>Content Detail</div>
          <v-spacer />
          <v-btn color="primary" large icon text @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="activityDatatableHeight">
              <v-divider class="mb-4 primary" />
              <div style="position: relative; height: 100%">
                <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" class="psActivityDetail">
                  <pre class="activityDatatablePreHeight">{{ JSON.stringify(requestBean, null, 4) }}</pre>
                </perfect-scrollbar>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="pa-0">
        <v-row align="center">
          <v-col cols="7" class="text-right">
            <v-menu ref="menu" top>
              <template #activator="{ on: menu }">
                <v-tooltip color="primary" bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn :disabled="dataset && dataset.length === 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                      <v-icon small left>
                        mdi-download
                      </v-icon> Download
                    </v-btn>
                  </template>
                  <span>Choose the format and download dataset</span>
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item @click="exportXlsx">
                  <v-list-item-title>
                    <v-icon left>
                      mdi-file-excel-outline
                    </v-icon>XLSX
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportCsv">
                  <v-list-item-title>
                    <v-icon left>
                      mdi-file-delimited-outline
                    </v-icon>CSV
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="searchString"
              outlined
              dense
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
              class="mr-4"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        must-sort
        :headers="headers"
        :items="dataset"
        class="elevation-1"
        :search="searchString"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50],
        }"
      >
        <template slot="no-data">
          <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
            Not enough data to render this table!
          </v-alert>
        </template>
        <template #item.timestamp="{ item }">
          <v-row no-gutters>
            {{ item.timestamp | formatVerboseDateTimeForActivity }}
          </v-row>
        </template>
        <template #item.detail="{ item }">
          <v-row
            v-if="
              item.action !== 'Login_Success' &&
                item.action !== 'Logout' &&
                (!item.action.includes('Delete') ||
                  item.action === 'Recording_Delete' ||
                  item.action === 'Delete_AgentDesktop' ||
                  item.action === 'Maintenance_Restore_Delete' ||
                  item.action === 'Maintenance_Backup_Delete') &&
                item.action !== 'Export_CustomerGui'
            "
            no-gutters
          >
            <v-tooltip color="primary" left>
              <template #activator="{ on }">
                <v-btn text icon v-on="on" @click="showDetail(item._id)">
                  <v-icon color="primary">
                    mdi-page-next-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import exportFile from "../../helpers/exportFile";
import customFilter from "../../plugins/customFilter";

export default {
  name: "ActivityDatatable",
  props: {
    "dataset": {
      type: Array,
      required: true
    },
    "dateStart": {
      type: Date,
      required: true
    },
    "dateEnd": {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      searchString: "",
      headers: [
        { text: "Username", value: "username" },
        { text: "Action", value: "action" },
        { text: "Date", value: "timestamp" },
        { text: "", value: "detail", sortable: false, width: "120" },
      ],
      requestBean: {},
      dialogDetail: false,
    };
  },
  methods: {
    ...exportFile,
    ...customFilter,
    async showDetail(rowId) {
      try {
        const result = await this.$http.get("/report-activity/" + rowId);
        this.requestBean = result.data.bean;
        this.dialogDetail = true;
      } catch (e) {
        this.$emit("ErrorShowDetail");
      }
    },
    exportXlsx() {
      this.exportActivityXlsx(this.dataset);
    },
    exportCsv() {
      this.exportActivityCsv(this.dataset);
    },
  },
};
</script>

<style>
.psActivityDetail {
  height: 65vh;
}
.activityDatatableScrollbar {
  overflow: hidden;
}

.activityDatatableHeight {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.activityDatatablePreHeight {
  height: 100%;
  position: absolute;
}
</style>
