<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteLoggerConfirm" />

    <v-row>
      <v-col cols="6" offset="3">
        <v-alert v-if="enforceLogLevelSecurity" type="warning">
          The flag to enforce log security is activated.Logs levels can not be modified.
        </v-alert>
      </v-col>
    </v-row>

    <v-form v-model="form.isValid">
      <v-card v-if="addNew" justify="center" width="90%" class="mx-auto">
        <v-row :class="getMainRowMargin">
          <v-col cols="12" class="ml-4">
            Add new Logger
          </v-col>
          <v-col cols="6" class="px-6">
            <v-text-field
              v-model="newCategoryItem"
              placeholder="Category name"
              :rules="[requiredField, simpleAlphaNumString]"
              autofocus
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>

          <v-col cols="6" class="px-6">
            <v-select v-model="newLogItem" :items="logLevels" label="Logger level" :rules="[requiredField]" />
          </v-col>
          <v-col cols="12">
            <v-btn :class="getButtonMargin" class="ml-3" color="success" :disabled="!form.isValid" @click="saveNewItem()">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
            <v-btn :class="getButtonMargin" color="error" @click="deleteNewItem()">
              <v-icon left>
                mdi-close
              </v-icon>Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <v-row justify="center">
      <v-col cols="12">
        <v-card class="mx-auto" width="90%">
          <v-card-text>
            <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" style="height: 80vh">
              <v-row v-for="(level, category) in loggers.categories" :key="category" no-gutters>
                <v-col cols="5">
                  <v-list-item dense class="mt-1">
                    <v-list-item-content>
                      <v-list-item-title>{{ category }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="5">
                  <v-select v-model="loggers.categories[category]" class="mt-2" dense :items="logLevels" />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    small
                    class="mt-3 ml-6"
                    icon
                    text
                    :disabled="enforceLogLevelSecurity"
                    @click="deleteItem(category)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </perfect-scrollbar>
          </v-card-text>
          <v-card-actions>
            <v-btn class="ml-4" color="success" :disabled="enforceLogLevelSecurity" @click.prevent="update">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AddNewItemButton name="Logger" @addNewItem="addNewLogger" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../../components/other/AddNewItemButton";
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";

export default {
  name: "Loggers",
  components: {
    AddNewItemButton,
    ConfirmDialog,
    ResultSnackbar,
  },
  data: () => ({
    //Flag per dialog di conferma cancellazione
    showConfirm: false,
    deleteMessage: "This Logger will be deleted!",

    form: {
      isValid: false,
      submitLoading: false,
    },
    logLevels: ["off", "fatal", "error", "warn", "info", "debug", "trace", "all"],
    loggers: {
      categories: {},
    },
    headers: [
      {
        text: "Categories",
        value: "category",
        align: "left",
        sortable: true,
        width: "350px",
      },
      {
        text: "Levels",
        value: "level",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
    newCategoryItem: "",
    newLogItem: "",
    addNew: false,
    elementToDelete: "",
    enforceLogLevelSecurity: false,
  }),
  computed: {
    ...spacing,
  },
  async mounted() {
    try {
      const logging = await this.$http.get("/logging");
      const serverConf = await this.$http.get("/settings");
      this.loggers = logging.data;
      if (serverConf.data.enforceLogLevelSecurity) {
        this.enforceLogLevelSecurity = serverConf.data.enforceLogLevelSecurity;
      }
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    deleteItem(category) {
      this.showConfirm = true;
      this.elementToDelete = category;
    },
    onDeleteLoggerConfirm() {
      delete this.loggers.categories[this.elementToDelete];
      this.elementToDelete = "";
      this.showConfirm = false;
      this.sendRequest("deleted");
    },
    update() {
      this.sendRequest("saved");
    },
    saveNewItem() {
      if (this.form.isValid) {
        this.loggers.categories[this.newCategoryItem] = this.newLogItem;
        this.addNew = false;
        this.newCategoryItem = "";
        this.newLogItem = "";
        this.sendRequest("saved");
      }
    },
    deleteNewItem() {
      this.addNew = false;
      this.newCategoryItem = "";
      this.newLogItem = "";
    },
    addNewLogger() {
      this.addNew = true;
    },
    async sendRequest(action) {
      try {
        await this.$http.post("/logging", this.loggers);
        this.$refs.resultSnackbar.showSuccess("Logger " + action + "!");
      } catch (err) {
        this.$refs.resultSnackbar.showError("Failed to save logger: " + err.message);
      }
    },
  },
};
</script>
<style>
.loggersDataTable tr th[role="columnheader"] {
  height: 55px !important;
}
.loggersDataTable .v-data-table__wrapper {
  height: calc(85vh - 125px) !important;
}
</style>
