<template>
  <v-form v-model="form">
    <v-row class="pl-4 pr-6" no-gutters>
      <v-col cols="6" v-show="isEdit ? false : true">
        <v-text-field
          dense
          :label="isEdit ? 'Customer Gui Name' : 'New Customer Gui Name'"
          v-model.trim="gui.name"
          :rules="
            !isEdit
              ? [
                  requiredField,
                  charsGreaterThanAndLessThan(gui.name, 3, 20),
                  isCustomerInterfaceNameUnique(customerInterfacesList, gui.name),
                  validateCustomerInterfaceName(gui.name),
                ]
              : []
          "
          :autocomplete="$store.getters.disableAutocomplete"
          autofocus
          prepend-icon="mdi-robot"
        >
        </v-text-field>
      </v-col>
      <v-col :cols="isEdit ? 12 : 6" class="d-flex justify-end py-0">
        <v-switch dense label="Import by web url" v-model="calcSwitch" @change="gui.type == 'zip' ? (gui.type = 'url') : (gui.type = 'zip')"></v-switch>
      </v-col>
      <fileUpload
        v-if="gui.type == 'zip'"
        :file="file"
        fileAccepted="zip"
        :isLogo="false"
        @uploadFile="uploadFile"
        @removeFile="removeFile"
        :loading="loading.save || loading.delete"
      />
      <v-col cols="6" v-else>
        <v-text-field dense v-model="gui.url" prepend-icon="mdi-file-link" :rules="[requiredField, isURL]" label="Insert url"> </v-text-field>
      </v-col>
      <!-- Buttons -->
      <v-col cols="12" sm="12" v-if="!isEdit" :class="gui.type == 'zip' ? 'mt-12 mb-4' : 'mt-10 mb-4'">
        <v-row align="center" class="px-2">
          <v-btn
            color="success"
            class="mx-2"
            @click.prevent="importFile"
            :loading="loading.save"
            :disabled="gui.type == 'zip' ? loading.delete || !file || (file && file.type && !isZipFile(file.type)) || !form : loading.delete || !form"
          >
            <v-icon left>mdi-floppy</v-icon>
            Save
          </v-btn>
          <v-btn color="error" @click="$emit('closePanel')" :loading="loading.delete" :disabled="loading.save">
            <v-icon left>mdi-close</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";
import fileUpload from "./fileUpload.vue";
import spacing from "../../../helpers/spacing";

export default {
  name: "CustomerGuiForm",
  components: {
    fileUpload,
  },
  props: ["isEdit", "gui", "file", "customerInterfacesList", "loading"],
  data() {
    return {
      form: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit("validForm", this.form);
      },
    },
  },
  methods: {
    ...fieldValidators,
    uploadFile(obj) {
      this.$emit("uploadFile", obj);
    },
    importFile() {
      this.$emit("importFile", false);
    },
    isZipFile(type) {
      return type.includes("zip");
    },
    removeFile() {
      this.$emit("removeFile");
      this.$emit("validForm", this.form);
    },
  },
  computed: {
    ...spacing,
    calcSwitch: {
      get() {
        if (this.gui.type == "zip") {
          return false;
        }
        return true;
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>
