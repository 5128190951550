import { render, staticRenderFns } from "./QueueCapacity.vue?vue&type=template&id=d783e1c6&scoped=true"
import script from "./QueueCapacity.vue?vue&type=script&lang=js"
export * from "./QueueCapacity.vue?vue&type=script&lang=js"
import style0 from "./QueueCapacity.vue?vue&type=style&index=0&id=d783e1c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d783e1c6",
  null
  
)

export default component.exports