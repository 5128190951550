<template>
  <v-form ref="createAgentForm" v-model="form.isValid" :class="getCreationFormMargin" :disabled="$store.getters.isCCMO">
    <v-container fluid>
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteAgentConfirm" @dialogDeny="showConfirm = false" />
      <KickModal
        :showConfirm="showConfirmKick"
        :message="kickMessage"
        @dialogConfirm="onKickAgentConfirm"
        @dialogDeny="
          showConfirmKick = false;
          kickLoading = false;
        "
      />
      <v-row :class="getFormRowMargin">
        <v-col cols="6" :class="getFormColMargin">
          <v-btn-toggle v-if="isEdit" tile group>
            <v-switch v-model="getAgentDisableFlag" color="primary" label="Enabled" @change="editedAgent.disabled = !editedAgent.disabled" />
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row v-if="!isEdit" :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-autocomplete
            v-model="getSelectedPermission"
            :items="permissionsListItems"
            label="Permission Set"
            prepend-inner-icon="mdi-lock"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>

      <!-- Riga agent eri e permission set -->
      <v-row v-if="isEdit" :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin" class="d-inline-flex">
          <v-text-field v-model.trim="editedAgent.eri" label="Agent ERI" readonly disabled />
          <CopyToClipboard :data="editedAgent.eri" message="ERI copied to clipboard!" />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-model="getSelectedPermission"
            :items="permissionsListItems"
            label="Permission Set"
            prepend-inner-icon="mdi-lock"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>

      <!-- Riga nome e cognome utente -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedAgent.info.name"
            label="First Name"
            :rules="[requiredField, charsGreaterThanAndLessThan(editedAgent.info.name, 3, 255)]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedAgent.info.surname"
            label="Last Name"
            :rules="[requiredField, charsGreaterThanAndLessThan(editedAgent.info.name, 3, 255)]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>

      <!-- Riga solo per la mail e username in visualizzazioen cosi è allineato -->
      <v-row :class="getFormRowMargin" align="center">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedAgent.authentication.credentials.email"
            label="Email"
            :rules="[requiredField, isValidEmail]"
            prepend-inner-icon="email"
            :autocomplete="$store.getters.disableAutocomplete"
            @blur="onEmailBlur"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedAgent.authentication.credentials.username"
            label="Username"
            :rules="[
              requiredField,
              isValidAgentUsername,
              isAgentUniqueUsername(editedAgent.authentication.credentials.username),
              customCharsGreaterThanAndLessThan(editedAgent.authentication.credentials.username, 5, 255),
            ]"
            prepend-inner-icon="mdi-account-tie"
            :autocomplete="$store.getters.disableAutocomplete"
            :disabled="isEdit"
            :readonly="isEdit"
          />
        </v-col>
      </v-row>

      <!-- Riga solo per la creazione password -->
      <v-row v-if="!isEdit" :class="getFormRowMargin" align="center">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedAgent.authentication.credentials.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[
              requiredField,
              isValidPassword,
              isUsernameInPassword(editedAgent.authentication.credentials.username, editedAgent.authentication.credentials.password),
              arePasswordsEqual(editedAgent.authentication.credentials.password, retypePassword),
            ]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn
                depressed
                icon
                text
                :disabled="editedAgent.authentication.credentials.password && editedAgent.authentication.credentials.password.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="retypePassword"
            label="Retype Password"
            :type="showPasswordRetype ? 'text' : 'password'"
            :rules="[requiredField, arePasswordsEqual(editedAgent.authentication.credentials.password, retypePassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="retypePassword && retypePassword.length < 1" tabindex="-1">
                <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Riga profilo-->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-model="getSelectedProfile"
            :items="profileListItems"
            label="Profile"
            prepend-inner-icon="mdi-drama-masks"
            :rules="[requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-model="editedAgent.authentication.credentials.expiration_months"
            :items="$store.getters.getExpirationPasswordChoice"
            label="Password Expiration"
            prepend-inner-icon="mdi-lock"
            :return-object="false"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>

      <!-- Reset password-->
      <v-row v-if="showResetPassword">
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.adminPassword"
            label="Admin Password"
            :hint="$store.getters.getUsername + ' password'"
            :type="showAdminPsw ? 'text' : 'password'"
            :rules="[requiredField, isValidPassword]"
            prepend-inner-icon="mdi-clipboard-account"
            :autocomplete="$store.getters.disableAutocomplete"
            validate-on-blur
          >
            <template slot="append">
              <v-btn
                small
                depressed
                icon
                text
                :disabled="resetPassword.adminPassword.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showAdminPsw" tabindex="-1" @click="showAdminPsw = !showAdminPsw">
                  visibility_off
                </v-icon>
                <v-icon v-show="showAdminPsw" tabindex="-1" @click="showAdminPsw = !showAdminPsw">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.newAgentPassword"
            label="User new password"
            :type="showPswToReset ? 'text' : 'password'"
            :rules="[requiredField, isValidPassword, isUsernameInPassword(agent.username, resetPassword.newAgentPassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
            validate-on-blur
            :error-messages="resetPasswordsEqual ? '' : customErrorMessage"
          >
            <template slot="append">
              <v-btn
                small
                depressed
                icon
                text
                :disabled="resetPassword.newAgentPassword.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showPswToReset" tabindex="-1" @click="showPswToReset = !showPswToReset">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPswToReset" tabindex="-1" @click="showPswToReset = !showPswToReset">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="resetPassword.confirmNewPassword"
            label="Repeat user new password"
            :type="showConfirmPassword ? 'text' : 'password'"
            :rules="[requiredField, confirmPassword(resetPassword.newAgentPassword, resetPassword.confirmNewPassword)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
            validate-on-blur
            :error-messages="resetPasswordsEqual ? '' : customErrorMessage"
            :error="!resetPasswordsEqual"
          >
            <template slot="append">
              <v-btn
                small
                depressed
                icon
                text
                :disabled="resetPassword.confirmNewPassword.length < 1"
                tabindex="-1"
              >
                <v-icon v-show="!showConfirmPassword" tabindex="-1" @click="showConfirmPassword = !showConfirmPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showConfirmPassword" tabindex="-1" @click="showConfirmPassword = !showConfirmPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Riga Actions -->
      <v-row v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)">
        <v-col cols="12">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="submitAgent"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn
            v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR) && isEdit"
            :class="getButtonMargin"
            color="primary"
            @click="showResetPassword = !showResetPassword"
          >
            <v-icon left>
              mdi-lock
            </v-icon>
            <span v-if="showResetPassword">Hide Password</span>
            <span v-else>Change Password</span>
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isEdit && form.submitLoading" @click.prevent="deleteAgent">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
          <v-btn
            v-if="canBeKicked && hasRequiredRole($store.getters.getRoles.ADMIN)"
            :key="rerender + 'kick_btn'"
            class="float-right mt-2"
            color="error"
            :loading="kickLoading"
            @click.prevent="kickAgent"
          >
            <v-icon left>
              mdi-radioactive
            </v-icon>
            <span>Force Logout</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "@/event-bus";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import KickModal from "./KickModal";
import merge from "deepmerge";
import CopyToClipboard from "../../components/other/CopyToClipboard";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "AgentForm",
  components: {
    ConfirmDialog,
    CopyToClipboard,
    KickModal,
  },
  props: ["agent", "profiles", "agents", "permissions"],
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Agent " + this.agent.authentication.credentials.username + " will be deleted!",
      showConfirmKick: false,
      kickMessage: "Agent " + this.agent.authentication.credentials.username + " will be logged out",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.agent.authentication.credentials.username !== "",
      //Flag gestione della form
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      //change password
      showAdminPsw: false,
      showPswToReset: false,
      showResetPassword: false,
      showConfirmPassword: false,
      resetPassword: {
        adminPassword: "",
        newAgentPassword: "",
        confirmNewPassword: "",
      },
      //Campi di utility
      kickLoading: false,
      showPassword: false,
      showPasswordRetype: false,
      retypePassword: "",
      editedAgent: {
        _id: this.agent._id,
        eri: this.agent.eri,
        authentication: {
          type: "basic",
          credentials: {
            username: this.agent.authentication.credentials.username,
            email: this.agent.authentication.credentials.email,
            password: this.agent.authentication.credentials.password,
            expiration_months: this.agent.authentication.credentials.expiration_months,
            realm: this.agent.authentication.credentials.realm,
          },
        },
        info: {
          name: this.agent.info.name,
          surname: this.agent.info.surname,
        },
        roles: this.agent.roles,
      },
      customErrorMessage: "Passwords doesn't match",
      rerender: 0,
    };
  },
  computed: {
    ...spacing,
    canBeKicked() {
      this.rerender;
      return this.isEdit && this.agent.status;
    },
    getSelectedProfile: {
      get() {
        let roles = this.agent.roles;
        let agentProfile = "";
        if (roles?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.profile) {
          agentProfile = roles[this.getRolesInfo("role")].resources[this.getRolesInfo("resource")]?.profile;
        }
        let result = this.profiles.find((el) => el.uuid === agentProfile.split(":")[4]);
        if (result) {
          return agentProfile;
        } else {
          if (roles?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.profile) {
            return (roles[this.getRolesInfo("role")].resources[this.getRolesInfo("resource")].profile = "");
          }
          return null;
        }
      },
      set(val) {
        const roleInfo = this.getRolesInfo("role");
        const resourceInfo = this.getRolesInfo("resource");
        this.editedAgent.roles[roleInfo].resources[resourceInfo].profile = val;
      },
    },
    getSelectedPermission: {
      get() {
        let roles = this.agent.roles;
        let agentPerm = roles?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.permission_set || "";
        let result = this.permissions.find((el) => el.uuid === agentPerm.split(":")[4]);
        if (result) {
          return agentPerm;
        } else {
          if (roles?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.permission_set) {
            return (roles[this.getRolesInfo("role")].resources[this.getRolesInfo("resource")].permission_set = "");
          }
          return null;
        }
      },
      set(val) {
        const roleInfo = this.getRolesInfo("role");
        const resourceInfo = this.getRolesInfo("resource");
        this.editedAgent.roles[roleInfo].resources[resourceInfo].permission_set = val;
      },
    },
    resetPasswordsEqual() {
      return this.resetPassword.newAgentPassword === this.resetPassword.confirmNewPassword;
    },
    profileListItems() {
      let result = [];
      result = this.profiles.map((profile) => {
        return { text: profile.name, value: profile.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    permissionsListItems() {
      let result = [];
      result = this.permissions.map((perm) => {
        return { text: perm.name, value: perm.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    getAgentDisableFlag: {
      get() {
        return !this.editedAgent.disabled;
      },
      set() {
        return !this.editedAgent.disabled;
      },
    },
  },
  async mounted() {
    this.editedAgent = merge(this.editedAgent, this.agent);
    if (this.$refs.createAgentForm) this.$refs.createAgentForm.resetValidation();
    EventBus.$on("updateAgentStatus", this.updateAgentStatus);
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    updateAgentStatus(agentStatus) {
      const status = agentStatus[this.agent.id];
      this.agent.status = status;
      this.rerender++;
    },
    onKickAgentConfirm(type) {
      try {
        this.$httpAuth.post("/agent/ws-utility/kickAgent", {
          agentERI: this.editedAgent.eri,
          tenantERI: this.$store.getters.getSelectedCompany,
          type,
        });
        EventBus.$emit(this.$store.getters.getEvents.KICK_AGENT, {
          message: "Force logout message sent to " + this.editedAgent.authentication.credentials.username,
          eri: this.editedAgent.eri,
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.KICK_AGENT_FAIL, {
          message: "Fail to send force logout to " + this.editedAgent.authentication.credentials.username,
        });
      } finally {
        this.kickLoading = false;
        this.showConfirmKick = false;
      }
    },
    kickAgent() {
      this.kickLoading = true;
      this.showConfirmKick = true;
    },
    onEmailBlur() {
      const email = this.editedAgent.authentication.credentials.email;
      if (this.isValidEmail(email) && !this.editedAgent.authentication.credentials.username) {
        this.editedAgent.authentication.credentials.username = email.substring(0, email.indexOf("@"));
      }
    },
    customCharsGreaterThanAndLessThan(value, min, max) {
      if (!value) return true;
      return this.charsGreaterThanAndLessThan(value, min, max);
    },
    isAgentUniqueUsername(value) {
      let compare = value;
      let errorMessage = "This agent already exists";
      if (this.isEdit) return true;
      if (!this.editedAgent.authentication.credentials.username) return true;
      let findAgent = this.agents.find((a) => a.authentication.credentials.username === compare);
      if (findAgent) {
        return errorMessage;
      }
      return true;
    },
    getRolesInfo(type) {
      switch (type) {
        case "role":
          return this.$store.getters.getProducts.CONVYAI;
        case "resource":
          return `${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`;
      }
    },
    async submitAgent() {
      if (this.form.isValid) {
        if (this.isEdit || this.editedAgent.authentication.credentials.password === this.retypePassword) {
          this.form.submitLoading = true;
          try {
            const response = await this.sendRequest();
            if (this.showResetPassword) {
              try {
                await this.sendChangePasswordRequest();
                EventBus.$emit(this.$store.getters.getEvents.RESET_AGENT_PASSWORD, {
                  agent: response.data,
                });
              } catch (e) {
                EventBus.$emit(this.$store.getters.getEvents.RESET_AGENT_PASSWORD_FAIL, {
                  message: "Fail to change password " + this.editedAgent.authentication.credentials.username,
                });
              }
            }
            //Avviso la navbar di aggiornarsi
            EventBus.$emit(this.$store.getters.getEvents.EDIT_AGENT, {
              agent: response.data,
            });
          } catch (err) {
            if (err.response.status === 409) {
              EventBus.$emit(this.$store.getters.getEvents.EDIT_AGENT_FAIL, {
                message: "Agent " + this.editedAgent.authentication.credentials.username + " already exist!",
              });
            } else {
              EventBus.$emit(this.$store.getters.getEvents.EDIT_AGENT_FAIL, {
                message: "Fail to edit " + this.editedAgent.authentication.credentials.username,
              });
            }
          } finally {
            this.form.submitLoading = false;
          }
        } else {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_AGENT_FAIL, {
            agent: this.editedAgent,
            message: "Passwords doesn't match",
          });
        }
      }
    },
    async sendChangePasswordRequest() {
      return this.$http.patch("/human-agents/agent/password-reset/" + this.editedAgent._id, {
        adminPassword: this.resetPassword.adminPassword,
        newPassword: this.resetPassword.newAgentPassword,
      });
    },
    sendRequest() {
      //Metodo che fa la richiesta HTTP in base al flag di edit
      if (this.isEdit) {
        return this.$http.put("/human-agents/agent/" + this.editedAgent._id, this.editedAgent);
      }
      if (!this.editedAgent.authentication.credentials.username) {
        const email = this.editedAgent.authentication.credentials.email;
        this.editedAgent.authentication.credentials.username = email.substring(0, email.indexOf("@"));
      }
      delete this.editedAgent.eri;
      delete this.editedAgent._id;
      return this.$http.post("/human-agents/agent", this.editedAgent);
    },
    async deleteAgent() {
      if (this.agent.authentication.credentials.username) {
        this.showConfirm = true;
      } else {
        //Caso di delete su utente in fase di creazione
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_AGENT_CREATION);
      }
    },
    async onDeleteAgentConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/human-agents/agent/" + this.agent._id);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_AGENT, {
          username: this.agent.authentication.credentials.username,
        });
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_AGENT_FAIL, {
          username: this.agent.authentication.credentials.username,
          message: "Fail to delete user " + this.agent.authentication.credentials.username,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
  },
};
</script>
