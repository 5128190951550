<template>
  <v-container id="indexingPage" fluid :class="getCreationContainerMargin">
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Knowledge Base Indexes Batch"
      @input="
        searchString = $event;
        forceRender++;
        pageNumber = 1;
      "
    />

    <v-col class="px-7" cols="12">
      <v-alert type="info" icon="mdi-information" color="primary" class="mx-1">
        <v-row class="no-gutters">
          <v-col cols="12">
            Define one or more indexes to start populating your Knowledge Base.
          </v-col>
        </v-row>
        <v-row class="no-gutters">
          <v-col cols="12">
            All the indexed information will be available to your bots for quick searches to answer your customer questions.
          </v-col>
        </v-row>
      </v-alert>
    </v-col>

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && openAiConfig.length === 0" cols="12">
        <v-alert type="warning">
          You need to configure one or more OpenAi keys before indexing data
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getIndexesList.length == 0 && !newConfig && searchString == ''" cols="12">
        <v-alert type="warning">
          No index batch configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getIndexesList.length == 0 && !newConfig && searchString != ''" cols="12">
        <v-alert type="warning">
          No index batch match your search
        </v-alert>
      </v-col>
      <!-- Panel for new item -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Index Batch
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <IndexingForm :IndexingsList="indexingList" :IndexingSetting="newConfig" :openAiConfig="openAiConfig" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel to list configuration -->

      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(indexsetting, id) in getIndexesList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col class="text-no-wrap" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ indexsetting.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title> {{ indexsetting.knowledge_base_configuration.type }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip small class="pa-2 pl-0">
                          <v-icon
                            v-if="getStatus(indexsetting.uuid).status != 'RUNNING'"
                            :color="getColorByStatus(indexsetting.uuid)"
                          >
                            mdi-circle-medium
                          </v-icon>
                          <v-icon v-else :color="getColorByStatus(indexsetting.uuid)" size="16" class="ml-1 mr-1">
                            mdi-play
                          </v-icon>

                          {{ getStatus(indexsetting.uuid).status }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="indexsetting.lastUpdate">
                        {{ indexsetting.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <IndexingForm
              :key="forceRender + id + '_sc'"
              :IndexingsList="indexingList"
              :IndexingSetting="indexsetting"
              :statusObj="getStatus(indexsetting.uuid)"
              :openAiConfig="openAiConfig"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filteredIndexingList.length > 1"
      :items="filteredIndexingList.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRender++;
      "
      @updatePageSize="updatePageSize($event)"
    />
    <AddNewItemButton name="Index Batch" @addNewItem="addNewConfig" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import IndexingForm from "../../components/knowledgebase/IndexingForm";
import Pagination from "../../components/other/Pagination";
import timeoutHelper from "@/helpers/timeout";

export default {
  name: "Indexing",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    IndexingForm,
    Pagination,
  },
  data() {
    return {
      interval: null,
      indexingList: [],
      openAiConfig: [],
      indexingStatuses: {},
      filteredIndexingList: [],
      expansionPanelCreation: null,
      expansionPanel: null,
      forceRender: 0,
      searchString: "",
      //Data bind per creazione nuova ocnfigurazione
      newConfig: null,
      showWarning: false,
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
    };
  },
  computed: {
    ...spacing,
    getIndexesList() {
      this.forceRender;
      let filterData = this.filterData();
      this.scrollToTop();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("knowledgeBase")) {
      this.$router.push("/");
    }
    EventBus.$on(this.$store.getters.getEvents.CANCEL_INDEXING_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_INDEXING, this.editIndexing);
    EventBus.$on(this.$store.getters.getEvents.EDIT_INDEXING_FAIL, this.editIndexingFail);
    EventBus.$on(this.$store.getters.getEvents.DELETE_INDEXING, this.deleteIndexing);
    EventBus.$on(this.$store.getters.getEvents.DELETE_INDEXING_FAIL, this.deleteIndexingFail);
    EventBus.$on(this.$store.getters.getEvents.INDEXING_START, this.startIndexing);
    EventBus.$on(this.$store.getters.getEvents.INDEXING_START_FAIL, this.startIndexingFail);
    EventBus.$on(this.$store.getters.getEvents.INDEXING_STOP, this.startIndexing);
    EventBus.$on(this.$store.getters.getEvents.INDEXING_STOP_FAIL, this.startIndexingFail);
    try {
      await this.loadStatuses();
      await this.loadOpenAiConfig();
      const result = await this.$http.get("/knowledge-base/indexing");
      result.data.forEach((indexBatch) => {
        if (!this.keyStillExists(indexBatch.openai_configuration.key)) {
          indexBatch.openai_configuration.key = "";
        }
        this.indexingList.push(indexBatch);
      });
    } catch (e) {
      this.indexingList = [];
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
    this.interval = setInterval(async () => {
      await this.loadStatuses();
    }, 30000);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    EventBus.$off(this.$store.getters.getEvents.CANCEL_INDEXING_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_INDEXING, this.editIndexing);
    EventBus.$off(this.$store.getters.getEvents.EDIT_INDEXING_FAIL, this.editIndexingFail);
    EventBus.$off(this.$store.getters.getEvents.DELETE_INDEXING, this.deleteIndexing);
    EventBus.$off(this.$store.getters.getEvents.DELETE_INDEXING_FAIL, this.deleteIndexingFail);
    EventBus.$off(this.$store.getters.getEvents.INDEXING_START, this.startIndexing);
    EventBus.$off(this.$store.getters.getEvents.INDEXING_START_FAIL, this.startIndexingFail);
    EventBus.$off(this.$store.getters.getEvents.INDEXING_STOP, this.startIndexing);
    EventBus.$off(this.$store.getters.getEvents.INDEXING_STOP_FAIL, this.startIndexingFail);
  },
  methods: {
    ...scroller,
    keyStillExists(key) {
      const find = this.openAiConfig.find((k) => k.uuid === key);
      if (find) {
        return true;
      }
      return false;
    },
    async loadOpenAiConfig() {
      try {
        const result = await this.$http.get("/knowledge-base/settings");
        this.openAiConfig = result.data.openai;
      } catch (e) {
        this.openAiConfig = {};
      }
    },
    async loadStatuses() {
      try {
        const statusResponse = await this.$httpAuth.get("/knowledge-base/indexing/status");
        this.indexingStatuses = statusResponse.data.result;
      } catch (e) {
        this.indexingStatuses = {};
      }
    },
    getStatus(uuid) {
      return this.indexingStatuses[uuid] || { status: "N/A" };
    },
    async startIndexing(obj) {
      await this.loadStatuses();
      this.$refs.resultSnackbar?.showSuccess(obj.message);
    },
    startIndexingFail(obj) {
      this.$refs.resultSnackbar?.showError(obj.message);
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewConfig() {
      if (!this.newConfig) {
        this.newConfig = {
          eri: null,
          uuid: null,
          name: "",
          knowledge_base_configuration: {},
          openai_configuration: {},
          database_configuration: {},
          params: {
            slit_text_chars: "\\n\\n",
            token_per_chunk: 500,
            encoding_model: "gpt-4o",
            embedding_engine: "text-embedding-3-small",
            completion_engine: "gpt-4o",
            max_token_completion: "1000",
            temperature: 0.7,
            top_p: 1,
            frequency_penality: 0.0,
            presence_penality: 0.0,
            embedding_dim: 1536,
            token_overlap: 50,
          },
          createdAt: "",
          lastUpdate: "",
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
    },
    async editIndexing(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      this.closeAllPanels();
      const index = this.indexingList.findIndex((i) => i.uuid === obj.editConfig.uuid);
      if (index >= 0) {
        this.indexingList[index] = obj.editConfig;
      } else {
        this.indexingList.push(obj.editConfig);
        await this.loadStatuses();
      }
      this.forceRender++;
    },
    editIndexingFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteIndexing(obj) {
      const index = this.indexingList.findIndex((i) => i.uuid === obj.editConfig.uuid);
      if (index >= 0) {
        this.indexingList.splice(index, 1);
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        if (this.getIndexesList.length == 0 && this.pageNumber > 1) {
          this.pageNumber--;
        }
        setTimeout(() => {
          this.forceRender++;
        }, 100);
      }
    },
    deleteIndexingFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    filterData() {
      this.filteredIndexingList = [].concat(this.indexingList);
      if (this.searchString) {
        this.filteredIndexingList = this.filteredIndexingList.filter((i) => i.name.includes(this.searchString));
      }
      //Sorting per un campo
      this.filteredIndexingList.sort((a, b) => {
        if (a.lastUpdate.toLowerCase() > b.lastUpdate.toLowerCase()) {
          return 1;
        }
        if (a.lastUpdate.toLowerCase() < b.lastUpdate.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return this.filteredIndexingList;
    },
    getColorByStatus(uuid) {
      if (this.getStatus(uuid).status == "READY") {
        return "blue";
      } else if (this.getStatus(uuid).status == "COMPLETED") {
        return "green";
      } else if (this.getStatus(uuid).status == "RUNNING") {
        return "orange";
      } else if (this.getStatus(uuid).status == "CANCELED") {
        return "red";
      } else return "grey";
    },
  },
};
</script>

<style>
#indexingPage .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#indexingPage .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
