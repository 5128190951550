<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeletScriptConfirm" @dialogDeny="showConfirm = false" />

    <SearchBar
      :searchString="searchString"
      title="Execute or schedule Watchbot Script"
      @input="
        searchString = $event;
        forceRerender++;
      "
    />
    <!--modale per vedere i dettagli di script failed -->
    <WatchbotFailedScript
      :visible="showScriptFailedModal"
      :watchbots="watchbots"
      :failedExecutionSelected="failedExecutionSelected"
      @close="showScriptFailedModal = false"
      @saveNewScript="saveEditedScript"
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(watchbot, id) in getWatchbotList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" no-gutters>
                <v-col cols="6" sm="6" md="4" lg="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Script Name</v-list-item-subtitle>
                      <v-list-item-title>{{ watchbot.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-tooltip v-if="watchbot.messages" top color="error">
                      <template #activator="{ on }">
                        <v-list-item-icon class="mt-4" v-on="on">
                          <v-icon color="error">
                            warning
                          </v-icon>
                        </v-list-item-icon>
                      </template>
                      <span> This script can not be executed because has been created with an old version of ConvyAdmin </span>
                    </v-tooltip>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate" cols="6" md="4" lg="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Owner</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ watchbot.owner }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down text-truncate" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Bot</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ watchbot.bot.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-md-and-down" cols="3">
                  <v-list-item dense>
                    <v-list-item-icon v-if="watchbot.executions && watchbot.executions.length > 0" class="mt-6">
                      <v-icon v-if="watchbot.executions[watchbot.executions.length - 1].result" color="success">
                        mdi-thumb-up
                      </v-icon>
                      <v-icon v-else color="error">
                        mdi-thumb-down
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-icon v-else class="mt-4">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon color="grey" v-on="on">
                            mdi-thumbs-up-down
                          </v-icon>
                        </template>
                        <span>This script was never executed</span>
                      </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Execution</v-list-item-subtitle>
                      <v-list-item-title v-if="watchbot.executions && watchbot.executions.length > 0">
                        {{ watchbot.executions[watchbot.executions.length - 1].date | formatVerboseDateTime }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <v-form v-model="form.isValid" :class="getCreationFormMargin" class="mt-6">
              <v-row :class="getFormRowMargin">
                <!-- Colonna con i risultati degli script -->
                <v-col cols="12" lg="6">
                  <v-data-table
                    id="allScript"
                    must-sort
                    :headers="headers"
                    :items="watchbot.executions"
                    disable-pagination
                    fixed-header
                    height="300"
                    hide-default-footer
                    sort-by="date"
                    sort-desc
                    dense
                  >
                    <template #body="{ items }">
                      <tr v-for="(item, index) in items" :key="index" :class="!item.result ? 'pointer' : ''" @click="showFailedScriptDetail(item)">
                        <td>{{ item.type }}</td>
                        <td>{{ item.user }}</td>
                        <td>{{ item.date | formatVerboseDateTime }}</td>
                        <td>
                          <v-icon v-if="item.result" color="success" class="my-1 mx-2">
                            mdi-thumb-up
                          </v-icon>
                          <v-btn v-else text icon color="error">
                            <v-icon>mdi-thumb-down</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- Riga Actions -->
                  <v-row :class="getFormRowMargin">
                    <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
                      <v-btn
                        :class="getButtonMargin"
                        color="success"
                        :loading="savingScriptLoading"
                        @click="saveScript(watchbot)"
                      >
                        <v-icon left>
                          mdi-floppy
                        </v-icon>Save
                      </v-btn>
                      <v-btn
                        :class="getButtonMargin"
                        color="primary"
                        :disabled="watchbot.messages != null"
                        :loading="executeButtonLoading"
                        @click.prevent="executeScript(watchbot.name)"
                      >
                        <v-icon left>
                          mdi-run-fast
                        </v-icon>Execute
                      </v-btn>
                      <v-btn :class="getButtonMargin" color="error" @click.prevent="showConfirmDialog(watchbot.name)">
                        <v-icon left>
                          mdi-delete
                        </v-icon>Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <!--Initial Variables -->
                <v-col cols="12" lg="6">
                  <InitialVariables
                    :key="'initvar' + forceRerender"
                    :script="watchbot.name"
                    :initialVariables="initialVariables[watchbot.name]"
                    @update:initialVariables="updateInitialVariables(watchbot.name, $event)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row :class="getMainRowMarginNoBottom">
        <v-btn v-if="getWatchbotList.length > 1" :class="getButtonMargin" color="primary" :loading="loadingRunAll" @click="runAllScript">
          <v-icon left>
            mdi-run-fast
          </v-icon>Run All
        </v-btn>
        <v-btn v-if="getWatchbotList.length != 0" :class="getButtonMargin" color="primary" to="/scheduledWatchbot">
          <v-icon left>
            mdi-calendar-clock
          </v-icon>Schedule Watchbot
        </v-btn>
        <v-col v-if="showAlertNoWatchbot && searchString == ''" cols="12">
          <v-alert type="warning">
            No Watchbot scripts configured
          </v-alert>
        </v-col>
        <v-col v-else-if="showAlertNoWatchbot && searchString != ''" cols="12">
          <v-alert type="warning">
            No Watchbot script match your search
          </v-alert>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import WatchbotFailedScript from "../../components/tools/WatchbotFailedScript";
import InitialVariables from "../../components/design/InitialVariables";
import merge from "deepmerge";

export default {
  name: "Watchbot",
  components: {
    ResultSnackbar,
    SearchBar,
    ConfirmDialog,
    WatchbotFailedScript,
    InitialVariables,
  },
  data() {
    return {
      showScheduleForm: false,
      company: this.$store.getters.getSelectedCompany,
      watchbots: [],
      bots: {},
      expansionPanel: null, //Indice del tab aperto
      forceRerender: 0,
      searchString: "",
      executeButtonLoading: false,
      loadingRunAll: false,
      // per visualizzare script falliti e modificarli/salvare il nuovo script
      showScriptFailedModal: false,
      failedExecutionSelected: {},
      // initvariables
      initialVariables: {},
      savingScriptLoading: false,
      //
      showConfirm: false,
      selectedScriptId: -1,
      deleteMessage: "This action will delete the script and can not be undone",

      form: {
        isValid: false,
      },

      headers: [
        { text: "Type", sortable: false, align: "left", value: "type" },
        { text: "User", sortable: false, align: "left", value: "user" },
        { text: "Date", sortable: true, align: "left", value: "date" },
        { text: "Result", sortable: false, align: "left", value: "result" },
      ],

      showAlertNoWatchbot: false,
    };
  },
  computed: {
    ...spacing,
    getWatchbotList() {
      this.forceRerender;
      return (
        this.watchbots
          //Filtro per la ricerca
          .filter(
            (script) =>
              script.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
              script.bot.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
              script.owner.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
          )
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
          .map((watchbot) => {
            watchbot.bot = this.bots[watchbot.bot_id];
            if (!watchbot.bot) {
              watchbot.bot = {
                name: "Deleted bot!",
              };
            }
            return watchbot;
          })
      );
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.NOTIFICATION, this.onNotificationReceived);
    EventBus.$on(this.$store.getters.getEvents.RELOAD_DATA, this.loadData);
    //Load Data...
    this.loadData();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.RELOAD_DATA, this.loadData);
  },
  methods: {
    ...scroller,
    updateInitialVariables(name, vars) {
      this.initialVariables[name] = vars;
      this.forceRerender++;
    },
    //questo metodo serve a stoppare il loading quando la navbar riceve la notifica
    onNotificationReceived(newNotifications) {
      newNotifications = newNotifications.filter((obj) => obj.type === "watchbot");
      if (newNotifications?.length > 0) {
        this.loadingRunAll = false;
        this.executeButtonLoading = false;
        this.loadData();
      }
    },
    // questo metodo permette di mostrare la modale di dettaglio degli script che sono falliti
    showFailedScriptDetail(execution) {
      if (!execution.result && execution.actualExecutedNodes) {
        this.failedExecutionSelected = merge({}, execution);
        let watchbotId = this.getScriptIdByName(execution.name);
        this.failedExecutionSelected.flow = this.watchbots[watchbotId].flow;
        this.failedExecutionSelected.version = this.watchbots[watchbotId].version;
        this.failedExecutionSelected.executedNodes = [].concat(this.watchbots[watchbotId].executedNodes);
        this.showScriptFailedModal = true;
      }
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    saveEditedScript() {
      this.showScriptFailedModal = false;
      let watchbotID = this.getScriptIdByName(this.failedExecutionSelected.name);
      this.watchbots[watchbotID].executedNodes = [].concat(this.failedExecutionSelected.executedNodes);
      this.saveScript(this.watchbots[watchbotID]);
    },
    async saveScript(watchbot) {
      this.savingScriptLoading = true;
      let script = {
        ...watchbot,
      };
      delete script.bot;
      script.user = this.$store.getters.getUsername;
      let newInitVariables = {};
      this.initialVariables[watchbot.name].forEach((variable) => {
        newInitVariables[variable.key] = variable.value;
      });
      script.initialVariables = newInitVariables;
      try {
        await this.$http.put("/watchbot/" + script.name, script);
        this.$refs.resultSnackbar.showSuccess("The script has been updated!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("The script can not be changed!");
      } finally {
        this.savingScriptLoading = false;
      }
    },
    async loadData() {
      try {
        const resultBot = await this.$http.get("/bot");
        this.bots = resultBot.data;
        const result = await this.$http.get("/watchbot");
        this.watchbots = [];
        this.initialVariables = {};
        for (let scriptName in result.data) {
          this.watchbots.push({
            name: scriptName,
            ...result.data[scriptName],
          });
          this.initialVariables[scriptName] = [];
          for (let y in result.data[scriptName].initialVariables) {
            this.initialVariables[scriptName].push({
              key: y,
              value: result.data[scriptName].initialVariables[y],
            });
          }
        }
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to load Scripts!");
      } finally {
        if (this.getWatchbotList.length === 0) {
          this.showAlertNoWatchbot = true;
        }
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      }
    },
    async runAllScript() {
      this.loadingRunAll = true;
      try {
        await this.$http.post("/watchbot/run-all");
        this.$refs.resultSnackbar.showSuccess("Script batch is running...");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Script batch can not run!");
      }
    },
    async executeScript(scriptName) {
      this.executeButtonLoading = true;
      try {
        await this.$http.post(`/watchbot/run/${scriptName}`);
        this.$refs.resultSnackbar.showSuccess("Script " + scriptName + " is running...");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Script " + scriptName + " can not run!");
      }
    },
    closeAllPanels() {
      this.expansionPanel = null;
    },
    showConfirmDialog(scriptName) {
      let id = this.getScriptIdByName(scriptName);
      this.selectedScriptId = id;
      this.showConfirm = true;
    },
    getScriptIdByName(scriptName) {
      for (let i = 0; i < this.watchbots.length; i++) {
        if (this.watchbots[i].name == scriptName) {
          return i;
        }
      }
      return -1;
    },
    async onDeletScriptConfirm() {
      if (this.selectedScriptId >= 0) {
        let script = this.watchbots[this.selectedScriptId];
        try {
          await this.$http.delete(`/watchbot/${script.name}`);
          this.$refs.resultSnackbar.showSuccess("Script " + script.name + " deleted!");
          this.watchbots.splice(this.selectedScriptId, 1);
          this.forceRerender++;
          this.expansionPanel = null;
          EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
        } catch (e) {
          this.$refs.resultSnackbar.showError("Fail to delete " + script.name + "!");
        } finally {
          this.showConfirm = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer !important;
}
#allScript.v-data-table {
  height: 84% !important;
}
</style>
