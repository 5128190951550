import merge from "deepmerge";

export default {
  getDialogFlowResponse,
  getLexResponse,
  getLuisResponse,
  getCogitoResponse,
  getWatsonResponse,
  getEudataResponse,
  getOpenAiResponse,
  getCustomEngineResponse,
  getBedrockResponse,
};

var formatted = {
  intents: [],
  entities: [],
};

function getOpenAiResponse(parsedJson) {
  const resp = {
    intents: [],
    entities: [],
    response: parsedJson["answer"],
    language: parsedJson["language-code"] || parsedJson["language"],
    sentiment: parsedJson["sentiment"],
  };
  if (parsedJson.intents && parsedJson.intents.length > 0) {
    parsedJson.intents.forEach((intentObj) => {
      const intent = Object.keys(intentObj)[0];
      resp.intents.push({ label: intent, score: intentObj[intent], name: null });
    });
  }
  if (parsedJson["entities"] && parsedJson["entities"].length > 0) {
    parsedJson["entities"].forEach((entityObj) => {
      const entity = Object.keys(entityObj)[0];
      resp.entities.push({ name: entity, value: entityObj[entity] });
    });
  }
  if (parsedJson.intent) {
    resp.intents.push({ label: parsedJson.intent, score: parsedJson.score, name: null });
  }
  if (parsedJson.entity && parsedJson.entityValue) {
    resp.entities.push({ name: parsedJson.entity, value: parsedJson.entityValue });
  }
  return resp;
}

function getBedrockResponse(parsedJson) {
  const resp = {
    intents: [],
    entities: [],
    response: parsedJson["answer"],
    language: parsedJson["language-code"] || parsedJson["language"],
    sentiment: parsedJson["sentiment"],
  };
  if (parsedJson.intents && parsedJson.intents.length > 0) {
    parsedJson.intents.forEach((intentObj) => {
      const intent = Object.keys(intentObj)[0];
      resp.intents.push({ label: intent, score: intentObj[intent], name: null });
    });
  }
  if (parsedJson["entities"] && parsedJson["entities"].length > 0) {
    parsedJson["entities"].forEach((entityObj) => {
      const entity = Object.keys(entityObj)[0];
      resp.entities.push({ name: entity, value: entityObj[entity] });
    });
  } else if (parsedJson["entities"] && parsedJson["entities"].entity) {
    const name = parsedJson.entities.entity.type || parsedJson.entities.entity["@_name"];
    const value = parsedJson.entities.entity.value || parsedJson.entities.entity.text || parsedJson.entities.entity["@_type"];
    if (value && name) {
      resp.entities.push({ name, value });
    }
  } else if (parsedJson["entities"] && parsedJson["entities"]["entity-name"]) {
    if (Array.isArray(parsedJson["entities"]["entity-name"])) {
      parsedJson["entities"]["entity-name"].forEach((entityName, index) => {
        if (entityName && parsedJson["entities"]["entity-value"][index]) {
          resp.entities.push({ name: entityName, value: parsedJson["entities"]["entity-value"][index] });
        }
      });
    } else {
      const name = parsedJson["entities"]["entity-name"];
      const value = parsedJson["entities"]["entity-value"];
      if (value && name) {
        resp.entities.push({ name, value });
      }
    }
  }
  if (parsedJson.intent) {
    if (parsedJson.intent["intent-name"]) {
      resp.intents.push({ label: parsedJson.intent["intent-name"], score: parsedJson.intent["intent-score"], name: null });
    } else {
      resp.intents.push({ label: parsedJson.intent, score: parsedJson.score, name: null });
    }
  }
  return resp;
}

function getCustomEngineResponse(response) {
  const resp = {
    intents: [],
    entities: [],
    response: response["answer"],
    language: response["language-code"] || response["language"],
    sentiment: response["sentiment"],
  };
  if (response.intents && response.intents.length > 0) {
    response.intents.forEach((intentObj) => {
      const intent = Object.keys(intentObj)[0];
      resp.intents.push({ label: intent, score: intentObj[intent], name: null });
    });
  }
  if (response["entities"] && response["entities"].length > 0) {
    response["entities"].forEach((entityObj) => {
      const entity = Object.keys(entityObj)[0];
      resp.entities.push({ name: entity, value: entityObj[entity] });
    });
  }
  if (response.intent) {
    resp.intents.push({ label: response.intent, score: response.score, name: null });
  }
  if (response.entity && response.entityValue) {
    resp.entities.push({ name: response.entity, value: response.entityValue });
  }
  return resp;
}

function getDialogFlowResponse(response) {
  let responseFormatted = merge({}, formatted);
  if (response.intent) {
    responseFormatted.intents[0] = {
      label: response.intent.displayName,
      score: Math.round(response.intentDetectionConfidence * 1000) / 10 + "%",
      name: null,
    };
  }
  for (let entity in response.parameters.fields) {
    if (
      response.parameters.fields[entity] &&
      response.parameters.fields[entity].listValue &&
      response.parameters.fields[entity].listValue.values &&
      response.parameters.fields[entity].listValue.values.length > 0
    ) {
      responseFormatted.entities.push({
        name: entity,
        value: response.parameters.fields[entity].listValue.values[0].stringValue,
      });
    }
  }
  return responseFormatted;
}

function getLexResponse(response) {
  let responseFormatted = merge({}, formatted);
  if (response.intentName) {
    responseFormatted.intents[0] = {
      label: response.intentName,
      score: null,
      name: null,
    };
  } else if (response.interpretations && response.interpretations.length > 0) {
    responseFormatted.intents[0] = {
      label: response.interpretations[0].intent.name,
      score: response.interpretations[0].nluConfidence.score * 100 + "%",
      name: null,
    };
    for (let entity in response.interpretations[0].intent.slots) {
      if (response.interpretations[0].intent.slots[entity] != null) {
        responseFormatted.entities.push({
          name: entity,
          value: response.interpretations[0].intent.slots[entity].value.interpretedValue,
        });
      }
    }
  }
  for (let entity in response.slots) {
    if (response.slots[entity] != null) {
      responseFormatted.entities.push({
        name: entity,
        value: response.slots[entity],
      });
    }
  }
  return responseFormatted;
}

function getLuisResponse(response) {
  let responseFormatted = merge({}, formatted);
  responseFormatted.intents = [];
  if (response.prediction && response.prediction.intents) {
    for (const intent in response.prediction.intents) {
      responseFormatted.intents.push({
        label: intent,
        score: Math.round(response.prediction.intents[intent].score * 100 * 100) / 100 + "%",
        name: intent,
      });
    }
  }
  if (response.prediction.entities) {
    for (let entity in response.prediction.entities) {
      if (entity !== "$instance") {
        for (let entry of response.prediction.entities[entity]) {
          responseFormatted.entities.push({
            name: entity,
            value: entry.values[0].resolution[0].value,
          });
        }
      }
    }
  }
  return responseFormatted;
}

function getCogitoResponse(response) {
  let responseFormatted = merge({}, formatted);
  if (response.sec_msg) {
    responseFormatted.intents.push({
      name: response.data,
      label: response.sec_msg,
      score: 0,
    });
    return responseFormatted;
  }
  response.CATEGORIZATION.DOMAIN = merge(response.CATEGORIZATION.DOMAIN || [], response.CLUSTERIZATION ? response.CLUSTERIZATION.DOMAIN : [], {
    arrayMerge: combineMerge,
  });
  for (let i = 0; i < response.CATEGORIZATION.DOMAIN.length; i++) {
    responseFormatted.intents.push({
      name: response.CATEGORIZATION.DOMAIN[i].NAME,
      label: response.CATEGORIZATION.DOMAIN[i].LABEL || response.CATEGORIZATION.DOMAIN[i].NAME,
      score: Math.round(response.CATEGORIZATION.DOMAIN[i].FREQUENCY * 1000) / 1000,
    });
  }
  if (response.EXTRACTION && response.EXTRACTION.RECORD && response.EXTRACTION.RECORD.FIELD) {
    if (Array.isArray(response.EXTRACTION.RECORD.FIELD)) {
      for (let i = 0; i < response.EXTRACTION.RECORD.FIELD.length; i++) {
        responseFormatted.entities.push({
          name: response.EXTRACTION.RECORD.FIELD[i].NAME,
          value: response.EXTRACTION.RECORD.FIELD[i].BASE,
        });
      }
    } else {
      responseFormatted.entities.push({
        name: response.EXTRACTION.RECORD.FIELD.NAME,
        value: response.EXTRACTION.RECORD.FIELD.BASE,
      });
    }
  }
  return responseFormatted;
}

function getWatsonResponse(response) {
  let responseFormatted = merge({}, formatted);
  responseFormatted.intents = [];
  if (response.intents) {
    for (let i = 0; i < response.intents.length; i++) {
      responseFormatted.intents.push({
        label: response.intents[i].intent,
        score: Math.round(response.intents[i].confidence * 1000) / 10 + "%",
        name: null,
      });
    }
  }
  if (response.entities) {
    for (let i = 0; i < response.entities.length; i++) {
      responseFormatted.entities.push({
        name: response.entities[i].entity,
        value: response.entities[i].value,
      });
    }
  }
  return responseFormatted;
}

function getEudataResponse(response) {
  let responseFormatted = merge({}, formatted);
  if (response.intent) {
    responseFormatted.intents.push({
      label: response.intent,
      name: null,
      score: null,
    });
  }
  for (let entity in response.entities) {
    responseFormatted.entities.push({
      name: entity,
      value: response.entities[entity],
    });
  }

  return responseFormatted;
}

const combineMerge = (target, source, options) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === "undefined") {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
    } else if (options.isMergeableObject(item)) {
      destination[index] = merge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};
