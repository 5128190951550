<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <v-row class="mt-4 mx-6">
      <v-col class="d-none d-md-block subtitle-1" cols="0" md="8">
        Select variables to store
      </v-col>
    </v-row>
    <ConfirmDialog :showConfirm="showConfirm" :message="getMessage" @dialogConfirm="onConfirm" @dialogDeny="showConfirm = false" />
    <!-- Cerd information about public entities -->
    <v-alert type="info" outlined text :color="$vuetify.theme.dark ? '#FBFBF7' : 'primary'" class="mx-4 my-5">
      <div class="px-6">
        <span>
          ConvyAI will save only the selected BOT variables on the historical reports. The system variables that will be always stored by default are:
          <ul>
            <li>system_user_id: unique identifier of the customer</li>
            <li>system_user_name: name of the customer</li>
            <li>system_channel: inbound channel used by the customer</li>
            <li>system_connector: unique identifier of the connector used</li>
            <li>system_connector_name: name of the connector used</li>
            <li>system_user_browser: name of the browser used if the customer used the web API</li>
            <li>system_user_browser_version: version of the browser used if the customer used the web API</li>
            <li>system_user_os: operating system used if the customer used the web API</li>
            <li>system_user_webrtc: flag that specify if the web browser has the webrtc capability available</li>
            <li>lang: language code</li>
            <li>domain: domain in which the customer interface is included (only for web interfaces)</li>
            <li>page: page in which the customer interface is included (only for web interfaces)</li>
          </ul>
        </span>
      </div>
    </v-alert>

    <v-card class="mb-12 mx-4">
      <v-expansion-panels v-model="panel" class="mt-6" focusable>
        <v-expansion-panel key="generativeai">
          <v-expansion-panel-header style="max-height: 45px !important">
            GenerativeAI engines variables<span v-if="numberOfVariablesSelectedFromGenerativeAI() > 0">&nbsp;- {{ numberOfVariablesSelectedFromGenerativeAI() }} selected</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div v-if="getGenerativeAIVariables().length == 0">
              No available variables
            </div>
            <v-chip-group
              v-else
              v-model="selectedVariables"
              class="mb-2"
              multiple
              column
              active-class="primary"
              @change="checkMaxVariables"
            >
              <v-chip
                v-for="(variable, index) in getGenerativeAIVariables()"
                :key="'genai_' + index"
                :value="variable.variable"
                class="ma-2"
                filter
                label
                variable
              >
                {{ variable.variable }}
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel key="unassigned">
          <v-expansion-panel-header style="max-height: 45px !important">
            Unassigned Flows variables<span v-if="numberOfVariablesSelectedFromUnassigned() > 0">&nbsp;- {{ numberOfVariablesSelectedFromUnassigned() }} selected</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div v-if="getUnassignedFlowVariables().length == 0">
              No available variables
            </div>
            <v-chip-group
              v-else
              v-model="selectedVariables"
              class="mb-2"
              multiple
              column
              active-class="primary"
              @change="checkMaxVariables"
            >
              <v-chip
                v-for="(variable, index) in getUnassignedFlowVariables()"
                :key="'unass_' + index"
                :value="variable"
                class="ma-2"
                filter
                label
                variable
              >
                {{ variable }}
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-for="bot of getBots" :key="bot.uuid">
          <v-expansion-panel-header style="max-height: 45px !important">
            {{ bot.name }} variables<span v-if="numberOfVariablesSelected(bot) > 0">&nbsp;- {{ numberOfVariablesSelected(bot) }} selected</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div v-if="getBotVariables(bot).length == 0">
              No available variables
            </div>
            <v-chip-group
              v-else
              v-model="selectedVariables"
              class="mb-2"
              multiple
              column
              active-class="primary"
              @change="checkMaxVariables"
            >
              <v-chip
                v-for="(variable, index) in getBotVariables(bot)"
                :key="'bot_' + bot.uuid + index"
                :value="variable"
                class="ma-2"
                filter
                label
                variable
              >
                {{ variable }}
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-row :class="getMainRowMargin" class="mt-n8">
      <!-- Button -->
      <v-btn color="success" :loading="loading" :class="getButtonMargin" @click.prevent="showConfirm = true">
        <v-icon left>
          mdi-floppy
        </v-icon>save
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import EventBus from "../../event-bus";

export default {
  name: "VariablesToStore",
  components: {
    ConfirmDialog,
    ResultSnackbar,
  },
  data() {
    return {
      maxVariable: 50,
      showConfirm: false,
      panel: 0,
      company: this.$store.getters.getSelectedCompany,
      loading: false,
      bots: {},
      selectedVariables: [],
      variables: [],
      systemVariables: [
        "system_user_id",
        "system_user_name",
        "system_channel",
        "system_connector",
        "system_connector_name",
        "system_user_browser",
        "system_user_browser_version",
        "system_user_os",
        "system_user_webrtc",
        "page",
        "domain",
        "lang",
      ],
    };
  },
  computed: {
    ...spacing,
    getBots() {
      return Object.values(this.bots).sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    getMessage() {
      return "The selected variables will be available in the dialog details reports";
    },
  },
  async mounted() {
    try {
      const botResponse = await this.$http.get("/bot-all");
      const flowVars = await this.$http.get("/flows/varsByBot");
      const publishedVars = await this.$http.get(`/company/${this.$store.getters.getSelectedCompany}`);
      if (flowVars) {
        this.variables = flowVars.data;
      }
      if (publishedVars?.data?.variablesToStore) {
        this.selectedVariables = publishedVars.data.variablesToStore;
      }
      if (botResponse) {
        this.bots = botResponse.data;
      }
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail to load data: ${e.message}`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...scroller,
    getGenerativeAIVariables() {
      return this.variables
        .filter((variable) => {
          return variable.engine && !this.systemVariables.includes(variable.variable);
        })
        .sort((a, b) => {
          if (a.variable.toLowerCase() > b.variable.toLowerCase()) {
            return 1;
          }
          if (a.variable.toLowerCase() < b.variable.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    getUnassignedFlowVariables() {
      return this.variables
        .filter((variable) => {
          return (!variable.bot || variable.bot == "0") && !this.systemVariables.includes(variable.variable);
        })
        .map((variable) => variable.variable)
        .sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    getBotVariables(bot) {
      return this.variables
        .filter((variable) => {
          return variable.bot == bot.bot_id && !this.systemVariables.includes(variable.variable);
        })
        .map((variable) => variable.variable)
        .sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    async onConfirm() {
      this.loading = true;
      try {
        await this.$http.put("/company/variable-storage/" + this.$store.getters.getSelectedCompany, this.selectedVariables);
        this.$refs.resultSnackbar.showSuccess("Variables published!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Variables cannot be published: " + e);
      } finally {
        this.loading = false;
        this.showConfirm = false;
      }
    },
    checkMaxVariables(value) {
      if (value.length > this.maxVariable) {
        value.pop();
        this.$refs.resultSnackbar.showError(
          `You reached the maximum number of selectable variable (${this.maxVariable}). You need to deselect at least one before adding something new`,
          0,
        );
      }
    },
    numberOfVariablesSelectedFromUnassigned() {
      const botVariables = this.getUnassignedFlowVariables();
      return botVariables.filter((variable) => this.selectedVariables.includes(variable)).length;
    },
    numberOfVariablesSelectedFromGenerativeAI() {
      const engineVariables = this.getGenerativeAIVariables();
      return engineVariables.filter((variable) => this.selectedVariables.includes(variable.variable)).length;
    },
    numberOfVariablesSelected(bot) {
      const botVariables = this.getBotVariables(bot);
      return botVariables.filter((variable) => this.selectedVariables.includes(variable)).length;
    },
  },
};
</script>
