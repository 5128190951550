<template>
  <v-container fluid class="pb-0 mb-0">
    <ResultSnackbar ref="resultSnackbar" />
    <v-row justify="center" class="mx-9 mt-4 mb-2">
      <v-expansion-panels v-model="panel" multiple :class="getMarginNewCard" readonly>
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate :class="showHelp || showSearch ? 'colorHeader' : ''">
            <v-col cols="11">
              <v-card elevation="3" class="pt-2 pb-4">
                <v-text-field
                  v-model.trim="searchString"
                  tile
                  class="mx-12"
                  hide-details
                  prepend-icon="search"
                  single-line
                  label="Search text..."
                  full-width
                  :autocomplete="$store.getters.disableAutocomplete"
                  dense
                  @input="editIndex = null"
                  @keypress.enter="getCards"
                  @keydown="resetAlert($event)"
                />
              </v-card>
              <v-btn color="primary" class="mt-6" @click="showAdvanced">
                <v-icon left>
                  mdi-crosshairs
                </v-icon>Advanced Search
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" class="mb-12" @click="getCards">
                Search
              </v-btn>
            </v-col>
            <template #actions>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon text class="mb-12 ml-12" v-on="on" @click="getHelp">
                    <v-icon color="primary">
                      help
                    </v-icon>
                  </v-btn>
                </template>
                <span>Need help?</span>
              </v-tooltip>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mb-n4">
            <v-row v-if="showHelp || showSearch" align="center" justify="center" class="spacer mx-8 mt-3 mb-10" no-gutters>
              <v-col v-if="showHelp" cols="12">
                <span class="mx-8 subtitle-1">How to edit bots contents:</span>
                <v-timeline dense>
                  <v-timeline-item
                    icon="mdi-filter-variant"
                    color="primary"
                    small
                    fill-dot
                  >
                    Type at least {{ minStringLength }} character in the above search box to filter the bot phrases
                  </v-timeline-item>
                  <v-timeline-item icon="mdi-crosshairs" color="primary" small fill-dot>
                    Use advanced search to choose from where extract your results.
                    <br>
                    <span style="font-size: 12px">By default, the filter is active on some nodes and on hooks. </span>
                  </v-timeline-item>
                  <v-timeline-item
                    icon="mdi-magnify"
                    color="primary"
                    small
                    fill-dot
                  >
                    You can look for a word in the middle of a sentence or a ordered sequence of words
                  </v-timeline-item>
                  <v-timeline-item icon="mdi-plus" color="primary" small fill-dot>
                    As you add more words, results should become more accurate
                  </v-timeline-item>
                  <v-timeline-item icon="mdi-eye" color="primary" small fill-dot>
                    Look into the results to find the correct sentence to edit
                  </v-timeline-item>
                  <v-timeline-item
                    icon="mdi-pencil"
                    color="primary"
                    small
                    fill-dot
                  >
                    You can fast edit the node directly from this page or go to the flow designer and change the node
                  </v-timeline-item>
                </v-timeline>
              </v-col>
              <v-col v-if="showSearch" cols="12">
                <!-- nodes -->
                <v-col cols="12" class="my-0">
                  <v-row no-gutters>
                    <v-checkbox
                      v-model="elementSelected.allNodes"
                      color="primary"
                      hide-details
                      dense
                      :indeterminate="elementSelected.nodeType.length > 0 && elementSelected.nodeType.length < 12"
                      @change="selectAllNodes"
                    />
                    <header class="ml-8 mt-4 mr-5 subtitle-1" style="width: 150px">
                      Type of nodes
                    </header>
                  </v-row>
                </v-col>
                <v-col cols="11" class="my-0 mx-12 py-0">
                  <v-select
                    v-model="elementSelected.nodeType"
                    class="mx-4 py-0"
                    return-object
                    :items="blockTypeTranslation"
                    label="Select node"
                    multiple
                    clearable
                    dense
                    @change="selectSingleNode"
                  >
                    <template #selection="{ item, index }">
                      <v-chip v-if="index < 9" small class="contentChips" color="primary">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span v-if="index == 9" class="grey--text caption">(+{{ elementSelected.nodeType.length - 9 }} others)</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" class="my-0 py-0">
                  <v-row no-gutters>
                    <v-checkbox v-model="elementSelected.flowConfigurations" color="primary" hide-details dense />
                    <header class="ml-8 mt-4 mr-5 subtitle-1" style="width: 150px">
                      Flow Configurations
                    </header>
                  </v-row>
                </v-col>
                <!-- Hooks -->
                <v-col cols="12" class="my-0">
                  <v-row no-gutters>
                    <v-checkbox
                      v-model="elementSelected.hook"
                      :indeterminate="elementSelected.hookType.length > 0 && elementSelected.hookType.length < blockTypeHook.length"
                      color="primary"
                      hide-details
                      dense
                      @change="selectAllHook"
                    />
                    <header class="ml-8 mt-4 mr-5 subtitle-1" style="width: 150px">
                      Hooks
                    </header>
                  </v-row>
                </v-col>
                <v-col cols="11" class="my-0 mx-12 py-0">
                  <v-select
                    v-model="elementSelected.hookType"
                    class="mx-4 py-0"
                    return-object
                    :items="blockTypeHook"
                    label="Select hook on specific node"
                    multiple
                    clearable
                    dense
                    color="primary"
                    @change="selectSingleHook"
                  >
                    <template #selection="{ item, index }">
                      <v-chip v-if="index < 9" small class="contentChips" color="primary">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span v-if="index == 9" class="grey--text caption">(+{{ elementSelected.hookType.length - 9 }} others)</span>
                    </template>
                  </v-select>
                </v-col>
                <!-- branch menu -->
                <v-col cols="12" class="my-0">
                  <v-row no-gutters>
                    <v-checkbox v-model="elementSelected.branchMenu" color="primary" hide-details dense />
                    <header class="ml-8 mt-4 mr-5 subtitle-1" style="width: 150px">
                      Menu Branches
                    </header>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-responsive v-if="showAlert" class="mx-9">
      <v-fade-transition>
        <v-alert type="warning">
          No results found.
        </v-alert>
      </v-fade-transition>
    </v-responsive>
    <v-responsive v-if="showResults" class="mx-9">
      <v-lazy
        v-for="(card, index) in result"
        :key="index"
        :options="{
          threshold: 0.15,
        }"
        min-height="255"
        class="pa-2 float-left"
        style="width: 50%"
        transition="fade-transition"
      >
        <v-card height="255px" class="py-2 px-4">
          <v-row class="mt-3" style="height: 90px">
            <v-col cols="6" class="my-0 py-0">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-list-item-title class="headline" v-on="on">
                        {{ card.flow }}
                      </v-list-item-title>
                    </template>
                    <span>
                      {{ card.flow }}
                    </span>
                  </v-tooltip>
                  <v-list-item-subtitle>version: {{ card.version }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-list-item four-line class="ma-0 pa-0">
                <v-list-item-content class="ma-0 pa-0">
                  <v-list-item-subtitle>
                    <v-row no-gutters>
                      <v-col cols="3">
                        Bot:
                      </v-col>
                      <v-col cols="9" class="ellipsis" style="width: 250px">
                        <strong>
                          <span v-if="card.botName" class="text-capitalize">{{ card.botName }}</span>
                          <span v-else class="text-capitalize">Unassigned</span>
                        </strong>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row no-gutters>
                      <v-col cols="3">
                        {{ getSectionDetails(card) }}
                      </v-col>
                      <v-col cols="5">
                        <strong class="text-capitalize">{{ getCardDetails(card) }}</strong>
                      </v-col>
                      <v-col v-if="card.uuidNode" cols="2" class="pl-2">
                        Uuid:
                      </v-col>
                      <v-col v-if="card.uuidNode" cols="2">
                        #<strong v-html="card.uuidNode || ''" />
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row no-gutters>
                      <v-col cols="3">
                        Section:
                      </v-col>
                      <v-col cols="9">
                        <strong>
                          <span v-if="card.type == 'Node'" class="text-capitalize">{{ card.typeMessage }}</span>
                          <span v-else class="text-capitalize">{{ card.type }}</span>
                        </strong>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row no-gutters>
                      <v-col v-if="card.lockOwner" cols="3">
                        Locked by:
                      </v-col>
                      <v-col v-if="card.lockOwner" cols="9" class="ellipsis">
                        <strong>
                          <span class="error--text">{{ card.lockOwner }}</span>
                        </strong>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-card-text v-show="index !== editIndex" class="py-0">
            <v-row class="ellipsisRow">
              <v-col v-if="card.flag" xs="1" sm="1'" md="1" lg="1">
                <flag :iso="card.flag" />
              </v-col>
              <v-col :xs="card.flag ? '11' : '12'" :sm="card.flag ? '11' : '12'" :md="card.flag ? '11' : '12'" :lg="card.flag ? '11' : '12'">
                <!-- qui è stato usato un v-html perchè le card sono formattate in html, non si può metter plain text -->
                <div class="ellipsisMultiline ma-0 pa-0" v-html="card.originalText" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-show="index === editIndex" class="py-0">
            <v-row class="ellipsisRow py-0">
              <v-col v-if="card.flag" xs="1" sm="1'" md="1" lg="1">
                <flag :iso="card.flag" />
              </v-col>
              <v-col
                :xs="card.flag ? '11' : '12'"
                :sm="card.flag ? '11' : '12'"
                :md="card.flag ? '11' : '12'"
                :lg="card.flag ? '11' : '12'"
                class="ellipsisMultiline"
              >
                <v-textarea v-model.trim="card.matchedText" class="mt-n3" label="New Text" auto-grow />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR)"
              v-show="index !== editIndex"
              text
              color="primary"
              @click="openFlowDesigner(card)"
            >
              View
            </v-btn>
            <v-btn
              v-show="index !== editIndex && showEditButton(card)"
              text
              color="primary"
              :disabled="card.lockOwner != null || !hasRequiredRole($store.getters.getRoles.SUPERVISOR)"
              @click="editCard(index)"
            >
              Edit
            </v-btn>
            <v-btn v-show="index === editIndex" text color="success" @click="saveNewText(card)">
              Save
            </v-btn>
            <v-btn v-show="index === editIndex" text color="error" @click="closeEditCard()">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-lazy>
    </v-responsive>
  </v-container>
</template>

<script>
import openFD from "../../helpers/openFD";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "ContentEditor",
  components: {
    ResultSnackbar,
  },
  data() {
    return {
      loading: true,
      panel: [0],
      showHelp: true,
      showSearch: false,
      company: this.$store.getters.getSelectedCompany,
      username: this.$store.getters.getUsername,
      minStringLength: 3,
      searchString: "",
      flowList: null,
      renewLockOwnerInterval: null,
      renewLockTimeout: 10000,
      rerender: 0,
      editIndex: null,
      blockTypeTranslation: [
        {
          text: "Buttons",
          value: "buttons",
        },
        {
          text: "Crossroads",
          value: "crossroads",
        },
        {
          text: "Delay",
          value: "delay",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Flow Jump",
          value: "flowjump",
        },
        {
          text: "Form",
          value: "form",
        },
        {
          text: "Geolocation",
          value: "geolocation",
        },
        {
          text: "Handover",
          value: "handover",
        },
        {
          text: "Hook Jump",
          value: "jump",
        },
        {
          text: "Menu",
          value: "menu",
        },
        {
          text: "Message",
          value: "text",
        },
        {
          text: "Multimedia",
          value: "multimedia",
        },
        {
          text: "Question",
          value: "question",
        },
        {
          text: "Send Event",
          value: "sendevents",
        },
        {
          text: "Set Variable",
          value: "setvars",
        },
        {
          text: "Survey",
          value: "survey",
        },
        {
          text: "Upload file",
          value: "file",
        },
        {
          text: "Web Service",
          value: "webserv",
        },
        {
          text: "Whatsapp template",
          value: "whatsapp_template",
        },
      ],
      blockTypeHook: [
        {
          text: "Authentication",
          value: "auth",
        },
        {
          text: "Buttons",
          value: "buttons",
        },
        {
          text: "Crossroads",
          value: "crossroads",
        },
        {
          text: "Delay",
          value: "delay",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "End conversation",
          value: "hangconversation",
        },
        {
          text: "Flow Jump",
          value: "flowjump",
        },
        {
          text: "Form",
          value: "form",
        },
        {
          text: "Geolocation",
          value: "geolocation",
        },
        {
          text: "Handover",
          value: "handover",
        },
        {
          text: "Hook Jump",
          value: "jump",
        },
        {
          text: "Menu",
          value: "menu",
        },
        {
          text: "Message",
          value: "text",
        },
        {
          text: "Multimedia",
          value: "multimedia",
        },
        {
          text: "Question",
          value: "question",
        },
        {
          text: "Send event",
          value: "sendevents",
        },
        {
          text: "Set Variable",
          value: "setvars",
        },
        {
          text: "Survey",
          value: "survey",
        },
        {
          text: "Upload file",
          value: "file",
        },
        {
          text: "Web Service",
          value: "webserv",
        },
        {
          text: "Whatsapp template",
          value: "whatsapp_template",
        },
      ],
      elementSelected: {
        allNodes: false,
        nodeType: [
          {
            text: "Menu",
            value: "menu",
          },
          {
            text: "Message",
            value: "text",
          },
          {
            text: "Question",
            value: "question",
          },
          {
            text: "Survey",
            value: "survey",
          },
        ],
        flowConfigurations: false,
        hook: false,
        hookType: [],
        branchMenu: false,
      },
      result: [],
      showAlert: false,
      showResults: false,
    };
  },
  computed: {
    ...spacing,
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  async mounted() {
    let crabNodes = [
      {
        text: "Merge Contact",
        value: "merge_contact",
      },
      {
        text: "Search Contact",
        value: "search_contact",
      },
      {
        text: "Search Customer Info",
        value: "search_customer_info",
      },
    ];
    this.blockTypeHook.splice(14, 0, ...crabNodes);
    try {
      const response = await this.$http.get("/content-editor");
      this.flowList = response.data;
      this.renewLockOwnerInterval = setInterval(this.renewLockOwners.bind(this), this.renewLockTimeout);
    } catch (e) {
      this.$refs.resultSnackbar.showError("Fal to load flow list!");
    } finally {
      this.loading = false;
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  beforeDestroy() {
    if (this.renewLockOwnerInterval) {
      clearInterval(this.renewLockOwnerInterval);
    }
    this.renewLockOwnerInterval = null;
  },
  methods: {
    ...checkRoleAndPermission,
    resetAlert(e) {
      if (e.keyCode === 8 && this.searchString.length === 1 && this.showAlert) {
        this.showAlert = false;
      }
    },
    getSectionDetails(card) {
      if (card.type === "Hook" || card.type === "Node" || card.type === "Branch Menu" || card.type === "Key" || card.type === "Value") {
        return "Node: ";
      } else return "Settings: ";
    },
    getCardDetails(card) {
      if (card.type === "Hook") {
        for (let index in this.blockTypeHook) {
          if (this.blockTypeHook[index].value === card.detail) {
            return this.blockTypeHook[index].text;
          }
        }
      }
      if (card.type === "Node" || card.type === "Branch Menu" || card.type === "Key" || card.type === "Value") {
        return card.detail;
      } else return "Flow Settings";
    },
    showEditButton(card) {
      if (
        card.typeDetail === "text" ||
        card.typeDetail === "menu" ||
        card.typeDetail === "survey" ||
        card.typeDetail === "question" ||
        card.typeDetail === "file" ||
        card.typeDetail === "handover" ||
        card.typeDetail === "sendevents" ||
        card.typeDetail === "delay" ||
        card.typeDetail === "buttons" ||
        card.typeDetail === "form" ||
        card.type === "Inactivity" ||
        card.type === "Error" ||
        card.type === "General" ||
        card.type === "Semantic Hooks" ||
        card.type === "Branch Menu" ||
        card.type === "Hook" ||
        (card.typeDetail === "email" && card.typeMessage !== "body")
      ) {
        return true;
      } else {
        return false;
      }
    },
    getHelp() {
      this.showSearch = false;
      this.showHelp = !this.showHelp;
    },
    showAdvanced() {
      this.showHelp = false;
      this.showSearch = !this.showSearch;
    },
    closePanelDuringSearch() {
      this.showHelp = false;
    },
    editCard(index) {
      this.editIndex = index;
      this.rerender++;
    },
    async saveNewText(card) {
      let entityVariableName = null;
      if (card.entityVariableName !== undefined) {
        entityVariableName = card.entityVariableName.substring(1, card.entityVariableName.length - 1);
      }
      try {
        await this.$http.post("/content-editor/update-flow-message", {
          newText: card.matchedText,
          nodeId: card.uuidNode || null,
          botName: card.botName,
          flow: card.flow,
          version: card.version,
          oldText: card.originalText,
          type: card.type,
          typeDetail: card.typeDetail,
          typeMessage: card.typeMessage,
          typeMessageDetail: card.typeMessage2 || null,
          entityVariableName: entityVariableName,
          menuPosition: card.menuPosition || null,
          selectedEngine: card.selectedEngine || null,
        });
        this.$refs.resultSnackbar.showSuccess("Text changed!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to change text!");
      } finally {
        this.closeEditCard();
      }
      try {
        const response = await this.$http.get("/content-editor");
        this.flowList = response.data;
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to reload data!");
      }
    },
    closeEditCard() {
      this.editIndex = null;
      this.rerender++;
    },
    async renewLockOwners() {
      try {
        const response = await this.$http.get("/lock-all");
        let locks = response.data.locks;
        for (let flow = 0; flow < this.flowList.length; flow++) {
          if (locks[this.company + "_" + this.flowList[flow].scene.attr.flowName + "_" + this.flowList[flow].flowVersion]) {
            this.flowList[flow].lockOwner = locks[this.company + "_" + this.flowList[flow].scene.attr.flowName + "_" + this.flowList[flow].flowVersion];
          } else {
            this.flowList[flow].lockOwner = null;
          }
        }
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to get flow locks");
      }
    },
    openFlowDesigner(card) {
      let nodeId = card.uuidNode;
      if (!card.botId) card.botId = "0";
      openFD.openNewWindow(card.botId, card.flow, card.version, nodeId);
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
    selectAllNodes($event) {
      this.elementSelected.nodeType = [];
      if ($event) {
        for (let i = 0; i < this.blockTypeTranslation.length; i++) {
          this.elementSelected.nodeType.push(merge({}, this.blockTypeTranslation[i]));
        }
      }
    },
    selectSingleNode($event) {
      if ($event.length < this.blockTypeTranslation.length && this.elementSelected.allNodes) {
        this.elementSelected.allNodes = false;
      }
      if ($event.length === this.blockTypeTranslation.length) {
        this.elementSelected.allNodes = true;
      }
    },
    selectAllHook($event) {
      this.elementSelected.hookType = [];
      if ($event) {
        for (let i = 0; i < this.blockTypeHook.length; i++) {
          this.elementSelected.hookType.push(merge({}, this.blockTypeHook[i]));
        }
      }
    },
    selectSingleHook($event) {
      if ($event.length < this.blockTypeHook.length && this.elementSelected.hook) {
        this.elementSelected.hook = false;
      }
      if ($event.length === this.blockTypeHook.length) {
        this.elementSelected.hook = true;
      }
    },
    checkString(string) {
      let str = new RegExp(this.escapeRegExp(this.searchString), "gi").exec(string);
      return str != null;
    },
    getCards() {
      if (this.loading) return;
      if (this.flowList && this.searchString.length >= this.minStringLength) {
        this.closePanelDuringSearch();
        this.rerender;
        this.result = [];
        for (let flow in this.flowList) {
          let botId = this.flowList[flow].botId;
          let botName = this.flowList[flow].botName;
          let flowName = this.flowList[flow].scene.attr.flowName;
          let flowVersion = this.flowList[flow].flowVersion;
          let lockOwner = this.flowList[flow].lockOwner ? this.flowList[flow].lockOwner : null;

          //* check flow configurations
          if (this.elementSelected.flowConfigurations) {
            //* GENERAL
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.general.completedMessage &&
              config < this.flowList[flow].scene.configurations.general.completedMessage.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.general.completedMessage[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.general.completedMessage[config].value;
                let originalText = this.flowList[flow].scene.configurations.general.completedMessage[config].value;
                let flag = this.flowList[flow].scene.configurations.general.completedMessage[config].flag;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  flag: flag,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "General",
                  typeMessage: "completedMessage",
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.general.license_cap_reached_message &&
              config < this.flowList[flow].scene.configurations.general.license_cap_reached_message.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.general.license_cap_reached_message[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.general.license_cap_reached_message[config].value;
                let originalText = this.flowList[flow].scene.configurations.general.license_cap_reached_message[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  flag: this.flowList[flow].scene.configurations.general.license_cap_reached_message[config].flag,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "General",
                  typeMessage: "license_cap_reached_message",
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.general.max_input_chars_message &&
              config < this.flowList[flow].scene.configurations.general.max_input_chars_message.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.general.max_input_chars_message[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.general.max_input_chars_message[config].value;
                let originalText = this.flowList[flow].scene.configurations.general.max_input_chars_message[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  flag: this.flowList[flow].scene.configurations.general.max_input_chars_message[config].flag,
                  type: "General",
                  typeMessage: "max_input_chars_message",
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message &&
              config < this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message[config].value;
                let originalText = this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  flag: this.flowList[flow].scene.configurations.general.not_acceptable_incoming_message[config].flag,
                  type: "General",
                  typeMessage: "not_acceptable_incoming_message",
                });
              }
            }

            //* ERROR
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error.error_message && config < this.flowList[flow].scene.configurations.error.error_message.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.error.error_message[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error.error_message[config].value;
                let originalText = this.flowList[flow].scene.configurations.error.error_message[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  flag: this.flowList[flow].scene.configurations.error.error_message[config].flag,
                  type: "Error",
                  typeMessage: "error_message",
                });
              }
            }
            /*
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error
                .advancedErrorModeHandoverMessage &&
              config <
                this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverMessage.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.error
                    .advancedErrorModeHandoverMessage[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverMessage[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .error.advancedErrorModeHandoverMessage[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "error",
                  typeMessage: "advancedErrorModeHandoverMessage"
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error
                .advancedErrorModeHandoverTargetBubbleIntroduction &&
              config <
                this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverTargetBubbleIntroduction.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.error
                    .advancedErrorModeHandoverTargetBubbleIntroduction[config]
                    .value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverTargetBubbleIntroduction[config]
                  .value;
                let originalText = this.flowList[flow].scene.configurations
                  .error.advancedErrorModeHandoverTargetBubbleIntroduction[
                  config
                ].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "error",
                  typeMessage:
                    "advancedErrorModeHandoverTargetBubbleIntroduction"
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error
                .advancedErrorModeHandoverTargetBubbleName &&
              config <
                this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverTargetBubbleName.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.error
                    .advancedErrorModeHandoverTargetBubbleName[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error
                  .advancedErrorModeHandoverTargetBubbleName[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .error.advancedErrorModeHandoverTargetBubbleName[config]
                  .value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "error",
                  typeMessage: "advancedErrorModeHandoverTargetBubbleName"
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error
                .advancedErrorModeSurveyFeedback &&
              config <
                this.flowList[flow].scene.configurations.error
                  .advancedErrorModeSurveyFeedback.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.error
                    .advancedErrorModeSurveyFeedback[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error
                  .advancedErrorModeSurveyFeedback[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .error.advancedErrorModeSurveyFeedback[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "error",
                  typeMessage: "advancedErrorModeSurveyFeedback"
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.error
                .advancedErrorModeSurveyScore &&
              config <
                this.flowList[flow].scene.configurations.error
                  .advancedErrorModeSurveyScore.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.error
                    .advancedErrorModeSurveyScore[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.error
                  .advancedErrorModeSurveyScore[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .error.advancedErrorModeSurveyScore[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "error",
                  typeMessage: "advancedErrorModeSurveyScore"
                });
              }
            }
             */

            //* INACTIVITY
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.inactivity.inactivityMessage &&
              config < this.flowList[flow].scene.configurations.inactivity.inactivityMessage.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.inactivity.inactivityMessage[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.inactivity.inactivityMessage[config].value;
                let originalText = this.flowList[flow].scene.configurations.inactivity.inactivityMessage[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  flag: this.flowList[flow].scene.configurations.error.inactivity.inactivityMessage[config].flag,
                  type: "Inactivity",
                  typeMessage: "inactivityMessage",
                });
              }
            }
            /*
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.inactivity
                .inactivitySurveyScore &&
              config <
                this.flowList[flow].scene.configurations.inactivity
                  .inactivitySurveyScore.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.inactivity
                    .inactivitySurveyScore[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations
                  .inactivity.inactivitySurveyScore[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .inactivity.inactivitySurveyScore[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "inactivity",
                  typeMessage: "inactivitySurveyScore"
                });
              }
            }
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.inactivity
                .inactivitySurveyFeedback &&
              config <
                this.flowList[flow].scene.configurations.inactivity
                  .inactivitySurveyFeedback.length;
              config++
            ) {
              if (
                this.checkString(
                  this.flowList[flow].scene.configurations.inactivity
                    .inactivitySurveyFeedback[config].value
                )
              ) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations
                  .inactivity.inactivitySurveyFeedback[config].value;
                let originalText = this.flowList[flow].scene.configurations
                  .inactivity.inactivitySurveyFeedback[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "inactivity",
                  typeMessage: "inactivitySurveyFeedback"
                });
              }
            }*/
            // Semantic Hook
            for (
              let config = 0;
              this.flowList[flow].scene.configurations.semantic_hooks.start_flow_answer &&
              config < this.flowList[flow].scene.configurations.semantic_hooks.start_flow_answer.length;
              config++
            ) {
              if (this.checkString(this.flowList[flow].scene.configurations.semantic_hooks.start_flow_answer[config].value)) {
                let index = config; //posizione nell'array
                let matchedText = this.flowList[flow].scene.configurations.semantic_hooks.start_flow_answer[config].value;
                let originalText = this.flowList[flow].scene.configurations.semantic_hooks.start_flow_answer[config].value;
                this.result.push({
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  index: index,
                  matchedText: matchedText,
                  originalText: originalText,
                  type: "Semantic Hooks",
                  typeMessage: "start_flow_answer",
                });
              }
            }
          }

          //* tipo di nodo, hook, branch menu (se nodo menu)
          if (this.elementSelected.nodeType.length > 0 || this.elementSelected.hookType.length > 0 || this.elementSelected.branchMenu) {
            let node = "Node";
            for (let block in this.flowList[flow].scene.blocks) {
              //* se blocco è checcato
              if (this.elementSelected.nodeType.find((x) => x.value == this.flowList[flow].scene.blocks[block].name)) {
                switch (this.flowList[flow].scene.blocks[block].name) {
                  case "whatsapp_template":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (this.flowList[flow].scene.blocks[block].values.property.templateName.value.body) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.templateName.value.body)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: 0,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.templateName.value.body,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.templateName.value.body,
                          detail: "WhatsApp template",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "Template name",
                        });
                      }
                    }
                    if (this.flowList[flow].scene.blocks[block].values.property.templateName.value.name) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.templateName.value.name)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: 0,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.templateName.value.name,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.templateName.value.name,
                          detail: "WhatsApp template",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "Template name",
                        });
                      }
                    }
                    break;
                  case "geolocation":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.locationName.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.locationName.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.locationName.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.locationName.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.locationName.value[message].flag,
                          detail: "Geolocation",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "Location Name",
                        });
                      }
                    }
                    if (this.flowList[flow].scene.blocks[block].values.property.latitude) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.latitude.value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: 0,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.latitude.value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.latitude.value,
                          detail: "Geolocation",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "Latitude",
                        });
                      }
                    }
                    if (this.flowList[flow].scene.blocks[block].values.property.latitude) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.longitude.value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: 0,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.longitude.value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.longitude.value,
                          detail: "Geolocation",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "longitude",
                        });
                      }
                    }
                    if (this.flowList[flow].scene.blocks[block].values.property.address) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.address.value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: 0,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.address.value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.address.value,
                          detail: "Geolocation",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "Address",
                        });
                      }
                    }
                    break;
                  case "setvars":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.variables.value.length; message++) {
                      let txt =
                        "Variable: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.variables.value[message].key +
                        "</b><br/>" +
                        "Value: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.variables.value[message].value +
                        "</b>";
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.variables.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Set Variable",
                          type: "Value",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "setvars",
                        });
                      } else if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.variables.value[message].key)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Set Variable",
                          type: "Key",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "setvars",
                        });
                      }
                    }
                    break;
                  case "jump":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.target.valueSelected)) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.target.valueSelected,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.target.valueSelected,
                        detail: "Hook Jump",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "jump",
                      });
                    }
                    break;
                  case "flowjump":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.flow_jump_target.value.flow.value)) {
                      const message =
                        "Flow: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.flow_jump_target.value.flow.value +
                        " </b><br> Version: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.flow_jump_target.value.version.value +
                        "</b> <br> Hook: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.flow_jump_target.value.hook.value +
                        "</b>";
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: message,
                        originalText: message,
                        detail: "Flow Jump",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "flowjump",
                      });
                    }
                    break;
                  case "text":
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.text.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.text.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.text.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.text.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.text.value[message].flag,
                          detail: "Message",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "text",
                        });
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Message",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    break;
                  case "menu":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Menu",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "description",
                      });
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.question &&
                      this.flowList[flow].scene.blocks[block].values.property.question.value
                    ) {
                      for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.question.value.length; message++) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.question.value[message].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.question.value[message].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.question.value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.question.value[message].flag,
                            detail: "Menu",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "question",
                          });
                        }
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion &&
                      this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value
                    ) {
                      for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value.length; message++) {
                        if (
                          this.checkString(this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value[message].value) &&
                          this.flowList[flow].scene.blocks[block].values.property.engine.valueSelected != "EudataEngine"
                        ) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value[message].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.disambiguationQuestion.value[message].flag,
                            detail: "Menu",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "disambiguationQuestion",
                          });
                        }
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.question_not_recognized &&
                      this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value
                    ) {
                      for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value.length; message++) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value[message].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value[message].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.question_not_recognized.value[message].flag,
                            detail: "Menu",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "question_not_recognized",
                          });
                        }
                      }
                    }
                    break;
                  case "question":
                    if (this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Question",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.question &&
                      this.flowList[flow].scene.blocks[block].values.property.question.value
                    ) {
                      for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.question.value.length; message++) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.question.value[message].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.question.value[message].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.question.value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.question.value[message].flag,
                            detail: "Question",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "question",
                          });
                        }
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.entities &&
                      this.flowList[flow].scene.blocks[block].values.property.entities.value
                    ) {
                      for (let entity = 0; entity < this.flowList[flow].scene.blocks[block].values.property.entities.value.length; entity++) {
                        for (
                          let message = 0;
                          message < this.flowList[flow].scene.blocks[block].values.property.entities.value[entity].detailQuestion.value[0].length;
                          message++
                        ) {
                          if (
                            this.checkString(
                              this.flowList[flow].scene.blocks[block].values.property.entities.value[entity].detailQuestion.value[0][message].value,
                            )
                          ) {
                            this.result.push({
                              uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                              index: message,
                              botId: botId,
                              botName: botName,
                              flow: flowName,
                              version: flowVersion,
                              lockOwner: lockOwner,
                              matchedText:
                                this.flowList[flow].scene.blocks[block].values.property.entities.value[entity].detailQuestion.value[0][message].value,
                              originalText:
                                this.flowList[flow].scene.blocks[block].values.property.entities.value[entity].detailQuestion.value[0][message].value,
                              detail: "Detail Question",
                              type: node,
                              typeDetail: this.flowList[flow].scene.blocks[block].name,
                              typeMessage: "entities",
                              typeMessage2: entity,
                              entityVariableName: this.flowList[flow].scene.blocks[block].values.property.entities.value[entity].variable.value,
                            });
                          }
                        }
                      }
                    }
                    break;
                  case "survey":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Survey",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.score.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.score.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.score.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.score.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.score.value[message].flag,
                          detail: "Survey score",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "score",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.feedback.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.feedback.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.feedback.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.feedback.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.feedback.value[message].flag,
                          detail: "Survey",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "score feedback",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.score_invalid.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.score_invalid.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.score_invalid.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.score_invalid.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.score_invalid.value[message].flag,
                          detail: "Survey score invalid",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "score_invalid",
                        });
                      }
                    }
                    break;
                  case "file":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Upload file",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.message_file.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.message_file.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.message_file.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.message_file.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.message_file.value[message].flag,
                          detail: "Message file",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "message_file",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.invalid_file.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.invalid_file.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.invalid_file.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.invalid_file.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.invalid_file.value[message].flag,
                          detail: "Invalid file",
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "invalid_file",
                        });
                      }
                    }
                    break;
                  case "multimedia":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Multimedia",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (
                      let element = 0;
                      element < this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static.length;
                      element++
                    ) {
                      for (
                        let message = 0;
                        message < this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static[element].value.length;
                        message++
                      ) {
                        if (
                          this.checkString(
                            this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static[element].value[message].value,
                          )
                        ) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static[element].value[message].value,
                            originalText:
                              this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static[element].value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.media_settings.value.link_static[element].value[message].flag,
                            detail: "Multimedia",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "link_static",
                            typeMessage2: element,
                          });
                        }
                      }
                    }

                    for (
                      let element = 0;
                      element < this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static.length;
                      element++
                    ) {
                      for (
                        let message = 0;
                        message < this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static[element].value.length;
                        message++
                      ) {
                        if (
                          this.checkString(
                            this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static[element].value[message].value,
                          )
                        ) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: message,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText:
                              this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static[element].value[message].value,
                            originalText:
                              this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static[element].value[message].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.media_settings.value.multimedia_static[element].value[message].flag,
                            detail: "Multimedia",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "multimedia_static",
                            typeMessage2: element,
                          });
                        }
                      }
                    }
                    break;
                  case "auth":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.caption.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.caption.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.caption.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.caption.value[message].value,
                          detail: this.flowList[flow].scene.blocks[block].name,
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "caption",
                        });
                      }
                    }
                    break;
                  case "email":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }

                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.subject.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.subject.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.subject.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.subject.value[message].value,
                          detail: this.flowList[flow].scene.blocks[block].name,
                          flag: this.flowList[flow].scene.blocks[block].values.property.subject.value[message].flag,
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "subject",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.body.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.body.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.body.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.body.value[message].value,
                          detail: this.flowList[flow].scene.blocks[block].name,
                          flag: this.flowList[flow].scene.blocks[block].values.property.body.value[message].flag,
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "body",
                        });
                      }
                    }
                    break;
                  case "handover":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.message.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.message.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.message.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.message.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.message.value[message].flag,
                          detail: this.flowList[flow].scene.blocks[block].name,
                          type: node,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "message",
                        });
                      }
                    }
                    break;
                  case "delay":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.typeOfDelay.valueSelected == "WAIT FOR EVENT" &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.eventName.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.eventName.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.eventName.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "eventName",
                      });
                    }
                    break;
                  case "sendevents":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.name.value)) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.name.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.name.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "name",
                      });
                    }
                    break;
                  case "crossroads":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.condition.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.condition.value) &&
                      this.flowList[flow].scene.blocks[block].values.property.type.valueSelected == "basic"
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.condition.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.condition.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: "Crossroads",
                      });
                    } else if (
                      this.flowList[flow].scene.blocks[block].values.property.multioutput.value.length > 0 &&
                      this.flowList[flow].scene.blocks[block].values.property.type.valueSelected == "advanced"
                    ) {
                      for (let condition in this.flowList[flow].scene.blocks[block].values.property.multioutput.value) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.multioutput.value[condition].condition)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.multioutput.value[condition].condition,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.multioutput.value[condition].condition,
                            detail: this.flowList[flow].scene.blocks[block].name,
                            type: "Crossroads",
                          });
                          break;
                        }
                      }
                    }
                    if (this.checkString(this.flowList[flow].scene.blocks[block].name)) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.condition.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.condition.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: "Crossroads",
                      });
                    }
                    break;
                  case "form":
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.title.value.length; message++) {
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.title.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: this.flowList[flow].scene.blocks[block].values.property.title.value[message].value,
                          originalText: this.flowList[flow].scene.blocks[block].values.property.title.value[message].value,
                          flag: this.flowList[flow].scene.blocks[block].values.property.title.value[message].flag,
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          type: node,
                          detail: "Form",
                          typeMessage: "title",
                        });
                      }
                    }
                    for (let formField = 0; formField < this.flowList[flow].scene.blocks[block].values.property.formField.value.length; formField++) {
                      for (let label = 0; label < this.flowList[flow].scene.blocks[block].values.property.formField.value[formField].label.length; label++) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.formField.value[formField].label[label].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.formField.value[formField].label[label].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.formField.value[formField].label[label].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.formField.value[formField].label[label].flag,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            type: node,
                            detail: "Form",
                            typeMessage: "label",
                          });
                        }
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        detail: "Form",
                        typeMessage: "description",
                      });
                    }
                    break;
                  case "buttons":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.question &&
                      this.flowList[flow].scene.blocks[block].values.property.question.value
                    ) {
                      for (let question = 0; question < this.flowList[flow].scene.blocks[block].values.property.question.value.length; question++) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.question.value[question].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            index: question,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.question.value[question].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.question.value[question].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.question.value[question].flag,
                            detail: "Buttons",
                            type: node,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            typeMessage: "question",
                          });
                        }
                      }
                    }
                    for (let output = 0; output < this.flowList[flow].scene.blocks[block].values.property.buttons_output.value.length; output++) {
                      for (
                        let label = 0;
                        label < this.flowList[flow].scene.blocks[block].values.property.buttons_output.value[output].label.label.length;
                        label++
                      ) {
                        if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.buttons_output.value[output].label.label[label].value)) {
                          this.result.push({
                            uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                            botId: botId,
                            botName: botName,
                            flow: flowName,
                            version: flowVersion,
                            lockOwner: lockOwner,
                            matchedText: this.flowList[flow].scene.blocks[block].values.property.buttons_output.value[output].label.label[label].value,
                            originalText: this.flowList[flow].scene.blocks[block].values.property.buttons_output.value[output].label.label[label].value,
                            flag: this.flowList[flow].scene.blocks[block].values.property.buttons_output.value[output].label.label[label].flag,
                            typeDetail: this.flowList[flow].scene.blocks[block].name,
                            type: node,
                            detail: "Buttons",
                            typeMessage: "buttons_output",
                          });
                        }
                      }
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: "Buttons",
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    break;
                  case "webserv":
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.settings.value.description.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Description",
                      });
                    }
                    if (
                      this.flowList[flow].scene.blocks[block].values.property.endpoint.value &&
                      this.checkString(this.flowList[flow].scene.blocks[block].values.property.endpoint.value)
                    ) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: 0,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].values.property.endpoint.value,
                        originalText: this.flowList[flow].scene.blocks[block].values.property.endpoint.value,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: node,
                        typeDetail: this.flowList[flow].scene.blocks[block].name,
                        typeMessage: "Endpoint",
                      });
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.vars_send.value.length; message++) {
                      let txt =
                        "Variable: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.vars_send.value[message].key +
                        "</b><br/>" +
                        "Value: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.vars_send.value[message].value +
                        "</b>";
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.vars_send.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Variable Value",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      } else if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.vars_send.value[message].key)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Variable Key",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.headers.value.length; message++) {
                      let txt =
                        "Variable: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.headers.value[message].key +
                        "</b><br/>" +
                        "Value: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.headers.value[message].value +
                        "</b>";
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.headers.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Header Value",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      } else if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.headers.value[message].key)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Header Key",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      }
                    }
                    for (let message = 0; message < this.flowList[flow].scene.blocks[block].values.property.result.value.length; message++) {
                      let txt =
                        "Variable: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.result.value[message].key +
                        "</b><br/>" +
                        "Value: <b>" +
                        this.flowList[flow].scene.blocks[block].values.property.result.value[message].value +
                        "</b>";
                      if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.result.value[message].value)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Result Value",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      } else if (this.checkString(this.flowList[flow].scene.blocks[block].values.property.result.value[message].key)) {
                        this.result.push({
                          uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                          index: message,
                          botId: botId,
                          botName: botName,
                          flow: flowName,
                          version: flowVersion,
                          lockOwner: lockOwner,
                          matchedText: txt,
                          originalText: txt,
                          detail: "Web Services",
                          type: "Result Key",
                          typeDetail: this.flowList[flow].scene.blocks[block].name,
                          typeMessage: "webserv",
                        });
                      }
                    }
                    break;
                }
              }
              //* hook
              if (
                this.elementSelected.hookType.find(
                  (x) => x.value == this.flowList[flow].scene.blocks[block].name && this.flowList[flow].scene.blocks[block].values.property.hook.value != "",
                ) &&
                this.checkString(this.flowList[flow].scene.blocks[block].values.property.hook.value)
              ) {
                this.result.push({
                  uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                  botId: botId,
                  botName: botName,
                  flow: flowName,
                  version: flowVersion,
                  lockOwner: lockOwner,
                  matchedText: this.flowList[flow].scene.blocks[block].values.property.hook.value,
                  originalText: this.flowList[flow].scene.blocks[block].values.property.hook.value,
                  detail: this.flowList[flow].scene.blocks[block].name,
                  type: "Hook",
                });
              }

              //* branch menu
              if (
                this.elementSelected.branchMenu &&
                this.flowList[flow].scene.blocks[block].name == "menu" &&
                this.flowList[flow].scene.blocks[block].outputs.length > 1
              ) {
                //* parto da uno perchè in posizione 0 c'è l'uscita di default che non devo considerare
                for (let element = 1; element < this.flowList[flow].scene.blocks[block].outputs.length; element++) {
                  for (let message = 0; message < this.flowList[flow].scene.blocks[block].outputs[element].label.length; message++) {
                    if (this.checkString(this.flowList[flow].scene.blocks[block].outputs[element].label[message].value)) {
                      this.result.push({
                        uuidNode: this.flowList[flow].scene.blocks[block].uuid,
                        index: message,
                        botId: botId,
                        botName: botName,
                        flow: flowName,
                        version: flowVersion,
                        lockOwner: lockOwner,
                        matchedText: this.flowList[flow].scene.blocks[block].outputs[element].label[message].value,
                        originalText: this.flowList[flow].scene.blocks[block].outputs[element].label[message].value,
                        flag: this.flowList[flow].scene.blocks[block].outputs[element].label[message].flag,
                        detail: this.flowList[flow].scene.blocks[block].name,
                        type: "Branch Menu",
                        menuPosition: element - 1,
                        selectedEngine: this.flowList[flow].scene.blocks[block].values.property.engine.valueSelected,
                        typeMessage: this.flowList[flow].scene.blocks[block].outputs[element].label[message].value,
                      });
                    }
                  }
                }
              }
            }
          }
        }
        if (this.result.length === 0) {
          this.showAlert = true;
          this.showResults = false;
        } else {
          this.showAlert = false;
          this.showResults = true;
        }
      } else {
        this.result = [];
        this.showAlert = false;
        this.showResults = false;
      }
    },
  },
};
</script>
<style>
.theme--dark.v-chip.contentChips {
  background: #1d9096 !important;
  background-color: #1d9096 !important;
}
.theme--light .colorHeader {
  background-color: #e5e5e5;
}
.theme--dark .colorHeader {
  background-color: #2e3c43;
}
.ellipsisRow {
  height: 105px;
  max-height: 105px;
  overflow: hidden;
}
.ellipsisMultiline {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-width: 630px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 105px;
  max-height: 105px;
  text-align: justify;
}

.ellipsis {
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsisRow .ellipsisMultiline textarea {
  font-size: 14px !important;
  font-family: Nunito, sans-serif !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  overflow-wrap: break-word !important;
}
</style>
