<template>
  <v-container :class="getCreationContainerMargin">
    <v-row>
      <v-col sm="6" md="5">
        <p v-if="editable" class="clickable-phrase" @click="onAddNodeClick">
          + Click here to add a new macro category
        </p>
      </v-col>
      <v-col sm="3" md="2">
        <v-btn v-if="editable" small color="primary" :disabled="!isValid" @click="sortTreeNodes">
          <v-icon small>
            mdi-sort-alphabetical-ascending
          </v-icon>
          Sort All
        </v-btn>
      </v-col>
      <v-col sm="3" md="2">
        <v-btn small color="primary" :disabled="!isValid" @click="toggleExpandNode">
          <span v-if="!isExpanded">
            <v-icon small>mdi-expand-all</v-icon>
            Expand All
          </span>
          <span v-if="isExpanded">
            <v-icon small>mdi-collapse-all</v-icon>
            Collapse All
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="parsingError" class="pa-1 mt-2 error" style="color: #fff; display: flex">
      Cannot drop that node. Maximum sub levels allowed are 3.
    </div>
    <vue-tree-list
      class="mx-5 mt-1 mb-5 treeList"
      :model="data"
      :default-tree-node-name="getDefaultTreeNodeName()"
      default-leaf-node-name="new leaf"
      :default-expanded="isExpanded"
      @delete-node="onDeleteNode"
      @add-node="onAddNode"
      @drop-before="onDragDrop"
      @drop="onDragDrop"
      @drop-after="onDragDrop"
      @change-name="onChangeName"
    >
      <template #leafNameDisplay="slotProps">
        <span class="d-inline-block text-truncate" style="min-width: 250px !important; max-width: 415px !important"> {{ slotProps.model.name }} </span>
        <span v-if="errorCategoryId[slotProps.model.id] && errorCategoryId[slotProps.model.id] === 'Name is required'" class="red--text error-empty-message">
          {{ errorCategoryId[slotProps.model.id] }}
        </span>
        <span v-if="errorCategoryId[slotProps.model.id] && errorCategoryId[slotProps.model.id] !== 'Name is required'" class="red--text error-message">
          {{ errorCategoryId[slotProps.model.id] }}
        </span>
      </template>
      <template #addTreeNodeIcon="slotProps">
        <span v-if="slotProps.model && editable" class="icon" @click="addTreeNodeIcon(slotProps)">
          <i title="Add sub category" class="action-icon mdi mdi-plus" />
        </span>
        <span v-else />
      </template>
      <template #delNodeIcon="">
        <span v-if="editable" class="icon"><i title="Delete" class="action-icon mdi mdi-delete" /></span>
        <span v-else />
      </template>
      <span slot="addLeafNodeIcon" class="icon">＋</span>
      <template #editNodeIcon="slotProps">
        <span
          v-if="editable && slotProps.model.children && slotProps.model.children.length > 1"
          class="icon"
          @click="sortNode(slotProps.model)"
        ><i title="Sort" class="action-icon mdi mdi-sort-alphabetical-ascending" /></span>
        <span v-else />
        <span v-if="editable" class="icon" @click="editChangeName"><i title="Edit" class="action-icon mdi mdi-pencil" /></span>
        <span v-else />
      </template>
      <span slot="treeNodeIcon" class="icon" />
    </vue-tree-list>
  </v-container>
</template>

<script>
import { VueTreeList, Tree, TreeNode } from "vue-tree-list";
import spacing from "../../helpers/spacing";
import timeoutHelper from "@/helpers/timeout";
import EventBus from "@/event-bus";
import XRegExp from "xregexp";

export default {
  components: {
    VueTreeList,
  },
  props: ["contactResult", "autoSorting", "editable"],
  data() {
    return {
      nodeTreeNumber: 0,
      newTree: {},
      data: new Tree([]),
      errorCategoryId: {},
      treeVersions: this.treeVersioning(),
      parsingError: false,
      nodeCount: 0,
      isExpanded: false,
    };
  },
  computed: {
    ...spacing,
    isValid() {
      return Object.keys(this.errorCategoryId).length === 0;
    },
  },
  async mounted() {
    await this.transformJSONToTree();
  },
  methods: {
    isTreeValid() {
      if (Object.keys(this.errorCategoryId).length === 0 && this.data && this.data.children && this.data.children.length > 0) {
        EventBus.$emit(this.$store.getters.getEvents.VALIDATION_TREE, true);
      } else {
        EventBus.$emit(this.$store.getters.getEvents.VALIDATION_TREE, false);
      }
    },
    sortResults(contactResult) {
      let cr = contactResult.sort((a, b) => {
        return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 0;
      });
      for (let i in cr) {
        if (cr[i].items && cr[i].items.length > 0) {
          cr[i].items = this.sortResults(cr[i].items);
        }
      }
      this.treeVersions.commit(cr);
      return cr;
    },
    addTreeNodeIcon() {
      this.nodeTreeNumber++;
    },
    getDefaultTreeNodeName() {
      return "Category" + this.nodeTreeNumber;
    },
    onDeleteNode(node) {
      node.remove();
      this.checkAfterEventName();
      this.isTreeValid();
    },
    onAddNode(event) {
      this.sanitizeNodeTree();
      this.changeName(event.id);
      setTimeout(() => {
        const cr = this.transformTreeToJSON(this.data);
        this.treeVersions.commit(cr);
      }, 250);
    },
    validateBranches(data) {
      const _validate = (obj, level) => {
        if (Array.isArray(obj)) {
          obj = { children: obj };
        }
        level = level || 0;
        if (level > 3) {
          return false;
        }
        if (level === 3) {
          if (obj.children && obj.children.length > 0) {
            return false;
          }
        }
        if (!obj.children || obj.children.length === 0) {
          return true;
        }
        let rr = true;
        for (let x in obj.children) {
          if (!_validate(obj.children[x], level + 1)) {
            rr = false;
            break;
          }
        }
        return rr;
      };

      return _validate(data);
    },
    onDragDrop() {
      let cr = [];
      this.parsingError = false;
      setTimeout(() => {
        if (!this.validateBranches(this.data)) {
          this.parsingError = true;
          //this.treeVersions.rollback();
          this.resetTree();
          this.transformJSONToTree(this.treeVersions.currentVersion(), true);
          setTimeout(() => {
            this.parsingError = false;
          }, 5000);

          return false;
        }
        this.clearAddNodeActions();
        cr = this.transformTreeToJSON(this.data);
        this.treeVersions.commit(cr);
      }, 300);
      return true;
    },
    onChangeName(params) {
      if (params && params.eventType === "blur") {
        this.checkAfterEventName();
        this.isTreeValid();
        // this.toggleDragDrop(true);
        this.listenerEnterInputBox(false);
        setTimeout(() => {
          const cr = this.transformTreeToJSON(this.data);
          this.treeVersions.commit(cr);
        }, 250);
      }
    },
    checkAfterEventName(name, id) {
      this.validateTreeName(name, id);
    },
    validateTreeName() {
      this.errorCategoryId = {};
      if (this.data) {
        this.validateTreeNodeName(this.data);
      }
    },
    clearAddNodeActions(data, level) {
      data = data || this.data.children;
      level = level || 0;
      for (let x = 0; x < data.length; x++) {
        data[x].addTreeNodeDisabled = level >= 2;
        //data[x].addLeafNodeDisabled = level >= 2;
        if (data[x].children && data[x].children.length > 0) {
          data[x].children = this.clearAddNodeActions(data[x].children, level + 1);
        }
      }
      return data;
    },
    async editChangeName() {
      // this.toggleDragDrop(false);
      await timeoutHelper.sleep(150);
      this.listenerEnterInputBox(true);
    },
    sortTreeNodes() {
      let contactResult = this.transformTreeToJSON();
      this.resetTree();
      contactResult = this.sortResults(contactResult);
      this.transformJSONToTree(contactResult);
      this.checkAfterEventName();
      this.isTreeValid();
    },
    sortNode(node) {
      setTimeout(() => {
        node.children = node.children.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.id - b.id;
        });
        setTimeout(() => {
          document.querySelector(".treeList input").blur();
        }, 0);
        this.checkAfterEventName();
        this.isTreeValid();
      }, 0);
    },
    listenerEnterInputBox(val) {
      if (val) {
        document.querySelector(".treeList input").removeEventListener("keypress", this.keyPressEvent);
        document.querySelector(".treeList input").addEventListener("keypress", this.keyPressEvent);
      } else {
        document.querySelector(".treeList input").removeEventListener("keypress", this.keyPressEvent);
      }
    },
    keyPressEvent(e) {
      if (e.keyCode === 13) {
        const input = document.querySelector(".treeList input");
        if (input) {
          input.blur();
        }
      }
    },
    onAddNodeClick() {
      let node = this.addNode();
      this.isTreeValid();
      this.changeName(node.id);
    },
    addNode(name) {
      const node = this.getNode(name);
      if (!this.data.children) {
        this.data.children = [];
      }
      this.data.addChildren(node);
      return node;
    },
    validateTreeNodeName(oldNode, brothers) {
      if (oldNode && oldNode.name !== "root") {
        oldNode.name = oldNode.name.trim();

        if (!oldNode.name) {
          this.errorCategoryId[oldNode.id] = "Name is required";
        } else {
          const regex = "^[a-zA-Z0-9_-\\s]*$";

          let regexp = new XRegExp(regex, "gi");
          let res = regexp.exec(oldNode.name);

          if (oldNode.name.length > 50) {
            this.errorCategoryId[oldNode.id] = "Name can be a string between 1 and 50 characters";
          } else if (res === null) {
            this.errorCategoryId[oldNode.id] = "Name may contain only alphabetic characters, spaces, numbers, dashes or underscores";
          } else {
            const sameName = brothers.filter((el) => el.name === oldNode.name);
            if (sameName.length > 1) {
              for (const same of sameName) {
                this.errorCategoryId[same.id] = "Name already exists at this level";
              }
            }
          }
        }
      }
      if (oldNode.children && oldNode.children.length > 0) {
        for (let i = 0, len = oldNode.children.length; i < len; i++) {
          this.validateTreeNodeName(oldNode.children[i], oldNode.children);
        }
      }
    },
    getNode(name, addTreeNodeDisabled, addLeafNodeDisabled) {
      this.nodeTreeNumber++;
      return new TreeNode({
        name: name ? name : "Category" + this.nodeTreeNumber,
        pid: 0,
        disabled: false,
        dragDisabled: false,
        addTreeNodeDisabled: typeof addTreeNodeDisabled === "boolean" ? addTreeNodeDisabled : false,
        addLeafNodeDisabled: typeof addLeafNodeDisabled === "boolean" ? addLeafNodeDisabled : true,
        editNodeDisabled: false,
        delNodeDisabled: false,
      });
    },
    resetTree() {
      this.data = new Tree([]);
    },
    async transformJSONToTree(contactResult, noCommit) {
      this.nodeCount = 0;
      if (typeof contactResult === undefined || !contactResult || contactResult.length === 0) {
        contactResult = this.contactResult;
      }
      noCommit = noCommit || false;
      for (const indexFirst in contactResult) {
        const first = contactResult[indexFirst];
        const fCount = Number(indexFirst);
        if (this.nodeCount < fCount) {
          this.nodeCount = fCount;
        }
        this.addNode(first.value);
        await timeoutHelper.sleep(1);
        const secondLevel = contactResult[indexFirst].items;

        for (const indexSecond in secondLevel) {
          const second = secondLevel[indexSecond].value;
          const sCount = Number(indexSecond);
          if (this.nodeCount < sCount) {
            this.nodeCount = sCount;
          }

          if (this.data.children[indexFirst]) {
            this.data.children[indexFirst].addChildren(this.getNode(second, false, true));

            await timeoutHelper.sleep(1);
            const thirdLevel = secondLevel[indexSecond].items;

            for (const indexThird in thirdLevel) {
              const third = thirdLevel[indexThird].value;
              const tCount = Number(indexThird);
              if (this.nodeCount < tCount) {
                this.nodeCount = tCount;
              }

              if (this.data.children[indexFirst].children[indexSecond]) {
                this.data.children[indexFirst].children[indexSecond].addChildren(this.getNode(third, true, true));
              }
              await timeoutHelper.sleep(1);
            }
          }
        }
      }
      !noCommit && this.treeVersions.commit(contactResult);
      //this.toggleDragDrop(this.editable);
    },
    transformTreeToJSON(data) {
      const contactResult = [];
      const idXName = {};
      data = data || this.data;

      function _dfs(oldNode, order) {
        if (oldNode && oldNode.name !== "root") {
          idXName[oldNode.id] = {
            name: oldNode.name,
            pid: oldNode.pid,
            order: order,
          };
          if (oldNode.pid === 0) {
            //se è un primo livello
            contactResult.push({
              items: [],
              value: oldNode.name,
            });
          }
          if (oldNode.pid !== 0) {
            //o il secondo o il terzo livello
            if (!oldNode.addTreeNodeDisabled) {
              //secondo livello
              const firstLevelIndex = idXName[oldNode.pid].order;
              contactResult[firstLevelIndex].items.push({
                items: [],
                value: oldNode.name,
              });
            }
            if (oldNode.addTreeNodeDisabled) {
              //terzo livello
              const secondLevelIndex = idXName[oldNode.pid].order;
              const firstLevelIndex = idXName[idXName[oldNode.pid].pid].order;
              contactResult[firstLevelIndex].items[secondLevelIndex].items.push({
                items: [],
                value: oldNode.name,
              });
            }
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          for (let i = 0, len = oldNode.children.length; i < len; i++) {
            _dfs(oldNode.children[i], i);
          }
        }
      }

      if (data && !!data.children) {
        for (let x in data.children) {
          _dfs(data.children[x], x);
        }
      }
      return contactResult;
    },
    sanitizeNodeTree() {
      if (this.data && this.data.children && this.data.children.length > 0) {
        let firstLevel = this.data.children;
        firstLevel.forEach((first) => {
          first.addTreeNodeDisabled = false;
          first.addLeafNodeDisabled = true;
          if (first.children && first.children.length > 0) {
            let secondLevel = first.children;
            secondLevel.forEach((second) => {
              second.addTreeNodeDisabled = false;
              second.addLeafNodeDisabled = true;
              if (second.children && second.children.length > 0) {
                let thirdLevel = second.children;
                thirdLevel.forEach((third) => {
                  third.addTreeNodeDisabled = true;
                  third.addLeafNodeDisabled = true;
                });
              }
            });
          }
        });
      }
    },
    toggleDragDrop(val) {
      if (this.data && this.data.children && this.data.children.length > 0) {
        let firstLevel = this.data.children;
        firstLevel.forEach((first) => {
          first.dragDisabled = !val;
          if (first.children && first.children.length > 0) {
            let secondLevel = first.children;
            secondLevel.forEach((second) => {
              second.dragDisabled = !val;
              if (second.children && second.children.length > 0) {
                let thirdLevel = second.children;
                thirdLevel.forEach((third) => {
                  third.dragDisabled = !val;
                });
              }
            });
          }
        });
      }
    },
    changeName(id) {
      //Per abilitare subito l'edit del nome dopo l'aggiunta della category
      setTimeout(function () {
        document.getElementById(id).querySelector(".vtl-node-main .vtl-operation span[title='edit']").click();
      }, 100);
    },
    treeVersioning() {
      let versions = [];
      return {
        currentVersion: () => {
          return versions[0] || [];
        },
        rollback: () => {
          versions.shift();
        },
        commit: (data) => {
          versions.unshift(data);
        },
      };
    },
    async toggleExpandNode() {
      this.isExpanded = !this.isExpanded;
      this.resetTree();
      await this.transformJSONToTree(this.treeVersions.currentVersion());
    },
  },
};
</script>

<style>
.vt1 .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}

.vt1 .vtl-disabled {
  background-color: #d0cfcf;
}

.vtl-node-content {
  color: #1f1f1f;
}

.action-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.54);
}

.theme--dark .vtl-node-content {
  color: #ffffff;
}

.theme--dark .clickable-phrase {
  color: #1d9096 !important;
}

.theme--dark .action-icon {
  color: #ffffff;
}

.theme--dark .vtl-node-main:hover {
  background-color: #41535d85 !important;
}

.theme--dark input:focus {
  outline: none !important;
  color: #ffffff;
  border-bottom-color: #ffffff;
}

.theme--light input:focus {
  outline: none !important;
  color: #1d9096 !important;
  border-bottom-color: #1d9096 !important;
}

.vtl-icon:hover {
  color: #a5aaad !important;
}

.vtl-input {
  min-width: 250px !important;
  max-width: 250px !important;
}
</style>

<style scoped>
.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

.clickable-phrase {
  margin-bottom: 0px;
  color: #039be5;
  font-size: 13px;
  cursor: pointer;
}

.clickable-phrase:hover {
  text-decoration: underline;
}

.error-message {
  font-size: 12px;
  position: absolute;
  margin-left: 100px;
}

.error-empty-message {
  font-size: 12px;
  position: absolute;
  margin-left: 100px;
  margin-top: -13px;
}
</style>
