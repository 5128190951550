import store from "../store";
export default {
  //Riga principale che contiene la pagina
  sanitizeAttachment: function (params, company) {
    for (let i in params.content) {
      let history = params.content[i];
      if (history.attachment && history.attachment.preprocessorType) {
        params.content[i].attachment.preprocessorType = store.getters.getInputProcessorByType(history.attachment.preprocessorType).label;
        if (!history.attachment.detectedLanguage) {
          params.content[i].attachment.language = store.getters.getGoogleSpeechSupportedLanguageByName(history.attachment.language);
        }
      }
      //in teoria qui non ci entro più..o è una casistica particolare??
      if (history.attachment && history.attachment.mime_type && history.attachment.mime_type.includes("image")) {
        params.content[i].attachment.file_url = "../document/view-document-by-name/" + company + "/" + params.dialog_id + "/" + history.attachment.file_name;
      }
      if (history.type === "share_document") {
        if (!params.content[i].attachment) params.content[i].attachment = {};
        if (typeof params.content[i].attachment !== "string") {
          params.content[i].attachment.mime_type = "image/" + history.message.substring(history.message.lastIndexOf(".") + 1, history.message.length);
          params.content[i].attachment.file_name = history.message;
          params.content[i].attachment.file_url = history.message; //perchè è già il link giusto;
        }
      }
    }
    if (params.content && params.privateNotes && params.privateNotes.length > 0) {
      params.privateNotes.forEach &&
        params.privateNotes.forEach((message) => {
          message.type = "privateNote";
        });
      params.content.push(...params.privateNotes);
      params.content.sort((dateA, dateB) => new Date(dateA.timestamp) - new Date(dateB.timestamp));
      delete params.privateNotes;
    }
    return params;
  },
};
