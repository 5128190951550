<template>
  <v-container v-show="notLoading" id="customer-gui" fluid>
    <ConfirmationDialog :showConfirm="confirmDialog" :message="dialogMessage" @dialogDeny="confirmDialog = false" @dialogConfirm="deleteFile" />
    <ResultSnackbar ref="resultSnackbar" />
    <v-row justify="center" :class="getMainRowMargin">
      <customerLogo
        :key="forceRerender"
        class="ma-0 pa-0"
        :logoFile="logoFile"
        :logoSrc="logoSrc"
        :existsGuiLogo="existsGuiLogo"
        @uploadLogo="uploadLogo"
        @showDialog="deleteInterfaceLogo"
        @removeFile="removeFile"
      />

      <v-col cols="12">
        <!-- Aggiunta gui -->
        <v-expansion-panels v-if="newGui" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="12">
                  New Customer Interface
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <customerGuiForm
                :isEdit="false"
                :gui.sync="newGui"
                :file.sync="file"
                :customerInterfacesList.sync="customerInterfacesList"
                :loading.sync="submitLoading"
                @importFile="importFile"
                @uploadFile="uploadFile"
                @removeFile="removeFile"
                @closePanel="closePanel"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Visualizzazione lista Gui-->
        <p v-if="customerInterfacesList.length > 0" class="ml-5">
          Customer Interfaces list
        </p>
        <v-expansion-panels
          v-if="customerInterfacesList.length > 0"
          :key="forceRerender + '_ep'"
          v-model="expansionPanel"
          popout
          :class="getMainExpansionPanelMargin"
        >
          <v-expansion-panel v-for="(gui, id) in customerInterfacesList" :key="'gui_' + id" @change="updateMainScrollbar">
            <v-hover v-slot="{ hover }">
              <v-expansion-panel-header hide-actions :class="hover ? 'hoverBanner' : ''" style="pointer-events: none !important">
                <v-row align="center" class="px-4">
                  <v-col cols="1">
                    <v-avatar size="35" color="primary" class="my-1">
                      <v-icon v-if="gui.type == 'zip'" small dark>
                        mdi-folder-zip
                      </v-icon>
                      <v-icon v-else small dark>
                        mdi-file-link
                      </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-subtitle class="pb-1">
                          Interface Name
                        </v-list-item-subtitle>
                        <v-list-item-title style="height: 18px !important">
                          {{ gui.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col class="text-truncate d-flex flex-grow" cols="8">
                    <v-row align="center" justify="end">
                      <v-btn
                        style="pointer-events: auto !important"
                        small
                        color="success"
                        class="mr-2"
                        :loading="submitLoading.save"
                        :disabled="submitLoading.export || submitLoading.delete"
                        @click.native.stop="manageOpen(gui, id)"
                      >
                        <v-icon left>
                          mdi-sync
                        </v-icon>
                        <span v-if="expansionPanel == id">close edit</span>
                        <span v-else>change</span>
                      </v-btn>
                      <v-btn
                        v-if="gui.type === 'zip'"
                        small
                        :disabled="submitLoading.save || submitLoading.delete"
                        :loading="submitLoading.export"
                        color="primary"
                        class="btnPointer mx-2 px-5"
                        @click.stop="exportFile(gui.name)"
                      >
                        <v-icon left>
                          mdi-download
                        </v-icon>export
                      </v-btn>
                      <v-btn
                        v-if="gui.type === 'url'"
                        v-clipboard="() => gui.url"
                        v-clipboard:success="clipboardSuccessHandler"
                        small
                        :disabled="submitLoading.save || submitLoading.delete"
                        :loading="submitLoading.export"
                        color="primary"
                        class="btnPointer mx-2"
                        @click.stop="() => {}"
                      >
                        <v-icon left>
                          mdi-web
                        </v-icon>copy url
                      </v-btn>
                      <v-btn
                        small
                        :disabled="submitLoading.save || submitLoading.export"
                        :loading="submitLoading.delete"
                        color="error"
                        class="btnPointer mx-2"
                        @click.stop="deleteInterface(gui.name)"
                      >
                        <v-icon left>
                          mdi-delete
                        </v-icon>delete
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
            </v-hover>
            <v-expansion-panel-content>
              <v-col cols="12">
                <customerGuiForm
                  :isEdit="true"
                  :gui="editGui"
                  :file.sync="file"
                  :customerInterfacesList.sync="customerInterfacesList"
                  :loading.sync="submitLoading"
                  @importFile="importFile($event)"
                  @uploadFile="uploadFile"
                  @removeFile="removeFile"
                  @validForm="enableBtn = $event"
                />
              </v-col>
              <!-- Buttons -->
              <v-col cols="12" sm="12" class="mb-4" :class="editGui.type == 'zip' ? 'mt-8' : 'mt-4'">
                <v-row align="center" class="px-4">
                  <v-btn
                    color="success"
                    class="mr-2"
                    :loading="submitLoading.save"
                    :disabled="
                      editGui.type == 'zip'
                        ? submitLoading.export || submitLoading.delete || isZipFile(file)
                        : editGui.url == selectedGuiUrl || submitLoading.export || submitLoading.delete || !enableBtn
                    "
                    @click.prevent="importFile(true)"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Update</span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <AddNewItemButton name="customer interface" @addNewItem="addNewGui" />
    <div v-show="false">
      <form v-show="false" ref="exportFormToSubmit" method="POST" action="/export">
        <input type="text" name="customerInterfaceName" value>
        <input type="text" name="type" value="CUSTOMER_INTERFACE">
      </form>
    </div>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import EventBus from "../../event-bus";
import ConfirmationDialog from "../../components/other/ConfirmationDialog";
import guiImage from "../../assets/img/bots/convyGuiBase64";
import fieldValidators from "../../helpers/fieldValidators";
import customerLogo from "./customerLogo.vue";
import customerGuiForm from "../../components/tools/gui/customerGuiForm.vue";

export default {
  name: "CustomerGui",
  components: {
    ResultSnackbar,
    AddNewItemButton,
    ConfirmationDialog,
    customerLogo,
    customerGuiForm,
  },
  data() {
    return {
      forceRerender: 0,
      existsGuiCustom: false,
      existsGuiLogo: false,
      logoSrc: guiImage.getImage(),
      file: null,
      logoFile: null,
      submitLoading: {
        save: false,
        delete: false,
        export: false,
      },
      confirmDialog: false,
      dialogMessage: "",
      maxSizeRule: [(value) => !value || value.size < 10000000 || "ZIP size should be less than 10 MB!"],
      preview: null,
      // gui
      newGui: null,
      customerInterfacesList: [],
      editGui: {
        name: "",
        type: "zip",
        url: "",
      },
      selectedGuiUrl: "",
      customerInterfaceToDelete: undefined,
      deleteLogo: false,
      newUpload: {},
      creationPanel: null,
      hovered: false,
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      selectedGui: null,
      guiSwitch: false,
      enableBtn: false,
      notLoading: false,
    };
  },
  computed: {
    ...spacing,
    calcSwitch() {
      if (this.customerInterfacesList[this.selectedGui].type == "zip") {
        return false;
      }
      return true;
    },
  },
  mounted() {
    EventBus.$emit(this.$store.getters.getEvents.LOADING, true);
    this.checkIfExistsCustomerGui();
  },
  methods: {
    ...fieldValidators,
    ...scroller,
    manageOpen(gui, id) {
      if (this.expansionPanel == id) {
        this.expansionPanel = null;
        this.editGui = {
          name: "",
          type: "zip",
          url: "",
        };
        this.selectedGuiUrl = "";
      } else {
        if (gui.type === "url") {
          this.selectedGuiUrl = gui.url;
        }
        this.editGui = gui;
        this.editGui.url = this.editGui.url || "";
        this.expansionPanel = id;
      }
    },
    clipboardSuccessHandler({ value, event }) {
      event.preventDefault();
      let findGui = this.customerInterfacesList.find((gui) => gui.url == value);
      this.$refs.resultSnackbar.showSuccess(findGui.name + "'s url copied! ");
    },
    copyUrl(url) {
      return url;
    },
    isZipFile(file) {
      if (file && file.type.includes("zip")) {
        return false;
      }
      return true;
    },
    uploadFile(obj) {
      if (obj.isLogo) {
        this.logoFile = obj.file;
      } else {
        this.file = obj.file;
      }
    },
    deleteInterface(name) {
      this.customerInterfaceToDelete = name;
      this.dialogMessage = "Are you sure to delete " + name + " customer interface?";
      this.confirmDialog = true;
    },
    deleteInterfaceLogo() {
      this.deleteLogo = true;
      this.dialogMessage = "Are you sure to delete the interface logo?";
      this.confirmDialog = true;
    },
    async checkIfExistsCustomerGui() {
      try {
        let result = await this.$http.get("/customer-ui/exists");
        if (result.data.length > 0) {
          this.customerInterfacesList = result.data;
          this.customerInterfacesList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
          this.existsGuiCustom = true;
        }
      } catch (e) {
        this.existsGuiCustom = false;
      }
      this.checkIfExistsCustomerGuiLogo();
    },
    async checkIfExistsCustomerGuiLogo() {
      try {
        const result = await this.$http.get("/customer-ui-logo");
        this.existsGuiLogo = true;
        this.logoSrc = "data:image/png;base64," + result.data.data;
      } catch (e) {
        this.existsGuiLogo = false;
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
        this.notLoading = true;
      }
    },
    uploadLogo(obj) {
      if (obj.upload) {
        this.logoFile = obj.logoFile;
        this.logoSrc = obj.logoSrc;
        this.existsGuiLogo = obj.existGui;
        this.$refs.resultSnackbar.showSuccess("Customer interface logo uploaded successfully!", 0);
      } else {
        this.$refs.resultSnackbar.showError("There were problems loading the customer interface logo", 0);
      }
    },
    async importFile(isEdit) {
      this.submitLoading.save = true;
      let data = new FormData();
      try {
        let result;
        if (!isEdit) {
          if (this.newGui.type === "zip") {
            data.append(this.file.name, this.file);
          }
          result = await this.$http.post("/customer-ui", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              customerInterfaceName: this.newGui.name,
              customerInterfaceType: this.newGui.type,
              customerInterfaceURL: this.newGui.url,
            },
          });
          this.customerInterfacesList.push(result.data);
          this.customerInterfacesList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
          this.newGui = "";
        } else {
          if (this.editGui.type === "zip") {
            data.append(this.file.name, this.file);
          }
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              customerInterfaceName: this.editGui.name,
              customerInterfaceType: this.editGui.type,
              customerInterfaceURL: this.editGui.url,
            },
          };
          result = await this.$http.put("/customer-ui", data, config);
          let index = this.customerInterfacesList.findIndex((el) => el.name === result.data.name);
          this.customerInterfacesList[index] = result.data;
          this.editGui.name = "";
          this.editGui.type = "zip";
        }
        this.file = null;
        this.existsGuiCustom = true;
        this.$refs.resultSnackbar.showSuccess("Customer interface uploaded successfully!", 0);
      } catch (e) {
        this.$refs.resultSnackbar.showError("There were problems loading the customer interface", 0);
      }
      this.submitLoading.save = false;
      this.selectedGuiUrl = "";
      this.creationPanel = null;
      this.expansionPanel = null;
    },
    async exportFile(name) {
      this.submitLoading.export = true;
      this.$refs.exportFormToSubmit.action = "../export/customer-ui?j=" + this.$store.state.loginResponse.jwt;
      this.$refs.exportFormToSubmit[0].value = name;
      this.$refs.exportFormToSubmit.submit();
      this.submitLoading.export = false;
    },
    async deleteFile() {
      this.expansionPanel = null;
      this.confirmDialog = false;
      this.submitLoading.delete = true;
      if (this.customerInterfaceToDelete) {
        try {
          await this.$http.delete("/customer-ui/" + this.customerInterfaceToDelete);
          this.customerInterfacesList = this.customerInterfacesList.filter((el) => el.name !== this.customerInterfaceToDelete);
          if (this.customerInterfacesList.length == 0) {
            this.existsGuiCustom = false;
          }
          this.$refs.resultSnackbar.showSuccess("Customer interface delete successfully!");
        } catch (e) {
          this.$refs.resultSnackbar.showError("Fail to delete customer interface!");
        } finally {
          this.customerInterfaceToDelete = undefined;
        }
      } else if (this.deleteLogo) {
        try {
          await this.$http.delete("/customer-ui-logo");
          this.existsGuiLogo = false;
          this.logoSrc = guiImage.getImage();
          this.logoFile = null;
          this.forceRerender++;
          this.$refs.resultSnackbar.showSuccess("Customer interface logo delete successfully!");
        } catch (e) {
          this.$refs.resultSnackbar.showError("Fail to delete customer interface logo!");
        } finally {
          this.deleteLogo = false;
        }
      }
      this.submitLoading.delete = false;
    },
    removeFile() {
      this.file = null;
      this.logoFile = null;
    },
    updateMainScrollbar() {
      this.expansionPanel = null;
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewGui() {
      if (!this.newGui) {
        this.newGui = {
          name: "",
          type: "zip",
          file: null,
          url: "",
        };
        this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
      }
    },
    closePanel() {
      this.newGui = null;
      this.removeFile();
      this.scrollToTop().finally(() => (this.expansionPanelCreation = null), 300);
    },
    updateFile() {
      this.$emit("importFile", true);
    },
  },
};
</script>
<style>
.btnPointer {
  pointer-events: auto !important;
  cursor: pointer !important;
  position: relative;
  z-index: 99;
}
.guiAlert {
  border-color: transparent !important;
  border-radius: 0 !important;
}
.theme--dark .guiAlert {
  background-color: #263238 !important;
}
</style>
