<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @onChangeView="onChangeView"
        @reloadData="reloadWidgetData"
      />
      <span @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-chart
          v-if="selectedView == 'chart'"
          ref="firstAnswerByQueue"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart"
          :option="options"
        />
        <div v-else-if="selectedView == 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="queue"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            must-sort
            sort-by="queue"
            dense
          >
            <template #top>
              <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
            </template>
          </v-data-table>
        </div>
      </span>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "FirstAnswerByQueue",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      info: "This widget shows, for each queue, the average response time for an incoming conversation",
      options: {
        tooltip: {
          trigger: "item",
          formatter: (data) => {
            const values = JSON.parse(JSON.stringify(data));
            values.value[0] = this.secondsToHms(values.value[0]);
            return this.chartDefaultTooltip(values);
          },
        },
        grid: {
          top: 10,
          bottom: 30,
          left: 50,
          right: 120,
        },
        xAxis: {
          minInterval: 1,
          max: "dataMax",
          name: "   Average",
        },
        dataset: {
          source: [["SLA", "Queue"]],
        },
        yAxis: {
          type: "category",
          axisLabel: {
            inside: true,
            rotate: 0,
            color: this.$vuetify.theme.dark ? "white" : "white",
            fontSize: 16,
            margin: 20,
            textShadowColor: "#3A3A3B",
            textShadowOffsetX: "1",
            textShadowOffsetY: "1",
            textShadowBlur: "2",
            rich: {
              flag: {
                fontSize: 25,
                padding: 5,
              },
            },
            formatter: function () {
              return "";
            },
          },
          z: 10000,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 9,
        },
        series: [
          {
            realtimeSort: true,
            seriesLayoutBy: "column",
            type: "bar",
            showBackground: true,
            encode: {
              x: 0,
              y: 3,
            },
            label: {
              show: true,
              precision: 1,
              position: "right",
              valueAnimation: true,
              fontFamily: "monospace",
              formatter: (value) => {
                return this.secondsToHms(value.value[0]);
                // return hms === "00:00" ? "0m" : hms;
              },
            },
          },
        ],
        // Disable init animation.
        animationDuration: 0,
        animationDurationUpdate: 2000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          borderRadius: [5, 5, 5, 5],
          borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
        },
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queues",
            align: "start",
            sortable: true,
            value: "queue",
          },
          {
            text: "Average response time",
            align: "center",
            sortable: true,
            value: "avg",
            width: 196,
          },
          {
            text: "", //colonna utile a nascondere la scrollbar orizzontale
            align: "",
            sortable: false,
            value: "",
            width: 0,
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    async loadWidgetData() {
      try {
        const result = await this.$httpAuth.get("/dashboard/wallboard/first-agent-answer");
        this.options.dataset.source = [["SLA", "Queue"]];
        this.viewTable.data = [];
        for (const queueERI in result.data.queue) {
          const queue = this.queues.find((q) => q.eri === queueERI);
          if (queue) {
            const arrayAvg = [result.data.queue[queueERI].average, queue.name];
            this.options.dataset.source.push(arrayAvg);
            this.viewTable.data.push({
              queue: queue.name,
              avg: this.secondsToHms(result.data.queue[queueERI].average),
            });
          }
        }
        this.options.yAxis.max = this.options.dataset.source.length - 2;
        if (this.$refs["firstAnswerByQueue"]) {
          this.$refs["firstAnswerByQueue"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 420px;
}
</style>
