<template>
  <v-snackbar v-model="snackBar.show" :color="snackBar.color" :timeout="snackBar.timeout" top multi-line>
    <v-row justify="center">
      <v-col :cols="!hideClose ? '9' : '12'">
        <v-col v-for="(string, index) in snackBar.message" :key="index" cols="12" class="body-1 ma-0 pa-0">
          {{ string }}
        </v-col>
      </v-col>
      <v-col v-if="!hideClose" cols="3">
        <v-btn small dark text @click="closeClicked">
          Close
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
export default {
  name: "ResultSnackbar",
  components: {},
  props: ["hideClose"],
  data() {
    return {
      snackBar: {
        show: false,
        message: [],
        color: null,
        timeout: 5000,
      },
    };
  },
  methods: {
    showError(msg, timeout) {
      this.setTimeout(timeout);
      this.snackBar.color = "error";
      if (!Array.isArray(msg)) {
        this.snackBar.message[0] = msg;
      } else {
        this.snackBar.message = msg;
      }
      this.snackBar.show = true;
    },
    showSuccess(msg, timeout) {
      this.setTimeout(timeout);
      this.snackBar.color = "success";
      if (!Array.isArray(msg)) {
        this.snackBar.message[0] = msg;
      } else {
        this.snackBar.message = msg;
      }
      this.snackBar.show = true;
    },
    setTimeout(timeout) {
      if (timeout != null && typeof timeout !== "undefined") this.snackBar.timeout = timeout;
    },
    getStatus() {
      return this.snackBar.color;
    },
    reset() {
      this.snackBar.show = false;
      this.snackBar.message = [];
      this.snackBar.color = "error";
      this.snackBar.timeout = 5000;
    },
    closeClicked() {
      this.snackBar.show = false;
      this.$emit("closeClicked");
    },
  },
};
</script>
