<template>
  <v-container fluid>
    <v-row
      class="py-4 px-6"
      align="center"
      justify="center"
    >
      <v-form
        v-model="isValid"
        class="fill-width"
      >
        <v-row align="center">
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="editedConnector.bot_id"
              :items="getBotList"
              item-text="name"
              item-value="bot_id"
              :return-object="false"
              label="Select which bot"
            >
              <template #prepend-inner>
                <v-avatar
                  v-if="isAvatarAvailable(editedConnector)"
                  size="25"
                  class="mr-2"
                  tile
                >
                  <img
                    alt="Avatar"
                    :src="getBotAvatarFromPublish(editedConnector)"
                  >
                </v-avatar>
                <v-icon
                  v-else
                  class="mr-2"
                >
                  mdi-alert
                </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="editedConnector.type"
              :items="connectorsTypes"
              :return-object="false"
              label="Select which connector"
              @change="onChangeConnectorType($event)"
            >
              <template #item="{ item }">
                <img
                  v-if="item.value != 'email'"
                  alt="Avatar"
                  :src="getLogo(item.value)"
                  :height="item.value != 'googlebusinessmessages' ? 25 : 23"
                  :width="item.value != 'googlebusinessmessages' ? 25 : 23"
                >
                <v-icon
                  v-else
                  color="primary"
                  style="font-size: 27px"
                >
                  mdi-email-outline
                </v-icon>
                <span class="mx-5">{{ item.text }}</span>
              </template>
              <!-- v-slot:selection customizza il primo elemento, quello selezionato -->
              <template #selection="{ item }">
                <img
                  v-if="item.value != 'email'"
                  alt="Avatar"
                  :src="getLogo(item.value)"
                  :height="item.value != 'googlebusinessmessages' ? 25 : 23"
                  :width="item.value != 'googlebusinessmessages' ? 25 : 23"
                >
                <v-icon
                  v-else
                  color="primary"
                  style="font-size: 27px"
                >
                  mdi-email-outline
                </v-icon>
                <span class="mx-5">{{ item.text }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <EmailConnectorConf
            v-if="editedConnector.type === 'email'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <TelegramConf
            v-if="editedConnector.type === 'telegram'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <FacebookConf
            v-else-if="editedConnector.type === 'facebookmessenger'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <WhatsAppInfoBipConf
            v-else-if="editedConnector.type === 'whatsapp'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <!--<AppleBusinessChatConf v-else-if="editedConnector.type === 'applebusinesschat'" :editedConnector="editedConnector" :connectors="connectors" />-->
          <AmazonAlexaConf
            v-else-if="editedConnector.type === 'amazonalexa'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <GoogleAssistantConf
            v-else-if="editedConnector.type === 'googlehome'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <!--<WebexTeamsConf v-else-if="editedConnector.type === 'webexmeeting'" :editedConnector="editedConnector" :connectors="connectors" />-->
          <WebConf
            v-else-if="editedConnector.type === 'webapi'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <TwitterConf
            v-else-if="editedConnector.type === 'twitter'"
            :edited-connector="editedConnector"
            :connectors="connectors"
          />
          <GoogleBusinessMessages
            v-else-if="editedConnector.type === 'googlebusinessmessages'"
            :edited-connector="editedConnector"
            :connectors="connectors"
            @fileUploaded="uploadForm($event)"
            @removeFileGoogle="editedConnector.details.file = null"
          />
        </v-row>
        <!--gestione parametri campagna outbound  v-if="hasOutboundParameterSectionAvailable(connector)"-->
        <v-row v-if="hasOutboundParameterSectionAvailable(editedConnector)">
          <OutboundParameters
            :parameters="editedConnector"
            :profiles="profiles"
            :bots="bots"
            :whatsapp-templates="whatsappTemplates"
            :connector-type="editedConnector.type"
            :connectors="connectors"
          />
        </v-row>
        <v-row v-if="hasAdditionalParameterSectionAvailable(editedConnector)">
          <AdditionalParameters :parameters="editedConnector" />
        </v-row>

        <v-row v-if="editedConnector.type === 'webapi'">
          <InitialForm :parameters="editedConnector" />
        </v-row>
        <v-row>
          <v-btn
            :id="render"
            class="mr-2"
            :class="getButtonMargin"
            color="success"
            :loading="connectorConfigurationLoading"
            :disabled="checkIsValidForm"
            @click="saveConnector"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>Save
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            @click="resetConnectorParam"
          >
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
        </v-row>
      </v-form>
    </v-row>
  </v-container>
</template>
<script>
import EmailConnectorConf from "./connectors/EmailConnectorConf";
import TelegramConf from "./connectors/TelegramConf";
import FacebookConf from "./connectors/FacebookConf";
import WhatsAppInfoBipConf from "./connectors/WhatsAppInfoBipConf";
import AmazonAlexaConf from "./connectors/AmazonAlexaConf";
import GoogleAssistantConf from "./connectors/GoogleAssistantConf";
import TwitterConf from "./connectors/TwitterConf";
import WebConf from "./connectors/WebConf";
import GoogleBusinessMessages from "./connectors/GoogleBusinessMessages";
import spacing from "../../helpers/spacing";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import AdditionalParameters from "./AdditionalParameters.vue";
import OutboundParameters from "./OutboundParameters";
import InitialForm from "./InitialForm";
import EventBus from "../../event-bus";

export default {
  name: "AddNewConnector",
  components: {
    TelegramConf,
    FacebookConf,
    WhatsAppInfoBipConf,
    AmazonAlexaConf,
    GoogleAssistantConf,
    WebConf,
    TwitterConf,
    GoogleBusinessMessages,
    AdditionalParameters,
    OutboundParameters,
    InitialForm,
    EmailConnectorConf,
  },
  props: ["bots", "connectors", "profiles", "whatsappTemplates"],
  data() {
    return {
      render: 0,
      company: this.$store.getters.getSelectedCompany,
      isValid: false,
      connectorConfigurationLoading: false,
      authentication: undefined,
      editedConnector: {
        type: "webapi",
        details: {
          agentAvailability: {
            availabilityType: "always",
            businessHours: {
              hours: [{ period: "", startAt: "", finishAt: "" }],
            },
            agentStatus: {
              selectedQueue: "",
              minAgentNumber: 1,
            },
            always: {},
          },
        },
        params: {
          spoolingQueueInterval: 2500,
          millisecondsPerCharacter: 50,
          maxTypingTimeInMilliseconds: 2500,
          minTypingTimeInMilliseconds: 500,
          typingActivationDelay: 150,
          requireTyping: true,
          fixedSendingTimeout: 1000,
          requireSplit: false,
          maxSplitLength: 120,
          saveAllAttachments: false,
        },
        outboundParams: {
          enabled: false,
          campaignName: "",
          outboundBot: "",
          enabledProfiles: [],
          outboundWhatsappTemplate: {},
        },
        form: [],
        name: "",
        enabled: true,
        webhookUrl: "",
        bot_id: "",
        identifierFieldName: "",
      },
      connectorsTypes: [
        { text: "Amazon Alexa", value: "amazonalexa" },
        { text: "Email", value: "email" },
        { text: "Facebook Messenger", value: "facebookmessenger" },
        { text: "Google Assistant", value: "googlehome" },
        { text: "Google Business Messages", value: "googlebusinessmessages" },
        { text: "Telegram", value: "telegram" },
        { text: "Web", value: "webapi" },
        { text: "WhatsApp", value: "whatsapp" },
        //{ text: "Apple Business Chat", value: "applebusinesschat" },
      ],
      fileIsUploaded: false,
    };
  },
  computed: {
    getBotList() {
      let botList = Object.values(this.bots);
      botList = botList?.filter((b) => !b?.isOutbound);
      return botList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    checkIsValidForm() {
      if (this.editedConnector.type === "email") {
        return !this.isValid || this.editedConnector.details.incoming.username === "" || this.editedConnector.details.outgoing.username === "";
      }
      if (this.editedConnector.type === "googlebusinessmessages") {
        return !this.isValid || !this.fileIsUploaded;
      } else {
        return !this.isValid;
      }
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.ENABLE_SAVE_EMAIL_CONNECTOR);
    if (Object.values(this.bots).length > 0) {
      this.editedConnector.bot_id = this.getBotList[0].bot_id;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.ENABLE_SAVE_EMAIL_CONNECTOR);
  },
  methods: {
    ...checkRoleAndPermission,
    ...spacing,
    hasAdditionalParameterSectionAvailable(connector) {
      return connector.type !== "googlehome" && connector.type !== "amazonalexa" && connector.type !== "email";
    },
    hasOutboundParameterSectionAvailable(connector) {
      return this.$store.getters.isIntegrationEnabled("outbound") && (connector.type === "whatsapp" || connector.type === "telegram");
    },
    isAvatarAvailable(connector) {
      const bot = this.bots[connector.bot_id];
      return !!bot && bot.icon;
    },
    getBotAvatarFromPublish(connector) {
      //
      if (connector) {
        const bot = this.bots[connector.bot_id];
        if (bot) {
          return this.$store.getters.getSystemBotIcon(bot.icon, "mini", bot.isVisualIvr) || this.$store.state.enums.botTemplates[0].images[0].image.mini;
        }
      }
      return null;
    },
    onChangeConnectorType() {
      if (this.editedConnector.type === "webapi") {
        this.editedConnector.details = {
          agentAvailability: {
            availabilityType: "always",
            businessHours: {
              hours: [{ period: "", startAt: "", finishAt: "" }],
            },
            agentStatus: {
              selectedQueue: "",
              minAgentNumber: 1,
            },
            always: {},
          },
        };
      }
      if (this.editedConnector.type === "email") {
        this.editedConnector.details = {
          incoming: {
            username: "",
            folder: "",
            password: "",
            retypePassword: "",
            protocol: "IMAP",
            host: "",
            port: "",
            spooling: 1,
            read_policy: "READ",
            passwordObscured: true,
            secure: false,
            serviceAccountEmail: "",
            privateKey: "",
            authType: "",
          },
          outgoing: {
            email: "",
            username: "",
            password: "",
            retypePassword: "",
            protocol: "SMTP",
            host: "",
            port: "",
            passwordObscured: true,
            secure: false,
            serviceClient: "",
            privateKey: "",
            authType: "",
          },
        };
      } else {
        this.editedConnector.details = {};
      }
    },
    getLogo(connector) {
      return this.$store.getters.getConnectorLogoByType(connector);
    },
    async saveConnector() {
      if (this.isValid) {
        this.connectorConfigurationLoading = true;
        this.editedConnector.enabled = true;
        if (this.editedConnector.type === "email") {
          this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling * 60000;
        }
        if (this.editedConnector.type === "googlebusinessmessages") {
          this.editedConnector.details.apiURL = "https://www.googleapis.com/auth/businessmessages";
          let obj = {
            name: this.editedConnector.details.file.name,
            json: this.editedConnector.details.file.json,
          };
          this.editedConnector.details.file = obj;
        }
        try {
          const response = await this.$http.post("/social-connector", this.editedConnector);
          EventBus.$emit(this.$store.getters.getEvents.CONNECTOR_CREATED, response.data);
          this.resetConnectorParam();
        } catch (err) {
          if (this.editedConnector.type == "email") {
            this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling / 60000;
          }
          EventBus.$emit(this.$store.getters.getEvents.EDIT_CONNECTOR_FAIL, this.editedConnector);
        } finally {
          this.connectorConfigurationLoading = false;
        }
      }
    },
    resetConnectorParam() {
      this.editedConnector = {
        type: "webapi",
        details: {},
        params: {
          spoolingQueueInterval: 2500,
          millisecondsPerCharacter: 50,
          maxTypingTimeInMilliseconds: 2500,
          minTypingTimeInMilliseconds: 500,
          typingActivationDelay: 150,
          requireTyping: true,
          fixedSendingTimeout: 1000,
          requireSplit: false,
          maxSplitLength: 120,
          saveAllAttachments: false,
        },
        form: [],
        name: "",
        enabled: true,
        webhookUrl: "",
        bot_id: "",
        identifierFieldName: "",
      };
      this.$emit("cancelCreation");
    },
    uploadForm(val) {
      this.fileIsUploaded = val.form;
      if (val.json) {
        this.editedConnector.details.file.json = val.json;
      } else {
        this.editedConnector.details.file = null;
        this.fileIsUploaded = false;
      }
    },
  },
};
</script>
