<template>
  <v-container class="mt-5" fluid>
    <div class="subtitle-1 mb-2">Customer can chat based on the availability agents.</div>
    <div class="subtitle-2 mb-2">Select target agent queue:</div>

    <v-row no-gutters v-if="loaded && agentQueueList && agentQueueList.length === 0">
      <v-alert dark border="left" color="warning" width="500"> Create a new Agent queue before configure Agent Availability </v-alert>
    </v-row>

    <v-row v-else-if="loaded">
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="agentStatus.selectedQueue"
          :items="agentQueueList"
          item-text="name"
          item-value="uuid"
          label="Agent queue"
          :rules="[requiredField]"
          multiple
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Minimum number of agents"
          v-model="agentStatus.minAgentNumber"
          type="number"
          :rules="[requiredField, positiveNumber]"
          min="1"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fieldValidators from "../../../../helpers/fieldValidators";

export default {
  name: "AgentStatus",
  props: ["agentStatus"],
  data() {
    return {
      agentQueueList: [],
      loaded: false,
    };
  },
  async mounted() {
    try {
      const response = await this.$http.get("/human-agents/queue");
      this.agentQueueList = response.data;
    } catch {
      this.agentQueueList = [];
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>

<style scoped></style>
