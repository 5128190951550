<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          ref="email"
          v-model.trim="editedConnector.email"
          label="Email"
          :rules="[requiredField, isValidEmail]"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" md="6" />
      <v-col cols="12" md="6">
        <v-text-field
          ref="username"
          v-model.trim="editedConnector.username"
          label="Username"
          :rules="[requiredField]"
          prepend-inner-icon="person"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="editedConnector.serviceAuth"
          :items="$store.state.enums.serviceAuthTypes"
          color="primary"
          label="Service Authentication"
          @change="onServiceAuthChange"
        />
      </v-col>
      <template v-if="serviceAuth === 'basic'">
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            ref="password"
            v-model.trim="editedConnector.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="lock"
            :error-messages="customErrorMessage"
            autocomplete="new-password"
            @blur="checkRetypePassword"
            @change="deleteErrorMessage"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="!editedConnector.password || editedConnector.password.length < 1" tabindex="-1">
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            ref="retypePassword"
            v-model.trim="editedConnector.retypePassword"
            label="Retype Password"
            :type="showPasswordRetype ? 'text' : 'password'"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="lock"
            :error-messages="customErrorMessage"
            autocomplete="new-password"
            @change="deleteErrorMessage"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="!editedConnector.retypePassword || editedConnector.retypePassword.length < 1" tabindex="-1">
                <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </template>
      <template v-if="serviceAuth === 'google-oauth2'">
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            ref="serviceClient"
            v-model.trim="editedConnector.serviceClient"
            label="Service Client"
            type="text"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-account-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            ref="privateKey"
            v-model.trim="editedConnector.privateKey"
            label="Private Key"
            type="text"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-key"
            autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </template>
      <template v-if="serviceAuth === 'office365-oauth2'">
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model.trim="editedConnector.tenant"
            label="Directory (Tenant) ID"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model.trim="editedConnector.applicationClientId"
            label="Application (Client) ID"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="mdi-account-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model.trim="editedConnector.clientSecret"
            label="Client Secret"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="mdi-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </template>
      <v-col cols="12" sm="12" md="6">
        <v-select v-model="editedConnector.protocol" :items="protocols" label="Protocol">
          <template #item="{ item }">
            <span>{{ item }}</span>
          </template>
          <template #selection="{ item }">
            <v-icon right class="ml-n1 pl-1">
              mdi-lan-connect
            </v-icon>
            <span class="ml-1">{{ item }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="serviceAuth !== 'office365-oauth2'" cols="12" sm="12" md="6">
        <v-text-field
          ref="host"
          v-model.trim="editedConnector.host"
          label="Server"
          type="text"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-server-network"
        />
      </v-col>
      <v-col v-if="serviceAuth !== 'office365-oauth2'" cols="12" sm="12" md="6">
        <v-text-field
          ref="port"
          v-model.number="editedConnector.port"
          label="Port"
          type="number"
          :rules="[requiredField, positiveNumber, serverPort]"
          prepend-inner-icon="mdi-swap-horizontal"
          min="0"
          max="36535"
        />
      </v-col>
      <v-col v-if="serviceAuth !== 'office365-oauth2'" cols="12" sm="12" md="6">
        <v-btn-toggle>
          <v-tooltip top>
            <template #activator="{ on }">
              <div class="ma-0 pa-0" v-on="on">
                <v-switch v-model="editedConnector.secure" color="primary" label="Secure" />
              </div>
            </template>
            <span>Enable TLS security</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-btn :color="getColor" large :disabled="isDisabled || isLoading" @click="testOutgoingConnection('outgoing')">
          {{ getLabel }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../../helpers/spacing";
import fieldValidators from "../../../../helpers/fieldValidators";
import EventBus from "../../../../event-bus";
export default {
  name: "OutgoingEmail",
  components: { },
  props: {
    "editedConnector": {
      type: Object,
      required: true
    },
    "isEdit": {
      type: Boolean,
      required: true
    },
    "publicationId": {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMounted: false,
      isLoading: false,
      failed: false,
      tested: false,
      //Campo protocol
      protocols: ["SMTP"],
      //Campi di Utility
      showPassword: false,
      showPasswordRetype: false,
      customErrorMessage: null,
      serviceAuth: "basic",
      availabilityTypesList: [
        { type: "businessHours", label: "Business Hours", icon: "mdi-briefcase-clock", size: "55" },
        { type: "agentStatus", label: "Agent Status", icon: "mdi-account-multiple-check", size: "75" },
        { type: "always", label: "Always", icon: "mdi-hours-24", size: "55" },
      ],
    };
  },
  computed: {
    isDisabled() {
      if (!this.isMounted) {
        return true;
      }
      for (let key of Object.keys(this.editedConnector)) {
        if (this.$refs[key] && this.$refs[key].hasError) {
          return true;
        }
      }
      return false;
    },
    getLabel() {
      if (this.isLoading && !this.tested && !this.failed) {
        return "Testing...";
      }
      if (this.failed) {
        return "Tested connection failed";
      }
      if (this.tested) {
        return "Tested connection";
      } else {
        return "Test connection";
      }
    },
    getColor() {
      if (this.isLoading) {
        return "primary";
      }
      if (this.failed) {
        return "error";
      }
      if (this.tested) {
        return "success";
      }
      return "primary";
    },
  },
  watch: {
    editedConnector: {
      handler() {
        this.failed = false;
        this.tested = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.isMounted = true;
    if (!this.editedConnector.serviceAuth) {
      this.editedConnector.serviceAuth = "basic";
    }
    this.serviceAuth = this.editedConnector.serviceAuth;
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    onServiceAuthChange(newValue) {
      this.serviceAuth = newValue;
    },
    async testOutgoingConnection(type) {
      this.isLoading = true;
      if (this.isEdit && this.editedConnector.password && this.editedConnector.password.length > 0) {
        this.editedConnector.passwordObscured = false;
      }
      if (this.isEdit && this.editedConnector.privateKey && this.editedConnector.privateKey.length > 0) {
        this.editedConnector.keyObscured = false;
      }
      try {
        await this.$httpAuth.post("/email/test-configuration", {
          clientType: type,
          clientConfigurations: this.editedConnector,
          publicationId: this.publicationId,
        });
        this.tested = true;
        this.failed = false;
        EventBus.$emit(this.$store.getters.getEvents.SHOW_SNACKBAR, { type: "success", msg: "Outgoing connection test success! "});
      } catch (err) {
        this.tested = false;
        this.failed = true;
        EventBus.$emit(this.$store.getters.getEvents.SHOW_SNACKBAR, { type: "error", msg: "Outgoing connection test failed!"});
      } finally {
        this.editedConnector.passwordObscured = true;
        this.editedConnector.keyObscured = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 15000);
      }
    },
    deleteErrorMessage() {
      this.customErrorMessage = "";
    },
    checkRetypePassword() {
      if (this.editedConnector.retypePassword && this.editedConnector.password) {
        if (this.editedConnector.retypePassword.length > 0 && this.editedConnector.password !== this.editedConnector.retypePassword) {
          this.customErrorMessage = "Password must match";
        }
      }
    },
    checkPasswords() {
      if (this.editedConnector.retypePassword && this.editedConnector.password) {
        if (this.editedConnector.password !== this.editedConnector.retypePassword) {
          this.customErrorMessage = "Password must match";
        }
      }
    },
  },
};
</script>
