<template>
  <v-tooltip bottom>
    <template #activator="{}">
      <span v-clipboard="() => data" v-clipboard:success="clipboardSuccessHandler" @click="() => {}">
        <v-icon small class="ml-1 mr-3 mt-n1" style="cursor: pointer"> mdi-content-copy </v-icon>
        <span v-if="label">{{ label }}</span>
      </span>
    </template>
    <span>Copy to clipboard</span>
  </v-tooltip>
</template>

<script>
import EventBus from "../../event-bus";

export default {
  name: "CopyToClipboardButton",
  props: ["data", "message", "notopmargin", "label", "button"],
  data() {
    return {};
  },
  methods: {
    clipboardSuccessHandler() {
      EventBus.$emit(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.message);
    },
  },
};
</script>
