<template>
  <div>
    <ConfirmDialog
      :showConfirm="showConfirm"
      :message="deleteMessage"
      :confirmMessage="companyDetail.name"
      @dialogDeny="showConfirm = false"
      @dialogConfirm="onDeleteCompanyConfirm"
    />

    <!-- create app -->
    <v-dialog v-model="appModal" persistent max-width="700">
      <v-card>
        <v-card-title> New App </v-card-title>
        <v-card-text>
          <v-form v-model="isAppFormValid">
            <CompanyAppForm :newApp="newApp" :companyId="editCompany.uuid" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" outlined :disabled="!isAppFormValid" @click="saveAndCloseAppModal()">
            Create
          </v-btn>
          <v-btn color="error" outlined @click="closeAppModal()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="createCompanyForm" v-model="form.isValid" :class="getCreationFormMargin">
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editCompany.name"
            prepend-inner-icon="mdi-city"
            label="Company Name"
            :rules="[requiredField, companyNameValidator]"
            autofocus
            :disabled="isTenantNameDisabled"
            :readonly="isTenantNameDisabled"
          />
        </v-col>
        <v-col v-if="isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field v-model.trim="editCompany.uuid" label="Company UUID" disabled readonly :rules="[requiredField]" />
        </v-col>
        <!-- Company timezone -->
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <TimeZonePicker :item="editCompany.timezone" />
        </v-col>
        <!-- Associazione Tenant -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model.trim="editCompany.crabTenant.name"
            prepend-inner-icon="mdi-vector-combine"
            label="Crab Tenant - optional"
            :hint="isEdit ? 'CRAB Tenant' : 'Associate the companies to a CRAB tenant. If not specified a new tenant will be created'"
            :persistent-hint="!isEdit"
            :autocomplete="$store.getters.disableAutocomplete"
            :disabled="isTenantNameDisabled"
            :readonly="isTenantNameDisabled"
            :rules="[crabTenantNameValidator]"
          />
        </v-col>
      </v-row>

      <CrabCustomSettings :company="editCompany" />

      <!-- Credenziali Kibana -->
      <v-row v-if="isEdit">
        <v-col cols="12" class="mb-n6">
          <v-subheader class="ma-0 pa-0 font-weight-bold">
            Manage OpenDashboard Access
          </v-subheader>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            v-model.trim="editCompany.crabTenant.cache.credentials.username"
            disabled
            readonly
            label="Username"
            hint="This is the username of your Kibana account"
            :autocomplete="$store.getters.disableAutocomplete"
          >
            <template #prepend-inner>
              <v-img contain class="my-1" height="24" max-width="24" :src="require('../../assets/img/brands/opendashboard.png')" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            v-model.trim="editCompany.crabTenant.cache.credentials.password"
            label="Password"
            readonly
            :type="showKibanaPassword ? 'text' : 'password'"
            hint="The password to access your Kibana Account"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn depressed icon text tabindex="-1">
                <v-icon v-show="!showKibanaPassword" tabindex="-1" @click="showKibanaPassword = !showKibanaPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showKibanaPassword" tabindex="-1" @click="showKibanaPassword = !showKibanaPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="text-right mt-2">
          <v-btn color="primary" small @click="openKibanaPage()">
            <v-icon small left>
              mdi-open-in-new
            </v-icon> Go To Dashboard
          </v-btn>
        </v-col>
      </v-row>

      <!-- integrations -->
      <v-container fill-height>
        <v-row>
          <v-subheader class="ma-0 pa-0 font-weight-bold">
            Manage integrations
          </v-subheader>
        </v-row>
        <v-row class="mx-n6 px-0 mb-4">
          <!-- style="height: auto;" -->
          <v-col
            v-for="integration in integrationsList"
            :id="'integration_col' + integration.label"
            :key="'integration_' + integration.value"
            class="defineheight fill-height addBorder"
            style="height: auto; position: relative"
            cols="12"
            sm="4"
            md="3"
          >
            <v-icon style="cursor: pointer; position: absolute !important; right: 18px; top: 18px" small @click="openInfo(integration.label)">
              mdi-information-outline
            </v-icon>
            <v-layout column align-center justify-center :style="$vuetify.theme.dark ? 'background: #26353c;' : ''" class="addBorder elevation-2 fill-height">
              <v-flex class="pt-3" justify="center">
                <v-img
                  contain
                  :src="integration.src"
                  :width="integration.value != 'successKPI' ? '65px' : '135px'"
                  :height="integration.value == 'knowledgeBase' ? '40px' : '35px'"
                >
                  <v-card-title v-text="integration.title" />
                </v-img>
              </v-flex>
              <v-flex style="height: 5px" />
              <v-flex>
                <div
                  class="infoService"
                  :style="$vuetify.theme.dark ? 'background: #f0f8ff0d;' : ''"
                  style="background: aliceblue; overflow: hidden; height: auto; text-align: center; font-size: 12px; line-height: 1.5"
                >
                  <span :id="'infoService' + integration.label">
                    <p style="padding: 15px">{{ integration.info }}</p>
                  </span>
                </div>
              </v-flex>
              <v-flex class="pb-4" style="transform: scale(0.9)">
                <v-switch v-model="editCompany.integrations[integration.value]" class="mylabel" :disabled="integration.disabled" :label="integration.label" />
              </v-flex>
            </v-layout>
          </v-col>
          <!-- emptySpaceCard: blocco per gli spazi vuoti, i valori vanno schiantati a codice non è dinamico -->
          <div v-if="!$vuetify?.breakpoint.xs" style="width: 100%; height: 100%; display: contents">
            <v-col
              v-for="(integration, index) in emptySpace.number"
              :key="index + 'emptyspace'"
              class="fill-height addBorder"
              style="height: 140px"
              cols="12"
              :sm="emptySpace.col"
              :md="emptySpace.col"
            >
              <v-layout
                column
                align-center
                justify-center
                class="addBorder fill-height"
                :style="$vuetify.theme.dark ? 'background: rgb(47 62 70 / 61%);' : ''"
                style="background: rgb(255 255 255); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 46px inset"
              >
                <v-flex class="pt-3" justify="center" />
              </v-layout>
            </v-col>
          </div>
        </v-row>
      </v-container>
      <!-- apps -->
      <v-container v-if="editCompany.integrations.apps" fill-height>
        <v-row>
          <v-col cols="6">
            <v-subheader class="ma-0 pa-0 font-weight-bold">
              Apps
            </v-subheader>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" class="ma-0 mr-n2 float-right" small @click="addNewApp(false)">
              <v-icon small left>
                mdi-plus
              </v-icon>Add new App
            </v-btn>
          </v-col>
          <v-col v-if="!editCompany.apps || editCompany.apps.length == 0" cols="12">
            <v-alert type="warning">
              No Apps configured.
              <span style="margin-right: 5px; font-style: italic; font-weight: bold; cursor: pointer" @click="addNewApp(false)">Click Here</span>
              to create the first one!
            </v-alert>
          </v-col>
          <v-col v-else cols="12">
            <v-expansion-panels v-model="appExpansionPanel" :class="getMarginNewCard">
              <v-expansion-panel v-for="(app, index) in editCompany.apps" :key="'app' + app.id" :disabled="!isAppFormValid">
                <v-hover v-slot="{ hover }">
                  <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
                    <v-row align="center" class="spacer" no-gutters>
                      <v-col cols="12" md="5" class="text-truncate">
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                            <v-list-item-title>
                              {{ app.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col cols="12" md="5" class="text-truncate">
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-subtitle>ERI</v-list-item-subtitle>
                            <v-list-item-title>
                              {{ app.eri }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-list-item dense class="ml-12">
                          <v-list-item-content>
                            <v-list-item-subtitle v-if="app.enabled">
                              Enabled
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              Disabled
                            </v-list-item-subtitle>
                            <v-list-item-icon class="ma-0">
                              <v-btn class="ma-0 ml-1" :color="!app.enabled ? 'error' : 'success'" x-small text depressed>
                                <v-icon v-if="!app.enabled" small>
                                  mdi-close-circle
                                </v-icon>
                                <v-icon v-else small>
                                  mdi-check-circle
                                </v-icon>
                              </v-btn>
                            </v-list-item-icon>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                </v-hover>
                <v-expansion-panel-content>
                  <v-divider />
                  <v-form v-model="isAppFormValid" class="mt-6">
                    <CompanyAppForm :key="'app_form_' + app.uuid" :newApp="app" :companyId="editCompany.uuid" @deleteApp="deleteApp(index)" />
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>

      <v-container fill-height class="mb-6">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-subheader class="ma-0 pa-0 font-weight-bold">
              Additional Configurations
            </v-subheader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-switch
              v-model="editCompany.integrations['contactcenterstats']"
              class="mylabel"
              :disabled="!editCompany.integrations.wcs"
              label="Calculate Contact Center Statistics"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="enabledLegacyTokens && companyDetail.legacyTokens && companyDetail.legacyTokens.length > 0" fill-height class="mb-6">
        <ResultSnackbar ref="resultSnackbar" />
        <v-row>
          <v-col cols="6">
            <v-subheader class="ma-0 pa-0 font-weight-bold">
              Legacy Tokens
            </v-subheader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="font-weight-bold">
                Business Partner
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[0]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[0] }}
                </v-chip>
              </v-col>
              <v-col cols="4" class="font-weight-bold">
                Admin
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[1]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[1] }}
                </v-chip>
              </v-col>
              <v-col cols="4" class="font-weight-bold">
                Supervisor
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[2]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[2] }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-row>
              <v-col cols="4" class="font-weight-bold">
                User
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[3]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[3] }}
                </v-chip>
              </v-col>
              <v-col cols="4" class="font-weight-bold">
                App
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[4]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[4] }}
                </v-chip>
              </v-col>
              <v-col cols="4" class="font-weight-bold">
                Plugin
              </v-col>
              <v-col cols="8">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-clipboard="() => companyDetail.legacyTokens[5]"
                      v-clipboard:success="clipboardSuccessHandler"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => {}"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
                <v-chip small color="primary">
                  {{ companyDetail.legacyTokens[5] }}
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <!-- Buttons -->
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading || !isAppFormValid"
            @click.prevent="saveCompany"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.deleteLoading"
            :disabled="isEdit && (!form.isValid || form.submitLoading)"
            @click.prevent="deleteCompany"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete Company</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import TimeZonePicker from "@/components/other/TimeZonePicker";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";
import CrabCustomSettings from "./CrabCustomSettings";
import CompanyAppForm from "../../components/other/CompanyAppForm";
import { v4 as uuidv4 } from "uuid";
import ResultSnackbar from "../../components/other/ResultSnackbar";

export default {
  name: "CompanyForm",
  components: {
    CrabCustomSettings,
    CompanyAppForm,
    ConfirmDialog,
    TimeZonePicker,
    ResultSnackbar,
  },
  props: ["companyDetail"],
  data() {
    return {
      enabledLegacyTokens: false,
      appModal: false,
      appExpansionPanel: null,
      isEditIndex: null,
      isAppFormValid: true,
      newApp: {
        id: "",
        eri: "",
        name: "",
        url: "",
        domains: [],
        enabled: true,
        jwtDuration: "28800",
        allowRoles: [],
      },

      showElasticPassword: false,
      showCrabbyPassword: false,
      showKibanaPassword: false,
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Company " + this.companyDetail.name + " will be deleted! This action is not reversible!",

      //Flag isEdit mode
      isEdit: this.companyDetail.name !== "",

      searchString: "",

      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },

      editCompany: {
        integrations: {},
        apps: [],
        crabTenant: {
          cache: {
            credentials: {
              password: "",
              username: "",
              type: "",
            },
          },
        },
        timezone: {
          zone: "",
          timezoneOffset: 0,
        },
        crabCustomSettings: {
          enabled: false,
          databaseType: "MONGO_DB",
          databaseUsername: "",
          databasePassword: "",
          databaseRegion: null,
          databaseUri: "",
          databasePort: 27017,
          cacheType: "BASIC",
          cachePassword: "",
          cacheUsername: "",
          cachePort: 0,
          cacheUrl: "",
          kibanaUrl: "",
        },
      },
    };
  },
  computed: {
    ...spacing,
    isTenantNameDisabled() {
      return this.isEdit;
    },
    emptySpace() {
      // in caso di spazi perfettamente riempiti levare il commento sotto del return number 0
      // return {number:0,col:3}
      if (this.$vuetify.breakpoint.sm) {
        return {
          number: 1,
          col: 4,
        };
      }
      return {
        number: 0,
        col: 3,
      };
    },
    integrationsList() {
      return [
        {
          value: "wcs",
          label: "ConvyAI Agents",
          src: require("../../assets/img/brands/convyai-agents.png"),
          info: "Enable human contact center capabilities. Define agents, queues, routing profiles and monitor contact center performances",
        },
        {
          value: "crab",
          label: "CRAB - NBA",
          src: require("../../assets/img/databases/crab-logo.png"),
          info: "Enable advanced capabilities for storing and searching end users details. The Database nodes will be added to FlowDesigner tool",
        },
        {
          value: "janus",
          label: "Janus Gateway",
          src: require("../../assets/img/brands/janus.png"),
          disabled: !this.$store.state.loginResponse.params.serverConfigurations.enableJanusFeature,
          info: "Enable audio/video calls recording capabilities to ConvyAI agents",
        },
        {
          value: "apps",
          label: "Apps",
          src: require("../../assets/img/webapp.jpg"),
          info: "Add external applications for enriching ConvyAI administration panel capabilities ",
        },
        /*
        {
          value: "ciscoECE",
          label: "Cisco ECE",
          src: require("../../assets/img/brands/cisco.png"),
          flex: 2,
        },
        {
          value: "webexTeams",
          label: "Webex Teams",
          src: require("../../assets/img/connectors/webexteams.png"),
          flex: 2,
        },
        {
          value: "rainbow",
          label: "ALE Rainbow",
          src: require("../../assets/img/brands/rainbow.png"),
          flex: 2,
        },
        */
        {
          value: "livePerson",
          label: "Live Person",
          src: require("../../assets/img/brands/liveperson.png"),
          info: "Enable LivePerson integration for transfering bot conversations to LivePerson agents",
        },
        {
          value: "successKPI",
          label: "Success KPI",
          src: require("../../assets/img/brands/SuccessKPI.png"),
          info: "Enable SuccessKPI integration as an advanced monitoring tool. Combine Omnichannel + Voice CTI contact center reporting KPIs ",
        },
        {
          value: "knowledgeBase",
          label: "Knowledge Base",
          // src: require("../../assets/img/brands/knowledgeBase.png"),
          src: require("../../assets/img/brands/knowledge2.png"),
          info: "Enrich bots and agents capabilities by managing a Generative-AI driven knowledge base, by uploading documents or crawling a particular website ",
        },
        {
          value: "outbound",
          label: "Outbound",
          // src: require("../../assets/img/brands/knowledgeBase.png"),
          src: require("../../assets/img/brands/outbound.png"),
          info: "Enrich bots and agents capabilities by managing outbound messaging and proactive communication ",
        },
      ];
    },
    rowPadding() {
      return "py-0";
    },
    colPadding() {
      return "py-0";
    },
  },
  async mounted() {
    this.editCompany = merge(this.editCompany, this.companyDetail);
    this.$refs.createCompanyForm.resetValidation();
    if (!this.$store.state.loginResponse.params.serverConfigurations.enableJanusFeature) {
      this.editCompany.integrations.janus = false;
    }

    const response = await this.$http.get("/settings/legacyTokens");
    this.enabledLegacyTokens = response.data.enableLegacyTokens;
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    deleteApp(index) {
      this.isAppFormValid = true;
      this.editCompany.apps.splice(index, 1);
    },
    addNewApp(isEditIndex) {
      this.isEditIndex = isEditIndex;
      if (this.isEditIndex != null && this.editCompany.apps[this.isEditIndex]) {
        this.newApp = this.editCompany.apps[this.isEditIndex];
      } else {
        this.newApp = {
          id: uuidv4(),
          name: "",
          url: "",
          domains: [],
          enabled: true,
          jwtDuration: "28800",
          allowRoles: [],
        };
      }
      this.appModal = true;
    },
    saveAndCloseAppModal() {
      this.newApp.eri = "eudata:convyai:" + this.editCompany.uuid + ":app:" + this.newApp.id;
      if (this.isEditIndex != null && this.editCompany.apps[this.isEditIndex]) {
        this.editCompany.apps[this.isEditIndex] = JSON.parse(JSON.stringify(this.newApp));
      } else {
        this.editCompany.apps.push(JSON.parse(JSON.stringify(this.newApp)));
      }
      this.closeAppModal();
    },
    closeAppModal() {
      this.isAppFormValid = true;
      this.appModal = false;
      this.isEditIndex = null;
    },
    openKibanaPage() {
      if (this.editCompany.crabCustomSettings.enabled && this.editCompany.crabCustomSettings.kibanaUrl) {
        window.open(this.editCompany.crabCustomSettings.kibanaUrl);
      } else {
        window.open(this.$store.state.loginResponse.params.serverConfigurations.kibanaUrl);
      }
    },
    deleteCompany() {
      if (this.companyDetail.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_COMPANY_CREATION);
      }
    },
    async onDeleteCompanyConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/company/" + this.companyDetail.uuid);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_COMPANY_SUCCESS, {
          company: this.companyDetail.uuid,
          message: "Company " + this.companyDetail.name + " Deleted!",
        });
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_COMPANY_FAIL, {
          message: "Fail to delete Company " + this.companyDetail.name,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async saveCompany() {
      try {
        this.form.submitLoading = true;
        if (this.isEdit) {
          await this.$http.put("/company/" + this.companyDetail.name, this.editCompany);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_COMPANY_SUCCESS, {
            editCompany: this.editCompany,
            message: "Company " + this.companyDetail.name + " modified!",
          });
        } else {
          const result = await this.$http.post("/company", this.editCompany);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_COMPANY_SUCCESS, {
            editCompany: result.data,
            message: "Company " + this.editCompany.name + " created!",
          });
        }
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.EDIT_COMPANY_FAIL, {
          editCompany: this.editCompany,
          message: "Error while saving Company " + this.editCompany.name,
        });
      } finally {
        this.form.submitLoading = false;
      }
    },
    clipboardSuccessHandler({ value, event }) {
      event.preventDefault();
      this.$refs.resultSnackbar.showSuccess("Token copied to clipboard " + value);
    },
    openInfo(id) {
      const col = document.getElementById("integration_col" + id);
      const item = document.getElementById("infoService" + id);
      if (!item.parentElement.classList.contains("infoCard_open")) {
        item.parentElement.classList.add("infoCard_open");
        col.classList.add("setMaxHeight");
      } else {
        // riduci
        item.parentElement.classList.remove("infoCard_open");
        col.classList.remove("setMaxHeight");
      }
    },
  },
};
</script>
<style scoped>
.mylabel {
}
.mylabel.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 13px;
}
.infoService {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  /* height: 0!important;
   transition: height 2s ease-out; */
}
.infoCard_open {
  max-height: 800px;
  transition: max-height 0.8s ease-in;
  /* height: 200px!important;
  transition: height 2s ease-in; */
}
.defineheight {
  max-height: 143px;
  transition: max-height 0.8s ease-out;
  /* height: 133px!important;
  transition: height 2s ease-out; */
}
.setMaxHeight {
  max-height: 800px;
  transition: max-height 0.5s ease-in;
  /* height: 200px!important;
  transition: height 2s ease-in; */
}
</style>
