import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import customFilter from "./plugins/customFilter";

import "@eudata/eu-login-page/dist/eu-login-page.css";
import "@eudata/eu-contact-modal/dist/eu-contact-modal.css";

import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";

import Clipboard from "v-clipboard";
import FlagIcon from "@eudata/eu-flags";
import perfectScrollbar from "vue2-perfect-scrollbar";
import browserDetect from "vue-browser-detect-plugin";
import ShortKeys from "vue-shortkey";
import ImageUploader from "vue-image-upload-resize";
import VueWordCloud from "vuewordcloud";

import JsonEditorVue from "json-editor-vue";

//import Fragment from "vue-fragment";

import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import Axios from "axios";

import "./assets/scss/custom_style.scss";
import "./assets/scss/jse-theme-dark.scss";

import VueDndZone from "vue-dnd-zone";
import "vue-dnd-zone/vue-dnd-zone.css";
import ECharts from "vue-echarts";
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, GaugeChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  MarkPointComponent,
  MarkLineComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  GraphicComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  BarChart,
  GaugeChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  MarkPointComponent,
  MarkLineComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  GraphicComponent,
]);
// registering custom theme for echarts
import darkTheme from "./helpers/echartsthemes/convyadmin-dark.project.json";
import lightTheme from "./helpers/echartsthemes/convyadmin-light.project.json";
registerTheme("darkTheme", darkTheme.theme);
registerTheme("lightTheme", lightTheme.theme);

Vue.prototype.$httpNoAuth = Axios.create({
  baseURL: "..",
  timeout: 300000,
});
Vue.prototype.$httpAuth = Axios.create({
  baseURL: "..",
  timeout: 300000,
});
Vue.prototype.$http = Axios.create({
  baseURL: "../provisioning",
  timeout: 300000,
});

for (let name in customFilter) {
  Vue.filter(name, customFilter[name]);
}

Vue.use(VueDndZone);
Vue.use(Clipboard);
Vue.use(browserDetect);
Vue.use(perfectScrollbar);
Vue.use(FlagIcon);
Vue.use(ShortKeys);
Vue.use(ImageUploader);
Vue.use(JsonEditorVue);
//Vue.use(Fragment.Plugin);
Vue.component(VueWordCloud.name, VueWordCloud);
Vue.component("VChart", ECharts);

if (process.env.NODE_ENV === "production") {
  Vue.config.productionTip = false;
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  JsonEditorVue,
  render: (h) => h(App),
}).$mount("#app");
