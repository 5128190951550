<template>
  <v-card flat>
    <v-card-text>
      <v-switch
        v-model="configuration.input_processors.enable_input_processor"
        class="mb-2"
        color="primary"
        label="Enable input processor"
        @change="onInputProcessorEnabled"
      />
      <div v-if="inputProcessorEnabled">
        <v-autocomplete
          v-model="configuration.input_processors.input_processor_type"
          class="ma-0 pa-0"
          label="Select input processor"
          :rules="[requiredField]"
          :items="inputProcessorsList"
        />
        <v-subheader class="mt-2 px-0 subtitle">
          Select on which nodes uses the input processor by default
        </v-subheader>
        <v-checkbox v-model="configuration.input_processors.is_active_on_question" class="mt-0 pt-0" color="primary" label="Question node" />
        <v-checkbox v-model="configuration.input_processors.is_active_on_menu" class="mt-0 pt-0" color="primary" label="Menu node" />
        <v-checkbox v-model="configuration.input_processors.is_active_on_survey" class="mt-0 pt-0" color="primary" label="Survey node" />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
export default {
  name: "InputProcessorsConfiguration",
  props: ["configuration", "inputProcessors"],
  computed: {
    inputProcessorsList() {
      let list = [];
      this.inputProcessors.forEach((element) => {
        list.push({
          text: element.name,
          value: element.type + "-" + element.name,
        });
      });
      return list;
    },
    inputProcessorEnabled: {
      get() {
        return this.configuration.input_processors.enable_input_processor;
      },
      set(val) {
        this.configuration.input_processors.enable_input_processor = val;
      },
    },
  },
  methods: {
    ...fieldValidators,
    onInputProcessorEnabled(e) {
      this.inputProcessorEnabled = e;
      if (e) {
        this.configuration.input_processors.is_active_on_menu = true;
        this.configuration.input_processors.is_active_on_question = true;
        this.configuration.input_processors.is_active_on_survey = true;
      }
    },
  },
};
</script>
