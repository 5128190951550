<template>
  <v-container fluid class="ma-0 pa-0" fill-height>
    <ResultSnackbar ref="resultSnackbar" @closeClicked="setFocusOnfFlowdesigner" />

    <InputTextDialog :showDiaglog="showCloneDialog" @dialogSubmit="cloneFlowConfirm" @dialogDeny="showCloneDialog = false" />

    <InputTextDialog
      :showDiaglog="showNewVersionFlowDialog"
      title="New Flow Version"
      inputLabel="Flow Version"
      submitButtonLabel="Save"
      @dialogSubmit="newFlowVersionConfirm"
      @dialogDeny="showNewVersionFlowDialog = false"
    />

    <ConfirmDialog
      :showConfirm="showConfirmLeavePage"
      message="Data will be lost if you leave the page and you will be exiting the edit mode"
      @dialogConfirm="confirmLeavePage()"
      @dialogDeny="showConfirmLeavePage = false"
    />

    <v-btn-toggle v-if="isDevelopment" v-model="flowDesignerVersion" style="position: fixed; top: 150px; right: 30px" mandatory @change="switchFlowDesigner">
      <v-btn :value="0">
        REMOTE
      </v-btn>
      <v-btn :value="1">
        LOCAL
      </v-btn>
    </v-btn-toggle>

    <iframe id="flowDesignerFrame" class="hidden" scrolling="no" src />
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import InputTextDialog from "../../components/other/InputTextDialog";
import bb from "../../helpers/blockBuilder";
import openFD from "../../helpers/openFD";
import merge from "deepmerge";
import EventBus from "../../event-bus";

export default {
  name: "FlowDesigner",
  components: {
    ResultSnackbar,
    InputTextDialog,
    ConfirmDialog,
  },
  beforeRouteLeave(to, from, next) {
    if (this.flowLock && this.$store.getters.getUsername === this.flowLock.user && !this.$route.query.forceReadOnly) {
      if (this.showWarningOfDataLoss) {
        this.showConfirmLeavePage = true;
        this.nextPageFunction = next;
        return;
      }
    }
    this.releaseLock();
    next();
  },
  data() {
    return {
      flowDesignerVersion: 0,
      currentFlowDesignerVersion: 0,
      isVisualIVR: false,
      isOutbound: false,
      showCloneDialog: false,
      showNewVersionFlowDialog: false,
      newFlowVersionParams: null,
      alreadySaved: true,
      flow: null,
      bots: null,
      company: this.$store.getters.getSelectedCompany,
      userID: this.$store.getters.getUserID,
      inputProcessorsList: null,
      flowsHooksList: null,
      keyboardsList: null,
      flowsSemanticHooksList: null,
      emailBoxesList: null,
      eventsNameList: null,
      semanticEnginesList: null,
      defaultConfiguration: null,
      renewLockTimeout: null,
      flowLock: null,
      loadRequirementsInterval: null,
      loadRequirementsTimer: 30000,
      callbackFunction: null,
      blockDefinition: null,
      blocksBuilder: null,
      showWarningOfDataLoss: false,
      showConfirmLeavePage: false,
      nextPageFunction: null,
      integrations: {},
      flowsVariables: null,
      waTemplates: null,
      redisIndexes: [],
      humanAgentsDetails: {
        agents: [],
        queues: [],
        profiles: [],
      },
    };
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV != "production";
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    if (!this.$route.query["flow"]) {
      this.$router.push("/bots");
      return;
    }
    let flowDesignerVersion = this.$store.getters.getSetting("flowDesigner");
    if (flowDesignerVersion) {
      this.flowDesignerVersion = flowDesignerVersion;
      this.currentFlowDesignerVersion = flowDesignerVersion;
    } else {
      this.flowDesignerVersion = 0;
      this.currentFlowDesignerVersion = 0;
    }
    var that = this;
    window.onbeforeunload = function () {
      that.releaseLock();
    };
    this.initMessageListener();
    this.loadFlow()
      .finally(() => {
        return this.loadBots();
      })
      .finally(() => {
        return this.getVariablesName();
      })
      .finally(() => {
        if (this.bots[this.$route.query["botId"]]) {
          this.isVisualIVR = this.bots[this.$route.query["botId"]].isVisualIvr;
          this.isOutbound = this.bots[this.$route.query["botId"]].isOutbound;
        }
        return this.loadRequirements(false);
      })
      .finally(() => {
        this.loadRequirementsInterval = setInterval(this.loadRequirements.bind(this, true), this.loadRequirementsTimer);
        if (this.flowDesignerVersion == 0) {
          this.initIframe();
        } else {
          this.initLocalIframe();
        }
      });
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    if (this.callbackFunction) {
      window.removeEventListener("message", this.callbackFunction);
    }
    if (this.loadRequirementsInterval) {
      clearInterval(this.loadRequirementsInterval);
    }
    if (this.renewLockTimeout) {
      clearTimeout(this.renewLockTimeout);
    }
    this.releaseLock();
  },
  methods: {
    changeTheme() {
      if (this.flowDesignerVersion == 0) {
        this.initIframe();
      } else {
        this.initLocalIframe();
      }
    },
    confirmLeavePage() {
      this.releaseLock();
      this.nextPageFunction();
    },
    switchFlowDesigner(e) {
      if (typeof e == "undefined") return;
      this.flowDesignerVersion = e;
      this.$store.dispatch("AddSetting", {
        flowDesigner: this.flowDesignerVersion,
      });
      if (this.currentFlowDesignerVersion != this.flowDesignerVersion) {
        this.currentFlowDesignerVersion = this.flowDesignerVersion;
        if (this.flowDesignerVersion == 0) {
          this.initIframe();
        } else {
          this.initLocalIframe();
        }
      }
    },
    initLocalIframe() {
      let iframe = document.getElementById("flowDesignerFrame");
      iframe.src =
        "http://localhost:8080" +
        "?company=" +
        this.company +
        "&flow=" +
        this.$route.query.flow +
        "&version=" +
        this.$route.query.version +
        "&nodeId=" +
        this.$route.query.nodeId +
        (this.$vuetify.theme.dark ? "&dark=true" : "") +
        "&crab=true" +
        ("&random=" + new Date().getTime()) +
        (this.$route.query.semanticHooks ? "&semanticHooks=" + this.$route.query.semanticHooks : "") +
        "?user=" +
        this.userID;
    },
    releaseLock() {
      this.$http.delete("/lock-flow/" + this.$route.query.flow + "/" + this.$route.query.version);
    },
    initMessageListener() {
      this.callbackFunction = this.flowDesignerEventListener.bind(this);
      window.addEventListener("message", this.callbackFunction);
    },
    setScene() {
      let engines = this.semanticEnginesList;
      if (this.isVisualIVR) {
        engines = this.filterEnginesForIVR();
      }
      this.blocksBuilder = bb.getBlocksBuilder({
        nodeType: this.nodeType,
        blockDefinition: this.blockDefinition,
        engines: engines,
        inputProcessors: this.inputProcessorsList,
        params: merge({}, this.$route.query),
        languageList: this.$store.getters.getLanguages,
        defaultLang: this.$store.getters.getUserLanguage,
        role: this.$store.getters.getUserRole,
      });
      let scene = this.blocksBuilder.getScene(this.flow, this.bots[this.$route.query["botId"]], this.defaultConfiguration, this.flowsVariables);
      // se ho questo flag, lo elimino per evitare di sporcare il json e lo salvo subito
      if (scene.attr.autosaveRequired) {
        delete scene.attr.autosaveRequired;
        const savedFlow = this.blocksBuilder.getFlow(
          scene,
          this.$route.query["botId"],
          this.flow,
          this.$route.query["version"],
          this.bots[this.$route.query["botId"]],
        );
        this.saveFlow(savedFlow, this.$route.query["version"], false, true);
        // sovrascrivo subito il flusso in modo che costruisco la nuova scena con il flusso aggiornato
        this.flow = savedFlow;
        scene = this.blocksBuilder.getScene(this.flow, this.bots[this.$route.query["botId"]], this.defaultConfiguration, this.flowsVariables);
      }
      this.sendMsgToIframe({
        event: "UPDATE_SCENE",
        params: scene,
      });
    },
    //Save as New Version
    newFlowVersionConfirm(inputValue) {
      this.saveFlow(
        this.blocksBuilder.getFlow(
          this.newFlowVersionParams.data.params,
          this.$route.query["botId"],
          this.flow,
          inputValue,
          this.bots[this.$route.query["botId"]],
        ),
        inputValue,
        true,
      );
      this.showNewVersionFlowDialog = false;
      this.newFlowVersionParams = null;
    },
    saveFlowWithNewVersion(e) {
      this.showNewVersionFlowDialog = true;
      this.newFlowVersionParams = e;
    },
    //CLONE
    async cloneFlowConfirm(inputValue) {
      try {
        let response = await this.$http.post(`/flow/clone/${this.$route.query["botId"]}/${this.$route.query["flow"]}/${this.$route.query["version"]}`, {
          flow: inputValue,
        });
        if (response.data) {
          this.$refs.resultSnackbar.showSuccess("Flow cloned!");
        } else {
          this.$refs.resultSnackbar.showError("Fail to clone flow!");
        }
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to clone flow: " + this.$route.query["flow"]);
      } finally {
        this.showCloneDialog = false;
      }
    },
    cloneFlow() {
      this.showCloneDialog = true;
    },
    //CHATBOT TESTER
    async initializeTester() {
      let flow = this.$route.query["flow"];
      let version = this.$route.query["version"];
      let bot_id = this.$route.query["botId"];
      let langFlow = null;

      try {
        let response = await this.$http.get("/flow/" + bot_id + "/" + flow + "/" + version);
        // Questo if serve per sanificare i template
        if (response.data.flow[flow].lang.toLowerCase() === "english" || response.data.flow[flow].lang.toLowerCase() === "italian") {
          langFlow = response.data.flow[flow].lang.toLowerCase().slice(0, 2);
        } else {
          langFlow = response.data.flow[flow].lang;
        }
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to test flow: " + flow);
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.OPEN_DEBUG_INTERFACE, {
          flow: flow,
          version: version,
          lang: langFlow,
          bot_id: bot_id,
        });
      }
    },
    //REDIREZIONE TO ADVANCED EDIT
    redirectToAdvancedEdit() {
      const bot = this.bots[this.$route.query["botId"]];
      if (bot?.isOutbound && bot?.flow === this.$route.query.flow && bot?.version === this.$route.query.version) {
        openFD.open(this.$route.query["botId"], this.$route.query.flow, this.$route.query.version, this.$route.query.nodeId);
      } else {
        openFD.openAdvancedEdit(this.$route.query["botId"], this.$route.query.flow, this.$route.query.version, this.$route.query.nodeId);
      }
    },
    async saveFlow(flow, version, clone, skipSuccessSnackbar) {
      try {
        let response = null;
        if (this.flow && !clone) {
          //salvataggio di flusso già esistente
          response = await this.$http.put("/flow/" + this.$route.query["botId"] + "/" + this.$route.query["flow"] + "/" + version, flow);
        } else {
          //primo salvataggio del flusso e clone di un flusso
          response = await this.$http.post("/flow/" + this.$route.query["botId"] + "/" + this.$route.query["flow"] + "/" + version, flow);
        }
        if (response.data) {
          if (!clone) this.flow = response.data;
          this.showWarningOfDataLoss = false;
          if (!skipSuccessSnackbar) {
            this.$refs.resultSnackbar.showSuccess("Flow Saved!");
          }
          this.alreadySaved = true;
          //faccio una loadrequirements cosi appena aggiorno un hook, nei semantic hook li ho subito aggiornati
          if (this.loadRequirementsInterval) {
            clearInterval(this.loadRequirementsInterval);
            this.loadRequirements(true);
            this.loadRequirementsInterval = setInterval(this.loadRequirements.bind(this, true), this.loadRequirementsTimer);
          }
        } else {
          this.$refs.resultSnackbar.showError("Fail to save flow");
        }
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to save flow");
      }
    },
    flowDesignerEventListener(e) {
      let botId = this.$route.query["botId"];
      if (!this.bots) return;
      let bot = this.bots[botId];
      switch (e.data.event) {
        case "READY":
          this.blockDefinition = {};
          for (let i = 0; i < e.data.params.length; i++) {
            let node = e.data.params[i];
            this.blockDefinition[node.name] = node;
          }
          this.updateLists();
          this.setScene();
          this.sendMsgToIframe({
            event: "UPDATE_LOCK",
            params: this.flowLock,
          });

          break;
        case "SAVE_FLOW":
          this.saveFlow(
            this.blocksBuilder.getFlow(
              e.data.params,
              this.$route.query["botId"],
              this.flow,
              this.$route.query["version"],
              this.bots[this.$route.query["botId"]],
            ),
            //Passo esplicitamente la versione perchè il save as new version esplicitamente ne passerà una diversa
            this.$route.query["version"],
          );
          break;
        case "TEST_FLOW":
          this.initializeTester();
          break;
        case "OPEN_ADVANCED_EDITOR":
          this.redirectToAdvancedEdit();
          break;
        case "SAVE_FLOW_WITH_NEW_VERSION":
          this.saveFlowWithNewVersion(e);
          break;
        case "CLONE_FLOW":
          this.cloneFlow();
          break;
        case "OPEN_NEW_TAB":
          this.openNewTab(e.data.params);
          break;
        case "FLOW_CHANGED":
          this.showWarningOfDataLoss = true;
          break;
        case "BACK_TO_FLOW_LIST":
          if (bot) {
            this.$router.push("/bots?q=" + bot.name).catch(() => {});
          } else {
            this.$router.push("/bots").catch(() => {});
          }
          break;
        case "CHECK_POINTERS_TO_NODE":
          this.checkPointersToNode(e.data.params);
          break;
      }
    },
    async checkPointersToNode(params) {
      try {
        let result = await this.$http.get("/flows/node-pointer/" + this.$route.query["flow"] + "/" + this.$route.query["version"] + "/" + params.hook);
        this.sendMsgToIframe({
          event: "POINTERS_TO_NODE",
          params: result.data,
        });
      } catch (e) {
        this.sendMsgToIframe({
          event: "POINTERS_TO_NODE",
          params: [],
        });
      }
    },
    openNewTab(params) {
      if (params.uuid) {
        openFD.openNewWindow(params.botId, params.flow, params.version, params.uuid);
      } else {
        openFD.openNewWindow(params.botId, params.flow, params.version);
      }
    },
    initIframe() {
      let iframe = document.getElementById("flowDesignerFrame");
      iframe.src = openFD.getIframeSrc(
        this.company,
        this.$route.query.flow,
        this.$route.query.version,
        this.$route.query.nodeId,
        this.$vuetify.theme.dark,
        this.$route.query.semanticHooks,
        this.userID,
      );
      if (this.$store.state.versions?.flowdesignerNeedRefresh) {
        let rndHash = Date.now().toString(36);
        iframe.src += "&rnd=" + rndHash;
      }
    },
    loadRequirements(updateLists) {
      return Promise.all([
        this.loadBots(),
        this.loadFlowsHooksAndSemanticHooksList(),
        this.loadInputProcessors(),
        this.loadKeyboards(),
        this.loadEmailBoxList(),
        this.loadEventNameList(),
        this.loadSemanticEngines(),
        this.loadFlow(),
        this.getPrivileges(),
        this.loadDefaultConfiguration(),
        this.loadIntegrations(),
        this.loadTemplatesWatsapp(),
        this.loadHumanAgentsConfiguration(),
        this.loadRedisIndexes(),
      ]).finally(() => {
        if (updateLists) {
          this.updateLists();
        }
      });
    },
    async getVariablesName() {
      //get per tutte le variabili custom create, presenti nella company
      try {
        return await this.$http.get("/flows/vars").then((response) => (this.flowsVariables = response.data));
      } catch {
        return;
      }
    },
    async getPrivileges() {
      if (!this.$route.query.flow || !this.$route.query.version) {
        return;
      }
      let role = this.$store.getters.getUserRole.toLowerCase();
      let forceReadonly = this.$route.query.forceReadOnly;
      let readOnlyMode = false;
      switch (role) {
        case this.$store.getters.getRoles.USER:
          readOnlyMode = true;
          break;
      }

      if (!readOnlyMode && !forceReadonly) {
        //se invece ho i permessi per modificare provo a prendere la lock
        try {
          let response = await this.$http.put("/lock-flow", { flow: this.$route.query.flow, version: this.$route.query.version });
          if (this.renewLockTimeout) {
            clearTimeout(this.renewLockTimeout);
          }
          this.renewLockTimeout = null;
          if (response.data === this.$store.getters.getUsername) {
            //La lock c'è e sono io
            if (this.flowLock && !this.flowLock.owner) {
              //al check prima non ero io! devo riscaricare il flusso prima di editarlo!
              this.loadFlow()
                .then(() => {
                  this.setScene();
                })
                .finally(() => {
                  this.flowLock = {
                    owner: true,
                    user: response.data,
                    readOnly: false,
                    disableAdvanceFunctions: role === "supervisor" ? true : false,
                  };
                  this.sendMsgToIframe({
                    event: "UPDATE_LOCK",
                    params: this.flowLock,
                  });
                });
            } else {
              this.flowLock = {
                owner: true,
                user: response.data,
                readOnly: false,
                disableAdvanceFunctions: role === "supervisor" ? true : false,
              };
              this.sendMsgToIframe({
                event: "UPDATE_LOCK",
                params: this.flowLock,
              });
            }
          } else {
            //La lock c'è e NON sono io
            //questo previene il fatto che per qualche strano motivo la lock possa essere presa da qualcuno che non ha un nome
            //potrebbe risolvere il problema segnalato da Luisa #3931
            if (response.data) {
              this.flowLock = {
                owner: false,
                user: response.data,
                readOnly: false,
                disableAdvanceFunctions: role === "supervisor" ? true : false,
              };
              this.sendMsgToIframe({
                event: "UPDATE_LOCK",
                params: this.flowLock,
              });
            }
          }
          if (!this.renewLockTimeout) {
            this.renewLockTimeout = setTimeout(this.getPrivileges.bind(this), 7000); //Cosi il check è più veloce
          }
        } catch {
          if (this.renewLockTimeout) {
            clearTimeout(this.renewLockTimeout);
          }
          this.renewLockTimeout = setTimeout(this.getPrivileges.bind(this), 7000);
          this.sendReadOnlyToFD();
        }
      } else {
        this.sendReadOnlyToFD();
      }
    },
    sendReadOnlyToFD() {
      //se non sono uno che puo modificare allora passo il flag readOnly a true
      this.flowLock = {
        owner: false,
        user: "",
        readOnly: true,
        disableAdvanceFunctions: true,
      };
      this.sendMsgToIframe({
        event: "UPDATE_LOCK",
        params: this.flowLock,
      });
    },
    sendMsgToIframe(params) {
      let iframe = document.getElementById("flowDesignerFrame");
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(params, "*");
      }
    },
    updateLists() {
      let engines = this.semanticEnginesList;
      if (this.isVisualIVR) {
        engines = this.filterEnginesForIVR();
      }
      let defaultEngine = Object.keys(engines)[0];
      for (let engineName in engines) {
        if (engines[engineName].engines[engines[engineName].name].default) {
          defaultEngine = engineName;
        }
      }
      this.sendMsgToIframe({
        event: "UPDATE_LISTS",
        params: {
          defaultEngine: defaultEngine,
          bots: this.bots,
          flowsHooksList: this.flowsHooksList,
          inputProcessorsList: this.inputProcessorsList,
          keyboardsList: this.keyboardsList,
          emailBoxesList: this.emailBoxesList,
          eventsNameList: this.eventsNameList,
          semanticEnginesList: engines,
          flowsSemanticHooks: this.flowsSemanticHooksList,
          permissions: this.$store.getters.getPermission,
          integrations: this.integrations,
          waTemplates: this.waTemplates,
          providerApi: "whatsapp_infobip",
          humanAgentsDetails: this.humanAgentsDetails,
          serverConfigurations: this.$store.state.loginResponse.params.serverConfigurations,
          redisIndexes: this.redisIndexes,
        },
      });
    },
    filterEnginesForIVR() {
      let result = {};
      for (let engineName in this.semanticEnginesList) {
        if (this.semanticEnginesList[engineName].engines[engineName].type == "EUDATA") {
          result[engineName] = this.semanticEnginesList[engineName];
        }
      }
      return result;
    },
    async loadHumanAgentsConfiguration() {
      this.humanAgentsDetails.queues = (await this.loadResource("/human-agents/queue")) || [];
      this.humanAgentsDetails.profiles = (await this.loadResource("/human-agents/profile")) || [];
      this.humanAgentsDetails.agents = (await this.loadResource("/human-agents/agent")) || [];
    },
    async loadRedisIndexes() {
      try {
        const response = await this.$httpAuth.get("/knowledge-base/indexing/index/list");
        this.redisIndexes = response.data.result;
      } catch (e) {
        this.redisIndexes = [];
      }
    },
    async loadResource(resource) {
      try {
        const response = await this.$http.get(resource);
        return response.data;
      } catch (e) {
        return null;
      }
    },
    async loadDefaultConfiguration() {
      try {
        let response = await this.$http.get("/flow-configurations");
        if (response.data) {
          this.defaultConfiguration = response.data;
          delete this.defaultConfiguration["system_jumps"];
          if (!this.defaultConfiguration.semantic_hooks.semanticHookList) {
            this.defaultConfiguration.semantic_hooks.semanticHookList = [];
          } else {
            for (var i = 0; i < this.defaultConfiguration.semantic_hooks.semanticHookList.length; i++) {
              if (!this.defaultConfiguration.semantic_hooks.semanticHookList[i].version) {
                if (!this.defaultConfiguration.semantic_hooks.semanticHookList[i].hook) {
                  this.defaultConfiguration.semantic_hooks.semanticHookList[i].hook = "begin";
                }
                var oldTarget = this.defaultConfiguration.semantic_hooks.semanticHookList[i].target;
                if (oldTarget) {
                  this.defaultConfiguration.semantic_hooks.semanticHookList[i].target = oldTarget.split("-")[0];
                  this.defaultConfiguration.semantic_hooks.semanticHookList[i].version = oldTarget.split("-")[1];
                }
              }
            }
          }
          if (this.defaultConfiguration.error.jump_to) {
            if (typeof this.defaultConfiguration.error.jump_to == "string") {
              this.defaultConfiguration.error.jump_to = {
                flow: this.defaultConfiguration.error.jump_to.split(" - ")[0],
                version: this.defaultConfiguration.error.jump_to.split(" - ")[1],
                hook: "begin",
              };
            }
          } else {
            this.defaultConfiguration.error.jump_to = {
              flow: "",
              version: "",
              hook: "",
            };
          }
          if (this.defaultConfiguration.inactivity.jump_to) {
            if (typeof this.defaultConfiguration.inactivity.jump_to == "string") {
              this.defaultConfiguration.inactivity.jump_to = {
                flow: this.defaultConfiguration.inactivity.jump_to.split(" - ")[0],
                version: this.defaultConfiguration.inactivity.jump_to.split(" - ")[1],
                hook: "begin",
              };
            }
          } else {
            this.defaultConfiguration.inactivity.jump_to = {
              flow: "",
              version: "",
              hook: "",
            };
          }
          if (!this.defaultConfiguration.menu_node_configurations) {
            this.defaultConfiguration.menu_node_configurations = this.$store.getters.getDefaultFlowConfiguration.menu_node_configurations;
          }
          if (!this.defaultConfiguration.global_variables) {
            this.defaultConfiguration.global_variables = this.$store.getters.getDefaultFlowConfiguration.global_variables;
          }
          if (!this.defaultConfiguration.inactivity.care_window) {
            this.defaultConfiguration.inactivity.care_window = this.$store.getters.getDefaultFlowConfiguration.inactivity.care_window;
          }
        } else {
          this.defaultConfiguration = this.$store.getters.getDefaultFlowConfiguration;
        }
      } catch {
        return;
      }
    },
    async loadFlowsHooksAndSemanticHooksList() {
      try {
        let response = await this.$http.get("/flows-targets-hooks");
        const semanticHooksList = {};
        const hooksList = {};
        if (response.data) {
          for (const f in response.data) {
            if (!semanticHooksList[f]) semanticHooksList[f] = {};
            if (!hooksList[f]) hooksList[f] = {};
            const versions = response.data[f];
            for (const v in versions) {
              if (!semanticHooksList[f][v]) semanticHooksList[f][v] = {};
              if (!hooksList[f][v]) hooksList[f][v] = [];
              semanticHooksList[f][v] = versions[v].semantic_hooks;
              hooksList[f][v] = versions[v].hooks;
            }
          }
        }
        this.flowsSemanticHooksList = semanticHooksList;
        this.flowsHooksList = hooksList;
      } catch {
        return;
      }
    },
    async loadEmailBoxList() {
      try {
        let response = await this.$http.get("/mail-settings");
        this.emailBoxesList = response.data;
        this.emailBoxesList["System Email Box"] = {
          name: "System Email Box",
        };
      } catch {
        return;
      }
    },
    async loadEventNameList() {
      try {
        await this.$http.get("/content-editor/list-events");
      } catch {
        return;
      }
    },
    async loadSemanticEngines() {
      try {
        let response = await this.$http.get("/semantic-engine");
        const engineList = {};
        for (const engine of response.data) {
          engineList[engine.name] = engine;
        }
        this.semanticEnginesList = engineList;
      } catch {
        return;
      }
    },
    async loadKeyboards() {
      try {
        let response = await this.$http.get("/keyboard");
        this.keyboardsList = response.data;
      } catch {
        return;
      }
    },
    async loadInputProcessors() {
      try {
        let response = await this.$http.get("/input-processor");
        const inputList = {};
        for (const input of response.data) {
          if (!inputList[input.type]) inputList[input.type] = [];
          inputList[input.type].push(input);
        }
        this.inputProcessorsList = inputList;
      } catch {
        return;
      }
    },
    async loadTemplatesWatsapp() {
      try {
        //lo carico già sull'admin quindi non ha senso fare la richiesta ma prendo quelli salvati sul profilo
        let templates = await this.$http.get("/whatsapp-template");
        this.waTemplates = templates.data;
        if (Object.keys(this.waTemplates).length > 0) {
          this.integrations.whatsapp_template = true;
        }
      } catch {
        return;
      }
    },
    async loadFlow() {
      if (this.alreadySaved) {
        try {
          let response = await this.$http.get("/flow/" + this.$route.query.botId + "/" + this.$route.query.flow + "/" + this.$route.query.version);
          this.flow = response.data;
        } catch {
          this.flow = null;
          this.alreadySaved = false;
        }
      } else {
        return;
      }
    },
    async loadBots() {
      try {
        let response = await this.$http.get("/bot");
        this.bots = response.data;
        //Tolgo le icon perchè non voglio iniettarle nel flow designer
        for (var bot in this.bots) {
          delete this.bots[bot].icon;
        }
      } catch {
        return;
      }
    },
    async loadIntegrations() {
      let rainbow = null;
      if (this.$store.getters.isIntegrationEnabled("rainbow")) {
        try {
          rainbow = await this.$http.get("/rainbow");
          if (
            (rainbow?.data?.appID != null ||
              rainbow?.data?.appSecret != null ||
              rainbow?.data?.host != null ||
              rainbow?.data?.login != null ||
              rainbow?.data?.password != null) &&
            this.$store.getters.isIntegrationEnabled("rainbow")
          ) {
            this.integrations.rainbow = "rainbow";
          }
        } catch (e) {
          rainbow = null;
        }
      }
      let ece = null;
      if (this.$store.getters.isIntegrationEnabled("ciscoECE")) {
        try {
          ece = await this.$http.get("/cisco-ece");
          if (
            (ece?.data?.clientKey != null || ece?.data?.clientSecret != null || ece?.data?.host != null) &&
            this.$store.getters.isIntegrationEnabled("ciscoECE")
          ) {
            this.integrations.ece = "ece";
          }
        } catch (e) {
          ece = null;
        }
      }
      let lp = null;
      if (this.$store.getters.isIntegrationEnabled("livePerson")) {
        try {
          lp = await this.$http.get("/liveperson");
          if (lp?.data?.host && lp?.data?.accountId && lp?.data?.clientId && lp?.data?.clientSecret && this.$store.getters.isIntegrationEnabled("livePerson")) {
            this.integrations.livePerson = "livePerson";
          }
        } catch (e) {
          lp = null;
        }
      }
      if (this.$store.getters.isIntegrationEnabled("wcs")) {
        this.integrations.wcs = "ConvyAI Agents";
      }
      //crab il primo controlla se il falg advanced è ok, il secondo se è abilitato crab come db
      this.integrations.crab = "crab";
      if (this.$store.getters.isIntegrationEnabled("crab")) {
        this.integrations.crab_advanced = true;
      }
      //webexteams
      if (this.$store.getters.isIntegrationEnabled("webexTeams")) {
        this.integrations.webexTeams = "webexTeams";
      }
      //openai
      if (this.$store.getters.isIntegrationEnabled("knowledgeBase")) {
        this.integrations.openai = "openai";
        this.integrations.knowledgeBase = "knowledgeBase";
      }
    },
    setFocusOnfFlowdesigner() {
      let iframe = document.getElementById("flowDesignerFrame");
      iframe.focus();
    },
  },
};
</script>

<style scoped>
#flowDesignerFrame {
  width: 100%;
  border: none;
  height: calc(100vh - 64px);
  overflow: hidden;
}
</style>
