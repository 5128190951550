var env = window.location.hostname;
var localStorageKey = "ConvyAdmin_State_" + env;
var localStorageSSOKey = "ConvyAdmin_SSO_" + env;

function getKey() {
  return localStorageKey;
}

function getSSOKey() {
  return localStorageSSOKey;
}

export default {
  getKey,
  getSSOKey,
};
