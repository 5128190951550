function getNewHash(botId, flowName, flowVersion, nodeId, semanticHooks, hash) {
  if (!hash) {
    hash = "#/flowDesigner";
  }
  let newHash = hash + "?";
  newHash += "&botId=" + botId;
  newHash += "&flow=" + flowName;
  newHash += "&version=" + flowVersion;

  if (nodeId != null && typeof nodeId != "undefined") {
    newHash += "&nodeId=" + nodeId;
  }
  if (semanticHooks != null && typeof semanticHooks != "undefined") {
    newHash += "&semanticHooks=" + semanticHooks;
  }
  return newHash;
}

export default {
  open: function (botId, flowName, flowVersion, nodeId, forceReadonly) {
    let newHash = getNewHash(botId, flowName, flowVersion, nodeId);
    if (forceReadonly) {
      newHash += "&forceReadOnly=" + forceReadonly;
    }
    let newUrl = window.location.href.replace(window.location.hash, newHash);
    window.location.href = newUrl;
  },
  openInNewTab: function (botId, flowName, flowVersion, nodeId, forceReadonly) {
    let newHash = getNewHash(botId, flowName, flowVersion, nodeId);
    if (forceReadonly) {
      newHash += "&forceReadOnly=" + forceReadonly;
    }
    let newUrl = window.location.href.replace(window.location.hash, newHash);
    window.open(newUrl);
  },
  openAdvancedEdit: function (botId, flowName, flowVersion, nodeId) {
    let newHash = getNewHash(botId, flowName, flowVersion, nodeId, null, "#/advancedEdit");
    let newUrl = window.location.href.replace(window.location.hash, newHash);
    window.location.href = newUrl;
  },
  openNewWindow: function (botId, flowName, flowVersion, nodeId, semanticHooks) {
    let newHash = getNewHash(botId, flowName, flowVersion, nodeId, semanticHooks);
    window.open(window.location.origin + window.location.pathname + newHash);
  },
  getIframeSrc: function (company, flow, version, nodeId, darkMode, semanticHooks) {
    let url = window.location.href.substring(0, window.location.href.indexOf("#"));
    url = url.replace("index.html", "");
    url += "FlowDesigner/index.html";

    return (
      url +
      "?company=" +
      company +
      "&flow=" +
      flow +
      "&version=" +
      version +
      "&nodeId=" +
      nodeId +
      (darkMode ? "&dark=true" : "") +
      "&crab=true" +
      ("&random=" + new Date().getTime()) +
      (semanticHooks ? "&semanticHooks=" + semanticHooks : "")
    );
  },
};
