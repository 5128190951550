<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <!-- Dialog per salvataggio criteria -->
    <v-dialog v-model="saveDialog" max-width="290" @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[simpleAlphaNumString]"
            :autocomplete="$store.getters.disableAutocomplete"
            :error-messages="errorNameMessage"
            @keypress.enter.prevent="saveCurrentCriteria"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined block :disabled="!newCriteriaName" @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :showConfirm="dialogDeleteCriteria"
      :message="deleteCriteriaMessage"
      @dialogConfirm="confirmDeleteCriteria"
      @dialogDeny="denyDeleteCriteria"
    />
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanels" :class="getMainRowMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-magnify
                </v-icon>Bot Navigation Statistics
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-form v-model="isFormValid">
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="botSelected" label="Bot" :items="getBotName" :rules="[requiredField]" />
                </v-col>
              </v-row>
              <!-- Riga per la gestione delle date -->
              <TimePickerRangeReport
                ref="timerPickerRow"
                @switchEnableDatePicker="enableDatePicker = $event"
                @setDateStart="updateDateTimeStart($event)"
                @setDateEnd="updateDateTimeEnd($event)"
              />
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn :class="getButtonMargin" :disabled="isLoadingData || searchButtonDisabled" color="success" @click="saveDialog = true">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  <span>Save</span>
                </v-btn>
                <v-btn
                  ref="searchBtn"
                  :loading="isLoadingData"
                  :class="getButtonMargin"
                  :disabled="searchButtonDisabled"
                  color="primary"
                  @click="searchData"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  <span>Search</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row>
              <SearchBar :searchString="searchStringCriteria" @input="searchStringCriteria = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length == 0" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Date Start
                        </th>
                        <th class="text-left">
                          Date End
                        </th>
                        <th class="text-left">
                          Bot
                        </th>
                        <th />
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="key">
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.name }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.start_date | formatVerboseDateTime }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.end_date | formatVerboseDateTime }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.bot }}
                        </td>
                        <td />
                        <td>
                          <v-btn text icon class="pl-3" @click="deleteCriteria(item.name)">
                            <v-icon left>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row v-if="queryResult" class="mx-0 px-0 my-2" style="height: 30px !important">
              <v-btn
                v-if="showDetailFlag"
                color="primary"
                small
                @click="
                  showDetailFlag = false;
                  pageToDisplay = 0;
                "
              >
                <v-icon small left>
                  mdi-keyboard-backspace
                </v-icon>back
              </v-btn>
            </v-row>
            <v-row justify="center" align="end" class="my-2">
              <v-col
                offset="1"
                cols="6"
                offset-sm="2"
                sm="5"
                offset-md="5"
                md="2"
                class="text-right"
              >
                <v-menu ref="menu" top>
                  <template #activator="{ on: menu }">
                    <v-tooltip color="primary" bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn :disabled="queryResult && queryResult.length == 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                          <v-icon small left>
                            mdi-download
                          </v-icon> Download
                        </v-btn>
                      </template>
                      <span>Choose the format and download dataset</span>
                    </v-tooltip>
                  </template>

                  <v-list>
                    <v-list-item @click="exportXlsx">
                      <v-list-item-title>
                        <v-icon left>
                          mdi-file-excel-outline
                        </v-icon>XLSX
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportCsv">
                      <v-list-item-title>
                        <v-icon left>
                          mdi-file-delimited-outline
                        </v-icon>CSV
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="searchString"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  style="margin-top: -3.5px"
                  :autocomplete="$store.getters.disableAutocomplete"
                />
              </v-col>
            </v-row>
            <v-data-table
              v-if="!showDetailFlag"
              must-sort
              :headers="headers"
              :items="queryResult"
              class="elevation-1"
              :search="searchString"
              hide-default-footer
              :page.sync="pageToDisplay"
            >
              <template slot="no-data">
                <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
                  Not enough data to render this table!
                </v-alert>
              </template>
              <template #[`item.tot`]="{ item }">
                <span class="pr-5">{{ item.tot }}</span>
              </template>
              <template #[`item.percentage`]="{ item }">
                <span class="pr-10">
                  <span v-if="item.percentage != 0">{{ item.percentage }} %</span>
                  <span v-else class="pr-6">-</span>
                </span>
              </template>
              <template #[`item.state`]="{ item }">
                <span> {{ item.state }}</span>
              </template>
              <template #item.detail="{ item }">
                <div v-if="item.state == 'OVERFLOW' && targetDetail.length > 0" class="text-right pr-6">
                  <v-tooltip color="primary" left>
                    <template #activator="{ on }">
                      <v-btn text icon color="primary" v-on="on" @click="showDetail(item.target)">
                        <v-icon color="primary">
                          mdi-page-next-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Show Detail</span>
                  </v-tooltip>
                </div>
              </template>
              <template #[`body.append`]>
                <tr v-if="queryResult.length > 0" class="total-background">
                  <td colspan="1" class="font-weight-bold">
                    TOTAL
                  </td>
                  <td colspan="1" class="font-weight-bold text-right pr-9">
                    {{ getTotalOccurrences }}
                  </td>
                  <td colspan="1" class="text-right">
                    <span v-if="getTotalOccurrences != 0.0" class="text-right pr-10 font-weight-bold">100 % </span>
                    <div v-else class="text-right pr-12 mr-4 font-weight-bold">
                      -
                    </div>
                  </td>
                  <td />
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="showDetailFlag"
              must-sort
              :headers="headersDetail"
              :items="targetDetail"
              class="elevation-1"
              :search="searchStringDetail"
              hide-default-footer
            >
              <template #[`item.tot`]="{ item }">
                <span class="pr-5">{{ item.tot }}</span>
              </template>
              <template #[`item.percentage`]="{ item }">
                <span class="pr-10"><span v-if="item.percentage != 0">{{ getPercentageDetail }} %</span>
                  <div v-else class="mr-7">-</div></span>
              </template>
              <template #[`body.append`]>
                <tr v-if="queryResult.length > 0" class="font-weight-bold">
                  <td>TOTAL</td>
                  <td class="text-right pr-9">
                    {{ getTotalDetailOccurrences }}
                  </td>
                  <td class="text-right">
                    <span class="text-right pr-10">{{ getTotalDetailOccurrences == 0.0 ? "-" : "100 %" }} </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import TimePickerRangeReport from "../../components/other/timePickerRangeReport";
import SearchBar from "../../components/other/SearchBar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import exportFile from "../../helpers/exportFile";
import merge from "deepmerge";
import moment from "moment";
import EventBus from "../../event-bus";

const Qs = require("qs");

export default {
  name: "BotNavigationStatistic",
  components: {
    ResultSnackbar,
    TimePickerRangeReport,
    ConfirmDialog,
    SearchBar,
  },
  data() {
    return {
      pageToDisplay: 0,
      botList: {},
      botSelected: "",
      showDetailFlag: false,
      expansionPanels: 0,
      searchCriterias: {},
      isFormValid: false,
      isLoadingData: false,
      saveDialog: false,
      enableDatePicker: true,
      dateStart: "",
      dateEnd: "",
      timeStart: "",
      timeEnd: "",
      newCriteriaName: "",
      dialogDeleteCriteria: false,
      deleteCriteriaMessage: "",
      criteriaToDelete: "",
      errorNameMessage: "",
      searchString: "",
      searchStringCriteria: "",
      searchStringDetail: "",
      forceRerender: 0,
      semanticConfidenceRanges: ["0-0", "1-40", "41-60", "61-85", "86-100"],
      headers: [
        { text: "Statistic", value: "state", width: "15%" },
        { text: "Occurrences", value: "tot", align: "right" },
        {
          text: "Percentage",
          value: "percentage",
          align: "right",
          class: "pr-12",
        },
        {
          text: "Details",
          value: "detail",
          sortable: false,
          align: "right",
          class: "pr-12",
        },
      ],
      headersDetail: [
        { text: "Bot", value: "bot_name" },
        { text: "Occurrences", value: "tot", align: "right" },
        {
          text: "Percentage",
          value: "percentage",
          align: "right",
          class: "pr-12",
        },
      ],
      queryResult: [],
      targetDetail: [],
    };
  },
  computed: {
    ...spacing,
    getDateStart() {
      return moment(this.dateStart).format("DD/MM/YYYY") + " " + this.timeStart + ":00";
    },
    getDateEnd() {
      return moment(this.dateEnd).format("DD/MM/YYYY") + " " + this.timeEnd + ":59";
    },
    getBotName() {
      var list = [];
      for (let botId in this.botList) {
        list.push(this.botList[botId].name);
      }
      list = list.sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return list;
    },
    searchButtonDisabled() {
      return this.isFormValid ? false : true;
    },
    getPercentageDetail() {
      for (var i = 0; i < this.queryResult.length; i++) {
        if (this.queryResult[i].state == "OVERFLOW" && this.targetDetail !== []) {
          return (this.targetDetail[0].tot / this.targetDetail[0].tot) * 100;
        }
      }
      return 0;
    },
    getTotalOccurrences() {
      let tot = 0;
      this.queryResult.forEach((item) => {
        tot += item.tot;
      });
      return tot;
    },
    getTotalDetailOccurrences() {
      let tot = 0;
      this.targetDetail.forEach((item) => {
        tot += item.tot;
      });
      return tot;
    },
    getSearchCriterias() {
      this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name.toLowerCase().indexOf(this.searchStringCriteria.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    try {
      let botList = await this.$http.get("/bot");
      this.botList = botList.data;
    } catch {
      this.$refs.resultSnackbar.showError("Error loading bot list");
    }

    try {
      let response = await this.$http.get("/search-criteria/BOT_NAVIGATION_DETAIL");
      for (let key in response.data) {
        if (response.data[key].isDateEnabled) {
          response.data[key].isDateEnable = response.data[key].isDateEnabled;
          delete response.data[key].isDateEnabled;
        }
      }
      this.searchCriterias = response.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...exportFile,
    ...fieldValidators,
    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    denyDeleteCriteria() {
      this.dialogDeleteCriteria = false;
      this.criteriaToDelete = "";
    },
    updateDateTimeStart(event) {
      this.dateStart = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeStart = event.split(" ")[1];
    },
    updateDateTimeEnd(event) {
      this.dateEnd = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeEnd = event.split(" ")[1];
    },
    searchData() {
      this.isLoadingData = true;
      let params = {
        type: "BOT_NAVIGATION_DETAIL",
        botName: this.botSelected,
        start_date: this.enableDatePicker ? this.getDate(this.getDateStart) : moment("1970-01-01", "YYYY-MM-DD").toDate(),
        end_date: this.enableDatePicker ? this.getDate(this.getDateEnd) : moment("2100-01-01", "YYYY-MM-DD").toDate(),
        scoreMin: 0,
        scoreMax: 10,
        filterOperator: "and",
        semanticConfidenceRanges: JSON.stringify(this.semanticConfidenceRanges),
      };
      this.$httpAuth
        .get("/report/bot-navigation-detail", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          this.isLoadingData = false;
          this.showDetailFlag = false;
          this.queryResult = result.data.result;
          this.targetDetail = result.data.botOverflowResult;
          this.expansionPanels = 2;
        })
        .catch((err) => {
          this.$refs.resultSnackbar.showError("Error Search Data: " + err.message);
          this.isLoadingData = false;
        });
    },
    getDate(data) {
      return moment(data, "DD/MM/YYYY HH:mm:ss").utc().toDate();
    },
    async saveCurrentCriteria() {
      let searchCriteriaConfig = {
        end_date: this.getDateEnd,
        start_date: this.getDateStart,
        flow: null,
        bot: this.botSelected,
        isDateEnable: this.enableDatePicker,
        name: this.newCriteriaName,
        scoreMax: "10",
        scoreMin: "0",
        intent: null,
        engine: null,
        filters: [],
        filterOperator: "Add Another",
        type: "BOT_NAVIGATION_DETAIL",
      };
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
        this.errorNameMessage = "Name Already Exists";
      } else {
        this.errorNameMessage = "";
        let newSearchCriteria = {
          searchCriteriaIdentifier: btoa(this.newCriteriaName),
          searchCriteriaConfig: searchCriteriaConfig,
        };

        try {
          let result = await this.$http.post("/search-criteria/", newSearchCriteria);
          this.saveDialog = false;
          if (result.data.result) {
            this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
            this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
            this.forceRerender++;
          } else {
            this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
          }
          this.newCriteriaName = "";
        } catch {
          this.saveDialog = false;
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaNamee);
        }
      }
    },
    deleteCriteria(name) {
      this.criteriaToDelete = name;
      this.deleteCriteriaMessage = "Delete " + name + " criteria permanently";
      this.dialogDeleteCriteria = true;
    },
    async confirmDeleteCriteria() {
      let key = btoa(this.criteriaToDelete);

      try {
        await this.$http.delete("/search-criteria/" + key);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.criteriaToDelete = "";
        this.deleteCriteriaMessage = "";
        this.dialogDeleteCriteria = false;
        this.$refs.resultSnackbar.showSuccess("Search Criteria deleted!");
      } catch {
        this.$refs.resultSnackbar.showError("Error deleting search criteria");
      }
    },
    checkIfSearchCriteriaExists(val) {
      return this.searchCriterias[btoa(val)] ? true : false;
    },
    getValue(name) {
      this.expansionPanels = 0;
      if (this.searchCriterias[btoa(name)].isDateEnable) {
        this.enableDatePicker = true;
        let start = this.searchCriterias[btoa(name)].start_date.split(" ");
        this.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.timeStart = start[1];
        let end = this.searchCriterias[btoa(name)].end_date.split(" ");
        this.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.timeEnd = end[1];
      } else {
        this.enableDatePicker = false;
      }
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, {
        datetimeStart: this.searchCriterias[btoa(name)].start_date,
        datetimeEnd: this.searchCriterias[btoa(name)].end_date,
        enable: this.searchCriterias[btoa(name)].isDateEnable,
      });
      this.botSelected = this.searchCriterias[btoa(name)].bot;
      this.triggerSearch();
    },
    triggerSearch() {
      setTimeout(() => {
        this.$refs["searchBtn"].$el.click();
      }, 500);
    },
    showDetail() {
      this.showDetailFlag = true;
    },
    exportXlsx() {
      this.showDetailFlag
        ? this.exportBotNavigationStatisticsXlsx(this.targetDetail, true, this.getPercentageDetail)
        : this.exportBotNavigationStatisticsXlsx(this.queryResult, false);
    },
    exportCsv() {
      this.showDetailFlag
        ? this.exportBotNavigationStatisticsCsv(this.targetDetail, true, this.getPercentageDetail)
        : this.exportBotNavigationStatisticsCsv(this.queryResult, false);
    },
  },
};
</script>
<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}
</style>
