<template>
  <v-app-bar app color="headingCard">
    <v-dialog v-model="switchCompanyDialog" max-width="290" persistent>
      <v-card>
        <v-card-title>Reload needed</v-card-title>
        <v-card-text class="mt-4 font-weight-light">
          You need to refresh the page to change the company
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn block color="success" outlined @click="confirmChanges">
                <v-icon left>
                  mdi-check
                </v-icon>Yes
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn block color="error" outlined @click="discardChanges">
                <v-icon left>
                  mdi-close
                </v-icon>No
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResultSnackbar ref="resultSnackbar" />
    <v-snackbar
      v-if="notificationOptions.type === 'Maintenance Mode'"
      v-model="notification"
      timeout="-1"
      min-width="400"
      multi-line
      top
      :color="getNotificationOptionsColor"
    >
      <v-row justify="center">
        <v-col cols="9">
          <div v-if="notificationOptions.result">
            <span v-if="hasRequiredRole($store.getters.getRoles.ROOT)">🛠️ Maintenance mode is on 🛠️</span>
            <span v-else>🛠️ Attention, maintenance mode is on and you will be logged out in one minute. Please save your work before loggig out 🛠️</span>
          </div>
          <div v-else>
            🛠️ Maintenance mode is now ended. You can keep using the service as usual 🛠️
          </div>
        </v-col>
        <v-col cols="3">
          <v-btn small dark text @click="notification = false">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-snackbar
      v-else
      v-model="notification"
      multi-line
      top
      right
      :color="getNotificationOptionsColor"
    >
      <div>
        <div v-for="key in Object.keys(notificationOptions)" :key="key">
          <span class="text-uppercase">{{ key }}</span>:
          <span class="text-capitalize">{{ notificationOptions[key] }}</span>
        </div>
      </div>
      <v-btn text @click="goToPage(notificationOptions)">
        View
      </v-btn>
    </v-snackbar>
    <v-app-bar-nav-icon dark style="background: #1d9096" @click="toggleLeftDrawer" />
    <v-toolbar-title class="hidden-sm-and-down toolbarText--text">
      <router-link class="white--text headline" to="/" style="text-decoration: none">
        <b>ConvyAI Admin</b>
      </router-link>
      <router-link class="white--text headline" to="/maintenance" style="text-decoration: none">
        <span v-if="isMaintenanceModeEnabled">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn class="error ml-5" small>
                  <v-icon> mdi-tools </v-icon>
                </v-btn>
              </span>
            </template>
            <span>Maintenance Mode ON</span>
          </v-tooltip>
        </span>
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <v-menu transition="slide-y-transition" width="340" :close-on-content-click="false">
      <template #activator="{ on }">
        <v-btn text class="mr-2" style="text-transform: none" dark v-on="on">
          <v-icon left>
            mdi-city
          </v-icon>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-list-item-title class="subtitle-1" v-on="on">
                    {{ getCompanyNameFromUUID(selectedCompany) }}
                  </v-list-item-title>
                </template>
                <span>{{ selectedCompany }}</span>
              </v-tooltip>
            </v-list-item-content>
          </v-list-item>
        </v-btn>
      </template>
      <v-list width="340">
        <v-text-field
          v-if="numberOfCompanies >= 8"
          v-model="searchCompanyString"
          dense
          hide-details
          outlined
          class="mx-2 mb-2"
          label="Search Company..."
          prepend-inner-icon="search"
          :autocomplete="$store.getters.disableAutocompleteOff"
          @click.stop="() => {}"
        />
        <v-list-item v-else class="px-3">
          <v-icon left>
            mdi-city
          </v-icon>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>{{ getCompanyNameFromUUID(selectedCompany) }}</v-list-item-title>
              <v-list-item-subtitle>{{ selectedCompany }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item>
        <v-divider />
        <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" :style="numberOfCompanies >= 8 ? 'height:280px' : ''">
          <v-list-item v-for="(company, i) in getCompanies" :key="i" class="mr-2" dense @click="showConfirmDialog(company)">
            <v-list-item-content>
              <v-list-item-title>{{ company.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ company.uuid }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="getCompanies.length === 0 && searchCompanyString.length > 0">
            <v-list-item-content>
              <v-list-item-title>No company match your search</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </perfect-scrollbar>
      </v-list>
    </v-menu>
    <v-menu transition="slide-y-transition" nudge-left="8">
      <template #activator="{ on }">
        <v-badge
          offset-x="8"
          offset-y="17"
          left
          overlap
          color="error"
          :value="pendingNotification.length > 0"
        >
          <template #badge>
            <span>{{ pendingNotification.length }}</span>
          </template>
          <v-list-item class="px-0 mt-n2 userProfileList pointer" v-on="on">
            <v-list-item-avatar size="45" class="mx-0 px-0" :color="$store.getters.getUserColor">
              <span
                v-if="!getAvatar(user).thereIsAvatar"
                style="font-size: 18px; font-weight: 600; margin-top: 4px"
                class="pb-1"
                :style="getAvatarFontColor($store.getters.getUserColor)"
              >
                {{ getUserInitials(user) }}</span>
              <v-img v-else :src="getAvatar(user).avatarPath" />
            </v-list-item-avatar>
            <v-list-item-content v-if="$vuetify.breakpoint.mdAndUp" class="ml-3">
              <v-list-item-title class="white--text">
                {{ $store.getters.getUserName }}
              </v-list-item-title>
              <v-list-item-subtitle class="white--text">
                {{ $store.getters.getRoleLabel($store.getters.getUserRole) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-badge>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="38" class="mr-4 px-0" :color="$store.getters.getUserColor">
              <span v-if="!getAvatar(user).thereIsAvatar" style="font-size: 1.5rem" class="pb-1" :style="getAvatarFontColor($store.getters.getUserColor)">
                {{ getUserInitials(user) }}</span>
              <v-img v-else :src="getAvatar(user).avatarPath" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $store.getters.getUserName }}</v-list-item-title>
              <v-list-item-subtitle>{{ $store.getters.getRoleLabel($store.getters.getUserRole) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" :style="pendingNotification.length >= 5 ? 'height:280px' : ''">
            <v-list-item v-for="(notification, index) in pendingNotification" :key="'notification_' + index" @click="removeNotification(index)">
              <v-list-item-icon>
                <v-icon v-if="notification.type !== 'maintenance'">
                  mdi-flash
                </v-icon>
                <v-icon v-else>
                  mdi-tools
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="notification.type !== 'maintenance'">
                {{ notification.data.name }} execution:
                <v-icon v-if="isNotificationOK(notification)" color="success" class="ml-2 mt-n1">
                  mdi-thumb-up
                </v-icon>
                <v-icon v-else color="error" class="ml-2 mt-n1">
                  mdi-thumb-down
                </v-icon>
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ notification.data.name }} status:
                <v-icon v-if="isNotificationOK(notification)" color="success" class="ml-2 mt-n1">
                  mdi-alarm-light-off
                </v-icon>
                <v-icon v-else color="error" class="ml-2 mt-n1">
                  mdi-alarm-light
                </v-icon>
              </v-list-item-title>
            </v-list-item>
          </perfect-scrollbar>
          <v-divider v-if="pendingNotification.length > 0" />
          <v-list-item-group class="" style="color: #1d9096; min-width: 220px">
            <v-list-item @click.stop="() => {}">
              <v-switch v-model="user.info.darkTheme" dense @change="updateProfile" @click.stop="() => {}" />
              <v-list-item-title @click.stop="() => {}">
                Light/Dark Theme
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/changeProfile">
              <v-list-item-icon class="ml-2">
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item class="aboutButton" @click="showAboutConvy">
              <v-list-item-icon class="ml-2">
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <v-tooltip bottom color="secondary">
      <template #activator="{ on }">
        <v-btn
          color="white"
          style="background: #1d9096"
          icon
          tile
          v-on="on"
          @click="logout"
        >
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>
<script>
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import avatars from "../../helpers/avatars";
import LocalStorage from "../../helpers/localStorage";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "Navbar",
  components: {
    ResultSnackbar,
  },
  props: ["isMaintenanceModeEnabled"],
  data() {
    return {
      pendingNotification: [],
      switchCompanyDialog: false,
      selectedCompany: this.$store.getters.getSelectedCompany,
      tmpCompany: null,
      notification: false,
      notificationOptions: {
        result: false,
        name: "",
        type: "",
      },
      searchCompanyString: "",
      numberOfCompanies: 0,
      user: this.$store.getters.getUser,
    };
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    getNotificationOptionsColor() {
      if (this.notificationOptions.type === "Maintenance Mode") {
        return this.notificationOptions.result ? "error" : "success";
      }
      if (this.notificationOptions.type === "Indexing Batch Terminated") {
        return this.notificationOptions.result != "COMPLETED" ? "orange lighten-2" : "green lighten-2";
      }
      if (this.notificationOptions.type === "batch") {
        let passed = 0;
        let failed = 0;
        for (let i = 0; i < this.pendingNotification.length; i++) {
          if (this.pendingNotification[i].data.result == true) {
            passed++;
          } else {
            failed++;
          }
        }

        if (passed == 0) {
          return "red lighten-2";
        }
        if (failed == 0) {
          return "green lighten-2";
        }
        return "orange lighten-2";
      } else {
        if (this.notificationOptions.result) {
          return "green lighten-2";
        } else {
          return "red lighten-2";
        }
      }
    },
    getCompanies() {
      this.searchCompanyString;
      return this.getUserCompanies()
        .filter(
          (company) =>
            company.name.toLowerCase().indexOf(this.searchCompanyString.toLowerCase()) !== -1 ||
            company.uuid.toLowerCase().indexOf(this.searchCompanyString.toLowerCase()) !== -1,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.LOGOUT, this.logout);
    EventBus.$on(this.$store.getters.getEvents.HIDE_ABOUT, this.closeAboutEvent);
    EventBus.$on(this.$store.getters.getEvents.NOTIFICATION, this.onNotificationReceived);
    EventBus.$on(this.$store.getters.getEvents.UPDATE_COMPANIES_LIST, this.notifyCompaniesList);
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.LOGOUT, this.logout);
    EventBus.$off(this.$store.getters.getEvents.HIDE_ABOUT, this.closeAboutEvent);
    EventBus.$off(this.$store.getters.getEvents.NOTIFICATION, this.onNotificationReceived);
    EventBus.$off(this.$store.getters.getEvents.UPDATE_COMPANIES_LIST, this.notifyCompaniesList);
  },
  methods: {
    ...avatars,
    ...checkRoleAndPermission,
    async updateProfile(value) {
      this.$vuetify.theme.dark = value;
      this.user.info.darkTheme = value;
      await this.$store.dispatch("UpdateUser", this.user);
      await this.$http.patch("/user/change-profile/" + this.user._id, this.user);
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_DARK_THEME, value);
    },
    isNotificationOK(notification) {
      if (notification.type === "maintenance") {
        return notification.data.status ? false : true;
      }
      return notification.data.result == true || notification.data.status == "COMPLETED";
    },
    goToPage(notificationOptions) {
      this.notification = false;
      if (notificationOptions.type == "batch" || notificationOptions.type == "single") {
        if (this.$router.currentRoute.path !== "/watchbot") {
          this.$router.push("/watchbot");
        }
      } else if (notificationOptions.type === "Indexing Batch") {
        if (this.$router.currentRoute.path !== "/indexing") {
          this.$router.push("/indexing");
        }
      }
    },
    getCompanyNameFromUUID(companyUUID) {
      const companies = this.$store.state.company.list;
      if (companies && companies.length > 0) {
        const company = companies.find((company) => company.uuid === companyUUID);
        return company ? company.name : "";
      }
      return "";
    },
    removeNotification(index) {
      this.notification = false;
      const notifications = this.pendingNotification.splice(index, 1);
      if (notifications?.length > 0) {
        if (notifications[0].type === "maintenance") {
          //se la notifica è di maintenance non devo fare nulla
          return;
        }
        if (notifications[0].type === "knowledge-base-indexing") {
          if (this.$router.currentRoute.path !== "/indexing") {
            this.$router.push("/indexing");
          }
        } else if (this.$router.currentRoute.path !== "/watchbot") {
          this.$router.push("/watchbot");
        } else {
          EventBus.$emit(this.$store.getters.getEvents.RELOAD_DATA);
        }
      }
    },
    onNotificationReceived(notifications) {
      let newNotifications = notifications.filter((obj) => obj.type === "watchbot");
      if (newNotifications?.length > 0) {
        this.pendingNotification = this.pendingNotification.concat(newNotifications);
        if (newNotifications[0] && newNotifications[0].data.type === "single") {
          this.notificationOptions.type = newNotifications[0].data.type;
          this.notificationOptions.name = newNotifications[0].data.name;
          this.notificationOptions.result = newNotifications[0].data.result;
        } else {
          if (newNotifications[0] && newNotifications[0].data.type == "batch") {
            this.notification = false;
            this.notificationOptions.type = newNotifications[0].data.type;
            this.notificationOptions.name = "all Scripts";
            let passed = 0;
            let failed = 0;
            for (let i = 0; i < this.pendingNotification.length; i++) {
              if (this.pendingNotification[i].data.result == true) {
                passed++;
              } else {
                failed++;
              }
            }
            if (passed == 0 || failed == 0) {
              if (passed > 0) {
                this.notificationOptions.result = "All " + this.pendingNotification.length + " scripts passed!";
              } else {
                this.notificationOptions.result = "All " + this.pendingNotification.length + " scripts failed!";
              }
            } else {
              this.notificationOptions.result = passed + " scripts passed and " + failed + " failed";
            }
          }
        }
        this.notification = true;
      }
      newNotifications = notifications.filter((obj) => obj.type === "knowledge-base-indexing");
      if (newNotifications?.length > 0) {
        this.pendingNotification = this.pendingNotification.concat(newNotifications);
        this.notificationOptions.type = "Indexing Batch";
        this.notificationOptions.name = newNotifications[0].data.name;
        this.notificationOptions.result = newNotifications[0].data.status;
        this.notification = true;
      }
      newNotifications = notifications.filter((obj) => obj.type === "maintenance" && new Date().getTime() - obj?.data?.timestamp < 120000);
      if (newNotifications?.length > 0) {
        this.pendingNotification = this.pendingNotification.concat(newNotifications);
        this.notificationOptions.type = "Maintenance Mode";
        this.notificationOptions.name = "Maintenance Mode";
        this.notificationOptions.result = newNotifications[0].data.status;
        this.notification = true;
        //alla ricezione della notifica di maintenence mode devo gestire la logout forzata con localStorage
        if (!this.hasRequiredRole(this.$store.getters.getRoles.ROOT)) {
          EventBus.$emit(this.$store.getters.getEvents.MAINTENANCE_MODE, newNotifications[0]);
        } else {
          ("sono root e quindi non devo fare la logout");
        }
      }
    },
    toggleLeftDrawer() {
      EventBus.$emit(this.$store.getters.getEvents.TOGGLE_LEFT_DRAWER);
    },
    closeAboutEvent() {
      //Rimozione manuale della selezione del bottone about perchè fa comparire una modale e non usa le rotte
      document.getElementsByClassName("aboutButton")[0].classList.remove("v-list-item--active");
    },
    async logout() {
      EventBus.$emit(this.$store.getters.getEvents.LOGGED_OUT);
      setTimeout(async () => {
        let jwt = this.$store.state.loginResponse.jwt;
        await this.$store.dispatch("Logoff");
        //Se ho il token jwt lo mando, cosi viene reportizzata la logout in modo corretto
        await this.$httpNoAuth.post("/auth/logout", null, { headers: { authorization: "Bearer " + jwt } });
        try {
          const content = localStorage.getItem(LocalStorage.getSSOKey());
          if (content) {
            const logoutPage = JSON.parse(content).logoutRedirect;
            if (logoutPage) {
              localStorage.removeItem(LocalStorage.getSSOKey());
              window.location.replace(logoutPage + "?jwt=" + jwt);
              return;
            }
          }
        } catch (e) {
          jwt = null;
        }
        await this.$router.push("/login").catch(() => {});
      }, 300);
    },
    showAboutConvy() {
      EventBus.$emit(this.$store.getters.getEvents.SHOW_ABOUT);
    },
    showConfirmDialog(company) {
      this.switchCompanyDialog = true;
      this.tmpCompany = company.uuid;
    },
    async confirmChanges() {
      try {
        const renewJwtResponse = await this.$http.get("/refresh-token/" + this.tmpCompany);
        if (renewJwtResponse.data) {
          await this.$store.dispatch("UpdateJWT", renewJwtResponse.data.jwt);
          this.$http.defaults.headers.common["authorization"] = "Bearer " + this.$store.state.loginResponse.jwt;
          this.$httpAuth.defaults.headers.common["authorization"] = "Bearer " + this.$store.state.loginResponse.jwt;
        }
        await this.$store.dispatch("UpdateSelectedCompany", this.tmpCompany);
        await this.$store.dispatch("AddSetting", { selectedCompany: this.tmpCompany });
        await this.$router
          .push({
            path: this.$router.currentRoute.path,
            query: {},
          })
          .catch(() => {});
      } finally {
        this.switchCompanyDialog = false;
        this.tmpCompany = null;
        location.reload();
      }
    },
    discardChanges() {
      this.switchCompanyDialog = false;
      this.selectedCompany = this.$store.getters.getSelectedCompany;
    },
    getUserCompanies() {
      let companies = this.$store.state.company.list;
      if (companies && companies.length > 0) {
        this.numberOfCompanies = companies.length;
        if (!this.selectedCompany) {
          this.selectedCompany = companies[0].uuid;
        }
        return companies.filter((company) => company.uuid.toLowerCase() !== this.selectedCompany.toLowerCase());
      }
      return [];
    },
    notifyCompaniesList() {
      this.$refs.resultSnackbar.showSuccess("Companies List Updated!");
    },
  },
};
</script>

<style>
.companySelection .mdi.mdi-city {
  color: #fff !important;
  caret-color: #fff !important;
}

.userProfileList {
  background-color: #1d9096 !important;
}

.aboutButton .v-list-item-group .v-list-item--active {
  color: #1d9096 !important;
}
</style>
