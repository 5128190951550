<template>
  <v-row id="customEngineRowForm">
    <v-col cols="12" md="4" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.engineURL"
        label="Engine URL"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[requiredField, isURL]"
        prepend-inner-icon="mdi-web"
      />
    </v-col>
    <v-col cols="12" md="4" :class="getFormColMargin">
      <v-select
        v-model.trim="engine.httpMethod"
        label="HTTP Method"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[requiredField]"
        :items="['POST', 'GET', 'PUT']"
        prepend-inner-icon="mdi-web"
      />
    </v-col>
    <v-col cols="12" md="4" :class="getFormColMargin">
      <v-select
        v-model.trim="engine.variableEncoding"
        label="Variable Encoding"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[requiredField]"
        :items="['QUERY STRING', 'JSON', 'FORM-DATA', 'X-WWW-FORM-URLENCODED', 'RAW']"
        prepend-inner-icon="mdi-tune"
      />
    </v-col>
    <v-col cols="12">
      <v-subheader>Sample JSON request body</v-subheader>
      <JsonEditorVue
        v-model="sampleRequestBody"
        readOnly
        :mainMenuBar="false"
        mode="text"
        style="height: 425px !important"
        class="ma-3 mt-0"
        :class="isDarkMode ? 'jse-theme-dark' : ''"
      />
    </v-col>
    <v-col cols="12" :class="getFormColMargin">
      <v-row>
        <v-col cols="12" md="3">
          <v-subheader class="pl-0 mt-3 ml-8">
            Intent validity threshold
          </v-subheader>
        </v-col>
        <v-col cols="12" md="9">
          <v-slider
            v-model="engine.threshold"
            min="0"
            max="100"
            thumb-label="always"
            thumb-size="22"
            class="mt-6"
          >
            <template #prepend>
              0
            </template>
            <template #append>
              100
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" :class="getFormColMargin">
      <simpletablewithactions
        title="Additional Headers"
        explanation="Here you can add additional parameters among the request headers, for example, if there is a need to support specific authentication methods."
        shortTitle="ce-headers"
        :object="engine.headers"
      />
    </v-col>
    <v-col cols="12" :class="getFormColMargin">
      <simpletablewithactions
        title="Additional Parameters"
        explanation="Here you can add additional parameters that, depending on the chosen encoding method, will be included in the request along with the default parameters."
        shortTitle="ce-params"
        :object="engine.parameters"
      />
    </v-col>
    <v-col cols="12" :class="getFormColMargin">
      <simpletablewithactions
        title="Custom Response Fields"
        explanation="If the used model returns a response different from what ConvyAI expects, here you can indicate within which fields ConvyAI should retrieve the value to associate with a specific key. You will be able to specify the field to use through xPath."
        shortTitle="ce-result"
        :choices="defaultResultFields"
        :object="engine.result"
      />
    </v-col>
    <!-- advanced configuration -->
    <v-expansion-panels class="my-6 mx-0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon color="primary">mdi-tune</v-icon>
            <span class="ml-4">Advanced parameters</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.temperature" label="Temperature" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_p" label="Top P" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.frequency_penality" label="Frequency Penality" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.presence_penality" label="Presence Penality" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import simpletablewithactions from "../../other/SimpleTableWithActions.vue";

export default {
  name: "Customengine",
  components: { simpletablewithactions },
  props: ["engine", "isEdit"],
  data() {
    return {
      showPassword: false,
      defaultResultFields: ["answer", "intent", "score", "entity", "entityValue", "sentiment", "language"],
      sampleRequestBody: {
        dialogId: "<dialogId|null>",
        userInput: "<userInput>",
        toneOfVoice: "<toneOfVoice>",
        additionalCommands: "<additionalCommands>",
        additionalContext: "<additionalContext>",
        index: "<index>",
        intents: ["<intent1>", "<intent2>"],
        entities: ["<entity1>", "<entity2>"],
        threshold: "<threshold>",
        history: [
          {
            role: "user|assistant",
            content: "text",
          },
        ],
        additionalParameters: {
          temperature: "<temperature>",
          top_p: "<top_p>",
          frequency_penalty: "<frequency_penalty>",
          presence_penalty: "<presence_penalty>",
        },
      },
    };
  },
  computed: {
    ...spacing,
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  created() {
    if (!this.engine.headers) this.engine.headers = {};
    if (!this.engine.parameters) this.engine.parameters = {};
    if (!this.engine.result) this.engine.result = {};
    if (!this.engine.threshold && this.engine.threshold !== 0) this.engine.threshold = 50;
    if (!this.engine.params) {
      this.engine.params = {
        temperature: "0.7",
        top_p: "1",
        frequency_penality: "0.0",
        presence_penality: "0.0",
      };
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
<style scoped>
.field-label {
  font-size: 13px;
}
.apikey-icon-prepend {
  font-size: 22px !important;
  position: relative !important;
  bottom: -4px;
}
.apikey-icon-append {
  font-size: 22px !important;
  margin-top: -4px !important;
}
</style>
