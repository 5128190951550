<template>
  <v-container fluid>
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanel" popout focusable @change="updateMainScrollbar">
        <v-expansion-panel :readonly="brokenFlowJumps.data.length == 0">
          <v-expansion-panel-header>
            <v-row align="center" no-gutters>
              <v-col cols="5" md="4" lg="3" align-self="center">
                <v-row align="center">
                  <v-badge v-if="brokenFlowJumps.data.length > 0" :content="brokenFlowJumps.data.length" color="error" overlap bordered>
                    <v-icon right color="warning">
                      mdi-alert
                    </v-icon>
                  </v-badge>
                  <v-icon v-else right color="success">
                    mdi-check-bold
                  </v-icon>
                  <span class="ml-8">{{ tabsTitle[0] }}</span>
                </v-row>
              </v-col>
              <v-col v-if="brokenFlowJumps.data.length > 0" class="caption pt-1" align-self="center">
                There are {{ brokenFlowJumps.data.length }} broken Flow Jumps
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :loading="loadingDatatable" :headers="headers" :items="brokenFlowJumps.data" :items-per-page="10" class="elevation-1">
              <template #item.botId="{ item }">
                {{ item.flowName }} ( version: {{ item.flowVersion }})
              </template>
              <template #item.target="{ item }">
                {{ item.flowTarget }} -> {{ item.versionTarget }} ->
                {{ item.hookTarget }}
              </template>
              <template #item.gotoButton="{ item }">
                <v-btn color="primary" small @click.prevent="gotoFlowDesigner(item)">
                  GO TO<v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :readonly="brokenHookJumps.data.length == 0">
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="5" md="4" lg="3" align-self="center">
                <v-row align="center">
                  <v-badge v-if="brokenHookJumps.data.length > 0" :content="brokenHookJumps.data.length" color="error" overlap bordered>
                    <v-icon right color="warning">
                      mdi-alert
                    </v-icon>
                  </v-badge><v-icon v-else right color="success">
                    mdi-check-bold
                  </v-icon>
                  <span class="ml-8">{{ tabsTitle[1] }}</span>
                </v-row>
              </v-col>
              <v-col v-if="brokenHookJumps.data.length > 0" class="caption pt-1" align-self="center">
                There are {{ brokenHookJumps.data.length }} broken Hook Jumps
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :loading="loadingDatatable" :headers="headers" :items="brokenHookJumps.data" :items-per-page="10" class="elevation-1">
              <template #item.botId="{ item }">
                {{ item.flowName }} ( version: {{ item.flowVersion }})
              </template>
              <template #item.target="{ item }">
                {{ item.flowTarget }} -> {{ item.versionTarget }} ->
                {{ item.hookTarget }}
              </template>
              <template #item.gotoButton="{ item }">
                <v-btn color="primary" small @click.prevent="gotoFlowDesigner(item)">
                  GO TO<v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :readonly="brokenSemanticHooks.data.length == 0">
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="5" md="4" lg="3" align-self="center">
                <v-row align="center">
                  <v-badge v-if="brokenSemanticHooks.data.length > 0" :content="brokenSemanticHooks.data.length" color="error" overlap bordered>
                    <v-icon right color="warning">
                      mdi-alert
                    </v-icon>
                  </v-badge><v-icon v-else right color="success">
                    mdi-check-bold
                  </v-icon>
                  <span class="ml-8">{{ tabsTitle[2] }}</span>
                </v-row>
              </v-col>
              <v-col v-if="brokenSemanticHooks.data.length > 0" class="caption pt-1" align-self="center">
                There are {{ brokenSemanticHooks.data.length }} broken Semantic Hooks
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :loading="loadingDatatable" :headers="headersSemanticHook" :items="brokenSemanticHooks.data" :items-per-page="10" class="elevation-1">
              <template #item.botId="{ item }">
                {{ item.flowName }} ( version: {{ item.flowVersion }})
              </template>
              <template #item.target="{ item }">
                {{ item.flowTarget }} -> {{ item.versionTarget }} ->
                {{ item.hookTarget }}
              </template>
              <template #item.gotoButton="{ item }">
                <v-btn color="primary" small @click.prevent="gotoFlowDesigner(item)">
                  GO TO<v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :readonly="brokenConfigurations.data.length == 0">
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="5" md="4" lg="3" align-self="center">
                <v-row align="center">
                  <v-badge v-if="brokenConfigurations.data.length > 0" :content="brokenConfigurations.data.length" color="error" overlap bordered>
                    <v-icon right color="warning">
                      mdi-alert
                    </v-icon>
                  </v-badge><v-icon v-else right color="success">
                    mdi-check-bold
                  </v-icon>
                  <span class="ml-8">{{ tabsTitle[3] }}</span>
                </v-row>
              </v-col>
              <v-col v-if="brokenConfigurations.data.length > 0" class="caption pt-1" align-self="center">
                There are {{ brokenConfigurations.data.length }} broken Configurations
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :loading="loadingDatatable"
              :headers="brokenConfigurations.headers"
              :items="brokenConfigurations.data"
              :items-per-page="10"
              class="elevation-1"
            >
              <template #item.gotoButton="{ item }">
                <v-btn color="primary" small @click.prevent="gotoFlowDesigner(item)">
                  GO TO<v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import openFD from "../../helpers/openFD";

export default {
  name: "FlowAnalyzer",
  data() {
    return {
      flows: null,
      hooks: null,
      flowsSemanticHooks: null,
      loadingDatatable: true,
      headers: [
        { text: "Flow", value: "botId" },
        { text: "Jump Target", value: "target" },
        { text: "Node UUID", value: "uuid" },
        { text: "", value: "gotoButton" },
      ],
      headersSemanticHook: [
        { text: "Flow", value: "botId" },
        { text: "Intent", value: "intent" },
        { text: "Hook Jump Target", value: "target" },
        { text: "", value: "gotoButton" },
      ],
      brokenFlowJumps: {
        data: [],
      },
      brokenHookJumps: {
        data: [],
      },
      brokenSemanticHooks: {
        data: [],
      },
      brokenConfigurations: {
        headers: [
          { text: "Type", value: "label" },
          { text: "Flow", value: "flow" },
          { text: "Version", value: "version" },
          { text: "Flow Target", value: "flowTarget" },
          { text: "Version Target", value: "versionTarget" },
          { text: "Hook Target", value: "hookTarget" },
          { text: "", value: "gotoButton" },
        ],
        data: [],
      },
      tab: "tab-0",
      expansionPanel: null,
      tabsTitle: ["Flow Jumps", "Hook Jumps", "Semantic Hooks", "Configurations"],
    };
  },
  computed: {
    ...spacing,
  },
  async mounted() {
    try {
      let flows = await this.$http.get("/flow");
      let flowsTarget = await this.$http.get("/flows-targets");
      this.flows = flows.data;
      this.hooks = flowsTarget.data;
      this.flowsSemanticHooks = this.getFlowsSemanticHook(flows);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.buildDataTable();
    }
  },
  methods: {
    getFlowsSemanticHook(flows) {
      let result = {};
      for (let flowName in flows) {
        let versionMap = {};
        for (let version in flows[flowName]) {
          if (flows[flowName][version]) {
            versionMap[version] = flows[flowName].semantic_hooks ? flows[flowName].semantic_hooks : {};
          }
        }
        result[flowName] = versionMap;
      }
      return result;
    },
    buildDataTable() {
      for (let flowName in this.flows) {
        if (flowName != "_configurations") {
          for (let flowVersion in this.flows[flowName]) {
            let flowObj = this.hooks[flowName][flowVersion];
            if (flowObj.scene) {
              let botId = flowObj.flow[flowName].bot_id;
              for (let nodeId in flowObj.nodes) {
                let node = flowObj.nodes[nodeId];
                let description = JSON.parse(node.description);
                // detection broken flow jumps
                if (node.action.type == "JUMP" && node.action.flow) {
                  let targetFlowName = node.action.flow;
                  let targetFlowVersion = node.action.version;
                  let targetFlowHook = node.action.hook;
                  if (!this.checkTarget(targetFlowName, targetFlowVersion, targetFlowHook)) {
                    let item = {
                      botId: botId,
                      flowName: flowName,
                      flowVersion: flowVersion,
                      flowTarget: targetFlowName,
                      versionTarget: targetFlowVersion,
                      hookTarget: targetFlowHook,
                      uuid: description.uuid,
                      type: "flow_jumps",
                    };
                    this.brokenFlowJumps.data.push(item);
                  }
                }
                // detection broken hook jumps
                if (node.action.type == "JUMP" && !node.action.flow && node.action.hook) {
                  let hookName = node.action.hook;
                  if (hookName.includes("-")) {
                    hookName = "begin";
                  }
                  if (!this.checkTarget(flowName, flowVersion, hookName)) {
                    let item = {
                      botId: botId,
                      flowName: flowName,
                      flowVersion: flowVersion,
                      flowTarget: flowName,
                      versionTarget: flowVersion,
                      hookTarget: hookName,
                      uuid: description.uuid,
                      type: "hook_jumps",
                    };
                    this.brokenHookJumps.data.push(item);
                  }
                }
              }
              // detection broken semantic hooks
              if (flowObj.semantic_hooks && Object.keys(flowObj.semantic_hooks).length > 0) {
                let engineName = Object.keys(flowObj["semantic_hooks"])[0];
                let semHooks = flowObj["semantic_hooks"][engineName];
                for (let intentName in semHooks) {
                  let semHook = semHooks[intentName];
                  if (!this.checkTarget(semHook.flow, semHook.version, semHook.hook)) {
                    let item = {
                      botId: botId,
                      flowName: flowName,
                      flowVersion: flowVersion,
                      flowTarget: semHook.flow,
                      versionTarget: semHook.version,
                      hookTarget: semHook.hook,
                      index: semHook.index,
                      intent: intentName,
                      type: "semantic_hooks",
                    };
                    this.brokenSemanticHooks.data.push(item);
                  }
                }
              }

              // detection broken conditional jumps
              if (flowObj.scene && flowObj.scene.configurations && flowObj.scene.configurations.conditional_jumps) {
                let conditionalJumps = flowObj.scene.configurations.conditional_jumps;
                for (let conditionalJump of Object.keys(conditionalJumps)) {
                  if (
                    !this.checkTarget(conditionalJumps[conditionalJump].flow, conditionalJumps[conditionalJump].version, conditionalJumps[conditionalJump].hook)
                  ) {
                    let obj = {
                      type: "conditional_jump",
                      label: "Conditional Jump",
                      name: conditionalJump,
                      flowTarget: conditionalJumps[conditionalJump].flow,
                      versionTarget: conditionalJumps[conditionalJump].version,
                      hookTarget: conditionalJumps[conditionalJump].hook,
                      flowName: flowName,
                      flowVersion: flowVersion,
                    };
                    this.brokenConfigurations.data.push(obj);
                  }
                }
              }

              // detection broken inactivity
              if (Object.keys(flowObj.flow).length > 0) {
                if (flowObj.flow[flowName].dialog_proactivity) {
                  let inactivity = flowObj.flow[flowName].dialog_proactivity;
                  if (
                    inactivity.jump_to_flow &&
                    inactivity.jump_to_version &&
                    inactivity.jump_to_hook &&
                    !this.checkTarget(inactivity.jump_to_flow, inactivity.jump_to_version, inactivity.jump_to_hook)
                  ) {
                    let obj = {
                      type: "inactivity",
                      label: "Inactivity",
                      flowTarget: inactivity.jump_to_flow,
                      versionTarget: inactivity.jump_to_version,
                      hookTarget: inactivity.jump_to_hook,
                      flowName: flowName,
                      flowVersion: flowVersion,
                    };
                    this.brokenConfigurations.data.push(obj);
                  }
                }
              }

              // detection broken error
              for (let node in flowObj.nodes) {
                let type = JSON.parse(flowObj.nodes[node].description).type;
                if (type == "error") {
                  let errorNode = flowObj.nodes[node];
                  if (errorNode.type == "JUMP") {
                    if (!this.checkTarget(errorNode.flow, errorNode.version, errorNode.hook)) {
                      let obj = {
                        type: "error",
                        label: "Error",
                        flowTarget: errorNode.flow,
                        versionTarget: errorNode.version,
                        hookTarget: errorNode.hook,
                        flow: flowName,
                        version: flowVersion,
                      };
                      this.brokenConfigurations.data.push(obj);
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.loadingDatatable = false;
    },
    checkTarget(flowName, flowVersion, flowHook) {
      return this.hooks[flowName] && this.hooks[flowName][flowVersion] && this.hooks[flowName][flowVersion].hooks.indexOf(flowHook) != -1;
    },
    gotoFlowDesigner(item) {
      if (item.type === "semantic_hooks") {
        openFD.openNewWindow(item.botId, item.flowName, item.flowVersion, null, item.intent);
      } else if (item.type === "inactivity" || item.type === "error" || item.type === "conditional_jump") {
        openFD.openNewWindow(item.botId, item.flowName, item.flowVersion, null);
      } else {
        openFD.openNewWindow(item.botId, item.flowName, item.flowVersion, item.uuid);
      }
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
  },
};
</script>
