<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="engine.url"
          label="Server URL"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-server-network"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <!-- le richieste in get non sono accettabili per motivi di sicurezza, quindi è stata tolta la scelta -->
        <v-autocomplete
          v-show="false"
          v-model="engine.method"
          :items="requestType"
          label="Request type"
          :rules="[requiredField]"
          prepend-inner-icon="swap_vert"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-switch
              v-if="engine.type != 'EUDATA'"
              v-model="engine.priorityScore"
              label="Enable intent priority based on higher Cogito score"
              color="primary"
              v-on="on"
            />
          </template>
          <span> When disambiguation question is not set, the intent is choosen based on it's cogito score and not by it's position on the Convy menu </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- tabella configurazioni  -->
    <v-row :class="getFormRowMargin" class="hidden-sm-and-down" style="margin-left: 1px">
      <v-btn color="primary" :class="getButtonMargin" small @click="show = !show">
        <v-icon left small>
          mdi-pencil
        </v-icon>
        <span v-if="!show">Show more parameters</span>
        <span v-else>Hide parameters</span>
      </v-btn>
    </v-row>

    <template v-if="show">
      <v-row :class="getFormRowMargin" class="mt-6">
        Type to add a configuration:
      </v-row>
      <v-form ref="addParameterForm" v-model="form.isValid">
        <v-row :class="getFormRowMargin">
          <v-col cols="4">
            <v-text-field
              v-model.trim="key"
              label="Key"
              :rules="[requiredField]"
              :error-messages="customErrorMessage"
              :autocomplete="$store.getters.disableAutocomplete"
              @change="checkKeyName"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model.trim="value" label="Value" :rules="[requiredField]" :autocomplete="$store.getters.disableAutocomplete" />
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" class="mt-4" small :disabled="!form.isValid || !isValidKey" @click="addItem()">
              <v-icon left small>
                mdi-plus
              </v-icon>Add Parameter
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row :class="getFormRowMargin">
        <v-data-table
          must-sort
          :headers="headers"
          :items="body"
          :items-per-page="10"
          class="elevation-1"
          dense
          disable-pagination
          style="width: 100%"
          hide-default-footer
        >
          <template #item.value="props">
            <v-edit-dialog :return-value.sync="props.item.value">
              {{ props.item.value }}

              <template #input>
                <v-text-field
                  v-model.trim="props.item.value"
                  label="Edit and press enter to confirm"
                  single-line
                  :autocomplete="$store.getters.disableAutocomplete"
                  :rules="[requiredField]"
                  @change="editItem(props.item.key, props.item.value)"
                  @keydown.enter.prevent="preventSave"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template #item.action="{ item }">
            <v-icon small @click="deleteItem(item)">
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </template>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Cogito",
  props: ["engine", "isEdit"],
  data() {
    return {
      show: false,
      requestType: ["POST"],
      serverUrl: ["http://94.177.185.30/AnswersServlet/rest/services/analyze"],
      form: {
        isValid: false,
      },
      isValidInlineChange: false,
      key: "",
      value: "",
      headers: [
        { text: "Key", value: "key" },
        { text: "Value", value: "value" },
        { text: "Actions", value: "action", sortable: false },
      ],
      body: [],
      customErrorMessage: "",
      isValidKey: true,
    };
  },
  computed: {
    ...spacing,
  },
  watch: {
    engine: {
      deep: true,
      handler(data) {
        //* al cambio della lingua,
        //* aggiorno la lingua presente nella tabella dei parameters
        for (let i = 0; i < this.body.length; i++) {
          if (this.body[i].key == "language") {
            this.body[i].value = data.data.language;
          }
        }
      },
    },
  },
  mounted() {
    this.engine.method = "POST";
    if (this.engine.data) {
      //* se c'è qualcosa è un engine già presente
      for (let i in this.engine.data) {
        this.body.push({
          key: i,
          value: this.engine.data[i],
        });
      }
    } else {
      //* se non c'è niente è un nuovo engine
      this.body = [
        { key: "text", value: "<input>" },
        { key: "project", value: "COGITO" },
        { key: "customer", value: "Eudata" },
        { key: "sector", value: "Standard" },
        { key: "user", value: "" },
        { key: "password", value: "" },
        { key: "sessionId", value: "<dialogId>" },
      ];

      for (let x in this.body) {
        this.$emit("addCogitoConfigurationCustom", {
          key: this.body[x].key,
          value: this.body[x].value,
        });
      }
    }
  },
  methods: {
    ...fieldValidators,
    deleteItem(item) {
      const index = this.body.indexOf(item);
      this.body.splice(index, 1);
      this.$emit("deleteCogitoConfigurationCustom", item.key);
    },
    addItem: function (editKey, editValue) {
      if (editKey && editValue) {
        //* Edit del valore
        this.$emit("addCogitoConfigurationCustom", {
          key: editKey,
          value: editValue,
        });
      } else {
        //* Nuovo dato
        this.body.push({
          key: this.key,
          value: this.value,
        });
        this.$emit("addCogitoConfigurationCustom", {
          key: this.key,
          value: this.value,
        });
        this.key = "";
        this.value = "";
        if (this.$refs.addParameterForm) this.$refs.addParameterForm.resetValidation();
      }
    },
    editItem: function (key, value) {
      this.isValidInlineChange = false;
      if (value) {
        this.isValidInlineChange = true;
        this.addItem(key, value);
      }
    },
    // this method prevents the dialog from closing during the INLINE change
    preventSave(event) {
      if (!this.isValidInlineChange) {
        event.stopImmediatePropagation();
      }
    },
    checkKeyName: function () {
      //* controllo univocità chiavi tabella
      for (let i in this.body) {
        if (this.body[i].key.toLowerCase() == this.key.toLowerCase().trim()) {
          this.isValidKey = false;
          this.customErrorMessage = "Key already present in table";
          break;
        } else {
          this.customErrorMessage = "";
          this.isValidKey = true;
        }
      }
    },
  },
};
</script>
