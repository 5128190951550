import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import store from "./store";
import checkRoleAndPermission from "./helpers/checkRoleAndPermission";
import ChangeProfile from "./views/profile/ChangeProfile";
import Settings from "./views/system/Settings";
import AdvacneServerSetting from "./views/system/AdvanceServerSettings";
import Loggers from "./views/system/Loggers";
import Maintenance from "./views/system/Maintenance";
import Companies from "./views/system/Companies";
import License from "./views/system/License";
import Users from "./views/advanced/Users";
import SemanticEngines from "./views/design/SemanticEngines";
import SemanticEngineIntentsAndEntities from "./components/design/SemanticEngineIntentsAndEntities";
import FlowConfigurations from "./views/design/FlowConfigurations";
import Propagate from "./views/tools/Propagate";
import Keyboards from "./views/tools/Keyboards";
import InputProcessors from "./views/tools/InputProcessors";
import Bots from "./views/design/Bots";
import FlowDesigner from "./views/design/FlowDesigner";
import AdvancedEdit from "./views/design/AdvancedEdit";
import ContentEditor from "./views/tools/ContentEditor";
import Watchbot from "./views/tools/Watchbot";
import ScheduledWatchbot from "./views/tools/ScheduledWatchbot";
import Export from "./views/tools/Export";
import Import from "./views/tools/Import";
import FlowAnalyzer from "./views/tools/FlowAnalyzer";
import Connectors from "./views/publish/Connectors";
import RainbowIntegration from "./views/publish/RainbowIntegration";
import CiscoECEIntegration from "./views/publish/CiscoECEIntegration";
import LivePersonIntegration from "./views/publish/LivePersonIntegration";
import Home from "./views/Home";
import Wallboard from "./views/analytics/Wallboards";
import companiesOverview from "./views/analytics/companiesOverview";
import scheduledReports from "./views/analytics/scheduledReports";
import Registry from "./views/report/Registry";
import DialogDetails from "./views/report/DialogDetails";
import SemanticStatistics from "./views/report/SemanticStatistics";
import FlowNavigationStatistics from "./views/report/FlowNavigationStatistics";
import BotNavigationStatistics from "./views/report/BotNavigationStatistics";
import SemanticConfidence from "./views/report/SemanticConfidence";
import ContactCenterStatistics from "./views/report/ContactCenterStatistics";
import Surveys from "./views/report/Surveys";
import Activity from "./views/advanced/Activity";
import EmailBoxes from "./views/advanced/EmailBoxes";
import PublicEntities from "./views/advanced/PublicEntities";
import VariabledToStore from "./views/advanced/VariablesToStore";
import SystemUsersOverview from "./views/system/SystemUsersOverview";
import CustomerGui from "./views/tools/CustomerGui";
import AgentDesktop from "./views/tools/AgentDesktop";
import RecoverPassword from "./views/RecoverPassword";
import Queue from "./views/humanAgents/Queues";
import Profiles from "./views/humanAgents/Profiles";
import Agents from "./views/humanAgents/Agents";
import Shortcodes from "./views/humanAgents/Shortcodes";
import AttachedVariables from "./views/humanAgents/AttachedVariables";
import PermissionSets from "./views/humanAgents/PermissionSet";
import ContactSurveys from "./views/humanAgents/ContactSurveys";
import Indexing from "./views/knowledgebase/Indexing.vue";
import SearchDocument from "./views/knowledgebase/SearchDocument.vue";
import KWBSettings from "./views/knowledgebase/KWBSettings.vue";
import AgentsSurveys from "./views/report/AgentsSurveys";
import ContactResults from "./views/report/ContactResults";
import App from "./views/advanced/App";
import RecordingIssuesManagement from "@/views/system/RecordingIssuesManagement";

const title = "ConvyAI Admin";
const titleSuffix = " - " + title;

Vue.use(Router);

let router = new Router({
  routes: [
    //Login
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        public: true,
        skipLoadingAnimation: true,
      },
    },
    //Recover Password
    {
      path: "/recoverPassword",
      name: "RecoverPassword",
      component: RecoverPassword,
      meta: {
        public: true,
        skipLoadingAnimation: true,
      },
    },
    //Toolbar
    {
      path: "/changeProfile",
      name: "ChangeProfile",
      component: ChangeProfile,
      meta: {
        title: "Profile",
      },
    },

    //System
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "Settings",
      },
    },
    {
      path: "/advanceServerSettings",
      name: "AdvanceServerSettings",
      component: AdvacneServerSetting,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "Settings",
      },
    },
    {
      path: "/loggers",
      name: "Loggers",
      component: Loggers,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "Loggers",
      },
    },
    {
      path: "/companies",
      name: "Companies",
      component: Companies,
      meta: {
        role: store.getters.getRoles.SUPERADMIN,
        title: "Companies",
      },
    },
    {
      path: "/license",
      name: "License",
      component: License,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "License",
      },
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: Maintenance,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "Maintenance",
      },
    },
    {
      path: "/systemUsersOverview",
      name: "SystemUsersOverview",
      component: SystemUsersOverview,
      meta: {
        role: store.getters.getRoles.SUPERADMIN,
        title: "Users",
      },
    },

    {
      path: "/recordingIssuesManagement",
      name: "RecordingIssuesManagement",
      component: RecordingIssuesManagement,
      meta: {
        role: store.getters.getRoles.ROOT,
        title: "Recordings analyzer",
      },
    },

    //Human Agents
    {
      path: "/queues",
      name: "Queues",
      component: Queue,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER,
        title: "Queues",
      },
    },
    {
      path: "/profiles",
      name: "Profiles",
      component: Profiles,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER,
        title: "Profiles",
      },
    },
    {
      path: "/agents",
      name: "Agents",
      component: Agents,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER,
        title: "Agents",
      },
    },
    {
      path: "/shortcodes",
      name: "Shortcodes",
      component: Shortcodes,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER,
        title: "Shortcodes",
      },
    },
    {
      path: "/handoverparameters",
      name: "AttachedVariables",
      component: AttachedVariables,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGER,
        title: "Handover parameters",
      },
    },
    {
      path: "/permissions",
      name: "PermissionSets",
      component: PermissionSets,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGER,
        title: "Permission Sets",
      },
    },
    {
      path: "/contactSurveys",
      name: "ContactSurveys",
      component: ContactSurveys,
      meta: {
        role: store.getters.getRoles.CONTACTCENTERMANAGER,
        title: "ContactSurveys",
      },
    },

    //KnowledgeBase
    {
      path: "/indexing",
      name: "Indexing",
      component: Indexing,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Indexing",
      },
    },
    {
      path: "/searchDocument",
      name: "SearchDocument",
      component: SearchDocument,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Document Management",
      },
    },
    {
      path: "/kwbSettings",
      name: "KWBSettings",
      component: KWBSettings,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Knowledge Base Settings",
      },
    },

    //Design
    {
      path: "/bots",
      name: "Bots",
      component: Bots,
      meta: {
        permission: "ReadOnlyFlows",
        title: "Bots",
      },
    },
    {
      path: "/flowDesigner",
      name: "FlowDesigner",
      component: FlowDesigner,
      meta: {
        permission: "ReadOnlyFlows",
        skipLoadingAnimation: true,
        title: " ",
      },
    },
    {
      path: "/semanticEngines",
      name: "SemanticEngines",
      component: SemanticEngines,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlySemanticEngines",
        title: "Semantic Engines",
      },
    },
    {
      path: "/semanticEngineIntentsAndEntities",
      name: "SemanticEngineIntentsAndEntities",
      component: SemanticEngineIntentsAndEntities,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Details",
      },
    },
    {
      path: "/flowConfigurations",
      name: "FlowConfigurations",
      component: FlowConfigurations,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyFlows",
        title: "Flow Configurations",
      },
    },

    // Tools
    {
      path: "/propagate",
      name: "Propagate",
      component: Propagate,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "FullAccessFlows",
        title: "Propagate",
      },
    },
    {
      path: "/keyboards",
      name: "Keyboards",
      component: Keyboards,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyKeyboards",
        title: "Keyboards",
      },
    },
    {
      path: "/inputProcessors",
      name: "InputProcessors",
      component: InputProcessors,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyInputProcessors",
        title: "Input Processors",
      },
    },
    {
      path: "/contentEditor",
      name: "ContentEditor",
      component: ContentEditor,
      meta: {
        permission: "ReadOnlyContentEditor",
        title: "Content Editor",
      },
    },
    {
      path: "/watchbot",
      name: "Watchbot",
      component: Watchbot,
      meta: {
        permission: "ReadOnlyWatchbot",
        title: "Watchbot",
      },
    },
    {
      path: "/scheduledWatchbot",
      name: "ScheduledWatchbot",
      component: ScheduledWatchbot,
      meta: {
        permission: "ReadOnlyWatchbot",
        title: "Scheduled Watchbot",
      },
    },
    {
      path: "/export",
      name: "Export",
      component: Export,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyImportExport",
        title: "Export",
      },
    },
    {
      path: "/import",
      name: "Import",
      component: Import,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyImportExport",
        title: "Import",
      },
    },
    {
      path: "/customerInterface",
      name: "CustomerGui",
      component: CustomerGui,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyImportExport",
        title: "Customer Interface",
      },
    },
    {
      path: "/agentDesktop",
      name: "AgentDesktop",
      component: AgentDesktop,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyImportExport",
        title: "Agent Desktop",
      },
    },
    {
      path: "/flowAnalyzer",
      name: "FlowAnalyzer",
      component: FlowAnalyzer,
      meta: {
        role: store.getters.getRoles.SUPERVISOR,
        permission: "ReadOnlyFlows",
        title: "Flow Analyzer",
      },
    },

    {
      path: "/advancedEdit",
      name: "AdvancedEdit",
      component: AdvancedEdit,
      meta: {
        permission: "FullAccessFlows",
        title: "Advanced Edit",
      },
    },

    //Publish
    {
      path: "/connectors",
      name: "Connectors",
      component: Connectors,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyWebConnectors",
        title: "Connectors",
      },
    },
    {
      path: "/rainbowIntegration",
      name: "RainbowIntegration",
      component: RainbowIntegration,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyIntegrations",
        title: "Rainbow",
      },
    },
    {
      path: "/ciscoECEIntegration",
      name: "CiscoECEIntegration",
      component: CiscoECEIntegration,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyIntegrations",
        title: "Cisco ECE",
      },
    },
    {
      path: "/livePersonIntegration",
      name: "LivePersonIntegration",
      component: LivePersonIntegration,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyIntegrations",
        title: "Live Person",
      },
    },
    //Analytics
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        permission: "ReadOnlyDashboard",
        title: "Home",
      },
    },
    {
      path: "/wallboard",
      name: "wallboard",
      component: Wallboard,
      meta: {
        permission: "ReadOnlyDashboard",
        title: "Wallboard",
      },
    },
    {
      path: "/companiesOverview",
      name: "companiesOverview",
      component: companiesOverview,
      meta: {
        permission: "AccessCompaniesOverview",
        title: "Companies Overview",
      },
    },
    {
      path: "/scheduledReports",
      name: "scheduledReports",
      component: scheduledReports,
      meta: {
        role: store.getters.getRoles.USER,
        permission: "ReadOnlyScheduledReports",
        title: "Scheduled Reports",
      },
    },

    //Report
    {
      path: "/registry",
      name: "Registry",
      component: Registry,
      meta: {
        permission: "AccessCharts",
        title: "Registry",
      },
    },
    {
      path: "/dialogDetails",
      name: "DialogDetails",
      component: DialogDetails,
      meta: {
        permission: "AccessDialogDetail",
        title: "Dialog Details",
      },
    },
    {
      path: "/semanticStatistics",
      name: "SemanticStatistics",
      component: SemanticStatistics,
      meta: {
        roleNot: [store.getters.getRoles.CONTACTCENTERMANAGER],
        permission: "AccessSemanticStatistics",
        title: "Semantic Statistics",
      },
    },
    {
      path: "/flowNavigationStatistics",
      name: "FlowNavigationStatistics",
      component: FlowNavigationStatistics,
      meta: {
        roleNot: [store.getters.getRoles.CONTACTCENTERMANAGER],
        permission: "AccessFlowNavigationStatistics",
        title: "Flow Navigation",
      },
    },
    {
      path: "/botNavigationStatistics",
      name: "BotNavigationStatistics",
      component: BotNavigationStatistics,
      meta: {
        roleNot: [store.getters.getRoles.CONTACTCENTERMANAGER],
        permission: "AccessFlowNavigationStatistics",
        title: "Bot Navigation",
      },
    },
    {
      path: "/semanticConfidence",
      name: "SemanticConfidence",
      component: SemanticConfidence,
      meta: {
        roleNot: [store.getters.getRoles.CONTACTCENTERMANAGER],
        permission: "AccessSemanticConfidence",
        title: "Semantic Confidence",
      },
    },
    {
      path: "/surveys",
      name: "Surveys",
      component: Surveys,
      meta: {
        roleNot: [store.getters.getRoles.CONTACTCENTERMANAGER],
        permission: "AccessSurvey",
        title: "Survey Details",
      },
    },
    {
      path: "/contactCenterStatistics",
      name: "ContactCenterStatistics",
      component: ContactCenterStatistics,
      meta: {
        permission: "AccessDialogDetail",
        title: "Contact Center Statistics",
      },
    },
    {
      path: "/agentsSurveys",
      name: "AgentsSurveys",
      component: AgentsSurveys,
      meta: {
        permission: "AccessDialogDetail",
        title: "Agents Surveys",
      },
    },
    {
      path: "/contactResults",
      name: "ReportContactResult",
      component: ContactResults,
      meta: {
        permission: "AccessDialogDetail",
        title: "Contact Results",
      },
    },

    //Advanced
    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyUsers",
        title: "Users",
      },
    },
    {
      path: "/activity",
      name: "Activity",
      component: Activity,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "AccessActivity",
        title: "Activity",
      },
    },
    {
      path: "/emailBoxes",
      name: "EmailBoxes",
      component: EmailBoxes,
      meta: {
        role: store.getters.getRoles.ADMIN,
        permission: "ReadOnlyemailBoxes",
        title: "Email Boxes",
      },
    },
    {
      path: "/publicVariables",
      name: "Public Variables",
      component: PublicEntities,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Public Variables",
      },
    },
    {
      path: "/variablesToStore",
      name: "Variables to Store",
      component: VariabledToStore,
      meta: {
        role: store.getters.getRoles.ADMIN,
        title: "Variables to Store",
      },
    },
    {
      path: "/apps",
      name: "Apps",
      component: App,
      meta: {
        role: store.getters.getRoles.USER,
        title: "App",
      },
    },
  ],
});

function handlePageTitle(to) {
  try {
    let prefix = "";
    if (to.query.engineName) {
      prefix = to.query.engineName + " ";
    }
    if (to.query.flow) {
      prefix = to.query.flow;
    }
    if (to.meta.title) document.title = prefix + to.meta.title + titleSuffix;
    else document.title = title;
  } catch (err) {
    document.title = title;
  }
}

router.beforeEach((to, from, next) => {
  handlePageTitle(to);

  //Gestione dei path pubblici e privati con redirezioni se necessarie
  if (to.meta && !to.meta.public && !store.getters.isLogged) {
    return next("/login");
  }
  //Se è già loggato lo spedisco alla home
  if (to.path === "/login" && store.getters.isLogged) {
    return next("/");
  }
  if (to.meta.public) {
    return next();
  }
  //Se non ha ruolo necessario lo rispedisco alla home
  if (to.meta && to.meta.role && !checkRoleAndPermission.hasRequiredRole(to.meta.role)) {
    return next("/");
  }
  if (to.meta && to.meta.roleNot && checkRoleAndPermission.hasRequiredRoleExcept(to.meta.roleNot)) {
    return next("/");
  }
  //Se non ha permission necessarie lo rispedisco alla home
  if (to.meta && to.meta.permission && !checkRoleAndPermission.hasRequiredPermission(to.meta.permission)) {
    return next("/");
  }
  return next();
});

export default router;
