<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <v-dialog v-model="saveDialog" max-width="290" @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[simpleAlphaNumString]"
            :error-messages="errorNameMessage"
            :autocomplete="$store.getters.disableAutocomplete"
            @keypress.enter.prevent="saveCurrentCriteria"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined block :disabled="!newCriteriaName" @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :showConfirm="dialogDeleteCriteria"
      :message="deleteCriteriaMessage"
      @dialogConfirm="confirmDeleteCriteria"
      @dialogDeny="denyDeleteCriteria"
    />
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanels" :class="getMainRowMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-magnify
                </v-icon>Surveys
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-form v-model="isFormValid">
              <v-row>
                <v-col cols="6" md="3">
                  <v-autocomplete
                    v-model="surveySelected"
                    label="Survey"
                    item-text="name"
                    item-value="eri"
                    return-object
                    :items="surveyList"
                    :rules="[requiredField]"
                  />
                </v-col>
              </v-row>
              <!-- Riga per la gestione delle date -->
              <TimePickerRangeReport
                ref="timerPickerRow"
                @switchEnableDatePicker="enableDatePicker = $event"
                @setDateStart="updateDateTimeStart($event)"
                @setDateEnd="updateDateTimeEnd($event)"
              />
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn :class="getButtonMargin" :disabled="isLoadingData || searchButtonDisabled" color="success" @click="saveDialog = true">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  <span>Save</span>
                </v-btn>
                <v-btn
                  ref="searchBtn"
                  :loading="isLoadingData"
                  :class="getButtonMargin"
                  :disabled="searchButtonDisabled"
                  color="primary"
                  @click="searchData"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  <span>Search</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row>
              <SearchBar :searchString="searchStringCriteria" @input="searchStringCriteria = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length == 0" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Date Start
                        </th>
                        <th class="text-left">
                          Date End
                        </th>
                        <th />
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="key">
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.name }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.start_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.end_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td />
                        <td>
                          <v-btn text icon class="pl-3" @click="deleteCriteria(item.name)">
                            <v-icon left>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row v-if="queryResult" justify="center" align="end" class="my-2">
              <v-col
                offset="1"
                cols="6"
                offset-sm="2"
                sm="5"
                offset-md="5"
                md="2"
                class="text-right"
              >
                <v-menu ref="menu" top>
                  <template #activator="{ on: menu }">
                    <v-tooltip color="primary" bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn :disabled="queryResult && queryResult.length == 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                          <v-icon small left>
                            mdi-download
                          </v-icon> Download
                        </v-btn>
                      </template>
                      <span>Choose the format and download dataset</span>
                    </v-tooltip>
                  </template>

                  <v-list>
                    <v-list-item @click="exportXlsx">
                      <v-list-item-title>
                        <v-icon left>
                          mdi-file-excel-outline
                        </v-icon>XLSX
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportCsv">
                      <v-list-item-title>
                        <v-icon left>
                          mdi-file-delimited-outline
                        </v-icon>CSV
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="searchString"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  style="margin-top: -3.5px"
                  :autocomplete="$store.getters.disableAutocomplete"
                />
              </v-col>
            </v-row>
            <v-data-table
              v-if="!isLoadingData"
              must-sort
              sort-by="time_stamp"
              sort-desc
              :headers="headers"
              :items="queryResult"
              class="elevation-1"
              :search="searchString"
            >
              <template slot="no-data">
                <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
                  Not enough data to render this table!
                </v-alert>
              </template>
              <template #[`item.time_stamp`]="{ item }">
                {{ item.time_stamp | formatVerboseDateTimeFromUtc }}
              </template>
              <template #[`item.detail`]="{ item }">
                <v-tooltip color="primary" left>
                  <template #activator="{ on }">
                    <v-btn text icon :loading="loadingDetail" v-on="on" @click="showDetail(item)">
                      <v-icon color="primary">
                        mdi-page-next-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View Details</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model="dialogDetailModel" class="reallyBigModal">
      <v-card class="reallyBigModal" style="overflow: hidden !important">
        <v-card-text class="pa-0 ma-0 reallyBigModal">
          <v-text-field id="dialogIDtocopy" v-model="itemDetailSelected.dialog_id" class="tocopy-class" />
          <EuContactModal
            v-if="dialogDetailModel"
            type="convy"
            :contact="itemDetailSelected | formatContact"
            @eucontactmodalclose="closeDialogShowDetail"
            @eucopydialog="copyDialogId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import fieldValidators from "../../helpers/fieldValidators";
import exportFile from "../../helpers/exportFile";
import TimePickerRangeReport from "../../components/other/timePickerRangeReport";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import moment from "moment";
import merge from "deepmerge";
import sanitizeHistoryContent from "../../helpers/sanitizeHistoryContent";
import { EuContactModal } from "@eudata/eu-contact-modal";
import customFilter from "../../plugins/customFilter";
import postProcessing from "../../helpers/postProcessing";

const Qs = require("qs");

export default {
  name: "AgentsSurveys",
  components: {
    ResultSnackbar,
    ConfirmDialog,
    TimePickerRangeReport,
    SearchBar,
    EuContactModal,
  },
  data() {
    return {
      expansionPanels: 0,
      isFormValid: false,
      isLoadingData: false,
      saveDialog: false,
      newCriteriaName: "",
      dialogDeleteCriteria: false,
      deleteCriteriaMessage: "",
      criteriaToDelete: "",
      errorNameMessage: "",
      searchCriterias: {},
      surveyList: [],
      surveySelected: "",
      queryResult: [],
      itemDetailSelected: {},
      dialogDetailModel: false,
      headers: [],
      standardHeaders: [
        { text: "Created At", value: "time_stamp" },
        { text: "Agent", value: "agent" },
        { text: "Queue", value: "queue" },
      ],
      enableDatePicker: true,
      dialogDateTimeStart: false,
      dialogDateTimeEnd: false,
      dateStartChose: false,
      dateEndChose: false,
      dateStart: "",
      dateEnd: "",
      timeStart: "",
      timeEnd: "",
      searchString: "",
      searchStringCriteria: "",
      forceRerender: 0,
      loadingDetail: false,
      refreshTimer: null,
    };
  },
  computed: {
    ...spacing,
    getDateStart() {
      return moment(this.dateStart).format("DD/MM/YYYY") + " " + this.timeStart + ":00";
    },
    getDateEnd() {
      return moment(this.dateEnd).format("DD/MM/YYYY") + " " + this.timeEnd + ":59";
    },
    searchButtonDisabled() {
      return this.isFormValid ? false : true;
    },
    getSearchCriterias() {
      this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name.toLowerCase().indexOf(this.searchStringCriteria.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    try {
      let response = await this.$http.get("/search-criteria/AGENT_SURVEY_DETAIL");
      for (let key in response.data) {
        if (response.data[key].isDateEnabled) {
          response.data[key].isDateEnable = response.data[key].isDateEnabled;
          delete response.data[key].isDateEnabled;
        }
      }
      this.searchCriterias = response.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }

    try {
      let surveyList = await this.$http.get("/human-agents/survey");

      Object.keys(surveyList.data).forEach((survey) => {
        this.surveyList.push({ name: surveyList.data[survey].name, eri: surveyList.data[survey].eri, details: surveyList.data[survey].details });
      });
      this.surveyList.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } catch {
      this.$refs.resultSnackbar.showError("Error loading survey list");
    }
  },
  beforeDestroy() {
    this.stopRefreshTimer();
  },
  methods: {
    ...customFilter,
    ...fieldValidators,
    ...exportFile,
    ...postProcessing,
    copyDialogId() {
      try {
        let input = document.getElementById("dialogIDtocopy");
        input.select();
        document.execCommand("copy");
        this.onCopy();
      } catch (err) {
        this.onError();
      }
    },
    onCopy() {
      this.$refs.resultSnackbar.showSuccess("DialogId Copied to Clipboard!");
    },
    onError() {
      this.$refs.resultSnackbar.showError("Error Copy to Clipboard!");
    },
    stopRefreshTimer() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
      }
      this.refreshTimer = null;
    },
    closeDialogShowDetail() {
      this.dialogDetailModel = false;
      this.stopRefreshTimer();
    },
    showDetail(item) {
      this.loadingDetail = true;
      let params = {
        type: "DIALOG_DETAIL_INFO_V2",
        dialog_id: item.dialog_id,
      };

      this.$httpAuth
        .get("/report/dialog-details-info", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then(async (result) => {
          let response = merge({}, item);
          try {
            if (result.data.rawHistory) {
              response.content = result.data.rawHistory;
            } else {
              response.content = JSON.parse(response.content) || [];
            }
          } catch (err) {
            response.content = [];
          }
          if (result.data.result && result.data.result.length > 0) response = merge(response, result.data.result[0]);

          for (let i = 0; i < response.content.length; i++) {
            for (let j = 0; j < result.data.history.length; j++) {
              if (
                (result.data.history[j].message && result.data.history[j].message === response.content[i].message && response.content[i].who?.toLowerCase() !== "convyai") ||
                result.data.history[j].originalMessage === response.content[i].message
              ) {
                if (!response.content[i].analyze) {
                  response.content[i].analyze = result.data.history[j].analyze ? result.data.history[j].analyze : null;
                }
                if (result.data.history[j].attachment) {
                  //vuol dire che mi è stato mandato un base64
                  if (result.data.history[j].attachment.skipConversion) {
                    //allora devo ricostruire il source per il base64
                    result.data.history[j].attachment.file_content =
                      "data:" + result.data.history[j].attachment.mime_type + ";base64," + result.data.history[j].attachment.file_content;
                  }
                  response.content[i].attachment = result.data.history[j].attachment;
                }
                if (result.data.history[j].attributes) {
                  if (!response.content[i].attributes) {
                    response.content[i].attributes = {};
                  }
                  response.content[i].attributes = merge(response.content[i].attributes, result.data.history[j].attributes);
                  //Evito di duplicare i campi
                  if (response.content[i].attributes.formField) {
                    response.content[i].attributes.formField = result.data.history[j].attributes.formField;
                  }
                  //Verifico che gli array siano tutti unici
                  if (response.content[i].attributes.to) {
                    response.content[i].attributes.to = result.data.history[j].attributes.to;
                  }
                  if (response.content[i].attributes.cc) {
                    response.content[i].attributes.cc = result.data.history[j].attributes.cc;
                  }
                  if (response.content[i].attributes.bcc) {
                    response.content[i].attributes.bcc = result.data.history[j].attributes.bcc;
                  }
                  if (response.content[i].attributes.attachments) {
                    response.content[i].attributes.attachments = result.data.history[j].attributes.attachments;
                  }
                }
                result.data.history.splice(j, 1);
              } else if (result.data.history[j].attachment && result.data.history[j].attachment.file_name == response.content[i].message) {
                response.content[i].attachment = result.data.history[j].attachment;
                result.data.history.splice(j, 1);
              } else if (response.content[i].type === "share_document") {
                //Gestione documenti condivisi
                const documentName = response.content[i].message;
                const findResult = result.data.history.find((msg) => {
                  return msg.message && msg.message.indexOf(documentName) !== -1;
                });
                if (findResult) {
                  response.content[i].attachment = findResult.attachment;
                  result.data.history.splice(j, 1);
                }
              }
            }
          }
          response.data = result.data.data;
          response.result = result.data.result;
          response.calls = result.data.calls;
          response.recordings = await this.retrieveRecordingStatus(result.data.recordings);
          response.privateNotes = result.data.privateNotes;
          response.surveys = result.data.surveys;
          response.contactResults = result.data.contactResults;
          this.itemDetailSelected = sanitizeHistoryContent.sanitizeAttachment(response, this.$store.getters.getSelectedCompany);
          this.dialogDetailModel = true;
          if (item.state === "PROGRESS") {
            this.stopRefreshTimer();
            this.refreshTimer = setTimeout(this.showDetail.bind(this, item), 30000);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingDetail = false;
        });
    },
    filterTable(value, search, item) {
      if (!search) search = "";
      return Object.values(item).find((field) => {
        field && field.toLowerCase && field.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    },

    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    denyDeleteCriteria() {
      this.dialogDeleteCriteria = false;
      this.criteriaToDelete = "";
    },
    updateDateTimeStart(event) {
      this.dateStart = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeStart = event.split(" ")[1];
    },
    updateDateTimeEnd(event) {
      this.dateEnd = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeEnd = event.split(" ")[1];
    },
    getDate(data) {
      return moment(data, "DD/MM/YYYY HH:mm:ss").utc().toDate();
    },
    searchData() {
      this.isLoadingData = true;
      this.queryResult = [];
      let params = {
        type: "AGENT_SURVEY_DETAIL",
        start_date: this.enableDatePicker ? this.getDate(this.getDateStart) : moment("1970-01-01", "YYYY-MM-DD").toDate(),
        end_date: this.enableDatePicker ? this.getDate(this.getDateEnd) : moment("2100-01-01", "YYYY-MM-DD").toDate(),
        filterOperator: "and",
      };
      params.survey = this.surveySelected.eri;
      this.$httpAuth
        .get("/report/agent-survey-detail", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          this.isLoadingData = false;
          if (result.data.length > 0) {
            this.headers = [...this.standardHeaders];
            const survey = this.surveyList.find((s) => s.eri === params.survey);
            survey.details.forEach((detail) => {
              this.headers.push({ text: detail.question, value: detail.question });
            });
            this.headers.push({ text: "", value: "detail", align: "center", sortable: false });

            this.queryResult = result.data.map((element) => {
              element.details.forEach((detail) => {
                element[detail.question] = detail.answers.map((answer) => answer.value).join(" | ");
              });
              delete element.details;
              return element;
            });
          }
          this.expansionPanels = 2;
        })
        .catch((err) => {
          this.$refs.resultSnackbar.showError("Error Search Data: " + err.message);
          this.isLoadingData = false;
        });
    },
    async saveCurrentCriteria() {
      let searchCriteriaConfig = {
        end_date: this.getDateEnd,
        start_date: this.getDateStart,
        isDateEnable: this.enableDatePicker,
        name: this.newCriteriaName,
        survey: this.surveySelected,
        type: "AGENT_SURVEY_DETAIL",
      };
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
        this.errorNameMessage = "Name Already Exists";
      } else {
        this.errorNameMessage = "";
        let newSearchCriteria = {
          searchCriteriaIdentifier: btoa(this.newCriteriaName),
          searchCriteriaConfig: searchCriteriaConfig,
        };
        try {
          let result = await this.$http.post("/search-criteria/", newSearchCriteria);
          this.saveDialog = false;
          if (result.data.result) {
            this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
            this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
            this.forceRerender++;
          } else {
            this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
          }
          this.newCriteriaName = "";
        } catch {
          this.saveDialog = false;
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
        }
      }
    },
    getValue(name) {
      this.expansionPanels = 0;
      if (this.searchCriterias[btoa(name)].isDateEnable) {
        this.enableDatePicker = true;
        let start = this.searchCriterias[btoa(name)].start_date.split(" ");
        this.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.timeStart = moment(start[1], "HH:mm:ss", true).isValid() ? moment(start[1], "HH:mm:ss").format("HH:mm") : start[1];
        let end = this.searchCriterias[btoa(name)].end_date.split(" ");
        this.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.timeEnd = moment(start[1], "HH:mm:ss", true).isValid() ? moment(end[1], "HH:mm:ss").format("HH:mm") : end[1];
      } else {
        this.enableDatePicker = false;
      }
      this.surveySelected = this.searchCriterias[btoa(name)].survey;
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, {
        datetimeStart: this.searchCriterias[btoa(name)].start_date,
        datetimeEnd: this.searchCriterias[btoa(name)].end_date,
        enable: this.searchCriterias[btoa(name)].isDateEnable,
      });
      this.triggerSearch();
    },
    triggerSearch() {
      setTimeout(() => {
        this.$refs["searchBtn"].$el.click();
      }, 500);
    },
    deleteCriteria(name) {
      this.criteriaToDelete = name;
      this.deleteCriteriaMessage = "Delete " + name + " criteria permanently";
      this.dialogDeleteCriteria = true;
    },
    async confirmDeleteCriteria() {
      let key = btoa(this.criteriaToDelete);

      try {
        await this.$http.delete("/search-criteria/" + key);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.criteriaToDelete = "";
        this.deleteCriteriaMessage = "";
        this.dialogDeleteCriteria = false;
        this.$refs.resultSnackbar.showSuccess("Search Criteria deleted!");
      } catch {
        this.$refs.resultSnackbar.showError("Error deleting search criteria");
      }
    },
    checkIfSearchCriteriaExists(val) {
      return this.searchCriterias[btoa(val)] ? true : false;
    },
    exportXlsx() {
      this.exportAgentSurveyXlsx(this.queryResult);
    },
    exportCsv() {
      this.exportAgentSurveyCsv(this.queryResult);
    },
  },
};
</script>
<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}
</style>
