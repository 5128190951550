import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import storage from "./helpers/localStorage";
import merge from "deepmerge";
import state from "./state.js";
import SecureLS from "secure-ls";

let ls = new SecureLS({ isCompression: true });
let persistentStoragOptions = {
  key: storage.getKey(),
  paths: ["loginResponse", "settings", "company", "userSettings", "selectedApp", "versions"],
};
//Gestione crittazione del local storage
//solo se NODE_ENV == production verrà usata la crittazione
if (process.env.NODE_ENV === "production") {
  persistentStoragOptions.storage = {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  };
}

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState(persistentStoragOptions)],
  state,
  getters: {
    isLogged: (state) => {
      return state.loginResponse && state.loginResponse.jwt;
    },
    isIntegrationEnabled: (state) => (type) => {
      return !!(state.company.settings && state.company.settings.integrations && state.company.settings.integrations[type]);
    },
    getUser: (state) => {
      return state.loginResponse.params.customerProfile.user || {};
    },
    getUserName: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.name;
    },
    getUserSurname: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.surname;
    },
    getUserLanguage: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.language;
    },
    getUsername: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.authentication?.credentials.username;
    },
    getUserTheme: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.darkTheme;
    },
    getUserColor: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.color;
    },
    getUserAvatar: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info?.avatar;
    },
    getUserResources: (state) => {
      const tenantERI = state.enums.products.CONVYAI + ":" + state.loginResponse.params.customerProfile.selectedCompany;
      return state.loginResponse?.params?.customerProfile?.user?.roles?.[state.enums.products.CONVYAI].resources?.[tenantERI];
    },
    getUserRole: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.roles?.[state.enums.products.CONVYAI].role;
    },
    isCCM: (state) => {
      const role = state.loginResponse?.params?.customerProfile?.user?.roles?.[state.enums.products.CONVYAI].role;
      return role && (role === state.enums.roles.CONTACTCENTERMANAGER || role === state.enums.roles.CONTACTCENTERMANAGEROUTSOURCER);
    },
    isCCMO: (state) => {
      const role = state.loginResponse?.params?.customerProfile?.user?.roles?.[state.enums.products.CONVYAI].role;
      return role && role === state.enums.roles.CONTACTCENTERMANAGEROUTSOURCER;
    },
    getGlobalScheduledReports: (state) => {
      return state.loginResponse?.params?.customerProfile?.user?.info.globalScheduledReport;
    },
    getPermission: (state) => {
      return state.company.companyPermission;
    },
    userIsRoot: (state) => {
      return (
        state.loginResponse.params.customerProfile.user &&
        state.loginResponse.params.customerProfile.user.roles[state.enums.products.CONVYAI].role === state.enums.roles.ROOT
      );
    },
    getLanguages: (state) => {
      return state.enums.adminLanguages;
    },
    getSetting: (state) => (key) => {
      return state.settings[key];
    },
    getEvents: (state) => {
      return state.events;
    },
    getProperty: (state) => (property) => {
      return state.loginResponse.params.serverConfigurations[property];
    },
    getUserRoles: (state) => {
      return Object.keys(state.enums.roles);
    },
    getPermissions: (state) => {
      return state.company.companyPermission;
    },
    getUserID: (state) => {
      return state.loginResponse.params.customerProfile.user._id;
    },
    getSelectedCompany: (state) => {
      return state.loginResponse.params.customerProfile.selectedCompany;
    },
    getSelectedCompanyName: (state) => {
      return state.company.list.find((c) => c.uuid === state.loginResponse.params.customerProfile.selectedCompany).name;
    },
    getCompaniesList: (state) => {
      return state.company.list.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    getCompanyNameByUuid: (state) => (uuid) => {
      let company = state.company.list.find((company) => {
        return company.uuid === uuid;
      });
      return company ? company.name : null;
    },
    getUserLanguages: () => {
      return [
        { text: "English", value: "EN" },
        { text: "Italiano", value: "IT" },
      ];
    },
    getLanguageMultilanguage: (state) => {
      let multiLanguageArray = [];
      for (let lang in state.enums.languages) {
        multiLanguageArray.push({
          name: state.enums.languages[lang].name,
          flag: state.enums.languages[lang].flag,
          code: state.enums.languages[lang].code,
        });
      }
      return multiLanguageArray;
    },
    getGoogleSpeechSupportedLanguage: (state) => {
      let languageArray = [];
      for (let lang in state.enums.googleSpeechSupportedLanguage) {
        languageArray.push({
          text: lang,
          value: state.enums.googleSpeechSupportedLanguage[lang],
        });
      }
      return languageArray;
    },
    getGoogleSpeechSupportedLanguageByName: (state) => (type) => {
      for (let lang in state.enums.googleSpeechSupportedLanguage) {
        if (state.enums.googleSpeechSupportedLanguage[lang].includes(type)) {
          return lang;
        }
      }
    },
    getGoogleSupportedLanguage: (state) => {
      let languageArray = [];
      for (let lang in state.enums.googleSupportedLanguage) {
        languageArray.push({
          text: lang,
          value: state.enums.googleSupportedLanguage[lang],
        });
      }
      return languageArray;
    },
    getAwsSupportedLanguage: (state) => {
      let languageArray = [];
      for (let lang in state.enums.awsSupportedLanguage) {
        languageArray.push({
          text: lang,
          value: state.enums.awsSupportedLanguage[lang],
        });
      }
      return languageArray;
    },
    getAwsRegionCode: (state) => {
      let regionArray = [];
      for (let region in state.enums.awsRegionsCode) {
        regionArray.push({
          text: region,
          value: state.enums.awsRegionsCode[region],
        });
      }
      return regionArray;
    },
    getBotTypes: (state) => {
      if (!state?.company?.settings?.integrations?.outbound) {
        return state.enums.botTypes.filter((t) => t.type !== "outbound");
      }
      return state.enums.botTypes;
    },
    getBotType: (state) => (type) => {
      return state.enums.botTypes.find((ty) => ty.type === type);
    },
    getBotTemplates: (state) => {
      return state.enums.botTemplates;
    },
    getBotCustomTemplate: (state) => {
      return state.enums.botTemplates.find((temp) => temp.label === "Your own flow");
    },
    getBotCustomTemplatebyLabel: (state) => (label) => {
      return state.enums.botTemplates.find((temp) => temp.label.toLowerCase() === label.toLowerCase());
    },
    getBotCustomTemplatebyType: (state) => (type) => {
      return state.enums.botTemplates.find((temp) => temp.type.toLowerCase() === type.toLowerCase());
    },
    getSystemBotIcon: (state) => (icon, type, isIvr) => {
      //-> customerInterfaceLogoPreset contiene i file base64 dei template vecchi
      //-> botTemplates contiene i file nuovi con le rispettive key dei template e system
      //-> botTemplates[0].images contiente le key del personal flow, è un sotto array perchè in quel caso si hanno più colori

      // retrocompatibilità
      const checkNewSetIcons =
        state.enums.botTemplates.find((file) => file.key === icon) || state.enums.botTemplates[0].images.find((file) => file.key === icon);

      // check nuoveIcone di system
      // in caso di outbound passerò per forza in questo if, quindi non serve fare passare il parametro isOutbound a questo metodo
      if (checkNewSetIcons) {
        // se sono le nuove icone restituire il file necessario
        if (type && type === "big") {
          return checkNewSetIcons.image.big;
        } else {
          return checkNewSetIcons.image.mini;
        }
      }
      // check vecchia icona di default Flow
      if (icon === state.enums.botAvatar) {
        // check if is ivr old type
        if (isIvr) {
          if (type && type === "big") {
            return require("./assets/img/botsNew/botIVR_big.png");
          } else {
            return require("./assets/img/botsNew/botIVR_mini.png");
          }
        }
        // uso quella nuova
        if (type && type === "big") {
          return require("./assets/img/botsNew/botCustom_big.png");
        } else {
          return require("./assets/img/botsNew/botCustom_mini.png");
        }
      }
      const checktemplate = state.enums.customerInterfaceLogoPreset.find((file) => "data:image/png;base64," + file.base64 === icon);
      if (checktemplate) {
        // se è un immagine di template vecchi uso quelle nuove
        if (type && type === "big") {
          return checktemplate.imagebig;
        } else {
          return checktemplate.image;
        }
      } else {
        // altrimenti è caricata dall' utente ed uso quella
        return icon;
      }
    },
    getCustomerInterfaceLogoPreset: (state) => {
      return state.enums.customerInterfaceLogoPreset;
    },
    getSemanticEnginesTypes: (state) => {
      return state.enums.semanticEngines;
    },
    getEudataEntityTypes: (state) => {
      return state.enums.eudata_entity_types;
    },
    getCogitoEntityTypes: (state) => {
      return state.enums.cogito_entity_types;
    },
    getCogitoSupportedLanguage: (state) => {
      return state.enums.cogitoSupportedLanguage;
    },
    getCogitoMLSupportedLanguage: (state) => {
      return state.enums.cogitoMLSupportedLanguage;
    },
    getInputProcessorTypes: (state) => {
      return state.enums.inputProcessors;
    },
    getInputProcessorByType: (state) => (type) => {
      for (let i = 0; i < state.enums.inputProcessors.length; i++) {
        if (state.enums.inputProcessors[i].type === type) {
          return state.enums.inputProcessors[i];
        }
      }
    },
    getLogoByType: (state) => (brandName) => {
      for (let i = 0; i < state.enums.brands.length; i++) {
        if (state.enums.brands[i].name === brandName) {
          return state.enums.brands[i].image;
        }
      }
    },
    getConnectorLogoByType: (state) => (brandName) => {
      for (let i = 0; i < state.enums.socialConnectors.length; i++) {
        if (state.enums.socialConnectors[i].name === brandName) {
          return state.enums.socialConnectors[i].image;
        }
      }
    },
    chooseDatabases: (state) => {
      return state.enums.databases;
    },
    getDatabases: (state) => {
      return state.enums.databases;
    },
    getDocumentDatabases: (state) => {
      return state.enums.DocumentDatabases;
    },
    getEngagementAttributesLivePerson: (state) => (type) => {
      return state.enums.engagementAttributesLivePerson[type];
    },
    getRoles: (state) => {
      return state.enums.roles;
    },
    getRoleLabel: (state) => (role) => {
      return state.enums.rolesLabel[role];
    },
    getProducts: (state) => {
      return state.enums.products;
    },
    getExpirationPasswordChoice: () => {
      return [
        { text: "never", value: 10000 },
        { text: "1 month", value: 1 },
        { text: "2 months", value: 2 },
        { text: "3 months", value: 3 },
      ];
    },
    enums: (state) => {
      return state.enums;
    },
    getTimeZones: (state) => {
      return state.enums.timeZones;
    },
    getAdvancedErrorList: (state) => {
      let res = [];
      let permissions = state.company.companyPermission;
      if (permissions) {
        if (permissions.indexOf("AccessHandoverNode") != -1) res.push("Handover");
        if (permissions.indexOf("AccessSurveyNode") != -1) res.push("Survey");
        if (permissions.indexOf("AccessFlowJumpNode") != -1) res.push("Flow");
      } else {
        res = ["Handover", "Survey", "Flow"];
      }
      return res;
    },
    getHandoverTypeList: (state) => {
      let res = [];
      let permissions = state.company.companyPermission;
      if (permissions) {
        res.push("External");
        if (permissions.indexOf("AccessWCSIntegration") !== -1) res.push("ConvyAI Agents");
        if (permissions.indexOf("AccessRainbowIntegration") !== -1 && state.company.settings.integrations && state.company.settings.integrations.rainbow)
          res.push("Rainbow");
        if (permissions.indexOf("AccessWebexTeamsIntegration") !== -1 && state.company.settings.integrations && state.company.settings.integrations.webexTeams)
          res.push("WebexTeams");
        if (permissions.indexOf("AccessECEIntegration") !== -1 && state.company.settings.integrations && state.company.settings.integrations.ciscoECE)
          res.push("CiscoECE");
        if (permissions.indexOf("AccessLivePersonIntegration") !== -1 && state.company.settings.integrations && state.company.settings.integrations.livePerson)
          res.push("LivePerson");
      } else {
        res = ["ConvyAI Agents", "External", "Rainbow", "WebexTeams", "CiscoECE", "LivePerson"];
      }
      return res;
    },
    getDefaultFlowConfiguration: (state) => {
      return state.enums.defaultFlowConfiguration;
    },
    getDefaultScrollProps: () => {
      return {
        minScrollbarLength: 60,
        wheelPropagation: true,
        suppressScrollX: true,
      };
    },
    getConnectorLabel: () => (type) => {
      switch (type) {
        case "webapi":
          return "WebAPI";
        case "facebookmessenger":
          return "Facebook Messenger";
        case "whatsapp":
          return "WhatsApp";
        case "webexmeeting":
          return "WebexTeams";
        case "applebusinesschat":
          return "Apple Business Chat";
        case "telegram":
          return "Telegram";
        case "googlehome":
          return "Google Assistant";
        case "amazonalexa":
          return "Amazon Alexa";
        case "twitter":
          return "Twitter";
        case "PersistentChat":
          return "Persistent Chat";
        case "phone":
          return "Phone";
        case "email":
          return "Email";
        case "googlebusinessmessages":
          return "Google Business Messages";
      }
      return "";
    },
    getConnectorIcon: () => (type) => {
      switch (type) {
        case "webapi":
          return "mdi-earth";
        case "facebookmessenger":
          return "mdi-facebook-messenger";
        case "whatsapp":
          return "mdi-whatsapp";
        case "webexmeeting":
          return "mdi-cisco-webex";
        case "applebusinesschat":
          return "mdi-apple";
        case "telegram":
          return "mdi-telegram";
        case "googlehome":
          return "mdi-google-assistant";
        case "amazonalexa":
          return "mdi-amazon-alexa";
        case "twitter":
          return "mdi-twitter";
        case "PersistentChat":
          return "mdi-message-text-outline";
        case "phone":
          return "mdi-phone";
        case "email":
          return "mdi-email";
        case "googlebusinessmessages":
          return "mdi-google";
      }
      return "";
    },
    getFlagFromLanguageCode: (state) => (code) => {
      return state.enums.languages.find((lang) => {
        return lang.code == code ? lang.flag : false;
      });
    },
    disableAutocomplete: () => {
      return "off"; //Una volta era "none" ma chrome poi ha cambiato...
    },
    disableAutocompleteOff: () => {
      return "off";
    },
    disableAutocompleteNewPassword: () => {
      return "new-password";
    },
    vAutocompleteDisableAutocomplete: () => {
      return "disable";
    },
    getCogitoPromote: (state) => {
      return state.settings.cogitoPromote;
    },
    getWhatsappTemplates: (state) => {
      return state.company.whatsappTemplate;
    },
    getSelectedApp: (state) => {
      return state.selectedApp;
    },
  },
  mutations: {
    SelectApp(state, app) {
      state.selectedApp = app;
    },
    AddSetting(state, newObj) {
      state.settings = merge(state.settings, newObj);
    },
    AddCompany(state, newCompany) {
      if (state.company.list) {
        const index = state.company.list.findIndex((company) => company.uuid === newCompany.uuid);
        if (index === -1) {
          state.company.list.push(newCompany);
        } else {
          state.company.list[index].name = newCompany.name;
          state.company.list[index].uuid = newCompany.uuid;
        }
      }
    },
    UpdateCompany(state, updateCompany) {
      state.company.settings.integrations = updateCompany.integrations;
    },
    DeleteCompany(state, companyUUID) {
      if (
        state.loginResponse.params.customerProfile.user &&
        state.loginResponse.params.customerProfile.user.roles[state.enums.products.CONVYAI].resources &&
        Object.keys(state.loginResponse.params.customerProfile.user.roles[state.enums.products.CONVYAI].resources).indexOf(
          `${state.enums.products.CONVYAI}:${companyUUID}`,
        ) !== -1
      ) {
        delete state.loginResponse.params.customerProfile.user.roles[state.enums.products.CONVYAI].resources[`${state.enums.products.CONVYAI}:${companyUUID}`];
      }
      const findIndex = state.company.list.findIndex((c) => c.uuid === companyUUID);
      if (findIndex >= 0) {
        state.company.list.splice(findIndex, 1);
      }
    },
    DeleteSetting(state, key) {
      delete state.settings[key];
    },
    UpdateLoginParameters(state, loginParameters) {
      state.loginResponse = loginParameters;
    },
    UpdateGlobalScheduledReport(state, report) {
      state.loginResponse.params.customerProfile.user.info.globalScheduledReport = report;
    },
    UpdateSelectedCompany(state, selectedCompany) {
      state.loginResponse.params.customerProfile.selectedCompany = selectedCompany;
    },
    UpdateCompanyPermission(state, companyPermissions) {
      state.company.companyPermission = companyPermissions.companyPermission;
      state.company.settings = companyPermissions.companySettings;
    },
    Logoff(state) {
      state.company = {
        list: [],
        companyPermission: [
          "ReadOnlyDashboard", //è quella iniziale
        ],
        settings: {},
        whatsappTemplate: {},
        versions: {},
      };
      state.loginResponse = {
        jwt: "",
        message: "",
        params: {
          changePassword: false,
          customerProfile: {
            user: {},
            selectedCompany: "",
          },
          serverConfigurations: {
            publicUrl: "",
            crabUrl: "",
            kibanaUrl: "",
          },
        },
      };
    },
    UpdateWhatsappTemplate(state, templates) {
      Vue.set(state.company, "whatsappTemplate", templates);
    },
    UpdateCompanySettings(state, settings) {
      state.company = settings.company;
      state.userSettings = settings.userSettings;
    },
    setProperty(state, property) {
      state.loginResponse.params.serverConfigurations[property.key] = property.value;
    },
    UpdateUser(state, user) {
      state.loginResponse.params.customerProfile.user = user;
    },
    UpdateJWT(state, jwt) {
      //Se c'è un token lo aggiorno, altrimenti no. Questo serve per non andare in conflitto con la logout
      if (state.loginResponse.jwt) {
        state.loginResponse.jwt = jwt;
      }
    },
    UpdateFlowDesignerUpdateFlag(state, flag) {
      state.versions.flowdesignerNeedRefresh = flag;
    },
    UpdateConvyAdminUpdateFlag(state, flag) {
      state.versions.convyadminNeedRefresh = flag;
    },
  },
  actions: {
    SelectApp(context, app) {
      context.commit("SelectApp", app);
    },
    AddSetting(context, newObj) {
      context.commit("AddSetting", newObj);
    },
    AddCompany(context, newCompany) {
      context.commit("AddCompany", newCompany);
    },
    UpdateCompany(context, updateCompany) {
      context.commit("UpdateCompany", updateCompany);
    },
    DeleteCompany(context, company) {
      context.commit("DeleteCompany", company);
    },
    DeleteSetting(context, key) {
      context.commit("DeleteSetting", key);
    },
    UpdateLoginParameters(context, loginParameters) {
      context.commit("UpdateLoginParameters", loginParameters);
    },
    UpdateGlobalScheduledReport(context, report) {
      context.commit("UpdateGlobalScheduledReport", report);
    },
    UpdateSelectedCompany(context, selectedCompany) {
      context.commit("UpdateSelectedCompany", selectedCompany);
    },
    UpdateCompanyPermission(context, companyPermission) {
      context.commit("UpdateCompanyPermission", companyPermission);
    },
    Logoff(context) {
      context.commit("Logoff");
    },
    UpdateWhatsappTemplate(context, templates) {
      context.commit("UpdateWhatsappTemplate", templates);
    },
    UpdateCompanySettings(context, settings) {
      context.commit("UpdateCompanySettings", settings);
    },
    setProperty(context, property) {
      context.commit("setProperty", property);
    },
    UpdateUser(context, user) {
      context.commit("UpdateUser", user);
    },
    UpdateJWT(context, jwt) {
      context.commit("UpdateJWT", jwt);
    },
    UpdateFlowDesignerUpdateFlag(context, flag) {
      context.commit("UpdateFlowDesignerUpdateFlag", flag);
    },
    UpdateConvyAdminUpdateFlag(context, flag) {
      context.commit("UpdateConvyAdminUpdateFlag", flag);
    },
  },
});
