<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <!-- Dialog per salvataggio criteria -->
    <v-dialog v-model="saveDialog" max-width="290" @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[simpleAlphaNumString]"
            :error-messages="errorNameMessage"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined block :disabled="!newCriteriaName" @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :showConfirm="dialogDeleteCriteria"
      :message="deleteCriteriaMessage"
      @dialogConfirm="confirmDeleteCriteria"
      @dialogDeny="denyDeleteCriteria"
    />
    <!-- Panel per la search -->
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanelSearchForm" :class="getMainRowMargin">
        <!-- Search Criteria -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-magnify
                </v-icon>Semantic Statistics
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <!-- Filtri -->
            <v-form v-model="isFormValid">
              <!-- Riga Engine -->
              <v-row>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="currentCriteria.engine" :rules="[requiredField]" label="Engine" :items="getEngineList" />
                </v-col>
              </v-row>
              <!-- Riga Date -->
              <TimePickerRangeReport
                ref="timerPickerRow"
                @switchEnableDatePicker="enableDatePicker = $event"
                @setDateStart="updateDateTimeStart($event)"
                @setDateEnd="updateDateTimeEnd($event)"
              />
            </v-form>
            <!-- riga bottoni -->
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn depressed :class="getButtonMargin" :disabled="isLoadingData || !isFormValid" color="success" @click="saveDialog = true">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  <span>Save</span>
                </v-btn>
                <v-btn
                  ref="searchBtn"
                  depressed
                  :loading="isLoadingData"
                  :class="getButtonMargin"
                  :disabled="!isFormValid"
                  color="primary"
                  @click="searchData"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  <span>Search</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- SAVED CRITERIA -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row>
              <SearchBar :searchString="searchString" @input="searchString = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length == 0" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Date Start
                        </th>
                        <th class="text-left">
                          Date End
                        </th>
                        <th />
                        <th class="text-left">
                          Filters
                        </th>
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="key">
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.name }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.start_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.end_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td />
                        <td>
                          <v-tooltip left color="primary">
                            <template #activator="{ on }">
                              <v-btn icon text v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span v-if="item.engine">Engine: {{ item.engine }}</span>
                            <span v-else>All Engine</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-btn text icon @click="deleteCriteria(item.name)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- RESULT -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <SemanticStatisticsDatatable
              v-if="!isLoadingData"
              :dataset="queryResult"
              :startDate="enableDatePicker ? getDate(getDateStart) : '1970-01-01T00:01:00.000Z'"
              :endDate="getDate(getDateEnd)"
              @showSnackbar="showSnackbar"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import fieldValidators from "../../helpers/fieldValidators";
import TimePickerRangeReport from "../../components/other/timePickerRangeReport";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import moment from "moment";
import merge from "deepmerge";
import SemanticStatisticsDatatable from "../../components/report/SemanticStatisticsDatatable";
import EventBus from "../../event-bus";

const Qs = require("qs");

export default {
  name: "SemanticStatistics",
  components: {
    ResultSnackbar,
    TimePickerRangeReport,
    ConfirmDialog,
    SearchBar,
    SemanticStatisticsDatatable,
  },
  data() {
    return {
      // Dialog salvataggio criteria
      saveDialog: false,
      newCriteriaName: "",
      errorNameMessage: "",
      dialogDeleteCriteria: false,
      deleteCriteriaMessage: "",
      criteriaToDelete: "",

      expansionPanelSearchForm: 0,
      isFormValid: false,
      enableDatePicker: true, //switch data
      isLoadingData: false,

      listEngine: ["All"], //lista engine autocomplete

      searchCriterias: {}, //criteri di ricerca salvati

      searchString: "",
      forceRerender: 0,

      currentCriteria: {
        //elementi selezionati (v-model)
        engine: "All",
        dateStart: "",
        dateEnd: "",
        timeStart: "",
        timeEnd: "",
      },

      queryResult: [],
    };
  },
  computed: {
    ...spacing,
    getEngineList() {
      return this.listEngine
        .filter(() => true)
        .sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    getDateStart() {
      //* data per il datapicker
      return moment(this.currentCriteria.dateStart).format("DD/MM/YYYY") + " " + this.currentCriteria.timeStart + ":00";
    },
    getDateEnd() {
      //* data per il datapicker
      return moment(this.currentCriteria.dateEnd).format("DD/MM/YYYY") + " " + this.currentCriteria.timeEnd + ":59";
    },
    getSearchCriterias() {
      this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  mounted() {
    this.request();
  },
  methods: {
    ...fieldValidators,
    showSnackbar(event) {
      if (event && event.type) {
        switch (event.type) {
          case "SUCCESS_MESSAGE":
            this.$refs.resultSnackbar.showSuccess(event.message);
            break;
          case "FAIL_MESSAGE":
            this.$refs.resultSnackbar.showError(event.message);
            break;
        }
      }
    },
    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    denyDeleteCriteria() {
      this.dialogDeleteCriteria = false;
      this.criteriaToDelete = "";
    },
    updateDateTimeStart(event) {
      this.currentCriteria.dateStart = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.currentCriteria.timeStart = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    updateDateTimeEnd(event) {
      this.currentCriteria.dateEnd = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.currentCriteria.timeEnd = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    async request() {
      try {
        let searchCriteriasResponse = await this.$http.get("/search-criteria/SEMANTIC_DETAIL");
        let listEngineResponse = await this.$http.get("/intent-list/");
        for (let key in searchCriteriasResponse.data) {
          if (searchCriteriasResponse.data[key].isDateEnabled) {
            searchCriteriasResponse.data[key].isDateEnable = searchCriteriasResponse.data[key].isDateEnabled;
            delete searchCriteriasResponse.data[key].isDateEnabled;
          }
        }
        this.searchCriterias = searchCriteriasResponse.data;

        for (var i in listEngineResponse.data.value) {
          this.listEngine.push(listEngineResponse.data.value[i].name);
        }
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      }
    },
    getDate(data) {
      return moment(data, "DD/MM/YYYY HH:mm:ss").utc().toDate();
    },
    searchData() {
      //* bottone search
      EventBus.$emit(this.$store.getters.getEvents.RESTORE_SEMANTIC_STATISTICS_DATATABLE);
      this.isLoadingData = true;
      var start_date = "";
      var end_date = "";
      if (this.enableDatePicker) {
        start_date = this.getDate(this.getDateStart);
        end_date = this.getDate(this.getDateEnd);
      } else {
        start_date = "1970-01-01T00:01:00.000Z";
        end_date = this.getDate(this.getDateEnd);
      }
      let params = {
        type: "SEMANTIC_DETAIL",
        start_date: start_date,
        end_date: end_date,
        engine: this.currentCriteria.engine == "All" ? "" : this.currentCriteria.engine,
      };
      this.$httpAuth
        .get("/report/semantic-statistics", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          this.isLoadingData = false;
          this.queryResult = result.data;

          this.expansionPanelSearchForm = 2;
        })
        .catch((err) => {
          this.$refs.resultSnackbar.showError("Error Search Data: " + err.message);
          this.isLoadingData = false;
        });
    },
    getDateSavedCriteria(data) {
      //* formattazione data per i saved criteria
      return moment(data, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    },
    async saveCurrentCriteria() {
      //* bottone save
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
        //*controllo che il nome non sia già presente
        this.errorNameMessage = "Name Already Exists";
      } else {
        this.errorNameMessage = "";

        var start_date = "";
        var end_date = "";
        if (this.enableDatePicker) {
          start_date = this.getDateSavedCriteria(this.getDateStart);
          end_date = this.getDateSavedCriteria(this.getDateEnd);
        } else {
          start_date = this.getDateSavedCriteria(new Date(1970, 0, 1));
          end_date = new Date();
          end_date.setHours(23, 59, 59);
          end_date = this.getDateSavedCriteria(end_date);
        }
        var searchCriteriaConfig = {
          start_date: start_date,
          end_date: end_date,
          engine: this.currentCriteria.engine == "All" ? "" : this.currentCriteria.engine,
          isDateEnable: this.enableDatePicker,
          type: "SEMANTIC_DETAIL",
          name: this.newCriteriaName,
        };
        let newSearchCriteria = {
          searchCriteriaIdentifier: btoa(this.newCriteriaName),
          searchCriteriaConfig: searchCriteriaConfig,
        };

        try {
          let result = await this.$http.post("/search-criteria/", newSearchCriteria);
          this.saveDialog = false;
          if (result.data.result) {
            this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
            this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
            this.forceRerender++;
          } else {
            this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
          }
          this.newCriteriaName = "";
        } catch {
          this.saveDialog = false;
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
        }
      }
    },
    checkIfSearchCriteriaExists(val) {
      if (this.searchCriterias[btoa(val)]) {
        return true;
      } else {
        return false;
      }
    },
    deleteCriteria(name) {
      this.criteriaToDelete = name;
      this.deleteCriteriaMessage = "Delete " + name + " criteria permanently";
      this.dialogDeleteCriteria = true;
    },
    async confirmDeleteCriteria() {
      let key = btoa(this.criteriaToDelete);

      try {
        await this.$http.delete("/search-criteria/" + key);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.criteriaToDelete = "";
        this.deleteCriteriaMessage = "";
        this.dialogDeleteCriteria = false;
        this.$refs.resultSnackbar.showSuccess("Search Criteria deleted!");
      } catch {
        this.$refs.resultSnackbar.showError("Error deleting search criteria");
      }
    },
    getValue(name) {
      //* caricamento search criteria
      this.expansionPanelSearchForm = 0;
      if (this.searchCriterias[btoa(name)].engine != "") {
        this.currentCriteria.engine = this.searchCriterias[btoa(name)].engine;
      } else {
        this.currentCriteria.engine = "All";
      }
      if (this.searchCriterias[btoa(name)].isDateEnable) {
        this.enableDatePicker = true;
        let start = this.searchCriterias[btoa(name)].start_date.split(" ");
        this.currentCriteria.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.currentCriteria.timeStart = moment(start[1], "HH:mm:ss", true).isValid() ? moment(start[1], "HH:mm:ss").format("HH:mm") : start[1];
        let end = this.searchCriterias[btoa(name)].end_date.split(" ");
        this.currentCriteria.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        this.currentCriteria.timeEnd = moment(start[1], "HH:mm:ss", true).isValid() ? moment(end[1], "HH:mm:ss").format("HH:mm") : end[1];
      } else {
        this.currentCriteria.timeStart = "00:00";
        this.currentCriteria.timeEnd = "23:59";
        this.currentCriteria.dateStart = moment().format("YYYY-MM-DD");
        this.currentCriteria.dateEnd = moment().format("YYYY-MM-DD");
        this.enableDatePicker = false;
      }
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, {
        datetimeStart: this.searchCriterias[btoa(name)].start_date,
        datetimeEnd: this.searchCriterias[btoa(name)].end_date,
        enable: this.searchCriterias[btoa(name)].isDateEnable,
      });
      this.triggerSearch();
    },
    triggerSearch() {
      setTimeout(() => {
        this.$refs["searchBtn"].$el.click();
      }, 1000);
    },
  },
};
</script>
