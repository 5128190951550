<template>
  <v-toolbar class="headingCard" style="cursor: grab" flat dense height="40">
    <v-tooltip bottom nudge-right="200" max-width="400">
      <template #activator="{ on }">
        <v-btn
          icon
          text
          tile
          small
          class="white--text"
          @mousedown.stop="() => {}"
          v-on="on"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span v-html="'<strong>' + title + '</strong><br>' + info" />
    </v-tooltip>
    <v-card-title class="white--text text-truncate d-inline-block">
      {{ title }}
    </v-card-title>
    <v-spacer />

    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          :loading="reload"
          :disabled="reload"
          class="mx-1 white--text"
          icon
          text
          small
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onReloadData()"
        >
          <v-icon dark>
            mdi-reload
          </v-icon>
        </v-btn>
      </template>
      <span>Reload data for this widget</span>
    </v-tooltip>

    <v-tooltip v-if="!hideTableButton" bottom>
      <template #activator="{ on }">
        <v-btn
          :outlined="selectedView === 'chart'"
          icon
          text
          tile
          small
          class="mx-1 white--text"
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onChangeView('chart')"
        >
          <v-icon>mdi-chart-bar</v-icon>
        </v-btn>
      </template>
      <span>View as chart</span>
    </v-tooltip>
    <v-tooltip v-if="!hideTableButton" bottom>
      <template #activator="{ on }">
        <v-btn
          :outlined="selectedView === 'table'"
          icon
          text
          tile
          small
          class="mx-1 white--text"
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onChangeView('table')"
        >
          <v-icon>mdi-table</v-icon>
        </v-btn>
      </template>
      <span>View as table</span>
    </v-tooltip>
    <v-tooltip v-if="buttons && buttons.includes('flip')" bottom>
      <template #activator="{ on }">
        <v-btn
          :outlined="selectedView === 'flip'"
          icon
          text
          tile
          small
          class="mx-1 white--text"
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onChangeView('flip')"
        >
          <v-icon>mdi-card-bulleted-outline</v-icon>
        </v-btn>
      </template>
      <span>View agent details</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          v-if="!hideResizeButton"
          icon
          text
          tile
          small
          class="mx-1 hidden-md-and-down white--text"
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onResizeWidget()"
        >
          <v-icon>mdi-resize</v-icon>
        </v-btn>
      </template>
      <span>Resize widget</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          text
          tile
          small
          class="mx-1 white--text"
          v-on="on"
          @mousedown.stop="() => {}"
          @click.prevent="onDeleteWidget()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>Remove widget</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  name: "WidgetToolbar",
  props: {
    "title": {
      type: String,
      required: true
    },
    "buttons": {
      type: Array,
      required: true
    },
    "info": {
      type: String,
      required: true
    },
    "selectedView": {
      type: String,
      required: true
    },
    "hideResizeButton": {
      type: Boolean,
      required: true
    },
    "hideTableButton": {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      reload: false,
      icon: "mdi-table",
    };
  },
  computed: {
    getIcon() {
      return this.icon;
    },
  },
  methods: {
    onResizeWidget() {
      this.$emit("resizeWidget");
    },
    onChangeView(type) {
      this.$emit("onChangeView", type);
    },
    onDeleteWidget() {
      this.$emit("deleteWidget");
    },
    onReloadData() {
      this.reload = true;
      this.$emit("reloadData");
      setTimeout(() => {
        this.reload = false;
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
