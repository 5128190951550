<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
        <v-text-field readonly disabled label="Webhook" v-model="editedConnector.webhookUrl" prepend-inner-icon="mdi-webhook" :rules="[requiredField]" />
        <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
      </v-col>
    </v-row>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" md="6" v-if="!editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="App ID"
          v-model.trim="editedConnector.details.appId"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, isConnectorAppIdUnique(connectors, editedConnector.details.appId)]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="App Secret"
          v-model.trim="editedConnector.details.appSecret"
          prepend-inner-icon="mdi-lock"
          :type="showSecretToken ? 'text' : 'password'"
          autocomplete="new-password"
          :rules="[requiredField]"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="editedConnector.details.appSecret && editedConnector.details.appSecret.length < 1" tabindex="-1">
              <v-icon tabindex="-1" v-show="!showSecretToken" @click="showSecretToken = !showSecretToken"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showSecretToken" @click="showSecretToken = !showSecretToken"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Page Access Token"
          v-model.trim="editedConnector.details.pageAccessToken"
          prepend-inner-icon="mdi-lock"
          :type="showPageAccessToken ? 'text' : 'password'"
          autocomplete="new-password"
          :rules="[requiredField]"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="editedConnector.details.pageAccessToken && editedConnector.details.pageAccessToken.length < 1" tabindex="-1">
              <v-icon tabindex="-1" v-show="!showPageAccessToken" @click="showPageAccessToken = !showPageAccessToken"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showPageAccessToken" @click="showPageAccessToken = !showPageAccessToken"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Validation Token"
          v-model.trim="editedConnector.details.validationToken"
          prepend-inner-icon="mdi-lock"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="API URL"
          v-model.trim="editedConnector.details.apiURL"
          prepend-inner-icon="mdi-web"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";

export default {
  name: "FacebookConf",
  props: ["editedConnector", "connectors"],
  components: { CopyToClipboard },
  data() {
    return {
      showSecretToken: false,
      showPageAccessToken: false,
    };
  },
  mounted() {
    this.editedConnector.type = "facebookmessenger";
    if (!this.editedConnector.uuid) {
      this.editedConnector.details.apiURL = "https://graph.facebook.com/v18.0/me/messages";
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
