<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent" style="height: 460px !important">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
        @onChangeView="onChangeView"
      />
      <div @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-row v-if="totalAgent === 0 && selectedView !== 'table'" class="no-data-wrap">
          <v-col cols="1" />
          <v-col cols="10">
            <v-alert type="warning" icon="mdi-alert">
              <h4>No data available</h4>
            </v-alert>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-chart
          v-if="selectedView === 'chart' && totalAgent > 0"
          ref="agentByStatus"
          :key="chartkey"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart"
          :option="options"
        />
        <div v-else-if="selectedView === 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="name"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            must-sort
            sort-by="name"
            dense
          >
            <template #top>
              <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "AgentByStatus",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    }
  },
  data() {
    const agentStatusLabel = "Agent Status";
    return {
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queue",
            align: "start",
            sortable: true,
            value: "name",
          },
          {
            text: "Ready",
            align: "center",
            sortable: true,
            value: "ready",
          },
          {
            text: "Not Ready",
            align: "center",
            sortable: true,
            value: "notReady",
          },
          {
            text: "Ready With Capacity",
            align: "center",
            sortable: true,
            value: "readyWithCapacity",
          },
          {
            text: "Total Agents",
            align: "center",
            sortable: true,
            value: "totalAgents",
          },
          {
            text: "", //colonna utile a nascondere la scrollbar orizzontale
            align: "",
            sortable: false,
            value: "",
            width: 0,
          },
        ],
        data: [],
      },
      info: "This widget shows the distribution of available agents for each queue and an overview related to the current agents availability",
      reloadWidgetDataInterval: null,
      totalAgent: 0,
      chartkey: 0,
      options: {
        grid: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
        tooltip: {
          trigger: "item",
          //formatter: "{a} {b}: ({d}%)",
          formatter: (params, ticket) => {
            let name = params.data.name;
            if (name && name.length > 50) {
              name = name.substring(0, 50) + "...";
            }
            if (ticket.includes(agentStatusLabel)) {
              return "Agent " + name + " (" + params.percent + "%)";
            } else {
              return (
                name +
                ":<br/><span style='color:green;font-size:12px'>READY: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
                params.data.ready +
                "</span><br/><span style='color:red;font-size:12px'>NOT READY: " +
                params.data.notready +
                "</span>"
              );
            }
          },
        },
        series: [
          {
            name: agentStatusLabel,
            type: "pie",
            selectedMode: "single",
            radius: [0, "50%"],
            label: {
              position: "inner",
              fontSize: 10,
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderRadius: 5,
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
              borderWidth: 1,
            },
            data: [
              //{ value: 1548, name: "Ready\n1548" },
            ],
            color: [],
          },
          {
            name: "Agent Capacity",
            type: "pie",
            radius: ["55%", "65%"],
            labelLine: {
              length: 30,
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
              borderWidth: 1,
            },
            label: {
              formatter: "{b|{b}：}{c} \n {per|{d}%}  ",
              backgroundColor: this.$vuetify.theme.dark ? "#40535C" : "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              padding: [5, 5, 5, 5],
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 20,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "90%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: this.$vuetify.theme.dark ? "#fff" : "#4C5058",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: 25,
                },
                per: {
                  color: "#fff",
                  backgroundColor: this.$vuetify.theme.dark ? "#666" : "#4C5058",
                  padding: [3, 3],
                  borderRadius: 4,
                },
              },
            },
            data: [
              //{ value: 1048, name: "Queue1" },
            ],
          },
        ],
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    getColorOfStatus(status) {
      switch (status.toLowerCase()) {
        case "ready":
          return this.$vuetify.theme.dark ? "#60a38d" : "#43a047";
        case "notready":
          return this.$vuetify.theme.dark ? "#ff5252" : "#e53935";
        case "offline":
          return this.$vuetify.theme.dark ? "#2a3c51" : "#ccc";
      }
      return "#2a3c51";
    },
    async loadWidgetData() {
      try {
        const result = await this.$httpAuth.get("/dashboard/wallboard/agent-status");
        this.options.series[0].data = [];
        this.options.series[0].color = [];
        this.totalAgent = result.data.aggregated.notready + result.data.aggregated.ready + result.data.aggregated.offline;
        for (const status in result.data.aggregated) {
          if (status !== "withCapacityAvailable" && status !== "readyAndWithCapacityAvailable") {
            if (result.data.aggregated[status] > 0) {
              this.options.series[0].color.push(this.getColorOfStatus(status));
              this.options.series[0].data.push({
                value: result.data.aggregated[status],
                name: this.getStatusLabel(status) + ": " + result.data.aggregated[status],
              });
            }
          }
        }
        this.options.series[1].data = [];
        this.viewTable.data = [];
        for (const queueERI in result.data.queue) {
          const queue = this.queues.find((q) => q.eri === queueERI);
          if (queue) {
            const logged = result.data.queue[queueERI].notready + result.data.queue[queueERI].ready;
            if (logged > 0) {
              this.options.series[1].data.push({
                value: logged,
                name: queue.name,
                ready: result.data.queue[queueERI].ready,
                notready: result.data.queue[queueERI].notready,
                withCapacityAvailable: result.data.queue[queueERI].withCapacityAvailable,
                readyAndWithCapacityAvailable: result.data.queue[queueERI].readyAndWithCapacityAvailable,
                totalAgent: result.data.queue[queueERI].notready + result.data.queue[queueERI].offline + result.data.queue[queueERI].ready,
              });
            }
            this.viewTable.data.push({
              name: queue.name,
              ready: result.data.queue[queueERI].ready,
              notReady: result.data.queue[queueERI].notready,
              readyWithCapacity: result.data.queue[queueERI].readyAndWithCapacityAvailable,
              totalAgents: result.data.queue[queueERI].notready + result.data.queue[queueERI].offline + result.data.queue[queueERI].ready,
            });
          }
        }
        if (this.$refs["agentByStatus"]) {
          this.$refs["agentByStatus"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 420px;
}
.no-data-wrap {
  height: 354px;
  align-items: center;
  align-content: center;
  text-align: center;
}
</style>
