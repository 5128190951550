export default {
  getStatusLabel(status) {
    switch (status.toLowerCase()) {
      case "ready":
        return "Ready";
      case "notready":
        return "Not Ready";
    }
    return "Offline";
  },
  getCapacityColor(value) {
    if (value >= 95) {
      return "#e53935";
    } else if (value >= 75) {
      return "#fb8c00";
    }
    return "#039be5";
  },
  getPublicationTypeLabel(value) {
    switch (value.toLowerCase()) {
      case "facebookmessenger":
        return "Facebook";
      case "telegram":
        return "Telegram";
      case "webexmeeting":
        return "Webex Meeting";
      case "googlehome":
        return "Google Assistant";
      case "amazonalexa":
        return "Amazon Alexa";
      case "whatsapp":
        return "Whatsapp";
      case "applebusinesschat":
        return "Apple Business Chat";
      case "twitter":
        return "Twitter";
      case "googlebusinessmessages":
        return "Google Business Messages";
    }
    return "Chat";
  },
  secondsToHms(seconds) {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    let hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    if (hDisplay + mDisplay + sDisplay === "") {
      hDisplay = "00:00";
    }
    return hDisplay + mDisplay + sDisplay;
  },
  chartDefaultTooltip(value) {
    return `<div>${value.marker}${value.value[1]}</div>
            <div class="text-right">
              <b>${value.value[0]}</b>
            </div>`;
  },
};
