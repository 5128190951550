<template>
  <v-row class="mx-0">
    <v-col cols="12" md="6" v-if="!editedConnector.uuid" class="pb-0">
      <v-text-field
        label="Connector's name"
        v-model.trim="editedConnector.name"
        prepend-inner-icon="mdi-card-bulleted"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
      />
    </v-col>
    <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
      <v-text-field readonly disabled label="Webhook" v-model="editedConnector.webhookUrl" prepend-inner-icon="mdi-webhook" :rules="[requiredField]" />
      <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
    </v-col>
    <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
      <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
    </v-col>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-text-field
        label="Client Secret for webhook validation"
        v-model="editedConnector.details.clientSecret"
        prepend-inner-icon="mdi-code-braces"
        :rules="[requiredField]"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="editedConnector.details.apiURL" :class="getFormColMargin" class="mt-n6">
      <v-text-field readonly disabled label="Api URL" v-model="editedConnector.details.apiURL" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
    </v-col>
    <v-col cols="12" class="mt-n6">
      <v-text-field
        class="d-flex flex-grow"
        label="Bot's avatar"
        v-model.trim="editedConnector.details.avatar"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[isImageValid]"
        @input="isUrlImage(editedConnector.details.avatar)"
        clearable
      >
        <template v-slot:prepend-inner>
          <v-tooltip top :key="rerender">
            <template v-slot:activator="{ on }">
              <v-icon v-if="checkIconImg">mdi-robot </v-icon>
              <v-icon v-on="on" v-else>mdi-eye</v-icon>
            </template>
            <v-avatar size="50" contain>
              <img :src="editedConnector.details.avatar" alt="Bot's avatar" />
            </v-avatar>
          </v-tooltip>
        </template>
      </v-text-field>
      <div v-show="imageWarning != ''" class="mt-n5 v-messages theme--light error--text" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
        <span class="v-messages__message message-transition-enter-to" style="font-size: 12px !important">{{ imageWarning }} </span>
      </div>
    </v-col>
    <v-col cols="12" class="mt-n6">
      <v-text-field
        class="d-flex flex-grow"
        label="Agent's avatar"
        v-model.trim="editedConnector.details.agentAvatar"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[isImageAgentValid]"
        @input="isAgentUrlImage(editedConnector.details.agentAvatar)"
        clearable
      >
        <template v-slot:prepend-inner>
          <v-tooltip top :key="rerender">
            <template v-slot:activator="{ on }">
              <v-icon v-if="checkAgentIconImg">mdi-face-agent</v-icon>
              <v-icon v-on="on" v-else>mdi-eye</v-icon>
            </template>
            <v-avatar size="50" contain>
              <img :src="editedConnector.details.agentAvatar" alt="Agent's avatar" />
            </v-avatar>
          </v-tooltip>
        </template>
      </v-text-field>
      <div v-show="agentImageWarning != ''" class="mt-n5 v-messages theme--light error--text" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
        <span class="v-messages__message message-transition-enter-to" style="font-size: 12px !important">{{ agentImageWarning }} </span>
      </div>
    </v-col>
    <v-col cols="12">
      <v-row
        align="center"
        justify="center"
        class="dropZone d-flex ma-0 pa-0"
        :class="{ dropZoneHover: hovered }"
        @drop="dropFile"
        @dragenter="hovered = true"
        @mouseleave="hovered = false"
        @dragover="dragover"
      >
        <label>
          <v-col cols="12" class="dropInput" :key="rerender">
            <span v-if="!editedConnector.details.file" class="text-center">
              <div><v-icon large :color="hovered ? 'primary' : ''">mdi-cloud-upload</v-icon></div>
              <div>Drag and drop, or <span :style="hovered ? 'color:#039BE5;font-weight:600' : 'color:#039BE5'">browse</span> your JSON file</div>
            </span>
            <span v-if="editedConnector.details.file && isLoaded" :key="rerender">
              <v-row v-if="isRightJson" align="center" justify="space-between">
                <div>
                  <v-avatar size="50" style="background-color: #2a3c51">
                    <v-icon dark>mdi-code-json</v-icon>
                  </v-avatar>
                  <span class="mx-4">{{ editedConnector.details.file.name }}</span>
                </div>
                <v-btn small color="error" @click.prevent.stop="removeFile"> remove </v-btn>
              </v-row>
              <v-row v-else align="center" justify="space-between">
                <div>
                  <v-btn icon class="error lighten-2">
                    <v-icon color="#EDEDED"> mdi-alert </v-icon>
                  </v-btn>
                  <span class="mx-4"
                    >{{ editedConnector.details.file.name }} <span class="px-1">{{ getErrorLabel }}</span></span
                  >
                </div>
                <v-btn color="error" small @click.prevent.stop="removeFile"> try again </v-btn>
              </v-row>
            </span>
          </v-col>
          <input v-if="!editedConnector.details.file" accept=".json" v-show="false" type="file" id="upload" @change="handleFileChange($event)" />
        </label>
      </v-row>
      <v-row class="px-2 pt-4 pb-8">
        If you need information about the JSON file, please visit
        <a target="_blank" class="pl-1" href="https://businessmessages.google/">Business Messages Docs</a>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-n6">
      <v-subheader>Handler for direct handover requests</v-subheader>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete label="Select Flow" :items="getFlows" v-model="editedConnector.details.handoverFlow" clearable @change="setFlowVersion" />
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            label="Select Version"
            :items="getFlowVersions"
            v-model="editedConnector.details.handoverFlowVersion"
            :rules="[mandatoryIfFlowSelected]"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";

export default {
  name: "GoogleBusinessMessages",
  props: ["editedConnector", "connectors", "isEdit"],
  components: { CopyToClipboard },
  data() {
    return {
      imageWarning: "",
      agentImageWarning: "",
      hovered: false,
      rerender: 0,
      isRightJson: false,
      isLoaded: false,
      notValidJson: false,
      missedParameters: false,
      availabilityTypesList: [
        { type: "businessHours", label: "Business Hours", icon: "mdi-briefcase-clock", size: "55" },
        { type: "agentStatus", label: "Agent Status", icon: "mdi-account-multiple-check", size: "75" },
        { type: "always", label: "Always", icon: "mdi-hours-24", size: "55" },
      ],
      isImageValid: true,
      isImageAgentValid: true,
      flowTargets: {},
    };
  },
  async mounted() {
    this.editedConnector.type = "googlebusinessmessages";
    const flowTargetsResponse = await this.$http.get("/flow");
    this.flowTargets = flowTargetsResponse.data;
    if (this.isEdit) {
      this.isRightJson = true;
      this.isLoaded = true;
      this.isUrlImage(this.editedConnector.details.avatar);
      this.isAgentUrlImage(this.editedConnector.details.agentAvatar);
      this.rerender++;
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    mandatoryIfFlowSelected(value) {
      if (!this.editedConnector.details.handoverFlow) {
        return true;
      }
      if (!value) {
        return "This field is required";
      }
      return true;
    },
    handleFileChange(event) {
      event.preventDefault();
      this.editedConnector.details.file = event.target.files[0];
      this.isValidFile();
    },
    dropFile(event) {
      event.preventDefault();
      this.editedConnector.details.file = event.dataTransfer.files[0];
      this.isValidFile();
    },
    dragover(event) {
      event.preventDefault();
    },
    async isValidFile() {
      this.editedConnector;
      this.missedParameters = false;
      this.notValidJson = false;
      if (this.editedConnector.details.file && this.editedConnector.details.file.type == "application/json") {
        let dataURL = await this.readFileAsDataURL(this.editedConnector.details.file);
        if (dataURL) {
          let data = JSON.parse(dataURL);
          let keysJSON = [
            "type",
            "project_id",
            "private_key_id",
            "private_key",
            "client_email",
            "client_id",
            "auth_uri",
            "token_uri",
            "auth_provider_x509_cert_url",
            "client_x509_cert_url",
          ];
          for (let key in keysJSON) {
            if (!(keysJSON[key] in data)) {
              this.missedParameters = true;
              this.notValidJson = true;
            }
          }
          this.$emit("fileUploaded", { form: !this.notValidJson, json: data });
          this.isRightJson = !this.notValidJson;
        } else {
          this.$emit("fileUploaded", { form: false });
          this.isRightJson = false;
        }
      } else {
        this.$emit("fileUploaded", { form: false });
        this.isRightJson = false;
      }
      this.isLoaded = true;
      this.rerender++;
    },
    async readFileAsDataURL(file) {
      let jsonFile = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsText(file);
      });
      return jsonFile;
    },
    async loadImage(url) {
      return new Promise((resolve) => {
        const image = new Image();
        image.addEventListener("load", () => {
          resolve(image);
        });
        image.src = url;
      });
    },
    removeFile() {
      this.$emit("fileUploaded", { form: false });
      this.rerender++;
    },
    async isUrlImage(url) {
      if (!url || url == "") {
        this.isImageValid = true;
        this.imageWarning = "";
      } else if (url && this.isURL(url) && url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
        let image = await this.loadImage(url);
        if (!image && !image.naturalWidth) {
          this.isImageValid = true;
        }
        if (image.naturalWidth != 1024 || image.naturalHeight != 1024) {
          this.isImageValid = "Avatar image is not 1024x1024 px";
        } else {
          let size = await fieldValidators.checkSize(url);
          if (size === -1) {
            this.isImageValid = true;
            this.imageWarning = "Avatar image size can not be checked. Maximum file size is 50KB";
          } else if (size <= 50000) {
            this.isImageValid = true;
          } else {
            this.isImageValid = "Avatar image size larger than the 50 KB maximum.";
          }
        }
      } else {
        this.isImageValid = "This field should be a valid image URL";
      }
      this.rerender++;
    },
    async isAgentUrlImage(url) {
      if (!url || url == "") {
        this.isImageAgentValid = true;
        this.agentImageWarning = "";
      } else if (url && this.isURL(url) && url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
        let image = await this.loadImage(url);
        if (!image && !image.naturalWidth) {
          this.isImageAgentValid = true;
        }
        if (image.naturalWidth != 1024 || image.naturalHeight != 1024) {
          this.isImageAgentValid = "Avatar image is not 1024x1024 px";
        } else {
          let size = await fieldValidators.checkSize(url);
          if (size === -1) {
            this.isImageAgentValid = true;
            this.agentImageWarning = "Avatar image size can not be checked. Maximum file size is 50KB";
          } else if (size <= 50000) {
            this.isImageAgentValid = true;
          } else {
            this.isImageAgentValid = "Avatar image size larger than the 50 KB maximum.";
          }
        }
      } else {
        this.isImageAgentValid = "This field should be a valid image URL";
      }
      this.rerender++;
    },
    setFlowVersion() {
      if (this.flowTargets && this.editedConnector && this.editedConnector.details) {
        if (this.editedConnector.details.handoverFlow) {
          this.editedConnector.details.handoverFlowVersion = Object.keys(this.flowTargets[this.editedConnector.details.handoverFlow])[0];
        } else {
          this.editedConnector.details.handoverFlowVersion = "";
        }
      }
    },
  },
  computed: {
    getFlows() {
      return Object.keys(this.flowTargets).sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    getFlowVersions() {
      if (this.editedConnector && this.editedConnector.details && this.flowTargets && this.editedConnector.details.handoverFlow) {
        const versions = this.flowTargets[this.editedConnector.details.handoverFlow];
        if (versions) {
          return Object.keys(versions).sort((a, b) => {
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            }
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
      }
      return [];
    },
    checkIconImg() {
      if (this.editedConnector.details.avatar && this.editedConnector.details.avatar.length > 0) {
        if (typeof this.isImageValid == "boolean") {
          if (this.isImageValid) {
            return false;
          }
        }
      }
      return true;
    },
    checkAgentIconImg() {
      if (this.editedConnector.details.agentAvatar && this.editedConnector.details.agentAvatar.length > 0) {
        if (typeof this.isImageAgentValid == "boolean") {
          if (this.isImageAgentValid) {
            return false;
          }
        }
      }
      return true;
    },
    getErrorLabel() {
      // non è un file json
      if (this.editedConnector.details.file.type != "application/json") {
        return "is not a valid configuration file. Only JSON format  is allowed.";
      }
      // è un json, ma non ha i parametri (keys) necessari
      if (this.missedParameters) {
        return "is a JSON file, but necessary parameters are missed for configuration.";
      } else {
        return "does not seem to be in the correct format!";
      }
    },
  },
};
</script>
<style lang="scss">
.theme--dark .dropZone {
  background-color: #455a64;
  border: 2px dashed rgba(211, 211, 211, 0.8);
}
.theme--light .dropZone {
  background-color: rgba(211, 211, 211, 0.4);
  border: 2px dashed rgba(211, 211, 211, 1);
}
.dropZone {
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 120px;
  width: 100%;
  padding: 0;
  span {
    width: 100%;
  }
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
  label,
  .dropInput {
    display: flex;
    height: 120px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 120px;
    width: 100%;
    background-color: transparent;
    cursor: pointer !important;
    padding: 0px 20px;
    span {
      width: 100%;
    }
  }
}
.theme--light {
  .dropZone:hover,
  .theme--light .dropZoneHover {
    background-color: #039be510;
    border: 2px dashed #039be580;
  }
}
.theme--dark {
  .dropZone:hover,
  .theme--dark .dropZoneHover {
    background-color: #34434c !important;
    border: 2px dashed #039be580 !important;
  }
}
</style>
