<template>
  <v-row>
    <v-col cols="12" md="5" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.endpointURL"
        label="Endpoint URL"
        :rules="[requiredField]"
        prepend-inner-icon="mdi-card-bulleted"
        :autocomplete="$store.getters.disableAutocomplete"
      />
    </v-col>
    <v-col cols="12" md="5" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.appId"
        label="App ID"
        :rules="[requiredField]"
        prepend-inner-icon="mdi-card-bulleted"
        :autocomplete="$store.getters.disableAutocomplete"
      />
    </v-col>
    <v-col cols="12" md="5" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.apiKey"
        label="API Key"
        :type="showPassword ? 'text' : 'password'"
        :rules="[requiredField]"
        prepend-inner-icon="mdi-key"
        autocomplete="new-password"
      >
        <template slot="append">
          <v-btn depressed icon text :disabled="engine.apiKey && engine.apiKey.length < 1" tabindex="-1">
            <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
              visibility_off
            </v-icon>
            <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
              visibility
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col :class="getFormColMargin" cols="6" md="3">
      <v-switch v-model="engine.staging" label="Is staging configuration" color="primary" />
    </v-col>
    <v-col :class="getFormColMargin" cols="6" md="3">
      <v-switch v-model="engine.enableDisambiguation" label="Enable Disambiguation" color="primary" />
    </v-col>
    <v-col v-show="engine.enableDisambiguation" cols="12" md="6" :class="getFormColMargin">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            Disambiguation Threshold
          </div>
        </template>
        <span>Threshold that will exclude from disambiguation questions all intents with the score below the configured limit</span>
      </v-tooltip>
      <v-slider
        v-model="engine.disambiguationThreshold"
        min="0"
        max="100"
        thumb-label="always"
        thumb-size="25"
        class="mt-6"
      >
        <template #prepend>
          0
        </template>
        <template #append>
          100
        </template>
      </v-slider>
    </v-col>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Luis",
  props: ["engine", "isEdit"],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    ...spacing,
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
