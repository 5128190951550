<template>
  <div>
    <!-- version 1 -->
    <template v-if="radioButton == 'version1'">
      <v-row :class="getFormRowMargin">
        <v-alert type="warning" dense outlined text>
          {{ warningText }}
        </v-alert>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-radio-group v-model="radioButton" row>
          <v-radio label="Assistant 1" value="version1" color="primary" />
          <v-radio label="Assistant 2" value="version2" color="primary" />
        </v-radio-group>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.username"
            label="Username"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[requiredField]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-btn depressed icon :disabled="engine.password.length < 1" tabindex="-1">
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.workspaceId"
            label="Workspace ID"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-card-bulleted"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>
    </template>

    <!-- version 2 -->
    <template v-else>
      <v-row v-if="engine.username" :class="getFormRowMargin">
        <v-radio-group v-model="radioButton" row>
          <v-radio label="Assistant 1" value="version1" color="primary" />
          <v-radio label="Assistant 2" value="version2" color="primary" />
        </v-radio-group>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.apiKey"
            label="ApiKey"
            :type="showPasswordApiKey ? 'text' : 'password'"
            :append-icon="showPasswordApiKey ? 'visibility' : 'visibility_off'"
            :rules="[requiredField]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
            @click:append="showPasswordApiKey = !showPasswordApiKey"
          />
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.url"
            label="Url"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-server-network"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.assistantId"
            label="Assistant ID"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-card-bulleted"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Watson",
  props: ["engine", "isEdit"],
  data() {
    return {
      showPassword: false,
      showPasswordApiKey: false,
      radioButton: "",
      warningText: "Warning: assistant 1 of Watson's API will be shut down. Upgrade the engine!",
    };
  },
  computed: {
    ...spacing,
  },
  mounted() {
    if (this.engine.username) {
      this.radioButton = "version1";
    } else {
      this.radioButton = "version2";
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
