<template>
  <v-container fluid class="exportPage">
    <ResultSnackbar ref="resultSnackbar" />
    <v-dialog v-model="botFlowDetailDialog" persistent max-width="600">
      <v-card style="overflow-x: hidden">
        <v-card-title>
          Flow List
          <SearchBar :searchString="searchFlowString" :noMargin="true" :intoModal="true" @input="searchFlowString = $event" />
        </v-card-title>
        <v-card-text class="pb-2">
          <v-chip-group v-model="botSelectedFlows" column multiple>
            <v-chip
              v-for="(flowObj, index) in botList[botSelected]"
              v-show="shouldFlowChipVisible(flowObj.flow)"
              :key="'chip_bot_' + index"
              label
              active-class="primary"
              :value="flowObj"
            >
              {{ flowObj.flow }}
            </v-chip>
          </v-chip-group>
          <v-divider class="mt-2" />
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-4" color="success" @click="onSaveFlowSelection">
            <v-icon left>
              mdi-floppy
            </v-icon>Save
          </v-btn>
          <v-btn class="ml-2" color="error" @click="botFlowDetailDialog = false">
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
          <v-spacer />
          <v-btn text color="primary" class="ml-2" @click="botSelectedFlows = botSelectedFlows.concat(botList[botSelected])">
            Select All
          </v-btn>
          <v-btn text color="primary" @click="botSelectedFlows = []">
            Deselect All
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanelModel" popout class="mt-12 mx-4">
        <v-expansion-panel v-for="(data, id) in expansionPanelData" :key="id">
          <!-- questa property serve per quegli oggetti che hanno solo la checkbox si/no es: customerinterface -->
          <v-expansion-panel-header v-if="!data.hasOwnProperty('selected')">
            <v-row align="center" class="spacer" no-gutters>
              <v-checkbox
                color="primary"
                class="mb-n5 mt-0"
                :value="data.allElements.length === data.elements.length"
                :indeterminate="data.elements.length > 0 && data.allElements.length > data.elements.length"
                @click.native.prevent="preventExpansionPanelBehavior($event)"
                @change="selectAllElement(id)"
              >
                <template #label>
                  <span>{{ data.title }} </span>
                  <span v-if="id === 'bots' && data.flows.length > 0"> - {{ data.flows.length }} flows selected</span>
                  <span v-if="id === 'flowsConfigurations' && data.elements.length > 0"> - {{ data.elements.length }} flows configurations selected</span>
                  <span v-if="id === 'flows' && data.elements.length > 0"> - {{ data.elements.length }} unassigned flows selected</span>
                  <span v-if="id === 'engines' && data.elements.length > 0"> - {{ data.elements.length }} engines selected</span>
                  <span v-if="id === 'inputProcessors' && data.elements.length > 0"> - {{ data.elements.length }} input processors selected</span>
                  <span v-if="id === 'watchbotScripts' && data.elements.length > 0"> - {{ data.elements.length }} watchbot selected</span>
                  <span v-if="id === 'scheduledReport' && data.elements.length > 0"> - {{ data.elements.length }} scheduled report selected</span>
                  <span v-if="id === 'emailBox' && data.elements.length > 0"> - {{ data.elements.length }} email boxes selected</span>
                  <span v-if="id === 'customerInterface' && data.elements.length > 0"> - {{ data.elements.length }} customer interfaces selected</span>
                  <span v-if="id === 'agentInterface' && data.elements.length > 0"> - {{ data.elements.length }} agent desktops selected</span>
                  <span v-if="id === 'agents' && data.elements.length > 0"> - {{ data.elements.length }} agents selected</span>
                  <span v-if="id === 'profiles' && data.elements.length > 0"> - {{ data.elements.length }} profiles selected</span>
                  <span v-if="id === 'queues' && data.elements.length > 0"> - {{ data.elements.length }} queues selected</span>
                  <span v-if="id === 'shortcodes' && data.elements.length > 0"> - {{ data.elements.length }} shortcodes selected </span>
                  <span v-if="id === 'attachedVariables' && data.elements.length > 0"> - {{ data.elements.length }} attached variables selected </span>
                  <span v-if="id === 'permissionSet' && data.elements.length > 0"> - {{ data.elements.length }} permission sets selected </span>
                  <span v-if="id === 'surveys' && data.elements.length > 0"> - {{ data.elements.length }} surveys selected </span>
                </template>
              </v-checkbox>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else disabled="true" hide-actions>
            <v-row align="center" class="spacer" no-gutters>
              <v-checkbox v-model="data.selected" color="primary" class="mb-n5 mt-0" :value="data.selected" :disabled="!data.enabled">
                <template #label>
                  <span>{{ data.title }}</span><span v-if="!data.enabled" class="disabled-message">{{ data.disabledMessage }}</span>
                </template>
              </v-checkbox>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <SearchBar :searchString="data.searchString" @input="data.searchString = $event" />
            <v-row v-if="id != 'bots' && id != 'flows' && id != 'agentInterface'" class="pl-4">
              <v-checkbox
                v-for="label in expansionPanelData[id].allElements"
                v-show="shouldShowCheckbox(id, label)"
                :key="label"
                v-model="data.elements"
                style="min-width: 250px"
                :label="getLabel(id, label)"
                color="primary"
                :value="label"
              />
            </v-row>
            <v-row v-else-if="id == 'agentInterface'" class="pl-4">
              <v-checkbox
                v-for="label in expansionPanelData[id].allElements"
                v-show="shouldShowCheckbox(id, label)"
                :key="label.name + '_' + label.uuid"
                v-model="data.elements"
                style="min-width: 250px"
                :label="getLabel(id, label)"
                color="primary"
                :value="label"
              />
            </v-row>
            <v-row v-else-if="id == 'flows'" class="pl-4">
              <v-tooltip v-for="label in expansionPanelData[id].allElements" :key="label.flow + '_' + label.version" bottom nudge-left="20" nudge-top="20">
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-checkbox
                      v-show="shouldShowCheckbox(id, label)"
                      v-model="data.elements"
                      style="min-width: 250px"
                      :label="getLabel(id, label)"
                      color="primary"
                      :value="label"
                    />
                  </span>
                </template>
                <span>Version: {{ label.version }}</span>
              </v-tooltip>
            </v-row>
            <v-row v-else-if="id == 'bots'">
              <v-col v-for="label in getBotList" :key="label" cols="12" md="4" class="pt-0">
                <v-list subheader two-line flat>
                  <v-list-item-group multiple>
                    <v-list-item>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-checkbox
                              v-model="data.elements"
                              color="primary"
                              :value="label"
                              :indeterminate="atLeastOneButNotAllFlowIsSelected(label)"
                              v-on="on"
                              @change="selectAllBotFlow(label, data)"
                            />
                          </template>
                          <span>Export {{ label }} bot and all its flows</span>
                        </v-tooltip>
                      </v-list-item-action>
                      <v-list-item-content @click="openBotDetailDialog(label)">
                        <v-list-item-title class="font-weight-medium">
                          {{ label }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-btn text x-small icon v-on="on">
                                <v-icon>mdi-format-list-checks</v-icon>
                              </v-btn>
                              <span>{{ getSelectedFlowLabel(id, label) }}</span>
                            </template>
                            <span>Select single flows of {{ label }}</span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer />
              <v-btn text color="primary" :class="getButtonMargin" @click.prevent="selectAllBotAndAllFlows(data)">
                <span>Select All</span>
              </v-btn>
              <v-btn text color="primary" :class="getButtonMargin" @click.prevent="deselectAllBotAndFlows(data)">
                <span>Deselect All</span>
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row :class="getMainRowMargin">
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn color="success ml-1" @click="exportData">
            <v-icon left>
              mdi-download
            </v-icon>
            <span>Export</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <div v-show="false">
      <form ref="exportFormToSubmit" method="POST" action="/export">
        <input type="text" name="bots" value>
        <input type="text" name="flows" value>
        <input type="text" name="engines" value>
        <input type="text" name="inputProcessors" value>
        <input type="text" name="watchbotScripts" value>
        <input type="text" name="scheduledReport" value>
        <input type="text" name="emailBox" value>
        <input type="text" name="flowsConfigurations" value>
        <input type="text" name="customerInterface" value>
        <input type="text" name="agents" value>
        <input type="text" name="profiles" value>
        <input type="text" name="queues" value>
        <input type="text" name="shortcodes" value>
        <input type="text" name="attachedVariables" value>
        <input type="text" name="permissionSet" value>
        <input type="text" name="surveys" value>
        <input type="text" name="agentInterface" value>
        <input type="text" name="type" value="EXPORT">
      </form>
    </div>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import SearchBar from "../../components/other/SearchBar";
import EventBus from "../../event-bus";

export default {
  name: "Export",
  components: {
    ResultSnackbar,
    SearchBar,
  },
  data() {
    return {
      rerender: 0,
      expansionPanelModel: 0,
      surveysList: {},
      botList: {},
      botFlowDetailDialog: false,
      botSelected: "",
      botSelectedFlows: [],
      expansionPanelData: {
        bots: {
          title: "Bots",
          searchString: "",
          allElements: [],
          elements: [],
          flows: [],
        },
        flowsConfigurations: {
          title: "Flows Configurations",
          searchString: "",
          allElements: [
            "General",
            "Error",
            "Inactivity",
            "Input Processors",
            "Semantic Hooks",
            "System Jumps",
            "Conditional Jumps",
            "Menu Node Configurations",
            "Global variables",
          ],
          elements: [],
        },
        flows: {
          title: "Unassigned Flows",
          searchString: "",
          allElements: [],
          elements: [],
        },
        engines: {
          title: "Semantic Engines",
          searchString: "",
          allElements: [],
          elements: [],
        },
        inputProcessors: {
          title: "Input Processors",
          searchString: "",
          allElements: [],
          elements: [],
        },
        watchbotScripts: {
          title: "Watchbot Scripts",
          searchString: "",
          allElements: [],
          elements: [],
        },
        scheduledReport: {
          title: "Scheduled Reports",
          searchString: "",
          allElements: [],
          elements: [],
        },
        emailBox: {
          title: "Email Boxes",
          searchString: "",
          allElements: [],
          elements: [],
        },
        customerInterface: {
          title: "Customer Interfaces",
          searchString: "",
          allElements: [],
          elements: [],
        },
        agentInterface: {
          title: "Agent Desktops",
          searchString: "",
          allElements: [],
          elements: [],
        },
        agents: {
          title: "Agents",
          searchString: "",
          allElements: [],
          elements: [],
        },
        profiles: {
          title: "Profiles",
          searchString: "",
          allElements: [],
          elements: [],
        },
        queues: {
          title: "Queues",
          searchString: "",
          allElements: [],
          elements: [],
        },
        shortcodes: {
          title: "Shortcodes",
          searchString: "",
          allElements: [],
          elements: [],
        },
        attachedVariables: {
          title: "Handover parameters",
          searchString: "",
          allElements: [],
          elements: [],
        },
        permissionSet: {
          title: "Permission sets",
          searchString: "",
          allElements: [],
          elements: [],
        },
        surveys: {
          title: "Surveys",
          searchString: "",
          allElements: [],
          elements: [],
        },
      },
      searchFlowString: "",
    };
  },
  computed: {
    ...spacing,
    getBotList() {
      this.rerender;
      return (
        Object.keys(this.botList)
          //Filtro per la ricerca
          .filter((bot) => bot.toLowerCase().indexOf(this.expansionPanelData.bots.searchString.toLowerCase()) !== -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            }
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    try {
      let botResponse = await this.$http.get("/bot");
      let unassignedFlowsResponse = await this.$http.get("/flow/list/unassigned");
      if (!botResponse.data) botResponse.data = {};
      this.expansionPanelData.bots.allElements = Object.values(botResponse.data).map((bot) => bot.name);
      if (!unassignedFlowsResponse.data.flows) unassignedFlowsResponse.data.flows = {};
      for (let flow in unassignedFlowsResponse.data.flows) {
        for (let version of Object.keys(unassignedFlowsResponse.data.flows[flow])) {
          this.expansionPanelData.flows.allElements.push({
            flow: flow,
            version: version,
          });
        }
      }
      for (let botId in botResponse.data) {
        let bot = botResponse.data[botId];
        this.botList[bot.name] = bot.flows;
      }
      this.rerender++;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }

    let agents = {};
    let profiles = {};
    let queues = {};
    let shortcodes = {};
    let attachedVariables = {};
    let permissionSet = {};
    let surveys = {};
    try {
      agents = await this.$http.get("/human-agents/agent");
      this.expansionPanelData.agents.allElements = this.expansionPanelData.agents.allElements.concat(
        agents.data.map((obj) => obj.authentication.credentials.username),
      );
      profiles = await this.$http.get("/human-agents/profile");
      this.expansionPanelData.profiles.allElements = this.expansionPanelData.profiles.allElements.concat(profiles.data.map((obj) => obj.name));
      queues = await this.$http.get("/human-agents/queue");
      this.expansionPanelData.queues.allElements = this.expansionPanelData.queues.allElements.concat(queues.data.map((obj) => obj.name));
      shortcodes = await this.$http.get("/human-agents/shortcode");
      const shortcodesList = Object.values(shortcodes.data);
      this.expansionPanelData.shortcodes.allElements = this.expansionPanelData.shortcodes.allElements.concat(shortcodesList.map((obj) => obj.label));

      attachedVariables = await this.$http.get("/human-agents/attached-variable");
      const attachedVariablesList = Object.values(attachedVariables.data);
      this.expansionPanelData.attachedVariables.allElements = this.expansionPanelData.attachedVariables.allElements.concat(
        attachedVariablesList.map((obj) => obj.label),
      );
      // Solo i permission set non di default possono essere esportati, perché quelli deafult vengono generati in automatico dal sistema e non sono modificabili
      permissionSet = (await this.$http.get("/human-agents/permission-set")).data.filter((permissionSet) => !permissionSet.default);
      this.expansionPanelData.permissionSet.allElements = this.expansionPanelData.permissionSet.allElements.concat(permissionSet.map((obj) => obj.name));

      surveys = await this.$http.get("/human-agents/survey");
      this.surveysList = surveys.data;
      this.expansionPanelData.surveys.allElements = this.expansionPanelData.surveys.allElements.concat(Object.values(this.surveysList).map((obj) => obj.uuid));
    } catch {
      agents = {};
      profiles = {};
      queues = {};
      shortcodes = {};
      attachedVariables = {};
      permissionSet = {};
      surveys = {};
    }

    //* richieste singole per compatibilità con le permission
    //* perchè vanno in 401 quando non autorizzate
    let engineResponse = {};
    try {
      engineResponse = await this.$http.get("/semantic-engine");
      this.expansionPanelData.engines.allElements = this.expansionPanelData.engines.allElements.concat(engineResponse.data.map((obj) => obj.name));
    } catch {
      engineResponse = {};
    }

    let inputProcessorResponse = {};
    try {
      inputProcessorResponse = await this.$http.get("/input-processor");
      this.expansionPanelData.inputProcessors.allElements = this.expansionPanelData.inputProcessors.allElements.concat(
        inputProcessorResponse.data.map((obj) => obj.name),
      );
    } catch {
      inputProcessorResponse = {};
    }

    let scriptResponse = {};
    try {
      scriptResponse = await this.$http.get("/watchbot");
      this.expansionPanelData.watchbotScripts.allElements = Object.keys(scriptResponse.data);
    } catch {
      scriptResponse = {};
    }

    let reportResponse = {};
    try {
      reportResponse = await this.$http.get("/analytics/scheduled-report");
      if (!reportResponse.data) reportResponse.data = {};
      this.expansionPanelData.scheduledReport.allElements = Object.keys(reportResponse.data);
    } catch {
      reportResponse = {};
    }

    let mailBoxResponse = {};
    try {
      mailBoxResponse = await this.$http.get("/mail-settings");
      if (!mailBoxResponse.data) mailBoxResponse.data = {};
      this.expansionPanelData.emailBox.allElements = Object.keys(mailBoxResponse.data);
    } catch {
      mailBoxResponse = {};
    }

    let customerInterface = {};
    try {
      customerInterface = await this.$http.get("/customer-ui/exists");
      if (!customerInterface.data) customerInterface.data = {};
      this.expansionPanelData.customerInterface.allElements = this.expansionPanelData.customerInterface.allElements.concat(
        customerInterface.data.map((obj) => obj.name),
      );
    } catch {
      customerInterface = {};
    }

    let agentInterface = {};
    try {
      agentInterface = await this.$http.get("/agent-ui/list");
      if (!agentInterface.data) agentInterface.data = {};
      this.expansionPanelData.agentInterface.allElements = this.expansionPanelData.agentInterface.allElements.concat(
        agentInterface.data.map((obj) => {
          return { uuid: obj.uuid, name: obj.name };
        }),
      );
    } catch {
      agentInterface = {};
    }
  },
  methods: {
    onSaveFlowSelection() {
      // Rimuovo tutti i flow del bot di cui ho aperto la dialog
      this.expansionPanelData["bots"].flows = this.expansionPanelData["bots"].flows.filter(
        (item) => !this.botList[this.botSelected].some((other) => item.flow === other.flow && item.version === other.version),
      );
      // Deseleziono il bot se almeno uno è stato rimosso
      if (this.expansionPanelData["bots"].elements.indexOf(this.botSelected) !== -1 && this.botSelectedFlows.length < this.botList[this.botSelected].length) {
        this.expansionPanelData["bots"].elements.splice(this.expansionPanelData["bots"].elements.indexOf(this.botSelected), 1);
      }
      // Aggiunto i flussi selezionati
      this.botSelectedFlows.forEach((flowObj) => {
        let alreadyIn = this.expansionPanelData["bots"].flows.some((item) => flowObj.flow === item.flow && flowObj.version === item.version);
        if (!alreadyIn) {
          this.expansionPanelData["bots"].flows.push(flowObj);
        }
      });
      this.botFlowDetailDialog = false;
      this.botSelectedFlows = [];
      this.botSelected = "";
    },
    openBotDetailDialog(bot) {
      this.botSelected = bot;
      this.botSelectedFlows = this.getFlowSelected(bot);
      this.botFlowDetailDialog = true;
    },
    atLeastOneButNotAllFlowIsSelected(bot) {
      let commonItems = 0;
      for (let i = 0; i < this.botList[bot].length; i++) {
        let flowObj = this.botList[bot][i];
        let isSelected = this.expansionPanelData["bots"].flows.some((obj) => {
          return obj.flow === flowObj.flow && obj.version === flowObj.version;
        });
        if (isSelected) {
          commonItems++;
        }
      }
      return commonItems > 0 && this.expansionPanelData.bots.elements.indexOf(bot) === -1;
    },
    removeSelectedFlowsOfBot(bot) {
      this.expansionPanelData["bots"].flows = this.expansionPanelData["bots"].flows.filter(
        (item) => !this.botList[bot].some((other) => item.flow === other.flow && item.version === other.version),
      );
      if (this.expansionPanelData["bots"].elements.indexOf(bot) !== -1) {
        this.expansionPanelData["bots"].elements.splice(this.expansionPanelData["bots"].elements.indexOf(bot), 1);
      }
    },
    deselectAllBotAndFlows(data) {
      data.elements = [];
      data.flows = [];
    },
    selectAllBotAndAllFlows(data) {
      data.elements = [].concat(data.allElements);
      if (data.flows) {
        let allFlows = [];
        for (let bot in this.botList) {
          allFlows = allFlows.concat(this.botList[bot]);
        }
        data.flows = allFlows;
      }
    },
    shouldShowCheckbox(section, label) {
      let val = label.flow || label.name || label;
      return val.toLowerCase().indexOf(this.expansionPanelData[section].searchString.toLowerCase()) !== -1;
    },
    shouldFlowChipVisible(flow) {
      return flow.toLowerCase().indexOf(this.searchFlowString.toLowerCase()) !== -1;
    },
    exportData() {
      if (this.atLeastOneElementIsSelected()) {
        this.$refs.exportFormToSubmit.action = "../export/global?j=" + this.$store.state.loginResponse.jwt;
        this.$refs.exportFormToSubmit[0].value = this.expansionPanelData.bots.elements.join(",");
        this.$refs.exportFormToSubmit[2].value = this.expansionPanelData.engines.elements.join(",");
        this.$refs.exportFormToSubmit[3].value = this.expansionPanelData.inputProcessors.elements.join(",");
        this.$refs.exportFormToSubmit[4].value = this.expansionPanelData.watchbotScripts.elements.join(",");
        this.$refs.exportFormToSubmit[5].value = this.expansionPanelData.scheduledReport.elements.join(",");
        this.$refs.exportFormToSubmit[6].value = this.expansionPanelData.emailBox.elements.join(",");
        let listOfConf = [];
        for (let i = 0; i < this.expansionPanelData.flowsConfigurations.elements.length; i++) {
          let el = this.expansionPanelData.flowsConfigurations.elements[i].toLowerCase();
          listOfConf.push(el.replace(/ /gi, "_"));
        }
        this.$refs.exportFormToSubmit[7].value = listOfConf.join(",");
        this.$refs.exportFormToSubmit[8].value = this.expansionPanelData.customerInterface.elements.join(",");
        this.$refs.exportFormToSubmit[9].value = this.expansionPanelData.agents.elements.join(",");
        this.$refs.exportFormToSubmit[10].value = this.expansionPanelData.profiles.elements.join(",");
        this.$refs.exportFormToSubmit[11].value = this.expansionPanelData.queues.elements.join(",");
        this.$refs.exportFormToSubmit[12].value = this.expansionPanelData.shortcodes.elements.join(",");
        this.$refs.exportFormToSubmit[13].value = this.expansionPanelData.attachedVariables.elements.join(",");
        this.$refs.exportFormToSubmit[14].value = this.expansionPanelData.permissionSet.elements.join(",");
        this.$refs.exportFormToSubmit[15].value = this.expansionPanelData.surveys.elements.join(",");
        const agentInterfaceUUIDs = [].concat(this.expansionPanelData.agentInterface.elements.map((obj) => obj.uuid));
        this.$refs.exportFormToSubmit[16].value = agentInterfaceUUIDs.join(",");
        let flowList = [].concat(this.expansionPanelData.flows.elements.map((obj) => obj.flow + "-" + obj.version));
        this.expansionPanelData.bots.elements.forEach((bot) => {
          flowList = flowList.concat(this.botList[bot].map((obj) => obj.flow + "-" + obj.version));
        });
        this.expansionPanelData.bots.flows.forEach((flowObj) => {
          let found = flowList.find((obj) => obj === flowObj.flow + "-" + flowObj.version);
          if (!found) {
            flowList.push(flowObj.flow + "-" + flowObj.version);
          }
        });
        this.$refs.exportFormToSubmit[1].value = flowList.join(",");
        this.$refs.exportFormToSubmit.submit();
      } else {
        this.$refs.resultSnackbar.showError("Select al least one element");
      }
    },
    getSelectedFlowLabel(id, label) {
      if (id === "bots" && this.botList[label]) {
        let numFlowSelected = this.getFlowSelected(label).length;
        return " ( " + numFlowSelected + " / " + this.botList[label].length + " flow" + (this.botList[label].length > 1 ? "s selected" : " selected") + ")";
      }
      return "";
    },
    getLabel(id, label) {
      if (id === "bots" && this.botList[label]) {
        let numFlowSelected = this.getFlowSelected(label).length;
        if (numFlowSelected === 0) return label;
        return (
          label + " ( " + numFlowSelected + " / " + this.botList[label].length + " flow" + (this.botList[label].length > 1 ? "s selected" : " selected") + ")"
        );
      }
      if (id === "agentInterface") return label.name;
      if (id == "flows" && label) return label.flow;
      if (id == "surveys" && this.surveysList[label]) return this.surveysList[label].name;
      return label;
    },
    getFlowSelected(bot) {
      let res = [];
      this.botList[bot].forEach((flowObj) => {
        for (let i = 0; i < this.expansionPanelData.bots.flows.length; i++) {
          let fo = this.expansionPanelData.bots.flows[i];
          if (fo.flow === flowObj.flow && fo.version === flowObj.version) {
            res.push(flowObj);
          }
        }
      });
      return res;
    },
    atLeastOneElementIsSelected() {
      for (let type in this.expansionPanelData) {
        if (this.expansionPanelData[type].elements && this.expansionPanelData[type].elements.length > 0) {
          return true;
        }
        if (this.expansionPanelData[type].flows && this.expansionPanelData[type].flows.length > 0) {
          return true;
        }
        if (this.expansionPanelData[type].selected) {
          return true;
        }
      }
      return false;
    },
    selectAllBotFlow(bot, data) {
      if (this.expansionPanelData.bots.elements.indexOf(bot) !== -1) {
        this.botList[bot].forEach((flowObj) => {
          let alreadyIn = data.flows.some((item) => flowObj.flow === item.flow && flowObj.version === item.version);
          if (!alreadyIn) {
            data.flows.push(flowObj);
          }
        });
      } else {
        data.flows = data.flows.filter((item) => !this.botList[bot].some((other) => item.flow === other.flow && item.version === other.version));
      }
    },
    selectAllElement(key) {
      if (this.expansionPanelData[key].elements.length === this.expansionPanelData[key].allElements.length) {
        this.expansionPanelData[key].elements = [];
        if (key === "bots") {
          this.expansionPanelData[key].flows = [];
        }
      } else {
        this.expansionPanelData[key].elements = [].concat(this.expansionPanelData[key].allElements);
        if (key === "bots") {
          for (let label in this.botList) {
            this.selectAllBotFlow(label, this.expansionPanelData.bots);
          }
        }
      }
    },
    preventExpansionPanelBehavior(e) {
      //* per evitare che il click sulla checkbox apra il panel
      e.cancelBubble = true;
    },
  },
};
</script>
<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}
/* per dare il colore azzurro a tutti i tipi di selezione degli export */
/* .exportPage .v-input--indeterminate i.v-icon.notranslate.mdi.mdi-minus-box,
.exportPage .v-input--checkbox i.v-icon.notranslate.mdi.mdi-checkbox-marked {
  color: #039be5 !important;
} */
.exportPage .disabled-message {
  color: orange;
  padding-left: 25px;
  font-size: small;
}
</style>
