<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
        <v-text-field
          v-if="editedConnector.uuid"
          readonly
          disabled
          label="Webhook"
          v-model="editedConnector.webhookUrl"
          prepend-inner-icon="mdi-webhook"
          :rules="[requiredField]"
        />
        <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
      </v-col>
    </v-row>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" md="6" v-if="!editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Main scene name"
          v-model.trim="editedConnector.details.scene"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, isConnectorAppIdUnique(connectors, editedConnector.details.scene)]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="API URL"
          v-model.trim="editedConnector.details.apiURL"
          prepend-inner-icon="mdi-web"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";

export default {
  name: "AlexaConf",
  props: ["editedConnector", "connectors"],
  components: { CopyToClipboard },
  data() {
    return {
      showSecretToken: false,
    };
  },
  mounted() {
    this.editedConnector.type = "googlehome";
    if (!this.editedConnector.uuid) {
      this.editedConnector.details.webhookUrl = "";
      this.editedConnector.details.apiURL = "https://console.actions.google.com";
      this.editedConnector.details.scene = "ConvyInteraction";
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
