export default {
  getUserInitials,
  getAvatarFontColor,
  getAvatar,
};

function getUserInitials(user) {
  if (user.info.name !== "" && user.info.surname !== "") {
    return user.info.name[0].toUpperCase() + user.info.surname[0].toUpperCase();
  }
  return "?";
}
// questo metodo serve se l'utente non ha scelto l'avatar e vengono mostrate le sue iniziali
function getAvatarFontColor(color) {
  // Variables for red, green, blue values
  let r, g, b, hsp;
  color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
  r = color >> 16;
  g = (color >> 8) & 255;
  b = color & 255;
  // HSP (Highly Sensitive Poo) equation
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5 ? "color: #000;" : "color: #fff;";
}

function getAvatar(user) {
  if (user && Object.keys(user).length > 0 && (user?.info?.name.length === 0 || user?.info?.surname.length === 0) && user?.info?.avatar === "avatar-00.png") {
    return {
      thereIsAvatar: true,
      avatarPath: "./avatars/" + user.info.avatar,
    };
  }
  if (user?.info?.avatar === "avatar-0.png" || user?.info?.avatar === "avatar-00.png") {
    return {
      thereIsAvatar: false,
      avatarPath: "",
    };
  } else {
    let avatar = "";
    if (user?.info?.avatar && user?.info?.avatar.endsWith(".png")) {
      avatar = "./avatars/" + user.info.avatar;
    } else {
      avatar = user?.info?.avatar;
    }
    return {
      thereIsAvatar: true,
      avatarPath: avatar,
    };
  }
}
