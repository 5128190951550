var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-row',{staticClass:"pl-4 pr-6",attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEdit ? false : true),expression:"isEdit ? false : true"}],attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.isEdit ? 'Customer Gui Name' : 'New Customer Gui Name',"rules":!_vm.isEdit
            ? [
                _vm.requiredField,
                _vm.charsGreaterThanAndLessThan(_vm.gui.name, 3, 20),
                _vm.isCustomerInterfaceNameUnique(_vm.customerInterfacesList, _vm.gui.name),
                _vm.validateCustomerInterfaceName(_vm.gui.name),
              ]
            : [],"autocomplete":_vm.$store.getters.disableAutocomplete,"autofocus":"","prepend-icon":"mdi-robot"},model:{value:(_vm.gui.name),callback:function ($$v) {_vm.$set(_vm.gui, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"gui.name"}})],1),_c('v-col',{staticClass:"d-flex justify-end py-0",attrs:{"cols":_vm.isEdit ? 12 : 6}},[_c('v-switch',{attrs:{"dense":"","label":"Import by web url"},on:{"change":function($event){_vm.gui.type == 'zip' ? (_vm.gui.type = 'url') : (_vm.gui.type = 'zip')}},model:{value:(_vm.calcSwitch),callback:function ($$v) {_vm.calcSwitch=$$v},expression:"calcSwitch"}})],1),(_vm.gui.type == 'zip')?_c('fileUpload',{attrs:{"file":_vm.file,"fileAccepted":"zip","isLogo":false,"loading":_vm.loading.save || _vm.loading.delete},on:{"uploadFile":_vm.uploadFile,"removeFile":_vm.removeFile}}):_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","prepend-icon":"mdi-file-link","rules":[_vm.requiredField, _vm.isURL],"label":"Insert url"},model:{value:(_vm.gui.url),callback:function ($$v) {_vm.$set(_vm.gui, "url", $$v)},expression:"gui.url"}})],1),(!_vm.isEdit)?_c('v-col',{class:_vm.gui.type == 'zip' ? 'mt-12 mb-4' : 'mt-10 mb-4',attrs:{"cols":"12","sm":"12"}},[_c('v-row',{staticClass:"px-2",attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","loading":_vm.loading.save,"disabled":_vm.gui.type == 'zip' ? _vm.loading.delete || !_vm.file || (_vm.file && _vm.file.type && !_vm.isZipFile(_vm.file.type)) || !_vm.form : _vm.loading.delete || !_vm.form},on:{"click":function($event){$event.preventDefault();return _vm.importFile.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-floppy")]),_vm._v(" Save ")],1),_c('v-btn',{attrs:{"color":"error","loading":_vm.loading.delete,"disabled":_vm.loading.save},on:{"click":function($event){return _vm.$emit('closePanel')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancel")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }