import whatsappTemplate from "@/helpers/whatsappTemplate";

function sanitizeMultilanguageConfig(configuration) {
  if (configuration["general"]) {
    configuration["general"].completedMessage = transformToMultilanguageIfNeeded(configuration["general"].completedMessage);
    configuration["general"].not_acceptable_incoming_message = transformToMultilanguageIfNeeded(configuration["general"].not_acceptable_incoming_message);
    configuration["general"].max_input_chars_message = transformToMultilanguageIfNeeded(configuration["general"].max_input_chars_message);
    configuration["general"].license_cap_reached_message = transformToMultilanguageIfNeeded(configuration["general"].license_cap_reached_message);
  }

  if (configuration["error"]) {
    configuration["error"].error_message = transformToMultilanguageIfNeeded(configuration["error"].error_message);
    configuration["error"].advancedErrorModeSurveyScore = transformToMultilanguageIfNeeded(configuration["error"].advancedErrorModeSurveyScore);
    configuration["error"].advancedErrorModeSurveyFeedback = transformToMultilanguageIfNeeded(configuration["error"].advancedErrorModeSurveyFeedback);
    configuration["error"].advancedErrorModeHandoverMessage = transformToMultilanguageIfNeeded(configuration["error"].advancedErrorModeHandoverMessage);
    configuration["error"].advancedErrorModeHandoverTargetBubbleIntroduction = transformToMultilanguageIfNeeded(
      configuration["error"].advancedErrorModeHandoverTargetBubbleIntroduction,
    );
    configuration["error"].advancedErrorModeHandoverTargetBubbleName = transformToMultilanguageIfNeeded(
      configuration["error"].advancedErrorModeHandoverTargetBubbleName,
    );
    if (configuration["error"].jump_to && typeof configuration["error"].jump_to == "string") {
      let split = configuration["error"].jump_to.split(" - ");
      configuration["error"].jump_to = {
        flow: split[0],
        version: split[1],
        hook: "begin",
      };
    }
  }

  if (configuration["inactivity"]) {
    configuration["inactivity"].inactivityMessage = transformToMultilanguageIfNeeded(configuration["inactivity"].inactivityMessage);
    configuration["inactivity"].inactivitySurveyScore = transformToMultilanguageIfNeeded(configuration["inactivity"].inactivitySurveyScore);
    configuration["inactivity"].inactivitySurveyFeedback = transformToMultilanguageIfNeeded(configuration["inactivity"].inactivitySurveyFeedback);
    if (configuration["inactivity"].jump_to && typeof configuration["inactivity"].jump_to == "string") {
      let split = configuration["inactivity"].jump_to.split(" - ");
      configuration["inactivity"].jump_to = {
        flow: split[0],
        version: split[1],
        hook: "begin",
      };
    }
  }

  if (configuration["semantic_hooks"]) {
    configuration["semantic_hooks"].start_flow_answer = transformToMultilanguageIfNeeded(configuration["semantic_hooks"].start_flow_answer);

    if (configuration["semantic_hooks"].semanticHookList) {
      let i = Object.keys(configuration["semantic_hooks"].semanticHookList).length;
      while (i--) {
        if (Object.keys(configuration["semantic_hooks"].semanticHookList[i]).length == 0) {
          configuration["semantic_hooks"].semanticHookList.splice(i, 1);
        }
      }
    } else {
      configuration["semantic_hooks"].semanticHookList = [];
    }
    for (let i in configuration["semantic_hooks"].semanticHookList) {
      let oldString = configuration["semantic_hooks"].semanticHookList[i].target;
      if (typeof oldString == "string" && oldString.indexOf("-") != -1) {
        configuration["semantic_hooks"].semanticHookList[i].target = oldString.split("-")[0].trim();
        configuration["semantic_hooks"].semanticHookList[i].version = oldString.split("-")[1].trim();
      }
    }
  }

  if (!configuration.system_jumps) configuration.system_jumps = {};
  if (!configuration.conditional_jumps) configuration.conditional_jumps = {};
  return configuration;
}

function transformToMultilanguageIfNeeded(field) {
  if (!field || typeof field == "string") {
    return [
      {
        code: "en",
        flag: "GB",
        label: "English",
        value: field || "",
      },
    ];
  }
  if (Array.isArray(field) && field.length == 0) {
    field.push({
      code: "en",
      flag: "GB",
      label: "English",
      value: "",
    });
    return field;
  }
  return field;
}

function sanitizeInactivityCareWindow(configuration) {
  if (configuration.inactivity) {
    if (
      configuration.inactivity.care_window &&
      typeof configuration.inactivity.care_window.enabled === "boolean" &&
      configuration.inactivity.care_window.template
    ) {
      if (!configuration.inactivity.care_window.enabled) {
        configuration.inactivity.care_window = whatsappTemplate.getCareWindowTemplate(false, null);
      } else {
        const template = whatsappTemplate.getTemplateInfo(configuration.inactivity.care_window.template);
        configuration.inactivity.care_window = whatsappTemplate.getCareWindowTemplate(true, template, configuration.inactivity.care_window);
      }
    }
    if (configuration.inactivity.care_window.value.enabled.value) {
      //caso in cui ho già la care window nuova, devo comunque fare il check sul numero di telefono
      //il metodo getTemplateInfo ritorna un template, o modifica il group dell'oggetto che gli passo
      //che è quello che serve a me
      whatsappTemplate.getTemplateInfo(configuration.inactivity.care_window.value.template.templateName.value);
    }
  }
  return configuration;
}

function sanitizePostCloseConversation(configuration) {
  if (configuration.general && !configuration.general.post_close_conversation) {
    configuration.general.post_close_conversation = {
      enabled: false,
      jump_to: {},
    };
  }
  return configuration;
}

function sanitizeInactivityProactivityType(configuration) {
  if (configuration.inactivity && configuration.inactivity.proactivityType === "jump") {
    configuration.inactivity.inactivity_survey_enabled = false;
  }
  return configuration;
}

export default {
  sanitizeMultilanguageConfig,
  sanitizeInactivityCareWindow,
  sanitizePostCloseConversation,
  sanitizeInactivityProactivityType,
};
