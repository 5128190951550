<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Setup your Semantic Engine"
      @input="
        searchString = $event;
        forceRerender++;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getSemanticEnginesList.length == 0 && !newEngine && searchString == ''" cols="12">
        <v-alert type="warning">
          No Semantic Engine configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getSemanticEnginesList.length == 0 && !newEngine && searchString != ''" cols="12">
        <v-alert type="warning">
          No Semantic Engine match your search
        </v-alert>
      </v-col>
      <!-- panel per NUOVO ENGINE -->
      <v-expansion-panels v-if="newEngine" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <img width="50" height="50" alt="Avatar" :src="newEngine.image" :style="getCustomAvatarImageMaxSize(newEngine.type)">
              </v-col>
              <v-col cols="8">
                New Engine {{ newEngine.label }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <SemanticEnginesForm :engine="newEngine" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- panel lista ENGINE -->
      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(engine, index) in getSemanticEnginesList" :key="'engine_' + index" :ref="'engine_' + index" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="1">
                  <img width="50" height="50" alt="Avatar" :src="getLogo(engine.type)" :style="getCustomAvatarImageMaxSize(engine.type)">
                </v-col>
                <v-col cols="4">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ engine.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ getTypeEngine(engine.type) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item v-if="engine.engines[engine.name].default" dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>default</v-list-item-subtitle>
                      <v-list-item-icon class="ma-0">
                        <v-btn class="ma-0 ml-1" :color="'success'" x-small text depressed>
                          <v-icon small>
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last update</v-list-item-subtitle>
                      <v-list-item-title v-if="engine.updated_at">
                        {{ engine.updated_at | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{ "-" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <SemanticEnginesForm :key="forceRerender + index + '_se'" :engine="engine.engines[engine.name]" :entity_types="engine.entity_types" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- BOTTONE ADD -->
    <AddNewItemButton :ripple="false" name="Semantic Engine" @addNewItem="showCreationMenu" />
    <v-menu v-model="addMenuProps.showMenu" :position-x="addMenuProps.x" :position-y="addMenuProps.y" absolute offset-y>
      <v-list class="semanticEnginesList">
        <v-list-item v-for="(semanticEngine, index) in sortedEngineTypeList" :key="'semanticEngine_' + index" @click="addSemanticEngine(semanticEngine)">
          <v-list-item-avatar size="23px" :color="$vuetify.theme.dark ? '#313234' : '#FAFAFA'">
            <img alt="Avatar" :src="getLogo(semanticEngine.type)" :style="getLogoStyle(semanticEngine.type)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ semanticEngine.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import SemanticEnginesForm from "../../components/design/SemanticEnginesForm";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "SemanticEngines",
  components: {
    ResultSnackbar,
    AddNewItemButton,
    SearchBar,
    SemanticEnginesForm,
  },
  data() {
    return {
      addMenuProps: {
        showMenu: false,
        x: 0,
        y: 0,
      },
      engineTypeList: this.$store.getters.getSemanticEnginesTypes, // lista tipi di engine
      newEngine: null,
      engines: [], //lista engine creati
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      searchString: "",
      showWarning: false,
    };
  },
  computed: {
    ...spacing,
    sortedEngineTypeList() {
      if (!this.$store.state.company.settings.integrations.knowledgeBase) {
        return this.engineTypeList.filter((engine) => !engine.generative);
      }
      return this.engineTypeList;
    },
    getSemanticEnginesList() {
      this.forceRerender;
      return this.engines
        .filter((engine) => engine.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
        .sort((a, b) => {
          //Sorting per un campo
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_SEMANTIC_ENGINE_CREATION, this.cancelSemanticEngineCreation);

    EventBus.$on(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE, this.deleteSemanticEngine);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE_FAIL, this.deleteSemanticEngineFail);

    EventBus.$on(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE, this.onSemanticEngineUpdate);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE_FAIL, this.onSemanticEngineFailUpdate);

    try {
      const engineResponse = await this.$http.get("/semantic-engine");
      this.engines = engineResponse.data;
      //Questo ciclo for serve per mantenere la compatibilità con SemanticEnginesForm.vue
      this.engines.forEach((engine) => {
        engine.engines[engine.name].engineName = engine.name;
      });
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
      if (this.$route.query.engineName) {
        this.openSpecificPanel(this.$route.query.engineName);
      }
    }

    //* filtro lista engine in base al ruolo
    this.filterEnginesList();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_SEMANTIC_ENGINE_CREATION, this.cancelSemanticEngineCreation);

    EventBus.$off(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE, this.deleteSemanticEngine);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE_FAIL, this.deleteSemanticEngineFail);

    EventBus.$off(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE, this.onSemanticEngineUpdate);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE_FAIL, this.onSemanticEngineFailUpdate);
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,

    getCustomAvatarImageMaxSize(type) {
      switch (type) {
        case "EUDATA":
          return "max-height:30px; max-width:38px";
        case "COGITO":
          return "max-height:35px; max-width:38px";
      }
      return "max-height:38px; max-width:38px";
    },
    getLogoStyle(type) {
      switch (type) {
        case "EUDATA":
          return "max-width:20px;max-height:17px !important;";
      }
      return "max-width:20px;max-height:20px";
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    openSpecificPanel(engineName) {
      this.expansionPanel = this.getSemanticEnginesList.findIndex((engine) => engine.name === engineName);
    },
    showCreationMenu(e) {
      e.preventDefault();
      this.addMenuProps.showMenu = false;
      this.addMenuProps.x = e.clientX;
      this.addMenuProps.y = e.clientY;
      this.$nextTick(() => {
        this.addMenuProps.showMenu = true;
      });
    },
    addSemanticEngine(semanticEngine) {
      //*aggiunta nuovo engine
      if (!this.newEngine) {
        this.newEngine = {
          image: semanticEngine.image,
          type: semanticEngine.type,
          label: semanticEngine.label,
          fastRecognition: true,
          name: "",
        };
        if (this.newEngine.type === "APIAI") {
          this.newEngine.project_id = "";
          this.newEngine.privateKey = "";
          this.newEngine.clientEmail = "";
        }

        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelSemanticEngineCreation() {
      //* annullamento aggiunta nuovo engine
      this.expansionPanelCreation = null;
      this.newEngine = null;
    },
    getLogo(engine) {
      //* logo per engine già presenti
      const types = this.$store.getters.getSemanticEnginesTypes;
      for (let typeConf of types) {
        if (engine === typeConf.type) {
          if (engine.indexOf("COGITO") !== -1 && this.$vuetify.theme.dark) {
            return typeConf.imageDark;
          }
          return typeConf.image;
        }
      }
    },
    deleteSemanticEngineFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteSemanticEngine(obj) {
      let index = this.engines.findIndex((engine) => engine.name === obj.name);
      this.engines.splice(index, 1);
      this.$refs.resultSnackbar.showSuccess("Engine " + obj.name + " deleted!");
      this.closeAllPanels();
      setTimeout(() => {
        this.forceRerender++;
      }, 100);
    },
    closeAllPanels() {
      this.cancelSemanticEngineCreation();
      this.expansionPanel = null;
    },
    onSemanticEngineUpdate(obj) {
      //* tutti gli altri engine
      if (obj.engines[obj.name].default) {
        //* gestione engine di default
        this.changeDefaultEngine(obj.name);
      }
      const index = this.engines.findIndex((engine) => engine.name === obj.name);
      if (index >= 0) {
        //* Edit
        this.engines[index] = merge(this.engines[index], obj);
        this.$refs.resultSnackbar.showSuccess("Engine " + obj.name + " updated!");
      } else {
        //* Nuovo engine
        this.engines.push(obj);
        this.$refs.resultSnackbar.showSuccess("Engine " + obj.name + " added!");
        this.closeAllPanels();
      }
      this.forceRerender++;
    },
    onSemanticEngineFailUpdate(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    changeDefaultEngine(engineName) {
      //* metto tutti gli engine a false
      this.engines.forEach((engine) => {
        if (engine.name !== engineName) engine.engines[engine.name].default = false;
      });
    },
    getTypeEngine(type) {
      if (type === "APIAI") {
        return "DIALOG FLOW";
      }
      if (type === "COGITO") {
        return "COGITO RULE";
      }
      if (type === "CUSTOMENGINE") {
        return "GENERATIVE AI ENGINE";
      }
      return type;
    },
    filterEnginesList() {
      this.engineTypeList = this.engineTypeList.filter((engineType) => {
        if (engineType.type === "COGITO" && !this.hasRequiredPermission("AccessCogitoEngine")) {
          return false;
        }
        if (engineType.type === "APIAI" && !this.hasRequiredPermission("AccessDialogFlowEngine")) {
          return false;
        }
        if (engineType.type === "LUIS" && !this.hasRequiredPermission("AccessLuisEngine")) {
          return false;
        }
        if (engineType.type === "WATSON" && !this.hasRequiredPermission("AccessWatsonEngine")) {
          return false;
        }
        if (engineType.type === "LEX" && !this.hasRequiredPermission("AccessLexEngine")) {
          return false;
        }
        if (engineType.type === "EUDATA" && !this.hasRequiredPermission("AccessEudataEngine")) {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style>
.semanticEnginesList .v-list-item__title {
  line-height: 1.3rem !important;
}
</style>
