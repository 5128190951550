<template>
  <v-form ref="SemanticEngineForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteEngineConfirm" @dialogDeny="showConfirm = false" />

      <!-- CAMPI COMUNI  -->
      <v-row :class="getFormRowMargin" align="center">
        <v-col v-show="!isEdit" cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedEngine.engineName"
            label="Engine name"
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[requiredField, validateSemanticEngineName]"
            prepend-inner-icon="mdi-card-bulleted"
          />
        </v-col>
        <v-col cols="6" md="2">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-switch v-model="editedEngine.default" label="Check as default" color="primary" v-on="on" />
            </template>
            <span>Preselect as default engine for menu nodes</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" md="2">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-switch v-if="editedEngine.type !== 'EUDATA'" v-model="editedEngine.fastRecognition" label="Fast recognition" color="primary" v-on="on" />
            </template>
            <span>
              When a customer click a menu button instead of typing the answer, ConvyAI will skip the semantic engine call and will automatically detect intent
              associated with the button clicked
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-if="editedEngine.type === 'COGITO'"
            v-model="newLangCogito"
            class="mt-3"
            :items="languageListCogito"
            :rules="[requiredField]"
            label="Chose Language"
            prepend-inner-icon="mdi-earth"
            @change="changeLanguageCogito(newLangCogito)"
          />
        </v-col>
      </v-row>

      <!-- CAMPI CUSTOM -->
      <template v-if="editedEngine.type === 'COGITO'">
        <cogito
          :engine="editedEngine"
          :isEdit="isEdit"
          @addCogitoConfigurationCustom="addCogitoConfigurationCustom"
          @deleteCogitoConfigurationCustom="deleteCogitoConfigurationCustom"
        />
      </template>
      <template v-if="editedEngine.type === 'APIAI'">
        <apiai :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'EUDATA'">
        <eudata :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'LUIS'">
        <luis :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'WATSON'">
        <watson :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'LEX'">
        <lex :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'OPENAI'">
        <openai :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'CUSTOMENGINE'">
        <customengine :engine="editedEngine" :isEdit="isEdit" />
      </template>
      <template v-if="editedEngine.type === 'BEDROCK'">
        <bedrock :engine="editedEngine" :isEdit="isEdit" />
      </template>

      <!-- submit/delete -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="submitEngine(editedEngine.type)"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn
            v-if="isEdit"
            :class="getButtonMargin"
            color="primary"
            :to="'/SemanticEngineIntentsAndEntities?engineName=' + editedEngine.engineName + '&engineType=' + editedEngine.type"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            <span>edit</span>
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isEdit && form.submitLoading" @click.prevent="deleteEngine">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import apiai from "./SemanticEngines-detail-form/apiai";
import eudata from "./SemanticEngines-detail-form/eudata";
import cogito from "./SemanticEngines-detail-form/cogito";
import lex from "./SemanticEngines-detail-form/lex";
import luis from "./SemanticEngines-detail-form/luis";
import watson from "./SemanticEngines-detail-form/watson";
import openai from "./SemanticEngines-detail-form/openai";
import customengine from "./SemanticEngines-detail-form/customengine";
import bedrock from "./SemanticEngines-detail-form/bedrock";

import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";

export default {
  name: "SemanticEnginesForm",
  components: {
    ConfirmDialog,
    cogito,
    apiai,
    eudata,
    lex,
    luis,
    watson,
    openai,
    customengine,
    bedrock,
  },
  props: {
    "engine": {
      type: Object,
      required: true
    },
    "entity_types": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Engine " + this.engine.engineName + " will be deleted!",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.engine.name !== "",
      //Flag gestione della form
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedEngine: {},

      languagesAvailable: this.$store.getters.getLanguages,
      languagesList: [], //lista lingue
      newLang: "English",

      languagesAvailableCogito: this.$store.getters.getCogitoSupportedLanguage,
      languageListCogito: [], //lista lingue cogito
      newLangCogito: "",
    };
  },
  computed: {
    ...spacing,
  },
  mounted() {
    this.editedEngine = merge({}, this.engine);
    if (this.isEdit) {
      setTimeout(() => this.$refs.SemanticEngineForm.validate(), 100);
    }

    if (this.engine.type === "COGITO") {
      //* gestione lingue cogito
      for (let x in this.languagesAvailableCogito) {
        this.languageListCogito.push(x);
        if (this.engine.data && this.engine.data.language && this.engine.data.language.toLowerCase() === this.languagesAvailableCogito[x].toLowerCase()) {
          this.newLangCogito = x;
        }
      }
    } else {
      for (let i in this.languagesAvailable) {
        //* gestione lingue
        this.languagesList.push(i); //* carico le lingue
        if (this.engine.language && this.engine.language === this.languagesAvailable[i]) {
          this.newLang = i; //* preselezione lingue
        }
      }
    }
  },
  methods: {
    ...fieldValidators,
    deleteEngine() {
      if (this.isEdit) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_SEMANTIC_ENGINE_CREATION);
      }
    },
    async onDeleteEngineConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/semantic-engine/" + this.editedEngine.engineName);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE, {
          name: this.editedEngine.engineName,
        });
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SEMANTIC_ENGINE_FAIL, {
          name: this.editedEngine.engineName,
          message: "Fail to delete semantic engine " + this.editedEngine.engineName,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async submitEngine(engineType) {
      //* Salvataggio engine
      if (this.form.isValid) {
        if (engineType === "APIAI" && this.editedEngine.token && !this.editedEngine.projectId) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE_FAIL, {
            engine: this.editedEngine,
            message: "ConvyAI supports DialogFlow agents with version 2. Upgrade it.",
          });
        } else if (engineType === "WATSON" && this.editedEngine.username && !this.editedEngine.apiKey && !this.editedEngine.url) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE_FAIL, {
            engine: this.editedEngine,
            message: "ConvyAI supports WATSON assistants with version 2. Upgrade it.",
          });
        } else {
          this.form.submitLoading = true;
          try {
            const response = await this.sendRequest(engineType);
            response.data.engines[response.data.name].engineName = this.editedEngine.engineName;
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE, response.data);
          } catch (err) {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SEMANTIC_ENGINE_FAIL, {
              engine: this.editedEngine,
              message: "Fail to save semantic engine: " + this.editedEngine.engineName,
            });
          } finally {
            this.form.submitLoading = false;
          }
        }
      }
    },
    sendRequest(engineType) {
      //* creazione struttura object engine
      let obj = {};
      let dataConfigurations;
      switch (engineType) {
        case "EUDATA":
          obj = {
            engine: {
              entity_types: this.$store.getters.getEudataEntityTypes,
              engines: {
                [this.editedEngine.engineName]: {
                  type: this.editedEngine.type,
                  language: this.getLanguageCode(this.newLang),
                  default: this.editedEngine.default || false,
                  fastRecognition: true,
                  types: {
                    NUMBER: "NUMBER",
                    TEXT: "TEXT",
                    EMAIL: "EMAIL",
                    PHONE: "PHONE",
                    DATE: "DATE",
                    TIME: "TIME",
                    SURVEY_SCORE: "SURVEY_SCORE",
                  },
                  intents: {
                    "": {
                      tags: [""],
                    },
                  },
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = {};
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "OPENAI":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  type: this.editedEngine.type,
                  isGenerativeEngine: true,
                  language: this.getLanguageCode(this.newLang),
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  params: this.editedEngine.params,
                  apiKey: this.editedEngine.apiKey,
                  threshold: this.editedEngine.threshold,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "BEDROCK":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  type: this.editedEngine.type,
                  isGenerativeEngine: true,
                  language: this.getLanguageCode(this.newLang),
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  params: this.editedEngine.params,
                  region: this.getRegionCode(this.editedEngine.region),
                  threshold: this.editedEngine.threshold,
                  accessKey: this.editedEngine.accessKey,
                  secretKey: this.editedEngine.secretKey,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "CUSTOMENGINE":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  type: this.editedEngine.type,
                  isGenerativeEngine: true,
                  isCustomGenerative: true,
                  language: this.getLanguageCode(this.newLang),
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  threshold: this.editedEngine.threshold,
                  engineURL: this.editedEngine.engineURL,
                  httpMethod: this.editedEngine.httpMethod,
                  variableEncoding: this.editedEngine.variableEncoding,
                  parameters: this.editedEngine.parameters,
                  result: this.editedEngine.result,
                  headers: this.editedEngine.headers,
                  params: this.editedEngine.params,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "COGITO":
          if (!this.editedEngine.data) this.editedEngine.data = {};
          dataConfigurations = this.editedEngine.data;
          obj = {
            engine: {
              entity_types: this.$store.getters.getCogitoEntityTypes,
              engines: {
                [this.editedEngine.engineName]: {
                  data: dataConfigurations,
                  default: this.editedEngine.default || false,
                  extraction: {
                    entities: {
                      key: "NAME",
                      path: "$..EXTRACTION.RECORD.FIELD",
                      value: "BASE",
                    },
                    intents: {
                      key: "NAME",
                      path: "$..DOMAIN",
                    },
                  },
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  headers: {},
                  intents: {},
                  intentsAttributes: {},
                  language: dataConfigurations.language.toLowerCase(),
                  method: this.editedEngine.method,
                  priorityScore: this.editedEngine.priorityScore || false,
                  type: this.editedEngine.type,
                  types: {
                    URLS: "URLS",
                    MAILADDRESSES: "MAILADDRESSES",
                    PHONENUMBERS: "PHONENUMBERS",
                    ADDRESSES: "ADDRESSES",
                    DATE: "DATE",
                    TEXT: "TEXT",
                    TIME: "TIME",
                    MEASURES: "MEASURES",
                    MONEY: "MONEY",
                    PERCENTAGE: "PERCENTAGE",
                    FILEFOLDER: "FILEFOLDER",
                    PLACES: "PLACES",
                    PEOPLE: "PEOPLE",
                    ORGANIZATIONS: "ORGANIZATIONS",
                    CONFIRMATION: "CONFIRMATION",
                    GENERIC_NUMBER: "GENERIC_NUMBER",
                  },
                  url: this.editedEngine.url,
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intentsAttributes = this.engine.intents;
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "APIAI":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  clientEmail: this.editedEngine.clientEmail,
                  type: this.editedEngine.type,
                  language: this.getLanguageCode(this.newLang),
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  privateKey: this.editedEngine.privateKey,
                  projectId: this.editedEngine.projectId,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "LUIS":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  apiKey: this.editedEngine.apiKey,
                  appId: this.editedEngine.appId,
                  endpointURL: this.editedEngine.endpointURL,
                  disambiguationThreshold: this.editedEngine.disambiguationThreshold || 0,
                  enableDisambiguation: this.editedEngine.enableDisambiguation,
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  language: this.getLanguageCode(this.newLang),
                  staging: this.editedEngine.staging || false,
                  type: this.editedEngine.type,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "WATSON":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  language: this.getLanguageCode(this.newLang),
                  apiKey: this.editedEngine.apiKey,
                  url: this.editedEngine.url,
                  assistantId: this.editedEngine.assistantId,
                  type: this.editedEngine.type,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
        case "LEX":
          obj = {
            engine: {
              entity_types: {
                TEXT: {
                  type: "basic",
                  extraction_pattern: ".+",
                  validation_expression: "'%value%'.length>0",
                },
              },
              engines: {
                [this.editedEngine.engineName]: {
                  accessKey: this.editedEngine.accessKey,
                  botAlias: this.editedEngine.botAlias,
                  botName: this.editedEngine.botName,
                  default: this.editedEngine.default || false,
                  fastRecognition: this.editedEngine.fastRecognition || false,
                  language: this.getLanguageCode(this.newLang),
                  region: this.getRegionCode(this.editedEngine.region),
                  lexApiVersion: this.editedEngine.lexApiVersion,
                  secretKey: this.editedEngine.secretKey,
                  type: this.editedEngine.type,
                  types: {},
                  intents: {},
                },
              },
            },
          };
          if (this.engine.intents) {
            obj.engine.engines[this.editedEngine.engineName].intents = this.engine.intents;
          }
          if (this.engine.types) {
            obj.engine.engines[this.editedEngine.engineName].types = this.engine.types;
          }
          if (this.entity_types) {
            obj.engine.entity_types = this.entity_types;
          }
          break;
      }

      obj.engine.name = this.editedEngine.engineName;
      obj.engine.type = engineType;
      if (this.isEdit) {
        return this.$http.put("/semantic-engine/" + this.editedEngine.engineName, obj.engine);
      } else {
        return this.$http.post("/semantic-engine", obj.engine);
      }
    },
    getLanguageCode(language) {
      return this.languagesAvailable[language];
    },
    getLanguageCodeCogito(language) {
      return this.languagesAvailableCogito[language];
    },
    getRegionCode(region) {
      for (let i in this.$store.getters.getAwsRegionCode) {
        if (region === this.$store.getters.getAwsRegionCode[i].text) {
          return this.$store.getters.getAwsRegionCode[i].value;
        }
      }
      return;
    },
    addCogitoConfigurationCustom(obj) {
      if (!this.editedEngine.data) {
        this.editedEngine.data = {};
      }
      this.editedEngine.data[obj.key] = obj.value;
    },
    deleteCogitoConfigurationCustom(obj) {
      delete this.editedEngine.data[obj];
    },
    changeLanguageCogito(newLangCogito) {
      //* al cambio della lingua,
      //* aggiorno la lingua presente nella tabella dei parameters
      this.editedEngine.data.language = this.getLanguageCodeCogito(newLangCogito);
    },
  },
};
</script>
