<template>
  <v-dialog v-model="showConfirm" max-width="500" persistent>
    <v-card>
      <v-card-title>Logout Agent</v-card-title>

      <v-card-text class="mt-4 font-weight-light">
        {{ message }} with the following policies:
      </v-card-text>
      <v-card-text class="font-weight-light">
        <ul>
          <li>
            Now: the agent will be set to NOT READY status and logged out immediately even with active tasks associated. Active tasks will be moved back to
            original queue
          </li>
          <li>When Idle: the agent will be set to NOT READY status and logged out after completing active tasks within a maximum time of 5 minutes</li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="4" class="px-2">
            <v-btn block color="success" outlined @click="sendConfirmation('now')">
              <v-icon left>
                mdi-check
              </v-icon>Now
            </v-btn>
          </v-col>
          <v-col cols="4" class="px-2">
            <v-btn block color="success" outlined @click="sendConfirmation('asap')">
              <v-icon left>
                mdi-check
              </v-icon>When Idle
            </v-btn>
          </v-col>
          <v-col cols="4" class="px-2">
            <v-btn block color="error" outlined @click="sendDeny">
              <v-icon left>
                mdi-close
              </v-icon>No
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "KickModal",
  props: ["showConfirm", "message"],
  methods: {
    sendConfirmation(type) {
      this.$emit(this.$store.getters.getEvents.DIALOG_CONFIRM, type);
    },
    sendDeny() {
      this.$emit(this.$store.getters.getEvents.DIALOG_DENY);
    },
  },
};
</script>
