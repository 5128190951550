<template>
  <div>
    <v-row :class="getFormRowMargin" align="center">
      <!-- auth token -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          label="Authentication Token"
          :type="showPassword ? 'text' : 'password'"
          v-model.trim="processor.details.authToken"
          :rules="[requiredField]"
          prepend-inner-icon="lock"
          autocomplete="new-password"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="processor.details.authToken.length < 1" tabindex="-1">
              <v-icon tabindex="-1" v-show="!showPassword" @click="showPassword = !showPassword"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showPassword" @click="showPassword = !showPassword"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <!-- API URL -->
      <v-col cols="12" sm="6" :class="getFormColMargin" v-if="isEdit">
        <v-text-field
          readonly
          disabled
          label="Api URL"
          v-model.trim="processor.details.apiURL"
          :rules="[requiredField]"
          prepend-inner-icon="cloud"
          :autocomplete="$store.getters.disableAutocomplete"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row :class="getFormRowMargin" align="center">
      <!-- target language -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-autocomplete
          :items="this.$store.getters.getGoogleSpeechSupportedLanguage"
          v-model="processor.details.languageCode"
          :rules="[requiredField]"
          label="Input Language"
          prepend-inner-icon="mdi-earth"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "GoogleSpeechRecognition",
  props: ["processor", "isEdit"],
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    ...fieldValidators,
  },
  computed: {
    ...spacing,
  },
};
</script>
