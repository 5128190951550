<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent" style="height: 460px !important">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        :hideTableButton="true"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
      />
      <v-row class="mt-6 mb-6 mr-1 ml-1" @mousedown.stop="() => {}">
        <v-col class="pa-0">
          <div class="rounded-wrap center lg">
            <div class="rounded-box grow box-4 ma-0 ml-12 mr-12">
              <div class="rounded-box-content sm">
                <h4 class="text-center pb-1">
                  Speed of answer
                </h4>
                <span class="box-value">{{ secondsToHms(dataset.data.timeToHandle) }}</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-6 mb-6 mr-1 ml-1" @mousedown.stop="() => {}">
        <v-col class="pa-0">
          <div class="rounded-wrap center lg mb-3">
            <div class="rounded-box grow box-5 ma-0 ml-12 mr-12">
              <div class="rounded-box-content sm">
                <h4 class="text-center pb-1">
                  Longest queueing time
                </h4>
                <span class="box-value">{{ secondsToHms(dataset.data.longestQueueing) }}</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-6 mb-6 mr-1 ml-1" @mousedown.stop="() => {}">
        <v-col cols="4" class="pa-0">
          <div class="rounded-wrap square column sm mb-3">
            <h4 class="text-center">
              Resolved
            </h4>
            <div class="rounded-box box-3 ma-0">
              <div class="rounded-box-content sm">
                <span class="box-value">{{ dataset.data.resolved }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="pa-0">
          <div class="rounded-wrap square column sm mb-3">
            <h4 class="text-center">
              Assigned
            </h4>
            <div class="rounded-box box-1 ma-0">
              <div class="rounded-box-content sm">
                <span class="box-value">{{ dataset.data.handling }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="rounded-wrap square column sm mb-3">
            <h4 class="text-center">
              Queued
            </h4>
            <div class="rounded-box box-2 ma-0">
              <div class="rounded-box-content sm">
                <span class="box-value">{{ dataset.data.enqueued }}</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "ConversationTotals",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    },
    "publications": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      info:
        "This widget shows an overview about conversations management, related to the current day.\n" +
        'The "Speed of Answer" KPI refers to the average time waited by a user before receiving the first response from an agent (waiting queue time plus first response time)',
      reloadWidgetDataInterval: null,
      dataset: {
        data: {
          timeToHandle: 0,
          resolved: 0,
          enqueued: 0,
          handling: 0,
        },
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onReloadEvent() {
      this.reloadWidgetData();
    },
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    async loadWidgetData() {
      try {
        this.dataset = await this.$httpAuth.get("/dashboard/wallboard/conversation-totals");
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
