<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <AskSuperAdminPasswordDialog :showConfirm="dialog" @dialogConfirm="save" @dialogDeny="dialog = false" />

    <v-form v-model="isValid">
      <v-card class="mx-9 mt-9" outlined height="45">
        <v-row class="pt-2">
          <v-col cols="2">
            <v-tooltip bottom max-width="250">
              <template #activator="{ on }">
                <p class="caption font-weight-bold ml-3" style="width: 100px" v-on="on">
                  <v-icon small class="mr-2">
                    info
                  </v-icon>Time Frame
                </p>
              </template>
              <span>Conversation count aggregation period</span>
            </v-tooltip>
          </v-col>
          <v-col cols="5">
            <v-tooltip bottom max-width="250">
              <template #activator="{ on }">
                <p class="caption font-weight-bold ml-3" style="width: 100px" v-on="on">
                  <v-icon small class="mr-2">
                    info
                  </v-icon>
                  Limit
                </p>
              </template>
              <span>Number of conversation that can be handled in the specified time frame</span>
            </v-tooltip>
          </v-col>
          <v-col cols="5">
            <v-tooltip bottom max-width="250">
              <template #activator="{ on }">
                <p class="caption font-weight-bold ml-3" style="width: 100px" v-on="on">
                  <v-icon small class="mr-2">
                    info
                  </v-icon>
                  Expiration
                </p>
              </template>
              <span>License expiration date</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-for="(item, index) in data" :key="index" class="mx-9" outlined height="90">
        <v-row class="pt-6" align-content="center">
          <v-col cols="2">
            <div class="pt-1 pl-5">
              {{ item.type }}
            </div>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-if="!limitConversation[item.type]"
                  value="Unlimited"
                  solo
                  flat
                  readonly
                  dense
                />
                <v-text-field
                  v-else
                  v-model.number="item.limit"
                  type="number"
                  :rules="[positiveNumber]"
                  label="License cap"
                  dense
                />
              </v-col>

              <v-switch v-model="limitConversation[item.type]" color="primary" dense label="Limit conversation" @change="item.limit = 0" />
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="5">
                <v-text-field
                  v-if="!expirationData[item.type]"
                  value="Unlimited"
                  solo
                  flat
                  readonly
                  dense
                />

                <v-menu v-else v-model="datePicker[item.type]" :close-on-content-click="false">
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="date[item.type]"
                      label="Request activation on"
                      prepend-icon="event"
                      readonly
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date[item.type]"
                    locale
                    no-title
                    scrollable
                    color="primary"
                    @input="datePicker[item.type] = false"
                  />
                </v-menu>
              </v-col>

              <v-switch v-model="expirationData[item.type]" color="primary" dense label="Expiration limit" />
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mx-9" outlined>
        <v-card-actions>
          <v-tooltip bottom max-width="250">
            <template #activator="{ on }">
              <v-switch
                v-model="blockAtLimit"
                color="primary"
                dense
                label="Block ConvyAI if license limit is reached"
                class="mr-10"
                v-on="on"
              />
            </template>
            <span>If enabled, conversation are blocked when license limit is reached</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>

      <v-row class="mt-4">
        <v-col cols="12">
          <v-btn color="success" class="ml-9" :disabled="!isValid" @click.prevent="dialog = true">
            <v-icon left>
              mdi-floppy
            </v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AskSuperAdminPasswordDialog from "../../components/other/AskSuperAdminPasswordDialog";
import fieldValidators from "../../helpers/fieldValidators";
import moment from "moment";
import EventBus from "../../event-bus";

export default {
  name: "License",
  components: {
    ResultSnackbar,
    AskSuperAdminPasswordDialog,
  },
  data() {
    return {
      company: this.$store.getters.getSelectedCompany,
      isValid: false,
      dialog: false,
      data: [],
      limitConversation: {
        //stato switch conversation
        Daily: false,
        Weekly: false,
        Monthly: false,
        Yearly: false,
      },

      expirationData: {
        //stato switch expiration
        Daily: false,
        Weekly: false,
        Monthly: false,
        Yearly: false,
      },
      datePicker: {
        Daily: false,
        Weekly: false,
        Monthly: false,
        Yearly: false,
      },
      date: {
        Daily: new Date().toISOString().substr(0, 10),
        Weekly: new Date().toISOString().substr(0, 10),
        Monthly: new Date().toISOString().substr(0, 10),
        Yearly: new Date().toISOString().substr(0, 10),
      },
      blockAtLimit: false,
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  computed: {
    ...spacing,
  },
  async mounted() {
    try {
      const response = await this.$http.get("/license");
      for (let i in response.data.license) {
        if (response.data.license[i].type) {
          this.data.push(response.data.license[i]);
          if (response.data.license[i].limit != "Unlimited" && response.data.license[i].limit) {
            this.limitConversation[i] = true;
          }
          if (response.data.license[i].expiration != "Unlimited" && response.data.license[i].expiration) {
            this.expirationData[i] = true;
            let offset = moment().utcOffset();
            this.date[i] = moment.utc(new Date(response.data.license[i].expiration).toUTCString()).utcOffset(offset).format("YYYY-MM-DD");
          }
        }
      }
      if (response.data.enforceLimit) {
        this.blockAtLimit = response.data.enforceLimit;
      }
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    async save(key) {
      let obj = {
        secret: btoa(key),
        enforceLimit: this.blockAtLimit,
        license: {},
      };
      for (let i in this.data) {
        //creazione oggetto
        obj.license[this.data[i].type] = {};
        obj.license[this.data[i].type]["type"] = this.data[i].type; //type

        if (this.limitConversation[this.data[i].type]) {
          //limit
          obj.license[this.data[i].type]["limit"] = this.data[i].limit;
        } else {
          obj.license[this.data[i].type]["limit"] = "Unlimited";
        }

        if (this.expirationData[this.data[i].type]) {
          //expiration
          let offset = moment().utcOffset();
          let date = moment.utc(this.date[this.data[i].type]).utcOffset(offset).format("DD-MM-YYYY");
          date = date.split("-");
          date[1] = this.monthNumToName(date[1]);
          date = date.join(" ");
          obj.license[this.data[i].type]["expiration"] = date;
        } else {
          obj.license[this.data[i].type]["expiration"] = "Unlimited";
        }
      }
      try {
        await this.$http.post("/license", obj);
        this.$refs.resultSnackbar.showSuccess("License saved!");
        this.dialog = false;
      } catch (err) {
        this.$refs.resultSnackbar.showError("Error saving license: " + err.response.data.error);
        this.dialog = false;
      }
    },
    monthNumToName(monthNum) {
      return this.months[monthNum - 1] || "";
    },
  },
};
</script>
<style>
.v-date-picker-header.theme--dark > button {
  background-color: #263238 !important;
}
.v-date-picker-header.theme--light > button {
  background-color: #ffffff !important;
}
</style>
