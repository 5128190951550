<template>
  <v-dialog v-model="reskillDialog" persistent max-width="550">
    <v-card>
      <v-card-title :class="$vuetify.theme.dark ? 'blue-grey darken-3' : 'grey lighten-3'">
        <v-avatar size="25" class="mr-4">
          <v-icon>mdi-face-agent</v-icon>
        </v-avatar>
        {{ headerMessage }}
      </v-card-title>

      <v-card-text>
        <v-row align="center" justify="start" class="px-2 py-4" no-gutters>
          <v-col cols="12" class="pb-4">
            <label style="width: 100%; display: block">{{ agentsCount == 1 ? "Agent " : " Agents " }} selected:</label>
            <span v-for="(agent, index) in agents" :key="agent._id" class="mx-1">
              <v-tooltip v-if="index < 9" color="primary" top>
                <template #activator="{ on }">
                  <v-chip label class="mt-1" v-on="on">
                    {{ agents[index].authentication.credentials.username }}
                  </v-chip>
                </template>
                <span>{{ getPermission(agents[index].roles) }} : {{ getProfile(agents[index].roles) }}</span>
              </v-tooltip>
              <v-chip v-if="index == 9" small label class="mt-1">
                + <span class="mx-1">{{ agentsCount - 9 }}</span> {{ agentsCount - 9 == 1 ? " agent" : " agents" }}
              </v-chip>
            </span>
          </v-col>
          <v-col v-if="actionSelected == 'Change profile'" cols="12" class="pb-2">
            <label>Choose a new profile and confirm to reassign the <b class="mx-1">{{ agentsCount }}</b> {{ agentsCount == 1 ? " agent" : " agents" }} you
              selected:</label>

            <v-autocomplete
              v-model="reskillPermission"
              :items="profileListItems"
              label="Profile"
              prepend-inner-icon="mdi-drama-masks"
              :rules="[requiredField]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="actionSelected == 'Force Logout'">
        <div>Agents will be logged out with the following policies:</div>
        <ul>
          <li>
            Now: the agent will be set to NOT READY status and logged out immediately even with active tasks associated. Active tasks will be moved back to
            original queue
          </li>
          <li>When Idle: the agent will be set to NOT READY status and logged out after completing active tasks within a maximum time of 5 minutes</li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col :cols="actionSelected != 'Force Logout' ? '6' : '4'" class="px-2">
            <v-btn
              class="mb-4"
              color="success"
              :disabled="actionSelected == 'Change profile' && reskillPermission == ''"
              :loading="loadingBtn"
              block
              outlined
              @click="changeProfile('now')"
            >
              {{ btnSubmit }}
            </v-btn>
          </v-col>
          <v-col v-if="actionSelected == 'Force Logout'" cols="4" class="px-2">
            <v-btn
              class="mb-4"
              color="success"
              :disabled="actionSelected == 'Change profile' && reskillPermission == ''"
              :loading="loadingBtn"
              block
              outlined
              @click="changeProfile('asap')"
            >
              {{ btnSubmit2 }}
            </v-btn>
          </v-col>
          <v-col :cols="actionSelected != 'Force Logout' ? '6' : '4'" class="px-2">
            <v-btn
              class="mb-4"
              color="error"
              :loading="loadingBtn"
              block
              outlined
              @click="$emit('closeRiassignDialog', { show: false, result: '' })"
            >
              close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import fieldValidators from "../../helpers/fieldValidators";
export default {
  name: "ReskillAgents",
  props: ["actionSelected", "agents", "reskillDialog", "profiles", "permissions"],
  data() {
    return {
      reskillPermission: "",
      loadingBtn: false,
      headerMessage: "",
      btnSubmit: "",
      btnSubmit2: "",
      path: "",
    };
  },
  computed: {
    agentsCount() {
      let count = this.agents.filter((agent) => agent.selected).length;
      return count;
    },
    profileListItems() {
      let result = [];
      result = this.profiles.map((profile) => {
        return { text: profile.name, value: profile.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    agentsSelected() {
      return this.agents.filter((agent) => agent.selected);
    },
    getHiddenAgents() {
      let list = [];
      for (let index = 5; index < this.agents.length; index++) {
        let agent = this.agents[index];
        if (agent.selected) {
          let string = agent.authentication.credentials.username + " - " + this.getPermission(agent.roles) + " : " + this.getProfile(agent.roles);
          list.push(string);
        }
      }
      return list;
    },
  },
  mounted() {
    this.setData();
  },
  methods: {
    ...fieldValidators,
    setData() {
      switch (this.actionSelected) {
        case "Change profile":
          this.headerMessage = "Reassign agents to a new profile";
          this.btnSubmit = "change profile";
          this.path = "/roles/eudata:convyai/resources/eudata:convyai:" + this.$store.getters.getSelectedCompany + "/profile";
          break;
        case "Enable":
          this.headerMessage = "Enable agents";
          this.btnSubmit = "enable";
          this.path = "/disabled";
          this.reskillPermission = false;
          break;
        case "Disable":
          this.headerMessage = "Disable agents";
          this.btnSubmit = "disable";
          this.path = "/disabled";
          this.reskillPermission = true;
          break;
        case "Force Logout":
          this.headerMessage = "Force Logout";
          this.btnSubmit = "Now";
          this.btnSubmit2 = "When Idle";
          this.path = "/agent/ws-utility/kickAgent";
          break;
      }
    },
    getProfile(role) {
      let agentProfile = role?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.profile || "";
      let result = this.profiles.find((el) => el.uuid === agentProfile.split(":")[4]);
      return result?.name ? result.name : "";
    },
    getRolesInfo(type) {
      switch (type) {
        case "role":
          return this.$store.getters.getProducts.CONVYAI;
        case "resource":
          return `${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`;
      }
    },
    getPermission(role) {
      let agentPerm = role?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.permission_set || "";
      let result = this.permissions.find((el) => el.uuid === agentPerm.split(":")[4]);
      return result?.name ? result.name : "";
    },
    async changeProfile(type) {
      this.loadingBtn = true;
      if (this.actionSelected == "Force Logout") {
        try {
          this.agents.forEach((agent) => {
            if (agent?.status?.status) {
              this.$httpAuth.post("/agent/ws-utility/kickAgent", {
                agentERI: agent.eri,
                tenantERI: this.$store.getters.getSelectedCompany,
                type,
              });
            }
          });
          this.$emit("forceLogoutSuccess");
        } catch (e) {
          this.$emit("closeRiassignDialog", { error: "error", data: e.response?.data?.data ? e.response.data.data : null });
        }
      } else {
        let agentsToChange = this.agents.filter((agent) => agent.selected).map((agent) => agent._id);
        let updates = {
          actions: [
            {
              op: "replace",
              path: this.path,
              value: this.reskillPermission,
            },
          ],
          accounts: agentsToChange,
        };
        try {
          let result = await this.$http.patch(`/human-agents/agent/agents-update`, updates);
          this.$emit("closeRiassignDialog", result.data);
        } catch (e) {
          this.$emit("closeRiassignDialog", { error: "error", data: e.response?.data?.data ? e.response.data.data : null });
        }
      }
      this.loadingBtn = false;
    },
  },
};
</script>
