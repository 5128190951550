<template>
  <div id="chatDebuggerContainer" class="debugger-minimized">
    <ConfirmDialog
      :showConfirm="showConfirm"
      message="There is another script saved with this name. This operation will override the old one"
      @dialogConfirm="sendSaveScriptRequest"
      @dialogDeny="denySaveScriptRequest"
    />
    <ResultSnackbar ref="resultSnackbar" />

    <div id="unyco_containernlknmtg1ja" />
    <div id="convyDebuggerContainer" class="debugger-minimized">
      <!-- i tabs hanno light e dark invertiti cosi risaltano, altrimenti non si vedono -->
      <v-tabs
        v-model="tabSelected"
        icons-and-text
        vertical
        height="469"
        hide-slider
        slider-size="0"
        :show-arrows="false"
        background-color="primary"
        class="elevation-24"
      >
        <!-- tab di apertura debug e suo tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="openDebugPanel">
              <v-icon>mdi-android-debug-bridge</v-icon>
            </v-tab>
          </template>
          <span>Open debug panel</span>
        </v-tooltip>
        <!-- tab di apertura log e suo tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="openLogPanel">
              <v-icon>mdi-text-box-search-outline</v-icon>
            </v-tab>
          </template>
          <span>Open log panel</span>
        </v-tooltip>
        <!-- tab di copia dialog id e suo tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="copyDialog()">
              <v-text-field id="tocopy" v-model="dialogId" class="tocopy-class" />
              <v-icon class="pl-n2">
                mdi-content-copy
              </v-icon>
            </v-tab>
          </template>
          <span>Copy DialogId</span>
        </v-tooltip>
        <!-- tab di cattura audio e suo tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="captureAudioMessage">
              <v-icon>mdi-microphone-outline</v-icon>
              <audio ref="audiostream" />
            </v-tab>
          </template>
          <span>Send voice message</span>
        </v-tooltip>

        <!-- tab di riavvio sessione e suo tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="restartDebugSession">
              <v-icon>mdi-restart</v-icon>
            </v-tab>
          </template>
          <span>Restart debug session</span>
        </v-tooltip>

        <!-- tab di chiusura pannello e suo tooltip -->
        <v-tooltip v-if="showCloseButton" bottom>
          <template #activator="{ on }">
            <v-tab class="px-0" v-on="on" @click.prevent="closeDebugPanel">
              <v-icon>mdi-chevron-right</v-icon>
            </v-tab>
          </template>
          <span>Close panel</span>
        </v-tooltip>

        <v-tab-item :key="1">
          <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" class="fullContainer debuggerScrollbar">
            <div class="mx-2 mt-2">
              <v-expansion-panels v-model="expansionPanelModel">
                <!-- current node -->
                <v-expansion-panel>
                  <v-expansion-panel-header>Current Node</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      class="mx-auto"
                      max-width="400"
                      :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : 'background-color:#f2f2f2!important'"
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1">
                            {{ currentNodeParams.flow }}
                          </v-list-item-title>
                          <v-list-item-content>
                            <div>
                              <v-btn color="primary" @click.prevent="openFlowDesigner">
                                Go To<v-icon right>
                                  mdi-chevron-right
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-list-item-content>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <div class="overline">
                            Version:
                            <strong v-html="currentNodeParams.version" />
                          </div>
                          <div class="overline">
                            Hook:
                            <strong v-html="currentNodeParams.hook" />
                          </div>
                          <div class="overline">
                            UUID:
                            <strong v-html="currentNodeParams.uuid" />
                          </div>
                          <div class="overline">
                            Node:
                            <strong v-html="currentNodeParams.nodeTpye" />
                          </div>
                        </v-list-item-action-text>
                      </v-list-item>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- variables -->
                <v-expansion-panel>
                  <v-expansion-panel-header>Variables</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      must-sort
                      dense
                      class="variableTable"
                      :items="variables"
                      :headers="variableHeaders"
                      :items-per-page="variables.length"
                      hide-default-header
                      hide-default-footer
                    >
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(item, index) in items" :key="item.key">
                            <td>
                              <div class="text-truncate" :title="item.key">
                                {{ item.key }}
                              </div>
                            </td>
                            <td>
                              <div class="text-truncate" :title="item.value" @click.prevent="openEditDialog(index, item, 'other')">
                                {{ item.value }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- system variables -->
                <v-expansion-panel>
                  <v-expansion-panel-header>System Variables</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      must-sort
                      dense
                      class="variableTable"
                      :items="systemVariables"
                      :items-per-page="systemVariables.length"
                      :headers="variableHeaders"
                      hide-default-header
                      hide-default-footer
                    >
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(item, index) in items" :key="item.key">
                            <td>
                              <div class="text-truncate" :title="item.key">
                                {{ item.key }}
                              </div>
                            </td>
                            <td>
                              <div class="text-truncate openDialog" :title="item.value" @click.prevent="openEditDialog(index, item, 'system')">
                                {{ item.value }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- watchbot -->
                <v-expansion-panel v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR)">
                  <v-expansion-panel-header>Watchbot</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form ref="scriptForm">
                      <v-text-field
                        v-model.trim="scriptName"
                        label="Script Name"
                        :rules="[validateWatchbotScriptName]"
                        prepend-inner-icon="mdi-card-bulleted"
                        :autocomplete="$store.getters.disableAutocompleteOff"
                        @keypress.enter.stop="saveScript"
                      />
                      <v-btn
                        color="success"
                        :disabled="scriptName.length == 0"
                        :loading="scriptSaveInProgress"
                        @click.prevent="saveScript"
                      >
                        <v-icon left>
                          mdi-floppy
                        </v-icon>Save
                      </v-btn>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- events -->
                <v-expansion-panel>
                  <v-expansion-panel-header>Events</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form v-model="sendEventForm">
                      <v-text-field
                        v-model.trim="sendEventParams.name"
                        label="Event Name"
                        :rules="[requiredField]"
                        :autocomplete="$store.getters.disableAutocompleteOff"
                      />
                      <v-row>
                        <template v-for="(param, index) in sendEventParams.params">
                          <v-col :key="index + '_key'" cols="6" class="py-0">
                            <v-text-field v-model.trim="param.key" label="Parameter key" :autocomplete="$store.getters.disableAutocompleteOff" />
                          </v-col>
                          <v-col :key="index + '_value'" cols="6" class="py-0">
                            <v-text-field
                              v-model.trim="param.value"
                              label="Parameter value"
                              :autocomplete="$store.getters.disableAutocompleteOff"
                            />
                          </v-col>
                        </template>
                        <v-col cols="12" class="py-0">
                          <v-btn
                            color="primary"
                            :loading="sendingEvent"
                            :disabled="isSendButtonDisabled()"
                            @click.prevent="sendEvent"
                          >
                            Send<v-icon right>
                              mdi-chevron-right
                            </v-icon>
                          </v-btn>
                          <v-btn
                            class="float-right mt-n5"
                            fab
                            x-small
                            color="primary"
                            @click.prevent="sendEventParams.params.splice(sendEventParams.params.length - 1, 1)"
                          >
                            <v-icon class="buttonText--text">
                              mdi-minus
                            </v-icon>
                          </v-btn>
                          <v-btn
                            class="float-right mt-n5 mr-4"
                            fab
                            x-small
                            color="primary"
                            @click.prevent="
                              sendEventParams.params.push({
                                key: '',
                                value: '',
                              })
                            "
                          >
                            <v-icon class="buttonText--text">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </perfect-scrollbar>
        </v-tab-item>
        <v-tab-item :key="2">
          <perfect-scrollbar class="debuggerScrollbar" :options="$store.getters.getDefaultScrollProps">
            <div class="logsContainer" style="word-break: break-all" :class="isDarkMode ? 'white--text' : 'black--text'" v-html="logs" />
          </perfect-scrollbar>
          <div class="logsButtons pl-12">
            <v-btn class="ml-12 mt-2" color="primary" @click.prevent="downloadLogs('refreshButton')">
              <v-icon left>
                mdi-refresh
              </v-icon>Refresh
            </v-btn>
            <v-btn
              v-clipboard="() => logs.replace(/<br\/>/gi, '\n')"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              color="primary"
              class="ml-12 mt-2"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>Copy
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="fullContainer" />
        </v-tab-item>
        <v-tab-item :key="4">
          <div class="mx-2 mt-2">
            <template>
              <perfect-scrollbar ref="stepperSrollbar" :options="$store.getters.getDefaultScrollProps" class="fullContainer debuggerScrollbar">
                <v-stepper v-model="recStep" class="mx-2 mt-2" vertical>
                  <v-stepper-step :complete="recStep > 1" step="1">
                    Start record voice message
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-card :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : 'background-color:#f2f2f2!important'" class="mb-3">
                      <v-card-title class="headline">
                        Let's start!
                      </v-card-title>
                      <v-card-subtitle>When you are ready press the play button and start to record your voice!</v-card-subtitle>
                    </v-card>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          class="start-record-button-step mx-2"
                          fab
                          small
                          color="success"
                          v-on="on"
                          @click.prevent="clickFirstStep"
                        >
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </template>
                      <span>Start recording</span>
                    </v-tooltip>
                  </v-stepper-content>

                  <v-stepper-step :complete="recStep > 2" step="2">
                    Stop record voice message
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-card :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : 'background-color:#f2f2f2!important'" class="mb-3">
                      <v-card-title class="headline">
                        Recording is started..
                      </v-card-title>
                      <v-card-subtitle>When you want press the stop button to stop recording your voice!</v-card-subtitle>
                      <v-card-subtitle>
                        Duration: <b>{{ formatRecordingDuration(recordingDuration) }}</b>
                      </v-card-subtitle>
                    </v-card>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          class="stop-record-button-step mx-2"
                          fab
                          small
                          color="error"
                          v-on="on"
                          @click.prevent="clickSecondStep"
                        >
                          <v-icon>mdi-stop</v-icon>
                        </v-btn>
                      </template>
                      <span>Stop recording</span>
                    </v-tooltip>
                  </v-stepper-content>

                  <v-stepper-step :complete="recStep > 3" step="3">
                    Send voice message
                  </v-stepper-step>

                  <v-stepper-content step="3">
                    <v-card :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : 'background-color:#f2f2f2!important'" class="mb-1">
                      <v-card-title class="headline">
                        Listen...
                      </v-card-title>
                      <v-card-subtitle>Listen your voice message before send it</v-card-subtitle>
                      <v-card-subtitle>
                        <vuetify-audio :file="recordingContent" title="recording.wav" />
                      </v-card-subtitle>
                    </v-card>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          class="delete-record-button-step mx-2"
                          fab
                          small
                          color="error"
                          v-on="on"
                          @click.prevent="discardThirdStep"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Discard recording</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          class="send-record-button-step mx-2"
                          fab
                          small
                          color="primary"
                          v-on="on"
                          @click.prevent="sendThirdStep"
                        >
                          <v-icon>mdi-send</v-icon>
                        </v-btn>
                      </template>
                      <span>Send recording</span>
                    </v-tooltip>
                  </v-stepper-content>

                  <v-stepper-step step="4">
                    Complete
                  </v-stepper-step>

                  <v-stepper-content step="4">
                    <v-card :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : 'background-color:#f2f2f2!important'">
                      <v-card-title class="headline">
                        {{ completeMessageTitle }}
                      </v-card-title>
                      <v-card-subtitle>If you want to send a new voice message please press the restart button</v-card-subtitle>
                    </v-card>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          class="restart-record-button-step mx-2"
                          fab
                          small
                          color="primary"
                          v-on="on"
                          @click.prevent="recStep = 1"
                        >
                          <v-icon>mdi-restart</v-icon>
                        </v-btn>
                      </template>
                      <span>Restart recording</span>
                    </v-tooltip>
                  </v-stepper-content>
                </v-stepper>
              </perfect-scrollbar>
            </template>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>

    <!-- edit entity dialog -->
    <v-dialog v-model="editDialog" persistent width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Variable Value</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model.trim="editedItemValue" label="New Value" :rules="[requiredField]" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click.prevent="updateVariable()">
            Save
          </v-btn>
          <v-btn color="error" @click.prevent="editDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import openFD from "../../helpers/openFD";
import EventBus from "../../event-bus";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import Recorder from "recorder-js";
import VuetifyAudio from "./VuetifyAudio";

export default {
  name: "DebuggerContainer",
  components: {
    VuetifyAudio,
    ConfirmDialog,
    ResultSnackbar,
  },
  data() {
    return {
      expansionPanelModel: 0,
      tabSelected: 0,
      debuggerContainer: null,
      convyDebuggerContainer: null,
      inclusionContainer: null,
      flowDesignerIframe: null,
      publicURL: this.$store.getters.getProperty("publicUrl"),
      language: this.$store.getters.getUserLanguage ? this.$store.getters.getUserLanguage.toLowerCase() : this.$store.getters.getUserLanguage,
      parsedPublicURL: this.parsePublicUrl(),
      baseUrl: this.getBaseURL(),
      logs: "",
      dialogId: null,
      sendEventForm: false,
      sendingEvent: false,
      sendEventParams: {
        name: "",
        params: [{ key: "", value: "" }],
      },
      variableHeaders: [
        { text: "Key", value: "key" },
        { text: "Value", value: "value" },
      ],
      variables: [],
      systemVariables: [],
      excludedInitialVariables: ["token", "flow", "version", "debug", "autostartsession"],
      discardedVars: ["autostartsession", "debug", "token", "relativePath"],
      systemVars: [
        "autostartsession",
        "debug",
        "domain",
        "page",
        "system_channel",
        "system_source",
        "system_user_name",
        "system_user_id",
        "system_user_token",
        "system_contact",
        "system_back_counter",
        "system_back_consecutive_counter",
        "system_user_interaction_counter",
        "userCoords",
        "flow",
        "version",
        "lastIntentValueDetected",
        "lastIntentLabelDetected",
        "lastIntentScoreDetected",
        "lastIntentSentimentDetected",
      ],
      editDialog: false,
      editedItemIndex: -1,
      editedItemValue: "",
      editedItemType: "",
      currentNodeParams: {
        flow: "",
        version: "",
        nodeTpye: "",
        hook: "",
        uuid: "",
      },
      showCloseButton: false,
      scriptName: "",
      initialVariables: {},
      selecteFlowObj: {},
      executedNodes: [],
      showConfirm: false,
      scriptSaveInProgress: false,
      constraints: {
        audio: true,
        video: false,
      },
      chunks: [],
      mediaRecorder: null,
      isRegistering: false,
      grantAudioPermission: true,
      audioStream: null,
      audioContext: null,
      rec: null,
      captureDevice: false,
      recStep: 1,
      recordingDuration: 0,
      updateRecordingDuration: null,
      recordingContent: "",
      completeMessageTitle: "",
      openDebuggerTab: true,
      openLogTab: false,
      openVoiceTab: false,
    };
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.debuggerContainer = document.getElementById("chatDebuggerContainer");
    this.convyDebuggerContainer = document.getElementById("convyDebuggerContainer");
    this.inclusionContainer = document.getElementById("unyco_containernlknmtg1ja");
    this.flowDesignerIframe = document.getElementById("#flowDesignerFrame");
    EventBus.$on(this.$store.getters.getEvents.OPEN_DEBUG_INTERFACE, this.openDebugger);
    let savedSession = this.$store.getters.getSetting("testSession");
    if (savedSession) {
      this.openDebugger(savedSession);
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.OPEN_DEBUG_INTERFACE, this.openDebugger);
  },
  methods: {
    ...checkRoleAndPermission,
    ...fieldValidators,
    copyDialog() {
      try {
        let input = document.getElementById("tocopy");
        input.select();
        document.execCommand("copy");
        this.$refs.resultSnackbar.showSuccess("Dialog ID copied!");
      } catch (err) {
        this.$refs.resultSnackbar.showError("Can not copy dialog id into clipboard!");
      }
      this.checkTabsSelected(false);
    },
    checkTabsSelected(forceFirstTab) {
      setTimeout(
        function () {
          if (forceFirstTab) {
            this.tabSelected = 0;
          } else {
            if (this.openDebuggerTab) {
              this.tabSelected = 0;
            }
            if (this.openLogTab) {
              this.tabSelected = 1;
            }
            if (this.openVoiceTab) {
              this.tabSelected = 3;
            }
          }
        }.bind(this),
        10,
      );
    },
    async saveScript() {
      if (this.scriptName && this.scriptName.length > 0) {
        this.scriptSaveInProgress = true;
        try {
          const response = await this.$http.get("/watchbot");
          if (response.data.scripts && response.data.scripts[this.scriptName]) {
            this.showConfirm = true;
          } else {
            this.sendSaveScriptRequest();
          }
        } catch (e) {
          this.$refs.resultSnackbar.showError("The script can not be saved!");
          this.scriptSaveInProgress = false;
          this.scriptName = "";
        }
      }
    },
    async sendSaveScriptRequest() {
      this.showConfirm = false;
      let script = {
        name: this.scriptName,
        bot_id: this.selecteFlowObj.bot_id,
        owner: this.$store.getters.getUsername,
        flow: this.selecteFlowObj.flow,
        version: this.selecteFlowObj.version,
        initialVariables: this.initialVariables,
        executedNodes: this.executedNodes,
      };
      try {
        await this.$http.post("/watchbot", script);
        this.$refs.resultSnackbar.showSuccess("The script has been saved!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("The script can not be saved!");
      } finally {
        this.scriptSaveInProgress = false;
        this.scriptName = "";
      }
    },
    denySaveScriptRequest() {
      this.showConfirm = false;
      this.scriptSaveInProgress = false;
    },
    openDebugger(flowObj) {
      this.clearCaptureDeviceVariables();
      this.logs = "";
      this.selecteFlowObj = flowObj;
      this.initialVariables = {};
      this.executedNodes = [];
      let js = document.createElement("script");
      js.type = "text/javascript";
      js.onload = () => {
        // eslint-disable-next-line no-undef
        euBridgeWrapperContainer.registerCallbacks(this.convyAdminCallbacks());
      };
      const token = this.$store.state.company.settings.legacyTokens[4];
      js.src =
        this.baseUrl +
        "static/include/interfaces/CustomerGuiAdvanced/js/includeUnycoPopover.js?type=convy&lang=" +
        flowObj.lang +
        "&rnd=nlknmtg1ja&bot=" +
        flowObj.flow +
        "&version=" +
        flowObj.version +
        "&token=" +
        token +
        "&debug=true&sourceConvyApp=ConvyAIAdmin&autostartsession=true" +
        (this.parsedPublicURL.proxy ? "&relativePath=" + this.parsedPublicURL.proxy : "");
      this.inclusionContainer.appendChild(js);
      this.$store.dispatch("AddSetting", {
        testSession: flowObj,
      });
    },
    getBaseURL() {
      let url = this.$store.getters.getProperty("publicUrl") || "";
      if (!url.endsWith("/")) url += "/";
      return url;
    },
    parsePublicUrl() {
      let path = this.$store.getters.getProperty("publicUrl") || window.location.origin;
      let tempPath = path.replace("http://", "").replace("https://", "");
      let proxy = tempPath.indexOf("/") > -1 ? tempPath.substring(tempPath.indexOf("/")) : "";
      path = path.endsWith("/") ? path.substring(0, path.length - 1) : path;
      proxy = proxy.endsWith("/") ? proxy.substring(0, proxy.length - 1) : proxy;
      return {
        path: path,
        proxy: proxy,
      };
    },
    convyAdminCallbacks() {
      return {
        onCloseSession: (e) => {
          if (!e.data.isRefresh) {
            this.$store.dispatch("DeleteSetting", "testSession");
            setTimeout(() => {
              this.debuggerContainer.classList.remove("debugger-active");
              this.debuggerContainer.classList.add("debugger-minimized");
              this.inclusionContainer.innerHTML = "";
              this.convyAdminCallbacks().onMinimized();
              //fix per integrazione con flowdesigner che deve risistemare la toolbar che si spacca su Edge
              //dopo che viene svuotato il contenuto del div contenente l'interfaccia
              if (this.flowDesignerIframe) {
                this.flowDesignerIframe[0].contentWindow.postMessage(
                  {
                    event: "ON_DEBUG_CLOSE_SESSION",
                    params: null,
                  },
                  "*",
                );
              }
            }, 400);
          }
        },
        onMinimized: () => {
          this.debuggerContainer.classList.remove("debugger-active");
          this.debuggerContainer.classList.add("debugger-minimized");
          this.convyDebuggerContainer.classList.remove("debugger-active");
          this.convyDebuggerContainer.classList.add("debugger-minimized");
        },
        onExpanded: () => {
          this.debuggerContainer.classList.add("debugger-active");
          this.debuggerContainer.classList.remove("debugger-minimized");
          this.convyDebuggerContainer.classList.add("debugger-active");
          this.convyDebuggerContainer.classList.remove("debugger-minimized");
        },
        onDebugInfo: (e) => {
          //FIX per le tab verticali. c'è una qualche incompatibilità con vuetify e i nostri stili
          let element = document.getElementsByClassName("v-slide-group__prev");
          if (element && element.length > 0) {
            element[0].click();
            element[0].click();
          }
          ////////////
          this.dialogId = e.dialogId;
          if (e.entities && Object.keys(e.entities).length > 0) {
            this.systemVariables = [];
            this.variables = [];
          }
          if (Object.keys(this.initialVariables).length === 0) {
            for (let entity in e.entities) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.entities.hasOwnProperty(entity)) {
                this.initialVariables[entity] = e.entities[entity];
              }
            }
          }
          if (e.entities) {
            for (let entity in e.entities) {
              // eslint-disable-next-line no-prototype-builtins
              if (e.entities.hasOwnProperty(entity)) {
                if (!this.isDiscardedVariable(entity)) {
                  if (this.isSystemVariable(entity)) {
                    this.systemVariables.push({
                      key: entity,
                      value: e.entities[entity],
                    });
                  } else {
                    this.variables.push({
                      key: entity,
                      value: e.entities[entity],
                    });
                  }
                }
              }
            }
          }
          if (e.node) {
            let desc = JSON.parse(e.node.description);
            this.currentNodeParams.flow = e.node.currentFlow;
            this.currentNodeParams.version = e.node.currentVersion;
            this.currentNodeParams.nodeTpye = desc.type;
            this.currentNodeParams.hook = desc.storyBoardHook || "No Hook";
            this.currentNodeParams.uuid = desc.uuid;
          }

          if (e.response && e.response.length > 0) {
            //Risposte di convy
            for (let i = 0; i < e.response.length; i++) {
              e.response[i].currentFlow = this.currentNodeParams.flow;
              e.response[i].currentVersion = this.currentNodeParams.version;
            }
            this.executedNodes = this.executedNodes.concat(e.response);
          } else if (e.type) {
            e.currentFlow = this.currentNodeParams.flow;
            e.currentVersion = this.currentNodeParams.version;
            this.executedNodes = this.executedNodes.concat([e]);
          }
        },
      };
    },
    captureAudioMessage() {
      this.convyDebuggerContainer.classList.add("debugger-opened");
      this.openVoiceTab = true;
      this.openDebuggerTab = false;
      this.openLogTab = false;
    },
    clickFirstStep() {
      if (!this.captureDevice) {
        this.captureDevice = true;
        this.handleRecording();
      }
    },
    clickSecondStep() {
      this.stopRecording();
    },
    discardThirdStep() {
      this.clearCaptureDeviceVariables();
      this.recStep = 1;
      setTimeout(() => {
        this.$refs.stepperSrollbar.$el.scrollTop = 0;
        this.$refs.stepperSrollbar.update();
      }, 200);
    },
    sendThirdStep() {
      //il risultato è l'src in base 64, ma affinchè sia un base64 devo fare la replace
      const base64Data = this.recordingContent.replace("data:audio/wav;base64,", "");
      this.sendAudioToConvy(base64Data);
    },
    handleRecording() {
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.getUserMediaDone.bind(this)).catch(this.getUserMediaFail.bind(this));
    },
    stopRecording() {
      this.rec.stop().then(({ blob }) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          this.recordingContent = reader.result;
          this.recStep = 3;
        }.bind(this);
      });
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      this.clearCaptureDeviceVariables();
    },
    async sendAudioToConvy(content) {
      try {
        const data = {
          dialogId: this.dialogId,
          attachments: {
            file_name: "voice-message.wav",
            file_content: content,
            type: "Voice",
            audioChannelCount: 2,
            sampleRateHertz: 48000,
            encoding: "LINEAR16",
            mimetype: "audio/wav", //fondamentale, perchè da qui ricostruisco il base64 lato convyadmin per il player audio nella history
          },
        };
        await this.$httpNoAuth.post("/api/v2/sendMessage", data);
        window.postMessage(
          {
            command: "SEND_VOICE_MESSAGE",
            action: "voice_message",
            status: true,
          },
          "*",
        );
        this.clearCaptureDeviceVariables();
        setTimeout(() => {
          this.$refs.stepperSrollbar.$el.scrollTop = 0;
          this.$refs.stepperSrollbar.update();
        }, 200);
        this.completeMessageTitle = "Voice message successfully sent!";
        this.recStep = 4;
      } catch (e) {
        window.postMessage(
          {
            command: "SEND_VOICE_MESSAGE",
            action: "voice_message_fail",
            status: false,
          },
          "*",
        );
        this.clearCaptureDeviceVariables();
        setTimeout(() => {
          this.$refs.stepperSrollbar.$el.scrollTop = 0;
          this.$refs.stepperSrollbar.update();
        }, 200);
        this.completeMessageTitle = "Fail to send voice message!";
        this.recStep = 4;
      }
    },
    getUserMediaDone(stream) {
      this.grantAudioPermission = true;
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioStream = stream;
      this.rec = new Recorder(this.audioContext, {
        numChannels: 1,
      });
      this.rec.init(this.audioStream);
      this.rec.start().then(() => {
        this.captureDevice = false;
        this.isRegistering = true;
        this.recStep = 2;
        this.updateRecordingDuration = setInterval(() => {
          this.recordingDuration++;
        }, 1000);
      });
    },
    formatRecordingDuration(duration) {
      let d = Number(duration);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      let mDisplay = m > 0 ? (m < 10 ? "0" + m : m) : "00";
      let sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
      return mDisplay + ":" + sDisplay;
    },
    getUserMediaFail() {
      this.grantAudioPermission = false;
      this.$refs.resultSnackbar.showError("Check if the microphone is enabled and you allow browser to access it!", 5000);
      this.clearCaptureDeviceVariables();
      this.recStep = 4;
      this.completeMessageTitle = "Fail to capture device!";
    },
    clearCaptureDeviceVariables() {
      this.audioStream = null;
      this.mediaRecorder = null;
      this.chunks = [];
      this.rec = null;
      this.audioContext = null;
      this.isRegistering = false;
      this.captureDevice = false;
      this.recStep = 1;
      this.recordingDuration = 0;
      this.recordingContent = null;
      this.completeMessageTitle = "";
      if (this.updateRecordingDuration) {
        clearInterval(this.updateRecordingDuration);
        this.updateRecordingDuration = null;
      }
    },
    restartDebugSession() {
      let testSession = this.$store.getters.getSetting("testSession");
      // eslint-disable-next-line no-undef
      if (euBridgeWrapperContainer) {
        // eslint-disable-next-line no-undef
        euBridgeWrapperContainer.sendCommandToGui(
          // eslint-disable-next-line no-undef
          euBridgeWrapperContainer.commands.CLOSESESSION,
        );
      }
      setTimeout(() => {
        this.inclusionContainer.innerHTML = "";
        this.convyAdminCallbacks().onMinimized();
      }, 500);
      setTimeout(() => {
        if (testSession) {
          this.openDebugger(testSession);
        }
      }, 1000);
      this.checkTabsSelected(true);
    },
    openDebugPanel() {
      this.convyDebuggerContainer.classList.add("debugger-opened");
      this.showCloseButton = true;
      this.openDebuggerTab = true;
      this.openLogTab = false;
      this.openVoiceTab = false;
    },
    closeDebugPanel() {
      this.convyDebuggerContainer.classList.remove("debugger-opened");
      this.showCloseButton = false;
    },
    openLogPanel() {
      this.convyDebuggerContainer.classList.add("debugger-opened");
      this.showCloseButton = true;
      this.openDebuggerTab = false;
      this.openLogTab = true;
      this.openVoiceTab = false;
      this.downloadLogs();
    },
    async downloadLogs(refreshButton) {
      if (this.dialogId) {
        try {
          const response = await this.$httpNoAuth.get("/api/v2/getConvyLog?dialogId=" + this.dialogId);
          let text = "";
          response.data.logs.forEach((obj) => {
            text +=
              this.getTimeFromMillis(obj.timestamp) +
              " - " +
              obj.message.replace(/ - [a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/, "").replace(/ - \[worker.+\]:/, "") +
              "<br/>";
          });
          this.logs += text;
          if (refreshButton) {
            this.$refs.resultSnackbar.showSuccess("Refresh completed!");
          }
        } catch (e) {
          this.$refs.resultSnackbar.showError("Error loading Logs");
        }
      }
    },
    getTimeFromMillis(millis) {
      let d = new Date(millis);
      let h = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      let m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      let s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      let mi = d.getMilliseconds() < 10 ? "00" + d.getMilliseconds() : d.getMilliseconds() < 100 ? "0" + d.getMilliseconds() : d.getMilliseconds();
      return h + ":" + m + ":" + s + ":" + mi;
    },
    isSendButtonDisabled() {
      return this.sendEventParams.name.length === 0;
    },
    sendEvent() {
      this.sendingEvent = true;
      // eslint-disable-next-line no-undef
      if (euBridgeWrapperContainer) {
        let params = {};
        this.sendEventParams.params.forEach((obj) => {
          if (obj.key !== "" && obj.value !== "") {
            params[obj.key] = obj.value;
          }
        });
        // eslint-disable-next-line no-undef
        euBridgeWrapperContainer.sendCommandToGui(
          // eslint-disable-next-line no-undef
          euBridgeWrapperContainer.commands.SEND_EVENT,
          {
            eventName: this.sendEventParams.name,
            parameters: params,
          },
        );
      }
      setTimeout(() => (this.sendingEvent = false), 2000);
    },
    openEditDialog(index, item, type) {
      this.editDialog = true;
      this.editedItemValue = item.value + "";
      this.editedItemIndex = index;
      this.editedItemType = type;
    },
    updateVariable() {
      let name = "";
      if (this.editedItemType === "system") {
        this.systemVariables[this.editedItemIndex].value = this.editedItemValue;
        name = this.systemVariables[this.editedItemIndex].key;
      } else {
        this.variables[this.editedItemIndex].value = this.editedItemValue;
        name = this.variables[this.editedItemIndex].key;
      }
      this.$http.put("/api/v2/updateEntity", {
        entityName: name,
        entityValue: this.editedItemValue,
        dialogId: this.dialogId,
      });
      this.editDialog = false;
    },
    isSystemVariable(variable) {
      return variable && (this.systemVars.indexOf(variable) !== -1 || variable.startsWith("system_"));
    },
    isDiscardedVariable(variable) {
      return variable && this.discardedVars.indexOf(variable) !== -1;
    },
    async openFlowDesigner() {
      let botId = "0";
      try {
        const response = await this.$http.get("/flows-targets-hooks");
        botId = response.data[this.currentNodeParams.flow][this.currentNodeParams.version].bot_id;
      } catch (e) {
        botId = "0";
      }
      openFD.openNewWindow(botId, this.currentNodeParams.flow, this.currentNodeParams.version, this.currentNodeParams.uuid);
    },
    onCopy() {
      this.$refs.resultSnackbar.showSuccess("Copied to Clipboard!");
    },
    onError() {
      this.$refs.resultSnackbar.showError("Cannot copy to Clipboard!");
    },
  },
};
</script>

<style scoped>
.debuggerScrollbar {
  height: 420px;
}
</style>
<style>
#chatDebuggerContainer,
#convyDebuggerContainer {
  position: fixed !important;
  bottom: 0px;
  right: 10px;
  height: 490px;
  width: 380px;
  z-index: 200;
}
#convyDebuggerContainer {
  width: 430px !important;
  right: 20px !important;
}
#chatDebuggerContainer.debugger-minimized,
#convyDebuggerContainer.debugger-minimized {
  height: 0px;
  width: 0px !important;
}
#convyDebuggerContainer.debugger-active .v-window.v-item-group {
  width: 100px;
}
#convyDebuggerContainer.debugger-opened .v-window.v-item-group {
  width: 510px !important;
}
#convyDebuggerContainer.debugger-opened {
  right: 377px !important;
  width: 600px !important;
}
#convyDebuggerContainer .logsContainer {
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.54);
  min-height: 420px;
  max-width: 510px;
}
#convyDebuggerContainer .logsButtons {
  height: 49px;
}
#convyDebuggerContainer .v-item-group.theme--light .logsButtons {
  background: #fff;
}
#convyDebuggerContainer .v-item-group.theme--dark .logsButtons {
  background: #263238;
}
#convyDebuggerContainer .fullContainer {
  color: rgba(0, 0, 0, 0.54);
  min-height: 469px;
}
#convyDebuggerContainer .v-item-group.theme--light .logsContainer,
#convyDebuggerContainer .v-item-group.theme--light .fullContainer {
  background: #fafafa;
}
#convyDebuggerContainer .v-item-group.theme--dark .logsContainer,
#convyDebuggerContainer .v-item-group.theme--dark .fullContainer {
  background: #535353;
}
#convyDebuggerContainer .variableTable td div.text-truncate {
  width: 180px !important;
}
#convyDebuggerContainer .variableTable td div.text-truncate.openDialog {
  cursor: pointer;
}
#convyDebuggerContainer .capture-audio-message {
  position: absolute;
  bottom: 0;
}
#convyDebuggerContainer .copy-dialog-id-tab {
  position: absolute;
  bottom: 70px;
}
#convyDebuggerContainer .audio-is-registering {
  color: red;
  position: absolute !important;
  bottom: 25px;
  margin-left: 10px;
  font-size: 15px;
}
#convyDebuggerContainer .blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
#convyDebuggerContainer .tocopy-class {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -999999 !important;
  opacity: 0 !important;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.start-record-button-step,
.stop-record-button-step,
.restart-record-button-step {
  position: relative;
  margin-top: -50px;
  left: 345px;
}
.delete-record-button-step {
  position: relative;
  margin-top: -45px;
  left: 0px;
}
.send-record-button-step {
  position: relative;
  margin-top: -45px;
  left: 279px;
}
</style>
