<template>
  <v-container fluid class="pa-12 mb-12">
    <ResultSnackbar ref="resultSnackbar" />

    <v-toolbar v-if="wallboards.length > 0" color="transparent" flat class="pa-0">
      <v-spacer />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            :loading="reload"
            :disabled="reload"
            class="primary--text"
            small
            v-on="on"
            @click="reloadAllCharts()"
          >
            <v-icon dark class="mr-3">
              mdi-reload
            </v-icon> Reload All charts
            <v-progress-linear
              v-if="value > 0"
              :value="value"
              color="#1D9096"
              style="width: 100%"
              :active="show"
              :indeterminate="query"
              :query="true"
              rounded
              height="2"
              class="myProgressBar ml-4"
              :background-color="!$vuetify.theme.dark ? '#b1c1c3' : '#364244'"
            />
          </v-btn>
        </template>
        <span>Reload timer: 15 seconds</span>
      </v-tooltip>
    </v-toolbar>

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && wallboards.length === 0" cols="12">
        <v-alert type="warning">
          No widget configured!
        </v-alert>
      </v-col>
    </v-row>
    <!-- wallboard -->
    <dnd-zone :transition-duration="0.3" @move="onMoveEvent">
      <dnd-container :dnd-model="wallboards" dnd-id="wallboard-container" class="row">
        <dnd-item
          v-for="(wallboard, index) in getWallboards"
          :key="wallboard.id"
          class="ma-4"
          :dnd-id="wallboard.id"
          :dnd-model="wallboard"
          style="min-width: 400px"
        >
          <TaskOverview
            v-if="wallboard.type === 'TASK_OVERVIEW'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :agents="agents"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <AgentByStatus
            v-else-if="wallboard.type === 'AGENT_STATUS'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <AgentCapacity
            v-else-if="wallboard.type === 'AGENT_CAPACITY'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <FirstAnswerByQueue
            v-else-if="wallboard.type === 'FIRST_ANSWER_BY_QUEUE'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <QueueCapacity
            v-else-if="wallboard.type === 'QUEUE_CAPACITY'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <SlaByQueueRT
            v-else-if="wallboard.type === 'SLA_BY_QUEUE_RT'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <SlaByQueueSocial
            v-else-if="wallboard.type === 'SLA_BY_QUEUE_SOCIAL'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <SlaOfExpiredMail
            v-else-if="wallboard.type === 'SLA_BY_QUEUE_EMAIL'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :profiles="profiles"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <TaskStatusByChannel
            v-else-if="wallboard.type === 'TASK_STATUS_BY_CHANNEL'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :agents="agents"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <TaskStatusByQueue
            v-else-if="wallboard.type === 'TASK_STATUS_BY_QUEUE'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :agents="agents"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <CallPerformance
            v-else-if="wallboard.type === 'CALL_PERFORMANCE'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :queues="queues"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
          <ConversationTotals
            v-else-if="wallboard.type === 'CONVERSATION_TOTALS'"
            :key="wallboard.id"
            :wallboard="wallboard"
            :publications="publications"
            @deleteWidget="deleteWidget(index)"
            @resizeWidget="onMoveEvent()"
            @failToLoadData="failToLoadData"
            @onChangeView="onChangeView(wallboard.type, ...arguments)"
          />
        </dnd-item>
      </dnd-container>
    </dnd-zone>
    <AddNewItemButton name="widget" :disabled="getAvailableCharts.length === 0" @addNewItem="showCreationMenu" />
    <v-menu v-model="addMenuProps.showMenu" :position-x="addMenuProps.x" :position-y="addMenuProps.y" absolute offset-y>
      <v-list class="wallboardList">
        <v-list-item v-for="(chart, index) in getAvailableCharts" :key="'wallboard_opts_' + index" @click="addWidget(chart)">
          <v-list-item-content>
            <v-list-item-title>{{ chart.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import scroller from "../../helpers/scrollToTop";
import spacing from "../../helpers/spacing";
import fieldValidator from "../../helpers/fieldValidators";
import AgentByStatus from "../../components/analytics/wallboard/AgentByStatus";
import AgentCapacity from "../../components/analytics/wallboard/AgentCapacity";
import FirstAnswerByQueue from "../../components/analytics/wallboard/FirstAnswerByQueue";
import QueueCapacity from "../../components/analytics/wallboard/QueueCapacity";
import SlaByQueueRT from "../../components/analytics/wallboard/SlaByQueueRT";
import SlaByQueueSocial from "../../components/analytics/wallboard/SlaByQueueSocial";
import SlaOfExpiredMail from "../../components/analytics/wallboard/SlaOfExpiredMail.vue";
import TaskStatusByChannel from "../../components/analytics/wallboard/TaskStatusByChannel";
import TaskStatusByQueue from "../../components/analytics/wallboard/TaskStatusByQueue";
import TaskOverview from "../../components/analytics/wallboard/TaskOverview";
import CallPerformance from "../../components/analytics/wallboard/CallPerformance";
import ConversationTotals from "../../components/analytics/wallboard/ConversationTotals";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Wallboards",
  components: {
    TaskStatusByQueue,
    TaskStatusByChannel,
    SlaByQueueRT,
    SlaByQueueSocial,
    QueueCapacity,
    FirstAnswerByQueue,
    AgentCapacity,
    AgentByStatus,
    ResultSnackbar,
    AddNewItemButton,
    TaskOverview,
    SlaOfExpiredMail,
    CallPerformance,
    ConversationTotals,
  },
  data() {
    return {
      value: 0,
      query: false,
      show: true,
      interval: 0,
      totalTime: 0,
      spentTime: 0,
      reload: false,
      queues: [],
      profiles: [],
      publications: [],
      agents: [],
      wallboards: [],
      showWarning: false,
      addMenuProps: {
        showMenu: false,
        x: 0,
        y: 0,
      },
      wallboardTypes: [],
      saveWallboardTimer: null,
    };
  },
  computed: {
    getWallboards() {
      return this.wallboards;
    },
    getAvailableCharts() {
      let filtred = this.wallboardTypes
        .filter((type) => !this.wallboards.some((w) => w.type === type))
        .map((type) => {
          return {
            type,
            name: type,
            label: this.getWidgetLabel(type),
          };
        });
      return filtred.sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
    },
  },
  watch: {
    wallboards(val) {
      if (val.length === 0) {
        clearInterval(this.interval);
      }
    },
  },
  async mounted() {
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    await this.loadWallboardTypes();
    await this.loadRequirements();
    this.loadWallboard();
    this.queryAndIndeterminate();
    this.fixViewTableScrollBar(1500);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = 0;
  },
  methods: {
    ...spacing,
    ...scroller,
    ...fieldValidator,
    fixViewTableScrollBar(timeout) {
      // se ci sono dei grafici in versione tabellare (sulla prima riga dei widget
      //con una scrollbar al loro interno) e ci sono abbastanza widget per far
      //comparire la scollbar sull pagina (perfect-scrollbar). Lo scroll su i
      //widget della prima riga non funziona se prima non si muove a mano la scrollbar
      //interna al grafico. Inoltre, una volta riportata in cima si blocca di nuovo
      //Questo workaround serve per sbloccarla e previene che si possa bloccare
      setTimeout(() => {
        const charts = document.querySelectorAll("#viewWallboardTableResult .v-data-table__wrapper");
        charts &&
          charts.forEach((chart) => {
            if (chart.scrollTop === 0) {
              chart.scrollTop = 1;
            }
            chart.onscroll = function () {
              this.handleViewTableScrollBar(chart);
            }.bind(this);
          });
      }, timeout);
    },
    handleViewTableScrollBar(chart) {
      if (chart.scrollTop < 1) {
        chart.scrollTop = 1;
      }
      if (chart.scrollTop >= chart.scrollHeight - chart.clientHeight) {
        chart.scrollTop--;
      }
    },
    async onChangeView(chartType, viewType) {
      if (viewType === "table" || viewType === "chart") {
        //escludo quella di dettaglio (tipo per agent capacity)
        const chart = this.wallboards.find((chart) => chart.type === chartType);
        if (chart) {
          if (chart.view !== viewType) {
            //cosi salvo solo se cambia effettivament il type e non ogni volta che pigio il pulsante
            chart.view = viewType;
            await this.saveWallboardState();
          }
          if (viewType === "table") {
            await this.fixViewTableScrollBar(250);
          }
        }
      }
    },
    async loadRequirements() {
      try {
        const response = await this.$http.get("/human-agents/queue");
        this.queues = response.data;
      } catch (e) {
        this.queues = [];
      }
      try {
        const response = await this.$http.get("/social-connector");
        this.publications = response.data;
      } catch (e) {
        this.publications = [];
      }
      try {
        const response = await this.$http.get("/human-agents/profile");
        this.profiles = response.data;
      } catch (e) {
        this.profiles = [];
      }
      try {
        const result = await this.$http.get("/human-agents/agent");
        this.agents = result.data;
      } catch (e) {
        this.agents = [];
      }
    },
    async loadWallboard() {
      try {
        const response = await this.$http.get("/analytics/wallboards");
        response.data.forEach((wallboard) => {
          wallboard.label = this.getWidgetLabel(wallboard.type);
        });
        this.wallboards = response.data || [];
        setTimeout(() => {
          this.reloadAllCharts();
        }, 500);
      } catch (e) {
        this.wallboards = [];
      } finally {
        this.showWarning = true;
      }
    },
    async loadWallboardTypes() {
      try {
        const response = await this.$http.get("/analytics/wallboards/types");
        this.wallboardTypes = response.data || [];
        /** TEST ONLY */
        this.wallboardTypes.push("CONVERSATION_TOTALS");
      } catch (e) {
        this.wallboardTypes = [];
      }
    },
    showCreationMenu(e) {
      e.preventDefault();
      this.addMenuProps.showMenu = false;
      this.addMenuProps.x = e.clientX;
      this.addMenuProps.y = e.clientY;
      this.$nextTick(() => {
        this.addMenuProps.showMenu = true;
      });
    },
    addWidget(widget) {
      widget.id = uuidv4();
      if (widget.type === "TASK_OVERVIEW") {
        widget.resize = true;
      } else {
        widget.resize = false;
      }
      //default visualizzazione all'aggiunta di un widget
      widget.view = "chart";
      this.wallboards.push(widget);
      this.saveWallboardState();
      setTimeout(() => {
        this.reloadAllCharts();
        this.queryAndIndeterminate();
      }, 500);
    },
    deleteWidget(index) {
      this.wallboards.splice(index, 1);
      this.saveWallboardState();
    },
    onMoveEvent() {
      if (this.saveWallboardTimer) {
        clearTimeout(this.saveWallboardTimer);
      }
      this.saveWallboardTimer = setTimeout(() => this.saveWallboardState(), 2000);
    },
    async saveWallboardState() {
      try {
        await this.$http.post("/analytics/wallboards", this.wallboards);
      } catch (e) {
        this.$refs.resultSnackbar.showError("Wallboard configuration can not be updated!");
      }
    },
    failToLoadData() {
      this.$refs.resultSnackbar.showError("Fail to load widget data!");
    },
    getWidgetLabel(type) {
      switch (type) {
        case "AGENT_CAPACITY":
          return "Agents capacity";
        case "FIRST_ANSWER_BY_QUEUE":
          return "AVG time first response";
        case "SLA_BY_QUEUE_RT":
          return "Percentage of expired SLA - Chat";
        case "SLA_BY_QUEUE_SOCIAL":
          return "Percentage of expired SLA - social messaging";
        case "SLA_BY_QUEUE_EMAIL":
          return "Percentage of expired SLA - email";
        case "AGENT_STATUS":
          return "Agents Status";
        case "TASK_STATUS_BY_QUEUE":
          return "Conversations status by queue";
        case "TASK_STATUS_BY_CHANNEL":
          return "Conversations status by channel";
        case "QUEUE_CAPACITY":
          return "Queues overload";
        case "TASK_OVERVIEW":
          return "Conversations overview";
        case "CALL_PERFORMANCE":
          return "Audio/Video calls overview";
        case "CONVERSATION_TOTALS":
          return "Conversations Monitoring";
      }
      return type;
    },
    reloadAllCharts() {
      this.reload = true;
      EventBus.$emit("reloadAll");
      clearInterval(this.interval);
      this.queryAndIndeterminate();
      setTimeout(() => {
        this.reload = false;
      }, 1000);
    },
    queryAndIndeterminate() {
      clearInterval(this.interval);

      let totalTime = 15000; // 100% della progress // 15 secondi
      let spentTime = 0; // tempo già passato

      // let valtoAdd = 1;

      this.totalTime = totalTime;
      this.spentTime = spentTime;
      this.query = true;
      this.show = true;
      this.value = (spentTime * 100) / totalTime;
      this.slacolor = "grey";

      this.query = false;

      this.interval = setInterval(() => {
        this.spentTime += 100;
        this.value = (this.spentTime * 100) / this.totalTime;

        if (this.value >= 100) {
          clearInterval(this.interval);
          this.interval = 0;
          this.reloadAllCharts();
          this.queryAndIndeterminate();
        }
      }, 100);
    },
  },
};
</script>

<style>
.wallboardList .v-list-item__title {
  line-height: 1.3rem !important;
}
</style>
<style scoped>
.myProgressBar {
  position: absolute;
  bottom: -2px;
  left: -12px;
  width: 188px;
}
</style>
