async function retrieveRecordingStatus(recordings) {
  const recordingNeedsInfo = [];
  for (const recording of recordings) {
    //se non ho il file --> non è ancora processata
    //se non ho il flag deleted --> non è stata eliminata
    //allora devo vedere in che stato si trova chiedendo al pps --> salvo in array per chiedere tutto in una volta
    if (!recording.file && !recording.deleted) {
      recordingNeedsInfo.push(recording.uuid);
    }
  }
  if (recordingNeedsInfo.length > 0) {
    let result = {};
    try {
      const res = await this.$http.post("/post-processing/recording/status", { metadataUUIDs: recordingNeedsInfo });
      result = res.data;
    } finally {
      for (const recording of recordings) {
        //controllo se c'è uno stato del pps che devo aggiungere alle informazioni
        if (result[recording.uuid]) {
          recording.postProcessingStatus = result[recording.uuid];
        }
      }
    }
  }
  return recordings;
}

export default {
  retrieveRecordingStatus,
};
