<template>
  <v-form ref="createShortcodeForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container fluid>
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteShortcodeConfirm" @dialogDeny="showConfirm = false" />
      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-text-field
            v-if="!isEdit"
            v-model.trim="editedShortcode.label"
            label="Shortcode label"
            :rules="[
              requiredField,
              charsGreaterThanAndLessThan(editedShortcode.label, 3, 200),
              validateShortcodeName,
              isShortcodeUnique(shortcodesList, editedShortcode.label),
            ]"
            prepend-inner-icon="mdi-code-braces"
            :autocomplete="$store.getters.disableAutocomplete"
          />
          <v-text-field
            v-else
            v-model.trim="editedShortcode.label"
            label="Shortcode label"
            prepend-inner-icon="mdi-code-braces"
            :autocomplete="$store.getters.disableAutocomplete"
            autofocus
            disabled
          />
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-textarea
            v-model.trim="editedShortcode.content"
            label="Shortcode content"
            :rules="[requiredField, charsGreaterThanAndLessThan(editedShortcode.content, 3)]"
            :autocomplete="$store.getters.disableAutocomplete"
            clearable
            prepend-inner-icon="mdi-message-reply-text-outline"
            auto-grow
            outlined
            rows="2"
            row-height="15"
            :disabled="isCCMUserWithRestrictPermission"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-0 pt-0" :class="getFormColMargin">
          <v-autocomplete
            v-model="editedShortcode.availability"
            :items="queueListItems"
            multiple
            small-chips
            :label="getQueuesLabel()"
            prepend-inner-icon="mdi-expand-all"
            :rules="[checkUserPermission]"
            validate-on-blur
            :hint="getQueuesHint()"
            persistent-hint
            :disabled="isCCMUserWithRestrictPermission"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- Buttons -->
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading || isCCMUserWithRestrictPermission"
            @click.prevent="saveShortcode"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.deleteLoading"
            :disabled="(isEdit && form.submitLoading) || isCCMUserWithRestrictPermission"
            @click.prevent="deleteShortcode"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "@/event-bus";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import merge from "deepmerge";

export default {
  name: "ShortcodeForm",
  components: {
    ConfirmDialog,
  },
  props: ["shortcodeSetting", "shortcodesList", "queuesList"],
  data() {
    return {
      showConfirm: false,
      deleteMessage: "Shortcode " + this.shortcodeSetting.label + " will be deleted!",
      isEdit: this.shortcodeSetting.label !== "",
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedShortcode: {
        eri: null,
        label: this.shortcodeSetting.label,
        content: this.shortcodeSetting.content,
        availability: this.shortcodeSetting.availability,
      },
      isCCMUserWithRestrictPermission: false,
    };
  },
  computed: {
    ...spacing,
    queueListItems() {
      return this.queuesList.map((queue) => {
        return { text: queue.name, value: queue.eri };
      });
    },
  },
  mounted() {
    this.editedShortcode = merge(this.editedShortcode, this.shortcodeSetting);
    this.editedShortcode.availability = this.shortcodeSetting.availability || []; //perchè altrimenti il merge mi duplica;
    if (this.$refs.createShortcodeForm) this.$refs.createShortcodeForm.resetValidation();
    const isCCM = this.$store.getters.isCCM;
    if (!this.isEdit && isCCM) {
      const owned_profiles = this.$store.getters.getUserResources.owned_profiles;
      if (owned_profiles.length > 0) {
        //aggiungo gli eri delle code per non farlo creare global
        this.queuesList.forEach((queue) => {
          this.editedShortcode.availability.push(queue.eri);
        });
      }
    }
    this.isCCMUserWithRestrictPermission = this.isCCMuser();
  },
  methods: {
    ...fieldValidators,
    getQueuesLabel() {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        if (isCCM) {
          const owned_profiles = resources.owned_profiles;
          if (owned_profiles.length > 0) {
            return "Shortcode available in these queues";
          }
        }
      }
      return this.editedShortcode.availability && this.editedShortcode.availability.length > 0
        ? "Shortcode available in these queues"
        : "Shortcode available in all queues";
    },
    getQueuesHint() {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        if (isCCM) {
          const owned_profiles = resources.owned_profiles;
          if (owned_profiles.length > 0) {
            return "You can add the queues to make available this shortcode only to the enabled queues";
          }
        }
      }
      return "You can add the queues to make available this shortcode only to the enabled queues, otherwise by leaving this field blank the shortcode will be available to all the queues.";
    },
    checkUserPermission(value) {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        const owned_profiles = resources.owned_profiles;
        if (isCCM) {
          if (owned_profiles.length > 0) {
            if (value.length === 0) {
              return "Select at least one or more queues";
            }
          }
        }
      }
      return true;
    },
    async saveShortcode() {
      if (this.form.isValid) {
        try {
          this.form.isSubmitted = true;
          this.form.submitLoading = true;
          this.editedShortcode.availability = this.editedShortcode.availability.filter((queueERI) => this.queuesList.find((q) => q.eri === queueERI) != null);
          const isCCM = this.$store.getters.isCCM;
          if (!this.isEdit && isCCM) {
            const owned_profiles = this.$store.getters.getUserResources.owned_profiles;
            if (owned_profiles.length > 0) {
              //aggiungo gli eri delle code per non farlo creare global
              this.queuesList.forEach((queue) => {
                if (this.editedShortcode.availability.indexOf(queue.eri) === -1) {
                  this.editedShortcode.availability.push(queue.eri);
                }
              });
            }
          }
          const result = await this.$http.post(`/human-agents/shortcode`, this.editedShortcode);
          this.editedShortcode = result.data;
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SHORTCODES, {
            editConfig: this.editedShortcode,
            message: "Shortcode " + this.editedShortcode.label + " updated",
          });
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SHORTCODES_FAIL, {
            editConfig: this.editedShortcode,
            message: `Error update shortcode ${this.editedShortcode.label} ${e.response.data.error}`,
          });
        } finally {
          this.form.isSubmitted = false;
          this.form.submitLoading = false;
        }
      }
    },
    deleteShortcode() {
      if (this.shortcodeSetting.label) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_SHORTCODES_CREATION);
      }
    },
    async onDeleteShortcodeConfirm() {
      this.showConfirm = true;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete(`/human-agents/shortcode/${this.editedShortcode.label}`);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SHORTCODES, {
          label: this.editedShortcode.label,
          message: `ShortCode ${this.editedShortcode.label} Deleted!`,
        });
        this.showConfirm = false;
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SHORTCODES_FAIL, {
          message: `Fail to delete shortcode ${this.editedShortcode.label} : ${err.response.data.error.response}`,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    isCCMuser() {
      const user = this.$store.getters.getUser;
      //disabilito se è un ccm, lo shortcode è global e il ccm non ha visibilità su tutti i profili
      if (this.isEdit && this.$store.getters.isCCM && this.editedShortcode.availability.length == 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          return true;
        }
      }
      //questo controlla che il ccm, se ha una lista di profili deve poter controllare tutte le code associate, altrimenti non può editare
      if (this.isEdit && this.$store.getters.isCCM && this.editedShortcode.availability.length > 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          const shortCodeQueues = this.editedShortcode.availability;
          const availableQueueERIs = this.queuesList.map((q) => q.eri);
          for (const queueERI of shortCodeQueues) {
            if (!availableQueueERIs.includes(queueERI)) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
};
</script>
