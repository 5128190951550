<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <!-- Grouped bar chart -->
    <v-col id="barChart" cols="12" align="center">
      <v-card raised>
        <v-system-bar dark window color="primary">
          <v-card-title class="white--text">
            Companies real time conversations
          </v-card-title>
        </v-system-bar>
        <v-row>
          <v-col cols="12" style="height: 400px" :class="!loadBarChart ? 'pa-6' : ''">
            <VueApexCharts
              v-if="loadBarChart"
              :key="'bar_chart_' + forceRerender"
              class="companiesOverview"
              type="bar"
              height="350"
              width="97%"
              :options="barChartOptions"
              :series="barChartSeries"
            />
            <v-alert v-else type="warning">
              No live conversations!
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Stacked bar chart -->
    <v-col id="stackCharts" cols="12" align="center">
      <v-card raised>
        <v-system-bar dark window color="primary">
          <v-card-title class="white--text">
            Companies Monthly Efficiency
          </v-card-title>
          <v-spacer />
          <v-btn
            icon
            text
            tile
            small
            depressed
            :disabled="isRefreshStackCharts"
            class="mx-1"
            @click="refreshstackCharts()"
          >
            <v-icon class="mx-0" color="white">
              mdi-refresh
            </v-icon>
          </v-btn>
        </v-system-bar>
        <v-row>
          <v-col cols="12" style="height: 400px" :class="showStackAlert ? 'pa-6' : ''">
            <VueApexCharts
              v-if="loadStackChart"
              class="companiesOverview"
              type="bar"
              height="350"
              width="97%"
              :options="stackChartOptions"
              :series="stackChartSeries"
            />
            <v-alert v-if="showStackAlert" type="warning">
              Not enough data to render the charts!
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- company details -->
    <SearchBar v-if="showCompaniesMonitoringSection" :searchString="searchString" title="Companies monitoring" @input="searchString = $event" />
    <v-expansion-panels
      v-if="showCompaniesMonitoringSection"
      :key="forceRender + '_ep'"
      v-model="expansionPanel"
      popout
      :class="getMainExpansionPanelMargin"
      class="mt-4"
    >
      <v-expansion-panel v-for="(license, id) in listLicenses" :key="id + 'coep'">
        <v-hover v-slot="{ hover }">
          <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
            <v-row align="center" class="spacer" no-gutters>
              <v-col lg="3" sm="4">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Company</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ getCompanyNameFromID(license.company) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="3">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>UUID</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ license.company }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col lg="3" sm="4">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Daily usage</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ license.Daily.currentUsage }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col lg="3" sm="4">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Monthly usage</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ license.Monthly.currentUsage }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
        </v-hover>
        <v-expansion-panel-content>
          <v-divider />
          <CompanyEfficiency :key="id + '_coep_ce'" :botList="getBotList" :company="license.company" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import VueApexCharts from "vue-apexcharts";
import CompanyEfficiency from "../../components/analytics/CompanyEfficiency";
import SearchBar from "../../components/other/SearchBar";
import lodash from "lodash";
import timeoutHelper from "@/helpers/timeout";

export default {
  name: "CompaniesOverview",
  components: {
    ResultSnackbar,
    VueApexCharts,
    CompanyEfficiency,
    SearchBar,
  },
  data() {
    return {
      showStackAlert: false,
      searchString: "",
      licenses: [],
      showCompaniesMonitoringSection: true,
      expansionPanel: null,
      forceRender: 0,
      loadLicenses: false,
      botList: [],
      intervalID: null,
      intervalDailyUsage: null,
      loadBarChart: false,
      loadStackChart: false,
      barChartSeries: [
        {
          name: "Active Conversations",
          data: [],
        },
        {
          name: "Handover Conversations",
          data: [],
        },
      ],
      barChartOptions: {
        theme: {
          mode: "light",
        },
        colors: this.$vuetify.theme.dark ? ["#1D9096", "#60a38d"] : ["#1E88E5", "#43A047"],
        xaxis: {
          tickAmount: 1,
          categories: [],
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        chart: {
          type: "bar",
          height: 430,
          background: "#ffffff",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        legend: {
          display: true,
          labels: {
            colors: "",
            useSeriesColors: false,
          },
        },
        tooltip: {
          theme: "light",
          y: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
          marker: {
            show: false,
          },
        },
      },
      stackChartSeries: [
        {
          name: "COMPLETED",
          data: [],
        },
        {
          name: "TIMEOUT",
          data: [],
        },
        {
          name: "ERROR",
          data: [],
        },
      ],
      stackChartOptions: {
        theme: {
          mode: "light",
        },
        colors: this.$vuetify.theme.dark ? ["#1D9096", "#ffa973", "#ff5252"] : ["#1E88E5", "#FB8C00", "#E53935"],
        chart: {
          type: "bar",
          height: 430,
          stacked: true,
          stackType: "100%",
          background: "#ffffff",
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (val) => {
              return val.toFixed(0);
            },
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        tooltip: {
          theme: "light",
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter: (value, { series, seriesIndex, dataPointIndex }) => {
              //seriesIndex ---> state index
              //dataPointIndex ---> company index
              if (this.numericValueStackCharts[seriesIndex][dataPointIndex] === 1) {
                return this.numericValueStackCharts[seriesIndex][dataPointIndex] + " conversation";
              }
              return this.numericValueStackCharts[seriesIndex][dataPointIndex] + " conversations";
            },
          },
          marker: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: true,
          offsetX: 40,
          labels: {
            colors: "",
            useSeriesColors: false,
          },
        },
        dataLabels: {
          formatter: function (val) {
            if (val % 1 != 0) {
              return val.toFixed(2);
            }
            return val;
          },
        },
      },
      isRefreshStackCharts: false,
      forceRerender: 0,
      numericValueStackCharts: [],
    };
  },
  computed: {
    ...spacing,
    getBotList() {
      return this.botList;
    },
    listLicenses() {
      let result = this.licenses.slice();
      result.sort((a, b) => {
        if (a.companyName < b.companyName) return -1;
        if (a.companyName > b.companyName) return 1;
        return 0;
      });
      result = result.filter((a) => {
        return a.companyName.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
      });
      return result;
    },
  },
  async mounted() {
    await this.loadBotList();
    //primo grafico
    this.loadCompaniesOverviewCurrentConversations();
    this.intervalID = setInterval(() => {
      this.loadCompaniesOverviewCurrentConversations();
    }, 30000);

    //secondo grafico
    this.loadCompaniesOverviewCompany();
    EventBus.$on(
      this.$store.getters.getEvents.UPDATED_USER,
      this.loadCompaniesOverviewCompany, // per qualche motivo, il cambio token avviene dopo questa richiesta
    );

    // richiesta uso giornaliero (expansion panels)
    this.loadCompaniesDailyUsage();
    this.intervalDailyUsage = setInterval(() => {
      this.loadCompaniesDailyUsage();
    }, 15000);

    if (this.$vuetify.theme.dark) {
      //* gestione colori assi del grafico
      this.barChartOptions.theme.mode = "dark";
      this.barChartOptions.chart.background = "#263238";
      this.stackChartOptions.theme.mode = "dark";
      this.stackChartOptions.chart.background = "#263238";
    }
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
  },
  beforeDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
    if (this.intervalDailyUsage) {
      clearInterval(this.intervalDailyUsage);
      this.intervalDailyUsage = null;
    }
    EventBus.$off(this.$store.getters.getEvents.UPDATED_USER, this.loadCompaniesOverviewCompany);
  },
  methods: {
    async loadCompaniesDailyUsage() {
      try {
        const list = this.$store.getters.getCompaniesList.map((c) => c.uuid);
        const result = await this.$http.post("/analytics/companies-overview/license", { companies: list });
        this.licenses = [];
        //aggiungo elementi con uso > 0
        result.data.forEach((el, index) => {
          if (el.Monthly.currentUsage > 0) {
            result.data[index].companyName = this.getCompanyNameFromID(result.data[index].company);
            this.licenses.push(result.data[index]);
          }
        });
        if (this.licenses.length === 0) {
          this.showCompaniesMonitoringSection = false;
        }
      } finally {
        this.loadLicenses = true;
      }
    },
    transformCompanyIDIntoName(list) {
      return list.map((companyId) => {
        return this.$store.getters.getCompaniesList.find((c) => c.uuid === companyId).name;
      });
    },
    getCompanyNameFromID(companyId) {
      return this.$store.getters.getCompaniesList.find((c) => c.uuid === companyId)?.name;
    },
    async loadCompaniesOverviewCompany(sync, setTime) {
      const list = this.$store.getters.getCompaniesList.map((c) => c.uuid);
      const data = {
        companies: list,
      };
      if (sync) {
        data.action = "sync";
      }
      let timeout = 0;
      if (setTime) {
        timeout = setTime;
      } else {
        timeout = 500;
      }
      await timeoutHelper.sleep(timeout);
      let result = null;
      try {
        result = await this.$httpAuth.post("/dashboard/companies-overview/overview", data);
        if (result.data.companies.length > 0) {
          this.stackChartSeries[0].data = result.data.fullbot;
          this.stackChartSeries[1].data = result.data.timeout;
          this.stackChartSeries[2].data = result.data.error;
          this.stackChartOptions.xaxis.categories = this.transformCompanyIDIntoName(result.data.companies);
          this.numericValueStackCharts = result.data.numericValue;
          // arriva il conteggio degli handover che non mostriamo più
          // quindi lo rimuovo perchè spacca il tooltip delle conversazioni andate in timeout
          this.numericValueStackCharts.splice(1, 1);
          await timeoutHelper.sleep(800);
          this.loadStackChart = true;
          this.showStackAlert = false;
          this.adjustApexCharts();
        } else {
          this.showStackAlert = true;
          this.loadStackChart = false;
        }
      } finally {
        result = null;
      }
    },
    refreshstackCharts() {
      this.isRefreshStackCharts = true;
      this.showStackAlert = false;
      this.loadStackChart = false;
      this.loadCompaniesOverviewCompany(true);
      setTimeout(() => {
        this.isRefreshStackCharts = false;
      }, 300000);
    },
    async loadCompaniesOverviewCurrentConversations() {
      let result = null;
      try {
        const list = this.$store.getters.getCompaniesList.map((c) => c.uuid);
        result = await this.$http.post("/analytics/companies-overview/current-conversations", { companies: list });
        if (result.data.companies.length > 0) {
          let mustBeRerender = false;
          const translatedCompanies = this.transformCompanyIDIntoName(result.data.companies);
          if (
            !lodash.isEqual(this.barChartOptions.xaxis.categories, translatedCompanies) ||
            !lodash.isEqualWith(this.barChartSeries[0].data, result.data.active) ||
            !lodash.isEqualWith(this.barChartSeries[1].data, result.data.handoverConversations)
          ) {
            mustBeRerender = true;
          }
          this.barChartOptions.xaxis.categories = translatedCompanies;
          this.barChartSeries[0].data = result.data.active;
          this.barChartSeries[1].data = result.data.handoverConversations;
          if (mustBeRerender) {
            this.adjustApexCharts();
            this.forceRerender++;
          }
          await timeoutHelper.sleep(800);
          this.loadBarChart = true;
        } else {
          this.loadBarChart = false;
        }
      } finally {
        result = null;
      }
    },
    async loadBotList() {
      let response = null;
      try {
        response = await this.$http.get("/bot");
        if (response && response.data) {
          const bots = Object.values(response.data);
          for (let i in bots) {
            this.botList.push(bots[i]);
          }
        }
      } finally {
        response = null;
      }
    },
    adjustApexCharts() {
      if (this.$vuetify.theme.dark) {
        setTimeout(() => {
          //correggo il dropdown per l'export
          const menu = document.getElementsByClassName("apexcharts-menu");
          menu &&
            menu.forEach((el) => {
              el.style.background = "#263238";
            });
        }, 300);
      }
    },
  },
};
</script>

<style>
.companiesOverview .apexcharts-tooltip {
  background: #f3f3f3;
  color: black;
}
.companiesOverview .apexcharts-toolbar {
  z-index: 4 !important;
}
</style>
