<template>
  <v-card style="text-align: center">
    <v-card-text>
      <v-btn
        icon
        class="primary--text"
        :disabled="!loaded"
        :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : '#background-color:#ececec!important'"
        @click.native="playing ? pause() : play()"
      >
        <v-icon v-if="!playing || paused">
          mdi-play-circle-outline
        </v-icon>
        <v-icon v-else>
          mdi-pause-circle-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        class="primary--text ml-2"
        :disabled="!loaded"
        :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : '#background-color:#ececec!important'"
        @click.native="stop()"
      >
        <v-icon>mdi-stop-circle-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        class="primary--text ml-2"
        :disabled="!loaded"
        :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : '#background-color:#ececec!important'"
        @click.native="mute()"
      >
        <v-icon v-if="!isMuted">
          mdi-volume-high
        </v-icon>
        <v-icon v-else>
          mdi-volume-off
        </v-icon>
      </v-btn>
      <v-btn
        icon
        class="primary--text ml-2"
        :style="$vuetify.theme.dark ? 'background-color:#3e4d54!important' : '#background-color:#ececec!important'"
        @click.native="loaded ? download() : reload()"
      >
        <v-icon v-if="!loaded">
          refresh
        </v-icon>
        <v-icon v-else>
          mdi-download
        </v-icon>
      </v-btn>
      <v-slider v-model="percentage" :disabled="!loaded" @click.native="setPosition()" />
      <div class="mb-5">
        Title: {{ title }}
      </div>
      <p>{{ currentTime }} / {{ duration }}</p>
    </v-card-text>
    <audio id="player" ref="player" :src="file" @ended="ended" @canplay="canPlay" />
  </v-card>
</template>
<script>
const formatTime = (second) => new Date(second * 1000).toISOString().substr(11, 8);
export default {
  name: "VuetifyAudio",
  props: {
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00:00:00",
      audio: undefined,
      totalDuration: 0,
    };
  },
  computed: {
    duration: function () {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },
  mounted() {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
  methods: {
    setPosition() {
      this.audio.currentTime = parseInt((this.audio.duration / 100) * this.percentage);
    },
    stop() {
      this.paused = this.playing = false;
      this.audio.pause();
      this.audio.currentTime = 0;
    },
    play() {
      if (this.playing) return;
      this.paused = false;
      this.audio.play().then(() => (this.playing = true));
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    download() {
      this.audio.pause();
      //se l'src è un base64 allora il download deve essere fatto diversamente
      if (this.file.includes("data:") && this.file.includes("base64")) {
        let a = document.createElement("a"); //Create <a>
        a.href = this.file; //Image Base64 Goes here
        a.download = this.title; //File name Here
        a.click();
      } else {
        window.open(this.file, "download");
      }
    },
    mute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
      this.volumeValue = this.isMuted ? 0 : 75;
    },
    reload() {
      this.audio.load();
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }
        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    _handlePlayingUI: function () {
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
    },
    _handlePlayPause: function (e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (e.type === "pause" && this.paused === false && this.playing === false) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
  },
};
</script>
