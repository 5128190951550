<template>
  <div>
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteProfile" />

    <v-form ref="createProfileForm" v-model="form.isValid" :class="getCreationFormMargin" :disabled="$store.getters.isCCMO">
      <v-row :class="getFormRowMargin">
        <v-col v-if="isEdit" cols="12" :class="getFormColMargin" class="d-inline-flex">
          <v-text-field v-model.trim="editProfile.eri" label="Profile ERI" disabled :rules="[requiredField]" />
          <CopyToClipboard :data="editProfile.eri" message="ERI copied to clipboard!" />
        </v-col>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col v-if="isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field v-model.trim="editProfile.uuid" prepend-inner-icon="mdi-vector-combine" label="Profile UUID" disabled :rules="[requiredField]" />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editProfile.name"
            prepend-inner-icon="mdi-arrow-decision"
            label="Profile Name"
            :rules="[
              requiredField,
              simpleValidatorNameWithSpaces,
              uniqueNameProfile(profilesList, editProfile.name, editProfile.uuid),
              charsGreaterThanAndLessThan(editProfile.name, 3, 255),
            ]"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-autocomplete
            v-model="getSelectedQueue"
            prepend-inner-icon="mdi-expand-all"
            :items="queueListItems"
            :rules="[requiredField]"
            multiple
            small-chips
            label="Choose Queues"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.number="editProfile.routing.concurrentTask"
            label="Concurrent Chat Task"
            type="number"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-message"
            min="0"
            max="36535"
          />
        </v-col>
        <v-col v-show="false" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.number="editProfile.routing.concurrentWebcallbackTask"
            label="Concurrent Web Callbak Task"
            type="number"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-av-timer"
            min="0"
            max="36535"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.number="editProfile.routing.concurrentEmailTask"
            label="Concurrent Email Task"
            type="number"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-email"
            min="0"
            max="36535"
          />
        </v-col>
        <v-col cols="12" sm="6" class="d-flex flex-grow" :class="getFormColMargin">
          <v-autocomplete
            v-model="getSelectedAgentDesktop"
            :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN)"
            class="choose-agent-desktop-profile"
            prepend-inner-icon="mdi-desktop-mac-dashboard"
            :items="agentDesktopItems"
            :rules="[requiredField]"
            label="Choose Agent Desktop"
            :hint="getAgentDesktopWarning"
            persistent-hint
          />
          <CopyToClipboard v-if="isEdit" :disabled="disableCopyToClipboard" :data="getAgentDesktopUrl()" message="Agent Desktop URL copied to clipboard!" />
        </v-col>
      </v-row>
      <v-col cols="12">
        <template>
          <v-row justify="center">
            <v-expansion-panels class="mt-6 mb-5" accordion>
              <v-expansion-panel :key="'data-options-' + editProfile.interface.uuid">
                <v-expansion-panel-header>
                  <span>
                    <v-icon color="primary">mdi-code-json</v-icon>
                    <span class="ml-4">Properties</span>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <JsonEditorVue
                    v-model="editProfile.interface.properties"
                    :readOnly="!hasRequiredRole($store.getters.getRoles.ADMIN)"
                    :mainMenuBar="false"
                    mode="text"
                    style="height: calc(100vh - 450px) !important"
                    :class="isDarkMode ? 'jse-theme-dark' : ''"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </template>
      </v-col>

      <v-row v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)">
        <!-- Buttons -->
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="saveProfile"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isEdit && form.submitLoading" @click.prevent="deleteProfile">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import spacing from "../../helpers/spacing";
import EventBus from "@/event-bus";
import merge from "deepmerge";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import CopyToClipboard from "../../components/other/CopyToClipboard";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "ProfileForm",
  components: {
    ConfirmDialog,
    CopyToClipboard,
  },
  props: ["profilesList", "profileDetail", "queuesList", "agentDesktopList"],
  data() {
    return {
      disableCopyToClipboard: false,
      showWarning: false,
      showConfirm: false,
      deleteMessage: "Profile " + this.profileDetail.name + " will be deleted!",

      isEdit: this.profileDetail.name !== "",

      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },

      editProfile: {
        name: "",
        routing: {
          concurrentTask: 1,
          concurrentWebcallbackTask: 0,
          concurrentEmailTask: 0,
          queues: [],
        },
        interface: {
          uuid: "",
          properties: {},
        },
      },
    };
  },
  computed: {
    ...spacing,
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    getAgentDesktopWarning() {
      let existsDefault = false;
      let fallbackType = "";
      this.agentDesktopList.forEach((agentDesktop) => {
        if (agentDesktop.default) {
          existsDefault = true;
        }
      });
      if (existsDefault) {
        fallbackType = "default";
      } else {
        fallbackType = "system";
      }
      if (this.showWarning) {
        return `The associated Agent Desktop is no available. You can continue to use the ${fallbackType} one`;
      }
      return "";
    },
    queueListItems() {
      let result = [];
      result = this.queuesList.map((queue) => {
        return { text: queue.name, value: queue.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    agentDesktopItems() {
      let result = [];
      const agentDesktopDefault = this.agentDesktopList.find((agentDesktop) => agentDesktop.default);
      result = this.agentDesktopList.map((agentDesktop) => {
        return { text: agentDesktop.name, value: agentDesktop.uuid };
      });
      if (agentDesktopDefault) {
        result = [{ text: `Default (${agentDesktopDefault.name})`, value: "default" }].concat(result);
      } else {
        result = [{ text: "System", value: "system" }].concat(result);
      }
      return result.sort((a, b) =>
        a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase() ? 1 : b.text.toLocaleLowerCase() > a.text.toLocaleLowerCase() ? -1 : 0,
      );
    },
    getSelectedQueue: {
      get() {
        let queuesList = this.queuesList;
        let profileQueues = this.profileDetail.routing.queues;

        let ownsQueue = false;
        if (profileQueues) {
          for (const queue of queuesList) {
            if (profileQueues.includes(queue.eri)) {
              ownsQueue = true;
              break;
            }
          }
        }

        if (ownsQueue) {
          return this.editProfile.routing.queues;
        } else {
          return [];
        }
      },
      set(val) {
        this.editProfile.routing.queues = val;
      },
    },
    getSelectedAgentDesktop: {
      get() {
        if (this.editProfile.interface.uuid) {
          return this.editProfile.interface.uuid;
        } else {
          let existsDefault = false;
          this.agentDesktopList.forEach((agentDesktop) => {
            if (agentDesktop.default) {
              existsDefault = true;
            }
          });
          if (existsDefault) {
            return "default";
          } else {
            return "system";
          }
        }
      },
      set(val) {
        this.disableCopyToClipboard = true;
        this.showWarning = false;
        this.editProfile.interface.uuid = val;
      },
    },
  },
  async mounted() {
    this.editProfile = merge(this.editProfile, this.profileDetail);
    this.$refs.createProfileForm.resetValidation();
    // //se non la trovo, devo mostrare un warning che mi dica che è stata cancellata e che bisogna utilizzare quella di default
    const existAgentDesktop = this.agentDesktopList.find((agentDesktop) => agentDesktop.uuid === this.editProfile.interface.uuid);
    this.showWarning = !existAgentDesktop && this.editProfile.interface.uuid;
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    getAgentDesktopUrl() {
      const agentDesktopUuid = this.getSelectedAgentDesktop;
      const company = this.$store.state.loginResponse.params.customerProfile.selectedCompany;
      const serverConfigs = this.$store.state.loginResponse.params.serverConfigurations;
      const base = serverConfigs.agentDesktopUrl || this.$store.state.loginResponse.params.serverConfigurations.publicUrl;
      /*2
        Se la gui selezionata esiste
          Se è un uuid --> ritorno l'url a quella gui
          Se è default --> recupero la gui default e ritorno l'url che punta alla default
          Se è system --> ritorno l'url a quella di sistema
        Se la gui selezionata non esiste
          Controllo se c'è una default --> recupero e ritorno l'url che punta alla default
          Se non c'è la default --> siamo nel caso della system -> ritorno l'url a quella di sistema
      */

      //recupero, nel caso mi serve, la default tra quelle in elenco, se presente
      const defaultAgentDesktop = this.agentDesktopList.find((agentDesktop) => agentDesktop.default);
      const existAgentDesktop = this.agentDesktopList.find((agentDesktop) => agentDesktop.uuid === this.editProfile.interface.uuid);
      if (existAgentDesktop) {
        return serverConfigs?.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/" + agentDesktopUuid + "/#/login";
      } else {
        if (defaultAgentDesktop) {
          return serverConfigs?.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/" + defaultAgentDesktop.uuid + "/#/login";
        } else {
          return serverConfigs?.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/#/login";
        }
      }
    },
    async saveProfile() {
      try {
        this.form.submitLoading = true;
        //sia in save che update, se uno seleziona default o system allora non devo mettere lo uuid nell'oggetto interface
        if (this.editProfile.interface.uuid === "system" || this.editProfile.interface.uuid === "default") {
          delete this.editProfile.interface.uuid;
        }
        if (!this.editProfile.interface.properties) {
          this.editProfile.interface.properties = "{}";
        }
        if (typeof this.editProfile.interface.properties === "string") {
          this.editProfile.interface.properties = JSON.parse(this.editProfile.interface.properties);
        }
        if (this.isEdit) {
          const result = await this.$http.put("/human-agents/profile/" + this.editProfile.uuid, this.editProfile);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, {
            editProfile: result.data,
            message: "Profile " + this.editProfile.name + " modified!",
          });
        } else {
          const result = await this.$http.post("/human-agents/profile", this.editProfile);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, {
            editProfile: result.data,
            message: "Profile " + this.editProfile.name + " created!",
          });
        }
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.EDIT_PROFILE_FAIL, {
          editProfile: this.editProfile,
          message: "Error while saving Profile " + this.editProfile.name,
        });
      } finally {
        this.form.submitLoading = false;
      }
    },
    deleteProfile() {
      if (this.profileDetail.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_PROFILE_CREATION);
      }
    },
    async onDeleteProfile() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/human-agents/profile/" + this.profileDetail.uuid);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_PROFILE_SUCCESS, {
          profile: this.profileDetail.uuid,
          message: "Profile " + this.profileDetail.name + " Deleted!",
        });
      } catch (e) {
        let message = "Fail to delete " + this.profileDetail.name;
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        EventBus.$emit(this.$store.getters.getEvents.DELETE_PROFILE_FAIL, {
          message: message,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
  },
};
</script>
<style>
.choose-agent-desktop-profile .v-text-field__details .v-messages__message {
  color: orange !important;
  font-weight: bold;
}
</style>
