<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <!-- Dialog per salvataggio criteria -->
    <v-dialog v-model="saveDialog" max-width="290" @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[simpleAlphaNumString]"
            :error-messages="errorNameMessage"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined :disabled="!newCriteriaName" block @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanels" :class="getMainRowMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-magnify
                </v-icon>Activity
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row class="my-1">
              <v-col cols="2" offset="10">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn-toggle v-model="conditionType" active-class="primary" class="white--text" mandatory v-on="on">
                      <v-btn small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                        <span>and</span>
                      </v-btn>
                      <v-btn small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                        <span>or</span>
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                  <span>Select the operator for your filters</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-form v-model="isFormValid">
              <v-row v-for="(criteria, index) in searchCriteriaQuery" :key="index" align="center" class="py-0 my-0">
                <v-col cols="3">
                  <v-autocomplete
                    v-model="searchCriteriaQuery[index].field"
                    :rules="[requiredField]"
                    label="Activity"
                    prepend-inner-icon="mdi-filter"
                    :items="filterOnList"
                    @change="searchCriteriaQuery[index].value = ''"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select v-model="searchCriteriaQuery[index].operator" :rules="[requiredField]" label="With Value" :items="operatorList" />
                </v-col>
                <v-col cols="5">
                  <v-autocomplete
                    v-if="searchCriteriaQuery[index].field !== 'request_path'"
                    v-model="searchCriteriaQuery[index].value"
                    :rules="searchCriteriaQuery.length > 1 ? [requiredField] : []"
                    :label="searchCriteriaQuery[index].field === 'username' ? 'Username' : 'Activity'"
                    prepend-inner-icon="mdi-filter"
                    :items="searchCriteriaQuery[index].field === 'username' ? getUserList : getValueList"
                    clearable
                  />
                  <v-text-field
                    v-else
                    v-model.trim="searchCriteriaQuery[index].value"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-keyboard"
                    label="Value"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="1" class="d-flex justify-start">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        class="mr-2"
                        :disabled="!searchCriteriaQuery[index].value"
                        depressed
                        x-small
                        fab
                        color="primary"
                        v-on="on"
                        @click="add"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add more filters</span>
                  </v-tooltip>
                  <v-btn
                    v-if="index !== 0"
                    class="mx-2"
                    depressed
                    x-small
                    fab
                    color="warning"
                    @click="remove(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Riga per la gestione delle date -->
              <TimePickerRangeReport
                ref="timerPickerRow"
                @switchEnableDatePicker="enableDatePicker = $event"
                @setDateStart="updateDateTimeStart($event)"
                @setDateEnd="updateDateTimeEnd($event)"
              />
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn :class="getButtonMargin" :disabled="isLoadingData || !isFormValid || searchButtonDisabled" color="success" @click="saveDialog = true">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  <span>Save</span>
                </v-btn>
                <v-btn
                  ref="searchBtn"
                  :loading="isLoadingData"
                  :class="getButtonMargin"
                  :disabled="searchButtonDisabled"
                  color="primary"
                  @click="searchData"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  <span>Search</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <SearchBar :searchString="searchStringCriteria" @input="searchStringCriteria = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length === 0" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Date Start
                        </th>
                        <th class="text-left">
                          Date End
                        </th>
                        <th />
                        <th class="text-left">
                          Filters
                        </th>
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="key">
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.name }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.start_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.end_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td />
                        <td>
                          <v-tooltip left color="primary">
                            <template #activator="{ on }">
                              <v-btn icon text v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <!-- <span v-text="getDescription(item.name)"></span> -->
                            <div v-for="(line, index) in item.filters" :key="index">
                              {{ line.filter }} {{ line.operator }}
                              {{ line.value }}
                            </div>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-btn text icon @click="deleteCriteria(item.name)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ActivityDatatable :dataset="queryResult" :dateStart="dateStartDetail" :dateEnd="dateEndDetail" @ErrorShowDetail="errorShowDetail" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ActivityDatatable from "../../components/advanced/ActivityDatatable";
import fieldValidators from "../../helpers/fieldValidators";
import exportFile from "../../helpers/exportFile";
import moment from "moment";
import merge from "deepmerge";
import EventBus from "../../event-bus";
import TimePickerRangeReport from "../../components/other/timePickerRangeReport";

export default {
  name: "Activity",
  components: {
    SearchBar,
    ResultSnackbar,
    ActivityDatatable,
    TimePickerRangeReport,
  },
  data() {
    return {
      searchCriterias: {},
      searchStringCriteria: "",
      userList: {},
      reportActivityList: [],
      isFormValid: false,
      conditionType: 0,
      expansionPanels: 0,
      operatorList: [
        {
          text: "Contains",
          value: "like",
        },
        {
          text: "Equals To",
          value: "=",
        },
      ],
      searchCriteriaQuery: [
        {
          field: "action",
          operator: "like",
          value: null,
        },
      ],
      filterOnList: [
        {
          text: "Action",
          value: "action",
        },
        {
          text: "Username",
          value: "username",
        },
        {
          text: "Entity",
          value: "request_path",
        },
      ],
      enableDatePicker: true,
      dialogDateTimeStart: false,
      dialogDateTimeEnd: false,
      dateStartChose: false,
      dateEndChose: false,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      timeStart: "00:00",
      timeEnd: "23:59",
      dateStartDetail: null,
      dateEndDetail: null,
      backupDate: {
        date: null,
        time: null,
      },
      queryResult: [],
      isLoadingData: false,
      saveDialog: false,
      newCriteriaName: "",
      errorNameMessage: "",
      forceRerender: 0,
    };
  },
  computed: {
    ...spacing,
    getDateStart() {
      return moment(this.dateStart).format("DD/MM/YYYY") + " " + this.timeStart + ":00";
    },
    getDateEnd() {
      return moment(this.dateEnd).format("DD/MM/YYYY") + " " + this.timeEnd + ":59";
    },
    getMinTime() {
      return this.dateStart === this.dateEnd ? this.timeStart : "00:00";
    },
    getMaxTime() {
      return this.dateStart === this.dateEnd ? this.timeStart : "23:59";
    },
    getValueList() {
      let list = this.reportActivityList;
      list = list.sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return list;
    },
    getUserList() {
      let list = [];
      for (let user of this.userList) {
        list.push(user.authentication.credentials.username);
      }
      return list.sort();
    },
    getSearchCriterias() {
      this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name && criteria.name.toLowerCase().indexOf(this.searchStringCriteria.toLowerCase()) !== -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
    searchButtonDisabled() {
      this.searchCriteriaQuery;
      //questo if permette le ricerche senza filtro
      if (this.searchCriteriaQuery.length == 1 && !this.searchCriteriaQuery[0].value) return false;
      if (!this.isFormValid && this.searchCriteriaQuery.length > 1) {
        return true;
      } else if (this.searchCriteriaQuery.length == 1) {
        if (
          ((this.searchCriteriaQuery[0].value == null || this.searchCriteriaQuery[0].value == "") &&
            (this.searchCriteriaQuery[0].operator == null || this.searchCriteriaQuery[0].operator == "none") &&
            (this.searchCriteriaQuery[0].field === null || this.searchCriteriaQuery[0].field === "none")) ||
          this.isFormValid
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  async mounted() {
    try {
      let userList = [];
      if (this.$store.getters.userIsRoot) {
        userList = await this.$http.get("/user-all");
      } else {
        userList = await this.$http.get("/user");
      }
      this.userList = userList.data;
      try {
        this.searchCriterias = (await this.$http.get("/search-criteria/ACTIVITY_DETAIL")).data;
      } catch (e) {
        this.searchCriterias = [];
      }
      const reportActivityList = await this.$http.get("/report-activity-action");
      for (let activity in reportActivityList.data.reportActivity) {
        let opt = {
          text: activity,
          value: reportActivityList.data.reportActivity[activity],
        };
        this.reportActivityList.push(opt);
      }
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail load data: ${e.message}`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    ...exportFile,
    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    add() {
      if (this.isFormValid) {
        this.searchCriteriaQuery.push({
          filter: "none",
          operator: "like",
          value: null,
        });
      }
    },
    remove(index) {
      this.searchCriteriaQuery.splice(index, 1);
    },
    backupDateStart() {
      this.backupDate.date = this.dateStart;
      this.backupDate.time = this.timeStart;
    },
    restoreDateStart() {
      this.dialogDateTimeStart = false;
      this.dateStartChose = false;
      this.dateStart = this.backupDate.date;
      this.timeStart = this.backupDate.time;
    },
    backupDateEnd() {
      this.backupDate.date = this.dateEnd;
      this.backupDate.time = this.timeEnd;
    },
    restoreDateEnd() {
      this.dialogDateTimeEnd = false;
      this.dateEndChose = false;
      this.dateEnd = this.backupDate.date;
      this.timeEnd = this.backupDate.time;
    },
    confirmDateTimeStart() {
      this.dateStartChose = false;
      this.dialogDateTimeStart = false;
    },
    confirmDateTimeEnd() {
      this.dateEndChose = false;
      this.dialogDateTimeEnd = false;
    },
    getDate(data) {
      return moment(data, "DD/MM/YYYY HH:mm:ss").utc().toDate();
    },

    checkIfSearchCriteriaExists(val) {
      return !!this.searchCriterias[btoa(val)];
    },
    async saveCurrentCriteria() {
      let searchCriteriaConfig = {
        end_date: this.enableDatePicker ? this.getDateEnd : moment().utc().format("DD/MM/YYYY"),
        start_date: this.enableDatePicker ? this.getDateStart : "01/01/1970",
        filterOperator: this.conditionType == 0 ? "AND" : "OR",
        filters: this.searchCriteriaQuery,
        isDateEnabled: this.enableDatePicker,
        name: this.newCriteriaName,
        type: "ACTIVITY_DETAIL",
      };
      // controllo che il nome non esista già
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
        this.errorNameMessage = "Name Already Exists";
      } else {
        this.errorNameMessage = "";
        let newSearchCriteria = {
          searchCriteriaIdentifier: btoa(this.newCriteriaName),
          searchCriteriaConfig: searchCriteriaConfig,
        };
        try {
          const result = await this.$http.post("/search-criteria", newSearchCriteria);
          this.saveDialog = false;
          if (result.data.result) {
            this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
            this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
            this.forceRerender++;
          } else {
            this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
          }
          this.newCriteriaName = "";
        } catch {
          this.saveDialog = false;
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
        }
      }
    },
    async deleteCriteria(name) {
      let key = btoa(name);
      try {
        await this.$http.delete(`/search-criteria/${key}`);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.$refs.resultSnackbar.showSuccess("Search Criteria " + name + " Deleted!");
      } catch (e) {
        this.$refs.resultSnackbar.showError(`Fail to delete Search Criteria: ${name}`);
      }
    },
    getValue(name) {
      this.expansionPanels = 0;
      this.searchCriteriaQuery = [];
      for (let i in this.searchCriterias[btoa(name)].filters) {
        let filter = merge({}, this.searchCriterias[btoa(name)].filters[i]);
        this.searchCriteriaQuery.push(filter);
      }
      this.conditionType = this.searchCriterias[btoa(name)].filterOperator === "AND" ? 0 : 1;
      if (this.searchCriterias[btoa(name)].isDateEnabled) {
        this.enableDatePicker = true;
        let start = this.searchCriterias[btoa(name)].start_date.split(" ");
        this.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (start[1]) {
          this.timeStart = start[1];
        } else {
          this.timeStart = "00:00:00";
        }

        let end = this.searchCriterias[btoa(name)].end_date.split(" ");
        this.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (end[1]) {
          this.timeEnd = end[1];
        } else {
          this.timeEnd = "23:59:59";
        }
      } else {
        this.enableDatePicker = false;
      }
      this.triggerSearch();
    },
    async searchData() {
      this.isLoadingData = true;
      const result = await this.makeRequest();
      if (result) {
        this.queryResult = result;
        this.expansionPanels = 2;
      }
      this.isLoadingData = false;
    },
    async makeRequest() {
      let params = {
        type: "ACTIVITY_DETAIL",
        start_date: this.enableDatePicker ? this.getDate(this.getDateStart) : moment("1970-01-01", "YYYY-MM-DD").toDate(),
        end_date: this.getDate(this.getDateEnd),
        filterOperator: this.conditionType === 0 ? "and" : "or",
      };
      params.filters = this.searchCriteriaQuery;
      this.dateStartDetail = params.start_date;
      this.dateEndDetail = params.end_date;
      try {
        const result = await this.$http.post("/report-activity", {
          params: params,
        });
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].created_at = moment(result.data[i].created_at).local().format("YYYY-MM-DD HH:mm:ss");
          result.data[i].closed_at = moment(result.data[i].closed_at).local().format("YYYY-MM-DD HH:mm:ss");
        }
        return result.data;
      } catch (e) {
        this.$refs.resultSnackbar.showError("Error Search Data: " + e.message);
        return false;
      }
    },
    triggerSearch() {
      setTimeout(() => {
        this.$refs["searchBtn"].$el.click();
      }, 1000);
    },
    updateDateTimeStart(event) {
      this.dateStart = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeStart = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    updateDateTimeEnd(event) {
      this.dateEnd = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.timeEnd = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    errorShowDetail() {
      this.$refs.resultSnackbar.showError(`Fail to load detail`);
    },
  },
};
</script>
<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}
</style>
