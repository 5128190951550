<template>
  <v-container fluid class="mt-10 flowConfigurationPage">
    <ResultSnackbar ref="resultSnackbar" />
    <v-row :class="getCreationFormMargin">
      <v-col cols="12">
        <v-form v-model="form.isValid">
          <v-expansion-panels v-model="panel" focusable>
            <v-expansion-panel v-for="(section, i) in sections" :key="i" @change="updateMainScrollbar">
              <v-expansion-panel-header>
                <span>
                  <v-icon v-show="section === 'Inactivity' && showAlert" class="mr-1 mt-n1" color="warning">warning </v-icon>
                  {{ section }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <General v-if="section === 'General'" :configuration="configuration" :flowTargets="flowTargets" />
                <Errors
                  v-if="section === 'Errors'"
                  :configuration="configuration"
                  :flowTargets="flowTargets"
                  :humanAgentsQueues="humanAgentsQueues"
                  :humanAgentsProfiles="humanAgentsProfiles"
                  :humanAgentsAgents="humanAgentsAgents"
                />
                <Inactivity v-if="section === 'Inactivity'" :configuration="configuration" :flowTargets="flowTargets" />
                <InputProcessorsConfiguration v-if="section === 'Input Processors'" :configuration="configuration" :inputProcessors="inputProcessor" />
                <SemanticHooks
                  v-if="section === 'Semantic Hooks'"
                  :configuration="configuration"
                  :semanticEngines="semanticEngines"
                  :flowTargets="flowTargets"
                  :allBots="allBots"
                />
                <SystemJump v-if="section === 'System Jump'" :configuration="configuration" :flowTargets="flowTargets" />
                <ConditionalJump v-if="section === 'Conditional Jump'" :configuration="configuration" :flowTargets="flowTargets" />
                <MenuConfigurations v-if="section === 'Menu Node Configurations'" :configuration="configuration" />
                <GlobalVariables v-if="section === 'Global Variables'" ref="globalVariablesObj" :configuration="configuration" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
    </v-row>
    <v-row :class="getCreationFormMargin">
      <v-col cols="12">
        <v-btn color="success" :loading="form.submitLoading" :disabled="!form.isValid" @click.prevent="saveFlowConfiguration">
          <v-icon left>
            mdi-floppy
          </v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import General from "../../components/design/FlowConfigurations/General";
import Errors from "../../components/design/FlowConfigurations/Errors";
import Inactivity from "../../components/design/FlowConfigurations/Inactivity";
import InputProcessorsConfiguration from "../../components/design/FlowConfigurations/InputProcessorsConfiguration";
import SemanticHooks from "../../components/design/FlowConfigurations/SemanticHooksConfiguration";
import SystemJump from "../../components/design/FlowConfigurations/SystemJump";
import ConditionalJump from "../../components/design/FlowConfigurations/ConditionalJump";
import MenuConfigurations from "../../components/design/FlowConfigurations/MenuConfigurations";
import GlobalVariables from "../../components/design/FlowConfigurations/GlobalVariables";
import fieldValidators from "../../helpers/fieldValidators";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";
import sanitizeConfiguration from "../../helpers/sanitizeConfiguration";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";

export default {
  name: "FlowConfigurations",
  components: {
    General,
    Errors,
    Inactivity,
    InputProcessorsConfiguration,
    SemanticHooks,
    SystemJump,
    ResultSnackbar,
    ConditionalJump,
    MenuConfigurations,
    GlobalVariables,
  },
  data() {
    return {
      panel: null,
      sections: [
        "General",
        "Errors",
        "Inactivity",
        "Input Processors",
        "Semantic Hooks",
        "System Jump",
        "Conditional Jump",
        "Menu Node Configurations",
        "Global Variables",
      ],
      form: {
        isValid: false,
        submitLoading: false,
      },
      configuration: merge({}, this.$store.getters.getDefaultFlowConfiguration),
      semanticEngines: [],
      inputProcessor: [],
      flowTargets: {}, //Contiene semantic hooks, hooks di ogni flusso, bot_if e isVisualIVRCompliant
      allBots: {},
      showAlert: false,
      waTemplates: {},
      humanAgentsQueues: [],
      humanAgentsProfiles: [],
      humanAgentsAgents: [],
    };
  },
  computed: {
    ...spacing,
  },
  async mounted() {
    const semanticEngineResponse = (await this.loadResource("/semantic-engine")) || [];
    const inputProcessorResponse = (await this.loadResource("/input-processor")) || [];
    const flowTargetResponse = (await this.loadResource("/flows-targets-hooks")) || {};
    const botsResponse = (await this.loadResource("/bot")) || {};
    const flowConfigurationResponse = (await this.loadResource("/flow-configurations")) || {};
    const whatsappTemplates = (await this.loadResource("/whatsapp-template")) || {};
    await this.$store.dispatch("UpdateWhatsappTemplate", whatsappTemplates);
    const humanAgentsQueueResponse = (await this.loadResource("/human-agents/queue")) || [];
    const humanAgentsProfileResponse = (await this.loadResource("/human-agents/profile")) || [];
    const humanAgentsAgentResponse = (await this.loadResource("/human-agents/agent")) || [];
    if (flowConfigurationResponse) {
      let sanitizedConf = sanitizeConfiguration.sanitizeMultilanguageConfig(flowConfigurationResponse);
      //effettuo la sanificazione per le conf che non hanno il post close conversation
      sanitizedConf = sanitizeConfiguration.sanitizePostCloseConversation(flowConfigurationResponse);
      //effettuo sanificazione survey in inactivity perchè inglobata dallo stay idle
      sanitizedConf = sanitizeConfiguration.sanitizeInactivityProactivityType(flowConfigurationResponse);
      this.configuration = merge(this.configuration, sanitizedConf, {
        arrayMerge: this.mergeMultilanguageField,
      }); //Oggettone completo delle conf

      //sanifico la care window, per forza dopo aver mergiato le conf arrivate dal backend con quelle di default
      this.configuration = sanitizeConfiguration.sanitizeInactivityCareWindow(this.configuration);
      if (!this.configuration.error.jump_to) {
        this.configuration.error.jump_to = {
          flow: "",
          version: "",
          hook: "begin",
        };
      }
    }
    this.semanticEngines = semanticEngineResponse; //Elenco di tutti gli engine
    this.inputProcessor = inputProcessorResponse; //Input processor raggruppati per tipologia
    this.flowTargets = flowTargetResponse; //TUtti i possibili target di un jump
    this.allBots = botsResponse;
    if (this.configuration.inactivity.proactivityType === "jump") {
      this.checkIfJumpIsValid(this.configuration.inactivity.jump_to);
    }
    this.humanAgentsQueues = humanAgentsQueueResponse;
    this.humanAgentsProfiles = humanAgentsProfileResponse;
    this.humanAgentsAgents = humanAgentsAgentResponse;
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
  },
  methods: {
    ...fieldValidators,
    async loadResource(resource) {
      try {
        const response = await this.$http.get(resource);
        return response.data;
      } catch (e) {
        return null;
      }
    },
    checkIfJumpIsValid(jump) {
      let check = true;
      if (
        jump.flow &&
        jump.version &&
        this.flowTargets &&
        this.flowTargets[jump.flow] &&
        this.flowTargets[jump.flow][jump.version] &&
        this.flowTargets[jump.flow][jump.version].hooks
      ) {
        check = this.flowTargets[jump.flow][jump.version].hooks.includes(jump.hook);
      }
      if (check) {
        this.panel = 2;
        this.showAlert = true;
        this.form.isValid = false;
      }
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    mergeMultilanguageField(target, source) {
      return source;
    },
    async saveFlowConfiguration() {
      if (this.form.isValid) {
        this.showAlert = false;
        if (this.$refs["globalVariablesObj"] && this.$refs["globalVariablesObj"][0]) {
          this.configuration.global_variables = this.$refs["globalVariablesObj"][0].getUpdatedGlobalVariables();
        }
        try {
          await this.$http.post("/flow-configurations", { ...this.configuration });
          this.$refs.resultSnackbar.showSuccess("Flow configurations saved!");
        } catch (e) {
          this.$refs.resultSnackbar.showError("Flow configurations has not been saved");
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
  },
};
</script>
<style>
.flowConfigurationPage .overlayMultilanguage div.v-overlay__content {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
}
.flowConfigurationPage .overlayMultilanguage div.v-overlay__content > .v-card {
  height: 100%;
}
.flowConfigurationPage .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 65%;
}
.flowConfigurationPage .inactivityCard .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 55% !important;
}
.flowConfigurationPage .semanticHookCard .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 65% !important;
}
.flowConfigurationPage .overlayMultilanguage div.v-overlay__content .listOfMessagesScrollBar {
  height: 90%;
}
</style>
