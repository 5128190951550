<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Input Processor"
      @input="
        searchString = $event;
        forceRerender++;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getInputProcessorsList.length == 0 && !newProcessor && searchString == ''" cols="12">
        <v-alert type="warning">
          No Input Processors configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getInputProcessorsList.length == 0 && !newProcessor && searchString != ''" cols="12">
        <v-alert type="warning">
          No Input Processor match your search
        </v-alert>
      </v-col>
      <!-- riga per la creazione di un nuovo elemento -->
      <v-expansion-panels v-if="newProcessor" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <img width="50" height="50" alt="Avatar" :src="newProcessor.image" :style="getAvatarImageMaxSize">
              </v-col>
              <v-col cols="8">
                New
                {{ $store.getters.getInputProcessorByType(newProcessor.type).label }}
                Input Processor
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <InputProcessorForm :processorsList="processors" :processor="newProcessor" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- riga per la visualizzazione -->
      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(processor, name) in getInputProcessorsList" :key="name" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="4" sm="2" md="1">
                  <img width="50" height="50" alt="Avatar" :src="$store.getters.getInputProcessorByType(processor.type).image" :style="getAvatarImageMaxSize">
                </v-col>

                <v-col class="text-truncate" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ processor.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-xs-only text-no-wrap" cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ $store.getters.getInputProcessorByType(processor.type).label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="hidden-xs-only text-no-wrap" cols="2" align="center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="processor.type == 'google_speech_recognition'">
                        Input Language
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        Target Language
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <flag :iso="getFlagIsoCodeFromLanguageCode(processor.details.languageCode)" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down" cols="2" align="center">
                  <v-list-item v-show="processor.type != 'google_speech_recognition'" dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Translation Enabled</v-list-item-subtitle>
                      <v-list-item-icon class="ma-0 justify-center">
                        <v-btn class="ma-0 ml-1" :color="processor.details.enableTranslator ? 'success' : 'error'" x-small text depressed>
                          <v-icon v-if="processor.details.enableTranslator" small>
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else small>
                            mdi-close-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down" cols="2" align="center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="processor.lastUpdate">
                        {{ processor.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <inputProcessorForm :key="forceRerender + name + '_ip'" :processor="processor" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <AddNewItemButton :ripple="false" name="Input Processor" @addNewItem="showCreationMenu" />
    <v-menu v-model="addMenuProps.showMenu" :position-x="addMenuProps.x" :position-y="addMenuProps.y" absolute offset-y>
      <v-list class="inputProcessorsList">
        <v-list-item v-for="(inputProcessor, index) in $store.getters.getInputProcessorTypes" :key="index" @click="addInputProcessor(inputProcessor)">
          <v-list-item-avatar size="23px" :color="$vuetify.theme.dark ? '#313234' : '#FAFAFA'">
            <img alt="Avatar" :src="inputProcessor.image" style="max-width:20px;max-height:20px;'">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ inputProcessor.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import InputProcessorForm from "../../components/tools/InputProcessorForm";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";

export default {
  name: "InputProcessors",
  components: {
    ResultSnackbar,
    AddNewItemButton,
    SearchBar,
    InputProcessorForm,
  },
  data() {
    return {
      addMenuProps: {
        showMenu: false,
        x: 0,
        y: 0,
      },
      newProcessor: null,
      processors: [],
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      searchString: "",
      showWarning: false,
    };
  },
  computed: {
    ...spacing,
    getInputProcessorsList: function () {
      this.forceRerender;
      let result = [].concat(this.processors);
      return (
        result
          //Filtro per la ricerca
          .filter((processor) => processor.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR, this.onInputProcessorUpdate);
    EventBus.$on(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR, this.deleteInputProcessor);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_INPUT_PROCESSOR_CREATION, this.cancelInputProcessorCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR_FAIL, this.deleteInputProcessorFail);
    EventBus.$on(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR_FAIL, this.onInputProcessorFailedUpdate);
    //Load Data...
    try {
      const result = await this.$http.get("/input-processor");
      this.processors = result.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR, this.onInputProcessorUpdate);
    EventBus.$off(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR, this.deleteInputProcessor);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_INPUT_PROCESSOR_CREATION, this.cancelInputProcessorCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR_FAIL, this.deleteInputProcessorFail);
    EventBus.$off(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR_FAIL, this.onInputProcessorFailedUpdate);
  },
  methods: {
    ...scroller,
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    showCreationMenu(e) {
      e.preventDefault();
      this.addMenuProps.showMenu = false;
      this.addMenuProps.x = e.clientX;
      this.addMenuProps.y = e.clientY;
      this.$nextTick(() => {
        this.addMenuProps.showMenu = true;
      });
    },
    onInputProcessorUpdate: function (obj) {
      let id = -1;
      this.processors.forEach((processor, index) => {
        if (processor.name === obj.processor.name) {
          id = index;
        }
      });

      if (id >= 0) {
        this.processors[id] = merge({}, obj.processor);
        this.$refs.resultSnackbar.showSuccess("InputProcessor " + obj.processor.name + " updated!");
      } else {
        this.processors.push(obj.processor);
        this.$refs.resultSnackbar.showSuccess("InputProcessor " + obj.processor.name + " added!");
        this.closeAllPanels();
      }
      this.forceRerender++;
    },
    onInputProcessorFailedUpdate: function (obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    cancelInputProcessorCreation: function () {
      this.expansionPanelCreation = null;
      this.newProcessor = null;
    },
    closeAllPanels: function () {
      this.cancelInputProcessorCreation();
      this.expansionPanel = null;
    },
    addInputProcessor: function (processorConfig) {
      //Se il primo elemento non è in edit
      if (!this.newProcessor) {
        this.newProcessor = {
          image: processorConfig.image,
          type: processorConfig.type,
          name: "",
          details: {},
          enable: true,
        };
        switch (processorConfig.type) {
          case "google_speech_recognition":
            this.newProcessor.details = {
              authToken: "",
              sampleRateHertz: "16000",
              apiURL: processorConfig.api,
              languageCode: "en-GB",
            };
            break;
          case "google_translate":
            this.newProcessor.details = {
              enableTranslator: true,
              authToken: "",
              apiURL: processorConfig.api,
              languageCode: "en",
            };
            break;
          case "aws_translate":
            this.newProcessor.details = {
              enableTranslator: true,
              accessKeyID: "",
              secretAccessKey: "",
              region: "us-east-1",
              languageCode: "en",
            };
            break;
        }
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    deleteInputProcessor: function (obj) {
      let selectedIndex = -1;
      this.processors.forEach((processor, index) => {
        if (processor.name === obj.name) {
          selectedIndex = index;
        }
      });
      if (selectedIndex >= 0) {
        this.processors.splice(selectedIndex, 1);
        this.$refs.resultSnackbar.showSuccess("Processor " + obj.name + " deleted!");
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRerender++;
        }, 100);
      }
    },
    deleteInputProcessorFail: function (obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    getFlagIsoCodeFromLanguageCode: function (code) {
      let flagCode = code;
      if (flagCode.indexOf("-") != -1) {
        flagCode = flagCode.split("-")[0];
      }
      //barbatrucco per la lingua inglese che ha più di un codice, lo devo convertire da standard BCP47 a ISO per la libreria FLAG
      switch (flagCode) {
        case "sq":
          flagCode = "al";
          break;
        case "hy":
          flagCode = "am";
          break;
        case "en":
          flagCode = "gb";
          break;
        case "da":
          flagCode = "dk";
          break;
        case "cs":
          flagCode = "cz";
          break;
        case "ar":
          flagCode = "sa";
          break;
        case "yue":
        case "cmn":
        case "zh":
          flagCode = "cn";
          break;
        case "el":
          flagCode = "gr";
          break;
        case "he":
          flagCode = "il";
          break;
        case "ko":
          flagCode = "kr";
          break;
      }
      return flagCode;
    },
  },
};
</script>

<style>
.inputProcessorsList .v-list-item__title {
  line-height: 1.3rem !important;
}
</style>
