<template>
  <v-container id="shortcodesPage" fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Shortcodes"
      @input="
        searchString = $event;
        forceRender++;
        pageNumber = 1;
      "
    />

    <v-col class="px-7" cols="12">
      <v-alert type="info" icon="mdi-information" color="primary" class="mx-1">
        <v-row class="no-gutters">
          <v-col cols="12">
            Remember that in shortcode messages you can use all the variables contained within the flows to make the messages dynamic.
          </v-col>
        </v-row>
        <v-row class="no-gutters">
          <v-col cols="12">
            Ex: Hi, my name is %agentName%, how can i help you?
          </v-col>
        </v-row>
      </v-alert>
    </v-col>

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getShortcodesList.length == 0 && !newConfig && searchString == ''" cols="12">
        <v-alert type="warning">
          No Shortcodes configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getShortcodesList.length == 0 && !newConfig && searchString != ''" cols="12">
        <v-alert type="warning">
          No Shortcodes match your search
        </v-alert>
      </v-col>
      <!-- Panel for new item -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Shortcode
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <ShortcodeForm :queuesList="queues" :shortcodesList="shortcodesList" :shortcodeSetting="newConfig" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel to list configuration -->

      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(shortcodeSetting, id) in getShortcodesList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col class="text-no-wrap" cols="2">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-list-item dense v-on="on">
                        <v-list-item-content>
                          <v-list-item-subtitle>Label</v-list-item-subtitle>
                          <v-list-item-title>
                            {{ shortcodeSetting.label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ shortcodeSetting.label }}</span>
                  </v-tooltip>
                </v-col>

                <v-col class="text-truncate" style="max-width: 600px" cols="7" md="6">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Content</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ shortcodeSetting.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-center hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Available on</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip
                          v-if="shortcodeSetting.availability && shortcodeSetting.availability.length > 0"
                          class="queue-global-chip"
                          color="success"
                          small
                        >
                          {{ shortcodeSetting.availability.length }}
                        </v-chip>
                        <v-chip v-else color="primary queue-number-chip" small class="text-uppercase">
                          Global
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-md-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="shortcodeSetting.lastUpdate">
                        {{ shortcodeSetting.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <ShortcodeForm :key="forceRender + id + '_sc'" :queuesList="queues" :shortcodesList="shortcodesList" :shortcodeSetting="shortcodeSetting" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredShortcodesList.length > 1"
      :items="filtredShortcodesList.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton v-if="!isCCMDisableProfile" name="Shortcode" @addNewItem="addNewConfig" />
  </v-container>
</template>

<script>
import Pagination from "../../components/other/Pagination";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import ShortcodeForm from "../../components/humanAgents/ShortcodeForm";

export default {
  name: "Shortcode",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    ShortcodeForm,
    Pagination,
  },
  data() {
    return {
      expansionPanelCreation: null,
      expansionPanel: null,
      shortcodesList: null,
      forceRender: 0,
      searchString: "",
      //Data bind per creazione nuova ocnfigurazione
      newConfig: null,
      showWarning: false,
      queues: [],
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
      filtredShortcodesList: [],
    };
  },
  computed: {
    ...spacing,
    getShortcodesList() {
      this.forceRender;
      let filterData = this.filterData();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    isCCMDisableProfile() {
      const user = this.$store.getters.getUser;
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.queues && this.queues.length === 0) {
        return true;
      }
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER && this.queues && this.queues.length === 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_SHORTCODES_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SHORTCODES, this.editShortcode);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SHORTCODES, this.deleteShortcode);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SHORTCODES_FAIL, this.editShortcodeFail);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SHORTCODES_FAIL, this.deleteShortcodeFail);
    //Load Configurations
    let response = null;
    try {
      response = await this.$http.get("/human-agents/queue");
      this.queues = response.data;
      this.queues = this.queues.sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });
    } finally {
      response = null;
    }
    try {
      const result = await this.$http.get("/human-agents/shortcode");
      this.shortcodesList = result.data;
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail to load Shortcodes`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_SHORTCODES_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SHORTCODES, this.editShortcode);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SHORTCODES, this.deleteShortcode);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SHORTCODES_FAIL, this.editShortcodeFail);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SHORTCODES_FAIL, this.deleteShortcodeFail);
  },
  methods: {
    ...scroller,
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewConfig() {
      if (!this.newConfig) {
        this.newConfig = {
          eri: null,
          label: "",
          content: "",
          availability: [],
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
    },
    editShortcode(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      this.closeAllPanels();
      this.shortcodesList[obj.editConfig.label] = obj.editConfig;
      this.forceRender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    editShortcodeFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteShortcode(obj) {
      if (this.shortcodesList[obj.label]) {
        delete this.shortcodesList[obj.label];
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRender++;
        }, 100);
      }
    },
    deleteShortcodeFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    filterData() {
      this.shortcodesList
        ? (this.filtredShortcodesList = Object.values(this.shortcodesList)
            //Filtro per la ricerca
            .filter(
              (shortcodes) =>
                shortcodes.label.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
                shortcodes.label.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
            )
            //Sorting per un campo
            .sort((a, b) => {
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }))
        : (this.filtredShortcodesList = []);
      return this.filtredShortcodesList;
    },
  },
};
</script>

<style>
#shortcodesPage .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#shortcodesPage .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
