<template>
  <v-container
    fluid
    class="mt-10"
  >
    <ResultSnackbar ref="resultSnackbar" />

    <v-form v-model="form.isValid">
      <v-row
        justify="center"
        :class="getMainRowMargin"
      >
        <v-expansion-panels focusable>
          <!-- server -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.server">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Server
              </span>
              <span v-else>Server</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>Public URLs</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.publicUrl"
                    label="Public HTTP URL"
                    autofocus
                    hint="This URL is the published URL of ConvyAI on internet/intranet. By default ConvyAI will use the local server address"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-switch
                    v-model="settings.server.enableLegacyTokens"
                    label="Enable Legacy Token authentication"
                    color="primary"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.throttleTimer"
                    label="Throttle Timer for messages"
                    type="number"
                    min="500"
                    color="primary"
                    hint="This timer, expressed in milliseconds, avoid message spamming from socials connectors"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.agentDesktopUrl"
                    label="Agent desktop base URL"
                    autofocus
                    hint="This URL is the public URL of ConvyAI Agent Desktop on internet/intranet. By default ConvyAI will use the public URL"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('server')"
                  />
                </v-col>
              </v-row>
              <v-subheader>HTTP Requests</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.http_timeout"
                    label="HTTP Timeout (ms)"
                    type="number"
                    hint="Default number of millisecond of timeout for every HTTP request perfomed in Convy. Default 10000"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.http_retry_delay"
                    label="HTTP Retry delay (ms)"
                    type="number"
                    hint="Delay between HTTP requests retry for social integrations and thyrd party system integrations. Default 1000"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.http_retry_attempt"
                    label="HTTP Retry Attempt"
                    type="number"
                    hint="Number of HTTP request retry if the remote HTTP service is not answering. Default 3"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.http_max_request_size_mb"
                    label="HTTP max request size in Mb"
                    type="number"
                    hint="Inbound HTTP maximum request size. Default 50Mb"
                    @change="onChangeSection('server')"
                  />
                </v-col>
              </v-row>
              <v-subheader>SOAP Requests</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.soap.timeout"
                    label="Timeout (ms)"
                    type="number"
                    hint="HTTP Timeout for the SOAP request. Default 15000"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.soap.replaceAttrRegExp"
                    label="Replace Attribute RegExp"
                    hint="Optional regular expression that will applied to each XML node of the soap request. Default null"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('server')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Javascript Evaluation Engine</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-select
                    v-model="settings.server.conversation.conditionalJumpEvaluationType"
                    label="Conditional Jump Evaluation Type"
                    type="number"
                    :items="evaluationTypes"
                    :return-object="false"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-switch
                    v-model="conditionalJumpNotUseStrict"
                    label="Conditional Jump enforce use strict"
                    color="primary"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-select
                    v-model="settings.server.conversation.setVariableEvaluationType"
                    label="Set Variable Evaluation Type"
                    type="number"
                    :items="evaluationTypes"
                    :return-object="false"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-switch
                    v-model="setVariableJumpNotUseStrict"
                    label="Set Variable enforce use strict"
                    color="primary"
                    @change="onChangeSection('server')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Cluster</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.waitForHazelcastPropagation"
                    label="Update entity/entities cluster propagation timeout"
                    type="number"
                    hint="It's the maximum timeout for update entity and update entities commands. Default 100 ms"
                    @change="onChangeSection('server')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-tooltip
                    bottom
                    max-width="250"
                  >
                    <template #activator="{ on }">
                      <v-switch
                        v-model="settings.server.enforceLogLevelSecurity"
                        label="Enforce Logs level for security"
                        color="primary"
                        v-on="on"
                        @change="onChangeSection('server')"
                      />
                    </template>
                    <span> Allow to enforce the logs levels to a configuration that doesn't print any personal information</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-tooltip
                    bottom
                    max-width="250"
                  >
                    <template #activator="{ on }">
                      <v-switch
                        v-model="settings.server.enableRequestMonitoring"
                        label="Monitoring Logs"
                        color="primary"
                        v-on="on"
                        @change="onChangeSection('server')"
                      />
                    </template>
                    <span>Enable the monitoring logs for inbound and outbound HTTP requests</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                  class="mb-4 pl-6 mt-2"
                >
                  To view the Conversation API - SWAGGER UI click
                  <a
                    :href="getSwaggerAddress()"
                    target="_blank"
                  >here</a> ( SWAGGER docs <a
                    :href="getJsonSwaggerAddress()"
                    target="_blank"
                  >here</a> )
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- conversation -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.conversation">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Conversation
              </span>
              <span v-else>Conversation</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>Conversations</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.max_flow_stack"
                    label="Max Flow Jumps"
                    type="number"
                    hint="Number of flow jump stored in memory that can be used to return to the father flow. Default 3"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.timeout"
                    label="Conversation Timeout (ms)"
                    type="number"
                    hint="Default conversation inactivity timeout if not specified in the flow itself. Default 600000"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.mute_timeout"
                    label="End Conversation Timeout (ms)"
                    type="number"
                    hint="Milliseconds of mute at the end of each conversation before allowing the customer to start a new one. Default 5000"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.max_mute_timeout"
                    label="End Conversation Handover Timeout (ms)"
                    type="number"
                    hint="Max duration of handover at the end of each conversation. Default 6000000"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.max_node_execution_per_second"
                    label="Node Execution per Second"
                    type="number"
                    hint="Number of max flow nodes that can be execute in one second, if a conversation exceed this threshold will be closed. This configuration prevent infinite loops. Default 40"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.max_conversation_map_entries"
                    label="Maximum conversation in memory"
                    type="number"
                    hint="Number of mamimum conversation that can be handled concurrently in memory. Default 20000"
                    @change="onChangeSection('conversation')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- videoSettings -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.videoSettings">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Video Settings
              </span>
              <span v-else>Video Settings</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>Low</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.low.width.max"
                    label="Width"
                    type="number"
                    min="0"
                    max="3860"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.low.height.max"
                    label="Height"
                    type="number"
                    min="0"
                    max="2160"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.low.frameRate.max"
                    label="FrameRate"
                    type="number"
                    min="0"
                    max="60"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Medium</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.medium.width.max"
                    label="Width"
                    type="number"
                    min="0"
                    max="3860"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.medium.height.max"
                    label="Height"
                    type="number"
                    min="0"
                    max="2160"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.medium.frameRate.max"
                    label="FrameRate"
                    type="number"
                    min="0"
                    max="60"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
              </v-row>
              <v-subheader>High</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.high.width.max"
                    label="Width"
                    type="number"
                    min="0"
                    max="3860"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.high.height.max"
                    label="Height"
                    type="number"
                    min="0"
                    max="2160"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.videoSettings.quality.high.frameRate.max"
                    label="FrameRate"
                    type="number"
                    min="0"
                    max="60"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('videoSettings')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- default email box -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.defaultEmailBox">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Default Email Box
              </span>
              <span v-else>Default Email Box</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>Email Box</v-subheader>
              <!-- riga email e flag user auth -->
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.email"
                    label="Email"
                    type="email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-checkbox
                    v-model="settings.server.defaultEmailConfig.useAuth"
                    class="mx-2"
                    label="Use Authentication"
                  />
                </v-col>
              </v-row>

              <!-- riga username e scelta service authentication -->
              <v-row
                v-if="settings.server.defaultEmailConfig.useAuth"
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.username"
                    label="Username"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-tooltip top>
                    <template #activator="{ off }">
                      <div
                        class="ma-0 pa-0"
                        v-on="off"
                      >
                        <v-select
                          v-model="settings.server.defaultEmailConfig.serviceAuth"
                          :items="$store.state.enums.serviceAuthTypes"
                          color="primary"
                          label="Service Authentication"
                        />
                        <!-- <v-switch v-on="off" v-model="serviceAuth" :disabled="!useAuth" color="primary" label="Service Authentication"></v-switch> -->
                      </div>
                    </template>
                    <span>Service Authentication</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- riga password -->
              <v-row
                v-if="settings.server.defaultEmailConfig.useAuth && settings.server.defaultEmailConfig.serviceAuth != 'office365-oauth2'"
                :class="rowPadding"
                align="center"
              >
                <v-col
                  v-if="settings.server.defaultEmailConfig.useAuth && settings.server.defaultEmailConfig.serviceAuth == 'basic'"
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.password"
                    label="Password"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :type="showPassword ? 'text' : 'password'"
                    @change="onChangeSection('defaultEmailBox')"
                  >
                    <template slot="append">
                      <v-btn
                        depressed
                        icon
                        text
                        :disabled="settings.server.defaultEmailConfig.password == null || settings.server.defaultEmailConfig.password.length < 1"
                        tabindex="-1"
                      >
                        <v-icon
                          v-show="!showPassword"
                          tabindex="-1"
                          @click="showPassword = !showPassword"
                        >
                          visibility_off
                        </v-icon>
                        <v-icon
                          v-show="showPassword"
                          tabindex="-1"
                          @click="showPassword = !showPassword"
                        >
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Riga per google oauth2 authentication -->
              <v-row
                v-if="settings.server.defaultEmailConfig.useAuth && settings.server.defaultEmailConfig.serviceAuth == 'google-oauth2'"
                :class="rowPadding"
                class="mb-5"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.serviceClient"
                    label="Client ID"
                    hint="Service client ID relative to the service account created"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-account-key"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.privateKey"
                    label="Private key"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :type="showKey ? 'text' : 'password'"
                    prepend-inner-icon="mdi-key"
                    class="pb-0 mb-0"
                  >
                    <template slot="append">
                      <v-btn
                        depressed
                        icon
                        text
                        class="mb-n2"
                        :disabled="settings.server.defaultEmailConfig.privateKey == null || settings.server.defaultEmailConfig.privateKey.length < 1"
                        tabindex="-1"
                      >
                        <v-icon
                          v-show="!showKey"
                          tabindex="-1"
                          @click="showKey = !showKey"
                        >
                          visibility_off
                        </v-icon>
                        <v-icon
                          v-show="showKey"
                          tabindex="-1"
                          @click="showKey = !showKey"
                        >
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Riga per office365 oauth2 authentication -->
              <v-row
                v-if="settings.server.defaultEmailConfig.useAuth && settings.server.defaultEmailConfig.serviceAuth == 'office365-oauth2'"
                :key="0"
                :class="rowPadding"
                class="mb-5"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.tenant"
                    label="Directory (Tenant) ID"
                    :rules="[requiredField]"
                    prepend-inner-icon="person"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.applicationClientId"
                    label="Application (Client) ID"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-account-key"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.clientSecret"
                    label="Client Secret"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-key"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
              </v-row>

              <!-- riga server, porta e secure -->
              <v-row
                v-if="settings.server.defaultEmailConfig.serviceAuth !== 'office365-oauth2'"
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="5"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.defaultEmailConfig.host"
                    label="Hostname"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.defaultEmailConfig.port"
                    label="Port"
                    type="number"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  :class="colPadding"
                >
                  <v-tooltip
                    bottom
                    max-width="250"
                  >
                    <template #activator="{ on }">
                      <v-switch
                        v-model="settings.server.defaultEmailConfig.secure"
                        label="Secure"
                        color="primary"
                        v-on="on"
                        @change="onChangeSection('defaultEmailBox')"
                      />
                    </template>
                    <span>Enable TLS security</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- riga per maxMbSize -->
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="5"
                  :class="colPadding"
                >
                  <v-checkbox
                    v-model="settings.server.defaultEmailConfig.attachments.report.enableMaxSize"
                    class="my-2"
                    label="Enable threshold for reports attachments"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
                <v-col
                  v-show="settings.server.defaultEmailConfig.attachments.report.enableMaxSize"
                  cols="12"
                  sm="4"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.defaultEmailConfig.attachments.report.maxMbSize"
                    suffix="MB"
                    label="Maximum attachments threshold"
                    type="number"
                    min="1"
                    hint="If the attachments of a scheduled report exceed this threshold, they will be saved to a bucket for the configured retention period"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('defaultEmailBox')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- license email template -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.licenseEmailTemplates">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes
                </v-tooltip>
                Email Templates
              </span>
              <span v-else>Email Templates</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>License limit warning threshold</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.min_lic_email_warning_threshold"
                    label="Minimum License for Warning Threshold"
                    type="number"
                    hint="Threshold of number of license above which Convy will enable the warning threashold email. Default 100"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number="settings.server.conversation.email_warning_threshold"
                    label="Warning Threshold"
                    type="number"
                    hint="Threshold percentage license above which Convy will try to send an email to each admin and superadmin to warn about the license usage. Default 80"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
              </v-row>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.conversation.template_subject_email_warning"
                    label="Subject"
                    hint="Subject of the warning email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.conversation.template_body_email_warning"
                    label="Body"
                    filled
                    hint="Subject of the warning email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
              </v-row>
              <v-subheader>License limit reached</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.conversation.template_subject_email_warning_enforce_limit"
                    label="Subject"
                    hint="Subject of the limit reached email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.conversation.template_body_email_warning_enforce_limit"
                    label="Body"
                    filled
                    hint="Body of the limit reached email"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.conversation.template_body_email_warning_enforce_limit_on"
                    label="Body"
                    filled
                    hint="Body of the limit reached email when the enforce flag is checked"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Watchbot scheduled script failure</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.conversation.template_subject_email_schedule_watchbot"
                    label="Subject"
                    hint="Subject of the failed watchbot script"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.conversation.template_body_email_schedule_watchbot"
                    label="Body"
                    filled
                    hint="Body of the failed watchbot script"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('licenseEmailTemplates')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- attachments -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.attachments">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Attachments
              </span>
              <span v-else>Attachments</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-subheader>Scheduled Reports</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.retentionDays"
                    label="Retention"
                    autofocus
                    suffix="Days"
                    type="number"
                    min="1"
                    hint="Time for which Convy will keep reports' attachments that exceed the size configured on the email box used. Default 5 days"
                    :rules="[greaterThanZero]"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
              </v-row>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  v-show="false"
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-select
                    v-model="settings.server.attachments.report.outputs[0].type"
                    label="Output Type"
                    :items="outputsType"
                    :return-object="false"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.outputs[0].endpoint"
                    label="Endpoint"
                    autofocus
                    hint=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.outputs[0].region"
                    label="Region"
                    autofocus
                    hint=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.outputs[0].accessKey"
                    label="Access Key"
                    autofocus
                    hint=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.outputs[0].secretKey"
                    label="Secret Key"
                    autofocus
                    hint=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.attachments.report.outputs[0].bucket"
                    label="Bucket"
                    autofocus
                    hint="This is the bucket's name used to store, for the retention period configured,the attachments of scheduled report"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('attachments')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- generative ai template -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.generativeAI">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes
                </v-tooltip>
                GenerativeAI Templates
              </span>
              <span v-else>GenerativeAI Templates</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="customScrollbarTextArea">
              <h5 class="mt-2">
                Bedrock
              </h5>
              <v-subheader>Prompt Template</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    height="400"
                    :value="defaultBedrockPrompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.bedrock.customPrompt"
                    filled
                    height="400"
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-divider />
              <h5 class="mt-4">
                OpenAI
              </h5>
              <v-subheader>Starting section of the prompt</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="You are a virtual assistant."
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.person"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Additional commands</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    :value="extraInfoText"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.extraInfo"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Knowledge Base Context</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    :value="forceContextText"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.forceContext"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Function Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.functionDescription"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Answer Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="response to give to the customer in his language. It must contain the entire response using the same level of detail as a text-only response."
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.answer"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Intent Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="most relevant intent from the user's question, if any, among the options enclosed by ###"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.intent"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Score Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="reliability of the intent extracted expressed with a number between 0 and 100"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.score"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Entity Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="entity extracted from the user's question, if any, based on the list I provided at the beginning enclosed by @@@"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.entity"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Entity Value Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="value of the entity extracted"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.entityValue"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Sentiment Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    :value="openAicustomerSentiment"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.sentiment"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Language Description</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value="code of the language used by the customer e.g. it, en"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.language"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Prompt End Text</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.end"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
              <v-subheader>Customer Additional Text</v-subheader>
              <v-row
                :class="rowPadding"
                align="center"
              >
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    filled
                    readonly
                    disabled
                    value=""
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-textarea
                    v-model.trim="settings.server.openai.customPrompt.customerAddition"
                    filled
                    hint="Custom Prompt"
                    placeholder="Custom Prompt"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @change="onChangeSection('generativeAI')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- opensearch rollover -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span v-if="sectionModified.opensearch_rollover">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      class="mt-n2"
                      color="warning"
                      v-on="on"
                    >warning</v-icon>
                  </template>
                  This section contains unsaved changes </v-tooltip>Opensearch Rollover
              </span>
              <span v-else>Opensearch Rollover</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                :class="rowPadding"
                class="my-4"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  :class="colPadding"
                >
                  <v-switch
                    v-model="settings.server.opensearch_rollover.enabled"
                    label="Enable"
                    class="mt-0"
                    color="primary"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number.trim="settings.server.opensearch_rollover.days_of_report_searchability"
                    label="Days of max report search"
                    type="number"
                    min="1"
                    hint="Days of max report search. Default 365"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.aws_credential.accessKeyId"
                    label="AWS Access Key"
                    hint="AWS Access Key to create event bridge rules"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.aws_credential.secretAccessKey"
                    label="AWS Secret Key"
                    hint="AWS Secret Key to create event bridge rules"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-autocomplete
                    v-model="settings.server.opensearch_rollover.aws_credential.region"
                    :items="regions"
                    label="Region"
                    :rules="[requiredField]"
                    prepend-inner-icon="vpn_lock"
                    :return-object="false"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.step_function_arn"
                    label="Step Function ARN"
                    hint="Step Function ARN"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.step_function_arn_delete_company"
                    label="Step Function ARN for company deletion"
                    hint="Step Function ARN for company deletion"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.role_arn"
                    label="Step Function Role ARN"
                    hint="Step Function Role ARN"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.opensearch_url"
                    label="Opensearch URL"
                    hint="Opensearch URL"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.trim="settings.server.opensearch_rollover.bucket"
                    label="S3 Bucket"
                    hint="S3 Bucket"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  :class="colPadding"
                >
                  <v-text-field
                    v-model.number.trim="settings.server.opensearch_rollover.batchSize"
                    label="Rollover Max Batch Size"
                    type="number"
                    min="1"
                    max="10000"
                    hint="Rollover Max Batch Size. Default 256"
                    @change="onChangeSection('opensearch_rollover')"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-form>

    <v-row
      :class="getMainRowMargin"
      class="mt-n8"
    >
      <!-- Buttons -->
      <v-btn
        color="success"
        :loading="form.submitLoading"
        :class="getButtonMargin"
        @click.prevent="saveServerConfiguration"
      >
        <v-icon left>
          mdi-floppy
        </v-icon>
        Save
      </v-btn>
      <v-btn
        :class="getButtonMargin"
        color="primary"
        to="/advanceServerSettings"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Advanced Edit
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import merge from "deepmerge";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";
import fieldValidators from "../../helpers/fieldValidators";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Settings",
  components: {
    ResultSnackbar,
  },
  data: () => ({
    showPassword: false,
    showKey: false,
    openAicustomerSentiment: `customer sentiment, choose one of the following, translated into customer language: 
- happy
- sad
- concerned
- curious
- fustrated
- neutral
- worried
- excited`,
    defaultBedrockPrompt: `You are an expert virtual assistant trained to offer practical assistance to help your customer better understand situations and actions to take on their behalf.

Given the context contained in the XML tag <context>, answer to the human question. extract input's intents and entities making a semantic analysis based on the intents contained in the XML tag <intents> and based on the entities you will find in the XML tag <entities>.
The intent score must be expressed with values between 0 and 100 and that's mandatory. The response must have only one intent specified. In case of multiple intents, pick the one with the higher score. extract intents found only between the ones provided in the XML tag <intents>. Fill the tag <entities> with a list of not null entities found only between the ones provided. In the XML tag <excerptedFrom> place the name of the source document used for the response. For the tag <sentiment> including the sentiment of the question, choosing one from the following, translated into customer language:
- happy
- sad
- concerned
- curious
- fustrated
- neutral
- worried
- excited.
Within the tag <language> the language must be specified in code format. Remember to offer practical solutions and information. 
Reply following these additional rules:
- make always sure to insert the > close tag for all CDATA tags in the xml especially for the tag entity-value
- do not produce examples for intents and entities
- when greeting use forms with no gender if the question does not contain entities that explicit a gender
- if you find technical intents alongside others prioritize the technical intents 
- if you find more than one technical intent prioritize the one regarding information
- if you don't find matching intents from the provided list or the list is empty, ignore them and pick the most suitable intent from your own knowledge base and vocabulary
- it's mandatory that you avoid referring to intents and entities in your answer 
- if you do not know the answer, answer I don't know 
- answer using the same language used in the question 
- {toneOfVoice}
{customPrompt}
<intents>{intents}</intents>
<entities>{entities}</entities>

Produce an XML response that will look like the following example: in the answer field, you MUST contain the output message that should be shown to the user. the other fields are technical fields that are used for managing the output process.
<?xml version="1.0" encoding="UTF-8"?>
<response>
   <answer><![CDATA[]]></answer>
   <intent>
	  <intent-name><![CDATA[]]></intent-name>
	  <intent-score></intent-score>
   </intent>
   <entities>
	  <entity-name><![CDATA[]]></entity-name>
	  <entity-value><![CDATA[]]></entity-value>
	  <entity-name><![CDATA[]]></entity-name>
	  <entity-value><![CDATA[]]></entity-value>
   </entities>
   <excerptedFrom><![CDATA[]]></excerptedFrom>
   <sentiment></sentiment>
   <language></language>
</response>
Please think hard. Skip the preamble. The response output MUST be ONLY a valid XML as indicated
`,
    extraInfoText: `use these additional instructions:
- heavily penalize the "greetings" intent score if you find other intents in the sentence provided by the user.`,
    forceContextText: `To answer the user's question, use only the context enclosed by the three single quotes.
'''
{customContextPlaceholder}
{context}
'''
`,
    form: {
      isValid: false,
      submitLoading: false,
    },
    regions: [],
    evaluationTypes: [
      { text: "Eval", value: 0 },
      { text: "Sandbox", value: 1 },
    ],
    outputsType: [
      { text: "AWS", value: "aws-s3" },
      { text: "Minio", value: "minio" },
    ],
    settings: {
      server: {
        //Server section
        publicUrl: "",
        agentDesktopUrl: "",
        enableLegacyTokens: false,
        enforceLogLevelSecurity: "",
        enableSwagger: true,
        throttleTimer: 500,
        enableRequestMonitoring: false,
        opensearch_rollover: {
          enabled: false,
          aws_credential: {
            region: "eu-west-1",
            accessKeyId: "",
            secretAccessKey: "",
          },
          days_of_report_searchability: 365,
          step_function_arn: "",
          step_function_arn_delete_company: "",
          role_arn: "",
          opensearch_url: "",
          bucket: "eudata-opensearch-rollover-12345",
          batchSize: 256,
        },
        //Conversation section
        conversation: {
          max_flow_stack: 5,
          timeout: 600000,
          mute_timeout: 5000,
          max_mute_timeout: 6000000,
          max_node_execution_per_second: 40,
          max_conversation_map_entries: 20000,
          //End Conversation section

          //Server section - http
          http_timeout: 10000,
          http_retry_delay: 1000,
          http_retry_attempt: 3,
          http_max_request_size_mb: 50,
          //End server section - http

          //Server section - cluster
          waitForHazelcastPropagation: 100,
          conditionalJumpEvaluationType: 1,
          conditionalJumpNotUseStrict: true,
          setVariableEvaluationType: 1,
          setVariableNotUseStrict: true,
          //End Server section - cluster

          //Email template settings
          min_lic_email_warning_threshold: 10,
          email_warning_threshold: 40,
          email_bcc: null,
          email_cc: null,
          template_subject_email_warning: "WARNING: Convy license usage is critical",
          virtualagent_delay: 50,
          template_body_email_warning: "The current license usage of %company% with type %licensetype% is %percentage%%",
          email_bcc_enforce_limit: null,
          email_cc_enforce_limit: null,
          template_subject_email_warning_enforce_limit: "WARNING: Reached maximum Convy license limit",
          template_body_email_warning_enforce_limit: "The current licenses available of %company% with type %licensetype% are terminated",
          template_body_email_warning_enforce_limit_on:
            "You have reached the maximum available %licensetype% licenses for %company%. Please contact sales@eudata.com for more details",
          template_subject_email_schedule_watchbot: "Watchbot script scheduled %scriptName% failed",
          template_body_email_schedule_watchbot: "One or more script inside the scheduled watchbot %scriptName% failed to run",
          //END Email template settings
        },
        //Server section - soap
        soap: {
          timeout: 10000,
          replaceAttrRegExp: "",
        },
        //End Server section - soap
        //Section default email
        defaultEmailConfig: {
          email: "",
          username: "",
          password: "",
          retypePassword: "",
          secure: true,
          host: "smtp.gmail.com",
          port: 465,
          authType: "",
          serviceClient: "",
          privateKey: "",
          useAuth: false,
          serviceAuth: "basic",
          tenant: "",
          applicationClientId: "",
          clientSecret: "",
          attachments: {
            report: {
              enableMaxSize: false,
              maxMbSize: 10,
            },
          },
        },
        videoSettings: {
          quality: {
            low: {
              width: { max: 480 },
              height: { max: 360 },
              frameRate: { max: 5 },
            },
            medium: {
              width: { max: 1280 },
              height: { max: 720 },
              frameRate: { max: 15 },
            },
            high: {
              width: { max: 1920 },
              height: { max: 1080 },
              frameRate: { max: 30 },
            },
          },
        },
        attachments: {
          report: {
            retentionDays: 5,
            outputs: [
              {
                uuid: uuidv4(),
                type: "aws-s3",
                endpoint: "",
                bucket: "",
                accessKey: "",
                secretKey: "",
              },
            ],
          },
        },
        bedrock: {
          customPrompt: "",
        },
        openai: {
          customPrompt: {
            person: "",
            extraInfo: "",
            functionDescription: "",
            answer: "",
            intent: "",
            score: "",
            entity: "",
            entityValue: "",
            sentiment: "",
            language: "",
            end: "",
            customerAddition: "",
            forceContext: "",
          },
        },
      },
    },
    sectionModified: {
      server: false,
      conversation: false,
      videoSettings: false,
      defaultEmailBox: false,
      licenseEmailTemplates: false,
      attachments: false,
      generativeAI: false,
      opensearch_rollover: false,
    },
  }),
  computed: {
    ...spacing,
    conditionalJumpNotUseStrict: {
      get() {
        return !this.settings.server.conversation.conditionalJumpNotUseStrict;
      },
      set(newValue) {
        this.settings.server.conversation.conditionalJumpNotUseStrict = !newValue;
      },
    },
    setVariableJumpNotUseStrict: {
      get() {
        return !this.settings.server.conversation.setVariableNotUseStrict;
      },
      set(newValue) {
        this.settings.server.conversation.setVariableNotUseStrict = !newValue;
      },
    },
    rowPadding() {
      return "py-0";
    },
    colPadding() {
      return "py-0";
    },
  },
  async mounted() {
    //* gestione region
    this.regions = this.$store.getters.getAwsRegionCode;

    let probableUrl = window.location.href;
    if (probableUrl.includes("/ConvyAdmin")) {
      this.settings.server.publicUrl = probableUrl.substring(0, probableUrl.indexOf("/ConvyAdmin"));
    } else {
      this.settings.server.publicUrl = probableUrl.replace(":8081/#/settings", "");
    }

    try {
      const response = await this.$http.get("/settings");
      this.settings.server = merge(this.settings.server, response.data);
      if (response.data.attachments?.report?.outputs?.length > 0) {
        this.settings.server.attachments.report.outputs = response.data.attachments.report.outputs;
      }
      // retrocompatibilità
      this.settings.server.defaultEmailConfig.useAuth = this.settings.server.defaultEmailConfig.username.length > 0;
      this.settings.server.defaultEmailConfig.serviceAuth =
        this.settings.server.defaultEmailConfig.serviceAuth || (this.settings.server.defaultEmailConfig.authType ? "google-oauth2" : "basic");
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    getSwaggerAddress() {
      return this.settings.server.publicUrl + "/swagger";
    },
    getJsonSwaggerAddress() {
      return this.settings.server.publicUrl + "/swagger-json";
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    onChangeSection(type) {
      this.sectionModified[type] = true;
    },
    async saveServerConfiguration() {
      if (this.form.isValid) {
        try {
          this.form.submitLoading = true;
          await this.$http.post("/settings", this.settings.server);
          this.resetModifiedSections();
          this.$refs.resultSnackbar.showSuccess("Server configurations updated!");
          await this.$store.dispatch("setProperty", {
            key: "publicUrl",
            value: this.settings.server.publicUrl,
          });
          await this.$store.dispatch("setProperty", {
            key: "days_of_report_searchability",
            value: this.settings.server.opensearch_rollover.days_of_report_searchability,
          });
        } catch (err) {
          this.$refs.resultSnackbar.showError("Fail to update server configurations: " + err.message);
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
    resetModifiedSections() {
      for (let section in this.sectionModified) {
        this.sectionModified[section] = false;
      }
    },
  },
};
</script>

<style>
.auth-toggle-wrap {
  margin: 12px 22px 16px 22px !important;
}
.customScrollbarTextArea {
  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 #f5f5f5;
}
.customScrollbarTextArea textarea::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.customScrollbarTextArea textarea::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.customScrollbarTextArea textarea::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 10px;
}
</style>
