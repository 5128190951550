<template>
  <v-card flat :light="!$vuetify.theme.dark" :dark="$vuetify.theme.dark">
    <v-toolbar flat dense class="my-0 py-0">
      <span :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">Translations <span v-if="$vuetify.breakpoint.smAndUp"> for ❛ {{ itemName }} ❜</span></span>
      <v-spacer />
      <v-btn small color="primary" @click.stop="closeOverlay">
        <v-icon small color class="ma-0 pr-2">
          keyboard_backspace
        </v-icon>
        <span>back</span>
      </v-btn>
    </v-toolbar>

    <v-row class="px-5">
      <!-- Almost any content can be placed inside this component -->
      <v-col sm="3" md="3" lg="3">
        <v-row>
          <flag v-if="$vuetify.breakpoint.smAndUp" :iso="flagSelected" class="mr-2 mb-1" />
          <v-autocomplete
            v-model="newObjLang"
            :light="!$vuetify.theme.dark"
            :dark="$vuetify.theme.dark"
            :items="$store.getters.getLanguageMultilanguage"
            item-text="name"
            item-value="code"
            label="Choose Language"
            class="mr-2"
            @change="changeFlagSelected(newObjLang)"
          />
        </v-row>
      </v-col>
      <v-col xs="8" sm="8" md="8" lg="8">
        <v-textarea
          v-model="translation"
          class="mt-n2"
          :light="!$vuetify.theme.dark"
          auto-grow
          rows="1"
          label="Translation"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
    </v-row>
    <v-row class="px-5">
      <v-layout justify-end class="my-2">
        <v-btn color="primary" class="ma-0" :disabled="translation.length == 0" @click="saveTransl">
          <v-icon left>
            mdi-plus
          </v-icon>
          <span class="pa-1">Add message</span>
        </v-btn>
      </v-layout>
    </v-row>

    <v-card flat :light="!$vuetify.theme.dark" class="my-0 px-3 listOfMessages mt-2">
      <span class="mx-2 my-10" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">List of messages</span>
      <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" class="listOfMessagesScrollBar">
        <v-form>
          <v-row v-for="(translationRow, index) in itemObj" :key="index" class="ma-0 pa-0" justify="center" align="center">
            <v-col cols="3" sm="3">
              <v-row>
                <!-- Almost any content can be placed inside this component -->
                <flag v-if="$vuetify.breakpoint.smAndUp" :iso="translationRow.flag" class="mr-2 mb-1" />
                <v-autocomplete
                  v-model="translationRow.code"
                  :light="!$vuetify.theme.dark"
                  :dark="$vuetify.theme.dark"
                  :items="$store.getters.getLanguageMultilanguage"
                  item-text="name"
                  item-value="code"
                  label="Choose Language"
                  :autocomplete="$store.getters.disableAutocomplete"
                  @change="updateTranslationObj(translationRow)"
                />
              </v-row>
            </v-col>
            <v-col cols="8" sm="8">
              <v-textarea
                v-model="translationRow.value"
                :light="!$vuetify.theme.dark"
                class="translationRow mr-2"
                rows="1"
                :label="translationRow.label"
                auto-grow
                :autocomplete="$store.getters.disableAutocomplete"
                :rules="[requiredField]"
              />
            </v-col>
            <v-col cols="1" class="text-center align-center">
              <v-btn
                v-show="index != 0"
                icon
                rounded
                text
                :light="!$vuetify.theme.dark"
                @click="removeTranslation(index, itemObj)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </perfect-scrollbar>
    </v-card>
  </v-card>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";
import spacing from "../../../helpers/spacing";

export default {
  name: "MultiLanguageConfigurationDialog",
  props: ["itemName", "itemObj"],
  data() {
    return {
      newObjLang: "",
      translation: "",
      flagSelected: this.$store.getters.getUserLanguage == "EN" ? "GB" : this.$store.getters.getUserLanguage,
    };
  },
  computed: {
    ...spacing,
  },
  mounted() {
    this.newObjLang = this.$store.getters.getUserLanguage == "EN" ? "en" : "it";
  },
  methods: {
    ...fieldValidators,
    closeOverlay() {
      this.$emit("back", true);
    },
    changeFlagSelected(flag) {
      if (flag) {
        this.flagSelected = this.$store.getters.getFlagFromLanguageCode(flag).flag;
      }
    },
    saveTransl() {
      if (!this.translation || this.translation.length == 0) {
        return;
      }
      if (this.itemObj && this.itemObj.length == 1 && !this.itemObj[0].value) {
        this.itemObj.splice(0, 1); //Rimuovo il primo elemento se è vuoto
      }
      let languagesList = this.$store.getters.getLanguageMultilanguage;
      let languageSelected = languagesList.filter((d) => d.code === this.newObjLang);

      let newTransl = {
        code: languageSelected[0].code,
        flag: languageSelected[0].flag,
        label: languageSelected[0].name,
        value: this.translation,
      };
      let added = false;
      for (let index = 0; index < this.itemObj.length; index++) {
        if (this.itemObj[0].code == newTransl.code && this.itemObj[index].code != this.itemObj[0].code) {
          //Search ultimo della lingua
          this.itemObj.splice(index, 0, newTransl);
          added = true;
          break;
        } else if (this.itemObj[0].code != this.itemObj[index].code && this.itemObj[index].code > newTransl.code) {
          //inserisco in questa posizione
          this.itemObj.splice(index, 0, newTransl);
          added = true;
          break;
        }
      }
      if (!added) {
        this.itemObj.push(newTransl);
      }

      this.translation = "";
    },
    removeTranslation(index, val) {
      val.splice(index, 1);
      this.editorkey += 1;
    },
    updateTranslationObj(translation) {
      let languagesList = this.$store.getters.getLanguageMultilanguage;
      let languageSelected = languagesList.filter((d) => d.code === translation.code);
      translation.label = languageSelected[0].name;
      translation.flag = languageSelected[0].flag;
    },
  },
};
</script>

<style scoped>
.bottom_menu {
  height: 100% !important;
}
</style>

<style>
.v-bottom-sheet {
  height: 100% !important;
  max-height: 100% !important;
}
#overlayContainer .v-overlay div.v-overlay__content {
  width: 100%;
}
</style>
