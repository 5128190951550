<template class="mt-2">
  <v-card flat>
    <v-card-text>
      <v-switch
        v-model="configuration.general.flow_enabled"
        class="mb-2"
        color="primary"
        label="Flow Enabled"
      />
      <multiLanguageItem
        :itemObj="configuration.general.completedMessage"
        :customLabel="labels.completedMessage"
        :errorMessage="null"
        :required="false"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.completedMessage,
            configuration.general.completedMessage
          )
        "
      />
      <v-text-field
        v-model="configuration.general.max_mute_timeout"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Max handover duration (in secs)"
        type="number"
        min="1"
        :rules="[greaterThanZero]"
        @keypress="onlyNumber"
      />
      <v-text-field
        v-model="configuration.general.mute_timeout"
        class="mx-0 mt-1 mb-2 pa-0"
        label="End conversation mute timeout (in secs)"
        type="number"
        min="1"
        :rules="[greaterThanZero]"
        @keypress="onlyNumber"
      />
      <v-text-field
        v-model="configuration.general.aggregation_timeout"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Aggregation timeout (in secs)"
        type="number"
        min="0"
        :rules="[positiveNumber]"
        @keypress="onlyNumber"
      />
      <v-autocomplete
        v-model="configuration.general.timezone"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Timezone"
        :items="$store.getters.getTimeZones"
        item-text="label"
        item-value="value"
      />
      <multiLanguageItem
        :itemObj="configuration.general.max_input_chars_message"
        :customLabel="labels.maxCharacters"
        :errorMessage="null"
        :required="true"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.maxCharacters,
            configuration.general.max_input_chars_message
          )
        "
      />
      <multiLanguageItem
        :itemObj="configuration.general.not_acceptable_incoming_message"
        :customLabel="labels.notAcceptableMessage"
        :errorMessage="null"
        :required="true"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.notAcceptableMessage,
            configuration.general.not_acceptable_incoming_message
          )
        "
      />
      <multiLanguageItem
        :itemObj="configuration.general.license_cap_reached_message"
        :customLabel="labels.courtesyMessage"
        :errorMessage="null"
        :required="true"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.courtesyMessage,
            configuration.general.license_cap_reached_message
          )
        "
      />
      <v-text-field
        v-model="configuration.general.max_input_chars"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Number of input chars before sending the warning message"
        type="number"
        min="1"
        :rules="[greaterThanZero]"
        @keypress="onlyNumber"
      />
      <!-- flusso di post chiusura -->
      <v-checkbox
        v-if="configuration.general.post_close_conversation"
        v-model="configuration.general.post_close_conversation.enabled"
        label="Enable technical flow when conversation is closed"
      />
      <!-- scelta flusso, versione e hook -->
      <FlowJumpConfigurations
        v-if="
          configuration.general.post_close_conversation &&
            configuration.general.post_close_conversation.enabled
        "
        :settings="configuration.general.post_close_conversation"
        :flowTargets="flowTargets"
        customIndent="ml-8"
      />
    </v-card-text>
    <v-overlay
      absolute
      opacity="1"
      :value="overlay"
      color="overlayMultiLanguage"
      class="overlayMultilanguage"
    >
      <multilanguageConfigurationDialog
        :itemName="itemName"
        :itemObj="itemObj"
        @back="overlay = !$event"
      />
    </v-overlay>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import multilanguageConfigurationDialog from "./MultilanguageConfigurationiDialog";
import multiLanguageItem from "../../other/multiLanguageItem";
import FlowJumpConfigurations from "@/components/other/FlowJumpConfigurations";
import EventBus from "../../../event-bus";
export default {
  name: "General",
  components: {
    multilanguageConfigurationDialog,
    multiLanguageItem,
    FlowJumpConfigurations
  },
  props: ["configuration", "flowTargets"],
  data: () => ({
    itemObj: null,
    itemName: null,
    overlay: false,
    labels: {
      completedMessage: "On flow completed message",
      maxCharacters: "Message for exceeded maximum limit of characters",
      notAcceptableMessage: "Not acceptable incoming message",
      courtesyMessage:
        "Courtesy message sent to the customer when Convy reachs the license limit or expiration"
    }
  }),
  methods: {
    ...fieldValidators,
    showMultiLanguage(label, data) {
      EventBus.$emit(this.$store.getters.getEvents.SCROLLBAR_TO_TOP);
      this.itemName = label;
      this.itemObj = data;
      this.overlay = !this.overlay;
      this.$emit("openOverlay");
    }
  }
};
</script>
