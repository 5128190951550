<template>
  <v-autocomplete
    v-model="item.zone"
    :items="timezones"
    :autocomplete="$store.getters.disableAutocompleteOff"
    :rules="[requiredField]"
    prepend-inner-icon="mdi-earth"
    :label="label ? label : 'Select Timezone'"
    hint="Select Timezone for this company"
    @change="onTZChange"
  />
</template>

<script>
import moment from "moment-timezone";
import fieldValidators from "@/helpers/fieldValidators";

export default {
  name: "TimeZonePicker",
  mixins: [fieldValidators],
  props: ["item", "label"],
  computed: {
    timezones() {
      return moment.tz.names();
    },
  },
  mounted() {
    //Devono eseguire prima i mounted dell'oggetto che contiene questo componente
    setTimeout(() => {
      this.onTZChange(this.item.zone);
    }, 1000);
  },
  methods: {
    ...fieldValidators,
    onTZChange(e) {
      if (e) {
        this.item.timezoneOffset = moment.tz(e)._offset;
      }
    },
  },
};
</script>
