import Vue from "vue";
import VueI18n from "vue-i18n";

import enMsg from "../assets/i18n/en";

Vue.use(VueI18n);

const messages = {
  en: {
    message: enMsg
  }
};

export default new VueI18n({
  locale: "en", // set locale
  messages // set locale messages
});
