<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <ConfirmDialog
      :showConfirm="confirmDialogSettings.backup.showConfirm"
      :message="confirmDialogSettings.backup.message"
      @dialogDeny="confirmDialogSettings.backup.showConfirm = false"
      @dialogConfirm="onBackupConfirm"
    />
    <ConfirmDialog
      :showConfirm="confirmDialogSettings.enableMaintenance.showConfirm"
      :message="confirmDialogSettings.enableMaintenance.message"
      @dialogDeny="confirmDialogSettings.enableMaintenance.showConfirm = false"
      @dialogConfirm="onChangeMaintenanceStatus(true)"
    />
    <ConfirmDialog
      :showConfirm="confirmDialogSettings.disableMaintenance.showConfirm"
      :message="confirmDialogSettings.disableMaintenance.message"
      @dialogDeny="confirmDialogSettings.disableMaintenance.showConfirm = false"
      @dialogConfirm="onChangeMaintenanceStatus(false)"
    />
    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="maintenanceSettings.maintenance" cols="12">
        <v-alert type="error" icon="mdi-alert" class="mx-1">
          <v-row class="no-gutters">
            <v-col cols="12">
              Maintenance Mode is ENABLED.
              <span v-if="maintenanceSettings.started_by">Last changed made by {{ maintenanceSettings.started_by }} </span>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col v-else cols="12">
        <v-alert type="info" icon="mdi-information" color="primary" class="mx-1">
          <v-row class="no-gutters">
            <v-col cols="12">
              Maintenance Mode is DISABLED.
              <span v-if="maintenanceSettings.started_by">Last changed made by {{ maintenanceSettings.started_by }} </span>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-expansion-panels v-model="sections" focusable>
        <!-- configuration -->
        <v-expansion-panel @change="updateMainScrollbar">
          <v-expansion-panel-header>
            <span>Configuration</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form v-model="form.isValid">
              <v-row :class="getFormRowMargin">
                <v-col cols="12" :class="getFormColMargin">
                  <v-text-field v-model.trim="maintenanceSettings.sentence" label="Maintenance mode courtesy messagge" :rules="[requiredField]" />
                </v-col>
              </v-row>
              <v-row>
                <!-- Buttons -->
                <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
                  <v-btn
                    :class="getButtonMargin"
                    color="success"
                    :loading="form.submitLoading"
                    :disabled="!form.isValid || form.deleteLoading"
                    @click.prevent="saveConfiguration"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Save</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- backup-->
        <v-expansion-panel @change="updateMainScrollbar">
          <v-expansion-panel-header>
            <span>Backup History</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="backupHistorySettings.headers" :items="getBackupHistory" sort-by="start_at" sort-desc class="elevation-1">
              <template #top>
                <v-toolbar flat>
                  <v-dialog v-model="backupHistorySettings.dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title>Are you sure?</v-card-title>
                      <v-card-text class="mt-4 font-weight-light">
                        You will delete {{ backupHistorySettings.editedItem.eri }}
                      </v-card-text>
                      <v-card-actions>
                        <v-row no-gutters>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="success" @click="deleteBackupItem">
                              <v-icon left>
                                mdi-floppy
                              </v-icon>Delete
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="error" @click="closeBackupDelete">
                              <v-icon left>
                                mdi-close
                              </v-icon>Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="backupHistorySettings.dialogRestore" max-width="500px">
                    <v-card>
                      <v-card-title>Are you sure?</v-card-title>
                      <v-card-text class="mt-4 font-weight-light">
                        You will restore {{ backupHistorySettings.editedItem.eri }}
                      </v-card-text>
                      <v-card-actions>
                        <v-row no-gutters>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="success" :disabled="!backupHistorySettings.editedItem.finish_at" @click="startRestoreBackup">
                              <v-icon left>
                                mdi-floppy
                              </v-icon>Restore
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="error" @click="closeStartRestore">
                              <v-icon left>
                                mdi-close
                              </v-icon>Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template #item.actions="{ item }">
                <v-tooltip v-if="backupCanBeRestore(item)" :key="'restore_' + rerender" color="primary" top>
                  <template #activator="{ on }">
                    <v-icon class="mr-4" v-on="on" @click="restoreBackup(item)">
                      mdi-restore
                    </v-icon>
                  </template>
                  <span>Restore this backup</span>
                </v-tooltip>
                <v-tooltip color="primary" top>
                  <template #activator="{ on }">
                    <v-icon v-on="on" @click="deleteBackup(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete this backup</span>
                </v-tooltip>
              </template>
              <template #no-data>
                <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
                  No backup available!
                </v-alert>
              </template>
              <template #item.start_at="{ item }">
                <v-row no-gutters>
                  {{ item.start_at | formatVerboseDateTimeForActivity }}
                </v-row>
              </template>
              <template #item.finish_at="{ item }">
                <v-row no-gutters>
                  {{ item.finish_at | formatVerboseDateTimeForActivity }}
                </v-row>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- restore -->
        <v-expansion-panel @change="updateMainScrollbar">
          <v-expansion-panel-header>
            <span>Restore History</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="restoreHistorySettings.headers" :items="getRestoreHistory" sort-by="start_at" sort-desc class="elevation-1">
              <template #top>
                <v-toolbar flat>
                  <v-dialog v-model="restoreHistorySettings.dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title>Are you sure?</v-card-title>
                      <v-card-text class="mt-4 font-weight-light">
                        You will delete {{ restoreHistorySettings.editedItem.eri }}
                      </v-card-text>
                      <v-card-actions>
                        <v-row no-gutters>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="success" :disabled="!restoreHistorySettings.editedItem.finish_at" @click="deleteRestoreItem">
                              <v-icon left>
                                mdi-floppy
                              </v-icon>Delete
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="px-2">
                            <v-btn block outlined color="error" @click="closeRestoreDelete">
                              <v-icon left>
                                mdi-close
                              </v-icon>Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template #no-data>
                <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
                  No restore available!
                </v-alert>
              </template>
              <template #item.actions="{ item }">
                <v-tooltip v-if="restoreCanBeDelete(item)" color="primary" top>
                  <template #activator="{ on }">
                    <v-icon v-on="on" @click="deleteRestoreBackup(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete this entry</span>
                </v-tooltip>
              </template>
              <template #item.start_at="{ item }">
                <v-row no-gutters>
                  {{ item.start_at | formatVerboseDateTimeForActivity }}
                </v-row>
              </template>
              <template #item.finish_at="{ item }">
                <v-row no-gutters>
                  {{ item.finish_at | formatVerboseDateTimeForActivity }}
                </v-row>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row :class="getMainRowMargin" class="mt-n8">
      <v-btn color="primary" :disabled="!canStartBackup()" :loading="form.submitLoading" :class="getButtonMargin" @click.prevent="saveBackup">
        <v-icon left>
          mdi-cloud-upload
        </v-icon>
        Backup
      </v-btn>
      <v-btn
        color="error"
        :disabled="maintenanceSettings.maintenance || !maintenanceSettings.sentence"
        :loading="form.submitLoading"
        :class="getButtonMargin"
        @click.prevent="openaDialogMaintenance(true)"
      >
        <v-icon left>
          mdi-alarm-light
        </v-icon>
        Enable Maintenance
      </v-btn>
      <v-btn
        color="success"
        :disabled="!maintenanceSettings.maintenance"
        :loading="form.submitLoading"
        :class="getButtonMargin"
        @click.prevent="openaDialogMaintenance(false)"
      >
        <v-icon left>
          mdi-alarm-light-off
        </v-icon>
        Disable Maintenance
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";
import customFilter from "../../plugins/customFilter";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";

export default {
  name: "Maintenance",
  components: {
    ResultSnackbar,
    ConfirmDialog,
  },
  data: () => ({
    sections: null,
    rerender: 0,
    confirmDialogSettings: {
      backup: {
        showConfirm: false,
        message: "Are you sure to start a new backup?",
      },
      enableMaintenance: {
        showConfirm: false,
        message: "Are you sure to enable maintenance mode?",
      },
      disableMaintenance: {
        showConfirm: false,
        message: "Are you sure to disable maintenance mode?",
      },
    },
    form: {
      isValid: false,
      submitLoading: false,
    },
    backupHistorySettings: {
      dialogDelete: false,
      editedIndex: -1,
      headers: [
        { text: "ERI", align: "start", sortable: false, value: "eri" },
        { text: "Started By", align: "start", sortable: false, value: "started_by" },
        { text: "Start At", align: "start", sortable: false, value: "start_at" },
        { text: "Finish At", align: "start", sortable: false, value: "finish_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedItem: {
        eri: "",
        start_at: null,
        finish_at: null,
      },
      defaultItem: {
        eri: "",
        start_at: null,
        finish_at: null,
      },
    },
    restoreHistorySettings: {
      dialogRestore: false,
      dialogDelete: false,
      editedIndex: -1,
      headers: [
        { text: "ERI", align: "start", sortable: false, value: "eri" },
        { text: "Started By", align: "start", sortable: false, value: "started_by" },
        { text: "Start At", align: "start", sortable: false, value: "start_at" },
        { text: "Finish At", align: "start", sortable: false, value: "finish_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedItem: {
        eri: "",
        start_at: null,
        finish_at: null,
      },
      defaultItem: {
        eri: "",
        start_at: null,
        finish_at: null,
      },
    },
    maintenanceSettings: {
      started_by: "",
      sentence: "",
      maintenance: false,
      backup: [],
      restore: [],
    },
    maintenanceCheckInterval: null,
  }),
  computed: {
    ...spacing,
    getBackupHistory() {
      return this.maintenanceSettings.backup;
    },
    getRestoreHistory() {
      return this.maintenanceSettings.restore;
    },
    rowPadding() {
      return "py-6";
    },
  },
  async mounted() {
    if (!this.maintenanceCheckInterval) {
      this.maintenanceCheckInterval = setInterval(() => {
        this.loadMaintenanceStatus();
      }, 5000);
    }
    this.loadMaintenanceStatus(true);
  },
  beforeDestroy() {
    if (this.maintenanceCheckInterval) {
      clearInterval(this.maintenanceCheckInterval);
    }
  },
  methods: {
    ...fieldValidators,
    ...customFilter,
    async loadMaintenanceStatus(forceOverwrite) {
      try {
        const resp = await this.$http.get("/maintenance");
        //per evitare di sovrascrivere modifiche del messaggio in canna
        const sentence = this.maintenanceSettings.sentence;
        let lastBackupIsRunning = false;
        if (this.maintenanceSettings.backup.length > 0 && !this.maintenanceSettings.backup[0].finish_at) {
          lastBackupIsRunning = true;
        }
        this.maintenanceSettings = Object.assign(this.maintenanceSettings, resp.data);
        if (resp.data.sentence !== sentence && !forceOverwrite) {
          this.maintenanceSettings.sentence = sentence;
        }
        if (lastBackupIsRunning && this.maintenanceSettings.backup[0].finish_at) {
          this.$refs.resultSnackbar.showSuccess("Backup finished!");
        }
      } catch (e) {
        this.maintenanceSettings = Object.assign({}, this.maintenanceSettings);
      } finally {
        this.rerender++;
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      }
    },
    async onBackupConfirm() {
      try {
        const resp = await this.$http.post("/maintenance/backup");
        this.maintenanceSettings = resp.data;
        this.$refs.resultSnackbar.showSuccess("Backup started successfully!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to start backup: " + e.message);
      } finally {
        this.sections = 1;
        this.confirmDialogSettings.backup.showConfirm = false;
      }
    },
    async onChangeMaintenanceStatus(status) {
      try {
        const resp = await this.$http.post("/maintenance/status", { enabled: status });
        this.maintenanceSettings = resp.data;
        EventBus.$emit(this.$store.getters.getEvents.UPDATED_MAINTENANCE_MODE, this.maintenanceSettings?.maintenance);
        this.$refs.resultSnackbar.showSuccess("Updated configuration successfully!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to enable maintenance: " + e.message);
      } finally {
        this.confirmDialogSettings.enableMaintenance.showConfirm = false;
        this.confirmDialogSettings.disableMaintenance.showConfirm = false;
      }
    },
    async saveConfiguration() {
      try {
        await this.$http.post("/maintenance", this.maintenanceSettings);
        this.$refs.resultSnackbar.showSuccess("Updated configuration successfully!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to update configurations: " + e.message);
      }
    },
    openaDialogMaintenance(status) {
      if (status) {
        this.confirmDialogSettings.enableMaintenance.showConfirm = true;
      } else {
        this.confirmDialogSettings.disableMaintenance.showConfirm = true;
      }
    },
    canStartBackup() {
      if (this.maintenanceSettings.backup.filter((el) => !el.finish_at).length > 0) return false;
      if (this.maintenanceSettings.restore.filter((el) => !el.finish_at).length > 0) return false;
      return true;
    },
    saveBackup() {
      this.confirmDialogSettings.backup.showConfirm = true;
    },
    restoreCanBeDelete(item) {
      if (!item.finish_at) return false;
      return true;
    },
    deleteRestoreBackup(item) {
      this.restoreHistorySettings.editedIndex = this.maintenanceSettings.backup.findIndex((el) => el.eri == item.eri);
      this.restoreHistorySettings.editedItem = item;
      this.restoreHistorySettings.dialogDelete = true;
    },
    async deleteRestoreItem() {
      try {
        const resp = await this.$http.delete("/maintenance/restore/" + this.restoreHistorySettings.editedItem.eri);
        this.maintenanceSettings = resp.data;
        this.$refs.resultSnackbar.showSuccess(`Delete restore ${this.restoreHistorySettings.editedItem.eri} successfully!`);
      } catch (e) {
        this.$refs.resultSnackbar.showError(`Fail to delete restore ${this.restoreHistorySettings.editedItem.eri}: ${e.message}`);
      } finally {
        this.closeRestoreDelete();
      }
    },
    closeRestoreDelete() {
      this.restoreHistorySettings.dialogDelete = false;
      this.$nextTick(() => {
        this.restoreHistorySettings.editedItem = Object.assign({}, this.restoreHistorySettings.defaultItem);
        this.restoreHistorySettings.editedIndex = -1;
      });
    },
    backupCanBeDelete(item) {
      if (!item.finish_at) return false;
      return true;
    },
    backupCanBeRestore(item) {
      if (!item.finish_at) return false;
      if (this.maintenanceSettings.restore.filter((el) => !el.finish_at).length > 0) return false;
      return this.maintenanceSettings.maintenance;
    },
    restoreBackup(item) {
      this.backupHistorySettings.editedIndex = this.maintenanceSettings.backup.findIndex((el) => el.eri == item.eri);
      this.backupHistorySettings.editedItem = item;
      this.backupHistorySettings.dialogRestore = true;
    },
    async startRestoreBackup() {
      try {
        const resp = await this.$http.post("/maintenance/restore/" + this.backupHistorySettings.editedItem.eri);
        this.maintenanceSettings = resp.data;
        this.$refs.resultSnackbar.showSuccess(`Restore backup ${this.backupHistorySettings.editedItem.eri}  started successfully!`);
      } catch (e) {
        this.$refs.resultSnackbar.showError(`Fail to delete backup ${this.backupHistorySettings.editedItem.eri}: ${e.message}`);
      } finally {
        this.closeStartRestore();
        this.sections = 2;
      }
    },
    closeStartRestore() {
      this.backupHistorySettings.dialogRestore = false;
      this.$nextTick(() => {
        this.backupHistorySettings.editedItem = Object.assign({}, this.backupHistorySettings.defaultItem);
        this.backupHistorySettings.editedIndex = -1;
      });
    },
    deleteBackup(item) {
      this.backupHistorySettings.editedIndex = this.maintenanceSettings.backup.findIndex((el) => el.eri == item.eri);
      this.backupHistorySettings.editedItem = item;
      this.backupHistorySettings.dialogDelete = true;
    },
    async deleteBackupItem() {
      try {
        const resp = await this.$http.delete("/maintenance/backup/" + this.backupHistorySettings.editedItem.eri);
        this.maintenanceSettings = resp.data;
        this.$refs.resultSnackbar.showSuccess(`Backup ${this.backupHistorySettings.editedItem.eri} deleted successfully!`);
      } catch (e) {
        this.$refs.resultSnackbar.showError(`Fail to delete backup ${this.backupHistorySettings.editedItem.eri}: ${e.message}`);
      } finally {
        this.closeBackupDelete();
      }
    },
    closeBackupDelete() {
      this.backupHistorySettings.dialogDelete = false;
      this.$nextTick(() => {
        this.backupHistorySettings.editedItem = Object.assign({}, this.backupHistorySettings.defaultItem);
        this.backupHistorySettings.editedIndex = -1;
      });
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
  },
};
</script>
<style></style>
