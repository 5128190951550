<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          bottom
          right
          fixed
          :disabled="disabled"
          class="v-plus-btn"
          v-on="on"
          @click.native="addNewItem"
        >
          <v-icon class="buttonText--text">
            mdi-plus
          </v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <span>Add new {{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AddNewItemButton",
  props: {
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addNewItem(e) {
      this.$emit(this.$store.getters.getEvents.ADD_NEW_ITEM, e);
    },
  },
};
</script>
