<template>
  <v-row>
    <v-col cols="12" style="height: 360px" class="pa-6">
      <v-alert v-if="showAlert" type="warning">
        Not enough data to render this widget!
      </v-alert>
      <vue-word-cloud v-else :words="getWords" font-family="Roboto" :spacing="0" :font-size-ratio="fontSizeRatio">
        <template slot-scope="{ text, weight, word }">
          <v-tooltip top>
            <template #activator="{ on }">
              <div style="cursor: pointer" :style="{ color: getRandomColor() }" v-on="on" @click="onWordClick(word)">
                {{ text }}
              </div>
            </template>
            <span>Occurrence: {{ weight }}</span>
          </v-tooltip>
        </template>
      </vue-word-cloud>
      <v-overlay class="wordCloud" absolute opacity="0.95" :value="configurationOverlay">
        <v-row class="ml-5 mr-5">
          <v-col>
            <v-chip class="mt-4 mb-3" style="background-color: #1d9096 !important">
              Select which intent should be ignored:
            </v-chip>
            <perfect-scrollbar class="intentsChipsContent">
              <v-chip-group v-model="exclusionList" column multiple active-class="error" active>
                <v-chip
                  v-for="intent in getIntentList"
                  :key="intent"
                  :value="intent"
                  filter
                  filter-icon="mdi-minus"
                  label
                  class="white--text"
                >
                  {{ intent }}
                </v-chip>
              </v-chip-group>
            </perfect-scrollbar>
          </v-col>
        </v-row>

        <v-row class="ml-8 mr-5">
          <v-btn color="success" @click="saveDashboard">
            <v-icon left>
              mdi-floppy
            </v-icon>Save
          </v-btn>
          <v-btn color="error" class="ml-4" @click="cancelEditConfiguration">
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
        </v-row>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<script>
import EventBus from "../../event-bus";
export default {
  name: "WordCloud",
  props: {
    "widget": {
      type: Object,
      required: true
    },
    "botList": {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showAlert: false,
      originalData: [],
      configurationOverlay: false,
      exclusionList: [],
      backUpExclusionList: [],
      colors: !this.$vuetify.theme.dark
        ? ["#2196F3", "#42A5F5", "#90CAF9", "#0D47A1", "#1E88E5", "#1565C0", "#4CAF50", "#66BB6A", "#A5D6A7", "#1B5E20", "#43A047", "#2E7D32"]
        : ["#1D9096", "#51b7df", "#57b6e0", "#5cb6e1", "#62b5e2", "#54b6e0", "#45b6dd", "#33b7da", "#00b8cd", "#00b9ba", "#00b7a3", "#2fb589"],
      fontSizeRatio: 5,
    };
  },
  computed: {
    getIntentList: function () {
      return this.originalData
        .map((obj) => obj[0])
        .sort((a, b) => {
          if (this.exclusionList.indexOf(a) != -1) {
            return 1;
          }
          if (this.exclusionList.indexOf(b) != -1) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    getWords: function () {
      return this.originalData.filter((entry) => {
        return this.exclusionList.indexOf(entry[0]) == -1;
      });
    },
  },
  async mounted() {
    let response = null;
    try {
      response = await this.$httpAuth.get("/dashboard/intent-distribution/" + this.widget.conf.engine, {
        timeout: 300000,
      });
      response.data.dashboard.forEach((entry) => {
        this.originalData.push([entry.key, entry.value]);
      });
      if (this.widget.conf.filters) {
        this.exclusionList = Object.keys(this.widget.conf.filters.intent);
      }
      this.backUpExclusionList = [].concat(this.exclusionList);
      if (this.originalData.length == 0) {
        this.showAlert = true;
        this.$emit("disableWidgetSettings", {
          noData: true,
          id: this.widget.widgetId,
        });
      } else {
        this.$emit("disableDashboardSettings", {
          noData: false,
          id: this.widget.widgetId,
        });
      }
    } finally {
      response = null;
    }
  },
  methods: {
    saveDashboard: function () {
      this.configurationOverlay = false;
      if (!this.widget.conf.filters) this.widget.conf.filters = {};
      this.widget.conf.filters.intent = {};
      this.exclusionList.forEach((excluded) => {
        this.widget.conf.filters.intent[excluded] = false;
      });
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DASHBOARD, this.widget);
    },
    cancelEditConfiguration: function () {
      this.exclusionList = [].concat(this.backUpExclusionList);
      this.configurationOverlay = false;
    },
    openConfiguration() {
      this.configurationOverlay = true;
    },
    onWordClick: function (word) {
      let intentSelected = word[0];
      window.open(
        window.location.origin +
          window.location.pathname +
          "#/semanticConfidence?engine=" +
          this.widget.conf.engine +
          "&intent=" +
          intentSelected +
          "&startdate=" +
          this.getStartDate() +
          "&enddate=" +
          this.getEndDate() +
          "&autosearch=true",
      );
    },
    getStartDate: function () {
      let previous = this.monthAdd(new Date(), -1);
      let today = new Date(previous);
      return this.getDateInFormat(today);
    },
    monthAdd: function (date, month) {
      let temp = date;
      temp = new Date(date.getFullYear(), date.getMonth(), 1);
      temp.setMonth(temp.getMonth() + (month + 1));
      temp.setDate(temp.getDate() - 1);
      if (date.getDate() < temp.getDate()) {
        temp.setDate(date.getDate());
      }
      return temp;
    },
    getDateInFormat: function (date) {
      let dd = date.getDate();
      let mm = date.getMonth() + 1; //January is 0!

      let yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },
    getEndDate: function () {
      let today = new Date();
      return this.getDateInFormat(today);
    },
    getRandomColor: function () {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
  },
};
</script>

<style>
.wordCloud .v-overlay__content {
  width: 100%;
  height: 410px;
}
.wordCloud .v-overlay__content > .row {
  height: 85%;
}
.intentsChipsContent {
  height: 290px;
}
</style>
