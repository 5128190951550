<template>
  <v-row justify="center" :class="getMainRowMargin">
    <v-col cols="12">
      <v-alert
        type="info"
        icon="mdi-information"
        outlined
        text
        :color="$vuetify.theme.dark ? '#FBFBF7' : 'primary'"
        class="mx-1"
      >
        <v-row class="no-gutters">
          <v-col cols="12">
            Customize your interface by uploading an image for the engagement button or click the (+) button and upload the zip file of your gui.
          </v-col>
        </v-row>
      </v-alert>
      <v-expansion-panels v-model="logoPanel">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions style="pointer-events: none !important">
            <v-row align="center" class="px-4">
              <v-col cols="12">
                <span>Your current interface logo</span>
              </v-col>
              <v-col cols="3" class="text-center px-0 mx-0 pb-3 pr-12">
                <v-avatar size="80" color="#2A3C51">
                  <v-img contain :src="logoSrc" />
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <v-row style="justify-content: end">
                  <v-btn small class="ml-2" color="success" style="pointer-events: auto !important" @click.native.stop="manageOpen">
                    <v-icon left>
                      mdi-cached
                    </v-icon>Change
                  </v-btn>
                  <v-btn
                    small
                    class="ml-2"
                    color="primary"
                    :disabled="!existsGuiLogo"
                    style="pointer-events: auto !important"
                    @click.prevent.stop="exportLogo"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>Export
                  </v-btn>
                  <v-btn
                    small
                    class="ml-2"
                    color="error"
                    :disabled="!existsGuiLogo"
                    style="pointer-events: auto !important"
                    @click.prevent.stop="showDialog"
                  >
                    <v-icon left>
                      mdi-delete
                    </v-icon>Delete
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 py-4" style="pointer-events: auto !important">
            <v-form v-model="formLogo.isValid">
              <v-row class="pb-4">
                <v-col cols="12">
                  <v-row align="center" justify="space-between" class="pr-6">
                    <span>Choose a new customer interface logo</span>
                    <v-switch v-model="importMethod" label="Show preloaded images" />
                  </v-row>
                </v-col>
                <fileUpload
                  v-if="!importMethod"
                  :file="newFile"
                  fileAccepted="image"
                  :isLogo="true"
                  @uploadFile="uploadFile"
                  @removeFile="removeFile"
                />
                <v-col v-else cols="12" class="imageSlide px-0">
                  <v-carousel v-model="templateSelectionIndex" class="defaultImages" show-arrows hide-delimiters height="120px">
                    <v-carousel-item v-for="(template, i) in presetImages" :key="i">
                      <v-row align="center">
                        <v-col offset="3" cols="6" class="text-center mt-4">
                          <v-avatar :id="'preset_template_' + i" size="80">
                            <v-img contain :src="template.image" />
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
            </v-form>

            <v-row class="pt-8 px-n2">
              <v-btn
                small
                outlined
                color="success"
                class="mr-4"
                :disabled="
                  !importMethod ? !newFile || !checkIsImage || !formLogo.isValid || formLogo.submitLoading : !formLogo.isValid && formLogo.submitLoading
                "
                @click="importLogo()"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>Submit
              </v-btn>
              <v-btn small outlined color="error" @click.prevent="manageOpen">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import fieldValidators from "../../helpers/fieldValidators";
import fileUpload from "../../components/tools/gui/fileUpload.vue";

export default {
  name: "CustomerLogo",
  components: {
    fileUpload,
  },
  props: {
    "logoFile": {
      type: Object,
      required: true
    },
    "logoSrc": {
      type: Object,
      required: true
    }, 
    "existsGuiLogo": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      logoPanel: null,
      formLogo: {
        isValid: false,
        submitLoading: false,
      },
      importMethod: false,
      // carosello
      presetImages: this.$store.getters.getCustomerInterfaceLogoPreset,
      templateSelectionIndex: 0,
      newFile: null,
      forceRender: 0,
    };
  },
  computed: {
    ...spacing,
    checkIsImage() {
      if (this.newFile && this.newFile.type && this.newFile.type.includes("image")) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...fieldValidators,
    ...scroller,
    manageOpen() {
      this.logoPanel = this.logoPanel === 0 ? null : 0;
      if (this.logoPanel === null && this.newFile) {
        this.removeFile();
      }
    },
    uploadFile(obj) {
      if (obj.isLogo) {
        this.newFile = obj.file;
      } else {
        this.file = obj.file;
      }
    },
    async importLogo() {
      this.formLogo.submitLoading = true;
      let data = new FormData();
      if (this.importMethod) {
        let base64 = this.presetImages[this.templateSelectionIndex].base64;
        let mime = "image/png";
        let bstr = atob(base64);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.newFile = new File([u8arr], "bot.png", { type: mime });
      }
      data.append("bot.png", this.newFile);
      try {
        const result = await this.$http.post("/customer-ui-logo", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$emit("uploadLogo", { upload: true, logoFile: this.newFile, existGui: true, logoSrc: "data:image/png;base64," + result.data });
      } catch (e) {
        this.$emit("uploadLogo", { upload: false });
      }
      this.formLogo.submitLoading = false;
      this.manageOpen();
      this.importMethod = false;
      this.newFile = null;
    },
    exportLogo() {
      if (this.logoSrc.includes("data:") && this.logoSrc.includes("base64")) {
        let a = document.createElement("a"); //Create <a>
        a.href = this.logoSrc; //Image Base64 Goes here
        a.download = "logo.png"; //File name Here
        a.click();
      }
    },
    removeFile() {
      this.newFile = null;
      this.forceRender++;
    },
    showDialog() {
      this.$emit("showDialog");
    },
  },
};
</script>
<style lang="scss">
.defaultImages {
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 120px;
  width: 100%;
  padding: 0;
}
.theme--dark {
  .defaultImages {
    background-color: #455a64;
    border: 2px dashed rgba(211, 211, 211, 0.8);
    .v-window__prev button,
    .v-window__next button {
      background-color: #1d9096 !important;
    }
  }
}
.theme--light {
  .defaultImages {
    background-color: rgba(211, 211, 211, 0.4);
    border: 2px dashed rgba(211, 211, 211, 1);
    .v-window__prev button,
    .v-window__next button {
      background-color: #039be5 !important;
    }
  }
}
</style>
