<template>
  <v-container id="searchDocumentPage" fluid :class="getCreationContainerMargin">
    <!-- dialog delete docs -->

    <v-dialog v-model="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title>Delete Docs</v-card-title>
        <v-card-text class="mt-4 font-weight-light" v-html="dialogMessage" />
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn block color="success" outlined @click.prevent="deleteDocuments">
                <v-icon left>
                  mdi-check
                </v-icon>Yes
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn block color="error" outlined @click.prevent="dialogDelete = false">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search bar -->

    <div class="ma-8">
      <v-expansion-panels class="">
        <v-expansion-panel>
          <v-expansion-panel-header>Search documents</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn-toggle v-model="modeSearch" mandatory dense active-class="primary" class="white--text">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn value="extended" small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                    Extended
                  </v-btn>
                </template>
                <span>Searh terms are related in "or"</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn value="strict" small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                    Strict
                  </v-btn>
                </template>
                <span>The search is performed on the exact string</span>
              </v-tooltip>
            </v-btn-toggle>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  v-model="searchByFname"
                  :disabled="selectedDocs.length > 0"
                  dense
                  hide-details
                  outlined
                  clearable
                  label="Search in document name"
                  prepend-inner-icon="search"
                />
              </v-col>
              <!-- <v-col class="mt-1" style="text-align: right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mx-2" v-on="on" @click="add" depressed x-small fab color="primary">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add more filters</span>
                </v-tooltip>
                <v-btn class="mx-2" depressed x-small fab color="error">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-switch v-model="enableIndexes" inset label="Limit research to specific indexes" @change="toggleIndexes" />
              </v-col>
              <v-col class="mt-3">
                <v-autocomplete
                  v-model="selectedIndexes"
                  :disabled="!enableIndexes"
                  :items="indexes"
                  small-chips
                  label="Indexes"
                  multiple
                  outlined
                  dense
                  deletable-chips
                  class="selectIndex"
                  @change="filterIndexes()"
                />
              </v-col>
            </v-row>
            <!-- <v-row class="mt-2 mb-2">
              <v-btn color="primary"> <v-icon class="mr-2">search</v-icon> Search</v-btn>
            </v-row> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <ResultSnackbar ref="resultSnackbar" />

    <div class="ma-8">
      <v-alert v-if="Object.keys(filteredDocs).length === 0 && showWarning" type="warning" class="ml-1">
        No data available or no match for your search
      </v-alert>
      <v-expansion-panels v-model="indexPanels" focusable>
        <v-expansion-panel v-for="(item, idx) in filteredDocs" :key="idx">
          <v-expansion-panel-header @click="selectedDocs = []">
            <v-row>
              <v-col cols="1">
                <v-icon class="ml-6 mt-6">
                  mdi-rhombus-split
                </v-icon>
              </v-col>
              <v-col cols="5" class="mt-2 text-truncate">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Index</v-list-item-subtitle>
                    <v-list-item-title class="pt-2">
                      {{ idx.split(":").pop() }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="2" class="mt-2">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Documents</v-list-item-subtitle>
                    <v-list-item-title class="pt-2">
                      <v-chip small class="success">
                        {{ item.documents.length }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3" class="mt-2 text-truncate">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                    <v-list-item-title class="pt-2">
                      {{ new Date(item.updated_at).toISOString() | formatVerboseDateTime }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      fab
                      text
                      icon
                      small
                      class="mt-4 elevation-0"
                      v-on="on"
                      @click.stop="openDelete(idx)"
                    >
                      <v-icon color="error">
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete full index</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-title>
              Documents
              <v-spacer />
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
            </v-card-title>
            <v-data-table
              v-model="selectedDocs"
              show-select
              hide-default-header
              :headers="headers"
              :items="getDocuments(idx, item.documents)"
              :items-per-page="10"
              class="elevation-1"
              item-key="document"
              :search="search"
            />
            <v-btn
              rounded
              color="error"
              class="ml-5 mt-5"
              :disabled="!selectedDocs.length"
              :loading="deletingDocumentsLoading"
              @click.stop="openDelete()"
            >
              <v-icon class="mr-2">
                mdi-delete
              </v-icon> Delete selected documents
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import EventBus from "../../event-bus";
import timeoutHelper from "@/helpers/timeout";

export default {
  name: "SearchDocument",
  components: {
    ResultSnackbar,
  },
  data() {
    return {
      deletingDocumentsLoading: false,
      search: "",
      headers: [
        {
          filterable: true,
          text: "Document",
          align: "start",
          sortable: true,
          value: "document",
        },
      ],
      showWarning: false,
      documentList: {},
      filteredDocumentList: {},
      dialogDelete: false,
      searchString: "",
      pageNumber: 1,
      searchByFname: "",
      searchOperator: "AND",
      enableIndexes: false,
      selectedIndexes: [],
      indexes: [],
      selectedDocs: [],
      selectedItems: [],
      dialogMessage: "",
      deleteMode: "",
      indexPanels: [],
      modeSearch: "extended",
    };
  },
  computed: {
    ...spacing,

    filteredDocs() {
      if (this.searchByFname === "" || !this.searchByFname) {
        return this.filteredDocumentList;
      } else {
        let searchTerms;
        if (this.modeSearch == "strict") {
          searchTerms = [this.searchByFname.trim()];
        } else {
          searchTerms = this.searchByFname.trim().split(" ");
        }

        let filteredList = {};
        for (const [key, value] of Object.entries(this.filteredDocumentList)) {
          const filteredDocs = value.documents.filter((doc) => {
            return searchTerms.some((term) => doc.toLowerCase().includes(term.toLowerCase()));
          });
          if (filteredDocs.length > 0) {
            filteredList[key] = { ...value, documents: filteredDocs };
          }
        }
        return filteredList;
      }
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("knowledgeBase")) {
      this.$router.push("/");
    }
    await this.listIndexes();
    await this.searchDocuments();
    this.filteredDocumentList = { ...this.documentList };
    this.showWarning = true;
  },
  methods: {
    ...scroller,
    getDocuments(index, listOfDocs) {
      const result = [];
      listOfDocs.forEach((doc) => {
        result.push({
          document: doc,
          idx: index,
        });
      });
      return result;
    },
    isSelected(idx, document) {
      return this.selectedDocs.some((item) => item.idx === idx && item.document === document);
    },

    toggleSelection(idx, document) {
      const selectedDocument = { idx, document };
      const index = this.selectedDocs.findIndex((doc) => doc.document === document && doc.idx === idx);
      if (index !== -1) {
        this.selectedDocs.splice(index, 1);
      } else {
        this.selectedDocs.push(selectedDocument);
      }
    },

    openDelete(idx) {
      this.deleteMode = "";
      if (idx) {
        this.dialogMessage =
          "Are you sure you want to proceed with the deletion of this index and all related documents?<strong style='color:green'><br>[" +
          idx.split(":").pop() +
          "]</strong>";
        this.deleteMode = idx;
      } else {
        this.dialogMessage = "Are you sure you want to proceed with deleting all the selected documents?";
        this.deleteMode = "docs";
      }
      this.dialogDelete = true;
    },

    groupDocuments() {
      const result = {};
      for (const obj of this.selectedDocs) {
        const idx = obj.idx;
        const document = obj.document;
        if (result[idx]) {
          result[idx].documents.push(document);
        } else {
          result[idx] = { index: idx, documents: [document] };
        }
      }
      //console.log(Object.values(result));
      return Object.values(result);
    },

    async deleteDocuments() {
      this.deletingDocumentsLoading = true;
      let delObj;
      if (this.deleteMode == "docs") {
        delObj = this.groupDocuments()[0];
      } else {
        delObj = { index: this.deleteMode, documents: [] };
      }
      try {
        await this.$httpAuth.post("knowledge-base/indexing/index/delete", {
          ...delObj,
        });
        this.searchDocuments();
        this.$refs.resultSnackbar.showSuccess("Successfully deleted all documents");
        this.selectedDocs = [];
      } catch {
        //this.dialogDelete = false;
        this.$refs.resultSnackbar.showError("Something wrong while deleting documents");
      } finally {
        this.dialogDelete = false;
        this.deletingDocumentsLoading = false;
      }
    },
    async searchDocuments() {
      try {
        const result = await this.$httpAuth.get("/knowledge-base/indexing/document/list");
        this.documentList = result.data.result || {};
        this.filteredDocumentList = result.data.result;
      } catch (e) {
        this.documentList = {};
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      }
    },
    async listIndexes() {
      try {
        const result = await this.$httpAuth.get("/knowledge-base/indexing/index/list");
        //const result =  ["idx:3869866a2c3c430e920fad2f699d6595:ultimo-test-2","idx:3869866a2c3c430e920fad2f699d6595:test-2"]
        const res = result.data.result.map((index) => {
          //  const res = result.map((index) => {
          const parts = index.split(":");
          return { value: index, text: parts[2] };
        });
        this.indexes = res;
      } catch {
        this.indexes = [];
      }
    },

    filterIndexes() {
      if (this.selectedIndexes.length === 0) {
        this.filteredDocumentList = this.documentList;
      } else {
        const filteredKeys = Object.keys(this.documentList).filter((key) => this.selectedIndexes.includes(key));
        const filteredObject = filteredKeys.reduce((obj, key) => {
          obj[key] = this.documentList[key];
          return obj;
        }, {});

        this.filteredDocumentList = filteredObject;
      }
    },

    toggleIndexes(e) {
      if (!e) {
        this.selectedIndexes = [];
        this.filterIndexes();
      }
    },
  },
};
</script>

<style>
#searchDocumentPage .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#searchDocumentPage .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}

.active-documents {
  background-color: #1976d2;
  color: #fff;
}

.selectIndex.v-text-field--outlined.v-input--dense .v-label--active {
  left: 0px !important;
}
</style>
