<template>
  <v-container class="mt-5" fluid>
    <div class="subtitle-1 mb-2">Set business hours:</div>
    <v-row v-for="(item, index) in businessHours.hours" :key="'business_hours_' + index" no-gutters>
      <v-col class="mr-5">
        <v-select :items="periodList" v-model="item.period" label="Period" :rules="[requiredField]"></v-select>
      </v-col>
      <v-col class="mr-5">
        <v-text-field
          v-model="item.startAt"
          label="Start At"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          :rules="[requiredField]"
          @click="openStartTimePicker(item, index)"
        ></v-text-field>
      </v-col>
      <v-col class="mr-5">
        <v-text-field
          v-model="item.finishAt"
          label="Finish At"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          :rules="[requiredField]"
          @click="openFinishTimePicker(item, index)"
        ></v-text-field>
      </v-col>
      <v-col class="ml-5" align-self="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" depressed x-small fab color="primary" :disabled="isAddDisabled(item)" @click="add">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add more filters</span>
        </v-tooltip>
        <v-btn v-if="index !== 0" class="ml-2" depressed x-small fab color="error" @click="remove(index)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog ref="dialog" v-model="timePickerDialog" persistent width="290px">
      <v-time-picker
        v-if="timePickerDialog"
        v-model="tmpTime"
        :max="max"
        :min="min"
        format="24hr"
        full-width
        scrollable
        color="primary"
        @click:minute="saveTime()"
      >
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="timePickerDialog = false"> Cancel </v-btn>
      </v-time-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import fieldValidators from "../../../../helpers/fieldValidators";
export default {
  name: "BusinessHours",
  props: ["businessHours"],
  data() {
    return {
      periodList: [
        { value: "noWeekend", text: "Mon-Fri" },
        { value: "weekend", text: "Sat-Sun" },
        { value: "mon", text: "Monday" },
        { value: "tue", text: "Tuesday" },
        { value: "wed", text: "Wednesday" },
        { value: "thu", text: "Thursday" },
        { value: "fri", text: "Friday" },
        { value: "sat", text: "Saturday" },
        { value: "sun", text: "Sunday" },
      ],
      timePickerDialog: false,
      startAtMenu: false,
      finishAtMenu: false,
      tmpTime: "",
      hoursIndex: null,
      editStart: true,
      min: null,
      max: null,
    };
  },
  methods: {
    ...fieldValidators,
    saveTime() {
      if (this.editStart) {
        this.businessHours.hours[this.hoursIndex].startAt = this.tmpTime;
      } else {
        this.businessHours.hours[this.hoursIndex].finishAt = this.tmpTime;
      }
      this.tmpTime = null;
      this.timePickerDialog = false;
    },
    openStartTimePicker(item, index) {
      this.editStart = true;
      this.hoursIndex = index;
      this.tmpTime = item.startAt;
      this.min = null;
      this.max = item.finishAt;
      this.timePickerDialog = true;
    },
    openFinishTimePicker(item, index) {
      this.editStart = false;
      this.hoursIndex = index;
      this.tmpTime = item.finishAt;
      this.min = item.startAt;
      this.max = null;
      this.timePickerDialog = true;
    },
    add() {
      this.businessHours.hours.push({ period: "", startAt: "", finishAt: "" });
    },
    remove(index) {
      this.businessHours.hours.splice(index, 1);
    },
    isAddDisabled(item) {
      return !item.period || !item.startAt || !item.finishAt;
    },
  },
};
</script>

<style scoped></style>
