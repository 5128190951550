<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Manage your User"
      @input="
        searchString = $event;
        forceRerender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && users.length == 0 && getUserList.length == 0 && !newUser && searchString == ''" cols="12">
        <v-alert type="warning">
          No Users configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getUserList.length == 0 && !newUser && searchString != ''" cols="12">
        <v-alert type="warning">
          No User match your search
        </v-alert>
      </v-col>
      <!-- riga per la creazione di un nuovo elemento -->
      <v-expansion-panels v-if="newUser" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel @change="changeStatusExpansionPanel">
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <v-avatar :size="getAvatarSize">
                  <img alt="Avatar" :src="'./avatars/avatar-00.png'">
                </v-avatar>
              </v-col>
              <v-col cols="8">
                New User
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <UserForm :user="newUser" :profiles="profiles" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(user, id) in getUserList" :key="id" @change="changeStatusExpansionPanel">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="4" sm="2" md="1">
                  <v-avatar :size="getAvatarSize" :color="user.info.color">
                    <span
                      v-if="!getAvatar(user).thereIsAvatar"
                      style="font-size: 18px; font-weight: 600; margin-top: 4px"
                      class="pb-1"
                      :style="getAvatarFontColor(user.info.color)"
                    >
                      {{ getUserInitials(user) }}</span>
                    <v-img v-else :src="getAvatar(user).avatarPath" />
                  </v-avatar>
                </v-col>

                <v-col class="text-truncate" sm="5" md="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.info.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ user.authentication.credentials.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-xs-only text-no-wrap" cols="5" sm="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Role</v-list-item-subtitle>
                      <v-list-item-title>{{ $store.getters.getRoleLabel(user.roles[$store.getters.getProducts.CONVYAI].role) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="user.disabled">
                        Disabled
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else-if="checkExpirationDate(user)">
                        Password expired
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        Enabled
                      </v-list-item-subtitle>
                      <v-list-item-icon class="ma-0">
                        <v-btn class="ma-0 ml-1" :color="user.disabled || checkExpirationDate(user) ? 'error' : 'success'" x-small text depressed>
                          <v-icon v-if="user.disabled" small>
                            mdi-close-circle
                          </v-icon>
                          <v-icon v-else-if="checkExpirationDate(user)" small>
                            mdi-close-circle
                          </v-icon>
                          <v-icon v-else small>
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title>{{ user.metadata.modified_at | formatVerboseDateTime }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <UserForm
              :key="forceRerender + id + '_uf'"
              :user="user"
              :isSystem="IsFromSystem"
              :hasPasswordExpired="checkExpirationDate(user)"
              :profiles="profiles"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredUsers.length > 1"
      :items="filtredUsers.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRerender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton name="User" @addNewItem="addNewUser" />
  </v-container>
</template>

<script>
import UserForm from "../../components/advanced/UserForm";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import avatars from "../../helpers/avatars";
import Pagination from "../../components/other/Pagination";

export default {
  name: "Users",
  components: {
    UserForm,
    ResultSnackbar,
    AddNewItemButton,
    SearchBar,
    Pagination,
  },
  props: { 
    "routeSystem": {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      users: [],
      filtredUsers: [],
      profiles: [],
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      searchString: "",
      //Data bind per creazione nuovo utente
      newUser: null,
      IsFromSystem: false,
      showWarning: false,
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
    };
  },
  computed: {
    ...spacing,
    getUserList() {
      this.forceRerender;
      let filterData = this.filterData();
      this.scrollToTop();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
  },
  created() {
    // In questo modo se il componente degli utenti è aperto dalla sezione System ( oppure dalla sezione Advanced)
    if (this.routeSystem) {
      this.IsFromSystem = true;
    }
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.EDIT_USER, this.onUserUpdate);
    EventBus.$on(this.$store.getters.getEvents.RESET_USER_PASSWORD, this.onUserUpdate);
    EventBus.$on(this.$store.getters.getEvents.DELETE_USER, this.deleteUser);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_USER_CREATION, this.cancelUserCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_USER_FAIL, this.deleteUserFail);
    EventBus.$on(this.$store.getters.getEvents.FAIL_TO_RESET_USER_PASSWORD, this.onUserFailedUpdate);
    EventBus.$on(this.$store.getters.getEvents.EDIT_USER_FAIL, this.onUserFailedUpdate);
    EventBus.$on(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL, this.onSendMail);
    EventBus.$on(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL_FAIL, this.onSendMail);
    try {
      //Load Data...
      let result;
      if (this.$route.path === "/systemUsersOverview" && this.$store.getters.userIsRoot) {
        result = await this.$http.get("/user-all");
      } else {
        result = await this.$http.get("/user");
      }
      this.users = result.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }

    let response = null;
    try {
      //richiesta elenco dei profili per contactCenterManager
      response = await this.$http.get("/human-agents/profile/companies/all");
      this.profiles = response.data;
    } finally {
      response = null;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_USER, this.onUserUpdate);
    EventBus.$off(this.$store.getters.getEvents.RESET_USER_PASSWORD, this.onUserUpdate);
    EventBus.$off(this.$store.getters.getEvents.DELETE_USER, this.deleteUser);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_USER_CREATION, this.cancelUserCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_USER_FAIL, this.deleteUserFail);
    EventBus.$off(this.$store.getters.getEvents.FAIL_TO_RESET_USER_PASSWORD, this.onUserFailedUpdate);
    EventBus.$off(this.$store.getters.getEvents.EDIT_USER_FAIL, this.onUserFailedUpdate);
    EventBus.$off(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL, this.onSendMail);
    EventBus.$off(this.$store.getters.getEvents.RESEND_WELCOME_EMAIL_FAIL, this.onSendMail);
  },
  methods: {
    ...scroller,
    ...avatars,
    changeStatusExpansionPanel() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    checkExpirationDate(user) {
      return user.authentication.credentials.expiration && user.authentication.credentials.expiration < new Date().toISOString();
    },
    onUserUpdate(obj) {
      //Resetto l'array di companies che va resettato e non mergiato
      let id = this.getArrayIdOfUser(obj.user.authentication.credentials.username);
      if (id < 0) {
        this.users.push(merge({}, obj.user));
        this.$refs.resultSnackbar.showSuccess("User " + obj.user.authentication.credentials.username + " added!");
      } else {
        this.users[id] = merge({}, obj.user);
        this.$refs.resultSnackbar.showSuccess("Agent " + obj.user.authentication.credentials.username + " updated!");
      }
      this.closeAllPanels();
      this.forceRerender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    onUserFailedUpdate(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    cancelUserCreation() {
      this.expansionPanelCreation = null;
      this.newUser = null;
    },
    closeAllPanels() {
      this.cancelUserCreation();
      this.expansionPanel = null;
    },
    addNewUser() {
      //Se il primo elemento non è in edit
      if (!this.newUser) {
        this.newUser = {
          _id: "",
          eri: "",
          disabled: false,
          deleted: false,
          authentication: {
            type: "basic",
            credentials: {
              username: "",
              email: "",
              password: "",
              expiration_months: 10000,
              expiration: "",
              realm: null,
            },
          },
          info: {
            name: "",
            surname: "",
            language: "EN",
            avatar: "avatar-00.png",
            color: this.$vuetify.theme.dark ? "#1D9096" : "#035075",
          },
          roles: {
            [this.$store.getters.getProducts.CONVYAI]: {
              role: this.$store.getters.getRoles.USER,
              resources: {
                ["eudata:convyai:" + this.$store.getters.getSelectedCompany]: {
                  owned_profiles: [],
                },
              },
            },
          },
        };
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    deleteUser(obj) {
      let id = this.getArrayIdOfUser(obj.username);
      if (id >= 0) {
        this.$refs.resultSnackbar.showSuccess("User " + obj.username + " deleted!");
        this.users.splice(id, 1);
        this.closeAllPanels();
        if (this.getUserList.length == 0 && this.pageNumber > 1) {
          this.pageNumber--;
        }
        setTimeout(() => {
          this.forceRerender++;
        }, 100);
      }
    },
    deleteUserFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    getArrayIdOfUser(username) {
      return this.users.findIndex((a) => a.authentication.credentials.username === username);
    },
    onSendMail(obj) {
      if (obj.success) {
        this.$refs.resultSnackbar.showSuccess(obj.message);
      } else {
        this.$refs.resultSnackbar.showError(obj.message);
      }
    },
    filterData() {
      this.filtredUsers = this.users
        //Filtro per la ricerca
        .filter(
          (user) =>
            user.authentication.credentials.email.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            user.info.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            user.info.surname.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            this.$store.getters
              .getRoleLabel(user.roles[this.$store.getters.getProducts.CONVYAI].role)
              .toLowerCase()
              .indexOf(this.searchString.toLowerCase()) !== -1,
        )
        //Sorting per un campo
        .sort((a, b) => {
          if (a.authentication.credentials.email.toLowerCase() > b.authentication.credentials.email.toLowerCase()) {
            return 1;
          }
          if (a.authentication.credentials.email.toLowerCase() < b.authentication.credentials.email.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      return this.filtredUsers;
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
  },
};
</script>
