<template>
  <v-row>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.accessKey"
        label="Access Key"
        :type="showPasswordAccessKey ? 'text' : 'password'"
        :rules="[requiredField]"
        prepend-inner-icon="mdi-key"
        autocomplete="new-password"
      >
        <template slot="append">
          <v-btn depressed icon text :disabled="engine.accessKey && engine.accessKey.length < 1" tabindex="-1">
            <v-icon v-show="!showPasswordAccessKey" tabindex="-1" @click="showPasswordAccessKey = !showPasswordAccessKey">
              visibility_off
            </v-icon>
            <v-icon v-show="showPasswordAccessKey" tabindex="-1" @click="showPasswordAccessKey = !showPasswordAccessKey">
              visibility
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-text-field
        v-model.trim="engine.secretKey"
        label="Secret Key"
        :type="showPasswordSecretKey ? 'text' : 'password'"
        :rules="[requiredField]"
        prepend-inner-icon="lock"
        autocomplete="new-password"
      >
        <template slot="append">
          <v-btn depressed icon text :disabled="engine.secretKey && engine.secretKey.length < 1" tabindex="-1">
            <v-icon v-show="!showPasswordSecretKey" tabindex="-1" @click="showPasswordSecretKey = !showPasswordSecretKey">
              visibility_off
            </v-icon>
            <v-icon v-show="showPasswordSecretKey" tabindex="-1" @click="showPasswordSecretKey = !showPasswordSecretKey">
              visibility
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-autocomplete
        v-model="engine.params.completion_engine"
        :items="completionEngine"
        label="Engine"
        :rules="[requiredField]"
        prepend-inner-icon="mdi-engine"
        auto-select-first
        :return-object="false"
      />
    </v-col>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-autocomplete v-model="engine.region" :items="awsRegion" label="Region" :rules="[requiredField]" prepend-inner-icon="vpn_lock" />
    </v-col>
    <v-col cols="12" :class="getFormColMargin">
      <v-subheader class="pl-0">
        Intent validity threshold
      </v-subheader>
      <v-slider
        v-model="engine.threshold"
        min="0"
        max="100"
        thumb-label="always"
        thumb-size="22"
        class="mt-6"
      >
        <template #prepend>
          0
        </template>
        <template #append>
          100
        </template>
      </v-slider>
    </v-col>
    <!-- advanced configuration -->
    <v-expansion-panels class="my-6 mx-0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon color="primary">mdi-tune</v-icon>
            <span class="ml-4">Advanced parameters</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.embedding_engine" label="Embedding Engine" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.max_token_completion" label="Max Token Completion" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.temperature" label="Temperature" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_p" label="Top P" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_k_model" label="Top K" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_k" label="Number of extracted chunks from the knowledge base" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Bedrock",
  props: ["engine", "isEdit"],
  data() {
    return {
      awsRegion: [],
      completionEngine: [
        { text: "Anthropic Claude 3 - Opus", value: "anthropic.claude-3-opus-20240229-v1:0"},
        { text: "Anthropic Claude 3 - Sonnet", value: "anthropic.claude-3-sonnet-20240229-v1:0" },
        { text: "Anthropic Claude 3 - Haiku", value: "anthropic.claude-3-haiku-20240307-v1:0" },
        { text: "Anthropic Claude 2.1", value: "anthropic.claude-v2:1" },
        { text: "Anthropic Claude 2", value: "anthropic.claude-v2" },
        { text: "Anthropic Claude Instant", value: "anthropic.claude-instant-v1" },
      ],
      showPasswordAccessKey: false,
      showPasswordSecretKey: false,
    };
  },
  computed: {
    ...spacing,
  },
  created() {
    if (!this.engine.params) {
      // se lo creo da zero gli metto di default il cohere
      this.engine.params = {
        completion_engine: this.completionEngine[0].value,
        embedding_engine: "cohere.embed-multilingual-v3",
        max_token_completion: "1000",
        temperature: "0.5",
        top_p: "1",
        top_k_model: "250",
        top_k: "3",
        frequency_penality: 0,
        presence_penality: 0,
      };
    }
    //metto un default per quelli vecchi
    if (!this.engine.params.top_k_model) {
      this.engine.params.top_k_model = "250";
    }
    if (!this.engine.threshold && this.engine.threshold != 0) {
      this.engine.threshold = 50;
    }
    if (!this.engine.params.embedding_engine) {
      //se l'engine è già creato di default gli metto il titan per retocompatibilità
      this.engine.params.embedding_engine = "amazon.titan-embed-text-v1"; //per non spaccare quelli già esistenti
    }
  },
  mounted() {
    //* gestione region
    for (let i in this.$store.getters.getAwsRegionCode) {
      this.awsRegion.push(this.$store.getters.getAwsRegionCode[i].text);
      if (this.engine.region == this.$store.getters.getAwsRegionCode[i].value) {
        this.engine.region = this.$store.getters.getAwsRegionCode[i].text;
      }
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
<style scoped>
.field-label {
  font-size: 13px;
}
.apikey-icon-prepend {
  font-size: 22px !important;
  position: relative !important;
  bottom: -4px;
}
.apikey-icon-append {
  font-size: 22px !important;
  margin-top: -4px !important;
}
</style>
