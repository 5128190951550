<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <!-- dialog di risposta del tester -->
    <v-dialog v-model="dialogDetail" max-width="1500" @click:outside="dialogDetail = false">
      <v-card>
        <v-card-title class="headline">
          Response Detail
          <v-btn class="ml-3" large text icon @click.prevent="copyTestResponse()">
            <v-icon left class="ml-2">
              mdi-content-copy
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" large text icon @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div style="position: relative; height: 100%">
                <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" style="height: 55vh">
                  <pre class="activityDatatablePreHeight">{{ getDetailResponseFormatted }}</pre>
                </perfect-scrollbar>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- card principale -->
    <v-row :class="getMainRowMarginNoBottom">
      <!-- colonna della configurazione intenti ed entità -->
      <v-col md="9" cols="12" class="pr-1">
        <v-card style="height: 88vh" class="semanticEngineMainCard">
          <v-card-title>
            <img :alt="engineType" height="75" :src="getLogo(engineType)">
            <div class="display-1 ml-5">
              {{ engineName }}
            </div>
            <v-spacer />
            <v-btn :class="getButtonMargin" :loading="isUpdatingEngine" color="success" @click="saveSemanticEngine">
              <v-icon left>
                mdi-floppy
              </v-icon>Update
            </v-btn>
          </v-card-title>
          <v-divider class="primary" />
          <v-card-text>
            <EudataEngine
              v-if="isEudataEngine"
              ref="eudataEngine"
              :semanticEngine="semanticEngine"
              :datasetIntents="datasetIntents"
              :engineName="engineName"
              :originalIntents="originalIntents"
            />
            <GenericEngine
              v-else
              ref="genericEngine"
              :semanticEngine="semanticEngine"
              :datasetIntents="datasetIntents"
              :engineName="engineName"
              :originalIntents="originalIntents"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- colonna del testing -->
      <v-col cols="3" class="pl-1 hidden-sm-and-down">
        <v-card style="height: 88vh">
          <v-card-title style="height: 107px">
            <v-icon x-large>
              mdi-test-tube
            </v-icon>
            <div class="title ml-5">
              Test Engine
            </div>
          </v-card-title>
          <v-divider class="primary" />
          <v-card-text>
            <div class="subtitle-2">
              Try the effectiveness of your intent checking here the responses
            </div>
            <v-combobox
              v-if="semanticEngine && engineName && semanticEngine.engines[engineName].lexApiVersion == 'v2'"
              v-model="locale"
              :items="defaultLocale"
              label="Language"
              prepend-inner-icon="vpn_lock"
            />
            <v-textarea
              v-model.trim="testText"
              auto-grow
              rows="1"
              class="mt-2"
              prepend-inner-icon="mdi-format-quote-open"
              label="The user says..."
              append-icon="mdi-send"
              :autocomplete="$store.getters.disableAutocomplete"
              @keydown.enter.prevent="sendRequest"
              @click:append="sendRequest"
            />
            <v-divider />
            <v-card v-if="alreadyTest" tile elevation="0" :loading="isLoadingEngineResponse">
              <v-card-title class="subtitle-1 pb-0 mb-0">
                Input sentence:
              </v-card-title>
              <v-subheader class="pt-0 mt-0" style="height: 30px">
                {{ lastTestedText }}
              </v-subheader>
              <v-card-title class="subtitle-1 pb-0">
                Engine Response
              </v-card-title>
              <v-card-text v-if="engineTestResponse">
                <v-container v-if="engineTestResponse.intents.length != 0" fluid>
                  <div class="subtitle-1">
                    Intents:
                  </div>
                  <div v-for="(intent, index) in engineTestResponse.intents" :key="index">
                    <v-tooltip v-if="intent.name" color="primary" left>
                      <template #activator="{ on }">
                        <span class="font-weight-bold" v-on="on">{{ intent.label }}</span>
                      </template>
                      <span>{{ intent.name }}</span>
                    </v-tooltip>
                    <span v-else class="font-weight-bold">{{ intent.label }}</span>
                    <span v-if="intent.score">: {{ intent.score }}</span>
                  </div>
                </v-container>
                <v-container v-else fluid>
                  No Intents Found
                </v-container>
                <v-container v-if="engineTestResponse.entities.length != 0" fluid>
                  <div class="subtitle-1">
                    Entities:
                  </div>
                  <div v-for="(entity, index) in engineTestResponse.entities" :key="index">
                    <span class="font-weight-bold">{{ entity.name }}</span>
                    <span v-if="entity.value">: {{ entity.value }}</span>
                  </div>
                </v-container>
                <v-container v-else fluid>
                  No Entity Found
                </v-container>
                <v-container v-if="engineTestResponse.response" fluid>
                  <div class="subtitle-1">
                    Chat Response:
                  </div>
                  <div>{{ engineTestResponse.response }}</div>
                </v-container>
                <v-container v-if="engineTestResponse.language" fluid>
                  <div class="subtitle-1">
                    Language code:
                  </div>
                  <div>{{ engineTestResponse.language }}</div>
                </v-container>
                <v-container v-if="engineTestResponse.sentiment" fluid>
                  <div class="subtitle-1">
                    Sentiment:
                  </div>
                  <div>{{ engineTestResponse.sentiment }}</div>
                </v-container>
              </v-card-text>
              <v-card-actions v-if="engineTestResponse">
                <v-btn small color="primary" :disabled="testResponse == ''" @click="dialogDetail = true">
                  <v-icon left>
                    mdi-magnify
                  </v-icon>Detail
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SemanticEnginesTestResponse from "../../helpers/semanticEnginesTestResponse";
import EudataEngine from "./SemanticEngineIntentAndEntitiesManage/EudataEngine";
import GenericEngine from "./SemanticEngineIntentAndEntitiesManage/GenericEngine";
import merge from "deepmerge";
import EventBus from "../../event-bus";

export default {
  name: "SemanticEngineIntentsAndEntities",
  components: {
    GenericEngine,
    ResultSnackbar,
    EudataEngine,
  },
  data() {
    return {
      defaultLocale: [],
      isUpdatingEngine: false,
      engineName: "",
      engineType: "",
      semanticEngine: null,

      datasetIntents: [],
      originalIntents: {},
      enableUpdate: true,
      cardHeight: 0,
      testResponse: "",
      testText: "",
      locale: "",
      lastTestedText: "",
      engineTestResponse: null,
      isLoadingEngineResponse: false,
      dialogDetail: false,
      alreadyTest: false,
    };
  },
  computed: {
    ...spacing,
    calcDatatableHeight() {
      return this.cardHeight - 285;
    },
    getDetailResponseFormatted() {
      return JSON.stringify(this.testResponse, null, 2);
    },
    isEudataEngine() {
      return this.$route.query.engineType === "EUDATA";
    },
  },
  async mounted() {
    const browserLanguage = navigator.language.replace("-", "_"); //i browser hanno it-IT mentre aws it_IT
    for (let country in this.$store.state.enums.lexV2LanguageSupport) {
      const obj = {
        text: country,
        value: this.$store.state.enums.lexV2LanguageSupport[country],
      };
      this.defaultLocale.push(obj);
      if (!this.locale || browserLanguage == obj.value) {
        this.locale = obj;
      }
    }
    EventBus.$on(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE, this.disableUpdateOnEnter);
    EventBus.$on(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE, this.enableUpdateOnEnter);
    if (!this.$route.query.engineName) {
      return await this.$router.push("/semanticEngines");
    }
    let card = document.querySelector(".semanticEngineMainCard");
    if (card) {
      this.cardHeight = card.offsetHeight;
    }
    this.engineName = this.$route.query.engineName;
    this.engineType = this.$route.query.engineType;

    try {
      const response = await this.$http.get("/semantic-engine/" + this.engineName);
      this.semanticEngine = response.data;
      for (let intent in this.semanticEngine.engines[this.engineName].intents) {
        let obj = {
          key: intent,
          value: this.semanticEngine.engines[this.engineName].intents[intent],
        };
        if (this.semanticEngine.engines[this.engineName].intentsAttributes && this.semanticEngine.engines[this.engineName].intentsAttributes[intent]) {
          obj = merge(obj, this.semanticEngine.engines[this.engineName].intentsAttributes[intent]);
          obj.enableTrain = !obj.easyConf;
        }
        this.datasetIntents.push(obj);
      }
      if (this.semanticEngine.engines[this.engineName].type === "EUDATA") {
        //Sanifico le entità con extraction_pattern come stringa
        for (let entity in this.semanticEngine.entity_types) {
          if (typeof this.semanticEngine.entity_types[entity].extraction_pattern == "string") {
            this.semanticEngine.entity_types[entity].extraction_pattern = [this.semanticEngine.entity_types[entity].extraction_pattern];
          }
        }
      }
      if (this.semanticEngine.engines[this.engineName].type !== "EUDATA") {
        this.semanticEngine.engines[this.engineName].types["TEXT"] = {};
        this.semanticEngine.engines[this.engineName].types["TEXT"] = "TEXT";
      }
      this.injectDataset();
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
    window.addEventListener("keypress", this.onPressEnter);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.onPressEnter);
    EventBus.$off(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE, this.disableUpdateOnEnter);
    EventBus.$off(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE, this.enableUpdateOnEnter);
  },
  methods: {
    ...fieldValidators,
    ...SemanticEnginesTestResponse,
    copyTestResponse() {
      try {
        navigator.clipboard.writeText(JSON.stringify(this.testResponse, null, 2));
        this.onCopy();
      } catch (err) {
        this.onError();
      }
    },
    enableUpdateOnEnter() {
      this.enableUpdate = true;
    },
    disableUpdateOnEnter() {
      this.enableUpdate = false;
    },
    onPressEnter(e) {
      if (e.keyCode === "13" && this.enableUpdate) {
        this.saveSemanticEngine();
      }
    },
    async sendRequest() {
      this.lastTestedText = this.testText;
      this.testResponse = {};
      this.engineTestResponse = null;
      this.isLoadingEngineResponse = true;
      this.alreadyTest = true;
      try {
        const data = { text: this.testText };
        if (this.engineType == "LEX") {
          data.locale = this.locale.value || this.locale;
        }
        const response = await this.$http.post("/semantic-engine-test/" + this.engineName, data);
        switch (this.engineType) {
          case "APIAI":
            this.engineTestResponse = this.getDialogFlowResponse(response.data[0].queryResult);
            this.testResponse = response.data[0].queryResult;
            break;
          case "COGITO":
            this.engineTestResponse = this.getCogitoResponse(response.data);
            this.testResponse = response.data;
            break;
          case "LEX":
            this.engineTestResponse = this.getLexResponse(response.data);
            this.testResponse = response.data;
            break;
          case "LUIS":
            this.engineTestResponse = this.getLuisResponse(response.data);
            this.testResponse = response.data;
            break;
          case "WATSON":
            this.engineTestResponse = this.getWatsonResponse(response.data.result.output);
            this.testResponse = response.data.result.output;
            break;
          case "EUDATA":
            this.engineTestResponse = this.getEudataResponse(response.data);
            this.testResponse = response.data;
            break;
          case "OPENAI":
            this.engineTestResponse = this.getOpenAiResponse(response.data);
            this.testResponse = response.data;
            break;
          case "CUSTOMENGINE":
            this.engineTestResponse = this.getCustomEngineResponse(response.data);
            this.testResponse = response.data;
            break;
          case "BEDROCK":
            this.engineTestResponse = this.getBedrockResponse(response.data);
            this.testResponse = response.data;
            break;
        }
        this.isLoadingEngineResponse = false;
        if (this.testResponse.error) {
          this.$refs.resultSnackbar.showError("Unable to Test: Please check the response details!");
        }
      } catch (e) {
        if (this.engineType === "LEX" && e.response.data.err == "ResourceNotFoundException") {
          this.$refs.resultSnackbar.showError("Unable to Test: Please check the language code!");
        } else {
          this.$refs.resultSnackbar.showError("Unable to Test: Please check the semantic engine parameters!");
        }
        this.isLoadingEngineResponse = false;
      } finally {
        this.testText = "";
      }
    },
    getLogo(engine) {
      //* logo per engine già presenti
      const types = this.$store.getters.getSemanticEnginesTypes;
      for (let typeConf of types) {
        if (engine === typeConf.type) {
          if (engine.indexOf("COGITO") !== -1 && this.$vuetify.theme.dark) {
            return typeConf.imageDark;
          }
          return typeConf.image;
        }
      }
    },
    async saveSemanticEngine() {
      if (this.engineType === "APIAI" && this.semanticEngine.engines[this.engineName].token && !this.semanticEngine.engines[this.engineName].projectId) {
        this.$refs.resultSnackbar.showError("ConvyAI supports DialogFlow agents with version 2. Upgrade it.");
      } else {
        this.isUpdatingEngine = true;
        this.semanticEngine.engines[this.engineName].intents = {};
        if (this.engineType === "COGITO") {
          this.semanticEngine.engines[this.engineName].intentsAttributes = {};
        }
        this.datasetIntents = this.isEudataEngine ? this.$refs.eudataEngine.getDatasetIntentsUpdated() : this.$refs.genericEngine.getDatasetIntentsUpdated();

        for (let i = 0; i < this.datasetIntents.length; i++) {
          this.semanticEngine.engines[this.engineName].intents[this.datasetIntents[i].key] = this.datasetIntents[i].value;
          if (this.engineType === "COGITO") {
            /* eslint-disable no-prototype-builtins */
            if (!this.datasetIntents[i].hasOwnProperty("enabled")) {
              this.datasetIntents[i].enabled = true;
            }
            /* eslint-enable no-prototype-builtins */
            this.semanticEngine.engines[this.engineName].intentsAttributes[this.datasetIntents[i].key] = {
              value: this.datasetIntents[i].value,
              enabled: this.datasetIntents[i].enabled,
            };
          }
        }
        if (this.semanticEngine.engines[this.engineName].type === "EUDATA") {
          delete this.semanticEngine.engines[this.engineName].intents[""];
        }

        //Sanificazione entity TEXT in basic
        this.semanticEngine.entity_types["TEXT"] = {
          type: "basic",
          extraction_pattern: ".+",
          validation_expression: "'%value%'.length>0",
        };
        //devo passare anche gli original se no non può propagare le modifiche sui flussi
        this.semanticEngine.engines[this.engineName].originalIntents = this.originalIntents;
        try {
          this.semanticEngine.name = this.engineName;
          this.semanticEngine.type = this.engineType;
          await this.$http.put("/semantic-engine/" + this.engineName, this.semanticEngine);
          this.$refs.resultSnackbar.showSuccess("Update Semantic Engine Complete!");
        } catch (err) {
          this.$refs.resultSnackbar.showError("Error to Update: " + err.message);
        } finally {
          this.isUpdatingEngine = false;
        }
      }
    },
    injectDataset() {
      if (this.isEudataEngine) {
        this.$refs.eudataEngine.setDataset(this.datasetIntents);
      } else {
        this.$refs.genericEngine.setDataset(this.datasetIntents);
      }
    },
    onCopy() {
      this.$refs.resultSnackbar.showSuccess("Response Copied to Clipboard!");
    },
    onError() {
      this.$refs.resultSnackbar.showError("Error Copy to Clipboard!");
    },
    intentsImported: function (data) {
      this.datasetIntents = data;
      this.$refs.resultSnackbar.showSuccess("Intents imported successfully!");
    },
    entitiesImported: function (data) {
      this.semanticEngine.engines[this.engineName].types = {};
      this.semanticEngine.engines[this.engineName].types = data;
      this.$refs.resultSnackbar.showSuccess("Entities imported successfully!");
    },
  },
};
</script>

<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}
.activityDatatablePreHeight {
  height: 100%;
  position: absolute;
}
.tocopy-class {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -999999 !important;
  opacity: 0 !important;
}
</style>
