import store from "../store";

function hasRequiredRole(requiredRole) {
  let roles = Object.values(store.state.enums.roles);
  let requiredRoleIndex = roles.indexOf(requiredRole);
  let userRoleIndex = roles.indexOf(store.getters.getUserRole);
  return userRoleIndex !== -1 && requiredRoleIndex >= userRoleIndex;
}

function hasRequiredRoleExcept(notRequiredRoles) {
  //filtro sui singoli ruoli
  return notRequiredRoles.includes(store.getters.getUserRole);
}

function hasRequiredPermission(requiredPermission) {
  if (!store.getters.getPermissions) return false;
  return store.getters.getPermissions.indexOf(requiredPermission) !== -1;
}
function hasWcsIntegration() {
  return store.getters.isIntegrationEnabled("wcs");
}

function hasJanusIntegration() {
  return store.state.loginResponse.params.serverConfigurations.enableJanusFeature && store.getters.isIntegrationEnabled("janus");
}

function hasRequiredRoleAndPermission(requiredRole, requiredPermission) {
  return hasRequiredPermission(requiredPermission) && hasRequiredRole(requiredRole);
}

export default {
  hasRequiredPermission,
  hasRequiredRole,
  hasRequiredRoleExcept,
  hasRequiredRoleAndPermission,
  hasWcsIntegration,
  hasJanusIntegration,
};
