<template>
  <v-row v-if="!intoModal" :class="nomargin ? '' : 'mt-4 mx-6'">
    <v-col v-if="title" class="d-none d-md-block subtitle-1" cols="0" md="8">
      {{ title }}
    </v-col>
    <v-col v-else cols="0" md="8" />
    <v-col cols="12" md="4" class="search">
      <v-text-field
        dense
        width="200px"
        hide-details
        outlined
        clearable
        label="Search..."
        prepend-inner-icon="search"
        :value="searchString"
        :autocomplete="$store.getters.disableAutocomplete"
        @input="$emit('input', $event ? $event : '')"
        @click:clear="$emit('input', '')"
      />
    </v-col>
  </v-row>
  <v-row v-else no-gutters>
    <v-col cols="8" offset="4">
      <v-text-field
        dense
        hide-details
        outlined
        clearable
        label="Search..."
        prepend-inner-icon="search"
        :value="searchString"
        :autocomplete="$store.getters.disableAutocomplete"
        @input="$emit('input', $event ? $event : '')"
        @click:clear="$emit('input', '')"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SearchBar",
  props: ["searchString", "title", "nomargin", "intoModal"],
};
</script>
