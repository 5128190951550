<template>
  <v-col
    cols="12"
    :lg="!wallboard.resize ? 6 : 12"
    style="margin: 0 !important"
  >
    <v-card
      class="v-sheet--offset mx-auto"
      color="transparent"
    >
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selected-view="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
        @onChangeView="onChangeView"
      />
      <span @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-chart
          v-if="selectedView == 'chart'"
          ref="taskStatusByChannel"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart mt-5"
          :option="options"
        />
        <div v-else-if="selectedView == 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="channel"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            must-sort
            sort-by="channel"
            dense
          >
            <template #top>
              <v-text-field
                v-model="viewTable.search"
                label="Search"
                class="mx-4"
              />
            </template>
          </v-data-table>
        </div>
      </span>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "TaskStatusByChannel",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    }
  },
  data() {
    let channelIcons = {
      chat: require("@/assets/img/channelsIcons/chat-color.png"),
      audiovideo: require("@/assets/img/channelsIcons/audiovideo.png"),
      whatsapp: require("@/assets/img/channelsIcons/whatsapp-color.png"),
      telegram: require("@/assets/img/channelsIcons/telegram-color.png"),
      twitter: require("@/assets/img/channelsIcons/twitter-color.png"),
      facebookmessenger: require("@/assets/img/channelsIcons/facebook-messenger-color.png"),
      webexmeeting: require("@/assets/img/channelsIcons/webex-color.png"),
      applebusinesschat: require("@/assets/img/channelsIcons/apple.png"),
      email: require("@/assets/img/channelsIcons/email-outline-color.png"),
      googlehome: require("@/assets/img/channelsIcons/assistant.png"),
      googlebusinessmessages: require("@/assets/img/channelsIcons/googlebusinessmessages.png"),
      amazonalexa: require("@/assets/img/channelsIcons/alexa.png"),
    };
    return {
      info: "This widget shows the number of active conversations for each channel, splitted between the current ones assigned to agents and the others still in queue",
      reloadWidgetDataInterval: null,

      options: {
        legend: {},
        tooltip: {
          trigger: "axis",
        },
        dataset: {
          source: [["Task", "Assigned", "Queued"]],
        },
        xAxis: {
          type: "category",
          /* axisLabel: {
            inside: true,
            rotate: 90,
            color: this.$vuetify.theme.dark ? "white" : "white",
            textShadowColor: "rgba(0, 0, 0, 0.9)",
            textShadowOffsetX: "1",
            textShadowOffsetY: "1",
            textShadowBlur: "2",
            fontSize: 16,
          }, */
          // data: ['chat', 'whatsapp', 'telegram','twitter','facebookmessenger','webexmeeting'],
          axisLabel: {
            formatter: function (value) {
              return "{" + value + "| }";
            },
            interval: 0,
            margin: 20,
            rich: {
              value: {
                lineheight: 25,
                align: "center",
              },
              chat: {
                height: 25,
                align: "center",
                backgroundColor: {
                  image: channelIcons.chat,
                },
              },
              whatsapp: {
                height: 25,
                align: "center",
                backgroundColor: {
                  image: channelIcons.whatsapp,
                },
              },
              telegram: {
                height: 25,
                align: "center",
                backgroundColor: {
                  image: channelIcons.telegram,
                },
              },
              facebookmessenger: {
                height: 22,
                align: "center",
                backgroundColor: {
                  image: channelIcons.facebookmessenger,
                },
              },
              webexmeeting: {
                height: 25,
                align: "center",
                backgroundColor: {
                  image: channelIcons.webexmeeting,
                },
              },
              applebusinesschat: {
                height: 25,
                align: "center",
                backgroundColor: {
                  image: channelIcons.applebusinesschat,
                },
              },
              email: {
                height: 24,
                align: "center",
                backgroundColor: {
                  image: channelIcons.email,
                },
              },
              googlehome: {
                height: 22,
                align: "center",
                backgroundColor: {
                  image: channelIcons.googlehome,
                },
              },
              googlebusinessmessages: {
                height: 22,
                align: "center",
                backgroundColor: {
                  image: channelIcons.googlebusinessmessages,
                },
              },
              amazonalexa: {
                height: 22,
                align: "center",
                backgroundColor: {
                  image: channelIcons.amazonalexa,
                },
              },
              audiovideo: {
                height: 24,
                align: "center",
                backgroundColor: {
                  image: channelIcons.audiovideo,
                },
              },
            },
          },
          z: 10000,
        },
        yAxis: {
          minInterval: 1,
          name: "Conversations #",
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            stack: "channel",
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            //  barWidth: '80%',
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
              // borderWidth: 2
            },
          },
          {
            type: "bar",
            stack: "channel",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            //  barWidth: '80%',
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
              // borderWidth: 2
            },
          },
        ],
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
      viewTable: {
        search: "",
        headers: [
          {
            text: "Channel",
            align: "start",
            sortable: true,
            value: "channel",
          },
          {
            text: "Assigned",
            align: "center",
            sortable: true,
            value: "assigned",
            width: 150,
          },
          {
            text: "Queued",
            align: "center",
            sortable: true,
            value: "queued",
            width: 150,
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    async loadWidgetData() {
      try {
        const result = await this.$httpAuth.get("/dashboard/wallboard/task-overview");
        this.options.dataset.source = [["Task", "Assigned", "Queued"]];
        this.viewTable.data = [];
        for (const channel in result.data.channel) {
          const array = [channel];
          array.push(result.data.channel[channel].assigned);
          array.push(result.data.channel[channel].queued);
          this.options.dataset.source.push(array);

          this.viewTable.data.push({
            channel: channel,
            assigned: result.data.channel[channel].assigned,
            queued: result.data.channel[channel].queued,
          });
        }
        if (this.$refs["taskStatusByChannel"]) {
          this.$refs["taskStatusByChannel"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
