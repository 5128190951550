<template>
  <v-container>
    <ResultSnackbar ref="resultSnackbar" />

    <ConfirmDialog :showConfirm="showConfirm" :message="message" @dialogConfirm="onUserConfirm" @dialogDeny="showConfirm = false" />

    <v-row>
      <v-col cols="12">
        <!-- <v-textarea v-model.trim="flow" autofocus full-width no-resize solo class="advancedFlowTextArea"> </v-textarea> -->
        <JsonEditorVue
          v-model="flow"
          :mainMenuBar="false"
          mode="text"
          style="height: calc(100vh - 200px) !important"
          class="ma-3"
          :class="isDarkMode ? 'jse-theme-dark' : ''"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn :class="getButtonMargin" color="primary" @click.prevent="back">
          <v-icon small color class="ma-0 pr-2">
            keyboard_backspace
          </v-icon>
          <span>Back</span>
        </v-btn>
        <v-btn :class="getButtonMargin" class="ml-4" color="success" @click.prevent="update">
          <v-icon left>
            mdi-floppy
          </v-icon>
          <span>Update</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";

export default {
  name: "AdvancedEdit",
  components: {
    ResultSnackbar,
    ConfirmDialog,
  },
  data() {
    return {
      showConfirm: false,
      message: "This flow will be overwritten",
      company: this.$store.getters.getSelectedCompany,
      flow: "",
    };
  },
  computed: {
    isDarkMode() {
      this.forceRerender;
      return this.$vuetify.theme.dark;
    },
  },
  async mounted() {
    try {
      const response = await this.$http.get("/flow/" + this.$route.query.botId + "/" + this.$route.query.flow + "/" + this.$route.query.version);
      //this.flow = JSON.stringify(response.data, null, 2);
      this.flow = response.data;
    } catch (e) {
      this.$refs.resultSnackbar.showError("Fail to retrieve flow!");
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...spacing,
    back() {
      window.history.back();
    },
    update() {
      this.showConfirm = true;
    },
    async onUserConfirm() {
      this.showConfirm = false;
      try {
        let newFlow = JSON.parse(this.flow);
        await this.$http.put("/flow/" + this.$route.query["botId"] + "/" + this.$route.query["flow"] + "/" + this.$route.query["version"], newFlow);
        this.$refs.resultSnackbar.showSuccess("Flow updated!");
      } catch (error) {
        this.$refs.resultSnackbar.showError("Fail to update flow!");
      }
    },
  },
};
</script>

<style>
.advancedFlowTextArea textarea {
  height: calc(100vh - 200px) !important;
}
</style>
