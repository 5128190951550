<template>
  <v-row class="mt-6 mx-4">
    <v-col cols="12" lg="3">
      <LiveCards :liveConversations="liveConversations" :liveHandover="liveHandover" />
    </v-col>

    <v-col v-if="loaded" cols="12" lg="4">
      <div align="center" style="height: 280px">
        <VueApexCharts
          :key="forceRerenderGraph"
          type="bar"
          class="botEfficiency"
          height="280"
          :options="chartOptions"
          :series="series"
        />
      </div>
      <p class="headline text-center mt-2 mb-0">
        <span class="hidden-md-and-down">Conversations Trend</span>
        <v-icon v-if="percentage > 0" class="ml-8 mr-1" large>
          trending_up
        </v-icon>
        <v-icon v-else-if="percentage < 0" class="ml-8 mr-1" large>
          trending_down
        </v-icon>
        <v-icon v-else class="ml-8 mr-1" large>
          trending_flat
        </v-icon>
        {{ percentage }}%
      </p>
    </v-col>
    <v-col v-else>
      <v-progress-linear value="0" :indeterminate="true" />
    </v-col>

    <v-col cols="12" lg="5" align="center" class="pr-6">
      <companyEfficiencyWidget :botList="botList" :botId="widget.conf.bot.botId" :botOverviewData="botOverviewData" />
    </v-col>
  </v-row>
</template>

<script>
import companyEfficiencyWidget from "./Charts/CompanyEfficiencyWidget";
import LiveCards from "./Charts/LiveCards";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BotEfficiency",
  components: {
    companyEfficiencyWidget,
    VueApexCharts,
    LiveCards,
  },
  props: {
    "widget": {
      type: Object,
      required: true
    },
    "botList": {
      type: Array,
      required: true
    },
    "botOverviewData": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      forceRerenderGraph: 0,
      liveConversations: null,
      liveHandover: null,
      loaded: false,
      percentage: 0,
      intervalID: null,
      chartOptions: {
        xaxis: {
          categories: ["Past Month", "Current Month"],
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        chart: {
          height: 280,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
      },
      series: [
        {
          name: "Conversations",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.request().then(() => {
      this.intervalID = setInterval(() => {
        this.request();
      }, 5000);
    });

    if (this.$vuetify.theme.dark) {
      //* gestione colori assi del grafico
      this.chartOptions.xaxis.labels.style.colors = "#ffffff";
      this.chartOptions.yaxis.labels.style.colors = "#ffffff";
    }
  },
  beforeDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  methods: {
    async request() {
      try {
        const currentConversationResponse = await this.$http.get(
          "/analytics/current-conversations/" + this.$store.getters.getSelectedCompany + "/" + this.widget.conf.bot.botId,
          { timeout: 300000 },
        );
        const companyBotStatisticsResponse = await this.$httpAuth.get("/dashboard/bot-statistics/" + this.widget.conf.bot.botId, { timeout: 300000 });
        this.liveConversations = currentConversationResponse.data.progress;
        this.liveHandover = currentConversationResponse.data.handover;

        const pastMonth =
          companyBotStatisticsResponse.data.dashboard && companyBotStatisticsResponse.data.dashboard.trendConversations
            ? companyBotStatisticsResponse.data.dashboard.trendConversations.previousMonth
            : 0;
        const thisMonth =
          companyBotStatisticsResponse.data.dashboard && companyBotStatisticsResponse.data.dashboard.trendConversations
            ? companyBotStatisticsResponse.data.dashboard.trendConversations.currentMonth
            : 0;
        if (pastMonth !== 0) {
          this.percentage = this.round(((thisMonth - pastMonth) / pastMonth) * 100);
        }
        let forceReload = false;
        if (this.series[0].data && this.series[0].data.length > 0) {
          if (this.series[0].data[0] !== pastMonth || this.series[0].data[1] !== thisMonth) {
            forceReload = true;
          }
        }
        this.series[0].data = [];
        this.series[0].data.push(pastMonth);
        this.series[0].data.push(thisMonth);
        if (forceReload) {
          this.forceRerenderGraph++;
        }
      } finally {
        this.loaded = true;
      }
    },
    round(num) {
      return Math.round(num * 100) / 100;
    },
  },
};
</script>

<style>
.botEfficiency .apexcharts-tooltip {
  background: #f3f3f3;
  color: black;
}
</style>
