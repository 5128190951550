<template>
  <div>
    <!-- version 1 -->
    <template v-if="radioButton == 'version1'">
      <v-row :class="getFormRowMargin">
        <v-alert type="warning" dense outlined text>
          <span v-html="warningText" />
        </v-alert>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-radio-group v-model="radioButton" row>
          <v-radio label="Version 1" value="version1" color="primary" />
          <v-radio label="Version 2" value="version2" color="primary" />
        </v-radio-group>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="5" :class="getFormColMargin">
          <v-text-field
            v-model="engine.token"
            label="Token"
            readonly
          />
        </v-col>
      </v-row>
    </template>

    <!-- version 2 -->
    <template v-else>
      <v-row v-if="engine.token" :class="getFormRowMargin">
        <v-radio-group v-model="radioButton" row>
          <v-radio label="Version 1" value="version1" color="primary" />
          <v-radio label="Version 2" value="version2" color="primary" />
        </v-radio-group>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          You can add your credentials by uploading them below or completing the
          inputs:
        </v-col>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-file-input
            v-model.trim="jsonFile"
            label="Click to upload the json configuration file given by Google Cloud Platform"
            dense
            prepend-inner-icon="mdi-file-document"
            prepend-icon=""
            show-size
            accept=".json"
            :error-messages="errorMessage"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :disabled="jsonFile == null"
            @click.prevent="importFile"
          >
            <v-icon left>
              mdi-upload
            </v-icon>
            Import JSON
          </v-btn>
        </v-col>
      </v-row>
      <v-row :class="getFormRowMargin">
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.projectId"
            label="project Id"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-card-bulleted"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.clientEmail"
            label="Client email"
            :rules="[requiredField, isValidEmail]"
            prepend-inner-icon="mail"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" md="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="engine.privateKey"
            type="password"
            label="Private Key"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Apiai",
  components: {},
  props: ["engine", "isEdit"],
  data() {
    return {
      jsonFile: null,
      errorMessage: "",
      radioButton: "",
      warningText:
        "Warning: version 1 of Dialogflow's API will be shut down on March 31, 2020 check <a href='https://dialogflow.com/docs/reference/v2-auth-setup' target='_blank'><b>here</b></a> for more details."
    };
  },
  computed: {
    ...spacing
  },
  mounted() {
    if (this.engine.token) {
      this.radioButton = "version1";
    } else {
      this.radioButton = "version2";
    }
  },
  methods: {
    ...fieldValidators,
    importFile: function() {
      //* importazione file json
      let fileReader = new FileReader();
      fileReader.readAsText(this.jsonFile);

      fileReader.onload = e => {
        let result = JSON.parse(e.target.result);
        if (result.project_id && result.private_key && result.client_email) {
          this.engine.projectId = result.project_id;
          this.engine.privateKey = result.private_key;
          this.engine.clientEmail = result.client_email;
          this.errorMessage = "";
        } else {
          this.errorMessage =
            "It's not the right file, please check on the Google Platform!";
        }
      };
    }
  }
};
</script>
