<template>
  <v-container fluid>
    <ConfirmationDialog
      :showConfirm="confirmDialog"
      message="Are you sure to delete Live Person's configuration?"
      @dialogDeny="confirmDialog = false"
      @dialogConfirm="cancelLivePersonConfiguration"
    />
    <ResultSnackbar ref="resultSnackbar" />
    <v-row justify="center" :class="getMainRowMarginNoSearchbar">
      <v-expansion-panels v-model="panel" popout multiple :class="getMarginNewCard">
        <v-expansion-panel readonly>
          <v-expansion-panel-header hide-actions>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1">
                <v-avatar tile :size="getAvatarSize" contain>
                  <img alt="Avatar" :src="$store.getters.getLogoByType('livePerson')">
                </v-avatar>
              </v-col>
              <v-col cols="8">
                Live Person Connector
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="livePersonForm" v-model="form.isValid" :class="getCreationFormMargin">
              <v-subheader>Live Engage connection parameters</v-subheader>
              <v-row :class="getFormRowMargin">
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.host"
                    label="LIVE PERSON Hostname"
                    prepend-inner-icon="mdi-server-network"
                    autofocus
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.accountId"
                    label="Account Id"
                    prepend-inner-icon="lock"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>
              </v-row>
              <v-row :class="getFormRowMargin">
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.clientId"
                    label="Client Id"
                    prepend-inner-icon="lock"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                </v-col>

                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.clientSecret"
                    label="Client Secret"
                    :type="showClientSecret ? 'text' : 'password'"
                    prepend-inner-icon="lock"
                    autocomplete="new-password"
                    :error-messages="customErrorMessage"
                    :rules="[requiredField]"
                  >
                    <template slot="append">
                      <v-btn depressed text icon :disabled="livePerson.clientSecret.length < 1" tabindex="-1">
                        <v-icon v-show="!showClientSecret" tabindex="-1" @click="showClientSecret = !showClientSecret">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showClientSecret" tabindex="-1" @click="showClientSecret = !showClientSecret">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-subheader>Conversational Cloud API keys (optional)</v-subheader>
              <v-row>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.oAuth.consumer_key"
                    label="Consumer Key"
                    prepend-inner-icon="mdi-lock"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.oAuth.consumer_secret"
                    label="Consumer Secret"
                    prepend-inner-icon="mdi-lock"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.oAuth.token"
                    label="Token"
                    prepend-inner-icon="mdi-lock"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.oAuth.token_secret"
                    label="Token Secret"
                    prepend-inner-icon="mdi-lock"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.ext_consumer_id"
                    label="ext_consumer_id"
                    prepend-inner-icon="mdi-hook"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-select
                    v-model.trim="livePerson.reservedField"
                    :items="stringFields"
                    label="Reserved Field for ext_consumer_id"
                    :error-messages="customErrorMessage"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="livePerson.unsupportedFunctionality"
                    label="Message for unsupported functionality"
                    prepend-inner-icon="mdi-message-bulleted-off"
                    :rules="[checkOptionalParameters]"
                    validate-on-blur
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
              </v-row>
              <v-row v-if="livePerson.webhookUrl" :class="getFormRowMargin">
                <v-col cols="12" :class="getFormColMargin" class="d-inline-flex">
                  <v-text-field
                    v-model.trim="livePerson.webhookUrl"
                    label="Webhook URL"
                    prepend-inner-icon="mdi-webhook"
                    autofocus
                    readonly
                    disabled
                    :autocomplete="$store.getters.disableAutocomplete"
                    :rules="[requiredField]"
                  />
                  <CopyToClipboard :data="livePerson.webhookUrl" message="Webhook copied to clipboard!" />
                </v-col>
              </v-row>
              <!-- File Mapping -->
              <v-row :class="getFormRowMargin">
                <v-btn color="primary" class="ml-3" small @click="showButton = !showButton">
                  <v-icon left small>
                    mdi-pencil
                  </v-icon>
                  <span v-if="!showButton">Show parameters</span>
                  <span v-if="showButton">Hide parameters</span>
                </v-btn>
              </v-row>
              <EngagementAttributesLivePerson
                v-if="dataLoaded > 0 && showButton"
                :key="dataLoaded"
                :fieldsMapping="livePerson.fieldsMapping"
                @update="updateFieldsMapping"
              />
              <!-- Riga Actions -->
              <v-row :class="getFormRowMargin">
                <v-col>
                  <v-btn
                    :class="getButtonMargin"
                    color="success"
                    :loading="form.submitLoading"
                    :disabled="!form.isValid"
                    @click.prevent="saveLivePersonIntegration"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Save</span>
                  </v-btn>
                  <v-btn :class="getButtonMargin" color="primary" :loading="form.submitLoading" :disabled="!form.isValid" @click="testLivePersonConfiguration">
                    <v-icon left>
                      mdi-test-tube
                    </v-icon>
                    <span>Test</span>
                  </v-btn>
                  <v-btn v-if="checkButton" :class="getButtonMargin" color="error" :disabled="form.submitLoading" @click.prevent="resetLivePersonConf">
                    <v-icon left>
                      mdi-cancel
                    </v-icon>
                    <span>Cancel</span>
                  </v-btn>
                  <v-btn
                    v-else
                    :class="getButtonMargin"
                    color="error"
                    :loading="form.deleteLoading"
                    :disabled="!form.isValid"
                    @click="confirmDialog = true"
                  >
                    <v-icon left>
                      mdi-delete
                    </v-icon>
                    <span>Delete</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "../../event-bus";
import ConfirmationDialog from "../../components/other/ConfirmationDialog";
import EngagementAttributesLivePerson from "../../components/publish/EngagementAttributesLivePerson";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
import CopyToClipboard from "../../components/other/CopyToClipboard";
export default {
  name: "LivePersonIntegration",
  components: {
    EngagementAttributesLivePerson,
    ResultSnackbar,
    ConfirmationDialog,
    CopyToClipboard,
  },
  data: () => ({
    confirmDialog: false,
    panel: [0],
    customErrorMessage: null,
    showButton: false,
    showClientSecret: false,
    checkButton: false,
    dataLoaded: 0,
    form: {
      isValid: false,
      submitLoading: false,
      deleteLoading: false,
    },
    stringFields: [],
    livePerson: {
      host: "",
      accountId: "",
      clientId: "",
      clientSecret: "",
      fieldsMapping: {},
      unsupportedFunctionality: "",
      ext_consumer_id: "",
      reservedField: "",
      reservedFieldCategory: "",
      oAuth: {
        consumer_key: "",
        consumer_secret: "",
        token: "",
        token_secret: "",
      },
    },
  }),
  computed: {
    ...spacing,
    //Questo metodo controlla che o tutti gli opzionali sono settati o nessuno
    checkOptionalParameters() {
      if (
        this.livePerson.reservedField ||
        this.livePerson.oAuth.token_secret ||
        this.livePerson.oAuth.token ||
        this.livePerson.oAuth.consumer_key ||
        this.livePerson.oAuth.consumer_secret
      ) {
        if (
          this.livePerson.reservedField &&
          this.livePerson.oAuth.token_secret &&
          this.livePerson.oAuth.token &&
          this.livePerson.oAuth.consumer_key &&
          this.livePerson.oAuth.consumer_secret
        ) {
          return true;
        } else if (
          //questo è il caso in cui voglio provare a sbiancare la parte opzionale. siccome la select non posso sbiancarla allora devo validare solo se tutte le chiavi sono eliminate
          this.livePerson.reservedField &&
          !this.livePerson.oAuth.token_secret &&
          !this.livePerson.oAuth.token &&
          !this.livePerson.oAuth.consumer_key &&
          !this.livePerson.oAuth.consumer_secret
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("livePerson")) {
      this.$router.push("/");
    }
    this.stringFields = this.$store.getters.getEngagementAttributesLivePerson("stringFields");
    try {
      const result = await this.$http.get("/liveperson");
      this.livePerson = merge(this.livePerson, result.data);
    } catch (err) {
      this.checkButton = true;
    } finally {
      this.dataLoaded = 1;
      if (Object.keys(this.livePerson.fieldsMapping).length > 0) {
        this.showButton = true;
      }
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    updateFieldsMapping(fields) {
      this.livePerson.fieldsMapping = merge(this.livePerson.fieldsMapping, fields);
    },
    resetLivePersonConf() {
      this.livePerson = {
        host: "",
        accountId: "",
        clientId: "",
        clientSecret: "",
        fieldsMapping: {},
        unsupportedFunctionality: "",
        ext_consumer_id: "",
        reservedField: "",
        reservedFieldCategory: "",
        oAuth: {
          consumer_key: "",
          consumer_secret: "",
          token: "",
          token_secret: "",
        },
      };
      this.$refs.livePersonForm.resetValidation();
      this.dataLoaded++;
    },
    async cancelLivePersonConfiguration() {
      this.livePerson = {
        host: "",
        accountId: "",
        clientId: "",
        clientSecret: "",
        fieldsMapping: {},
        unsupportedFunctionality: "",
        ext_consumer_id: "",
        reservedField: "",
        reservedFieldCategory: "",
        oAuth: {
          consumer_key: "",
          consumer_secret: "",
          token: "",
          token_secret: "",
        },
      };
      try {
        await this.$http.delete("/liveperson");
        this.$refs.resultSnackbar.showSuccess("LIVE PERSON configuration deleted!");
      } catch (err) {
        this.$refs.resultSnackbar.showError("Fail to delete LIVE PERSON configuration!");
      } finally {
        this.dataLoaded++;
        this.$refs.livePersonForm.resetValidation();
        this.checkButton = true;
        this.confirmDialog = false;
      }
    },
    async saveLivePersonIntegration() {
      if (this.form.isValid) {
        if (this.checkOptionalParameters) {
          this.livePerson.reservedFieldCategory = "Personal";
          const customerInfo = this.$store.getters.getEngagementAttributesLivePerson("customerInfoChoices");
          if (customerInfo && customerInfo.includes(this.livePerson.reservedField)) {
            this.livePerson.reservedFieldCategory = "Customer";
          }
          this.form.submitLoading = true;
          try {
            const response = await this.$http.post("/liveperson", this.livePerson);
            this.livePerson = response.data;
            this.$refs.resultSnackbar.showSuccess("LIVE PERSON configuration saved!");
            this.checkButton = false;
          } catch (err) {
            this.$refs.resultSnackbar.showError("Fail to save LIVE PERSON configuration!");
          } finally {
            this.form.submitLoading = false;
          }
        }
      } else {
        this.$refs.resultSnackbar.showError("Conversational Cloud API keys are optional but all fields are mandatory if you want to enable them");
      }
    },
    async testLivePersonConfiguration() {
      if (this.form.isValid) {
        if (this.checkOptionalParameters) {
          this.livePerson.reservedFieldCategory = "Personal";
          const customerInfo = this.$store.getters.getEngagementAttributesLivePerson("customerInfoChoices");
          if (customerInfo && customerInfo.includes(this.livePerson.reservedField)) {
            this.livePerson.reservedFieldCategory = "Customer";
          }
          this.form.submitLoading = true;
          try {
            await this.$http.post("/liveperson/test", this.livePerson);
            this.$refs.resultSnackbar.reset();
            this.$refs.resultSnackbar.showSuccess("LIVE PERSON configuration tested!");
          } catch (err) {
            let message = err.message;
            if (err && err.response && err.response.data && err.response.data.error) {
              message = err.response.data.error;
            }
            let messages = ["Fail to test LIVE PERSON configuration:"];
            if (Array.isArray(message)) {
              messages = messages.concat(message);
            } else {
              messages.push(message);
            }
            this.$refs.resultSnackbar.reset();
            this.$refs.resultSnackbar.showError(messages);
          } finally {
            this.form.submitLoading = false;
          }
        }
      } else {
        this.$refs.resultSnackbar.showError("Conversational Cloud API keys are optional but all fields are mandatory if you want to enable them");
      }
    },
  },
};
</script>
