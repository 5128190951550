<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="6" sm="6" v-if="!editedConnector.uuid">
        <v-text-field
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <!-- Incoming -->
      <v-expansion-panels class="my-6">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon color="primary">mdi-email-receive-outline</v-icon>
              <span class="ml-4">Incoming</span>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <IncomingEmail :editedConnector="editedConnector.details.incoming" :isEdit="!!editedConnector.uuid" :publicationId="editedConnector.uuid" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Outcoming -->
      <v-expansion-panels class="my-6">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon color="primary">mdi-email-send-outline</v-icon>
              <span class="ml-4">Outgoing</span>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <OutgoingEmail :editedConnector="editedConnector.details.outgoing" :isEdit="!!editedConnector.uuid" :publicationId="editedConnector.uuid" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import IncomingEmail from "../connectors/emailConf/IncomingEmail";
import OutgoingEmail from "../connectors/emailConf/OutgoingEmail";

export default {
  components: {
    OutgoingEmail,
    IncomingEmail,
  },
  name: "EmailConnectorConf",
  props: ["editedConnector", "connectors"],
  data() {
    return {};
  },
  mounted() {
    this.editedConnector.type = "email";
  },
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
