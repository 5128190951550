<template>
  <v-container id="kwbsettings" fluid class="mt-10">
    <ResultSnackbar ref="resultSnackbar" />

    <v-form v-model="form.isValid">
      <v-row justify="center" :class="getMainRowMargin">
        <v-expansion-panels focusable>
          <!-- GenerativeAI -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span>GenerativeAI Configs</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="headers" :items="getGenerativeApiList" sort-by="name" class="elevation-1">
                <template #top>
                  <v-toolbar flat>
                    <v-toolbar-title>GenerativeAI Keys</v-toolbar-title>
                    <v-spacer />
                    <v-dialog v-model="dialog" max-width="700px">
                      <template #activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2 mt-6" v-bind="attrs" v-on="on">
                          + Add New Key
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span>{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" class="p-0">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Name"
                                  :rules="[requiredField, isOpenAiNameUnique(editedItem.name, settings.openai, editedIndex)]"
                                />
                              </v-col>
                              <v-col cols="12" class="p-0">
                                <v-select v-model="editedItem.type" :items="generativeAiEnginesType" label="Type" :rules="[requiredField]" />
                              </v-col>
                              <v-col v-if="editedItem.type == 'OpenAI'" cols="12" class="p-0">
                                <v-text-field
                                  v-model="editedItem.key"
                                  label="Key"
                                  :rules="[requiredField, isOpenAiKeyUnique(editedItem.key, settings.openai, editedIndex)]"
                                />
                              </v-col>
                              <template v-else-if="editedItem.type == 'Bedrock'">
                                <v-col cols="12" class="p-0">
                                  <v-text-field v-model="editedItem.key" label="Key" :rules="[requiredField]" />
                                </v-col>
                                <v-col cols="12" class="p-0">
                                  <v-text-field v-model="editedItem.secret" label="Secret" :rules="[requiredField]" />
                                </v-col>
                                <v-col cols="12" class="p-0">
                                  <v-autocomplete
                                    v-model="editedItem.region"
                                    :items="awsRegion"
                                    label="Region"
                                    :rules="[requiredField]"
                                    prepend-inner-icon="vpn_lock"
                                    :return-object="false"
                                  />
                                </v-col>
                              </template>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-row no-gutters>
                            <v-col cols="6" class="px-2">
                              <v-btn block outlined color="success" :disabled="!form.isValid || !editedItem.name || !editedItem.key" @click="save">
                                <v-icon left>
                                  mdi-floppy
                                </v-icon>Save
                              </v-btn>
                            </v-col>
                            <v-col cols="6" class="px-2">
                              <v-btn block outlined color="error" @click="close">
                                <v-icon left>
                                  mdi-close
                                </v-icon>Cancel
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title>Are you sure?</v-card-title>
                        <v-card-text class="mt-4 font-weight-light">
                          You will delete {{ editedItem.name }}
                        </v-card-text>
                        <v-card-actions>
                          <v-row no-gutters>
                            <v-col cols="6" class="px-2">
                              <v-btn block outlined color="success" :disabled="!editedItem.name || !editedItem.key" @click="deleteItemConfirm">
                                <v-icon left>
                                  mdi-floppy
                                </v-icon>Delete
                              </v-btn>
                            </v-col>
                            <v-col cols="6" class="px-2">
                              <v-btn block outlined color="error" @click="closeDelete">
                                <v-icon left>
                                  mdi-close
                                </v-icon>Cancel
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template #item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template #no-data>
                  Add your first key by clicking the "Add New Key" button!
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- redis -->
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <span>Redis Configs</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row :class="rowPadding" align="center">
                <v-col cols="12" :class="colPadding">
                  <v-switch v-model="settings.useSystemRedisConfiguration" label="Use System default redis instance" />
                </v-col>
                <v-col cols="12" sm="6" :class="colPadding">
                  <v-text-field
                    v-model.trim="settings.redis.url"
                    label="DNS/IP"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :disabled="useSystemRedisConfiguration"
                  />
                </v-col>
                <v-col cols="12" sm="6" :class="colPadding">
                  <v-text-field
                    v-model.trim="settings.redis.port"
                    label="Port"
                    :autocomplete="$store.getters.disableAutocomplete"
                    type="number"
                    :disabled="useSystemRedisConfiguration"
                  />
                </v-col>
                <v-col cols="12" sm="6" :class="colPadding">
                  <v-text-field
                    v-model.trim="settings.redis.username"
                    label="Username"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :disabled="useSystemRedisConfiguration"
                  />
                </v-col>
                <v-col cols="12" sm="6" :class="colPadding">
                  <v-text-field
                    v-model.trim="settings.redis.password"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    autocomplete="new-password"
                    :disabled="useSystemRedisConfiguration"
                  >
                    <template slot="append">
                      <v-btn depressed icon text :disabled="!settings.redis.password || settings.redis.password.length < 1" tabindex="-1">
                        <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :class="colPadding">
                  <v-switch v-model.trim="settings.redis.cluster" label="Redis Cluster Enabled" :disabled="useSystemRedisConfiguration" />
                </v-col>
              </v-row>
              <v-row class="mx-4 mb-2">
                <!-- Buttons -->
                <v-btn color="success" :loading="form.submitLoading" :class="getButtonMargin" @click.prevent="saveConfiguration">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  Save
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import fieldValidators from "../../helpers/fieldValidators";
import timeoutHelper from "@/helpers/timeout";

export default {
  name: "KWBSettings",
  components: {
    ResultSnackbar,
  },
  data() {
    return {
      showPassword: false,
      awsRegion: this.$store.getters.getAwsRegionCode,
      generativeAiEnginesType: ["OpenAI", "Bedrock"],
      form: {
        isValid: false,
        submitLoading: false,
      },
      settings: {
        openai: [],
        redis: {},
        useSystemRedisConfiguration: true,
      },
      headers: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Type", align: "start", sortable: true, value: "type" },
        { text: "Key", align: "start", sortable: false, value: "key" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        key: "",
        secret: "",
      },
      defaultItem: {
        name: "",
        key: "",
        secret: "",
      },
    };
  },
  computed: {
    ...spacing,
    useSystemRedisConfiguration() {
      return this.settings.useSystemRedisConfiguration;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Key" : "Edit Key";
    },
    getGenerativeApiList() {
      return this.settings.openai;
    },
    rowPadding() {
      return "py-6";
    },
    colPadding() {
      return "py-0";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("knowledgeBase")) {
      this.$router.push("/");
    }
    try {
      const result = await this.$http.get("/knowledge-base/settings");
      this.settings.redis = result.data.redis;
      this.settings.openai = result.data.openai;
      this.settings.openai.forEach((e) => {
        if (!e.type) {
          e.type = this.generativeAiEnginesType[0];
        }
      });
      if (typeof result.data.useSystemRedisConfiguration != "boolean") {
        this.settings.useSystemRedisConfiguration = true;
      } else {
        this.settings.useSystemRedisConfiguration = result.data.useSystemRedisConfiguration;
      }
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...checkRoleAndPermission,
    ...fieldValidators,
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    async saveConfiguration() {
      try {
        await this.$http.post("/knowledge-base/settings", this.settings);
        this.$refs.resultSnackbar.showSuccess("Knowledge base configurations updated!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to update knowledge base configurations: " + e.message);
      }
    },
    editItem(item) {
      this.editedIndex = this.settings.openai.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.settings.openai.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.settings.openai.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    async close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      await this.saveConfiguration();
    },
    async closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      await this.saveConfiguration();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.settings.openai[this.editedIndex], this.editedItem);
      } else {
        this.settings.openai.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<style>
#kwbsettings .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#kwbsettings .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
