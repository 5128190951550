<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      @input="
        searchString = $event;
        forceRender++;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="getCompanyList.length == 0 && searchString != ''" cols="12">
        <v-alert type="warning">
          No company match your search
        </v-alert>
      </v-col>
      <!-- Panel for new Company -->
      <v-expansion-panels v-if="newCompany" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Company
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <CompanyForm :companyDetail="newCompany" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel company configuration -->
      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(companyDetail, id) in getCompanyList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col md="2" sm="3" lg="2" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ companyDetail.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="4" sm="5" lg="4" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>UUID</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ companyDetail.uuid }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="2" sm="3" lg="2" class="text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-center">
                        Timezone
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-center">
                        {{ companyDetail.timezone.zone }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="4" sm="5" lg="4" class="hidden-sm-and-down text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-center">
                        Crab Tenant
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-center">
                        {{ companyDetail.crabTenant.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <CompanyForm :key="forceRender + id + '_sr'" :companyDetail="companyDetail" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <AddNewItemButton name="Company" @addNewItem="addNewCompany" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import CompanyForm from "../../components/system/CompanyForm";
import moment from "moment-timezone";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";
import scroller from "../../helpers/scrollToTop";

export default {
  name: "Companies",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    CompanyForm,
  },
  data() {
    return {
      expansionPanelCreation: null,
      expansionPanel: null,
      companiesDetail: {},
      forceRender: 0,
      searchString: "",
      //Data bind per creazione company
      newCompany: null,
    };
  },
  computed: {
    ...spacing,
    getCompanyList: function () {
      this.forceRender;
      if (this.companiesDetail) {
        return (
          Object.values(this.companiesDetail)
            //Filtro per la ricerca
            .filter((company) => !this.searchString || company.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
            //Sorting per un campo
            .sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              return 0;
            })
        );
      }
      return [];
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_COMPANY_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_COMPANY_SUCCESS, this.companyCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_COMPANY_FAIL, this.companyError);
    EventBus.$on(this.$store.getters.getEvents.EDIT_COMPANY_FAIL, this.companyCreatedFail);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_COMPANY_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_COMPANY_SUCCESS, this.deleteCompany);
    EventBus.$on(this.$store.getters.getEvents.DELETE_COMPANY_FAIL, this.customPermissionUploaded);
    EventBus.$on(this.$store.getters.getEvents.EDIT_CUSTOM_PERMISSION_SUCCESS, this.customPermissionUploaded);
    EventBus.$on(this.$store.getters.getEvents.EDIT_CUSTOM_PERMISSION_FAIL, this.customPermissionUploadFailed);
    try {
      const result = await this.$http.get("/company");
      result.data.forEach((company) => {
        if (company.integrations["contactcenterstats"] == undefined) {
          company.integrations["contactcenterstats"] = true;
        }
        this.companiesDetail[company.uuid] = company;
      });
      this.forceRender++;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_COMPANY_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_COMPANY_SUCCESS, this.companyCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_COMPANY_FAIL, this.companyCreatedFail);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_COMPANY_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_COMPANY_SUCCESS, this.deleteCompany);
    EventBus.$off(this.$store.getters.getEvents.DELETE_COMPANY_FAIL, this.customPermissionUploaded);
    EventBus.$off(this.$store.getters.getEvents.EDIT_CUSTOM_PERMISSION_SUCCESS, this.customPermissionUploaded);
    EventBus.$off(this.$store.getters.getEvents.EDIT_CUSTOM_PERMISSION_FAIL, this.customPermissionUploadFailed);
  },
  methods: {
    ...scroller,
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewCompany() {
      if (!this.newCompany) {
        this.newCompany = {
          profile: "premium",
          integrations: {
            livePerson: false,
            ciscoECE: false,
            webexTeams: false,
            rainbow: false,
            wcs: false,
            apps: false,
          },
          crabTenant: {
            cache: {
              credentials: {
                password: "",
                username: "",
                type: "",
              },
            },
          },
          apps: [],
          name: "",
          timezone: {
            zone: moment.tz.guess(),
            timezoneOffset: moment.tz(moment.tz.guess())._offset,
          },
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newCompany = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    deleteCompany(obj) {
      if (this.companiesDetail[obj.company]) {
        delete this.companiesDetail[obj.company];
        this.$store.dispatch("DeleteCompany", obj.company);
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        this.forceRender++;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    async companyCreated(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      if (!this.companiesDetail[obj.editCompany.uuid]) {
        //Siamo in creazione
        await this.$store.dispatch("AddCompany", { uuid: obj.editCompany.uuid, name: obj.editCompany.name });
      } else {
        //Siamo in edit
        // Aggiorno le integrations se la company editata è quella corrente
        if (obj.editCompany.uuid === this.$store.getters.getSelectedCompany) {
          await this.$store.dispatch("UpdateCompany", obj.editCompany);
        }
      }
      this.companiesDetail[obj.editCompany.uuid] = merge({}, obj.editCompany);
      this.closeAllPanels();
      this.forceRender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    companyError(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    companyCreatedFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    customPermissionUploaded(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
    },
    customPermissionUploadFailed(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
  },
};
</script>
