<template>
  <v-card>
    <v-dialog v-model="dialogForceSessionClose" persistent max-width="290">
      <v-card>
        <v-card-title class="title">
          Force Session Close
        </v-card-title>
        <v-card-text>Are you sure to force session shutdown?</v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click.prevent="sessionClosingForce">
                <v-icon left>
                  mdi-radioactive
                </v-icon>Force
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="secondary" outlined block @click="closeSessionCloseDialog">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="pa-0">
      <v-row align="center" class="my-2">
        <v-col cols="7" class="text-right">
          <v-menu v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" ref="menu" top>
            <template #activator="{ on: menu }">
              <v-tooltip color="primary" bottom>
                <template #activator="{ on: tooltip }">
                  <v-btn :disabled="dataset && dataset.length === 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                    <v-icon small left>
                      mdi-download
                    </v-icon> Download
                  </v-btn>
                </template>
                <span>Choose the format and download dataset</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item @click="exportXlsx">
                <v-list-item-title>
                  <v-icon left>
                    mdi-file-excel-outline
                  </v-icon>XLSX
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportCsv">
                <v-list-item-title>
                  <v-icon left>
                    mdi-file-delimited-outline
                  </v-icon>CSV
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="searchString"
            outlined
            dense
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            class="mr-4"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      v-model="selected"
      must-sort
      :headers="getHeaders"
      :items="getDataset"
      class="elevation-1 px-1"
      :search="searchString"
      :custom-filter="filterTable"
      sort-by="time_stamp"
      item-key="dialog_id"
      sort-desc
      :show-select="false"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 25, 50],
      }"
    >
      <template slot="no-data">
        <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
          Not enough data to render this table!
        </v-alert>
      </template>

      <template #item.closed_at="{ item }">
        <div v-if="item.closed_at == 'Invalid date'">
          -
        </div>
        <div v-else>
          {{ item.closed_at | formatVerboseDateTimeFromUtc }}
        </div>
      </template>
      <template #item.time_stamp="{ item }">
        {{ item.time_stamp | formatVerboseDateTimeFromUtc }}
      </template>
      <template #item.bot_name="{ item }">
        <v-tooltip v-if="item.bot_name" color="primary" bottom :disabled="isDisabledTooltip(item, 'bot_name')">
          <template #activator="{ on }">
            <div class="text-truncate" style="width: 155px; max-width: 155px" v-on="on">
              {{ item.bot_name }}
            </div>
          </template>
          <span>{{ item.bot_name }}</span>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template #item.channel="{ item }">
        <v-badge bordered avatar overlap :value="item.outbound || false" class="mr-2">
          <template #badge>
            <v-icon class="mt-1">
              mdi-bullhorn-outline
            </v-icon>
          </template>
          <v-tooltip color="primary" right>
            <template #activator="{ on }">
              <span v-if="item.channel === 'webexmeeting'" class="d-flex flex-column justify-space-between align-center">
                <v-img v-if="isDarkMode" width="24" height="24" :src="require('../../assets/img/connectors/webexteams_white.png')" v-on="on" />
                <v-img v-else width="24" height="24" :src="require('../../assets/img/connectors/webexteams_grey.png')" v-on="on" />
              </span>
              <v-icon v-else v-on="on">
                {{ $store.getters.getConnectorIcon(item.channel) }}
              </v-icon>
            </template>
            <span>{{ $store.getters.getConnectorLabel(item.channel) }}</span>
          </v-tooltip>
        </v-badge>
      </template>
      <template #item.user_id="{ item }">
        <v-tooltip v-if="item.user_id && item.user_id !== 'null'" color="primary" bottom :disabled="isDisabledTooltip(item, 'user_name')">
          <template #activator="{ on }">
            <div class="text-truncate" style="max-width: 155px" v-on="on">
              {{ item.user_name || item.user_id }}
            </div>
          </template>
          <div>
            <p v-if="item.user_name" style="margin: 0">
              User Name: {{ item.user_name }}
            </p>
            <p v-if="item.user_id" style="margin: 0">
              User ID: {{ item.user_id }}
            </p>
          </div>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template #item.state="{ item }">
        <span v-if="item.state">{{ item.state }}</span>
        <span v-else>-</span>
      </template>
      <template #item.handover="{ item }">
        <v-tooltip v-if="item.handoverType" color="primary" bottom>
          <template #activator="{ on }">
            <v-badge
              v-if="item.call_established"
              bordered
              avatar
              overlap
              offset-x="5"
              offset-y="10"
              class="avBadge"
              v-on="on"
            >
              <template #badge>
                <v-avatar v-on="on">
                  <v-icon color="info" v-on="on">
                    mdi-phone
                  </v-icon>
                </v-avatar>
                <v-avatar v-if="item.recording" v-on="on">
                  <v-icon color="info" v-on="on">
                    mdi-radiobox-marked
                  </v-icon>
                </v-avatar>
              </template>
              <v-icon v-if="item.channel == 'webapi'" color="success" v-on="on">
                mdi-message-text-outline
              </v-icon>
              <v-icon v-else-if="item.channel == 'email'" color="success" v-on="on">
                mdi-email-outline
              </v-icon>
              <v-icon v-else color="success" v-on="on">
                mdi-chat-outline
              </v-icon>
            </v-badge>
            <v-icon v-else-if="item.channel == 'webapi'" color="success" v-on="on">
              mdi-message-text-outline
            </v-icon>
            <v-icon v-else-if="item.channel == 'email'" color="success" v-on="on">
              mdi-email-outline
            </v-icon>
            <v-icon v-else color="success" v-on="on">
              mdi-chat-outline
            </v-icon>
          </template>
          <span>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title v-if="item.agent && item.agent.username" class="white--text">Username: {{ item.agent.username }}</v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.agent && item.agent.surname"
                  class="white--text"
                >Name: {{ item.agent.name }} {{ item.agent.surname }}</v-list-item-subtitle>
                <v-list-item-subtitle class="white--text">Type: {{ item.handoverType }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </span>
        </v-tooltip>
        <v-icon v-else color="secondary">
          mdi-minus-thick
        </v-icon>
      </template>
      <template #item.reason="{ item }">
        <span v-if="item.reason">{{ item.reason }}</span>
        <span v-else>-</span>
      </template>
      <template #item.flow="{ item }">
        <v-tooltip v-if="item.channel !== 'phone'" color="primary" left>
          <template #activator="{ on }">
            <v-btn color="primary" :loading="loadingDetail" text v-on="on" @click="showDetail(item)">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <template #item.user_name="{ item }">
        <v-tooltip v-if="item.channel !== 'phone' && item.state === 'PROGRESS' && hasRequiredRole($store.getters.getRoles.ADMIN)" color="error" left>
          <template #activator="{ on }">
            <v-btn text icon color="error" v-on="on" @click="openSessionCloseDialog(item)">
              <v-icon>mdi-radioactive</v-icon>
            </v-btn>
          </template>
          <span>Force Session Close</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import EventBus from "../../event-bus";
import merge from "deepmerge";
import exportFile from "../../helpers/exportFile";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import customFilter from "@/plugins/customFilter";
import postProcessing from "../../helpers/postProcessing";

const Qs = require("qs");

export default {
  name: "DialogDetailsDatatable",
  props: ["dataset"],
  data() {
    return {
      refreshTimer: null,
      searchString: "",
      selected: [],
      headers: [
        { text: "Created At", value: "time_stamp", width: "17%", showOnSmallScreens: true },
        { text: "Closed At", value: "closed_at", width: "17%" },
        { text: "Bot", value: "bot_name", width: "155px", showOnSmallScreens: true },
        { text: "Channel", value: "channel", width: "10%", align: "center", showOnSmallScreens: true },
        { text: "User Name", value: "user_id", width: "155px", showOnSmallScreens: true },
        { text: "State", value: "state", width: "10%", showOnSmallScreens: true },
        { text: "Handover", value: "handover", width: "5%" },
        // questa riga della tabella ha come value user_name, cosi il filtro di ricerca funziona anche su quell'attributo
        {
          text: "",
          value: "flow",
          sortable: false,
          align: "center",
          showOnSmallScreens: true,
        },
        {
          text: "",
          value: "user_name",
          sortable: false,
          align: "center",
          showOnSmallScreens: true,
        },
      ],
      dialogForceSessionClose: false,
      loadingDetail: false,
      tmpDialogid: null,
    };
  },
  computed: {
    getHeaders() {
      return this.headers.map((h) => {
        if (h.value === "user_id") {
          if (this.$vuetify.breakpoint.smAndDown) {
            h.align += " d-none";
          } else if (h.align) {
            h.align = h.align.replace(" d-none", "");
          }
        } else {
          if (this.$vuetify.breakpoint.mdAndDown && !h.showOnSmallScreens) {
            h.align += " d-none";
          } else if (h.align) {
            h.align = h.align.replace(" d-none", "");
          }
        }
        return h;
      });
    },
    getDataset() {
      return this.dataset;
    },
    isDarkMode: function () {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...exportFile,
    ...customFilter,
    ...checkRoleAndPermission,
    ...postProcessing,
    isDisabledTooltip(item, from) {
      let itemToCheck = null;
      let showUserId = false;
      if (from == "bot_name") {
        itemToCheck = item.bot_name;
      } else {
        if (item.user_id && item.user_id != "null") {
          if (item.user_name) {
            itemToCheck = item.user_name;
            showUserId = true;
          } else {
            itemToCheck = item.user_id;
          }
        }
      }
      if ((itemToCheck && itemToCheck.length >= 19) || showUserId) {
        return false;
      }

      return true;
    },
    filterTable(value, search, item) {
      if (!search) search = "";
      return Object.values(item).find((field) => field && field.toLowerCase && field.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    },
    closeSessionCloseDialog() {
      this.dialogForceSessionClose = false;
      this.tmpDialogid = null;
    },
    openSessionCloseDialog(item) {
      this.dialogForceSessionClose = true;
      this.tmpDialogid = item.dialog_id;
    },
    showDetail(item) {
      let params = {
        type: "DIALOG_DETAIL_INFO_V2",
        dialog_id: item.dialog_id,
      };
      this.loadingDetail = true;
      this.$httpAuth
        .get("/report/dialog-details-info", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then(async (result) => {
          let response = merge({}, item);
          try {
            if (result.data.rawHistory) {
              response.content = result.data.rawHistory;
            } else {
              response.content = JSON.parse(response.content) || [];
            }
          } catch (err) {
            response.content = [];
          }
          if (result.data.result && result.data.result.length > 0) response = merge(response, result.data.result[0]);
          for (let i = 0; i < response.content.length; i++) {
            for (let j = 0; j < result.data.history.length; j++) {
              if (
                (result.data.history[j].message && result.data.history[j].message == response.content[i].message && response.content[i].who?.toLowerCase() !== "convyai") ||
                result.data.history[j].originalMessage == response.content[i].message
              ) {
                if (!response.content[i].analyze) {
                  response.content[i].analyze = result.data.history[j].analyze ? result.data.history[j].analyze : null;
                }
                if (result.data.history[j].attachment) {
                  //vuol dire che mi è stato mandato un base64
                  if (result.data.history[j].attachment.skipConversion) {
                    //allora devo ricostruire il source per il base64
                    result.data.history[j].attachment.file_content =
                      "data:" + result.data.history[j].attachment.mime_type + ";base64," + result.data.history[j].attachment.file_content;
                  }
                  response.content[i].attachment = result.data.history[j].attachment;
                }
                if (result.data.history[j].attributes) {
                  if (!response.content[i].attributes) {
                    response.content[i].attributes = {};
                  }
                  response.content[i].attributes = merge(response.content[i].attributes, result.data.history[j].attributes);
                  //Evito di duplicare i campi
                  if (response.content[i].attributes.formField) {
                    response.content[i].attributes.formField = result.data.history[j].attributes.formField;
                  }
                  //Verifico che gli array siano tutti unici
                  if (response.content[i].attributes.to) {
                    response.content[i].attributes.to = result.data.history[j].attributes.to;
                  }
                  if (response.content[i].attributes.cc) {
                    response.content[i].attributes.cc = result.data.history[j].attributes.cc;
                  }
                  if (response.content[i].attributes.bcc) {
                    response.content[i].attributes.bcc = result.data.history[j].attributes.bcc;
                  }
                  if (response.content[i].attributes.attachments) {
                    response.content[i].attributes.attachments = result.data.history[j].attributes.attachments;
                  }
                }
                result.data.history.splice(j, 1);
              } else if (result.data.history[j].attachment && result.data.history[j].attachment.file_name == response.content[i].message) {
                response.content[i].attachment = result.data.history[j].attachment;
                result.data.history.splice(j, 1);
              } else if (response.content[i].type === "share_document") {
                //Gestione documenti condivisi
                const documentName = response.content[i].message;
                const findResult = result.data.history.find((msg) => {
                  return msg.message && msg.message.indexOf(documentName) !== -1;
                });
                if (findResult) {
                  response.content[i].attachment = findResult.attachment;
                  result.data.history.splice(j, 1);
                }
              }
            }
          }
          response.data = result.data.data;
          response.result = result.data.result;
          response.calls = result.data.calls;
          response.recordings = await this.retrieveRecordingStatus(result.data.recordings);
          response.privateNotes = result.data.privateNotes;
          response.surveys = result.data.surveys;
          response.contactResults = result.data.contactResults;
          EventBus.$emit(this.$store.getters.getEvents.DIALOG_DETAIL_SELECTED_DETAIL, response);
          if (item.state === "PROGRESS") {
            this.stopRefreshTimer();
            this.refreshTimer = setTimeout(this.showDetail.bind(this, item), 30000);
          }
        })
        .catch(() => {})
        .finally(() => {
          setTimeout(() => {
            this.loadingDetail = false;
          }, 150);
        });
    },
    stopRefreshTimer() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
      }
      this.refreshTimer = null;
    },
    exportXlsx() {
      this.$emit("directExport", "xlsx", true, true);
      //this.exportDialogDetailXlsx(this.dataset);
    },
    exportCsv() {
      this.$emit("directExport", "csv", true, true);
      //this.exportDialogDetailCsv(this.dataset);
    },
    async sessionClosingForce() {
      try {
        await this.$httpAuth.delete("/conversation-api/" + this.tmpDialogid);
        for (let i = 0; i < this.dataset.length; i++) {
          if (this.dataset[i].dialog_id === this.tmpDialogid) {
            this.dataset[i].state = "COMPLETED";
            EventBus.$emit(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_SUCCESS, "Session " + this.tmpDialogid + " closed!");
            this.tmpDialogid = null;
            this.dialogForceSessionClose = false;
            return;
          }
        }
      } catch (e) {
        this.dialogForceSessionClose = false;
        EventBus.$emit(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_FAIL, "Unable to close session " + this.tmpDialogid + ". Maybe it's already closed");
      }
    },
  },
};
</script>
<style>
.v-application .avBadge i.info--text.theme--dark {
  color: #fff !important;
  caret-color: #fff !important;
}
.theme--dark.v-badge .v-badge__badge:after {
  border-color: #263238 !important;
}
.theme--dark.v-alert{
  color: #fff !important;
  background-color: #263238 !important;
}
.theme--light.v-badge .v-icon.theme--light.info--text {
  color: #fff !important;
  caret-color: #fff !important;
}
</style>
