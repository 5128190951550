<template>
  <v-container fluid :class="getCreationContainerMargin">
    <ResultSnackbar ref="resultSnackbar" />
    <ResultSnackbar ref="resultErrorSnackbar" />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col cols="12" :class="getFormColMargin">
        <v-alert type="warning" dense>
          Warning, All imported objects will override the current ones with the same name
        </v-alert>
        <v-card :loading="form.submitLoading">
          <v-card-title>
            <p class="headline">
              Import
            </p>
          </v-card-title>
          <v-card-text>
            <p class="subtitle-1">
              Select previously exported file
            </p>
            <v-file-input
              v-model="file"
              accept=".zip"
              label="Select File"
              validate-on-blur
              prepend-inner-icon="mdi-file-document"
              prepend-icon
              show-size
            />
          </v-card-text>
          <v-card-actions>
            <v-row :class="getFormRowMargin">
              <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
                <v-btn class="ml-2" color="success" :disabled="file == null || form.submitLoading ? true : false" @click.prevent="importFile">
                  <v-icon left>
                    mdi-upload
                  </v-icon>Import Zip
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";

export default {
  name: "Import",
  components: {
    ResultSnackbar,
  },
  data() {
    return {
      file: null,
      form: {
        isValid: false,
        submitLoading: false,
      },
    };
  },
  computed: {
    ...spacing,
  },
  mounted() {
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
  },
  methods: {
    ...fieldValidators,
    async importFile() {
      let data = new FormData();
      data.append(0, this.file);
      this.form.submitLoading = true;
      try {
        const res = await this.$http.post("/import", data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.importFinished(res.data);
      } catch (e) {
        this.importFailed();
      } finally {
        this.form.submitLoading = false;
      }
    },
    importFinished(res) {
      let message = [
        "Import " + res.zipFileName + " complete:",
        "Flows imported: " + res.flows.length,
        "Engines imported: " + res.engine.length,
        "Bots imported: " + res.bots.length,
        "Flow Configuration Sections imported: " + res.configurations.length,
        "Agents imported: " + res.agents.length,
        "Profiles imported: " + res.profiles.length,
        "Queues imported: " + res.queues.length,
        "Shortcodes imported: " + res.shortcodes.length,
        "Handover Parameters imported: " + res.attachedvariables.length,
        "Customer interfaces imported: " + res.customerinterfaces.length,
        "Agent Desktops imported: " + res.agentinterfaces.length,
        "Permission Sets imported: " + res.permissionSet.length,
        "Surveys imported: " + res.surveys.length,
      ];

      let companyPermission = this.$store.getters.getPermission;
      for (let permission in companyPermission) {
        if (companyPermission[permission] == "FullAccessWatchbot") {
          message.push("Watchbot Imported: " + res.scripts.length);
        }
        if (companyPermission[permission] == "FullAccessInputProcessors") {
          message.push("Input Processors Imported: " + res.inputProcessors.length);
        }
        if (companyPermission[permission] == "FullAccessScheduledReports") {
          message.push("Scheduler Reports Imported: " + res.scheduler.length);
        }
        if (companyPermission[permission] == "FullAccessEmailBoxes") {
          message.push("Email Boxes Imported: " + res.emailboxes.length);
        }
      }
      this.$refs.resultSnackbar.showSuccess(message, 0);

      if (res.failedImports.length > 0) {
        let msg = [];
        msg.push("Fail to import:");
        for (let i = 0; i < res.failedImports.length; i++) {
          msg.push("- " + res.failedImports[i]);
        }
        this.$refs.resultErrorSnackbar.showError(msg, 0);
      }
    },
    importFailed() {
      this.$refs.resultSnackbar.showError("Error Import Zip File!", 0);
    },
  },
};
</script>
