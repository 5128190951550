<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          label="Select Semantic Engine"
          :items="semanticEngineList"
          v-model="semanticEngineSelectedProp"
          :rules="[requiredField]"
          :disabled="semanticHookList.length > 0"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title> All available semantic engines don't have any intent configured </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="8" class="mt-3">
        <v-btn color="primary" @click="addNewSemanticHook" :disabled="addHookIsDisabled"><v-icon left>mdi-plus</v-icon>Add New Hook</v-btn>
      </v-col>
    </v-row>
    <perfect-scrollbar class="psInner" :options="$store.getters.getDefaultScrollProps">
      <v-row v-for="(semanticHook, index) in semanticHookList" :key="'hook_' + index">
        <v-col cols="3">
          <v-autocomplete label="Intent" :items="intentList" v-model="semanticHook.intent" auto-select-first></v-autocomplete>
        </v-col>
        <v-col cols="3" v-show="selectedType == 'Add Semantic Hooks'">
          <v-autocomplete
            label="Flow"
            :items="flowList"
            v-model="semanticHook.flow"
            @change="
              semanticHook.version = Object.keys(flowsTargets[semanticHook.flow])[0];
              semanticHook.hook = 'begin';
            "
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" v-show="selectedType == 'Add Semantic Hooks'">
          <v-autocomplete
            label="Version"
            :items="semanticHook.flow ? Object.keys(flowsTargets[semanticHook.flow]) : []"
            v-model="semanticHook.version"
            @change="semanticHook.hook = 'begin'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" v-show="selectedType == 'Add Semantic Hooks'">
          <v-autocomplete
            label="Hook"
            :items="semanticHook.version ? flowsTargets[semanticHook.flow][semanticHook.version].hooks : []"
            v-model="semanticHook.hook"
          ></v-autocomplete>
        </v-col>
        <v-col cols="1">
          <v-btn text icon color="error" class="mt-3" @click="deleteSemanticHook(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "ConfigureSemanticHooks",
  props: ["semanticHookList", "semanticEngines", "flowsTargets", "semanticEngineSelected", "selectedType"],
  data() {
    return {};
  },
  methods: {
    ...fieldValidators,
    addNewSemanticHook() {
      const selected = this.semanticEngines.filter((engine) => engine.name === this.semanticEngineSelected);
      let unselectedIntents = Object.keys(selected[0].engines[this.semanticEngineSelected].intents).filter((intent) => {
        return !this.semanticHookList.some((semanticHook) => semanticHook.intent == intent);
      });
      if (unselectedIntents.length > 0) {
        let flow = Object.keys(this.flowsTargets)[0];
        let version = Object.keys(this.flowsTargets[flow])[0];
        this.semanticHookList.push({
          intent: unselectedIntents[0],
          flow: flow,
          version: version,
          hook: "begin",
        });
      }
    },
    deleteSemanticHook(index) {
      this.semanticHookList.splice(index, 1);
    },
  },
  computed: {
    semanticEngineSelectedProp: {
      get() {
        return this.semanticEngineSelected;
      },
      set(value) {
        this.$emit("updateEngineSelected", value);
      },
    },
    addHookIsDisabled() {
      this.semanticEngineList;
      const selected = this.semanticEngines.filter((engine) => engine.name === this.semanticEngineSelected);
      return !(
        this.semanticEngineSelected.length > 0 &&
        selected.length === 1 &&
        Object.keys(selected[0].engines[this.semanticEngineSelected].intents).length > 0
      );
    },
    intentList() {
      if (this.semanticEngineSelected) {
        const selected = this.semanticEngines.filter((engine) => engine.name === this.semanticEngineSelected);
        return Object.keys(selected[0].engines[this.semanticEngineSelected].intents);
      }
      return [];
    },
    flowList() {
      return Object.keys(this.flowsTargets);
    },
    semanticEngineList() {
      let list = [];
      for (let engine of this.semanticEngines) {
        if (Object.keys(engine.engines[engine.name].intents).length > 0) {
          list.push(engine.name);
        }
      }
      return list;
    },
  },
};
</script>

<style>
.psInner {
  height: 300px;
}
</style>
