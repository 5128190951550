<template>
  <v-row>
    <!-- Enable datepicker -->
    <v-col cols="4" md="3">
      <v-tooltip color="warning" bottom>
        <template #activator="{ on }">
          <v-switch v-model="enable" color="primary" label="Select Period" v-on="on" @change="switchEnableDatePicker" />
        </template>
        <span> If disabled no time restriction will be applied to the query. Disabling this filter can slower the data retrieve process! </span>
      </v-tooltip>
    </v-col>
    <!-- DateStart -->
    <v-col v-if="enable" cols="4" md="3">
      <v-dialog ref="dialogDateStart" v-model="dialogDateTimeStart" persistent width="290px">
        <template #activator="{ on }">
          <v-text-field
            v-model="getDateStartFormatted"
            :rules="[requiredField]"
            label="Date Start"
            prepend-inner-icon="event"
            readonly
            v-on="on"
            @click.prevent="backupDateStart"
          />
        </template>
        <v-date-picker
          v-if="!dateStartChose"
          v-model="dateStart"
          color="primary"
          :max="dateEnd"
          :min="minDate"
          @change="dateStartChose = true"
        >
          <v-btn color="error" outlined block @click="restoreDateStart">
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
        </v-date-picker>
        <v-time-picker
          v-if="dateStartChose"
          v-model="timeStart"
          color="primary"
          :max="getMaxTime"
          format="24hr"
          scrollable
          full-width
        >
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn v-if="dateStartChose" color="success" outlined block @click="confirmDateTimeStart">
                <v-icon left>
                  mdi-check
                </v-icon>OK
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn v-if="dateStartChose" color="error" outlined block @click="dateStartChose = false">
                <v-icon small left>
                  mdi-keyboard-backspace
                </v-icon>Back
              </v-btn>
            </v-col>
          </v-row>
        </v-time-picker>
      </v-dialog>
    </v-col>
    <!-- DateEnd -->
    <v-col v-if="enable" cols="4" md="3">
      <v-dialog ref="dialogDateEnd" v-model="dialogDateTimeEnd" persistent width="290px">
        <template #activator="{ on }">
          <v-text-field
            v-model="getDateEndFormatted"
            :rules="[requiredField]"
            label="Date End"
            prepend-inner-icon="event"
            readonly
            v-on="on"
            @click.prevent="backupDateEnd"
          />
        </template>
        <v-date-picker v-if="!dateEndChose" v-model="dateEnd" color="primary" :min="dateStart" @change="dateEndChose = true">
          <v-btn color="error" outlined block @click="restoreDateEnd">
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
        </v-date-picker>
        <v-time-picker
          v-if="dateEndChose"
          v-model="timeEnd"
          color="primary"
          format="24hr"
          :min="getMinTime"
          scrollable
          full-width
        >
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn v-if="dateEndChose" color="success" outlined block @click="confirmDateTimeEnd">
                <v-icon left>
                  mdi-check
                </v-icon>OK
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn v-if="dateEndChose" color="error" outlined block @click="dateEndChose = false">
                <v-icon small left>
                  mdi-keyboard-backspace
                </v-icon>Back
              </v-btn>
            </v-col>
          </v-row>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "../../event-bus";

export default {
  name: "TimePickerRangeReport",
  data() {
    return {
      minDate: moment().format("YYYY-MM-DD"),
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      timeStart: "00:00",
      timeEnd: "23:59",
      enable: true,
      dialogDateTimeStart: false,
      dialogDateTimeEnd: false,
      backupDate: {
        date: null,
        time: null,
      },
      dateStartChose: false,
      dateEndChose: false,
    };
  },
  computed: {
    getDateStart() {
      return moment(this.dateStart).format("DD/MM/YYYY") + " " + this.timeStart + ":00";
    },
    getDateEnd() {
      return moment(this.dateEnd).format("DD/MM/YYYY") + " " + this.timeEnd + ":59";
    },
    getDateStartFormatted() {
      return moment(this.getDateStart, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, HH:mm:ss");
    },
    getDateEndFormatted() {
      return moment(this.getDateEnd, "DD/MM/YYYY HH:mm:ss").format("DD MMM YYYY, HH:mm:ss");
    },
    getMinTime() {
      if (this.dateStart === this.dateEnd) {
        return this.timeStart;
      } else {
        return "00:00";
      }
    },
    getMaxTime() {
      if (this.dateStart === this.dateEnd) {
        return this.timeEnd;
      } else {
        return "23:59";
      }
    },
  },
  mounted() {
    const d = new Date();
    if (!this.$store.state.loginResponse.params.serverConfigurations.days_of_report_searchability) {
      this.$store.state.loginResponse.params.serverConfigurations.days_of_report_searchability = 365;
    }
    d.setDate(d.getDate() - (this.$store.state.loginResponse.params.serverConfigurations.days_of_report_searchability - 1));
    this.minDate = moment(d).format("YYYY-MM-DD");
    this.dateStart = this.minDate;
    EventBus.$on(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, this.updateDatetimeRange);
    if (this.$store.getters.getSetting("dateStart")) {
      this.dateStart = this.$store.getters.getSetting("dateStart");
      this.timeStart = this.$store.getters.getSetting("timeStart");
    }
    if (this.$store.getters.getSetting("dateEnd")) {
      this.dateEnd = this.$store.getters.getSetting("dateEnd");
      this.timeEnd = this.$store.getters.getSetting("timeEnd");
    }
    this.sendUpdateStoredTimeRange();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, this.updateDatetimeRange);
  },
  methods: {
    ...fieldValidators,
    sendUpdateStoredTimeRange() {
      this.$emit("setDateStart", this.getDateStart);
      this.$emit("setDateEnd", this.getDateEnd);
    },
    setDateStart(date, time) {
      this.dateStart = date;
      this.timeStart = time;
    },
    setDateEnd(date, time) {
      this.dateEnd = date;
      this.timeEnd = time;
    },
    switchEnableDatePicker() {
      this.$emit("switchEnableDatePicker", this.enable);
    },
    backupDateStart() {
      this.backupDate.date = this.dateStart;
      this.backupDate.time = this.timeStart;
    },
    restoreDateStart() {
      this.dialogDateTimeStart = false;
      this.dateStartChose = false;
      this.dateStart = this.backupDate.date;
      this.timeStart = this.backupDate.time;
    },
    backupDateEnd() {
      this.backupDate.date = this.dateEnd;
      this.backupDate.time = this.timeEnd;
    },
    restoreDateEnd() {
      this.dialogDateTimeEnd = false;
      this.dateEndChose = false;
      this.dateEnd = this.backupDate.date;
      this.timeEnd = this.backupDate.time;
    },
    updateDatetimeRange(datetimeRange) {
      datetimeRange.enable ? (this.enable = true) : (this.enable = false);
      if (this.enable) {
        let start = datetimeRange.datetimeStart.split(" ");
        this.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (start[1]) {
          this.timeStart = moment(start[1], "HH:mm:ss", true).isValid() ? moment(start[1], "HH:mm:ss").format("HH:mm") : start[1];
        } else {
          this.timeStart = "00:00";
        }
        let end = datetimeRange.datetimeEnd.split(" ");
        this.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (end[1]) {
          this.timeEnd = moment(end[1], "HH:mm:ss", true).isValid() ? moment(end[1], "HH:mm:ss").format("HH:mm") : end[1];
        } else {
          this.timeEnd = "23:59";
        }
      }
    },
    confirmDateTimeStart() {
      this.dateStartChose = false;
      this.dialogDateTimeStart = false;
      this.$emit("setDateStart", this.getDateStart);
      this.$store.dispatch("AddSetting", {
        dateStart: this.dateStart,
        timeStart: this.timeStart,
      });
    },
    confirmDateTimeEnd() {
      this.dateEndChose = false;
      this.dialogDateTimeEnd = false;
      this.$emit("setDateEnd", this.getDateEnd);
      this.$store.dispatch("AddSetting", {
        dateEnd: this.dateEnd,
        timeEnd: this.timeEnd,
      });
    },
  },
};
</script>
