<template>
  <iframe id="registryIframe" class="hidden mb-n2" scrolling="no" src="" />
</template>

<script>
import EventBus from "../../event-bus";

export default {
  name: "Registry",
  data() {
    return {};
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    this.initMessageListener();
    this.initIframe();
    EventBus.$on("jwtTokenUpdated", this.updateJwt);
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
  },
  methods: {
    changeTheme() {
      this.initIframe();
    },
    initIframe() {
      let url = window.location.href.substring(0, window.location.href.indexOf("#"));
      url = url.replace("index.html", "");
      url += "ContactManager/index.html";
      let iframe = document.getElementById("registryIframe");
      iframe.src = url + "?cssStyle=convy&dark=" + this.$vuetify.theme.dark + "&random=" + new Date().getTime();
      iframe.classList.remove("hidden");
    },
    initMessageListener() {
      this.callbackFunction = this.registryEventListener.bind(this);
      window.addEventListener("message", this.callbackFunction);
    },
    updateJwt() {
      const params = {
        url: this.$store.getters.getProperty("publicUrl") + "/provisioning/crab",
        token: this.$store.state.loginResponse.jwt,
        company: this.$store.getters.getSelectedCompany,
        publicUrl: this.$store.getters.getProperty("publicUrl"),
      };
      this.sendMsgToIframe({ event: "CRAB_CONFIGURATIONS", params });
    },
    registryEventListener(e) {
      const params = {
        url: this.$store.getters.getProperty("publicUrl") + "/provisioning/crab",
        token: this.$store.state.loginResponse.jwt,
        company: this.$store.getters.getSelectedCompany,
        publicUrl: this.$store.getters.getProperty("publicUrl"),
      };
      switch (e.data.event) {
        case "GET_CRAB_CONFIGURATIONS":
          this.sendMsgToIframe({ event: "CRAB_CONFIGURATIONS", params });
          this.sendMsgToIframe({
            event: "CONVY_CONFIGURATIONS",
            params: {
              days_of_report_searchability: this.$store.state.loginResponse.params.serverConfigurations.days_of_report_searchability,
            },
          });
          break;
        case "UPDATE_SCROLLBAR":
          EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
          break;
      }
    },
    sendMsgToIframe(params) {
      let iframe = document.getElementById("registryIframe");
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(params, "*");
      }
    },
  },
};
</script>

<style scoped>
#registryIframe {
  width: 100%;
  border: none;
  overflow: hidden;
  height: calc(100vh - 64px);
}
</style>
