<template>
  <v-dialog v-model="showConfirm" width="390" persistent>
    <v-card>
      <v-card-title> License Key </v-card-title>

      <v-card-text class="mt-4 font-weight-light">
        <span>Insert the management license key</span>
        <v-form ref="adminPasswordForm">
          <v-text-field
            v-model.trim="inputBoxValue"
            label="Enter the license key"
            :type="showPassword ? 'text' : 'password'"
            prepend-inner-icon="lock"
            autofocus
            autocomplete="new-password"
            :rules="[requiredField]"
            validate-on-blur
            @keypress.enter.prevent="sendConfirmation"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="inputBoxValue.length < 1" tabindex="-1">
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          color="success"
          class="ml-4"
          :disabled="inputBoxValue.length == 0"
          @click="sendConfirmation"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>Submit
        </v-btn>
        <v-btn outlined color="error" @click="sendDeny">
          <v-icon left>
            mdi-close
          </v-icon>Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";

export default {
  name: "AskSuperAdminPasswordDialog",
  props: ["showConfirm"],
  data: function () {
    return {
      showPassword: false,
      inputBoxValue: "",
    };
  },
  mounted() {
    this.resetValidation();
  },
  methods: {
    ...fieldValidators,
    sendConfirmation() {
      if (this.inputBoxValue.length > 0) {
        this.$emit(this.$store.getters.getEvents.DIALOG_CONFIRM, this.inputBoxValue);
        this.inputBoxValue = "";
      }
    },
    sendDeny() {
      this.$emit(this.$store.getters.getEvents.DIALOG_DENY);
      this.inputBoxValue = "";
      this.resetValidation();
    },
    resetValidation() {
      if (this.$refs.adminPasswordForm) {
        this.$refs.adminPasswordForm.resetValidation();
      }
    },
  },
};
</script>
