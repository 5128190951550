<template>
  <v-app :class="!isDarkMode ? 'loginBackground' : 'loginBackground-dark'">
    <ResultSnackbar ref="snackbar" />

    <v-container
      fluid
      fill-height
    >
      <v-card
        width="400"
        raised
        class="mx-auto"
      >
        <v-card-title>
          <v-img
            v-show="isDarkMode"
            class="ma-10"
            max-width="350px"
            max-height="145px"
            aspect-ratio="2.4"
            :src="require('../assets/img/logo_full_white.png')"
          />
          <v-img
            v-show="!isDarkMode"
            class="ma-10"
            max-width="350px"
            max-height="145px"
            aspect-ratio="2.4"
            :src="require('../assets/img/logo-convy-colors.png')"
          />
        </v-card-title>
        <v-divider class="primary" />
        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid"
            class="px-5 pt-5"
          >
            <v-text-field
              v-model.trim="password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[requiredField, isValidPassword]"
              prepend-icon="lock"
              name="password"
              label="Password"
              hint="At least 8 characters"
              autocomplete="new-password"
              validate-on-blur
              @keypress.enter="changePassword"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="password.length < 1"
                  tabindex="-1"
                >
                  <v-icon
                    v-show="!showPassword"
                    tabindex="-1"
                    @click="showPassword = !showPassword"
                  >
                    visibility_off
                  </v-icon>
                  <v-icon
                    v-show="showPassword"
                    tabindex="-1"
                    @click="showPassword = !showPassword"
                  >
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model.trim="passwordConfirm"
              :type="showPasswordConfirm ? 'text' : 'password'"
              :rules="[requiredField, isValidPassword, passwordAreEquals]"
              prepend-icon="lock"
              name="retypePassword"
              label="Retype password"
              hint="At least 8 characters"
              autocomplete="new-password"
              @keypress.enter="changePassword"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="passwordConfirm.length < 1"
                  tabindex="-1"
                >
                  <v-icon
                    v-show="!showPasswordConfirm"
                    tabindex="-1"
                    @click="showPasswordConfirm = !showPasswordConfirm"
                  >
                    visibility_off
                  </v-icon>
                  <v-icon
                    v-show="showPasswordConfirm"
                    tabindex="-1"
                    @click="showPasswordConfirm = !showPasswordConfirm"
                  >
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            x-large
            text
            :loading="isFormLoading"
            :disabled="!isFormValid"
            @click.prevent="changePassword"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import fieldValidators from "../helpers/fieldValidators";
import ResultSnackbar from "../components/other/ResultSnackbar";

export default {
  name: "RecoverPassword",
  components: {
    ResultSnackbar,
  },
  data() {
    return {
      showPassword: false,
      showPasswordConfirm: false,
      password: "",
      passwordConfirm: "",
      id: this.$route.query.id,
      token: this.$route.query.jwt,
      isFormValid: false,
      isFormLoading: false,
    };
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...fieldValidators,
    passwordAreEquals() {
      return this.password === this.passwordConfirm;
    },
    async changePassword() {
      if (this.isFormValid && this.password === this.passwordConfirm) {
        this.isFormLoading = true;
        try {
          const decoded = this.parseJwt(this.token);
          const patchPassword = [
            {
              op: "replace",
              path: "/authentication/credentials/password",
              value: this.password,
            },
          ];
          const headers = {
            authorization: "Bearer " + this.token,
          };
          await this.$httpNoAuth.patch("/iam/auth/" + decoded.id, patchPassword, { headers });
          this.$refs.snackbar.showSuccess("Password Updated!");
          setTimeout(() => {
            this.$router.push("/login").catch(() => {});
          }, 1000);
        } catch (error) {
          this.$refs.snackbar.showError("Fail to change password: the link is expired! Contact your administrator.");
        } finally {
          this.isFormLoading = false;
        }
      }
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      return JSON.parse(jsonPayload);
    },
  },
};
</script>
<style>
.loginBackground {
  background: url("../assets/img/background/background_light_ottanio-magenta.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.loginBackground-dark {
  background: url("../assets/img/background/background_dark_ott-mag.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
