<template>
  <v-row>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-subheader class="pl-0">
        API Key
      </v-subheader>

      <v-text-field
        v-model.trim="engine.apiKey"
        :type="showPassword ? 'text' : 'password'"
        :rules="[requiredField]"
        autocomplete="new-password"
        class="mt-0 pt-0"
      >
        <template slot="prepend-inner">
          <v-icon class="apikey-icon-prepend">
            mdi-key
          </v-icon>
        </template>
        <template slot="append">
          <v-btn depressed icon text :disabled="engine.apiKey && engine.apiKey.length < 1" tabindex="-1">
            <v-icon v-show="!showPassword" class="apikey-icon-append" tabindex="-1" @click="showPassword = !showPassword">
              visibility_off
            </v-icon>
            <v-icon v-show="showPassword" class="apikey-icon-append" tabindex="-1" @click="showPassword = !showPassword">
              visibility
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" :class="getFormColMargin">
      <v-subheader class="pl-0">
        Intent validity threshold
      </v-subheader>
      <v-slider
        v-model="engine.threshold"
        min="0"
        max="100"
        thumb-label="always"
        thumb-size="22"
        class="mt-6"
      >
        <template #prepend>
          0
        </template>
        <template #append>
          100
        </template>
      </v-slider>
    </v-col>
    <!-- advanced configuration -->
    <v-expansion-panels class="my-6 mx-0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon color="primary">mdi-tune</v-icon>
            <span class="ml-4">Advanced parameters</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mx-0">
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.completion_engine" label="Completion Engine" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.embedding_engine" label="Embedding Engine" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.max_token_completion" label="Max Token Completion" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.temperature" label="Temperature" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_p" label="Top P" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.frequency_penality" label="Frequency Penality" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.presence_penality" label="Presence Penality" />
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field v-model="engine.params.top_k" label="Number of extracted chunks from the knowledge base" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Openai",
  props: ["engine", "isEdit"],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    ...spacing,
  },
  created() {
    if (!this.engine.params) {
      // se l'engine è nuovo allora gli metto il nuovo embedding di openai 
      this.engine.params = {
        completion_engine: "gpt-4o",
        embedding_engine: "text-embedding-3-small",
        max_token_completion: "1000",
        temperature: "0.7",
        top_p: "1",
        top_k: "3",
        frequency_penality: "0.0",
        presence_penality: "0.0",
        embedding_dim: "1536",
      };
    }
    if (!this.engine.threshold && this.engine.threshold != 0) {
      this.engine.threshold = 50;
    }
    if (!this.engine.params.embedding_engine) {
      // per retrocompatibilità se l'engine è già creato continuo ad usare quello vecchio
      this.engine.params.embedding_engine = "text-embedding-ada-002"; //per non spaccare quelli già esistenti
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
<style scoped>
.field-label {
  font-size: 13px;
}
.apikey-icon-prepend {
  font-size: 22px !important;
  position: relative !important;
  bottom: -4px;
}
.apikey-icon-append {
  font-size: 22px !important;
  margin-top: -4px !important;
}
</style>
