<template>
  <v-container fluid>
    <ResultSnackbar ref="snackbar" />

    <v-form v-model="form.isValid" class="changeProfileForm">
      <v-container fluid>
        <v-card raised class="mt-12 mx-10 px-3 pt-9 pb-3 mainCard">
          <v-row justify="center" class="mb-4">
            <v-col cols="12" md="3">
              <v-row no-gutters justify="center">
                <v-col
                  cols="5"
                  offset="2"
                  offset-sm="1"
                  sm="4"
                  offset-md="0"
                  md="10"
                >
                  <v-row justify="center">
                    <v-avatar size="130" :color="user.info.color">
                      <span v-if="!getAvatar(user).thereIsAvatar" style="font-size: 3rem" :style="getAvatarFontColor(user.info.color)">
                        {{ getUserInitials(user) }}</span>
                      <img v-else :src="getAvatar(user).avatarPath">
                    </v-avatar>
                  </v-row>
                  <!-- bottone 'Change avatar' -->
                  <v-row justify="center" class="py-0">
                    <v-btn class="mt-4" small color="primary" @click="openAvatarPicker">
                      Change Avatar
                    </v-btn>
                    <AvatarPicker v-model="showAvatarPicker" :current-avatar="user.info.avatar" @selected="selectAvatar" />
                  </v-row>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <image-uploader
                    ref="uploader"
                    :preview="false"
                    class="fileinput"
                    capture="false"
                    :debug="0"
                    accept=".gif, .jpg, .jpeg, .png, .bmp"
                    :max-width="130"
                    :max-height="130"
                    :maxSize="0.0169"
                    :quality="1"
                    :autoRotate="false"
                    outputFormat="string"
                    @input="setImage"
                  >
                    <!-- Le immagini che superano maxSize (:maxSize = width * height / 1000) o le dimensioni (130px), vengono ridimensionate automaticamente dalla libreria prima del caricamento -->
                    <label slot="upload-label" for="fileInput" style="display: inline-flex">
                      <figure style="width: 25px" class="avatarImage">
                        <div :class="$vuetify.breakpoint.smAndDown ? 'tooltip ml-10 mt-n5' : 'tooltip ml-n1 mt-n10'">
                          <span class="tooltiptext">Upload your custom avatar</span>
                        </div>
                        <svg class="avatarImageSVG" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                          <path
                            :fill="user.info.darkTheme ? '#fff' : '#0000008a '"
                            d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z"
                          />
                        </svg>
                      </figure>
                    </label>
                  </image-uploader>
                  <v-menu offset-y>
                    <template #activator="{ on: onMenu }">
                      <v-tooltip bottom nudge-right="50">
                        <template #activator="{ on: onTooltip }">
                          <v-icon
                            style="align-self: flex-end"
                            :color="user.info.darkTheme ? '#fff' : '#0000008a '"
                            v-on="{ ...onMenu, ...onTooltip }"
                            @update:color="pickColor($event)"
                          >
                            mdi-eyedropper-variant
                          </v-icon>
                        </template>

                        <span>Change background color</span>
                      </v-tooltip>
                    </template>
                    <v-color-picker v-model="user.info.color" :value="user.info.darkTheme ? '#fff' : '#039BE5'" dot-size="25" swatches-max-height="200" />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="user.info.name"
                    prepend-inner-icon="person"
                    type="text"
                    :rules="[requiredField]"
                    name="firstName"
                    label="First name"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @keypress.enter="saveProfile"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="user.info.surname"
                    prepend-inner-icon="person"
                    type="text"
                    :rules="[requiredField]"
                    name="lastName"
                    label="Last name"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @keypress.enter="saveProfile"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="user.info.language"
                    name="language"
                    :items="$store.getters.getUserLanguages"
                    prepend-inner-icon="flag"
                    :rules="[requiredField]"
                    label="Language"
                  />
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center">
                  <v-switch v-model="switchModel" class="mr-4" color="primary" label="Use dark theme" @change="switchTheme" />
                  <v-tooltip v-if="switchModel != user.info.darkTheme" top class="px-4">
                    <template #activator="{ on, attrs }">
                      <v-icon color="warning" dark v-bind="attrs" v-on="on">
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>Save profile to keep this theme</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-if="rowChangePassword">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="oldPassword"
                    :type="showOldPassword ? 'text' : 'password'"
                    :rules="[requiredField]"
                    chips
                    label="Old password"
                    single-line
                    prepend-inner-icon="lock"
                    autocomplete="new-password"
                  >
                    <template slot="append">
                      <v-btn
                        small
                        depressed
                        icon
                        text
                        :disabled="oldPassword.length < 1"
                        tabindex="-1"
                      >
                        <v-icon v-show="!showOldPassword" tabindex="-1" @click="showOldPassword = !showOldPassword">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showOldPassword" tabindex="-1" @click="showOldPassword = !showOldPassword">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.trim="newPassword"
                    :type="showNewPassword ? 'text' : 'password'"
                    :rules="[requiredField, isValidPassword]"
                    chips
                    label="New password"
                    single-line
                    prepend-inner-icon="lock"
                    hint="At least 8 characters"
                    autocomplete="new-password"
                  >
                    <template slot="append">
                      <v-btn
                        small
                        depressed
                        icon
                        text
                        :disabled="newPassword.length < 1"
                        tabindex="-1"
                      >
                        <v-icon v-show="!showNewPassword" tabindex="-1" @click="showNewPassword = !showNewPassword">
                          visibility_off
                        </v-icon>
                        <v-icon v-show="showNewPassword" tabindex="-1" @click="showNewPassword = !showNewPassword">
                          visibility
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="confirmNewPassword"
                    :append-icon="showConfirmNewPassword ? 'visibility' : 'visibility_off'"
                    :type="showConfirmNewPassword ? 'text' : 'password'"
                    :rules="[requiredField, isValidPassword]"
                    chips
                    label="Confirm new password"
                    single-line
                    prepend-inner-icon="lock"
                    autocomplete="new-password"
                    @click:append="showConfirmNewPassword = !showConfirmNewPassword"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9" offset-md="3">
              <v-btn class="mr-4 mt-2" color="success" :loading="form.submitLoading" :disabled="!form.isValid" @click.prevent="saveProfile">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
              <v-btn class="mt-2" color="primary" @click.stop="rowChangePassword = !rowChangePassword">
                <v-icon left>
                  mdi-lock
                </v-icon>
                <span v-if="rowChangePassword">hide change password</span>
                <span v-else>show change password</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import AvatarPicker from "../../components/login/AvatarPicker";
import avatars from "../../helpers/avatars";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";

export default {
  name: "ChangeProfile",
  components: {
    ResultSnackbar,
    AvatarPicker,
  },
  data() {
    return {
      form: {
        isValid: false,
        submitLoading: false,
      },
      user: this.$store.getters.getUser,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      rowChangePassword: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      image: null,
      showAvatarPicker: false,
      switchModel: false,
    };
  },
  beforeMount() {
    if (this.user.info.darkTheme || this.$vuetify.theme.dark) {
      this.switchModel = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    //Aggiorno il profilo prima di renderlo editabile
    const updatedUserResponse = await this.$http.get("/user/profile/" + this.user._id);
    if (updatedUserResponse && updatedUserResponse.data) {
      this.$store.state.loginResponse.params.customerProfile.user = updatedUserResponse.data;
    }
    //Load data
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
  },
  methods: {
    ...fieldValidators,
    ...avatars,
    changeTheme(e) {
      this.user.info.darkTheme = e;
      this.switchModel = e;
    },
    pickColor(color) {
      this.user.info.color = color.hex;
    },
    async saveProfile() {
      if (this.form.isValid) {
        if (this.user && this.user.info) {
          this.user.info.darkTheme = this.switchModel;
        }
        if (!this.rowChangePassword || this.newPassword === this.confirmNewPassword) {
          try {
            this.form.submitLoading = true;
            await this.$http.patch("/user/change-profile/" + this.user._id, this.user);
            if (this.rowChangePassword) {
              await this.$http.patch("/user/password/" + this.user._id, {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
              });
            }
            await this.$store.dispatch("UpdateUser", this.user);
            if (this.rowChangePassword) {
              this.$refs.snackbar.showSuccess("Password changed and profile updated!");
            } else {
              this.$refs.snackbar.showSuccess("Profile Changed!");
            }
            this.rowChangePassword = false;
          } catch (err) {
            this.$refs.snackbar.showError("Fail to change profile: " + err.message);
          } finally {
            this.form.submitLoading = false;
          }
        } else {
          this.$refs.snackbar.showError("New passwords don't match");
        }
      }
    },
    switchTheme(e) {
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_DARK_THEME, e);
      //Questo doppio evento serve perchè ogni tanto non funziona al primo tentativo
      setTimeout(() => {
        EventBus.$emit(this.$store.getters.getEvents.ENABLE_DARK_THEME, e);
      }, 100);
    },
    setImage(output) {
      this.user.info.avatar = output;
    },
    openAvatarPicker() {
      this.showAvatarPicker = true;
    },
    selectAvatar(avatar) {
      if (avatar.id != "0") {
        this.user.info.avatar = avatar.name;
      } else {
        this.user.info.avatar = "avatar-00.png";
      }
    },
  },
};
</script>

<style>
.changeProfileForm #fileInput {
  display: none;
}
.changeProfileForm .avatarImageSVG {
  cursor: pointer;
}
.changeProfileForm .avatarImage .tooltiptext {
  visibility: hidden;
  opacity: 0;
}
.changeProfileForm .avatarImage:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.changeProfileForm .avatarImage .tooltip {
  visibility: hidden;
  opacity: 0;
}
.changeProfileForm .avatarImage:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.theme--light .changeProfileForm .tooltip {
  background: #31708e;
}
.theme--dark .changeProfileForm .tooltip {
  background: #263238;
  border: 1px solid #455a64;
}
.changeProfileForm .tooltip {
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: block;
  padding: 5px 16px;
  position: absolute;
  text-transform: none;
  width: auto;
  opacity: 1;
}
</style>
