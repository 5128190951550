<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Queue"
      @input="
        searchString = $event;
        forceRerender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && !newConfig && getQueuesList.length === 0 && searchString == ''" cols="12">
        <v-alert type="warning">
          No Queues configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && !newConfig && getQueuesList.length === 0 && searchString != ''" cols="12">
        <v-alert type="warning">
          No Queue match your search
        </v-alert>
      </v-col>

      <!-- Expansion per nuovo elemento -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Queue
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <QueueForm :queuesList="queues" :profilesList="profiles" :queueDetail="newQueue" :emailBoxList="emailBoxList" :searchString="searchString" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(queue, id) in getQueuesList" :key="'queue_' + id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row class="spacer" no-gutters>
                <v-col cols="3" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title class="pt-2">
                        {{ queue.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col :cols="getColumnVisibility(queue) ? 3 : 6" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Enabled Channels</v-list-item-subtitle>
                      <v-list-item-title class="pt-1">
                        <v-tooltip color="primary" top>
                          <template #activator="{ on }">
                            <v-icon
                              v-if="queue.channels.social && queue.channels.social.enabled"
                              size="22"
                              class="mx-2 pt-1"
                              color="primary"
                              v-on="on"
                            >
                              mdi-chat-outline
                            </v-icon>
                          </template>
                          <span>Social</span>
                        </v-tooltip>
                        <v-tooltip color="primary" top>
                          <template #activator="{ on }">
                            <v-icon
                              v-if="queue.channels.email && queue.channels.email.enabled"
                              size="22"
                              class="mx-2"
                              color="primary"
                              v-on="on"
                            >
                              mdi-email-outline
                            </v-icon>
                          </template>
                          <span>Email</span>
                        </v-tooltip>
                        <v-tooltip color="primary" top>
                          <template #activator="{ on }">
                            <v-icon
                              v-if="queue.channels.chat && queue.channels.chat.enabled"
                              size="22"
                              class="mx-2"
                              color="primary"
                              v-on="on"
                            >
                              mdi-message-text-outline
                            </v-icon>
                          </template>
                          <span>Chat</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col v-if="getColumnVisibility(queue)" cols="3" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-tooltip color="primary" top>
                        <template #activator="{ on }">
                          <v-list-item-subtitle v-on="on">
                            Chat Collaboration Tools
                          </v-list-item-subtitle>
                        </template>
                        <span>Audio, video and screen sharing are options available for the chat channel only</span>
                      </v-tooltip>
                      <v-list-item-title v-if="queue.channels.chat.features.audioVideo.enabled" class="pt-1">
                        <v-tooltip
                          v-if="
                            queue.channels.chat.features.audioVideo &&
                              (queue.channels.chat.features.audioVideo.agent.startCall || queue.channels.chat.features.audioVideo.customer.startCall)
                          "
                          color="primary"
                          top
                        >
                          <template #activator="{ on }">
                            <v-icon size="22" class="mx-2" color="primary" v-on="on">
                              mdi-phone
                            </v-icon>
                          </template>
                          <span>Audio options enabled for {{ getTooltip(queue.channels.chat.features.audioVideo, "audio") }}</span>
                        </v-tooltip>

                        <v-tooltip
                          v-if="
                            queue.channels.chat.features.audioVideo &&
                              (queue.channels.chat.features.audioVideo.agent.enableVideo || queue.channels.chat.features.audioVideo.customer.enableVideo)
                          "
                          color="primary"
                          top
                        >
                          <template #activator="{ on }">
                            <v-icon size="22" class="mx-2" color="primary" v-on="on">
                              mdi-video
                            </v-icon>
                          </template>
                          <span>Video options enabled for {{ getTooltip(queue.channels.chat.features.audioVideo, "video") }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="isRecordingEnabled(queue)" color="primary" top>
                          <template #activator="{ on }">
                            <v-icon size="22" class="mx-2" color="primary" v-on="on">
                              mdi-record-circle-outline
                            </v-icon>
                          </template>
                          <span>Call recording option enabled for agent</span>
                        </v-tooltip>
                        <v-tooltip
                          v-if="
                            queue.channels.chat.features.audioVideo &&
                              (queue.channels.chat.features.audioVideo.agent.enableScreenSharing ||
                                queue.channels.chat.features.audioVideo.customer.enableScreenSharing)
                          "
                          color="primary"
                          top
                        >
                          <template #activator="{ on }">
                            <v-icon size="22" class="mx-2" color="primary" v-on="on">
                              mdi-monitor-share
                            </v-icon>
                          </template>
                          <span>Screen Sharing options enabled for {{ getTooltip(queue.channels.chat.features.audioVideo, "screenSharing") }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="3" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title class="pt-2">
                        {{ queue.updated_at | formatVerboseDateTime }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <QueueForm :key="forceRerender + id + '_sr'" :queuesList="queues" :queueDetail="queue" :emailBoxList="emailBoxList" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredQueues.length > 1"
      :items="filtredQueues.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRerender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" name="Queue" @addNewItem="addNewQueue" />
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import QueueForm from "../../components/humanAgents/QueueForm";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
import Pagination from "../../components/other/Pagination";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "Queues",
  components: {
    ResultSnackbar,
    SearchBar,
    AddNewItemButton,
    QueueForm,
    Pagination,
  },
  data() {
    return {
      searchString: "",
      forceRerender: 0,
      expansionPanelCreation: null,
      expansionPanel: null,
      emailBoxList: [],
      queues: [],
      profiles: [],
      newConfig: false,
      queueSettings: null,
      newQueue: null,
      showWarning: false,
      filtredQueues: [],
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
    };
  },
  computed: {
    ...spacing,
    getQueuesList() {
      this.forceRerender;
      let filterData = this.filterData();
      this.scrollToTop();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("wcs")) {
      this.$router.push("/");
    }
    EventBus.$on(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, this.profileCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_QUEUE_SUCCESS, this.queueCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_QUEUE_FAIL, this.queueCreatedFail);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_QUEUE_CREATION, this.cancelQueueCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_QUEUE_SUCCESS, this.queueDeleted);
    EventBus.$on(this.$store.getters.getEvents.DELETE_QUEUE_FAIL, this.failDeleteQueue);
    EventBus.$on(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
    let response = null;
    try {
      response = await this.$http.get("/human-agents/queue");
      this.queues = response.data;
    } finally {
      response = null;
    }
    let responseProfile = null;
    try {
      responseProfile = await this.$http.get("/human-agents/profile");
      this.profiles = responseProfile.data;
    } finally {
      responseProfile = null;
    }
    this.emailBoxList.push("System Email Box");
    try {
      const emailBoxResponse = await this.$http.get("/mail-settings");
      for (let i in emailBoxResponse.data) {
        this.emailBoxList.push(i);
      }
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, this.profileCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_QUEUE_SUCCESS, this.queueCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_QUEUE_FAIL, this.queueCreatedFail);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_QUEUE_CREATION, this.cancelQueueCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_QUEUE_SUCCESS, this.queueDeleted);
    EventBus.$off(this.$store.getters.getEvents.DELETE_QUEUE_FAIL, this.failDeleteQueue);
    EventBus.$off(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,
    getColumnVisibility(queue) {
      if (
        queue.channels.chat &&
        queue.channels.chat.enabled &&
        queue.channels.chat.features &&
        queue.channels.chat.features.audioVideo &&
        queue.channels.chat.features.audioVideo.enabled
      ) {
        return true;
      }
      return false;
    },
    getTooltip(features, which) {
      let string = "";
      if (features && features.customer && features.agent) {
        switch (which) {
          case "audio":
            string = features.customer.startCall ? "customer" : "agent";
            string += features.agent.startCall && features.customer.startCall ? " and agent" : "";
            break;
          case "video":
            string = features.customer.enableVideo ? "customer" : "agent";
            string += features.agent.enableVideo && features.customer.enableVideo ? " and agent" : "";
            break;
          case "screenSharing":
            string = features.customer.enableScreenSharing ? "customer" : "agent";
            string += features.agent.enableScreenSharing && features.customer.enableScreenSharing ? " and agent" : "";
            break;
        }
      }
      return string;
    },
    onChangeCompany() {
      if (!this.$store.getters.isIntegrationEnabled("wcs")) {
        this.$router.push("/");
      }
    },
    addNewQueue() {
      this.newConfig = true;
      if (!this.newQueue) {
        this.newQueue = {
          name: "",
          type: "presence",
          channels: {
            chat: {
              enabled: false,
              timeout: 60,
              timeoutPreferredAgent: 30,
              features: {
                audioVideo: {
                  enabled: false,
                  agent: {
                    startCall: false,
                    enableVideo: false,
                    enableScreenSharing: false,
                  },
                  customer: {
                    startCall: true,
                    enableVideo: false,
                    enableScreenSharing: false,
                  },
                  autoStart: false,
                },
              },
              sla: {
                enabled: false,
                timeout: 0,
              },
            },
            social: {
              enabled: false,
              timeout: 86400, //1440 minuti -> 24 ore
              timeoutPreferredAgent: 28800, //480 minuti -> 8 ore,
              features: {},
              sla: {
                enabled: false,
                timeout: 0,
              },
            },
            audio: {
              enabled: false,
              timeout: 0,
              timeoutPreferredAgent: 0,
              features: {},
            },
            video: {
              enabled: false,
              timeout: 0,
              timeoutPreferredAgent: 0,
              features: {},
            },
            webcallback: {
              enabled: false,
              timeout: 86400, //1440 minuti -> 24 ore
              timeoutPreferredAgent: 28800, //480 minuti -> 8 ore,
              features: {},
              sla: {
                enabled: false,
                timeout: 0,
              },
            },
          },
          features: {},
          emailBox: "",
          routing: {
            timeoutRona: 20,
            autoaccept: false,
            transfer: {
              enabled: false,
              targets: [],
            },
          },
          contactResult: {
            enabled: false,
            required: false,
            options: {},
          },
        };
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    queueCreatedFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    queueCreated(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      const index = this.queues.findIndex((p) => p.uuid === obj.editQueue.uuid);
      if (index < 0) {
        //Siamo in creazione devo fare una push nel mio array che poi verrà ordinato dalla computed
        this.queues.push(obj.editQueue);
      } else {
        //Siamo in edit aggiorno quello che ho trovato
        this.queues[index] = merge({}, obj.editQueue);
      }
      this.closeAllPanels();
      this.forceRerender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    profileCreated(profile) {
      this.profiles.push(profile);
    },
    closeAllPanels: function () {
      this.cancelQueueCreation();
      this.expansionPanel = null;
    },
    cancelQueueCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    saveQueueCreation(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    queueDeleted(obj) {
      const index = this.queues.findIndex((p) => p.uuid === obj.queue);
      if (index > -1) {
        this.queues.splice(index, 1);
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        this.forceRerender++;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    failDeleteQueue(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    onEriCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    isRecordingEnabled(queue) {
      return (
        this.hasJanusIntegration() &&
        queue.channels.chat.features.audioVideo &&
        queue.channels.chat.features.recording &&
        queue.channels.chat.features.recording.enabled
      );
    },
    filterData() {
      let result = [].concat(this.queues);
      this.filtredQueues = [];
      let social = "social";
      let chat = "chat";
      let email = "email";
      let audio = "audiocall";
      let video = "videocall";
      let screen = "screen sharing";
      for (let queue of result) {
        if (
          queue.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
          queue.eri.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
          (social.indexOf(this.searchString.toLowerCase()) != -1 && queue.channels.social && queue.channels.social.enabled) ||
          (chat.indexOf(this.searchString.toLowerCase()) != -1 && queue.channels.chat && queue.channels.chat.enabled) ||
          (email.indexOf(this.searchString.toLowerCase()) != -1 && queue.channels.email && queue.channels.email.enabled) ||
          (audio.indexOf(this.searchString.toLowerCase()) != -1 &&
            queue.channels.chat.features.audioVideo &&
            queue.channels.chat.features.audioVideo.enabled &&
            (queue.channels.chat.features.audioVideo.agent.startCall || queue.channels.chat.features.audioVideo.customer.startCall)) ||
          (video.indexOf(this.searchString.toLowerCase()) != -1 &&
            queue.channels.chat.features.audioVideo &&
            queue.channels.chat.features.audioVideo.enabled &&
            (queue.channels.chat.features.audioVideo.agent.enableVideo || queue.channels.chat.features.audioVideo.customer.enableVideo)) ||
          (screen.indexOf(this.searchString.toLowerCase()) != -1 &&
            queue.channels.chat.features.audioVideo &&
            queue.channels.chat.features.audioVideo.enabled &&
            (queue.channels.chat.features.audioVideo.agent.enableScreenSharing || queue.channels.chat.features.audioVideo.customer.enableScreenSharing))
        ) {
          this.filtredQueues.push(queue);
        }
      }

      //Sorting per un campo
      this.filtredQueues.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return this.filtredQueues;
    },
  },
};
</script>
<style lang="scss">
#badgeCall {
  // z-index:100!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
#badgeVideo {
  // z-index:99!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
#badgeScreen {
  // z-index:98!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
</style>
