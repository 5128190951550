<template>
  <v-container fluid>
    <v-row v-if="showContent">
      <v-col v-if="Object.keys(bots).length === 0" cols="12" class="py-4 pr-12">
        <v-alert type="warning" icon="mdi-alert" class="fill-width my-12 mx-4 pt-4" align="center" justify="center">
          <v-row>
            <v-col cols="12">
              To create a connector you first need a bot: start by clicking on Design and then go to the Bots page or using the shortcut button below:
            </v-col>
            <v-col cols="12" class="pt-4" align="center" justify="center">
              <router-link class="white--text headline" to="/Bots" style="text-decoration: none">
                <v-btn small color="white" class="primary--text">
                  <v-icon x-small left>
                    mdi-arrow-right
                  </v-icon> to the Bots page
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col v-else cols="12" class="ma-0 py-4">
        <ResultSnackbar ref="resultSnackbar" />

        <SearchBar
          :search-string="searchString"
          title="Configure your connectors"
          @input="
            searchString = $event;
            forceRerender++;
          "
        />
        <v-row justify="center" :class="getMainRowMargin">
          <v-col v-if="getConnectorList.length == 0 && !showAddNewConnectorPanel && searchString == ''" cols="12">
            <v-alert type="warning">
              No Connectors configured
            </v-alert>
          </v-col>
          <v-col v-else-if="getConnectorList.length == 0 && !showAddNewConnectorPanel && searchString != ''" cols="12">
            <v-alert type="warning">
              No Connector match your search
            </v-alert>
          </v-col>
          <!-- riga per la creazione di un nuovo elemento -->
          <v-expansion-panels v-if="showAddNewConnectorPanel" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row align="center" class="spacer" no-gutters>
                  <v-col cols="12">
                    Publish your bot!
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AddNewConnector
                  :bots="bots"
                  :connectors="connectors"
                  :profiles="profiles"
                  :whatsapp-templates="whatsappTemplates"
                  @cancelCreation="showAddNewConnectorPanel = false"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
            <v-expansion-panel v-for="(connector, id) in getConnectorList" :key="id" @change="updateMainScrollbar">
              <v-hover v-slot="{ hover }">
                <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
                  <v-row align="center" class="spacer" no-gutters>
                    <v-col cols="12" sm="6" md="4" class="text-no-wrap" :class="$vuetify.breakpoint.lgAndUp ? 'pl-10' : ''">
                      <v-list-item dense>
                        <v-list-item-avatar>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-avatar tile :size="getAvatarSize" v-on="on">
                                <v-img v-if="connector.type != 'email'" alt="Avatar" :src="getConnectorAvatar(connector.type)" />
                                <v-icon v-else color="primary" style="font-size: 36px">
                                  mdi-email-outline
                                </v-icon>
                              </v-avatar>
                            </template>
                            <span>{{ connector.type }}</span>
                          </v-tooltip>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle>Name</v-list-item-subtitle>
                          <v-list-item-title>
                            {{ connector.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col class="hidden-xs-only text-no-wrap" cols="6" md="4">
                      <v-badge
                        bordered
                        avatar
                        overlap
                        offset-x="65"
                        offset-y="20"
                        left
                        :value="(connector.enabled && connector?.outboundParams?.enabled && $store.getters.isIntegrationEnabled('outbound')) || false"
                      >
                        <template #badge>
                          <v-icon class="mt-1">
                            mdi-bullhorn-outline
                          </v-icon>
                        </template>
                        <v-list-item dense>
                          <v-list-item-avatar style="border-radius: 50% !important" class="elevation-1">
                            <v-avatar size="40" tile>
                              <img v-if="isAvatarAvailable(connector)" alt="Bot Avatar" :src="getBotAvatarFromPublish(connector)">
                              <v-icon v-else color="error" :size="getAvatarSize">
                                mdi-alert-outline
                              </v-icon>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-subtitle v-if="!connector.enabled">
                              Not Published!
                            </v-list-item-subtitle>
                            <template v-else>
                              <v-list-item-subtitle>Bot</v-list-item-subtitle>
                              <v-list-item-title>
                                {{ getBotNameFromPublish(connector) }}
                              </v-list-item-title>
                            </template>
                          </v-list-item-content>
                        </v-list-item>
                      </v-badge>
                    </v-col>

                    <v-col class="hidden-sm-and-down" cols="4">
                      <v-list-item dense>
                        <v-list-item-content>
                          <v-list-item-subtitle>Updated</v-list-item-subtitle>
                          <v-list-item-title v-if="connector.updated_at">
                            {{ connector.updated_at | formatVerboseDateTime }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-else>
                            -
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
              </v-hover>
              <v-expansion-panel-content>
                <v-divider />
                <ConnectorForm
                  :key="forceRerender + id + '_cf'"
                  :connector="connector"
                  :connectors="connectors"
                  :bots="bots"
                  :customer-interfaces-list="customerInterfacesList"
                  :profiles="profiles"
                  :whatsapp-templates="whatsappTemplates"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>

        <AddNewItemButton name="Connector" @addNewItem="addNewConnector()" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConnectorForm from "../../components/publish/ConnectorForm";
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import AddNewConnector from "../../components/publish/AddNewConnector";

export default {
  name: "Connectors",
  components: {
    ConnectorForm,
    ResultSnackbar,
    AddNewItemButton,
    SearchBar,
    AddNewConnector,
  },
  data() {
    return {
      bots: {},
      connectors: [],
      customerInterfacesList: [],
      whatsappTemplates: {},
      profiles: [],
      showContent: false,
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      searchString: this.$route.query["q"] || "",
      //Data bind per creazione nuovo connettore
      showAddNewConnectorPanel: false,
    };
  },
  computed: {
    ...spacing,
    getConnectorList() {
      this.forceRerender;
      return (
        this.connectors
          //Filtro per la ricerca
          .filter((connector) => {
            if (
              connector.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
              connector.type.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
            ) {
              return true;
            }
            const bot = this.bots[connector.bot_id];
            if (bot && bot.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) {
              return true;
            }
            return false;
          })
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.EDIT_CONNECTOR, this.onConnectorUpdate);
    EventBus.$on(this.$store.getters.getEvents.DELETE_CONNECTOR, this.deleteConnector);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_CONNECTOR_CREATION, this.cancelConnectorCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_CONNECTOR_FAIL, this.deleteConnectorFail);
    EventBus.$on(this.$store.getters.getEvents.EDIT_CONNECTOR_FAIL, this.onConnectorFailedUpdate);
    EventBus.$on(this.$store.getters.getEvents.CONNECTOR_UNPUBLISHED, this.onConnectorUnpublished);
    EventBus.$on(this.$store.getters.getEvents.CONNECTOR_UNPUBLISH_FAIL, this.onConnectorUnpublishFail);
    EventBus.$on(this.$store.getters.getEvents.CONNECTOR_PUBLISHED, this.onConnectorPublished);
    EventBus.$on(this.$store.getters.getEvents.CONNECTOR_PUBLISH_FAIL, this.onConnectorPublishFail);
    EventBus.$on(this.$store.getters.getEvents.CONNECTOR_CREATED, this.onConnectorCreatedAndPublished);
    EventBus.$on(this.$store.getters.getEvents.HTML_SNIPPET_COPIED, this.onSnippetCopy);
    EventBus.$on(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onWebhookCopied);
    EventBus.$on(this.$store.getters.getEvents.WHATSAPP_TEMPLATES_TEST_CONFIGURATION, this.whatsappTemplatesTestConfiguration);
    EventBus.$on(this.$store.getters.getEvents.SHOW_SNACKBAR, this.onShowSnackbar);
    try {
      const botResponse = await this.$http.get("/bot");
      const socialConnectorResponse = await this.$http.get("/social-connector");
      const customerInterfaceResponse = await this.$http.get("/customer-ui/exists");
      const profileResult = await this.$http.get("/human-agents/profile");
      const whatsappTemplatesResponse = await this.$http.get("/whatsapp-template");
      if (Object.keys(whatsappTemplatesResponse.data).length === 0) {
        this.whatsappTemplates = [];
      } else {
        this.whatsappTemplates = whatsappTemplatesResponse.data;
      }
      this.profiles = profileResult.data;
      this.bots = botResponse.data;
      this.connectors = socialConnectorResponse.data;
      this.customerInterfacesList = customerInterfaceResponse.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    } finally {
      if (this.$route.query["a"] === "new") {
        this.addNewConnector();
      }
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showContent = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_CONNECTOR, this.onConnectorUpdate);
    EventBus.$off(this.$store.getters.getEvents.DELETE_CONNECTOR, this.deleteConnector);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_CONNECTOR_CREATION, this.cancelConnectorCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_CONNECTOR_FAIL, this.deleteConnectorFail);
    EventBus.$off(this.$store.getters.getEvents.EDIT_CONNECTOR_FAIL, this.onConnectorFailedUpdate);
    EventBus.$off(this.$store.getters.getEvents.CONNECTOR_UNPUBLISHED, this.onConnectorUnpublished);
    EventBus.$off(this.$store.getters.getEvents.CONNECTOR_UNPUBLISH_FAIL, this.onConnectorUnpublishFail);
    EventBus.$off(this.$store.getters.getEvents.CONNECTOR_PUBLISHED, this.onConnectorPublished);
    EventBus.$off(this.$store.getters.getEvents.CONNECTOR_PUBLISH_FAIL, this.onConnectorPublishFail);
    EventBus.$off(this.$store.getters.getEvents.CONNECTOR_CREATED, this.onConnectorCreatedAndPublished);
    EventBus.$off(this.$store.getters.getEvents.HTML_SNIPPET_COPIED, this.onSnippetCopy);
    EventBus.$off(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onWebhookCopied);
    EventBus.$off(this.$store.getters.getEvents.WHATSAPP_TEMPLATES_TEST_CONFIGURATION, this.whatsappTemplatesTestConfiguration);
    EventBus.$off(this.$store.getters.getEvents.SHOW_SNACKBAR, this.onShowSnackbar);
  },
  methods: {
    ...scroller,
    onShowSnackbar(obj) {
      if (obj.type == "success") {
        this.$refs.resultSnackbar.showSuccess(obj.msg);
      } else if (obj.type == "error") {
        this.$refs.resultSnackbar.showError(obj.msg);
      }
    },
    whatsappTemplatesTestConfiguration(templates) {
      const keys = Object.keys(templates);
      if (keys?.length === 1) {
        this.whatsappTemplates[keys[0]] = templates[keys[0]];
      }
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    clearString() {
      this.searchString = "";
    },
    onSnippetCopy() {
      this.$refs.resultSnackbar.showSuccess("HTML snippet copied to clipboard!");
    },
    onWebhookCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    onConnectorCreatedAndPublished(connector) {
      const index = this.connectors.findIndex((c) => c.uuid === connector.uuid);
      if (index >= 0) this.connectors[index] = connector;
      else this.connectors.push(connector);

      this.$refs.resultSnackbar.showSuccess("Connector " + connector.name + " created and published!");
      this.closeAllPanels();
      this.forceRerender++;
    },
    onConnectorPublished(connector) {
      this.updateConnectorByUUID(connector);
      this.$refs.resultSnackbar.showSuccess("Connector " + connector.name + " published!");
      this.forceRerender++;
    },
    onConnectorPublishFail(connector) {
      this.$refs.resultSnackbar.showError("Fail to publish connector " + connector.name + "!");
    },
    onConnectorUnpublished(connector) {
      this.updateConnectorByUUID(connector);
      this.$refs.resultSnackbar.showSuccess("Connector " + connector.name + " unpublished!");
      this.forceRerender++;
    },
    onConnectorUnpublishFail(connector) {
      this.$refs.resultSnackbar.showError("Fail to unpublish connector " + connector.name + "!");
    },
    onConnectorUpdate(connector) {
      this.updateConnectorByUUID(connector);
      this.$refs.resultSnackbar.showSuccess("Connector " + connector.name + " updated!");
      this.forceRerender++;
    },
    updateConnectorByUUID(connector) {
      const index = this.connectors.findIndex((c) => c.uuid === connector.uuid);
      if (index >= 0) this.connectors[index] = connector;
    },
    onConnectorFailedUpdate(connector) {
      this.$refs.resultSnackbar.showError("Fail to save connector " + connector.name + "! Check if the configurations are correct and try again");
      //this.closeAllPanels();
      //this.forceRerender++;
    },
    cancelConnectorCreation() {
      this.expansionPanelCreation = null;
      this.showAddNewConnectorPanel = false;
    },
    closeAllPanels() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
      this.cancelConnectorCreation();
      this.expansionPanel = null;
    },
    addNewConnector() {
      //Se il primo elemento non è in edit
      if (!this.showAddNewConnectorPanel) {
        this.showAddNewConnectorPanel = true;
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    deleteConnector(connector) {
      this.deleteConnectorByUUID(connector);
      this.$refs.resultSnackbar.showSuccess("Connector " + connector.name + " deleted!");
      this.closeAllPanels();
      setTimeout(() => {
        this.forceRerender++;
      }, 100);
    },
    deleteConnectorByUUID(connector) {
      const index = this.connectors.findIndex((c) => c.uuid === connector.uuid);
      if (index >= 0) this.connectors.splice(index, 1);
    },
    deleteConnectorFail(connector) {
      this.$refs.resultSnackbar.showError("Fail to delete connector " + connector.name + "!");
    },
    getConnectorAvatar(connectorType) {
      return this.$store.getters.getConnectorLogoByType(connectorType);
    },
    getBotNameFromPublish(connector) {
      const bot = this.bots[connector.bot_id];
      if (bot) {
        return bot.name;
      }
      return "Not in use!";
    },
    isAvatarAvailable(connector) {
      const bot = this.bots[connector.bot_id];
      return !!bot && connector.enabled && bot.icon;
    },
    getBotAvatarFromPublish(connector) {
      if (connector.enabled) {
        const bot = this.bots[connector.bot_id];
        if (bot) {
          return this.$store.getters.getSystemBotIcon(bot.icon, "mini", bot.isVisualIvr) || this.$store.state.enums.botTemplates[0].images[0].image.mini;
        }
      }
      return null;
    },
  },
};
</script>
