<template>
  <v-form v-model="form.isValid" :class="$vuetify.breakpoint.mdAndUp ? getCreationFormMargin : ''">
    <v-container :class="getCreationContainerMargin">
      <v-row :class="getFormRowMargin">
        <!-- colonna proprietà bot -->
        <v-col cols="12" offset-md="3" md="6" :class="getFormColMargin" align="center">
          <!-- bot name -->
          <v-text-field
            v-model.trim="botName"
            label="Bot Name"
            :rules="[requiredField, simpleAlphaNumString, isBotNameUnique(botsList, botName), charsGreaterThanAndLessThan(botName, 3, 255)]"
            prepend-inner-icon="mdi-robot"
            :autocomplete="$store.getters.disableAutocomplete"
          />
          <div v-if="newBotType.type !== 'visual_ivr' && newBotType.type !== 'outbound'" style="position: relative">
            <v-subheader class="ma-0 pa-0" style="height: 30px; text-align: center; display: block">
              Select the bot template:
            </v-subheader>

            <v-carousel
              v-model="templateSelectionIndex"
              show-arrows
              hide-delimiters
              height="180px"
              width="100px"
              class="carousel-root"
            >
              <v-carousel-item v-for="(template, i) in botTempatesFilter" :key="i">
                <v-row align="center">
                  <!-- selector avatar arrows bot -->
                  <div v-if="template.label === 'Your own flow'" id="colorsSelectionBots">
                    <div class="botColors topArrow" @click="selectAvatar(true)">
                      <img :src="require('../../assets/img/botsNew/arrowTop.png')" style="height: 10px">
                    </div>
                    <div class="botColors bottomArrow" @click="selectAvatar(false)">
                      <img :src="require('../../assets/img/botsNew/arrowBottom.png')" style="height: 10px">
                    </div>
                  </div>

                  <v-col offset="3" cols="6" align="center">
                    <v-img
                      v-if="template.label !== 'Your own flow'"
                      height="120px"
                      width="120px"
                      :src="template.image.big"
                      :alt="template.label"
                      style="margin-top: 18px"
                    />

                    <!-- custom flow colors selections -->
                    <div v-else id="avatarBotSelection" style="margin-top: 18px">
                      <v-img height="120px" width="120px" :src="template.images[ownFlowColorsIndex].image.big" :alt="template.label" />
                    </div>

                    <div class="titleFlow" :style="getLabelColor">
                      {{ template.label.toUpperCase() }}
                    </div>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </div>
          <div v-if="templateSelectionIndex === 0 || newBotType.type === 'visual_ivr' || newBotType.type === 'outbound'">
            <v-subheader v-if="newBotType.type === 'outbound'" class="mt-4">
              Create the initial flow for your bot:
            </v-subheader>
            <v-subheader v-else class="mt-4">
              Select the initial flow for your bot:
            </v-subheader>
            <v-img
              v-if="newBotType.type === 'visual_ivr'"
              height="120px"
              width="120px"
              :src="$store.getters.getBotTemplates[$store.getters.getBotTemplates.findIndex((template) => template.label === 'Visual Ivr')].image.big"
              :alt="$store.getters.getBotTemplates[$store.getters.getBotTemplates.findIndex((template) => template.label === 'Visual Ivr')].label"
              style="margin-top: 18px"
            />
            <v-img
              v-if="newBotType.type === 'outbound'"
              height="120px"
              width="120px"
              :src="$store.getters.getBotTemplates[$store.getters.getBotTemplates.findIndex((template) => template.label === 'Outbound Bot')].image.big"
              :alt="$store.getters.getBotTemplates[$store.getters.getBotTemplates.findIndex((template) => template.label === 'Outbound Bot')].label"
              style="margin-top: 18px"
            />

            <v-radio-group v-model="flowType" class="selectInitialFlow ma-0 pa-0" @change="checkSelection">
              <v-radio value="newFlow" color="primary" class="ma-0 pa-0">
                <template #label>
                  <v-text-field
                    v-model.trim="newFlowName"
                    class="ma-0 pa-0"
                    label="New Flow"
                    :rules="[validateFlowName, requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :error-messages="messageErrorFlowName"
                    @change="checkSelection('newFlow')"
                  />
                </template>
              </v-radio>
              <v-radio
                v-if="newBotType.type !== 'outbound'"
                value="existingFlow"
                color="primary"
                :disabled="getUnassignedFlowsList.length === 0"
                class="ma-0 pa-0 mt-8"
              >
                <template #label>
                  <v-autocomplete
                    v-model="existingFlow"
                    class="ma-0 pa-0"
                    label="Existing Flow"
                    auto-select-first
                    :items="getUnassignedFlowsList"
                    :filter="advancedAutocompleteFlowFilter"
                    @change="checkSelection('existingFlow')"
                  >
                    <template slot="selection" slot-scope="data">
                      Flow:&nbsp;<strong>{{ data.item.flow }}</strong>&nbsp;with version:&nbsp;<strong>{{ data.item.version }}</strong>
                    </template>
                    <template slot="item" slot-scope="data">
                      Flow:&nbsp;<strong>{{ data.item.flow }}</strong>&nbsp;with version:&nbsp;<strong>{{ data.item.version }}</strong>
                    </template>
                  </v-autocomplete>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div v-else>
            <v-radio-group v-model="language" mandatory class="ma-0 pa-0">
              <v-radio value="eng" color="primary">
                <template #label>
                  <flag iso="gb" class="mr-4" />ENG
                </template>
              </v-radio>
              <v-radio value="ita" color="primary">
                <template #label>
                  <flag iso="it" class="mr-4" />ITA
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-col>
        <v-col v-if="newBotType.type !== 'outbound'" cols="12" offset-md="3" md="6" :class="getFormColMargin">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-switch v-model="enhancedSecurity" color="primary" class="float-left mt-n2" label="Enhanced Security" v-on="on" />
            </template>
            <span>If checked, the bot will be protected from CSFR attacks.</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="newBotType.type !== 'outbound'" cols="12" offset-md="3" md="6" :class="getFormColMargin">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-switch v-model="enableRegistry" color="primary" class="float-left mt-n2" label="Enable customer history" v-on="on" />
            </template>
            <span>If unchecked, the bot will not try to reconcile the customer registry</span>
          </v-tooltip>
        </v-col>
        <!--buttons -->
        <v-col cols="12" offset-md="3" md="6" :class="getFormColMargin">
          <v-btn :class="getButtonMargin" color="success" :loading="form.submitLoading" :disabled="!form.isValid || !botName" @click.prevent="saveBot">
            <v-icon left>
              mdi-floppy
            </v-icon>Save
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :disabled="form.submitLoading" @click.prevent="cancelBotCreation">
            <v-icon left>
              mdi-close
            </v-icon>Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";
import openFD from "../../helpers/openFD";

export default {
  name: "NewBotForm",
  props: {
    "newBotType": {
      type: Object,
      required: true
    },
    "unassignedFlows": {
      type: Object,
      required: true
    },
    "botsList": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        isValid: false,
        submitLoading: false,
      },
      botName: "",
      flowType: "newFlow",
      newFlowName: "",
      existingFlow: {
        flow: "",
        version: "",
      },
      language: "eng",
      enhancedSecurity: true,
      enableRegistry: true,
      templateSelectionIndex: 0,
      messageErrorFlowName: "",
      ownFlowColorsIndex: 0,
      ownFlowColorslenght: this.$store.getters.getBotCustomTemplate.images.length - 1,
      ivrKey: this.$store.getters.getBotTemplates[this.$store.getters.getBotTemplates.findIndex((template) => template.type === "visualivr")].key,
      isOutboundKey: this.$store.getters.getBotTemplates[this.$store.getters.getBotTemplates.findIndex((template) => template.type === "outbound")].key,
    };
  },
  computed: {
    ...spacing,
    botTempatesFilter() {
      return this.$store.getters.getBotTemplates.filter((t) => t.type !== "visualivr" && t.type !== "outbound" && t.type !== "unassigned");
    },
    getLabelColor() {
      return this.$vuetify.theme.dark ? "color: #fff" : "color: rgba(0,0,0,.87)";
    },
    getUnassignedFlowsList() {
      let result = [];
      for (let flowName in this.unassignedFlows.flows) {
        let versions = Object.keys(this.unassignedFlows.flows[flowName]);
        versions.forEach((version) => {
          result.push({
            flow: flowName,
            version: version,
          });
        });
      }
      return result;
    },
  },
  methods: {
    ...fieldValidators,
    selectAvatar(up) {
      if (up) {
        if (this.ownFlowColorsIndex <= 0) {
          this.ownFlowColorsIndex = this.ownFlowColorslenght;
        } else {
          this.ownFlowColorsIndex--;
        }
      } else {
        if (this.ownFlowColorsIndex >= this.ownFlowColorslenght) {
          this.ownFlowColorsIndex = 0;
        } else {
          this.ownFlowColorsIndex++;
        }
      }
    },
    cancelBotCreation() {
      EventBus.$emit(this.$store.getters.getEvents.CANCEL_BOT_CREATION);
    },
    async saveBot() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        let bot = {};
        const isOutbound = this.newBotType.type === "outbound";
        const isIVR = this.newBotType.type === "visual_ivr";
        let seticon = isIVR ? this.ivrKey : this.$store.getters.getBotCustomTemplate.images[this.ownFlowColorsIndex].key;
        seticon = isOutbound ? this.isOutboundKey : this.$store.getters.getBotCustomTemplate.images[this.ownFlowColorsIndex].key;
        switch (this.templateSelectionIndex) {
          //Non template
          case 0:
            bot = {
              name: this.botName,
              enhancedSecurity: this.enhancedSecurity,
              enableRegistry: this.enableRegistry,
              isVisualIvr: isIVR,
              isOutbound: isOutbound,
              icon: seticon,
              language: this.language,
            };
            if (this.flowType === "newFlow") {
              bot.flow = this.newFlowName;
              bot.version = this.botName;
              bot.flows = [];
              bot.flows.push({
                flow: this.newFlowName,
                version: this.botName,
              });
              bot.restartNode = {
                flow: this.newFlowName,
                version: this.botName,
                hook: "begin",
              };
            } else {
              bot.flow = this.existingFlow.flow;
              bot.version = this.existingFlow.version;
              bot.flows = [];
              bot.restartNode = {
                flow: this.existingFlow.flow,
                version: this.existingFlow.version,
                hook: "begin",
              };
            }
            break;
          //Se viene selezionato un template
          default:
            bot = {
              name: this.botName,
              enhancedSecurity: this.enhancedSecurity,
              enableRegistry: this.enableRegistry,
              language: this.language,
              isVisualIvr: false,
              isOutbound: false,
              icon: this.$store.getters.getBotCustomTemplatebyLabel(this.getTemplateNameFromIndex(this.templateSelectionIndex)).key,
              template: this.getTemplateNameFromIndex(this.templateSelectionIndex),
            };
            break;
        }
        try {
          const response = await this.sendRequest(bot);
          if (!bot.template && this.flowType !== "newFlow") {
            const data = {
              version: bot.version,
              flow: bot.flow,
              newBotId: response.data.bot_id,
              oldBotId: "0",
            };
            await this.$http.post("/bot/move", data);
          } else if (!bot.template && this.flowType === "newFlow") {
            openFD.open(response.data.bot_id, this.newFlowName, this.botName);
          }
          EventBus.$emit(this.$store.getters.getEvents.CREATE_BOT, response.data);
        } catch (err) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_BOT_FAIL, { message: "Fail to create bot!" });
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
    sendRequest(obj) {
      if (obj.template) {
        return this.$http.post("/bot-template", obj);
      }
      return this.$http.post("/bot", obj);
    },
    getTemplateNameFromIndex(templateSelectionIndex) {
      switch (templateSelectionIndex) {
        case 1:
          return "tourism";
        case 2:
          return "restaurant";
        case 3:
          return "pmi";
      }
    },
    setImage(output) {
      this.bot.icon = output;
    },
    advancedAutocompleteFlowFilter(item, queryText) {
      return (
        item.flow.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.version.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.hook && item.hook.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      );
    },
    checkSelection($event, type) {
      //* gestione errori dei campi
      //* allo switch dei radio button
      if (type === "newFlow" || $event === "newFlow") {
        if (this.newFlowName === "") {
          this.form.isValid = false;
          this.messageErrorFlowName = "This field is required";
        } else {
          this.form.isValid = true;
          this.messageErrorFlowName = "";
        }
      }
      if (type === "existingFlow" || $event === "existingFlow") {
        this.messageErrorFlowName = "";
        if (this.existingFlow.flow === "") {
          this.form.isValid = false;
        } else {
          this.form.isValid = true;
        }
      }
    },
  },
};
</script>

<style>
.selectInitialFlow .v-input__control {
  width: 100% !important;
}
.selectInitialFlow .v-label {
  width: 100% !important;
}
.carousel-root button.v-btn--round.theme--dark {
  background-color: transparent !important;
}
.titleFlow {
  font-size: 14px;
  margin-top: 20px;
}
.botColors {
  width: 20px;
  height: 20px;
  /* color: red; */
  border-radius: 50%;
  display: block;
  cursor: pointer;
  position: relative;
}
#colorsSelectionBots {
  position: absolute;
  left: calc(50% - 10px);
  z-index: 99999;
}
.topArrow {
  top: -72px;
}
.bottomArrow {
  top: 45px;
}
</style>
