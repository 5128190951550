<template>
  <v-card flat>
    <v-row class="my-0 py-0 px-6">
      <v-col cols="6">
        <v-row class="align-center">
          <v-col cols="4" sm="4">
            <v-row>
              <v-avatar size="100" :color="user.info.color">
                <span style="font-size: 3rem" :style="getAvatarFontColor(user.info.color)" v-if="!getAvatar(user).thereIsAvatar">
                  {{ getUserInitials(user) }}</span
                >
                <v-img v-else :src="getAvatar(user).avatarPath" alt="Avatar" />
              </v-avatar>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon style="align-self: flex-end" v-on="on" @update:color="pickColor($event)"> mdi-eyedropper-variant </v-icon>
                </template>
                <v-color-picker value="user.color" v-model="user.info.color" dot-size="25" swatches-max-height="200" />
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="8" sm="8" justify="center">
            <v-btn color="primary" @click="openAvatarPicker">Change Avatar</v-btn>
            <AvatarPicker v-model="showAvatarPicker" :current-avatar="user.info.avatar" @selected="selectAvatar" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-form v-model="isValid">
      <v-row class="my-0 py-0">
        <v-col cols="12" sm="6">
          <v-text-field
            label="Email"
            v-model.trim="user.authentication.credentials.username"
            :rules="[requiredField, isValidEmail]"
            prepend-inner-icon="email"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            validate-on-blur
            v-model.trim="user.authentication.credentials.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[requiredField, isValidPassword, isUsernameInPassword(user.authentication.credentials.username, user.authentication.credentials.password)]"
            prepend-inner-icon="lock"
            autocomplete="new-password"
          >
            <template slot="append">
              <v-icon
                :disabled="user.authentication.credentials.password.length < 1"
                tabindex="-1"
                v-show="!showPassword"
                @click="showPassword = !showPassword"
              >
                visibility_off
              </v-icon>
              <v-icon :disabled="user.authentication.credentials.password.length < 1" tabindex="-1" v-show="showPassword" @click="showPassword = !showPassword">
                visibility
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field validate-on-blur label="First name" v-model.trim="user.info.name" :rules="[requiredField]" prepend-inner-icon="person" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field validate-on-blur label="Last name" v-model.trim="user.info.surname" :rules="[requiredField]" prepend-inner-icon="person" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="$store.getters.getUserLanguages"
            v-model="user.info.language"
            :rules="[requiredField]"
            label="Language"
            prepend-inner-icon="mdi-earth"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="$store.getters.getExpirationPasswordChoice"
            v-model="user.authentication.credentials.expiration_months"
            label="Password Expiration"
            prepend-inner-icon="mdi-lock"
            :return-object="false"
            :rules="[requiredField]"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-btn :disabled="!isValid" color="primary" @click="$emit('next')"> Continue </v-btn>
      <v-btn text @click="$emit('resetUser')"> Reset </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import AvatarPicker from "../AvatarPicker";
import avatars from "../../../helpers/avatars";
export default {
  name: "AccountCreation",
  props: ["user"],
  components: {
    AvatarPicker,
  },
  data() {
    return {
      isValid: false,
      showPassword: false,
      showAvatarPicker: false,
    };
  },
  computed: {
    getAvailableRoles() {
      return Object.values(this.$store.getters.getUserRoles);
    },
  },
  methods: {
    ...fieldValidators,
    ...avatars,
    pickColor(color) {
      this.user.info.color = color.hex;
    },
    openAvatarPicker() {
      this.showAvatarPicker = true;
    },
    selectAvatar(avatar) {
      if (avatar.id != "0") {
        this.user.info.avatar = avatar.name;
      } else {
        this.user.info.avatar = "avatar-00.png";
      }
    },
  },
};
</script>
