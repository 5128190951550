import { saveAs } from "file-saver";
import XLSX from "xlsx";
import Papa from "papaparse";
import moment from "moment";

const xlsxLimit = 32767;

export default {
  exportDialogDetailXlsx,
  exportDialogDetailCsv,
  exportSemanticStatistcsXlsx,
  exportSemanticStatistcsCsv,
  exportFlowNavigationStatisticsXlsx,
  exportFlowNavigationStatisticsCsv,
  exportBotNavigationStatisticsXlsx,
  exportBotNavigationStatisticsCsv,
  exportSurveyXlsx,
  exportSurveyCsv,
  exportSemanticConfidenceXlsx,
  exportSemanticConfidenceCsv,
  exportActivityXlsx,
  exportActivityCsv,
  exportIntentsCogitoMLCsv,
  exportEntitiesCogitoMLCsv,
  exportSemanticStatistcsDetailXlsx,
  exportSemanticStatistcsDetailCsv,
  exportContactCenterStatisticsXlsx,
  exportContactCenterStatisticsCsv,
  exportAgentSurveyXlsx,
  exportAgentSurveyCsv,
  exportContactReportXlsx,
  exportContactReportCsv,
};

function exportContactCenterStatisticsXlsx(data, criteria) {
  let itemFormatted = formatContactCenterStatsItem(data, criteria);
  xlsx(itemFormatted);
}

function exportContactCenterStatisticsCsv(data, criteria) {
  let itemFormatted = formatContactCenterStatsItem(data, criteria, true);
  csv(itemFormatted);
}

function formatContactCenterStatsItem(dataset, criteria, isCsv) {
  const headers = {};
  const kpiNames = {};
  const resultSet = [];

  criteria.kpis.forEach((o) => {
    kpiNames[o.value] = o.text;
  });

  dataset.forEach((item) => {
    const itemFormatted = {};
    Object.keys(item).forEach((k) => {
      let friendlyName = (!!kpiNames[k] && kpiNames[k]) || k;
      itemFormatted[friendlyName] = item[k];
      if (resultSet.length === 0) {
        headers[friendlyName] = friendlyName;
      }
    });

    let format = "DD MMM YYYY";
    if (criteria.interval.scale === "m") {
      format = "DD MMM YYYY, HH:mm";
    }
    itemFormatted.from = moment(item.from).tz(criteria.timezone.zone).format(format);
    itemFormatted.to = moment(item.to).tz(criteria.timezone.zone).format(format);
    // if (isCsv) {
    //   itemFormatted.from = itemFormatted.from.format(format);
    //   itemFormatted.to = itemFormatted.to.format(format);
    // }
    resultSet.push(itemFormatted);
  });
  if (isCsv) {
    return [...[headers], ...resultSet];
  }
  return resultSet;
}

function exportSemanticStatistcsXlsx(data) {
  let itemFormatted = formatSemanticStatisticsItem(data);
  xlsx(itemFormatted);
}

function exportSemanticStatistcsCsv(data) {
  let itemFormatted = formatSemanticStatisticsItem(data, true);
  csv(itemFormatted);
}

function exportDialogDetailXlsx(data) {
  let itemFormatted = formatItem(data);
  xlsx(itemFormatted);
}

function exportDialogDetailCsv(data) {
  let itemFormatted = formatItem(data, true);
  csv(itemFormatted);
}

function exportSurveyXlsx(data) {
  let itemFormatted = formatSurveyDetail(data);
  xlsx(itemFormatted);
}
function exportSurveyCsv(data) {
  let itemFormatted = formatSurveyDetail(data, true);
  csv(itemFormatted);
}

function exportAgentSurveyXlsx(data) {
  let itemFormatted = formatAgentSurveyDetail(data);
  xlsx(itemFormatted);
}

function exportAgentSurveyCsv(data) {
  let itemFormatted = formatAgentSurveyDetail(data, true);
  csv(itemFormatted);
}

function exportContactReportXlsx(data) {
  let itemFormatted = formatContactResult(data);
  xlsx(itemFormatted);
}

function exportContactReportCsv(data) {
  let itemFormatted = formatContactResult(data, true);
  csv(itemFormatted);
}

function exportFlowNavigationStatisticsXlsx(data, detail) {
  let itemFormatted = detail ? formatFlowNavigationDetail(data) : formatFlowNavigation(data);
  xlsx(itemFormatted);
}

function exportFlowNavigationStatisticsCsv(data, detail) {
  let itemFormatted = detail ? formatFlowNavigationDetail(data, true) : formatFlowNavigation(data, true);
  csv(itemFormatted);
}

function exportBotNavigationStatisticsXlsx(data, detail, precentage) {
  let itemFormatted = detail ? formatBotNavigationDetail(data, false, precentage) : formatFlowNavigation(data);
  xlsx(itemFormatted);
}

function exportBotNavigationStatisticsCsv(data, detail, precentage) {
  let itemFormatted = detail ? formatBotNavigationDetail(data, true, precentage) : formatFlowNavigation(data, true);
  csv(itemFormatted);
}

function exportSemanticConfidenceXlsx(data, detail) {
  let itemFormatted = detail ? formatSemanticConfidenceDetail(data, false) : formatSemanticConfidence(data, false);
  xlsx(itemFormatted);
}

function exportSemanticConfidenceCsv(data, detail) {
  let itemFormatted = detail ? formatSemanticConfidenceDetail(data, true) : formatSemanticConfidence(data, true);
  csv(itemFormatted);
}

function exportActivityXlsx(data) {
  let itemFormatted = formatActivityDetail(data);
  xlsx(itemFormatted);
}

function exportActivityCsv(data) {
  let itemFormatted = formatActivityDetail(data, true);
  csv(itemFormatted);
}

function exportIntentsCogitoMLCsv(data) {
  let itemFormatted = formatIntentsCogitoML(data, true);
  csv(itemFormatted);
}

function exportEntitiesCogitoMLCsv(data) {
  let itemFormatted = formatEntitiesCogitoML(data, true);
  csv(itemFormatted);
}
function exportSemanticStatistcsDetailXlsx(data) {
  let itemFormatted = formatSemanticStatisticsDetailData(data);
  xlsx(itemFormatted);
}

function exportSemanticStatistcsDetailCsv(data) {
  let itemFormatted = formatSemanticStatisticsDetailData(data, true);
  csv(itemFormatted);
}

function formatSemanticStatisticsDetailData(data, isCsv) {
  let itemFormatted = [];
  data.forEach((item) => {
    itemFormatted.push({
      DialogId: item.dialog_id,
      Engine: item.engine,
      Intent: item.intent,
      "Intent Label": item.intentLabel,
      Text: sanitizeInputText(item.input_text),
      Score: item.intent_score ? item.intent_score.toString().replace(".00", "") : "100",
      Hook: item.path,
      Timestamp: getDateToShow(item.navigated_at, isCsv),
    });
  });
  if (isCsv) {
    let headers = {
      DialogId: "DialogId",
      Engine: "Engine",
      Intent: "Intent",
      "Intent Label": "Intent Label",
      Text: "Text",
      Score: "Score",
      Hook: "Hook",
      Timestamp: "Timestamp",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatSemanticStatisticsItem(data, isCsv) {
  let itemFormatted = [];
  data.forEach((item) => {
    itemFormatted.push({
      Label: item.label,
      Intent: item.intent,
      Engine: item.engine ? item.engine : "N/A",
      Occurrences: item.tot,
      Percentage: item.totPercentage ? item.totPercentage + "%" : "N/A",
      "Confidence AVG": item.semanticScoreAVG ? item.semanticScoreAVG + "%" : "N/A",
      "Semantic Occurrences": item.semanticTot ? item.semanticTot : "N/A",
      "Semantic Percentage": item.semanticTotPercentage ? item.semanticTotPercentage + "%" : "N/A",
      "MultiIntent Detected": item.multiIntentOccurrency ? item.multiIntentOccurrency : "0",
    });
  });
  if (isCsv) {
    let headers = {
      Label: "Label",
      Intent: "Intent",
      Engine: "Engine",
      Occurrences: "Occurrences",
      Percentage: "Percentage",
      "Confidence AVG": "Confidence AVG",
      "Semantic Occurrences": "Semantic Occurrences",
      "Semantic Percentage": "Semantic Percentage",
      "MultiIntent Detected": "MultiIntent Detected",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function getFormattedHistory(item) {
  let history;
  try {
    history = JSON.parse(item.content);
  } catch (err) {
    history = [];
  }
  try {
    const privateNotes = JSON.parse(item.privateNotes);
    privateNotes.forEach((note) => {
      note.who = "Agent (private note)";
    });
    history = history.concat(privateNotes);
  } catch (err) {
    history = history.concat([]);
  }
  history.sort((a, b) => {
    const atime = new Date(a.timestamp);
    const btime = new Date(b.timestamp);
    if (atime > btime) {
      return 1;
    }
    if (atime < btime) {
      return -1;
    }
    return 0;
  });
  history = jsonToText(history);
  return history;
}

function formatItem(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Dialog ID": item.dialog_id,
      "Created At": getDateToShow(item.time_stamp, isCsv),
      "Closed At": getDateToShow(item.closed_at, isCsv),
      Channel: item.channel,
      Connector: item.connector,
      "Bot Name": item.bot_name,
      "Last Flow": item.last_flow,
      "Previous Flow": item.previous_flow,
      "User Id": item.user_id,
      "User Name": item.user_name ? item.user_name : "-",
      State: item.state,
      History: item.channel == "email" ? [] : (!isCsv && getFormattedHistory(item)?.length > xlsxLimit ? getFormattedHistory(item).substring(0, xlsxLimit) : getFormattedHistory(item)),
      Handover: item.handover || item.agent?.username ? "Y" : "N",
      Agent: item.agent ? item.agent.username || "" : "",
      Call: item.call_established ? "Y" : "N",
      Outbound: item.outbound ? "Y" : "N",
    });
  });
  if (isCsv) {
    let headers = {
      "Dialog ID": "Dialog ID",
      "Created At": "Created At",
      "Closed At": "Closed At",
      Channel: "Channel",
      Connector: "Connector",
      "Bot Name": "Bot Name",
      "Last Flow": "Last Flow",
      "Previous Flow": "Previous Flow",
      "User Id": "User Id",
      "User Name": "User Name",
      State: "State",
      History: "History",
      Handover: "Handover",
      Agent: "Agent",
      Call: "Call",
      Outbound: "Outbound"
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatFlowNavigation(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      Statistic: item.state,
      Occurrences: item.tot,
      Percentage: item.percentage,
    });
  });
  if (isCsv) {
    let headers = {
      Statistic: "Statistic",
      Occurrences: "Occurrences",
      Percentage: "Percentage",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatFlowNavigationDetail(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Bot Name": item.bot_name,
      "Overflow Target": item.overflow_target,
      Occurrences: item.tot,
      Percentage: item.percentage,
    });
  });
  if (isCsv) {
    let headers = {
      "Bot Name": "Bot Name",
      "Overflow Target": "Overflow Target",
      Occurrences: "Occurrences",
      Percentage: "Percentage",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatBotNavigationDetail(dataset, isCsv, precentage) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Bot Name": item.bot_name,
      Occurrences: item.tot,
      Percentage: precentage,
    });
  });
  if (isCsv) {
    let headers = {
      "Bot Name": "Bot Name",
      Occurrences: "Occurrences",
      Percentage: "Percentage",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatSurveyDetail(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Created at": getDateToShow(item.time_stamp, isCsv),
      "Dialog Id": item.dialog_id,
      Survey: item.name,
      Score: item.score,
      Feedback: typeof item.feedback === "string" ? item.feedback : JSON.stringify(item.feedback),
      History: item.channel == "email" ? [] : (!isCsv && getFormattedHistory(item)?.length > xlsxLimit ? getFormattedHistory(item).substring(0, xlsxLimit) : getFormattedHistory(item)),
    });
  });
  if (isCsv) {
    let headers = {
      "Created at": "Created at",
      "Dialog Id": "Dialog ID",
      Survey: "Survey",
      Score: "Score",
      Feedback: "Feedback",
      History: "History",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}
function formatAgentSurveyDetail(dataset, isCsv) {
  let itemFormatted = [];
  const keysToExclude = ["name", "dialog_id", "time_stamp", "queue", "content", "agent", "privateNotes"];
  dataset.forEach((item) => {
    const obj = {
      "Dialog ID": item.dialog_id,
      Queue: item.queue,
      Agent: item.agent,
      "Created at": getDateToShow(item.time_stamp, isCsv),
      ...item,
    };
    keysToExclude.forEach((key) => {
      delete obj[key];
    });
    itemFormatted.push(obj);
  });
  if (isCsv) {
    let headers = {
      "Created at": "Created at",
      Queue: "Queue",
      Agent: "Agent",
      "Dialog ID": "Dialog ID",
    };

    Object.keys(dataset[0]).forEach((key) => {
      if (!keysToExclude.includes(key)) {
        headers[key] = key;
      }
    });
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatContactResult(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Created at": getDateToShow(item.time_stamp, isCsv),
      "Dialog Id": item.dialog_id,
      Queue: item.queue,
      Agent: item.agent,
      "Macro Category": item.level1,
      Category: item.level2,
      "Sub Category": item.level3,
      Note: item.note,
    });
  });
  if (isCsv) {
    let headers = {
      "Created at": "Created at",
      "Dialog Id": "Dialog ID",
      Queue: "Queue",
      Agent: "Agent",
      "Macro Category": "Macro Category",
      Category: "Category",
      "Sub Category": "Sub Category",
      Note: "Note",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatSemanticConfidence(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      "Semantic Confidence": item.range,
      Occurrences: item.tot,
      Percentage: item.percentage + "%",
    });
  });
  if (isCsv) {
    let headers = {
      "Semantic Confidence": "Semantic Confidence",
      Occurrences: "Occurrences",
      Percentage: "Percentage",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function sanitizeInputText(text) {
  if (text) {
    text = text.replace(/\\'/,"'");
  }
  return text;
}

function formatSemanticConfidenceDetail(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    const score = item.intent_score || item.intent_score == 0 ? item.intent_score : "100";
    const scoreParsed = parseFloat(score);
    itemFormatted.push({
      Text: sanitizeInputText(item.input_text),
      Score: scoreParsed,
      Intent: item.intentLabel,
      Engine: item.engine,
      Path: item.path,
      Hook: item.hook,
      Timestamp: getDateToShow(item.navigated_at, isCsv),
    });
  });
  if (isCsv) {
    let headers = {
      Text: "Text",
      Score: "Score",
      Intent: "Intent",
      Engine: "Engine",
      Path: "Path",
      Hook: "Hook",
      Timestamp: "Timestamp",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatActivityDetail(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    let entity = item.entity;
    if ((!entity || entity === "unknown") && item.action.indexOf("_") !== -1) {
      entity = item.action.substring(item.action.indexOf("_") + 1, item.action.length);
    }
    itemFormatted.push({
      Username: item.username,
      Action: item.action,
      Entity: entity,
      Date: getDateToShow(item.timestamp, isCsv),
    });
  });
  if (isCsv) {
    let headers = {
      Username: "Username",
      Action: "Action",
      Entity: "Entity",
      Date: "Date",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatIntentsCogitoML(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      Name: item.key,
      Value: item.value,
      Utterances: item.utterances && item.utterances.length > 0 ? item.utterances.join(";") : "",
      Response: item.response ? item.response : "",
      BoostedTerms: item.boostedterms && item.boostedterms.length > 0 ? item.boostedterms.join(";") : "",
      Enabled: typeof item.enabled == "boolean" ? item.enabled : true,
      Linguistics: typeof item.easyConf == "boolean" ? item.easyConf : true,
    });
  });

  if (isCsv) {
    let headers = {
      Name: "Name",
      Value: "Value",
      Utterances: "Utterances",
      Response: "Response",
      BoostedTerms: "BoostedTerms",
      Enabled: "Enabled",
      Linguistics: "Linguistics",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function formatEntitiesCogitoML(dataset, isCsv) {
  let itemFormatted = [];
  dataset.forEach((item) => {
    itemFormatted.push({
      Name: item,
    });
  });

  if (isCsv) {
    let headers = {
      Name: "Name",
    };
    itemFormatted.unshift(headers);
  }
  return itemFormatted;
}

function jsonToText(historyJson) {
  let history = "";
  for (let z = 0; historyJson && z < historyJson.length; z++) {
    if (historyJson[z].who == "convy") historyJson[z].who = "ConvyAI";
    if (historyJson[z].who == "customer") historyJson[z].who = "Customer";
    if (historyJson[z].message) {
      history += historyJson[z].who + ": " + historyJson[z].message + "\n";
      if (historyJson[z].messageDescription) {
        history += historyJson[z].who + ": " + historyJson[z].messageDescription + "\n";
      }
    } else if (historyJson[z].type == "form" && historyJson[z].attributes) {
      if (historyJson[z].attributes.title?.value) {
        history += historyJson[z].who + ": " + historyJson[z].attributes.title.value + "\nCustomer:\n";
      }
      historyJson[z].attributes?.formField?.forEach((f) => {
        history += "  - " + f.label + ": " + (f.value || "Not Available") + "\n";
      });
    }
  }
  return history;
}

function xlsx(data) {
  let xlsx = XLSX.utils.json_to_sheet(data, { dateNF: "dd-mm-yyyy hh:mm:ss" });
  let workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, xlsx, "Report");
  XLSX.writeFile(workbook, "export.xlsx");
}

function csv(data) {
  let papaConfig = {
    quotes: true,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: false,
    newline: "\r\n",
    skipEmptyLines: true, //or 'greedy',
    columns: null,
  };
  let csv = Papa.unparse(data, papaConfig);
  let exportedFile = "export.csv";
  let blob = new Blob([csv], {
    encoding: "UTF-8",
    type: "text/csv;charset=utf-8;",
  });
  saveAs(blob, exportedFile, { autoBom: true });
}

function getDateToShow(date, isCsv) {
  if (!date) return "-";
  return isCsv ? moment(date).local().format("DD MMM YYYY, HH:mm:ss") : new Date(date);
}
