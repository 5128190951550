<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @onChangeView="onChangeView"
        @reloadData="reloadWidgetData"
      />
      <span @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <div v-if="selectedView == 'chart'">
          <v-chart ref="queueCapacity" :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'" autoresize class="chart" :option="options" />
          <div class="mt-n7 ml-2 secondary--text" style="position: absolute">The chart shows up to 10 queues with the hightest percentage of overload</div>
        </div>
        <div v-else-if="selectedView == 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="queue"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            must-sort
            sort-by="queue"
            dense
          >
            <template #top>
              <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
            </template>
          </v-data-table>
        </div>
      </span>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "QueueCapacity",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      info: "This widget shows, for each queue, the percentage of conversations that are still in queue compared to the total amount of active conversations (assigned plus in queue). The lower is the value, the better your team is performing",
      reloadWidgetDataInterval: null,
      options: {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return params.tooltip;
          },
          show: true,
        },
        series: [
          {
            center: ["30%", "50%"],
            radius: "65%",
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: true,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#E6EBF8",
              },
            },
            axisLine: {
              lineStyle: {
                width: 40,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              // {
              //   value: 20,
              //   name: "Task in queue 1",
              //   title: {
              //     offsetCenter: ["0%", "-30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "-20%"],
              //   },
              // },
            ],
            title: {
              fontSize: 14,
            },
            detail: {
              show: false,
              width: 50,
              height: 16,
              fontSize: 18,
              color: "inherit",
              borderColor: "inherit",
              borderRadius: 20,
              borderWidth: 1,
              formatter: "{value}%",
            },
          },
          { type: "pie", radius: "55%", center: [-1050, -1050], data: [] },
        ],
        legend: {
          icon: "circle",
          type: "scroll",
          selectedMode: false,
          orient: "vertical",
          top: "middle",
          right: "5",
          formatter: function (value) {
            if (value && value.length > 50) {
              const tokens = value.split(": ");
              value = tokens[0].substring(0, 45) + "...: " + tokens[1];
            }
            return value;
          },
          data: [],
        },
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queues",
            align: "start",
            sortable: true,
            value: "queue",
          },
          {
            text: "Overload (%)",
            align: "center",
            sortable: true,
            value: "overload",
            width: 150,
          },
          {
            text: "", //colonna utile a nascondere la scrollbar orizzontale
            align: "",
            sortable: false,
            value: "",
            width: 0,
          },
        ],
        data: [],
      },
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    async loadWidgetData() {
      try {
        const result = await this.$httpAuth.get("/dashboard/wallboard/queue-capacity");
        this.options.series[0].data = [];
        this.options.series[1].data = [];
        let startOffset = -30;
        const sortedSerie = [];
        this.viewTable.data = [];
        for (const queueERI in result.data) {
          const queue = this.queues.find((q) => q.eri === queueERI);
          if (queue) {
            const totalTask = result.data[queueERI].assigned + result.data[queueERI].queued;
            let value = 0;
            if (totalTask > 0) {
              value = Math.round((result.data[queueERI].queued * 100) / totalTask);
            }
            sortedSerie.push({
              value,
              name: queue.name,
              title: {
                offsetCenter: ["0%", startOffset + "%"],
              },
              detail: {
                offsetCenter: ["0%", startOffset + 13 + "%"],
              },
              tooltip: queue.name + ": " + value + "%",
            });
            startOffset += 30;

            this.viewTable.data.push({
              queue: queue.name,
              overload: value,
            });
          }
        }
        sortedSerie.sort((a, b) => {
          return b.value - a.value || a.name.localeCompare(b.name);
        });

        for (let serie of sortedSerie) {
          this.options.series[1].data.push({
            value: serie.value,
            name: serie.name + ": " + serie.value + "%",
          });
          this.options.legend.data.push(serie.name + ": " + serie.value + "%");
          delete serie.name;
        }
        //Inserisco solo le peggiori 10 queue
        for (let i = 0; i < sortedSerie.length && i < 10; i++) {
          this.options.series[0].data.push(sortedSerie[i]);
        }
        let length = this.options.series[0].data.length;
        if (length <= 3) {
          this.options.series[0].axisLine.lineStyle.width = 15 + 15 * length;
        }
        if (length > 3 && length <= 7) {
          this.options.series[0].axisLine.lineStyle.width = 90 - 1.5 * length;
        }
        if (length > 7 && length <= 10) {
          this.options.series[0].axisLine.lineStyle.width = 110 - 2 * length;
        }
        if (this.$refs["queueCapacity"]) {
          this.$refs["queueCapacity"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 420px;
}
</style>
