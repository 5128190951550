<template>
  <div>
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteCompanyConfirm" />
    <v-row v-if="newApp.eri" no-gutters>
      <v-col cols="6">
        <v-switch v-model="newApp.enabled" label="Enabled" />
      </v-col>
    </v-row>
    <v-text-field
      v-if="!newApp.eri"
      v-model.trim="newApp.name"
      label="Name"
      :rules="[requiredField, validateAppName, isAppUniqueName]"
      prepend-inner-icon="person"
      :autocomplete="$store.getters.disableAutocomplete"
    />
    <v-text-field
      v-else
      v-model.trim="newApp.id"
      label="UUID"
      readonly
      disabled
      prepend-inner-icon="person"
      :autocomplete="$store.getters.disableAutocomplete"
    />
    <v-text-field
      v-model.trim="newApp.url"
      label="URL"
      :rules="[requiredField, isURL, isHttpsUrl]"
      prepend-inner-icon="mdi-webhook"
      :autocomplete="$store.getters.disableAutocomplete"
    />
    <v-select
      v-model="newApp.allowRoles"
      :items="getAvailableRoles"
      label="Additional roles for this app. By default only admins will access it"
      prepend-inner-icon="mdi-clipboard-account"
      validate-on-blur
      multiple
    />
    <v-combobox
      v-show="false"
      v-model="newApp.domains"
      hide-selected
      label="Domain List"
      hint="Configure which domains will be included in JWT generation. Can be empty if you don't need it"
      multiple
      small-chips
      deletable-chips
      prepend-inner-icon="mdi-domain"
    />
    <v-text-field
      v-show="false"
      v-model.number="newApp.jwtDuration"
      label="JWT Expiration seconds. Default 8h"
      prepend-inner-icon="mdi-clock-outline"
      :autocomplete="$store.getters.disableAutocomplete"
    />
    <v-row v-if="newApp.eri" no-gutters>
      <v-col cols="12">
        <v-btn color="error" class="mt-2" @click="deleteApp()">
          Delete App
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "./ConfirmationDialog";

export default {
  name: "CompanyAppForm",
  components: {
    ConfirmDialog,
  },
  props: ["newApp", "companyId"],
  data() {
    return {
      showConfirm: false,
      deleteMessage: "App " + this.newApp.name + " will be deleted!",
    };
  },
  computed: {
    getAvailableRoles() {
      let roles = Object.values(this.$store.state.enums.roles);
      roles = roles.splice(3, roles.length - 4);
      const result = [];
      roles.forEach((role) => {
        result.push({
          text: this.$store.state.enums.rolesLabel[role],
          value: role,
        });
      });
      return result;
    },
  },
  mounted() {},
  methods: {
    ...fieldValidators,
    deleteApp() {
      this.showConfirm = true;
    },
    onDeleteCompanyConfirm() {
      this.$emit("deleteApp");
    },
    isAppUniqueName() {
      if (this.$store.state.loginResponse.apps) {
        const apps = this.$store.state.loginResponse.apps[this.companyId];
        if (apps) {
          const app = apps.find((app) => app.name === this.newApp.name);
          if (app) {
            return "The app name must be unique";
          }
        }
      }
      return true;
    },
  },
};
</script>
