<template>
  <v-form ref="createSurveyForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container fluid>
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteSurveyConfirm" @dialogDeny="showConfirm = false" />
      <v-row :class="getFormRowMargin">
        <v-col cols="6" :class="getFormColMargin">
          <v-text-field
            v-if="!isEdit"
            v-model.trim="editedSurvey.name"
            label="Survey name"
            :rules="[requiredField, charsGreaterThanAndLessThan(editedSurvey.name, 3, 20), validateSurveyName, isSurveyUnique(surveysList, editedSurvey.name)]"
            prepend-inner-icon="mdi-format-list-checks"
            :autocomplete="$store.getters.disableAutocomplete"
          />
          <v-text-field
            v-else
            v-model.trim="editedSurvey.name"
            label="Survey name"
            prepend-inner-icon="mdi-format-list-checks"
            :autocomplete="$store.getters.disableAutocomplete"
            autofocus
            disabled
          />
        </v-col>
        <v-col v-if="false" :class="getFormColMargin">
          <v-switch v-model="editedSurvey.required" color="primary" label="Mandatory" />
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="12" :class="getFormColMargin">
          <draggable v-model="editedSurvey.details" handle=".sortHandle">
            <v-card v-for="(obj, index) in editedSurvey.details" :key="index" class="px-5 mb-5">
              <v-row :class="getFormRowMargin">
                <v-col cols="1" class="text-center justify-center" :class="getFormColMargin">
                  <v-btn small text style="cursor: move" icon class="sortHandle ma-0 pa-0 mt-4">
                    <v-icon>mdi-cursor-move</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="10" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="obj.question"
                    label="Question"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    clearable
                    prepend-inner-icon="mdi-comment-question-outline"
                    :disabled="isBlocked"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-subheader class="px-3 subtitle text-darken-5">
                  Answer type:
                </v-subheader>
                <v-radio-group v-model="obj.type" :disabled="isBlocked" row class="ma-0 mt-2">
                  <template v-for="(type, index) in answerType">
                    <v-radio :key="index" :label="type.label" :value="type.value" color="primary" class="mx-5" />
                  </template>
                </v-radio-group>
                <v-spacer />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      depressed
                      x-small
                      fab
                      color="primary"
                      class="ml-0 mr-3"
                      :disabled="!form.isValid || editedSurvey.details.length - 1 !== index || isBlocked"
                      v-on="on"
                      @click="addQuestion"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add a question</span>
                </v-tooltip>
                <v-btn
                  v-if="editedSurvey.details.length > 1"
                  :disabled="isBlocked"
                  class="ml-n1 mr-3"
                  depressed
                  x-small
                  fab
                  color="error"
                  @click="removeQuestion(index)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="11" :class="getFormColMargin">
                  <v-combobox
                    v-if="obj.type !== 'text'"
                    v-model.trim="obj.answers"
                    class="pa-0"
                    label="Add answers"
                    prepend-inner-icon="mdi-reply"
                    deletable-chips
                    small-chips
                    multiple
                    :rules="[requiredField]"
                    :disabled="isBlocked"
                  />
                </v-col>
              </v-row>
            </v-card>
          </draggable>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-autocomplete
            v-model="editedSurvey.queues"
            :items="queueListItems"
            clearable
            multiple
            small-chips
            deletable-chips
            label="Queues list"
            prepend-inner-icon="mdi-expand-all"
            validate-on-blur
            persistent-hint
            hint="Select the queues in which to use the survey"
            @change="checkQueue"
          >
            <template #prepend-item>
              <v-list-item ripple @mousedown.prevent @click="selectAll">
                <v-list-item-action />
                <v-list-item-content class="text-uppercase">
                  <span v-if="editedSurvey.queues.length !== queueListItems.length"> Select all</span>
                  <span v-else>Deselect all</span>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="queueAlreadyInUse.length > 0" cols="12">
          <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
            <p>The following queues are already selected from another survey:</p>
            <v-list-item v-for="(queue, index) in queueAlreadyInUse" :key="index" class="mt-n4">
              <v-list-item-content>
                <v-list-item-subtitle v-html="queue" />
              </v-list-item-content>
            </v-list-item>
            <p>By saving, the previously associated survey will be replaced</p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <!-- Buttons -->
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="saveSurvey"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isEdit && form.submitLoading" @click.prevent="deleteSurvey">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
          <v-btn
            v-if="isEdit"
            :class="getButtonMargin"
            color="primary"
            :loading="form.submitLoading"
            :disabled="isEdit && form.submitLoading"
            @click.prevent="cloneSurvey"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-content-copy
              </v-icon>
              <span>Clone</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "@/event-bus";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import merge from "deepmerge";
import draggable from "vuedraggable";

export default {
  name: "ContactSurveyForm",
  components: {
    ConfirmDialog,
    draggable,
  },
  props: ["surveySetting", "surveysList", "queuesList"],
  data() {
    return {
      showConfirm: false,
      deleteMessage: "Survey " + this.surveySetting.name + " will be deleted!",
      isEdit: this.surveySetting.name !== "",
      answerType: [
        {
          label: "Free text",
          value: "text",
        },
        {
          label: "Multiple choice",
          value: "multiple",
        },
        {
          label: "Single choice",
          value: "single",
        },
      ],
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedSurvey: {
        eri: null,
        uuid: null,
        updated_at: null,
        name: this.surveySetting.name,
        details: [],
        required: true,
        queues: [],
      },
      isCCMUserWithRestrictPermission: false,
      queueAlreadyInUse: [],
    };
  },
  computed: {
    ...spacing,
    queueListItems() {
      return this.queuesList.map((queue) => {
        return { text: queue.name, value: queue.eri };
      });
    },
    isBlocked() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.surveySetting.hasOwnProperty("blocked")) {
        if (this.surveySetting.blocked) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.editedSurvey = merge(this.editedSurvey, this.surveySetting);
    if (this.$refs.createSurveyForm) this.$refs.createSurveyForm.resetValidation();
    this.isCCMUserWithRestrictPermission = this.isCCMuser();
    this.checkQueue(this.editedSurvey.queues);
  },
  methods: {
    ...fieldValidators,
    selectAll() {
      if (this.editedSurvey.queues.length !== this.queueListItems.length) {
        this.editedSurvey.queues = this.queuesList.map((queue) => {
          return queue.eri;
        });
      } else {
        this.editedSurvey.queues = [];
      }
      this.checkQueue(this.editedSurvey.queues);
    },
    checkQueue($event) {
      this.queueAlreadyInUse = [];
      if ($event && $event.length > 0) {
        for (let survey in this.surveysList) {
          if (survey !== this.editedSurvey.uuid) {
            $event.forEach((el) => {
              let elementToFind = this.surveysList[survey].queues.indexOf(el);
              if (elementToFind >= 0) {
                this.queueAlreadyInUse.push('<span class="white--text">' + this.getQueueName(el) + "</span> &mdash; " + this.surveysList[survey].name);
              }
            });
          }
        }
      }
    },
    getQueueName(queueEri) {
      let queueName = "";
      this.queuesList.forEach((queue) => {
        if (queue.eri === queueEri) {
          queueName = queue.name;
        }
      });
      return queueName;
    },
    addQuestion() {
      this.editedSurvey.details.push({
        question: "",
        type: "text",
        answers: [],
      });
    },
    removeQuestion(index) {
      this.editedSurvey.details.splice(index, 1);
    },
    async saveSurvey() {
      if (this.form.isValid) {
        try {
          this.form.isSubmitted = true;
          this.form.submitLoading = true;
          this.editedSurvey.queues = this.editedSurvey.queues.filter((queueERI) => this.queuesList.find((q) => q.eri === queueERI) != null);
          const result = await this.$http.post(`/human-agents/survey`, this.editedSurvey);
          this.editedSurvey = result.data;
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SURVEY, {
            editConfig: this.editedSurvey,
            message: "Survey " + this.editedSurvey.name + " updated",
          });
        } catch (e) {
          if (e.response.status === 409) {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SURVEY_FAIL, {
              editConfig: this.editedSurvey,
              message: `Fail to save ${this.editedSurvey.name} ${e.response.data.error}`,
            });
          } else {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SURVEY_FAIL, {
              editConfig: this.editedSurvey,
              message: `Error update survey ${this.editedSurvey.name} ${e.response.data.error}`,
            });
          }
        } finally {
          this.form.isSubmitted = false;
          this.form.submitLoading = false;
        }
      }
    },
    deleteSurvey() {
      if (this.surveySetting.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_SURVEY_CREATION);
      }
    },
    cloneSurvey() {
      EventBus.$emit(this.$store.getters.getEvents.CLONE_SURVEY, this.editedSurvey);
    },
    async onDeleteSurveyConfirm() {
      this.showConfirm = true;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete(`/human-agents/survey/${this.editedSurvey.uuid}`);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SURVEY, {
          uuid: this.editedSurvey.uuid,
          message: `Survey ${this.editedSurvey.name} Deleted!`,
        });
        this.showConfirm = false;
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SURVEY_FAIL, {
          message: `Fail to delete survey ${this.editedSurvey.name} : ${err.response.data.error.response}`,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    isCCMuser() {
      const user = this.$store.getters.getUser;
      //disabilito se è un ccm, la survey è global e il ccm non ha visibilità su tutti i profili
      if (this.isEdit && this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.editedSurvey.queues.length == 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          return true;
        }
      }
      //questo controlla che il ccm, se ha una lista di profili deve poter controllare tutte le code associate, altrimenti non può editare
      if (this.isEdit && this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.editedSurvey.queues.length > 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          const surveyQueues = this.editedSurvey.queues;
          const availableQueueERIs = this.queuesList.map((q) => q.eri);
          for (const queueERI of surveyQueues) {
            if (!availableQueueERIs.includes(queueERI)) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
};
</script>
