<template>
  <v-progress-linear
    :active="loading"
    indeterminate
    rounded
    absolute
    color="primary"
  />
</template>

<script>
export default {
  name: "Loading",
  props: ["loading"]
};
</script>
