<template>
  <v-expansion-panels class="my-6 mx-0">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span>
          <v-icon color="primary">mdi-tune</v-icon>
          <span class="ml-4">Additional parameters</span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0">
          <v-col cols="12" class="pb-4">
            <v-switch v-model="parameters.params.requireTyping" label="Enable typing" color="primary" />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="parameters.params.millisecondsPerCharacter"
              label="Milliseconds per char"
              type="number"
              :rules="[requiredField, positiveNumber]"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="parameters.params.minTypingTimeInMilliseconds"
              label="Minumum typing time (in millis)"
              type="number"
              :rules="[requiredField, positiveNumber]"
            />
          </v-col>
          <v-col cols="4" class="py-0">
            <v-text-field
              v-model="parameters.params.maxTypingTimeInMilliseconds"
              label="Maximum typing time (in millis)"
              type="number"
              :rules="[requiredField, positiveNumber]"
            />
          </v-col>
        </v-row>
        <v-row v-if="hasSaveAllAttachmentsSectionAvailable(parameters)" class="mx-0">
          <v-col cols="12" class="py-0 mb-5">
            <v-switch
              v-model="parameters.params.saveAllAttachments"
              label="Enable storage of all documents, including those sent as first messages"
              color="primary"
            />
          </v-col>
        </v-row>
        <v-row v-if="parameters.type == 'whatsapp'" class="mx-0">
          <v-col cols="12" class="py-0 mb-5">
            <v-switch v-model="parameters.details.enableInteractiveList" label="Enable usage of interactive list and buttons" color="primary" />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
export default {
  name: "AdditionalParameters",
  props: ["parameters"],
  data() {
    return {};
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    hasSaveAllAttachmentsSectionAvailable(connector) {
      return connector.type !== "webapi" && connector.type !== "googlehome" && connector.type !== "amazonalexa" && connector.type !== "email";
    },
  },
};
</script>
