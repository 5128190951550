<template>
  <div class="permissions-form">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDelete" />
    <v-form ref="createPermissionForm" v-model="form.isValid" :class="getCreationFormMargin">
      <v-container :class="getCreationContainerMargin">
        <v-row :class="getFormRowMargin">
          <v-col v-if="isEdit" cols="12" :class="getFormColMargin" class="d-inline-flex">
            <v-text-field v-model.trim="permission.uuid" label="Permission set UUID" disabled />
            <CopyToClipboard :data="permission.uuid" message="UUID copied to clipboard!" />
          </v-col>
        </v-row>

        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="12" :class="getFormColMargin">
            <v-text-field
              v-model.trim="permission.name"
              label="Permission set name"
              :rules="[
                requiredField,
                simpleValidatorNameWithSpaces,
                uniqueNamePermissionSet(allPermissionSets, permission.name, permission.uuid),
                charsGreaterThanAndLessThan(permission.name, 3, 255),
              ]"
              prepend-inner-icon="mdi-pencil"
              autofocus
              :autocomplete="$store.getters.disableAutocomplete"
              :disabled="permission.default === true"
            />
          </v-col>
          <v-col cols="12" sm="12" :class="getFormColMargin" class="pb-2">
            <v-text-field
              v-model.trim="permission.description"
              label="Description"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-keyboard"
              :autocomplete="$store.getters.disableAutocomplete"
              :disabled="permission.default === true"
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" class="">
            <v-icon class="mr-1">
              mdi-face-agent
            </v-icon>
            <span>Available Permissions</span>
            <v-btn
              v-if="!permission.default"
              :class="[getButtonMargin, allSelected() ? 'error--text' : 'primary--text']"
              depressed
              color="white"
              class="ml-5 mt-n1"
              :loading="form.deleteLoading"
              :disabled="form.submitLoading || permission.default"
              @click="selectAll(!allSelected())"
            >
              <span v-if="allSelected()">Deselect All</span>
              <span v-else>Select All</span>
            </v-btn>
          </v-col>
          <v-divider class="mx-4 primary" />
        </v-row>

        <v-row>
          <v-col
            v-for="(group, groupIndex) in chunkPermissionsInGroups()"
            :key="'group_' + groupIndex"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            xl="3"
            lg="3"
            class="subtitle-1 pt-3 px-0 mx-0"
            align="center"
          >
            <v-card-text class="ml-3 px-0 mx-0">
              <v-row v-for="(permissionObj, index) in group" :key="permissionObj.eri + '_' + index" align="center">
                <v-switch
                  v-model="allPermissions[getPermissionIndexByEri(permissionObj.eri)].selected"
                  dense
                  primary
                  :disabled="permission.default"
                  @change="onSwitchChange()"
                />
                <v-tooltip top :disabled="false">
                  <template #activator="{ on, attrs }">
                    <span style="font-size: 16px" v-bind="attrs" v-on="on">{{ permissionObj.name }}</span>
                  </template>
                  <span>{{ permissionObj.description }}</span>
                </v-tooltip>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>

        <!-- BUTTONS -->
        <v-row :class="getFormRowMargin" class="mt-5">
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn
              :class="getButtonMargin"
              color="success"
              :loading="form.submitLoading"
              :disabled="!form.isValid || form.deleteLoading || permission.default || isAtLeastOnePermissionSelected"
              @click.prevent="save"
            >
              <v-icon left>
                mdi-floppy
              </v-icon>
              <span v-if="!isEdit">Save</span>
              <span v-else>Update</span>
            </v-btn>

            <v-btn
              :class="getButtonMargin"
              color="error"
              :loading="form.deleteLoading"
              :disabled="form.submitLoading || permission.default"
              @click.prevent="deleteItem"
            >
              <template v-if="isEdit">
                <v-icon left>
                  mdi-delete
                </v-icon>
                <span>Delete</span>
              </template>
              <template v-else>
                <v-icon left>
                  mdi-close
                </v-icon>
                <span>Cancel</span>
              </template>
            </v-btn>

            <v-btn
              v-if="isEdit"
              :class="getButtonMargin"
              color="primary"
              :loading="form.submitLoading"
              :disabled="form.submitLoading"
              @click.prevent="initClone"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>
              <span>Clone</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import CopyToClipboard from "../../components/other/CopyToClipboard";

export default {
  name: "PermissionSetForm",
  components: { ConfirmDialog, CopyToClipboard },
  props: ["item", "allPermissionSets", "searchString"],
  data() {
    return {
      forceRender: 0,
      showConfirm: false,
      permission: JSON.parse(JSON.stringify(this.item)),
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      isEdit: this.item.uuid !== "",
      deleteMessage: "Permission set " + this.item.name + " will be deleted!",
      allPermissions: [],
    };
  },
  computed: {
    ...spacing,
    isAtLeastOnePermissionSelected() {
      this.forceRender;
      const find = this.allPermissions.find((o) => o.selected === true);
      if (find) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.getAllPermissionItems();
    this.$refs.createPermissionForm.resetValidation();
  },
  beforeDestroy() {},
  methods: {
    ...fieldValidators,
    onSwitchChange() {
      this.forceRender++;
    },
    selectAll(value) {
      this.allPermissions = this.allPermissions.map((o) => {
        o.selected = value;
        return o;
      });
    },
    allSelected() {
      return this.allPermissions.every((o) => {
        return o.selected;
      });
    },
    getPermissionIndexByEri(eri) {
      return this.allPermissions.findIndex((o) => o.eri === eri);
    },
    async getAllPermissionItems() {
      this.allPermissions = (await this.$http.get("/human-agents/system-permissions")).data;
      this.allPermissions.forEach((permission, index) => {
        const pos = this.permission.permissions.findIndex((o) => o.eri === permission.eri);
        this.allPermissions[index].selected = pos > -1;
      });
    },
    chunkPermissionsInGroups(groupSize) {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          groupSize = 4;
          break;
        case "md":
          groupSize = 8;
          break;
        case "sm":
          groupSize = Math.ceil(this.allPermissions.length / 2);
          break;
        case "xs":
          groupSize = this.allPermissions.length;
          break;
        default:
          groupSize = 4;
          break;
      }
      const result = [];
      for (let i = 0; i < this.allPermissions.length; i += groupSize) {
        result.push(this.allPermissions.slice(i, i + groupSize));
      }
      return result;
    },
    async save() {
      try {
        this.form.submitLoading = true;
        const selected = this.allPermissions.filter((o) => o.selected === true);
        let payload = {
          name: this.permission.name,
          description: this.permission.description,
          permissions: selected.map((e) => {
            return { eri: e.eri };
          }),
        };
        let result = null;
        let txt = null;
        const path = "/human-agents/permission-set/";

        if (this.isEdit) {
          payload.uuid = this.permission.uuid;
          payload.created_at = this.permission.created_at;
          payload.default = this.permission.default;
          payload.eri = this.permission.eri;
          result = await this.$http.put(path + this.permission.uuid, payload);
          txt = "updated";
        } else {
          result = await this.$http.post(path, payload);
          txt = "created";
        }
        EventBus.$emit(this.$store.getters.getEvents.EDIT_PERMISSION_SET_SUCCESS, {
          editedItem: this.permission,
          message: "Permission set " + this.permission.name + " " + txt + ". ",
          response: result.data,
        });
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.EDIT_PERMISSION_SET_FAIL, {
          editedItem: this.permission,
          message: "Error while saving Permission set " + this.permission.name,
        });
      } finally {
        this.form.submitLoading = false;
      }
    },
    initClone() {
      EventBus.$emit(this.$store.getters.getEvents.ADD_NEW_ITEM, {
        name: this.permission.name,
        description: this.permission.description,
        permissions: this.permission.permissions,
      });
    },
    deleteItem() {
      if (this.isEdit) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_PERMISSION_SET_CREATION);
      }
    },
    async onDelete() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/human-agents/permission-set/" + this.permission.uuid);

        EventBus.$emit(this.$store.getters.getEvents.DELETE_PERMISSION_SET_SUCCESS, {
          uuid: this.permission.uuid,
          message: "Permission set " + this.permission.name + " deleted!",
        });
      } catch (e) {
        let message = "Fail to delete " + this.permission.name;
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        EventBus.$emit(this.$store.getters.getEvents.DELETE_PERMISSION_SET_FAIL, {
          uuid: this.permission.uuid,
          message: message,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
  },
};
</script>
