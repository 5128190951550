<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <!-- Dialog per salvataggio criteria -->
    <v-dialog v-model="saveDialog" max-width="290" @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[simpleAlphaNumString]"
            :error-messages="errorNameMessage"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined block :disabled="!newCriteriaName" @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :showConfirm="dialogDeleteCriteria"
      :message="deleteCriteriaMessage"
      @dialogConfirm="confirmDeleteCriteria"
      @dialogDeny="denyDeleteCriteria"
    />
    <!-- Panel per la search -->
    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanelSearchForm" :class="getMainRowMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon class="mr-2">
                  mdi-magnify
                </v-icon>Dialog Details
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row align="center" justify="center" class="px-4 text-right mt-2">
              <v-col>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn-toggle v-model="conditionType" active-class="primary" class="white--text" mandatory v-on="on">
                      <v-btn small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                        <span>and</span>
                      </v-btn>
                      <v-btn small :color="$vuetify.theme.dark ? '#455a64' : ''" v-on="on">
                        <span>or</span>
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                  <span>Select the operator for your filters</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-form v-model="isFormValid" class="ma-0 px-4">
              <v-row v-for="(condition, index) in currentCriteria.filters" :key="index" align="center" justify="center" class="py-0 my-0">
                <v-col cols="6" md="3">
                  <v-autocomplete
                    v-model="currentCriteria.filters[index].field"
                    :rules="[requiredField]"
                    label="Filter On"
                    prepend-inner-icon="mdi-filter"
                    :items="fieldList"
                    @change="resetCriteria(index)"
                  />
                </v-col>
                <v-col cols="6" md="3">
                  <v-select
                    v-model="currentCriteria.filters[index].operator"
                    :rules="[requiredField]"
                    label="With Value"
                    :items="getOperatorList(index)"
                  />
                </v-col>
                <v-col cols="8" md="3">
                  <v-combobox
                    v-if="currentCriteria.filters[index].field === 'bot_name'"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :items="bots"
                  />
                  <v-combobox
                    v-else-if="currentCriteria.filters[index].field === 'channel'"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                    :items="channels"
                  />
                  <v-combobox
                    v-else-if="currentCriteria.filters[index].field === 'agent' && !currentCriteria.filters[index].operator.includes('exists')"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :items="agents"
                    item-value="eri"
                    item-text="username"
                  />
                  <v-combobox
                    v-else-if="currentCriteria.filters[index].field === 'queue' && !currentCriteria.filters[index].operator.includes('exists')"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :items="queues"
                  />
                  <v-combobox
                    v-else-if="currentCriteria.filters[index].field === 'flow'"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :items="flows"
                  />
                  <v-text-field
                    v-else-if="!currentCriteria.filters[index].operator.includes('exists')"
                    :key="'value' + rerenderCriteria"
                    v-model.trim="currentCriteria.filters[index].value"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="4" md="3">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-2"
                        :disabled="!isFormValid && index == currentCriteria.filters.length - 1"
                        depressed
                        x-small
                        fab
                        color="primary"
                        v-on="on"
                        @click="add"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add more filters</span>
                  </v-tooltip>
                  <v-btn
                    class="mx-2"
                    depressed
                    x-small
                    fab
                    color="error"
                    @click="remove(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- Riga per la gestione delle date -->
              <TimePickerRangeReport
                ref="timerPickerRow"
                @switchEnableDatePicker="enableDatePicker = $event"
                @setDateStart="updateDateTimeStart($event)"
                @setDateEnd="updateDateTimeEnd($event)"
              />
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-btn
                  depressed
                  :class="getButtonMargin"
                  :disabled="isLoadingData || searchButtonDisabled || isCCMDisableProfile"
                  color="success"
                  @click="saveDialog = true"
                >
                  <v-icon left>
                    mdi-floppy
                  </v-icon>
                  <span>Save</span>
                </v-btn>
                <v-btn
                  ref="searchBtn"
                  depressed
                  :loading="isLoadingData"
                  :class="getButtonMargin"
                  :disabled="searchButtonDisabled || isCCMDisableProfile"
                  color="primary"
                  @click="searchData"
                >
                  <v-icon left>
                    search
                  </v-icon>
                  <span>Search</span>
                </v-btn>
                <v-menu v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" top>
                  <template #activator="{ on: menu }">
                    <v-tooltip bottom color="primary">
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          color="primary"
                          dark
                          depressed
                          :class="getButtonMargin"
                          :loading="isLoadingData"
                          :disabled="searchButtonDisabled || isCCMDisableProfile"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon left>
                            mdi-download
                          </v-icon>Export
                        </v-btn>
                      </template>
                      <span> If the result contains more than 1000 entry, you can export directly the results </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item @click="directExport('xslx', false)">
                      <v-list-item-title>XLSX</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="directExport('csv', false)">
                      <v-list-item-title>CSV</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon class="mr-2">
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <SearchBar :searchString="searchString" @input="searchString = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length == 0 || isCCMDisableProfile" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Date Start
                        </th>
                        <th class="text-left">
                          Date End
                        </th>
                        <th />
                        <th class="text-left">
                          Filters
                        </th>
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="key">
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.name }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.start_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td style="cursor: pointer" @click="getValue(item.name)">
                          {{ item.end_date | formatVerboseDateTimeCriteria }}
                        </td>
                        <td />
                        <td>
                          <v-tooltip left color="primary">
                            <template #activator="{ on }">
                              <v-btn icon text v-on="on">
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <!-- <span v-text="getDescription(item.name)"></span> -->
                            <div v-for="(line, index) in item.filters" :key="index">
                              <span v-if="line.field != 'none'">
                                {{ line.field }} {{ line.operator }}
                                {{ line.value }}
                              </span>
                              <span v-else>No Filter</span>
                            </div>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-btn text icon @click="deleteCriteria(item.name)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DialogDetailsDatatable v-if="!isLoadingData" ref="DialogDetailsDatatable" :dataset="queryResult" @directExport="directExport" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-dialog v-model="dialogDetailModel" class="reallyBigModal">
      <v-card class="reallyBigModal" style="overflow: hidden !important">
        <v-card-text class="pa-0 ma-0 reallyBigModal">
          <v-text-field id="dialogIDtocopy" v-model="itemDetailSelected.dialog_id" class="tocopy-class" />
          <EuContactModal
            v-if="dialogDetailModel"
            type="convy"
            :contact="itemDetailSelected | formatContact"
            @eucontactmodalclose="closeDialogShowDetail"
            @eucopydialog="copyDialogId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import fieldValidators from "../../helpers/fieldValidators";
import DialogDetailsDatatable from "../../components/report/DialogDetailsDatatable";
import TimePickerRangeReport from "../../components/other/timePickerRangeReport";
import { EuContactModal } from "@eudata/eu-contact-modal";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import scroller from "../../helpers/scrollToTop";
import EventBus from "../../event-bus";
import moment from "moment";
import merge from "deepmerge";
import exportFile from "../../helpers/exportFile";
import sanitizeHistoryContent from "../../helpers/sanitizeHistoryContent";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

const Qs = require("qs");

export default {
  name: "DialogDetails",
  components: {
    ResultSnackbar,
    ConfirmDialog,
    SearchBar,
    DialogDetailsDatatable,
    TimePickerRangeReport,
    EuContactModal,
  },
  data() {
    return {
      saveDialog: false,
      newCriteriaName: "",
      dialogDeleteCriteria: false,
      deleteCriteriaMessage: "",
      criteriaToDelete: "",
      expansionPanelSearchForm: 0,
      conditionType: 0,
      isFormValid: false,
      isLoadingData: false,
      currentCriteria: {
        filters: [
          {
            field: "none",
            operator: "none",
            value: null,
          },
        ],
        dateStart: "",
        dateEnd: "",
        timeStart: "",
        timeEnd: "",
      },
      enableDatePicker: true,
      fieldList: [
        {
          text: "",
          value: "none",
        },
        {
          text: "Agent",
          value: "agent",
        },
        {
          text: "Bot Name",
          value: "bot_name",
        },

        {
          text: "Channel",
          value: "channel",
        },
        {
          text: "Dialog ID",
          value: "dialog_id",
        },
        {
          text: "Entity",
          value: "entity",
        },
        {
          text: "Entity Name",
          value: "entity_name",
        },
        {
          text: "Flow",
          value: "flow",
        },
        {
          text: "Page",
          value: "page",
        },
        {
          text: "Queue",
          value: "queue",
        },
        {
          text: "UserID",
          value: "user_id",
        },
        {
          text: "User Name",
          value: "user_name",
        },
        {
          text: "Version",
          value: "version",
        },
        {
          text: "State",
          value: "state",
        },
      ],
      operatorList: [
        {
          text: "",
          value: "none",
        },
        {
          text: "Contains",
          value: "like",
        },
        {
          text: "Equals To",
          value: "=",
        },
      ],
      errorNameMessage: "",
      searchCriterias: {},
      searchString: "",
      criteriaSelected: null,
      forceRerender: 0,
      semanticConfidenceRanges: ["0-0", "1-40", "41-60", "61-85", "86-100"],

      queryResult: [],
      itemDetailSelected: {},
      dialogDetailModel: false,

      bots: [],
      channels: [],
      flows: [],
      queues: [],
      agents: [],
      rerenderCriteria: 0,
    };
  },
  computed: {
    ...spacing,
    isCCMDisableProfile() {
      const user = this.$store.getters.getUser;
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.queues && this.queues.length === 0) {
        return true;
      }
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER && this.queues && this.queues.length === 0) {
        return true;
      }
      return false;
    },
    getDateStart() {
      return moment(this.currentCriteria.dateStart).format("DD/MM/YYYY") + " " + this.currentCriteria.timeStart + ":00";
    },
    getDateEnd() {
      return moment(this.currentCriteria.dateEnd).format("DD/MM/YYYY") + " " + this.currentCriteria.timeEnd + ":59";
    },
    getSearchCriterias() {
      this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
    searchButtonDisabled() {
      this.currentCriteria.filters;
      if (!this.isFormValid && this.currentCriteria.filters.length > 1) {
        return true;
      } else if (this.currentCriteria.filters.length == 1) {
        if (
          ((this.currentCriteria.filters[0].value == null || this.currentCriteria.filters[0].value == "") &&
            (this.currentCriteria.filters[0].operator == null || this.currentCriteria.filters[0].operator == "none") &&
            (this.currentCriteria.filters[0].field === null || this.currentCriteria.filters[0].field === "none")) ||
          this.isFormValid
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.DIALOG_DETAIL_SELECTED_DETAIL, this.showItemDetail);
    EventBus.$on(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS, this.deleteItems);
    EventBus.$on(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS_CONFIRM, this.confirmDeleteItems);
    EventBus.$on(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS_ERROR, this.errorDeleteItems);
    EventBus.$on(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_SUCCESS, this.forceSessionCloseSuccess);
    EventBus.$on(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_FAIL, this.forceSessionCloseFail);

    try {
      let response = await this.$http.get("/search-criteria/DIALOGS_DETAIL");
      for (let key in response.data) {
        if (response.data[key].isDateEnabled) {
          response.data[key].isDateEnable = response.data[key].isDateEnabled;
          delete response.data[key].isDateEnabled;
        }
      }
      this.searchCriterias = response.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
    this.loadLists();

    if (this.$route.query["autosearch"]) {
      this.searchData();
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.DIALOG_DETAIL_SELECTED_DETAIL, this.showItemDetail);
    EventBus.$off(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS, this.deleteItems);
    EventBus.$off(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS_CONFIRM, this.confirmDeleteItems);
    EventBus.$off(this.$store.getters.getEvents.DIALOG_DETAIL_DELETE_ITEMS_ERROR, this.errorDeleteItems);
    EventBus.$off(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_SUCCESS, this.forceSessionCloseSuccess);
    EventBus.$off(this.$store.getters.getEvents.FORCE_CLOSING_SESSION_FAIL, this.forceSessionCloseFail);
    if (this.$refs["DialogDetailsDatatable"]) {
      this.$refs["DialogDetailsDatatable"].stopRefreshTimer();
    }
  },
  methods: {
    ...fieldValidators,
    ...scroller,
    ...exportFile,
    ...checkRoleAndPermission,
    resetCriteria(index) {
      this.currentCriteria.filters[index].value = "";
      this.rerenderCriteria++;
    },
    async loadLists() {
      try {
        const botResponse = await this.$http.get("/bot");
        this.bots = Object.values(botResponse.data).map((bot) => bot.name);
        this.bots = this.sortArray(this.bots);
      } catch (e) {
        this.bots = [];
      }
      this.channels = this.$store.state.enums.socialConnectors.map((channel) => {
        return { text: channel.text, value: channel.name };
      });
      this.channels.push({
        text: "Email",
        value: "email",
      });
      this.channels = this.sortObj(this.channels, "text");
      try {
        const flowResponse = await this.$http.get("/flow");
        this.flows = Object.keys(flowResponse.data);
        this.flows = this.sortArray(this.flows);
      } catch (e) {
        this.flows = [];
      }
      try {
        const queueResponse = await this.$http.get("/human-agents/queue");
        this.queues = queueResponse.data.map((queue) => queue.name);
        this.queues = this.sortArray(this.queues);
      } catch (e) {
        this.queues = [];
      }
      try {
        const agentResponse = await this.$http.get("/human-agents/agent");
        this.agents = agentResponse.data.map((agent) => {
          return { username: agent.authentication.credentials.username, eri: agent.eri };
        });
        this.agents = this.sortObj(this.agents, "username");
      } catch (e) {
        this.agents = [];
      }
    },
    getOperatorList(index) {
      if (this.currentCriteria.filters[index].field === "queue" || this.currentCriteria.filters[index].field === "agent") {
        return this.operatorList.concat([
          {
            text: "Exists",
            value: "exists",
          },
          {
            text: "Not exists",
            value: "notexists",
          },
        ]);
      }
      return this.operatorList;
    },
    sortArray(data) {
      return data.sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 0;
      });
    },
    sortObj(obj, key) {
      return obj.sort((a, b) => {
        if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
        if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
        return 0;
      });
    },
    copyDialogId() {
      try {
        let input = document.getElementById("dialogIDtocopy");
        input.select();
        document.execCommand("copy");
        this.onCopy();
      } catch (err) {
        this.onError();
      }
    },
    onCopy() {
      this.$refs.resultSnackbar.showSuccess("DialogId Copied to Clipboard!");
    },
    onError() {
      this.$refs.resultSnackbar.showError("Error Copy to Clipboard!");
    },
    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    denyDeleteCriteria() {
      this.dialogDeleteCriteria = false;
      this.criteriaToDelete = "";
    },
    updateDateTimeStart(event) {
      this.currentCriteria.dateStart = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.currentCriteria.timeStart = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    updateDateTimeEnd(event) {
      this.currentCriteria.dateEnd = moment(event.split(" ")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
      this.currentCriteria.timeEnd = moment(event.split(" ")[1], "HH:mm:ss").format("HH:mm");
    },
    add() {
      if (this.isFormValid) {
        this.currentCriteria.filters.push({
          field: "none",
          operator: "none",
          value: null,
        });
      }
    },
    remove(index) {
      if (this.currentCriteria.filters.length > 1) this.currentCriteria.filters.splice(index, 1);
      else if (this.currentCriteria.filters.length === 1) {
        this.currentCriteria.filters[0].field = "none";
        this.currentCriteria.filters[0].operator = "none";
        this.currentCriteria.filters[0].value = null;
      }
    },
    async saveCurrentCriteria() {
      let searchCriteriaConfig = {
        end_date: this.enableDatePicker ? this.getDateEnd : moment().utc().format("DD/MM/YYYY"),
        start_date: this.enableDatePicker ? this.getDateStart : "01/01/1970",
        filterOperator: this.conditionType == 0 ? "AND" : "OR",
        filters: this.currentCriteria.filters,
        isDateEnable: this.enableDatePicker,
        name: this.newCriteriaName,
        scoreMax: "10",
        scoreMin: "0",
        type: "DIALOGS_DETAIL",
      };
      // controllo che il nome non esista già
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
        this.errorNameMessage = "Name Already Exists";
      } else {
        this.errorNameMessage = "";
        let newSearchCriteria = {
          searchCriteriaIdentifier: btoa(this.newCriteriaName),
          searchCriteriaConfig: searchCriteriaConfig,
        };
        try {
          let result = await this.$http.post("/search-criteria/", newSearchCriteria);
          this.saveDialog = false;
          if (result.data.result) {
            this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
            this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
            this.forceRerender++;
          } else {
            this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
          }
          this.newCriteriaName = "";
        } catch {
          this.saveDialog = false;
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
        }
      }
    },
    deleteCriteria(name) {
      this.criteriaToDelete = name;
      this.deleteCriteriaMessage = "Delete " + name + " criteria permanently";
      this.dialogDeleteCriteria = true;
    },
    async confirmDeleteCriteria() {
      let key = btoa(this.criteriaToDelete);

      try {
        await this.$http.delete("/search-criteria/" + key);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.criteriaToDelete = "";
        this.deleteCriteriaMessage = "";
        this.dialogDeleteCriteria = false;
        this.$refs.resultSnackbar.showSuccess("Search Criteria deleted!");
      } catch {
        this.$refs.resultSnackbar.showError("Error deleting search criteria");
      }
    },
    checkIfSearchCriteriaExists(val) {
      if (this.searchCriterias[btoa(val)]) {
        return true;
      } else {
        return false;
      }
    },
    getValue(name) {
      this.expansionPanelSearchForm = 0;
      if (!this.searchCriterias[btoa(name)].filters.length == 0) {
        this.currentCriteria.filters = [];
        for (let i in this.searchCriterias[btoa(name)].filters) {
          let filter = merge({}, this.searchCriterias[btoa(name)].filters[i]);
          this.currentCriteria.filters.push(filter);
        }
      }
      this.conditionType = this.searchCriterias[btoa(name)].filterOperator == "AND" ? 0 : 1;
      if (this.searchCriterias[btoa(name)].isDateEnable) {
        this.enableDatePicker = true;
        let start = this.searchCriterias[btoa(name)].start_date.split(" ");
        this.currentCriteria.dateStart = moment(start[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (start[1]) {
          this.currentCriteria.timeStart = moment(start[1], "HH:mm:ss", true).isValid() ? moment(start[1], "HH:mm:ss").format("HH:mm") : start[1];
        } else {
          this.currentCriteria.timeStart = "00:00";
        }

        let end = this.searchCriterias[btoa(name)].end_date.split(" ");
        this.currentCriteria.dateEnd = moment(end[0], "DD/MM/YYYY").format("YYYY-MM-DD");
        if (end[1]) {
          this.currentCriteria.timeEnd = moment(start[1], "HH:mm:ss", true).isValid() ? moment(end[1], "HH:mm:ss").format("HH:mm") : end[1];
        } else {
          this.currentCriteria.timeEnd = "23:59";
        }
      } else {
        this.enableDatePicker = false;
      }
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DATETIME_RANGE_REPORT, {
        datetimeStart: this.searchCriterias[btoa(name)].start_date,
        datetimeEnd: this.searchCriterias[btoa(name)].end_date,
        enable: this.searchCriterias[btoa(name)].isDateEnable,
      });
      this.triggerSearch();
    },
    triggerSearch() {
      setTimeout(() => {
        this.$refs["searchBtn"].$el.click();
      }, 1000);
    },
    makeRequest(directExportType, keepLimit) {
      let params = {
        type: "DIALOGS_DETAIL",
        start_date: this.enableDatePicker ? this.getDate(this.getDateStart) : moment("1970-01-01", "YYYY-MM-DD").toDate(),
        end_date: this.enableDatePicker ? this.getDate(this.getDateEnd) : moment("2100-01-01", "YYYY-MM-DD").toDate(),
        scoreMin: 0,
        scoreMax: 10,
        directExportType: directExportType,
        keepLimit,
        filterOperator: this.conditionType == 0 ? "and" : "or",
        semanticConfidenceRanges: JSON.stringify(this.semanticConfidenceRanges),
      };
      if (this.currentCriteria.filters[0].field !== "none") {
        params.filters = JSON.parse(JSON.stringify(this.currentCriteria.filters));
      }
      params.filters &&
        params.filters.forEach((filter) => {
          //su db relazionale i channel sono salvati lowercase ma lato UI la gente li scrive con le maiuscole
          if (filter.field === "channel") {
            filter.value = filter.value.toLowerCase();
          }
          if (filter.field === "agent" && filter.value.eri) {
            filter.value = filter.value.eri;
          }
        });
      return this.$httpAuth
        .get("/report/dialog-details", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          return result.data;
        })
        .catch((err) => {
          this.$refs.resultSnackbar.showError("Error Search Data: " + err.message);
        });
    },
    directExport(type, skipLoading, keepLimit) {
      if (skipLoading) {
        this.isLoadingData = true;
      }
      this.makeRequest(type, keepLimit)
        .then((result) => {
          if (type == "csv") {
            this.exportDialogDetailCsv(result);
          } else {
            this.exportDialogDetailXlsx(result);
          }
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },
    searchData() {
      this.isLoadingData = true;
      this.makeRequest()
        .then((result) => {
          this.queryResult = result;
          this.expansionPanelSearchForm = 2;
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },
    getDate(data) {
      return moment(data, "DD/MM/YYYY HH:mm:ss").utc().toDate();
    },
    showItemDetail(params) {
      if (this.dialogDetailModel) {
        this.itemDetailSelected = sanitizeHistoryContent.sanitizeAttachment(params, this.$store.getters.getSelectedCompany);
        this.dialogDetailModel = true;
      } else {
        this.scrollToTop().finally(() => {
          this.itemDetailSelected = sanitizeHistoryContent.sanitizeAttachment(params, this.$store.getters.getSelectedCompany);
          this.dialogDetailModel = true;
        }, 100);
      }
    },
    closeDialogShowDetail() {
      if (this.$refs["DialogDetailsDatatable"]) {
        this.$refs["DialogDetailsDatatable"].stopRefreshTimer();
      }
      this.dialogDetailModel = false;
    },
    deleteItems(selected) {
      this.queryResult = this.queryResult.filter((item) => !selected.some((other) => item.dialog_id == other.dialog_id));
    },
    confirmDeleteItems() {
      this.$refs.resultSnackbar.showSuccess("Items Deleted!");
    },
    errorDeleteItems(message) {
      this.$refs.resultSnackbar.showError(message);
    },
    forceSessionCloseSuccess(message) {
      this.$refs.resultSnackbar.showSuccess(message);
    },
    forceSessionCloseFail(message) {
      this.$refs.resultSnackbar.showError(message);
    },
  },
};
</script>
