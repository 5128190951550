<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <!-- Dialog per salvataggio criteria -->
    <v-dialog v-model="saveDialog" max-width="500" persistent @click:outside="newCriteriaName = ''">
      <v-card>
        <v-card-title class="headline">
          Save Configuration
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="newCriteriaName"
            placeholder="name search criteria"
            prepend-inner-icon="mdi-keyboard"
            :rules="[validateScheduledReportName]"
            :error-messages="errorNameMessage"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="6" class="px-2">
              <v-btn color="success" outlined block :disabled="!newCriteriaName" @click="saveCurrentCriteria">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-btn color="error" outlined block @click="cancelSaveCriteria">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :showConfirm="dialogDeleteCriteria"
      :message="deleteCriteriaMessage"
      @dialogConfirm="confirmDeleteCriteria"
      @dialogDeny="denyDeleteCriteria"
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-expansion-panels v-model="expansionPanelSearchForm" :class="getMainRowMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon class="mr-2">
                  mdi-magnify
                </v-icon>Contact Center Statistics
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="primary" />
            <v-row align="center" justify="center" class="px-4 text-right mt-2">
              <v-col cols="12">
                <ContactCenterCriteria :criteria="searchCriteria" :values="values" @save="saveDialog = true" @search="getReport(searchCriteria)" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10" class="title">
                <v-icon class="mr-2">
                  mdi-floppy
                </v-icon>Saved Criteria
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <SearchBar :searchString="searchString" @input="searchString = $event" />
            </v-row>
            <v-row v-if="getSearchCriterias.length === 0" class="mb-3 title" justify="center">
              No Criteria Saved
            </v-row>
            <v-row v-else :class="getMainRowMargin">
              <v-col cols="12">
                <v-simple-table :key="'st_' + forceRerender" fixed-header>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Type
                        </th>
                        <th class="text-left">
                          Aggregation
                        </th>
                        <th class="text-left">
                          Time Frame
                        </th>
                        <th class="text-left">
                          Filters
                        </th>
                        <th v-if="$vuetify.breakpoint.mdAndUp" class="text-left">
                          Time Zone
                        </th>
                        <th class="text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in getSearchCriterias" :key="'ccst_' + key" style="cursor: pointer" @click="getReport(item.conf)">
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <span class="dottedUnderline" v-on="on">{{ item.conf.type }}</span>
                            </template>
                            <span>
                              <v-chip
                                v-for="kpi in item.conf.kpis"
                                :key="'ccst_' + kpi.value"
                                outlined
                                color="white"
                                small
                                label
                                class="px-1 mr-2"
                              >
                                <span v-if="kpi">{{ kpi.text }}</span>
                              </v-chip>
                            </span>
                          </v-tooltip>
                        </td>
                        <td>
                          {{ item.conf.interval.text }}
                        </td>
                        <td>
                          <v-tooltip v-if="item.conf.range.value === 'customrange'" top>
                            <template #activator="{ on }">
                              <span class="dottedUnderline" v-on="on">{{ item.conf.range.text }}</span>
                            </template>
                            <span>
                              <div>From {{ item.conf.custom_range[0] }}</div>
                              <div>To {{ item.conf.custom_range[1] }}</div>
                            </span>
                          </v-tooltip>
                          <span v-else>{{ item.conf.range.text }}</span>
                        </td>
                        <td>
                          <v-tooltip v-if="getNumberOfFilters(item)" top>
                            <template #activator="{ on }">
                              <span class="dottedUnderline" v-on="on">{{ getNumberOfFilters(item) + " Filters" }}</span>
                            </template>
                            <span>
                              <v-chip
                                v-for="kpi in item.conf.filters"
                                :key="'ccst_' + kpi.value"
                                outlined
                                color="white"
                                small
                                label
                                class="px-1 mr-2"
                              >{{
                                kpi.text
                              }}</v-chip>
                              <v-chip
                                v-for="kpi in item.conf.agent_queue_filters"
                                :key="'ccst_' + kpi.value"
                                outlined
                                color="white"
                                small
                                label
                                class="px-1 mr-2"
                              >{{ kpi.text }}</v-chip>
                              <v-chip
                                v-for="kpi in item.conf.agent_profile_filters"
                                :key="'ccst_' + kpi.value"
                                outlined
                                color="white"
                                small
                                label
                                class="px-1 mr-2"
                              >{{ kpi.text }}</v-chip>
                            </span>
                          </v-tooltip>
                          <span v-else>No Filters</span>
                        </td>
                        <td v-if="$vuetify.breakpoint.mdAndUp">
                          {{ item.conf.timezone.zone }}
                        </td>
                        <td>
                          <v-btn text icon @click.stop="editCriteria(item.name)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn text icon @click.stop="deleteCriteria(item.name)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header :style="showResult ? 'padding-top:0.5px; padding-bottom:0.5px;' : ''">
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="2" class="title d-inline-flex align-center">
                <v-icon left>
                  mdi-table
                </v-icon>Result
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '9'">
                <v-card v-if="showResult" elevation="0" style="width: 98%" color="transparent" class="d-flex text-left mx-3">
                  <v-simple-table class="grey--text" fixed-header style="width: 100%; background: transparent; font-weight: 400">
                    <template #default>
                      <tbody>
                        <tr>
                          <td>
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <span class="dottedUnderline" v-on="on"> {{ searchCriteria.type }}</span>
                              </template>
                              <div style="max-width: 300px">
                                <v-chip
                                  v-for="(kpi, index) in searchCriteria.kpis"
                                  :key="index"
                                  color="white"
                                  outlined
                                  class="px-1 mr-2"
                                  label
                                  small
                                >
                                  {{
                                    kpi.text
                                  }}
                                </v-chip>
                              </div>
                            </v-tooltip>
                          </td>
                          <td>{{ searchCriteria.interval.text }}</td>
                          <td>{{ searchCriteria.timezone.zone }}</td>
                          <td>
                            <span v-if="searchCriteria.custom_range.length === 2 && searchCriteria.range.value === 'customrange'">
                              {{ searchCriteria.custom_range[0] }} to {{ searchCriteria.custom_range[1] }}
                            </span>
                            <span v-else>{{ searchCriteria.range.text }}</span>
                          </td>
                          <td>
                            <v-tooltip v-if="getNumberOfFiltersResult(searchCriteria)" top>
                              <template #activator="{ on }">
                                <span class="dottedUnderline" v-on="on">{{ getNumberOfFiltersResult(searchCriteria) + " Filters" }}</span>
                              </template>
                              <span v-if="searchCriteria.filters">
                                <v-chip
                                  v-for="(kpi, index) in searchCriteria.filters"
                                  :key="'result' + index + +kpi.value"
                                  color="white"
                                  outlined
                                  class="px-1 mr-2"
                                  label
                                  small
                                >{{ kpi.text }}</v-chip>
                              </span>
                              <span v-if="searchCriteria.agent_queue_filters">
                                <v-chip
                                  v-for="(kpi, index) in searchCriteria.agent_queue_filters"
                                  :key="'result' + index + +kpi.value"
                                  color="white"
                                  outlined
                                  class="px-1 mr-2"
                                  label
                                  small
                                >{{ kpi.text }}</v-chip></span>
                              <span v-if="searchCriteria.agent_profile_filters">
                                <v-chip
                                  v-for="(kpi, index) in searchCriteria.agent_profile_filters"
                                  :key="'result' + index + +kpi.value"
                                  color="white"
                                  outlined
                                  class="px-1 mr-2"
                                  label
                                  small
                                >{{ kpi.text }}</v-chip>
                              </span>
                            </v-tooltip>
                            <span v-else>No Filters</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="loadingData">
              Loading....
            </div>
            <v-card v-else :key="'query_result' + forceRerender">
              <v-card-title class="pa-0">
                <v-row align="center" class="my-2">
                  <v-col cols="7" class="text-right">
                    <v-menu ref="menu" top>
                      <template #activator="{ on: menu }">
                        <v-tooltip color="primary" bottom>
                          <template #activator="{ on: tooltip }">
                            <v-btn :disabled="dataset && dataset.length === 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                              <v-icon small left>
                                mdi-download
                              </v-icon> Download
                            </v-btn>
                          </template>
                          <span>Choose the format and download dataset</span>
                        </v-tooltip>
                      </template>
                      <v-list>
                        <v-list-item @click="exportXlsx">
                          <v-list-item-title>
                            <v-icon left>
                              mdi-file-excel-outline
                            </v-icon>XLSX
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportCsv">
                          <v-list-item-title>
                            <v-icon left>
                              mdi-file-delimited-outline
                            </v-icon>CSV
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <!-- reload -->
                    <v-tooltip top :disabled="(isReloadDisabled && !showResult) || intervalTimer == null">
                      <template #activator="{ on }">
                        <span v-on="on">
                          <v-btn
                            style="cursor: pointer"
                            :disabled="isReloadDisabled"
                            color="primary"
                            small
                            class="ml-5"
                            @click="getReport(searchCriteria)"
                          ><v-icon left>mdi-reload</v-icon>Reload
                            <v-progress-linear
                              v-if="valueProgress > 0 && valueProgress < 900"
                              :value="(valueProgress * 100) / 900"
                              color="#1D9096"
                              rounded
                              height="3"
                              class="myProgressBar"
                            />
                          </v-btn> </span>
                      </template>
                      <span> there are {{ getTime }} minutes left until the reload button is enabled </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="dataSetSearchString"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                      class="mr-4"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                must-sort
                :headers="getHeaders"
                :items="getDataset"
                class="contactResult elevation-1 px-1 allow-horizontal-scroll"
                :search="dataSetSearchString"
                sort-by="from"
                sort-desc
                :show-select="false"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 25, 50],
                }"
              >
                <template v-for="h in getHeaders" #[`header.${h.value}`]="{}">
                  <v-tooltip v-if="!!h.tooltip" :key="h.value" bottom>
                    <template #activator="{ on }">
                      <div class="text-center" v-on="on">
                        {{ h.text }}
                      </div>
                    </template>
                    <span>{{ h.tooltip }}</span>
                  </v-tooltip>
                  <span v-else :key="h.value">{{ h.text }}</span>
                </template>
                <template slot="no-data">
                  <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
                    Not enough data to render this table!
                  </v-alert>
                </template>

                <template v-for="kpi in getHeaders" #[`item.${kpi.value}`]="{ item }">
                  {{ formatKpiValue(item[kpi.value], kpi.format) }}
                </template>

                <template #item.from="{ item }">
                  {{ formatDateStrings(item.from, item.to) }}
                </template>
                <!-- <template v-slot:footer>
                  <div class="table-footer-text">* Times are expressed in seconds</div>
                </template> -->
              </v-data-table>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import SearchBar from "../../components/other/SearchBar";
import ContactCenterCriteria from "../../components/report/ContactCenterCriteria";
import fieldValidators from "../../helpers/fieldValidators";
import merge from "deepmerge";
import spacing from "../../helpers/spacing";
import moment from "moment-timezone";
import exportFile from "../../helpers/exportFile";

export default {
  name: "ContactCenterStatistics",
  components: {
    ResultSnackbar,
    ConfirmDialog,
    SearchBar,
    ContactCenterCriteria,
  },
  data() {
    return {
      forceRerender: 0,
      isReloadDisabled: true,
      countdown: 900,
      valueProgress: 0,
      intervalTimer: null,
      intervalCount: null,
      values: {
        kpis: {
          AGENT: [
            {
              text: "Resolved",
              tooltip: "Number of conversations resolved by the agent",
              value: "Agent::ConversationsResolved",
              filterBy: "username",
              type: "number",
              fields: { total: "resolved" },
            },
            {
              text: "Ready Time",
              tooltip: "The time the agent has been ready to handle new conversations",
              value: "Agent::ReadyTime",
              type: "number",
              format: "time",
              filterBy: "username",
              fields: { total: "time" },
            },
            {
              text: "Not Ready Time",
              tooltip: "The time the agent has been online but not ready to handle new conversations",
              value: "Agent::NotReadyTime",
              type: "number",
              format: "time",
              filterBy: "username",
              fields: { total: "time" },
            },
            {
              text: "Offline Time",
              tooltip: "The time the agent has been offline",
              value: "Agent::OfflineTime",
              type: "number",
              format: "time",
              filterBy: "username",
              fields: { total: "time" },
            },
            {
              text: "Accepted",
              tooltip: "Number of conversations accepted by the agent",
              value: "Agent::HandoversAccepted",
              type: "number",
              filterBy: "username",
              fields: { total: "accepted" },
            },
            {
              text: "Handled",
              tooltip: "Number of conversations handled by the agent",
              value: "Agent::HandoversHandled",
              type: "number",
              filterBy: "username",
              fields: { total: "handled" },
            },
            {
              text: "Handling",
              tooltip: "Number of conversations still handling by the agent",
              value: "Agent::HandoversHandling",
              type: "number",
              strategy: "getLatest",
              filterBy: "username",
              fields: { total: "handling" },
            },
            {
              text: "Parked Conversations",
              tooltip: "Number of conversations still parked by the end of the interval",
              value: "Agent::ParkedConversations",
              type: "number",
              strategy: "getLatest",
              filterBy: "username",
              fields: { total: "parked" },
            },
            {
              text: "Transferred (%)",
              tooltip: "Number of conversations transferred by the agent",
              value: "Agent::HandoversTransferred",
              type: "percentage",
              format: "percentage",
              filterBy: "username",
              fields: { total: "total", occurrence: "transferred" },
            },
            {
              text: "Inactive Customers",
              tooltip: "Number of conversations in which the customer is unresponsive",
              value: "Agent::InactiveCustomers",
              type: "number",
              strategy: "getLatest",
              filterBy: "username",
              fields: { total: "inactive" },
            },
            {
              text: "Handling Time (AVG)",
              tooltip: "The average time it takes the agent to handle a task",
              value: "Agent::AverageHandlingTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "accepted", occurrence: "duration" },
            },
            {
              text: "First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer during the conversation",
              value: "Agent::AverageFirstResponseTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Email First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer after accepting the email type handover",
              value: "Agent::EmailAverageFirstResponseTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Conversation First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write the first response to the customer",
              value: "Agent::AverageConversationFirstResponseTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer",
              value: "Agent::AverageResponseTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Expired SLA (%)",
              tooltip: "The percentage of messages not handled within the SLA timeout",
              value: "Agent::PercentageExpiredSLA",
              type: "percentage",
              format: "percentage",
              filterBy: "username",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Conversation Expired SLA (%)",
              tooltip: "The percentage of conversations whose messages have not been handled within the SLA timeout",
              value: "Agent::ConversationExpiredSLA",
              type: "percentage",
              format: "percentage",
              filterBy: "username",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Audio/Video Accepted",
              tooltip: "The amount of audio/video calls accepted",
              value: "Agent::HandoversCollaborations::CallAccepted",
              filterBy: "username",
              type: "number",
              fields: { total: "accepted" },
            },
            {
              text: "Audio/Video Conversations (%)",
              tooltip: "The percentage of audio/video handled compared to the total conversations handled",
              value: "Agent::HandoversCollaborations::CallPercentage",
              filterBy: "username",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "collaborations" },
            },
            {
              text: "Audio/Video Conversation Time (AVG)",
              tooltip: "The audio/video average call time",
              value: "Agent::HandoversCollaborations::AverageCallTime",
              type: "average",
              format: "time",
              filterBy: "username",
              fields: { total: "calls", occurrence: "offset" },
            },
            /**
              I kpi di CallTimePercentage sono stati soppressi con l'introduzione della gestione degli errori per le chiamate audio/video 
              (feature CVY-5526).
              A backend sono disponibili due diverse implementazione del kpi, ma il KPI non viene calcolato dal cronjob a seguito della soppressione.
            {
              text: "Audio/Video AVG Conversation Time (%)",
              tooltip: "The percentage of audio/video call handling time compared to the total conversations handling time",
              value: "Agent::HandoversCollaborations::CallTimePercentage",
              type: "percentage",
              format: "percentage",
              filterBy: "username",
              fields: { total: "total", occurrence: "collaborations" },
            },
            */
          ],
          QUEUE: [
            {
              text: "Resolved",
              tooltip: "Number of conversations resolved by the agent",
              value: "Queue::ConversationsResolved",
              type: "number",
              fields: { total: "resolved" },
            },
            {
              text: "Queued",
              tooltip: "The number of conversations set to queued during the current time frame",
              value: "Queue::HandoversQueued",
              type: "number",
              fields: { total: "queued" },
            },
            {
              text: "Queueing",
              tooltip: "The number of conversations still queued at the end of the time frame",
              value: "Queue::HandoversQueueing",
              type: "number",
              strategy: "getLatest",
              fields: { total: "queueing" },
            },
            {
              text: "Accepted",
              tooltip: "The number of conversations accepted by an agent",
              value: "Queue::HandoversAccepted",
              type: "number",
              fields: { total: "accepted" },
            },
            {
              text: "Handled",
              tooltip: "The number of conversation handled by an agent",
              value: "Queue::HandoversHandled",
              type: "number",
              fields: { total: "handled" },
            },
            {
              text: "Handling",
              tooltip: "Number of handovers still handling at the end of the timeframe",
              value: "Queue::HandoversHandling",
              type: "number",
              strategy: "getLatest",
              fields: { total: "handling" },
            },
            {
              text: "Parked Conversations",
              tooltip: "Number of conversations still parked by the end of the interval",
              value: "Queue::ParkedConversations",
              type: "number",
              strategy: "getLatest",
              fields: { total: "parked" },
            },
            {
              text: "Transferred (%)",
              tooltip: "The number of conversations transferred",
              value: "Queue::HandoversTransferred",
              type: "percentage",
              format: "percentage",
              fields: { total: "closed", occurrence: "transferred" },
            },
            {
              text: "Inactive Customers",
              tooltip: "Number of conversations in which the customer is unresponsive",
              value: "Queue::InactiveCustomers",
              type: "number",
              strategy: "getLatest",
              fields: { total: "inactive" },
            },
            {
              text: "Abandoned (%)",
              tooltip: "The number of conversations abandoned by the customer",
              value: "Queue::HandoversAbandoned",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "abandoned" },
            },
            {
              text: "Handling Time (AVG)",
              tooltip: "The average time it takes the agent to handle a task",
              value: "Queue::AverageHandlingTime",
              type: "average",
              format: "time",
              fields: { total: "accepted", occurrence: "duration" },
            },
            {
              text: "Queue Time (AVG)",
              tooltip: "The average time spent waiting for the first contact with an agent",
              value: "Queue::AverageFirstQueueTime",
              type: "average",
              format: "time",
              fields: { total: "routed", occurrence: "duration" },
            },
            {
              text: "First Message Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer after accepting the handover",
              value: "Queue::AverageFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Email First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer after accepting the email type handover",
              value: "Queue::EmailAverageFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Conversation First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write the first response to the customer",
              value: "Queue::AverageConversationFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer",
              value: "Queue::AverageResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Expired SLA (%)",
              tooltip: "The percentage of messages not handled within the SLA timeout",
              value: "Queue::PercentageExpiredSLA",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Conversation Expired SLA (%)",
              tooltip: "The percentage of conversations whose messages have not been handled within the SLA timeout",
              value: "Queue::ConversationExpiredSLA",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Conversation in Inactivity (%)",
              tooltip: "The percentage of conversations that triggered the handover inactivity management",
              value: "Queue::PercentageConversationInactivity",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Closed Conversation for Inactivity (%)",
              tooltip: "The percentage of conversations that have been abandoned or closed inside the handover inactivity management",
              value: "Queue::PercentageConversationClosedInactivity",
              type: "percentage",
              format: "percentage",
              fields: { total: "accepted", occurrence: "abandoned" },
            },
            {
              text: "Audio/Video Accepted",
              tooltip: "The amount of Audio/Video conversations accepted",
              value: "Queue::HandoversCollaborations::CallAccepted",
              type: "number",
              fields: { total: "accepted" },
            },
            {
              text: "Audio/Video Conversation Time (AVG)",
              tooltip: "The average time duration of a conversation",
              value: "Queue::HandoversCollaborations::AverageCallTime",
              type: "average",
              format: "time",
              fields: { total: "calls", occurrence: "offset" },
            },
            {
              text: "Speed of Answer (AVG)",
              tooltip: "Time it takes a customer to reach an agent once they've been routed to the right queue",
              value: "Queue::AverageSpeedOfAnswer",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "total" },
            },
            {
              text: "Audio/Video Conversations (%)",
              tooltip: "The percentage of audio/video handled compared to the total conversations handled",
              value: "Queue::HandoversCollaborations::CallPercentage",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "collaborations" },
            },
            /**
              I kpi di CallTimePercentage sono stati soppressi con l'introduzione della gestione degli errori per le chiamate audio/video 
              (feature CVY-5526).
              A backend sono disponibili due diverse implementazione del kpi, ma il KPI non viene calcolato dal cronjob a seguito della soppressione.
            {
              text: "Audio/Video AVG Conversation Time (%)",
              tooltip: "The percentage of audio/video call handling time compared to the total conversations handling time",
              value: "Queue::HandoversCollaborations::CallTimePercentage",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "collaborations" },
            },
            */
          ],
          PROFILE: [
            {
              text: "Resolved",
              tooltip: "Number of conversations resolved by the agent",
              value: "Profile::ConversationsResolved",
              type: "number",
              fields: { total: "resolved" },
            },
            {
              text: "Speed of Answer (AVG)",
              tooltip: "Time it takes a customer to reach an agent once they've been routed to the right queue",
              value: "Profile::AverageSpeedOfAnswer",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "total" },
            },
            {
              text: "Queueing",
              tooltip: "The number of conversations still queued at the end of the time frame",
              value: "Profile::HandoversQueueing",
              type: "number",
              strategy: "getLatest",
              fields: { total: "queueing" },
            },
            {
              text: "Queued",
              tooltip: "The number of conversations queued",
              value: "Profile::HandoversQueued",
              type: "number",
              fields: { total: "queued" },
            },
            {
              text: "Accepted",
              tooltip: "The number of conversations accepted by an agent",
              value: "Profile::HandoversAccepted",
              type: "number",
              fields: { total: "accepted" },
            },
            {
              text: "Handled",
              tooltip: "The number of conversation handled by an agent",
              value: "Profile::HandoversHandled",
              type: "number",
              fields: { total: "handled" },
            },
            {
              text: "Handling",
              tooltip: "Number of conversations still handling at the end of the timeframe",
              value: "Profile::HandoversHandling",
              type: "number",
              strategy: "getLatest",
              fields: { total: "handling" },
            },
            {
              text: "Parked Conversations",
              tooltip: "Number of conversations still parked by the end of the interval",
              value: "Profile::ParkedConversations",
              type: "number",
              strategy: "getLatest",
              fields: { total: "parked" },
            },
            {
              text: "Transferred (%)",
              tooltip: "The number of conversations transferred",
              value: "Profile::HandoversTransferred",
              type: "percentage",
              format: "percentage",
              fields: { total: "closed", occurrence: "transferred" },
            },
            {
              text: "Inactive Customers",
              tooltip: "Number of conversations in which the customer is unresponsive",
              value: "Profile::InactiveCustomers",
              type: "number",
              strategy: "getLatest",
              fields: { total: "inactive" },
            },
            {
              text: "Abandoned (%)",
              tooltip: "The number of conversations abandoned by the customer",
              value: "Profile::HandoversAbandoned",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "abandoned" },
            },
            {
              text: "Handling Time (AVG)",
              tooltip: "The average time it takes the agent to handle a task",
              value: "Profile::AverageHandlingTime",
              type: "average",
              format: "time",
              fields: { total: "accepted", occurrence: "duration" },
            },
            {
              text: "Queue Time (AVG)",
              tooltip: "The average time spent waiting for the first contact with an agent",
              value: "Profile::AverageFirstQueueTime",
              type: "average",
              format: "time",
              fields: { total: "routed", occurrence: "duration" },
            },
            {
              text: "First Message Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer after accepting the handover",
              value: "Profile::AverageFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Email First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer after accepting the email type handover",
              value: "Profile::EmailAverageFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Conversation First Response Time (AVG)",
              tooltip: "The average time it takes the agent to write the first response to the customer",
              value: "Profile::AverageConversationFirstResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Response Time (AVG)",
              tooltip: "The average time it takes the agent to write back to the customer",
              value: "Profile::AverageResponseTime",
              type: "average",
              format: "time",
              fields: { total: "dialogs", occurrence: "offset" },
            },
            {
              text: "Expired SLA (%)",
              tooltip: "The percentage of messages not handled within the SLA timeout",
              value: "Profile::PercentageExpiredSLA",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Conversation Expired SLA (%)",
              tooltip: "The percentage of conversations whose messages have not been handled within the SLA timeout",
              value: "Profile::ConversationExpiredSLA",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Conversation in Inactivity (%)",
              tooltip: "The percentage of conversations that triggered the handover inactivity management",
              value: "Profile::PercentageConversationInactivity",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "expired" },
            },
            {
              text: "Closed Conversation for Inactivity (%)",
              tooltip: "The percentage of conversations that have been abandoned or closed inside the handover inactivity management",
              value: "Profile::PercentageConversationClosedInactivity",
              type: "percentage",
              format: "percentage",
              fields: { total: "accepted", occurrence: "abandoned" },
            },
            {
              text: "Audio/video Accepted",
              tooltip: "The amount of audio/video calls accepted",
              value: "Profile::HandoversCollaborations::CallAccepted",
              type: "number",
              fields: { total: "accepted" },
            },
            {
              text: "Audio/Video Conversation Time (AVG)",
              tooltip: "The average time of an audio/video conversation",
              value: "Profile::HandoversCollaborations::AverageCallTime",
              type: "average",
              format: "time",
              fields: { total: "calls", occurrence: "offset" },
            },
            {
              text: "Audio/Video Conversations (%)",
              tooltip: "The percentage of audio/video handled compared to the total conversations handled",
              value: "Profile::HandoversCollaborations::CallPercentage",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "collaborations" },
            },
            /**
              I kpi di CallTimePercentage sono stati soppressi con l'introduzione della gestione degli errori per le chiamate audio/video 
              (feature CVY-5526).
              A backend sono disponibili due diverse implementazione del kpi, ma il KPI non viene calcolato dal cronjob a seguito della soppressione.
            {
              text: "Audio/Video AVG Conversation Time (%)",
              tooltip: "The percentage of audio/video call handling time compared to the total conversations handling time",
              value: "Profile::HandoversCollaborations::CallTimePercentage",
              type: "percentage",
              format: "percentage",
              fields: { total: "total", occurrence: "collaborations" },
            },
            */
            // Invisualizzabile nella tabella ? { text: "Agent Contribution", value: "Profile::AgentContribution", type: "number", fields: { total: "value" } },
            // Invisualizzabile nella tabella ? { text: "WorkLoad Distribution", value: "Profile::WorkLoadDistribution", type: "number", fields: { total: "value" } },
          ],
        },
        intervals: [
          { text: "15 minutes", value: "15m", scale: "m" },
          { text: "30 minutes", value: "30m", scale: "m" },
          { text: "Day", value: "1d", scale: "d" },
          { text: "Total", value: "total", scale: "d" },
        ],
        ranges: [
          { text: "Today", value: "today", scale: ["d", "m"] },
          { text: "Yesterday", value: "yesterday", scale: ["d", "m"] },
          { text: "Last 7 days", value: "last7days", scale: ["d"] },
          { text: "This week", value: "thisweek", scale: ["d"] },
          { text: "Last week", value: "lastweek", scale: ["d"] },
          { text: "This Month", value: "thismonth", scale: ["d"] },
          { text: "Last Month", value: "lastmonth", scale: ["d"] },
          { text: "Custom range", value: "customrange", scale: ["d"] },
        ],
        queues: [],
        profiles: [],
        agents: [],
      },
      saveDialog: false,
      isLoadingData: false,
      searchCriteriaFiltersBak: {},
      searchCriteria: {
        type: "AGENT",
        text: "Agents",
        kpis: [],
        interval: { text: "Day", value: "1d", scale: "d" },
        range: { text: "Last 7 days", value: "last7days", scale: ["d"] },
        filters: [],
        agent_queue_filters: [],
        agent_profile_filters: [],
        custom_range: [],
        timezone: {
          zone: moment.tz.guess(),
          timezoneOffset: "",
        },
      },
      showResult: false,
      searchCriterias: {},
      newCriteriaName: "",
      dialogDeleteCriteria: false,
      deleteCriteriaMessage: "",
      criteriaToDelete: "",
      errorNameMessage: "",
      expansionPanelSearchForm: 0,
      searchString: "",
      dataSetSearchString: "",
      isEdit: false,
      loadingData: false,
      dataset: [],
      dataRegistry: {},
      requestedTimeFrames: [],
      headers: [],
    };
  },
  computed: {
    ...spacing,
    getTime() {
      return Math.round(this.countdown / 60);
    },
    getHeaders() {
      return this.headers;
    },
    getDataset() {
      return this.dataset;
    },
    getSearchCriterias() {
      //this.forceRerender;
      return (
        Object.values(this.searchCriterias)
          //Filtro per la ricerca
          .filter((criteria) => criteria.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
  async mounted() {
    for (const group in this.values.kpis) {
      let names = [];
      let items = {};

      this.values.kpis[group].forEach((o) => {
        names.push(o.text);
        items[o.text.toLowerCase()] = Object.create(o);
      });

      this.values.kpis[group] = [];
      names.sort().forEach((kpiName) => {
        this.values.kpis[group].push(items[kpiName.toLowerCase()]);
      });
    }
    this.searchCriteria.timezone.timezoneOffset = this.getTzOffset(new Date()) + "";
    await this.loadRequirements();
    try {
      let response = await this.$http.get("/search-criteria/CONTACT_CENTER_STATISTICS");
      this.searchCriterias = response.data;
      this.initialSearchCriterias = JSON.parse(JSON.stringify(response.data));
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  beforeDestroy() {
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer);
      this.intervalTimer = null;
    }
    if (this.intervalCount) {
      clearTimeout(this.intervalCount);
      this.intervalCount = null;
    }
  },
  methods: {
    ...fieldValidators,
    ...exportFile,
    exportXlsx() {
      this.exportContactCenterStatisticsXlsx(this.getFormattedDataset(), this.searchCriteria);
    },
    exportCsv() {
      this.exportContactCenterStatisticsCsv(this.getFormattedDataset(), this.searchCriteria);
    },
    getFormattedDataset() {
      return JSON.parse(JSON.stringify(this.dataset)).map((entry) => {
        Object.keys(entry).forEach((v) => {
          const kpiConfig = this.getKpiConfig(v);
          if (kpiConfig) {
            entry[v] = this.formatKpiValue(entry[v], kpiConfig.format);
          }
        });
        return entry;
      });
    },
    getNumberOfFilters(item) {
      if (!item.conf.agent_queue_filters) {
        return item.conf.filters.length;
      }
      return item.conf.filters.length + item.conf.agent_queue_filters.length + item.conf.agent_profile_filters.length;
    },
    getNumberOfFiltersResult(item) {
      if (!item.agent_queue_filters) {
        return item.filters.length;
      }
      return item.filters.length + item.agent_queue_filters.length + item.agent_profile_filters.length;
    },
    getMonday(d) {
      d = new Date(d);
      const day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getRangeInISO(criteria) {
      let gte = "";
      let lte = "";
      const now = new Date();
      const thisMorning = new Date();
      thisMorning.setHours(0);
      thisMorning.setMinutes(0);
      thisMorning.setSeconds(0);
      thisMorning.setMilliseconds(0);
      const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
      const thisWeek = this.getMonday(now);
      thisWeek.setHours(0);
      thisWeek.setMinutes(0);
      thisWeek.setSeconds(0);
      thisWeek.setMilliseconds(0);

      switch (criteria.range.value) {
        case "today":
          gte = thisMorning.toISOString();
          now.setHours(23);
          now.setMinutes(59);
          now.setSeconds(59);
          now.setMilliseconds(999);
          lte = now.toISOString();
          break;
        case "yesterday":
          lte = thisMorning.toISOString();
          thisMorning.setDate(thisMorning.getDate() - 1);
          gte = thisMorning.toISOString();
          break;
        case "last7days":
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          lte = now.toISOString();
          now.setDate(now.getDate() - 7);
          gte = now.toISOString();
          break;
        case "thisweek":
          lte = now.toISOString();
          gte = thisWeek.toISOString();
          break;
        case "lastweek":
          lte = thisWeek.toISOString();
          thisWeek.setDate(thisWeek.getDate() - 7);
          gte = thisWeek.toISOString();
          break;
        case "thismonth":
          now.setHours(23);
          now.setMinutes(59);
          now.setSeconds(59);
          now.setMilliseconds(999);
          lte = now.toISOString();
          gte = thisMonth.toISOString();
          break;
        case "lastmonth":
          thisMonth.setMonth(thisMonth.getMonth() - 1);
          lte = new Date(new Date(now.getFullYear(), now.getUTCMonth(), 1, 0, 0, 0).getTime() - 1).toISOString();
          gte = thisMonth.toISOString();
          break;
        case "customrange":
          gte = ((dateStr) => {
            const d = new Date(dateStr);
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            return d.toISOString();
          })(criteria.custom_range[0]);

          lte = ((dateStr) => {
            const d = new Date(dateStr);
            d.setHours(23);
            d.setMinutes(59);
            d.setSeconds(59);
            d.setMilliseconds(999);
            return d.toISOString();
          })(criteria.custom_range[1]);
          break;
        default:
          return null;
      }

      const toDate = new Date(lte);
      if (toDate.getHours() === 0 && toDate.getMinutes() === 0 && toDate.getSeconds() === 0) {
        lte = new Date(toDate.valueOf() - 1).toISOString();
      }
      return {
        gte,
        lte,
        format: "strict_date_time",
      };
    },
    formatDate(date) {
      if (this.searchCriteria.interval.scale === "m") {
        return moment(date).tz(this.searchCriteria.timezone.zone).format("DD MMM YYYY, HH:mm");
      }
      return moment(date).tz(this.searchCriteria.timezone.zone).format("DD MMM YYYY");
    },
    formatDateStrings(from, to) {
      let format = "DD MMM YYYY";
      if (this.searchCriteria.interval.scale === "m") {
        format += ", HH:mm";
      }
      const fd = moment(from).tz(this.searchCriteria.timezone.zone).format(format);
      if (this.searchCriteria.interval.value === "1d") {
        return `${fd}`;
      }
      if (this.searchCriteria.interval.scale === "m") {
        format = "HH:mm";
      }
      const td = moment(to).tz(this.searchCriteria.timezone.zone).format(format);
      return `${fd} - ${td}`;
    },
    twoDecimalToNumber(number) {
      if (number % 1 !== 0) {
        return parseFloat(number).toFixed(2);
      }
      return number;
    },
    getTzOffset(date) {
      return moment(date).tz(moment.tz.guess(true))._offset;
    },
    generateTimeFrames(searchCriteria, fromDate, toDate) {
      const offset = {
        from: this.getTzOffset(fromDate),
        to: this.getTzOffset(toDate),
      };
      let timeFrames = {};
      let latestFromDate = fromDate;
      const msecToNow = new Date().valueOf() - 15 * 60 * 1000; // now less than 15 minutes
      let delta = 1;
      switch (searchCriteria.interval.value) {
        case "15m":
          delta = 15;
          break;
        case "30m":
          delta = 30;
          break;
        case "1d":
        case "total":
          latestFromDate = new Date(fromDate.valueOf() + offset.from * 60 * 1000);
          toDate = new Date(toDate.valueOf() + offset.to * 60 * 1000);
          if (searchCriteria.interval.value === "1d") {
            delta = 24 * 60; // minutes
          } else {
            delta = (toDate.valueOf() - fromDate.valueOf()) / 60 / 1000;
          }
          break;
        default:
          // minutes
          delta = 24 * 60 * 7;
          break;
      }
      delta *= 60 * 1000; // msec
      let frameNumber = (toDate.valueOf() - fromDate.valueOf()) / delta;
      frameNumber = frameNumber % 1 !== 0 ? parseInt(frameNumber + 1) : parseInt(frameNumber);

      for (let x = 0; x < frameNumber; x++) {
        const msecs = x > 0 ? latestFromDate.valueOf() + delta : latestFromDate.valueOf();
        latestFromDate = new Date(msecs);

        if (toDate.valueOf() <= latestFromDate.valueOf()) {
          continue;
        }
        if (latestFromDate.valueOf() >= msecToNow) {
          continue;
        }
        timeFrames[latestFromDate.toISOString()] = x;
      }
      return timeFrames;
    },
    initKpiAggregationItem(kpiList, kpiTypesCollection) {
      let ob = {};
      const entities = this.getKpiEntityGroupList();
      entities.forEach((e) => {
        ob[e.text] = ob[e.text] || {};
        kpiList.forEach((kpi) => {
          let type = "number";
          if (typeof kpiTypesCollection !== "undefined") {
            type = kpiTypesCollection[kpi.value].type;
          }
          ob[e.text][kpi.value] = {
            occurrence: 0,
            total: 0,
            type,
            format: kpiTypesCollection[kpi.value].format,
          };
        });
      });

      return ob;
    },
    getKpiEntityGroupList() {
      let entities = (this.searchCriteriaFiltersBak.length > 0 && JSON.parse(JSON.stringify(this.searchCriteriaFiltersBak))) || [];

      if (entities.length === 0) {
        switch (this.searchCriteria.type) {
          case "AGENT":
            entities = this.values.agents.map((o) => {
              return {
                type: "agent",
                text: o.authentication.credentials.username,
              };
            });
            break;
          case "QUEUE":
            entities = this.values.queues.map((o) => {
              return {
                type: "queue",
                text: o.name,
              };
            });
            break;
          case "PROFILE":
            entities = this.values.profiles.map((o) => {
              return {
                type: "profile",
                text: o.name,
              };
            });
            break;
        }
      } else {
        let loadedValues = [];

        // filter out any deleted filter entries
        switch (this.searchCriteria.type) {
          case "AGENT":
            loadedValues = this.values.agents;
            break;
          case "QUEUE":
            loadedValues = this.values.queues;
            break;
          case "PROFILE":
            loadedValues = this.values.profiles;
            break;
        }
        entities = entities.filter((entity) => {
          return loadedValues.filter((loadedObject) => loadedObject.eri == entity.value).length > 0;
        });
      }

      return entities.sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    getAggregationTimeKey(isoDateString, minDate, maxDate) {
      let timeKey = isoDateString;
      const d = new Date(isoDateString);
      const olderTzTS = this.createFakeTimestampByTzOffset(minDate);
      const latestTzTS = this.createFakeTimestampByTzOffset(maxDate);

      const utcTs = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
      let fake = new Date(utcTs + this.getTzOffset(d) * 60 * 1000);

      switch (this.searchCriteria.interval.value) {
        case "30m":
          if (d.getMinutes() === 15 || d.getMinutes() === 45) {
            d.setMinutes(d.getMinutes() - 15);
            timeKey = d.toISOString();
          }
          break;
        case "1d":
          timeKey = fake.toISOString();
          // Fix per eliminare l'errata aggregazione di dati in insiemi giornalieri se si utilizza un timezone superiore o inferiore a UTC+0.
          // La causa è il timezone UTC+0, utilizzato per il raggruppamento giornaliero, piuttosto che il timezone richiesto dall'utente in fase di ricerca.
          if (fake.valueOf() < olderTzTS) {
            fake = new Date(olderTzTS);
          } else if (fake.valueOf() >= latestTzTS) {
            fake = new Date(latestTzTS);
          }

          fake.setUTCHours(0);
          fake.setUTCMinutes(0);
          fake.setUTCSeconds(0);
          fake.setUTCMilliseconds(0);
          timeKey = fake.toISOString();
          break;
        case "total":
          timeKey = new Date(olderTzTS).toISOString();
          break;
      }

      return timeKey;
    },
    patchEndDate(someDate) {
      if (someDate.getHours() === 0 && someDate.getMinutes() === 0) {
        someDate = new Date(someDate.valueOf() - 60000); //subtract 1 minute
        someDate.setMinutes(23);
        someDate.setSeconds(59);
        someDate.setMilliseconds(999);
      }
      return someDate;
    },
    isSameDateAs(someDate, equalityDate) {
      return (
        someDate.getDate() === equalityDate.getDate() &&
        someDate.getMonth() === equalityDate.getMonth() &&
        someDate.getFullYear() === equalityDate.getFullYear()
      );
    },
    isToday(someDate) {
      return this.isSameDateAs(someDate, new Date());
    },
    isDateValuableTarget(targetDate, limitDate, queryInterval) {
      // Use only for kpis having "strategy = 'getLatest'"
      let valuable = true;
      if (this.searchCriteria.interval.value === "total" || this.searchCriteria.interval.value === "1d") {
        limitDate = this.patchEndDate(limitDate);
        valuable = this.isSameDateAs(targetDate, limitDate);
      }
      if (this.searchCriteria.interval.scale !== "m") {
        return valuable && this.isLatestTimeFrameOfDay(targetDate, queryInterval);
      }
      return true;
    },
    getQueryInterval(kpiConfig) {
      let interval = this.searchCriteria.interval.scale === "m" ? "15m" : "1h";
      switch (kpiConfig.strategy) {
        case "getLatest":
          interval = "15m";
          break;
        default:
          break;
      }
      return interval;
    },
    isLatestTimeFrameOfDay(targetDate, interval) {
      let latestTimeFrame = new Date();
      if (this.isToday(targetDate)) {
        if (interval === "1h") {
          latestTimeFrame.setMinutes(0);
        } else {
          const minutes = new Date().getMinutes();
          if (minutes <= 17) {
            latestTimeFrame.setMinutes(0);
          } else if (minutes > 17 && minutes <= 32) {
            latestTimeFrame.setMinutes(15);
          } else if (minutes > 32 && minutes <= 47) {
            latestTimeFrame.setMinutes(30);
          } else if (minutes > 47) {
            latestTimeFrame.setMinutes(45);
          }
        }
        latestTimeFrame.setSeconds(0);
        latestTimeFrame.setMilliseconds(0);
        if (targetDate.valueOf() >= latestTimeFrame.valueOf()) {
          // this happens due to the 2 minutes delay we wait before elaborating the latest timeframe.
          // if data are processed and still ready before these two minutes delay ends, we are going to ignore them until the delay expires.
          return false;
        }
        let diffInMinutes = interval === "1h" ? 60 : 15;
        latestTimeFrame = new Date(latestTimeFrame.valueOf() - diffInMinutes * 60 * 1000);
      } else {
        latestTimeFrame = new Date(targetDate.toISOString());
        let min = interval === "15m" ? 45 : 0;
        latestTimeFrame.setHours(23);
        latestTimeFrame.setMinutes(min);
        latestTimeFrame.setSeconds(0);
      }
      return targetDate.valueOf() >= latestTimeFrame.valueOf();
    },
    createFakeTimestampByTzOffset(date) {
      return date.valueOf() + this.getTzOffset(date) * 60 * 1000;
    },
    getKeyNameFromEntity(entityObj) {
      let key = null;
      let obj = null;
      switch (this.searchCriteria.type) {
        case "AGENT":
          obj = this.values.agents.find(
            (a) => a.authentication.credentials.username === entityObj.agent || a.authentication.credentials.username === entityObj.username,
          );
          key = (!!obj && obj.authentication.credentials.username) || entityObj.username || entityObj.agent;
          break;
        case "QUEUE":
          obj = this.values.queues.find((q) => q.eri === entityObj.queue);
          key = (!!obj && obj.name) || entityObj.queue.name;
          break;
        case "PROFILE":
          obj = this.values.profiles.find((p) => p.eri === entityObj.profile);
          key = (!!obj && obj.name) || entityObj.profile.name;
          break;
      }
      return key;
    },
    getKpisFieldsMap() {
      const type = this.searchCriteria.type;
      const mapping = {};
      this.values.kpis[type].forEach((o) => {
        mapping[o.value] = {
          field: o.fields.total,
          type: o.type,
          format: o.format,
        };
      });
      return mapping;
    },
    async loadRequirements() {
      try {
        const response = await this.$http.get("/human-agents/queue");
        this.values.queues = response.data;
      } catch (e) {
        this.values.queues = [];
      }
      try {
        const response = await this.$http.get("/human-agents/profile");
        this.values.profiles = response.data;
      } catch (e) {
        this.values.profiles = [];
      }
      try {
        const result = await this.$http.get("/human-agents/agent");
        this.values.agents = result.data;
      } catch (e) {
        this.values.agents = [];
      }
    },
    async saveCurrentCriteria() {
      let searchCriteriaConfig = {
        name: this.newCriteriaName,
        type: "CONTACT_CENTER_STATISTICS",
        conf: JSON.parse(JSON.stringify(this.searchCriteria)),
      };
      searchCriteriaConfig.conf.kpis = [];
      this.searchCriteria.kpis.forEach((o) => {
        searchCriteriaConfig.conf.kpis.push({
          fields: o.fields,
          value: o.value,
          type: o.type,
          text: o.text,
        });
      });
      // controllo che il nome non esista già
      if (this.checkIfSearchCriteriaExists(this.newCriteriaName) && !this.isEdit) {
        this.errorNameMessage = "Name Already Exists";
        return;
      }
      this.errorNameMessage = "";
      let newSearchCriteria = {
        searchCriteriaIdentifier: btoa(this.newCriteriaName),
        searchCriteriaConfig: searchCriteriaConfig,
      };
      try {
        let result = null;
        if (Object.keys(newSearchCriteria.searchCriteriaConfig).length === 0) {
          throw Error("No criteria settings found");
        }

        if (!this.checkIfSearchCriteriaExists(this.newCriteriaName)) {
          result = await this.$http.post("/search-criteria/", newSearchCriteria);
        } else {
          result = await this.$http.put("/search-criteria/" + btoa(this.newCriteriaName), newSearchCriteria);
        }
        this.saveDialog = false;

        if (result.data.result) {
          this.searchCriterias = {};
          Object.assign(this.searchCriterias, this.initialSearchCriterias);
          this.searchCriterias[btoa(this.newCriteriaName)] = merge({}, searchCriteriaConfig);
          this.initialSearchCriterias = Object.assign({}, this.searchCriterias);
          this.$refs.resultSnackbar.showSuccess("Search Criteria " + this.newCriteriaName + " Saved!");
          this.forceRerender++;
          this.expansionPanelSearchForm = 1;
          this.isEdit = false;
        } else {
          this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
        }

        this.newCriteriaName = "";
        this.resetCriteria();
      } catch {
        this.saveDialog = false;
        this.$refs.resultSnackbar.showError("Error saving search criteria " + this.newCriteriaName);
      }
    },
    resetCriteria() {
      this.searchCriteria = {
        type: "AGENT",
        kpis: [],
        interval: { text: "Day", value: "1d", scale: "d" },
        range: { text: "Last 7 days", value: "last7days", scale: ["d"] },
        filters: [],
        agent_queue_filters: [],
        agent_profile_filters: [],
        custom_range: [],
        timezone: {
          zone: moment.tz.guess(),
          timezoneOffset: "",
        },
      };
    },
    editCriteria(name) {
      const key = btoa(name);
      this.isEdit = true;
      this.searchCriteria = JSON.parse(JSON.stringify(this.searchCriterias[key].conf));
      this.expansionPanelSearchForm = 0;
      this.newCriteriaName = name;
    },
    deleteCriteria(name) {
      this.criteriaToDelete = name;
      this.deleteCriteriaMessage = "Delete " + name + " criteria permanently";
      this.dialogDeleteCriteria = true;
    },
    async confirmDeleteCriteria() {
      let key = btoa(this.criteriaToDelete);
      try {
        await this.$http.delete("/search-criteria/" + key);
        delete this.searchCriterias[key];
        this.forceRerender++;
        this.criteriaToDelete = "";
        this.deleteCriteriaMessage = "";
        this.dialogDeleteCriteria = false;
        this.$refs.resultSnackbar.showSuccess("Search Criteria deleted!");
      } catch {
        this.$refs.resultSnackbar.showError("Error deleting search criteria");
      }
    },
    checkIfSearchCriteriaExists(val) {
      return this.searchCriterias[btoa(val)];
    },
    cancelSaveCriteria() {
      this.saveDialog = false;
      this.newCriteriaName = "";
    },
    denyDeleteCriteria() {
      this.dialogDeleteCriteria = false;
      this.criteriaToDelete = "";
    },
    isCCMuser() {
      const user = this.$store.getters.getUser;
      //disabilito la selectAll se è un ccm senza visibilità su tutti i profili
      return (
        this.$store.getters.isCCM &&
        user.roles &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
      );
    },
    prepareComputation(criteria) {
      this.showResult = false;
      this.isReloadDisabled = true;
      clearTimeout(this.intervalTimer);
      clearInterval(this.intervalCount);
      this.countdown = 900;
      this.valueProgress = 0;
      this.intervalTimer = setTimeout(() => {
        this.isReloadDisabled = false;
      }, 900000);
      this.intervalCount = setInterval(() => {
        if (this.countdown && this.countdown > 0) {
          this.countdown--;
          this.valueProgress++;
        }
      }, 1000);
      this.expansionPanelSearchForm = 2;
      this.forceRerender++;
      this.loadingData = true;
      this.headers = [];
      this.dataset = [];
      this.dataRegistry = {};
      this.searchCriteria = criteria;
      this.searchCriteriaFiltersBak = [...this.searchCriteria.filters];
      this.headers.push({ text: "Time", value: "from", width: this.searchCriteria.interval.scale === "m" ? 280 : 200 });
      this.headers.push({ text: this.searchCriteria.type, value: "name" });
    },
    getKpiConfig(kpiName) {
      return this.values.kpis[this.searchCriteria.type].filter((k) => k.value === kpiName)[0];
    },
    postComputationTasks() {
      this.showResult = true;
      this.searchCriteria.filters = [...this.searchCriteriaFiltersBak];
      this.loadingData = false;
    },
    buildQueries() {
      const kpiGroups = {};
      const queries = [];

      this.searchCriteria.kpis
        .filter((kpi) => {
          return this.values.kpis[this.searchCriteria.type].filter((k) => k.value === kpi.value).length > 0;
        })
        .forEach((kpi) => {
          const configs = this.values.kpis[this.searchCriteria.type].filter((k) => k.value === kpi.value)[0];
          let strategy = configs.strategy || "default";
          if (this.searchCriteria.interval.scale === "m") {
            strategy = "default";
          }
          kpiGroups[strategy] = kpiGroups[strategy] || [];
          kpiGroups[strategy].push(kpi);
        });

      Object.keys(kpiGroups).forEach((strategy) => {
        queries.push({
          targetKPIs: kpiGroups[strategy],
          query: this.buildQuery(kpiGroups[strategy]),
        });
      });
      return queries;
    },

    /** 
        TODO: migliorare la costruzione della query e il consecutivo recupero delle informazioni da essa. 
        Attualmente vengono eseguite due query separate a seconda della strategia di recupero dei dati (getLatest || default), 
        ma il metodo di costruzione della query è sempre lo stesso e non ottimizzato per evitare di recuperare dati in più inutili. 
        
        Nel caso di strategia getLatest, infatti, attualmente il size a 10000 recupera tutte le aggregazioni al quarto d'ora per il 
        range temporale impostato, quando quello che serve è semplicemente avere l'ultima aggregazione di ogni giorno.
        
        Sarebbe sufficiente aggiungere un'aggregazione per tipo di kpi, con annidata una date_histogram per giorno e una top_hits con 
        valore a 1 e ordinamento discendente, come segue: 
        {
          "aggs": {
            "by_type": {
              "terms": {
                "field": "type.keyword",
                "size": 10
              },
              "aggs": {
                "by_date": {
                  "date_histogram": {
                    "field": "from",
                    "interval": "1d"
                  },
                  "aggs": {
                    "hit": {
                      "top_hits": {
                        "size": 1,
                        "sort": [
                          {
                            "from": "desc"
                          }
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
    */
    buildQuery(targetKPIs) {
      const shouldHaveKPI = [];
      // let minimum_should_match = 1;
      const shouldHaveFilter = {};
      const additionalFilters = [];
      let randConfig = this.getKpiConfig(targetKPIs[0].value);
      const interval = this.getQueryInterval(randConfig);

      targetKPIs.forEach((kpi) => {
        const configs = this.values.kpis[this.searchCriteria.type].filter((k) => k.value === kpi.value)[0];
        shouldHaveKPI.push(kpi.value);
        shouldHaveFilter[kpi.value] = [];

        this.headers.push({
          text: configs.text,
          value: kpi.value,
          align: "center",
          tooltip: configs.tooltip,
          format: configs.format,
        });
      });

      if (this.searchCriteria.filters.length > 0) {
        this.searchCriteria.filters.forEach((filter) => {
          for (const kpiValue in shouldHaveFilter) {
            shouldHaveFilter[kpiValue].push(filter.text);
          }
        });
      }
      for (const kpiValue in shouldHaveFilter) {
        if (shouldHaveFilter[kpiValue].length > 0) {
          const configs = this.values.kpis[this.searchCriteria.type].filter((k) => k.value === kpiValue)[0];
          // minimum_should_match = 2;
          let criteria = this.searchCriteria.type.toLowerCase();
          criteria = ((criteria === "queue" || criteria === "profile") && "name") || criteria;
          additionalFilters.push({
            terms: {
              ["data." + kpiValue + "." + criteria + ".keyword"]: shouldHaveFilter[kpiValue],
            },
          });
          
          if (configs.filterBy) {
            additionalFilters.push({
            terms: {
              ["data." + kpiValue + "." + configs.filterBy + ".keyword"]: shouldHaveFilter[kpiValue],
            },
          });
          }
        }
      }
      //Se è un CCM con visibilità limitata applico in automatico dei filtri
      if (this.isCCMuser()) {
        // minimum_should_match++; //Aumento in minumum should match per avere l'obbligo di match che per questo filtro aggiuntivo
        for (const kpiValue in shouldHaveFilter) {
          const kpiType = this.searchCriteria.type.toLowerCase();
          let filterArray = [];
          switch (kpiType) {
            case "agent":
              filterArray = this.values.agents.map((a) => a.authentication.credentials.username);
              break;
            case "profile":
              filterArray = this.values.profiles.map((p) => p.eri);
              break;
            case "queue":
              filterArray = this.values.queues.map((q) => q.eri);
              break;
          }
          if (filterArray.length > 0) {
            additionalFilters.push({
              terms: {
                ["data." + kpiValue + "." + kpiType + ".keyword"]: filterArray,
              },
            });
            //Update 20/05/2024 --> cvy-9091
            //quando seleziono gli agenti con select all, metto sia agent che username
            //quindi nel caso di agent metto anche username come additional filter
            if (kpiType === "agent") {
              additionalFilters.push({
                terms: {
                  ["data." + kpiValue + "." + "username" + ".keyword"]: filterArray,
                },
              });
            }
          }
        }
      }
      const isoRange = this.getRangeInISO(this.searchCriteria);
      const queryFilters = [];
      const fieldMapping = this.getKpisFieldsMap();

      const query = {
        size: 10000,
        sort: [{ from: "desc" }, { "uuid.keyword": "desc" }],
        query: {
          bool: {
            must: [
              {
                range: {
                  from: isoRange,
                },
              },
              {
                terms: {
                  "type.keyword": shouldHaveKPI,
                },
              },
              {
                match: {
                  "interval.keyword": interval,
                },
              },
            ],
          },
        },
      };

      shouldHaveKPI.forEach((kpi) => {
        const fieldPath = `data.${kpi}.${fieldMapping[kpi].field}`;
        queryFilters.push({
          range: {
            [fieldPath]: {
              gt: 0,
            },
          },
        });
      });

      if (queryFilters.length > 0) {
        query.query.bool["should"] = queryFilters;
        query.query.bool["minimum_should_match"] = 1;
      }

      /**
          I due if seguenti servono per aggiungere filtri alla query se e solo se i filtri contengono qualcosa. 
          In caso contrario, inserendo filtri con array vuoti, specialmente all'interno della clausola must,
          i risultati restituiti non saranno quelli desiderati. 
      */
      if (additionalFilters.length > 0) {
        query.query.bool.must.push({
          bool: {
            should: [...additionalFilters],
          },
        });
      }

      return query;
    },
    elaborate(result, requestedKpis) {
      const shouldHaveKPI = requestedKpis;
      const isoRange = this.getRangeInISO(this.searchCriteria);
      const isoFromDate = new Date(Date.parse(isoRange.gte));
      const isoToDate = new Date(Date.parse(isoRange.lte));
      this.requestedTimeFrames = this.generateTimeFrames(this.searchCriteria, isoFromDate, isoToDate);
      const fieldMapping = this.getKpisFieldsMap();
      const aggregationItem = this.initKpiAggregationItem(shouldHaveKPI, fieldMapping);

      result.data.hits.hits.forEach((hit) => {
        const kpiName = Object.keys(hit._source.data)[0];
        const key = this.getKeyNameFromEntity(hit._source.data[kpiName]);
        const timeKey = this.getAggregationTimeKey(hit._source.from, isoFromDate, isoToDate);
        const targetDate = new Date(Date.parse(hit._source.from));

        if (typeof aggregationItem[key] === "undefined") {
          // indicates the user is deleted
          return;
        }
        const kpiConfig = this.values.kpis[this.searchCriteria.type].find((kpi) => kpi.value === kpiName);
        if (kpiConfig && hit._source.data[kpiName][kpiConfig.fields.total] === null) {
          return;
        }
        this.dataRegistry[timeKey] = this.dataRegistry[timeKey] || {};
        this.dataRegistry[timeKey][key] = this.dataRegistry[timeKey][key] || {};

        const total = hit._source.data[kpiName][kpiConfig.fields.total];
        let occurrence = null;
        if (kpiConfig.fields.occurrence) {
          occurrence = hit._source.data[kpiName][kpiConfig.fields.occurrence];
        }
        let pushItem = true;

        if (!!kpiConfig.strategy && kpiConfig.strategy === "getLatest") {
          if (this.searchCriteria.interval.scale !== "m") {
            pushItem = this.isDateValuableTarget(targetDate, isoToDate, this.getQueryInterval(kpiConfig));
          } else {
            pushItem = typeof this.dataRegistry[timeKey][key][kpiName] === "undefined";
          }
        }

        if (pushItem) {
          this.dataRegistry[timeKey][key][kpiName] = this.dataRegistry[timeKey][key][kpiName] || {
            total: 0,
            occurrence: 0,
            type: kpiConfig.type,
            format: kpiConfig.format,
          };
          this.dataRegistry[timeKey][key][kpiName].total += total;
          this.dataRegistry[timeKey][key][kpiName].occurrence += occurrence;
        }
      });

      for (const timeKey in this.requestedTimeFrames) {
        this.dataRegistry[timeKey] =
          (this.dataRegistry[timeKey] && merge(aggregationItem, this.dataRegistry[timeKey])) || JSON.parse(JSON.stringify(aggregationItem));

        Object.keys(this.dataRegistry[timeKey]).forEach((entity) => {
          const kpis = {};
          for (const kpi in this.dataRegistry[timeKey][entity]) {
            const kpiConfig = this.getKpiConfig(kpi);

            if (kpiConfig) {
              switch (this.dataRegistry[timeKey][entity][kpi].type) {
                case "percentage":
                  if (this.dataRegistry[timeKey][entity][kpi].total) {
                    kpis[kpi] = this.twoDecimalToNumber(
                      (this.dataRegistry[timeKey][entity][kpi].occurrence * 100) / this.dataRegistry[timeKey][entity][kpi].total,
                    );
                  } else {
                    kpis[kpi] = 0;
                  }
                  break;
                case "average":
                  if (this.dataRegistry[timeKey][entity][kpi].total) {
                    kpis[kpi] = this.twoDecimalToNumber(this.dataRegistry[timeKey][entity][kpi].occurrence / this.dataRegistry[timeKey][entity][kpi].total);
                    kpis[kpi] = Math.round(kpis[kpi]);
                  } else {
                    kpis[kpi] = 0;
                  }
                  break;
                case "number":
                  kpis[kpi] = Math.round(this.dataRegistry[timeKey][entity][kpi].total);
                  break;
              }
            }
          }
          let toDate = new Date(timeKey);
          switch (this.searchCriteria.interval.value) {
            case "15m":
              toDate.setMinutes(toDate.getMinutes() + 15);
              break;
            case "30m":
              toDate.setMinutes(toDate.getMinutes() + 30);
              break;
            case "1d":
              toDate.setUTCHours(23);
              toDate.setUTCMinutes(59);
              toDate.setUTCSeconds(59);
              toDate.setUTCMilliseconds(0);
              break;
            case "total":
              toDate = new Date(isoRange.lte);
              break;
          }

          this.dataRegistry[timeKey][entity].data = {
            from: timeKey.toString(),
            to: toDate.toISOString(),
            name: entity,
            ...kpis,
          };
        });
      }
    },
    getComputedKpiValue(kpiValue) {
      if (typeof kpiValue.value != "undefined") {
        return kpiValue.value;
      } else {
        return kpiValue;
      }
    },
    formatKpiValue(value, format) {
      switch (format) {
        case "percentage": {
          value = `${value}%`;
          break;
        }
        case "time": {
          // Time saved in seconds
          // Convert it in hh:mm:ss format (omit the hh if zero)
          let days = Math.floor(value / 86400);
          let hours = Math.floor((value % 86400) / 3600);
          let minutes = Math.floor((value % 3600) / 60);
          let seconds = value % 60;
          let formatted = ``;

          if (days == 0) {
            formatted = `${seconds < 10 ? 0 : ""}${seconds}s`;
          }

          formatted = `${minutes < 10 ? 0 : ""}${minutes}m ${formatted}`;

          if (hours > 0 || days > 0) {
            formatted = `${hours < 10 ? 0 : ""}${hours}h ${formatted}`;
          }

          if (days > 0) {
            formatted = `${days < 10 ? 0 : ""}${days}d ${formatted}`;
          }

          value = formatted;
          break;
        }
      }
      return value;
    },
    flattenReportData() {
      let dataset = [];
      Object.keys(this.dataRegistry).forEach((tk) => {
        Object.keys(this.dataRegistry[tk]).forEach((o) => {
          if (this.dataRegistry[tk][o] && this.dataRegistry[tk][o].data) {
            dataset.push(this.dataRegistry[tk][o].data);
          }
        });
      });
      return dataset;
    },
    async getReport(criteria) {
      this.prepareComputation(criteria);
      const queries = this.buildQueries();
      for (const item of queries) {
        await this.computeQueryResult(item.query, item.targetKPIs);
      }
      this.dataset = this.flattenReportData();
      this.postComputationTasks();
    },
    async getQueryResult(query) {
      const data = { url: "/webapi/aggregation/elasticsearch", method: "POST", data: query };
      return await this.$http.post("/crab", data, { timeout: 300000 });
    },
    async computeQueryResult(query, requestedKpis, pageSize, searchAfter) {
      if (searchAfter) {
        query.search_after = searchAfter;
      }
      if (!pageSize || pageSize <= 0) {
        pageSize = 10000;
      }
      query.size = pageSize;

      const result = await this.getQueryResult(query);
      this.elaborate(result, requestedKpis);

      //this.showResult = true;
      if (result && result.data) {
        const resultLen = result.data.hits.hits.length;
        if (resultLen > 0 && resultLen === pageSize) {
          searchAfter = result.data.hits.hits[resultLen - 1].sort;
          await this.computeQueryResult(query, requestedKpis, pageSize, searchAfter);
        }
      }
      return this.dataRegistry;
    },
  },
};
</script>

<style scoped>
.dottedUnderline {
  border-bottom: 1px dotted;
}
.myProgressBar {
  position: absolute;
  bottom: -5px;
  left: auto;
  width: 80px;
}
</style>
<style>
.contactResult {
  width: 100%;
  white-space: nowrap;
}

.contactResult .v-data-table__wrapper table thead tr th:not(:first-child) {
  width: auto;
  max-width: 120px;
  min-width: 85px;
  word-break: keep-all;
  white-space: break-spaces;
}
.contactResult .v-data-table__wrapper table tbody tr td:not(:first-child) {
  width: auto;
  max-width: 115px;
  min-width: 85px;
  word-break: keep-all;
  /* white-space: break-spaces; */
}
.contactResult .v-data-table__wrapper table thead tr th:nth-child(2),
.contactResult .v-data-table__wrapper table tbody tr td:nth-child(2) {
  max-width: 200px !important;
}
</style>
