<template>
  <v-dialog v-model="showConfirm" max-width="290" persistent>
    <v-card>
      <v-card-title>Are you sure?</v-card-title>

      <v-card-text class="mt-4 font-weight-light">
        {{ message }}
      </v-card-text>

      <v-card-text
        v-if="confirmMessage"
        class="font-weight-light"
      >
        Type the name of the company:
        <v-text-field v-model="typedCompany" />
      </v-card-text>

      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" class="px-2">
            <v-btn block color="success" outlined :disabled="isDeletionConfirmed" @click="sendConfirmation">
              <v-icon left>
                mdi-check
              </v-icon>Yes
            </v-btn>
          </v-col>
          <v-col cols="6" class="px-2">
            <v-btn block color="error" outlined @click="sendDeny">
              <v-icon left>
                mdi-close
              </v-icon>No
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: ["showConfirm", "message", "confirmMessage"],
  data() {
    return {
      typedCompany: "",
    };
  },
  computed: {
    isDeletionConfirmed() {
      return this.confirmMessage && this.confirmMessage !== this.typedCompany;
    },
  },
  mounted() {
    this.typedCompany = "";
  },
  methods: {
    sendConfirmation() {
      this.typedCompany = "";
      this.$emit(this.$store.getters.getEvents.DIALOG_CONFIRM);
    },
    sendDeny() {
      this.typedCompany = "";
      this.$emit(this.$store.getters.getEvents.DIALOG_DENY);
    },
  },
};
</script>
