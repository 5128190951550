<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <SearchBar :searchString="searchString" title="Select variables to publish" @input="searchString = $event" />
    <ConfirmDialog :showConfirm="showConfirm" :message="getMessage" @dialogConfirm="onConfirm" @dialogDeny="showConfirm = false" />
    <!-- Cerd information about public variables -->
    <v-alert type="info" outlined text :color="$vuetify.theme.dark ? '#FBFBF7' : 'primary'" class="mx-4 my-5">
      <div class="px-6">
        <span>
          In accordance with best security practices, ConvyAI does not include the value of variables in external responses during conversations. This section
          allows you to select which variables among those defined in each flow must be public. The selected variables will be returned in the external response
          and by default no variable is public.
        </span>
      </div>
    </v-alert>

    <v-card class="mb-12 mx-4">
      <v-row class="pt-4">
        <v-switch v-model="publishAll" class="ml-10" label="All published by default" />
      </v-row>
      <v-expansion-panels v-model="panel" class="mt-6" focusable>
        <v-expansion-panel>
          <v-expansion-panel-header style="max-height: 45px !important">
            Global Variables List
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <!-- Se select all NON è abilitato -->
            <v-row v-if="globalVariablesList.length > 0" class="ma-0 pa-1">
              <v-col col="12" class="text-right">
                <v-btn small text color="primary" @click="selectAllGlobal">
                  Select All
                </v-btn>
                <v-btn small text color="error" @click="deselectAllGlobal">
                  Deselect all
                </v-btn>
              </v-col>
            </v-row>
            <v-chip-group
              v-if="globalVariablesList.length > 0"
              v-model="selectedVariablesGlobal"
              class="mb-2"
              multiple
              column
              active-class="primary"
            >
              <v-chip
                v-for="(variable, index) in globalVariablesList"
                v-show="isChipVisible(variable)"
                :key="index"
                :value="variable"
                class="ma-2"
                filter
                label
              >
                {{ variable }}
              </v-chip>
            </v-chip-group>
            <v-alert v-if="globalVariablesList.length === 0" class="mt-6" type="warning">
              Currently there are no global variables to publish
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header style="max-height: 45px !important">
            Variables List
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <!-- Se select all NON è abilitato -->
            <v-row v-if="variablesList.length > 0" class="ma-0 pa-1">
              <v-col col="12" class="text-right">
                <v-btn small text color="primary" @click="selectAll">
                  Select All
                </v-btn>
                <v-btn small text color="error" @click="deselectAll">
                  Deselect all
                </v-btn>
              </v-col>
            </v-row>
            <v-chip-group
              v-if="variablesList.length > 0"
              v-model="selectedVariables"
              class="mb-2"
              multiple
              column
              active-class="primary"
            >
              <v-chip
                v-for="(variable, index) in variablesList"
                v-show="isChipVisible(variable)"
                :key="index"
                :value="variable"
                class="ma-2"
                filter
                label
              >
                {{
                  variable
                }}
              </v-chip>
            </v-chip-group>
            <v-alert v-if="variablesList.length == 0" class="mt-6" type="warning">
              Currently there are no variables to publish
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-row :class="getMainRowMargin" class="mt-n8">
      <!-- Button -->
      <v-btn color="success" :loading="loading" :class="getButtonMargin" @click.prevent="showConfirm = true">
        <v-icon left>
          mdi-floppy
        </v-icon>save
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import EventBus from "../../event-bus";

export default {
  name: "PublishEntities",
  components: {
    SearchBar,
    ConfirmDialog,
    ResultSnackbar,
  },
  data() {
    return {
      publishAll: false,
      searchString: "",
      showConfirm: false,
      showConfirmPrivatize: false,
      panel: 0,
      company: this.$store.getters.getSelectedCompany,
      selectedVariables: [],
      selectedVariablesGlobal: [],
      variablesList: [],
      globalVariablesList: [],
      werePublished: false,
      werePublishedGlobal: false,
      loading: false,
    };
  },
  computed: {
    ...spacing,
    getMessage() {
      if (
        (this.werePublished && this.selectedVariables.length === 0 && this.werePublishedGlobal && this.selectedVariablesGlobal.length === 0) ||
        (!this.publishAll && this.selectedVariables.length === 0 && this.selectedVariablesGlobal.length === 0)
      ) {
        return "All variables will no longer be public";
      }
      if (this.publishAll) {
        return "All variables present and those that will be created will be published by default.";
      }
      if ((this.selectedVariables && this.selectedVariables.length > 1) || (this.selectedVariablesGlobal && this.selectedVariablesGlobal.length > 1)) {
        return "These variables will be published";
      }
      return "";
    },
  },
  async mounted() {
    try {
      const flowVars = await this.$http.get("/flows/vars");
      const globalVars = await this.$http.get("/global-variable");
      const publishedVars = await this.$http.get(`/company/${this.$store.getters.getSelectedCompany}`);
      if (flowVars) {
        this.variablesList = flowVars.data;
        this.variablesList.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
      }
      if (globalVars && globalVars.data) {
        for (let variable in globalVars.data) {
          this.globalVariablesList.push(globalVars.data[variable].key.replaceAll("%", ""));
        }
      }
      if (publishedVars.data && publishedVars.data.security) {
        this.publishAll =
          publishedVars.data.security && publishedVars.data.security.publishAllEntities ? publishedVars.data.security.publishAllEntities : false;
        this.selectedVariables =
          publishedVars.data.security && !publishedVars.data.security.publishAllEntities ? publishedVars.data.security.publicEntities : [];

        this.selectedVariablesGlobal = [];
        if (publishedVars.data.security) {
          if (publishedVars.data.security.publishAllEntities) {
            this.selectedVariablesGlobal = this.globalVariablesList || [];
          } else {
            this.selectedVariablesGlobal = publishedVars.data.security.globalEntities || [];
          }
        }

        if (this.selectedVariables.length > 0) {
          this.werePublished = true;
        }
        if (this.selectedVariablesGlobal && this.selectedVariablesGlobal.length > 0) {
          this.werePublishedGlobal = true;
        }
      }
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail to load data: ${e.message}`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...scroller,
    isChipVisible(variable) {
      if (this.searchString && this.searchString === "") {
        return true;
      } else {
        return !(this.searchString && !variable.toLowerCase().includes(this.searchString.toLowerCase()));
      }
    },
    selectAll() {
      this.selectedVariables = this.variablesList;
    },
    deselectAll() {
      this.selectedVariables = [];
    },
    selectAllGlobal() {
      this.selectedVariablesGlobal = this.globalVariablesList;
    },
    deselectAllGlobal() {
      this.selectedVariablesGlobal = [];
    },
    async onConfirm() {
      this.loading = true;
      let security = {
        publishAllEntities: this.publishAll,
        publicEntities: this.selectedVariables,
        globalEntities: this.selectedVariablesGlobal,
      };
      try {
        await this.$http.put("/company/security/" + this.$store.getters.getSelectedCompany, { security });
        this.$refs.resultSnackbar.showSuccess("Variables published!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Variables cannot be published: " + e);
      } finally {
        this.loading = false;
        this.showConfirm = false;
      }
    },
  },
};
</script>
