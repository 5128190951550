<template>
  <v-row class="justify-space-between" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
    <v-col cols="12" class="fill-height">
      <v-card
        class="liveConversation"
        :color="$vuetify.theme.dark ? '#1D9096' : '#1E88E5'"
        elevation="10"
        :class="$vuetify.breakpoint.mdAndDown ? 'mb-4 px-4' : ''"
      >
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          height="150px"
          :src="
            $vuetify.theme.dark
              ? require('../../../assets/img/dashboard/botEfficiency/liveConversationOttanio.png')
              : require('../../../assets/img/dashboard/botEfficiency/liveConversation.png')
          "
        >
          <v-card-text class="fill-height">
            <v-row class="justify-space-between">
              <v-card-title class="headline white--text">
                Live <br>
                Conversations
              </v-card-title>
              <v-card-text class="display-1 text-right white--text">
                {{ liveConversations }}
              </v-card-text>
            </v-row>
          </v-card-text>
        </v-img>
        <v-row v-else justify="center" align="center">
          <v-icon v-if="$vuetify.breakpoint.smAndUp" dark left right large>
            mdi-chat-processing-outline
          </v-icon>
          <v-card-title class="headline white--text">
            Live Conversations
          </v-card-title>
          <v-spacer />
          <v-card-title class="display-1 white--text">
            {{ liveConversations }}
          </v-card-title>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="fill-height">
      <v-card
        :color="$vuetify.theme.dark ? '#60a38d' : '#43A047'"
        class="liveConversation"
        elevation="10"
        :class="$vuetify.breakpoint.mdAndDown ? 'mb-4 px-4' : ''"
      >
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          cover
          height="150px"
          :src="
            $vuetify.theme.dark
              ? require('../../../assets/img/dashboard/botEfficiency/liveHandoverDarkTheme.png')
              : require('../../../assets/img/dashboard/botEfficiency/liveHandover.png')
          "
        >
          <v-card-text class="fill-height">
            <v-row class="justify-space-between">
              <v-card-title class="headline white--text">
                Live <br>
                Handover
              </v-card-title>
              <v-card-text class="display-1 text-right white--text">
                {{ liveHandover }}
              </v-card-text>
            </v-row>
          </v-card-text>
        </v-img>
        <v-row v-else justify="center" align="center">
          <v-icon v-if="$vuetify.breakpoint.smAndUp" dark left right large>
            mdi-headphones
          </v-icon>
          <v-card-title class="headline white--text">
            Live Handover
          </v-card-title>
          <v-spacer />
          <v-card-title class="display-1 white--text">
            {{ liveHandover }}
          </v-card-title>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "LiveCards",
  props: {
    "liveConversations": {
      type: Number,
      required: false,
      default: 0
    },
    "liveHandover": {
      type: Number,
      required: false,
      default: 0
    }
  },
};
</script>
