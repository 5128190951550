<template>
  <v-dialog v-model="about" max-width="800">
    <v-card>
      <v-card-title class="headline justify-center">
        <div class="mr-10">
          <v-img v-if="isDarkMode" max-height="90" max-width="80" :src="require('../../assets/img/logo_white.png')" />
          <v-img v-else max-height="90" max-width="80" :src="require('../../assets/img/marchio-convy.png')" />
        </div>
        Release: <b class="ml-4">{{ release + minorRelease }}</b>
      </v-card-title>
      <v-divider class="py-2" />
      <v-card-text class="text-center">
        <v-row>
          <v-col cols="4" class="text-center">
            COMPONENT
          </v-col>
          <v-col cols="3" class="text-center">
            BUILD TAG
          </v-col>
          <v-col cols="2" class="text-center">
            BUILD NUMBER
          </v-col>
          <v-col cols="3" class="text-center">
            COMPILE DATE
          </v-col>
        </v-row>
        <v-row v-for="(comp, index) in versions" :key="'about_' + index">
          <v-col cols="4" class="text-center">
            {{ comp.NAME }}
          </v-col>
          <v-col cols="3" class="text-center">
            {{ getBuildTag(comp.BUILD_TAG) }}
          </v-col>
          <v-col cols="2" class="text-center">
            {{ comp.BUILD_NUMBER }}
          </v-col>
          <v-col cols="3" class="text-center">
            {{ comp.COMPILE_DATE }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from "../../event-bus";
import versionFile from "../../helpers/version";

export default {
  name: "About",
  components: {},
  data() {
    return {
      about: false,
      release: "ConvyAI ",
      minorRelease: "",
      versions: [],
    };
  },
  computed: {
    isDarkMode: function () {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.versions.push(versionFile);
    this.minorRelease = this.getBuildTag(versionFile.BUILD_TAG);
    if (this.minorRelease.indexOf("-") !== -1) {
      this.minorRelease = this.minorRelease.substring(0, this.minorRelease.indexOf("-"));
    }
  },
  created() {
    EventBus.$on(this.$store.getters.getEvents.SHOW_ABOUT, this.toggleAbout);
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.SHOW_ABOUT, this.toggleAbout);
  },
  methods: {
    toggleAbout() {
      if (this.versions.length === 1) {
        const backendVersions = this.$store.state.company.versions;
        if (backendVersions) {
          this.versions = this.versions.concat(backendVersions);
        }
      }
      this.about = true;
    },
    getBuildTag(tag) {
      if (tag) {
        return tag.replace("origin/", "").replace("refs/tags/", "");
      }
      return tag || "";
    },
  },
};
</script>
