<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <SearchBar
      :searchString="searchString"
      @input="
        searchString = $event;
        forceRerender++;
      "
    />
    <ConfirmDialog
      :showConfirm="showConfirm"
      :message="deleteMessage"
      @dialogDeny="
        showConfirm = false;
        tmpScheduleNameToDelete = '';
      "
      @dialogConfirm="onDeleteConfigConfirm"
    />
    <!-- Main row container -->
    <v-row justify="center" :class="getMainRowMargin">
      <!-- Expansion panel creation -->
      <v-expansion-panels v-if="scheduleCreation" v-model="expansionPanelCreation" :class="getMarginNewCard" popout>
        <v-expansion-panel @change="changeStatusExpansionaPanel">
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="2" sm="2" md="1">
                <v-icon>mdi-calendar-clock</v-icon>
              </v-col>
              <v-col cols="8">
                New Scheduled Watchbot
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ScheduledWatchbotForm :schedule="newSchedule" :watchbots="watchbots" :schedules="schedules" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Expansion panel Schedules -->
      <v-expansion-panels v-model="expansionPanel" :class="getMarginNewCard" popout>
        <v-expansion-panel v-for="schedule in getSchdules" :key="schedule.name" @change="changeStatusExpansionaPanel">
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="2" sm="2" md="2">
                <v-icon>mdi-calendar-clock</v-icon>
              </v-col>
              <v-col cols="6" sm="5" md="3">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Name</v-list-item-subtitle>
                    <v-list-item-title>{{ schedule.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4" sm="5" md="3">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Type</v-list-item-subtitle>
                    <v-list-item-title>{{ schedule.type }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col md="4" class="hidden-sm-and-down">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                    <v-list-item-title>{{ schedule.lastUpdate | formatVerboseDateTime }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ScheduledWatchbotForm :schedule="schedule" :watchbots="watchbots" :schedules="schedules" :loading="loading" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row v-if="schedules.length == 0 && !scheduleCreation && notLoading" :class="getMainRowMargin">
      <v-col cols="12">
        <v-alert type="warning">
          There isn't any Scheduled Watchbot Available
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="notLoading">
      <v-col cols="12">
        <v-btn class="ml-8 mt-n2" :class="getButtonMargin" color="primary" to="/watchbot">
          <v-icon small left>
            mdi-keyboard-backspace
          </v-icon>Back
        </v-btn>
      </v-col>
    </v-row>
    <AddNewItemButton name="Schedule" @addNewItem="addNewSchedule()" />
  </v-container>
</template>
<script>
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import ScheduledWatchbotForm from "../../components/tools/ScheduledWatchbotForm";

export default {
  name: "ScheduledWatchbot",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    ScheduledWatchbotForm,
    ConfirmDialog,
  },
  data() {
    return {
      watchbots: [],
      schedules: [],
      newSchedule: {
        name: "",
        type: "daily",
        time: "00:00",
        days: [],
        watchbotList: [],
        dayNumber: 1,
      },

      //name WB to delete temporary
      tmpScheduleNameToDelete: "",
      showConfirm: false,
      deleteMessage: "",

      //search
      searchString: "",

      //Expansion panel manage
      scheduleCreation: false,
      expansionPanelCreation: null,
      expansionPanel: null,

      //Rerender alla creazione e alla cancellazione
      forceRerender: 0,

      //Property per non far visualizzare l'alert mentre carica la pagin
      notLoading: false,
      loading: false,
    };
  },
  computed: {
    ...spacing,
    getSchdules() {
      this.forceRerender;
      if (this.schedules) {
        return (
          this.schedules
            //Filtro per la ricerca
            .filter((schedule) => schedule.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
            //Sorting per un campo
            .sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              return 0;
            })
        );
      }
      return [];
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.TEST_WATCHBOT_SCHEDULE, this.printEvent);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_WATCHBOT_SCHEDULE_CREATION, this.resetNewSchedule);
    EventBus.$on(this.$store.getters.getEvents.CREATE_WATCHBOT_SCHEDULE, this.createWatchbotSchedule);
    EventBus.$on(this.$store.getters.getEvents.UPDATE_WATCHBOT_SCHEDULE, this.updateWatchbotSchedule);
    EventBus.$on(this.$store.getters.getEvents.DELETE_WATCHBOT_SCHEDULE, this.deleteWatchbotSchedule);

    try {
      const watchbotsResponse = await this.$http.get("/watchbot");
      this.watchbots = [];
      for (let scriptName in watchbotsResponse.data) {
        this.watchbots.push({
          name: scriptName,
          ...watchbotsResponse.data[scriptName],
        });
      }
      const scheduleResponse = await this.$http.get("/watchbot-scheduler");
      for (let name in scheduleResponse.data) {
        scheduleResponse.data[name].name = name;
        //portiamo l'orario che si visualizza nell'orario del browser
        this.schedules.push(scheduleResponse.data[name]);
      }
    } finally {
      this.forceRerender++;
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.notLoading = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_WATCHBOT_SCHEDULE_CREATION, this.resetNewSchedule);
    EventBus.$off(this.$store.getters.getEvents.CREATE_WATCHBOT_SCHEDULE, this.createWatchbotSchedule);
    EventBus.$off(this.$store.getters.getEvents.UPDATE_WATCHBOT_SCHEDULE, this.updateWatchbotSchedule);
    EventBus.$off(this.$store.getters.getEvents.DELETE_WATCHBOT_SCHEDULE, this.deleteWatchbotSchedule);
  },
  methods: {
    ...scroller,
    printEvent(obj) {
      if (obj.error) {
        this.$refs.resultSnackbar?.showError(obj.msg);
      } else {
        this.$refs.resultSnackbar?.showSuccess(obj.msg);
      }
    },
    changeStatusExpansionaPanel() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewSchedule() {
      this.scheduleCreation = true;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    async createWatchbotSchedule(scheduleWb) {
      scheduleWb.offsetTime = this.getOffsetTime();
      try {
        this.loading = true;
        const res = await this.$http.post("/watchbot-scheduler", scheduleWb);
        this.$refs.resultSnackbar.showSuccess("Scheduled Watchbot " + res.data.name + " Created!");
        this.schedules.push(res.data);
        this.expansionPanel = null;
        this.forceRerender++;
        this.scheduleCreation = false;
        this.resetNewSchedule();
      } catch (err) {
        this.$refs.resultSnackbar.showError("Unable to create Schedule Watchbot!");
      } finally {
        this.loading = false;
      }
    },
    async updateWatchbotSchedule(scheduleWb) {
      try {
        this.loading = true;
        scheduleWb.offsetTime = this.getOffsetTime();
        const res = await this.$http.put("/watchbot-scheduler/" + scheduleWb.name, scheduleWb);
        for (let i = 0; i < this.schedules.length; i++) {
          if (this.schedules[i].name === res.data.name) {
            this.schedules[i] = res.data;
            this.forceRerender++;
            this.expansionPanel = null;
            this.$refs.resultSnackbar.showSuccess("Scheduled Watchbot " + res.data.name + " Updated!");
          }
        }
      } catch (err) {
        this.$refs.resultSnackbar.showError("Unable to update Schedule Watchbot");
      } finally {
        this.loading = false;
      }
    },
    getOffsetTime() {
      return new Date().getTimezoneOffset() / 60;
    },
    deleteWatchbotSchedule(scheduleName) {
      this.tmpScheduleNameToDelete = scheduleName;
      this.deleteMessage = "Are you sure to delete schedule: " + scheduleName + " ?";
      this.showConfirm = true;
    },
    async onDeleteConfigConfirm() {
      try {
        this.loading = true;
        await this.$http.delete("/watchbot-scheduler/" + this.tmpScheduleNameToDelete);
        for (let i = 0; i < this.schedules.length; i++) {
          if (this.schedules[i].name === this.tmpScheduleNameToDelete) {
            this.schedules.splice(i, 1);
            this.forceRerender++;
            this.$refs.resultSnackbar.showSuccess("Scheduled Watchbot " + this.tmpScheduleNameToDelete + " Deleted!");
          }
        }
      } catch (err) {
        this.$refs.resultSnackbar.showError("Unable to delete schdule watchbot " + this.tmpScheduleNameToDelete);
      } finally {
        this.changeStatusExpansionaPanel();
        this.showConfirm = false;
        this.tmpScheduleNameToDelete = "";
        this.loading = false;
      }
    },
    resetNewSchedule() {
      this.expansionPanelCreation = null;
      this.scheduleCreation = false;
      this.newSchedule = {
        name: "",
        type: "daily",
        time: "00:00",
        days: [],
        watchbotList: [],
        dayNumber: 1,
      };
    },
  },
};
</script>
