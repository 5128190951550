<template>
  <v-col cols="12" class="fill-height pa-0" :class="$vuetify.breakpoint.mdAndDown ? 'mb-4 pb-4' : ''">
    <v-row class="pa-0 ma-0 fill-height justify-space-between">
      <v-skeleton-loader v-if="skeleton" class="mx-auto" type="image" />
      <v-col
        v-for="(section, index) in getSections"
        v-else
        :key="'section_cew_' + index + rerender"
        cols="12"
        class="pl-0"
        :class="$vuetify.breakpoint.mdAndDown ? 'px-0 mx-0 mt-1 mb-1' : 'mx-0 px-0 mb-4'"
      >
        <v-sheet
          rounded
          elevation="10"
          :color="section.background"
          class="companyEfficiencyWidget white--text pl-0"
          :class="$vuetify.breakpoint.mdAndDown ? 'ml-0 mr-2' : 'ml-2 mr-0'"
        >
          <v-row justify="center" class="py-3 px-8" style="height: 96px">
            <v-col cols="12" lg="6" justify="center" class="ma-0 pa-0" :class="$vuetify.breakpoint.mdAndDown ? '' : 'pt-6'">
              <v-avatar tile size="35" class="mr-2" style="overflow: visible">
                <v-img contain :src="section.avatar" class="mt-n2" />
              </v-avatar>
              <div style="display: inline-block; font-size: 24px">
                {{ section.label }}
              </div>
            </v-col>
            <v-col
              cols="6"
              md="4"
              lg="3"
              align="center"
              justify="center"
              class="ma-0 pa-0"
            >
              <v-btn
                dark
                text
                class="efficiencyButton fill-width ma-0 pa-0"
                style="height: 38px"
                :style="getCursor"
                @click="showDetails(section, getPreviousMonth, false)"
              >
                <div>{{ getPreviousMonth.substring(0, 3).toUpperCase() }}</div>
                <div>{{ section.previousMonth }}</div>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              md="4"
              lg="3"
              align="center"
              justify="center"
              class="ma-0 pa-0"
              :style="getColLeftBorder"
            >
              <v-btn
                text
                dark
                class="efficiencyButton fill-width ma-0 pa-0"
                style="height: 38px"
                :style="getCursor"
                @click="showDetails(section, getCurrentMonth, true)"
              >
                <div>{{ getCurrentMonth.substring(0, 3).toUpperCase() }}</div>
                <div>{{ section.currentMonth }}</div>
              </v-btn>
            </v-col>
            <v-btn
              v-if="company && section.label == 'FULLBOT'"
              icon
              text
              tile
              small
              depressed
              :disabled="disableReload"
              style="position: absolute; right: 20px; top: 110px"
              @click="refresh"
            >
              <v-icon class="mx-0" color="this.$vuetify.theme.dark ? 'black':'white'">
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-row>
        </v-sheet>
        <v-overlay
          :absolute="absolute"
          :opacity="opacity"
          :value="overlay"
          z-index="2"
          :class="'companyWidgetOverlay ' + index"
          color="overlayDashboard"
        >
          <v-system-bar window :color="dataDetails.background">
            <v-avatar tile size="25" class="mx-5">
              <v-img contain :src="dataDetails.avatar" />
            </v-avatar>
            <span>Details of {{ dataDetails.month }}</span>
            <v-spacer />
            <v-icon @click="overlay = false">
              mdi-close
            </v-icon>
          </v-system-bar>
          <!--overlay content -->
          <!--CURRENT MONTH STATISTICS -->
          <span v-if="dataDetails.current">
            <perfect-scrollbar class="psCompanyEfficiencyWidget" :options="$store.getters.getDefaultScrollProps">
              <v-list dense :light="!isDarkMode" max-height="390" class="companyEfficiencyList">
                <v-list-item-group color="primary">
                  <v-list-item v-for="(flow, i) in getOrderedListCurrent" :key="'getOrderedListCurrent' + i" dense>
                    <v-list-item-content :title="flow.flow + ' - ' + flow.version">
                      <v-list-item-title class="text-left">{{ flow.flow }} - {{ flow.version }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="flow.previous_flow"
                        :title="flow.previous_flow"
                        class="ml-5 text-left"
                      >From: {{ flow.previous_flow }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-spacer />
                    <span>{{ flow.recurrences }}</span>
                    <v-list-item-action @click="canOpenFlow() && getBotFromFlow(flow.flow, flow.version)">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon v-if="canOpenFlow()" small :color="dataDetails.background" v-on="on">mdi-open-in-new</v-icon>
                        </template>
                        <span class="caption">Go to {{ flow.flow }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </perfect-scrollbar>
          </span>
          <!--PREVIOUS MONTH STATISTICS -->
          <span v-else>
            <perfect-scrollbar class="psCompanyEfficiencyWidget" :options="$store.getters.getDefaultScrollProps">
              <v-list dense :light="!isDarkMode" max-height="390" class="companyEfficiencyList">
                <v-list-item-group color="primary">
                  <v-list-item v-for="(flow, i) in getOrderedListPrevious" :key="'getOrderedListPrevious' + i" dense>
                    <v-list-item-content :title="flow.flow + ' - ' + flow.version">
                      <v-list-item-title class="ml-5">{{ flow.flow }} - {{ flow.version }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="flow.previous_flow"
                        :title="flow.previous_flow"
                        class="ml-5"
                      >From: {{ flow.previous_flow }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-spacer />
                    <span>{{ flow.recurrences }}</span>
                    <v-list-item-icon @click="canOpenFlow() && getBotFromFlow(flow.flow, flow.version)">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon v-if="canOpenFlow()" small :color="dataDetails.background" v-on="on">mdi-open-in-new</v-icon>
                        </template>
                        <span class="caption">Go to {{ flow.flow }}</span>
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </perfect-scrollbar>
          </span>
        </v-overlay>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import openFD from "../../../helpers/openFD";
import checkRoleAndPermission from "../../../helpers/checkRoleAndPermission";

export default {
  name: "CompanyEfficiencyWidget",
  props: {
    "botList": {
      type: Array,
      required: true
    },
    "botId": {
      type: String,
      required: false,
      default: null
    },
    "company": {
      type: String,
      required: false,
      default: null
    },
    "botOverviewData": {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      absolute: true,
      opacity: 1,
      overlay: false,
      //sections: { COMPLETED: {}, HANDOVER: {}, TIMEOUT: {}, ERROR: {} },
      sections: { COMPLETED: {}, TIMEOUT: {}, ERROR: {} },
      dataSet: {},
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      dataDetails: {},
      skeleton: false,
      disableReload: false,
      rerender: 0,
    };
  },
  computed: {
    getOrderedListCurrent() {
      let currentStat = this.getCurrentStats();
      if (currentStat && currentStat != "n/a") {
        return currentStat.sort((a, b) => (a.flow > b.flow ? 1 : b.flow > a.flow ? -1 : 0));
      } else return [];
    },
    getOrderedListPrevious() {
      let previousStats = this.getPreviousStats();
      if (previousStats && previousStats != "n/a") {
        return previousStats.sort((a, b) => (a.flow > b.flow ? 1 : b.flow > a.flow ? -1 : 0));
      } else return [];
    },
    getColLeftBorder() {
      return this.$vuetify.breakpoint.smAndUp ? { "border-left": "1px solid white" } : {};
    },
    getColBorders() {
      return this.$vuetify.breakpoint.smAndUp ? "border-left:1px solid white;border-right:1px solid white" : "";
    },
    getCursor() {
      if (this.company) {
        return "cursor:default !important";
      } else {
        return "";
      }
    },
    titleClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "subheading";
        case "lg":
          return "font-weight-bold subheading";
        case "xl":
          return "font-weight-bold headline";
        default:
          return "title";
      }
    },
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "3px";
        case "md":
          return "30px";
        case "lg":
        case "xl":
        default:
          return "35px";
      }
    },
    isDarkMode: function () {
      return this.$vuetify.theme.dark;
    },
    getPreviousMonth: function () {
      let date = new Date();
      if (date.getUTCMonth() === 0) return this.months[11];
      else return this.months[date.getUTCMonth() - 1];
    },
    getCurrentMonth: function () {
      let date = new Date();
      return this.months[date.getUTCMonth()];
    },
    getSections: function () {
      this.rerender;
      this.createStatistics();
      return this.sections;
    },
  },
  async mounted() {
    if (!this.botOverviewData || Object.keys(this.botOverviewData).length == 0) {
      await this.request();
    }
  },
  methods: {
    ...checkRoleAndPermission,
    async request() {
      if (this.botId) {
        let result = await this.$httpAuth.get("/dashboard/bot-efficiency/" + this.botId);
        this.dataSet = result.data.dashboard;
        if (this.dataSet) {
          this.dataSet.currentStats.sort((a, b) => {
            if (a.state.toLowerCase() > b.state.toLowerCase()) {
              return 1;
            }
            if (a.state.toLowerCase() < b.state.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          this.dataSet.previousStats.sort((a, b) => {
            if (a.state.toLowerCase() > b.state.toLowerCase()) {
              return 1;
            }
            if (a.state.toLowerCase() < b.state.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          this.createStatistics();
          this.rerender++;
        }
      } else {
        if (this.company) {
          this.skeleton = true;
        }
        const company = this.company || this.$store.getters.getSelectedCompany;
        let result = await this.$httpAuth.get("/dashboard/company-overview/" + company);
        this.dataSet = result.data.dashboard;
        if (this.dataSet) {
          this.dataSet.currentStats.sort((a, b) => {
            if (a.state.toLowerCase() > b.state.toLowerCase()) {
              return 1;
            }
            if (a.state.toLowerCase() < b.state.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          this.dataSet.previousStats.sort((a, b) => {
            if (a.state.toLowerCase() > b.state.toLowerCase()) {
              return 1;
            }
            if (a.state.toLowerCase() < b.state.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
        this.skeleton = false;
      }
      this.rerender++;
    },
    createStatistics: function () {
      if (this.botOverviewData && Object.keys(this.botOverviewData).length > 0) {
        this.dataSet = this.botOverviewData[this.botId];
      }
      if (this.dataSet && this.dataSet.currentStats) {
        for (let i in this.dataSet.currentStats) {
          switch (this.dataSet.currentStats[i].state) {
            case "COMPLETED":
              this.sections.COMPLETED.label = "COMPLETED";
              if (this.$vuetify.theme.dark) {
                this.sections.COMPLETED.background = "#1D9096";
              } else {
                this.sections.COMPLETED.background = "#1E88E5";
              }
              this.sections.COMPLETED.avatar = require("../../../assets/img/dashboard/fullbot.png");
              this.sections.COMPLETED.currentMonth = this.adjustPercentage(this.dataSet.currentStats[i].recurrences, 2);
              this.sections.COMPLETED.previousMonth = this.adjustPercentage(this.dataSet.previousStats[i].recurrences, 2);
              // Current Statistics
              this.sections.COMPLETED.currentStats = [];
              var totCompleted = this.dataSet.currentStats[i].numericrec;
              for (let flowBot in this.dataSet.currentCompleted) {
                this.sections.COMPLETED.currentStats.push(this.dataSet.currentCompleted[flowBot]);
                if (this.sections.COMPLETED.currentStats[flowBot]) {
                  this.sections.COMPLETED.currentStats[flowBot].flow = this.dataSet.currentCompleted[flowBot].flow;
                  this.sections.COMPLETED.currentStats[flowBot].recurrences = this.adjustPercentage(
                    (this.dataSet.currentCompleted[flowBot].recurrences * 100) / totCompleted,
                    2,
                  );
                }
              }
              // previous Month Statistics
              this.sections.COMPLETED.previousStats = [];
              var prevCompared = this.dataSet.previousStats[i].numericrec;
              for (let flowBotPast in this.dataSet.previousCompleted) {
                this.sections.COMPLETED.previousStats.push(this.dataSet.previousCompleted[flowBotPast]);
                if (this.sections.COMPLETED.previousStats[flowBotPast]) {
                  this.sections.COMPLETED.previousStats[flowBotPast].flow = this.dataSet.previousCompleted[flowBotPast].flow;
                  this.sections.COMPLETED.previousStats[flowBotPast].recurrences = this.adjustPercentage(
                    (this.dataSet.previousCompleted[flowBotPast].recurrences * 100) / prevCompared,
                    2,
                  );
                }
              }
              break;
            /*
            case "HANDOVER":
              this.sections.HANDOVER.label = "HANDOVER";
              if (this.$vuetify.theme.dark) {
                this.sections.HANDOVER.background = "#60a38d";
              } else {
                this.sections.HANDOVER.background = "#43A047";
              }
              this.sections.HANDOVER.avatar = require("../../../assets/img/dashboard/handover.png");
              this.sections.HANDOVER.currentMonth = this.adjustPercentage(this.dataSet.currentStats[i].recurrences, 2);
              this.sections.HANDOVER.previousMonth = this.adjustPercentage(this.dataSet.previousStats[i].recurrences, 2);
              // Current Statistics
              this.sections.HANDOVER.currentStats = [];
              var totHandoverCurrent = this.dataSet.currentStats[i].numericrec;
              for (let flowHandover in this.dataSet.currentHandover) {
                this.sections.HANDOVER.currentStats.push(this.dataSet.currentHandover[flowHandover]);
                if (this.sections.HANDOVER.currentStats[flowHandover]) {
                  this.sections.HANDOVER.currentStats[flowHandover].flow = this.dataSet.currentHandover[flowHandover].flow;
                  this.sections.HANDOVER.currentStats[flowHandover].recurrences = this.adjustPercentage(
                    (this.dataSet.currentHandover[flowHandover].recurrences * 100) / totHandoverCurrent,
                    2,
                  );
                }
              }
              // previous Month Statistics
              this.sections.HANDOVER.previousStats = [];
              var prevHandover = this.dataSet.previousStats[i].numericrec;
              for (let flowHandoverPast in this.dataSet.previousHandover) {
                this.sections.HANDOVER.previousStats.push(this.dataSet.previousHandover[flowHandoverPast]);
                if (this.sections.HANDOVER.previousStats[flowHandoverPast]) {
                  this.sections.HANDOVER.previousStats[flowHandoverPast].flow = this.dataSet.previousHandover[flowHandoverPast].flow;
                  this.sections.HANDOVER.previousStats[flowHandoverPast].recurrences = this.adjustPercentage(
                    (this.dataSet.previousHandover[flowHandoverPast].recurrences * 100) / prevHandover,
                    2,
                  );
                }
              }
              break;
              */
            case "TIMEOUT":
              this.sections.TIMEOUT.label = "TIMEOUT";
              if (this.$vuetify.theme.dark) {
                this.sections.TIMEOUT.background = "#ffa973";
              } else {
                this.sections.TIMEOUT.background = "#FB8C00";
              }
              this.sections.TIMEOUT.avatar = require("../../../assets/img/dashboard/timeout.png");
              this.sections.TIMEOUT.currentMonth = this.adjustPercentage(this.dataSet.currentStats[i].recurrences, 2);
              this.sections.TIMEOUT.previousMonth = this.adjustPercentage(this.dataSet.previousStats[i].recurrences, 2);
              // Current Month Statistics
              this.sections.TIMEOUT.currentStats = [];
              var timeoutCurrent = null;
              timeoutCurrent = this.dataSet.currentStats[i].numericrec;
              for (let flowTimeout in this.dataSet.currentTimeout) {
                this.sections.TIMEOUT.currentStats.push(this.dataSet.currentTimeout[flowTimeout]);
                if (this.sections.TIMEOUT.currentStats[flowTimeout]) {
                  this.sections.TIMEOUT.currentStats[flowTimeout].flow = this.dataSet.currentTimeout[flowTimeout].flow;
                  this.sections.TIMEOUT.currentStats[flowTimeout].recurrences = this.adjustPercentage(
                    (this.dataSet.currentTimeout[flowTimeout].recurrences * 100) / timeoutCurrent,
                    2,
                  );
                }
              }
              // Previous Month Statistics
              this.sections.TIMEOUT.previousStats = [];
              var timeoutPrev = this.dataSet.previousStats[i].numericrec;
              for (let flowTimeoutPast in this.dataSet.previousTimeout) {
                this.sections.TIMEOUT.previousStats.push(this.dataSet.previousTimeout[flowTimeoutPast]);
                if (this.sections.TIMEOUT.previousStats[flowTimeoutPast]) {
                  this.sections.TIMEOUT.previousStats[flowTimeoutPast].flow = this.dataSet.previousTimeout[flowTimeoutPast].flow;
                  this.sections.TIMEOUT.previousStats[flowTimeoutPast].recurrences = this.adjustPercentage(
                    (this.dataSet.previousTimeout[flowTimeoutPast].recurrences * 100) / timeoutPrev,
                    2,
                  );
                }
              }

              break;
            case "ERROR":
              this.sections.ERROR.label = "ERROR";
              if (this.$vuetify.theme.dark) {
                this.sections.ERROR.background = "#ff5252";
              } else {
                this.sections.ERROR.background = "#E53935";
              }
              this.sections.ERROR.avatar = require("../../../assets/img/dashboard/error.png");
              this.sections.ERROR.currentMonth = this.adjustPercentage(this.dataSet.currentStats[i].recurrences, 2);
              this.sections.ERROR.previousMonth = this.adjustPercentage(this.dataSet.previousStats[i].recurrences, 2);
              // Current Statistics
              this.sections.ERROR.currentStats = [];
              var totErrorCurrent = this.dataSet.currentStats[i].numericrec;
              for (let flowError in this.dataSet.currentError) {
                this.sections.ERROR.currentStats.push(this.dataSet.currentError[flowError]);
                if (this.sections.ERROR.currentStats[flowError]) {
                  this.sections.ERROR.currentStats[flowError].flow = this.dataSet.currentError[flowError].flow;
                  this.sections.ERROR.currentStats[flowError].recurrences = this.adjustPercentage(
                    (this.dataSet.currentError[flowError].recurrences * 100) / totErrorCurrent,
                    2,
                  );
                }
              }
              // Previous Month Statistics
              this.sections.ERROR.previousStats = [];
              var totError = this.dataSet.previousStats[i].numericrec;
              for (let flowErrorPast in this.dataSet.previousError) {
                this.sections.ERROR.previousStats.push(this.dataSet.previousError[flowErrorPast]);
                if (this.sections.ERROR.previousStats[flowErrorPast]) {
                  this.sections.ERROR.previousStats[flowErrorPast].flow = this.dataSet.previousError[flowErrorPast].flow;
                  this.sections.ERROR.previousStats[flowErrorPast].recurrences = this.adjustPercentage(
                    (this.dataSet.previousError[flowErrorPast].recurrences * 100) / totError,
                    2,
                  );
                }
              }
              break;
          }
        }
      } else {
        this.createSectionsWithNAData();
      }
      return this.sections;
    },

    //Nel caso in cui non ci sia il db reader attivo e non ci siano dati per le widget
    createSectionsWithNAData: function () {
      this.sections.COMPLETED.label = "COMPLETED";
      if (this.$vuetify.theme.dark) {
        this.sections.COMPLETED.background = "#1D9096";
      } else {
        this.sections.COMPLETED.background = "#1E88E5";
      }
      this.sections.COMPLETED.avatar = require("../../../assets/img/dashboard/fullbot.png");
      this.sections.COMPLETED.currentMonth = "n/a";
      this.sections.COMPLETED.previousMonth = "n/a";
      this.sections.COMPLETED.currentStats = [];
      this.sections.COMPLETED.previousStats = [];
      this.sections.TIMEOUT.label = "TIMEOUT";
      if (this.$vuetify.theme.dark) {
        this.sections.TIMEOUT.background = "#ffa973";
      } else {
        this.sections.TIMEOUT.background = "#FB8C00";
      }
      this.sections.TIMEOUT.avatar = require("../../../assets/img/dashboard/timeout.png");
      this.sections.TIMEOUT.currentMonth = "n/a";
      this.sections.TIMEOUT.previousMonth = "n/a";
      // Current Month Statistics
      this.sections.TIMEOUT.currentStats = [];
      this.sections.TIMEOUT.previousStats = [];

      this.sections.ERROR.label = "ERROR";
      if (this.$vuetify.theme.dark) {
        this.sections.ERROR.background = "#ff5252";
      } else {
        this.sections.ERROR.background = "#E53935";
      }
      this.sections.ERROR.avatar = require("../../../assets/img/dashboard/error.png");
      this.sections.ERROR.currentMonth = "n/a";
      this.sections.ERROR.previousMonth = "n/a";
      // Current Statistics
      this.sections.ERROR.currentStats = [];
      // Previous Month Statistics
      this.sections.ERROR.previousStats = [];
    },
    adjustPercentage: function (percentage, decimal) {
      if (typeof percentage == "string" && percentage.indexOf("%") != -1) return percentage;
      return Math.round(percentage * Math.pow(10, decimal)) / Math.pow(10, 2) + "%";
    },
    showDetails: function (section, month, current) {
      if (!this.company) {
        this.dataDetails = section;
        this.dataDetails.month = month;
        this.dataDetails.current = current;
        if (current) {
          if (this.dataDetails.currentStats.length >= 1) {
            this.overlay = true;
          }
        } else {
          if (this.dataDetails.previousStats.length >= 1) {
            this.overlay = true;
          }
        }
      }
    },
    canOpenFlow() {
      return this.hasRequiredRoleAndPermission(this.$store.getters.getRoles.SUPERVISOR, "ReadOnlyFlows");
    },
    getBotFromFlow: function (flow, version) {
      let result = {
        flow: flow,
        version: version,
        bot_id: "",
      };
      for (let i = 0; i < this.botList.length; i++) {
        if (flow == this.botList[i].flow && version == this.botList[i].version) {
          result.bot_id = this.botList[i].bot_id;
          break;
        }
      }
      this.openFlowDesigner(result);
    },

    openFlowDesigner: function (flow) {
      openFD.openNewWindow(flow.bot_id, flow.flow, flow.version);
    },
    getCurrentStats: function () {
      return this.dataDetails.currentStats;
    },
    getPreviousStats: function () {
      return this.dataDetails.previousStats;
    },
    refresh: function () {
      this.request();
      this.disableReload = true;
      setTimeout(() => {
        this.disableReload = false;
      }, 600000);
    },
  },
};
</script>

<style>
.companyWidgetOverlay div.v-overlay__content {
  width: 100%;
  height: 100%;
}
.companyEfficiencyWidget .efficiencyButton span.v-btn__content {
  display: block;
}
.theme--dark div.v-overlay.companyWidgetOverlay div.v-list.companyEfficiencyList {
  background: #263238;
}
.companyEfficiencyWidget .efficiencyButton {
  width: 100% !important;
  height: 100% !important;
}
.companyEfficiencyWidget .efficiencyButton .v-btn__content {
  display: block !important;
}
.companyEfficiencyWidget .v-overlay div.v-overlay__content {
  width: 100% !important;
  height: 100% !important;
}
.companyEfficiencyWidget .psCompanyEfficiencyWidget {
  height: 390px;
}
.theme--light.v-application .companyEfficiencyWidget .v-list-item.v-item--active.v-list-item--active {
  color: black !important;
}
.theme--dark.v-application .companyEfficiencyWidget .v-list-item.v-item--active.v-list-item--active {
  color: white !important;
}
.companyEfficiencyWidget div.v-list-item__content {
  text-align: left;
}
</style>
