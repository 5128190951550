<template>
  <v-form ref="botForm" v-model="form.isValid" class="botForm">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteBotConfirm" @dialogDeny="showConfirm = false" />
      <ConfirmDialog :showConfirm="showConfirmFlow" :message="deleteFlowMessage" @dialogConfirm="onDeleteFlowConfirm" @dialogDeny="showConfirmFlow = false" />

      <InputTextDialog :showDiaglog="showCloneDialog" @dialogSubmit="cloneFlowConfirm" @dialogDeny="showCloneDialog = false" />

      <v-row :class="getFormRowMargin">
        <!-- colonna proprietà bot -->
        <v-col v-if="editedBot.bot_id !== '0'" cols="12" sm="6" :class="getFormColMargin">
          <v-row :class="getFormRowMargin">
            <!-- avatar -->
            <v-col v-if="editedBot.icon" cols="12" :class="getFormColMargin" align="center" style="position: relative">
              <!-- selectors avatar arrows bot -->
              <div v-if="isSystemCustomFlowIcon" id="colorsSelectionBots">
                <div class="botColors topArrow" @click="selectAvatar(true)">
                  <img :src="require('../../assets/img/botsNew/arrowTop.png')" style="height: 10px">
                </div>
                <div class="botColors bottomArrow" @click="selectAvatar(false)">
                  <img :src="require('../../assets/img/botsNew/arrowBottom.png')" style="height: 10px">
                </div>
              </div>

              <v-avatar tile size="130px" style="border-radius: 50% !important" class="botAvatar elevation-2">
                <img v-if="!isSystemCustomFlowIcon" alt="Avatar" :src="$store.getters.getSystemBotIcon(editedBot.icon, 'big', editedBot.isVisualIvr)">
                <img v-else alt="Avatar" :src="$store.getters.getBotCustomTemplate.images[ownFlowColorsIndex].image.big">
              </v-avatar>
              <image-uploader
                v-if="hasRequiredRole($store.getters.getRoles.ADMIN)"
                :id="getFileInputId()"
                :preview="false"
                class="fileinput imageUploader"
                capture="false"
                :debug="0"
                accept=".gif, .jpg, .jpeg, .png, .bmp"
                :max-width="100"
                :max-height="100"
                :maxSize="0.169"
                :quality="1"
                :autoRotate="false"
                outputFormat="string"
                @input="setImage"
              >
                <!-- Le immagini che superano maxSize (:maxSize = width * height / 1000) o le dimensioni (130px), vengono ridimensionate automaticamente dalla libreria prima del caricamento -->
                <label slot="upload-label" :for="getFileInputId()" style="display: inline-flex">
                  <figure style="width: 25px">
                    <svg class="avatarImageSVG" style="width: 24px; height: 24px" viewBox="0 0 24 24">
                      <path
                        :fill="isDarkMode ? '#FAFAFA' : '#616161'"
                        d="M22.7 14.3L21.7 15.3L19.7 13.3L20.7 12.3C20.8 12.2 20.9 12.1 21.1 12.1C21.2 12.1 21.4 12.2 21.5 12.3L22.8 13.6C22.9 13.8 22.9 14.1 22.7 14.3M13 19.9V22H15.1L21.2 15.9L19.2 13.9L13 19.9M21 5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11V19.1L12.1 18H5L8.5 13.5L11 16.5L14.5 12L16.1 14.1L21 9.1V5Z"
                      />
                    </svg>
                  </figure>
                </label>
              </image-uploader>
              <div class="deleteIcon">
                <v-icon size="25" @click="deleteImage">
                  mdi-delete-circle
                </v-icon>
              </div>
            </v-col>
            <!-- bot id -->
            <v-col cols="12" :class="getFormColMargin">
              <v-text-field
                v-model="editedBot.bot_id"
                readonly
                disabled
                label="UUID"
                :autocomplete="$store.getters.disableAutocomplete"
                prepend-inner-icon="mdi-code-braces"
              />
            </v-col>
            <!-- bot name -->
            <v-col cols="12" :class="getFormColMargin" class="pt-0">
              <v-text-field
                v-model.trim="editedBot.name"
                label="Bot Name"
                :rules="[
                  requiredField,
                  simpleAlphaNumString,
                  isBotNameUnique(bots, editedBot.name, editedBot.bot_id),
                  charsGreaterThanAndLessThan(editedBot.name, 3, 255),
                ]"
                prepend-inner-icon="mdi-robot"
                :autocomplete="$store.getters.disableAutocomplete"
                :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN)"
              />
            </v-col>
            <!-- initial flow -->
            <v-col cols="12" :class="getFormColMargin" class="pt-0">
              <v-autocomplete
                v-model="editedBot.initialFlow"
                label="Initial Flow"
                :items="editedBot.flows"
                :rules="[requiredField]"
                return-object
                prepend-inner-icon="mdi-flag"
                :filter="advancedAutocompleteFlowFilter"
                :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN) || editedBot.isOutbound"
              >
                <template slot="selection" slot-scope="data">
                  Flow:&nbsp;
                  <strong class="px-1">{{ data.item.flow }}</strong>&nbsp;with version:&nbsp;
                  <strong class="px-1">{{ data.item.version }}</strong>
                </template>
                <template slot="item" slot-scope="data">
                  <span :class="$vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--darken-3'">
                    Flow:&nbsp;
                    <strong class="px-1">{{ data.item.flow }}</strong>&nbsp;with version:&nbsp;
                    <strong class="px-1">{{ data.item.version }}</strong>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- restart hook -->
            <v-col cols="12" :class="getFormColMargin" class="pt-0">
              <v-autocomplete
                v-model="editedBot.restartNode"
                label="Restart Hook"
                :items="getAvailableBotHooks"
                :rules="[requiredField]"
                return-object
                prepend-inner-icon="mdi-restart"
                :filter="advancedAutocompleteFlowFilter"
                :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN) || editedBot.isOutbound"
              >
                <template slot="selection" slot-scope="data">
                  Flow:&nbsp;
                  <strong class="px-1">{{ data.item.flow }}</strong>&nbsp;( <strong class="px-1">{{ data.item.version }}</strong>)&nbsp;and hook&nbsp;
                  <strong class="px-1">{{ data.item.hook }}</strong>
                </template>
                <template slot="item" slot-scope="data">
                  <span :class="$vuetify.theme.dark ? 'grey--text text--lighten-3' : 'grey--text text--darken-3'">
                    Flow:&nbsp;
                    <strong class="px-1">{{ data.item.flow }}</strong>&nbsp;( <strong class="px-1">{{ data.item.version }}</strong>)&nbsp;and hook&nbsp;
                    <strong class="px-1">{{ data.item.hook }}</strong>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- enhanced security e bottoni -->
            <v-col v-if="!editedBot.isOutbound" cols="12" sm="6" md="4" :class="getFormColMargin">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-switch
                    v-model="editedBot.enhancedSecurity"
                    color="primary"
                    class="float-left mt-n2"
                    label="Enhanced Security"
                    :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN)"
                    v-on="on"
                  />
                </template>
                <span>If checked, the bot will be protected from CSFR attacks.</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="!editedBot.isOutbound" cols="12" sm="6" md="4" :class="getFormColMargin">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-switch
                    v-model="editedBot.enableRegistry"
                    color="primary"
                    class="float-left mt-n2"
                    label="Enable customer history"
                    :disabled="!hasRequiredRole($store.getters.getRoles.ADMIN)"
                    v-on="on"
                  />
                </template>
                <span>If unchecked, the bot will not try to reconcile the customer registry.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pb-0" :class="editedBot.isOutbound ? 'pt-10' : ''">
              <v-btn
                v-if="hasRequiredRole($store.getters.getRoles.ADMIN)"
                color="success"
                class="mr-2"
                :loading="form.submitLoading"
                :disabled="!form.isValid"
                @click.prevent="updateBot"
              >
                <v-icon left>
                  mdi-floppy
                </v-icon>Update
              </v-btn>
              <v-btn v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" class="mr-2" color="primary" :to="'/connectors?q=' + editedBot.name + '&a=new'">
                <v-icon left>
                  mdi-upload
                </v-icon>Publish
              </v-btn>

              <v-btn
                v-if="hasRequiredRole($store.getters.getRoles.ADMIN)"
                color="error"
                :loading="form.deleteLoading"
                :disabled="form.submitLoading"
                @click.prevent="showConfirm = true"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>Delete Bot
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!-- colonna dell'elenco flussi -->
        <v-col
          cols="12"
          sm="6"
          :class="getFormColMargin"
          :style="$vuetify.breakpoint.smAndUp && editedBot.bot_id != '0' ? { 'border-left': '1px solid rgba(0,0,0,.12)' } : {}"
        >
          <!--intestazione e search -->
          <v-row no-gutters>
            <v-col cols="6">
              <strong class="headline">Flows</strong>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="searchString"
                dense
                hide-details
                outlined
                label="Search Flow..."
                prepend-inner-icon="search"
                :autocomplete="$store.getters.disableAutocomplete"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4" class="mb-n4">
              <v-select
                v-model="sortFlowsSelected"
                dense
                :items="sortFlowTypes"
                :prepend-icon="sortAsc ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                @click:prepend="changeSortDirection()"
                @change="updateSort()"
              />
            </v-col>
          </v-row>
          <!--lista -->
          <perfect-scrollbar class="botScrollbar" :options="$store.getters.getDefaultScrollProps">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(flowObj, i) in getFlowList"
                  :key="i"
                  @click="hasRequiredRole($store.getters.getRoles.SUPERVISOR) ? editFlow(flowObj) : viewFlow(flowObj)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon v-if="editedBot.bot_id != '0' && isEntryFlow(flowObj)">
                        mdi-star
                      </v-icon>
                      {{ flowObj.flow }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="flowObj.version" />
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ getLastUpdate(flowObj) | formatVerboseDateTime }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon @click.stop="() => {}">
                    <v-menu v-model="moveToFlowMenuStatus[i]" right offset-x :close-on-content-click="false">
                      <template #activator="{ on: menu }">
                        <v-btn text icon tile :class="isEntryFlow(flowObj) ? 'mt-1' : ''" v-on="{ ...menu }">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR)" @click="editFlow(flowObj)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="viewFlow(flowObj)">
                          <v-list-item-title>View</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openInNweTab(flowObj)">
                          <v-list-item-title>Open in new tab</v-list-item-title>
                        </v-list-item>
                        <v-menu
                          v-if="hasRequiredRole($store.getters.getRoles.ADMIN) && (!isEntryFlow(flowObj) || editedBot.bot_id == '0')"
                          right
                          open-on-click
                          offset-x
                        >
                          <template #activator="{ on: menu2 }">
                            <v-list-item>
                              <v-list-item-title v-on="{ ...menu2 }">
                                Move to...
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <perfect-scrollbar class="botScrollbar" :options="$store.getters.getDefaultScrollProps" style="height: 370px">
                            <v-list min-height="370">
                              <v-text-field
                                v-model="searchBotString"
                                class="ma-1 mr-3"
                                dense
                                hide-details
                                outlined
                                label="Search Bot..."
                                prepend-inner-icon="search"
                                @click.stop="() => {}"
                              />
                              <v-list-item v-if="editedBot.bot_id != '0'" @click="moveFlowTo(flowObj, '0')">
                                <v-list-item-title>Unassigned Flows</v-list-item-title>
                              </v-list-item>
                              <v-list-item v-for="targetBot in getFilteredBotList" :key="targetBot.bot_id" @click="moveFlowTo(flowObj, targetBot.bot_id)">
                                <v-list-item-title>{{ targetBot.name }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </perfect-scrollbar>
                        </v-menu>

                        <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ADMIN) && !isOutboundEntryFlow(flowObj)" @click="cloneFlow(flowObj)">
                          <v-list-item-title>Clone</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="testFlow(flowObj)">
                          <v-list-item-title>Test</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="(!isEntryFlow(flowObj) || (i == 0 && editedBot.bot_id == '0')) && hasRequiredRole($store.getters.getRoles.ADMIN)"
                          @click="deleteFlow(flowObj)"
                        >
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </perfect-scrollbar>
          <!-- modale creazione flusso -->

          <v-btn v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" class="ml-4 mb-3" color="primary" @click="newFlow.dialog = true">
            <v-icon left>
              mdi-plus
            </v-icon>Add new flow
          </v-btn>

          <v-dialog v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" v-model="newFlow.dialog" width="500" persistent>
            <v-card>
              <v-card-title class="headline primary white--text" primary-title>
                New Flow Name
              </v-card-title>
              <v-card-text>
                <v-form v-model="newFlow.form" @keypress.enter.prevent="">
                  <v-text-field
                    v-model.trim="newFlow.name"
                    label="Flow Name"
                    class="mt-4"
                    :rules="[requiredField, validateFlowName]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    @keypress.enter.prevent="openFlowDesigner"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="6" class="px-2">
                    <v-btn
                      block
                      outlined
                      color="primary"
                      :loading="newFlow.formSubmitted"
                      :disabled="!newFlow.form"
                      @click.prevent="openFlowDesigner"
                    >
                      <v-icon left>
                        mdi-pencil
                      </v-icon>Start Design
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="error" @click.prevent="newFlow.dialog = false">
                      <v-icon left>
                        mdi-close
                      </v-icon>Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";
import openFD from "../../helpers/openFD";
import InputTextDialog from "../../components/other/InputTextDialog";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "BotForm",
  components: {
    ConfirmDialog,
    InputTextDialog,
  },
  props: {
    "bot": {
      type: Object,
      required: true
    },
    "flowsTargetsBot": {
      type: Object,
      required: true
    },
    "bots": {
      type: Object,
      required: true
    },
    "flowList": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //Dialog creazione flusso
      newFlow: {
        dialog: false,
        name: "",
        form: false,
        formSubmitted: false,
      },
      searchBotString: "",
      forceRerender: 0,
      //Flag visualizzazione menu hamburger di ogni flusso
      moveToFlowMenuStatus: [],

      //Flag per dialog di richiesta nome clone del flusso
      showCloneDialog: false,
      cloneDialogParams: null,

      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Bot " + this.bot.name + " will be deleted!",
      showConfirmFlow: false,
      deleteFlowMessage: "Flow will be deleted!",
      deleteFlowParams: null,

      //Search flow
      searchString: "",
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedBot: {},
      sortFlowsSelected: 0,
      sortAsc: true,
      sortFlowTypes: [
        {
          text: "standard",
          value: 0,
        },
        {
          text: "name",
          value: 1,
        },
        {
          text: "last update",
          value: 2,
        },
      ],
      isSystemCustomFlowIcon: false,
      ownFlowColorsIndex: 0,
      ownFlowColorslenght: this.$store.getters.getBotCustomTemplate.images.length - 1,
      botIconUploaded: false,
      saveOldIcon: "",
    };
  },
  computed: {
    ...spacing,
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    getFilteredBotList() {
      if (this.bots) {
        return Object.values(this.bots)
          .filter((bot) => bot.name.toLowerCase().indexOf(this.searchBotString.toLowerCase()) !== -1 && bot.name !== this.editedBot.name)
          .sort((a, b) => {
            //Ordinamento normale
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
      }
      return [];
    },
    getFlowList() {
      this.forceRerender;
      if (this.editedBot.flows && this.editedBot.flows.length > 0) {
        return this.editedBot.flows
          .filter((flowObj) => flowObj.flow.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
          .sort((a, b) => {
            //Check flusso iniziale per primo
            if (this.sortFlowsSelected === 0) {
              if (a.flow === this.editedBot.flow && a.version === this.editedBot.version) {
                return -1;
              }
              if (b.flow === this.editedBot.flow && b.version === this.editedBot.version) {
                return 1;
              }
            }
            //Ordinamento normale
            if (this.sortFlowsSelected === 1) {
              if (this.sortAsc) {
                if (a.flow.toLowerCase() > b.flow.toLowerCase()) {
                  return 1;
                }
                if (a.flow.toLowerCase() < b.flow.toLowerCase()) {
                  return -1;
                }
              } else {
                if (a.flow.toLowerCase() < b.flow.toLowerCase()) {
                  return 1;
                }
                if (a.flow.toLowerCase() > b.flow.toLowerCase()) {
                  return -1;
                }
              }
              return 0;
            } else {
              let lastUpdateA = this.getLastUpdate(a);
              let lastUpdateB = this.getLastUpdate(b);
              if (this.sortAsc) {
                if (lastUpdateA > lastUpdateB) {
                  return 1;
                }
                if (lastUpdateA < lastUpdateB) {
                  return -1;
                } else {
                  return 0;
                }
              } else {
                if (lastUpdateA < lastUpdateB) {
                  return 1;
                }
                if (lastUpdateA > lastUpdateB) {
                  return -1;
                } else {
                  return 0;
                }
              }
            }
          });
      }
      return [];
    },
    getAvailableBotHooks() {
      let result = [];
      if (this.flowsTargetsBot && this.flowsTargetsBot[this.bot.bot_id]) {
        let availableHooks = this.flowsTargetsBot[this.bot.bot_id];
        if (availableHooks) {
          availableHooks.forEach((hookConf) => {
            hookConf.hook.forEach((hookName) => {
              result.push({
                flow: hookConf.flow,
                version: hookConf.version,
                hook: hookName,
              });
            });
          });
        }
      }
      return result;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.MOVE_FLOW, this.handleRerender);
    this.editedBot = merge({}, this.bot);
    this.editedBot.initialFlow = this.editedBot.flows.find((f) => f.flow === this.editedBot.flow && f.version === this.editedBot.version);

    setTimeout(() => {
      this.$refs.botForm && this.$refs.botForm.validate();
    }, 100);

    if (this.$store.getters.getSetting("flowSortType")) {
      this.sortFlowsSelected = this.$store.getters.getSetting("flowSortType");
      this.sortAsc = this.$store.getters.getSetting("sortAsc");
    }

    if (this.editedBot.enableRegistry === undefined || this.editedBot.enableRegistry === null) {
      this.editedBot.enableRegistry = true;
    }
    this.saveOldIcon = this.editedBot.icon;
    await this.convertOldIconset(); // mi serve aggiornare on the fly i bot cosi da salvare l'immagine nuova di system in caso di update
    this.checkifSystemMultyAvatars();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.MOVE_FLOW, this.handleRerender);
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    deleteImage() {
      let type = "custom";
      if (this.editedBot.isVisualIvr) {
        type = "visualivr";
      } else if (this.editedBot.isOutbound) {
        type = "outbound";
      } else if (this.editedBot.template) {
        type = this.editedBot.template;
      }
      const typeIconSet = this.$store.getters.getBotCustomTemplatebyType(type);
      this.editedBot.icon = typeIconSet.key || typeIconSet.images[0].key;
      this.checkifSystemMultyAvatars();
    },
    async convertOldIconset() {
      // converto le vecchie icone in nuove in caso di update le aggiorno
      // nel caso di bot outbound questo metodo è superfluo perchè è già con le icone nuove
      const ivrKey = this.$store.getters.getBotTemplates[this.$store.getters.getBotTemplates.findIndex((template) => template.type === "visualivr")].key;
      if (!this.editedBot.template) {
        if (this.$store.state.enums.botAvatar === this.editedBot.icon) {
          if (!this.editedBot.isVisualIvr) {
            this.editedBot.icon = this.$store.getters.getBotCustomTemplate.images[0].key;
          } else {
            this.editedBot.icon = ivrKey;
          }
        }
      } else {
        // è di tipo template
        const checktemplate = this.$store.state.enums.customerInterfaceLogoPreset.find(
          (file) => "data:image/png;base64," + file.base64 === this.editedBot.icon,
        );
        if (checktemplate) {
          this.editedBot.icon = checktemplate.key;
        }
      }
    },
    checkif_SystemIcon_isCustomFlow(icon) {
      if (icon) return icon.includes("System_123EuD45_Custom") || false;
      return false;
    },
    checkifSystemMultyAvatars() {
      if (!this.editedBot.template) {
        // se non è un template è di tipo Custom  e quindi ha più set di colori
        // controllo se è un icona di "system Custom" oppure se inserita dall'utente
        if (!this.editedBot.isVisualIvr && !this.editedBot.isOutbound && this.checkif_SystemIcon_isCustomFlow(this.editedBot.icon)) {
          this.ownFlowColorsIndex = this.$store.getters.getBotCustomTemplate.images.findIndex((im) => im.key === this.editedBot.icon);
          this.isSystemCustomFlowIcon = true;
        }
      }
    },
    selectAvatar(up) {
      if (up) {
        if (this.ownFlowColorsIndex <= 0) {
          this.ownFlowColorsIndex = this.ownFlowColorslenght;
        } else {
          this.ownFlowColorsIndex--;
        }
      } else {
        if (this.ownFlowColorsIndex >= this.ownFlowColorslenght) {
          this.ownFlowColorsIndex = 0;
        } else {
          this.ownFlowColorsIndex++;
        }
      }
    },
    isEntryFlow(flowObj) {
      return this.bot.flow === flowObj.flow && this.bot.version === flowObj.version;
    },
    isOutboundEntryFlow(flowObj) {
      return this.bot.isOutbound && this.bot.flow === flowObj.flow && this.bot.version === flowObj.version;
    },
    handleRerender() {
      for (let i = 0; i < this.moveToFlowMenuStatus.length; i++) {
        this.moveToFlowMenuStatus[i] = false;
      }
      setTimeout(() => {
        this.editedBot = merge({}, this.bot);
        this.editedBot.initialFlow = {
          flow: this.editedBot.flow,
          version: this.editedBot.version,
        };
        this.forceRerender++;
      }, 100);
    },
    async moveFlowTo(flowObj, targetBotId) {
      let opts = {
        version: flowObj.version,
        flow: flowObj.flow,
        newBotId: targetBotId,
        oldBotId: this.bot.bot_id,
      };
      try {
        await this.$http.post("/bot/move", opts);
        EventBus.$emit(this.$store.getters.getEvents.MOVE_FLOW);
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.MOVE_FLOW_FAILED, {
          flow: flowObj.flow,
          message: "Fail to move flow!",
        });
      }
    },
    cloneFlow(flowObj) {
      this.showCloneDialog = true;
      this.cloneDialogParams = flowObj;
    },
    async cloneFlowConfirm(inputValue) {
      this.showCloneDialog = false;
      if (this.cloneDialogParams) {
        try {
          await this.$http.post(`/flow/clone/${this.editedBot.bot_id}/${this.cloneDialogParams.flow}/${this.cloneDialogParams.version}`, {
            flow: inputValue,
          });
          if (this.editedBot.flows) {
            this.editedBot.flows.push({
              flow: inputValue,
              version: this.cloneDialogParams.version,
            });
          }
          EventBus.$emit(this.$store.getters.getEvents.CLONE_FLOW);
        } catch (err) {
          EventBus.$emit(this.$store.getters.getEvents.CLONE_FLOW_FAILED, {
            flow: this.cloneDialogParams,
            message: "Fail to clone flow!",
          });
        }
      }
    },
    async testFlow(flowObj) {
      this.closeAllMenus();
      try {
        let response = await this.$http.get("/flow/" + flowObj.bot_id + "/" + flowObj.flow + "/" + flowObj.version);
        // Questo if serve per sanificare i template
        if (response.data.flow[flowObj.flow].lang.toLowerCase() === "english" || response.data.flow[flowObj.flow].lang.toLowerCase() === "italian") {
          flowObj.lang = response.data.flow[flowObj.flow].lang.toLowerCase().slice(0, 2);
        } else {
          flowObj.lang = response.data.flow[flowObj.flow].lang;
        }
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.TEST_FLOW_FAILED, {
          flow: flowObj.flow,
          message: "Cannot test flow" + flowObj.flow,
        });
      } finally {
        flowObj.bot_id = this.editedBot.bot_id;
        EventBus.$emit(this.$store.getters.getEvents.OPEN_DEBUG_INTERFACE, flowObj);
      }
    },
    editFlow(flowObj) {
      openFD.open(this.editedBot.bot_id, flowObj.flow, flowObj.version, null, false);
    },
    openInNweTab(flowObj) {
      openFD.openInNewTab(this.editedBot.bot_id, flowObj.flow, flowObj.version, null, false);
    },
    viewFlow(flowObj) {
      openFD.open(this.editedBot.bot_id, flowObj.flow, flowObj.version, null, true);
    },
    deleteFlow(flowObj) {
      this.deleteFlowMessage = "Flow " + flowObj.flow + " will be deleted!";
      this.deleteFlowParams = flowObj;
      this.showConfirmFlow = true;
    },
    async onDeleteFlowConfirm() {
      this.showConfirmFlow = false;
      if (this.deleteFlowParams) {
        try {
          await this.$http.delete(`/flow/${this.editedBot.bot_id}/${this.deleteFlowParams.flow}/${this.deleteFlowParams.version}`);
          this.editedBot.flows = this.editedBot.flows.filter((flowObj) => {
            return flowObj.flow !== this.deleteFlowParams.flow || flowObj.version !== this.deleteFlowParams.version;
          });
          EventBus.$emit(this.$store.getters.getEvents.DELETE_FLOW, {
            flow: this.deleteFlowParams,
          });
        } catch (err) {
          EventBus.$emit(this.$store.getters.getEvents.DELETE_FLOW_FAILED, {
            flow: this.deleteFlowParams,
            message: "Fail to delete flow!",
          });
        }
      }
    },
    getFileInputId() {
      return "fileInput_" + this.editedBot.bot_id;
    },
    async openFlowDesigner() {
      //controllo se esiste già o meno il flusso
      if (this.newFlow.form) {
        try {
          await this.$http.get("/flow/" + this.editedBot.bot_id + "/" + this.newFlow.name + "/" + this.editedBot.name);
          EventBus.$emit(this.$store.getters.getEvents.FLOW_ALREADY_EXISTS, {
            flow: this.newFlow.name,
            message: "Flow " + this.newFlow.name + " with version " + this.editedBot.name + " already exists",
          });
        } catch (e) {
          //se mi risponde 404 vuol dire che non esiste e posso crearlo
          this.newFlow.formSubmitted = true;
          openFD.open(this.editedBot.bot_id, this.newFlow.name, this.editedBot.name);
        }
      }
    },
    async onDeleteBotConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/bot/" + this.editedBot.bot_id);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_BOT, this.editedBot);
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_BOT_FAIL, {
          message: "Fail to delete bot " + this.editedBot.name,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async updateBot() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        this.editedBot.flow = this.editedBot.initialFlow.flow;
        this.editedBot.version = this.editedBot.initialFlow.version;
        // update del bot avatar di system se presente
        if (this.editedBot.icon.includes("System_123EuD45_")) {
          // se è di tipo system (e non upload faccio cose)
          if (!this.editedBot.template) {
            // è di tipo custom flow e quindi ha più colori
            if (!this.editedBot.isVisualIvr && !this.editedBot.isOutbound) {
              this.editedBot.icon = this.$store.getters.getBotCustomTemplate.images[this.ownFlowColorsIndex].key;
            } else {
              this.editedBot.icon = this.$store.getters.getBotTemplates.find((template) => template.key && template.key === this.editedBot.icon).key;
            }
          } else {
            this.editedBot.icon = this.$store.getters.getBotTemplates.find((template) => template.key && template.key === this.editedBot.icon).key;
          }
        }
        // save.
        try {
          this.editedBot.isOutbound = this.editedBot?.isOutbound || false;
          const response = await this.$http.put("/bot/" + this.editedBot.bot_id, this.editedBot);
          if (response && response.data && response.data.bots) {
            //dal backend mi arriva aggiornato quini il mio edited è quello del backend
            this.editedBot = response.data.bots[this.editedBot.bot_id];
          }
          EventBus.$emit(this.$store.getters.getEvents.EDIT_BOT, response.data);
        } catch (err) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_BOT_FAIL, {
            bot: this.editedBot,
            message: "Fail to update bot: " + err.response.data.error,
          });
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
    setImage(output) {
      this.editedBot.icon = output;
      this.isSystemCustomFlowIcon = false;
    },
    advancedAutocompleteFlowFilter(item, queryText) {
      return (
        item.flow.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.version.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.hook && item.hook.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      );
    },
    closeAllMenus() {
      for (let i = 0; i < this.moveToFlowMenuStatus.length; i++) {
        this.moveToFlowMenuStatus[i] = false;
      }
      this.forceRerender++;
    },
    getLastUpdate(flowObj) {
      if (
        this.flowList &&
        this.flowList[flowObj.flow] &&
        this.flowList[flowObj.flow][flowObj.version] &&
        this.flowList[flowObj.flow][flowObj.version].updated_at
      )
        return this.flowList[flowObj.flow][flowObj.version].updated_at;
      return "-";
    },
    updateSort() {
      this.$store.dispatch("AddSetting", {
        flowSortType: this.sortFlowsSelected,
        sortAsc: this.sortAsc,
      });
      this.forceRerender++;
    },
    changeSortDirection() {
      this.sortAsc = !this.sortAsc;
      this.$store.dispatch("AddSetting", {
        flowSortType: this.sortFlowsSelected,
        sortAsc: this.sortAsc,
      });
      this.forceRerender++;
    },
  },
};
</script>

<style scoped>
.botScrollbar {
  height: 420px;
}
.bottomArrow {
  top: 57px;
}
#colorsSelectionBots {
  top: 64px;
}
.deleteIcon {
  position: relative;
  margin-top: -33px;
  margin-left: 125px;
  width: 25px;
  display: block;
  cursor: pointer;
}
</style>

<style>
.botForm .imageUploader {
  position: relative;
  margin-top: -20px;
  margin-left: -125px;
}
.botForm input[type="file"] {
  display: none;
}
.botForm .avatarImageSVG {
  cursor: pointer;
}
.v-list-item--active:before,
.v-list-item:focus:before {
  opacity: 0 !important;
}
.v-list-item--active:hover:before {
  opacity: 0.12 !important;
}
</style>
