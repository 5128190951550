<template>
  <v-card flat style="height: auto">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="deleteItem(itemToDelete)" />
    <v-alert v-if="configuration.semantic_hooks.semanticHookList.length === 0" class="mt-4" type="warning">
      semantic Hook have not yet been configured
    </v-alert>
    <v-card-text>
      <v-switch v-model="configuration.semantic_hooks.analyze_start_flow" class="mb-2" color="primary" :label="labels.firstMessage" />
      <multiLanguageItem
        :itemObj="configuration.semantic_hooks.start_flow_answer"
        :customLabel="labels.promptedMessage"
        :disabledFlag="!configuration.semantic_hooks.analyze_start_flow"
        itemName="Message"
        @openOverlay="showMultiLanguage(labels.promptedMessage, configuration.semantic_hooks.start_flow_answer)"
      />
      <!-- semantic engine -->
      <v-autocomplete
        v-model="configuration.semantic_hooks.semanticHookEngineName"
        label="Semantic Engine"
        class="mt-0 pa-0"
        clearable
        :items="enginesList"
        :disabled="configuration.semantic_hooks.semanticHookList && configuration.semantic_hooks.semanticHookList.length > 0"
      />
      <v-row justify="end">
        <v-col cols="3" class="pt-0 mt-n2">
          <v-text-field v-model="searchString" append-icon="search" label="Search" single-line hide-details />
        </v-col>
      </v-row>
      <v-data-table
        id="semanticDataTable"
        must-sort
        height="270px"
        :headers="headers"
        :items="configuration.semantic_hooks.semanticHookList"
        :search="searchString"
        class="elevation-1 fixed-header v-table__overflow"
        hide-default-footer
        hide-default-header
        fixed-header
        disable-pagination
      >
        <template #header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="header in headers" :key="header.value" :class="header.class">
                <b v-if="!header.deleteAll">{{ header.text }}</b>
                <span v-else-if="header.deleteAll">
                  <v-btn
                    v-show="configuration.semantic_hooks.semanticHookList && configuration.semantic_hooks.semanticHookList.length > 1"
                    small
                    text
                    color="error"
                    class="hidden-sm-and-down ml-n6"
                    @click.prevent="showConfirmDelete('all')"
                  >
                    <b>Delete All</b>
                  </v-btn>
                  <v-icon class="hidden-md-and-up ma-o pa-0" size="24" @click.prevent="showConfirmDelete(all)">delete_forever</v-icon>
                </span>
              </th>
            </tr>
          </thead>
        </template>
        <template #body="{ items }">
          <draggable v-model="configuration.semantic_hooks.semanticHookList" tag="tbody" handle=".sortHandle">
            <tr v-for="(item, index) in items" :key="index" class="formFiledRow">
              <td class="hidden-sm-and-down text-start">
                <v-btn
                  x-small
                  text
                  color="primary"
                  style="cursor: move"
                  icon
                  class="sortHandle ma-0 pa-0"
                >
                  <v-icon>mdi-cursor-move</v-icon>
                </v-btn>
              </td>
              <td style="max-width: 150px" class="text-left text-truncate">
                <v-tooltip v-if="checkExistingFlowVersion(item)" top>
                  <template #activator="{ on }">
                    <v-icon class="mt-n2 mr-2" color="warning" v-on="on">
                      warning
                    </v-icon>
                  </template>
                  {{ item.target }} - {{ item.version }} - {{ item.hook }} no longer exists
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.intent }}</span>
                  </template>
                  <span>{{ item.intent }}</span>
                </v-tooltip>
              </td>
              <td style="max-width: 150px" class="text-left text-truncate">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.target }}</span>
                  </template>
                  <span>{{ item.target }}</span>
                </v-tooltip>
              </td>
              <td class="text-left text-truncate">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.version }}</span>
                  </template>
                  <span>{{ item.version }}</span>
                </v-tooltip>
              </td>
              <td style="max-width: 100px" class="text-left text-truncate">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.hook }}</span>
                  </template>
                  <span>{{ item.hook }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-layout justify-space-between>
                  <v-btn small color="primary" class="ml-0 hidden-sm-and-down" @click.prevent="openAnotherFlowDesigner(item)">
                    Go to
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <!-- is shown only when the screen is small -->
                  <v-icon class="hidden-md-and-up" size="24" @click.prevent="openAnotherFlowDesigner(item)">
                    open_in_new
                  </v-icon>
                  <v-icon size="24" @click="editItem(item)">
                    edit
                  </v-icon>
                </v-layout>
              </td>
              <td>
                <v-icon size="24" @click="showConfirmDelete(item)">
                  delete
                </v-icon>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-dialog v-model="copyDialog" max-width="500px">
              <template #activator="{ on }">
                <v-btn
                  small
                  color="primary"
                  class="mx-2 my-1"
                  :disabled="configuration.semantic_hooks.semanticHookEngineName != null"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>Copy From FLow
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Copy Semantic Hook From Flow</span>
                </v-card-title>
                <v-card-text style="text-align: left">
                  <span> Notice that this operation will delete all the semantic hooks already configured </span>
                </v-card-text>
                <v-card-text>
                  <v-form ref="copyDialogForm" v-model="isCopyValid" xs12>
                    <v-autocomplete
                      v-model="copyTarget.target"
                      class="ma-0 pa-0"
                      label="Target flow"
                      :items="copyFlowList"
                      :rules="[requiredField]"
                      @change="copyTarget.target ? (copyTarget.version = Object.keys(flowTargets[copyTarget.target])[0]) : ''"
                    />
                    <v-autocomplete v-model="copyTarget.version" class="ma-0 pa-0" label="Version" :rules="[requiredField]" :items="copyVersionList" />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters>
                    <v-col cols="6" class="px-2">
                      <v-btn color="success" block outlined :disabled="!isCopyValid" @click="copyFromFlow">
                        <v-icon left>
                          mdi-floppy
                        </v-icon>Save
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="px-2">
                      <v-btn color="error" block outlined @click="closeCopyDialog">
                        <v-icon left>
                          mdi-close
                        </v-icon>Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="editDialog" max-width="500px">
              <template #activator="{ on }">
                <v-btn
                  right
                  small
                  color="primary"
                  class="my-1 mx-2"
                  :disabled="
                    !configuration.semantic_hooks.semanticHookEngineName ||
                      (configuration.semantic_hooks.semanticHookList && configuration.semantic_hooks.semanticHookList.length === numberOfIntent)
                  "
                  v-on="on"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>Add Semantic Hook
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Semantic Hook</span>
                </v-card-title>
                <v-card-text>
                  <v-form ref="editDialogForm" v-model="isNewItemValid">
                    <v-autocomplete
                      v-model="editedItem.intent"
                      class="ma-0 pa-0"
                      label="Intent"
                      :items="intentList"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      :rules="[requiredField]"
                    />
                    <v-autocomplete
                      v-model="editedItem.target"
                      class="ma-0 pa-0"
                      label="Target flow"
                      :items="Object.keys(flowTargets)"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      :rules="[requiredField]"
                      @change="onChangeFlowSelected()"
                    />
                    <v-autocomplete
                      v-model="editedItem.version"
                      class="ma-0 pa-0"
                      label="Version"
                      :items="editedItem.target && flowTargets[editedItem.target] ? Object.keys(flowTargets[editedItem.target]) : []"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      :rules="[requiredField]"
                      @change="onChangeVersionSelected()"
                    />
                    <v-autocomplete
                      v-model="editedItem.hook"
                      class="ma-0 pa-0"
                      label="Hook"
                      :items="
                        editedItem.version && flowTargets[editedItem.target] && flowTargets[editedItem.target][editedItem.version]
                          ? flowTargets[editedItem.target][editedItem.version].hooks
                          : []
                      "
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      :rules="[requiredField]"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters>
                    <v-col cols="6" class="px-2">
                      <v-btn color="success" block outlined :disabled="!isNewItemValid" @click="saveNewItem">
                        <v-icon left>
                          mdi-floppy
                        </v-icon>Save
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="px-2">
                      <v-btn color="error" block outlined @click="closeDialog">
                        <v-icon left>
                          mdi-close
                        </v-icon>Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card-text>
    <v-overlay absolute opacity="1" :value="overlay" color="overlayMultilanguage" class="overlayMultilanguage">
      <multilanguageConfigurationDialog :itemName="itemName" :itemObj="itemObj" @back="overlay = !$event" />
    </v-overlay>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import merge from "deepmerge";
import draggable from "vuedraggable";
import openFD from "../../../helpers/openFD";
import multilanguageConfigurationDialog from "./MultilanguageConfigurationiDialog";
import ConfirmDialog from "../../../components/other/ConfirmationDialog";
import multiLanguageItem from "../../other/multiLanguageItem";
export default {
  name: "SemanticHooksConfiguration",
  components: {
    draggable,
    multilanguageConfigurationDialog,
    ConfirmDialog,
    multiLanguageItem,
  },
  props: ["configuration", "semanticEngines", "flowTargets", "allBots"],
  data() {
    return {
      showConfirm: false,
      itemToDelete: {},
      deleteMessage: "",
      labels: {
        firstMessage: "Should Convy analyze the first message?",
        promptedMessage: "Once the first message has been recognized, Convy will prompt the following message:",
      },
      itemObj: null,
      itemName: null,
      overlay: false,
      isNewItemValid: false,
      isCopyValid: false,
      headers: [
        {
          text: "",
          value: "drag",
          sortable: false,
          class: "hidden-sm-and-down drag",
        },
        { text: "Intents", value: "intent", sortable: false },
        { text: "Flow", value: "target", sortable: false },
        { text: "Version", value: "version", sortable: false },
        { text: "Hook", value: "hook", sortable: false },
        { text: "Actions", value: "action", sortable: false },
        {
          text: "",
          value: "delete",
          sortable: false,
          deleteAll: true,
          width: "100px",
          class: "ma-0 pa-0",
        },
      ],
      editDialog: false,
      editedIndex: -1,
      editedItem: {
        intent: "",
        target: "",
        version: "",
        hook: "",
      },
      copyTarget: {
        target: "",
        version: "",
      },
      searchString: "",
      copyDialog: false,
    };
  },
  computed: {
    enginesList() {
      if (this.semanticEngines) return this.semanticEngines.map((engine) => engine.name);
      return [];
    },
    numberOfIntent() {
      const engineName = this.configuration.semantic_hooks.semanticHookEngineName;
      if (engineName) {
        let engine = this.semanticEngines.find((engine) => engine.name === engineName);
        if (engine && engine.engines[engineName] && engine.engines[engineName].intents) {
          let intents = Object.keys(engine.engines[engineName].intents);
          if (intents) return intents.length;
        }
      }
      return 0;
    },
    intentList() {
      const engineName = this.configuration.semantic_hooks.semanticHookEngineName;
      let engine = this.semanticEngines.find((engine) => engine.name === engineName);
      if (engineName && engine && engine.engines[engineName].intents) {
        let intents = Object.keys(engine.engines[engineName].intents);
        //Per ogni intento controllo se è contenuto nella lista dei semantic hook utilizzati
        let filteredList = intents.filter((intent) => {
          return !this.configuration.semantic_hooks.semanticHookList.some((semanticHook) => {
            return semanticHook.intent === intent;
          });
        });
        //Aggiunto quello che sto editando, nel caso in cui non volessi cambiarlo
        if (this.editedItem && this.editedItem.intent) {
          filteredList.push(this.editedItem.intent);
        }
        return filteredList;
      }
      return [];
    },
    flowList() {
      return Object.keys(this.flowTargets);
    },
    copyFlowList() {
      let flowListToCopy = [];
      for (let flow in this.flowTargets) {
        for (let version in this.flowTargets[flow]) {
          let keys = Object.keys(this.flowTargets[flow][version].semantic_hooks);
          if (keys.length > 0) {
            if (!this.copyTarget.target) {
              this.updateCopyTarget(flow, version);
            }
            flowListToCopy.push(flow);
          }
        }
      }
      return flowListToCopy;
    },
    copyVersionList() {
      let versionList = [];
      for (let version in this.flowTargets[this.copyTarget.target]) {
        let keys = Object.keys(this.flowTargets[this.copyTarget.target][version].semantic_hooks);
        if (keys.length > 0) {
          versionList.push(version);
        }
      }
      return versionList;
    },
  },
  methods: {
    ...fieldValidators,
    checkExistingFlowVersion(item) {
      return !(
        this.flowTargets &&
        this.flowTargets[item.target] &&
        this.flowTargets[item.target][item.version] &&
        this.flowTargets[item.target][item.version].hooks.indexOf(item.hook) > -1
      );
    },
    onChangeFlowSelected() {
      if (this.editedItem.target) this.editedItem.version = Object.keys(this.flowTargets[this.editedItem.target])[0];
      if (this.editedItem.version) this.editedItem.hook = this.flowTargets[this.editedItem.target][this.editedItem.version].hooks[0];
    },
    onChangeVersionSelected() {
      this.editedItem.hook = this.flowTargets[this.editedItem.target][this.editedItem.version].hooks[0];
    },
    showMultiLanguage(label, data) {
      this.itemName = label;
      this.itemObj = data;
      this.overlay = !this.overlay;
      this.$emit("openOverlay");
    },
    updateCopyTarget(flow, version) {
      this.copyTarget.target = flow;
      this.copyTarget.version = version;
    },
    editItem(item) {
      this.editedIndex = this.configuration.semantic_hooks.semanticHookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },
    showConfirmDelete(item) {
      this.itemToDelete = item;
      if (item === "all") {
        this.deleteMessage = "You will delete ALL the semantic hooks";
      } else {
        this.deleteMessage =
          "You will delete this semantic hook:\n" +
          this.itemToDelete.intent +
          " / " +
          this.itemToDelete.target +
          " / " +
          this.itemToDelete.version +
          " / " +
          this.itemToDelete.hook;
      }
      this.showConfirm = true;
    },
    deleteItem(item) {
      if (item === "all") {
        this.configuration.semantic_hooks.semanticHookList.splice(0, this.configuration.semantic_hooks.semanticHookList.length);
      } else {
        const index = this.configuration.semantic_hooks.semanticHookList.indexOf(item);
        this.configuration.semantic_hooks.semanticHookList.splice(index, 1);
      }
      this.closeDialog();
    },
    closeDialog() {
      this.showConfirm = false;
      this.editDialog = false;
      this.editedIndex = -1;
      this.copyDialog = false;
      this.resetEditedItem();
      if (this.$refs.editDialogForm) {
        this.$refs.editDialogForm.resetValidation();
      }
    },
    saveNewItem() {
      if (this.editedIndex > -1) {
        this.configuration.semantic_hooks.semanticHookList[this.editedIndex].intent = this.editedItem.intent;
        this.configuration.semantic_hooks.semanticHookList[this.editedIndex].target = this.editedItem.target;
        this.configuration.semantic_hooks.semanticHookList[this.editedIndex].version = this.editedItem.version;
        this.configuration.semantic_hooks.semanticHookList[this.editedIndex].hook = this.editedItem.hook;
      } else {
        this.configuration.semantic_hooks.semanticHookList.push(merge({}, this.editedItem));
      }
      this.closeDialog();
    },
    closeCopyDialog() {
      this.$refs.copyDialogForm.resetValidation();
      this.resetCopyItem();
      this.copyDialog = false;
    },
    copyFromFlow() {
      //svuoto tutti i semantic hookList prima di salvare gli altri
      this.deleteItem("all");
      //cambio il nome dell'engine in base al flusso che sono andato a pescare
      this.configuration.semantic_hooks.semanticHookEngineName = Object.keys(
        this.flowTargets[this.copyTarget.target][this.copyTarget.version].semantic_hooks,
      )[0];
      // prendo la nuova lista da importare al posto di quella presente attualmente
      // prendo tutti gli intent
      let obj = this.flowTargets[this.copyTarget.target][this.copyTarget.version].semantic_hooks[this.configuration.semantic_hooks.semanticHookEngineName];
      for (let intent in obj) {
        this.configuration.semantic_hooks.semanticHookList.push({
          target: obj[intent].flow,
          version: obj[intent].version,
          hook: obj[intent].hook,
          intent: intent,
        });
      }
      this.closeCopyDialog();
    },
    resetCopyItem() {
      this.copyTarget = {
        target: "",
        version: "",
      };
    },
    resetEditedItem() {
      this.editedItem = {
        intent: "",
        target: "",
        version: "",
        hook: "",
      };
    },
    openAnotherFlowDesigner(item) {
      let found = false;
      for (let botId in this.allBots) {
        if (this.allBots[botId].flows && this.allBots[botId].flows.length > 0) {
          this.allBots[botId].flows.forEach((flowObj) => {
            let uuid = undefined;
            for (let hookKey in flowObj.hooks) {
              if (flowObj.hooks[hookKey] === item.hook) {
                uuid = hookKey;
              }
            }
            if (flowObj.flow === item.target && flowObj.version === item.version && !found) {
              found = true;
              if (uuid) {
                openFD.openNewWindow(botId, item.target, item.version, uuid);
              } else {
                openFD.openNewWindow(botId, item.target, item.version);
              }
            }
          });
        }
      }
      if (!found) {
        openFD.openNewWindow("0", item.target, item.version);
      }
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.semanticHookStyle {
  border-left: 3px solid #3f5185;
  background: #fafafa;
}
.drag {
  width: 30px !important;
}
</style>
