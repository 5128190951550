<template>
  <v-card flat class="mt-2" :min-height="minHeight">
    <v-card-text>
      <multiLanguageItem
        :itemObj="configuration.menu_node_configurations.questionNotRecognized"
        :customLabel="labels.questionNotRecognized"
        :errorMessage="null"
        :required="false"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.questionNotRecognized,
            configuration.menu_node_configurations.questionNotRecognized
          )
        "
      />
      <multiLanguageItem
        :itemObj="configuration.menu_node_configurations.disambiguationQuestion"
        :customLabel="labels.disambiguationQuestion"
        :errorMessage="null"
        :required="false"
        itemName="Message"
        @openOverlay="
          showMultiLanguage(
            labels.disambiguationQuestion,
            configuration.menu_node_configurations.disambiguationQuestion
          )
        "
      />
    </v-card-text>
    <v-overlay
      absolute
      opacity="1"
      :value="overlay"
      color="overlayMultiLanguage"
      class="overlayMultilanguage"
    >
      <multilanguageConfigurationDialog
        :itemName="itemName"
        :itemObj="itemObj"
        @back="overlay = !$event"
      />
    </v-overlay>
  </v-card>
</template>
<script>
import multilanguageConfigurationDialog from "./MultilanguageConfigurationiDialog";
import multiLanguageItem from "../../other/multiLanguageItem";
export default {
  name: "General",
  components: {
    multilanguageConfigurationDialog,
    multiLanguageItem
  },
  props: ["configuration"],
  data: () => ({
    minHeight: "100%",
    itemName: null,
    itemObj: null,
    overlay: false,
    labels: {
      questionNotRecognized: "Question if not recognized",
      disambiguationQuestion: "Disambiguation Question"
    }
  }),
  watch: {
    overlay(val) {
      if (val) {
        this.minHeight = "600px";
      } else {
        this.minHeight = "100%";
      }
    }
  },
  methods: {
    showMultiLanguage(label, data) {
      this.overlay = !this.overlay;
      this.itemName = label;
      this.itemObj = data;
      this.$emit("openOverlay");
    }
  }
};
</script>
