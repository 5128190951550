<template>
  <v-container v-show="notLoading" id="agent-desktop-id" fluid>
    <ConfirmationDialog :showConfirm="confirmDialog" :message="dialogMessage" @dialogDeny="confirmDialog = false" @dialogConfirm="deleteFile" />
    <ResultSnackbar ref="resultSnackbar" />
    <v-row justify="center" :class="getMainRowMargin">
      <v-col cols="12">
        <v-alert
          type="info"
          icon="mdi-information"
          outlined
          text
          :color="$vuetify.theme.dark ? '#FBFBF7' : 'primary'"
          class="mx-1"
        >
          <v-row class="no-gutters">
            <v-col cols="12">
              Customize your ConvyAI Agent Desktop by clicking the (+) button and upload the zip file. <br>
              Remember that the export feature will not export the properties associated to the Agent Desktop.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="agentDesktopList.length === 0 && !newGui" justify="center" :class="getMainRowMargin">
      <v-col cols="12">
        <v-alert type="warning">
          No Agent Desktops configured
        </v-alert>
      </v-col>
    </v-row>
    <v-row justify="center" :class="getMainRowMargin">
      <v-col cols="12">
        <!-- Aggiunta gui -->
        <v-expansion-panels v-if="newGui" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
          <v-expansion-panel @change="updateMainScrollbar">
            <v-expansion-panel-header>
              <v-row align="center" class="spacer" no-gutters>
                <v-col cols="12">
                  New Agent Desktop
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <agentDesktopForm
                :isEdit="false"
                :gui.sync="newGui"
                :file.sync="file"
                :agentDesktopList.sync="agentDesktopList"
                :loading.sync="submitLoading"
                @importFile="importFile"
                @uploadFile="uploadFile"
                @removeFile="removeFile"
                @closePanel="closePanel"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Visualizzazione lista Gui-->
        <p v-if="agentDesktopList.length > 0" class="ml-5">
          Agent Desktop list
        </p>
        <v-expansion-panels
          v-if="agentDesktopList.length > 0"
          :key="forceRerender + '_ep'"
          v-model="expansionPanel"
          popout
          :class="getMainExpansionPanelMargin"
        >
          <v-expansion-panel v-for="(gui, id) in agentDesktopList" :key="'gui' + id" @change="updateMainScrollbar">
            <v-hover v-slot="{ hover }">
              <v-expansion-panel-header hide-actions :class="hover ? 'hoverBanner' : ''" style="pointer-events: none !important">
                <v-row align="center" class="px-4">
                  <v-col cols="1">
                    <v-avatar size="35" color="primary" class="my-1">
                      <v-icon small dark>
                        mdi-folder-zip
                      </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title style="height: 18px !important">
                          <v-icon v-if="gui.default" color="success" class="mt-n1" small>
                            mdi-checkbox-marked-circle
                          </v-icon> {{ gui.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="pb-1">
                          {{ gui.uuid }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="8" class="text-truncate d-flex flex-grow">
                    <v-row align="center" justify="end">
                      <v-btn
                        small
                        class="btnPointer mx-4 px-2"
                        color="primary"
                        @click.stop=""
                      >
                        <CopyToClipboardButton
                          :notopmargin="true"
                          :button="true"
                          label="Copy URL"
                          :data="getAgentDesktopURL(gui)"
                          message="Agent Desktop URL copied to clipboard!"
                        />
                      </v-btn>
                      <v-btn
                        style="pointer-events: auto !important"
                        small
                        color="success"
                        class="mr-2"
                        :loading="submitLoading.save"
                        :disabled="submitLoading.export || submitLoading.delete"
                        @click.native.stop="manageOpen(gui, id)"
                      >
                        <v-icon left>
                          mdi-sync
                        </v-icon>
                        <span v-if="expansionPanel == id">close edit</span>
                        <span v-else>change</span>
                      </v-btn>
                      <v-btn
                        small
                        :disabled="submitLoading.save || submitLoading.delete"
                        :loading="submitLoading.export"
                        color="primary"
                        class="btnPointer mx-2 px-5"
                        @click.stop="exportFile(gui)"
                      >
                        <v-icon left>
                          mdi-download
                        </v-icon>export
                      </v-btn>
                      <v-btn
                        small
                        :disabled="submitLoading.save || submitLoading.export"
                        :loading="submitLoading.delete"
                        color="error"
                        class="btnPointer mx-2"
                        @click.stop="deleteInterface(gui)"
                      >
                        <v-icon left>
                          mdi-delete
                        </v-icon>delete
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
            </v-hover>
            <v-expansion-panel-content>
              <v-col cols="12">
                <agentDesktopForm
                  :isEdit="true"
                  :gui="editGui"
                  :file.sync="file"
                  :agentDesktopList.sync="agentDesktopList"
                  :loading.sync="submitLoading"
                  @importFile="importFile($event)"
                  @uploadFile="uploadFile"
                  @removeFile="removeFile"
                  @validForm="enableBtn = $event"
                />
              </v-col>
              <!-- Buttons -->
              <!-- Se sono in edit allora potrei non voler uploadare la agent desktop, ma voglio solo cambiare le conf -->
              <v-col cols="12" sm="12" class="mb-4">
                <v-row align="center" class="px-4">
                  <v-btn
                    color="success"
                    class="mr-2"
                    :loading="submitLoading.save"
                    :disabled="submitLoading.export || submitLoading.delete"
                    @click.prevent="importFile(true)"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Update</span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <AddNewItemButton name="agent desktop" @addNewItem="addNewGui" />
    <div v-show="false">
      <form v-show="false" ref="exportFormToSubmit" method="POST" action="/export">
        <input type="text" name="uuid" value>
        <input type="text" name="type" value="AGENT_INTERFACE">
      </form>
    </div>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import EventBus from "../../event-bus";
import ConfirmationDialog from "../../components/other/ConfirmationDialog";
import fieldValidators from "../../helpers/fieldValidators";
import agentDesktopForm from "../../components/tools/gui/agentDesktopForm.vue";
import CopyToClipboardButton from "../../components/other/CopyToClipboardButton";

export default {
  name: "AgentDesktop",
  components: {
    ResultSnackbar,
    AddNewItemButton,
    ConfirmationDialog,
    agentDesktopForm,
    CopyToClipboardButton,
  },
  data() {
    return {
      beforeEditGui: null, //utilizzata per revertare modifiche non salvate
      forceRerender: 0,
      existsAgentDesktop: false,
      file: null,
      submitLoading: {
        save: false,
        delete: false,
        export: false,
      },
      confirmDialog: false,
      dialogMessage: "",
      maxSizeRule: [(value) => !value || value.size < 20000000 || "ZIP size should be less than 20 MB!"],
      preview: null,
      // gui
      newGui: null,
      agentDesktopList: [],
      editGui: {
        name: "",
        uuid: "",
        properties: {},
        default: false,
      },
      agentDesktopToDelete: undefined,
      newUpload: {},
      creationPanel: null,
      hovered: false,
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      selectedGui: null,
      guiSwitch: false,
      enableBtn: false,
      notLoading: false,
    };
  },
  computed: {
    ...spacing,
    calcSwitch() {
      return false;
    },
  },
  mounted() {
    EventBus.$emit(this.$store.getters.getEvents.LOADING, true);
    EventBus.$on(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onUrlCopied);
    this.listAgentDesktop();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onUrlCopied);
  },
  methods: {
    ...fieldValidators,
    ...scroller,
    onUrlCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    getAgentDesktopURL(agentDesktop) {
      const company = this.$store.state.loginResponse.params.customerProfile.selectedCompany;
      const serverConfigs = this.$store.state.loginResponse.params.serverConfigurations;
      const base = serverConfigs.agentDesktopUrl || this.$store.state.loginResponse.params.serverConfigurations.publicUrl;
      return serverConfigs?.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/" + agentDesktop.uuid + "/#/login";
    },
    manageOpen(gui, id) {
      if (this.expansionPanel == id) {
        //se faccio dei cambi (senza passare per l'update) allora rimetto la vecchia (cosi non ho modifiche pending, non salvate)
        if (this.beforeEditGui?.uuid) {
          const index = this.agentDesktopList.findIndex((agentDesktop) => agentDesktop.uuid === this.beforeEditGui.uuid);
          if (this.agentDesktopList[index]) this.agentDesktopList[index] = this.beforeEditGui;
        }
        this.expansionPanel = null;
        this.editGui = {
          name: "",
          properties: {},
          default: false,
        };
        this.beforeEditGui = null;
      } else {
        //se faccio dei cambi (senza passare per l'update) allora rimetto la vecchia (cosi non ho modifiche pending, non salvate)
        if (this.beforeEditGui?.uuid) {
          const index = this.agentDesktopList.findIndex((agentDesktop) => agentDesktop.uuid === this.beforeEditGui.uuid);
          if (this.agentDesktopList[index]) this.agentDesktopList[index] = this.beforeEditGui;
        }
        gui.properties = typeof gui.properties === "string" ? JSON.parse(gui.properties) : gui.properties;
        this.editGui = gui;
        this.expansionPanel = id;
        this.beforeEditGui = JSON.parse(JSON.stringify(this.editGui));
      }
    },
    isZipFile(file) {
      if (file && file.type.includes("zip")) {
        return false;
      }
      return true;
    },
    uploadFile(obj) {
      this.file = obj.file;
    },
    deleteInterface(gui) {
      this.agentDesktopToDelete = gui;
      this.dialogMessage = "Are you sure to delete " + gui.name + " agent desktop?";
      if (this.beforeEditGui && this.beforeEditGui.uuid !== gui.uuid && !gui.default) {
        this.dialogMessage = "Are you sure to delete " + gui.name + " agent desktop?";
      } else if (
        (!this.beforeEditGui && gui.default) ||
        (this.beforeEditGui && this.beforeEditGui.default) ||
        (this.beforeEditGui && this.beforeEditGui.uuid !== gui.uuid && gui.default)
      ) {
        this.dialogMessage =
          "This is a default Agent Desktop. By confirming all the profiles that use this will automatically switch to the system one. Are you sure to delete " +
          gui.name +
          " agent desktop?";
      }
      this.confirmDialog = true;
    },
    async listAgentDesktop() {
      try {
        let result = await this.$http.get("/agent-ui/list");
        if (result.data.length > 0) {
          this.agentDesktopList = result.data;
          this.agentDesktopList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));
          this.existsAgentDesktop = true;
        } else {
          this.existsAgentDesktop = false;
        }
      } catch (e) {
        this.existsAgentDesktop = false;
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
        this.notLoading = true;
      }
    },
    async importFile(isEdit) {
      //una volta fatto update non mi serve più la before update altrimenti faccio pasticci
      this.beforeEditGui = null;
      this.submitLoading.save = true;
      let data = new FormData();
      try {
        let result;
        if (!isEdit) {
          data.append("file", this.file);
          data.append("name", this.newGui.name);
          if (!this.newGui.properties) {
            this.newGui.properties = {};
          }
          if (typeof this.newGui.properties === "object") {
            this.newGui.properties = JSON.stringify(this.newGui.properties);
          }
          data.append("properties", this.newGui.properties);
          data.append("default", this.newGui.default);
          result = await this.$http.post("/agent-ui", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          this.agentDesktopList.push(result.data);
          this.agentDesktopList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
          this.newGui = "";
          if (result.data.default) {
            //perchè in caso di cambio del default devo prendermi tutte le barre aggiornate
            this.listAgentDesktop();
          }
        } else {
          if (this.file && this.file.name) {
            data.append("file", this.file);
          }
          data.append("name", this.editGui.name);
          if (!this.editGui.properties) {
            this.editGui.properties = {};
          }
          if (typeof this.editGui.properties === "object") {
            this.editGui.properties = JSON.stringify(this.editGui.properties);
          }
          data.append("properties", this.editGui.properties);
          data.append("default", this.editGui.default);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          result = await this.$http.put(`/agent-ui/${this.editGui.uuid}`, data, config);
          let index = this.agentDesktopList.findIndex((el) => el.uuid === result.data.uuid);
          this.agentDesktopList[index] = result.data;
          this.editGui.name = "";
          if (result.data.default) {
            //perchè in caso di cambio del default devo prendermi tutte le barre aggiornate
            this.listAgentDesktop();
          }
        }
        this.file = null;
        this.existsAgentDesktop = true;
        this.$refs.resultSnackbar.showSuccess("Agent desktop uploaded successfully!", 0);
        this.scrollToTop().finally(() => (this.expansionPanelCreation = null), 100);
      } catch (e) {
        if (typeof this.newGui.properties === "string") {
          this.newGui.properties = JSON.parse(this.newGui.properties);
        }
        this.$refs.resultSnackbar.showError("There were problems loading the agent desktop", 0);
      }
      this.submitLoading.save = false;
      this.creationPanel = null;
      this.expansionPanel = null;
    },
    async exportFile(agentDesktop) {
      this.submitLoading.export = true;
      this.$refs.exportFormToSubmit.action = "../export/agent-ui?j=" + this.$store.state.loginResponse.jwt;
      this.$refs.exportFormToSubmit[0].value = agentDesktop.uuid;
      this.$refs.exportFormToSubmit.submit();
      this.submitLoading.export = false;
    },
    async deleteFile() {
      this.beforeEditGui = null;
      this.expansionPanel = null;
      this.confirmDialog = false;
      this.submitLoading.delete = true;
      if (this.agentDesktopToDelete) {
        try {
          await this.$http.delete("/agent-ui/" + this.agentDesktopToDelete.uuid);
          this.agentDesktopList = this.agentDesktopList.filter((el) => el.uuid !== this.agentDesktopToDelete.uuid);
          if (this.agentDesktopList.length == 0) {
            this.existsAgentDesktop = false;
          }
          this.$refs.resultSnackbar.showSuccess("Agent Desktop delete successfully!");
        } catch (e) {
          if (e.response.status === 403) {
            this.$refs.resultSnackbar.showError("Agent desktop cannot be delete because it is linked to a profile!");
          } else {
            this.$refs.resultSnackbar.showError("Fail to delete agent desktop!");
          }
        } finally {
          this.agentDesktopToDelete = undefined;
        }
      }
      this.submitLoading.delete = false;
    },
    removeFile() {
      this.file = null;
    },
    updateMainScrollbar() {
      this.expansionPanel = null;
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewGui() {
      if (!this.newGui) {
        this.newGui = {
          name: "",
          file: null,
          properties: {},
          default: false,
        };
        this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
      }
    },
    closePanel() {
      this.newGui = null;
      this.removeFile();
      this.scrollToTop().finally(() => (this.expansionPanelCreation = null), 300);
    },
    updateFile() {
      this.$emit("importFile", true);
    },
  },
};
</script>
<style>
.btnPointer {
  pointer-events: auto !important;
  cursor: pointer !important;
  position: relative;
  z-index: 99;
}
.guiAlert {
  border-color: transparent !important;
  border-radius: 0 !important;
}
.theme--dark .guiAlert {
  background-color: #263238 !important;
}
</style>
