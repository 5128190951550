<template>
  <v-stepper v-model="stepper">
    <v-stepper-header id="customized-header">
      <v-stepper-step step="1" :complete="stepper > 1">
        <template #default>
          <v-tooltip top :disabled="stepper < 2">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn :class="stepper > 1 ? 'completed-step' : 'primary'" class="mr-2 white--text" icon small @click="navigateStep(1)">
                  <v-icon v-if="stepper > 1">mdi-check</v-icon>
                  <span v-else>1</span>
                </v-btn>
                <span v-if="$vuetify.breakpoint.mdAndUp">What</span>
              </span>
            </template>

            <table class="flex flex-column align-start text-left px-2 mx-n2" style="max-width: 500px">
              <tr class="pb-12">
                <th style="min-width: 95px" class="text-caption font-weight-bold">
                  Type of report:
                </th>
                <td>
                  <v-chip color="white" outlined class="px-1 mr-2" label small>
                    {{ criteria.type }}
                  </v-chip>
                </td>
              </tr>
              <tr>
                <th style="min-width: 95px" class="d-flex pt-4 text-caption font-weight-bold">
                  KPIs:
                </th>
                <td class="pt-4">
                  <v-chip
                    v-for="(criteria, index) in criteria.kpis"
                    :key="index"
                    label
                    small
                    color="white"
                    outlined
                    class="px-1 mr-2 mb-2"
                  >
                    {{ criteria.text }}
                  </v-chip>
                </td>
              </tr>
            </table>
          </v-tooltip>
        </template>
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="stepper > 2" step="2" @click="navigateStep(2)">
        <template #default>
          <v-tooltip top :disabled="stepper < 3">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn
                  :class="stepper > 2 ? 'completed-step' : 'primary'"
                  class="mr-2 white--text"
                  icon
                  small
                  :disabled="Object.keys(criteria.kpis).length == 0"
                >
                  <v-icon v-if="stepper > 2">mdi-check</v-icon>
                  <span v-else>2</span>
                </v-btn>
                <span v-if="$vuetify.breakpoint.mdAndUp">When</span>
              </span>
            </template>
            <table class="flex flex-column align-start text-left px-2" style="max-width: 250px">
              <tr>
                <th style="min-width: 95px" class="text-caption font-weight-bold">
                  Aggregation:
                </th>
                <td>
                  <v-chip color="white" outlined class="px-1 mr-2" label small>
                    {{ criteria.interval.text }}
                  </v-chip>
                </td>
              </tr>
              <tr>
                <th style="min-width: 95px" class="text-caption font-weight-bold">
                  Timezone:
                </th>
                <td class="pt-4">
                  <v-chip color="white" outlined class="px-1 mr-2" label small>
                    {{ criteria.timezone.zone }}
                  </v-chip>
                </td>
              </tr>
              <tr>
                <th style="min-width: 95px" class="d-flex pt-4 text-caption font-weight-bold">
                  Timeframe:
                </th>
                <td class="pt-4">
                  <v-chip
                    v-if="criteria.custom_range.length === 2 && criteria.range.value === 'customrange'"
                    color="white"
                    outlined
                    class="px-1 mr-2"
                    label
                    small
                  >
                    {{ criteria.custom_range[0] }} to {{ criteria.custom_range[1] }}
                  </v-chip>
                  <v-chip
                    v-else
                    color="white"
                    outlined
                    class="px-1 mr-2"
                    label
                    small
                  >
                    {{ criteria.range.text }}
                  </v-chip>
                </td>
              </tr>
            </table>
          </v-tooltip>
        </template>
      </v-stepper-step>
      <v-divider />
      <v-stepper-step step="3">
        <template #default>
          <span>
            <v-btn
              class="mr-2 white--text"
              icon
              small
              :class="stepper > 2 ? 'primary' : 'grey'"
              :disabled="Object.keys(criteria.kpis).length === 0"
              @click="navigateStep(3)"
            >
              <v-icon v-if="stepper > 2">mdi-check</v-icon>
              <span v-else>3</span>
            </v-btn>
            <span v-if="$vuetify.breakpoint.mdAndUp">Filters</span>
          </span>
        </template>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12" flat height="200px">
          <v-select v-model="criteria.type" :items="Object.keys(values.kpis)" label="Select the type of report" return-object @change="resetKPI" />
          <v-autocomplete
            v-model="criteria.kpis"
            :items="values.kpis[criteria.type]"
            label="Select one or more KPIs"
            multiple
            clearable
            return-object
          >
            <v-list-item slot="prepend-item" :ripple="false" @change="selectAll">
              <v-list-item-action>
                <v-icon :style="Object.keys(criteria.kpis).length > 0 ? 'color:#0d4e51 ' : ''">
                  {{ iconFirstStep }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-uppercase">
                <span v-if="Object.keys(criteria.kpis).length !== Object.keys(values.kpis[criteria.type]).length"> Select all kpi</span>
                <span v-else>Deselect all</span>
              </v-list-item-content>
            </v-list-item>
            <template #selection="{ item, index }">
              <v-chip v-if="$vuetify.breakpoint.mdAndUp ? index < 10 : index < 5" small class="contentChips" color="primary">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="$vuetify.breakpoint.mdAndUp ? index === 10 : index === 5" class="grey--text caption">
                <span v-if="$vuetify.breakpoint.mdAndUp">(+{{ Object.keys(criteria.kpis).length - 10 }} others)</span>
                <span v-else>(+{{ Object.keys(criteria.kpis).length - 5 }} others)</span>
              </span>
            </template>
          </v-autocomplete>
        </v-card>

        <v-btn color="primary" class="mr-2" :disabled="!criteria.kpis || criteria.kpis.length === 0 || isCCMDisableProfile" @click="stepper = 2">
          Next
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-12" flat height="200px">
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-select v-model="criteria.interval" :items="values.intervals" label="Select the aggregation interval" return-object />
            </v-col>
            <v-col cols="6" class="pb-0">
              <TimeZonePicker :item="criteria.timezone" label="Select the timezone to display the report" />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-autocomplete v-model="criteria.range" :items="getAvailableRanges" label="Select the time frame" return-object @change="onRangeSelect" />
              <div v-if="criteria.custom_range.length === 2 && criteria.range.value === 'customrange'" class="float-left">
                Range selected: from <b>{{ criteria.custom_range[0] }}</b> to <b>{{ criteria.custom_range[1] }}</b>
                <v-icon small class="ml-2" @click="onRangeSelect">
                  mdi-pencil
                </v-icon>
              </div>
              <v-dialog v-model="customRangeDialog" width="340">
                <v-card>
                  <v-card-title class="primary">
                    Select the date range
                  </v-card-title>
                  <v-card-text>
                    <v-date-picker
                      id="customdatapiker"
                      v-model="criteria.custom_range"
                      range
                      :max="getMaxSelectableDate"
                      :min="getMinSelectableDate"
                      :selected-items-text="getDatePickerTitle"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" :disabled="criteria.custom_range.length !== 2" @click="customRangeDialog = false">
                      Confirm
                    </v-btn>
                    <v-btn text @click="customRangeDialog = false">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>

        <v-btn color="primary" class="mr-2" :disabled="!isRangeValid" @click="stepper = 3">
          Next
        </v-btn>
        <v-btn text @click="stepper--">
          Back
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mb-12" flat height="200px">
          <v-autocomplete
            v-model="criteria.filters"
            :items="getFilters"
            multiple
            return-object
            label="Select one or more items to filter your result. By leaving this field blank no filter will be applied."
          >
            <v-list-item slot="prepend-item" :ripple="false" @change="selectAllItems(getFilters)">
              <v-list-item-action>
                <v-icon :color="Object.keys(criteria.filters).length > 0 ? 'color:#0d4e51' : ''">
                  {{ iconThirdStep }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-uppercase">
                <span v-if="Object.keys(criteria.filters).length !== Object.keys(getFilters).length"> Select all {{ getFilterTitle }}</span>
                <span v-else>Deselect all</span>
              </v-list-item-content>
            </v-list-item>
            <template #selection="{ item, index }">
              <!-- <span  v-if="$vuetify.breakpoint.mdAndUp"> -->
              <v-chip v-if="$vuetify.breakpoint.mdAndUp ? index < 10 : index < 5" small class="contentChips" color="primary">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="$vuetify.breakpoint.mdAndUp ? index === 10 : index === 5" class="grey--text caption">
                <span v-if="$vuetify.breakpoint.mdAndUp"> (+{{ Object.keys(criteria.filters).length - 10 }} others)</span>
                <span v-else> (+{{ Object.keys(criteria.filters).length - 5 }} others)</span>
              </span>
            </template>
          </v-autocomplete>
          <!-- per ora i dati aggregati non hanno questa info
          <template v-if="criteria.type === 'AGENT'">
            <v-autocomplete
              :items="getQueueList"
              v-model="criteria.agent_queue_filters"
              multiple
              return-object
              label="Select one or more queues to filter your result. If empty no filter will be applied"
            />
            <v-autocomplete
              :items="getProfileList"
              v-model="criteria.agent_profile_filters"
              multiple
              return-object
              label="Select one or more profiles to filter your result. If empty no filter will be applied"
            />
          </template>
          -->
        </v-card>

        <v-btn color="success" class="mr-2" @click="save">
          <v-icon left>
            mdi-floppy
          </v-icon> Save
        </v-btn>
        <v-btn color="primary" class="mr-2" @click="search">
          <v-icon left>
            mdi-magnify
          </v-icon> Search
        </v-btn>
        <v-btn text @click="stepper--">
          Back
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import TimeZonePicker from "../../components/other/TimeZonePicker";

export default {
  name: "ContactCenterCriteria",
  components: {
    TimeZonePicker,
  },
  props: ["criteria", "values", "isCCMDisableProfile"],
  // watch: {
  //   $props: {}
  //   filterToOverride: {
  //     // the callback will be called immediately after the start of the observation
  //     immediate: true,
  //     handler(val, oldVal) {
  //       console.log("watcher", val, oldVal);
  //     },
  //   },
  // },
  data() {
    return {
      stepper: 1,
      customRangeDialog: false,
      selectAllStep1: false,
      selectAllStep3: false,
      typeToOverride: "",
    };
  },
  computed: {
    isCCMuser() {
      const user = this.$store.getters.getUser;
      //disabilito la selectAll se è un ccm senza visibilità su tutti i profili
      if (
        this.$store.getters.isCCM &&
        user.roles &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
        user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
      ) {
        return true;
      }
      return false;
    },
    iconFirstStep() {
      if (Object.keys(this.criteria.kpis).length === Object.keys(this.values.kpis[this.criteria.type]).length) return "mdi-close-box";
      if (Object.keys(this.criteria.kpis).length > 0 && Object.keys(this.criteria.kpis).length !== Object.keys(this.values.kpis[this.criteria.type]).length)
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconThirdStep() {
      let items = this.getFilters;
      if (Object.keys(this.criteria.filters).length === Object.keys(items).length) return "mdi-close-box";
      if (Object.keys(this.criteria.filters).length > 0 && Object.keys(this.criteria.filters).length !== Object.keys(items).length) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getDatePickerTitle() {
      if (this.criteria.custom_range.length === 0) {
        return "No date selected";
      } else if (this.criteria.custom_range.length === 1) {
        return "From " + this.criteria.custom_range[0] + " to...";
      } else {
        const num = this.getSelectedDays(this.criteria.custom_range[0], this.criteria.custom_range[1]);
        return "Selected " + num + " day" + (num > 1 ? "s" : "");
      }
    },
    getMinSelectableDate() {
      if (this.criteria.custom_range.length === 1) {
        const d = new Date(this.criteria.custom_range[0]);
        d.setDate(d.getDate() - 30);
        return d.toISOString();
      }
      return undefined;
    },
    getMaxSelectableDate() {
      if (this.criteria.custom_range.length === 1) {
        const d = new Date(this.criteria.custom_range[0]);
        d.setDate(d.getDate() + 30);
        return d.toISOString();
      }
      return undefined;
    },
    getAvailableRanges() {
      return this.values.ranges.filter((r) => r.scale.includes(this.criteria.interval.scale));
    },
    isRangeValid() {
      return this.criteria.range.scale.includes(this.criteria.interval.scale);
    },
    getQueueList() {
      return this.values.queues.map((q) => {
        return {
          text: q.name,
          value: q.eri,
        };
      });
    },
    getProfileList() {
      return this.values.profiles.map((p) => {
        return {
          text: p.name,
          value: p.eri,
        };
      });
    },
    getFilterTitle() {
      switch (this.criteria.type) {
        case "PROFILE":
          return "Profiles";
        case "QUEUE":
          return "Queues";
        case "AGENT":
        default:
          return "Agents";
      }
    },
    getFilters() {
      let context = [];
      if (this.criteria.type === "AGENT") {
        context = this.values.agents.map((a) => {
          return {
            text: a.authentication.credentials.username,
            value: a.eri,
          };
        });
      }
      if (this.criteria.type === "QUEUE") {
        context = this.values.queues.map((q) => {
          return {
            text: q.name,
            value: q.eri,
          };
        });
      }
      if (this.criteria.type === "PROFILE") {
        context = this.values.profiles.map((p) => {
          return {
            text: p.name,
            value: p.eri,
          };
        });
      }

      return (
        context.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        }) || []
      );
    },
  },
  watch: {
    "criteria.type"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.criteria.filters = [];
      }
    },
  },
  methods: {
    navigateStep(step) {
      if (Object.keys(this.criteria.kpis).length > 0) {
        this.stepper = step;
      }
    },
    selectAll() {
      if (Object.keys(this.criteria.kpis).length !== Object.keys(this.values.kpis[this.criteria.type]).length) {
        for (let criteria of this.values.kpis[this.criteria.type]) {
          let found = this.criteria.kpis.some((el) => el.value === criteria.value);
          if (!found)
            this.criteria.kpis.push({
              fields: criteria.fields,
              value: criteria.value,
              type: criteria.type,
              text: criteria.text,
            });
        }
        this.selectAllStep1 = true;
      } else {
        this.resetKPI();
      }
    },
    resetKPI() {
      this.criteria.kpis = [];
      this.selectAllStep1 = false;
    },
    selectAllItems(filtred) {
      let items = filtred;
      if (Object.keys(this.criteria.filters).length !== Object.keys(items).length) {
        for (let criteria of items) {
          let found = this.criteria.filters.some((el) => el.value === criteria.value);
          if (!found) this.criteria.filters.push(criteria);
        }
        this.selectAllStep3 = true;
      } else {
        this.criteria.filters = [];
        this.selectAllStep3 = false;
      }
    },
    getSelectedDays(first, second) {
      return 1 + Math.round((new Date(second).getTime() - new Date(first).getTime()) / (1000 * 60 * 60 * 24));
    },
    onRangeSelect() {
      this.customRangeDialog = this.criteria.range.value === "customrange";
    },
    save() {
      this.stepper = 1;
      this.$emit("save");
    },
    search() {
      this.stepper = 1;
      this.$emit("search");
    },
  },
};
</script>

<style>
.completed-step {
  background-color: #035075 !important;
}
#customized-header .v-stepper__step__step {
  display: none !important;
}
#customized-header .v-stepper__label {
  display: block !important;
}
.theme--light #customdatapiker .v-picker__title {
  background-color: white !important;
  color: black;
}
</style>
