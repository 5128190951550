import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        // in common with flow designer
        primary: "#1D9096",
        error: colors.red.darken1,
        info: colors.indigo.darken1,
        success: "#4E963C", // #4E963C
        warning: "#B823A2",
        secondary: colors.grey.darken2,
        accent: colors.deepOrange.darken1,
        more: colors.amber.darken1,
        //
        mainCard: colors.grey.lighten5,
        headingCard: "#1D9096",
        accentInputTextField: colors.blue.accent3,
        toolbarText: colors.grey.lighten5,
        primaryText: "#1A2C40",
        iconColor: "#fff",
        secondaryText: colors.blueGrey.darken3,
        buttonDiscard: colors.deepOrange.darken2,
        buttonCancel: colors.grey.darken2,
        buttonText: "#FFFFFF",
        hoverBanner: colors.grey.lighten3,
        leftMenuIcon: colors.grey.darken4,
        sliderKeyboard: colors.grey.lighten3,
        overlayDashboard: "#FFFFFF",
        overlayMultiLanguage: "#FFFFFF",
        leftMenu: "#035075",
        sheetColor: "#fff",
      },
      dark: {
        // in common with flow designer
        primary: "#1D9096",
        error: "#FF6F75",
        info: "#639AFF",
        success: "#60A38D",
        warning: colors.pink.darken4, //#B823A2
        more: "#FFD54F",
        secondary: colors.grey.darken2,
        accent: colors.deepOrange.lighten1,
        iconColor: "#fff",
        //
        mainCard: colors.blueGrey.darken4,
        headingCard: "#1D9096",
        dialogConfirm: colors.grey.lighten5,
        accentInputTextField: colors.lightBlue.lighten3,
        toolbarText: colors.grey.lighten5,
        primaryText: colors.grey.lighten5,
        secondaryText: colors.blueGrey.darken4,
        buttonDiscard: "#FFA973",
        buttonCancel: colors.grey.darken1,
        buttonText: "#FFFFFF",
        hoverBanner: colors.grey.darken2,
        sliderKeyboard: colors.blueGrey.darken3,
        overlayDashboard: "#263238",
        overlayMultiLanguage1: "#263238",
        leftMenu: "#263238",
        sheetColor: "#455a64",
      },
    },
  },
});
