<template>
  <div class="queueform">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteQueue" />
    <v-form ref="createQueueForm" v-model="form.isValid" :class="getCreationFormMargin" :disabled="$store.getters.isCCMO">
      <v-container :class="getCreationContainerMargin">
        <v-row :class="getFormRowMargin">
          <v-col v-if="isEdit" cols="12" :class="getFormColMargin" class="d-inline-flex">
            <v-text-field v-model.trim="queueConfig.eri" label="Queue ERI" disabled />
            <CopyToClipboard :data="queueConfig.eri" message="ERI copied to clipboard!" />
          </v-col>
        </v-row>
        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="queueConfig.name"
              label="Queue name"
              :rules="[
                requiredField,
                simpleValidatorNameWithSpaces,
                uniqueNameQueue(queuesList, queueConfig.name, queueConfig.uuid),
                charsGreaterThanAndLessThan(queueConfig.name, 3, 255),
              ]"
              prepend-inner-icon="mdi-expand-all"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>

          <v-col v-if="isCCM && !isEdit && profilesList && profilesList.length > 0" cols="12" sm="6" :class="getFormColMargin">
            <v-autocomplete
              v-model="profileAssociated"
              :items="profilesList"
              item-text="name"
              item-value="uuid"
              :rules="[requiredField]"
              multiple
              small-chips
              label="Choose a profile"
              prepend-inner-icon="mdi-arrow-decision"
              persistent-hint
              hint="Profiles to which the queue is associated"
              validate-on-blur
            />
          </v-col>

          <v-col v-show="false" cols="12" sm="6" :class="getFormColMargin">
            <v-autocomplete
              v-model="queueConfig.emailBox"
              clearable
              :items="emailBoxList"
              label="Choose an Email Box"
              prepend-inner-icon="email"
              persistent-hint
              hint="Email account used for all communications to the customer"
              validate-on-blur
            />
          </v-col>
        </v-row>

        <!-- EXPANSION CREATE A PROFILE -->
        <!-- Creazione profilo se utente ccm non ne possiede -->
        <v-expansion-panels v-if="isCCM && !isEdit && profilesList && profilesList.length === 0" v-model="expansionPanels[3]" class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header @click="expansionClickEvent(3)">
              <span>
                <v-icon color="primary">mdi-arrow-decision</v-icon>
                <span class="ml-4">Create a profile to associate with the queue</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <v-row class="mt-5">
                <v-col cols="12" sm="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.trim="newProfile.name"
                    prepend-inner-icon="mdi-arrow-decision"
                    label="Profile Name"
                    :rules="[requiredField, simpleValidatorNameWithSpaces, charsGreaterThanAndLessThan(newProfile.name, 3, 255)]"
                    autofocus
                  />
                </v-col>
                <v-col cols="12" sm="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.number="newProfile.routing.concurrentTask"
                    label="Concurrent Chat Task"
                    type="number"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-message"
                    min="0"
                    max="36535"
                  />
                </v-col>
                <v-col v-show="false" cols="12" sm="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.number="newProfile.routing.concurrentWebcallbackTask"
                    label="Concurrent Web Callbak Task"
                    type="number"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-av-timer"
                    min="0"
                    max="36535"
                  />
                </v-col>
                <v-col cols="12" sm="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.number="newProfile.routing.concurrentEmailTask"
                    label="Concurrent Email Task"
                    type="number"
                    :rules="[requiredField]"
                    prepend-inner-icon="mdi-email"
                    min="0"
                    max="36535"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- EXPANSION ENABLE CHANNELS -->
        <v-expansion-panels v-model="expansionPanels[0]" class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header @click="expansionClickEvent(0)">
              <span>
                <v-icon color="primary">mdi-apache-kafka</v-icon>
                <span class="ml-4">Enabled channels</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-subtitle class="text--secondary">
                Click on the channel you want to enable. At least one channel must be enabled
              </v-list-item-subtitle>
              <v-divider />
              <v-row v-for="(channel, index) in channelList" :key="'channelList_' + index" class="mt-5" no-gutters>
                <v-col cols="12">
                  <v-btn
                    :text="queueConfig.channels[channel.value].enabled ? false : true"
                    color="primary"
                    :disabled="$store.getters.isCCMO"
                    @click="toggleChannel(channel)"
                  >
                    <v-icon left>
                      {{ channel.icon }}
                    </v-icon>
                    <span>{{ channel.text }}</span>
                  </v-btn>
                </v-col>
                <v-row v-if="queueConfig.channels[channel.value].enabled" class="pa-5">
                  <v-col cols="12" sm="4" :class="getFormColMargin">
                    <v-text-field
                      v-model.number="queueConfig.channels[channel.value].timeoutPreferredAgent"
                      :label="
                        channel.value === 'social' || channel.value === 'webcallback' || channel.value === 'email'
                          ? 'Timeout preferred agent (min)'
                          : 'Timeout preferred agent (sec)'
                      "
                      type="number"
                      :rules="[
                        requiredField,
                        checkQueueLessPreferred(queueConfig.channels[channel.value].timeout, queueConfig.channels[channel.value].timeoutPreferredAgent),
                        maxTimeout(channel, queueConfig.channels[channel.value].timeoutPreferredAgent),
                      ]"
                      prepend-inner-icon="mdi-av-timer"
                      min="0"
                      max="214748"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" :class="getFormColMargin">
                    <v-text-field
                      v-model.number="queueConfig.channels[channel.value].timeout"
                      :label="
                        channel.value === 'social' || channel.value === 'webcallback' || channel.value === 'email'
                          ? 'Queue timeout (min)'
                          : 'Queue timeout (sec)'
                      "
                      type="number"
                      :rules="[
                        requiredField,
                        checkQueueLessPreferred(queueConfig.channels[channel.value].timeout, queueConfig.channels[channel.value].timeoutPreferredAgent),
                        /*
                        COMMENTATO PER FAR CONTENTO JERRY
                        checkQueueGreaterRona(
                          queueConfig.channels[channel.value].timeout,
                          queueConfig.routing.timeoutRona,
                          channel.value,
                          queueConfig.routing.autoaccept,
                        ),*/
                        maxTimeout(channel, queueConfig.channels[channel.value].timeout),
                      ]"
                      prepend-inner-icon="mdi-timer-outline"
                      :min="
                        channel.value === 'social' || channel.value === 'webcallback' || channel.value === 'email'
                          ? Math.ceil(queueConfig.routing.timeoutRona / 60)
                          : queueConfig.routing.timeoutRona
                      "
                      max="214748"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model.number="queueConfig.channels[channel.value].sla.timeout"
                      :label="channel.value === 'social' || channel.value === 'webcallback' || channel.value === 'email' ? 'SLA (min)' : 'SLA (sec)'"
                      type="number"
                      :rules="[requiredField, maxTimeout(channel, queueConfig.channels[channel.value].sla.timeout)]"
                      prepend-inner-icon="mdi-timer-sand"
                      min="0"
                      max="214748"
                      persistent-hint
                      hint="This is the threshold of time an agent must respond to a customer. If 0 the SLA is disabled"
                    />
                  </v-col>
                  <v-row v-if="channel.text == 'Chat'" class="ma-0">
                    <v-col cols="12">
                      <v-checkbox
                        v-model="queueConfig.channels.chat.features.audioVideo.enabled"
                        :label="'Enable audio/video communication'"
                        class="mb-0"
                        @change="selectCallInitiation()"
                      />
                      <label v-if="queueConfig.channels.chat.features.audioVideo.enabled">The communication is always bidirectional, the following permissions will enable the related capability on user side</label>
                    </v-col>
                    <v-col v-if="queueConfig.channels.chat.features.audioVideo.enabled" cols="12">
                      <v-row class="ma-0 pa-0" no-gutters>
                        <v-col cols="6">
                          <v-card class="mr-3">
                            <v-col cols="12" class="subtitle-1 pt-3">
                              <v-row align="center" justify="center">
                                <v-col cols="1">
                                  <v-tooltip bottom>
                                    <template #activator="{ on, attrs }">
                                      <v-icon small :color="$vuetify.theme.dark ? 'light-grey' : 'grey'" dark v-bind="attrs" v-on="on">
                                        mdi-information
                                      </v-icon>
                                    </template>
                                    <span>By enabling permissions, the agent can start a call, a video call, or share the screen and the client can accept or
                                      reject the request.</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="11" class="text-center">
                                  <v-icon class="mr-1">
                                    mdi-face-agent
                                  </v-icon>
                                  <span>Agent's permissions</span>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-divider class="mx-4 primary" />
                            <v-card-text class="ml-3">
                              <v-row
                                v-for="(feature, index) in queueConfig.channels.chat.features.audioVideo.agent"
                                :key="feature + '_' + index"
                                align="center"
                              >
                                <v-switch
                                  v-model="queueConfig.channels.chat.features.audioVideo.agent[index]"
                                  dense
                                  primary
                                  :disabled="
                                    index != 'startCall'
                                      ? false
                                      : queueConfig.channels.chat.features.audioVideo.agent[index] &&
                                        !queueConfig.channels.chat.features.audioVideo.customer.startCall
                                  "
                                />
                                <v-tooltip
                                  top
                                  :disabled="
                                    index != 'startCall'
                                      ? true
                                      : !(
                                        queueConfig.channels.chat.features.audioVideo.agent[index] &&
                                        !queueConfig.channels.chat.features.audioVideo.customer.startCall
                                      )
                                  "
                                >
                                  <template #activator="{ on, attrs }">
                                    <span style="font-size: 16px" v-bind="attrs" v-on="on">{{ getAudioVideoLabel(index) }}</span>
                                  </template>
                                  <span>At least one audio feature must be selected</span>
                                </v-tooltip>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card class="mr-3">
                            <v-col cols="12" class="subtitle-1 pt-3">
                              <v-row align="center" justify="center">
                                <v-col cols="1">
                                  <v-tooltip bottom>
                                    <template #activator="{ on, attrs }">
                                      <v-icon small :color="$vuetify.theme.dark ? 'light-grey' : 'grey'" dark v-bind="attrs" v-on="on">
                                        mdi-information
                                      </v-icon>
                                    </template>
                                    <span>By enabling permissions, the customer can start a call, a video call, or share the screen and the agent can accept or
                                      reject the request.</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="11" class="text-center">
                                  <v-icon class="mr-1">
                                    mdi-account
                                  </v-icon>
                                  <span>Customer's permissions</span>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-divider class="mx-4 primary" />
                            <v-card-text class="ml-3">
                              <v-row
                                v-for="(feature, index) in queueConfig.channels.chat.features.audioVideo.customer"
                                :key="feature + '_' + index"
                                align="center"
                              >
                                <v-switch
                                  v-model="queueConfig.channels.chat.features.audioVideo.customer[index]"
                                  dense
                                  primary
                                  :disabled="
                                    index != 'startCall'
                                      ? false
                                      : queueConfig.channels.chat.features.audioVideo.customer[index] &&
                                        !queueConfig.channels.chat.features.audioVideo.agent.startCall
                                  "
                                />
                                <v-tooltip
                                  top
                                  :disabled="
                                    index != 'startCall'
                                      ? true
                                      : !(
                                        queueConfig.channels.chat.features.audioVideo.customer[index] &&
                                        !queueConfig.channels.chat.features.audioVideo.agent.startCall
                                      )
                                  "
                                >
                                  <template #activator="{ on, attrs }">
                                    <span style="font-size: 16px" v-bind="attrs" v-on="on">{{ getAudioVideoLabel(index) }}</span>
                                  </template>
                                  <span>At least one audio feature must be selected</span>
                                </v-tooltip>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row class="mr-2">
                        <v-col cols="12" lg="6">
                          <v-checkbox
                            v-model="queueConfig.channels.chat.features.audioVideo.autoStart"
                            label="Start an audio call when the agent accepts the task"
                          />
                        </v-col>
                        <v-col
                          v-if="
                            queueConfig.channels.chat.features.audioVideo.agent.enableVideo ||
                              queueConfig.channels.chat.features.audioVideo.customer.enableVideo
                          "
                          cols="12"
                          lg="6"
                          class="mt-n4"
                        >
                          <v-subheader class="mb-n4">
                            Set the video quality
                          </v-subheader>
                          <v-slider
                            v-model="videoQuality"
                            :tick-labels="ticksVideoQuality"
                            tick-size="0"
                            :max="3"
                            step="1"
                            dense
                            thumb-label
                            prepend-icon="mdi-blur"
                            append-icon="mdi-auto-fix"
                            @end="changeSliderValue"
                          >
                            <template #thumb-label="{ value }">
                              {{ thumbsVideoQuality[value] }}
                            </template>
                          </v-slider>
                        </v-col>
                      </v-row>
                      <v-row v-if="janusIntegration" class="mr-2 mt-0">
                        <v-col cols="12" lg="6" class="py-0">
                          <v-checkbox
                            v-model="queueConfig.channels.chat.features.recording.enabled"
                            label="Enable recording feature in audio/video calls"
                          />
                        </v-col>
                        <v-col cols="12" lg="6" class="py-0">
                          <v-checkbox
                            v-if="queueConfig.channels.chat.features.recording.enabled"
                            v-model="queueConfig.channels.chat.features.recording.autoStart"
                            label="Start recording when a call is established"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mr-2 mt-0">
                        <v-col cols="12" lg="6">
                          <v-checkbox
                            v-if="
                              queueConfig.channels.chat.features.audioVideo.customer.enableVideo ||
                                queueConfig.channels.chat.features.audioVideo.customer.enableScreenSharing
                            "
                            v-model="queueConfig.channels.chat.features.audioVideo.takeScreenShot"
                            label="Enable take screenshots feature in audio/video calls"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- EXPANSION ROUTING -->
        <v-expansion-panels v-model="expansionPanels[1]" class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header @click="expansionClickEvent(1)">
              <span>
                <v-icon color="primary">mdi-cogs</v-icon>
                <span class="ml-4">Configurations</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <v-row class="mt-5">
                <v-col cols="12" sm="6" :class="getFormColMargin">
                  <v-checkbox v-model="queueConfig.routing.autoaccept" label="Enable if you want to allow auto accept for incoming task" />
                </v-col>
                <v-col v-if="!queueConfig.routing.autoaccept" cols="12" sm="6" :class="getFormColMargin">
                  <v-text-field
                    v-model.number="queueConfig.routing.timeoutRona"
                    label="RONA Timeout (sec)"
                    type="number"
                    :rules="[requiredField, maxTimeoutInSeconds]"
                    prepend-inner-icon="mdi-av-timer"
                    persistent-hint
                    hint="The maximum amount of time an agent has to accept a task before it gets rerouted by the system and offered to another agent. This timeout must be less than the queue time."
                    min="5"
                    max="214748"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :class="getFormColMargin">
                  <v-checkbox v-model="queueConfig.routing.transfer.enabled" label="Enable if you want to allow transfer for this queue" />
                </v-col>
                <v-col cols="12" class="mb-3 mt-n6" :class="getFormColMargin">
                  <v-autocomplete
                    v-if="queueConfig.routing.transfer.enabled"
                    v-model="queueConfig.routing.transfer.targets"
                    :disabled="!queueConfig.routing.transfer.enabled"
                    :items="queueListItems"
                    multiple
                    small-chips
                    :label="
                      queueConfig.routing.transfer.targets && queueConfig.routing.transfer.targets.length > 0
                        ? 'Transfer allowed to these queues '
                        : 'Transfer allowed to all queues'
                    "
                    prepend-inner-icon="mdi-expand-all"
                    validate-on-blur
                    hint="You can add the queues to limit the transfer to only the enabled queues, otherwise by leaving this field blank the transfer will be enabled to all the queues. The transfer to the current task queue will always be available."
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- EXPANSION CONTACT RESULT -->
        <v-expansion-panels v-model="expansionPanels[2]" class="mt-5">
          <v-expansion-panel>
            <v-expansion-panel-header @click="expansionClickEvent(2)">
              <span>
                <v-icon color="primary">mdi-card-account-details-outline</v-icon>
                <span class="ml-4">Contact Result</span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <v-row class="mt-5 mb-2">
                <v-col cols="12" :class="getFormColMargin">
                  <v-checkbox
                    v-model="queueConfig.contactResult.enabled"
                    label="Enable the ability to configure the contact result"
                    @change="enabledContactResult"
                  />
                </v-col>
              </v-row>
              <v-row v-if="queueConfig.contactResult.enabled">
                <TreeList
                  ref="contactResultTree"
                  :editable="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)"
                  :contactResult="queueConfig.contactResult.items"
                  class="pl-10"
                />
              </v-row>
              <v-row>
                <v-col v-if="queueConfig.contactResult.enabled" cols="12" class="pl-10" :class="getFormColMargin">
                  <v-checkbox v-model="queueConfig.contactResult.required" class="mt-n3 pt-0" label="Enable if you want the contact result to be mandatory" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- BUTTONS -->
        <v-row v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" :class="getFormRowMargin" class="mt-5">
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn
              :class="getButtonMargin"
              color="success"
              :loading="form.submitLoading"
              :disabled="!form.isValid || form.deleteLoading || !isValidTree || Object.values(selectedChannels).findIndex((el) => el !== '') === -1"
              @click.prevent="saveQueue"
            >
              <v-icon left>
                mdi-floppy
              </v-icon>
              <span v-if="!isEdit">Save</span>
              <span v-else>Update</span>
            </v-btn>
            <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="form.submitLoading" @click.prevent="deleteQueue">
              <template v-if="isEdit">
                <v-icon left>
                  mdi-delete
                </v-icon>
                <span>Delete</span>
              </template>
              <template v-else>
                <v-icon left>
                  mdi-close
                </v-icon>
                <span>Cancel</span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import TreeList from "../../components/other/TreeList";
import merge from "deepmerge";
import CopyToClipboard from "../../components/other/CopyToClipboard";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "QueueForm",
  components: { ConfirmDialog, TreeList, CopyToClipboard },
  props: ["queuesList", "queueDetail", "emailBoxList", "profilesList", "searchString"],
  data() {
    return {
      showConfirm: false,
      deleteMessage: "Queue " + this.queueDetail.name + " will be deleted!",
      expansionPanels: [null, null, null, null],
      isEdit: this.queueDetail.name !== "",

      //gestione profilo
      profileAssociated: "",
      newProfile: {
        name: "",
        routing: {
          concurrentTask: 1,
          concurrentWebcallbackTask: 0,
          concurrentEmailTask: 0,
        },
      },

      selectedChannels: {
        chat: "",
        webcallback: "",
      },

      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      channelList: [
        { text: "Chat", value: "chat", icon: "mdi-message-text" },
        { text: "Social", value: "social", icon: "mdi-chat" },
        { text: "Email", value: "email", icon: "mdi-email" },
        /*{ text: "Audio", value: "audio", icon: "mdi-volume-high" },
        { text: "Video", value: "video", icon: "mdi-video" },
        { text: "Web Callback", value: "webcallback", icon: "mdi-phone" },*/
      ],

      janusIntegration: false,
      //struttura oggetto
      queueConfig: {
        name: "",
        type: "presence",
        channels: {
          chat: {
            enabled: false,
            timeout: 60,
            timeoutPreferredAgent: 30,
            sla: {
              enabled: false,
              timeout: 0,
            },
            features: {
              audioVideo: {
                enabled: false,
                videoQuality: "adaptive",
                agent: {
                  startCall: false,
                  enableVideo: false,
                  enableScreenSharing: false,
                },
                customer: {
                  startCall: true,
                  enableVideo: false,
                  enableScreenSharing: false,
                },
                autoStart: false,
              },
              recording: {
                enabled: false,
                autoStart: false,
              },
            },
          },
          social: {
            enabled: false,
            timeout: 86400, //1440 minuti -> 24 ore
            timeoutPreferredAgent: 28800, //480 minuti -> 8 ore,
            features: {},
            sla: {
              enabled: false,
              timeout: 0,
            },
          },
          email: {
            enabled: false,
            timeout: 86400, //1440 minuti -> 24 ore
            timeoutPreferredAgent: 28800, //480 minuti -> 8 ore,
            features: {},
            sla: {
              enabled: false,
              timeout: 0,
            },
          },
          audio: {
            timeout: 0,
            timeoutPreferredAgent: 0,
            enabled: false,
            features: {},
          },
          video: {
            timeout: 0,
            timeoutPreferredAgent: 0,
            enabled: false,
            features: {},
          },
          webcallback: {
            timeout: 86400, //1440 minuti -> 24 ore
            timeoutPreferredAgent: 28800, //480 minuti -> 8 ore
            enabled: false,
            features: {},
            sla: {
              enabled: false,
              timeout: 0,
            },
          },
        },
        features: {},
        emailBox: "",
        routing: {
          timeoutRona: 20,
          autoaccept: false,
          transfer: {
            enabled: false,
            targets: [],
          },
        },
        contactResult: {
          enabled: false,
          required: false,
          options: {},
          items: [],
        },
      },
      parsedContactResult: {},
      isValidTree: false,
      videoQuality: 3,
      ticksVideoQuality: ["Low", "Medium", "High", "Adaptive"],
      thumbsVideoQuality: ["360p", "720p", "1080p", "auto"],
    };
  },
  computed: {
    ...spacing,
    isCCM() {
      return this.$store.getters.isCCM;
    },
    queueListItems() {
      let result = [];
      if (this.isEdit) {
        const filteredList = this.queuesList.filter((queue) => {
          return queue.uuid !== this.queueDetail.uuid;
        });
        result = filteredList.map((queue) => {
          return { text: queue.name, value: queue.uuid };
        });
      } else {
        result = this.queuesList.map((queue) => {
          return { text: queue.name, value: queue.uuid };
        });
      }
      return result.sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    atLeastOneChannelIsSelected() {
      for (const channel of this.channelList) {
        if (this.selectedChannels[channel.value]) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.VALIDATION_TREE, this.validationTreeEvent);
    this.queueConfig = merge(this.queueConfig, this.queueDetail);
    const vq = this.queueConfig.channels.chat.features.audioVideo.videoQuality;
    this.videoQuality = this.ticksVideoQuality.indexOf(vq);
    // questa fix serve per la retrocompatibilità con le code vecchie
    if (!this.queueDetail.channels.chat.features?.audioVideo?.enabled) {
      this.queueConfig.channels.chat.features.audioVideo.enabled = false;
    }
    //retrocompatibilità per code create prima di introdurre il recording
    if (!this.queueDetail.channels.chat.features.recording) {
      this.queueDetail.channels.chat.features.recording = { enabled: false, autoStart: false };
    }
    this.janusIntegration = this.hasJanusIntegration();
    for (const channel of this.channelList) {
      this.selectedChannels[channel.value] = this.queueConfig.channels[channel.value].enabled ? channel.value : "";
    }
    this.timeoutSecondsToMinutes();

    if (this.isLegacyFormatted(this.queueConfig.contactResult)) {
      this.queueConfig.contactResult = this.convertToSortableFormat(this.queueConfig.contactResult);
    }

    this.isValidTree = this.categoryTreeIsValid();
    this.$refs.createQueueForm.resetValidation();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.VALIDATION_TREE, this.validationTreeEvent);
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    selectCallInitiation() {
      if (this.queueConfig.channels.chat.features.audioVideo.enabled) {
        if (!this.queueConfig.channels.chat.features.audioVideo.agent.startCall && !this.queueConfig.channels.chat.features.audioVideo.customer.startCall) {
          this.queueConfig.channels.chat.features.audioVideo.agent.startCall = true;
          this.queueConfig.channels.chat.features.audioVideo.customer.startCall = true;
        }
      }
    },
    changeSliderValue(value) {
      this.queueConfig.channels.chat.features.audioVideo.videoQuality = this.ticksVideoQuality[value];
    },
    maxTimeout(channel, timeout) {
      if (channel.value === "social" || channel.value === "webcallback" || channel.value === "email") {
        //Espresso in minuti
        if (timeout > 35791) {
          return "The max value is 35791";
        } else {
          return true;
        }
      } else {
        //Espresso in secondi
        if (timeout > 2147483) {
          return "The max value is 2147483";
        } else {
          return true;
        }
      }
    },
    timeoutSecondsToMinutes() {
      const chat = this.queueConfig.channels.chat;
      chat.sla.enabled = chat.sla.timeout !== 0;
      const social = this.queueConfig.channels.social;
      social.timeout = social.timeout / 60;
      social.timeoutPreferredAgent = social.timeoutPreferredAgent / 60;
      social.sla.timeout = social.sla.timeout / 60;
      social.sla.enabled = social.sla.timeout !== 0;
      const webcallback = this.queueConfig.channels.webcallback;
      webcallback.timeout = webcallback.timeout / 60;
      webcallback.timeoutPreferredAgent = webcallback.timeoutPreferredAgent / 60;
      webcallback.sla.timeout = webcallback.sla.timeout / 60;
      webcallback.sla.enabled = webcallback.sla.timeout !== 0;
      const email = this.queueConfig.channels.email;
      email.timeout = email.timeout / 60;
      email.timeoutPreferredAgent = email.timeoutPreferredAgent / 60;
      email.sla.timeout = email.sla.timeout / 60;
      email.sla.enabled = email.sla.timeout !== 0;
    },
    timeoutMinutesToSeconds() {
      const chat = this.queueConfig.channels.chat;
      chat.sla.enabled = chat.sla.timeout !== 0;
      const social = this.queueConfig.channels.social;
      social.timeout = social.timeout * 60;
      social.timeoutPreferredAgent = social.timeoutPreferredAgent * 60;
      social.sla.timeout = social.sla.timeout * 60;
      social.sla.enabled = social.sla.timeout !== 0;
      const webcallback = this.queueConfig.channels.webcallback;
      webcallback.timeout = webcallback.timeout * 60;
      webcallback.timeoutPreferredAgent = webcallback.timeoutPreferredAgent * 60;
      webcallback.sla.timeout = webcallback.sla.timeout * 60;
      webcallback.sla.enabled = webcallback.sla.timeout !== 0;
      const email = this.queueConfig.channels.email;
      email.timeout = email.timeout * 60;
      email.timeoutPreferredAgent = email.timeoutPreferredAgent * 60;
      email.sla.timeout = email.sla.timeout * 60;
      email.sla.enabled = email.sla.timeout !== 0;
    },
    enabledContactResult(val) {
      if (!val) {
        this.queueConfig.contactResult.required = false;
      }
      //this.isValidTree = !(val && Object.keys(this.queueConfig.contactResult.options).length === 0);
      this.isValidTree = !(val && this.queueConfig.contactResult.items === 0);
    },
    validationTreeEvent(val) {
      this.isValidTree = val;
    },
    categoryTreeIsValid() {
      // return !(this.queueConfig.contactResult.enabled && Object.keys(this.queueConfig.contactResult.options).length === 0);
      return !(this.queueConfig.contactResult.enabled && this.queueConfig.contactResult.items.length === 0);
    },
    toggleChannel(channel) {
      this.queueConfig.channels[channel.value].enabled = !this.queueConfig.channels[channel.value].enabled;
      this.selectedChannels[channel.value] = this.queueConfig.channels[channel.value].enabled ? channel.value : "";
    },
    async saveQueue() {
      try {
        this.form.submitLoading = true;
        // Se abilito autoaccept lascio il timeout rona al valore di default
        if (this.queueConfig.routing.autoaccept) {
          this.queueConfig.routing.timeoutRona = 20;
        }
        //traformo il tempo dei social che sono in minuti li metto in secondi
        this.timeoutMinutesToSeconds();
        if (!this.queueConfig.channels.chat.features.audioVideo.enabled) {
          this.queueConfig.channels.chat.features.audioVideo.autoStart = false;
          this.queueConfig.channels.chat.features.audioVideo.agent.enableScreenSharing = false;
          this.queueConfig.channels.chat.features.audioVideo.agent.enableVideo = false;
          this.queueConfig.channels.chat.features.audioVideo.agent.startCall = false;
          this.queueConfig.channels.chat.features.audioVideo.customer.enableScreenSharing = false;
          this.queueConfig.channels.chat.features.audioVideo.customer.enableVideo = false;
          this.queueConfig.channels.chat.features.audioVideo.customer.startCall = false;
          this.queueConfig.channels.chat.features.recording.enabled = false;
          this.queueConfig.channels.chat.features.recording.autoStart = false;
        } else {
          if (!this.queueConfig.channels.chat.features.recording.enabled) {
            this.queueConfig.channels.chat.features.recording.autoStart = false;
          }
        }
        //forzo il salvataggio a false se il recording janus non è abilitato
        if (!this.$store.state.loginResponse.params.serverConfigurations.enableJanusFeature) {
          this.queueConfig.channels.chat.features.recording.enabled = false;
          this.queueConfig.channels.chat.features.recording.autoStart = false;
        }
        if (this.queueConfig.routing.transfer.targets) {
          const availableERIs = this.queuesList.map((q) => q.eri.split(":")[4]);
          this.queueConfig.routing.transfer.targets = this.queueConfig.routing.transfer.targets.filter((queueERI) => availableERIs.includes(queueERI));
        }
        if (this.isEdit) {
          if (this.$refs.contactResultTree) {
            this.queueConfig.contactResult.items = this.$refs.contactResultTree.transformTreeToJSON();
          }
          const result = await this.$http.put("/human-agents/queue/" + this.queueConfig.uuid, this.queueConfig);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_QUEUE_SUCCESS, {
            editQueue: result.data,
            message: "Queue " + this.queueConfig.name + " updated",
          });
        } else {
          if (this.$refs.contactResultTree) {
            this.queueConfig.contactResult.items = this.$refs.contactResultTree.transformTreeToJSON();
          }
          let result = {};
          if (this.isCCM) {
            if (this.profilesList.length === 0) {
              result = await this.$http.post("/human-agents/queue-for-ccm", { queue: this.queueConfig, newProfile: this.newProfile });
            } else {
              result = await this.$http.post("/human-agents/queue-for-ccm", { queue: this.queueConfig, profileAssociated: this.profileAssociated });
            }
          } else {
            result = await this.$http.post("/human-agents/queue", this.queueConfig);
          }

          if (result.data.profile) {
            //ho creato anche il profile, emitto per aggiornare la lista profili
            EventBus.$emit(this.$store.getters.getEvents.EDIT_PROFILE_SUCCESS, result.data.profile);
          }
          EventBus.$emit(this.$store.getters.getEvents.EDIT_QUEUE_SUCCESS, {
            editQueue: result.data.queue ? result.data.queue : result.data,
            message: "Queue " + this.queueConfig.name + " created",
          });
        }
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.EDIT_QUEUE_FAIL, {
          editQueue: this.queueConfig,
          message: "Error while saving Queue " + this.queueConfig.name,
        });
      } finally {
        this.form.submitLoading = false;
      }
    },
    deleteQueue() {
      if (this.queueDetail.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_QUEUE_CREATION);
      }
    },
    async onDeleteQueue() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/human-agents/queue/" + this.queueDetail.uuid);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_QUEUE_SUCCESS, {
          queue: this.queueDetail.uuid,
          message: "Queue " + this.queueConfig.name + " Deleted!",
        });
      } catch (e) {
        let message = "Fail to delete " + this.queueDetail.name;
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        EventBus.$emit(this.$store.getters.getEvents.DELETE_QUEUE_FAIL, {
          queue: this.queueDetail.name,
          message: message,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    expansionClickEvent(expansionName) {
      this.expansionPanels.forEach((el, index) => {
        if (index !== expansionName) {
          this.expansionPanels[index] = null;
        }
      });
    },
    getAudioVideoLabel(label) {
      switch (label) {
        case "startCall": {
          return "Call initiation";
        }
        case "enableVideo": {
          return "Publish Video";
        }
        case "enableScreenSharing": {
          return "Screen sharing";
        }
      }
    },
    isLegacyFormatted(contactResult) {
      return (
        contactResult && contactResult.options && JSON.stringify(contactResult.options).length > 2 && (!contactResult.items || contactResult.items.length === 0)
      );
    },
    convertToSortableFormat(contactResult) {
      contactResult.items = this.toItems(contactResult.options);
      contactResult.options = {};
      //delete contactResult.options;
      return contactResult;
    },
    toItems(options) {
      return Object.entries(options)
        .sort()
        .map(([key, value]) => {
          return {
            value: key,
            items: Array.isArray(value) ? value.sort().map((v) => ({ value: v })) : this.toItems(value),
          };
        });
    },
  },
};
</script>

<style>
.queueform .v-slider {
  cursor: pointer;
}
</style>
