<template>
  <v-row align="center" justify="center">
    <v-col cols="12" class="px-12 text-right mt-n12">
      <span class="ml-2 small-text">{{ getPoint }} of {{ items }} elements</span>
    </v-col>
    <v-col v-if="getVisibility" cols="12" class="ma-0 pa-0">
      <v-row align="center">
        <v-col cols="4" offset="4" class="text-center">
          <v-pagination
            v-model="pageVal"
            :length="getLength"
            :class="getLength == 1 ? 'hideArrows' : ''"
            :total-visible="7"
            @input="updatePagination($event)"
          />
        </v-col>
        <v-col cols="4">
          <v-row class="px-12" align="end" justify="end">
            <v-menu top close-on-click left>
              <template #activator="{ on, attrs }">
                <v-btn class="itemPerPageBtn" :ripple="false" v-bind="attrs" v-on="on">
                  {{ elementsPerPage }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in getOptions" :key="index" @click="changePagination(item)">
                  <v-list-item-title class="text-right">
                    {{ item }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="ml-2 mr-2 small-text">items per page</span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["items", "page", "pageSize"],
  data() {
    return {
      elementsPerPage: this.pageSize,
      options: [10, 20, 30, 50, 100],
    };
  },
  computed: {
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("updatePage", val);
      },
    },
    getLength() {
      if (this.elementsPerPage > this.items) {
        return 1;
      }
      if (this.elementsPerPage) {
        return Math.ceil(this.items / this.elementsPerPage);
      }
      return 0;
    },
    getVisibility() {
      if (this.elementsPerPage > this.items && this.elementsPerPage != this.$store.state.pageSize) {
        return true;
      }
      if (Math.ceil(this.items / this.elementsPerPage) > 1) {
        return true;
      }
      return false;
    },
    getOptions() {
      return this.options.filter((opt) => opt != this.elementsPerPage);
    },
    getPoint() {
      if (this.elementsPerPage > this.items) {
        return 1 + "-" + this.items;
      }
      if (this.page == 1) {
        return 1 + "-" + this.page * this.elementsPerPage;
      }
      if (this.page * this.elementsPerPage > this.items) {
        let startItems = (this.page - 1) * this.elementsPerPage + 1;
        let end = this.items;
        return startItems + "-" + end;
      } else {
        let start = (this.page - 1) * this.elementsPerPage + 1;
        return start + "-" + this.page * this.elementsPerPage;
      }
    },
  },
  mounted() {
    if (!this.elementsPerPage) {
      this.elementsPerPage = this.page;
    }
  },
  methods: {
    updatePagination($event) {
      this.$emit("updatePage", $event);
    },
    changePagination(item) {
      this.elementsPerPage = item;
      this.$emit("updatePageSize", this.elementsPerPage);
    },
  },
};
</script>
<style lang="scss">
.itemPerPageBtn.theme--light {
  background-color: white !important;
}
.itemPerPageBtn {
  border-radius: 4px !important;
  border-bottom-width: 1px;
  border-image-width: 1px;
  font-size: 16px;
  font-stretch: 100%;
  font-weight: 400;
  line-height: 25px;
  height: 34px !important;
  min-width: 34px !important;
  max-width: 38px !important;
  text-decoration: none;
  width: auto;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  .v-btn__content {
    align-items: end;
  }
}
.small-text {
  display: block;
  font-size: 13px;
}
.hideArrows ul li:first-of-type,
.hideArrows ul li:last-of-type {
  visibility: hidden !important;
}
</style>
