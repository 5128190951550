<template v-resize="onResizeScreen">
  <v-container fluid>
    <!-- dialog creazione/edit intenti -->
    <v-dialog v-model="editIntentDialog" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Intent</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formNewIntent" v-model="intentFormValid">
            <v-text-field
              v-model.trim="newItem.key"
              :rules="[requiredField, validateIntentName]"
              label="Label"
              :error-messages="checkIfIntentAlreadyExists"
              validate-on-blur
              :autocomplete="$store.getters.disableAutocomplete"
              @keypress.enter.stop="() => {}"
            />
            <v-text-field
              v-model.trim="newTag"
              prepend-inner-icon="mdi-plus"
              class="my-2"
              label="Add new synonym"
              :error-messages="tagAlreadyExists"
              :autocomplete="$store.getters.disableAutocomplete"
              hint="Press enter to insert"
              @keypress.enter.stop="addNewTagToIntent"
            />
            <v-chip-group column>
              <v-chip v-for="(tag, index) in newItem.value.tags" :key="index" close color="primary" @click:close="deleteIntentTag(index)">
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </v-form>
        </v-card-text>
        <v-card-actions :class="getFormRowMargin">
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn :class="getButtonMargin" color="success" :disabled="!enableFormButton" @click="saveIntent">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
            <v-btn v-if="editedIndex == -1" :class="getButtonMargin" color="primary" :disabled="!enableFormButton" @click="addMoreIntent">
              <v-icon left>
                mdi-plus
              </v-icon>Add More
            </v-btn>
            <v-btn :class="getButtonMargin" color="error" @click="closeDialog">
              <v-icon left>
                mdi-close
              </v-icon>discard
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog cancellazione intenti -->
    <ConfirmationDialog
      :showConfirm="deleteDialog"
      :message="'Intent ' + selectedIntentName + ' will be deleted permanently'"
      @dialogDeny="closeDeleteIntentDialog"
      @dialogConfirm="confirmDeleteIntent"
    />
    <!-- dialog per la creazione entità -->
    <v-dialog v-model="editEntityDialog" max-width="900px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">New Entity</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formNewEntity" v-model="entityFormValid">
            <v-col class="mb-2 mx-0">
              <v-text-field
                v-model.trim="editedEntityLabel"
                :rules="[requiredField]"
                label="Entity Name"
                :disabled="isEntityEdit"
                :error-messages="checkIfEntityAlreadyExists"
                :autocomplete="$store.getters.disableAutocomplete"
              />
              <v-text-field
                v-model.trim="newEntity.validation_expression"
                :rules="[requiredField]"
                label="Validation Expression"
                :autocomplete="$store.getters.disableAutocomplete"
                hint="The entity value is contained in %value% variable. To validate a non empty string you can use '%value%'.length>0"
              />
            </v-col>
            <v-col>
              <b>Extraction Patterns</b>
              <v-row
                v-for="(pattern, index) in newEntity.extraction_pattern"
                :key="'newentity_' + index"
                class="justify-space-between my-0 py-0"
                align="center"
              >
                <v-col>
                  <v-text-field
                    v-model.trim="newEntity.extraction_pattern[index].pattern"
                    :rules="[requiredField]"
                    :placeholder="'Extraction Pattern ' + (index + 1)"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model.trim="newEntity.extraction_pattern[index].associated_value"
                    placeholder="Normalized value"
                    :autocomplete="$store.getters.disableAutocomplete"
                    hint="Optional. Will be used as entity value if this extraction pattern match"
                  />
                </v-col>
                <v-col cols="1" align-self="center">
                  <v-btn v-if="index > 0 || newEntity.extraction_pattern.length > 1" color="error" icon @click="deleteExtractionPattern(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1" align-self="center">
                  <v-btn color="primary" :disabled="isNewPatternDisabled(newEntity.extraction_pattern[index])" icon @click="addExtractionPattern">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions :class="getFormRowMargin">
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn :class="getButtonMargin" color="success" :disabled="!enableEntityFormButton" @click="saveEntity">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
            <v-btn :class="getButtonMargin" color="error" @click="closeCreateEntityDialog">
              <v-icon left>
                mdi-close
              </v-icon>Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog cancellazione entità -->
    <ConfirmationDialog
      :showConfirm="confirmDeleteEntityDialog"
      :message="'Entity ' + entityNameToDelete + ' will be deleted permanently'"
      @dialogDeny="confirmDeleteEntityDialog = false"
      @dialogConfirm="confirmDeleteEntity"
    />

    <v-tabs v-model="tab" background-color="transparent" color="primary" grow @change="searchString = ''">
      <v-tab>Intents</v-tab>
      <v-tab>Entities</v-tab>
    </v-tabs>
    <v-toolbar flat class="toolBarIntentsEntity">
      <v-btn small color="primary" :to="'/semanticEngines?engineName=' + engineName">
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        <span>back</span>
      </v-btn>
      <v-spacer />
      <v-row align="center">
        <v-col offset="3" cols="9">
          <v-text-field
            v-model="searchString"
            dense
            width="200px"
            hide-details
            outlined
            clearable
            label="Search..."
            prepend-inner-icon="search"
            @input="$emit('input', $event ? $event : '')"
          />
        </v-col>
      </v-row>
    </v-toolbar>
    <v-tabs-items v-model="tab" class="mt-2 mx-2" style="width: 100% !important; height: 60vh">
      <v-tab-item>
        <v-simple-table fixed-header :height="calcDatatableHeight">
          <template #default>
            <thead>
              <tr>
                <th style="width: 5%" class="text-left" />
                <th style="width: 25%" class="text-left">
                  Label
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        small
                        color="primary"
                        class="ml-4"
                        @click="sortIntents('key')"
                        v-on="on"
                      >
                        <v-icon small>
                          mdi-sort-alphabetical-descending
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Sort Alphabetically</span>
                  </v-tooltip>
                </th>
                <th style="width: 55%" class="text-left">
                  Value
                </th>
                <th style="width: 15%" class="text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <draggable v-model="datasetIntentsCopy" tag="tbody" handle=".sortHandle">
              <tr v-for="(intent, index) in getFilteredDatasetIntents" :key="index">
                <template v-if="intent.key != ''">
                  <td class="hidden-sm-and-down text-start">
                    <v-btn
                      x-small
                      text
                      color="primary"
                      style="cursor: move"
                      icon
                      class="sortHandle ma-0 pa-0"
                    >
                      <v-icon>mdi-cursor-move</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ intent.key }}</td>
                  <td>
                    <v-row>
                      <v-chip v-for="(tag, index) in intent.value.tags" :key="'intentchip_' + index" color="#035075" class="mr-2 mt-2" dark>
                        {{ tag }}
                      </v-chip>
                    </v-row>
                  </td>
                  <td>
                    <v-row no-gutters>
                      <v-icon class="mr-4" @click="editIntent(intent)">
                        mdi-pencil
                      </v-icon>
                      <v-icon @click="deleteIntent(intent)">
                        mdi-delete
                      </v-icon>
                    </v-row>
                  </td>
                </template>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
        <v-row no-gutters class="mt-1">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="mb-n8" color="primary" small v-on="on" @click="openEditDialog">
                <v-icon left>
                  mdi-plus
                </v-icon>Add Intent
              </v-btn>
            </template>
            <span>Add new Intent</span>
          </v-tooltip>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text :style="{ height: calcDatatableHeight + 'px' }">
            <span v-for="(entity, index) in getEntities" :key="'entitychip_' + index">
              <v-chip
                v-if="entity.value.custom"
                class="mt-2 mr-2"
                color="#035075"
                dark
                close
                @click:close="deleteEntity(entity.label)"
                @click="editEntity(entity)"
              >{{ entity.label }}</v-chip>
              <v-chip v-else class="mt-2 mr-2" color="#035075" dark>{{ entity.label }}</v-chip>
            </span>
          </v-card-text>
          <v-card-actions class="px-0">
            <v-btn color="primary" small @click="openCreateEntityDialog">
              <v-icon left>
                mdi-plus
              </v-icon>Add Entities
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import ConfirmationDialog from "../../other/ConfirmationDialog";
import EventBus from "../../../event-bus";
import draggable from "vuedraggable";
import merge from "deepmerge";

export default {
  name: "EudataEngine",
  components: {
    draggable,
    ConfirmationDialog,
  },
  props: ["semanticEngine", "datasetIntents", "engineName", "originalIntents"],
  data() {
    return {
      //v-model del tab navigator
      tab: 0,
      cardHeight: 0,
      searchString: "",

      datasetIntentsCopy: [],
      //Intenti
      editIntentDialog: false,
      deleteDialog: false,
      intentFormValid: false,
      selectedIntentName: "",
      deleteIntentIndex: -1,
      editedIndex: -1,

      deleteIntentMessage: "",
      newItem: {
        key: "",
        value: {
          tags: [],
        },
      },
      tagAlreadyExists: null,
      newTag: "",
      forceRerender: 0,

      //Entità
      editEntityDialog: false,
      editedEntityLabel: "",
      isEntityEdit: false,
      entityFormValid: false,
      forceEntityRerender: 0,
      newEntity: {
        custom: true,
        validation_expression: "",
        extraction_pattern: [
          {
            pattern: "",
            associated_value: "",
          },
        ],
        type: "basic",
      },
      entityNameToDelete: null,
      confirmDeleteEntityDialog: false,
    };
  },
  computed: {
    ...spacing,
    calcDatatableHeight() {
      return this.cardHeight - 330;
    },
    enableFormButton() {
      return this.intentFormValid && this.newItem.value.tags.length > 0;
    },
    enableEntityFormButton() {
      return this.entityFormValid && this.newEntity.extraction_pattern.length > 0;
    },
    getFilteredDatasetIntents() {
      this.forceRerender;
      if (this.searchString == null) {
        return Object.values(this.datasetIntentsCopy);
      }
      return (
        Object.values(this.datasetIntentsCopy)
          //Filtro per la ricerca
          .filter((intent) => intent.key.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
      );
    },
    // computed per enetities
    getEntities() {
      this.forceEntityRerender;
      let entities = [];
      if (this.semanticEngine) {
        let index = 0;
        for (let entity in this.semanticEngine.entity_types) {
          entities.push({
            label: entity,
            value: this.semanticEngine.entity_types[entity],
          });
          if (entities[index].value.custom) {
            for (let valuePattern in entities[index].value.extraction_pattern) {
              if (!entities[index].value.extraction_pattern[valuePattern].pattern) {
                let formattedPattern = {
                  pattern: entities[index].value.extraction_pattern[valuePattern],
                  associated_value: "",
                };
                entities[index].value.extraction_pattern[valuePattern] = formattedPattern;
              }
            }
          }
          index++;
        }
      }
      return (
        entities
          //Filtro per la ricerca
          .filter((entity) => entity.label.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
    checkIfIntentAlreadyExists() {
      if (this.editedIndex > -1) {
        for (let i = 0; i < this.datasetIntentsCopy.length; i++) {
          if (this.datasetIntentsCopy[i].key.toLowerCase() == this.newItem.key.toLowerCase().trim() && i != this.editedIndex) {
            return "Inent Already Exist";
          }
        }
        return "";
      } else {
        for (let i = 0; i < this.datasetIntentsCopy.length; i++) {
          if (this.datasetIntentsCopy[i].key.toLowerCase() == this.newItem.key.toLowerCase().trim()) {
            return "Inent Already Exist";
          }
        }
      }
      return "";
    },
    checkIfEntityAlreadyExists() {
      if (this.semanticEngine && !this.isEntityEdit) {
        return this.semanticEngine.entity_types[this.editedEntityLabel.trim()] ? "Entity Already Exists" : "";
      } else {
        return "";
      }
    },
  },
  mounted() {
    let card = document.querySelector(".semanticEngineMainCard");
    if (card) {
      this.cardHeight = card.offsetHeight;
    }
    window.addEventListener("resize", this.onResizeScreen);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeScreen);
  },
  methods: {
    ...fieldValidators,
    onResizeScreen() {
      let card = document.querySelector(".semanticEngineMainCard");
      if (card) {
        this.cardHeight = card.offsetHeight;
      }
    },
    setDataset(dataset) {
      this.datasetIntentsCopy = merge({}, dataset);
    },
    saveIntent() {
      this.newItem.key = this.newItem.key.trim();
      if (this.editedIndex > -1) {
        let value = this.datasetIntentsCopy[this.editedIndex].value.tags.join(",");
        this.originalIntents[value] = {
          originalKey: this.datasetIntentsCopy[this.editedIndex].key,
          originalValue: value,
          newDataKey: this.newItem.key,
          newDataValue: this.newItem.value.tags.join(","),
        };
        Object.assign(this.datasetIntentsCopy[this.editedIndex], this.newItem);
      } else {
        this.datasetIntentsCopy.push(this.newItem);
      }
      this.forceRerender++;
      this.closeDialog();
    },
    openEditDialog() {
      if (this.tab == 0) {
        this.resetNewIntents();
        if (this.$refs.formNewIntent) {
          this.$refs.formNewIntent.resetValidation();
        }
        this.editIntentDialog = true;
      } else {
        this.resetNewEntity();
        if (this.$refs.formNewEntity) {
          this.$refs.formNewEntity.resetValidation();
        }
        this.editEntityDialog = true;
      }
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    editIntent(intent) {
      this.editedIndex = this.datasetIntentsCopy.indexOf(intent);
      this.newItem = merge({}, intent);
      this.editIntentDialog = true;
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    deleteIntentTag(index) {
      this.newItem.value.tags.splice(index, 1);
    },
    closeDeleteIntentDialog() {
      this.deleteIntentIndex = -1;
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.deleteDialog = false;
    },
    deleteIntent(intent) {
      this.deleteIntentIndex = this.datasetIntentsCopy.indexOf(intent);
      this.selectedIntentName = intent.key;
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.deleteDialog = true;
    },
    closeDialog() {
      this.editedIndex = -1;
      this.editIntentDialog = false;
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    confirmDeleteIntent() {
      this.datasetIntentsCopy.splice(this.deleteIntentIndex, 1);
      this.closeDeleteIntentDialog();
    },
    addNewTagToIntent() {
      this.tagAlreadyExists = null;
      if (this.newTag.trim() !== "" && !this.newItem.value.tags.includes(this.newTag.trim())) {
        this.newItem.value.tags.push(this.newTag.trim());
        this.newTag = "";
      } else {
        this.tagAlreadyExists = "Tag Already Exist";
      }
    },
    addMoreIntent() {
      this.newItem.key = this.newItem.key.trim();
      if (this.editedIndex > -1) {
        Object.assign(this.datasetIntentsCopy[this.editedIndex], this.newItem);
      } else {
        this.datasetIntentsCopy.push(this.newItem);
      }
      this.forceRerender++;
      this.resetNewIntents();
    },
    resetNewIntents() {
      this.newItem = merge(
        {},
        {
          key: "",
          value: {
            tags: [],
          },
        },
      );
    },
    // methods per entities
    isNewPatternDisabled(item) {
      return item.pattern.trim() === "";
    },
    resetNewEntity() {
      this.editedEntityLabel = "";
      this.newEntity = merge(
        {},
        {
          custom: true,
          extraction_pattern: [
            {
              pattern: "",
              associated_value: "",
            },
          ],
          type: "basic",
          validation_expression: "",
        },
      );
    },
    openCreateEntityDialog() {
      this.resetNewEntity();
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.editEntityDialog = true;
    },
    closeCreateEntityDialog() {
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
      this.editEntityDialog = false;
      this.isEntityEdit = false;
    },
    editEntity(entity) {
      this.isEntityEdit = true;
      this.editedEntityLabel = entity.label;
      this.newEntity = merge({}, entity.value);
      this.editEntityDialog = true;
    },
    deleteExtractionPattern(index) {
      this.newEntity.extraction_pattern.splice(index, 1);
    },
    addExtractionPattern() {
      this.newEntity.extraction_pattern.push({
        pattern: "",
        associated_value: "",
      });
    },
    saveEntity() {
      if (!this.semanticEngine.entity_types) this.semanticEngine.entity_types = {};
      this.semanticEngine.entity_types[this.editedEntityLabel.trim()] = this.newEntity;
      this.semanticEngine.engines[this.engineName].types[this.editedEntityLabel] = this.editedEntityLabel;
      this.editEntityDialog = false;
      this.resetNewEntity();
      this.forceEntityRerender++;
      this.isEntityEdit = false;
    },
    deleteEntity(name) {
      this.entityNameToDelete = name;
      this.confirmDeleteEntityDialog = true;
      EventBus.$emit(this.$store.getters.getEvents.DISABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    confirmDeleteEntity() {
      delete this.semanticEngine.entity_types[this.entityNameToDelete];
      delete this.semanticEngine.engines[this.engineName].types[this.entityNameToDelete];
      this.entityNameToDelete = null;
      this.confirmDeleteEntityDialog = false;
      this.forceEntityRerender++;
      EventBus.$emit(this.$store.getters.getEvents.ENABLE_ENTER_TO_UPDATE_SEMANTIC_ENGINE);
    },
    getDatasetIntentsUpdated() {
      return this.datasetIntentsCopy;
    },
    sortIntents() {
      this.datasetIntentsCopy.sort((a, b) => {
        if (a.key.toLowerCase() > b.key.toLowerCase()) {
          return 1;
        }
        if (a.key.toLowerCase() < b.key.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.toolBarIntentsEntity .v-toolbar__content {
  padding: 0px !important;
}
</style>
