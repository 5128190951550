<template>
  <v-col cols="12">
    <v-row
      align="center"
      justify="center"
      class="dropZone d-flex ma-0 pa-0"
      :class="{ dropZoneHover: hovered }"
      @drop="dropFile"
      @dragenter="hovered = true"
      @mouseleave="hovered = false"
      @dragover="dragover"
    >
      <label>
        <v-col cols="12" class="dropInput">
          <span v-if="!file" class="text-center">
            <div><v-icon large :color="hovered ? 'primary' : ''">mdi-cloud-upload</v-icon></div>
            <div>
              Drag and drop, or <span :style="hovered ? 'color:#1d9096;font-weight:600' : 'color:#1d9096'">browse</span> your
              {{ fileAccepted == "image" ? "image" : "zip file" }}
            </div>
          </span>
          <span v-if="file && file.name">
            <v-row v-if="isValidFile" align="center" justify="space-between">
              <div>
                <v-avatar :size="isLogo ? 80 : 50" style="background-color: #2a3c51">
                  <img v-if="isLogo" :src="previewImage" contain />
                  <v-icon dark v-else>mdi-folder-zip</v-icon>
                </v-avatar>
                <span class="mx-4">{{ file.name }}</span>
              </div>
              <v-btn small color="error" @click.prevent.stop="removeFile" :disabled="loading"> remove </v-btn>
            </v-row>
            <v-row v-else align="center" justify="space-between">
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="error lighten-2">
                      <v-icon color="#EDEDED"> mdi-alert </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ getTooltip }}</span>
                </v-tooltip>
                <span class="mx-4">{{ file.name }} does not seem to be in a correct format!</span>
              </div>
              <v-btn color="error" small @click.prevent.stop="removeFile" :disabled="loading"> try again </v-btn>
            </v-row>
          </span>
        </v-col>
        <input v-if="!file || file.url" :accept="accept" :rules="maxSizeRule" v-show="false" type="file" id="upload" @change="handleFileChange($event)" />
      </label>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Upload",
  props: ["file", "fileAccepted", "isLogo", "loading"],
  data() {
    return {
      hovered: false,
      maxSizeRule: [(value) => !value || value.size < 10000000 || "ZIP size should be less than 10 MB!"],
      preview: "",
      accept: "",
    };
  },
  mounted() {
    if (this.fileAccepted == "image") {
      this.accept = "image/png, image/jpeg, image/bmp";
    }
    if (this.fileAccepted == "zip") {
      this.accept = "application/zip, application/x-zip-compressed";
    }
  },
  methods: {
    handleFileChange(event) {
      event.preventDefault();
      this.$emit("uploadFile", { isLogo: this.isLogo, file: event.target.files[0] });
    },
    dropFile(event) {
      event.preventDefault();
      this.$emit("uploadFile", { isLogo: this.isLogo, file: event.dataTransfer.files[0] });
    },
    dragover(event) {
      event.preventDefault();
    },
    removeFile() {
      this.$emit("removeFile");
    },
  },
  computed: {
    isValidFile() {
      if (this.accept.includes(this.file.type)) {
        return true;
      }
      return false;
    },
    previewImage() {
      var input = this.file;
      if (input) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(input);
        return this.preview;
      }
      return "";
    },
    getTooltip() {
      if (this.fileAccepted == "image") {
        return "Engagement button's background can only be an image. Please try again with an image file, we always recommend you a 'png'.";
      }
      return "The customer gui can be uploaded only if in .zip format";
    },
  },
};
</script>
<style scoped lang="scss">
.theme--dark .dropZone {
  background-color: #455a64;
  border: 2px dashed rgba(211, 211, 211, 0.8);
}
.theme--light .dropZone {
  background-color: rgba(211, 211, 211, 0.4);
  border: 2px dashed rgba(211, 211, 211, 1);
}
.dropZone {
  align-items: center;
  justify-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 120px;
  width: 100%;
  padding: 0;
  span {
    width: 100%;
  }
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
  label,
  .dropInput {
    display: flex;
    height: 120px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 120px;
    width: 100%;
    background-color: transparent;
    cursor: pointer !important;
    padding: 0px 20px;
    span {
      width: 100%;
    }
  }
}
.theme--light {
  .dropZone:hover,
  .theme--light .dropZoneHover {
    background-color: #1d909629;
    border: 2px dashed #1d9096;
  }
}
.theme--dark {
  .dropZone:hover,
  .theme--dark .dropZoneHover {
    background-color: #34434c !important;
    border: 2px dashed #1d9096 !important;
  }
}
</style>
