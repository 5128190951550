<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
        <v-text-field readonly disabled label="Webhook" v-model="editedConnector.webhookUrl" prepend-inner-icon="mdi-webhook" :rules="[requiredField]" />
        <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
      </v-col>
    </v-row>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" md="6" v-if="!editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Telegram Bot Name"
          v-model.trim="editedConnector.details.appName"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, isConnectorAppIdUnique(connectors, editedConnector.details.appName)]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Access Token"
          v-model.trim="editedConnector.details.accessToken"
          prepend-inner-icon="mdi-lock"
          :type="showSecretToken ? 'text' : 'password'"
          autocomplete="new-password"
          :rules="[requiredField]"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="editedConnector.details.accessToken && editedConnector.details.accessToken.length < 1" tabindex="-1">
              <v-icon tabindex="-1" v-show="!showSecretToken" @click="showSecretToken = !showSecretToken"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showSecretToken" @click="showSecretToken = !showSecretToken"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-select
          label="Message parse mode"
          :items="['Markdown', 'HTML']"
          v-model="editedConnector.details.parse_mode"
          prepend-inner-icon="mdi-code-tags"
          :rules="[requiredField]"
          persistent-hint
          hint="Could be Markdown or HTML. See Telegram documentation about message formatting https://core.telegram.org/bots/api#sendmessage"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          label="API URL"
          v-model.trim="editedConnector.details.apiURL"
          prepend-inner-icon="mdi-web"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";

export default {
  name: "TelegramConf",
  props: ["editedConnector", "connectors"],
  components: { CopyToClipboard },
  data() {
    return {
      showSecretToken: false,
    };
  },
  mounted() {
    this.editedConnector.type = "telegram";
    if (!this.editedConnector.uuid) {
      this.editedConnector.details.apiURL = "https://api.telegram.org/bot";
      this.editedConnector.details.parse_mode = "Markdown";
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
