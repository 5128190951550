<template>
  <v-container id="surveysPage" fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Surveys"
      @input="
        searchString = $event;
        forceRender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getSurveysList.length == 0 && !newConfig && searchString == ''" cols="12">
        <v-alert type="warning">
          No Surveys configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getSurveysList.length == 0 && !newConfig && searchString != ''" cols="12">
        <v-alert type="warning">
          No Surveys match your search
        </v-alert>
      </v-col>
      <!-- Panel for new item -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Survey
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <SurveyForm :queuesList="queues" :surveysList="surveysList" :surveySetting="newConfig" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel to list configuration -->

      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(surveySetting, id) in getSurveysList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col class="text-no-wrap" cols="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ surveySetting.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                      <v-list-item-icon class="ma-0 justify-center">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              :color="getStatus(surveySetting).color"
                              class="ma-0 ml-1"
                              x-small
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>
                                {{ getStatus(surveySetting).icon }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ getStatus(surveySetting).tooltip }}</span>
                        </v-tooltip>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Questions</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip v-if="surveySetting.details" class="queue-global-chip" color="success" small>
                          {{ surveySetting.details.length }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down text-center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Available on</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-chip v-if="surveySetting.queues" class="queue-global-chip" color="success" small>
                          {{ surveySetting.queues.length }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-md-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="surveySetting.updated_at">
                        {{ surveySetting.updated_at | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <SurveyForm :key="forceRender + id + '_sc'" :queuesList="queues" :surveysList="surveysList" :surveySetting="surveySetting" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="filtredSurveysList.length > 1"
      :items="filtredSurveysList.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton v-if="!isCCMDisableProfile" name="Survey" @addNewItem="addNewConfig" />
  </v-container>
</template>

<script>
import Pagination from "../../components/other/Pagination";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import SurveyForm from "../../components/humanAgents/ContactSurveyForm";

export default {
  name: "ContactSurvey",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    SurveyForm,
    Pagination,
  },
  data() {
    return {
      expansionPanelCreation: null,
      expansionPanel: null,
      surveysList: null,
      forceRender: 0,
      searchString: "",
      //Data bind per creazione nuova configurazione
      newConfig: null,
      showWarning: false,
      queues: [],
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
      filtredSurveysList: [],
    };
  },
  computed: {
    ...spacing,
    getSurveysList() {
      this.forceRender;
      let filterData = this.filterData();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    isCCMDisableProfile() {
      const user = this.$store.getters.getUser;
      if (user?.roles?.["eudata:convyai"]?.role === this.$store.getters.getRoles.CONTACTCENTERMANAGER && this.queues && this.queues.length === 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_SURVEY_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SURVEY, this.editSurvey);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SURVEY, this.deleteSurvey);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SURVEY_FAIL, this.editSurveyFail);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SURVEY_FAIL, this.deleteSurveyFail);
    EventBus.$on(this.$store.getters.getEvents.CLONE_SURVEY, this.cloneSurvey);
    //Load Configurations
    let response = null;
    try {
      response = await this.$http.get("/human-agents/queue");
      this.queues = response.data;
      this.queues = this.queues.sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });
    } finally {
      response = null;
    }
    await this.getSurveyRequest();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_SURVEY_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SURVEY, this.editSurvey);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SURVEY, this.deleteSurvey);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SURVEY_FAIL, this.editSurveyFail);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SURVEY_FAIL, this.deleteSurveyFail);
  },
  methods: {
    ...scroller,
    getStatus(survey) {
      let result = {
        color: "success",
        icon: "mdi-lock-open-variant-outline",
        tooltip: "Unlocked with associated queues",
        blocked: false,
      };
      // eslint-disable-next-line no-prototype-builtins
      if (survey.hasOwnProperty("blocked")) {
        if (survey.blocked) {
          result.blocked = true;
          if (survey.queues.length > 0) {
            result.color = "primary";
            result.icon = "mdi-lock-outline";
            result.tooltip = "Locked with associated queues";
          } else {
            result.color = "error";
            result.icon = "mdi-lock-outline";
            result.tooltip = "Locked with no associated queues";
          }
        }
      } else {
        if (survey.queues.length == 0) {
          result.color = "primary";
          result.icon = "mdi-lock-open-variant-outline";
          result.tooltip = "Unlocked with no associated queues";
        }
      }
      return result;
    },
    async getSurveyRequest() {
      try {
        const result = await this.$http.get("/human-agents/survey");
        this.surveysList = Object.assign({}, result.data);
      } catch (e) {
        this.$refs.resultSnackbar.showError(`Fail to load Surveys`);
      } finally {
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
        this.showWarning = true;
      }
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    cloneSurvey(clonedSurvey) {
      this.newConfig = JSON.parse(JSON.stringify(clonedSurvey));
      delete this.newConfig.uuid;
      delete this.newConfig.eri;
      delete this.newConfig.created_by;
      delete this.newConfig.blocked;
      this.newConfig.queues = [];
      this.newConfig.updated_at = null;
      this.newConfig.name = "";
      this.addNewConfig();
    },
    addNewConfig() {
      if (!this.newConfig) {
        this.newConfig = {
          eri: null,
          uuid: null,
          updated_at: null,
          name: "",
          details: [
            {
              question: "",
              type: "text",
              answers: [],
            },
          ],
          required: true,
          queues: [],
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
    },
    async editSurvey(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      this.closeAllPanels();
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
      await this.getSurveyRequest();
      this.forceRender++;
    },
    editSurveyFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteSurvey(obj) {
      if (this.surveysList[obj.uuid]) {
        delete this.surveysList[obj.uuid];
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRender++;
        }, 100);
      }
    },
    deleteSurveyFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    filterData() {
      this.surveysList
        ? (this.filtredSurveysList = Object.values(this.surveysList)
            //Filtro per la ricerca
            .filter(
              (survey) =>
                survey.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
                survey.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
            )
            //Sorting per un campo
            .sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              return 0;
            }))
        : (this.filtredSurveysList = []);
      return this.filtredSurveysList;
    },
  },
};
</script>

<style>
#surveysPage .queue-global-chip.theme--dark {
  background-color: #86b9a8 !important;
}
#surveysPage .queue-number-chip.theme--dark {
  background-color: #1d9096 !important;
}
</style>
