<template>
  <div id="whatsapp_template_configurations">
    <!-- name -->
    <v-select
      v-model="property.templateName.value"
      :items="getTemplateList"
      label="Select Template"
      item-text="name"
      :item-value="getValue"
      return-object
      :rules="[validateSelectTemplate]"
      @change="changeTemplate"
    >
      <template #item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title class="pl-3" v-html="data.item.name" />
          </v-list-item-content>
        </template>
      </template>
    </v-select>

    <div v-if="property.templateName.value">
      <!-- select type of header -->
      <v-row class="mx-0 mt-1 mb-4 pa-0" no-gutters>
        <div>Header type: {{ property.headerType.valueSelected }}</div>
      </v-row>

      <!-- url -->
      <v-textarea
        v-if="property.headerType.valueSelected != 'None' && property.headerType.valueSelected != 'LOCATION' && property.headerType.valueSelected != 'TEXT'"
        v-model.trim="property.headerSettings.value.url"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Url"
        :autocomplete="$store.getters.disableAutocomplete"
        rows="1"
        auto-grow
        :rules="[requiredField, isURLorVariable]"
      >
        <template #append>
          <v-tooltip left content-class="ma-0 pa-1" max-width="130" max-height="130" :rules="[isImage]">
            <template #activator="{ on }">
              <v-icon v-show="showImageFromUrl" v-on="on">
                mdi-eye
              </v-icon>
            </template>
            <v-img class="ma-0 pa-0" :src="property.headerSettings.value.url" />
            <span class="caption">Preview image</span>
          </v-tooltip>
        </template>
      </v-textarea>

      <!-- document name -->
      <v-textarea
        v-if="property.headerType.valueSelected == 'DOCUMENT'"
        v-model.trim="property.headerSettings.value.documentName"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Document name"
        :autocomplete="$store.getters.disableAutocomplete"
        rows="1"
        auto-grow
        :rules="[requiredField]"
      />

      <!-- location -->
      <template v-if="property.headerType.valueSelected == 'LOCATION'">
        <v-textarea
          v-model.trim="property.headerSettings.value.latitude"
          class="mx-0 mt-1 mb-2 pa-0"
          label="Latitude"
          :autocomplete="$store.getters.disableAutocomplete"
          rows="1"
          auto-grow
          :rules="[requiredField, isNumber]"
        />
        <v-textarea
          v-model.trim="property.headerSettings.value.longitude"
          class="mx-0 mt-1 mb-2 pa-0"
          label="Longitude"
          :autocomplete="$store.getters.disableAutocomplete"
          rows="1"
          auto-grow
          :rules="[requiredField, isNumber]"
        />
      </template>

      <!-- language -->
      <v-textarea
        v-model.trim="property.language.value"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Language"
        :autocomplete="$store.getters.disableAutocomplete"
        rows="1"
        auto-grow
        :rules="[requiredField]"
      />

      <!-- Configurazione numeri telefono -->
      <v-row v-if="enableSelectPhoneNumber" no-gutters justify="center" align="center">
        <v-btn-toggle v-model="property.conditionPhoneNumber.value" active-class="primary white--text" mandatory>
          <v-btn small>
            Current User
          </v-btn>
          <v-btn small>
            Multiple
          </v-btn>
          <v-btn small>
            Dynamic
          </v-btn>
        </v-btn-toggle>
      </v-row>
      <div class="mt-3">
        <v-combobox
          v-if="property.conditionPhoneNumber.value == 1"
          v-model="property.phoneNumber.value.multiple"
          label="insert a list of phone numbers"
          multiple
          color="primary"
          chips
          :rules="[requiredField]"
          type="number"
        />
        <v-textarea
          v-if="property.conditionPhoneNumber.value == 2"
          v-model.trim="property.phoneNumber.value.dynamic"
          class="mx-0 my-0"
          label="Phone number list variable"
          :autocomplete="$store.getters.disableAutocomplete"
          rows="1"
          auto-grow
          :rules="[requiredField, isVariableField]"
        />
      </div>

      <!-- header type TEXT -->
      <v-row v-if="property.headerType.valueSelected == 'TEXT'" class="mx-0 mt-1 pa-0" no-gutters>
        <div><b>Header Text:</b> {{ headerText }}</div>
      </v-row>

      <!-- Body Message -->
      <v-row class="mx-0 mt-1 pa-0" no-gutters>
        <v-col cols="12" class="ma-0 pa-0">
          <b>Body Message:</b>
          <div class="templateCode">
            <code class="my-4 py-4 px-8" :class="$vuetify.theme.dark ? 'dark-code' : 'light-code'" v-text="templateBodySelected" />
          </div>
        </v-col>
      </v-row>

      <!-- Buttons -->
      <v-row v-if="property.buttons.value.length > 0" class="mx-0 mt-1 mb-2 pa-0" no-gutters>
        <div><b>Buttons:</b> {{ getTemplateButtons }}</div>
      </v-row>

      <!-- body -->
      <template v-if="property.conditionPhoneNumber.value < 2 && templateBodyPlaceholders != 0">
        <span class="subtitle-2">Add a parameter for each placeholder in the message body</span>
        <v-row v-for="(item, index) in templateBodyPlaceholders" :key="item + '_' + index" no-gutters>
          <v-col cols="2" class="d-flex align-center">
            <code :class="$vuetify.theme.dark ? 'dark-code' : 'light-code'" class="align-self-center text-center pt-1 pb-1">{{ getPlaceholder(index) }}</code>
          </v-col>
          <v-col cols="10" class="pl-2">
            <v-textarea v-model.trim="property.body.value[index]" :autocomplete="$store.getters.disableAutocomplete" rows="1" :rules="[requiredField]" />
          </v-col>
        </v-row>
      </template>
      <!-- Response variable in case of buttons -->
      <div v-if="property.buttons.value.length > 0 && allButtonsAreQuickReply" class="mt-3">
        <v-textarea
          v-model.trim="property.responseVariable.value"
          class="mx-0 my-0"
          label="Set a response variable"
          :autocomplete="$store.getters.disableAutocomplete"
          rows="1"
          auto-grow
          :rules="[requiredField, isVariableField]"
        />
      </div>
    </div>

    <!-- Switch nascosto per check sul numero di parametri che devono matchare -->
    <v-switch v-show="false" v-model="matchBodyWithMarkers" :rules="[(v) => v]" />
  </div>
</template>

<script>
import fieldValidators from "@/helpers/fieldValidators";
import whatsappTemplate from "@/helpers/whatsappTemplate";

export default {
  name: "WhatsappTemplateConfiguration",
  props: ["property", "enableSelectPhoneNumber"],
  data() {
    return {
      headerType: [],
      bodyItems: [],
      newBodyValue: "",
      templateBodySelected: "",
      templateBodyPlaceholders: 0,
      headerText: "",
    };
  },
  computed: {
    showImageFromUrl() {
      if (this.property.headerSettings.value.url != "") {
        let checkImage = this.isImage(this.property.headerSettings.value.url);
        if (typeof checkImage === "boolean") {
          return true;
        }
      }
      return false;
    },
    allButtonsAreQuickReply() {
      if (this.property.buttons && this.property.buttons.value && this.property.buttons.value.length > 0) {
        return this.property.buttons.value[0].type == "QUICK_REPLY";
      } else {
        return false;
      }
    },
    getTemplateButtons() {
      let text = "";
      for (let button of this.property.buttons.value) {
        text += button.text + ", ";
      }
      return text.substring(0, text.length - 2);
    },
    getTemplateList() {
      let templates = this.$store.getters.getWhatsappTemplates;
      let list = [];
      if (!templates) {
        return list;
      }
      for (let key of Object.keys(templates)) {
        list.push({ header: "Account: " + key });
        for (let template of templates[key]) {
          let body = template.structure ? template.structure.body : template.body;
          list.push({
            name: template.name + " (" + template.language + ")",
            cleanName: template.name,
            group: key,
            body: body,
            language: template.language,
          });
        }
      }
      return list;
    },
    matchBodyWithMarkers() {
      return this.templateBodyPlaceholders == this.property.body.value.length || this.property.conditionPhoneNumber.value == 2;
    },
  },
  watch: {
    property() {
      this.setPropertyConfiguration();
    },
  },
  mounted() {
    this.setPropertyConfiguration();
  },
  methods: {
    ...fieldValidators,
    ...whatsappTemplate,
    setPropertyConfiguration() {
      if (!this.enableSelectPhoneNumber) {
        this.property.conditionPhoneNumber.value = 0;
      }
      if (!this.property.headerType.valueSelected) {
        this.property.headerType.valueSelected = "None";
      }
      if (this.property.templateName.value) {
        if (!this.property.templateName.value.group || this.property.templateName.value.group == "") {
          this.property.templateName.value.group = Object.keys(this.$store.getters.getWhatsappTemplates)[0];
        }
        if (!this.property.templateName.value.language) {
          this.property.templateName.value.language = this.$store.getters.getWhatsappTemplates[this.property.templateName.value.group].language;
        }
        if (!this.property.templateName.value.name) {
          this.property.templateName.value.name = this.property.templateName.value;
        }
        if (this.property.templateName.value.body) {
          this.templateBodySelected = this.property.templateName.value.body;
          this.templateBodyPlaceholders = this.placeholdersTemplate(this.templateBodySelected);
        } else {
          this.templateBodyPlaceholders = 0;
          this.templateBodySelected = "";
        }
        let templateSelected = this.getTemplateInfo(this.property.templateName.value);
        this.assignHeaderText(templateSelected);
      }
    },
    getPlaceholder(index) {
      let placeholder = index + 1;
      return "{{" + placeholder + "}}";
    },
    addNewBodyValue() {
      this.property.body.value.push(this.newBodyValue);
      this.newBodyValue = "";
    },
    changeTemplate(item) {
      let templateSelected = this.getTemplateInfo(item);
      this.property.headerType.valueSelected = "None";
      if (templateSelected.structure && templateSelected.structure.header && templateSelected.structure.header.format) {
        this.property.headerType.valueSelected = templateSelected.structure.header.format;
      } else if (templateSelected.header && templateSelected.header.format) {
        this.property.headerType.valueSelected = templateSelected.header.format;
      }
      this.assignHeaderText(templateSelected);
      if (!this.property.language.value || !(this.property.language.value.startsWith("%") && this.property.language.value.endsWith("%"))) {
        this.property.language.value = templateSelected.language;
      }
      this.property.templateName.value.language = templateSelected.language;
      this.templateBodySelected = templateSelected.structure ? templateSelected.structure.body : templateSelected.body;
      this.templateBodyPlaceholders = this.placeholdersTemplate(this.templateBodySelected);
      if (templateSelected.buttons || templateSelected.structure.buttons) {
        this.property.buttons.value = templateSelected.buttons || templateSelected.structure.buttons;
        if (!this.allButtonsAreQuickReply) {
          this.property.responseVariable.value = "";
        }
      } else {
        this.property.buttons.value = [];
        this.property.responseVariable.value = "";
      }
      if (this.templateBodyPlaceholders < this.property.body.value.length) {
        this.property.body.value = [];
      }
    },
    getValue(value) {
      if (value.header) {
        return value;
      }
      return value.name + "_" + value.group;
    },
  },
};
</script>
<style>
#whatsapp_template_configurations .v-select__selection.v-select__selection--comma {
  line-height: 1.3 !important;
}
#whatsapp_template_configurations .templateCode {
  display: block;
  width: 100%;
}
#whatsapp_template_configurations code {
  width: 100%;
  font-weight: 400;
  font-family: Nunito, sans-serif !important;
  letter-spacing: 1.2px;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
#whatsapp_template_configurations .dark-code {
  color: white !important;
  background-color: #263238 !important;
  border-bottom: 1px solid #1d9096;
}
#whatsapp_template_configurations .light-code {
  color: black !important;
}
</style>
