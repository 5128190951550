<template>
  <div>
    <v-row :class="getFormRowMargin" align="center">
      <!-- access key -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          label="Access Key Id"
          v-model.trim="processor.details.accessKeyID"
          :rules="[requiredField]"
          prepend-inner-icon="lock"
          :autocomplete="$store.getters.disableAutocomplete"
        ></v-text-field>
      </v-col>
      <!-- secret access ke -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          label="Secret Access Key"
          :type="showPassword ? 'text' : 'password'"
          v-model.trim="processor.details.secretAccessKey"
          :rules="[requiredField]"
          prepend-inner-icon="lock"
          autocomplete="new-password"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="processor.details.secretAccessKey.length < 1" tabindex="-1">
              <v-icon tabindex="-1" v-show="!showPassword" @click="showPassword = !showPassword"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showPassword" @click="showPassword = !showPassword"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row :class="getFormRowMargin" align="center">
      <!-- aws region -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-autocomplete
          :items="this.$store.getters.getAwsRegionCode"
          :value="processor.details.region"
          v-model="processor.details.region"
          :rules="[requiredField]"
          label="Region"
          prepend-inner-icon="vpn_lock"
        ></v-autocomplete>
      </v-col>
      <!-- target language -->
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-autocomplete
          :items="this.$store.getters.getAwsSupportedLanguage"
          :value="processor.details.languageCode"
          v-model="processor.details.languageCode"
          :rules="[requiredField]"
          label="Target Language"
          prepend-inner-icon="mdi-earth"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row :class="getFormRowMargin" align="center">
      <!-- enable translation -->
      <v-col cols="12" sm="6" lg="2" :class="getFormColMargin">
        <v-tooltip right max-width="250">
          <template v-slot:activator="{ on }">
            <v-switch v-on="on" v-model="processor.details.enableTranslator" label="Enable Translation" color="primary"></v-switch>
          </template>
          <span>Enable translation if input test language is different from selected Target Language</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" sm="6" :class="getFormColMargin"> </v-col>
    </v-row>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "AWSTranslate",
  props: ["processor", "isEdit"],
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    ...fieldValidators,
  },
  computed: {
    ...spacing,
  },
};
</script>
