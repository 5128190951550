<template>
  <v-form v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteConfigConfirm" />

      <!-- Riga per madifica mail (in creazione visualizza Name) -->
      <v-row :class="getFormRowMargin">
        <v-col v-if="!isEdit" cols="12" sm="5" :class="getFormColMargin">
          <v-text-field
            v-model.trim="newConfig.name"
            label="Email Box name"
            :rules="[requiredField, simpleAlphaNumString]"
            prepend-inner-icon="mdi-card-bulleted"
            autofocus
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="5" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.email"
            label="Email"
            :rules="[isValidEmail]"
            prepend-inner-icon="email"
            :autocomplete="$store.getters.disableAutocomplete"
            validate-on-blur
          />
        </v-col>
      </v-row>

      <!-- Riga abilitazione metodi di autenticazione -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="4">
          <v-checkbox v-model="editConfig.useAuth" label="Use Authentication" :disabled="form.isSubmitted" />
        </v-col>
        <v-col v-if="editConfig.useAuth" cols="12" sm="4">
          <v-tooltip top>
            <template #activator="{ off }">
              <div v-on="off">
                <v-select
                  v-model="editConfig.serviceAuth"
                  :items="$store.state.enums.serviceAuthTypes"
                  :disabled="!editConfig.useAuth || form.isSubmitted"
                  color="primary"
                  label="Service Authentication"
                  v-on="off"
                />
              </div>
            </template>
            <span>Service Authentication</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Riga solo per la modifica password -->
      <v-row v-if="editConfig.useAuth && editConfig.serviceAuth == 'basic'" align="center" :class="getFormRowMargin">
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.username"
            label="Username"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            autofocus
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="lock"
            :error-messages="customErrorMessage"
            autocomplete="new-password"
            @change="deleteErrorMessage"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="!editConfig.password || editConfig.password.length < 1" tabindex="-1">
                <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.retypePassword"
            label="Retype Password"
            :type="showPasswordRetype ? 'text' : 'password'"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="lock"
            :error-messages="customErrorMessage"
            autocomplete="new-password"
            @change="deleteErrorMessage"
          >
            <template slot="append">
              <v-btn depressed icon text :disabled="!editConfig.retypePassword || editConfig.retypePassword.length < 1" tabindex="-1">
                <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility_off
                </v-icon>
                <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                  visibility
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Riga per google oauth2 authentication -->
      <v-row v-if="editConfig.useAuth && editConfig.serviceAuth == 'google-oauth2'" align="center" :class="getFormRowMargin">
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.username"
            label="Username"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.serviceClient"
            label="Client ID"
            hint="Service client ID relative to the service account created"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-account-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.privateKey"
            label="Private key"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="mdi-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>

      <!-- Riga per office365 oauth2 authentication -->
      <v-row v-if="editConfig.useAuth && editConfig.serviceAuth == 'office365-oauth2'" :key="0" align="center" :class="getFormRowMargin">
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.tenant"
            label="Directory (Tenant) ID"
            :rules="[requiredField]"
            prepend-inner-icon="person"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.applicationClientId"
            label="Application (Client) ID"
            :rules="[requiredField]"
            prepend-inner-icon="mdi-account-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="4" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editConfig.clientSecret"
            label="Client Secret"
            :rules="[
              isEdit
                ? () => {
                  return true;
                }
                : requiredField,
            ]"
            prepend-inner-icon="mdi-key"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
      </v-row>

      <!-- Riga per configurazione server -->
      <v-row align="center" :class="getFormRowMargin">
        <v-col v-if="!editConfig.useAuth || editConfig.serviceAuth !== 'office365-oauth2'" cols="12" sm="4" md="4" :class="getFormColMargin">
          <v-text-field v-model.trim="editConfig.host" label="Server" type="text" :rules="[requiredField]" prepend-inner-icon="mdi-server-network" />
        </v-col>
        <v-col v-if="!editConfig.useAuth || editConfig.serviceAuth !== 'office365-oauth2'" cols="12" sm="2" md="2" :class="getFormColMargin">
          <v-text-field
            v-model.number="editConfig.port"
            label="Port"
            type="number"
            :rules="[requiredField, positiveNumber, serverPort]"
            prepend-inner-icon="mdi-swap-horizontal"
            min="0"
            max="36535"
          />
        </v-col>
        <v-col v-if="!editConfig.useAuth || editConfig.serviceAuth !== 'office365-oauth2'" cols="12" sm="3" md="2" :class="getFormColMargin">
          <v-btn-toggle>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-switch v-model="editConfig.secure" color="primary" label="Secure" v-on="on" />
              </template>
              <span>Enable TLS security</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="3" md="3" :class="getFormColMargin">
          <v-btn-toggle>
            <v-tooltip bottom max-width="250">
              <template #activator="{ on }">
                <v-switch v-model="editConfig.primary" color="primary" label="Use as notification" v-on="on" />
              </template>
              <span>Use as default email address for ConvyAdmin notifications</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
        <!-- La seguente colonna è hidden perche il campo non è utilizzato dalla libreria -->
        <v-col cols="12" sm="2" :class="getFormColMargin" hidden>
          <v-select v-model="editConfig.protocol" :items="protocols" label="Protocol" />
        </v-col>
      </v-row>

      <!-- riga per maxMbSize -->
      <v-row :class="getFormRowMargin" align="center">
        <v-col cols="12" sm="5" class="getFormColMargin">
          <v-checkbox
            v-model="editConfig.attachments.report.enableMaxSize"
            class="my-2"
            label="Enable threshold for reports attachments"
            @change="toggleEnableMaxSize"
          />
        </v-col>
        <v-col v-show="editConfig.attachments.report.enableMaxSize" cols="12" sm="4" class="getFormColMargin">
          <v-text-field
            v-model.number="editConfig.attachments.report.maxMbSize"
            suffix="MB"
            label="Maximum attachments threshold"
            type="number"
            min="1"
            hint="If the attachments of a scheduled report exceed this threshold, they will be saved to a bucket for the configured retention period"
            :rules="[greaterThanZero]"
          />
        </v-col>
      </v-row>

      <!-- bottoni -->
      <v-row :class="getFormRowMargin">
        <!-- Buttons -->
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="saveConfig"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="primary"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="testConnection"
          >
            <v-icon left>
              mdi-test-tube
            </v-icon>
            <span>Test</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.submitLoading"
            :disabled="isEdit && (!form.isValid || form.submitLoading)"
            @click.prevent="deleteConfig"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";

export default {
  name: "EmailBoxesForm",
  components: {
    ConfirmDialog,
  },
  props: {
    "mailSetting": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "EmailBox " + this.mailSetting.name + " will be deleted!",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.mailSetting.email !== "",
      //Flag gestione della form
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
        isSubmitted: false,
      },

      //Campi di Utility
      showPassword: false,
      showPasswordRetype: false,
      customErrorMessage: null,

      //Campo protocol
      protocols: ["SMTP"],

      // Campi dedicati a una nuova configurazione
      editConfig: {
        email: this.mailSetting.email,
        username: this.mailSetting.username,
        password: this.mailSetting.password,
        retypePassword: this.mailSetting.password,
        secure: this.mailSetting.secure,
        host: this.mailSetting.host,
        port: this.mailSetting.port,
        protocol: this.mailSetting.protocol,
        primary: this.mailSetting.primary,
        lastUpdate: this.mailSetting.lastUpdate || "-",
        serviceClient: this.mailSetting.serviceClient,
        privateKey: this.mailSetting.privateKey,
        authType: this.mailSetting.authType, //Per autenticazione con service account
        name: this.mailSetting.name,
        tenant: this.mailSetting.tenant,
        applicationClientId: this.mailSetting.applicationClientId,
        clientSecret: this.mailSetting.clientSecret,
        //il calcolo di queste due è stampalato per retrocompatibilità on vecchie configurazione che non riportavano questi campi
        serviceAuth: this.mailSetting.serviceAuth || (this.mailSetting.authType ? "google-oauth2" : "basic"),
        useAuth: this.mailSetting.useAuth !== undefined ? this.mailSetting.useAuth : !!(this.mailSetting.authType || this.mailSetting.username),
        attachments: {
          report: {
            enableMaxSize: this.mailSetting?.attachments?.report?.enableMaxSize || false,
            maxMbSize: this.mailSetting?.attachments?.report?.maxMbSize || 10,
          },
        },
      },
      newConfig: {
        name: "",
      },
    };
  },
  computed: {
    ...spacing,
  },
  methods: {
    ...fieldValidators,
    toggleEnableMaxSize(val) {
      //se per qualche motivo mette a mano -1 e disabilita la check, rimane bloccato il salvataggio, allora ripristino al default il valore dei mb
      if (!val && this.editConfig.attachments.report.maxMbSize < 1) {
        this.editConfig.attachments.report.maxMbSize = 10;
      }
    },
    deleteConfig() {
      if (this.mailSetting.name) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_EMAILBOXES_CREATION);
      }
    },
    async onDeleteConfigConfirm() {
      this.form.deleteLoading = true;
      try {
        await this.$http.delete(`/mail-settings/${this.mailSetting.name}`);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_EMAILBOXES, {
          name: this.mailSetting.name,
          message: "Email Boxes " + this.mailSetting.name + " Deleted!",
        });
        this.showConfirm = false;
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_EMAILBOXES_FAIL, {
          name: this.mailSetting.name,
          message: `Fail to delete emailboxes ${this.mailSetting.name} : ${e.response.data.error.response}`,
        });
      } finally {
        this.form.deleteLoading = false;
        this.showConfirm = false;
      }
    },
    async saveConfig() {
      if (this.form.isValid) {
        this.form.isSubmitted = true;
        this.form.submitLoading = true;
        try {
          this.isEdit ? (this.editConfig.name = this.mailSetting.name) : (this.editConfig.name = this.newConfig.name.toUpperCase());
          if (!this.editConfig.useAuth) {
            this.editConfig.serviceAuth = null;
          }
          const result = await this.$http.post(`/mail-settings`, this.editConfig);
          this.editConfig = result.data.emailBox;
          EventBus.$emit(this.$store.getters.getEvents.EDIT_EMAILBOXES, {
            editConfig: this.editConfig,
            message: "Email Box " + this.editConfig.name + " updated",
          });
        } catch (e) {
          if (!this.editConfig.password) {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_EMAILBOXES_FAIL, {
              editConfig: this.editConfig,
              message: `Error update Email Box ${this.editConfig.name}: missing password field`,
            });
          } else {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_EMAILBOXES_FAIL, {
              editConfig: this.editConfig,
              message: `Error update Email Box ${this.editConfig.name} ${e.response.data.error.response}`,
            });
          }
        } finally {
          this.form.isSubmitted = false;
          this.form.submitLoading = false;
        }
      } else {
        this.customErrorMessage = "Passwords doesn't match";
        EventBus.$emit(this.$store.getters.getEvents.EDIT_EMAILBOXES_FAIL, {
          message: "Passwords doesn't match",
        });
      }
    },
    async testConnection() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        this.form.isSubmitted = true;
        try {
          if (!this.editConfig.useAuth) {
            this.editConfig.serviceAuth = null;
          }
          const response = await this.$httpAuth.post("/scheduled-report/test-email-box", this.editConfig);
          if (response.data.data) {
            EventBus.$emit(this.$store.getters.getEvents.TEST_EMAILBOXES_SUCCESS);
          } else {
            EventBus.$emit(this.$store.getters.getEvents.TEST_EMAILBOXES_FAIL, { error: "Fail to validate email box configurations" });
          }
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.TEST_EMAILBOXES_FAIL, { error: "Fail to validate email box configurations" });
        } finally {
          this.form.isSubmitted = false;
          setTimeout(() => {
            this.form.submitLoading = false;
          }, 15000);
        }
      }
    },
    deleteErrorMessage() {
      this.customErrorMessage = "";
    },
  },
};
</script>
