<template>
  <v-container fluid>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" v-if="editedConnector.uuid" class="d-inline-flex" :class="getFormColMargin">
        <v-text-field readonly disabled label="Webhook" v-model="editedConnector.webhookUrl" prepend-inner-icon="mdi-webhook" :rules="[requiredField]" />
        <CopyToClipboard :data="editedConnector.webhookUrl" message="Webhook copied to clipboard!" />
      </v-col>
    </v-row>
    <v-row :class="getFormRowMargin">
      <v-col cols="12" md="6" v-if="!editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          v-if="!editedConnector.uuid"
          label="Connector's name"
          v-model.trim="editedConnector.name"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Access Token"
          v-model.trim="editedConnector.details.accessToken"
          prepend-inner-icon="mdi-lock"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Access Token Secret"
          v-model.trim="editedConnector.details.accessTokenSecret"
          prepend-inner-icon="mdi-lock"
          :type="showSecretToken ? 'text' : 'password'"
          autocomplete="new-password"
          :rules="[requiredField]"
        >
          <template slot="append">
            <v-btn
              depressed
              icon
              text
              :disabled="editedConnector.details.accessTokenSecret && editedConnector.details.accessTokenSecret.length < 1"
              tabindex="-1"
            >
              <v-icon tabindex="-1" v-show="!showSecretToken" @click="showSecretToken = !showSecretToken"> visibility_off </v-icon>
              <v-icon tabindex="-1" v-show="showSecretToken" @click="showSecretToken = !showSecretToken"> visibility </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Consumer Key"
          v-model.trim="editedConnector.details.consumerKey"
          prepend-inner-icon="mdi-lock"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Consumer Key Secret"
          v-model.trim="editedConnector.details.consumerKeySecret"
          prepend-inner-icon="mdi-lock"
          :append-icon="consumerKeySecret ? 'visibility' : 'visibility_off'"
          :type="consumerKeySecret ? 'text' : 'password'"
          autocomplete="new-password"
          @click:append="consumerKeySecret = !consumerKeySecret"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Bearer Token"
          v-model.trim="editedConnector.details.bearerToken"
          prepend-inner-icon="mdi-lock"
          :append-icon="bearerToken ? 'visibility' : 'visibility_off'"
          :type="bearerToken ? 'text' : 'password'"
          autocomplete="new-password"
          @click:append="bearerToken = !bearerToken"
          :rules="[requiredField]"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          label="Environment"
          v-model.trim="editedConnector.details.environment"
          prepend-inner-icon="mdi-lock"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField, simpleAlphaNumString]"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="editedConnector.uuid" :class="getFormColMargin">
        <v-text-field
          v-if="editedConnector.uuid"
          label="API URL"
          v-model.trim="editedConnector.details.apiURL"
          prepend-inner-icon="mdi-web"
          :autocomplete="$store.getters.disableAutocomplete"
          :rules="[requiredField]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import CopyToClipboard from "../../../components/other/CopyToClipboard";

export default {
  name: "TwitterConf",
  props: ["editedConnector", "connectors"],
  components: { CopyToClipboard },
  data() {
    return {
      showSecretToken: false,
      consumerKeySecret: false,
      bearerToken: false,
    };
  },
  mounted() {
    this.editedConnector.type = "twitter";
    if (!this.editedConnector.uuid) {
      this.editedConnector.details.apiURL = "https://api.twitter.com/1.1";
    }
  },
  methods: {
    ...spacing,
    ...fieldValidators,
  },
};
</script>
