<template>
  <v-container fluid class="ma-0 pa-0" fill-height>
    <iframe v-if="isAppSelected" id="injectedAppFrame" :src="getSelectedAppURL" style="width: 100%; height: 93vh; border: none" />
    <v-row v-if="!isAppSelected" justify="center" class="mt-6 mx-6">
      <v-col cols="12">
        <v-alert type="warning">
          Select one app in the left menu
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import EventBus from "../../event-bus";
import ConvyAdminSDK from "@eudata/eudata-convyadmin-sdk";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "App",
  data() {
    return {
      adminSdk: null,
      titleSuffix: " - ConvyAI Admin",
    };
  },
  computed: {
    ...spacing,
    isAppSelected() {
      return this.$store.getters.getSelectedApp != null;
    },
    getSelectedAppURL() {
      if (this.$store.getters.getSelectedApp) {
        return this.$store.getters.getSelectedApp.url + "?themeDark=" + this.$vuetify.theme.dark;
      }
      return "";
    },
  },
  async mounted() {
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    if (this.$store.getters.getSelectedApp) {
      document.title = this.$store.getters.getSelectedApp.name + this.titleSuffix;
      this.adminSdk = ConvyAdminSDK.getInstance({ cache: { ttl: 30000 }, iframe: { id: "injectedAppFrame" } }, this.getCallbacks());
    }
    if (!this.atLeastOneAppIsAvailable()) {
      this.$router.push("/").catch(() => {});
    }
  },
  beforeUpdate() {
    if (this.$store.getters.getSelectedApp && document.title !== this.$store.getters.getSelectedApp.name + this.titleSuffix) {
      document.title = this.$store.getters.getSelectedApp.name + this.titleSuffix;
      this.adminSdk = ConvyAdminSDK.getInstance({ cache: { ttl: 30000 }, iframe: { id: "injectedAppFrame" } }, this.getCallbacks());
    }
  },
  methods: {
    ...scroller,
    atLeastOneAppIsAvailable() {
      if (this.$store.state.loginResponse.apps && this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany]) {
        const apps = this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany];
        let countAvailableApp = 0;
        if (apps) {
          for (let app of apps) {
            if (checkRoleAndPermission.hasRequiredRole(this.$store.getters.getRoles.ADMIN) || this.isRoleAmong(app.allowRoles)) {
              if (app.enabled) {
                countAvailableApp++;
              }
            }
          }
        }
        return apps && countAvailableApp > 0;
      }
      return false;
    },
    isRoleAmong(roles) {
      return roles.indexOf(this.$store.getters.getUserRole) > -1;
    },
    getCallbacks() {
      return {
        getBots: async () => {
          let resp = [];
          try {
            const botResponse = await this.$http.get("/bot-all");
            resp = botResponse.data;
          } catch (e) {
            resp = [];
          }
          return resp;
        },
        getAgents: async () => {
          let resp = [];
          try {
            const agentResponse = await this.$http.get("/human-agents/agent");
            resp = agentResponse.data;
          } catch (e) {
            resp = [];
          }
          return resp;
        },
        getProfiles: async () => {
          let resp = [];
          try {
            const profileResponse = await this.$http.get("/human-agents/profile");
            resp = profileResponse.data;
          } catch (e) {
            resp = [];
          }
          return resp;
        },
        getQueues: async () => {
          let resp = [];
          try {
            const queuesResponse = await this.$http.get("/human-agents/queue");
            resp = queuesResponse.data;
          } catch (e) {
            resp = [];
          }
          return resp;
        },
        getJWT: () => {
          return this.$store.state.loginResponse.jwt;
        },
      };
    },
  },
};
</script>
