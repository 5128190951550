<template>
  <v-row v-if="loaded">
    <v-col cols="12" :style="showAlert ? 'height:360px;' : 'height:390px;'" :class="showAlert ? 'pa-6' : ''">
      <v-alert v-if="showAlert" type="warning">
        Not enough data to render this widget!
      </v-alert>
      <VueApexCharts
        v-else-if="loaded"
        class="flowPerformance text-center"
        type="bubble"
        height="380"
        width="99%"
        :options="chartOptions"
        :series="series"
      />
    </v-col>

    <v-overlay class="flowPerformance" absolute opacity="0.95" :style="showAlert ? 'height:360px;' : 'height:420px;'" :value="configurationOverlay">
      <v-row class="ml-5 mr-5">
        <v-col cols="6" class="pr-5">
          <v-chip class="mt-1 mb-3" style="background-color: #1d9096 !important">
            Configure thresholds:
          </v-chip>
          <v-row class="mb-2">
            <v-subheader :class="isDarkMode() ? 'white--text' : 'black--text'" style="width: 100%">
              Average NPS
            </v-subheader>

            <v-range-slider
              v-model="slider.nps.range"
              light
              :max="slider.nps.max"
              :min="slider.nps.min"
              thumb-label
              hide-details
              class="align-center"
              :class="isDarkMode() ? 'white--text' : 'black--text'"
            >
              <template #prepend>
                <p>{{ slider.nps.min }}</p>
              </template>
              <template #append>
                <p class="mr-2">
                  {{ slider.nps.max }}
                </p>
              </template>
            </v-range-slider>
          </v-row>

          <v-row class="mb-2">
            <v-subheader :class="isDarkMode() ? 'white--text' : 'black--text'" style="width: 100%">
              FullBot %
            </v-subheader>
            <v-range-slider
              v-model="slider.fullbot.range"
              light
              :max="slider.fullbot.max"
              :min="slider.fullbot.min"
              thumb-label
              hide-details
              class="align-center"
              :class="isDarkMode() ? 'white--text' : 'black--text'"
            >
              <template #prepend>
                <p>{{ slider.fullbot.min }}</p>
              </template>
              <template #append>
                <p>{{ slider.fullbot.max }}</p>
              </template>
            </v-range-slider>
          </v-row>

          <v-row class="mb-2">
            <v-subheader :class="isDarkMode() ? 'white--text' : 'black--text'" style="width: 100%">
              Total Entry
            </v-subheader>
            <v-range-slider
              v-model="slider.total.range"
              light
              :max="slider.total.max"
              :min="slider.total.min"
              thumb-label
              hide-details
              class="align-center"
              :class="isDarkMode() ? 'white--text' : 'black--text'"
            >
              <template #prepend>
                <p>{{ slider.total.min }}</p>
              </template>
              <template #append>
                <p class="mr-4">
                  {{ slider.total.max }}
                </p>
              </template>
            </v-range-slider>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-5">
          <v-chip class="mt-1 mb-3" style="background-color: #1d9096 !important">
            Select which flow should be ignored:
          </v-chip>
          <perfect-scrollbar class="flowVersionChipsContent">
            <v-chip-group v-model="exclusionList" column multiple active-class="error" active>
              <v-chip
                v-for="(flow, index) in listFlow"
                :key="index"
                :value="getChipsValue(flow)"
                filter
                filter-icon="mdi-minus"
                label
                class="white--text"
              >
                {{ flow.flow }} - {{ flow.version }}
              </v-chip>
            </v-chip-group>
          </perfect-scrollbar>
        </v-col>
      </v-row>

      <v-row class="ml-8 mr-5">
        <v-btn color="success" @click="saveDashboard">
          <v-icon left>
            mdi-floppy
          </v-icon>Save
        </v-btn>
        <v-btn color="error" class="ml-2" @click="cancelEditConfiguration">
          <v-icon left>
            mdi-close
          </v-icon>Cancel
        </v-btn>
      </v-row>
    </v-overlay>
  </v-row>
</template>

<script>
import EventBus from "../../event-bus";
import VueApexCharts from "vue-apexcharts";
import openFD from "../../helpers/openFD";

export default {
  name: "FlowsPerformance",
  components: {
    VueApexCharts,
  },
  props: {
    "widget": {
      type: Object,
      required: true
    },
    "botList": {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showAlert: false,
      slider: {
        nps: {
          min: 0,
          max: 10,
          slider: 10,
          range: [0, 10],
        },
        fullbot: {
          min: 0,
          max: 100,
          slider: 100,
          range: [0, 100],
        },
        total: {
          min: 0,
          max: 0,
          slider: 0,
          range: [0, 0],
        },
      },

      configurationOverlay: false,
      exclusionList: [],
      backUpExclusionList: [],
      listFlow: [],
      loaded: false,

      scale: {
        min: 100,
        max: 0,
        rangeMin: 4, //più piccolo di 4 si vede pochissimo
        rangeMax: 25, //Oltre 25 non va
      },

      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        colors: !this.$vuetify.theme.dark
          ? ["#2196F3", "#42A5F5", "#90CAF9", "#0D47A1", "#1E88E5", "#1565C0", "#4CAF50", "#66BB6A", "#A5D6A7", "#1B5E20", "#43A047", "#2E7D32"]
          : ["#1D9096", "#51b7df", "#57b6e0", "#5cb6e1", "#62b5e2", "#54b6e0", "#45b6dd", "#33b7da", "#00b8cd", "#00b9ba", "#00b7a3", "#2fb589"],
        fill: {
          opacity: 0.8,
        },
        title: {},
        xaxis: {
          min: 0,
          max: 100,
          tickAmount: 10,
          title: {
            text: "% Fullbot",
            style: {
              color: "",
            },
          },
          labels: {
            style: {
              colors: "",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          title: {
            text: "Average NPS",
            style: {
              color: "",
            },
          },
          labels: {
            style: {
              color: "",
            },
          },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            click: function (event, chartContext, config) {
              if (config.config.series[config.seriesIndex]) {
                let obj = config.config.series[config.seriesIndex].data[0];
                openFD.openNewWindow(obj.botId, obj.flow, obj.version);
              }
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: ({ seriesIndex, w }) => {
            return (
              '<div><div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
              w.config.series[seriesIndex].name +
              '</div><div class="apexcharts-tooltip-series-group active" style="display: flex;"><div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"><div class="apexcharts-tooltip-x-group"><span class="apexcharts-tooltip-text-label">% Fullbot: </span><span class="apexcharts-tooltip-text-value">' +
              w.config.series[seriesIndex].data[0].x +
              '</span></div><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Average NPS: </span><span class="apexcharts-tooltip-text-value">' +
              w.config.series[seriesIndex].data[0].y +
              '</span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label">Conversations:</span><span class="apexcharts-tooltip-text-z-value">' +
              w.config.series[seriesIndex].data[0].totalEntry +
              "</span></div></div></div></div>"
            );
          },
        },
      },
      rawData: null,
      series: [],
    };
  },
  computed: {},
  mounted() {
    this.request();

    if (this.isDarkMode()) {
      //* gestione colori assi del grafico
      this.chartOptions.xaxis.labels.style.colors = "white";
      this.chartOptions.yaxis.labels.style.colors = "white";
      this.chartOptions.xaxis.title.style.color = "white";
      this.chartOptions.yaxis.title.style.color = "white";
    }
  },
  methods: {
    getChipsValue(flow) {
      return flow.flow + " - " + flow.version;
    },
    openConfiguration() {
      this.configurationOverlay = true;
    },
    saveDashboard() {
      this.configurationOverlay = false;
      this.backUpExclusionList = [].concat(this.exclusionList);
      if (!this.widget.conf.filters) this.widget.conf.filters = {};
      this.widget.conf.filters.intent = {};
      this.exclusionList.forEach((excluded) => {
        this.widget.conf.filters.intent[excluded] = false;
      });

      //* gestione valori slider
      if (!this.widget.conf.filters.nps) {
        this.widget.conf.filters.nps = { min: 0 };
        this.widget.conf.filters.nps = { max: 10 };
      }
      if (!this.widget.conf.filters.fullbot) {
        this.widget.conf.filters.fullbot = { min: 0 };
        this.widget.conf.filters.fullbot = { max: 100 };
      }
      if (!this.widget.conf.filters.total) {
        this.widget.conf.filters.total = { min: 0 };
        this.widget.conf.filters.total = { max: 0 };
      }
      //* assegno nuovi valori slider
      this.chartOptions.yaxis.min = this.slider.nps.range[0];
      this.chartOptions.yaxis.max = this.slider.nps.range[1];
      this.chartOptions.xaxis.min = this.slider.fullbot.range[0];
      this.chartOptions.xaxis.max = this.slider.fullbot.range[1];
      this.scale.min = this.slider.total.range[0];
      this.scale.max = this.slider.total.range[1];

      if (this.slider.nps.min == this.slider.nps.range[0]) {
        this.widget.conf.filters.nps.min = null;
      } else {
        this.widget.conf.filters.nps.min = this.slider.nps.range[0];
      }
      if (this.slider.nps.max == this.slider.nps.range[1]) {
        this.widget.conf.filters.nps.max = null;
      } else {
        this.widget.conf.filters.nps.max = this.slider.nps.range[1];
      }
      if (this.slider.fullbot.min == this.slider.fullbot.range[0]) {
        this.widget.conf.filters.fullbot.min = null;
      } else {
        this.widget.conf.filters.fullbot.min = this.slider.fullbot.range[0];
      }
      if (this.slider.fullbot.max == this.slider.fullbot.range[1]) {
        this.widget.conf.filters.fullbot.max = null;
      } else {
        this.widget.conf.filters.fullbot.max = this.slider.fullbot.range[1];
      }
      if (this.slider.total.min == this.slider.total.range[0]) {
        this.widget.conf.filters.total.min = null;
      } else {
        this.widget.conf.filters.total.min = this.slider.total.range[0];
      }
      if (this.slider.total.max == this.slider.total.range[1]) {
        this.widget.conf.filters.total.max = null;
      } else {
        this.widget.conf.filters.total.max = this.slider.total.range[1];
      }
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_DASHBOARD, this.widget);
      this.calculateChartData();
    },
    cancelEditConfiguration() {
      this.exclusionList = [].concat(this.backUpExclusionList);
      if (this.widget.conf.filters) {
        //* ripristino vecchi valori slider
        this.slider.nps.range[0] = this.widget.conf.filters.nps.min ? this.widget.conf.filters.nps.min : 0;
        this.slider.nps.range[1] = this.widget.conf.filters.nps.max ? this.widget.conf.filters.nps.max : 10;
        this.slider.fullbot.range[0] = this.widget.conf.filters.fullbot.min ? this.widget.conf.filters.fullbot.min : 0;
        this.slider.fullbot.range[1] = this.widget.conf.filters.fullbot.max ? this.widget.conf.filters.fullbot.max : 100;
        this.slider.total.range[0] = this.widget.conf.filters.total.min ? this.widget.conf.filters.total.min : 0;
        this.slider.total.range[1] = this.widget.conf.filters.total.max ? this.widget.conf.filters.total.max : this.slider.total.max;
      }
      this.configurationOverlay = false;
    },
    async request() {
      if (this.widget.conf.filters && this.widget.conf.filters.intent) {
        this.exclusionList = Object.keys(this.widget.conf.filters.intent);
      }
      this.backUpExclusionList = [].concat(this.exclusionList);

      //* se presenti, salvo i valori dei range
      if (this.widget.conf.filters && this.widget.conf.filters.nps) {
        if (this.widget.conf.filters.nps.min) {
          this.slider.nps.range[0] = this.widget.conf.filters.nps.min;
        }
        if (this.widget.conf.filters.nps.max) {
          this.slider.nps.range[1] = this.widget.conf.filters.nps.max;
        }
      }
      if (this.widget.conf.filters && this.widget.conf.filters.fullbot) {
        if (this.widget.conf.filters.fullbot.min) {
          this.slider.fullbot.range[0] = this.widget.conf.filters.fullbot.min;
        }
        if (this.widget.conf.filters.fullbot.max) {
          this.slider.fullbot.range[1] = this.widget.conf.filters.fullbot.max;
        }
      }
      if (this.widget.conf.filters && this.widget.conf.filters.total) {
        if (this.widget.conf.filters.total.min) {
          this.slider.total.range[0] = this.widget.conf.filters.total.min;
        }
        if (this.widget.conf.filters.total.max) {
          this.slider.total.range[1] = this.widget.conf.filters.total.max;
        }
      }

      try {
        const response = await this.$httpAuth.get("/dashboard/flows-performance", {
          timeout: 300000,
        });
        if (response.data.dashboard.length === 0) {
          this.showAlert = true;
          this.$emit("disableWidgetSettings", {
            noData: true,
            id: this.widget.widgetId,
          });
        } else {
          this.rawData = response.data.dashboard;
          //Con questo primo ciclo mi setto tutti i massimi e minimi degli slider di configurazione e degli assi cartesiano
          for (var x = 0; x < this.rawData.length; x++) {
            this.listFlow.push({
              flow: this.rawData[x].flow,
              version: this.rawData[x].version,
            });
            if (this.rawData[x].totalEntry > this.slider.total.max) {
              //* trovo elemento maggiore
              this.slider.total.max = this.rawData[x].totalEntry;
            }
            if (this.rawData[x].totalEntry > this.scale.max) {
              this.scale.max = this.rawData[x].totalEntry;
            }
            if (this.rawData[x].totalEntry < this.scale.min) {
              this.scale.min = this.rawData[x].totalEntry;
            }
          }
          if (this.slider.total.range[1] === 0) {
            //*se è zero, assegno elemento massimo
            this.slider.total.range[1] = this.slider.total.max;
          }
          this.calculateChartData();
          this.showAlert = false;
        }
      } finally {
        this.loaded = true;
      }
    },
    calculateChartData: function () {
      this.series = [];
      for (var x = 0; x < this.rawData.length; x++) {
        if (
          this.exclusionList.indexOf(this.rawData[x].flow + " - " + this.rawData[x].version) === -1 &&
          this.rawData[x].totalCompletedEntry >= this.slider.fullbot.range[0] &&
          this.rawData[x].totalCompletedEntry <= this.slider.fullbot.range[1] &&
          this.rawData[x].avgScore >= this.slider.nps.range[0] &&
          this.rawData[x].avgScore <= this.slider.nps.range[1] &&
          this.rawData[x].totalEntry >= this.slider.total.range[0] &&
          this.rawData[x].totalEntry <= this.slider.total.range[1]
        ) {
          let data = {
            x: parseFloat(parseFloat(this.rawData[x].totalCompletedEntry).toFixed(2)),
            y: parseFloat(parseFloat(this.rawData[x].avgScore).toFixed(2)),
            z: parseFloat(this.rawData[x].totalEntry),
            totalEntry: this.rawData[x].totalEntry,
            flow: this.rawData[x].flow,
            version: this.rawData[x].version,
            botId: this.getBotID(this.rawData[x].flow, this.rawData[x].version),
          };
          this.series.push({
            name: this.rawData[x].flow + " - " + this.rawData[x].version,
            data: [data],
          });
        }
      }
      this.series.forEach((serie) => {
        serie.data.forEach((entry) => {
          entry.z = parseFloat(this.scaleToRange(parseFloat(entry.z)));
        });
      });
    },
    scaleToRange: function (value) {
      let val = this.scale.rangeMin + (this.scale.rangeMax - this.scale.rangeMin) * ((value - this.scale.min) / (this.scale.max - this.scale.min));
      return val;
    },
    getBotID: function (flow, version) {
      for (var i = 0; i < this.botList.length; i++) {
        if (flow === this.botList[i].flow && version === this.botList[i].version) {
          return this.botList[i].bot_id;
        }
      }
    },
    isDarkMode: function () {
      return this.$vuetify.theme.dark;
    },
  },
};
</script>

<style>
.flowPerformance .v-overlay__content {
  width: 100%;
  height: 100%;
}
.v-overlay.flowPerformance .v-overlay__content {
  height: 410px;
}

.flowPerformance .apexcharts-tooltip {
  background: #f3f3f3;
  color: black;
}
.flowVersionChipsContent {
  height: 290px;
}
</style>
