<template>
  <v-row />
</template>

<script>
import spacing from "../../../helpers/spacing";

export default {
  name: "Eudata",
  props: ["engine", "isEdit"],
  data() {
    return {};
  },
  computed: {
    ...spacing
  },
  mounted() {}
};
</script>
