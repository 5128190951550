<template>
  <v-form v-model="form">
    <v-row class="pl-4 pr-6" no-gutters>
      <v-col cols="6" v-show="isEdit ? false : true">
        <v-text-field
          dense
          :label="isEdit ? 'Agent Desktop Name' : 'New Agent Desktop Name'"
          v-model.trim="gui.name"
          :rules="
            !isEdit
              ? [
                  requiredField,
                  charsGreaterThanAndLessThan(gui.name, 3, 128),
                  isAgentDesktopNameUnique(agentDesktopList, gui.name),
                  validateAgentDesktopName(gui.name),
                ]
              : []
          "
          :autocomplete="$store.getters.disableAutocomplete"
          autofocus
          prepend-icon="mdi-robot"
        >
        </v-text-field>
      </v-col>
      <v-col class="mt-1" cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-switch class="ml-5 mb-5 mr-5" :class="isEdit ? '' : 'mt-n1'" color="primary" v-on="on" v-model="gui.default">
              <template v-slot:label>
                <v-row class="ma-0 pt-5 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <div class="subtitle-1">Check as default</div>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0">
                    <div class="caption">
                      By default all profiles without an associated Agent Desktop use the system one. Enabling this configuration all profiles that use the
                      system one are automatically switched to this Agent Desktop
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-switch>
          </template>
          <span>Select as default Agent Desktop for all profiles</span>
        </v-tooltip>
      </v-col>
      <fileUpload :file="file" fileAccepted="zip" @uploadFile="uploadFile" @removeFile="removeFile" :loading="loading.save || loading.delete" />
      <v-col cols="12">
        <template>
          <v-row justify="center">
            <v-expansion-panels class="mt-6 mb-5 mx-3" accordion>
              <v-expansion-panel :key="'data-options-' + gui.uuid">
                <v-expansion-panel-header>
                  <span>
                    <v-icon color="primary">mdi-code-json</v-icon>
                    <span class="ml-4">Properties</span>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <JsonEditorVue
                    v-model="gui.properties"
                    :mainMenuBar="false"
                    mode="text"
                    style="height: calc(100vh - 450px) !important"
                    class="ma-3"
                    :class="isDarkMode ? 'jse-theme-dark' : ''"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </template>
      </v-col>
      <!-- Buttons -->
      <v-col cols="12" sm="12" v-if="!isEdit" class="mt-5 mb-4">
        <v-row align="center" class="px-2 pb-2">
          <v-btn
            color="success"
            class="mx-2"
            @click.prevent="importFile"
            :loading="loading.save"
            :disabled="loading.delete || !file || (file && file.type && !isZipFile(file.type)) || !form"
          >
            <v-icon left>mdi-floppy</v-icon>
            Save
          </v-btn>
          <v-btn color="error" @click="$emit('closePanel')" :loading="loading.delete" :disabled="loading.save">
            <v-icon left>mdi-close</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";
import fileUpload from "./fileUpload.vue";
import spacing from "../../../helpers/spacing";

export default {
  name: "AgentDesktopForm",
  components: {
    fileUpload,
  },
  props: ["isEdit", "gui", "file", "agentDesktopList", "loading"],
  data() {
    return {
      form: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit("validForm", this.form);
      },
    },
  },
  methods: {
    ...fieldValidators,
    uploadFile(obj) {
      this.$emit("uploadFile", obj);
    },
    importFile() {
      this.$emit("importFile", false);
    },
    isZipFile(type) {
      return type.includes("zip");
    },
    removeFile() {
      this.$emit("removeFile");
      this.$emit("validForm", this.form);
    },
  },
  computed: {
    ...spacing,
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    isValidJson() {
      try {
        JSON.parse(this.gui.properties);
        return true;
      } catch (e) {
        return false;
      }
    },
    calcSwitch: {
      get() {
        return false;
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>
