<template>
  <v-row class="mt-6 mx-4">
    <ResultSnackbar ref="resultSnackbar" :hideClose="true" />
    <v-col v-if="!isMarketplaceCompany" cols="12" lg="3">
      <LiveCards :liveConversations="liveConversations" :liveHandover="liveHandover" />
      <!-- gauge presente solo nei dispostivi xs e sm e md -->
      <v-row class="mb-1 mx-1">
        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12">
          <v-card no-gutters elevation="10" :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''">
            <v-row :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''" justify="center" align="center">
              <v-col cols="12" sm="6">
                <v-simple-table dense class="licenseUsageTable">
                  <template #default>
                    <thead>
                      <tr>
                        <th style="width: 33%">
                          Time
                        </th>
                        <th style="width: 34%; text-align: center">
                          Usage
                        </th>
                        <th class="hidden-lg-and-down" style="width: 33%; text-align: center">
                          Expiration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="time in timeSlots" :key="time + '_' + forceRerender" :class="checkLicense(time)">
                        <td style="width: 30%">
                          {{ time }}
                        </td>
                        <td v-if="licenseUsage[time]" class="pl-0" align="center">
                          {{ licenseUsage[time].usage }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" xs="12" sm="6" class="mdAndDownDisplay" :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''">
                <VueSvgGauge
                  v-if="licenseUsage[selectedGauge] && licenseUsage[selectedGauge].percentage"
                  :key="forceRerenderGauge"
                  :start-angle="-110"
                  :end-angle="110"
                  :value="licenseUsage[selectedGauge].percentage"
                  :min="0"
                  :max="100"
                  :separatorThickness="0"
                  :separatorStep="10"
                  :scaleInterval="0"
                  :innerRadius="70"
                  :gauge-color="getColours"
                >
                  <v-tooltip top nudge-top="20" nudge-right="50">
                    <template #activator="{ on }">
                      <div
                        class="inner-text"
                        :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                        v-on="on"
                        v-html="getGaugeContent(licenseUsage[selectedGauge], selectedGauge)"
                      />
                    </template>
                    <span v-html="getTooltipContent(licenseUsage[selectedGauge], selectedGauge)" />
                  </v-tooltip>
                </VueSvgGauge>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- fine gauge per xs e sm -->
    </v-col>

    <v-col v-else cols="4">
      <v-sheet class="mx-auto" elevation="12" :height="$vuetify.breakpoint.mdAndUp ? '320px' : '284px'" color="sheetColor">
        <v-system-bar dark class="px-4" color="primary white--text" height="34">
          <v-icon>mdi-information-outline</v-icon>
          <span>Account details</span>
          <v-spacer />
        </v-system-bar>
        <v-row v-if="loadData" no-gutters style="height: 286px" :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''">
          <v-col cols="12">
            <p class="text-uppercase text-center text-h6 font-weight-bold mt-1 mb-0">
              {{ marketplaceData.profile }} TIER
            </p>
            <v-divider class="primary mx-5" />
            <p class="text-center text-subtitle-1 font-weight-bold mt-2 mb-0">
              {{ marketplaceData.licenseLimit }} conversations with {{ marketplaceData.connectorsLimit }} bot
            </p>
            <p class="text-center caption mb-0">
              included
            </p>
            <v-divider class="primary mx-5" />

            <p class="body-2 my-2 ml-2">
              Your subscription is active since
            </p>
            <div class="text-center">
              <v-chip color="success" outlined>
                <v-icon left class="ml-0">
                  mdi-rocket
                </v-icon>
                {{ getActiveDate() }}
              </v-chip>
            </div>
            <p class="body-2 my-2 ml-2">
              and will expire on
            </p>
            <div class="text-center">
              <v-chip color="error" outlined>
                <v-icon left class="ml-0">
                  mdi-flag
                </v-icon>
                {{ getExpirationDate() }}
              </v-chip>
            </div>
            <p class="body-2 mt-4 ml-2">
              Days before it runs out:
              <v-chip color="primary" outlined>
                <v-icon left class="ml-0">
                  mdi-calendar
                </v-icon>
                {{ getDaysLeft() }}
              </v-chip>
            </p>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col v-if="!isMarketplaceCompany" v-show="$vuetify.breakpoint.lgAndUp" cols="3">
      <v-sheet v-if="showGauge" rounded class="mx-auto" elevation="12" color="sheetColor">
        <v-system-bar dark class="px-4 custom_Border_top" color="primary white--text" height="35">
          Conversations
          <span class="ml-2">usage</span>
        </v-system-bar>
        <v-row class="custom_Border_bottom" no-gutters :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''">
          <v-col cols="12">
            <v-simple-table dense class="licenseUsageTable">
              <template #default>
                <thead>
                  <tr>
                    <th style="width: 33%">
                      Time
                    </th>
                    <th style="width: 34%; text-align: center">
                      Usage
                    </th>
                    <th class="hidden-lg-and-down" style="width: 33%; text-align: center">
                      Expiration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="time in timeSlots" :key="time + '_' + forceRerender" :class="checkLicense(time)">
                    <td style="width: 33%">
                      {{ time }}
                    </td>
                    <td v-if="licenseUsage[time]" style="width: 34%" align="center">
                      {{ licenseUsage[time].usage }}
                    </td>
                    <td v-if="licenseUsage[time]" class="hidden-lg-and-down" style="width: 33%" align="center">
                      {{ licenseUsage[time].expiration }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="mdAndUpDisplay text-center pb-1">
              <VueSvgGauge
                v-if="licenseUsage[selectedGauge] && licenseUsage[selectedGauge]"
                :key="forceRerenderGauge"
                :start-angle="-110"
                :end-angle="110"
                :value="licenseUsage[selectedGauge].percentage"
                :min="0"
                :max="100"
                :separatorThickness="0"
                :separatorStep="10"
                :scaleInterval="0"
                :innerRadius="70"
                :gauge-color="getColours"
              >
                <v-tooltip top nudge-top="20" nudge-right="50">
                  <template #activator="{ on }">
                    <div
                      class="inner-text"
                      :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
                      style="position: absolute; width: 100%; bottom: 25px"
                      v-on="on"
                      v-html="getGaugeContent(licenseUsage[selectedGauge], selectedGauge)"
                    />
                  </template>
                  <span v-html="getTooltipContent(licenseUsage[selectedGauge], selectedGauge)" />
                </v-tooltip>
              </VueSvgGauge>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet v-else rounded class="mx-auto" elevation="12">
        <v-progress-linear value="0" :indeterminate="true" />
      </v-sheet>
    </v-col>

    <v-col v-else v-show="$vuetify.breakpoint.mdAndUp" cols="4">
      <v-sheet class="mx-auto" elevation="12" :height="$vuetify.breakpoint.mdAndUp ? '320px' : '284px'" color="sheetColor">
        <v-system-bar dark class="px-4" color="success white--text" height="34">
          <v-icon>mdi-power</v-icon>
          <span>Usage</span>
          <v-spacer />
        </v-system-bar>
        <v-row no-gutters style="height: 286px" :style="$vuetify.theme.dark ? 'background-color:#455A64' : ''">
          <v-col cols="12">
            <p class="text-center text-subtitle-1 font-weight-bold mt-2 mb-0">
              <v-tooltip bottom max-width="250">
                <template #activator="{ on, attrs }">
                  <v-icon v-if="showAlertPublicationLimit" v-bind="attrs" small color="warning" v-on="on">
                    mdi-alert
                  </v-icon>
                </template>
                <span>You have published more bots than your tier includes. If your need more bots consider an upgrade of your tier!</span>
              </v-tooltip>
              {{ getBotPublished() }}
            </p>
            <VueApexCharts
              v-if="loadData"
              :key="forceRerenderGraph"
              class="marketplaceUsageChart"
              type="bar"
              height="250"
              :options="marketplaceChartOptions"
              :series="marketplaceChartSeries"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col :cols="isMarketplaceCompany ? '4' : '12'" :lg="isMarketplaceCompany ? '4' : '6'" :class="$vuetify.breakpoint.mdAndDown ? 'py-0 my-0' : ''">
      <companyEfficiencyWidget :botList="botList" :company="company" />
    </v-col>
  </v-row>
</template>

<script>
import companyEfficiencyWidget from "./Charts/CompanyEfficiencyWidget";
import LiveCards from "./Charts/LiveCards";
import { VueSvgGauge } from "vue-svg-gauge";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "CompanyEfficiency",
  components: {
    companyEfficiencyWidget,
    VueSvgGauge,
    LiveCards,
    ResultSnackbar,
    VueApexCharts,
  },
  props: {
    "widget": {
      type: Object,
      required: false,
      default: null
    },
    "botList": {
      type: Array,
      required: true
    },
    "company": {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      warningSnackbar: false,
      intervalID: null,

      liveConversations: null,
      liveHandover: null,

      licenseUsage: {},
      timeSlots: ["Daily", "Weekly", "Monthly", "Yearly"],
      selectedGauge: "Monthly",
      showGauge: false,
      forceRerender: 0,
      marketplaceData: {
        profile: null,
        licenseLimit: 0,
        licenseAvailable: 0,
        licenseExtra: 0,
        connectorsLimit: 0,
        contractUpdatedAt: 0,
        contractExpireAt: 0,
        connectors: 0,
        conversations: 0,
      },
      showAlertPublicationLimit: false,
      loadData: false,
      forceRerenderGraph: 0,
      forceRerenderGauge: 0,
      marketplaceChartSeries: [
        {
          data: [],
        },
      ],
      marketplaceChartOptions: {
        theme: {
          mode: "light",
        },
        colors: this.$vuetify.theme.dark ? ["#60a38d", "#1D9096", "#ff5252"] : ["#43A047", "#1E88E5", "#E53935"],
        chart: {
          type: "bar",
          background: "#ffffff",
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["AVAILABLE", "USED", "EXTRA"],
          labels: {
            style: {
              colors: "",
              useSeriesColors: false,
            },
          },
        },
        tooltip: {
          custom: function ({ dataPointIndex, w }) {
            let data = 0;
            let description = "";
            switch (w.globals.labels[dataPointIndex]) {
              case "AVAILABLE":
                data = w.config.series[0].data[0];
                description = "Available conversations represent the remaining conversations included in your bundle";
                break;
              case "USED":
                data = w.config.series[0].data[1];
                description = "Used conversation between one of your customer and your bot/s";
                break;
              case "EXTRA":
                data = w.config.series[0].data[2];
                description =
                  "Extra licenses are used only if your bundle is not enough to cover your needs. If you exceed your limit every month consider an upgrade of your tier!";
                break;
            }
            return (
              '<div style="width:250px;">' +
              '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; background: #eceff1">' +
              w.globals.labels[dataPointIndex] +
              ": " +
              data +
              "</div>" +
              '<div class="apexcharts-tooltip-series-group active" style="display: flex;">' +
              '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
              '<div class="apexcharts-tooltip-x-group">' +
              '<span class="apexcharts-tooltip-text-label" style="white-space: pre-wrap;">' +
              description +
              "</span>" +
              "</div>" +
              "</div>" +
              "</div>" +
              "</div>"
            );
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
      },
    };
  },
  computed: {
    getColours() {
      if (!this.$vuetify.theme.dark) {
        if (this.warningSnackbar) {
          /* Se è presente la snackbar visibile, allora la gauge sarà completamente rossa*/
          return [{ offset: 100, color: "#E53935" }];
        } else if (this.licenseUsage[this.selectedGauge].tot != "Unlimited") {
          return [
            { offset: 0, color: "#039be5" },
            { offset: 30, color: "#00acc1" },
            { offset: 40, color: "#43a047" },
            { offset: 50, color: "#7cb342" },
            { offset: 60, color: "#c0ca33" },
            { offset: 70, color: "#fdd835" },
            { offset: 80, color: "#ffb300" },
            { offset: 90, color: "#fb8c00" },
            { offset: 100, color: "#f4511e" },
          ];
        } else {
          return [{ offset: 100, color: "#039be5" }];
        }
      } else {
        if (this.warningSnackbar) {
          /* Se è presente la snackbar visibile, allora la gauge sarà completamente rossa*/
          return [{ offset: 100, color: "#FF5252" }];
        } else if (this.licenseUsage[this.selectedGauge].tot != "Unlimited") {
          return [
            { offset: 0, color: "#1D9096" },
            { offset: 30, color: "#00c2d5" },
            { offset: 40, color: "#2cd099" },
            { offset: 50, color: "#94c94b" },
            { offset: 60, color: "#e8c153" },
            { offset: 70, color: "#f4ac4e" },
            { offset: 80, color: "#fc9651" },
            { offset: 90, color: "#ff805b" },
            { offset: 100, color: "#fd6a6a" },
          ];
        } else {
          return [{ offset: 100, color: "#1D9096" }];
        }
      }
    },
    isMarketplaceCompany: function () {
      return this.$store.getters.isMarketplaceCompany;
    },
  },
  mounted() {
    this.request().then(() => {
      this.intervalID = setInterval(() => {
        this.request();
      }, 5000);
    });
  },
  beforeDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  methods: {
    loadMarketplaceRealTimeData(result) {
      this.marketplaceData.profile = result.data.settings.subscription.profile;
      this.marketplaceData.licenseLimit = result.data.settings.subscription.licenseLimit;
      this.marketplaceData.connectorsLimit = result.data.settings.subscription.connectorsLimit;
      this.marketplaceData.contractUpdatedAt = result.data.settings.contractUpdatedAt;
      this.marketplaceData.contractExpireAt = result.data.settings.contractExpireAt;
      this.marketplaceData.connectors = result.data.realTimeData.connectors;
      if (this.marketplaceData.connectors > this.marketplaceData.connectorsLimit) {
        this.showAlertPublicationLimit = true;
      }
      this.marketplaceData.conversations = result.data.realTimeData.conversation;
      this.marketplaceData.licenseAvailable = this.marketplaceData.licenseLimit - this.marketplaceData.conversations;
      if (this.marketplaceData.licenseAvailable < 0) {
        this.marketplaceData.licenseExtra = this.marketplaceData.conversations - this.marketplaceData.licenseLimit;
        this.marketplaceData.licenseAvailable = 0;
      }
      //popolo tabella
      let reload = false;
      if (this.marketplaceChartSeries[0].data.length > 0) {
        if (
          this.marketplaceData.licenseAvailable !== this.marketplaceChartSeries[0].data[0] ||
          this.marketplaceData.conversations !== this.marketplaceChartSeries[0].data[1] ||
          this.marketplaceData.licenseExtra !== this.marketplaceChartSeries[0].data[2]
        ) {
          reload = true;
        }
      }
      this.marketplaceChartSeries[0].data = [this.marketplaceData.licenseAvailable, this.marketplaceData.conversations, this.marketplaceData.licenseExtra];
      if (reload) {
        this.forceRerenderGraph++;
      }
      this.loadData = true;
      if (this.$vuetify.theme.dark) {
        //* gestione colori assi del grafico
        this.marketplaceChartOptions.theme.mode = "dark";
        this.marketplaceChartOptions.chart.background = "#455a64";
      }
    },
    getSnackbarContent(snackbarContent) {
      let content = "";
      for (let i = 0; i < snackbarContent.length; i++) {
        if (snackbarContent.length - 1 != i) {
          content += snackbarContent[i] + " and ";
        } else {
          content += snackbarContent[i];
        }
      }
      return content + " licenses!";
    },
    getTooltipContent(data, gauge) {
      return gauge + " conversations used: " + data.usage + " of " + data.tot + "<br/>Expiration: " + data.expiration;
    },
    getGaugeContent(data) {
      return data.usage + "<br/>Conversations";
    },
    async request() {
      let currentConversation = null;
      try {
        const company = this.company || this.$store.getters.getSelectedCompany;
        currentConversation = await this.$http.get("/analytics/current-conversations/" + company, { timeout: 300000 });
        const license = await this.$http.get("/license-usage/" + company, { timeout: 300000 });
        this.liveConversations = currentConversation.data.progress;
        this.liveHandover = currentConversation.data.handover;
        this.createStatistics(license.data);
      } finally {
        currentConversation = null;
      }
    },
    createStatistics(results) {
      let limitReached = 0;
      let forceReloadGauge = false;
      let snackbarContentUsage = [];
      let snackbarContentDate = [];
      let snackbarMessage = [];
      for (let result in results) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          results.hasOwnProperty(result) &&
          result != "company"
        ) {
          let usage = results[result].currentUsage || 0;
          let tot = "Unlimited";
          let percentage = 100;
          let expiration = "Unlimited";
          if (results[result].expiration) expiration = results[result].expiration;
          if (results[result].limit !== "Unlimited") {
            //questo lo sistemiamo una volta per tutte
            //if (this.selectedGauge == "Montly") this.selectedGauge = "Monthly"; //Per correggere errore grammaticale del backend...
            tot = parseInt(results[result].limit);
            percentage = (usage / tot) * 100;
          }
          if (this.licenseUsage && result == "Monthly" && this.licenseUsage["Monthly"] && this.licenseUsage["Monthly"].usage !== usage) {
            forceReloadGauge = true;
          }
          //si deve risolvere una volta per tutte
          //if (result == "Montly") result = "Monthly"; //Per correggere errore grammaticale del backend...
          this.licenseUsage[result] = {
            usage: usage,
            tot: tot,
            percentage: percentage,
            expiration: expiration,
          };
          //controllo numero conversazioni
          if (this.licenseUsage[result].usage > this.licenseUsage[result].tot) {
            if (!snackbarContentUsage.includes(result)) {
              snackbarContentUsage.push(result.toLowerCase());
            }

            if (this.licenseUsage[result].tot - this.licenseUsage[result].usage <= limitReached) {
              limitReached = this.licenseUsage[result].tot - this.licenseUsage[result].usage;
              this.selectedGauge = result;
            }
          }
          //controllo data scadenza licenze
          let today = moment();
          let expirationDate = moment(this.licenseUsage[result].expiration, "DD MMM YYYY");
          if (today >= expirationDate) {
            if (!snackbarContentDate.includes(result)) {
              snackbarContentDate.push(result.toLowerCase());
            }
          }
        }
      }
      if (snackbarContentUsage.length > 0) {
        snackbarMessage.push("You have reached the maximum available " + this.getSnackbarContent(snackbarContentUsage));
      }
      if (snackbarContentDate.length > 0) {
        snackbarMessage.push("Expired " + this.getSnackbarContent(snackbarContentDate));
      }
      if (snackbarMessage.length > 0) {
        this.warningSnackbar = true;
        if (this.$refs.resultSnackbar) {
          this.$refs.resultSnackbar.showError(snackbarMessage, 0);
        }
      }
      if (forceReloadGauge) {
        this.forceRerenderGauge++;
      }
      this.forceRerender++;
      this.showGauge = true;
    },
    checkLicense(time) {
      if (this.licenseUsage[time]) {
        let today = moment();
        let expirationDate = moment(this.licenseUsage[time].expiration, "DD MMM YYYY");
        if (this.licenseUsage[time].usage > this.licenseUsage[time].tot || today >= expirationDate) {
          return "license-error";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    hideConversationNumber(event) {
      var type = event.currentTarget.parentElement.className;
      for (var i in this.show) {
        if (i == type) {
          this.show[i] = false;
        } else {
          this.show[i] = true;
        }
      }
    },
    getStyleGaugeContent() {
      if (this.$browserDetect.isSafari) {
        return "left: 26px;";
      } else {
        return "position:absolute; bottom:0; width: 100%";
      }
    },
    getActiveDate() {
      return moment(this.marketplaceData.contractUpdatedAt).format("D MMMM YYYY");
    },
    getExpirationDate() {
      return moment(this.marketplaceData.contractExpireAt).format("D MMMM YYYY");
    },
    getDaysLeft() {
      return moment(this.marketplaceData.contractExpireAt).diff(moment(), "days");
    },
    getBotPublished() {
      return this.marketplaceData.connectors + (this.marketplaceData.connectors > 1 ? " bots" : " bot") + " published";
    },
  },
};
</script>

<style>
.licenseUsageTable > div {
  overflow-x: hidden !important;
}
.theme--dark .licenseUsageTable .v-data-table__wrapper {
  background-color: #455a64;
}
.inner-text {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
}
.license-error {
  color: rgb(229, 57, 53);
}
.mdAndUpDisplay svg {
  max-height: 120px !important;
}
.mdAndDownDisplay svg {
  max-height: 125px !important;
}
.marketplaceUsageChart .apexcharts-tooltip {
  background: #f3f3f3 !important;
  color: black !important;
}
.marketplaceUsageChart .apexcharts-toolbar {
  z-index: 4 !important;
}
</style>
