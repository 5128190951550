<template>
  <v-expansion-panels
    v-model="panel"
    class="my-6 mx-0"
  >
    <v-expansion-panel v-model="panel">
      <v-expansion-panel-header @click.native="expandPanel()">
        <span>
          <v-badge
            v-if="thereIsBadge"
            color="warning"
            bordered
            overlap
            :content="getBadgeContent"
          >
            <v-icon color="primary">mdi-form-select</v-icon>
          </v-badge>
          <v-icon v-else>mdi-form-select</v-icon>
          <span class="ml-4">Initial form <span class="font-weight-light">- optional</span></span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mx-0 mt-12">
          <v-simple-table
            dense
            class="fill-width"
          >
            <thead>
              <tr>
                <th
                  class="text-left"
                  style="width: 25%"
                >
                  Type
                </th>
                <th
                  class="text-left pl-4"
                  style="width: 25%"
                >
                  Label
                </th>
                <th
                  class="text-left pl-4"
                  style="width: 25%"
                >
                  Variable name - optional
                </th>
                <th
                  class="text-center pl-0"
                  style="width: 8%"
                >
                  Required
                </th>
                <th
                  class="text-center"
                  style="width: 10%"
                >
                  Used as<br>
                  initial text
                </th>
                <th class="text-center" />
                <th />
              </tr>
            </thead>
          </v-simple-table>
          <v-row class="ma-0 pa-0">
            <!-- Ci sono due tabelle per poter utilizzare la perfect scrollbar solo sul body e mantenere la header fissa -->
            <perfect-scrollbar
              ref="ps"
              :options="$store.getters.getDefaultScrollProps"
              :style="calculateHeight"
              class="fill-width"
            >
              <v-simple-table
                dense
                class="fill-width"
              >
                <tbody>
                  <tr
                    v-for="(field, index) in parameters.form"
                    :key="index"
                    class="formFiledRow"
                  >
                    <td style="width: 25%">
                      <v-select
                        v-model="field.type"
                        label="Field type"
                        :items="fieldTypes"
                      />
                    </td>
                    <td style="width: 25%">
                      <v-text-field
                        v-model.trim="field.name"
                        label="Field label"
                        :autocomplete="$store.getters.disableAutocomplete"
                        :rules="[requiredField]"
                      />
                    </td>
                    <td style="width: 25%">
                      <v-text-field
                        v-model.trim="field.variableName"
                        label="Variable name (optional)"
                        :autocomplete="$store.getters.disableAutocomplete"
                        :rules="[isOptionalVariableField]"
                      />
                    </td>
                    <td
                      class="text-center"
                      style="width: 8%"
                    >
                      <v-checkbox
                        v-model="field.required"
                        class="mx-auto"
                        color="primary"
                      />
                    </td>
                    <td style="width: 10%">
                      <v-checkbox
                        v-model="field.textToAnalyze"
                        class="mx-auto"
                        color="primary"
                      />
                    </td>
                    <td style="width: 8%">
                      <v-icon @click="deleteSelectInitialFormEntry(index)">
                        delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </perfect-scrollbar>
          </v-row>
        </v-row>
        <v-row class="mt-2 mb-4">
          <v-col
            cols="12"
            align="right"
          >
            <v-btn
              color="primary"
              small
              right
              @click="addNewFormRow"
            >
              <v-icon left>
                mdi-plus
              </v-icon>add field
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";

export default {
  name: "InitialForm",
  props: {
    "parameters": {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      panel: null,
      fieldTypes: ["email", "notes", "number", "phone", "text"],
    };
  },
  computed: {
    thereIsBadge() {
      return this.parameters.form.length > 0 && this.parameters.form[0].name != "" ? true : false;
    },
    getBadgeContent() {
      if (this.parameters.form.length > 0) {
        let length = this.parameters.form.length;
        if (this.parameters.form[length - 1].name != "") {
          return length;
        } else {
          return length - 1;
        }
      }
      return false;
    },
    calculateHeight() {
      this.rerender;
      let height = 0;
      let length = this.parameters.form.length;
      if (length > 0 && length <= 5) {
        height = 71 * length;
      }
      if (length > 5) {
        height = 355;
      }
      return "height:" + height + "px";
    },
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    scrollToBottom() {
      let wrapper = this.$refs["ps"];
      this.$vuetify.goTo(".formFiledRow:last-child", { container: wrapper });
    },
    addNewFormRow() {
      let fields_length = this.parameters.form.length;
      if (fields_length === 0 || this.parameters.form[fields_length - 1].name !== "") {
        this.parameters.form.push({
          name: "",
          type: "text",
          required: false,
          textToAnalyze: false,
          variableName: "",
        });
      }
      if (this.parameters.form.length > 1) {
        setTimeout(this.scrollToBottom, 10);
      }
      this.rerender++;
    },
    deleteSelectInitialFormEntry(index) {
      this.parameters.form.splice(index, 1);
      if (this.parameters.form.length === 0) {
        this.panel = null;
      }
      this.rerender++;
    },
    expandPanel() {
      let length = this.parameters.form.length;
      if (length == 0) {
        this.parameters.form.push({
          name: "",
          type: "text",
          required: false,
          textToAnalyze: false,
          variableName: "",
        });
      } else {
        let param = this.parameters.form[length - 1];
        if (param.name == "") {
          this.parameters.form.splice(length - 1, 1);
        }
      }
    },
  },
};
</script>
<style>
.v-input__slot {
  align-items: center;
  justify-content: center;
}
</style>
