<template>
  <v-card flat class="inactivityCard">
    <v-card-text>
      <v-subheader class="ma-0 px-0 caption font-weight-medium">
        The conversation will timeout after:
      </v-subheader>
      <v-text-field
        v-model="configuration.inactivity.dialog_timeout"
        class="mx-0 mt-0 mb-2 pa-0"
        label="Waiting time before sending the configuration inactivity message (in sec)"
        type="number"
        min="1"
        :rules="[positiveNumber]"
        :error-messages="checkInactivityIsCompleted"
        @keypress="onlyNumber"
      />
      <multiLanguageItem
        :itemObj="configuration.inactivity.inactivityMessage"
        :customLabel="labels.inactivityMessage"
        :errorMessage="checkInactivityIsCompleted"
        :required="false"
        itemName="Message"
        @openOverlay="showMultiLanguage(labels.inactivityMessage, configuration.inactivity.inactivityMessage)"
      />
      <v-subheader class="mx-0 px-0 mb-1 caption font-weight-medium">
        When the conversation is inactive Convy will:
      </v-subheader>
      <v-radio-group v-model="advancedInactivityRadioGroup" column @change="onChangeAdvacedInactivity">
        <v-radio v-for="type in proactivityTypes" :key="type.label" :label="type.label" :value="type.value.toLowerCase()" color="primary" />
      </v-radio-group>
      <template v-if="advancedInactivityRadioGroup !== 'jump'">
        <v-row style="display: block" class="pl-5">
          <v-checkbox v-model="configuration.inactivity.care_window.value.enabled.value" label="Enable Care Window Template Management" />
          <WhatsappTemplateConfiguration
            v-if="configuration.inactivity.care_window.value.enabled.value"
            :property="configuration.inactivity.care_window.value.template"
            :enableSelectPhoneNumber="false"
          />

          <v-row v-if="configuration.inactivity.care_window.enabled && configuration.inactivity.care_window.template" class="mx-0 mt-1 pa-0" no-gutters>
            <div class="templateCode">
              Body Message:
              <code
                class="mt-4 py-4 px-8"
                :class="$vuetify.theme.dark ? 'dark-code' : 'light-code'"
                v-text="configuration.inactivity.care_window.template ? configuration.inactivity.care_window.template.body : ''"
              />
            </div>
          </v-row>
        </v-row>
      </template>
      <div v-if="advancedInactivityRadioGroup == 'jump'">
        <v-row>
          <v-col cols="12">
            <!-- scelta flusso, versione, hook -->
            <FlowJumpConfigurations :settings="configuration.inactivity" :flowTargets="flowTargets" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="configuration.inactivity.dialog_proactivity"
              class="mx-0 mt-1 mb-2 pa-0"
              label="Proactivity timeout (in minutes)"
              type="number"
              min="1"
              :rules="[greaterThanZero]"
              :error-messages="checkInactivity"
              @keypress="onlyNumber"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="configuration.inactivity.dialog_proactivity_repetition"
              class="mx-0 mt-1 mb-2 pa-0"
              label="Proactivity repetition"
              type="number"
              min="1"
              :rules="[greaterThanZero]"
              @keypress="onlyNumber"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-menu
              ref="datepickerbeginjump"
              v-model="inactivityInfo.startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="configuration.inactivity.start_exclusion_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field v-model="configuration.inactivity.start_exclusion_time" label="From" prepend-icon="access_time" readonly v-on="on" />
              </template>
              <v-time-picker
                v-if="inactivityInfo.startMenu"
                v-model="configuration.inactivity.start_exclusion_time"
                format="24hr"
                @click:minute="$refs.datepickerbeginjump.save(configuration.inactivity.start_exclusion_time)"
              />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="datepickerendjump"
              v-model="inactivityInfo.endMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="configuration.inactivity.end_exclusion_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="configuration.inactivity.end_exclusion_time"
                  label="To"
                  prepend-icon="access_time"
                  readonly
                  :error="checkTime()"
                  :error-messages="customErrorMessage"
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-if="inactivityInfo.endMenu"
                v-model="configuration.inactivity.end_exclusion_time"
                format="24hr"
                @click:minute="$refs.datepickerendjump.save(configuration.inactivity.end_exclusion_time)"
              />
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div v-if="advancedInactivityRadioGroup !== 'jump'">
        <v-switch
          v-model="configuration.inactivity.inactivity_survey_enabled"
          class="mt-4 mb-3"
          color="primary"
          label="Should Convy display a survey?"
        />
        <div v-if="configuration.inactivity.inactivity_survey_enabled">
          <v-textarea
            v-model.trim="configuration.inactivity.inactivitySurveyName"
            class="mx-0 mt-1 mb-2 pa-0"
            label="Name"
            rows="1"
            auto-grow
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[validateSurveyName]"
          />
          <v-text-field
            v-model="configuration.inactivity.inactivitySurveyScore[0].value"
            class="mx-0 mt-1 mb-2 pa-0"
            itemName="Score"
            :label="labels.scoreQuestion"
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[
              checkSurvey(
                configuration.inactivity.inactivity_survey_enabled,
                configuration.inactivity.inactivitySurveyScore,
                configuration.inactivity.inactivitySurveyFeedback,
              ),
            ]"
          >
            <v-tooltip slot="append-outer" left>
              <template #activator="{ on }">
                <v-icon tabindex="-1" v-on="on" @click="showMultiLanguage(labels.scoreQuestion, configuration.inactivity.inactivitySurveyScore)">
                  playlist_add
                </v-icon>
              </template>
              <span>Add translation</span>
            </v-tooltip>
          </v-text-field>
          <v-text-field
            v-model="configuration.inactivity.inactivitySurveyFeedback[0].value"
            class="mx-0 mt-1 mb-2 pa-0"
            itemName="Feedback"
            :label="labels.scoreFeedback"
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[
              checkSurvey(
                configuration.inactivity.inactivity_survey_enabled,
                configuration.inactivity.inactivitySurveyScore,
                configuration.inactivity.inactivitySurveyFeedback,
              ),
            ]"
          >
            <v-tooltip slot="append-outer" left>
              <template #activator="{ on }">
                <v-icon tabindex="-1" v-on="on" @click="showMultiLanguage(labels.scoreFeedback, configuration.inactivity.inactivitySurveyFeedback)">
                  playlist_add
                </v-icon>
              </template>
              <span>Add translation</span>
            </v-tooltip>
          </v-text-field>
        </div>
      </div>
    </v-card-text>
    <v-overlay absolute opacity="1" :value="overlay" color="overlayMultilanguage" class="overlayMultilanguage">
      <multilanguageConfigurationDialog :itemName="itemName" :itemObj="itemObj" @back="overlay = !$event" />
    </v-overlay>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import multilanguageConfigurationDialog from "./MultilanguageConfigurationiDialog";
import multiLanguageItem from "../../other/multiLanguageItem";
import WhatsappTemplateConfiguration from "@/components/other/WhatsappTemplateConfiguration";
import FlowJumpConfigurations from "@/components/other/FlowJumpConfigurations";
export default {
  name: "Inactivity",
  components: {
    multilanguageConfigurationDialog,
    multiLanguageItem,
    WhatsappTemplateConfiguration,
    FlowJumpConfigurations,
  },
  props: ["configuration", "flowTargets"],
  data() {
    return {
      customErrorMessage: null,
      itemObj: null,
      itemName: null,
      overlay: false,
      labels: {
        inactivityMessage: "Inactivty message",
        scoreQuestion: "Score question",
        scoreFeedback: "Score feedback",
      },
      proactivityTypes: [
        { label: "Stay idle", value: "idle" },
        { label: "Jump to", value: "jump" },
      ],
      inactivityInfo: {
        startMenu: false,
        endMenu: false,
      },
    };
  },
  computed: {
    getTemplateList() {
      let templates = this.$store.getters.getWhatsappTemplates;
      let list = [];
      if (!templates) {
        return list;
      }
      for (let key of Object.keys(templates)) {
        list.push({ header: "Account: " + key });
        for (let template of templates[key]) {
          let body = template.structure ? template.structure.body : template.body;
          list.push({
            name: template.name + " (" + template.language + ")",
            cleanName: template.name,
            group: key,
            body: body,
            language: template.language,
          });
        }
      }
      return list;
    },
    checkInactivityIsCompleted() {
      if (this.configuration.inactivity.dialog_proactivity && this.configuration.inactivity.dialog_timeout == "") {
        return ["Complete this field if you want to set proactivity timeout"];
      }
      return [];
    },
    checkInactivity() {
      if (
        this.configuration.inactivity.proactivityType == "jump" &&
        this.configuration.inactivity.dialog_proactivity * 60 >= this.configuration.inactivity.dialog_timeout
      ) {
        return ["Proactivity timeout can not be lower than the conversation timeout"];
      }
      return [];
    },
    advancedInactivityRadioGroup: {
      get() {
        return this.configuration.inactivity.proactivityType;
      },
      set(val) {
        this.configuration.inactivity.proactivityType = val;
      },
    },
  },
  methods: {
    ...fieldValidators,
    getTemplateValue(value) {
      if (value.header) {
        return value;
      }
      return value.name + "_" + value.group;
    },
    checkTime() {
      if (this.configuration.inactivity.end_exclusion_time < this.configuration.inactivity.start_exclusion_time) {
        this.customErrorMessage = "Not valid range time";
        return true;
      }
      this.customErrorMessage = null;
      return false;
    },
    showMultiLanguage(label, data) {
      this.itemName = label;
      this.itemObj = data;
      this.overlay = !this.overlay;
      this.$emit("openOverlay");
    },
    onChangeAdvacedInactivity(e) {
      this.advancedInactivityRadioGroup = e;
      if (e === "jump") {
        this.configuration.inactivity.inactivity_survey_enabled = false;
      }
    },
  },
};
</script>
