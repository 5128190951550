export default {
  //Riga principale che contiene la pagina
  getMainRowMargin: function() {
    return "mx-4 mt-4 mb-12";
  },
  getMainRowMarginNoSearchbar: function() {
    return "mt-12 mb-12 pt-6 mx-4";
  },
  getMainRowMarginNoBottom: function() {
    return "mx-4";
  },
  //Expansion panel di creazione nuovo oggetto
  getMarginNewCard: function() {
    return "mb-4";
  },
  //Expansion panel principale con l'elenco oggetti
  getMainExpansionPanelMargin: function() {
    return "mb-8";
  },
  //Dimensione avatar
  getAvatarSize: function() {
    return "35px";
  },
  getAvatarImageMaxSize: function() {
    return "max-height:38px; max-width:38px";
  },
  //Form di creazione
  getCreationFormMargin: function() {
    return "px-12";
  },
  //Container interno alla form di creazione
  getCreationContainerMargin: function() {
    return "";
  },
  //Form row e col margin
  getFormRowMargin: function() {
    return "py-1";
  },
  getFormColMargin: function() {
    return "pb-0";
  },
  //Footer button margin
  getButtonMargin: function() {
    return "mt-2 mr-2";
  },
  //Footer button margin close top
  getButtonMarginTop: function() {
    return "mr-2";
  },
  //FiX autocomplete margin
  getAutocompleteMargin: function() {
    return "margin-top: -7px!important;";
  },
  //FIX inline checkbox
  getMarginForCheckbox: function() {
    return "mr-2 mt-n1";
  },
  //margini <v-datatable>
  getDataTableMargin: function() {
    return "mt-1 mx-8";
  },
  getSheetContentMargin: function() {
    return "mx-5";
  },
  getNoYPadding: function() {
    return "py-0";
  }
};
