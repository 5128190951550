<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Permission sets"
      @input="
        searchString = $event;
        forceRerender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && !newConfig && getItemsList.length === 0 && searchString === ''" cols="12">
        <v-alert type="warning">
          No Permission sets configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && !newConfig && getItemsList.length === 0 && searchString !== ''" cols="12">
        <v-alert type="warning">
          No Permission sets match your search
        </v-alert>
      </v-col>

      <!-- Expansion per nuovo elemento -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Permission set
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <PermissionSetForm :allPermissionSets="permissionSets" :item="newSet" :searchString="searchString" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(group, id) in getItemsList" :key="'queue_' + id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row class="spacer" no-gutters>
                <v-col cols="2" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title class="pt-2">
                        {{ group.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title class="pt-2">
                        <v-chip v-if="group.default" color="primary" outlined bottom style="font-size: 75%">
                          Default
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="4" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-list-item-subtitle v-on="on">
                            Description
                          </v-list-item-subtitle>
                        </template>
                        <span>Permission set description</span>
                      </v-tooltip>
                      <v-list-item-title class="pt-2">
                        {{ group.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="3" class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title class="pt-2">
                        {{ group.updated_at | formatVerboseDateTime }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <PermissionSetForm :key="forceRerender + id + '_sr'" :allPermissionSets="permissionSets" :item="group" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <Pagination
      v-if="permissionSets.length > 1"
      :items="permissionSets.length"
      :page="pageNumber"
      :pageSize="pageSize"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton name="PermissionSet" @addNewItem="addNewItem" />
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
import Pagination from "../../components/other/Pagination";
import PermissionSetForm from "../../components/humanAgents/PermissionSetForm";

export default {
  name: "PermissionSet",
  components: {
    ResultSnackbar,
    SearchBar,
    AddNewItemButton,
    PermissionSetForm,
    Pagination,
  },
  data() {
    return {
      searchString: "",
      forceRerender: 0,
      expansionPanelCreation: null,
      expansionPanel: null,
      permissionSets: [],
      newSet: null,
      newConfig: null,
      showWarning: false,
      pageNumber: 1,
      pageSize: this.$store.state.pageSize,
      permissionItemTpl: {
        uuid: "",
        eri: "",
        name: "",
        description: "",
        permissions: [
          //{ eri: "" }
        ],
        default: false,
        updated_at: "",
        created_at: new Date().toISOString(),
      },
    };
  },
  computed: {
    ...spacing,
    getItemsList() {
      this.forceRerender;
      this.scrollToTop();
      //copia perchè altrimenti l'edit modifica l'oggetto originale
      let filterData = this.filterData();
      return JSON.parse(JSON.stringify(filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)));
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("wcs")) {
      await this.$router.push("/");
    }
    EventBus.$on(this.$store.getters.getEvents.EDIT_PERMISSION_SET_SUCCESS, this.itemCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_PERMISSION_SET_FAIL, this.itemCreatedFail);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_PERMISSION_SET_CREATION, this.cancelItemCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_PERMISSION_SET_SUCCESS, this.itemDeleted);
    EventBus.$on(this.$store.getters.getEvents.DELETE_PERMISSION_SET_FAIL, this.failDeleteItem);
    EventBus.$on(this.$store.getters.getEvents.ADD_NEW_ITEM, this.addNewItem);

    let response = null;
    try {
      response = await this.$http.get("/human-agents/permission-set");
      this.permissionSets = response.data;
    } catch {
      EventBus.$emit(this.$store.getters.getEvents.LOAD_PERMISSION_SET_FAIL, {
        message: "Fail to load permission sets list.",
      });
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_PERMISSION_SET_SUCCESS, this.itemCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_PERMISSION_SET_FAIL, this.itemCreatedFail);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_PERMISSION_SET_CREATION, this.cancelItemCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_PERMISSION_SET_SUCCESS, this.itemDeleted);
    EventBus.$off(this.$store.getters.getEvents.DELETE_PERMISSION_SET_FAIL, this.failDeleteItem);
  },
  methods: {
    ...scroller,
    onChangeCompany() {
      if (!this.$store.getters.isIntegrationEnabled("wcs")) {
        this.$router.push("/");
      }
    },
    addNewItem(e) {
      this.newConfig = true;
      e = e || {};
      if (!this.newSet) {
        this.newSet = Object.assign({}, this.permissionItemTpl, e);
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    itemCreatedFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    itemCreated(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      const index = this.permissionSets.findIndex((p) => p.uuid === obj.response.uuid);
      if (index < 0) {
        //Siamo in creazione devo fare una push nel mio array che poi verrà ordinato dalla computed
        this.permissionSets.push(obj.response);
      } else {
        //Siamo in edit aggiorno quello che ho trovato
        this.permissionSets[index] = merge({}, obj.response);
      }
      this.closeAllPanels();
      this.forceRerender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    closeAllPanels: function () {
      this.cancelItemCreation();
      this.expansionPanel = null;
    },
    cancelItemCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
      this.newSet = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    saveItemCreation(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    itemDeleted(obj) {
      const index = this.permissionSets.findIndex((p) => p.uuid === obj.uuid);
      if (index > -1) {
        this.permissionSets.splice(index, 1);
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        this.forceRerender++;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    failDeleteItem(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    onEriCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    filterData() {
      return (
        Object.values(this.permissionSets)
          .filter(
            (permission) =>
              permission.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
              permission.description.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1,
          )
          //Sorting per un campo
          .sort((a, b) => {
            if (b.default && a.default !== b.default) {
              return 1;
            }
            if (a.default && a.default !== b.default) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
  },
};
</script>
<style lang="scss">
#badgeCall {
  // z-index:100!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
#badgeVideo {
  // z-index:99!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
#badgeScreen {
  // z-index:98!important;
  * {
    border-radius: 22px;
    height: 22px;
    width: 22px;
  }
}
</style>
