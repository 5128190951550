<template>
  <div>
    <v-select
      v-model="settings.jump_to.flow"
      class="mx-0 mt-1 mb-2 pa-0"
      :class="customIndent"
      label="Flow"
      :items="target.flow"
      :autocomplete="$store.getters.disableAutocomplete"
      :rules="[requiredField]"
      :error="checkFlow(settings.jump_to.flow)"
      @change="onFlowChange($event, settings.jump_to)"
    />
    <v-select
      v-model="settings.jump_to.version"
      class="mx-0 mt-1 mb-2 pa-0"
      :class="customIndent"
      label="Version"
      :items="target.version"
      :autocomplete="$store.getters.disableAutocomplete"
      :rules="[requiredField]"
      @change="onVersionChange($event, settings.jump_to)"
    />
    <v-select
      v-model="settings.jump_to.hook"
      class="mx-0 mt-1 mb-2 pa-0"
      :class="customIndent"
      label="Hook"
      :items="target.hook"
      :autocomplete="$store.getters.disableAutocomplete"
      :rules="[requiredField]"
    />
  </div>
</template>

<script>
import fieldValidators from "@/helpers/fieldValidators";
export default {
  name: "FlowJumpConfigurations",
  props: ["settings", "flowTargets", "customIndent"],
  data() {
    return {
      target: {
        flow: Object.keys(this.flowTargets),
        version: [],
        hook: [],
      },
      targetSelected: {
        flow: null,
        version: null,
        hook: null,
      },
    };
  },
  computed: {
    flowHooksList() {
      return this.flowTargets;
    },
  },
  watch: {
    settings() {
      this.updateFlowJump();
    },
  },
  mounted() {
    this.updateFlowJump();
  },
  methods: {
    ...fieldValidators,
    onFlowChange(e, item) {
      this.targetSelected.flow = e;
      this.target.version = Object.keys(this.flowHooksList[e]);
      item.version = this.target.version[0];
      this.target.hook = this.flowHooksList[e][item.version].hooks;
      item.hook = this.target.hook[0];
    },
    onVersionChange(e, item) {
      this.targetSelected.version = e;
      this.target.hook = this.flowHooksList[this.targetSelected.flow][e].hooks;
      item.hook = this.target.hook[0];
    },
    updateFlowJump() {
      let conf = this.settings;
      let jump = conf.jump_to;
      if (jump) {
        if (jump.flow && this.flowTargets) {
          if (this.flowTargets[jump.flow]) {
            this.target.version = Object.keys(this.flowTargets[jump.flow]);
            this.target.hook = this.flowTargets[jump.flow][jump.version].hooks;
          }
        }
        this.targetSelected.flow = jump.flow;
        this.targetSelected.version = jump.version;
        this.targetSelected.hook = jump.hook;
      }
    },
    checkFlow(flow) {
      for (let i = 0; i < this.target.flow.length; i++) {
        if (this.target.flow[i] == flow) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
