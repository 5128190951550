<template>
  <v-dialog v-model="showScriptFailedModal" persistent max-width="1100">
    <v-card flat class="px-4">
      <v-card-title class="px-0">
        What went wrong
      </v-card-title>
      <perfect-scrollbar class="failedScriptScrollbar" :options="$store.getters.getDefaultScrollProps">
        <v-card-text class="pt-0">
          <v-row :class="getFormRowMargin">
            <v-col cols="6">
              Saved Script
              <v-simple-table dense class="mt-4" style="overflow: hidden">
                <template #default>
                  <thead>
                    <tr>
                      <th style="width: 25px" class="px-0" />
                      <th scope="col" class="text-right pl-0 ml-0" style="width: 60px">
                        Node Id
                      </th>
                      <th scope="col" class="text-right pr-0" style="width: 60px">
                        who
                      </th>
                      <th scope="col" class="text-left">
                        Text
                      </th>
                      <th scope="col" class="text-right pl-1" style="width: 25px" />
                    </tr>
                  </thead>
                  <draggable v-model="getExecutedNodes" tag="tbody" handle=".sortHandle" :end="forcerender++">
                    <tr v-for="(item, index) in getExecutedNodes" :key="index">
                      <td style="width: 25px" class="text-left px-0">
                        <v-btn
                          x-small
                          color="primary"
                          style="cursor: move"
                          icon
                          text
                          class="sortHandle ma-0 pa-0"
                        >
                          <v-icon small>
                            mdi-cursor-move
                          </v-icon>
                        </v-btn>
                      </td>
                      <td scope="row" class="text-right px-0" style="width: 60px">
                        {{ item.uuid }}
                      </td>
                      <td scope="row" class="text-right pr-0" style="width: 80px">
                        <span v-if="item.uuid">Convy:</span>
                        <span v-else>Customer:</span>
                      </td>
                      <td class="text-left text-truncate">
                        <v-tooltip top nudge-top="-10px" :disabled="(item.text && item.text.length < 30) || (item.message && item.message.length < 30)">
                          <template #activator="{ on }">
                            <div class="text-truncate pr-3" style="max-width: 200px" v-on="on">
                              {{ item.text || item.message }}
                            </div>
                          </template>
                          <span>
                            {{ item.text || item.message }}
                          </span>
                        </v-tooltip>
                      </td>
                      <td class="text-right px-0 mx-0" style="width: 25px">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              v-show="item.uuid && !checkIfUUIDisMissingFromActualExecution(item)"
                              x-small
                              icon
                              text
                              color="error"
                              @click="deleteFromExecution(index)"
                              v-on="on"
                            >
                              <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove from script</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6">
              Current tested nodes
              <v-simple-table dense class="mt-4 ml-0 p-0">
                <template #default>
                  <thead>
                    <tr>
                      <th scope="col" class="text-left" style="width: 25px" />
                      <th scope="col" class="text-right pr-0 mr-0" style="width: 60px">
                        Node Id
                      </th>
                      <th scope="col" class="text-right pr-0" style="width: 80px">
                        who
                      </th>
                      <th scope="col" class="text-left">
                        Text
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in getActualNodes" :key="index">
                      <td class="text-left px-0 mx-0" style="width: 25px">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              v-show="item.uuid && !checkIfUUIDisMissing(item)"
                              x-small
                              icon
                              text
                              color="error"
                              v-on="on"
                              @click="addToScript(item, index)"
                            >
                              <v-icon>mdi-merge</v-icon>
                            </v-btn>
                          </template>
                          <span>Merge into the saved script</span>
                        </v-tooltip>
                      </td>
                      <td class="text-right px-0" style="width: 60px">
                        {{ item.uuid }}
                      </td>
                      <td class="text-right pr-0" style="width: 80px">
                        <span v-if="item.uuid">Convy:</span>
                        <span v-else>Customer:</span>
                      </td>
                      <td class="text-left">
                        <v-tooltip top nudge-top="-10px" :disabled="(item.text && item.text.length < 30) || (item.message && item.message.length < 30)">
                          <template #activator="{ on }">
                            <div class="text-truncate pr-3" style="max-width: 200px" v-on="on">
                              {{ item.text || item.message }}
                            </div>
                          </template>
                          <span>
                            {{ item.text || item.message }}
                          </span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </perfect-scrollbar>
      <v-card-actions class="mt-4">
        <v-btn color="success" :loading="scriptSaveInProgress" @click="saveNewScript">
          <v-icon left>
            mdi-floppy
          </v-icon>Save
        </v-btn>
        <v-btn color="error" @click="discardChanges">
          <v-icon left>
            mdi-close
          </v-icon>
          Discard
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import spacing from "../../helpers/spacing";

export default {
  name: "WatchbotFailedScript",
  components: {
    draggable,
  },
  props: ["visible", "watchbots", "failedExecutionSelected"],
  data() {
    return {
      scriptSaveInProgress: false,
      forceRerender: 0,
    };
  },
  computed: {
    ...spacing,
    showScriptFailedModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    //questo metodo riporta i current
    getActualNodes: function () {
      this.forceRerender;
      return this.failedExecutionSelected.actualExecutedNodes;
    },
    //questo metodo riporta i saved
    getExecutedNodes: {
      get() {
        this.forceRerender;
        return this.failedExecutionSelected.executedNodes;
      },
      set(value) {
        this.failedExecutionSelected.executedNodes = value;
        this.forceRerender++;
        return value;
      },
    },
  },
  methods: {
    checkIfUUIDisMissingFromActualExecution(item) {
      for (let i = 0; i < this.failedExecutionSelected.actualExecutedNodes.length; i++) {
        let exec = this.failedExecutionSelected.actualExecutedNodes[i];
        if (exec.uuid == item.uuid && exec.currentFlow == item.currentFlow && exec.currentVersion == item.currentVersion) {
          return true;
        }
      }
      return false;
    },
    checkIfUUIDisMissing(item) {
      for (let i = 0; i < this.failedExecutionSelected.executedNodes.length; i++) {
        let exec = this.failedExecutionSelected.executedNodes[i];
        if (exec.uuid == item.uuid && exec.currentFlow == item.currentFlow && exec.currentVersion == item.currentVersion) {
          return true;
        }
      }
      return false;
    },
    // elimina le modifiche effettuate all'interno della modale mdello script fallito
    discardChanges() {
      this.showScriptFailedModal = false;
    },
    // aggiunge il nodo in cui il test è fallito allo script
    addToScript(item, index) {
      item.isMerged = index;
      this.failedExecutionSelected.executedNodes.splice(index, 0, item);
      this.failedExecutionSelected.actualExecutedNodes.splice(index, 1);
      this.forceRerender++;
    },
    // elimina l'aggiunta del nodo ailo script da salvare
    deleteFromExecution(index) {
      let itemRemoved = this.failedExecutionSelected.executedNodes.splice(index, 1);
      if (itemRemoved.length > 0 && itemRemoved[0].isMerged) {
        this.failedExecutionSelected.actualExecutedNodes.splice(itemRemoved[0].isMerged, 0, itemRemoved[0]);
      }
      this.forceRerender++;
    },
    // questo metodo salva il nuovo script modificato dalla modale
    saveNewScript: function () {
      this.$emit("saveNewScript");
    },
  },
};
</script>

<style>
.failedScriptScrollbar {
  height: 70vh;
}
</style>
