<template>
  <v-row :class="getFormRowMargin">
    <v-col cols="12" :class="getFormColMargin">
      <v-switch
        v-model="company.crabCustomSettings.enabled"
        label="Enable CRAB custom settings"
      />
    </v-col>
    <template v-if="company.crabCustomSettings.enabled">
      <v-col cols="12" class="ma-0 pa-0">
        <v-subheader>Cache</v-subheader>
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.cacheUsername"
          label="Elastic search username"
          hint="Username for the elastic search connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.cachePassword"
          label="Elastic search password"
          hint="Password for the elastic search connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.cacheUrl"
          label="Elastic search endpoint"
          hint="URL for the elastic search connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim.number="company.crabCustomSettings.cachePort"
          label="Elastic search endpoint port"
          type="number"
          min="1"
          max="65535"
          hint="Port for the elastic search connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-select
          v-model="company.crabCustomSettings.cacheType"
          :items="[
            { text: 'AWS', value: 'AWS' },
            { text: 'Internal', value: 'BASIC' },
          ]"
          label="Select elastic search type"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.kibanaUrl"
          label="Kibana URL"
          hint="Kibana URL"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-subheader>Database</v-subheader>
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.databaseUsername"
          label="Database username"
          hint="Username for the database connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.databasePassword"
          label="Database password"
          hint="Password for the database connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="company.crabCustomSettings.databaseUri"
          label="Database endpoint url"
          hint="URL for the database connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim.number="company.crabCustomSettings.databasePort"
          label="Database endpoint port"
          type="number"
          min="1"
          max="65535"
          hint="Port for the database connection"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-select
          v-model="company.crabCustomSettings.databaseType"
          :items="$store.getters.getDocumentDatabases"
          label="Document database type"
          item-text="name"
          item-value="value"
        >
          <template #item="{ item }">
            <img
              alt="Avatar"
              :src="item.image"
              style="max-height: 25px; max-width: 28px"
            >
            <span class="mx-5">{{ item.name }}</span>
          </template>
          <!-- v-slot:selection customizza il primo elemento, quello selezionato -->
          <template #selection="{ item }">
            <img
              alt="Avatar"
              :src="item.image"
              style="max-height: 20px; max-width: 28px"
            >
            <span class="mx-5">{{ item.name }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" :class="getFormColMargin">
        <v-select
          v-model.trim="company.crabCustomSettings.databaseRegion"
          :disabled="company.crabCustomSettings.databaseType !== 'DYNAMO_DB'"
          label="AWS Region"
          :items="$store.getters.getAwsRegionCode"
          :return-object="false"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import spacing from "../../helpers/spacing";

export default {
  name: "CrabCustomSettings",
  props: ["company"],
  computed: {
    ...spacing,
  },
};
</script>
