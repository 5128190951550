<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-autocomplete
          v-model="engine.lexApiVersion"
          :items="lexApiVersion"
          label="Api Version"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-transit-detour"
          @change="rerender++"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-autocomplete v-model="engine.region" :items="awsRegion" label="Region" :rules="[requiredField]" prepend-inner-icon="vpn_lock" />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          :key="rerender + '_bot_name'"
          v-model.trim="engine.botName"
          :label="getLabelForBotName"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          :key="rerender + '_bot_alias'"
          v-model.trim="engine.botAlias"
          :label="getLabelForBotAlias"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-card-bulleted"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="engine.accessKey"
          label="Access Key"
          :type="showPasswordAccessKey ? 'text' : 'password'"
          :rules="[requiredField]"
          prepend-inner-icon="mdi-key"
          autocomplete="new-password"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="engine.accessKey && engine.accessKey.length < 1" tabindex="-1">
              <v-icon v-show="!showPasswordAccessKey" tabindex="-1" @click="showPasswordAccessKey = !showPasswordAccessKey">
                visibility_off
              </v-icon>
              <v-icon v-show="showPasswordAccessKey" tabindex="-1" @click="showPasswordAccessKey = !showPasswordAccessKey">
                visibility
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" :class="getFormColMargin">
        <v-text-field
          v-model.trim="engine.secretKey"
          label="Secret Key"
          :type="showPasswordSecretKey ? 'text' : 'password'"
          :rules="[requiredField]"
          prepend-inner-icon="lock"
          autocomplete="new-password"
        >
          <template slot="append">
            <v-btn depressed icon text :disabled="engine.secretKey && engine.secretKey.length < 1" tabindex="-1">
              <v-icon v-show="!showPasswordSecretKey" tabindex="-1" @click="showPasswordSecretKey = !showPasswordSecretKey">
                visibility_off
              </v-icon>
              <v-icon v-show="showPasswordSecretKey" tabindex="-1" @click="showPasswordSecretKey = !showPasswordSecretKey">
                visibility
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";

export default {
  name: "Lex",
  props: ["engine", "isEdit"],
  data() {
    return {
      showPasswordAccessKey: false,
      showPasswordSecretKey: false,
      awsRegion: [],
      lexApiVersion: ["v1", "v2"],
      rerender: 0,
    };
  },
  computed: {
    ...spacing,
    getLabelForBotName() {
      this.rerender;
      return this.engine.lexApiVersion == "v2" ? "Bot ID" : "Bot Name";
    },
    getLabelForBotAlias() {
      this.rerender;
      return this.engine.lexApiVersion == "v2" ? "Bot Alias ID" : "Bot Alias";
    },
  },
  mounted() {
    if (!this.engine.lexApiVersion) {
      this.engine.lexApiVersion = this.lexApiVersion[0];
    }
    //* gestione region
    for (let i in this.$store.getters.getAwsRegionCode) {
      this.awsRegion.push(this.$store.getters.getAwsRegionCode[i].text);
      if (this.engine.region == this.$store.getters.getAwsRegionCode[i].value) {
        this.engine.region = this.$store.getters.getAwsRegionCode[i].text;
      }
    }
  },
  methods: {
    ...fieldValidators,
  },
};
</script>
