import XRegExp from "xregexp";
import validUrl from "valid-url";
import axios from "axios";

let hookName = "^[\\pL0-9]+[\\pL0-9\\s-_]*$";
let surveyName = "^[a-zA-Z0-9_-]*$";
let attachedVariableName = "^[a-zA-Z0-9_-\\s]*$";
let indexName = "^[a-zA-Z0-9_-\\s]*$";
let scheduledReportName = "^[a-zA-Z0-9_-\\s]*$";
let flowName = "^[\\pL0-9]+$";
let placeholderPattern = "^%[\\pL0-9]+[\\pL0-9\\-_\\\\]*%$";
let emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let simpleAlphaNumStringRegExp = /^[a-zA-Z0-9]+$/;
let simpleValidatorNameWithSpacesRegExp = "^[a-zA-Z0-9_-\\s]*$";
let usernameRegExp = "^[a-zA-Z0-9_\\-.]*$";
let companyNameRegExp = "^[\\pL0-9][\\pL0-9]{3,99}$";
let crabTenantNameRegExp = "^[a-zA-Z0-9-]{3,99}$";
let customerInterfaceNameRegExp = "^[\\pL0-9]+[\\pL0-9\\s-_]*$";
let agentDesktopNameRegExp = "^[\\pL0-9]+[\\pL0-9\\s-_]*$";
let customEngineParamsRegExp = "^[\\pL]+[\\pL-_]*$";

export default {
  isOptionalVariableField,
  validateHookName,
  requiredField,
  isHttpsUrl,
  isVariableField,
  positiveNumber,
  onlyNumber,
  greaterThanZero,
  greaterThan500,
  isURL,
  checkURL,
  isURLorVariable,
  isJsonPath,
  validateSurveyName,
  requiredFileExtensions,
  validBranchName,
  isValidEmail,
  isValidPassword,
  serverPort,
  isValidArrayEmail,
  validateScheduledReportName,
  simpleAlphaNumString,
  simpleValidatorNameWithSpaces,
  validateFlowName,
  confirmPassword,
  validateFileInput,
  isConnectorNameUnique,
  isKeyboardUniqueName,
  validateSemanticEngineName,
  validateWatchbotScriptName,
  isConnectorAppIdUnique,
  isUsernameInPassword,
  validateIntentName,
  validateCogitoMLIntentValue,
  validateConnectorName,
  validateDayNumber,
  companyNameValidator,
  crabTenantNameValidator,
  validateConditionalJump,
  validateSelectTemplate,
  isNumber,
  placeholdersTemplate,
  isImage,
  checkSurvey,
  numberInRange,
  charsGreaterThanAndLessThan,
  isInputProcessorNameUnique,
  isBotNameUnique,
  uniqueNameProfile,
  uniqueNameQueue,
  uniqueName,
  uniqueNamePermissionSet,
  checkQueueLessPreferred,
  checkQueueGreaterRona,
  isValidUsername,
  isValidAgentUsername,
  arePasswordsEqual,
  maxTimeoutInSeconds,
  validateShortcodeName,
  validateAppName,
  isShortcodeUnique,
  isSurveyUnique,
  validateAttachedVariableName,
  validateIndexName,
  isAttachedVariableUnique,
  isIndexUnique,
  isCustomerInterfaceNameUnique,
  isAgentDesktopNameUnique,
  validateCustomerInterfaceName,
  validateAgentDesktopName,
  validateCustomEngineParameters,
  checkSize,
  simpleIndexNameString,
  isOpenAiNameUnique,
  isOpenAiKeyUnique,
  isOutboundCampaignNameUnique,
};

function isOpenAiNameUnique(name, list, editedIndex) {
  for (const i in list) {
    const conf = list[i];
    if (conf.name === name && editedIndex != i) {
      return "Name should be unique";
    }
  }
  return true;
}

function isOpenAiKeyUnique(key, list, editedIndex) {
  for (const i in list) {
    const conf = list[i];
    if (conf.key === key && editedIndex != i) {
      return "Name should be unique";
    }
  }
  return true;
}

function isHttpsUrl(url) {
  const res = url.startsWith("https");
  if (!res) {
    return "URL must be HTTPS";
  }
  return res;
}

function maxTimeoutInSeconds(val) {
  if (val > 214748) {
    return "The max value is 214748";
  }
  return true;
}

function arePasswordsEqual(first, second) {
  if (!first) return true;
  if (!second) return true;
  if (first === second) {
    return true;
  }
  return "Password doesn't match";
}

function isValidAgentUsername(value) {
  if (!value) return true;
  if (value && (typeof isValidUsername(value) === "boolean" || typeof isValidEmail(value) === "boolean")) {
    return true;
  }
  return "This field may contain only alphabetic characters, numbers, period, dashes or underscores. This field can be an email";
}
function isValidUsername(value) {
  if (value) {
    let regexp = new XRegExp(usernameRegExp, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "This field may contain only alphabetic characters, numbers, period, dashes or underscores";
  }
  return "This field is required";
}
function checkQueueLessPreferred(first, second) {
  if (first < second) {
    return "Queue timeout should be greater or equal than the preferred agent timeout";
  }
  return true;
}

function checkQueueGreaterRona(queue, rona, type, enableRona) {
  if (!enableRona) {
    if (type === "social" || type === "webcallback") {
      if (queue < Math.ceil(rona / 60)) {
        return "Queue timeout should be greater or equal than rona timeout";
      }
    } else {
      if (queue < rona) {
        return "Queue timeout should be greater or equal than the rona timeout";
      }
    }
  }
  return true;
}
function checkSurvey(surveyEnabled, score, feedback) {
  if (surveyEnabled) {
    if (score && score.length > 0 && !score[0].value && feedback && feedback.length > 0 && !feedback[0].value) {
      return "At least one among score and feedback should be configured";
    }
  }
  return true;
}

function isUsernameInPassword(username, password) {
  if (username && password) {
    for (let i = 0; i < username.length - 3; i++) {
      let subSurname = username.substring(i, i + 4);
      if (password.toLowerCase().indexOf(subSurname.toLowerCase()) > -1) {
        return "Password must not contains part of the username";
      }
    }
    return true;
  }
  return true;
}

function validateIntentName(value) {
  if (!value) return true;
  if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1 || value.indexOf("\\") !== -1) {
    return "Intent name can not contain single quote, quote or backslash";
  }
  return true;
}

function validateCogitoMLIntentValue(value) {
  if (!value) return true;
  if (value.indexOf("'") !== -1 || value.indexOf('"') !== -1 || value.indexOf("|") !== -1 || value.indexOf("\\") !== -1) {
    return "Intent value can not contains single quote, quote, pipe or backslash";
  }
  if (value.indexOf("/") === -1) {
    return "Intent value should contains at least one forward slash /";
  }
  if (value.indexOf("/") === value.length - 1) {
    return "Intent value should contains at least one character after the forward slash /";
  }
  return true;
}

function validateWatchbotScriptName(value) {
  let ret = validateHookName(value);
  if (typeof ret == "string") {
    return "Watchbot name may contain alphabetic characters, numbers, dashes or underscores";
  }
  return ret;
}

function validateSemanticEngineName(value) {
  let ret = validateHookName(value);
  if (typeof ret == "string") {
    return "Semantic Engine name may contain alphabetic characters, numbers, dashes or underscores";
  }
  return ret;
}

function isConnectorNameUnique(connectors, value) {
  if (connectors) {
    for (let i = 0; i < connectors.length; i++) {
      if (connectors[i].name === value) {
        return "This connector name is already in use";
      }
    }
  }
  return true;
}

function isOutboundCampaignNameUnique(connectors, connector) {
  const campaignName = connector?.outboundParams?.campaignName;
  const id = connector?.uuid;
  if (connectors) {
    for (let i = 0; i < connectors.length; i++) {
      //il controllo sul nome della campagna vale solo per connettori dello stesso tipo
      if (connectors[i].type === connector.type) {
        if (connectors[i].outboundParams?.campaignName?.trim() === campaignName?.trim() && (!id || connectors[i].uuid !== id)) {
          return "This campaign name is already in use in a connector of the same type";
        }
      }
    }
  }
  return true;
}

function isInputProcessorNameUnique(inputProcessors, value) {
  if (inputProcessors) {
    for (let i = 0; i < inputProcessors.length; i++) {
      if (inputProcessors[i].name === value) {
        return "This input processor name is already in use";
      }
    }
  }
  return true;
}

function isBotNameUnique(bots, name, id) {
  if (bots) {
    for (let i in bots) {
      if (bots[i].name === name && (!id || bots[i].bot_id !== id)) {
        return "This bot name is already in use";
      }
    }
  }
  return true;
}

function uniqueNameProfile(profiles, name, id) {
  if (profiles) {
    for (let i in profiles) {
      if (profiles[i].name === name && (!id || profiles[i].uuid !== id)) {
        return "This profile name is already in use";
      }
    }
  }
  return true;
}

function uniqueNameQueue(queues, name, id) {
  if (queues) {
    for (let i in queues) {
      if (queues[i].name === name && (!id || queues[i].uuid !== id)) {
        return "This queue name is already in use";
      }
    }
  }
  return true;
}

function uniqueNamePermissionSet(permissionSets, name, id) {
  if (permissionSets) {
    for (let i in permissionSets) {
      if (permissionSets[i].name.toLowerCase() === name.toLowerCase() && (!id || permissionSets[i].uuid !== id)) {
        return "This permission set name is already in use";
      }
    }
  }
  return true;
}

function uniqueName(namesList, name, id) {
  if (namesList) {
    for (let i in namesList) {
      if (namesList[i].name === name && (!id || namesList[i].uuid !== id)) {
        return "This name is already in use";
      }
    }
  }
  return true;
}

function isKeyboardUniqueName(keyboards, value) {
  if (keyboards) {
    for (let i = 0; i < keyboards.length; i++) {
      if (keyboards[i].value.label === value) {
        return "This keyboard name is already in use";
      }
    }
  }
  return true;
}

function isConnectorAppIdUnique(connectors, value) {
  let socialConnetorsTypes = {
    facebookmessenger: {
      identifierFieldName: "appId",
    },
    telegram: {
      identifierFieldName: "appName",
    },
    webexmeeting: {
      identifierFieldName: "bot_name",
    },
    googlehome: {
      identifierFieldName: "access_token",
    },
    amazonalexa: {
      identifierFieldName: "access_token",
    },
    whatsapp: {
      identifierFieldName: "api_key",
    },
    whatsapp_nexmo: {
      identifierFieldName: "api_key",
    },
    applebusinesschat: {
      identifierFieldName: "cspId",
    },
  };
  if (connectors) {
    for (let i = 0; i < connectors.length; i++) {
      if (socialConnetorsTypes[connectors[i].type] && value === connectors[i].details[socialConnetorsTypes[connectors[i].type].identifierFieldName]) {
        return "Identifier already in use";
      }
    }
  }
  return true;
}

function serverPort(value) {
  if (!value) return true;
  if (typeof value == "string") {
    value = parseInt(value);
  }
  if (value <= 0 || value > 65535) {
    return "Port should be between 1 and 65535";
  }
  if (!Number.isInteger(value)) {
    return "Port should be an integer";
  }
  return true;
}

function isValidPassword(value) {
  if (!value) return true;
  if (value.length < 8) {
    return "The password should contains at least 8 characters";
  }

  let reLow = /[a-z]+/;
  let reUp = /[A-Z]+/;
  let reNum = /[0-9]+/;
  let reChars = /[:;?!@#$%^&*+=._-]+/;
  if (!reLow.test(value) || !reUp.test(value) || !reNum.test(value) || !reChars.test(value)) {
    return "The password must contains at least one uppercase and one lowercase character, numbers and special characters; allowed ones are: .:;?!@#$%^&*+=_-";
  }
  if (/[^a-zA-Z0-9:;?!@#$%^&*+=._-]+/.test(value)) {
    return "Global The password must contains at least one uppercase and one lowercase character, numbers and special characters; allowed ones are: .:;?!@#$%^&*+=_-";
  }
  return true;
}
function confirmPassword(password, repeatedPassword) {
  if (password.length > 0 && repeatedPassword.length > 0 && password !== repeatedPassword) {
    return "Passwords do not match";
  }
  return true;
}

function isValidEmail(value) {
  if (!value) return true;
  if (emailValidator.test(value)) {
    return true;
  }
  return "Email not valid";
}

function isValidArrayEmail(value) {
  if (value.length > 0) {
    for (let i = 0; i < value.length; i++) {
      if (!emailValidator.test(value[i])) {
        return "Emails not valid";
      }
    }
  }
  return true;
}

function validBranchName(value) {
  if (!value) return true;
  if (value.indexOf("\\") !== -1) {
    return "Can not contains backslash";
  }
  return true;
}

function isJsonPath(value) {
  if (!value) return true;
  return value.startsWith("$") ? true : "This field should be a JSON path";
}

function isURLorVariable(value) {
  if (!value) return true;
  if (value && (typeof isURL(value) === "boolean" || typeof isVariableField(value) === "boolean")) {
    return true;
  }
  return "This field should be a valid URL or a variable";
}
async function checkSize(image) {
  try {
    let response = await getImageSize(image);
    return response;
  } catch {
    return -1;
  }
}
async function getImageSize(url) {
  const response = axios.get(url);
  const length = response.headers["content-length"];
  if (length) {
    return length;
  }
  return "Couldn't get file size";
}
function isURL(value) {
  if (!value) return true;
  if (validUrl.isWebUri(value)) {
    return true;
  }
  return "This field should be a valid URL";
}

function checkURL(value) {
  if (!value) return true;
  if (validUrl.isWebUri(value)) {
    return true;
  }
  return false;
}
function onlyNumber($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if (keyCode < 48 || keyCode > 57) {
    // 46 is dot
    $event.preventDefault();
  }
}

function positiveNumber(value) {
  if (typeof value == "undefined" || value == null) return true;
  if (!isNaN(value) && value >= 0) {
    return true;
  }
  return "This field should be 0 or a positive whole number";
}

function greaterThanZero(value) {
  if (typeof value == "undefined" || value == null) return true;
  if (!isNaN(value) && value > 0) {
    return true;
  }
  return "This field should be greater than 0";
}

function greaterThan500(value) {
  if (typeof value == "undefined" || value == null) return true;
  if (!isNaN(value) && value >= 500) {
    return true;
  }
  return "This field should be greater or equal than 500";
}

function requiredFileExtensions(value) {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      let validField = true;
      for (let i in value) {
        if (!value[i] || (value[i] && !value[i].trim().length > 0)) {
          validField = false;
        }
      }
      if (!validField) {
        return "One of the extension is not valid";
      } else {
        return true;
      }
    } else {
      return "This field is required";
    }
  } else {
    return "Invalid field value";
  }
}

function requiredField(value) {
  if (value || value == 0) {
    if (typeof value == "string" && value.trim().length > 0) {
      return true;
    }
    if (typeof value == "number") {
      return true;
    }
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
    if (typeof value == "object" && Object.keys(value).length > 0) {
      return true;
    }
  }
  return "This field is required";
}

function validateFlowName(value) {
  if (!value || value.length === 0) {
    return true;
  }
  if (value.length < 3) {
    return "Flow name should contains at least 3 character";
  }
  let regexp = new XRegExp(flowName, "gi");
  let res = regexp.exec(value);
  if (res != null) {
    return true;
  }
  return "Flow name may contain alphabetic characters and numbers";
}

function validateHookName(value) {
  if (!value || value.length === 0) {
    return true;
  }
  let regexp = new XRegExp(hookName, "gi");
  let res = regexp.exec(value);
  if (res != null) {
    return true;
  }
  return "Hook name may contain alphabetic characters, numbers, dashes or underscores";
}

function validateConditionalJump(value) {
  if (!value || value.length === 0) {
    return true;
  }
  let regexp = new XRegExp(surveyName, "gi");
  let res = regexp.exec(value);
  if (res != null) {
    return true;
  }
  return "Conditional jump name may contain alphabetic characters, numbers, dashes or underscores";
}

function isOptionalVariableField(value) {
  if (!value) return true;
  return isVariableField(value);
}

function isVariableField(value) {
  if (value) {
    let regexp = new XRegExp(placeholderPattern, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "This field may contain only a variable name";
  }
  return "This field is required";
}

function validateConnectorName(value) {
  if (!value || value.length === 0) {
    return true;
  }
  if (value.length < 3) {
    return "Connector name should contains at least 3 character";
  }
  if (value) {
    let regexp = new XRegExp(surveyName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Connector name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateAppName(value) {
  if (value) {
    let regexp = new XRegExp(surveyName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      if (value.length > 20) {
        return "App name length should be between 1 and 20 characters";
      }
      return true;
    }
    return "App name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateShortcodeName(value) {
  if (value) {
    let regexp = new XRegExp(surveyName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Shortcode name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateAttachedVariableName(value) {
  if (value) {
    let regexp = new XRegExp(attachedVariableName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Attached Variable name may contain only alphabetic characters, numbers, spaces, dashes or underscores";
  }
  return "This field is required";
}

function validateIndexName(value) {
  if (value) {
    let regexp = new XRegExp(indexName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Index name may contain only alphabetic characters, numbers, spaces, dashes or underscores";
  }
  return "This field is required";
}

function isAttachedVariableUnique(attachedvariables, value) {
  if (attachedvariables) {
    const keys = Object.keys(attachedvariables);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === value) {
        return "This attached variable label is already in use";
      }
    }
  }
  return true;
}

function isIndexUnique(indexes, index) {
  if (indexes) {
    for (let i = 0; i < indexes.length; i++) {
      if (indexes[i].name === index.name && indexes[i].uuid !== index.uuid) {
        return "This index name is already in use";
      }
    }
  }
  return true;
}

function isShortcodeUnique(shortcodes, value) {
  if (shortcodes) {
    const keys = Object.keys(shortcodes);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === value) {
        return "This shortcode label is already in use";
      }
    }
  }
  return true;
}

function isSurveyUnique(survey, value) {
  if (survey) {
    for (let i in survey) {
      if (survey[i].name.toLowerCase() === value.toLowerCase()) {
        return "This survey name is already in use";
      }
    }
  }
  return true;
}

function validateSurveyName(value) {
  if (value) {
    let regexp = new XRegExp(surveyName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Survey name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateScheduledReportName(value) {
  if (value) {
    let regexp = new XRegExp(scheduledReportName, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Scheduled Report name may contain only alphabetic characters, spaces, numbers, dashes or underscores";
  }
  return "This field is required";
}

function simpleAlphaNumString(value) {
  if (!value) return true;
  let regexp = new XRegExp(simpleAlphaNumStringRegExp);
  let res = regexp.exec(value);
  if (res != null) {
    return true;
  }
  return "This field may contains only alphabetic characters and numbers";
}

function simpleIndexNameString(value) {
  if (!value) return true;
  let regexp = new XRegExp(surveyName);
  let res = regexp.exec(value);
  if (res != null) {
    return true;
  }
  return "This field may contains only alphabetic characters, numbers, '-' and '_'";
}

function simpleValidatorNameWithSpaces(value) {
  if (value) {
    let regexp = new XRegExp(simpleValidatorNameWithSpacesRegExp, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "This field may contain only alphabetic characters, spaces, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateFileInput(value) {
  if (value != null && value.name) {
    let valueToCheck = value.name.replace(/^.*\\/, "");
    if (valueToCheck.endsWith(".js") || valueToCheck.endsWith(".zip")) {
      return true;
    }
    return "Only zip and js files are accepted";
  }
  return true;
}

function validateDayNumber(value) {
  if (value >= 1 && value <= 31) {
    return true;
  } else {
    return "Not valid number, must be between 1 and 31";
  }
}

function crabTenantNameValidator(value) {
  const forbiddenNames = ["admin", "local", "config"];
  if (!value) return true;
  let regexp = new XRegExp(crabTenantNameRegExp, "gi");
  let res = regexp.exec(value);
  if (res != null && forbiddenNames.indexOf(value.toLowerCase()) == -1) {
    return true;
  } else if (res != null && forbiddenNames.indexOf(value.toLowerCase()) != -1) {
    return "This field may contains values different from " + value;
  }
  return "This field may contains only alphabetic characters and numbers";
}

function companyNameValidator(value) {
  const forbiddenNames = ["admin", "local", "config"];
  if (!value) return true;
  let regexp = new XRegExp(companyNameRegExp, "gi");
  let res = regexp.exec(value);
  if (res != null && forbiddenNames.indexOf(value.toLowerCase()) == -1) {
    return true;
  } else if (res != null && forbiddenNames.indexOf(value.toLowerCase()) != -1) {
    return "This field may contains values different from " + value;
  }
  return "This field may contains only alphabetic characters and numbers";
}

function validateSelectTemplate(value) {
  if (value.name && value.group && value.body) {
    return true;
  } else {
    return false;
  }
}

function isNumber(value) {
  if (typeof value == "undefined" || value == null) return true;
  if (isNaN(value)) {
    return "This field should be a number";
  }
  return true;
}

function placeholdersTemplate(body) {
  if (body) {
    let mathces = body.match(/\{[0-9]}/g);
    return mathces ? mathces.length : 0;
  }
  return 9999999;
}

function isImage(value) {
  if (!value) return true;
  if (value) {
    let parts = value.split(".");
    let extension = parts[parts.length - 1];
    //define some image types to test against
    let imageTypes = ["jpg", "jpeg", "tiff", "png", "gif", "bmp"];
    //devo gestire parametri in query string
    extension = extension.split("?");
    if (imageTypes.indexOf(extension[0]) !== -1) {
      return true;
    }
  }
  return "This field should be a valid URL to image in 'jpg', 'jpeg', 'png', 'gif', 'bmp', or 'tiff' format";
}
function numberInRange(value, min, max) {
  if (typeof value == "undefined" || value == null) return true;
  if (value < min || value > max) {
    return "Value should be between " + min + " and " + max;
  }
  return true;
}
function charsGreaterThanAndLessThan(value, min, max) {
  if (typeof value == "undefined" || value == null) return true;
  if (min || min === 0) {
    if (max && max > min) {
      if (value.length < min || value.length > max) {
        return "Value should be greater than " + min + " and less than " + max + " characters";
      }
    }
    if (value.length < min) {
      return "Value should be greater than " + min + " characters";
    }
  }
  if (max && value.length > max) {
    return "Value should be less than " + max + " characters";
  }
  return true;
}

function isCustomerInterfaceNameUnique(customerInterface, name) {
  if (customerInterface) {
    for (let i in customerInterface) {
      if (customerInterface[i].name.toLowerCase() === name.toLowerCase()) {
        return "This customer interface name is already in use";
      }
    }
  }
  return true;
}

function isAgentDesktopNameUnique(agentDesktop, name) {
  if (agentDesktop) {
    for (let i in agentDesktop) {
      if (agentDesktop[i].name.toLowerCase() === name.toLowerCase()) {
        return "This agent desktop name is already in use";
      }
    }
  }
  return true;
}

function validateAgentDesktopName(value) {
  if (value) {
    let regexp = new XRegExp(agentDesktopNameRegExp, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Agent Desktop name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}

function validateCustomEngineParameters(value) {
  if (value) {
    let regexp = new XRegExp(customEngineParamsRegExp, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Key may contain only alphabetic characters, dashes or underscores";
  }
  return "This field is required";
}

function validateCustomerInterfaceName(value) {
  if (value) {
    let regexp = new XRegExp(customerInterfaceNameRegExp, "gi");
    let res = regexp.exec(value);
    if (res != null) {
      return true;
    }
    return "Customer interface name may contain only alphabetic characters, numbers, dashes or underscores";
  }
  return "This field is required";
}
