<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="6">
        <v-subheader>
          {{ title }}
          <template>
            <v-tooltip v-if="explanation" max-width="400" color="primary" right>
              <template #activator="{ on }">
                <v-btn text icon v-on="on">
                  <v-icon color="primary">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ explanation }}</span>
            </v-tooltip>
          </template>
        </v-subheader>
      </v-col>
      <v-col cols="6">
        <v-btn small color="primary" class="mt-2 float-right" @click="addNewItemObj()">
          <v-icon left>
            mdi-plus
          </v-icon>New Item
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-simple-table :key="shortTitle + '_' + rerender" dense fixed-header>
          <template #default>
            <thead>
              <tr>
                <th style="width: 30%" class="text-left">
                  Key
                </th>
                <th style="width: 50%" class="text-left">
                  Value
                </th>
                <th style="width: 20%" class="text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in Object.keys(object)" :key="shortTitle + '_' + item">
                <td style="width: 30%">
                  {{ item }}
                </td>
                <td style="width: 50%">
                  {{ object[item] }}
                </td>
                <td style="width: 20%">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <ConfirmDialog :showConfirm="dialogDelete" :message="deleteMessage" @dialogDeny="dialogDelete = false" @dialogConfirm="deleteItemConfirm()" />
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">New Item</span>
        </v-card-title>
        <perfect-scrollbar ref="ps" class="psSimpleTableWithAction" :options="$store.getters.getDefaultScrollProps">
          <v-card-text>
            <v-form ref="editDialogForm" v-model="isNewItemValid">
              <v-select v-if="choices && choices.length > 0" v-model="newItemObj.key" :items="availableChoices" label="Field" />
              <v-text-field
                v-else
                v-model.trim="newItemObj.key"
                :autocomplete="$store.getters.disableAutocomplete"
                label="Key"
                :rules="[requiredField, isUniqueItem, validateCustomEngineParameters]"
              />
              <v-text-field
                ref="itemValue"
                v-model.trim="newItemObj.value"
                :autocomplete="$store.getters.disableAutocomplete"
                label="Value"
                :rules="[requiredField]"
              />
            </v-form>
          </v-card-text>
        </perfect-scrollbar>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="4" class="px-2">
              <v-btn block outlined color="success" :disabled="!isNewItemValid" @click="saveNewItem(false)">
                <v-icon left>
                  mdi-floppy
                </v-icon>Save
              </v-btn>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-btn block outlined color="primary" :disabled="!isNewItemValid" @click="saveAndAddAnotherNewItem">
                <v-icon left>
                  mdi-plus
                </v-icon>Add More
              </v-btn>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-btn block outlined color="error" @click="closeDialog">
                <v-icon left>
                  mdi-close
                </v-icon>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "./ConfirmationDialog";

export default {
  name: "Simpletablewithactions",
  components: {
    ConfirmDialog,
  },
  props: ["object", "title", "shortTitle", "explanation", "choices"],
  data() {
    return {
      rerender: 0,
      dialogDelete: false,
      dialogEdit: false,
      dialogAdd: false,
      newItemObj: {
        key: "",
        value: "",
      },

      isNewItemValid: false,
      placeholderPattern: ".*%[\\pL0-9]+[\\pL0-9\\-_\\\\]*%.*",
      colorValidation: "",
      messageValidation: "",
      showConfirm: false,
      editDialog: false,
      editedKey: null,

      deleteMessage: "",
      indexToDelete: -1,
    };
  },
  computed: {
    ...spacing,
    availableChoices() {
      this.rerender;
      const filteredChoices = [];
      const allChoices = JSON.parse(JSON.stringify(this.choices));
      for (let c of allChoices) {
        if (this.object[c]) {
          filteredChoices.push({
            text: c,
            value: c,
            disabled: true,
          });
        } else {
          filteredChoices.push({
            text: c,
            value: c,
            disabled: false,
          });
        }
      }
      return filteredChoices;
    },
  },
  methods: {
    ...fieldValidators,
    closeDialog() {
      this.editedKey = null;
      this.editDialog = false;
      this.resetNewItemObj();
    },
    resetNewItemObj() {
      this.newItemObj = {
        key: "",
        value: "",
      };
      if (this.$refs.editDialogForm) {
        this.$refs.editDialogForm.resetValidation();
      }
      this.rerender++;
    },
    deleteItem(item) {
      this.deleteMessage = null;
      if (item === "all") {
        this.indexToDelete = -1;
        this.deleteMessage = "You will delete all item";
      } else {
        this.indexToDelete = item;
        this.deleteMessage = "You will delete " + item;
      }
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.closeDelete();
      if (this.indexToDelete == -1) {
        this.object = [];
      } else {
        delete this.object[this.indexToDelete];
      }
      this.rerender++;
    },
    addNewItemObj() {
      this.resetNewItemObj();
      this.editDialog = true;
    },
    saveAndAddAnotherNewItem() {
      this.saveNewItem(true);
    },
    saveNewItem(skipCloseModal) {
      if (this.isNewItemValid) {
        let newVar = {
          key: this.newItemObj.key,
          value: this.newItemObj.value,
        };
        if (this.editedKey != null) {
          //se ho cambiato la chiave, devo eliminare quella vecchia e inserire quella nuova
          if (this.editedKey !== newVar.key) {
            delete this.object[this.editedKey];
          }
          this.object[newVar.key] = newVar.value;
        } else {
          this.object[newVar.key] = newVar.value;

          if (this.object.length > 1) {
            this.scrollToBottom;
          }
        }
        this.editedKey = null;
        if (!skipCloseModal) {
          this.closeDialog();
        } else {
          this.resetNewItemObj();
        }
      }
      this.rerender++;
    },
    isUniqueItem() {
      //è null se sono in creazione e non in edit
      if (this.object[this.newItemObj.key] && this.editedKey === null) {
        return "Item key must be unique";
      }
      return true;
    },
    editItem(item) {
      this.editedKey = item;
      this.newItemObj = {
        key: item,
        value: this.object[item],
      };
      this.editDialog = true;
      this.rerender++;
    },
  },
};
</script>
