<template>
  <span>
    <span
      v-if="personalInfoChoices.length != 0 || customerInfoChoices.length != 0"
    >
      <v-row :class="getFormRowMargin">
        <span class="mt-8">Type to add a configuration:</span>
      </v-row>
      <v-form ref="addParameterForm" v-model="formParameters.isValid">
        <v-row :class="getFormRowMargin">
          <v-col cols="2">
            <v-select
              v-model.trim="attribute"
              :items="getEngagementAttributes"
              label="Attribute"
              :error-messages="customErrorMessage"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model.trim="keyMap"
              :items="
                attribute == 'Personal'
                  ? personalInfoChoices
                  : customerInfoChoices
              "
              label="Key"
              :error-messages="customErrorMessage"
              :autocomplete="$store.getters.disableAutocomplete"
              :disabled="!attribute"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="valueMap"
              label="Value"
              :rules="[requiredField]"
              :autocomplete="$store.getters.disableAutocomplete"
              :disabled="!keyMap"
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              color="primary"
              class="mt-4"
              small
              :disabled="!formParameters.isValid"
              @click="addItem()"
            >
              <v-icon left small>mdi-plus</v-icon>Add Parameter
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </span>
    <!-- Data Table -->
    <v-row
      v-if="body.length > 0"
      :class="
        !(personalInfoChoices.length != 0 || customerInfoChoices.length != 0)
          ? 'mt-8'
          : getFormRowMargin
      "
    >
      <v-data-table
        must-sort
        :headers="headers"
        :items="body"
        class="elevation-1 enagagementTable"
        dense
        disable-pagination
        style="width:100%"
        hide-default-footer
      >
        <template #item.value="props">
          <v-edit-dialog :return-value.sync="props.item.value">
            {{ props.item.value }}
            <template #input>
              <v-text-field
                v-model.trim="props.item.value"
                label="Edit and press enter to confirm"
                single-line
                :autocomplete="$store.getters.disableAutocomplete"
                :rules="[requiredField]"
                @change="
                  editItem(props.item.type, props.item.key, props.item.value)
                "
                @keydown.enter.prevent="preventSave"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template #item.action="{ item }">
          <v-icon class="ml-1 pl-1" @click="deleteItem(item)">delete</v-icon>
        </template>
      </v-data-table>
    </v-row>
  </span>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
export default {
  name: "EngagementAttributesLivePerson",
  props: ["fieldsMapping"],
  data: () => ({
    customErrorMessage: null,
    showButton: false,
    showClientSecret: false,
    showBody: false,
    showAddField: true,
    checkButton: false,
    formParameters: {
      isValid: false
    },
    engagementAttributes: ["Personal", "Customer"],
    attribute: "Personal",
    keyMap: "",
    valueMap: "",
    isValidInlineChange: false,
    personalInfoChoices: [],
    customerInfoChoices: [],
    headers: [
      { text: "Type", value: "type", width: "25%" },
      { text: "Key", value: "key", width: "25%" },
      { text: "Value", value: "value", width: "25%" },
      { text: "Actions", value: "action", width: "10%", sortable: false }
    ],
    body: []
  }),
  computed: {
    ...spacing,
    getEngagementAttributes() {
      if (this.personalInfoChoices.length == 0) {
        this.setAttribute("Customer");
        return ["Customer"];
      }
      if (this.customerInfoChoices.length == 0) {
        this.setAttribute("Personal");
        return ["Personal"];
      }
      return this.engagementAttributes;
    }
  },
  mounted() {
    this.personalInfoChoices = this.$store.getters.getEngagementAttributesLivePerson(
      "personalInfoChoices"
    );
    this.customerInfoChoices = this.$store.getters.getEngagementAttributesLivePerson(
      "customerInfoChoices"
    );
    if (Object.keys(this.fieldsMapping).length > 0) {
      for (let type in this.fieldsMapping) {
        for (let attribute in this.fieldsMapping[type]) {
          if (type == "Personal") {
            const index = this.personalInfoChoices.indexOf(attribute);
            if (index > -1) {
              this.personalInfoChoices.splice(index, 1);
            }
          }
          if (type == "Customer") {
            const index = this.customerInfoChoices.indexOf(attribute);
            if (index > -1) {
              this.customerInfoChoices.splice(index, 1);
            }
          }
          this.updateBodyTable(
            type,
            attribute,
            this.fieldsMapping[type][attribute]
          );
        }
      }
    }
    this.personalInfoChoices.sort();
    this.customerInfoChoices.sort();
  },
  methods: {
    ...fieldValidators,
    setAttribute(attribute) {
      if (attribute == "Personal") {
        this.attribute = "Personal";
      } else {
        this.attribute = "Customer";
      }
    },
    addItem: function(editType, editKey, editValue) {
      if (editType && editKey && editValue) {
        for (let key in this.fieldsMapping[editType]) {
          if (key == editKey) {
            this.fieldsMapping[editType][key] = editValue;
          }
        }
      } else {
        //* Nuovo dato
        this.updateBodyTable(this.attribute, this.keyMap, this.valueMap);
        if (!this.fieldsMapping[this.attribute]) {
          this.fieldsMapping[this.attribute] = {};
        }
        this.fieldsMapping[this.attribute][this.keyMap] = this.valueMap;
        if (this.attribute == "Personal") {
          const index = this.personalInfoChoices.indexOf(this.keyMap);
          if (index > -1) {
            this.personalInfoChoices.splice(index, 1);
          }
        } else {
          const index = this.customerInfoChoices.indexOf(this.keyMap);
          if (index > -1) {
            this.customerInfoChoices.splice(index, 1);
          }
        }
        this.keyMap = "";
        this.valueMap = "";
        this.showBody = true;
        if (this.$refs.addParameterForm)
          this.$refs.addParameterForm.resetValidation();
      }
      this.$emit("update", this.fieldsMapping);
    },
    editItem: function(type, key, value) {
      this.isValidInlineChange = false;
      if (value) {
        this.isValidInlineChange = true;
        this.addItem(type, key, value);
      }
    },
    // this method prevents the dialog from closing during the INLINE change
    preventSave(event) {
      if (!this.isValidInlineChange) {
        event.stopImmediatePropagation();
      }
    },
    deleteItem(item) {
      const index = this.body.indexOf(item);
      this.body.splice(index, 1);
      if (item.type == "Personal") {
        this.personalInfoChoices.push(item.key);
      } else {
        this.customerInfoChoices.push(item.key);
      }
      this.personalInfoChoices.sort();
      this.customerInfoChoices.sort();
      for (let type in this.fieldsMapping) {
        if (type == item.type) {
          delete this.fieldsMapping[type][item.key];
          if (Object.keys(this.fieldsMapping[type]).length == 0) {
            delete this.fieldsMapping[type];
          }
        }
      }
      if (this.body.length < 1) {
        this.showBody = false;
      }
      this.$emit("update", this.fieldsMapping);
    },
    updateBodyTable: function(type, key, value) {
      this.body.push({
        type: type,
        key: key,
        value: value
      });
    }
  }
};
</script>
