var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-2",attrs:{"flat":"","min-height":_vm.minHeight}},[_c('v-card-text',[_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.menu_node_configurations.questionNotRecognized,"customLabel":_vm.labels.questionNotRecognized,"errorMessage":null,"required":false,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.questionNotRecognized,
          _vm.configuration.menu_node_configurations.questionNotRecognized
        )}}}),_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.menu_node_configurations.disambiguationQuestion,"customLabel":_vm.labels.disambiguationQuestion,"errorMessage":null,"required":false,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.disambiguationQuestion,
          _vm.configuration.menu_node_configurations.disambiguationQuestion
        )}}})],1),_c('v-overlay',{staticClass:"overlayMultilanguage",attrs:{"absolute":"","opacity":"1","value":_vm.overlay,"color":"overlayMultiLanguage"}},[_c('multilanguageConfigurationDialog',{attrs:{"itemName":_vm.itemName,"itemObj":_vm.itemObj},on:{"back":function($event){_vm.overlay = !$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }