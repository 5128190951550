<template>
  <v-app>
    <nav>
      <Navbar :isMaintenanceModeEnabled="isMaintenanceModeEnabled" v-if="renderDrawer" />
      <LeftMenu v-if="renderDrawer" />
    </nav>
    <About v-if="renderDrawer" />

    <DebuggerContainer v-if="renderDrawer" />

    <v-snackbar
      v-model="showRefreshSnackbar"
      style="z-index: 20 !important"
      absolute
      top
      :timeout="-1"
      :color="$vuetify.theme.dark ? '#263238 !important' : '#293d52 !important'"
    >
      A new version is available!
      <v-btn @click="forceRefresh()" class="elevation-0 ml-3 float-right" x-small color="primary"><v-icon small>mdi-refresh</v-icon></v-btn>
    </v-snackbar>

    <perfect-scrollbar
      ref="ps"
      id="mainScrollBar"
      :options="$store.getters.getDefaultScrollProps"
      style="height: 100vh"
      :style="isDarkMode ? 'background: #455a64 !important' : 'background: #fafafa !important'"
    >
      <v-main>
        <Loading :loading="loading" style="margin-top: 12px !important"></Loading>
        <router-view></router-view>
      </v-main>
    </perfect-scrollbar>
  </v-app>
</template>

<script>
import axios from "axios";
import LeftMenu from "./components/other/LeftMenu";
import Navbar from "./components/other/Navbar";
import EventBus from "./event-bus";
import About from "./components/other/About";
import DebuggerContainer from "./components/other/DebuggerContainer";
import Loading from "./components/other/Loading";
import moment from "moment";
import LocalStorage from "./helpers/localStorage";

export default {
  name: "App",
  components: {
    LeftMenu,
    Navbar,
    About,
    DebuggerContainer,
    Loading,
  },
  data() {
    return {
      userNotificationInterval: null,
      refreshTokenInterval: null,
      loading: false,
      worker: null,
      reloadScrollbar: 0,
      refreshTokenTimer: 30000, //1 minuto
      userNotificationTimer: 30000, //30 secondi
      isTokenRefreshing: false,
      showRefreshSnackbar: false,
      versions: {},
      userForceLogoutTime: 60000, //1 minuto
      userForceLogoutTimeout: null,
      checkMaintenanceModeInterval: null,
      checkMaintenanceModeTimer: 60000, //1 minuto
      isMaintenanceModeEnabled: false,
    };
  },
  async created() {
    this.setUpInterceptors();
    this.setUpFocusListener();
    //Caricamento token di company appena ti logghi o quando ricarichi la pagina
    EventBus.$on(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    EventBus.$on(this.$store.getters.getEvents.LOGIN_SUCCESS, this.initAdminEnvironment);
    let themeDarkEnabled = this.$store.getters.getSetting("themeDarkEnabled") || false;
    this.changeTheme(themeDarkEnabled);
    setTimeout(() => this.changeTheme(themeDarkEnabled), 300); //fix per bug tema parzialmente scuro

    EventBus.$on(this.$store.getters.getEvents.LOGGED_OUT, this.loggedOut);

    EventBus.$on(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR, this.updateScrollbar);

    EventBus.$on(this.$store.getters.getEvents.SCROLLBAR_TO_TOP, this.scrollbarToTop);

    EventBus.$on(this.$store.getters.getEvents.LOADING, this.updateLoading);

    EventBus.$on(this.$store.getters.getEvents.MAINTENANCE_MODE, this.onChangeMaintenanceModeStatus);
    EventBus.$on(this.$store.getters.getEvents.UPDATED_MAINTENANCE_MODE, this.onUpdatedMaintenanceStatus);

    setTimeout(() => {
      //* update scrollbar al primo caricamento
      if (this.$refs.ps) {
        this.$refs.ps.update();
      }
    }, 1500);

    let loginResult = null;
    let logoutRedirect = null;
    try {
      const content = localStorage.getItem(LocalStorage.getSSOKey());
      if (content) {
        const parsedContent = JSON.parse(content);
        if (parsedContent.loginResult) {
          loginResult = parsedContent.loginResult;
        }
        if (parsedContent.logoutRedirect) {
          logoutRedirect = parsedContent.logoutRedirect;
        }
      }
      if (logoutRedirect) {
        localStorage.setItem(LocalStorage.getSSOKey(), JSON.stringify({ logoutRedirect: logoutRedirect }));
      }
      if (loginResult) {
        await this.$store.dispatch("AddSetting", {
          username: loginResult.params.customerProfile.user.authentication.credentials.username,
          selectedCompany: loginResult.params.customerProfile.selectedCompany,
        });
        await this.$store.dispatch("UpdateLoginParameters", loginResult);
        EventBus.$emit(this.$store.getters.getEvents.LOGIN_SUCCESS);
      }
    } catch (e) {
      loginResult = null;
      logoutRedirect = null;
    }
    setTimeout(() => {
      this.checkBuildVersion();
    }, 2000);
    if (this.$store.getters.isLogged) {
      this.initAdminEnvironment(true);
    }
  },
  beforeDestroy() {
    this.stopNotificationInterval();
    this.stopRefreshTokenInterval();
    this.stopCheckMaintenanceModeInterval();

    EventBus.$off(this.$store.getters.getEvents.ENABLE_DARK_THEME, this.changeTheme);
    EventBus.$off(this.$store.getters.getEvents.LOGIN_SUCCESS, this.initAdminEnvironment);
    EventBus.$off(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR, this.updateScrollbar);
    EventBus.$off(this.$store.getters.getEvents.LOGGED_OUT, this.loggedOut);
    EventBus.$off(this.$store.getters.getEvents.SCROLLBAR_TO_TOP, this.scrollbarToTop);
    EventBus.$off(this.$store.getters.getEvents.MAINTENANCE_MODE, this.onChangeMaintenanceModeStatus);
    EventBus.$off(this.$store.getters.getEvents.UPDATED_MAINTENANCE_MODE, this.onUpdatedMaintenanceStatus);
  },
  methods: {
    forceRefresh() {
      this.$store.commit("UpdateConvyAdminUpdateFlag", false);
      localStorage.setItem("convyadmin_version_checker", JSON.stringify(this.versions));
      location.reload(true);
    },
    async checkBuildVersion() {
      let rndHash = Date.now().toString(36);
      let flowdesignerNeedRefresh = false;
      let convyadminNeedRefresh = false;
      let prefix = "";
      if (process.env.NODE_ENV === "production") {
        prefix = "ConvyAdmin/";
      }
      try {
        const fdVersion = await this.$httpAuth.get(prefix + "FlowDesigner/version?rndHash=" + rndHash);
        if (fdVersion?.data) {
          this.versions.flowDesigner = fdVersion.data;
        }
      } catch (e) {
        this.versions.flowDesigner = null;
      }
      try {
        const adminVersion = await this.$httpAuth.get(prefix + "version?rndHash=" + rndHash);
        if (adminVersion?.data) {
          this.versions.convyadmin = adminVersion.data;
        }
      } catch (e) {
        this.versions.convyadmin = null;
      }
      let currentVersions = localStorage.getItem("convyadmin_version_checker");
      if (currentVersions) {
        currentVersions = JSON.parse(currentVersions);
        if (JSON.stringify(currentVersions.convyadmin) !== JSON.stringify(this.versions.convyadmin)) {
          convyadminNeedRefresh = true;
        }
        if (JSON.stringify(currentVersions.flowDesigner) !== JSON.stringify(this.versions.flowDesigner)) {
          flowdesignerNeedRefresh = true;
        }
      } else {
        //se non c'è nulla nello store metto a true
        flowdesignerNeedRefresh = true;
        convyadminNeedRefresh = true;
      }
      this.$store.commit("UpdateConvyAdminUpdateFlag", convyadminNeedRefresh);
      this.$store.commit("UpdateFlowDesignerUpdateFlag", flowdesignerNeedRefresh);

      if (convyadminNeedRefresh) {
        this.showRefreshSnackbar = true;
      }
    },
    setUpFocusListener() {
      let hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        visibilityChange = "visibilitychange";
        hidden = "hidden";
      } else if (typeof document.msHidden !== "undefined") {
        visibilityChange = "msvisibilitychange";
        hidden = "msHidden";
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityChange = "webkitvisibilitychange";
        hidden = "webkitHidden";
      }
      if (visibilityChange) {
        window.addEventListener(visibilityChange, () => {
          if (!document[hidden] && !this.isTokenRefreshing && !this.worker) {
            this.refreshToken();
          }
        });
      }
    },
    async loggedOut() {
      this.stopNotificationInterval();
      this.stopRefreshTokenInterval();
      this.stopCheckMaintenanceModeInterval();
    },
    stopNotificationInterval() {
      if (this.userNotificationInterval) {
        clearInterval(this.userNotificationInterval);
      }
      this.userNotificationInterval = null;
    },
    stopRefreshTokenInterval() {
      if (this.refreshTokenInterval) {
        clearInterval(this.refreshTokenInterval);
      }
      this.refreshTokenInterval = null;
      if (this.worker) {
        this.worker.postMessage({ action: "stop"});
      }
    },
    stopCheckMaintenanceModeInterval() {
      if (this.checkMaintenanceModeInterval) {
        clearInterval(this.checkMaintenanceModeInterval);
      }
      this.checkMaintenanceModeInterval = null;
    },
    async refreshToken() {
      if (!this.isTokenRefreshing) {
        this.isTokenRefreshing = true;
        if (this.$router.currentRoute.path !== "/login" && this.$router.currentRoute.path !== "/recoverPassword") {
          try {
            const renewJwtResponse = await this.$http.get("/refresh-token");
            if (renewJwtResponse.data) {
              await this.$store.dispatch("UpdateJWT", renewJwtResponse.data.jwt);
              this.updateJwt();
            }
          } catch (e) {
            this.stopNotificationInterval();
            await this.forcedLogout();
          }
        } else {
          this.stopNotificationInterval();
        }
        setTimeout(() => {
          this.isTokenRefreshing = false;
        }, 1000);
      }
    },
    async checkMaintenanceMode() {
      if (this.$router.currentRoute.path !== "/login" && this.$router.currentRoute.path !== "/recoverPassword") {
        try {
          const maintenanceResponse = await this.$http.get("/maintenance");
          if (maintenanceResponse.data) {
            this.isMaintenanceModeEnabled = maintenanceResponse.data.maintenance;
          }
        } catch (e) {
          this.stopCheckMaintenanceModeInterval();
        }
      } else {
        this.stopCheckMaintenanceModeInterval();
      }
    },
    async forcedLogout() {
      EventBus.$emit(this.$store.getters.getEvents.LOGGED_OUT);
      setTimeout(async () => {
        let jwt = this.$store.state.loginResponse.jwt;
        await this.$store.dispatch("Logoff");
        try {
          //Se ho il token jwt lo mando, cosi viene reportizzata la logout in modo corretto
          await this.$httpNoAuth.post("/auth/logout", null, { headers: { authorization: "Bearer " + jwt } });
        } catch {
          jwt = this.$store.state.loginResponse.jwt;
        }
        try {
          const content = localStorage.getItem(LocalStorage.getSSOKey());
          if (content) {
            const logoutPage = JSON.parse(content).logoutRedirect;
            if (logoutPage) {
              localStorage.removeItem(LocalStorage.getSSOKey());
              window.location.replace(logoutPage + "?jwt=" + jwt);
              return;
            }
          }
        } catch (e) {
          jwt = null;
        }
        await this.$router.push("/login").catch(() => {});
      }, 300);
    },
    async getUserNotification() {
      if (this.$router.currentRoute.path !== "/login") {
        try {
          const userNotificationResponse = await this.$http.get("/user-notifications");
          if (userNotificationResponse.data.length > 0) {
            EventBus.$emit(this.$store.getters.getEvents.NOTIFICATION, userNotificationResponse.data);
          }
        } catch (e) {
          this.stopNotificationInterval();
        }
      } else {
        this.stopNotificationInterval();
      }
    },
    updateScrollbar() {
      setTimeout(() => {
        if (this.$refs.ps) {
          this.$refs.ps.update();
        }
      }, 400);
    },
    scrollbarToTop() {
      if (this.$refs.ps) {
        this.$refs.ps.$el.scrollTop = 0;
      }
    },
    updateJwt() {
      this.$http.defaults.headers.common["authorization"] = "Bearer " + this.$store.state.loginResponse.jwt;
      this.$httpAuth.defaults.headers.common["authorization"] = "Bearer " + this.$store.state.loginResponse.jwt;
      EventBus.$emit("jwtTokenUpdated", this.$store.state.loginResponse.jwt);
    },
    async initAdminEnvironment(skipPushMainRoute) {
      this.updateJwt();
      let jwt = this.$store.state.loginResponse.jwt;
      try {
        const response = await this.$http.get("/company/user/settings");
        if (response.data) {
          await this.$store.dispatch("UpdateCompanySettings", response.data);
        }
        if (!skipPushMainRoute) {
          await this.$router.push("/").catch(() => {});
        }
      } catch (e) {
        await this.$store.dispatch("Logoff");
        let content = null;
        try {
          content = localStorage.getItem(LocalStorage.getSSOKey());
          if (content) {
            const logoutPage = JSON.parse(content).logoutRedirect;
            if (logoutPage) {
              localStorage.removeItem(LocalStorage.getSSOKey());
              window.location.replace(logoutPage + "?jwt=" + jwt);
              return;
            }
          }
        } catch (e) {
          content = null;
        }
        return this.$router.push("/login").catch(() => {});
      }

      this.userNotificationInterval = setInterval(this.getUserNotification.bind(this), this.userNotificationTimer);
      if (window.Worker) {
        this.worker = new Worker('./workers/updateJWT.js')
        this.worker.postMessage({ action: "start", jwt: this.$store.state.loginResponse.jwt });
        this.worker.onmessage = async (e) => {
          if (e?.data) {
            //console.log(`ConvyAdmin: updating JWT with ${e.data}...`);
            await this.$store.dispatch("UpdateJWT", e.data);
            this.updateJwt(e);
            //console.log("ConvyAdmin: JWT updated!");
          } else {
            this.stopNotificationInterval();
            this.forcedLogout();
          }
        };
      } else {
        this.refreshTokenInterval = setInterval(this.refreshToken.bind(this), this.refreshTokenTimer);
      }
      this.checkMaintenanceModeInterval = setInterval(this.checkMaintenanceMode.bind(this), this.checkMaintenanceModeTimer);
      this.checkMaintenanceMode();
      EventBus.$emit(this.$store.getters.getEvents.INIT_SUCCESS, "init");

      //*azzero le date salvate per i report che rimangono salvate nel local storage
      await this.$store.dispatch("AddSetting", {
        dateStart: moment().format("YYYY-MM-DD"),
        timeStart: "00:00",
        dateEnd: moment().format("YYYY-MM-DD"),
        timeEnd: "23:59",
      });

      this.changeTheme(this.$store.getters.getUserTheme);
      //faccio un check se il tizio fa un refresh con la force logout per maintenance mode in corso

      let maintenanceStorage = localStorage.getItem("convyai_maintenance_mode");
      try {
        if (maintenanceStorage) {
          maintenanceStorage = JSON.parse(maintenanceStorage);
          const now = new Date().getTime();
          if (maintenanceStorage.expire > now) {
            //se ho una force pending risetto il timer per il tempo che manca
            const delay = maintenanceStorage.expire - now;
            this.setForceLogoutMaintenanceMode(delay);
          }
        }
      } catch (e) {
        maintenanceStorage = null;
      }
    },
    setUpInterceptors() {
      //Interceptor per sbiancare lo stato a sessione http scaduta
      axios.interceptors.response.use(
        (response) => {
          // Do something with response data
          return response;
        },
        async (error) => {
          // Do something with response error
          if (error && error.response && error.response.status === 403) {
            await this.$store.dispatch("Logoff");
            let content = null;
            let jwt = this.$store.state.loginResponse.jwt;
            try {
              content = localStorage.getItem(LocalStorage.getSSOKey());
              if (content) {
                const logoutPage = JSON.parse(content).logoutRedirect;
                if (logoutPage) {
                  localStorage.removeItem(LocalStorage.getSSOKey());
                  window.location.replace(logoutPage + "?jwt=" + jwt);
                  return;
                }
              }
            } catch (e) {
              content = null;
            }
            await this.$router.push("/login").catch(() => {});
          }
          return Promise.reject(error);
        },
      );
    },
    changeTheme(e) {
      this.$vuetify.theme.dark = e;
      this.$store.dispatch("AddSetting", {
        themeDarkEnabled: e,
      });
    },
    updateLoading(state) {
      this.loading = state;
    },
    onChangeMaintenanceModeStatus(payload) {
      if (!payload.data.status) {
        this.clearForceLogoutTimeout();
      } else {
        this.setForceLogoutMaintenanceMode(this.userForceLogoutTime);
      }
    },
    onUpdatedMaintenanceStatus(status) {
      this.isMaintenanceModeEnabled = status;
    },
    setForceLogoutMaintenanceMode(delay) {
      if (!this.userForceLogoutTimeout) {
        this.isMaintenanceModeEnabled = true;
        localStorage.setItem("convyai_maintenance_mode", JSON.stringify({ status: true, expire: new Date().getTime() + this.userForceLogoutTime }));
        this.userForceLogoutTimeout = setTimeout(() => {
          //TODO se e solo se non sono root
          this.clearForceLogoutTimeout();
          this.forcedLogout();
        }, delay);
      }
    },
    clearForceLogoutTimeout() {
      localStorage.removeItem("convyai_maintenance_mode");
      if (this.userForceLogoutTimeout) {
        clearTimeout(this.userForceLogoutTimeout);
        this.userForceLogoutTimeout = null;
      }
    },
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    renderDrawer() {
      return this.$store.getters.isLogged;
    },
  },
  watch: {
    $route(value, oldValue) {
      this.reloadScrollbar++;
      if (value.meta.skipLoadingAnimation) return;
      if (oldValue.path.toLowerCase() === this.$route.path.toLowerCase()) return;
      this.updateLoading(true);
    },
  },
};
</script>

<style>
/* Rende clickabili i radio button e checkbox */
.v-icon {
  position: unset !important;
}

/*active*/
.theme--dark
  .drawerLeftMenu.v-navigation-drawer
  div.leftMenu.theme--dark.v-list--dense.v-list--nav
  div.v-list-group--active.v-list-group
  div.v-list-group__items
  div.theme--dark.v-list-item-group
  a.v-item--active.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  background: #293d52 !important;
  background-color: #293d52 !important;
}

.theme--dark.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:before {
  background: #455a64 !important;
  background-color: #455a64 !important;
  color: white;
}

.theme--dark .v-tooltip__content {
  border: 1px solid #455a64;
}

.theme--dark .v-list-item-group .v-list-item--active.theme--dark {
  color: white;
  opacity: 1;
}

.theme--dark .v-list-item--link:before {
  background-color: #039be5 !important;
}

/* hover sui panels e sulle tabelle*/
.theme--dark div.v-expansion-panel button.hoverBanner.v-expansion-panel-header,
.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: #41535d85 !important;
  border-color: #41535d85 !important;
}

html {
  overflow-y: hidden !important;
}

/* Customizzazione scrollbar globali */
@supports (-moz-appearance: none) {
  .jse-main .jse-contents .cm-scroller,
  .enagagementTable.v-data-table--dense,
  .v-data-table__wrapper,
  .v-menu__content,
  .v-autocomplete__content {
    scrollbar-width: thin;
    scrollbar-color: #a2a2a2 #f5f5f5;
  }
}

.jse-main .jse-contents .cm-scroller::-webkit-scrollbar,
.enagagementTable.v-data-table--dense::-webkit-scrollbar,
.v-data-table__wrapper::-webkit-scrollbar,
.v-menu__content::-webkit-scrollbar,
.v-autocomplete__content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.jse-main .jse-contents .cm-scroller::-webkit-scrollbar-track-piece,
.enagagementTable.v-data-table--dense::-webkit-scrollbar-track-piece,
.v-data-table__wrapper::-webkit-scrollbar-track-piece,
.v-menu__content::-webkit-scrollbar-track-piece,
.v-autocomplete__content::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
}

.jse-main .jse-contents .cm-scroller::-webkit-scrollbar-thumb,
.enagagementTable.v-data-table--dense::-webkit-scrollbar-thumb,
.v-data-table__wrapper::-webkit-scrollbar-thumb,
.v-menu__content::-webkit-scrollbar-thumb,
.v-autocomplete__content::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 10px;
}

.v-dialog {
  overflow-x: hidden;
}

.v-dialog--active {
  max-height: 95% !important;
}

.dialogDetailDialogContent {
  height: calc(100vh - 110px) !important;
}

.enagagementTable {
  max-height: 315px !important;
  overflow-x: auto;
}

.reallyBigModal {
  height: 95vh;
}

/* Scrollbar per la modale di dettaglio (eu-contact-modal) */
/* che eredita lo stile dal convyadmin */

/* Scrollbar per chromium */
.scrollbarcontainer::-webkit-scrollbar {
  width: 6px !important;
}

/* TRACK */
.theme--light .scrollbarcontainer::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
}

.theme--dark .scrollbarcontainer::-webkit-scrollbar-track {
  background-color: #263238 !important;
}

/* TRACK HOVER */
.theme--light .scrollbarcontainer:hover::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
}

.theme--dark .scrollbarcontainer:hover::-webkit-scrollbar-track {
  background-color: #263238 !important;
}

/* TRACK-PIECE */
.theme--light .scrollbarcontainer::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5 !important;
}

.theme--dark .scrollbarcontainer::-webkit-scrollbar-track-piece {
  background-color: #263238 !important;
}

/* //TRACK HOVER */
.theme--light .scrollbarcontainer:hover::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5 !important;
}

.theme--dark .scrollbarcontainer:hover::-webkit-scrollbar-track-piece {
  background-color: #263238 !important;
}

/* THUMB */
.scrollbarcontainer::-webkit-scrollbar-thumb {
  background-color: transparent !important;
  border-radius: 10px !important;
}

/* THUMB HOVER */
.theme--light .scrollbarcontainer:hover::-webkit-scrollbar-thumb {
  background-color: #cccccc !important;
}

.theme--dark .scrollbarcontainer:hover::-webkit-scrollbar-thumb {
  background-color: #8d8e8f !important;
}

/* Scrollbar per mozilla*/
.scrollbarcontainer {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.theme--light .scrollbarcontainer:hover {
  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 #f5f5f5;
}

.theme--dark .scrollbarcontainer:hover {
  scrollbar-width: thin;
  scrollbar-color: #a2a2a2 #263238;
}

/* CSS per visualizzazione tabellare dei grafici della wallboard */
#viewWallboardTableResult.v-data-table:not(.allow-horizontal-scroll) .v-data-table__wrapper {
  overflow-x: auto !important;
}

#viewWallboardTableResult .v-data-table__wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

#viewWallboardTableResult.table-tiny .v-data-table__wrapper {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
</style>
