<template>
  <v-card flat style="height: auto" class="pt-4">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="deleteItem(itemToDelete)" />
    <v-alert v-if="jumps && jumps.length == 0" class="mt-4" type="warning">
      System Jump have not yet been added
    </v-alert>
    <v-data-table
      v-else
      id="systemJumps"
      must-sort
      height="460"
      :headers="headers"
      :items="jumps"
      class="elevation-1"
      hide-default-footer
      hide-default-header
      disable-pagination
      fixed-header
    >
      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.value" :class="header.class" :style="header.width ? header.width : ''">
              <b v-show="!header.deleteAll">{{ header.text }}</b>
              <span v-show="header.deleteAll">
                <v-btn
                  v-show="jumps && jumps.length > 1"
                  small
                  text
                  color="error"
                  class="hidden-sm-and-down ml-n6 px-0"
                  @click.prevent="showConfirmDelete('all')"
                >
                  <b>Delete All</b>
                </v-btn>
                <v-icon class="hidden-md-and-up ma-o pa-0" size="24" @click.prevent="showConfirmDelete('all')">delete_forever</v-icon>
              </span>
            </th>
          </tr>
        </thead>
      </template>
      <template #body="{ items }">
        <tr v-for="(item, index) in items" :key="index">
          <td class="text-left text-truncate pa-2 pl-4">
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">{{ item.jumpName }}</span>
              </template>
              <span>{{ item.jumpName }}</span>
            </v-tooltip>
            <v-tooltip v-if="checkExistingFlowVersion(item)" top>
              <template #activator="{ on }">
                <v-icon class="mt-n2 ml-5" color="warning" v-on="on">
                  warning
                </v-icon>
              </template>
              {{ item.flow }} - {{ item.version }} no longer exists
            </v-tooltip>
          </td>
          <td class="text-truncate">
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">{{ item.flow }}</span>
              </template>
              <span>{{ item.flow }}</span>
            </v-tooltip>
          </td>
          <td class="text-truncate">
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">{{ item.version }}</span>
              </template>
              <span>{{ item.version }}</span>
            </v-tooltip>
          </td>
          <td class="text-truncate">
            <v-tooltip top>
              <template #activator="{ on }">
                <span v-on="on">{{ item.hook }}</span>
              </template>
              <span>{{ item.hook }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-icon size="24" @click="editItem(item)">
              edit
            </v-icon>
          </td>
          <td>
            <v-icon size="24" @click="showConfirmDelete(item)">
              delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-row justify="end">
        <v-col xs="12">
          <v-dialog v-model="editDialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn small color="primary" @click="resetNewJumpName" v-on="on">
                <v-icon left>
                  mdi-plus
                </v-icon>New System Jump
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">System Jump</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="editDialogForm" v-model="isNewItemValid">
                  <v-text-field
                    v-model.trim="newJump.jumpName"
                    label="System jump name"
                    placeholder="System jump name"
                    :rules="[requiredField, validateHookName]"
                    :autocomplete="$store.getters.disableAutocomplete"
                    :error-messages="duplicateNameError"
                    @keypress="duplicateNameError = []"
                  />
                  <v-autocomplete
                    v-model="newJump.flow"
                    label="Flow"
                    :items="flowList"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                  />
                  <v-autocomplete
                    v-model="newJump.version"
                    label="Version"
                    :items="versionList"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                  />
                  <v-autocomplete
                    v-model="newJump.hook"
                    label="Hook"
                    :items="hooksList"
                    :rules="[requiredField]"
                    :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions class="mt-n8">
                <v-row no-gutters>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="success" :disabled="!isNewItemValid" @click="saveNewItem">
                      <v-icon left>
                        mdi-floppy
                      </v-icon>Save
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="error" @click="closeDialog">
                      <v-icon left>
                        mdi-close
                      </v-icon>Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";
import merge from "deepmerge";
import ConfirmDialog from "../../../components/other/ConfirmationDialog";

export default {
  name: "SystemJump",
  components: {
    ConfirmDialog,
  },
  props: ["configuration", "flowTargets"],
  data() {
    return {
      showConfirm: false,
      itemToDelete: {},
      deleteMessage: "",
      render: 0,
      headers: [
        {
          text: "System Jump Name",
          value: "",
          sortable: true,
        },
        { text: "Flow", value: "target", sortable: true },
        { text: "Version", value: "version", sortable: true },
        { text: "Hook", value: "hook", sortable: true },
        { text: "", value: "edit", sortable: false, width: "width:80px" },
        {
          text: "",
          value: "delete",
          sortable: false,
          deleteAll: true,
          width: "width:80px",
        },
      ],
      editDialog: false,
      editedKey: "",
      newJump: {
        jumpName: "",
        flow: "",
        version: "",
        hook: "",
      },
      isNewItemValid: false,
      duplicateNameError: [],
    };
  },
  computed: {
    jumps() {
      this.render;
      let jumps = [];
      for (let jumpName in this.configuration.system_jumps) {
        jumps.push({
          jumpName: jumpName,
          ...this.configuration.system_jumps[jumpName],
        });
      }
      this.updateFlow();
      return jumps;
    },
    flowList() {
      let flows = [];
      if (this.flowTargets) {
        flows = Object.keys(this.flowTargets);
        this.updateFlow();
      }
      return flows;
    },
    versionList() {
      let versionList = [];
      if (this.flowTargets && this.newJump.flow !== "" && this.flowTargets[this.newJump.flow]) {
        versionList = Object.keys(this.flowTargets[this.newJump.flow]);
        this.updateVersion();
      }
      return versionList;
    },
    hooksList() {
      let hooksList = [];
      if (this.flowTargets && this.newJump.flow && this.newJump.version) {
        if (this.flowTargets[this.newJump.flow] && this.flowTargets[this.newJump.flow][this.newJump.version])
          hooksList = this.flowTargets[this.newJump.flow][this.newJump.version].hooks;
        this.updateHook();
      }
      return hooksList;
    },
  },
  methods: {
    ...fieldValidators,
    checkExistingFlowVersion(item) {
      return !this.flowTargets[item.flow];
    },
    updateFlow() {
      if (this.newJump.flow === "") {
        this.newJump.flow = Object.keys(this.flowTargets)[0];
      }
    },
    editItem(item) {
      for (let i = 0; i < Object.keys(this.configuration.system_jumps).length; i++) {
        if (
          Object.keys(this.configuration.system_jumps)[i] === item.jumpName &&
          this.configuration.system_jumps[item.jumpName].version === item.version &&
          this.configuration.system_jumps[item.jumpName].flow === item.flow &&
          this.configuration.system_jumps[item.jumpName].hook === item.hook
        ) {
          this.editedKey = Object.keys(this.configuration.system_jumps)[i];
          this.newJump = merge(this.newJump, item);
          this.editDialog = true;
        }
      }
    },
    showConfirmDelete(item) {
      this.itemToDelete = item;
      this.deleteMessage = "You will delete " + this.itemToDelete.jumpName;
      this.showConfirm = true;
    },
    deleteItem(item) {
      if (item === "all") {
        this.configuration.system_jumps = {};
      } else if (this.configuration.system_jumps[item.jumpName].version === item.version && item.hook === this.configuration.system_jumps[item.jumpName].hook) {
        delete this.configuration.system_jumps[item.jumpName];
      }
      this.render++;
      this.showConfirm = false;
    },
    updateVersion() {
      if (this.newJump.flow !== "" && this.newJump.version === "") this.newJump.version = Object.keys(this.flowTargets[this.newJump.flow])[0];
    },
    updateHook() {
      if (this.newJump.flow !== "" && this.newJump.version !== "" && this.newJump.hook === "")
        this.newJump.hook = this.flowTargets[this.newJump.flow][this.newJump.version][0];
    },
    closeDialog() {
      this.editDialog = false;
      this.showConfirm = false;
      this.editedKey = "";
      this.resetNewJumpName();
    },
    saveNewItem() {
      if (this.isNewItemValid) {
        this.duplicateNameError = [];
        if (Object.keys(this.configuration.system_jumps).includes(this.editedKey)) {
          delete this.configuration.system_jumps[this.editedKey];
        }
        if (!Object.keys(this.configuration.system_jumps).includes(this.newJump.jumpName)) {
          let newItem = {};
          newItem[this.newJump.jumpName] = {
            flow: this.newJump.flow,
            version: this.newJump.version,
            hook: this.newJump.hook,
          };

          this.configuration.system_jumps = merge(this.configuration.system_jumps, newItem);
          this.closeDialog();
        } else {
          this.duplicateNameError.push("This system jump name is already in use");
        }
      }
    },
    resetNewJumpName() {
      this.newJump = {
        jumpName: "",
        flow: "",
        version: "",
        hook: "",
      };
      if (this.$refs.editDialogForm) this.$refs.editDialogForm.resetValidation();
    },
  },
};
</script>
