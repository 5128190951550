<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Email Box"
      @input="
        searchString = $event;
        forceRender++;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="showWarning && getMailConfigList.length == 0 && !newConfig && searchString == ''" cols="12">
        <v-alert type="warning">
          No Email boxes configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getMailConfigList.length == 0 && !newConfig && searchString != ''" cols="12">
        <v-alert type="warning">
          No Email box match your search
        </v-alert>
      </v-col>
      <!-- Panel for new item -->
      <v-expansion-panels v-if="newConfig" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Email Box
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <EmailBoxesForm :mailSetting="newConfig" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Panel to list configuration -->

      <v-expansion-panels :key="forceRender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(mailSetting, id) in getMailConfigList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col md="2" sm="4">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ mailSetting.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="4" sm="6" lg="3">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ mailSetting.email }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down" md="4">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Authentication</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ getAuthenticationType(mailSetting) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col v-if="mailSetting.primary" md="1" offset-lg="0" offset-md="1" sm="1">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon color="grey lighten-1">
                          mdi-bell
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Used for notifications</span>
                  </v-tooltip>
                </v-col>
                <v-col v-else md="1" offset-lg="0" offset-md="1" sm="1">
                  <v-icon color="grey lighten-1">
                    mdi-bell-off
                  </v-icon>
                </v-col>

                <v-col class="hidden-md-and-down" lg="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="mailSetting.lastUpdate">
                        {{ mailSetting.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <EmailBoxesForm :key="forceRender + id + '_eb'" :mailSetting="mailSetting" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <AddNewItemButton name="Email Box" @addNewItem="addNewConfig" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import EmailBoxesForm from "../../components/advanced/EmailBoxesForm";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";

export default {
  name: "EmailBoxes",
  components: {
    AddNewItemButton,
    SearchBar,
    ResultSnackbar,
    EmailBoxesForm,
  },
  data() {
    return {
      expansionPanelCreation: null,
      expansionPanel: null,
      mailSettings: null,
      forceRender: 0,
      searchString: "",
      //Data bind per creazione nuova ocnfigurazione
      newConfig: null,
      showWarning: false,
    };
  },
  computed: {
    ...spacing,
    getMailConfigList() {
      this.forceRender;
      if (this.mailSettings) {
        return (
          Object.values(this.mailSettings)
            //Filtro per la ricerca
            .filter(
              (emailBox) =>
                emailBox.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1 ||
                emailBox.name.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1,
            )
            //Sorting per un campo
            .sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              return 0;
            })
        );
      }
      return [];
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CANCEL_EMAILBOXES_CREATION, this.cancelConfigCreation);
    EventBus.$on(this.$store.getters.getEvents.EDIT_EMAILBOXES, this.editEmailBoxes);
    EventBus.$on(this.$store.getters.getEvents.DELETE_EMAILBOXES, this.deleteEmailBoxes);
    EventBus.$on(this.$store.getters.getEvents.EDIT_EMAILBOXES_FAIL, this.editEmailBoxesFail);
    EventBus.$on(this.$store.getters.getEvents.DELETE_EMAILBOXES_FAIL, this.deleteEmailBoxesFail);
    EventBus.$on(this.$store.getters.getEvents.TEST_EMAILBOXES_SUCCESS, this.testSuccess);
    EventBus.$on(this.$store.getters.getEvents.TEST_EMAILBOXES_FAIL, this.testFail);
    //Load Configurations
    try {
      const result = await this.$http.get("/mail-settings");
      this.mailSettings = result.data;
    } catch (e) {
      this.$refs.resultSnackbar.showError(`Fail to load Email Boxes`);
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CANCEL_EMAILBOXES_CREATION, this.cancelConfigCreation);
    EventBus.$off(this.$store.getters.getEvents.EDIT_EMAILBOXES, this.editEmailBoxes);
    EventBus.$off(this.$store.getters.getEvents.DELETE_EMAILBOXES, this.deleteEmailBoxes);
    EventBus.$off(this.$store.getters.getEvents.EDIT_EMAILBOXES_FAIL, this.editEmailBoxesFail);
    EventBus.$off(this.$store.getters.getEvents.DELETE_EMAILBOXES_FAIL, this.deleteEmailBoxesFail);
    EventBus.$off(this.$store.getters.getEvents.TEST_EMAILBOXES_SUCCESS, this.testSuccess);
    EventBus.$off(this.$store.getters.getEvents.TEST_EMAILBOXES_FAIL, this.testFail);
  },
  methods: {
    ...scroller,
    getAuthenticationType(mailSetting) {
      if (mailSetting.useAuth) {
        return this.getLabelOf(mailSetting.serviceAuth);
      }
      //Retrocompatibilità
      if (mailSetting.authType) {
        return this.getLabelOf("google-oauth2");
      }
      if (mailSetting.username) {
        return this.getLabelOf("basic");
      }
      return "N/A";
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    getLabelOf(key) {
      const obj = this.$store.state.enums.serviceAuthTypes.find((sat) => sat.value === key);
      if (obj) {
        return obj.text;
      }
      //Per retrocompatibilità
      return "basic";
    },
    addNewConfig() {
      if (!this.newConfig) {
        this.newConfig = {
          name: "",
          email: "",
          username: "",
          password: "",
          retypePassword: "",
          secure: false,
          host: "",
          port: "",
          protocol: "smtp",
          primary: false,
          serviceClient: "",
          privateKey: "",
          attachments: {
            report: {
              enableMaxSize: false,
              maxMbSize: 10,
            },
          },
        };
      }
      this.expansionPanel = null;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelConfigCreation() {
      this.expansionPanelCreation = null;
      this.newConfig = null;
    },
    editEmailBoxes(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
      if (!this.mailSettings[obj.editConfig.name]) {
        this.closeAllPanels();
      }
      this.mailSettings[obj.editConfig.name] = obj.editConfig;
      if (this.mailSettings[obj.editConfig.name].primary) {
        for (let key in this.mailSettings) {
          if (this.mailSettings[key].primary && this.mailSettings[key].name !== obj.editConfig.name) {
            this.mailSettings[key].primary = false;
          }
        }
      }
      this.forceRender++;
    },
    editEmailBoxesFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    deleteEmailBoxes(obj) {
      if (this.mailSettings[obj.name]) {
        delete this.mailSettings[obj.name];
        this.$refs.resultSnackbar.showSuccess(obj.message);
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRender++;
        }, 100);
      }
    },
    deleteEmailBoxesFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelConfigCreation();
      this.expansionPanel = null;
    },
    testSuccess() {
      this.$refs.resultSnackbar.showSuccess("Test Connection Success");
    },
    testFail(obj) {
      this.$refs.resultSnackbar.showError("Test Connection Failed: " + obj.error);
    },
  },
};
</script>
