<template>
  <v-card flat style="height: auto" class="pt-4">
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="deleteItem(itemToDelete)" />
    <v-alert v-if="conditionalJumps && conditionalJumps.length == 0" class="mt-4" type="warning">
      Conditional Jumps have not yet been added
    </v-alert>
    <v-data-table
      v-else
      id="conditionalJumps"
      must-sort
      height="460"
      :headers="headers"
      :items="conditionalJumps"
      class="elevation-1"
      hide-default-footer
      hide-default-header
      disable-pagination
      fixed-header
    >
      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.value" :class="header.class" :style="header.width ? header.width : ''">
              <b v-show="!header.deleteAll">{{ header.text }}</b>
              <span v-show="header.deleteAll">
                <v-btn
                  v-show="conditionalJumps && conditionalJumps.length > 1"
                  small
                  text
                  color="error"
                  class="hidden-sm-and-down ml-n6 px-0"
                  @click.prevent="showConfirmDelete('all')"
                >
                  <b>Delete All</b>
                </v-btn>
                <v-icon class="hidden-md-and-up ma-o pa-0" size="24" @click.prevent="showConfirmDelete('all')">delete_forever</v-icon>
              </span>
            </th>
          </tr>
        </thead>
      </template>
      <template #body="{ items }">
        <draggable v-model="conditionalJumps" tag="tbody" handle=".sortHandle" @change="onDragMoved">
          <tr v-for="(item, index) in items" :key="index">
            <td class="hidden-sm-and-down text-start">
              <v-btn small text style="cursor: move" icon class="sortHandle ma-0 pa-0">
                <v-icon>mdi-cursor-move</v-icon>
              </v-btn>
            </td>
            <td class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.jumpName }}</span>
                </template>
                <span>{{ item.jumpName }}</span>
              </v-tooltip>
              <v-tooltip v-if="checkExistingFlowVersion(item)" top>
                <template #activator="{ on }">
                  <v-icon class="mt-n2 ml-5" color="warning" v-on="on">
                    warning
                  </v-icon>
                </template>
                {{ item.flow }} - {{ item.version }} no longer exists
              </v-tooltip>
            </td>
            <td style="max-width: 40vh" class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.condition }}</span>
                </template>
                <span>{{ item.condition }}</span>
              </v-tooltip>
            </td>
            <td class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.flow }}</span>
                </template>
                <span>{{ item.flow }}</span>
              </v-tooltip>
            </td>
            <td class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.version }}</span>
                </template>
                <span>{{ item.version }}</span>
              </v-tooltip>
            </td>
            <td class="text-left text-truncate">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.hook }}</span>
                </template>
                <span>{{ item.hook }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-icon size="24" @click="editItem(item)">
                edit
              </v-icon>
            </td>

            <td>
              <v-icon size="24" @click="showConfirmDelete(item, index)">
                delete
              </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-row justify="end">
        <v-col xs="12">
          <v-dialog v-model="editDialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn small color="primary" @click="resetNewJumpName" v-on="on">
                <v-icon left>
                  mdi-plus
                </v-icon>New Conditional Jump
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">Conditional Jump</span>
              </v-card-title>
              <perfect-scrollbar ref="ps" class="psConditionalJumpSectionModal" :options="$store.getters.getDefaultScrollProps">
                <v-card-text>
                  <v-form ref="editDialogForm" v-model="isNewItemValid">
                    <v-text-field
                      v-model.trim="newJump.jumpName"
                      label="Conditional jump name"
                      placeholder="Conditional jump name"
                      :rules="[requiredField, validateConditionalJump]"
                      :autocomplete="$store.getters.disableAutocomplete"
                      :error-messages="duplicateNameError"
                      @keypress="duplicateNameError = []"
                    />
                    <v-textarea
                      v-model.trim="newJump.condition"
                      label="Jump Condition"
                      placeholder="Jump Condition"
                      :rules="[requiredField]"
                      :autocomplete="$store.getters.disableAutocomplete"
                    />
                    <v-autocomplete
                      v-model="newJump.flow"
                      label="Flow"
                      :items="flowList"
                      :rules="[requiredField]"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      @change="onChangeFLow"
                    />
                    <v-autocomplete
                      v-model="newJump.version"
                      label="Version"
                      :items="versionList"
                      :rules="[requiredField]"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                      @change="onChangeVersion"
                    />
                    <v-autocomplete
                      v-model="newJump.hook"
                      label="Hook"
                      :items="hooksList"
                      :rules="[requiredField]"
                      :autocomplete="$store.getters.vAutocompleteDisableAutocomplete"
                    />
                  </v-form>
                </v-card-text>
              </perfect-scrollbar>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="success" :disabled="!isNewItemValid" @click="saveNewItem">
                      <v-icon left>
                        mdi-floppy
                      </v-icon>Save
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-btn block outlined color="error" @click="closeDialog">
                      <v-icon left>
                        mdi-close
                      </v-icon>Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import fieldValidators from "../../../helpers/fieldValidators";
import merge from "deepmerge";
import ConfirmDialog from "../../../components/other/ConfirmationDialog";
import draggable from "vuedraggable";

export default {
  name: "ConditionalJump",
  components: {
    ConfirmDialog,
    draggable,
  },
  props: ["configuration", "flowTargets"],
  data() {
    return {
      showConfirm: false,
      itemToDelete: {},
      deleteMessage: "",
      headers: [
        {
          text: "",
          value: "drag",
          sortable: false,
          class: "hidden-sm-and-down drag",
        },
        {
          text: "Conditional Jump Name",
          value: "",
          sortable: false,
        },
        { text: "Jump Condition", value: "condition", sortable: false },
        { text: "Flow", value: "target", sortable: false },
        { text: "Version", value: "version", sortable: false },
        { text: "Hook", value: "hook", sortable: false },
        { text: "", value: "edit", sortable: false, width: "width:100px" },
        {
          text: "",
          value: "delete",
          sortable: false,
          deleteAll: true,
          width: "width:140px",
        },
      ],
      editDialog: false,
      editedKey: "",
      editedIndex: null,
      newJump: {
        jumpName: "",
        condition: "",
        flow: "",
        version: "",
        hook: "",
      },
      isNewItemValid: false,
      duplicateNameError: [],
      conditionalJumps: [],
    };
  },
  computed: {
    flowList() {
      let flows = [];
      if (this.flowTargets) {
        flows = Object.keys(this.flowTargets);
        this.updateFlow();
      }
      return flows;
    },
    versionList() {
      let versionList = [];
      if (this.flowTargets && this.newJump.flow !== "" && this.flowTargets[this.newJump.flow]) {
        versionList = Object.keys(this.flowTargets[this.newJump.flow]);
        this.updateVersion();
      }
      return versionList;
    },
    hooksList() {
      let hooksList = [];
      if (this.flowTargets && this.newJump.flow && this.newJump.version) {
        if (this.flowTargets[this.newJump.flow] && this.flowTargets[this.newJump.flow][this.newJump.version])
          hooksList = this.flowTargets[this.newJump.flow][this.newJump.version].hooks;
        this.updateHook();
      }
      return hooksList;
    },
  },
  mounted() {
    for (let jumpName in this.configuration.conditional_jumps) {
      this.conditionalJumps.push({
        jumpName: jumpName,
        ...this.configuration.conditional_jumps[jumpName],
      });
    }
    this.updateFlow();
  },
  methods: {
    ...fieldValidators,
    onChangeFLow() {
      this.newJump.version = "";
      this.newJump.hook = "";
    },
    onChangeVersion() {
      this.newJump.hook = "";
    },
    onDragMoved() {
      let jumpsObj = {};
      for (let i in this.conditionalJumps) {
        let obj = {};
        obj.flow = this.conditionalJumps[i].flow;
        obj.version = this.conditionalJumps[i].version;
        obj.hook = this.conditionalJumps[i].hook;
        obj.condition = this.conditionalJumps[i].condition;
        jumpsObj[this.conditionalJumps[i].jumpName] = merge(jumpsObj, obj);
      }
      this.configuration.conditional_jumps = jumpsObj;
    },
    checkExistingFlowVersion(item) {
      if (!this.flowTargets[item.flow]) {
        return true;
      }
      return false;
    },
    scrollToBottom() {
      let wrapper = this.$refs["psConditionalJump"];
      this.$vuetify.goTo(".formFiledRow:last-of-type", { container: wrapper });
    },
    updateFlow() {
      if (this.newJump.flow === "") {
        this.newJump.flow = Object.keys(this.flowTargets)[0];
      }
    },
    editItem(item) {
      for (let i = 0; i < Object.keys(this.configuration.conditional_jumps).length; i++) {
        if (
          Object.keys(this.configuration.conditional_jumps)[i] == item.jumpName &&
          this.configuration.conditional_jumps[item.jumpName].version == item.version &&
          this.configuration.conditional_jumps[item.jumpName].flow == item.flow &&
          this.configuration.conditional_jumps[item.jumpName].hook == item.hook
        ) {
          this.editedKey = Object.keys(this.configuration.conditional_jumps)[i];
          this.editedIndex = i;
          this.newJump = merge(this.newJump, item);
          this.editDialog = true;
        }
      }
    },
    showConfirmDelete(item, index) {
      this.itemToDelete = item;
      if (index || index === 0) {
        this.itemToDelete.index = index;
      }
      this.deleteMessage = "You will delete " + this.itemToDelete.jumpName;
      this.showConfirm = true;
    },
    deleteItem(item) {
      if (item === "all") {
        this.configuration.conditional_jumps = {};
        this.conditionalJumps = [];
      } else if (
        this.configuration.conditional_jumps[item.jumpName].version === item.version &&
        item.hook === this.configuration.conditional_jumps[item.jumpName].hook
      ) {
        if (this.itemToDelete.index || this.itemToDelete.index === 0) {
          this.conditionalJumps.splice(this.itemToDelete.index, 1);
        }
        delete this.configuration.conditional_jumps[item.jumpName];
      }
      this.showConfirm = false;
    },
    updateVersion() {
      if (this.newJump.flow !== "" && this.newJump.version === "") this.newJump.version = Object.keys(this.flowTargets[this.newJump.flow])[0];
    },
    updateHook() {
      if (this.newJump.flow !== "" && this.newJump.version !== "" && this.newJump.hook === "")
        this.newJump.hook = this.flowTargets[this.newJump.flow][this.newJump.version][0];
    },
    closeDialog() {
      this.editDialog = false;
      this.showConfirm = false;
      this.editedKey = "";
      this.editedIndex = null;
      this.resetNewJumpName();
    },
    saveNewItem() {
      if (this.isNewItemValid) {
        this.duplicateNameError = [];
        if (Object.keys(this.configuration.conditional_jumps).includes(this.editedKey)) {
          delete this.configuration.conditional_jumps[this.editedKey];
        }
        if (!Object.keys(this.configuration.conditional_jumps).includes(this.newJump.jumpName)) {
          let newItem = {};
          newItem[this.newJump.jumpName] = {
            condition: this.newJump.condition,
            flow: this.newJump.flow,
            version: this.newJump.version,
            hook: this.newJump.hook,
          };

          this.configuration.conditional_jumps = merge(this.configuration.conditional_jumps, newItem);
          if (this.editedIndex || this.editedIndex === 0) {
            this.conditionalJumps.splice(this.editedIndex, 1);
          }
          this.conditionalJumps.push({
            jumpName: this.newJump.jumpName,
            ...newItem[this.newJump.jumpName],
          });
          if (Object.keys(this.configuration.conditional_jumps).length > 1) {
            this.scrollToBottom();
          }
          this.closeDialog();
        } else {
          this.duplicateNameError.push("This conditional jump name is already in use");
        }
      }
    },
    resetNewJumpName() {
      this.newJump = {
        jumpName: "",
        condition: "",
        flow: "",
        version: "",
        hook: "",
      };
      if (this.$refs.editDialogForm) this.$refs.editDialogForm.resetValidation();
    },
  },
};
</script>

<style>
.psConditionalJumpSectionModal {
  height: 65vh !important;
}
</style>
