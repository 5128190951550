<template>
  <v-row class="mx-0" align="center" justify="center">
    <v-col cols="12" md="6" v-if="!editedConnector.uuid">
      <v-text-field
        label="Connector's name"
        v-model.trim="editedConnector.name"
        prepend-inner-icon="mdi-card-bulleted"
        :autocomplete="$store.getters.disableAutocomplete"
        :rules="[requiredField, validateConnectorName, isConnectorNameUnique(connectors, editedConnector.name)]"
      />
    </v-col>
    <v-col cols="12" md="6" v-if="editedConnector.uuid">
      <v-text-field readonly disabled label="UUID" v-model="editedConnector.uuid" prepend-inner-icon="mdi-code-braces" :rules="[requiredField]" />
    </v-col>
    <v-col cols="12" md="6">
      <v-combobox
        class="mt-1"
        v-model="editedConnector.domains"
        hide-selected
        label="Domain List"
        hint="Configure in which domains your bot will be published"
        multiple
        small-chips
        deletable-chips
        prepend-inner-icon="mdi-domain"
        :rules="[requiredField]"
      />
    </v-col>
    <v-expansion-panels class="my-6" v-if="$store.getters.isIntegrationEnabled('wcs')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon color="primary">mdi-clock-start</v-icon>
            <span class="ml-4">Availability Times</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-item-group mandatory>
            <v-row justify="center">
              <v-col cols="4" lg="3" md="3" sm="3" v-for="(item, index) in availabilityTypesList" :key="'availabilityTypeList_' + index">
                <v-item v-slot="{ toggle }">
                  <v-tooltip :disabled="$vuetify.breakpoint.lgAndUp" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="60"
                        class="fill-width"
                        :class="editedConnector.details.agentAvailability.availabilityType === item.type ? 'primary' : 'light'"
                        @click="changeAvailabilitySelected(toggle, item.type)"
                        elevation="5"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-row align="center" no-gutters>
                          <v-col cols="4" lg="4" md="12" sm="12">
                            <v-icon :size="item.size" :color="$vuetify.theme.dark ? '#0c666a' : '#6fc1c5'">{{ item.icon }}</v-icon>
                          </v-col>
                          <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" class="ma-0 pa-0 text-center" style="position: absolute">
                            <span :style="getFontSize()"> {{ item.label }}</span>
                          </v-col>
                        </v-row>
                      </v-btn>
                    </template>
                    <span>{{ item.label }}</span>
                  </v-tooltip>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
          <BusinessHours v-if="isActiveCard('businessHours')" :businessHours="editedConnector.details.agentAvailability.businessHours" />
          <AgentStatus v-if="isActiveCard('agentStatus')" :agentStatus="editedConnector.details.agentAvailability.agentStatus" />
          <Always v-if="isActiveCard('always')" :always="editedConnector.details.agentAvailability.always" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import spacing from "../../../helpers/spacing";
import fieldValidators from "../../../helpers/fieldValidators";
import BusinessHours from "@/components/publish/connectors/agentAvailability/BusinessHours";
import AgentStatus from "@/components/publish/connectors/agentAvailability/AgentStatus";
import Always from "@/components/publish/connectors/agentAvailability/Always";

export default {
  components: {
    BusinessHours,
    AgentStatus,
    Always,
  },
  name: "WebConf",
  props: ["editedConnector", "connectors"],
  data() {
    return {
      availabilityTypesList: [
        { type: "businessHours", label: "Business Hours", icon: "mdi-briefcase-clock", size: "55" },
        { type: "agentStatus", label: "Agent Status", icon: "mdi-account-multiple-check", size: "75" },
        { type: "always", label: "Always", icon: "mdi-hours-24", size: "55" },
      ],
    };
  },
  mounted() {
    this.editedConnector.type = "webapi";
  },
  methods: {
    ...spacing,
    ...fieldValidators,
    getFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
        case "xl":
          return "font-size:1.4em";
        case "md":
        case "sm":
          return "font-size:1.2em";
        default:
          return "font-size:0.8em";
      }
    },
    changeAvailabilitySelected(toggle, type) {
      //se la mia ultima slezione era businesshours allora controllo che l'ultimo elemento sia confiurato altrimenti o elimino
      if (this.editedConnector.details.agentAvailability.availabilityType === "businessHours") {
        let item =
          this.editedConnector.details.agentAvailability.businessHours.hours[this.editedConnector.details.agentAvailability.businessHours.hours.length - 1];
        if (!item.period || !item.startAt || !item.finishAt) {
          this.editedConnector.details.agentAvailability.businessHours.hours.pop();
        }
      }
      //se il mio nuovo tipo e' businesshours e avevo rimosso l'unico elemento che aveva lo inserisco nuovo pulito
      this.editedConnector.details.agentAvailability.availabilityType = type;
      if (type === "businessHours") {
        if (this.editedConnector.details.agentAvailability.businessHours.hours.length === 0) {
          this.editedConnector.details.agentAvailability.businessHours.hours.push({ period: "", startAt: "", finishAt: "" });
        }
      }
      toggle();
    },
    isActiveCard(type) {
      return this.editedConnector.details.agentAvailability && this.editedConnector.details.agentAvailability.availabilityType === type;
    },
  },
};
</script>
<style>
table td div.v-input--checkbox {
  justify-content: center;
}
.prova {
  margin-left: -0.22em;
  margin-bottom: -0.7em;
}
</style>
