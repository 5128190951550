import { render, staticRenderFns } from "./Errors.vue?vue&type=template&id=8063f662&scoped=true&class=mt-2"
import script from "./Errors.vue?vue&type=script&lang=js"
export * from "./Errors.vue?vue&type=script&lang=js"
import style0 from "./Errors.vue?vue&type=style&index=0&id=8063f662&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8063f662",
  null
  
)

export default component.exports