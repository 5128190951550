import XRegExp from "xregexp";
import store from "../store";

var languagesConfigurations = [
  {
    name: "Afrikaans",
    code: "af",
    flag: "ZA",
  },
  {
    name: "Albanian",
    code: "sq",
    flag: "AL",
  },
  {
    name: "Arabic",
    code: "ar",
    flag: "AE",
  },
  {
    name: "Azerbaijani",
    code: "az",
    flag: "AZ",
  },
  {
    name: "Basque",
    code: "eu",
    flag: "ES",
  },
  {
    name: "Belarusian",
    code: "be",
    flag: "BY",
  },
  {
    name: "Bengali",
    code: "bn",
    flag: "BD",
  },
  {
    name: "Bulgarian",
    code: "bg",
    flag: "BG",
  },
  {
    name: "Catalan",
    code: "ca",
    flag: "ES-ca",
  },
  {
    name: "Chinese Simplified",
    code: "zh-CN",
    flag: "CN",
  },
  {
    name: "Chinese Traditional",
    code: "zh-TW",
    flag: "CN",
  },
  {
    name: "Croatian",
    code: "hr",
    flag: "HR",
  },
  {
    name: "Czech",
    code: "cs",
    flag: "CZ",
  },
  {
    name: "Danish",
    code: "da",
    flag: "DK",
  },
  {
    name: "Dutch",
    code: "nl",
    flag: "DE",
  },
  {
    name: "English",
    code: "en",
    flag: "GB",
  },
  {
    name: "Esperanto",
    code: "eo",
    flag: "ES",
  },
  {
    name: "Estonian",
    code: "et",
    flag: "EE",
  },
  {
    name: "Filipino",
    code: "tl",
    flag: "PH",
  },
  {
    name: "Finnish",
    code: "fi",
    flag: "FI",
  },
  {
    name: "French",
    code: "fr",
    flag: "FR",
  },
  {
    name: "Galician",
    code: "gl",
    flag: "ES",
  },
  {
    name: "Georgian",
    code: "ka",
    flag: "GE",
  },
  {
    name: "German",
    code: "de",
    flag: "DE",
  },
  {
    name: "Greek",
    code: "el",
    flag: "GR",
  },
  {
    name: "Gujarati",
    code: "gu",
    flag: "IN",
  },
  {
    name: "Haitian Creole",
    code: "ht",
    flag: "HT",
  },
  {
    name: "Hebrew",
    code: "iw",
    flag: "IL",
  },
  {
    name: "Hindi",
    code: "hi",
    flag: "IN",
  },
  {
    name: "Hungarian",
    code: "hu",
    flag: "HU",
  },
  {
    name: "Icelandic",
    code: "is",
    flag: "IS",
  },
  {
    name: "Indonesian",
    code: "id",
    flag: "ID",
  },
  {
    name: "Irish",
    code: "ga",
    flag: "IE",
  },
  {
    name: "Italian",
    code: "it",
    flag: "IT",
  },
  {
    name: "Japanese",
    code: "ja",
    flag: "JP",
  },
  {
    name: "Kannada",
    code: "kn",
    flag: "CA",
  },
  {
    name: "Korean",
    code: "ko",
    flag: "KP",
  },
  {
    name: "Latin",
    code: "la",
    flag: "IT",
  },
  {
    name: "Latvian",
    code: "lv",
    flag: "LV",
  },
  {
    name: "Lithuanian",
    code: "lt",
    flag: "LT",
  },
  {
    name: "Macedonian",
    code: "mk",
    flag: "RS",
  },
  {
    name: "Malay",
    code: "ms",
    flag: "MY",
  },
  {
    name: "Maltese",
    code: "mt",
    flag: "MT",
  },
  {
    name: "Norwegian",
    code: "no",
    flag: "NO",
  },
  {
    name: "Persian",
    code: "fa",
    flag: "IR",
  },
  {
    name: "Polish",
    code: "pl",
    flag: "PL",
  },
  {
    name: "Portuguese",
    code: "pt",
    flag: "PT",
  },
  {
    name: "Romanian",
    code: "ro",
    flag: "RO",
  },
  {
    name: "Russian",
    code: "ru",
    flag: "RU",
  },
  {
    name: "Serbian",
    code: "sr",
    flag: "RS",
  },
  {
    name: "Slovak",
    code: "sk",
    flag: "SK",
  },
  {
    name: "Slovenian",
    code: "sl",
    flag: "SL",
  },
  {
    name: "Spanish",
    code: "es",
    flag: "ES",
  },
  {
    name: "Swahili",
    code: "sw",
    flag: "KE",
  },
  {
    name: "Swedish",
    code: "sv",
    flag: "SE",
  },
  {
    name: "Tamil",
    code: "ta",
    flag: "LK",
  },
  {
    name: "Telugu",
    code: "te",
    flag: "IN",
  },
  {
    name: "Thai",
    code: "th",
    flag: "TH",
  },
  {
    name: "Turkish",
    code: "tr",
    flag: "TR",
  },
  {
    name: "Ukrainian",
    code: "uk",
    flag: "UA",
  },
  {
    name: "Urdu",
    code: "ur",
    flag: "PK",
  },
  {
    name: "Vietnamese",
    code: "vi",
    flag: "VN",
  },
  {
    name: "Welsh",
    code: "cy",
    flag: "GB",
  },
  {
    name: "Yiddish",
    code: "yi",
    flag: "DE",
  },
];

var BlocksBuilder = function (opts) {
  this.availableLanguages = Object.assign({}, opts.languageList);
  this.nodeType = opts.nodeType;
  this.blockDefinition = opts.blockDefinition;
  this.engines = opts.engines;
  this.inputProcessors = opts.inputProcessors;
  this.nodeOutput = [{ name: "", label: "" }];
  this.scene = null;
  this.params = opts.params;
  this.defaultLang = opts.defaultLang;
  this.role = opts.role;
  this.excludedSystemVariables = [
    "%dialogId%",
    "%textToAnalyze%",
    "%lastInputText%",
    "%lastOutputText%",
    "%conversation_history%",
    "%conversation_history_plain%",
    "%system_source%",
    "%system_channel%",
    "%system_user_name%",
    "%system_user_id%",
    "%company%",
    "%previous_flow%",
    "%bot_name%",
    "%lang%",
    "%system_user_token%",
    "%system_contact%",
    "%system_back_counter%",
    "%system_back_consecutive_counter%",
    "%system_user_interaction_counter%",
    "%lastIntentValueDetected%",
    "%lastIntentLabelDetected%",
    "%lastIntentScoreDetected%",
    "%lastIntentSentimentDetected%",
    "%LP_conversationId%",
    "%LP_agentLoginName%",
    "%LP_mcs%",
    "%LP_acceptedTaskTime%",
    "%LP_fullReport%",
    "%LP_handoverError%",
    "%LP_handoverErrorRequest%",
    "%agentId",
    "%agentName%",
    "%agentSurname%",
    "%agentUsername%",
    "%agentProfileName%",
    "%email_thread_id%",
    "%email_sender%",
    "%email_cc%",
    "%email_ccn%",
    "%email_receiver%",
    "%email_subject%",
    "%email_id%",
    "%email_reply_to%",
    "%email_references%",
    "%queueId%",
    "%queueName%",
  ];

  this.getScene = function (flow, bot, defaultConfiguration, flowsVariables) {
    var role = this.role.toLowerCase();
    var readOnlyMode = false;
    switch (role) {
      case store.getters.getRoles.SUPERVISOR:
      case store.getters.getRoles.USER:
        readOnlyMode = true;
        break;
    }
    //Se non esiste un flow, ne creo uno
    if (flow == null) {
      var flowName = this.params.flow;
      var langInfo = this.defaultLang.toLowerCase();
      //var lastUpdate = this.formatDate();
      flow = {
        blocks: {},
        links: {},
        container: {
          centerX: 0,
          centerY: 0,
          scale: 0.7,
        },
        version: 0,
        configurations: defaultConfiguration,
        attr: {
          flowName: flowName,
          flowSelectedLang: langInfo,
          flowsVariables: flowsVariables,
          ivr: bot ? bot.isVisualIvr : false,
          outbound: false,
          lastUpdate: -1,
          readOnlyMode: readOnlyMode,
          firstNodeId: 1,
        },
      };
      if (bot?.isOutbound && bot?.flow === this.params.flow && bot?.version === this.params.version) {
        //definizione sample nodo outbound
        flow.attr.outbound = true; //solo il primo flusso del bot è di tipo outbound
        flow.version = 2;
        flow.blocks = {
          1: {
            id: 1,
            uuid: 1,
            x: 600,
            y: 260,
            selected: true,
            matchedNodes: false,
            evident: true,
            name: "outbound",
            inputs: [{}],
            outputs: [
              {
                name: "",
                label: "",
              },
              {
                name: "Take",
                label: "Take",
              },
            ],
            values: {
              property: {
                hook: {
                  value: "",
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: false,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: false,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: false,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: "",
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: "",
                      type: "textarea",
                    },
                  },
                },
              },
            },
          },
        };
        // nel caso di bot outbound, in questo caso non ho un flusso allora devo effettuare l'autosalvataggio
        flow.attr.autosaveRequired = true;
      }
      return flow;
    }
    //Se ne flow c'è salvata la scena, la riuso
    //if (flow && flow.scene && new Date(flow.scene.attr.lastUpdate) >= new Date(flow.updated_at)) {
    if (flow && flow.scene) {
      // Teo: ho tolro il controllo date perchè non capisco il caso in cui serve.
      //aggiorno per avere la modalità di lettura o modifica
      flow.scene.attr["readOnlyMode"] = readOnlyMode;
      flow.scene.attr.outbound = bot?.isOutbound && bot?.flow === this.params.flow && bot?.version === this.params.version ? true : false;
      if (!flow.scene.attr.flowsVariables) {
        flow.scene.attr.flowsVariables = {};
        flow.scene.attr.flowsVariables = flowsVariables;
      }
      if (!flow.scene.configurations.menu_node_configurations) {
        flow.scene.configurations.menu_node_configurations = defaultConfiguration.menu_node_configurations;
      }
      flow.scene.configurations.global_variables = defaultConfiguration.global_variables;
      /* eslint-disable no-console */
      //console.log("Loaded flow with scene version " + flow.scene.version);
      /* eslint-enable no-console */
      return flow.scene;
    }
    var targets = {}; //Per segnarmi tutte le uscite
    flowName = Object.keys(flow.flow)[0];
    langInfo = this.getLanguageInfo(flow.flow[flowName].lang);
    var configurations = {};
    var blocks = {};
    var links = {};
    var yModifiersTable = {};
    var yModifier = 200;
    var xModifier = 400;
    var numberBlockOfBlock = 0;

    //Blocks
    Object.keys(flow.nodes).forEach(
      function (nodeId, index) {
        var node = flow.nodes[nodeId];
        var description = JSON.parse(node.description);
        var blockDefinition = this.blockDefinition[this.blockTypeTranslation[description.type]];
        if (blockDefinition) {
          var b = {};
          b.id = parseInt(nodeId);
          b.uuid = parseInt(description.uuid);
          b.x = index && yModifiersTable[nodeId] ? yModifiersTable[nodeId].x : 400; //tentativo di ordinamento
          if (index && yModifiersTable[nodeId]) {
            b.y = yModifiersTable[nodeId].y;
          } else {
            b.y = numberBlockOfBlock * yModifier;
            numberBlockOfBlock += 2;
          }
          //b.y = index&&yModifiersTable[nodeId]?yModifiersTable[nodeId].y:(numberBlockOfBlock*yModifier);
          b.selected = false;
          b.name = blockDefinition.name;
          b.inputs = [{}];
          b.values = {
            property: {},
          };
          //Calcolo esoterico della posizione dei propri figli
          var exits = []; //Object.keys(node.intents);
          for (var intent in node.intents) {
            if (!node.intents[intent].inherited) {
              exits.push(intent);
            }
          }
          var multipleExitSpacer = 0;
          exits.forEach(
            function (exit, exitIndex) {
              if (!node.intents[exit].inherited) {
                var nextNodeId = node.intents[exit].returnNode ? node.intents[exit].returnNode : node.intents[exit].nextNode;
                var numberOfExits = this.getNumberOfExits(flow.nodes, nextNodeId);
                //Se il figlio e' un menu, conto le uscite e aggiungo una spaziatura proporzionale
                if (nextNodeId && numberOfExits > 1) {
                  if (exit == "default") {
                    yModifiersTable[nextNodeId] = {
                      x: b.x + xModifier,
                      y: b.y + yModifier * (exits.length - 1),
                    };
                  } else {
                    multipleExitSpacer = yModifier * (numberOfExits - 1);
                    yModifiersTable[nextNodeId] = {
                      x: b.x + xModifier,
                      y: b.y + yModifier * exitIndex,
                    };
                  }
                } else {
                  //Cosi metto il default per ultimo
                  if (exit == "default") {
                    yModifiersTable[nextNodeId] = {
                      x: b.x + xModifier,
                      y: b.y + yModifier * (exits.length - 1) + multipleExitSpacer,
                    };
                  } else {
                    yModifiersTable[nextNodeId] = {
                      x: b.x + xModifier,
                      y: b.y + yModifier * (exitIndex - 1) + multipleExitSpacer,
                    };
                  }
                }
              }
            }.bind(this),
          );
          //Mappa che tiene a mente tutti i link tra i nodi per poi andarli a mettere nell'oggetto della scena
          targets[b.id] = node.intents;
          var message = null;
          var caption = null;
          var mainQuestion = null;
          var disambiguationQuestion = null;
          var outputs = null;
          switch (description.type) {
            case "geolocation":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                address: {
                  value: node.action.address,
                },
                latitude: {
                  value: node.action.latitude,
                },
                longitude: {
                  value: node.action.longitude,
                },
                locationName: {
                  value: node.action.locationName,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "whatsapp_template":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                body: {
                  value: node.action.body,
                },
                headerSettings: {
                  value: node.action.headerSettings,
                },
                headerType: {
                  valueSelected: node.action.headerType,
                },
                language: {
                  value: node.action.language,
                },
                templateName: {
                  value: node.action.templateName,
                },
                conditionPhoneNumber: {
                  value: node.action.conditionPhoneNumber,
                },
                buttons: {
                  value: node.action.buttons,
                },
                responseVariable: {
                  value: node.action.responseVariable,
                },
                phoneNumber: {
                  value: {
                    multiple: node.action.phoneNumber.multiple,
                    dynamic: node.action.phoneNumber.dynamic,
                  },
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "message":
              message = this.getMultiLanguageObj(langInfo, node.action.message);
              var messageDescription = this.getMultiLanguageObj(langInfo, node.action.messageDescription);

              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                text: {
                  value: message,
                },
                textDescription: {
                  value: messageDescription,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "end":
              delete targets[b.id];
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "file":
              message = this.getMultiLanguageObj(langInfo, node.action.message);
              var invalidFileMessage = this.getMultiLanguageObj(langInfo, node.action.invalidFileMessage);

              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                message_file: {
                  value: message,
                },
                invalid_file: {
                  value: invalidFileMessage,
                },
                file_variable: {
                  value: node.action.fileName,
                },
                max_size: {
                  value: node.action.fileMaxSize,
                },
                extension_allow: {
                  value: node.action.filterExtension,
                  extensions: node.action.allowedExtension,
                },
                settings: {
                  value: {
                    overrideInputProcessor: {
                      checkbox: {
                        label: "Override input processor configuration",
                        value: node.input_processors ? node.input_processors.overrideInputProcessor : false,
                        type: "boolean",
                      },
                      input: {
                        label: "Which input processor should be used?",
                        type: "select_input_processor",
                        valueSelected: node.input_processors ? node.input_processors.input_processor_type : "",
                      },
                      type: "overrideInput",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "survey":
              disambiguationQuestion = [];
              var scoreQuestion = [];
              var feedbackQuestion = [];
              if (node.action.score) {
                disambiguationQuestion = this.getMultiLanguageObj(
                  langInfo,
                  flow.flow[flowName].entities[this.removeVariableChar(node.action.score)].disambiguation_question,
                );
                scoreQuestion = this.getMultiLanguageObj(langInfo, flow.flow[flowName].entities[this.removeVariableChar(node.action.score)].questions);
              }
              if (node.action.feedback) {
                feedbackQuestion = this.getMultiLanguageObj(langInfo, flow.flow[flowName].entities[this.removeVariableChar(node.action.feedback)].questions);
              }

              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                name: {
                  value: node.action.name,
                },
                score: {
                  value: scoreQuestion,
                },
                score_invalid: {
                  value: disambiguationQuestion,
                },
                feedback: {
                  value: feedbackQuestion,
                },
                keyboard: {
                  valueSelected: node.action.score
                    ? flow.flow[flowName].entities[this.removeVariableChar(node.action.score)].keyboard || "keyboard_default_6"
                    : "keyboard_default_6",
                },
                settings: {
                  value: {
                    resetAllVariables: {
                      label: "Reset all variables",
                      value: node.enableResetVariables,
                      type: "boolean",
                    },
                    overrideInputProcessor: {
                      checkbox: {
                        label: "Override input processor configuration",
                        value: node.input_processors ? node.input_processors.overrideInputProcessor : false,
                        type: "boolean",
                      },
                      input: {
                        label: "Which input processor should be used?",
                        type: "select_input_processor",
                        valueSelected: node.input_processors ? node.input_processors.input_processor_type : "",
                      },
                      type: "overrideInput",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "multimedia":
              var title = this.getMultiLanguageObj(langInfo, node.action.title);
              caption = this.getMultiLanguageObj(langInfo, node.action.caption);
              var button_title = this.getMultiLanguageObj(langInfo, node.action.button_title);
              var multimedia_url = this.getMultiLanguageObj(langInfo, node.action.media_url);
              var button_url = this.getMultiLanguageObj(langInfo, node.action.button_url);
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                media_settings: {
                  value: {
                    media_type: [
                      {
                        valueSelected: this.capitalize(node.action.media_type),
                      },
                    ],
                    dynamic: [
                      {
                        value: node.action.is_dynamic,
                      },
                    ],
                    multimedia_static: [{ value: title }, { value: caption }, { value: multimedia_url }, { value: button_title }, { value: button_url }],
                    link_static: [{ value: title }, { value: button_title }, { value: button_url }],
                    multimedia_dynamic: [
                      { value: node.action.input },
                      { value: node.action.title },
                      { value: node.action.caption },
                      { value: node.action.media_url },
                      { value: node.action.button_title },
                      { value: node.action.button_url },
                    ],
                    link_dynamic: [
                      { value: node.action.input },
                      { value: node.action.title },
                      { value: node.action.button_title },
                      { value: node.action.button_url },
                    ],
                  },
                },
                settings: {
                  value: {
                    reportTileInHistory: {
                      label: "Report title as chat history",
                      value: node.reportTitleAsHistory,
                      type: "boolean",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "authentication":
              caption = this.getMultiLanguageObj(langInfo, node.action.caption);
              b.outputs = this.clone(this.nodeOutput);
              b.outputs.push({
                name: "Failed",
                label: "Failed",
                active: false,
              });
              b.outputs.push({
                name: "Not Available",
                label: "Not Available",
                active: false,
              });
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                auth_service_type: {
                  value: node.action.auth_service_type,
                },
                endpoint: {
                  value: node.action.endpoint,
                },
                caption: {
                  value: caption,
                },
                enable_oauth: {
                  value: node.action.enable_oauth,
                },
                token_var: {
                  value: this.addVariableChar(node.action.token),
                },
                settings: {
                  value: {
                    waitForLogin: {
                      label: "How long should Convy wait for a successfull login? (in secs)",
                      value: node.authFailureTimeout,
                      type: "number",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "outbound":
              b.outputs = this.clone(this.nodeOutput);
              b.outputs.push({
                name: "Take",
                label: "Take",
                active: false,
              });
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "web service":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                endpoint: {
                  value: node.action.url,
                },
                type: {
                  valueSelected: node.action.type,
                },
                method: {
                  valueSelected: node.action.method,
                },
                security: {
                  valueSelected: node.action.soapSecurityType,
                  username: node.action.soapSecurityAttr ? node.action.soapSecurityAttr.username : "",
                  password: node.action.soapSecurityAttr ? node.action.soapSecurityAttr.password : "",
                  bearer: node.action.soapSecurityAttr ? node.action.soapSecurityAttr.token : "",
                },
                encoding: {
                  valueSelected: node.action.requestParamEncoding,
                },
                vars_send: {
                  value: this.getArrayOfPropFromMap(node.action.data),
                },
                headers: {
                  value: this.getArrayOfPropFromMap(node.action.headers),
                },
                attachments: {
                  value: this.getArrayOfPropFromMap(node.action.attachments),
                },
                result: {
                  value: this.getArrayOfPropFromMap(node.action.result),
                },
                settings: {
                  value: {
                    continueExecutionOnError: {
                      label: "Continue execution on error",
                      value: node.continueExecutionOnError,
                      type: "boolean",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "Flow Jump":
              if (!node.action.flow && !node.action.version && node.action.hook) {
                let splitted = node.action.hook.split("-");
                if (splitted && splitted.length > 1) {
                  node.action.flow = node.action.hook.split("-")[0];
                  node.action.version = node.action.hook.split("-")[1];
                  node.action.hook = "begin";
                }
              }
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                flow_jump_target: {
                  value: {
                    flow: {
                      value: node.action.flow,
                    },
                    version: {
                      value: node.action.version,
                    },
                    hook: {
                      value: node.action.hook,
                    },
                  },
                },
                settings: {
                  value: {
                    skipJumpReport: {
                      label: "Skip jump report",
                      value: node.skipJumpToFlowReport,
                      type: "boolean",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "variables":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                variables: {
                  value: this.formatValues(node.action.operations),
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "email":
              var subject = this.getMultiLanguageObj(langInfo, node.action.emailInfo.subject);
              var body = this.getMultiLanguageObj(langInfo, node.action.emailInfo.html);
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                from: {
                  valueSelected: node.action.emailInfo.emailBox ? node.action.emailInfo.emailBox : "System Email Box",
                },
                to: {
                  value: node.action.emailInfo.to,
                },
                cc: {
                  value: node.action.emailInfo.cc,
                },
                bcc: {
                  value: node.action.emailInfo.bcc,
                },
                reply: {
                  value: node.action.emailInfo.replyTo,
                },
                subject: {
                  value: subject,
                },
                body: {
                  value: body,
                },
                attachments: {
                  value: this.getArrayOfObjFromString(node.action.emailInfo.attachments),
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "delay":
              var typeOfDelay = "DELAY";
              if (node.action.typeOfDelay == "pause") typeOfDelay = "WAIT FOR EVENT";
              else if (node.action.typeOfDelay == "delay") typeOfDelay = "DELAY";
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                typeOfDelay: {
                  valueSelected: typeOfDelay,
                },
                delay: {
                  value: node.action.delay,
                },
                timeoutValue: {
                  value: node.action.timeoutValue,
                },
                customDelayEnabled: {
                  value: node.action.customDelayEnabled,
                  values: node.action.customDelays,
                },
                eventName: {
                  value: node.action.eventName,
                },
                eventParameters: {
                  value: this.getArrayOfPropFromMap(node.action.eventParameters),
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "sendevent":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                name: {
                  value: node.action.name,
                },
                eventParameters: {
                  value: this.getArrayOfPropFromMap(node.action.parameters),
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "question":
              mainQuestion = [];
              if (node.action.entities.length > 0 && node.action.entities[0].indexOf("menu_question_") != -1) {
                mainQuestion = this.getMultiLanguageObj(langInfo, flow.flow[flowName].entities[this.removeVariableChar(node.action.entities[0])].questions);
              }
              var entitiesValue = [];
              for (var x = 0; x < node.action.entities.length; x++) {
                //Skip della domanda principale
                if (node.action.entities[x].indexOf("menu_question_") != -1) continue;

                var entity = this.removeVariableChar(node.action.entities[x]);
                var questions = flow.flow[flowName].entities[entity].questions;
                if (flow.flow[flowName].entities[entity]["questions_" + b.uuid]) {
                  questions = flow.flow[flowName].entities[entity]["questions_" + b.uuid];
                }
                entitiesValue.push({
                  variable: {
                    value: node.action.entities[x],
                  },
                  type: {
                    valueSelected: flow.flow[flowName].entities[entity].type,
                  },
                  keyboard: {
                    valueSelected: flow.flow[flowName].entities[entity].keyboard || "keyboard_default_1",
                  },
                  detailQuestion: {
                    value: this.getMultiLanguageDetailQuestionObj(langInfo, questions),
                  },
                });
              }
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                question: {
                  value: mainQuestion,
                },
                engine: {
                  valueSelected: node.action.engine,
                },
                keyboard: {
                  valueSelected:
                    node.action.entities.length > 0
                      ? flow.flow[flowName].entities[this.removeVariableChar(node.action.entities[0])].keyboard || "keyboard_default_1"
                      : "keyboard_default_1",
                },
                intents: {
                  value: node.action.intents,
                },
                context: {
                  valueSelected: node.action.context,
                },
                entities: {
                  value: entitiesValue,
                },
                settings: {
                  value: {
                    resetAllVariables: {
                      label: "Reset all variables",
                      value: node.enableResetVariables,
                      type: "boolean",
                    },
                    useEntitiesPool: {
                      label: "Use entities pool",
                      value: node.enableEntitiesPool,
                      type: "boolean",
                    },
                    overrideCustomerInputTimeout: {
                      checkbox: {
                        label: "Override processing customer input timeout",
                        value: node.overrideAggregationTimeout,
                        type: "boolean",
                      },
                      input: {
                        label: "How long should Convy wait before analyzing input? (in secs)",
                        value: node.aggregationTimeout / 1000,
                        type: "number",
                      },
                      type: "overrideInput",
                    },
                    overrideInputProcessor: {
                      checkbox: {
                        label: "Override input processor configuration",
                        value: node.input_processors ? node.input_processors.overrideInputProcessor : false,
                        type: "boolean",
                      },
                      input: {
                        label: "Which input processor should be used?",
                        type: "select_input_processor",
                        valueSelected: node.input_processors ? node.input_processors.input_processor_type : "",
                      },
                      type: "overrideInput",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "crossroads":
            case "Date":
            case "multi crossroads":
              outputs = [];
              var type = "basic";
              if (node.action.type == "DATE") type = "date";
              else if (node.action.conditions) type = "advanced";

              b.outputs = this.clone(this.nodeOutput);
              //Per retrocompatibilità con i nodi date eseguo l'algoritmo solito
              if (node.action.type != "DATE" || (node.action.type == "DATE" && node.action.conditions)) {
                Object.keys(node.intents).forEach(
                  function (intent, index) {
                    if (intent != "default") {
                      var o = {
                        type: "event",
                        labelProp: "Value",
                        label: intent,
                        attr: "output",
                        active: false,
                      };
                      if (type == "advanced") {
                        o.labelCondition = "Condition";
                        o.condition = node.action.conditions[index - 1].condition;
                      } else if (type == "date") {
                        var days = [];
                        if (node.action.conditions[index]) {
                          node.action.conditions[index].condition.forEach(
                            function (cond) {
                              days.push(this.getDayOfWeek(cond.indexDayOfWeek));
                            }.bind(this),
                          );
                          o.labelCondition = "Select days";
                          o.condition = days;
                          o.labelTimeBegin = "Time frame start";
                          o.labelTimeEnd = "Time frame end";
                          o.timeBegin = node.action.conditions[index].condition[0].startTime;
                          o.timeEnd = node.action.conditions[index].condition[0].endTime;
                        }
                      }
                      b.outputs.push(o);
                      outputs.push(o);
                    }
                  }.bind(this),
                );
              } else {
                //I nuovi nodi date salvano esattamente il json necessario al FD per lavorare, quindi niente elaborazioni
                outputs = node.action.datesConfiguration.value;
              }
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                type: {
                  valueSelected: type,
                },
                condition: {
                  value: node.action.condition || "",
                },
                multioutput: {
                  value: outputs,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "handover":
              message = this.getMultiLanguageObj(langInfo, node.action.message);
              var bubbleName = this.getMultiLanguageObj(langInfo, node.action.bubbleName);
              var bubbleIntroduction = this.getMultiLanguageObj(langInfo, node.action.bubbleIntroduction);
              delete targets[b.id];
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                type: {
                  valueSelected: node.action.mode,
                },
                target: {
                  value: node.action.target,
                },
                message: {
                  value: message,
                },
                bubble_name: {
                  value: bubbleName,
                },
                bubble_introduction: {
                  value: bubbleIntroduction,
                },
                priority: {
                  value: {
                    isDynamic: {
                      value: node.priority ? node.priority.isDynamic : false,
                    },
                    level: {
                      value: node.priority ? node.priority.priorityLevel : 5,
                    },
                  },
                },
                care_window: node.action.enableCareWindowManagement,
                post_handover: {
                  value: {
                    enabled: {
                      value: node.action.enablePostHandover ? node.action.enablePostHandover : false,
                    },
                    flow: {
                      value: node.action.jump_to ? node.action.jump_to.flow : "",
                    },
                    version: {
                      value: node.action.jump_to ? node.action.jump_to.version : "",
                    },
                    hook: {
                      value: node.action.jump_to ? node.action.jump_to.hook : "",
                    },
                  },
                },
                handover_error: {
                  value: {
                    enabled: {
                      value: node.action.enableHandoverError ? node.action.enableHandoverError : false,
                    },
                    flow: {
                      value: node.action.handover_error_jump_to ? node.action.handover_error_jump_to.flow : "",
                    },
                    version: {
                      value: node.action.handover_error_jump_to ? node.action.handover_error_jump_to.version : "",
                    },
                    hook: {
                      value: node.action.handover_error_jump_to ? node.action.handover_error_jump_to.hook : "",
                    },
                  },
                },
                handover_inactivity: {
                  value: {
                    enabled: {
                      value: node.action.enableHandoverInactivity ? node.action.enableHandoverInactivity : false,
                    },
                    timeout: {
                      value: node.action.handoverInactivityTimeout ? node.action.handoverInactivityTimeout : 10,
                    },
                    flow: {
                      value: node.action.handover_inactivity_jump_to ? node.action.handover_inactivity_jump_to.flow : "",
                    },
                    version: {
                      value: node.action.handover_inactivity_jump_to ? node.action.handover_inactivity_jump_to.version : "",
                    },
                    hook: {
                      value: node.action.handover_inactivity_jump_to ? node.action.handover_inactivity_jump_to.hook : "",
                    },
                  },
                },
                humanAgentsHandoverDetails: {
                  value: {
                    queue: {
                      value: node.action.humanAgentsHandoverDetails.queue,
                    },
                    preferredAgentType: {
                      value: node.action.humanAgentsHandoverDetails.preferredAgentType,
                    },
                    preferredAgentValue: {
                      value: node.action.humanAgentsHandoverDetails.preferredAgentValue,
                    },
                  },
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "Hook Jump":
              delete targets[b.id];
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                target: {
                  valueSelected: node.action.hook,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "knowledgebase":
              b.outputs = this.clone(this.nodeOutput);
              b.outputs.push({
                name: "Failed",
                label: "Failed",
                active: false,
              });
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                question: {
                  value: node.action.question,
                },
                engine: {
                  valueSelected: node.action.engine,
                },
                redisContext: {
                  valueSelected: node.action.useContext,
                },
                toneOfVoice: {
                  valueSelected: node.action.toneOfVoice,
                },
                customPrompt: {
                  value: node.action.customPrompt,
                },
                customContext: {
                  value: node.action.customContext,
                },
                intents: {
                  valueSelected: node.action.intents,
                },
                entities: {
                  valueSelected: node.action.entities,
                },
                contextMemory: {
                  valueSelected: node.action.contextMemory || false,
                },
                fileSearch: {
                  valueSelected: node.action.fileSearch || false,
                },
                enableExitButtons: {
                  valueSelected: node.action.enableExitButtons,
                },
                multioutput: {
                  value: node.action.multioutput,
                },
                inputVariableToAnalyze: {
                  valueSelected: node.action.inputVariableToAnalyze || "",
                },
                inputVariableToSave: {
                  valueSelected: node.action.inputVariableToSave || false,
                },
                settings: {
                  value: {
                    useSemanticHooks: {
                      label: "Use semantic hooks",
                      value: node.useSemanticHooks,
                      type: "boolean",
                    },
                    sendToUser: {
                      label: "Send answer to user",
                      value: node.action.useMessageResponse,
                      type: "boolean",
                    },
                    exitFromLoop: {
                      checkbox: {
                        label: "Enable auto exit from openai loop",
                        value: node.action.exitFromLoop.enabled,
                        type: "boolean",
                      },
                      input: {
                        label: "How many loop before exit in default?",
                        value: node.action.exitFromLoop.value,
                        placeholder: "50",
                        type: "number",
                      },
                      type: "overrideInput",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "menu":
              mainQuestion = [];
              if (node.action.entities.length > 0) {
                mainQuestion = this.getMultiLanguageObj(
                  langInfo,
                  flow.flow[flowName].entities[this.removeVariableChar(node.action.entities[0])].questions[0].text,
                );
              }
              var questionDescription = this.getMultiLanguageObj(langInfo, node.action.questionDescription);
              disambiguationQuestion = [];
              if (node.action.disambiguationQuestion) {
                disambiguationQuestion = this.getMultiLanguageObj(
                  langInfo,
                  flow.flow[flowName].entities[this.removeVariableChar(node.action.disambiguationQuestion)].questions,
                );
              }
              var questionNotRecognized = [];
              if (node.action.noBranchRecognizedQuestion) {
                questionNotRecognized = this.getMultiLanguageObj(
                  langInfo,
                  flow.flow[flowName].entities[this.removeVariableChar(node.action.noBranchRecognizedQuestion)].questions[0].text,
                );
              }
              outputs = [];
              b.outputs = this.clone(this.nodeOutput);
              var engineType = this.getEngineType(node.action.engine);
              Object.keys(node.intents).forEach(
                function (intent) {
                  var intentObj = node.intents[intent];
                  if (intent != "default" && !intentObj.inherited) {
                    var customLabel = intentObj.label;
                    let intentLabel = this.getIntentLabelFromEngine(node.action.engine, intent);
                    var question = flow.flow[flowName].entities[this.removeVariableChar(node.action.entities[0])].questions[0];
                    var options = this.getOptionOfIntent(question, customLabel || intent, node.action.engine);
                    if (engineType == "EUDATA") {
                      var tagStr = this.getTagsOfIntent(node.action.conditions, intent);
                      var tags = [];
                      try {
                        tags = JSON.parse(tagStr);
                      } catch (e) {
                        tags = [];
                      }
                      let outputLabel = this.getMultiLanguageObj(langInfo, intent);
                      intentLabel = "";
                      if (this.engines[node.action.engine].engines[node.action.engine].intents[intent]) {
                        intentLabel = intent;
                      }
                      outputs.push({
                        label: {
                          labelProp: "Label",
                          type: "event",
                          label: outputLabel,
                          attr: "output",
                          active: false,
                        },
                        intentOptional: {
                          label: "Intent",
                          type: "select_intent_optional",
                          valueSelected: intentLabel,
                        },
                        synonyms: {
                          label: "Synonyms",
                          type: "multi_string",
                          value: tags,
                        },
                        image_url: {
                          label: "Image URL",
                          type: "string",
                          value: options.image_url,
                        },
                        icon_url: {
                          label: "Icon URL",
                          type: "string",
                          value: options.icon_url,
                        },
                        description: {
                          label: "Description",
                          type: "multilanguage_string",
                          value: this.getMultiLanguageObj(langInfo, options.caption),
                        },
                      });
                      b.outputs.push({
                        labelProp: "Label",
                        type: "event",
                        label: outputLabel,
                        attr: "output",
                      });
                    } else {
                      let outputLabel = this.getMultiLanguageObj(langInfo, options.text);
                      if (outputLabel.length == 0) {
                        //Metto una label custom agli intenti che sono senza
                        outputLabel.push({
                          code: langInfo.code,
                          flag: langInfo.flag,
                          label: langInfo.label,
                          value: intentLabel,
                        });
                      }
                      outputs.push({
                        label: {
                          labelProp: "Label",
                          type: "event",
                          label: outputLabel,
                          attr: "output",
                          active: false,
                        },
                        intent: {
                          label: "Intent",
                          type: "select_intent",
                          valueSelected: intentLabel,
                        },
                        image_url: {
                          label: "Image URL",
                          type: "string",
                          value: options.image_url,
                        },
                        icon_url: {
                          label: "Icon URL",
                          type: "string",
                          value: options.icon_url,
                        },
                        description: {
                          label: "Description",
                          type: "multilanguage_string",
                          value: this.getMultiLanguageObj(langInfo, options.caption),
                        },
                      });
                      b.outputs.push({
                        labelProp: "Label",
                        type: "event",
                        label: outputLabel,
                        attr: "output",
                      });
                    }
                  }
                }.bind(this),
              );
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                question: {
                  value: mainQuestion,
                },
                description: {
                  value: questionDescription,
                },
                engine: {
                  valueSelected: node.action.engine,
                },
                question_not_recognized: {
                  value: questionNotRecognized,
                },
                keyboard: {
                  valueSelected: node.action.entities
                    ? flow.flow[flowName].entities[this.removeVariableChar(node.action.entities[0])].keyboard || "keyboard_default_1"
                    : "keyboard_default_1",
                },
                disambiguationQuestion: {
                  value: disambiguationQuestion,
                },
                context: {
                  valueSelected: node.action.context,
                },
                dynamic: {
                  value: {
                    checkbox: { value: node.action.isDynamic },
                    inputs: [
                      { value: node.action.entities[0] },
                      { value: node.action.dynamicMenuVariable },
                      { value: node.action.dynamicTextField },
                      { value: node.action.dynamicImageUrlField },
                      { value: node.action.dynamicIconUrlField },
                      { value: node.action.dynamicDescriptionField },
                    ],
                  },
                },
                entities: {
                  value: node.action.menuOptionalEntities,
                },
                multioutput: {
                  value: outputs,
                },
                settings: {
                  value: {
                    useSemanticHooks: {
                      label: "Use semantic hooks",
                      value: node.useSemanticHooks,
                      type: "boolean",
                    },
                    overrideCustomerInputTimeout: {
                      checkbox: {
                        label: "Override processing customer input timeout",
                        value: node.overrideAggregationTimeout,
                        type: "boolean",
                      },
                      input: {
                        label: "How long should Convy wait before analyzing input? (in secs)",
                        value: node.aggregationTimeout / 1000,
                        type: "number",
                      },
                      type: "overrideInput",
                    },
                    overrideInputProcessor: {
                      checkbox: {
                        label: "Override input processor configuration",
                        value: node.input_processors ? node.input_processors.overrideInputProcessor : false,
                        type: "boolean",
                      },
                      input: {
                        label: "Which input processor should be used?",
                        type: "select_input_processor",
                        valueSelected: node.input_processors ? node.input_processors.input_processor_type : "",
                      },
                      type: "overrideInput",
                    },
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "merge_contact":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                conditionType: {
                  value: node.action.conditionType,
                },
                filters: {
                  value: node.action.filters,
                },
                var_update: {
                  value: node.action.var_update,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "search_contact":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                typeValue: {
                  valueSelected: node.action.typeValue,
                },
                number: {
                  numberSelected: node.action.number,
                },
                advancedOptions: {
                  value: node.action.advancedOptions,
                },
                conditionType: {
                  value: node.action.conditionType,
                },
                userConditionType: {
                  value: node.action.userConditionType ? node.action.userConditionType : 0,
                },
                userId: {
                  value: node.action.userId ? node.action.userId : "",
                },
                botSelected: {
                  value: node.action.botSelected,
                },
                channelSelected: {
                  value: node.action.channelSelected,
                },
                dateSelected: {
                  value: node.action.dateSelected,
                },
                vars_result: {
                  value: node.action.vars_result,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "search_customer_info":
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                conditionType: {
                  value: node.action.conditionType,
                },
                filters: {
                  value: node.action.filters,
                },
                vars_result: {
                  value: node.action.vars_result,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
            case "form":
              b.outputs = this.clone(this.nodeOutput);
              b.outputs.push({
                name: "Skip",
                label: "Skip",
                active: false,
              });
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                title: {
                  value: node.action.title.value,
                },
                formField: {
                  value: [],
                },
                formRequired: {
                  value: node.action.formRequired,
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              node.action.formField &&
                node.action.formField.forEach((field) => {
                  var label = this.getMultiLanguageObj(langInfo, field.label);
                  var hint = this.getMultiLanguageObj(langInfo, field.hint);
                  field.label = label;
                  field.hint = hint;
                  b.values.property.formField.value.push(field);
                });
              blocks[b.id] = b;
              break;
            case "buttons":
              var question = this.getMultiLanguageObj(langInfo, node.questions);
              b.outputs = this.clone(this.nodeOutput);
              b.values.property = {
                hook: {
                  value: description.storyBoardHook,
                },
                question: {
                  value: question,
                },
                dynamic: {
                  value: { checkbox: { value: node.action.isDynamic } },
                },
                multipleSelection: {
                  value: {
                    checkbox: { value: node.action.isMultipleSelection },
                  },
                },
                buttons_choice: {
                  value: node.buttonsChoiceVariable,
                },
                searchableItems: {
                  value: {
                    checkbox: { value: node.action.isSearchEnabled },
                    number_of_visible_buttons: node.action.number_of_visible_buttons,
                  },
                },
                dynamic_variable: {
                  value: {
                    variable: node.dynamic_variable ? node.dynamic_variable.variable : "",
                    buttonLabel: node.dynamic_variable ? node.dynamic_variable.buttonLabel : "",
                    image_url: node.dynamic_variable ? node.dynamic_variable.image_url : "",
                  },
                },
                buttons_output: {
                  value: node.static_buttons ? node.static_buttons : [],
                },
                settings: {
                  value: {
                    starter: {
                      label: "Starter node",
                      value: parseInt(nodeId) == flow.flow[flowName].rootNode,
                      type: "boolean",
                    },
                    disabled: {
                      label: "Disable node",
                      value: node.executionDisabled,
                      type: "boolean",
                    },
                    enableAsBackTarget: {
                      label: "Enable as BACK button target",
                      value: node.enableAsBackTarget,
                      type: "boolean",
                    },
                    log: {
                      label: "Log",
                      value: node.log,
                      type: "textarea",
                    },
                    description: {
                      label: "Description",
                      value: node.note,
                      type: "textarea",
                    },
                  },
                },
              };
              blocks[b.id] = b;
              break;
          }
        }
      }.bind(this),
    );

    //Links
    var uniqueLinkId = 1;
    for (var id in targets) {
      var intents = targets[id];
      var originSlotId = 0;
      let intentKeys = Object.keys(intents);
      intentKeys.sort(function (a, b) {
        if (a === "default") return -1;
        if (b === "default") return 1;
        //il confronto va fatto solo con il default, gli altri devono rimanere nello stesso ordine e quindi di default ritorno 0
        return 0;
      });
      for (var i = 0; i < intentKeys.length; i++) {
        var intentLabel = intentKeys[i];
        var intent = intents[intentLabel];
        var next = intent.returnNode ? intent.returnNode : intent.nextNode;
        if (next && !intent.inherited && !isNaN(next)) {
          if (originSlotId == 0 && intentLabel != "default") originSlotId++;
          links[uniqueLinkId] = {
            id: uniqueLinkId,
            originID: parseInt(id),
            originSlot: intentLabel == "default" ? 0 : originSlotId,
            targetID: next,
            targetSlot: 0,
          };
          uniqueLinkId++;
        }
        originSlotId++;
      }
    }
    //Configurations
    configurations["general"] = {
      flow_enabled: flow.flow[flowName].enabled,
      completedMessage: this.migrationToMultilanguage(flow.flow[flowName].bye_message, flow.flow[flowName].lang),
      max_mute_timeout: flow.flow[flowName].max_mute_timeout / 1000,
      aggregation_timeout: flow.flow[flowName].aggregation_timeout / 1000,
      mute_timeout: flow.flow[flowName].mute_timeout / 1000,
      timezone: flow.flow[flowName].olson_name,
      not_acceptable_incoming_message: this.migrationToMultilanguage(flow.flow[flowName].not_acceptable_incoming_message, flow.flow[flowName].lang),
      max_input_chars: flow.flow[flowName].max_input_chars,
      max_input_chars_message: this.migrationToMultilanguage(flow.flow[flowName].max_input_chars_message, flow.flow[flowName].lang),
      license_cap_reached_message: this.migrationToMultilanguage(flow.flow[flowName].license_cap_reached_message, flow.flow[flowName].lang),
    };
    var errorNodeId = flow.flow[flowName].errorNode;
    var message = null;
    var error_complex_node_enabled = false;
    var advancedErrorType = {};
    if (errorNodeId) {
      var errorNode = flow.nodes[errorNodeId];
      if (errorNode.action.type == "MSGOUT") {
        message = errorNode.action.message;
        if (errorNode.intents.default.nextNode) {
          error_complex_node_enabled = true;
          advancedErrorType = this.getAdvancedErrorType(flow.nodes[errorNode.intents.default.nextNode], flow, flowName);
        }
      } else {
        error_complex_node_enabled = true;
        advancedErrorType = this.getAdvancedErrorType(errorNode, flow, flowName);
      }
    }
    configurations["error"] = {
      number_of_failures: flow.flow[flowName].number_of_failures,
      error_message: this.migrationToMultilanguage(message, flow.flow[flowName].lang),
      error_complex_node_enabled: error_complex_node_enabled,
      advancedErrorMode: advancedErrorType.mode,
      advancedErrorModeHandoverType: advancedErrorType.advancedErrorModeHandoverType,
      advancedErrorModeHandoverTarget: advancedErrorType.advancedErrorModeHandoverTarget,
      advancedErrorModeHandoverMessage: advancedErrorType.advancedErrorModeHandoverMessage,
      advancedErrorModeHandoverTargetBubbleName: advancedErrorType.advancedErrorModeHandoverTargetBubbleName,
      advancedErrorModeHandoverTargetBubbleIntroduction: advancedErrorType.advancedErrorModeHandoverTargetBubbleIntroduction,
      advancedErrorModeSurveyName: advancedErrorType.advancedErrorModeSurveyName,
      advancedErrorModeSurveyScore: advancedErrorType.advancedErrorModeSurveyScore,
      advancedErrorModeSurveyFeedback: advancedErrorType.advancedErrorModeSurveyFeedback,
      jump_to: advancedErrorType.jump_to,
    };

    var inactivityNodeId = flow.flow[flowName].inactivityNode;
    message = null;
    var inactivity_complex_node_enabled = false;
    var advancedInactivityType = {};
    var proactivityType = flow.flow[flowName].dialog_proactivity && flow.flow[flowName].dialog_proactivity.jump_to_flow ? "jump" : "idle";
    var jumpTo = {};
    var dialog_proactivity = 1;
    var dialog_proactivity_repetition = 1;
    var start_exclusion_time = "00:00";
    var end_exclusion_time = "23:59";
    if (inactivityNodeId) {
      var inactivityNode = flow.nodes[inactivityNodeId];
      if (inactivityNode.action.type == "MSGOUT") {
        message = inactivityNode.action.message;
        if (inactivityNode.intents.default.nextNode) {
          inactivity_complex_node_enabled = true;
          var advancedInactivityNode = flow.nodes[inactivityNode.intents.default.nextNode];
          advancedInactivityType = {
            inactivitySurveyName: advancedInactivityNode.action.name,
            inactivitySurveyScore: this.migrationSurvey(
              flow.flow[flowName].entities[this.removeVariableChar(advancedInactivityNode.action.score)].questions,
              flow.flow[flowName].lang,
            ),
            inactivitySurveyFeedback: this.migrationSurvey(
              flow.flow[flowName].entities[this.removeVariableChar(advancedInactivityNode.action.feedback)].questions,
              flow.flow[flowName].lang,
            ),
          };
        } else {
          advancedInactivityType = {
            inactivitySurveyName: null,
            inactivitySurveyScore: [],
            inactivitySurveyFeedback: [],
          };
        }
      } else {
        inactivity_complex_node_enabled = true;
        advancedInactivityType = {
          inactivitySurveyName: inactivityNode.action.name,
          inactivitySurveyScore: this.migrationSurvey(
            flow.flow[flowName].entities[this.removeVariableChar(inactivityNode.action.score)].questions,
            flow.flow[flowName].lang,
          ),
          inactivitySurveyFeedback: this.migrationSurvey(
            flow.flow[flowName].entities[this.removeVariableChar(inactivityNode.action.feedback)].questions,
            flow.flow[flowName].lang,
          ),
        };
      }
    }
    if (proactivityType == "jump") {
      jumpTo = {
        flow: flow.flow[flowName].dialog_proactivity.jump_to_flow,
        version: flow.flow[flowName].dialog_proactivity.jump_to_version,
        hook: flow.flow[flowName].dialog_proactivity.jump_to_hook || "begin",
      };
      dialog_proactivity = flow.flow[flowName].dialog_proactivity.timeout / 1000;
      dialog_proactivity_repetition = flow.flow[flowName].dialog_proactivity.repetition;
      start_exclusion_time = flow.flow[flowName].dialog_proactivity.start_exclusion_date;
      end_exclusion_time = flow.flow[flowName].dialog_proactivity.end_exclusion_date;
    }

    //Se espresso in minuti lo trasformo
    let dialogProac = dialog_proactivity;
    if (dialog_proactivity >= 60) {
      dialogProac = dialogProac / 60;
    }
    configurations["inactivity"] = {
      proactivityType: proactivityType,
      jump_to: jumpTo,
      dialog_proactivity: dialogProac,
      dialog_proactivity_repetition: dialog_proactivity_repetition,
      start_exclusion_time: start_exclusion_time,
      end_exclusion_time: end_exclusion_time,
      dialog_timeout: flow.flow[flowName].dialog_timeout / 1000,
      inactivityMessage: this.migrationToMultilanguage(message, flow.flow[flowName].lang),
      inactivity_survey_enabled: inactivity_complex_node_enabled,
      inactivitySurveyName: advancedInactivityType.inactivitySurveyName,
      inactivitySurveyScore: advancedInactivityType.inactivitySurveyScore,
      inactivitySurveyFeedback: advancedInactivityType.inactivitySurveyFeedback,
    };
    //gestione care window
    configurations["inactivity"].care_window = inactivityNode.care_window;

    var semanticHookList = [];
    var semHookMap = flow.semantic_hooks[flow.flow[flowName].entity_engine];
    for (intent in semHookMap) {
      if (semHookMap[intent]) {
        semanticHookList.push({
          intent: intent,
          target: semHookMap[intent].flow,
          version: semHookMap[intent].version,
          hook: semHookMap[intent].hook,
        });
      }
    }
    configurations["semantic_hooks"] = {
      analyze_start_flow: flow.flow[flowName].analyze_start_flow,
      start_flow_answer: this.migrationToMultilanguage(flow.flow[flowName].start_flow_answer, flow.flow[flowName].lang),
      semanticHookList: semanticHookList,
      semanticHookEngineName: flow.flow[flowName].entity_engine,
    };

    configurations["input_processors"] = flow.flow[flowName].input_processors;
    // if (configurations["input_processors"].input_processor_type) {
    //   configurations["input_processors"].input_processor_type =
    //     configurations["input_processors"].input_processor_type; //.split('-')[1];
    // }
    configurations["menu_node_configurations"] =
      flow.scene && flow.scene.configurations && flow.scene.configurations.menu_node_configurations
        ? flow.scene.configurations.menu_node_configurations
        : defaultConfiguration.menu_node_configurations;
    configurations["global_variables"] = defaultConfiguration.global_variables;
    configurations["conditional_jumps"] =
      flow.scene && flow.scene.configurations && flow.scene.configurations.conditional_jumps ? flow.scene.configurations.conditional_jumps : {};
    return {
      blocks: blocks,
      links: links,
      container: {
        centerX: 0,
        centerY: 0,
        scale: 0.7,
      },
      configurations: configurations,
      attr: {
        flowName: flowName,
        flowSelectedLang: this.getLanguageInfo(flow.flow[flowName].lang).code,
        flowsVariables: flowsVariables,
        ivr: bot ? bot.isVisualIvr : false,
        outbound: bot?.isOutbound && bot?.flow === this.params.flow && bot?.version === this.params.version ? true : false,
        lastUpdate: flow.flow[flowName].last_update_date,
        readOnlyMode: readOnlyMode,
      },
    };
  };
  this.getFlow = function (scene, botId, flow, version, bot) {
    this.scene = scene;
    var now = this.formatDate();
    var flowName = this.params.flow;
    var maxNodeId = 0;
    var newFlow = {
      name: this.params.flow,
      version: version,
      bot_id: botId,
      flow: {},
      nodes: {},
      engines: [],
      updated_at: now,
      semantic_hooks: {},
    };
    this.addToFlowEngines(newFlow, scene.configurations.semantic_hooks.semanticHookEngineName);
    if (scene.configurations.input_processors.enable_input_processor) {
      if (
        scene.configurations.input_processors.input_processor_type &&
        scene.configurations.input_processors.input_processor_type.toLowerCase() != "disabled"
      ) {
        var genericIP = this.getInputProcessor(scene.configurations.input_processors.input_processor_type);
        scene.configurations.input_processors.input_processor_type = genericIP.type + "-" + genericIP.name;
      } else {
        scene.configurations.input_processors.enable_input_processor = false;
        scene.configurations.input_processors.input_processor_type = null;
      }
    }
    newFlow.flow[flowName] = {
      bot_id: botId,
      name: flowName,
      description: flowName,
      generated_by: "flowdesigner",
      creation_date: flow && flow.flow && flow.flow[flowName] ? flow.flow[flowName].creation_date : this.formatDate(),
      last_update_date: now,
      lang: scene.attr.language,
      number_of_failures: Number(scene.configurations.error.number_of_failures),
      entity_engine: scene.configurations.semantic_hooks.semanticHookEngineName,
      rootNode: null,
      errorNode: null,
      entities: {},
      analyze_start_flow: scene.configurations.semantic_hooks.analyze_start_flow,
      start_flow_answer: scene.configurations.semantic_hooks.start_flow_answer,
      not_acceptable_incoming_message: scene.configurations.general.not_acceptable_incoming_message,
      dialog_timeout: scene.configurations.inactivity.dialog_timeout * 1000,
      mute_timeout: scene.configurations.general.mute_timeout * 1000,
      max_mute_timeout: scene.configurations.general.max_mute_timeout * 1000,
      aggregation_timeout: scene.configurations.general.aggregation_timeout * 1000,
      enabled: scene.configurations.general.flow_enabled,
      olson_name: scene.configurations.general.timezone,
      bye_message: scene.configurations.general.completedMessage,
      dialog_proactivity: null,
      max_input_chars: scene.configurations.general.max_input_chars,
      max_input_chars_message: scene.configurations.general.max_input_chars_message,
      license_cap_reached_message: scene.configurations.general.license_cap_reached_message,
      input_processors: scene.configurations.input_processors,
      inactivityNode: null,
      end_flow: null,
      active: true,
    };

    //Proattività
    if (scene.configurations.inactivity.proactivityType == "jump") {
      newFlow.flow[flowName].dialog_proactivity = {
        timeout: scene.configurations.inactivity.dialog_proactivity * 60 * 1000,
        jump_to_flow: scene.configurations.inactivity.jump_to.flow,
        jump_to_version: scene.configurations.inactivity.jump_to.version,
        jump_to_hook: scene.configurations.inactivity.jump_to.hook,
        start_exclusion_date: scene.configurations.inactivity.start_exclusion_time,
        end_exclusion_date: scene.configurations.inactivity.end_exclusion_time,
        type: "every",
        repetition: scene.configurations.inactivity.dialog_proactivity_repetition,
      };
    }
    //Ciclo per ogni blocco e vedo di creare tutti i nodi
    for (var blockId in scene.blocks) {
      var block = this.scene.blocks[blockId];
      var nodeId = block.id + "";
      maxNodeId = nodeId;
      if (block.values.property.settings.value.starter.value) {
        newFlow.flow[flowName].rootNode = parseInt(nodeId);
      }
      var node = {
        log: block.values.property.settings.value.log ? block.values.property.settings.value.log.value : null,
        note: block.values.property.settings.value.description ? block.values.property.settings.value.description.value : null,
        executionDisabled: block.values.property.settings.value.disabled ? block.values.property.settings.value.disabled.value : null,
        enableAsBackTarget: block.values.property.settings.value.enableAsBackTarget ? block.values.property.settings.value.enableAsBackTarget.value : null,
        description: {
          uuid: block.uuid,
          name: this.capitalize(this.blockTypeTranslation[block.name]),
          type: this.blockTypeTranslation[block.name],
          storyBoardHook: block.values.property.hook ? block.values.property.hook.value : null,
          storyBoardName: flowName,
        },
      };
      let score = null;
      let feedback = null;
      switch (block.name) {
        case "buttons":
          node.action = {
            type: "BUTTONS",
            isMultipleSelection: block.values.property.multipleSelection.value.checkbox.value,
            isDynamic: block.values.property.dynamic.value.checkbox.value,
            isSearchEnabled: block.values.property.searchableItems.value.checkbox.value,
            number_of_visible_buttons: block.values.property.searchableItems.value.number_of_visible_buttons,
          };
          node.questions = block.values.property.question.value;
          node.buttonsChoiceVariable = block.values.property.buttons_choice.value;
          // Se è dinamico
          if (block.values.property.dynamic.value.checkbox.value) {
            node.dynamic_variable = block.values.property.dynamic_variable.value ? block.values.property.dynamic_variable.value : null;
          } // Se è statico
          else {
            node.static_buttons = block.values.property.buttons_output.value ? block.values.property.buttons_output.value : null;
          }
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "form":
          node.action = {
            type: "FORM",
            title: block.values.property.title,
            formRequired: block.values.property.formRequired,
            formField: [],
          };
          node.intents = {
            default: {
              nextNode: null,
            },
            Skip: {
              nextNode: null,
            },
          };

          if (block.values.property.formField.value) {
            block.values.property.formField.value.forEach((form) => {
              node.action.formField.push(form);
            });
          }
          break;
        case "geolocation":
          node.action = {
            type: "GEOLOCATION",
            address: block.values.property.address.value,
            latitude: block.values.property.latitude.value,
            longitude: block.values.property.longitude.value,
            locationName: block.values.property.locationName.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "whatsapp_template":
          node.action = {
            type: "WHATSAPP_TEMPLATE",
            body: block.values.property.body.value,
            headerSettings: block.values.property.headerSettings.value,
            headerType: block.values.property.headerType.valueSelected,
            language: block.values.property.language.value,
            templateName: block.values.property.templateName.value,
            conditionPhoneNumber: block.values.property.conditionPhoneNumber.value,
            phoneNumber: block.values.property.phoneNumber.value,
            buttons: block.values.property.buttons.value,
            responseVariable: block.values.property.responseVariable.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "text":
        case "message":
          node.action = {
            type: "MSGOUT",
            message: block.values.property.text.value,
            messageDescription: block.values.property.textDescription ? block.values.property.textDescription.value : [],
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "crossroads":
          var type = block.values.property.type.valueSelected;
          if (type == "advanced") {
            node.description.name = "Multi Crossroads";
            node.description.type = "multi crossroads";
            node.action = {
              type: "SWITCH",
              conditions: [],
            };
            node.intents = {
              default: {
                nextNode: null,
              },
            };
            block.values.property.multioutput.value.forEach(function (crossOut) {
              node.intents[crossOut.label] = {
                nextNode: null,
              };
              node.action.conditions.push({
                condition: crossOut.condition,
                conditionType: "javascript",
                conditionValue: crossOut.condition,
                intent: crossOut.label,
              });
            });
          } else if (type == "basic") {
            node.action = {
              type: "SWITCH",
              condition: block.values.property.condition.value,
            };
            node.intents = {
              default: {
                nextNode: null,
              },
            };
            block.values.property.multioutput.value.forEach(function (crossOut) {
              node.intents[crossOut.label] = {
                nextNode: null,
              };
            });
          } else if (type == "date") {
            node.description.name = "Date Crossroads";
            node.description.type = "Date";
            node.action = {
              type: "DATE",
              datesConfiguration: block.values.property.multioutput,
            };
            node.intents = {
              default: {
                nextNode: null,
              },
            };
            //Con i nuovi crossroad genero solo le uscite perchè memorizzo l'oggetto esattamente uguale a come viene spedito dal FD
            block.values.property.multioutput.value.forEach((crossOut) => {
              node.intents[crossOut.label] = {
                nextNode: null,
              };
            });
          }
          break;
        case "sendevents":
          node.description.name = "Send Event";
          node.action = {
            type: "SEND_EVENT",
            name: block.values.property.name.value,
            parameters: this.getKeyValueMap(block.values.property.eventParameters.value),
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "multimedia":
          node.reportTitleAsHistory = block.values.property.settings.value.reportTileInHistory.value;
          var mediaType = block.values.property.media_settings.value.media_type[0].valueSelected;
          var isDynamic = block.values.property.media_settings.value.dynamic[0].value;
          node.action = {
            type: "MEDIA",
            is_dynamic: isDynamic,
            media_type: mediaType.toLowerCase(),
          };
          if (mediaType.toLowerCase() == "link") {
            if (isDynamic) {
              node.action.input = block.values.property.media_settings.value.link_dynamic[0].value;
              node.action.title = block.values.property.media_settings.value.link_dynamic[1].value;
              node.action.button_title = block.values.property.media_settings.value.link_dynamic[2].value;
              node.action.button_url = block.values.property.media_settings.value.link_dynamic[3].value;
            } else {
              node.action.title = block.values.property.media_settings.value.link_static[0].value;
              node.action.button_title = block.values.property.media_settings.value.link_static[1].value;
              node.action.button_url = block.values.property.media_settings.value.link_static[2].value;
            }
          } else {
            if (isDynamic) {
              node.action.input = block.values.property.media_settings.value.multimedia_dynamic[0].value;
              node.action.title = block.values.property.media_settings.value.multimedia_dynamic[1].value;
              node.action.caption = block.values.property.media_settings.value.multimedia_dynamic[2].value;
              node.action.media_url = block.values.property.media_settings.value.multimedia_dynamic[3].value;
              node.action.button_title = block.values.property.media_settings.value.multimedia_dynamic[4].value;
              node.action.button_url = block.values.property.media_settings.value.multimedia_dynamic[5].value;
            } else {
              node.action.title = block.values.property.media_settings.value.multimedia_static[0].value;
              node.action.caption = block.values.property.media_settings.value.multimedia_static[1].value;
              node.action.media_url = block.values.property.media_settings.value.multimedia_static[2].value;
              node.action.button_title = block.values.property.media_settings.value.multimedia_static[3].value;
              node.action.button_url = block.values.property.media_settings.value.multimedia_static[4].value;
            }
          }
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "auth":
          node.authFailureTimeout = block.values.property.settings.value.waitForLogin.value;
          node.action = {
            type: "AUTHENTICATION",
            caption: block.values.property.caption.value,
            auth_service_type: block.values.property.auth_service_type.value,
            endpoint: block.values.property.endpoint.value,
            enable_oauth: block.values.property.enable_oauth.value,
            token: this.removeVariableChar(block.values.property.token_var.value),
          };
          node.intents = {
            default: {
              nextNode: null,
            },
            Failed: {
              nextNode: null,
            },
            "Not Available": {
              nextNode: null,
            },
          };
          break;
        case "outbound":
          node.description.name = "Outbound";
          node.action = {
            type: "OUTBOUND",
          };
          node.intents = {
            default: {
              nextNode: null,
            },
            Take: {
              nextNode: null,
            },
          };
          break;
        case "webserv":
          node.description.name = "Web Service";
          node.continueExecutionOnError = block.values.property.settings.value.continueExecutionOnError.value;
          node.action = {
            type: block.values.property.type.valueSelected,
            url: block.values.property.endpoint.value,
            endpoint: block.values.property.endpoint.value,
            method: block.values.property.method.valueSelected,
            requestParamEncoding: block.values.property.encoding.valueSelected,
            headers: this.getKeyValueMap(block.values.property.headers.value),
            data: this.getKeyValueMap(block.values.property.vars_send.value),
            result: this.getKeyValueMap(block.values.property.result.value),
            attachments: this.getAttachmentsMap(block.values.property.attachments.value),
          };
          if (node.action.type == "SOAP") {
            node.action.soapSecurityType = block.values.property.security.valueSelected;
            node.action.soapSecurityAttr = {
              username: block.values.property.security.username,
              password: block.values.property.security.password,
              token: block.values.property.security.bearer,
            };
          }
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          if (node.action.result) {
            for (var result in node.action.result) {
              this.addEntityToFlow(newFlow, flowName, result, "TEXT", []);
            }
          }

          break;
        case "file":
          if (block.values.property.settings.value.overrideInputProcessor.checkbox.value) {
            node.input_processors = this.getInputProcessorObject(block.values.property.settings.value.overrideInputProcessor);
          } else {
            node.input_processors = {
              overrideInputProcessor: false,
            };
          }
          node.action = {
            type: "FILE",
            allowedExtension: block.values.property.extension_allow.extensions,
            fileMaxSize: parseInt(block.values.property.max_size.value),
            fileName: block.values.property.file_variable.value,
            filterExtension: block.values.property.extension_allow.value,
            invalidFileMessage: block.values.property.invalid_file.value,
            message: block.values.property.message_file.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "email":
          node.action = {
            type: "EMAIL",
            emailInfo: {
              attachments: this.getArrayOfStringFromMap(block.values.property.attachments.value),
              bcc: block.values.property.bcc.value,
              cc: block.values.property.cc.value,
              emailBox: block.values.property.from.valueSelected ? block.values.property.from.valueSelected : "System Email Box",
              html: block.values.property.body.value,
              replyTo: block.values.property.reply.value,
              subject: block.values.property.subject.value,
              to: block.values.property.to.value,
            },
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "survey":
          node.enableResetVariables = block.values.property.settings.value.resetAllVariables.value;
          if (block.values.property.settings.value.overrideInputProcessor.checkbox.value) {
            node.input_processors = this.getInputProcessorObject(block.values.property.settings.value.overrideInputProcessor);
          } else {
            node.input_processors = {
              overrideInputProcessor: false,
            };
          }
          score = this.atLeastOneSentence(block.values.property.score.value);
          feedback = this.atLeastOneSentence(block.values.property.feedback.value);
          node.action = {
            type: "SURVEY",
            name: block.values.property.name.value,
          };
          if (score) {
            node.action.score = "%" + block.values.property.name.value + "_score%";
          }
          if (feedback) {
            node.action.feedback = "%" + block.values.property.name.value + "_feedback%";
          }
          node.initialize_entities = [];
          if (node.action.score) {
            if (node.enableResetVariables) {
              node.initialize_entities.push("%" + block.values.property.name.value + "_score%");
            }
            this.addEntityToFlow(
              newFlow,
              flowName,
              block.values.property.name.value + "_score",
              "SURVEY_SCORE",
              block.values.property.score.value,
              block.values.property.keyboard.valueSelected,
            );
            newFlow.flow[flowName].entities[block.values.property.name.value + "_score"].disambiguation_question = block.values.property.score_invalid.value;
          }
          if (node.action.feedback) {
            if (node.enableResetVariables) {
              node.initialize_entities.push("%" + block.values.property.name.value + "_feedback%");
            }
            this.addEntityToFlow(newFlow, flowName, block.values.property.name.value + "_feedback", "TEXT", block.values.property.feedback.value);
          }
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "hangconversation":
          node.description.name = "Hang Conversation";
          node.action = {
            type: "HANG_CONVERSATION",
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "handover":
          var mode = block.values.property.type.valueSelected;
          if (mode.toLowerCase().indexOf("convyai") !== -1) {
            mode = "WCS";
          }
          node.action = {
            type: "HANDOVER",
            message: block.values.property.message.value,
            mode: mode,
            target: block.values.property.target.value,
            bubbleName: block.values.property.bubble_name.value,
            bubbleIntroduction: block.values.property.bubble_introduction.value,
            priority: {
              isDynamic: block.values.property.priority.value.isDynamic.value,
              level: block.values.property.priority.value.level.value,
            },
            enableCareWindowManagement: block.values.property.care_window.value,
            enablePostHandover: block.values.property.post_handover.value.enabled.value,
            jump_to: {
              flow: block.values.property.post_handover.value.flow.value,
              version: block.values.property.post_handover.value.version.value,
              hook: block.values.property.post_handover.value.hook.value,
            },
            enableHandoverError: block.values.property.handover_error.value.enabled.value,
            handover_error_jump_to: {
              flow: block.values.property.handover_error.value.flow.value,
              version: block.values.property.handover_error.value.version.value,
              hook: block.values.property.handover_error.value.hook.value,
            },
            enableHandoverInactivity: block.values.property.handover_inactivity.value.enabled.value,
            handoverInactivityTimeout: block.values.property.handover_inactivity.value.timeout.value,
            handover_inactivity_jump_to: {
              flow: block.values.property.handover_inactivity.value.flow.value,
              version: block.values.property.handover_inactivity.value.version.value,
              hook: block.values.property.handover_inactivity.value.hook.value,
            },
          };
          if (block.values.property.humanAgentsHandoverDetails) {
            node.action.humanAgentsHandoverDetails = {
              queue: block.values.property.humanAgentsHandoverDetails.value.queue.value,
              preferredAgentType: block.values.property.humanAgentsHandoverDetails.value.preferredAgentType.value,
              preferredAgentValue: block.values.property.humanAgentsHandoverDetails.value.preferredAgentValue.value,
            };
          }
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "setvars":
          node.description.name = "Set Variables";
          var operations = [];
          block.values.property.variables.value.forEach((setVarEntry) => {
            operations.push({
              scope: "session",
              entity: setVarEntry.key,
              operation: setVarEntry.value,
            });
            this.addEntityToFlow(newFlow, flowName, setVarEntry.key, "TEXT", []);
          });
          node.action = {
            type: "SET_ENTITIES",
            operations: operations,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "delay":
          node.action = {
            type: "DELAY",
            eventName: block.values.property.eventName.value,
            eventParameters: this.getKeyValueMap(block.values.property.eventParameters.value),
            customDelayEnabled: block.values.property.customDelayEnabled.value,
            customDelays: block.values.property.customDelayEnabled.values,
            delay: block.values.property.delay.value,
            timeoutValue: block.values.property.timeoutValue.value,
            typeOfDelay: block.values.property.typeOfDelay.valueSelected == "DELAY" ? "delay" : "pause",
          };
          node.intents = {
            default: {
              nextNode: null,
            },
            Timeout: {
              nextNode: null,
            },
          };
          break;
        case "flowjump":
          node.skipJumpToFlowReport = block.values.property.settings.value.skipJumpReport.value;
          node.action = {
            type: "JUMP",
            flow: block.values.property.flow_jump_target.value.flow.value,
            version: block.values.property.flow_jump_target.value.version.value,
            hook: block.values.property.flow_jump_target.value.hook.value,
            isDynamic: block.values.property.isDynamic ? block.values.property.isDynamic.value : false,
          };
          node.intents = {
            default: {
              nextNode: block.values.property.flow_jump_target.value.flow.value,
              version: block.values.property.flow_jump_target.value.version.value,
              hook: block.values.property.flow_jump_target.value.hook.value,
              returnNode: null,
              returnNodeUUID: null,
            },
          };
          break;
        case "question":
          node.enableEntitiesPool = block.values.property.settings.value.useEntitiesPool.value;
          node.enableResetVariables = block.values.property.settings.value.resetAllVariables.value;
          node.aggregationTimeout = this.getAggregationTimeout(block);
          node.overrideAggregationTimeout = block.values.property.settings.value.overrideCustomerInputTimeout.checkbox.value;
          if (block.values.property.settings.value.overrideInputProcessor.checkbox.value) {
            node.input_processors = this.getInputProcessorObject(block.values.property.settings.value.overrideInputProcessor);
          } else {
            node.input_processors = {
              overrideInputProcessor: false,
            };
          }
          var engineName = block.values.property.engine.valueSelected;
          if (!newFlow.flow[flowName].entity_engine) {
            newFlow.flow[flowName].entity_engine = engineName;
          }
          this.addToFlowEngines(newFlow, engineName);
          var engineType = this.getEngineType(engineName);

          var menuVariable = "%menu_question_" + block.uuid + "%";
          var questions = block.values.property.question.value;

          node.action = {
            type: "SEMANTIC",
            engine: engineName,
            data: {
              text: "<input>",
            },
            entities: [],
          };
          node.initialize_entities = [];
          node.intents = {
            default: {
              nextNode: null,
            },
          };

          if (questions && questions.length > 0 && questions[0].value) {
            node.action.entities.push(menuVariable);
            node.initialize_entities.push(menuVariable);
            this.addEntityToFlow(newFlow, flowName, menuVariable, "TEXT", questions, block.values.property.keyboard.valueSelected);
          }

          if (block.values.property.context.valueSelected) {
            node.action.context = block.values.property.context.valueSelected;
          }
          if (block.values.property.intents.value) {
            node.action.intents = block.values.property.intents.value;
            node.action.intents.forEach((intentVar) => {
              this.addEntityToFlow(newFlow, flowName, intentVar, "TEXT", []);
            });
          }
          if (block.values.property.entities.value.length > 0) {
            block.values.property.entities.value.forEach((entity) => {
              this.addEntityToFlow(
                newFlow,
                flowName,
                this.removeVariableChar(entity.variable.value),
                entity.type.valueSelected,
                entity.detailQuestion.value,
                entity.keyboard.valueSelected,
                block.uuid,
              );
              node.action.entities.push(entity.variable.value);
              if (node.enableResetVariables) {
                node.initialize_entities.push(entity.variable.value);
              }
            });
          }
          break;
        case "knowledgebase":
          engineName = block.values.property.engine.valueSelected;
          if (!newFlow.flow[flowName].entity_engine) {
            newFlow.flow[flowName].entity_engine = engineName;
          }
          this.addToFlowEngines(newFlow, engineName);

          node.useSemanticHooks = block.values.property.settings.value.useSemanticHooks.value;
          node.action = {
            type: "KNOWLEDGEBASE",
            useMessageResponse: block.values.property.settings.value.sendToUser.value,
            exitFromLoop: {
              enabled: block.values.property.settings.value.exitFromLoop.checkbox.value,
              value: block.values.property.settings.value.exitFromLoop.input.value,
            },
            engine: block.values.property.engine.valueSelected,
            useContext: block.values.property.redisContext.valueSelected,
            customPrompt: block.values.property.customPrompt.value,
            customContext: block.values.property.customContext ? block.values.property.customContext.value : "",
            toneOfVoice: block.values.property.toneOfVoice.valueSelected,
            intents: block.values.property.intents.valueSelected,
            enableExitButtons: block.values.property.enableExitButtons.valueSelected,
            contextMemory: block.values.property.contextMemory ? block.values.property.contextMemory.valueSelected : false,
            fileSearch: block.values.property.fileSearch ? block.values.property.fileSearch.valueSelected : false,
            showSourceDocument: block.values.property.showSourceDocument ? block.values.property.showSourceDocument.valueSelected : false,
            sourceDocumentText: block.values.property.sourceDocumentText ? block.values.property.sourceDocumentText.value : [],
            inputVariableToAnalyze: block.values.property.inputVariableToAnalyze ? block.values.property.inputVariableToAnalyze.valueSelected : "",
            inputVariableToSave: block.values.property.inputVariableToSave ? block.values.property.inputVariableToSave.valueSelected : false,
          };
          questions = block.values.property.question.value;
          if (questions && questions.length > 0 && questions[0].value) {
            menuVariable = "%menu_question_" + block.uuid + "%";
            node.action.entities = [];
            node.action.entities.push(menuVariable);
            node.initialize_entities = [];
            node.initialize_entities.push(menuVariable);
            const q = [
              {
                text: questions,
              },
            ];
            if (block.values.property.enableExitButtons.valueSelected) {
              const options = [];
              block.values.property.multioutput.value.forEach((intentObj) => {
                options.push({
                  text: intentObj.label.label,
                  image_url: intentObj.image_url.value,
                  icon_url: intentObj.icon_url.value,
                  caption: intentObj.description.value,
                  is_intent_enabled: this.isIntentEnabled(engineName, intentObj.intent ? intentObj.intent.valueSelected : label),
                });
              });
              q[0].options = options;
            }
            this.addEntityToFlow(newFlow, flowName, menuVariable, "TEXT", q, "keyboard_default_1");
          }
          node.intents = {
            default: {
              nextNode: null,
            },
            Failed: {
              nextNode: null,
            },
          };
          var maxOpenAiIndexId = 0;
          if (block.values.property.enableExitButtons.valueSelected) {
            block.values.property.multioutput.value.forEach((intentObj, index) => {
              const intent = intentObj.intent.valueSelected;
              const intentValue = this.getIntentValue(node.action.engine, intent);
              node.intents[intentValue] = {
                nextNode: null,
                index: index + 1,
                label: intentObj.label.label[0].value,
                multilanguageLabel: intentObj.label.label,
              };
              maxOpenAiIndexId = index + 1;
            });
          } else {
            node.action.intents.forEach((intent, index) => {
              const intentValue = this.getIntentValue(node.action.engine, intent);
              node.intents[intentValue] = {
                nextNode: null,
                index: index + 1,
                label: intent,
              };
              maxOpenAiIndexId = index + 1;
            });
          }
          if (node.useSemanticHooks && scene.configurations.semantic_hooks.semanticHookEngineName === engineName) {
            scene.configurations.semantic_hooks.semanticHookList.forEach((hookObj, index) => {
              if (hookObj.intent && !node.intents[hookObj.intent]) {
                node.intents[hookObj.intent] = {
                  nextNode: hookObj.target,
                  version: hookObj.version,
                  inherited: true,
                  hook: hookObj.hook,
                  index: maxOpenAiIndexId + 1 + index,
                };
              }
            });
          }
          break;
        case "menu":
          node.aggregationTimeout = this.getAggregationTimeout(block);
          node.overrideAggregationTimeout = block.values.property.settings.value.overrideCustomerInputTimeout.checkbox.value;
          node.useSemanticHooks = block.values.property.settings.value.useSemanticHooks.value;
          if (block.values.property.settings.value.overrideInputProcessor.checkbox.value) {
            node.input_processors = this.getInputProcessorObject(block.values.property.settings.value.overrideInputProcessor);
          } else {
            node.input_processors = {
              overrideInputProcessor: false,
            };
          }
          engineName = block.values.property.engine.valueSelected;
          if (!newFlow.flow[flowName].entity_engine) {
            newFlow.flow[flowName].entity_engine = engineName;
          }
          this.addToFlowEngines(newFlow, engineName);
          engineType = this.getEngineType(engineName);
          //lo zero è il campo per la variabile del menu, non il 4
          menuVariable = block.values.property.dynamic.value.inputs[0].value
            ? block.values.property.dynamic.value.inputs[0].value
            : "%menu_question_" + block.uuid + "%";
          var menuDisambiguationVariable = null;
          if (engineType != "EUDATA") {
            if (
              block.values.property.disambiguationQuestion.value &&
              block.values.property.disambiguationQuestion.value.length > 0 &&
              block.values.property.disambiguationQuestion.value[0].value.length > 0
            ) {
              menuDisambiguationVariable = "%menu_disambiguation_question_" + block.uuid + "%";
            }
          }
          var menuNoBranchRecognizedVariable = null;
          if (
            block.values.property.question_not_recognized.value &&
            block.values.property.question_not_recognized.value.length > 0 &&
            block.values.property.question_not_recognized.value[0].value.length > 0
          ) {
            menuNoBranchRecognizedVariable = "%menu_no_branch_question_" + block.uuid + "%";
          }
          node.action = {
            type: engineType == "EUDATA" ? "SWITCH" : "SEMANTIC",
            engine: engineName,
            data: {
              text: "<input>",
            },
            menuOptionalEntities: block.values.property.entities.value,
            isDynamic: block.values.property.dynamic.value.checkbox.value,
            dynamicMenuVariable: block.values.property.dynamic.value.inputs[1].value,
            dynamicTextField: block.values.property.dynamic.value.inputs[2].value,
            dynamicImageUrlField: block.values.property.dynamic.value.inputs[3].value,
            dynamicIconUrlField: block.values.property.dynamic.value.inputs[4].value,
            dynamicDescriptionField: block.values.property.dynamic.value.inputs[5].value,
            entities: [menuVariable],
            questionDescription: block.values.property.description.value,
            disambiguationQuestion: menuDisambiguationVariable,
            noBranchRecognizedQuestion: menuNoBranchRecognizedVariable,
          };
          if (block.values.property.context.valueSelected) {
            node.action.context = block.values.property.context.valueSelected;
          }
          if (block.values.property.entities.value.enable) {
            block.values.property.entities.value.list.forEach(
              function (entity) {
                this.addEntityToFlow(newFlow, flowName, entity.name, entity.type, [], null, block.uuid);
              }.bind(this),
            );
          }
          if (engineType == "EUDATA") {
            node.action.conditions = [];
          }
          node.initialize_entities = [menuVariable];
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          var maxIndexId = 0;
          var options = [];
          block.values.property.multioutput.value.forEach(
            function (intentObj, index) {
              var label = intentObj.label.label;
              //Caso multilingua
              if (typeof label != "string") {
                //altrimenti si spacca se non setto la lingua
                if (label && label[0]) {
                  label = label[0].value;
                } else {
                  label = "";
                }
              }

              var intent = this.getIntentValue(engineName, intentObj.intent && intentObj.intent.valueSelected ? intentObj.intent.valueSelected : label);

              if (intent) {
                node.intents[intent] = {
                  nextNode: null,
                  index: index + 1,
                  label: label,
                  multilanguageLabel: intentObj.label.label,
                };
                maxIndexId = index + 1;
              }
              options.push({
                text: intentObj.label.label,
                image_url: intentObj.image_url.value,
                icon_url: intentObj.icon_url.value,
                caption: intentObj.description.value,
                is_intent_enabled: this.isIntentEnabled(engineName, intentObj.intent ? intentObj.intent.valueSelected : label),
              });
              //Caso motore eudata
              if (node.action.conditions) {
                var cond = this.createEudataMenuRegExp(menuVariable, intentObj.label.label);
                intentObj.synonyms.value &&
                  intentObj.synonyms.value.forEach(
                    function (tag) {
                      cond += "|| new RegExp('\\\\W" + this.sanitizeRegExpValue(tag) + "\\\\W').test(' '+" + menuVariable + ".toLowerCase()+' ')";
                    }.bind(this),
                  );
                node.action.conditions.push({
                  intent: label,
                  intentValue: intentObj.intentOptional ? intentObj.intentOptional.valueSelected : null,
                  tags: JSON.stringify(intentObj.synonyms.value),
                  condition: cond,
                });
              }
            }.bind(this),
          );
          if (node.useSemanticHooks) {
            scene.configurations.semantic_hooks.semanticHookList.forEach(
              function (hookObj, index) {
                var intent = hookObj.intent;
                if (node.action.conditions) {
                  //Caso motore eudata, devo generare le conditions
                  var intentConf = this.getIntentContent(engineName, hookObj.intent);
                  var cond = this.createEudataMenuRegExp(menuVariable, [{ value: intent }]);
                  intentConf &&
                    intentConf.tags &&
                    intentConf.tags.forEach(
                      function (tag) {
                        cond += "|| new RegExp('\\\\W" + this.sanitizeRegExpValue(tag) + "\\\\W').test(' '+" + menuVariable + ".toLowerCase()+' ')";
                      }.bind(this),
                    );
                  node.action.conditions.push({
                    intent: intent,
                    tags: null,
                    condition: cond,
                    inherited: true,
                  });
                } else {
                  //Caso motore NON eudata vado a prendere il valore
                  intent = this.getIntentValue(engineName, hookObj.intent);
                }
                if (intent && !node.intents[intent]) {
                  node.intents[intent] = {
                    nextNode: hookObj.target,
                    version: hookObj.version,
                    inherited: true,
                    hook: hookObj.hook,
                    index: maxIndexId + 1 + index,
                  };
                }
              }.bind(this),
            );
          }
          //Caso motore eudata
          if (node.action.conditions) {
            node.action.conditions.push({
              condition: "true",
              intent: "default",
              tags: [],
              inherited: false,
            });
          }

          questions = [
            {
              text: block.values.property.question.value,
              options: options,
            },
          ];
          this.addEntityToFlow(newFlow, flowName, menuVariable, "TEXT", questions, block.values.property.keyboard.valueSelected, block.uuid);
          if (menuNoBranchRecognizedVariable) {
            node.initialize_entities.push(menuNoBranchRecognizedVariable);
            node.action.entities.push(menuNoBranchRecognizedVariable);
            questions = [
              {
                text: block.values.property.question_not_recognized.value,
                options: options,
              },
            ];
            this.addEntityToFlow(newFlow, flowName, menuNoBranchRecognizedVariable, "TEXT", questions, block.values.property.keyboard.valueSelected);
          }
          if (menuDisambiguationVariable) {
            questions = block.values.property.disambiguationQuestion.value;
            this.addEntityToFlow(newFlow, flowName, menuDisambiguationVariable, "TEXT", questions, block.values.property.keyboard.valueSelected);
          }
          break;
        case "jump":
          node.action = {
            type: "JUMP",
            hook: block.values.property.target.valueSelected,
          };
          node.intents = {
            default: {
              nextNode: this.getNodeIdWithHook(this.scene.blocks, block.values.property.target.valueSelected),
            },
          };
          break;
        case "merge_contact":
          node.action = {
            type: "MERGE_CONTACT",
            conditionType: block.values.property.conditionType.value,
            filters: block.values.property.filters.value,
            var_update: block.values.property.var_update.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
            Failed: {
              nextNode: null,
            },
          };
          break;
        case "search_contact":
          node.action = {
            type: "SEARCH_CONTACT",
            typeValue: block.values.property.typeValue.valueSelected,
            number: block.values.property.number.numberSelected,
            advancedOptions: block.values.property.advancedOptions.value,
            conditionType: block.values.property.conditionType.value,
            userConditionType: block.values.property.userConditionType ? block.values.property.userConditionType.value : 0,
            userId: block.values.property.userId ? block.values.property.userId.value : "",
            botSelected: block.values.property.botSelected.value,
            channelSelected: block.values.property.channelSelected.value,
            dateSelected: block.values.property.dateSelected.value,
            vars_result: block.values.property.vars_result.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
        case "search_customer_info":
          node.action = {
            type: "SEARCH_CUSTOMER_INFO",
            conditionType: block.values.property.conditionType.value,
            filters: block.values.property.filters.value,
            vars_result: block.values.property.vars_result.value,
          };
          node.intents = {
            default: {
              nextNode: null,
            },
          };
          break;
      }
      newFlow.nodes[nodeId] = node;
    }

    if (!newFlow.flow[flowName].entity_engine) {
      newFlow.flow[flowName].entity_engine = "EudataEngine";
    }

    this.updateEntityList(scene.blocks, newFlow, flowName);

    //Ciclo per tutti i link e collego tutti i nodi
    for (var linkId in scene.links) {
      var link = scene.links[linkId];
      block = scene.blocks[link.originID];
      if (block) {
        var key = block.outputs[link.originSlot].label;
        //Caso multilingua
        if (typeof key != "string") {
          if (key.length > 0) {
            key = key[0].value;
          }
        }

        var intent = this.isValidIntentForNode(newFlow.nodes[block.id].intents, key);

        if (!intent || key == "default") {
          if (block.name == "flowjump") {
            //Gestione flow jump
            newFlow.nodes[link.originID].intents.default.returnNode = link.targetID;
            newFlow.nodes[link.originID].intents.default.returnNodeUUID = this.getNodeUUID(link.targetID, scene.blocks);
          } else {
            newFlow.nodes[link.originID].intents.default.nextNode = link.targetID;
            //faccio un check perchè se il target è un altro flusso nel flusso corrente non ho il nodo target successivo
            if (newFlow.nodes[link.targetID]) {
              newFlow.nodes[link.targetID].description.storyBoardName = newFlow.nodes[link.originID].description.storyBoardName;
            }
            if (block.name == "menu") {
              //Nei menu devo valorizzare tutti i returnNode dei sematic hook come il next
              for (let intentName in newFlow.nodes[link.originID].intents) {
                if (newFlow.nodes[link.originID].intents[intentName].inherited) {
                  newFlow.nodes[link.originID].intents[intentName].returnNode = link.targetID;
                  newFlow.nodes[link.originID].intents[intentName].returnNodeUUID = this.getNodeUUID(link.targetID, scene.blocks);
                }
              }
            }
          }
        } else if (newFlow.nodes[block.id].intents[intent].inherited) {
          newFlow.nodes[block.id].intents[intent].returnNode = link.targetID;
          newFlow.nodes[block.id].intents[intent].returnNodeUUID = this.getNodeUUID(link.targetID, scene.blocks);
        } else {
          newFlow.nodes[block.id].intents[intent].nextNode = link.targetID;
          var label = newFlow.nodes[link.originID].intents[intent].label || intent;
          newFlow.nodes[link.targetID].description.storyBoardName =
            newFlow.nodes[link.originID].description.storyBoardName + "-" + newFlow.nodes[link.originID].description.uuid + "_" + label;
        }
      }
    }

    //Sistemo le storyboard name nelle descrizioni dei nodi
    for (var nId in newFlow.nodes) {
      newFlow.nodes[nId].description = JSON.stringify(newFlow.nodes[nId].description);
    }

    //Semantic Hooks
    if (scene.configurations.semantic_hooks.semanticHookList && scene.configurations.semantic_hooks.semanticHookList.length > 0) {
      newFlow.semantic_hooks[scene.configurations.semantic_hooks.semanticHookEngineName] = {};
      scene.configurations.semantic_hooks.semanticHookList.forEach(
        function (semanticHook, index) {
          newFlow.semantic_hooks[scene.configurations.semantic_hooks.semanticHookEngineName][semanticHook.intent] = {
            flow: semanticHook.target,
            version: semanticHook.version,
            hook: semanticHook.hook,
            index: index + 1 + "", //<-- lo vuole come stringa
          };
        }.bind(this),
      );
    }
    //Gestione errore
    maxNodeId++;
    newFlow.flow[flowName].errorNode = maxNodeId;
    newFlow.nodes[maxNodeId] = {
      description: '{"type":"error","uuid":"9999998"}',
      action: {
        type: "MSGOUT",
        message: scene.configurations.error.error_message || "",
      },
      intents: {
        default: {
          nextNode: null,
        },
      },
    };
    //Gestione errore advanced
    let scoreVar = "";
    let feedbackVar = "";
    if (scene.configurations.error.error_complex_node_enabled) {
      newFlow.nodes[maxNodeId].intents.default.nextNode = maxNodeId + 1;
      maxNodeId++;
      switch (scene.configurations.error.advancedErrorMode) {
        case "errorTabFlow":
          newFlow.nodes[maxNodeId] = {
            description: '{"type":"error","uuid":"9999999"}',
            action: {
              type: "JUMP",
              flow: scene.configurations.error.jump_to.flow,
              version: scene.configurations.error.jump_to.version,
              hook: scene.configurations.error.jump_to.hook,
            },
            intents: {
              default: {
                nextNode: scene.configurations.error.jump_to.flow,
                version: scene.configurations.error.jump_to.version,
                hook: scene.configurations.error.jump_to.hook,
                returnNode: null,
              },
            },
          };
          break;
        case "errorTabHandover":
          newFlow.nodes[maxNodeId] = {
            description: '{"type":"error","uuid":"9999999"}',
            action: {
              type: "HANDOVER",
              mode: scene.configurations.error.advancedErrorModeHandoverType,
              target: scene.configurations.error.advancedErrorModeHandoverTarget,
              message: scene.configurations.error.advancedErrorModeHandoverMessage,
            },
            intents: {
              default: {
                nextNode: null,
              },
            },
          };
          if (scene.configurations.error.advancedErrorModeHandoverBubble) {
            newFlow.nodes[maxNodeId].action.bubbleName = scene.configurations.error.advancedErrorModeHandoverBubble;
          }
          if (scene.configurations.error.advancedErrorModeHandoverBubbleIntroduction) {
            newFlow.nodes[maxNodeId].action.bubbleIntroduction = scene.configurations.error.advancedErrorModeHandoverBubbleIntroduction;
          }
          break;
        case "errorTabSurvey":
          scoreVar = "%" + scene.configurations.error.advancedErrorModeSurveyName + "_score%";
          feedbackVar = "%" + scene.configurations.error.advancedErrorModeSurveyName + "_feedback%";
          newFlow.nodes[maxNodeId] = {
            description: '{"type":"error","uuid":"9999999"}',
            action: {
              type: "SURVEY",
              name: scene.configurations.error.advancedErrorModeSurveyName,
              score: scoreVar,
              feedback: feedbackVar,
            },
            initialize_entities: [scoreVar, feedbackVar],
            intents: {
              default: {
                nextNode: null,
              },
            },
          };
          newFlow.flow[flowName].entities[this.removeVariableChar(scoreVar)] = {
            description: this.removeVariableChar(scoreVar),
            type: "SURVEY_SCORE",
            questions: scene.configurations.error.advancedErrorModeSurveyScore,
            placeholder: scoreVar,
            keyboard: "keyboard_default_6",
          };
          newFlow.flow[flowName].entities[this.removeVariableChar(feedbackVar)] = {
            description: this.removeVariableChar(feedbackVar),
            type: "TEXT",
            questions: scene.configurations.error.advancedErrorModeSurveyFeedback,
            placeholder: feedbackVar,
          };
          break;
      }
    }

    //Gestione inactivity
    maxNodeId++;
    newFlow.flow[flowName].inactivityNode = maxNodeId;
    newFlow.nodes[maxNodeId] = {
      description: '{"type":"inactivity","uuid":"9999988"}',
      action: {
        type: "MSGOUT",
        message: scene.configurations.inactivity.inactivityMessage || "",
      },
      intents: {
        default: {
          nextNode: null,
        },
      },
    };
    //Gestione inactivity advanced
    if (scene.configurations.inactivity.inactivity_survey_enabled) {
      newFlow.nodes[maxNodeId].intents.default.nextNode = maxNodeId + 1;
      maxNodeId++;
      let scoreVar = "%" + scene.configurations.inactivity.inactivitySurveyName + "_score%";
      let feedbackVar = "%" + scene.configurations.inactivity.inactivitySurveyName + "_feedback%";
      newFlow.nodes[maxNodeId] = {
        description: '{"type":"inactivity","uuid":"9999999"}',
        action: {
          type: "SURVEY",
          name: scene.configurations.inactivity.inactivitySurveyName,
          score: scoreVar,
          feedback: feedbackVar,
        },
        initialize_entities: [scoreVar, feedbackVar],
        intents: {
          default: {
            nextNode: null,
          },
        },
      };
      newFlow.flow[flowName].entities[this.removeVariableChar(scoreVar)] = {
        description: this.removeVariableChar(scoreVar),
        type: "SURVEY_SCORE",
        questions: scene.configurations.inactivity.inactivitySurveyScore,
        placeholder: scoreVar,
        keyboard: "keyboard_default_6",
      };
      newFlow.flow[flowName].entities[this.removeVariableChar(feedbackVar)] = {
        description: this.removeVariableChar(feedbackVar),
        type: "TEXT",
        questions: scene.configurations.inactivity.inactivitySurveyFeedback,
        placeholder: feedbackVar,
      };
    }
    newFlow.scene = scene;
    newFlow.scene.attr = {
      flowName: flowName,
      flowSelectedLang: this.scene.attr.language,
      ivr: bot ? bot.isVisualIvr : false,
      outbound: this.scene.attr?.outbound || false,
      lastUpdate: newFlow.flow[flowName].last_update_date,
      firstNodeId: this.scene.attr.firstNode,
    };
    return newFlow;
  };

  this.getNodeUUID = function (targetID, blocks) {
    return blocks[targetID].uuid;
  };

  this.addEntityToFlow = function (flow, flowName, entity, type, questions, keyboard, uuid) {
    var sanitizedEntity = this.removeVariableChar(entity);
    if (flow.flow[flowName].entities[sanitizedEntity] && uuid) {
      flow.flow[flowName].entities[sanitizedEntity]["questions_" + uuid] = questions;
    } else if (!flow.flow[flowName].entities[sanitizedEntity] || type != "TEXT") {
      flow.flow[flowName].entities[sanitizedEntity] = {
        description: sanitizedEntity,
        type: type,
        questions: questions,
        placeholder: this.addVariableChar(sanitizedEntity),
      };
    }
    if (type != "TEXT") {
      flow.flow[flowName].entities[sanitizedEntity].type = type;
    }
    if (keyboard) {
      if (typeof keyboard != "string") {
        keyboard = keyboard.value;
      }
      flow.flow[flowName].entities[sanitizedEntity].keyboard = keyboard;
    }
  };
  this.getIntentContent = function (engineName, intent) {
    if (this.engines[engineName].engines[engineName]) {
      return this.engines[engineName].engines[engineName].intents[intent] || intent;
    }
    return intent;
  };
  this.getIntentValue = function (engineName, intent) {
    if (this.engines[engineName] && this.engines[engineName].engines[engineName] && this.engines[engineName].engines[engineName].type == "EUDATA")
      return intent;
    if (this.engines[engineName].engines[engineName]) {
      return this.engines[engineName].engines[engineName].intents[intent] || intent;
    }
    return intent;
  };
  this.isIntentEnabled = function (engineName, intentLabel) {
    if (
      this.engines[engineName].engines[engineName] &&
      this.engines[engineName].engines[engineName].intentsAttributes &&
      this.engines[engineName].engines[engineName].intentsAttributes[intentLabel]
    )
      return this.engines[engineName].engines[engineName].intentsAttributes[intentLabel].enabled;
    return true;
  };
  this.getAggregationTimeout = function (block) {
    var aggregationTimeout = null;
    if (block.values.property.settings.value.overrideCustomerInputTimeout.checkbox.value) {
      aggregationTimeout = block.values.property.settings.value.overrideCustomerInputTimeout.input.value * 1000;
    }
    return aggregationTimeout;
  };
  this.clone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
  };
  this.capitalize = function (s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  this.addVariableChar = function (s) {
    if (s) return "%" + s + "%";
    return s;
  };
  this.removeVariableChar = function (s) {
    if (s) return s.replace(new RegExp("%", "g"), "");
    return s;
  };
  this.formatValues = function (e) {
    var res = [];
    if (e) {
      if (e.forEach) {
        e.forEach(function (obj) {
          res.push({
            key: obj.entity,
            value: obj.operation,
          });
        });
      }
    }
    return res;
  };
  this.getArrayOfObjFromString = function (arr) {
    var res = [];
    if (arr) {
      arr.forEach(function (str) {
        res.push({
          key: str,
        });
      });
    }
    return res;
  };
  this.getArrayOfPropFromMap = function (map) {
    var res = [];
    for (var key in map) {
      if (map[key]) {
        if (typeof map[key] == "string") {
          res.push({
            key: key,
            value: map[key],
          });
        } else {
          res.push({
            key: key,
            value: map[key].value,
            select: {
              value: ["Binary", "Base64"],
              valueSelected: map[key].encoding,
            },
          });
        }
      }
    }
    return res;
  };
  this.getDayOfWeek = function (num) {
    switch (num) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
    }
  };
  this.getNumDayOfWeek = function (day) {
    switch (day) {
      case "Sunday":
        return 0;
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
    }
  };
  this.getEngineType = function (engineName) {
    if (this.engines[engineName]) return this.engines[engineName].engines[engineName].type;
    return null;
  };
  this.getIntentLabelFromEngine = function (engineName, intent) {
    if (this.engines[engineName]) {
      var engine = this.engines[engineName].engines[engineName];
      for (let intentLabel in engine.intents) {
        if (engine.intents[intentLabel] == intent) {
          return intentLabel;
        }
      }
    }
    return intent;
  };
  this.formatDate = function (d) {
    if (!d) {
      d = new Date();
    }
    d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    var h = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + h + ":" + m + ":" + s;
  };
  this.getInputProcessorObject = function (blockConfig) {
    var enabled = blockConfig.input.valueSelected.toLowerCase() != "disabled" ? true : false;
    var name = blockConfig.input.valueSelected;
    if (enabled) {
      var ip = this.getInputProcessor(name);
      if (ip) {
        name = ip.type + "-" + ip.name;
      }
    }
    return {
      overrideInputProcessor: blockConfig.checkbox.value,
      enable_input_processor: enabled,
      input_processor_type: name,
    };
  };
  this.getInputProcessor = function (name) {
    var type = name.substr(0, name.indexOf("-"));
    name = name.substr(name.indexOf("-") + 1);
    for (var ipType in this.inputProcessors) {
      if (this.inputProcessors[ipType] && type == ipType) {
        for (var i = 0; i < this.inputProcessors[ipType].length; i++) {
          if ((this.inputProcessors[ipType][i].name = name)) {
            return this.inputProcessors[ipType][i];
          }
        }
      }
    }
    return null;
  };
  this.getAdvancedErrorType = function (node, flow, flowName) {
    var res = {
      mode: "",
      advancedErrorModeHandoverType: "",
      advancedErrorModeHandoverTarget: "",
      advancedErrorModeSurveyName: "",
      advancedErrorModeSurveyScore: "",
      advancedErrorModeSurveyFeedback: "",
      jump_to: {
        flow: "",
        version: "",
        hook: "",
      },
    };
    switch (node.action.type) {
      case "SURVEY":
        res.mode = "errorTabSurvey";
        res.advancedErrorModeSurveyName = node.action.name;
        res.advancedErrorModeSurveyScore = this.migrationSurvey(
          flow.flow[flowName].entities[this.removeVariableChar(node.action.score)].questions,
          flow.flow[flowName].lang,
        );
        res.advancedErrorModeSurveyFeedback = this.migrationSurvey(
          flow.flow[flowName].entities[this.removeVariableChar(node.action.feedback)].questions,
          flow.flow[flowName].lang,
        );
        break;
      case "HANDOVER":
        res.mode = "errorTabHandover";
        res.advancedErrorModeHandoverType = node.action.mode;
        res.advancedErrorModeHandoverTarget = node.action.target;
        res.advancedErrorModeHandoverMessage = node.action.message || [];
        res.advancedErrorModeHandoverTargetBubbleName = node.action.bubbleName || [];
        res.advancedErrorModeHandoverTargetBubbleIntroduction = node.action.bubbleIntroduction || [];
        break;
      case "JUMP":
        res.mode = "errorTabFlow";
        res.jump_to = {
          flow: node.action.flow,
          version: node.action.version,
          hook: node.action.hook,
        };
        break;
    }
    return res;
  };
  this.getSingleQuestion = function (flow, flowName, entity) {
    if (flow.flow[flowName].entities[entity]) {
      return this.migrationToMultilanguage(flow.flow[flowName].entities[entity].questions[0], flow.flow[flowName].lang);
    } else {
      return "";
    }
  };
  this.addToFlowEngines = function (flow, engine) {
    if (engine && flow.engines.indexOf(engine) == -1) {
      flow.engines.push(engine);
    }
    return;
  };
  this.charToSanitize = ["'", "(", ")", "[", "]", "?", "+", "*"];
  this.getMaxOccurrencyOfSpecialChars = function (exp) {
    var expCounts = {};
    var maxKey = "";
    for (var i = 0; i < exp.length; i++) {
      var key = exp[i];
      if (this.charToSanitize.indexOf(key) !== -1) {
        if (!expCounts[key]) {
          expCounts[key] = 0;
        }
        expCounts[key]++;
        if (maxKey == "" || expCounts[key] > expCounts[maxKey]) {
          maxKey = key;
        }
      }
    }
    if (maxKey) return expCounts[maxKey];
    return 0;
  };
  this.sanitizeRegExpValue = function (str) {
    if (str) {
      //Sanifico
      str = str.trim();
      var iteration = this.getMaxOccurrencyOfSpecialChars(str);
      for (var i = 0; i < iteration; i++) {
        str = str
          .replace(/([^\\])'/g, "$1\\'") //apice singolo
          .replace(/([^\\])\(/g, "$1\\\\(") //tonde
          .replace(/([^\\])\)/g, "$1\\\\)")
          .replace(/([^\\])\[/g, "$1\\\\[") //quadre
          .replace(/([^\\])\]/g, "$1\\\\]")
          .replace(/([^\\])\?/g, "$1\\\\?") //?
          .replace(/([^\\])\+/g, "$1\\\\+") //+
          .replace(/([^\\])\*/g, "$1\\\\*"); //* Non serve
      }
    }
    return str.toLowerCase();
  };
  this.createEudataMenuRegExp = function (menuVariable, multilanguageObj) {
    var cond = "";
    for (var i = 0; i < multilanguageObj.length; i++) {
      var multiLangOpt = multilanguageObj[i];
      if (i > 0) cond += " || ";
      cond += "new RegExp('\\\\W" + this.sanitizeRegExpValue(multiLangOpt.value) + "\\\\W').test(' '+" + menuVariable + ".toLowerCase()+' ')";
    }
    return cond;
  };
  this.getOptionOfIntent = function (question, intent, engineName) {
    for (var i = 0; question.options && i < question.options.length; i++) {
      let intentLabel = this.getIntentLabelFromEngine(engineName, intent);
      if (question.options[i].text == intent || question.options[i].text == intentLabel) {
        return question.options[i];
      }
    }
    return {};
  };
  this.getTagsOfIntent = function (conditions, intent) {
    for (var i = 0; conditions && i < conditions.length; i++) {
      if (conditions[i].intent == intent) {
        return conditions[i].tags;
      }
    }
    return [];
  };
  this.getKeyValueMap = function (arrayOfObj) {
    var res = {};
    arrayOfObj &&
      arrayOfObj.forEach(function (obj) {
        res[obj.key] = obj.value;
      });
    return res;
  };
  this.getArrayOfStringFromMap = function (arrayOfKeys) {
    var res = [];
    arrayOfKeys &&
      arrayOfKeys.forEach(function (obj) {
        res.push(obj.key);
      });
    return res;
  };
  this.getAttachmentsMap = function (arrayOfObj) {
    var res = {};
    arrayOfObj &&
      arrayOfObj.forEach(function (obj) {
        res[obj.key] = { value: obj.value, encoding: obj.select.valueSelected };
      });
    return res;
  };
  this.getLanguageCode = function (language) {
    // ?non viene usata?
    return this.availableLanguages[language];
  };
  this.getLanguageInfo = function (language) {
    if (this.availableLanguages[language]) {
      return {
        code: this.availableLanguages[language],
        label: language,
        flag: this.getFlagFromLanguageCode(this.availableLanguages[language].toLowerCase()),
      };
    }
    for (var lang in this.availableLanguages) {
      if (this.availableLanguages[lang] === language) {
        return {
          code: this.availableLanguages[lang],
          label: lang,
          flag: this.getFlagFromLanguageCode(this.availableLanguages[lang].toLowerCase()),
        };
      }
    }
    return {
      code: "en",
      label: "English",
    };
  };
  this.getMultiLanguageDetailQuestionObj = function (langInfo, detailQuestions) {
    var result = [];
    for (var i = 0; detailQuestions && i < detailQuestions.length; i++) {
      result.push({
        code: langInfo.code,
        flag: langInfo.flag,
        label: langInfo.label,
        value: detailQuestions[i],
      });
    }
    return [result];
  };
  this.getMultiLanguageObj = function (langInfo, str) {
    if (str) {
      //Se è una stringa la trasformo nell'array con lingua di default
      if (typeof str == "string") {
        return [
          {
            code: langInfo.code,
            flag: langInfo.flag,
            label: langInfo.label,
            value: str,
          },
        ];
      }
      if (str.length > 0) {
        //Se è un array di stringhe lo trasformo in array di oggetti multilingua
        if (typeof str[0] == "string") {
          for (var i = 0; i < str.length; i++) {
            str[i] = {
              code: langInfo.code,
              flag: langInfo.flag,
              label: langInfo.label,
              value: str[i],
            };
          }
        }
      }
    } else {
      str = [];
    }
    return str;
  };
  this.isValidIntentForNode = function (intents, key) {
    if (intents[key]) {
      return key;
    }
    for (var intent in intents) {
      if (intents[intent].label == key) {
        return intent;
      }
      //se c'è il multilingua controllo anche quelli
      if (intents[intent].multilanguageLabel && intents[intent].multilanguageLabel.length > 0) {
        let multiLanguage = intents[intent].multilanguageLabel;
        for (let index in multiLanguage) {
          let intentLang = multiLanguage[index];
          if (intentLang.value === key) {
            return intent;
          }
        }
      }
    }
    return false;
  };
  this.getNodeIdWithHook = function (blocks, hook) {
    if (blocks) {
      var ids = Object.keys(blocks);
      for (var i = 0; i < ids.length; i++) {
        var blockId = ids[i];
        if (blocks[blockId].values.property.hook && blocks[blockId].values.property.hook.value == hook) {
          return blocks[blockId].id;
        }
      }
    }
    return null;
  };
  this.getNumberOfExits = function (nodes, nextNodeId) {
    var res = 0;
    if (nextNodeId && nodes[nextNodeId] && nodes[nextNodeId].intents) {
      for (var intent in nodes[nextNodeId].intents) {
        var intentObj = nodes[nextNodeId].intents[intent];
        if (intentObj && !intentObj.inherited) {
          res++;
        }
      }
    }
    return res;
  };
  this.getFlagFromLanguageCode = function (code) {
    for (var i = 0; i < languagesConfigurations.length; i++) {
      if (languagesConfigurations[i].code.toLowerCase() == code) {
        return languagesConfigurations[i].flag;
      }
    }
  };
  this.atLeastOneSentence = function (val) {
    if (val && val.length > 0 && val[0].value) {
      return true;
    }
    return false;
  };
  this.blockTypeTranslation = {
    message: "text",
    text: "message",
    end: "hangconversation",
    hangconversation: "end",
    survey: "survey",
    file: "file",
    multimedia: "multimedia",
    auth: "authentication",
    authentication: "auth",
    webserv: "web service",
    outbound: "outbound",
    "web service": "webserv",
    flowjump: "Flow Jump",
    "Flow Jump": "flowjump",
    variables: "setvars",
    setvars: "variables",
    email: "email",
    delay: "delay",
    sendevent: "sendevents",
    sendevents: "sendevent",
    handover: "handover",
    question: "question",
    crossroads: "crossroads",
    "multi crossroads": "crossroads",
    Date: "crossroads",
    "Hook Jump": "jump",
    jump: "Hook Jump",
    menu: "menu",
    merge_contact: "merge_contact",
    search_contact: "search_contact",
    search_customer_info: "search_customer_info",
    whatsapp_template: "whatsapp_template",
    "whatsapp template": "whatsapp template",
    geolocation: "geolocation",
    form: "form",
    buttons: "buttons",
    knowledgebase: "knowledgebase",
  };
  this.migrationToMultilanguage = function (obj, lang) {
    if (!obj) {
      return [];
    } else if (typeof obj == "string") {
      return [
        {
          code: this.getLanguageInfo(lang).code,
          flag: this.getLanguageInfo(lang).flag,
          label: this.getLanguageInfo(lang).label,
          value: obj,
        },
      ];
    } else {
      return obj;
    }
  };
  this.migrationSurvey = function (obj, lang) {
    if (obj.length > 0) {
      if (typeof obj[0] == "string") {
        for (var i = 0; i < obj.length; i++) {
          obj[i] = {
            code: this.getLanguageInfo(lang).code,
            flag: this.getLanguageInfo(lang).flag,
            label: this.getLanguageInfo(lang).label,
            value: obj[i],
          };
        }
      }
    }
    return obj;
  };
  //Questo metodo aggiunge tutte le entity definite in giro per i nodi
  this.updateEntityList = function (blocks, flow, flowName) {
    let placeholderPattern = "%[\\pL0-9]+[\\pL0-9\\-_\\\\]*%";
    let str = JSON.stringify(blocks);
    let res = [];
    let regexp = new XRegExp(placeholderPattern, "gi");
    while ((res = regexp.exec(str)) != null) {
      let clearRes = res[0].substring(1, res[0].length - 1);
      if (this.excludedSystemVariables.indexOf(res[0]) === -1 && !flow.flow[flowName].entities[clearRes]) {
        this.addEntityToFlow(flow, flowName, clearRes, "TEXT", []);
      }
    }
  };
};

function getBlocksBuilder(opts) {
  return new BlocksBuilder(opts);
}

export default {
  getBlocksBuilder,
};
