<template>
  <v-container fluid :class="getCreationContainerMargin" class="mb-12 propagatePage">
    <ResultSnackbar ref="resultSnackbar" />
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onPropagateConfirm" />

    <v-row :class="getMainRowMargin">
      <v-col cols="12">
        <v-stepper v-model="stepper" class="stepperColor">
          <v-stepper-header>
            <v-stepper-step :complete="stepper > 1" step="1">
              What
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="stepper > 2" step="2">
              Configuration
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="stepper > 3" step="3">
              Where
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="4">
              Sum Up
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0" :height="cardStepperHeight - 50">
                <v-card-title>What you need to change</v-card-title>
                <v-card-text>
                  <v-select v-model="selectedType" :items="typesOfPropagate" :disabled="showWarning" />
                  <v-alert v-if="showWarning" type="error" outlined text>
                    There are no target flow available! You need to create at least one flow to use this functionality!
                  </v-alert>
                  <v-alert type="info" outlined text :color="$vuetify.theme.dark ? '#FBFBF7' : 'primary'">
                    {{ getMessage() }}
                  </v-alert>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-spacer />
                <v-btn :class="getButtonMargin" color="primary" :disabled="Object.keys(flowsTargets).length === 0" @click="stepper = 2">
                  Next
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card elevation="0">
                <v-card-title
                  v-if="selectedType === 'Add Semantic Hooks' || selectedType === 'Remove Semantic Hooks'"
                  v-html="getSecondStepperTitle()"
                />
                <v-card-text class="pt-0">
                  <v-form v-model="isValid">
                    <ConfigureSemanticHooks
                      v-if="selectedType === 'Add Semantic Hooks' || selectedType === 'Remove Semantic Hooks'"
                      :semanticHookList="semanticHookList"
                      :flowsTargets="flowsTargets"
                      :semanticEngines="semanticEngines"
                      :semanticEngineSelected="semanticEngineSelected"
                      :selectedType="selectedType"
                      @updateEngineSelected="semanticEngineSelected = $event"
                    />
                    <General v-else-if="selectedType === 'General'" :configuration="configuration" :flowTargets="flowsTargets" @openOverlay="onOpenOverlay()" />
                    <Errors
                      v-else-if="selectedType === 'Error'"
                      :configuration="configuration"
                      :flowTargets="flowsTargets"
                      :humanAgentsQueues="humanAgentsQueues"
                      :humanAgentsProfiles="humanAgentsProfiles"
                      :humanAgentsAgents="humanAgentsAgents"
                      @openOverlay="onOpenOverlay()"
                    />
                    <Inactivity
                      v-else-if="selectedType === 'Inactivity'"
                      :configuration="configuration"
                      :flowTargets="flowsTargets"
                      @openOverlay="onOpenOverlay()"
                    />
                    <InputProcessorsConfiguration
                      v-else-if="selectedType === 'Input Processor'"
                      :configuration="configuration"
                      :inputProcessors="inputProcessor"
                    />
                    <SemanticHooks
                      v-else-if="selectedType === 'Semantic Hook'"
                      :configuration="configuration"
                      :semanticEngines="semanticEngines"
                      :flowTargets="flowsTargets"
                      :allBots="bots"
                      @openOverlay="onOpenOverlay()"
                    />
                    <ConditionalJump v-if="selectedType === 'Conditional Jump'" :configuration="configuration" :flowTargets="flowsTargets" />
                    <MenuConfigurations v-if="selectedType === 'Menu Node Configurations'" :configuration="configuration" />
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-btn :class="getButtonMargin" color="error" @click="stepper = 1">
                  <v-icon left>
                    mdi-chevron-left
                  </v-icon>Back
                </v-btn>
                <v-spacer />
                <v-btn :class="getButtonMargin" color="primary" :disabled="isSecondStepDisabled() || !isValid" @click="stepper = 3">
                  Next
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" elevation="0" :height="cardStepperHeight - 50">
                <SearchBar class="mr-12 mt-0" :nomargin="true" :searchString="searchString" @input="searchString = $event" />
                <perfect-scrollbar :options="$store.getters.getDefaultScrollProps" :style="'height:' + (cardStepperHeight - 80) + 'px'">
                  <v-row v-for="(bot, botId) in getBots" :key="botId">
                    <v-col v-if="getFlowExist(botId)" cols="12" class="py-0">
                      <v-tooltip left>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="checkboxBots[botId]"
                            class="ml-4"
                            color="primary"
                            :label="bot.name"
                            v-on="on"
                            @change="$event ? addAllFlowsOfBot(bot) : removeSelectedFlowsOfBot(bot)"
                          />
                        </template>
                        <span>Select/Deselect all {{ bot.name }} flows</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" class="py-0 mt-n4">
                      <v-chip-group v-model="flows" multiple column active-class="primary">
                        <v-tooltip v-for="flowObj in getFlows(botId)" :key="flowObj.flow + '_' + flowObj.version" bottom>
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-chip v-show="chipShouldBeDisplayed(flowObj)" :value="flowObj" class="ma-2" filter label>
                                {{ flowObj.flow }}
                              </v-chip>
                            </div>
                          </template>
                          <span>Version: {{ flowObj.version }}</span>
                        </v-tooltip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </perfect-scrollbar>
              </v-card>
              <v-card-actions>
                <v-btn :class="getButtonMargin" color="error" @click="stepper = 2">
                  <v-icon left>
                    mdi-chevron-left
                  </v-icon>Back
                </v-btn>
                <v-spacer />
                <v-btn color="primary" :class="getButtonMargin" :disabled="flows.length === 0" @click="stepper = 4">
                  Next
                  <v-icon right>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card elevation="0" class="mb-12" :height="cardStepperHeight - 50">
                <v-card-subtitle class="overline text-left">
                  Are you sure?
                </v-card-subtitle>
                <v-row justify="center" align="center" style="min-height: 400px">
                  <v-alert
                    text
                    type="info"
                    color="primary"
                    outlined
                    border="right"
                    max-height="80"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle v-if="!selectedType.includes('Hooks')" class="headline overline bigger-text primary--text">
                          You will propagate the
                          {{ selectedType }} section's configuration to {{ Object.keys(flows).length }} flow
                          <span v-if="Object.keys(flows).length > 1">s</span>.
                          <br>
                          <b>All the existing options will be overwritten.</b>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else class="headline overline bigger-text primary--text">
                          <span v-if="selectedType.includes('Add')">
                            You will propagate
                            {{ semanticHookList.length }} semantic hook
                            <span v-if="semanticHookList.length > 1">s</span>
                            to {{ Object.keys(flows).length }} flow <span v-if="Object.keys(flows).length > 1">s</span>. <br>The new semantic hook
                            <span v-if="semanticHookList.length > 1">s</span>
                            will be added as last elements of the list or replaced if the intent already exist
                          </span>
                          <span v-else>
                            You will remove
                            {{ semanticHookList.length }} semantic hook
                            <span v-if="semanticHookList.length > 1">s</span>
                            from {{ Object.keys(flows).length }} flow <span v-if="Object.keys(flows).length > 1">s</span>.
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-alert>
                </v-row>
              </v-card>

              <v-card-actions>
                <v-btn :class="getButtonMargin" color="error" @click="stepper = 3">
                  <v-icon left>
                    mdi-chevron-left
                  </v-icon>Back
                </v-btn>
                <v-btn color="success" :class="getButtonMargin" @click="showConfirm = true">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>Propagate
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import ConfigureSemanticHooks from "../../components/tools/propagate/ConfigureSemanticHooks";
import SearchBar from "../../components/other/SearchBar";
import General from "../../components/design/FlowConfigurations/General";
import Errors from "../../components/design/FlowConfigurations/Errors";
import Inactivity from "../../components/design/FlowConfigurations/Inactivity";
import InputProcessorsConfiguration from "../../components/design/FlowConfigurations/InputProcessorsConfiguration";
import ConditionalJump from "../../components/design/FlowConfigurations/ConditionalJump";
import SemanticHooks from "../../components/design/FlowConfigurations/SemanticHooksConfiguration";
import MenuConfigurations from "../../components/design/FlowConfigurations/MenuConfigurations";
import sanitizeConfiguration from "../../helpers/sanitizeConfiguration";
import merge from "deepmerge";
import EventBus from "../../event-bus";

export default {
  name: "Propagate",
  components: {
    ConfirmDialog,
    ResultSnackbar,
    ConfigureSemanticHooks,
    SearchBar,
    General,
    Errors,
    Inactivity,
    InputProcessorsConfiguration,
    SemanticHooks,
    ConditionalJump,
    MenuConfigurations,
  },
  data() {
    return {
      showWarning: false,
      isValid: false,
      deleteMessage: "This action will modify each selected flows with the new configurations",
      showConfirm: false,

      searchString: "",

      stepper: 1,
      stepperHeight: 580,
      cardStepperHeight: 580,

      typesOfPropagate: [
        "Add Semantic Hooks",
        "Conditional Jump",
        "Error",
        "General",
        "Inactivity",
        "Input Processor",
        "Menu Node Configurations",
        "Remove Semantic Hooks",
        "Semantic Hook",
      ],
      selectedType: "General",
      semanticEngineSelected: "",
      flowsTargets: {},
      semanticEngines: {},
      bots: {},
      checkboxBots: [],
      flows: [],
      inputProcessor: {},
      flowsSemanticHooks: {},
      semanticHookList: [],
      configuration: merge({}, this.$store.getters.getDefaultFlowConfiguration),
      humanAgentsQueues: [],
      humanAgentsProfiles: [],
      humanAgentsAgents: [],
    };
  },
  computed: {
    ...spacing,
    getBots() {
      return this.getSortedBots();
    },
  },
  async mounted() {
    try {
      const flowsTargetsResponse = await this.$http.get("/flows-targets-hooks");
      const botsResponse = await this.$http.get("/bot");
      const semanticEnginesResponse = await this.$http.get("/semantic-engine");
      const flowConfiguration = await this.$http.get("/flow-configurations");
      const inputProcessor = await this.$http.get("/input-processor");
      const unassignedFlows = await this.$http.get("/flow/list/unassigned");
      const humanAgentsQueueResponse = await this.$http.get("/human-agents/queue");
      const humanAgentsProfileResponse = await this.$http.get("/human-agents/profile");
      const humanAgentsAgentResponse = await this.$http.get("/human-agents/agent");
      this.bots = botsResponse.data;
      this.flowsTargets = flowsTargetsResponse.data;
      this.semanticEngines = semanticEnginesResponse.data;
      this.inputProcessor = inputProcessor.data;
      this.humanAgentsQueues = humanAgentsQueueResponse.data;
      this.humanAgentsProfiles = humanAgentsProfileResponse.data;
      this.humanAgentsAgents = humanAgentsAgentResponse.data;

      if (Object.keys(this.flowsTargets).length === 0) {
        this.showWarning = true;
      }
      if (flowConfiguration.data) {
        let sanitizedConf = sanitizeConfiguration.sanitizeMultilanguageConfig(flowConfiguration.data);
        //effettuo la sanificazione per le conf che non hanno il post close conversation
        sanitizedConf = sanitizeConfiguration.sanitizePostCloseConversation(flowConfiguration.data);
        //effettuo sanificazione survey in inactivity perchè inglobata dallo stay idle
        sanitizedConf = sanitizeConfiguration.sanitizeInactivityProactivityType(flowConfiguration.data);
        this.configuration = merge(this.configuration, sanitizedConf, {
          arrayMerge: this.mergeMultilanguageField,
        }); //Oggettone completo delle conf

        //sanifico la care window, per forza dopo aver mergiato le conf arrivate dal backend con quelle di default
        this.configuration = sanitizeConfiguration.sanitizeInactivityCareWindow(this.configuration);

        if (!this.configuration.error.jump_to) {
          this.configuration.error.jump_to = {
            flow: "",
            version: "",
            hook: "begin",
          };
        }
      }
      if (unassignedFlows.data) {
        if (Object.keys(unassignedFlows.data.flows).length > 0) {
          this.bots["0"] = {
            name: "Unassigned Flows",
            flows: [],
          };
          for (let flowName in unassignedFlows.data.flows) {
            for (let flowVersion in unassignedFlows.data.flows[flowName]) {
              this.bots["0"].flows.push({
                flow: flowName,
                version: flowVersion,
                generated_by: unassignedFlows.data.flows[flowName][flowVersion].generated_by,
              });
            }
          }
        }
      }
    } catch (e) {
      this.$refs.resultSnackbar.showError("Fail to load data!");
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    onOpenOverlay() {
      EventBus.$emit(this.$store.getters.getEvents.SCROLLBAR_TO_TOP);
    },
    mergeMultilanguageField(target, source) {
      return source;
    },
    resetPage() {
      this.semanticHookList = [];
      this.selectedType = "General";
      this.semanticEngineSelected = "";
      this.flows = [];
      this.stepper = 1;
      this.checkboxBots = [];
    },
    getMessage() {
      let msg = "";
      switch (this.selectedType) {
        case "Conditional Jump":
          msg = "With this option you can can add Conditional Jump to one or more flows";
          break;
        case "Menu Node Configurations":
          msg = "With this option you can change the values of the 'Menu Node Configurations' section of one or more flows";
          break;
        case "Error":
          msg = "With this option you can change the values of the 'Error' section configurations of one or more flows";
          break;
        case "Inactivity":
          msg = "With this option you can change the values of the 'Inactivity' section configurations of one or more flows";
          break;
        case "Input Processor":
          msg = "With this option you can change the values of the default Input Processor of one or more flows";
          break;
        case "Semantic Hook":
          msg = "With this option you can override ALL the Semantic Hooks of one or more flows";
          break;
        case "Add Semantic Hooks":
          msg =
            "With this option you can add one or more Semantic Hooks to one or more flows. The new Semantic Hooks will be added as last elements of the list if not present. If the intent is already in the Semantic Hook list, it will be replaced";
          break;
        case "Remove Semantic Hooks":
          msg = "With this option you can remove one or more Semantic Hooks to one or more flows";
          break;
        case "General":
        default:
          msg = "With this option you can change the values of the 'General' section configurations of one or more flows";
          break;
      }
      return msg;
    },
    isSecondStepDisabled() {
      if (this.selectedType === "Add Semantic Hooks" || this.selectedType === "Remove Semantic Hooks") {
        return this.semanticHookList.length === 0;
      }
      return false;
    },
    chipShouldBeDisplayed(flowObj) {
      return !this.searchString || flowObj.flow.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1;
    },
    getSecondStepperTitle() {
      if (this.selectedType === "Add Semantic Hooks") {
        return "Configure which semantic hook should be added";
      }
      if (this.selectedType === "Remove Semantic Hooks") {
        return "Configure which semantic hook should be removed";
      }
      return "Modify the configuration";
    },
    addAllFlowsOfBot(bot) {
      bot.flows.forEach((flowObj) => {
        let alreadyIn = this.flows.some((item) => flowObj.flow === item.flow && flowObj.version === item.version);
        if (!alreadyIn) {
          if (this.selectedType !== "Conditional Jump" || (this.selectedType === "Conditional Jump" && flowObj.generated_by !== "teacher")) {
            this.flows.push(flowObj);
          }
        }
      });
    },
    removeSelectedFlowsOfBot(bot) {
      this.flows = this.flows.filter((item) => !bot.flows.some((other) => item.flow === other.flow && item.version === other.version));
    },
    async onPropagateConfirm() {
      this.showConfirm = false;
      let config = {};
      switch (this.selectedType) {
        case "Add Semantic Hooks":
          config = {
            add_semantic_hooks: {
              semanticHookEngineName: this.semanticEngineSelected,
              semanticHookList: this.semanticHookList,
            },
          };
          break;
        case "Remove Semantic Hooks":
          config = {
            remove_semantic_hooks: {
              semanticHookEngineName: this.semanticEngineSelected,
              semanticHookList: this.semanticHookList,
            },
          };
          break;
        default:
          config = this.configuration;
      }
      try {
        await this.$http.post("/propagate", {
          configuration: config,
          propagateParams: {
            flows: this.flows,
            sections: [this.selectedType],
          },
        });
        this.$refs.resultSnackbar.showSuccess("Configuration propagated to the selected flows!");
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to propagate the configuration!");
      } finally {
        this.resetPage();
      }
    },
    getSortedBots() {
      if (this.bots) {
        //ordino i flussi
        for (let bot in this.bots) {
          this.bots[bot].flows = this.bots[bot].flows.sort((a, b) => {
            if (a.flow.toLowerCase() > b.flow.toLowerCase()) {
              return 1;
            }
            if (a.flow.toLowerCase() < b.flow.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
        //ordino i bot
        this.bots = Object.values(this.bots).sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase() || a.name == "Unassigned Flows" || b.name == "Unassigned Flows") {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      }
      return this.bots;
    },
    getFlows(botId) {
      if (this.selectedType === "Conditional Jump" || this.selectedType === "Menu Node Configurations") {
        let flowList = this.bots[botId].flows;
        return flowList.filter(function (el) {
          return el.generated_by != "teacher";
        });
      } else {
        return this.bots[botId].flows;
      }
    },
    getFlowExist(botId) {
      let list = this.getFlows(botId);
      if (list.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
/* Risolve il bug di posizionamento del text-field outlined dal secondo in poi */
.v-text-field--outlined.v-input--dense .v-label--active {
  left: -28px !important;
}

.propagatePage .overlayMultilanguage div.v-overlay__content {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
}

.propagatePage .overlayMultilanguage div.v-overlay__content > .v-card {
  height: 100%;
}

.propagatePage .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 65%;
}

.propagatePage .inactivityCard .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 55% !important;
}

.propagatePage .semanticHookCard .overlayMultilanguage div.v-overlay__content .v-card.listOfMessages {
  height: 65% !important;
}

.propagatePage .overlayMultilanguage div.v-overlay__content .listOfMessagesScrollBar {
  height: 90%;
}
</style>
