<template>
  <v-container fluid style="height: 100%">
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar :searchString="searchString" title="Create your bots" @input="updateSearchString($event)" />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="getBotList.length < 2 && !newBotType && loaded && searchString == ''" cols="12">
        <v-alert type="warning">
          No Bots configured
        </v-alert>
      </v-col>
      <v-col v-else-if="getBotList.length < 2 && !newBotType && loaded && searchString != ''" cols="12">
        <v-alert type="warning">
          No Bot match your search
        </v-alert>
      </v-col>
      <!-- riga per la creazione di un nuovo elemento -->
      <v-expansion-panels v-if="newBotType" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="4" sm="2" md="1" class="mr-3">
                <v-avatar :size="40" style="width: auto" tile>
                  <img style="height: -webkit-fill-available" alt="Bot Avatar" :src="$store.getters.getBotType(newBotType.type)?.image">
                </v-avatar>
              </v-col>
              <v-col cols="8">
                New {{ newBotType.label }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <NewBotForm :botsList="bots" :newBotType="newBotType" :unassignedFlows="unassignedFlows" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels
        :key="forceRerender + '_ep'"
        v-model="expansionPanel"
        :disabled="!loaded"
        popout
        :class="[getMainExpansionPanelMargin, !loaded ? 'disableHeader' : '']"
      >
        <v-expansion-panel v-for="(bot, id) in getBotList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <!-- avatar -->
                <v-col cols="1">
                  <v-avatar v-if="bot.icon" size="56px" style="border-radius: 50% !important" class="botAvatar elevation-2" tile>
                    <img alt="Bot Avatar" :src="$store.getters.getSystemBotIcon(bot.icon, 'mini', bot.isVisualIvr)">
                  </v-avatar>
                </v-col>
                <!-- nome -->
                <v-col class="text-truncate" cols="4" lg="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{ bot.name }}</v-list-item-title>
                      <v-list-item-subtitle v-if="bot.bot_id !== '0'">
                        Template:
                        <i>{{ bot.template ? bot.template : "Custom" }}</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- type -->
                <v-col class="hidden-md-and-down text-no-wrap" cols="2" align="center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                      <v-list-item-title v-if="bot.bot_id !== '0'">
                        {{ bot.isVisualIvr ? "VisualIVR" : bot.isOutbound ? "Outbound Bot" : "Traditional Bot" }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        Flow without bot
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- # flows -->
                <v-col class="hidden-md-and-down" cols="1" align="center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle># Flows</v-list-item-subtitle>
                      <v-list-item-title v-if="bot.flows.length > 0">
                        {{ bot.flows.length }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        Empty bot!
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- publish -->
                <v-col class="hidden-xs" cols="5" lg="4" align="center">
                  <v-list-item v-if="bot.bot_id !== '0'" dense>
                    <v-list-item-content v-if="bot.publish && Object.keys(bot.publish).length > 0" class="pa-0">
                      <v-list-item-subtitle>Published on:</v-list-item-subtitle>
                      <div class="mt-2" style="display: inline-flex; justify-content: center">
                        <template v-for="(count, type) in bot.publish">
                          <v-badge :key="type" color="success" overlap right class="mr-2">
                            <template #badge>
                              <span>{{ count }}</span>
                            </template>
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-icon v-if="type === 'webapi'" large color="primary" v-on="on">
                                  mdi-earth
                                </v-icon>
                                <v-icon v-else-if="type === 'facebookmessenger'" large color="primary" v-on="on">
                                  mdi-facebook-messenger
                                </v-icon>
                                <v-icon v-else-if="type === 'telegram'" large color="primary" v-on="on">
                                  mdi-telegram
                                </v-icon>
                                <v-icon v-else-if="type === 'googlebusinessmessages'" large color="primary" v-on="on">
                                  mdi-google
                                </v-icon>
                                <!--                                <v-icon large v-on="on" v-else-if="type === 'webexmeeting'" color="primary">mdi-cisco-webex</v-icon>-->
                                <!--                                <v-img-->
                                <!--                                  width="36"-->
                                <!--                                  height="36"-->
                                <!--                                  v-else-if="type === 'webexmeeting'"-->
                                <!--                                  :src="require('../../assets/img/connectors/webexteams_primary.png')"-->
                                <!--                                  v-on="on"-->
                                <!--                                />-->
                                <v-icon v-else-if="type === 'googlehome'" large color="primary" v-on="on">
                                  mdi-google-assistant
                                </v-icon>
                                <v-icon v-else-if="type === 'amazonalexa'" large color="primary" v-on="on">
                                  mdi-amazon-alexa
                                </v-icon>
                                <v-icon v-else-if="type === 'whatsapp'" large color="primary" v-on="on">
                                  mdi-whatsapp
                                </v-icon>
                                <!--                                <v-icon large v-on="on" v-else-if="type === 'applebusinesschat'" color="primary">mdi-apple</v-icon>-->
                                <v-icon v-else-if="type === 'twitter'" large color="primary" v-on="on">
                                  mdi-twitter
                                </v-icon>
                                <v-icon v-else-if="type === 'email'" large color="primary" v-on="on">
                                  mdi-email
                                </v-icon>
                              </template>
                              <span>
                                {{ $store.getters.getConnectorLabel(type) }}
                              </span>
                            </v-tooltip>
                          </v-badge>
                        </template>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else-if="!bot.flow || !bot.version">
                      <v-list-item-title class="subtitle-1 warning">
                        This bot has no initial flow configured!
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title class="subtitle-1">
                        Not yet published!
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- date -->
                <v-col class="hidden-sm-and-down" cols="2" align="center">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Updated</v-list-item-subtitle>
                      <v-list-item-title v-if="bot.updated_at">
                        {{ bot.updated_at | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{ { flowsList, bot } | formatVerboseDateUnassignedFlow | formatVerboseDateTime }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <BotForm :key="forceRerender + id + '_bf'" :bot="bot" :flowsTargetsBot="flowsTargetsBot" :bots="bots" :flowList="flowsList" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div v-if="!loaded" class="loadinganimCon">
      <div class="loadingContent">
        <div class="chipRoundedBG">
          <img style="height: 60px; margin: auto; margin-top: 9px" :src="require('../../assets/img/botsNew/loadingCon.gif')">
        </div>
        <div class="mt-2">
          wait loading bots...
        </div>
      </div>
    </div>

    <AddNewItemButton v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" :disabled="!loaded" :ripple="false" name="Bot" @addNewItem="showCreationMenu" />
    <v-menu
      v-model="addMenuProps.showMenu"
      min-width="260"
      :position-x="addMenuProps.x"
      :position-y="addMenuProps.y"
      absolute
      offset-y
    >
      <v-list :disabled="checknewBot()">
        <v-list-item v-for="(botType, index) in $store.getters.getBotTypes" :key="index" @click="addBot(botType)">
          <div v-if="index > 0" class="separator" />
          <v-list-item-avatar :class="checknewBot() ? 'disableLine' : ''" class="ml-3 mr-5" tile size="48px" style="width: auto">
            <img style="height: -webkit-fill-available" alt="Avatar" :src="botType.image">
          </v-list-item-avatar>
          <v-list-item-content :class="checknewBot() ? 'disableLine' : ''">
            <v-list-item-title style="font-weight: bold; font-size: 14px">
              {{ botType.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import BotForm from "../../components/design/BotForm";
import EventBus from "../../event-bus";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import NewBotForm from "../../components/design/NewBotForm";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "Bots",
  components: {
    BotForm,
    ResultSnackbar,
    AddNewItemButton,
    NewBotForm,
    SearchBar,
  },
  data() {
    return {
      loaded: false,
      addMenuProps: {
        showMenu: false,
        x: 0,
        y: 0,
      },
      bots: {},
      unassignedFlows: {},
      flowsList: {},
      expansionPanel: this.$route.query["q"] ? 1 : null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      searchString: this.$route.query["q"] || "",
      flowsTargetsBot: null,
      newBotType: "",
    };
  },
  computed: {
    ...spacing,
    getBotList() {
      this.forceRerender;
      if (this.bots) {
        let result = Object.values(this.bots)
          //Filtro per la ricerca
          .filter((bot) => bot.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        result.unshift({
          bot_id: "0",
          flows: this.getUnassignedFlowsList(),
          icon: "System_123EuD45_Unassigned",
          name: "Unassigned Flows",
          publish: null,
          restartNode: null,
        });
        return result;
      }
      return [];
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.CREATE_BOT, this.onBotCreated);
    EventBus.$on(this.$store.getters.getEvents.EDIT_BOT, this.onBotUpdate);
    EventBus.$on(this.$store.getters.getEvents.DELETE_BOT, this.deleteBot);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_BOT_CREATION, this.cancelBotCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_BOT_FAIL, this.deleteBotFail);
    EventBus.$on(this.$store.getters.getEvents.EDIT_BOT_FAIL, this.onBotFailedUpdate);
    EventBus.$on(this.$store.getters.getEvents.MOVE_FLOW, this.onMoveFlow);
    EventBus.$on(this.$store.getters.getEvents.MOVE_FLOW_FAILED, this.onActionFailed);
    EventBus.$on(this.$store.getters.getEvents.DELETE_FLOW, this.onDeleteFlow);
    EventBus.$on(this.$store.getters.getEvents.DELETE_FLOW_FAILED, this.onActionFailed);
    EventBus.$on(this.$store.getters.getEvents.CLONE_FLOW, this.onCloneFlow);
    EventBus.$on(this.$store.getters.getEvents.CLONE_FLOW_FAILED, this.onActionFailed);
    EventBus.$on(this.$store.getters.getEvents.TEST_FLOW_FAILED, this.onActionFailed);
    EventBus.$on(this.$store.getters.getEvents.FLOW_ALREADY_EXISTS, this.onActionFailed);
    await this.loadData();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.CREATE_BOT, this.onBotCreated);
    EventBus.$off(this.$store.getters.getEvents.EDIT_BOT, this.onBotUpdate);
    EventBus.$off(this.$store.getters.getEvents.DELETE_BOT, this.deleteBot);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_BOT_CREATION, this.cancelBotCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_BOT_FAIL, this.deleteBotFail);
    EventBus.$off(this.$store.getters.getEvents.EDIT_BOT_FAIL, this.onBotFailedUpdate);
    EventBus.$off(this.$store.getters.getEvents.MOVE_FLOW, this.onMoveFlow);
    EventBus.$off(this.$store.getters.getEvents.MOVE_FLOW_FAILED, this.onActionFailed);
    EventBus.$off(this.$store.getters.getEvents.DELETE_FLOW, this.onDeleteFlow);
    EventBus.$off(this.$store.getters.getEvents.DELETE_FLOW_FAILED, this.onActionFailed);
    EventBus.$off(this.$store.getters.getEvents.CLONE_FLOW, this.onCloneFlow);
    EventBus.$off(this.$store.getters.getEvents.CLONE_FLOW_FAILED, this.onActionFailed);
    EventBus.$off(this.$store.getters.getEvents.TEST_FLOW_FAILED, this.onActionFailed);
    EventBus.$off(this.$store.getters.getEvents.FLOW_ALREADY_EXISTS, this.onActionFailed);
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,
    checknewBot() {
      if (typeof this.newBotType === "string") {
        return false;
      } else {
        return this.newBotType ? typeof this.newBotType === "object" : false;
      }
    },
    updateSearchString(evt) {
      this.searchString = evt;
      this.forceRerender++;
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    showCreationMenu(e) {
      e.preventDefault();
      this.addMenuProps.showMenu = false;
      this.addMenuProps.x = e.clientX;
      this.addMenuProps.y = e.clientY;
      this.$nextTick(() => {
        this.addMenuProps.showMenu = true;
      });
    },
    async onMoveFlow() {
      await this.loadData();
      this.$refs.resultSnackbar.showSuccess("Flow moved!");
      this.forceRerender++;
    },
    onCloneFlow() {
      this.$refs.resultSnackbar.showSuccess("Flow cloned!");
      //Con l'aggiunta del rerender alla key dell'expansion panel si e' reso necessario il delay
      setTimeout(() => {
        this.forceRender++;
      }, 100);
    },
    onDeleteFlow() {
      this.$refs.resultSnackbar.showSuccess("Flow deleted!");
      //Con l'aggiunta del rerender alla key dell'expansion panel si e' reso necessario il delay
      setTimeout(() => {
        this.forceRender++;
      }, 100);
    },
    onActionFailed(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    addBot(botType) {
      if (!this.newBotType) {
        this.newBotType = botType;
        this.expansionPanel = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0));
    },
    deleteBot(obj) {
      if (this.bots[obj.bot_id]) {
        delete this.bots[obj.bot_id];
        this.$refs.resultSnackbar.showSuccess("Bot " + obj.name + " deleted!");
        this.closeAllPanels();
        this.forceRerender++;
      }
    },
    async onBotCreated(bot) {
      await this.loadData();
      if (this.$refs.resultSnackbar) {
        //se sto aprendo il flowdesigner non esiste più
        this.$refs.resultSnackbar.showSuccess("Bot " + bot.name + " created!");
      }
      this.closeAllPanels();
      this.forceRerender++;
    },
    async onBotUpdate(obj) {
      await this.loadData();
      this.$refs.resultSnackbar.showSuccess("Bot " + obj.name + " updated!");
      this.forceRerender++;
    },
    deleteBotFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    onBotFailedUpdate(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelBotCreation();
      this.expansionPanel = null;
    },
    cancelBotCreation() {
      this.expansionPanelCreation = null;
      this.newBotType = null;
    },
    getUnassignedFlowsList() {
      let result = [];
      for (let flowName in this.unassignedFlows.flows) {
        let versions = Object.keys(this.unassignedFlows.flows[flowName]);
        versions.forEach((version) => {
          result.push({
            flow: flowName,
            version: version,
          });
        });
      }
      return result;
    },
    async loadData() {
      try {
        const botResponse = await this.$http.get("/bot-all");
        const flowTargetsResponse = await this.$http.get("/flows-targets-hooks");
        const unassignedFlowsResponse = await this.$http.get("/flow/list/unassigned");
        const flowTargets = {};
        for (const flow in flowTargetsResponse.data) {
          const versions = flowTargetsResponse.data[flow];
          for (const version in versions) {
            const bot_id = versions[version].bot_id;
            const hooks = versions[version].hooks;
            if (!flowTargets[bot_id]) flowTargets[bot_id] = [];
            flowTargets[bot_id].push({
              flow: flow,
              version: version,
              hook: hooks,
            });
          }
        }
        this.flowsTargetsBot = flowTargets;
        this.unassignedFlows = unassignedFlowsResponse.data;
        this.flowsList = flowTargetsResponse.data;
        this.bots = botResponse.data;
        for (let botId in this.bots) {
          let bot = this.bots[botId];
          if (!bot.restartNode) {
            bot.restartNode = {
              flow: bot.flow,
              version: bot.version,
              hook: "begin",
            };
          }
          this.bots[botId] = bot;
        }
      } finally {
        this.loaded = true;
        EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
        if (this.$route.query["q"]) {
          let container = document.querySelector("#mainScrollBar");
          if (container) container.scrollTop = 200;
        }
      }
    },
  },
};
</script>
<style scoped>
.disableLine {
  opacity: 0.5;
}
.separator {
  width: 100%;
  position: absolute;
  background: antiquewhite;
  height: 1px;
  left: 0;
  top: 0;
}
</style>
<style>
.theme--dark .botAvatar.elevation-2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.15), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.15) !important;
}
.loadinganimCon {
  height: 100%;
  align-items: center;
  display: grid;
  position: relative;
  top: -50px;
  text-align: center;
}
.disableHeader {
  opacity: 0.5;
}
.theme--dark .loadingContent {
  color: #9ca7ac;
}
.chipRoundedBG {
  width: 80px;
  margin: auto;
  background: #293d52ff;
  border-radius: 50%;
  height: 80px;
}
</style>
