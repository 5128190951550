<template>
  <v-container
    fluid
    :class="getCreationContainerMargin"
    class="mb-12"
  >
    <ResultSnackbar ref="resultSnackbar" />
    <ConfirmDialog
      :show-confirm="showConfirm"
      :message="deleteMessage"
      @dialogDeny="showConfirm = false"
      @dialogConfirm="onDeleteConfigConfirm"
    />

    <v-sheet
      class="mt-5 mx-auto addBorder"
      elevation="6"
      color="sliderKeyboard"
      max-width="1400"
    >
      <v-slide-group
        ref="slider"
        v-model="model"
        class="pa-4"
        show-arrows
        center-active
      >
        <v-slide-item
          v-for="(keyboard, index) in keyboards"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-container>
            <v-card
              height="507"
              width="300"
              class="mainCard"
              :elevation="active ? 20 : 0"
            >
              <v-card-title>{{ keyboard.value.label }}</v-card-title>
              <v-container>
                <v-img
                  style="cursor: pointer"
                  :src="require('../../assets/img/keyboards/' + keyboard.value.type.toLowerCase() + '.png')"
                  width="280"
                  @click="toggle"
                />
              </v-container>
            </v-card>
          </v-container>
        </v-slide-item>
      </v-slide-group>

      <!-- Expansion panel con i dettagli delle keyboard -->
      <v-expand-transition>
        <v-sheet
          v-if="model != null"
          color="mainCard"
          class="mb-10 px-5"
          tile
        >
          <v-container>
            <div class="title">
              <span v-if="keyboardSelected.value.type == 'custom' && !keyboardSelected.value.creation">
                {{ descriptionMessage.customEdit }}
              </span>

              <span v-if="keyboardSelected.value.type == 'date'">
                {{
                  formatDateSelected ? descriptionMessage[keyboardSelected.value.type] + formatDateSelected : descriptionMessage[keyboardSelected.value.type]
                }}
              </span>

              <span v-else>
                {{ descriptionMessage[keyboardSelected.value.type] }}
              </span>
            </div>
            <div
              v-if="!keyboardSelected.value.custom && !(keyboardSelected.value.type == 'geolocation') && !(keyboardSelected.value.type == 'date')"
              class="caption"
            >
              A default keyboard can not be edited or canceled
            </div>
            <v-row align="center">
              <v-col cols="12">
                <span class="text-center mr-5">Available on:</span>
                <div style="display: inline-flex; justify-content: center">
                  <v-tooltip
                    v-if="keyboardSelected.value.channels.includes('webapi')"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="justify-center"
                        large
                        color="primary"
                        v-on="on"
                      >
                        mdi-earth
                      </v-icon>
                    </template>
                    <span>WebAPI</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="
                      keyboardSelected.value.type == 'text_input' ||
                        keyboardSelected.value.type == 'survey_10_buttons' ||
                        keyboardSelected.value.type == 'geolocation'
                    "
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-icon
                        large
                        color="primary"
                        v-on="on"
                      >
                        mdi-facebook-messenger
                      </v-icon>
                    </template>
                    <span>Facebook Messenger</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="keyboardSelected.value.channels.includes('telegram')"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-icon
                        large
                        color="primary"
                        v-on="on"
                      >
                        mdi-telegram
                      </v-icon>
                    </template>
                    <span>Telegram</span>
                  </v-tooltip>
                  <!--                  <v-tooltip bottom v-if="keyboardSelected.value.channels.includes('webexmeeting')">-->
                  <!--                    <template v-slot:activator="{ on }">-->
                  <!--                      <v-img-->
                  <!--                        v-if="!$vuetify.theme.dark"-->
                  <!--                        v-on="on"-->
                  <!--                        width="36"-->
                  <!--                        height="36"-->
                  <!--                        :src="require('../../assets/img/connectors/webexteams_primary.png')"-->
                  <!--                      />-->
                  <!--                      <v-img v-else v-on="on" width="36" height="36" :src="require('../../assets/img/connectors/webexteams_primary_dark.png')" />-->
                  <!--                    </template>-->
                  <!--                    <span>Webex Team</span>-->
                  <!--                  </v-tooltip>-->
                  <v-tooltip
                    v-if="
                      keyboardSelected.value.channels.includes('whatsapp') ||
                        keyboardSelected.value.type == 'text_input' ||
                        keyboardSelected.value.type == 'geolocation'
                    "
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-icon
                        large
                        color="primary"
                        v-on="on"
                      >
                        mdi-whatsapp
                      </v-icon>
                    </template>
                    <span>WhatsApp</span>
                  </v-tooltip>
                  <!--                  <v-tooltip-->
                  <!--                    bottom-->
                  <!--                    v-if="-->
                  <!--                      keyboardSelected.value.channels.includes('apple') ||-->
                  <!--                      keyboardSelected.value.type == 'text_input' ||-->
                  <!--                      keyboardSelected.value.type == 'survey_10_buttons'-->
                  <!--                    "-->
                  <!--                  >-->
                  <!--                    <template v-slot:activator="{ on }">-->
                  <!--                      <v-icon v-on="on" large color="primary">mdi-apple</v-icon>-->
                  <!--                    </template>-->
                  <!--                    <span>Apple Business Chat</span>-->
                  <!--                  </v-tooltip>-->
                  <v-tooltip
                    v-if="keyboardSelected.value.channels.includes('googlebusinessmessages') || keyboardSelected.value.type == 'text_input'"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-icon
                        large
                        color="primary"
                        v-on="on"
                      >
                        mdi-google
                      </v-icon>
                    </template>
                    <span>Google Business Messages</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-form
              ref="keyboardForm"
              v-model="form.isValid"
            >
              <v-row v-if="keyboardSelected.value.type == 'date'">
                <v-col>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        v-model="formatDateSelected"
                        :items="formatDate"
                        :rules="[requiredField]"
                        label="Select a date format"
                        prepend-inner-icon="mdi-calendar-range"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="keyboardSelected.value.type == 'geolocation'">
                <v-col cols="4">
                  <v-text-field
                    :placeholder="keyboardSelected.value.label"
                    disabled
                    prepend-inner-icon="mdi-keyboard"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.trim="keyboardSelected.value.messages.shareLocation"
                    :rules="[requiredField]"
                    hint="Share Location Button Message"
                    prepend-inner-icon="mdi-keyboard"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model.trim="keyboardSelected.value.messages.cancelLocation"
                    :rules="[requiredField]"
                    hint="Deny Share Location Message"
                    prepend-inner-icon="mdi-keyboard"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
              </v-row>
              <v-row v-if="keyboardSelected.value.custom && !keyboardSelected.value.creation">
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="temporaryName"
                    :placeholder="keyboardSelected.value.label"
                    :hint="'Current name: ' + keyboardSelected.value.label"
                    prepend-inner-icon="mdi-keyboard"
                    :rules="[requiredField, simpleAlphaNumString, charsGreaterThanAndLessThan(temporaryName, 3, 255)]"
                    autofocus
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
              </v-row>
              <v-row v-if="keyboardSelected.value.creation">
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="temporaryName"
                    placeholder="New Keyboard Name"
                    :rules="[requiredField, simpleAlphaNumString, isKeyboardUniqueName(keyboards, temporaryName)]"
                    prepend-inner-icon="mdi-keyboard"
                    :autocomplete="$store.getters.disableAutocomplete"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="4"
                >
                  <v-btn
                    v-if="keyboardSelected.value.type == 'date'"
                    :class="getButtonMargin"
                    color="success"
                    :disabled="!form.isValid"
                    @click.prevent="saveDateFormat"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span>Update</span>
                  </v-btn>
                  <v-btn
                    v-if="keyboardSelected.value.custom || keyboardSelected.value.type == 'geolocation' ? true : false"
                    :class="getButtonMargin"
                    color="success"
                    :disabled="!form.isValid"
                    @click.prevent="createKeyboard"
                  >
                    <v-icon left>
                      mdi-floppy
                    </v-icon>
                    <span v-if="keyboardSelected.value.creation">Save</span>
                    <span v-else>Update</span>
                  </v-btn>
                  <v-btn
                    v-show="!keyboardSelected.value.creation"
                    v-if="keyboardSelected.value.custom ? true : false"
                    :class="getButtonMargin"
                    color="error"
                    @click.prevent="showConfirm = true"
                  >
                    <v-icon>mdi-delete</v-icon>
                    Delete
                  </v-btn>
                  <v-btn
                    v-show="keyboardSelected.value.creation"
                    :class="getButtonMargin"
                    color="error"
                    @click.prevent="cancelCreation"
                  >
                    <v-icon>mdi-close</v-icon>Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-sheet>
      </v-expand-transition>
    </v-sheet>
    <AddNewItemButton
      v-if="hasRequiredPermission('FullAccessKeyboards')"
      name="Keyboard"
      @addNewItem="addNewKeyboard"
    />
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";
import EventBus from "../../event-bus";

export default {
  name: "Keyboards",
  components: {
    ResultSnackbar,
    AddNewItemButton,
    ConfirmDialog,
  },
  data() {
    return {
      keyboards: [],
      model: null,
      showConfirm: false,
      formatDateSelected: "MM-DD-YYYY",
      formatDate: ["MM-DD-YYYY", "DD-MM-YYYY", "YYYY-MM-DD"],
      newCustomKeyboard: {
        channels: ["webapi"],
        custom: true,
        label: "New Keyboard",
        type: "custom",
        timestamp: 0,
        creation: true,
      },
      temporaryName: "",
      newKeyboardAdded: false, //per evitare di premere più volte il bottone +
      form: {
        isValid: false,
      },
      descriptionMessage: {
        text_input: "This is the default general text input keyboard",
        date: "The keyboard only admits date in format: ",
        datetime: "The keyboard shows date and time input like ' October 14, 2021 11:50 Am'",
        time: "The keyboard is designed for setting a time input HH:MM XM, based on 12 hour system",
        geolocation: "With this keyboard you can share your position",
        survey_10_buttons: "Use this keyboard for creating generic survey rating scales (from 10 to 0)",
        password: "This keyboard makes secret your input",
        text_input_disabled: "The keyboard does not allow text input, it must be configurated with options",
        custom: "Create a keyboard depending on your needs",
        customEdit: "Edit your custom keyboard",
      },
      deleteMessage: " Are you sure to delete this Keyboard?",
    };
  },
  computed: {
    ...spacing,
    keyboardSelected: function () {
      return this.keyboards[this.model];
    },
  },
  async mounted() {
    try {
      const result = await this.$http.get("/keyboard");
      let keyboards = result.data;
      for (let k in keyboards) {
        this.keyboards.push({
          name: k,
          value: keyboards[k],
        });
        if (keyboards[k].type == "date" && keyboards[k].format) {
          this.formatDateSelected = keyboards[k].format;
        }
      }
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    cancelCreation() {
      this.newKeyboardAdded = false;
      this.temporaryName = "";
      this.keyboards.pop();
      this.model = null;
    },
    addNewKeyboard() {
      if (!this.newKeyboardAdded) {
        this.keyboards.push({
          name: "keyboard_" + this.keyboards.length,
          value: this.newCustomKeyboard,
        });
        this.model = this.keyboards.length - 1;
        this.newKeyboardAdded = true;
        this.temporaryName = "";
      }
    },
    async createKeyboard() {
      if (this.form.isValid && this.keyboardSelected.value.creation) {
        this.newCustomKeyboard.label = this.temporaryName;
        this.keyboards[this.model].value = this.newCustomKeyboard;
        delete this.keyboards[this.model].value.creation;
        try {
          await this.$http.post("/keyboard", { keyboardName: this.keyboards[this.model].name, keyboard: this.keyboards[this.model].value });
          this.$refs.resultSnackbar.showSuccess("Keyboard " + this.keyboards[this.model].value.label + " Created!");
          this.newKeyboardAdded = false;
          this.model = null;
          this.$refs.keyboardForm.resetValidation();
        } catch (e) {
          this.$refs.resultSnackbar.showError("Unable to Update" + this.keyboards[this.model].value.label + " Keyboard");
          this.keyboards.pop();
        } finally {
          this.newCustomKeyboard = {
            channels: ["webapi"],
            custom: true,
            label: "New Keyboard",
            type: "custom",
            timestamp: 0,
            creation: true,
          };
          this.temporaryName = "";
        }
      } else {
        this.keyboards[this.model] = this.keyboardSelected;
        this.keyboards[this.model].value.label = this.temporaryName;
        try {
          await this.$http.post("/keyboard", { keyboardName: this.keyboards[this.model].name, keyboard: this.keyboards[this.model].value });
          this.$refs.resultSnackbar.showSuccess("Keyboard " + this.keyboards[this.model].value.label + " Updated!");
          this.model = null;
        } catch (e) {
          this.$refs.resultSnackbar.showError("Unable to Update" + this.keyboards[this.model].value.label + " Keyboard");
        }
      }
    },
    async onDeleteConfigConfirm() {
      if (this.keyboards[this.model].value.creation) {
        this.keyboards.pop();
        this.model -= 1;
        this.newKeyboardAdded = false;
        this.showConfirm = false;
      } else {
        this.showConfirm = false;
        try {
          await this.$http.delete("/keyboard/" + this.keyboards[this.model].name);
          this.$refs.resultSnackbar.showSuccess("Keyboard " + this.keyboards[this.model].value.label + " Deleted!");
          this.keyboards.splice(this.model, 1);
          this.model = null;
        } catch (e) {
          this.$refs.resultSnackbar.showError("Unable to delete " + this.keyboards[this.model].value.label + " Keyboard");
        }
      }
    },
    async saveDateFormat() {
      try {
        this.keyboards[this.model].value.format = this.formatDateSelected;
        await this.$http.post("keyboard", { keyboardName: this.keyboards[this.model].name, keyboard: this.keyboards[this.model].value });
        this.$refs.resultSnackbar.showSuccess("Keyboard " + this.keyboards[this.model].value.label + " Updated!");
        this.model = null;
      } catch (e) {
        this.$refs.resultSnackbar.showError("Unable to update " + this.keyboards[this.model].value.label + " Keyboard");
      }
    },
  },
};
</script>
