<template>
  <v-dialog v-if="avatars" v-model="show" max-width="600px" :fullscreen="$vuetify.breakpoint.xs">
    <v-card>
      <v-toolbar class="headingCard" color="primary" dense>
        <v-toolbar-title>Pick an avatar</v-toolbar-title>
        <v-spacer />
        <v-btn text small @click="show = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <perfect-scrollbar
        id="mainScrollBar"
        ref="ps"
        :options="$store.getters.getDefaultScrollProps"
        style="max-height: 500px"
        :style="isDarkMode ? 'background: #455a64 !important' : 'background: #fafafa !important'"
      >
        <v-card-text class="pa-2" style="overflow: hidden !important">
          <v-layout row wrap>
            <v-flex v-for="avatar in avatars" :key="avatar.id">
              <v-card tile flat class="d-flex">
                <v-card-text class="d-flex">
                  <v-avatar size="80" class="avatar-picker-avatar pointer" :class="{ current: avatar.id === currentAvatar }" @click="selectAvatar(avatar)">
                    <img class="pointer" :src="`./avatars/${avatar.name}`">
                  </v-avatar>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AvatarPicker",
  props: {
    currentAvatar: {
      type: String,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {
      avatars: [
        { id: 0, name: "avatar-0.png" },
        { id: 1, name: "avatar-1.png" },
        { id: 2, name: "avatar-2.png" },
        { id: 3, name: "avatar-3.png" },
        { id: 4, name: "avatar-4.png" },
        { id: 5, name: "avatar-5.png" },
        { id: 6, name: "avatar-6.png" },
        { id: 7, name: "avatar-7.png" },
        { id: 8, name: "avatar-8.png" },
        { id: 9, name: "avatar-9.png" },
        { id: 10, name: "avatar-10.png" },
        { id: 11, name: "avatar-11.png" },
        { id: 12, name: "avatar-12.png" },
        { id: 13, name: "avatar-13.png" },
        { id: 14, name: "avatar-14.png" },
        { id: 15, name: "avatar-15.png" },
        { id: 16, name: "avatar-16.png" },
        { id: 17, name: "avatar-17.png" },
        { id: 18, name: "avatar-18.png" },
        { id: 20, name: "avatar-20.png" },
        { id: 21, name: "avatar-21.png" },
        { id: 22, name: "avatar-22.png" },
        { id: 23, name: "avatar-23.png" },
        { id: 24, name: "avatar-24.png" },
        { id: 25, name: "avatar-25.png" },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isDarkMode: function () {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    selectAvatar(avatar) {
      this.$emit("selected", avatar);
      this.show = false;
    },
  },
};
</script>
<style scoped>
.avatar-picker-avatar {
  opacity: 0.8;
  transition: all 0.1s ease-in-out;
}

.avatar-picker-avatar:hover,
.avatar-picker-avatar.current {
  opacity: 1;
}
</style>
