<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Configure your Scheduled Report"
      @input="
        searchString = $event;
        forceRerender++;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <!-- REPORT GLOBALI -->
      <v-expansion-panels v-if="userHasMoreCompanies" v-model="expansionPanelGlobalScheduledReport" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col>Global Scheduled Report</v-col>
              <v-col v-if="globalReport && globalReport.date" class="text-truncate">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Email Recurrence</v-list-item-subtitle>
                    <v-list-item-title>{{ globalReport.date.join(", ") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col v-if="globalReport && globalReport.hasOwnProperty('enabledScheduling')" class="hidden-sm-and-down">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="globalReport.enabledScheduling">
                      Enabled
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      Disabled
                    </v-list-item-subtitle>
                    <v-list-item-icon class="ma-0">
                      <v-btn class="ma-0 ml-1" :color="globalReport.enabledScheduling ? 'success' : 'error'" x-small text>
                        <v-icon v-if="globalReport.enabledScheduling" small>
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else small>
                          mdi-close-circle
                        </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col v-if="globalReport && globalReport.companiesList">
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>Companies Selected</v-list-item-subtitle>
                    <v-list-item-title>{{ getCompaniesNameList() }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ScheduledReportsGlobal
              :emailBoxList="emailBoxList"
              :globalReport="globalReport"
              @globalReportUpdated="globalReportSuccess"
              @failUpdateGlobalReport="globalReportFailed"
              @deleteGlobalReport="deleteGlobalReport"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- //! riga per la creazione di un nuovo elemento -->
      <v-col v-if="showWarning && getReportList.length == 0 && !newReport && searchString == ''" cols="12">
        <v-alert type="warning">
          No Scheduled Reports configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getReportList.length == 0 && !newReport && searchString != ''" cols="12">
        <v-alert type="warning">
          No Scheduled Report match your search
        </v-alert>
      </v-col>
      <v-expansion-panels v-if="newReport" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="10">
                New Scheduled Report
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <ScheduledReportsForm :report="newReport" :emailBoxList="emailBoxList" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- //! LISTA REPORT  -->
      <v-expansion-panels :key="forceRerender + '_ep'" v-model="expansionPanelScheduledReport" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(report, id) in getReportList" :key="id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center" class="spacer" no-gutters>
                <v-col class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Name</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ report.id }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="text-truncate">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Email Recurrence</v-list-item-subtitle>
                      <v-list-item-title>{{ report.date }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="report.enabledScheduling">
                        Enabled
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        Disabled
                      </v-list-item-subtitle>
                      <v-list-item-icon class="ma-0">
                        <v-btn class="ma-0 ml-1" :color="report.enabledScheduling ? 'success' : 'error'" x-small text>
                          <v-icon v-if="report.enabledScheduling" small>
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else small>
                            mdi-close-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col class="hidden-sm-and-down">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title v-if="report.lastUpdate">
                        {{ report.lastUpdate | formatVerboseDateTime }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        -
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <ScheduledReportsForm :key="forceRerender + id + '_sr'" :report="report" :emailBoxList="emailBoxList" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <AddNewItemButton v-if="hasRequiredRole($store.getters.getRoles.USER)" name="Scheduled Report" @addNewItem="addNewScheduledReport" />
  </v-container>
</template>

<script>
import ScheduledReportsForm from "../../components/analytics/scheduledReportsForm";
import ScheduledReportsGlobal from "../../components/analytics/scheduledReportsGlobal";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "ScheduledReports",
  components: {
    ScheduledReportsForm,
    ScheduledReportsGlobal,
    ResultSnackbar,
    AddNewItemButton,
    SearchBar,
  },
  data() {
    return {
      expansionPanelGlobalScheduledReport: null,
      expansionPanelScheduledReport: null, //Indice del tab aperto
      expansionPanelCreation: null,
      forceRerender: 0,
      scheduledReports: [],
      searchString: "",
      newReport: null,
      emailBoxList: [],
      globalReport: {},
      showWarning: false,
    };
  },
  computed: {
    ...spacing,

    getReportList() {
      this.forceRerender;
      return (
        this.scheduledReports
          .filter((report) => report.id.toLowerCase().indexOf(this.searchString.toLowerCase()) != -1)
          //Sorting per un campo
          .sort((a, b) => {
            if (a.id.toLowerCase() > b.id.toLowerCase()) {
              return 1;
            }
            if (a.id.toLowerCase() < b.id.toLowerCase()) {
              return -1;
            }
            return 0;
          })
      );
    },
    userHasMoreCompanies() {
      let numberOfCompanies = this.$store.getters.getCompaniesList.length;
      return numberOfCompanies > 1;
    },
  },
  async mounted() {
    EventBus.$on(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT, this.onScheduledReportUpdate);
    EventBus.$on(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT_FAIL, this.onScheduledReportFailedUpdate);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_SCHEDULED_REPORT_CREATION, this.cancelScheduledReportCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT, this.deleteScheduledReport);
    EventBus.$on(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT_FAIL, this.cancelScheduledReportFail);
    EventBus.$on(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, this.testScheduledReportSuccess);
    EventBus.$on(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, this.testScheduledReportFail);
    if (
      this.$store.getters.getGlobalScheduledReports &&
      this.$store.getters.getGlobalScheduledReports.emails &&
      this.$store.getters.getGlobalScheduledReports.emails.length > 0
    ) {
      this.globalReport = merge({}, this.$store.getters.getGlobalScheduledReports);
      this.globalReport.emailBoxConf.password = "";
      this.globalReport.emailBoxConf.retypePassword = "";
    }

    //* separo le richieste così il supervisor può vedere i report schedulati
    //* anche se non ha i permessi per accedere alle email boxes
    let reportEmailResponse = null;
    try {
      reportEmailResponse = await this.$http.get("/analytics/scheduled-report");
      for (let i in reportEmailResponse.data) {
        reportEmailResponse.data[i].id = i;
        this.scheduledReports.push(reportEmailResponse.data[i]);
      }
    } finally {
      reportEmailResponse = null;
    }

    try {
      const emailBoxResponse = await this.$http.get("/mail-settings");
      this.emailBoxList.push("System Email Box");
      this.emailBoxList.push("Custom");
      for (let i in emailBoxResponse.data) {
        this.emailBoxList.push(i);
      }
    } catch (e) {
      this.emailBoxList.push("System Email Box");
      this.emailBoxList.push("Custom");
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT, this.onScheduledReportUpdate);
    EventBus.$off(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT_FAIL, this.onScheduledReportFailedUpdate);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_SCHEDULED_REPORT_CREATION, this.cancelScheduledReportCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT, this.deleteScheduledReport);
    EventBus.$off(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT_FAIL, this.cancelScheduledReportFail);
    EventBus.$off(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, this.testScheduledReportSuccess);
    EventBus.$off(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, this.testScheduledReportFail);
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    onScheduledReportUpdate(obj) {
      let isEdit = false;
      for (let i in this.scheduledReports) {
        if (this.scheduledReports[i].id == obj.id) {
          this.scheduledReports[i].emails = [];
          this.scheduledReports[i].formats = [];
          this.scheduledReports[i].report = [];
          this.scheduledReports[i] = merge(this.scheduledReports[i], obj);
          this.$refs.resultSnackbar.showSuccess("Scheduled report " + obj.id + " updated!");
          isEdit = true;
        }
      }
      if (!isEdit) {
        // se è nuovo
        this.scheduledReports.push(merge({}, obj));
        this.$refs.resultSnackbar.showSuccess("Scheduled report " + obj.id + " created!");
        this.closeAllPanels();
      }
      this.forceRerender++;
    },
    onScheduledReportFailedUpdate(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    reportCreation() {
      this.expansionPanelCreation = null;
      this.newReport = null;
    },
    closeAllPanels() {
      this.cancelScheduledReportCreation();
      this.expansionPanelScheduledReport = null;
    },
    addNewScheduledReport() {
      if (!this.newReport) {
        this.newReport = {
          date: "monthly",
          emails: [],
          enabledScheduling: true,
          formats: ["csv"],
          name: [],
          report: [],
          id: "",
        };
        this.expansionPanelScheduledReport = null;
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    cancelScheduledReportCreation() {
      this.expansionPanelCreation = null;
      this.newReport = null;
    },
    deleteScheduledReport(obj) {
      for (let i in this.scheduledReports) {
        if (this.scheduledReports[i].id == obj.scheduledReport) {
          delete this.scheduledReports[i];
        }
      }
      this.$refs.resultSnackbar.showSuccess("Scheduled report " + obj.scheduledReport + " deleted!");
      this.closeAllPanels();
      setTimeout(() => {
        this.forceRerender++;
      }, 100);
    },
    cancelScheduledReportFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    testScheduledReportSuccess(obj) {
      this.$refs.resultSnackbar.showSuccess(obj.message);
    },
    testScheduledReportFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    globalReportSuccess(editScheduledReport) {
      this.$store
        .dispatch("UpdateGlobalScheduledReport", editScheduledReport)
        .then(() => {
          this.globalReport = merge({}, editScheduledReport);
          this.$refs.resultSnackbar.showSuccess("Global scheduled report updated!");
        })
        .catch((err) => {
          this.$refs.resultSnackbar.showSuccess("Unable to save Global Report into" + err);
        });
    },
    deleteGlobalReport() {
      this.$refs.resultSnackbar.showSuccess("Global scheduled report deleted!");
      this.expansionPanelGlobalScheduledReport = null;
      this.globalReport = {};
    },
    globalReportFailed() {
      this.$refs.resultSnackbar.showError("Fail to update Global scheduled report");
    },
    getCompaniesNameList() {
      let companyNameList = [];
      for (const company of this.globalReport.companiesList) {
        const val = this.$store.getters.getCompanyNameByUuid(company);
        if (val) {
          companyNameList.push(this.$store.getters.getCompanyNameByUuid(company));
        }
      }
      return companyNameList.join(", ");
    },
  },
};
</script>
