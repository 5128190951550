<template>
  <v-form ref="inputProcessorForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteProcessorConfirm" @dialogDeny="showConfirm = false" />

      <!-- common fields among all input processors -->
      <v-row :class="getFormRowMargin" align="center">
        <!-- identifier -->
        <v-col v-show="!isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedProcessor.name"
            label="Input Processor name"
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[
              isInputProcessorNameUnique(processorsList, editedProcessor.name),
              simpleAlphaNumString,
              charsGreaterThanAndLessThan(editedProcessor.name, 3, 255),
            ]"
            prepend-inner-icon="mdi-card-bulleted"
          />
        </v-col>
        <!-- UUID -->
        <v-col v-if="isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model="editedProcessor.uuid"
            readonly
            disabled
            label="UUID"
            :autocomplete="$store.getters.disableAutocomplete"
            prepend-inner-icon="mdi-code-braces"
          />
        </v-col>
      </v-row>

      <!-- campi custom per tipo di connettore -->
      <div v-if="editedProcessor.type == 'aws_translate'">
        <AWSTranslate :processor="editedProcessor" :isEdit="isEdit" />
      </div>
      <div v-else-if="editedProcessor.type == 'google_translate'">
        <GoogleTranslate :processor="editedProcessor" :isEdit="isEdit" />
      </div>
      <div v-else-if="editedProcessor.type == 'google_speech_recognition'">
        <GoogleSpeechRecognition :processor="editedProcessor" :isEdit="isEdit" />
      </div>

      <!-- submit/delete -->
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading"
            @click.prevent="submitInputProcessor"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn :class="getButtonMargin" color="error" :loading="form.deleteLoading" :disabled="isEdit && form.submitLoading" @click.prevent="deleteProcessor">
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import AWSTranslate from "./input-processor-detail-form/AWSTranslate";
import GoogleTranslate from "./input-processor-detail-form/GoogleTranslate";
import GoogleSpeechRecognition from "./input-processor-detail-form/GoogleSpeechRecognition";
import merge from "deepmerge";

export default {
  name: "InputProcessorForm",
  components: {
    ConfirmDialog,
    AWSTranslate,
    GoogleTranslate,
    GoogleSpeechRecognition,
  },
  props: ["processor", "processorsList"],
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Processor " + this.processor.name + " will be deleted!",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.processor.name != "",
      //Flag gestione della form
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedProcessor: {},
    };
  },
  computed: {
    ...spacing,
  },
  mounted() {
    this.editedProcessor = merge({}, this.processor);
    if (this.isEdit) {
      setTimeout(() => this.$refs.inputProcessorForm.validate(), 100);
    }
  },
  methods: {
    ...fieldValidators,
    deleteProcessor() {
      if (this.isEdit) {
        this.showConfirm = true;
      } else {
        //Caso di delete su un input processor in fase di creazione
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_INPUT_PROCESSOR_CREATION);
      }
    },
    async onDeleteProcessorConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/input-processor/" + this.editedProcessor.uuid);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR, {
          name: this.editedProcessor.name,
          type: this.processor.type,
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_INPUT_PROCESSOR_FAIL, {
          name: this.editedProcessor.name,
          message: "Fail to delete input processor " + this.editedProcessor.name,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async submitInputProcessor() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        this.editedProcessor.details.language = this.getLanguageFromLanguageCode(this.editedProcessor);
        if (this.editedProcessor.type === "google_speech_recognition") {
          this.editedProcessor.details.sampleRateHertz = "" + 16000; //stringa perchè se no non piace e forzo a 16000 perchè suppongo vengo prima da app mobile
        }
        try {
          const response = await this.sendRequest();
          EventBus.$emit(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR, {
            processor: response.data,
          });
        } catch (err) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_INPUT_PROCESSOR_FAIL, {
            processor: this.editedProcessor,
            message: "Fail to save input processor",
          });
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
    sendRequest() {
      //Metodo che fa la richiesta HTTP in base al flag di edit
      if (this.isEdit) {
        return this.$http.put("/input-processor/" + this.editedProcessor.uuid, this.editedProcessor);
      }
      return this.$http.post("/input-processor", this.editedProcessor);
    },
    getLanguageFromLanguageCode(processor) {
      let obj = {};
      switch (processor.type) {
        case "google_speech_recognition":
          obj = this.$store.getters.enums.googleSpeechSupportedLanguage;
          break;
        case "google_translate":
          obj = this.$store.getters.enums.googleSupportedLanguage;
          break;
        case "aws_translate":
          obj = this.$store.getters.enums.awsSupportedLanguage;
          break;
      }
      let result = null;
      Object.values(obj).forEach((langCode, index) => {
        if (langCode == processor.details.languageCode) {
          result = Object.keys(obj)[index];
        }
      });
      return result;
    },
  },
};
</script>
