<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
        @onChangeView="onChangeView"
      />
      <span @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-chart
          v-if="selectedView == 'chart'"
          ref="taskOverview"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart"
          :option="options"
        />
        <div v-else-if="selectedView == 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="queue"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            must-sort
            sort-by="queue"
            dense
          >
            <template #top>
              <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
            </template>
          </v-data-table>
        </div>
      </span>
    </v-card>
  </v-col>
</template>
<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "TaskOverview",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    },
    "publications": {
      type: Object,
      required: true
    },
    "agents": {
      type: Object,
      required: true
    }
  },
  data() {
    let channelIcons = {
      chat: require("@/assets/img/channelsIcons/chat-color.png"),
      whatsapp: require("@/assets/img/channelsIcons/whatsapp-color.png"),
      telegram: require("@/assets/img/channelsIcons/telegram-color.png"),
      twitter: require("@/assets/img/channelsIcons/twitter-color.png"),
      facebookmessenger: require("@/assets/img/channelsIcons/facebook-messenger-color.png"),
      webexmeeting: require("@/assets/img/channelsIcons/webex-color.png"),
      applebusinesschat: require("@/assets/img/channelsIcons/apple.png"),
      email: require("@/assets/img/channelsIcons/email-outline-color.png"),
      googlehome: require("@/assets/img/channelsIcons/assistant.png"),
      googlebusinessmessages: require("@/assets/img/channelsIcons/googlebusinessmessages.png"),
      audiovideo: require("@/assets/img/channelsIcons/audiovideo.png"),
      amazonalexa: require("@/assets/img/channelsIcons/alexa.png"),
    };
    return {
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queue",
            align: "start",
            sortable: true,
            value: "queue",
          },
          {
            text: "Assigned",
            align: "center",
            sortable: true,
            value: "assigned",
            width: 150,
          },
          {
            text: "", //colonna utile a nascondere la scrollbar orizzontale
            align: "",
            sortable: false,
            value: "",
            width: 0,
          },
        ],
        data: [],
      },
      info: "This widget shows the number of active conversations currently assigned to agents, splitted for channel and for queue",
      reloadWidgetDataInterval: null,
      options: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            data: [],
            gridIndex: 0,
            axisLabel: {
              inside: true,
              rotate: 90,
              color: this.$vuetify.theme.dark ? "white" : "white",
              fontSize: 16,
              textShadowColor: "rgba(0, 0, 0, 0.9)",
              textShadowOffsetX: "1",
              textShadowOffsetY: "1",
              textShadowBlur: "2",
              formatter: function (value) {
                if (value) {
                  return value.length <= 14 ? value : value.substring(0, 14) + "...";
                }
                return value;
              },
            },
            z: 10000,
          },
          {
            type: "category",
            data: [],
            gridIndex: 1,
            axisLabel: {
              formatter: function (value) {
                return "{" + value + "| }";
              },
              interval: 0,
              margin: 20,
              rich: {
                value: {
                  lineheight: 25,
                  align: "center",
                },
                chat: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.chat,
                  },
                },
                whatsapp: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.whatsapp,
                  },
                },
                telegram: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.telegram,
                  },
                },
                twitter: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.twitter,
                  },
                },
                facebookmessenger: {
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.facebookmessenger,
                  },
                },
                webexmeeting: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.webexmeeting,
                  },
                },
                applebusinesschat: {
                  height: 25,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.applebusinesschat,
                  },
                },
                email: {
                  height: 24,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.email,
                  },
                },
                googlehome: {
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.googlehome,
                  },
                },
                googlebusinessmessages: {
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.googlebusinessmessages,
                  },
                },
                amazonalexa: {
                  height: 22,
                  align: "center",
                  backgroundColor: {
                    image: channelIcons.amazonalexa,
                  },
                },
                audiovideo: {
                  height: 24,
                  align: "center",
                  color: "#232323",
                  backgroundColor: {
                    image: channelIcons.audiovideo,
                  },
                },
              },
            },
            z: 10000,
          },
        ],
        yAxis: [
          { gridIndex: 0, minInterval: 1, name: "                    Conversations assigned by queue #", position: "left" },
          { gridIndex: 1, minInterval: 1, name: "                       Conversations assigned by channel #", position: "left" },
        ],
        grid: [{ bottom: "55%" }, { top: "55%" }],
        series: [],
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    loadWidgetData: async function () {
      try {
        const result = await this.$httpAuth.get("/dashboard/wallboard/task-overview");

        let serieStyle = {
          showBackground: true,
          backgroundStyle: { color: "rgba(180, 180, 180, 0.2)" },
          emphasis: { focus: "series" },
          itemStyle: {
            borderRadius: [5, 5, 5, 5],
            borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
            color: this.$vuetify.theme.dark ? "#1D9096" : "#039be5",
          },
        };

        this.options.series = [];

        const series = {
          row: [],
          xy: [],
        };
        this.viewTable.data = [];

        // grouped by single queue
        const queueData = [];
        this.options.xAxis[0].data = [];
        for (const queueERI in result.data.queue) {
          const queue = this.queues.find((q) => q.eri === queueERI);
          if (queue) {
            const queueName = queue.name;
            queueData.push({ name: queueName, value: result.data.queue[queueERI].assigned });
            this.options.xAxis[0].data.push(queueName);

            this.viewTable.data.push({
              queue: queueName,
              assigned: result.data.queue[queueERI].assigned,
            });
          }
        }
        series.row.push({ type: "bar", seriesLayoutBy: "row", ...serieStyle, data: queueData });

        // grouped by single channel
        const channelData = [];
        this.options.xAxis[1].data = [];
        for (const channel in result.data.channel) {
          channelData.push({ name: channel, value: result.data.channel[channel].assigned });
          this.options.xAxis[1].data.push(channel);
        }
        series.xy.push({ type: "bar", xAxisIndex: 1, yAxisIndex: 1, ...serieStyle, data: channelData });

        this.options.series = [...series.row, ...series.xy];

        if (this.$refs["taskOverview"]) {
          this.$refs["taskOverview"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 500px;
}
</style>
