<template>
  <v-container
    fluid
    class="RecordingsPage"
  >
    <ConfirmDialog
      :show-confirm="showConfirmDelete"
      :message="deleteMessage"
      @dialogConfirm="deleteResources"
      @dialogDeny="showConfirmDelete = false"
    />
    <ConfirmDialog
      :show-confirm="showConfirmRestore"
      :message="restoreMessage"
      @dialogConfirm="startRestoring"
      @dialogDeny="showConfirmRestore = false"
    />
    <ResultSnackbar ref="resultSnackbar" />
    <v-dialog
      v-model="recDetailDialog"
      max-width="600"
    >
      <v-card style="overflow-x: hidden">
        <v-card-title>
          Recording Details <v-spacer />

          <v-btn
            style="border-radius: 50% !important; background-color: transparent !important"
            rounded
            icon
            tile
            small
            class="mx-1"
            @click.prevent="recDetailDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-12 pr-12 pb-2">
          <v-row
            v-if="currentRecDetails.task"
            :class="getFormRowMargin"
          >
            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>UUID:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <span>
                <strong>{{ currentRecDetails.task.externalOptions.metadataUUID }}</strong>
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>Status:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <span :class="currentRecDetails.statusCssClass">
                {{ currentRecDetails.restoring ? "Restore in progress..." : "Ready to be restored" }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>DialogID:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <span>
                <strong>{{ currentRecDetails.task.externalOptions.dialogId }}</strong>
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>Queue:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <strong>{{ currentRecDetails.task.externalOptions.queue.name }}</strong>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>Start date:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <strong>{{ formatDate(currentRecDetails.task.externalOptions.time_stamp) }}</strong>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>End date:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <strong>{{ formatDate(currentRecDetails.task.externalOptions.time_stamp_end) }}</strong>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>Duration:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <strong>{{ timeBetweenDates(currentRecDetails.task.externalOptions.time_stamp, currentRecDetails.task.externalOptions.time_stamp_end) }}</strong>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>

            <v-col
              cols="4"
              class="pa-1 activityDatatableHeight text-right"
            >
              <span>Issue Phase:</span>
            </v-col>
            <v-col
              cols="8"
              class="pa-1 activityDatatableHeight"
            >
              <strong>{{ getIssueTypeDescription(currentRecDetails.type) }}</strong>
            </v-col>
            <v-col
              cols="12"
              class="pa-0"
            >
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pl-8 pt-4 pb-4" />
      </v-card>
    </v-dialog>

    <v-row :class="getMainRowMargin">
      <v-col
        cols="12"
        class="d-flex justify-end pa-0 ma-0"
      >
        <v-btn
          :loading="refreshLoading"
          color="primary"
          small
          class="mr-4"
          @click="getList()"
        >
          <v-icon
            left
            small
          >
            mdi-refresh
          </v-icon>
          <span>Refresh all</span>
        </v-btn>
      </v-col>

      <v-row v-if="warningNoRecording">
        <v-col
          class="mt-3"
          cols="12"
        >
          <v-alert
            type="warning"
            icon="mdi-alert"
          >
            There are no broken recordings to show.
          </v-alert>
        </v-col>
      </v-row>

      <v-col
        cols="12"
        justify="center"
      >
        <v-expansion-panels
          v-model="expansionPanelModel"
          popout
          class="mt-3 mb-3"
        >
          <v-expansion-panel
            v-for="data in orderedRecordingsList"
            :key="data[0].task.externalOptions.tenant"
          >
            <v-expansion-panel-header>
              <v-row
                align="center"
                class="spacer"
                no-gutters
              >
                <v-checkbox
                  color="primary"
                  class="mb-n5 mt-0"
                  :value="resourcesCount(data[0].task.externalOptions.tenant) > 0"
                  @click.native.prevent="preventExpansionPanelBehavior($event)"
                  @change="selectAllItems(data[0].task.externalOptions.tenant)"
                >
                  <template #label>
                    <span>{{ recordingsCompanyList[data[0].task.externalOptions.tenant] }} -
                      <span class="subtitle-2">{{ data[0].task.externalOptions.tenant }}</span></span>
                    <span
                      v-if="resourcesCount(data[0].task.externalOptions.tenantt) > 0"
                      class="pl-1 pr-1"
                      style="font-style: italic"
                    >
                      - {{ resourcesCount(data[0].task.externalOptions.tenant) }} resources selected</span>
                  </template>
                </v-checkbox>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <!--            <SearchBar :searchString="data.searchString" v-on:input="data.searchString = $event" />-->
              <v-row class="mt-6 mb-6">
                <v-col
                  v-for="(rec, index) in data"
                  :key="index"
                  cols="6"
                >
                  <v-row class="mt-0 mb-0 ml-4">
                    <v-col class="pa-0 flex justify-center flex-column">
                      <v-checkbox
                        :key="rec.task.externalOptions.metadataUUID"
                        v-model="selectedItems[data[0].task.externalOptions.tenant][rec.task.externalOptions.metadataUUID]"
                        :disabled="!boxEnabled(rec)"
                        style="min-width: 250px"
                        class="mt-0"
                        color="primary"
                        :label="getLabel(rec.task.externalOptions)"
                        :value="rec.task.externalOptions.metadataUUID"
                      />
                      <div
                        style="white-space: nowrap"
                        class="v-messages subtitle pl-9"
                      >
                        DialogId: {{ getDialogId(rec.task.externalOptions) }}
                      </div>
                      <div
                        style="white-space: nowrap"
                        class="v-messages subtitle pl-9"
                      >
                        Metadata: {{ getMetadataUUID(rec.task.externalOptions) }}
                      </div>
                      <div class="v-messages subtitle pl-9">
                        {{ getDescription(rec.task.externalOptions) }}
                      </div>
                    </v-col>
                    <v-col class="pa-0 ml-8 flex align-center">
                      <v-btn
                        class="detail-btn"
                        small
                        color="primary"
                        @click="openDetails(rec)"
                      >
                        <span
                          v-if="!boxEnabled(rec)"
                          class="alerts"
                        >
                          <v-icon
                            left
                            small
                          >mdi-reload-alert</v-icon>
                          Restore in progress...
                        </span>
                        <span v-else>
                          <v-icon
                            left
                            small
                          >mdi-file-video-outline</v-icon>
                          <span>Details</span>
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row
          v-if="!refreshLoading && !warningNoRecording"
          :class="getMainRowMargin"
          class="mt-8"
        >
          <div class="d-flex ml-1 mr-2">
            <v-btn
              color="success"
              :disabled="resourcesCount() === 0"
              @click="showConfirmRestore = true"
            >
              <v-icon left>
                mdi-restore
              </v-icon>
              <span>Restore</span>
            </v-btn>
          </div>
          <div class="d-flex ml-2">
            <v-btn
              color="error"
              :disabled="resourcesCount() === 0"
              @click="showConfirmDelete = true"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";
import Vue from "vue";
import ConfirmDialog from "../../components/other/ConfirmationDialog";

export default {
  name: "ReportingIssuesManagement",
  components: {
    ResultSnackbar,
    ConfirmDialog,
  },
  data() {
    return {
      warningNoRecording: false,
      selectedItems: {},
      availItems: {},
      recDetailDialog: false,
      currentRecDetails: {},
      recordingsList: {},
      recordingsCompanyList: {},
      orderedRecordingsList: [],
      apiUrls: {
        restore: "/post-processing/recording/restore",
        delete: "/post-processing/recording/delete",
        list: "/post-processing/recording/list",
      },
      expansionPanelModel: false,
      //Flag per dialog di conferma cancellazione
      showConfirmDelete: false,
      showConfirmRestore: false,
      refreshLoading: false,
    };
  },
  computed: {
    ...spacing,
    deleteMessage() {
      return this.resourcesCount() + " resources will be deleted!";
    },
    restoreMessage() {
      return this.resourcesCount() + " resources will be restored!";
    },
  },
  async mounted() {
    try {
      this.recordingsList = await this.getList();
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    getIssueTypeDescription(type) {
      let description = "";
      switch (type) {
        case "raw":
          description = "Post-processing";
          break;
        case "processed":
          description = "Upload";
          break;
      }
      return description;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    convertMsToTime(milliseconds) {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      // hours = hours % 24;
      return `${hours}h ${this.padTo2Digits(minutes)}m ${this.padTo2Digits(seconds)}s`;
    },
    timeBetweenDates(start, end) {
      start = new Date(start);
      end = new Date(end);
      const diff = Math.abs(end.getTime() - start.getTime());
      return this.convertMsToTime(diff);
    },
    async getList() {
      this.refreshLoading = true;
      try {
        const res = await this.$http.get(this.apiUrls.list);
        Object.keys(res.data).forEach((key) => {
          Vue.set(this.selectedItems, key, {});
          Vue.set(this.availItems, key, []);
          res.data[key].forEach((o) => {
            Vue.set(this.selectedItems[key], o.task.externalOptions.metadataUUID, null);
            this.recordingsCompanyList[key] = o.task.externalOptions.tenantName;
            if (o.restoring !== true) {
              this.availItems[key].push(o.task.externalOptions.metadataUUID);
            }
          });
        });
        this.recordingsList = res.data;
        let orderedRecordingsValues = [];
        if (this.recordingsList && Object.values(this.recordingsList).length > 0) {
          const recordingsValues = Object.values(this.recordingsList);
          orderedRecordingsValues = recordingsValues.sort((a, b) => {
            return a[0].task.externalOptions.tenantName.localeCompare(b[0].task.externalOptions.tenantName);
          });
        }
        this.orderedRecordingsList = orderedRecordingsValues;
        this.refreshLoading = false;
        if (this.recordingsList && Object.keys(this.recordingsList).length > 0) {
          this.warningNoRecording = false;
        } else {
          this.warningNoRecording = true;
        }
        return res.data;
      } catch (e) {
        this.refreshLoading = false;
        this.warningNoRecording = true;
        return {};
      }
    },
    getLabel(data) {
      const duration = this.timeBetweenDates(data.time_stamp, data.time_stamp_end);
      return `${data.queue.name} - ${duration}`;
    },
    getDialogId(data) {
      return data.dialogId;
    },
    getMetadataUUID(data) {
      return data.metadataUUID;
    },
    getDescription(data) {
      const format = this.formatDate(data.time_stamp);
      return `${format} - ${data.agent.username}`;
    },
    formatDate(dateStr) {
      const ts = new Date(dateStr);
      return `${this.padTo2Digits(ts.getDate())}/${this.padTo2Digits(ts.getMonth() + 1)}/${ts.getFullYear()} ${this.padTo2Digits(
        ts.getHours(),
      )}:${this.padTo2Digits(ts.getMinutes())}:${this.padTo2Digits(ts.getSeconds())}`;
    },
    boxEnabled(data) {
      return data.restoring !== true;
    },
    selectAllItems(key) {
      let opSet = true;
      const resCount = this.resourcesCount(key);
      if (resCount === this.availItems[key].length || resCount > 0) {
        opSet = false;
      }
      this.recordingsList[key].forEach((o, i) => {
        this.recordingsList[key][i].selected = opSet && !o.restoring;
        this.selectedItems[key][o.task.externalOptions.metadataUUID] = opSet && !o.restoring ? o.task.externalOptions.metadataUUID : null;
      });
      this.resourcesCount(key);
    },
    selectedResourceCount(ob) {
      let resources = 0;
      if (JSON.stringify(ob).length > 2) {
        Object.keys(ob).forEach((i) => {
          if (ob[i] !== null) {
            resources++;
          }
        });
      }
      return resources;
    },
    resourcesCount(key) {
      let resourcesToHandle = 0;
      if (key) {
        resourcesToHandle += this.selectedResourceCount(this.selectedItems[key]);
      } else {
        this.selectedItems &&
          Object.keys(this.selectedItems).forEach((o) => {
            resourcesToHandle += this.selectedResourceCount(this.selectedItems[o]);
          });
      }
      return resourcesToHandle;
    },
    openDetails(taskDetail) {
      this.currentRecDetails = taskDetail;
      this.currentRecDetails.statusCssClass = taskDetail.restoring === true ? "alerts" : "bold";
      this.recDetailDialog = true;
    },
    clearSelection(removeAvailable) {
      removeAvailable = removeAvailable || false;
      Object.keys(this.selectedItems).forEach((key) => {
        Object.keys(this.selectedItems[key]).forEach((x) => {
          this.selectedItems[key][x] = null;
          if (removeAvailable) {
            this.availItems[key] = this.availItems[key].filter((z) => {
              return z !== x;
            });
          }
        });
      });
    },
    async deleteResources() {
      const metadataUUIDs = {};
      Object.keys(this.selectedItems).forEach((key) => {
        Object.values(this.selectedItems[key]).forEach((o) => {
          metadataUUIDs[key] = metadataUUIDs[key] || [];
          metadataUUIDs[key].push(o);
        });
      });
      try {
        const response = await this.$http.delete(this.apiUrls.delete, { data: metadataUUIDs });
        this.clearSelection(true);
        this.$refs.resultSnackbar.showSuccess("Resources deleted");
        await this.getList();
        return response;
      } catch (e) {
        this.$refs.resultSnackbar.showError("There was an error while deleting the selected resourced");
      } finally {
        this.showConfirmDelete = false;
      }
    },
    async startRestoring() {
      let metadataUUIDs = [];
      Object.keys(this.selectedItems).forEach((key) => {
        Object.values(this.selectedItems[key]).forEach((o) => {
          if (typeof o === "string") {
            metadataUUIDs.push(o);
            const index = this.recordingsList[key].findIndex((x) => {
              return x.task.externalOptions.metadataUUID === o;
            });
            if (index) {
              this.recordingsList[key][index].restoring = true;
              this.recordingsList[key][index].selected = false;
            }
          }
        });
      });
      try {
        const response = await this.$http.post(this.apiUrls.restore, { metadataUUIDs });
        this.$refs.resultSnackbar.showSuccess("Resources staged for restore.");
        this.clearSelection();
        await this.getList();
        return response;
      } catch (e) {
        this.$refs.resultSnackbar.showError("There was an error while starting the restore process");
      } finally {
        this.showConfirmRestore = false;
      }
    },
    preventExpansionPanelBehavior(e) {
      //* per evitare che il click sulla checkbox apra il panel
      e.cancelBubble = true;
    },
  },
};
</script>
<style>
.v-expansion-panel-content .v-input__slot {
  margin-bottom: 0;
}
.v-expansion-panel-content .v-messages {
  min-height: 0;
}
.alerts {
  color: #9e0c0c;
}
.subtitle {
  margin-left: 0;
}
.bold {
  font-weight: 600;
}
.detail-btn {
}
</style>
