<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
        @onChangeView="onChangeView"
      />
      <span @mousedown.stop="() => {}">
        <!--blocca il drag&drop dal grafico -->
        <v-chart
          v-if="selectedView == 'chart'"
          ref="taskStatusByQueue"
          :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
          autoresize
          class="chart mt-5"
          :option="options"
        />
        <div v-else-if="selectedView == 'table'">
          <v-data-table
            id="viewWallboardTableResult"
            height="354px"
            fixed-header
            :headers="viewTable.headers"
            :items="viewTable.data"
            item-key="name"
            class="elevation-1"
            :search="viewTable.search"
            :custom-filter="searchInViewTable"
            hide-default-footer
            disable-pagination
            dense
            item-class="taskStatusByQueue"
          >
            <template #top>
              <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
            </template>
            <template #item="{ item }">
              <tr :class="getClass(item)">
                <td class="text-start">{{ item.name }}</td>
                <td class="text-center">{{ item.queued }}</td>
                <td class="text-center">{{ item.assigned }}</td>
                <td class="text-center">{{ item.resolved }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </span>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "TaskStatusByQueue",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    },
    "agents": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queue",
            align: "start",
            sortable: true,
            value: "name",
          },
          {
            text: "Queued",
            align: "center",
            sortable: true,
            value: "queued",
            width: 150,
          },
          {
            text: "Assigned",
            align: "center",
            sortable: true,
            value: "assigned",
            width: 150,
          },
          {
            text: "Resolved",
            align: "center",
            sortable: true,
            value: "resolved",
            width: 150,
          },
        ],
        data: [],
      },
      info: "This widget shows the number of active conversations for each queue, splitted between the current ones assigned to agents and the others still in queue.<br>It also shows, when the <i>Preferred Agent</i> feature is enabled, the number of conversations that are waiting into agents personal queue",
      reloadWidgetDataInterval: null,
      options: {
        legend: {},
        tooltip: {
          trigger: "axis",
        },
        dataset: {
          source: [["Task", "Assigned", "Queued", "Resolved"]],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            inside: true,
            rotate: 90,
            color: this.$vuetify.theme.dark ? "white" : "white",
            fontSize: 16,
            textShadowColor: "rgba(0, 0, 0, 0.9)",
            textShadowOffsetX: "1",
            textShadowOffsetY: "1",
            textShadowBlur: "2",
            formatter: function (value) {
              if (value) {
                return value.length < 30 ? value : value.substring(0, 30) + "...";
              }
              return value;
            },
          },
          z: 10000,
        },
        yAxis: {
          minInterval: 1,
          name: "Conversations #",
        },
        color: [
          this.$vuetify.theme.dark ? "#1D9096" : "#039be5",
          this.$vuetify.theme.dark ? "#ffa973" : "#fb8c00",
          this.$vuetify.theme.dark ? "#60A38D" : "#43A047",
        ],
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            stack: "queue",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
            },
          },
          {
            type: "bar",
            stack: "queue",
            barGap: "-10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
            },
          },
          {
            type: "bar",
            stack: "queue",
            barGap: "-10%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              borderRadius: [5, 5, 5, 5],
              borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
            },
          },
        ],
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    onReloadEvent() {
      this.reloadWidgetData();
    },
    async loadWidgetData() {
      try {
        let totalAssigned = 0;
        let totalQueued = 0;
        let totalResolved = 0;
        const result = await this.$httpAuth.get("/dashboard/wallboard/task-overview");
        this.options.dataset.source = [["Task", "Assigned", "Queued", "Resolved"]];
        this.viewTable.data = [];
        for (const queueERI in result.data.queue) {
          const queue = this.queues.find((q) => q.eri === queueERI);
          let queueName;
          if (queue) {
            queueName = queue.name;
          } else {
            const agent = this.agents.find((a) => a.eri === queueERI);
            if (agent) {
              queueName = agent.authentication.credentials.username;
            }
          }
          const array = [queueName];
          array.push(result.data.queue[queueERI].assigned);
          array.push(result.data.queue[queueERI].queued);
          array.push(result.data.queue[queueERI].resolved);
          this.options.dataset.source.push(array);

          this.viewTable.data.push({
            name: queueName,
            assigned: result.data.queue[queueERI].assigned,
            queued: result.data.queue[queueERI].queued,
            resolved: result.data.queue[queueERI].resolved,
          });
          totalAssigned += result.data.queue[queueERI].assigned;
          totalQueued += result.data.queue[queueERI].queued;
          totalResolved += result.data.queue[queueERI].resolved;
        }
        this.viewTable.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
        this.viewTable.data.push({
          name: "Total",
          assigned: totalAssigned,
          queued: totalQueued,
          resolved: totalResolved,
        });

        if (this.$refs["taskStatusByQueue"]) {
          this.$refs["taskStatusByQueue"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    getClass(item) {
      if (JSON.stringify(item) === JSON.stringify(this.viewTable.data[this.viewTable.data.length - 1])) {
        return "font-weight-bold";
      }
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
