<template>
  <v-form ref="scheduledReportForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteScheduledReportConfirm" @dialogDeny="showConfirm = false" />

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-switch v-model="editedScheduledReport.enabledScheduling" class="ml-4" color="primary" label="Enable schedule" v-on="on" />
            </template>
            <span v-if="editedScheduledReport.enabledScheduling" label="Enable schedule">Disable the scheduled report</span>
            <span v-else>Enable the scheduled report</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedScheduledReport.name"
            :items="emailBoxList"
            :rules="[requiredField]"
            label="Choose an Email Box"
            prepend-inner-icon="email"
            :validate-on-blur="editedScheduledReport.name.length === 0"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-combobox
            v-model="editedScheduledReport.emails"
            multiple
            chips
            small-chips
            deletable-chips
            :rules="[requiredField, isValidArrayEmail]"
            label="Subscribers' emails"
            prepend-inner-icon="contact_mail"
            class="emailRecipientComboBox"
            :validate-on-blur="editedScheduledReport.emails.length === 0"
            @change="checkEmptyField"
          />
        </v-col>
      </v-row>

      <!-- Gestione email box custom -->
      <v-container v-if="customEmailBoxes" fluid>
        <!-- Riga per madifica mail (in creazione visualizza Name) -->
        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model="editedScheduledReport.emailBoxConf.email"
              label="Email"
              :rules="[isValidEmail]"
              prepend-inner-icon="email"
              :autocomplete="$store.getters.disableAutocomplete"
              validate-on-blur
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox v-model="editedScheduledReport.emailBoxConf.useAuth" class="mx-2" label="Use Authentication" />
          </v-col>
        </v-row>

        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.username"
              label="Username"
              :rules="[requiredField]"
              prepend-inner-icon="person"
              autofocus
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col v-if="editedScheduledReport.emailBoxConf.useAuth" cols="12" sm="6">
            <v-tooltip top>
              <template #activator="{ off }">
                <div v-on="off">
                  <v-select
                    v-model="editedScheduledReport.emailBoxConf.serviceAuth"
                    :items="$store.state.enums.serviceAuthTypes"
                    color="primary"
                    label="Service Authentication"
                  />
                </div>
              </template>
              <span>Service Authentication</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <!-- Riga solo per la modifica password -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'basic'"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="!editedScheduledReport.emailBoxConf.password || editedScheduledReport.emailBoxConf.password.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.retypePassword"
              label="Retype Password"
              :type="showPasswordRetype ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="!editedScheduledReport.emailBoxConf.retypePassword || editedScheduledReport.emailBoxConf.retypePassword.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Riga per google oauth2 authentication -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'google-oauth2'"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.serviceClient"
              label="Client ID"
              hint="Service client ID relative to the service account created"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-account-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.privateKey"
              label="Private key"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="mdi-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
        </v-row>

        <!-- Riga per office365 oauth2 authentication -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'office365-oauth2'"
          :key="0"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.tenant"
              label="Directory (Tenant) ID"
              :rules="[requiredField]"
              prepend-inner-icon="person"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.applicationClientId"
              label="Application (Client) ID"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-account-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.clientSecret"
              label="Client Secret"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
        </v-row>

        <!-- Riga per configurazione server -->
        <v-row
          v-if="!editedScheduledReport.emailBoxConf.useAuth || editedScheduledReport.emailBoxConf.serviceAuth != 'office365-oauth2'"
          :key="0"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="12" md="5" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.host"
              label="Server"
              type="text"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-server-network"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" :class="getFormColMargin">
            <v-text-field
              v-model.number="editedScheduledReport.emailBoxConf.port"
              label="Port"
              type="number"
              :rules="[requiredField, positiveNumber, serverPort]"
              prepend-inner-icon="mdi-swap-horizontal"
              min="0"
              max="36535"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" :class="getFormColMargin">
            <v-btn-toggle>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-switch v-model="editedScheduledReport.emailBoxConf.secure" color="primary" label="Secure" v-on="on" />
                </template>
                <span>Enable TLS security</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <!-- La seguente colonna è hidden perche il campo non è utilizzato dalla libreria -->
          <v-col cols="12" sm="2" :class="getFormColMargin" hidden>
            <v-select v-model="editedScheduledReport.emailBoxConf.protocol" :items="protocols" label="Protocol" />
          </v-col>
        </v-row>

        <!-- riga per maxMbSize -->
        <v-row :class="getFormRowMargin" align="center">
          <v-col cols="12" sm="5" class="getFormColMargin">
            <v-checkbox
              v-model="editedScheduledReport.emailBoxConf.attachments.report.enableMaxSize"
              class="my-2"
              label="Enable threshold for reports attachments"
              @change="toggleEnableMaxSize"
            />
          </v-col>
          <v-col v-show="editedScheduledReport.emailBoxConf.attachments.report.enableMaxSize" cols="12" sm="4" class="getFormColMargin">
            <v-text-field
              v-model.number="editedScheduledReport.emailBoxConf.attachments.report.maxMbSize"
              suffix="MB"
              label="Maximum attachments threshold"
              type="number"
              min="1"
              hint="If the attachments of a scheduled report exceed this threshold, they will be saved to a bucket for the configured retention period"
              :rules="[greaterThanZero]"
            />
          </v-col>
        </v-row>

        <v-row :class="getFormRowMargin">
          <!-- Buttons -->
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn
              :class="getButtonMargin"
              color="primary"
              :loading="form.submitLoading"
              :disabled="!form.isValid || form.deleteLoading"
              @click.prevent="testEmailBoxConnection"
            >
              <v-icon left>
                mdi-test-tube
              </v-icon>
              <span>Test Connection</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" lg="6" :class="getFormColMargin">
          <v-subheader>Select which report you want to send:</v-subheader>
          <v-chip-group v-model="editedScheduledReport.report" column multiple active-class="primary">
            <v-chip
              v-for="report in reportType"
              :key="'gsr_' + report"
              :value="report"
              class="ma-2"
              filter
              label
            >
              {{ report }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col cols="12" lg="6" :class="getFormColMargin">
          <v-subheader>Select for which Company you want receive report:</v-subheader>
          <v-chip-group v-model="editedScheduledReport.companiesList" column multiple active-class="primary">
            <v-tooltip v-for="company in $store.getters.getCompaniesList" :key="'gsr_' + company.uuid" top>
              <template #activator="{ on }">
                <v-chip :value="company.uuid" class="ma-2" filter label v-on="on">
                  {{ company.name }}
                </v-chip>
              </template>
              <span>{{ company.uuid }}</span>
            </v-tooltip>
          </v-chip-group>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-subheader>Select the recurrence:</v-subheader>
          <v-row :class="getFormRowMargin" class="pl-4">
            <v-checkbox
              v-for="period in periods"
              :key="'gsr_' + period"
              v-model="editedScheduledReport.date"
              class="mx-2"
              :label="period"
              :value="period"
            />
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-subheader>Select the report format/formats:</v-subheader>
          <v-btn-toggle class="ml-4">
            <template v-for="format in fileFormats">
              <v-switch
                :key="'gsr_' + format"
                v-model="editedScheduledReport.formats"
                color="primary"
                class="mr-2"
                :label="format"
                :value="format"
              />
            </template>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="missingField || form.submitLoading || editedScheduledReport.companiesList.length === 0"
            @click.prevent="submitGlobalReport"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="saveButton">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="primary"
            :loading="form.submitLoading"
            :disabled="missingField || form.submitLoading || editedScheduledReport.companiesList.length === 0"
            @click.prevent="testConfiguration"
          >
            <v-icon left>
              mdi-test-tube
            </v-icon>
            <span>Test Report</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.submitLoading"
            :disabled="saveButton || form.submitLoading"
            @click.prevent="showConfirm = true"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            <span>Delete</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";

export default {
  name: "ScheduledReportsGlobal",
  components: {
    ConfirmDialog,
  },
  props: {
    "globalReport": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        isValid: false,
        submitLoading: false,
      },
      isEdit: false,
      protocols: ["SMTP"],
      //Campi di Utility
      showPassword: false,
      showPasswordRetype: false,
      customErrorMessage: null,
      emailBoxList: ["System Email Box", "Custom"],
      // useAuth: false,
      // serviceAuth: "",
      saveButton: false,
      editedScheduledReport: {
        emails: [],
        enabledScheduling: true,
        formats: [],
        name: [],
        report: [],
        date: [],
        companiesList: [],
        emailBoxConf: {
          name: "",
          email: "",
          username: "",
          password: "",
          retypePassword: "",
          secure: false,
          host: "",
          port: "",
          protocol: "smtp",
          authentication: false,
          authType: "",
          serviceClient: "",
          privateKey: "",
          useAuth: this.globalReport.useAuth !== undefined ? this.globalReport.useAuth : !!(this.globalReport.authType || this.globalReport.username),
          serviceAuth: this.globalReport.serviceAuth || (this.globalReport.authType ? "google-oauth2" : "basic"),
          tenant: "",
          applicationClientId: "",
          attachments: {
            report: {
              enableMaxSize: this.globalReport?.attachments?.report?.enableMaxSize || false,
              maxMbSize: this.globalReport?.attachments?.report?.maxMbSize || 10,
            },
          },
        },
      },
      reportType: ["Survey NPS", "Conversations Usage", "Bot Efficiency"],
      periods: ["daily", "weekly", "monthly"],
      fileFormats: ["excel", "csv"],
      showConfirm: false,
      deleteMessage: "to delete the Global Report Scheduled?",
    };
  },
  computed: {
    ...spacing,
    missingField() {
      if (
        !this.form.isValid ||
        this.editedScheduledReport.date.length == 0 ||
        this.editedScheduledReport.formats.length == 0 ||
        this.editedScheduledReport.report.length == 0 ||
        this.editedScheduledReport.companiesList.length == 0
      ) {
        return true;
      }
      return false;
    },
    customEmailBoxes() {
      return this.editedScheduledReport.name == "Custom";
    },
  },
  mounted() {
    this.isEdit = !!this.globalReport.emails;
    if (this.globalReport.emails) {
      this.editedScheduledReport = merge(this.editedScheduledReport, this.globalReport);
    } else {
      this.saveButton = true;
    }
    if (!this.globalReport.emails && !this.editedScheduledReport.companiesList.includes(this.$store.getters.getSelectedCompany)) {
      this.editedScheduledReport.companiesList.push(this.$store.getters.getSelectedCompany);
    }
  },
  methods: {
    ...fieldValidators,
    toggleEnableMaxSize(val) {
      //se per qualche motivo mette a mano -1 e disabilita la check, rimane bloccato il salvataggio, allora ripristino al default il valore dei mb
      if (!val && this.editedScheduledReport.emailBoxConf.attachments.report.maxMbSize < 1) {
        this.editedScheduledReport.emailBoxConf.attachments.report.maxMbSize = 10;
      }
    },
    checkEmptyField() {
      if (this.editedScheduledReport.emails.length === 0) {
        this.form.isValid = false;
      } else if (this.editedScheduledReport.emails.length > 0 && this.editedScheduledReport.name.length > 0) {
        this.form.isValid = true;
      }
    },
    async submitGlobalReport() {
      if (this.form.isValid) {
        //salvataggio report (sia nuovo che in caso di edit)
        this.form.submitLoading = true;
        let userProfile = merge({}, this.$store.getters.getUser);
        userProfile.info.name = this.$store.getters.getUserName;
        userProfile.info.surname = this.$store.getters.getUserSurname;
        userProfile.info.globalScheduledReport = this.editedScheduledReport;
        try {
          await this.$http.put("/user/" + userProfile._id, userProfile);
          this.$store.dispatch("UpdateGlobalScheduledReport", this.editedScheduledReport).then(() => {
            this.$emit("globalReportUpdated", this.editedScheduledReport);
            this.saveButton = false;
          });
        } catch (e) {
          this.$emit("failUpdateGlobalReport");
        } finally {
          this.form.submitLoading = false;
        }
      }
    },
    async onDeleteScheduledReportConfirm() {
      let userProfile = merge({}, this.$store.getters.getUser);
      userProfile.info.globalScheduledReport = {};
      this.editedScheduledReport = {
        emails: [],
        enabledScheduling: false,
        formats: [],
        name: [],
        report: [],
        date: [],
        companiesList: [],
        emailBoxConf: {
          name: "",
          email: "",
          username: "",
          password: "",
          retypePassword: "",
          secure: false,
          host: "",
          port: "",
          protocol: "smtp",
          authentication: false,
          serviceClient: "",
          privateKey: "",
          authType: "",
        },
      };
      try {
        await this.$http.put("/user/" + userProfile._id, userProfile);
        this.$store.dispatch("UpdateGlobalScheduledReport", this.editedScheduledReport).then(() => {
          this.$emit("deleteGlobalReport");
          this.saveButton = true;
          this.showConfirm = false;
        });
      } catch (e) {
        this.$emit("failUpdateGlobalReport");
      } finally {
        this.form.submitLoading = false;
      }
    },
    async testConfiguration() {
      this.form.submitLoading = true;
      try {
        await this.$httpAuth.post("/scheduled-report/test-global-scheduled-report", this.editedScheduledReport);
        EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, {
          message: "Test scheduled report: OK!",
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
          message: "Test scheduled report failed",
        });
      } finally {
        setTimeout(() => {
          this.form.submitLoading = false;
        }, 15000);
      }
    },
    async testEmailBoxConnection() {
      if (this.form.isValid) {
        this.form.submitLoading = true;
        this.form.isSubmitted = true;
        try {
          const response = await this.$httpAuth.post("/scheduled-report/test-email-box", this.editedScheduledReport.emailBoxConf);
          if (response.data.data) {
            EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, {
              message: "Test Email Boxes custom: OK!",
            });
          } else {
            EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
              message: "Test Email Boxes custom failed",
            });
          }
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
            message: "Test Email Boxes custom failed",
          });
        } finally {
          this.form.isSubmitted = false;
          setTimeout(() => {
            this.form.submitLoading = false;
          }, 15000);
        }
      }
    },
    deleteErrorMessage() {
      this.customErrorMessage = "";
    },
  },
};
</script>
<style>
.emailRecipientComboBox div[role="combobox"] {
  min-height: 32px;
}
</style>
