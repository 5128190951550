<template>
  <v-container fluid class="pa-12 mb-12">
    <ResultSnackbar ref="resultSnackbar" />

    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogDeny="showConfirm = false" @dialogConfirm="onDeleteConfirm" />

    <!-- widget di creazione -->
    <v-row v-if="newWidget">
      <v-col cols="12">
        <v-card raised tile>
          <v-system-bar dark window color="primary">
            <v-icon color="white">
              mdi-chart-bar
            </v-icon>
            <v-card-title class="white--text">
              {{ newWidget.title }}
            </v-card-title>
          </v-system-bar>
          <v-row>
            <v-col
              offset="0"
              cols="12"
              offset-xs="1"
              xs="5"
              offset-md="1"
              md="4"
              class="px-4"
            >
              <div style="height: 200px">
                <v-form v-model="newDashboardFormValid">
                  <v-select v-model="newWidget.typeSelected" :items="getAvailableCharts()" label="Select the widget type" :rules="[requiredField]" />
                  <div v-if="newWidget.typeSelected === 'word_cloud'">
                    <v-select
                      v-model="newWidget.engineSelected"
                      :items="getAvailableEngines"
                      item-text="name"
                      item-value="name"
                      :return-object="false"
                      label="Select the engine"
                      :rules="[requiredField]"
                    />
                  </div>
                  <div v-else-if="newWidget.typeSelected === 'bot_details'">
                    <v-select v-model="newWidget.botSelected" :items="getAvailableBot" label="Select the bot" return-object :rules="[requiredField]" />
                  </div>
                </v-form>
              </div>
              <div>
                <v-btn color="success" :class="getButtonMargin" :loading="loading" :disabled="!newDashboardFormValid" @click="saveNewWidget()">
                  <v-icon left>
                    mdi-floppy
                  </v-icon>Save
                </v-btn>
                <v-btn color="error" :class="getButtonMargin" class="ml-3" @click="newWidget = null">
                  <v-icon left>
                    mdi-close
                  </v-icon>Cancel
                </v-btn>
              </div>
            </v-col>
            <v-col
              offset="0"
              cols="12"
              xs="5"
              offset-md="1"
              md="5"
              align-self="center"
            >
              <v-img v-if="newWidget.typeSelected" :src="getImageForChart(newWidget.typeSelected)" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- lista widget -->
    <v-row :class="getMainRowMargin">
      <v-col cols="12">
        <draggable v-model="dashboard" handle=".elementToDrag" @end="saveDashboard">
          <v-card v-for="(widget, index) in getWidgetList" :key="'darshboard_widget_' + index" class="mb-8" raised tile>
            <v-toolbar class="headingCard" dark flat dense>
              <v-card-title class="elementToDrag pointer">
                <v-icon left>
                  mdi-cursor-move
                </v-icon>
                {{ widget.title }}
              </v-card-title>
              <v-spacer />
              <v-btn
                v-show="(widget.type === 'word_cloud' || widget.type === 'bubble_chart') && hideSettingsButton"
                icon
                text
                small
                class="mx-1"
                @click="openConfiguration(index, widget)"
              >
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                text
                tile
                small
                class="mx-1"
                :disabled="widget.disableReload"
                @click="refreshWidget(index, widget)"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                icon
                text
                tile
                small
                class="mx-1"
                @click="deleteWidget(index, widget)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row v-if="loaded">
              <v-col cols="12">
                <CompanyEfficiency
                  v-if="widget.type == 'usage_chart' || widget.type == 'mm_usage_chart'"
                  :ref="widget.widgetId"
                  :key="'widget_' + widget.widgetId"
                  :botList="botList"
                  :widget="widget"
                />
                <BotEfficiency
                  v-else-if="widget.type == 'bot_details'"
                  :ref="widget.widgetId"
                  :key="'widget_' + widget.widgetId + widget.type"
                  :widget="widget"
                  :botList="botList"
                  :botOverviewData="botOverviewData"
                />
                <FlowsPerformance
                  v-else-if="widget.type == 'bubble_chart'"
                  :ref="widget.widgetId"
                  :key="'widget_' + widget.widgetId"
                  :widget="widget"
                  :botList="botList"
                  @disableWidgetSettings="disableWidgetSettings"
                />
                <WordCloud
                  v-else-if="widget.type == 'word_cloud'"
                  :ref="widget.widgetId"
                  :key="'widget_' + widget.widgetId"
                  :widget="widget"
                  :botList="botList"
                  @disableWidgetSettings="disableWidgetSettings"
                />
                <div v-else>
                  <v-skeleton-loader type="image" tile class="mx-auto" />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </draggable>
      </v-col>
    </v-row>
    <AddNewItemButton name="Widget" @addNewItem="addNewWidget" />
  </v-container>
</template>

<script>
import AddNewItemButton from "../components/other/AddNewItemButton";
import ResultSnackbar from "../components/other/ResultSnackbar";
import ConfirmDialog from "../components/other/ConfirmationDialog";
import spacing from "../helpers/spacing";
import draggable from "vuedraggable";
import CompanyEfficiency from "../components/analytics/CompanyEfficiency";
import BotEfficiency from "../components/analytics/BotEfficiency";
import FlowsPerformance from "../components/analytics/FlowsPerformance";
import WordCloud from "../components/analytics/WordCloud";
import scroller from "../helpers/scrollToTop";
import EventBus from "../event-bus";
import fieldValidator from "../helpers/fieldValidators";

export default {
  name: "Home",
  components: {
    AddNewItemButton,
    ResultSnackbar,
    draggable,
    CompanyEfficiency,
    BotEfficiency,
    FlowsPerformance,
    WordCloud,
    ConfirmDialog,
  },
  data() {
    return {
      loaded: false,
      company: this.$store.getters.getSelectedCompany,
      engineList: [],
      botList: [],
      forceRerender: 0,

      dashboard: [],
      newWidget: null,
      //disableReload: false,
      reloadingWidgetTimeout: null,

      showConfirm: false,
      selectedIndex: -1,
      deleteMessage: "",
      newDashboardFormValid: false,
      loading: false,
      disableSettings: {},
      hideSettingsButton: true,
      botOverviewData: {},
    };
  },
  computed: {
    isMarketplaceCompany() {
      return this.$store.getters.isMarketplaceCompany;
    },
    getWidgetList() {
      this.forceRerender;
      this.dashboard.forEach((widget) => {
        switch (widget.type) {
          case "mm_usage_chart":
            widget.title = "Bot" + (this.$vuetify.breakpoint.smAndUp ? " Overview" : "");
            break;
          case "usage_chart":
            widget.title = this.getCompanyNameFromUUID(this.company) + (this.$vuetify.breakpoint.smAndUp ? " Overview" : "");
            break;
          case "bot_details":
            widget.title = widget.conf.bot.name + (this.$vuetify.breakpoint.smAndUp ? "'s Efficiency" : "");
            break;
          case "bubble_chart":
            widget.title = "Flows Performance" + (this.$vuetify.breakpoint.smAndUp ? " - Last 30 days" : "");
            break;
          case "word_cloud":
            widget.title = widget.conf.engine + (this.$vuetify.breakpoint.smAndUp ? " Top intents - Last 30 days" : "");
            break;
        }
      });
      return this.dashboard;
    },
    getAvailableEngines() {
      return this.engineList
        .filter((engine) => {
          return !this.dashboard.some((w) => w.type == "word_cloud" && w.conf.engine == engine.name);
        })
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
    getAvailableBot() {
      return this.botList
        .map((bot) => {
          return { text: bot.name, value: bot.bot_id };
        })
        .filter((bot) => {
          return !this.dashboard.some((w) => w.type == "bot_details" && w.conf.bot.name == bot.text);
        })
        .sort((a, b) => {
          if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          }
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.UPDATE_DASHBOARD, this.updateDashboard);
    EventBus.$on(this.$store.getters.getEvents.INIT_SUCCESS, this.loadDashboardPage);
    EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    this.loadDashboardPage("load");
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.UPDATE_DASHBOARD, this.updateDashboard);
    EventBus.$off(this.$store.getters.getEvents.INIT_SUCCESS, this.loadDashboardPage);
  },
  methods: {
    ...spacing,
    ...scroller,
    ...fieldValidator,
    loadDashboardPage(status) {
      if (status == "load" && this.$store.getters.isLogged) {
        this.getDashboard().finally(this.getEnginesList()).finally(this.getBotList());
      }
      if (status == "init" && this.dashboard.length == 0) {
        this.getDashboard().finally(this.getEnginesList()).finally(this.getBotList());
      }
    },
    openConfiguration(index, widget) {
      if (!this.disableSettings[widget.widgetId]) {
        this.$refs[widget.widgetId][0].openConfiguration();
      }
    },
    getAvailableCharts() {
      let res = [
        { value: "bot_details", text: "Bot Efficiency" },
        { value: "word_cloud", text: "Intent Distribution" },
      ];
      if (!this.dashboard.some((w) => w.type === "usage_chart") && !this.isMarketplaceCompany) {
        res.push({ value: "usage_chart", text: "Company Overview" });
      }
      if (!this.dashboard.some((w) => w.type === "mm_usage_chart") && this.isMarketplaceCompany) {
        res.push({ value: "mm_usage_chart", text: "Bot Overview" });
      }
      if (!this.dashboard.some((w) => w.type === "bubble_chart")) {
        res.push({ value: "bubble_chart", text: "Flow Performance" });
      }
      return res.sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    getImageForChart(type) {
      if (type === "mm_usage_chart") {
        return require("../assets/img/dashboard/preview/" + type + ".png");
      } else {
        return require("../assets/img/dashboard/preview/" + type + ".png");
      }
    },
    addNewWidget() {
      if (!this.newWidget) {
        this.scrollToTop().finally(() => {
          this.newWidget = {
            type: "new",
            typeSelected: "",
            title: "Add New Widget",
            engineSelected: "",
            botSelected: {},
          };
        }, 100);
      }
    },
    async getEnginesList() {
      try {
        const response = await this.$http.get("/semantic-engine");
        this.engineList = response.data || [];
      } catch (e) {
        this.engineList = [];
      }
    },
    async getBotList() {
      try {
        const response = await this.$http.get("/bot");
        this.botList = Object.values(response.data);
      } catch (e) {
        this.botList = [];
      }
    },
    async getDashboard() {
      try {
        const response = await this.$http.get("/analytics/dashboards");
        this.dashboard = response.data || [];
        for (let widget of this.dashboard) {
          widget.disableReload = false;
          //richieste per il companyEfficiencyWidget
          //perchè con il drag&drop tra widget bot efficiency non aggiorna correttamente i componenti figli
          if (widget.type === "bot_details") {
            let response = {};
            try {
              response = await this.$httpAuth.get("/dashboard/bot-efficiency/" + widget.conf.bot.botId);
              let dataSet = response.data.dashboard;
              if (dataSet) {
                dataSet.currentStats.sort((a, b) => {
                  if (a.state.toLowerCase() > b.state.toLowerCase()) {
                    return 1;
                  }
                  if (a.state.toLowerCase() < b.state.toLowerCase()) {
                    return -1;
                  }
                  return 0;
                });
                dataSet.previousStats.sort((a, b) => {
                  if (a.state.toLowerCase() > b.state.toLowerCase()) {
                    return 1;
                  }
                  if (a.state.toLowerCase() < b.state.toLowerCase()) {
                    return -1;
                  }
                  return 0;
                });
              }
              this.botOverviewData[widget.conf.bot.botId] = dataSet;
            } catch {
              response = {};
            }
          }
        }
      } catch (e) {
        this.dashboard = [];
      } finally {
        this.loaded = true;
      }
    },
    deleteWidget(index, widget) {
      if (this.dashboard.length > 1) {
        this.selectedIndex = index;
        this.deleteMessage = "Widget " + widget.title + " will be deleted!";
        this.showConfirm = true;
      } else {
        this.$refs.resultSnackbar.showError("At least one widget should be present!");
      }
    },
    onDeleteConfirm() {
      this.showConfirm = false;
      this.dashboard.splice(this.selectedIndex, 1);
      this.saveDashboard();
    },
    saveNewWidget() {
      this.loading = true;
      let conf = null;
      switch (this.newWidget.typeSelected) {
        case "mm_usage_chart":
          conf = {};
          break;
        case "usage_chart":
          conf = {};
          break;
        case "bubble_chart":
          conf = {};
          break;
        case "bot_details":
          conf = {
            bot: {
              name: this.newWidget.botSelected.text,
              botId: this.newWidget.botSelected.value,
            },
          };
          break;
        case "word_cloud":
          conf = {
            engine: this.newWidget.engineSelected,
          };
          break;
      }

      this.dashboard.push({
        widgetId: "wid-id-" + this.guid(),
        type: this.newWidget.typeSelected,
        conf: conf,
        refresh: false,
      });
      this.saveDashboard().finally(() => (this.newWidget = null));
    },
    updateDashboard(newWidget) {
      for (let i = 0; i < this.dashboard.length; i++) {
        if (this.dashboard[i].widgetId == newWidget.widgetId) {
          this.dashboard[i] = newWidget;
        }
      }
      this.saveDashboard();
    },
    async saveDashboard() {
      try {
        await this.$http.post("/analytics/dashboards", {
          dashboard: this.dashboard,
        });
        this.$refs.resultSnackbar.showSuccess("Dashboard updated!");
      } finally {
        this.loading = false;
      }
    },
    async refreshWidget(index, widget) {
      widget.disableReload = true;
      try {
        switch (widget.type) {
          case "mm_usage_chart":
          case "usage_chart":
            await this.$httpAuth.get("/dashboard/company-overview/" + this.company + "?action=sync", {
              timeout: 300000,
            });
            break;
          case "bot_details":
            await this.$httpAuth.get("/dashboard/bot-efficiency/" + widget.conf.bot.botId + "?action=sync", {
              timeout: 300000,
            });
            await this.$httpAuth.get("/dashboard/bot-statistics/" + widget.conf.bot.botId + "?action=sync", {
              timeout: 300000,
            });
            break;
          case "bubble_chart":
            await this.$httpAuth.get("/dashboard/flows-performance?action=sync", {
              timeout: 300000,
            });
            break;
          case "word_cloud":
            await this.$httpAuth.get("/dashboard/intent-distribution/" + widget.conf.engine + "?action=sync", {
              timeout: 300000,
            });
            break;
        }
        let oldType = widget.type;
        widget.type = "reload";
        this.$refs.resultSnackbar.showSuccess("Chart data reloaded!");
        widget.type = oldType;
      } catch (e) {
        this.$refs.resultSnackbar.showError("Fail to reload chart data!");
      } finally {
        this.reloadingWidgetTimeout = setTimeout(() => {
          this.reloadingWidgetTimeout = null;
          widget.disableReload = false;
          this.forceRerender++;
        }, 600000);
      }
    },
    guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + s4() + s4();
    },
    disableWidgetSettings(data) {
      this.hideSettingsButton = false;
      this.disableSettings[data.id] = data;
    },
    getCompanyNameFromUUID(companyUUID) {
      const companies = this.$store.state.company.list;
      if (companies && companies.length > 0) {
        const company = companies.find((company) => company.uuid === companyUUID);
        return company ? company.name : "";
      }
      return "";
    },
  },
};
</script>
