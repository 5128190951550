<template>
  <v-form ref="scheduledReportForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container :class="getCreationContainerMargin">
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteScheduledReportConfirm" @dialogDeny="showConfirm = false" />

      <!-- riga nome e selezione mailBox -->
      <v-row :class="getFormRowMargin">
        <v-col v-show="!isEdit" cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-model.trim="editedScheduledReport.id"
            label="Report name"
            :rules="[validateScheduledReportName]"
            prepend-inner-icon="mdi-card-bulleted"
            :autocomplete="$store.getters.disableAutocomplete"
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-switch v-model="editedScheduledReport.enabledScheduling" class="ml-4" color="primary" label="Enable schedule" v-on="on" />
            </template>
            <span>Enable the scheduled report</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-select
            v-model="editedScheduledReport.name"
            :items="emailBoxList"
            :rules="[requiredField]"
            label="Choose an Email Box"
            prepend-inner-icon="email"
            validate-on-blur
          />
        </v-col>
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-combobox
            v-model="editedScheduledReport.emails"
            multiple
            chips
            small-chips
            deletable-chips
            :rules="[requiredField, isValidArrayEmail]"
            label="Subscribers' emails"
            prepend-inner-icon="contact_mail"
            validate-on-blur
            class="emailRecipientComboBox"
            @change="checkEmptyField"
          />
        </v-col>
      </v-row>

      <v-form v-if="customEmailBoxes" v-model="formEmailConnection.isValid">
        <!-- Riga per madifica mail (in creazione visualizza Name) -->
        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model="editedScheduledReport.emailBoxConf.email"
              label="Email"
              :rules="[isValidEmail]"
              prepend-inner-icon="email"
              :autocomplete="$store.getters.disableAutocomplete"
              validate-on-blur
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox v-model="editedScheduledReport.emailBoxConf.useAuth" class="mx-2" label="Use Authentication" />
          </v-col>
        </v-row>

        <!-- Riga susername e tipo di auntenticazione -->
        <v-row :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.username"
              label="Username"
              :rules="[requiredField]"
              prepend-inner-icon="person"
              autofocus
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col v-if="editedScheduledReport.emailBoxConf.useAuth" cols="12" sm="6">
            <v-tooltip top>
              <template #activator="{ off }">
                <div v-on="off">
                  <v-select
                    v-model="editedScheduledReport.emailBoxConf.serviceAuth"
                    :items="$store.state.enums.serviceAuthTypes"
                    color="primary"
                    label="Service Authentication"
                  />
                </div>
              </template>
              <span>Service Authentication</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <!-- riga password -->
        <v-row v-if="editedScheduledReport.emailBoxConf.useAuth && !editedScheduledReport.emailBoxConf.serviceAuth" align="center" :class="getFormRowMargin">
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="editedScheduledReport.emailBoxConf.password == null || editedScheduledReport.emailBoxConf.password.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.retypePassword"
              label="Retype Password"
              :type="showPasswordRetype ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="editedScheduledReport.emailBoxConf.retypePassword == null || editedScheduledReport.emailBoxConf.retypePassword.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Riga solo per la modifica password -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'basic'"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="!editedScheduledReport.emailBoxConf.password || editedScheduledReport.emailBoxConf.password.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPassword" tabindex="-1" @click="showPassword = !showPassword">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.retypePassword"
              label="Retype Password"
              :type="showPasswordRetype ? 'text' : 'password'"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="lock"
              :error-messages="customErrorMessage"
              autocomplete="new-password"
              @change="deleteErrorMessage"
            >
              <template slot="append">
                <v-btn
                  depressed
                  icon
                  text
                  :disabled="!editedScheduledReport.emailBoxConf.retypePassword || editedScheduledReport.emailBoxConf.retypePassword.length < 1"
                  tabindex="-1"
                >
                  <v-icon v-show="!showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility_off
                  </v-icon>
                  <v-icon v-show="showPasswordRetype" tabindex="-1" @click="showPasswordRetype = !showPasswordRetype">
                    visibility
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Riga per google oauth2 authentication -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'google-oauth2'"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.serviceClient"
              label="Client ID"
              hint="Service client ID relative to the service account created"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-account-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.privateKey"
              label="Private key"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="mdi-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
        </v-row>

        <!-- Riga per office365 oauth2 authentication -->
        <v-row
          v-if="editedScheduledReport.emailBoxConf.useAuth && editedScheduledReport.emailBoxConf.serviceAuth == 'office365-oauth2'"
          :key="0"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.tenant"
              label="Directory (Tenant) ID"
              :rules="[requiredField]"
              prepend-inner-icon="person"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.applicationClientId"
              label="Application (Client) ID"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-account-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
          <v-col cols="12" sm="4" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.clientSecret"
              label="Client Secret"
              :rules="[
                isEdit
                  ? () => {
                    return true;
                  }
                  : requiredField,
              ]"
              prepend-inner-icon="mdi-key"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-col>
        </v-row>

        <!-- Riga per configurazione server -->
        <v-row
          v-if="!editedScheduledReport.emailBoxConf.useAuth || editedScheduledReport.emailBoxConf.serviceAuth != 'office365-oauth2'"
          align="center"
          :class="getFormRowMargin"
        >
          <v-col cols="12" sm="6" md="6" :class="getFormColMargin">
            <v-text-field
              v-model.trim="editedScheduledReport.emailBoxConf.host"
              label="Server"
              type="text"
              :rules="[requiredField]"
              prepend-inner-icon="mdi-server-network"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2" :class="getFormColMargin">
            <v-text-field
              v-model.number="editedScheduledReport.emailBoxConf.port"
              label="Port"
              type="number"
              :rules="[requiredField, positiveNumber, serverPort]"
              prepend-inner-icon="mdi-swap-horizontal"
              min="0"
              max="36535"
            />
          </v-col>
          <v-col cols="12" sm="3" md="2" :class="getFormColMargin">
            <v-btn-toggle>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-switch v-model="editedScheduledReport.emailBoxConf.secure" color="primary" label="Secure" v-on="on" />
                </template>
                <span>Enable TLS security</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <!-- La seguente colonna è hidden perche il campo non è utilizzato dalla libreria -->
          <v-col cols="12" sm="2" :class="getFormColMargin" hidden>
            <v-select v-model="editedScheduledReport.emailBoxConf.protocol" :items="protocols" label="Protocol" />
          </v-col>
        </v-row>

        <!-- riga per maxMbSize -->
        <v-row :class="getFormRowMargin" align="center">
          <v-col cols="12" sm="5" class="getFormColMargin">
            <v-checkbox
              v-model="editedScheduledReport.emailBoxConf.attachments.report.enableMaxSize"
              class="my-2"
              label="Enable threshold for reports attachments"
              @change="toggleEnableMaxSize"
            />
          </v-col>
          <v-col v-show="editedScheduledReport.emailBoxConf.attachments.report.enableMaxSize" cols="12" sm="4" class="getFormColMargin">
            <v-text-field
              v-model.number="editedScheduledReport.emailBoxConf.attachments.report.maxMbSize"
              suffix="MB"
              label="Maximum attachments threshold"
              type="number"
              min="1"
              hint="If the attachments of a scheduled report exceed this threshold, they will be saved to a bucket for the configured retention period"
              :rules="[greaterThanZero]"
            />
          </v-col>
        </v-row>

        <v-row :class="getFormRowMargin">
          <!-- Buttons -->
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn
              :class="getButtonMargin"
              color="primary"
              :loading="formEmailConnection.loading"
              :disabled="!formEmailConnection.isValid || formEmailConnection.loading"
              @click.prevent="testEmailBoxConnection"
            >
              <v-icon left>
                mdi-test-tube
              </v-icon>
              <span>Test Connection</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" :class="getFormColMargin">
          <v-subheader>Select which report you want to send:</v-subheader>
          <v-chip-group v-model="editedScheduledReport.report" column multiple active-class="primary">
            <v-chip
              v-for="reporty in reportType"
              :key="reporty"
              :value="reporty"
              class="ma-2"
              filter
              label
            >
              {{ reporty }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-subheader>Select the recurrence:</v-subheader>
          <v-radio-group v-model="editedScheduledReport.date" row class="ml-4">
            <template v-for="period in periods">
              <v-radio :key="period" :label="period" :value="period" color="primary" />
            </template>
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-subheader>Select the report format/formats:</v-subheader>
          <v-btn-toggle class="ml-4">
            <template v-for="format in fileFormats">
              <v-switch :key="format" v-model="editedScheduledReport.formats" color="primary" :label="format" :value="format" />
            </template>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col v-if="hasRequiredRole($store.getters.getRoles.USER)" cols="12" sm="6" :class="getFormColMargin">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="
              !form.isValid ||
                (!formEmailConnection.isValid && customEmailBoxes) ||
                form.deleteLoading ||
                editedScheduledReport.report.length === 0 ||
                editedScheduledReport.formats.length === 0
            "
            @click.prevent="submitReport"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="isEdit">Update</span>
            <span v-else>Save</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="primary"
            :loading="form.testLoading"
            :disabled="
              !form.isValid ||
                (!formEmailConnection.isValid && customEmailBoxes) ||
                editedScheduledReport.report.length === 0 ||
                editedScheduledReport.formats.length === 0
            "
            @click.prevent="testConfiguration"
          >
            <v-icon left>
              mdi-test-tube
            </v-icon>
            <span>Test Report</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.deleteLoading"
            :disabled="isEdit && (!form.isValid || form.submitLoading)"
            @click.prevent="deleteReport"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import spacing from "../../helpers/spacing";
import merge from "deepmerge";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "ScheduledReportForm",
  components: {
    ConfirmDialog,
  },
  props: {
    "report": {
      type: Object,
      required: true
    },
    "emailBoxList": {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      //Flag per dialog di conferma cancellazione
      showConfirm: false,
      deleteMessage: "Report " + this.report.id + " will be deleted!",

      //Flag per capire se siamo in edit o creazione
      isEdit: this.report.id != "",

      formEmailConnection: {
        isValid: true,
        loading: false,
      },

      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
        testLoading: false,
      },
      //Copia dell'oggetto editato
      protocols: ["SMTP"],
      customErrorMessage: null,
      showPassword: false,
      showPasswordRetype: false,
      // useAuth: true,
      // serviceAuth: false,
      editedScheduledReport: {
        date: "monthly",
        emails: [],
        enabledScheduling: true,
        formats: [],
        name: [],
        report: [],
        id: "",
        emailBoxConf: {
          name: "",
          email: "",
          username: "",
          password: "",
          retypePassword: "",
          secure: false,
          host: "",
          port: "",
          protocol: "smtp",
          authentication: false,
          serviceClient: "",
          privateKey: "",
          authType: "",
          useAuth: this.report.useAuth !== undefined ? this.report.useAuth : !!(this.report.authType || this.report.username),
          serviceAuth: this.report.serviceAuth || (this.report.authType ? "google-oauth2" : "basic"),
          tenant: "",
          applicationClientId: "",
          attachments: {
            report: {
              enableMaxSize: this.report?.attachments?.report?.enableMaxSize || false,
              maxMbSize: this.report?.attachments?.report?.maxMbSize || 10,
            },
          },
        },
      },
      reportType: ["Survey details", "Survey NPS", "Dialog Detail", "Bot Efficiency", "Flow Performance", "Intent Distribution"],
      periods: ["daily", "weekly", "monthly"],
      fileFormats: ["excel", "csv"],
    };
  },
  computed: {
    ...spacing,
    customEmailBoxes() {
      return this.editedScheduledReport.name == "Custom";
    },
  },
  mounted() {
    this.editedScheduledReport = merge(this.editedScheduledReport, this.report);
    if (this.isEdit) {
      setTimeout(() => this.$refs.scheduledReportForm.validate(), 100);
    }
  },
  methods: {
    ...fieldValidators,
    ...checkRoleAndPermission,
    toggleEnableMaxSize(val) {
      //se per qualche motivo mette a mano -1 e disabilita la check, rimane bloccato il salvataggio, allora ripristino al default il valore dei mb
      if (!val && this.editedScheduledReport.emailBoxConf.attachments.report.maxMbSize < 1) {
        this.editedScheduledReport.emailBoxConf.attachments.report.maxMbSize = 10;
      }
    },
    async submitReport() {
      if (this.form.isValid) {
        if (this.editedScheduledReport.report.length > 0) {
          //salvataggio report (sia nuovo che in caso di edit)
          this.form.submitLoading = true;
          try {
            const response = await this.$http.post("/analytics/scheduled-report", this.editedScheduledReport);
            let data = response.data;
            data.id = this.editedScheduledReport.id;
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT, data);
          } catch (err) {
            EventBus.$emit(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT_FAIL, { message: "Fail to create scheduled report" + this.id });
          } finally {
            this.form.submitLoading = false;
          }
        } else {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_SCHEDULED_REPORT_FAIL, {
            message: "At least one report should be selected",
          });
        }
      }
    },
    deleteReport() {
      if (this.isEdit) {
        //se report già presente
        this.showConfirm = true;
      } else {
        //se report in creazione
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_SCHEDULED_REPORT_CREATION);
      }
    },
    async onDeleteScheduledReportConfirm() {
      this.showConfirm = false;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete("/analytics/scheduled-report/" + this.editedScheduledReport.id);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT, {
          scheduledReport: this.report.id,
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_SCHEDULED_REPORT_FAIL, {
          message: "Fail to delete scheduled report " + this.editedScheduledReport.id,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    async testConfiguration() {
      this.form.testLoading = true;
      try {
        await this.$httpAuth.post("/scheduled-report/test-scheduled-report", this.editedScheduledReport);
        EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, {
          message: "Test scheduled report: OK!",
        });
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
          message: "Test scheduled report failed",
        });
      } finally {
        setTimeout(() => {
          this.form.testLoading = false;
        }, 15000);
      }
    },
    async testEmailBoxConnection() {
      if (this.formEmailConnection.isValid) {
        this.formEmailConnection.loading = true;
        try {
          const emailBoxConf = this.editedScheduledReport.emailBoxConf;
          if (this.editedScheduledReport.id) {
            emailBoxConf.reportId = this.editedScheduledReport.id;
          }
          const response = await this.$httpAuth.post("/scheduled-report/test-email-box", this.editedScheduledReport.emailBoxConf);
          if (response.data.data) {
            EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_SUCCESS, {
              message: "Test Email Boxes custom: OK!",
            });
          } else {
            EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
              message: "Test Email Boxes custom failed",
            });
          }
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.TEST_SCHEDULED_REPORT_FAIL, {
            message: "Test Email Boxes custom failed",
          });
        } finally {
          setTimeout(() => {
            this.formEmailConnection.loading = false;
          }, 15000);
        }
      }
    },
    checkEmptyField() {
      if (!this.editedScheduledReport.emails.length > 0) {
        this.form.isValid = false;
      } else if (this.editedScheduledReport.emails.length > 0 && this.editedScheduledReport.name.length > 0 && this.editedScheduledReport.id != "") {
        this.form.isValid = true;
      }
    },
    deleteErrorMessage() {
      this.customErrorMessage = "";
    },
  },
};
</script>
<style>
.emailRecipientComboBox div[role="combobox"] {
  min-height: 32px;
}
</style>
