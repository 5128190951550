//import * as easings from "vuetify/lib/services/goto/easing-patterns";

export default {
  scrollToTop: function (timeout) {
    return new Promise((resolve) => {
      if (!timeout) {
        timeout = 300;
      }
      let container = document.querySelector("#mainScrollBar");
      if (container) container.scrollTop = 0;
      //Da quando usiamo la perfect scrollbar non possiamo usare la funzione di vuetify ma va scrollato il main container al top
      // this.$vuetify.goTo(0, {
      //   duration: timeout,
      //   type: "number",
      //   offset: 0,
      //   easing: "easeInOutCubic",
      //   easings: Object.keys(easings)
      // });
      setTimeout(() => resolve(), timeout++);
    });
  },
};
