<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />

    <SearchBar
      :searchString="searchString"
      title="Manage your agent"
      @input="
        searchString = $event;
        forceRerender++;
        pageNumber = 1;
      "
    />

    <v-row justify="center" :class="getMainRowMargin">
      <v-col v-if="noProfileCreated" cols="12">
        <v-alert v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" type="warning" icon="mdi-alert">
          <v-row>
            <v-col cols="12">
              To create an agent you first need a profile: go to the Profiles page or using the shortcut button below:
            </v-col>
            <v-col cols="12" class="pt-4" align="center" justify="center">
              <router-link class="white--text headline" to="/profiles" style="text-decoration: none">
                <v-btn small color="white" class="primary--text">
                  <v-icon x-small left>
                    mdi-arrow-right
                  </v-icon> to the Profiles page
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert v-else type="warning">
          No Agents configured
        </v-alert>
      </v-col>
      <v-col v-else cols="12">
        <v-alert type="info" icon="mdi-information" color="primary" class="mx-1">
          <v-row class="no-gutters">
            <v-col cols="12">
              ConvyAI Agent desktop url ->
              <a class="white--text" :href="getAgentDesktopURL()" target="_blank">{{ getAgentDesktopURL() }}</a>
              <CopyToClipboard message="Link Copied!" :data="getAgentDesktopURL()" />
            </v-col>
          </v-row>
        </v-alert>
      </v-col>

      <v-col v-if="showWarning && getAgentList.length == 0 && !newAgent && !noProfileCreated && searchString == ''" cols="12">
        <v-alert type="warning">
          No Agents configured
        </v-alert>
      </v-col>
      <v-col v-else-if="showWarning && getAgentList.length == 0 && !newAgent && !noProfileCreated && searchString != ''" cols="12">
        <v-alert type="warning">
          No Agent match your search
        </v-alert>
      </v-col>

      <!-- Aggiunta Agents -->
      <v-expansion-panels v-if="newAgent" v-model="expansionPanelCreation" popout :class="getMarginNewCard">
        <v-expansion-panel @change="updateMainScrollbar">
          <v-expansion-panel-header>
            <v-row align="center" class="spacer" no-gutters>
              <v-col cols="8">
                New Agent
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <AgentForm :agents="agents" :agent="newAgent" :profiles="profiles" :permissions="availPermissions" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Bottone select e actions -->
      <v-row v-if="availPermissions.length > 0 && getAgentList.length > 1" class="pb-8 px-7 pt-2">
        <div class="text-center mr-2">
          <v-btn color="primary" min-width="117" small @click="selectAll()">
            <span v-if="selectAllAgent">deselect all</span>
            <span v-else>select all</span>
          </v-btn>
        </div>
        <div>
          <v-menu top :close-on-click="true">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                color="primary"
                small
                class="mx-1"
                :disabled="checkIfAgentIsSelected"
                v-on="on"
              >
                actions
              </v-btn>
            </template>

            <v-list>
              <v-list-item-group>
                <v-list-item v-for="(item, index) in getActions" :key="index" @click="itemSelected(item.title)">
                  <v-list-item-title style="cursor: pointer">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-row>

      <!-- Visualizzazione lista Agents-->
      <v-expansion-panels v-if="availPermissions.length > 0" :key="forceRerender + '_ep'" v-model="expansionPanel" popout :class="getMainExpansionPanelMargin">
        <v-expansion-panel v-for="(agent, id) in getAgentList" :key="'agent_' + id" @change="updateMainScrollbar">
          <v-hover v-slot="{ hover }">
            <v-expansion-panel-header :class="hover ? 'hoverBanner' : ''">
              <v-row align="center">
                <!-- check di selezione -->
                <v-col class="text-no-wrap" cols="1">
                  <v-checkbox
                    v-model="agent.selected"
                    :value="agent.selected"
                    color="primary"
                    @click.native.prevent="preventExpansionPanelBehavior($event)"
                    @change="selectItem()"
                  />
                </v-col>
                <!-- username -->
                <v-col class="text-no-wrap" lg="3" md="3" sm="4" cols="7">
                  <v-list-item dense>
                    <v-tooltip color="primary" top>
                      <template #activator="{ on }">
                        <v-list-item-content v-on="on">
                          <v-list-item-subtitle>Username</v-list-item-subtitle>
                          <v-list-item-title>{{ agent.authentication.credentials.username }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <span>{{ agent.info.name }} {{ agent.info.surname }}</span>
                    </v-tooltip>
                  </v-list-item>
                </v-col>
                <!-- profile -->
                <v-col class="text-truncate d-none d-sm-flex" lg="2" md="3" sm="4" cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Profile</v-list-item-subtitle>
                      <v-list-item-title>{{ getProfile(agent.roles) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- permission set -->
                <v-col class="text-truncate hidden-sm-and-down" lg="2" md="3" cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Permissions Set</v-list-item-subtitle>
                      <v-list-item-title>{{ getPermission(agent.roles) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- status -->
                <v-col class="text-truncate px-0" lg="1" md="2" sm="3" cols="4">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                      <v-list-item-icon class="ma-0 ml-2">
                        <v-icon v-if="getAgentStatusColor(agent) == 'gray'" color="grey">
                          mdi-account-circle
                        </v-icon>
                        <v-icon v-else-if="getAgentStatusColor(agent) == 'error'" color="error">
                          mdi-account-circle
                        </v-icon>
                        <v-icon v-else color="success">
                          mdi-account-circle
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- enabled -->
                <v-col class="hidden-md-and-down px-0" cols="1">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="agent.disabled">
                        Disabled
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else-if="checkExpirationDate(agent)">
                        Password Expired
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        Enabled
                      </v-list-item-subtitle>
                      <v-list-item-icon class="ma-0">
                        <v-btn class="ma-0 ml-1" :color="agent.disabled || checkExpirationDate(agent) ? 'error' : 'success'" x-small text depressed>
                          <v-icon v-if="agent.disabled" small>
                            mdi-close-circle
                          </v-icon>
                          <v-icon v-else-if="checkExpirationDate(agent)" small>
                            mdi-close-circle
                          </v-icon>
                          <v-icon v-else small>
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <!-- last update -->
                <v-col class="hidden-md-and-down" cols="2">
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle>Last Update</v-list-item-subtitle>
                      <v-list-item-title>{{ agent.metadata.modified_at | formatVerboseDateTime }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
          </v-hover>
          <v-expansion-panel-content>
            <v-divider />
            <AgentForm :key="forceRerender + id + '_af'" :agents="agents" :agent="agent" :profiles="profiles" :permissions="availPermissions" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <ReskillAgents
      v-if="reskillDialog"
      :actionSelected="actionSelected"
      :reskillDialog="reskillDialog"
      :agents="filtredAgents.filter((agent) => agent.selected)"
      :profiles="profiles"
      :permissions="availPermissions"
      @closeRiassignDialog="closeRiassignDialog($event)"
      @forceLogoutSuccess="forceLogoutSuccess"
    />
    <Pagination
      v-if="filtredAgents.length > 1"
      :items="filtredAgents.length"
      :pageSize="pageSize"
      :page="pageNumber"
      @updatePage="
        pageNumber = $event;
        scrollToTop();
        forceRerender++;
      "
      @updatePageSize="updatePageSize($event)"
    />

    <AddNewItemButton v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" name="Agent" @addNewItem="addNewAgent" />
  </v-container>
</template>

<script>
import ResultSnackbar from "../../components/other/ResultSnackbar";
import SearchBar from "../../components/other/SearchBar";
import spacing from "../../helpers/spacing";
import scroller from "../../helpers/scrollToTop";
import AddNewItemButton from "../../components/other/AddNewItemButton";
import AgentForm from "../../components/humanAgents/AgentForm";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import timeoutHelper from "@/helpers/timeout";
import CopyToClipboard from "../../components/other/CopyToClipboard";
import Pagination from "../../components/other/Pagination";
import ReskillAgents from "../../components/humanAgents/ReskillAgents";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "Agents",
  components: {
    CopyToClipboard,
    AgentForm,
    ResultSnackbar,
    SearchBar,
    AddNewItemButton,
    Pagination,
    ReskillAgents,
  },
  data() {
    return {
      agents: [],
      searchString: "",
      forceRerender: 0,
      newAgent: null,
      expansionPanel: null, //Indice del tab aperto
      expansionPanelCreation: null,
      profiles: [],
      noProfileCreated: false,
      showWarning: false,
      availPermissions: [],
      pageNumber: 1,
      filtredAgents: [],
      selectAllAgent: false,
      actions: [{ title: "Change profile" }, { title: "Enable" }, { title: "Disable" }, { title: "Force Logout" }],
      reskillDialog: false,
      actionSelected: null,
      agentDesktopList: [],
      pageSize: this.$store.state.pageSize,
      agentStatus: {},
      agentStatusInterval: null,
    };
  },
  computed: {
    ...spacing,
    getActions() {
      if (this.$store.getters.getUserRole === this.$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER) {
        return [this.actions[0]];
      }
      return this.actions;
    },
    getAgentList() {
      this.forceRerender;
      let filterData = this.filterData();
      return filterData.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
    },
    checkIfAgentIsSelected() {
      this.forceRerender;
      for (let agent of this.filtredAgents) {
        if (agent.selected) {
          return false;
        }
      }
      return true;
    },
  },
  async mounted() {
    await timeoutHelper.sleep(500);
    if (!this.$store.getters.isIntegrationEnabled("wcs")) {
      this.$router.push("/");
    }
    EventBus.$on(this.$store.getters.getEvents.EDIT_AGENT, this.onAgentUpdate);
    EventBus.$on(this.$store.getters.getEvents.DELETE_AGENT, this.deleteAgent);
    EventBus.$on(this.$store.getters.getEvents.CANCEL_AGENT_CREATION, this.cancelAgentCreation);
    EventBus.$on(this.$store.getters.getEvents.DELETE_AGENT_FAIL, this.deleteAgentFail);
    EventBus.$on(this.$store.getters.getEvents.EDIT_AGENT_FAIL, this.onAgentFailUpdate);
    EventBus.$on(this.$store.getters.getEvents.RESET_AGENT_PASSWORD, this.onAgentUpdate);
    EventBus.$on(this.$store.getters.getEvents.RESET_AGENT_PASSWORD_FAIL, this.onAgentUpdate);
    EventBus.$on(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
    EventBus.$on(this.$store.getters.getEvents.KICK_AGENT, this.onKickAgent);
    EventBus.$on(this.$store.getters.getEvents.KICK_AGENT_FAIL, this.onKickAgentFail);
    try {
      const profileResult = await this.$http.get("/human-agents/profile");
      this.profiles = profileResult.data;
      if (this.profiles.length === 0) {
        this.noProfileCreated = true;
      }
      const result = await this.$http.get("/human-agents/agent");
      this.agents = result.data;

      const agentDesktopResult = await this.$http.get("/agent-ui/list");
      this.agentDesktopList = agentDesktopResult.data;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
      this.showWarning = true;
    }

    try {
      const permissionResult = await this.$http.get(`/human-agents/permission-set`);
      this.availPermissions = permissionResult.data;
      if (!this.availPermissions || this.availPermissions.length === 0) {
        this.$refs.resultSnackbar.showError("This company doesn't have any permission set available.");
      }
    } catch (e) {
      this.$refs.resultSnackbar.showError("This company doesn't have any permission set available.");
    }
    if (!this.agentStatusInterval) {
      this.agentStatusInterval = setInterval(() => {
        this.loadAgentStatus();
      }, 10000);
    }
    this.loadAgentStatus();
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.EDIT_AGENT, this.onAgentUpdate);
    EventBus.$off(this.$store.getters.getEvents.DELETE_AGENT, this.deleteAgent);
    EventBus.$off(this.$store.getters.getEvents.CANCEL_AGENT_CREATION, this.cancelAgentCreation);
    EventBus.$off(this.$store.getters.getEvents.DELETE_AGENT_FAIL, this.deleteAgentFail);
    EventBus.$off(this.$store.getters.getEvents.EDIT_AGENT_FAIL, this.onAgentFailUpdate);
    EventBus.$off(this.$store.getters.getEvents.RESET_AGENT_PASSWORD, this.onAgentUpdate);
    EventBus.$off(this.$store.getters.getEvents.RESET_AGENT_PASSWORD_FAIL, this.onAgentUpdate);
    EventBus.$off(this.$store.getters.getEvents.COPY_TO_CLIPBOARD, this.onEriCopied);
    EventBus.$off(this.$store.getters.getEvents.KICK_AGENT, this.onKickAgent);
    EventBus.$off(this.$store.getters.getEvents.KICK_AGENT_FAIL, this.onKickAgentFail);
    if (this.agentStatusInterval) {
      clearInterval(this.agentStatusInterval);
    }
  },
  methods: {
    ...scroller,
    ...checkRoleAndPermission,
    forceLogoutSuccess() {
      this.$refs.resultSnackbar.showSuccess("Logout command sent to all selected agents!");
      this.reskillDialog = false;
      this.searchString = "";
      setTimeout(this.loadAgentStatus, 1000);
    },
    onKickAgent(msg) {
      this.$refs.resultSnackbar.showSuccess(msg.message);
      delete this.agentStatus[msg.eri];
      setTimeout(this.loadAgentStatus, 1000);
    },
    onKickAgentFail(msg) {
      this.$refs.resultSnackbar.showError(msg.message);
    },
    async loadAgentStatus() {
      try {
        const resp = await this.$httpAuth.get("/dashboard/wallboard/agent-capacity");
        this.agentStatus = resp.data;
      } catch (e) {
        this.agentStatus = {};
      } finally {
        EventBus.$emit("updateAgentStatus", this.agentStatus);
      }
    },
    getAgentStatus(agent) {
      return this.agentStatus[agent.eri];
    },
    getAgentStatusColor(agent) {
      if (agent.status) {
        switch (agent.status.status) {
          case "READY":
            return "success";
          case "NOT_READY":
            return "error";
        }
      }
      return "gray";
    },
    itemSelected(item) {
      this.actionSelected = item;
      this.reskillDialog = true;
      this.expansionPanel = null;
      this.expansionPanelCreation = null;
    },
    getAgentDesktopURL() {
      //se c'è una agent desktop custom di default mostro quell'url
      const defaultAgentDesktop = this.agentDesktopList.find((agentDesktop) => agentDesktop.default);
      const company = this.$store.state.loginResponse.params.customerProfile.selectedCompany;
      const serverConfigs = this.$store.state.loginResponse.params.serverConfigurations;
      const base = serverConfigs.agentDesktopUrl || this.$store.state.loginResponse.params.serverConfigurations.publicUrl;
      if (defaultAgentDesktop) {
        return serverConfigs.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/" + defaultAgentDesktop.uuid + "/#/login";
      }
      return serverConfigs.customUrls?.agentDesktop[company] || base + "/agent/" + company + "/#/login";
    },
    checkExpirationDate(agent) {
      return agent.authentication.credentials.expiration && agent.authentication.credentials.expiration < new Date().toISOString();
    },
    getArrayIdOfAgent(username) {
      return this.agents.findIndex((a) => a.authentication.credentials.username === username);
    },
    onAgentUpdate(obj) {
      let id = this.getArrayIdOfAgent(obj.agent.authentication.credentials.username);
      if (id < 0) {
        this.agents.push(merge({}, obj.agent));
        this.$refs.resultSnackbar.showSuccess("Agent " + obj.agent.authentication.credentials.username + " added!");
      } else {
        this.agents[id] = merge({}, obj.agent);
        this.$refs.resultSnackbar.showSuccess("Agent " + obj.agent.authentication.credentials.username + " updated!");
      }
      this.closeAllPanels();
      this.forceRerender++;
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    onAgentFailUpdate(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    cancelAgentCreation() {
      this.expansionPanelCreation = null;
      this.newAgent = null;
    },
    deleteAgent(obj) {
      let id = this.getArrayIdOfAgent(obj.username);
      if (id >= 0) {
        this.$refs.resultSnackbar.showSuccess("Agent " + obj.username + " deleted!");
        this.agents.splice(id, 1);
        this.closeAllPanels();
        setTimeout(() => {
          this.forceRerender++;
        }, 100);
      }
    },
    deleteAgentFail(obj) {
      this.$refs.resultSnackbar.showError(obj.message);
    },
    closeAllPanels() {
      this.cancelAgentCreation();
      this.expansionPanel = null;
    },
    updateMainScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
    },
    addNewAgent() {
      if (!this.newAgent) {
        this.newAgent = {
          _id: "",
          eri: "",
          authentication: {
            type: "basic",
            credentials: {
              username: "",
              email: "",
              password: "",
              expiration_months: 10000,
              realm: `${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`,
            },
          },
          info: {
            name: "",
            surname: "",
          },
          roles: {
            [this.$store.getters.getProducts.CONVYAI]: {
              role: this.$store.getters.getRoles.CONTACTCENTERAGENT,
              resources: {
                [`${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`]: {
                  //Dati liberi. Per l'agente deve esserci l'eri del routing profile
                  profile: "",
                  permission_set: "",
                },
              },
            },
          },
        };
      }
      this.scrollToTop().finally(() => (this.expansionPanelCreation = 0), 100);
    },
    onEriCopied(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    getProfile(role) {
      let agentProfile = role?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.profile || "";
      let result = this.profiles.find((el) => el.uuid === agentProfile.split(":")[4]);
      return result?.name ? result.name : "";
    },
    getRolesInfo(type) {
      switch (type) {
        case "role":
          return this.$store.getters.getProducts.CONVYAI;
        case "resource":
          return `${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`;
      }
    },
    getPermission(role) {
      let agentPerm = role?.[this.getRolesInfo("role")]?.resources?.[this.getRolesInfo("resource")]?.permission_set || "";
      let result = this.availPermissions.find((el) => el.uuid === agentPerm.split(":")[4]);
      return result?.name ? result.name : "";
    },
    filterData() {
      this.filtredAgents = this.agents
        //Filtro per la ricerca
        .filter(
          (agent) =>
            agent.authentication.credentials.username.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            agent.authentication.credentials.email.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            agent.info.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            agent.info.surname.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            agent.eri.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            this.getPermission(agent.roles).toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            this.getProfile(agent.roles).toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1,
        )
        .map((agent) => {
          agent.status = this.getAgentStatus(agent);
          return agent;
        })
        //Sorting per un campo
        .sort((a, b) => {
          if (a.authentication.credentials.username.toLowerCase() > b.authentication.credentials.username.toLowerCase()) {
            return 1;
          }
          if (a.authentication.credentials.username.toLowerCase() < b.authentication.credentials.username.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      return this.filtredAgents;
    },
    updatePageSize(event) {
      this.pageNumber = 1;
      this.pageSize = event;
      this.scrollToTop();
    },
    selectItem() {
      let count = this.filtredAgents.filter((agent) => agent.selected).length;
      if (count == 0) {
        this.selectAllAgent = false;
      }
      if (count == this.filtredAgents.length) {
        this.selectAllAgent = true;
      } else {
        this.selectAllAgent = false;
      }
      this.forceRerender++;
    },
    preventExpansionPanelBehavior(e) {
      //* per evitare che il click sulla checkbox apra il panel
      e.cancelBubble = true;
    },
    selectAll() {
      for (let agent of this.filtredAgents) {
        if (!this.selectAllAgent) {
          agent.selected = true;
        } else {
          agent.selected = false;
        }
      }
      this.selectAllAgent = !this.selectAllAgent;
      this.forceRerender++;
    },
    closeRiassignDialog(event) {
      this.searchString = "";
      this.filtredAgents = this.agents;
      this.filtredAgents.map((agent) => {
        if (agent.selected) {
          agent.selected = false;
        }
      });
      this.selectAllAgent = false;
      this.reskillDialog = false;
      if (event.success) {
        let merged = [];
        for (let i = 0; i < this.agents.length; i++) {
          merged.push({
            ...this.agents[i],
            ...event.data.find((el) => el._id === this.agents[i]._id),
          });
        }
        this.agents = merged;

        this.$refs.resultSnackbar.showSuccess("Agents profile correctly updated!");
      } else if (event.error) {
        this.$refs.resultSnackbar.showError("Error! Agents not found: " + event.data);
      }
    },
  },
};
</script>
