<template>
  <div>
    <v-app :class="!isDarkMode ? 'loginBackground' : 'loginBackground-dark'" class="wizardCard">
      <v-card raised class="my-auto mx-auto" style="width: 1000px">
        <v-card-title class="py-0 my-0" style="background-color: #039be5 !important" dark>
          <v-img contain class="my-1" height="55" max-width="169" :src="require('../../../assets/img/logo_full_white.png')" />
          <v-spacer />
          <v-card-title class="white--text small-caps"> Initial configuration </v-card-title>
        </v-card-title>
        <v-card-text class="pa-0" v-if="!isStepperComplete">
          <v-stepper v-model="stepper" vertical>
            <v-stepper-step :complete="stepper > 1" step="1"> Create your account </v-stepper-step>

            <v-stepper-content step="1">
              <AccountCreation :user="user" @next="next" @resetUser="resetUser" />
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 2" step="2"> Create your company </v-stepper-step>

            <v-stepper-content step="2">
              <CompanyCreation :publicUrl="publicUrl" :company="company" @save="save" @back="back" />
            </v-stepper-content>
          </v-stepper>
        </v-card-text>

        <!-- Messaggi per avvertire l'utente del completamento del wizard -->
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" xs="12" sm="8" offset-sm="2" v-if="messageError === ''" justify="center">
              <v-col cols="12" class="text-center py-6">
                <span class="headline py-6">Your setup was a success!</span>
              </v-col>
              <v-col cols="12" class="text-center py-4">
                <v-avatar size="100" :color="user.info.color">
                  <span style="font-size: 3rem" :style="getAvatarFontColor(user.info.color)" v-if="!getAvatar(user).thereIsAvatar">
                    {{ getUserInitials(user) }}</span
                  >
                  <v-img v-else :src="getAvatar(user).avatarPath" />
                </v-avatar>
              </v-col>
              <v-col cols="12" class="text-center py-6">
                <span class="headline"
                  >Hello {{ user.info.name }}, <br />you're about to be redirected to the ConvyAi homepage, <br />where you can login with your email and
                  password.
                </span>
              </v-col>
              <v-col cols="12" class="text-center py-4">
                <span class="body-2 pt-12">
                  If you can't wait,
                  <v-btn small text @click="$emit('wizardCompleted')"> click here</v-btn></span
                >
              </v-col>
            </v-col>
            <v-col v-else>
              <v-col cols="12" class="text-center py-6">
                <span class="headline py-6">Your setup went wrong!</span>
              </v-col>
              <v-col cols="12" class="text-center py-6">
                <span class="body-2"
                  ><i>
                    {{ messageError }}
                  </i>
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="headline py-6">
                  <p v-if="user.info.name">We're sorry, {{ user.info.name }}! :(</p>
                  <p>Please try again, better luck next time</p></span
                >
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import scroller from "../../../helpers/scrollToTop";
import moment from "moment-timezone";
import AccountCreation from "./AccountCreation";
import CompanyCreation from "./CompanyCreation";
import avatars from "../../../helpers/avatars";

export default {
  name: "Wizard",
  components: {
    AccountCreation,
    CompanyCreation,
  },
  data() {
    return {
      forceRerender: 0,
      isStepperComplete: false,
      messageError: "",
      stepper: 1,
      publicUrl: "",
      user: {
        authentication: {
          type: "basic",
          credentials: {
            username: "",
            email: "",
            password: "",
            expiration_months: 10000,
            realm: null,
          },
        },
        info: {
          name: "",
          surname: "",
          language: "EN",
          avatar: "avatar-00.png",
          color: this.$vuetify.theme.dark ? "#1D9096" : "#035075",
          darkTheme: this.$vuetify.theme.dark,
        },
        roles: {
          [this.$store.getters.getProducts.CONVYAI]: {
            role: this.$store.getters.getRoles.ROOT,
            resources: {},
          },
        },
      },
      company: {
        name: "",
        timezone: {
          zone: moment.tz.guess(),
          timezoneOffset: "",
        },
        integrations: {},
        crabCustomSettings: {
          enabled: false,
        },
      },
    };
  },
  mounted() {
    this.company.timezone.timezoneOffset = moment.tz(moment.tz.guess())._offset + "";
    let probableUrl = window.location.href;
    if (probableUrl.includes("/ConvyAdmin")) {
      this.publicUrl = probableUrl.substring(0, probableUrl.indexOf("/ConvyAdmin"));
    } else {
      this.publicUrl = probableUrl.replace(":8081/#/login", "");
    }
  },
  computed: {
    isDarkMode() {
      this.forceRerender;
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...scroller,
    ...avatars,
    back() {
      this.stepper = 1;
      this.scrollToTop();
    },
    next() {
      this.stepper = 2;
      this.scrollToTop();
    },
    resetUser() {
      this.user = {
        authentication: {
          type: "basic",
          credentials: {
            username: "",
            email: "",
            password: "",
            expiration_months: 10000,
            realm: null,
          },
        },
        info: {
          name: "",
          surname: "",
          language: "EN",
          avatar: "avatar-00.png",
          color: this.$vuetify.theme.dark ? "#1D9096" : "#035075",
          darkTheme: this.$vuetify.theme.dark,
        },
        roles: {
          [this.$store.getters.getProducts.CONVYAI]: {
            role: this.$store.getters.getRoles.ROOT,
            resources: {},
          },
        },
      };
      this.scrollToTop();
    },
    async save() {
      try {
        this.user.authentication.credentials.email = this.user.authentication.credentials.username;
        await this.$httpNoAuth.post("/auth/initialize", {
          publicUrl: this.publicUrl,
          user: this.user,
          company: this.company,
        });
        this.isStepperComplete = true;
      } catch (err) {
        this.messageError = err;
        this.isStepperComplete = true;
      } finally {
        this.scrollToTop();
        setTimeout(() => {
          this.$emit("wizardCompleted");
        }, 15000);
      }
    },
  },
};
</script>
<style>
.overflowHidden {
  overflow-y: hidden !important;
}

.loginBackground {
  background: url("../../../assets/img/background/background_light_ottanio-magenta.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.loginBackground-dark {
  background: url("../../../assets/img/background/background_dark_ott-mag.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

#initialConfiguration div.v-stepper__wrapper {
  min-height: 450px !important;
}

.theme--light #initialConfiguration .v-footer {
  background-color: white;
}

.theme--dark #initialConfiguration .v-footer {
  background-color: #263238;
}

.wizardCard .v-card .v-card__title {
  height: 92px !important;
}
</style>
