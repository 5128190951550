<template>
  <v-col cols="12" :lg="!wallboard.resize ? 6 : 12" style="margin: 0 !important">
    <v-card class="v-sheet--offset mx-auto" color="transparent" style="height: 460px !important">
      <WidgetToolbar
        :title="wallboard.label"
        :info="info"
        :selectedView="selectedView"
        @deleteWidget="deleteWidget"
        @resizeWidget="resizeWidget"
        @reloadData="reloadWidgetData"
        @onChangeView="onChangeView"
      />
      <v-row style="height: 430px !important" @mousedown.stop="() => {}">
        <v-col cols="4" :lg="!wallboard.resize ? 4 : 4" style="margin: 0 !important">
          <div class="rounded-wrap square justify-start column mt-4 md">
            <h4 class="mb-12">
              Active Conversations
            </h4>
            <div class="rounded-box mt-8">
              <div class="rounded-box-content lg">
                <span v-if="dataset.data" class="box-value">{{ dataset.data.active }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="8" :lg="!wallboard.resize ? 8 : 8" style="margin: 0 !important">
          <div class="calls-queue-rows-wrap" style="height: 400px !important">
            <h4>Average call time by queue</h4>
            <div style="height: 400px !important" @mousedown.stop="() => {}">
              <!--blocca il drag&drop dal grafico -->
              <v-chart
                v-if="selectedView === 'chart'"
                ref="callPerformance"
                :theme="$vuetify.theme.dark ? 'darkTheme' : 'lightTheme'"
                autoresize
                class="chart"
                :option="options"
              />
              <div v-else-if="selectedView === 'table'">
                <v-data-table
                  id="viewWallboardTableResult"
                  height="290px"
                  fixed-header
                  :headers="viewTable.headers"
                  :items="viewTable.data"
                  item-key="name"
                  class="elevation-1 table-tiny"
                  :search="viewTable.search"
                  :custom-filter="searchInViewTable"
                  hide-default-footer
                  disable-pagination
                  must-sort
                  sort-by="name"
                  dense
                >
                  <template #top>
                    <v-text-field v-model="viewTable.search" label="Search" class="mx-4" />
                  </template>
                  <template #item.name="{ item }">
                    {{ item.name }}
                  </template>
                  <template #item.calls="{ item }">
                    {{ item.calls }}
                  </template>
                  <template #item.average="{ item }">
                    {{ item.average }}
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import WidgetToolbar from "../utils/WidgetToolbar";
import wallboardUtils from "../../../helpers/wallboard";
import EventBus from "../../../event-bus";

export default {
  name: "CallPerformance",
  components: {
    WidgetToolbar,
  },
  props: {
    "wallboard": {
      type: Object,
      required: true
    },
    "queues": {
      type: Object,
      required: true
    },
    "publications": {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewTable: {
        search: "",
        headers: [
          {
            text: "Queue",
            align: "start",
            sortable: true,
            value: "name",
          },
          {
            text: "Calls",
            align: "center",
            sortable: true,
            value: "calls",
            width: 100,
          },
          {
            text: "Average",
            align: "center",
            sortable: true,
            value: "average",
            width: 100,
          },
        ],
        data: [],
      },
      info: "This widget shows the total of all active audio/video calls and the average response time for each queue during the last 24 hours.",
      reloadWidgetDataInterval: null,
      dataset: {},
      options: {
        tooltip: {
          trigger: "axis",
          formatter: (data) => {
            const values = JSON.parse(JSON.stringify(data[0]));
            values.value[0] = this.secondsToHms(values.value[0]);
            return this.chartDefaultTooltip(values);
          },
        },
        grid: {
          top: 10,
          bottom: 30,
          left: 50,
          right: 120,
        },
        xAxis: {
          minInterval: 60,
          max: "dataMax",
          name: "   Average",
          axisLabel: {
            formatter: (value) => {
              const hms = this.secondsToHms(value);
              return hms === "00:00" ? "0m" : hms;
            },
          },
        },
        dataset: {
          source: [["Average call time", "Queue"]],
        },
        yAxis: {
          type: "category",
          axisLabel: {
            inside: true,
            rotate: 0,
            color: this.$vuetify.theme.dark ? "white" : "white",
            fontSize: 14,
            margin: 20,
            textShadowColor: "#3A3A3B",
            textShadowOffsetX: "1",
            textShadowOffsetY: "1",
            textShadowBlur: "2",
            rich: {
              flag: {
                fontSize: 25,
                padding: 5,
              },
            },
            formatter: function (value) {
              return value.length <= 14 ? value : value.substring(0, 14) + "...";
            },
          },
          z: 10000,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 9,
        },
        series: [
          {
            realtimeSort: true,
            showBackground: true,
            seriesLayoutBy: "column",
            type: "bar",
            encode: {
              x: 0,
              y: 3,
              tooltip: [0, 1],
            },
            label: {
              show: true,
              precision: 1,
              position: "right",
              valueAnimation: true,
              fontFamily: "monospace",
              fontWeight: "bold",
              color: this.$vuetify.theme.dark ? "#e7e7e7" : "#5e5e5e",
              formatter: (value) => {
                return this.secondsToHms(value.value[0]);
              },
            },
          },
        ],
        // Disable init animation.
        animationDuration: 0,
        animationDurationUpdate: 2000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          borderRadius: [5, 5, 5, 5],
          borderColor: this.$vuetify.theme.dark ? "#253238" : "#fff",
        },
      },
      selectedView: this.wallboard.view ? this.wallboard.view : "chart",
    };
  },
  mounted() {
    EventBus.$on("reloadAll", this.onReloadEvent);
  },
  beforeDestroy() {
    EventBus.$off("reloadAll", this.onReloadEvent);
  },
  methods: {
    ...wallboardUtils,
    onReloadEvent() {
      this.reloadWidgetData();
    },
    onChangeView(type) {
      this.selectedView = type;
      this.$emit("onChangeView", type);
    },
    async loadWidgetData() {
      try {
        this.dataset = await this.$httpAuth.get("/dashboard/wallboard/call-performance");
        this.options.dataset.source = [["Call time", "Queue"]];
        this.viewTable.data = [];
        this.dataset.data.queue.forEach((item) => {
          this.options.dataset.source.push([item.avg, item.name]);
          this.viewTable.data.push({
            name: item.name,
            calls: item.calls,
            average: this.secondsToHms(item.avg),
          });
        });
        this.options.yAxis.max = this.options.dataset.source.length - 2;

        if (this.$refs["callPerformance"]) {
          this.$refs["callPerformance"].setOption(this.options);
        }
      } catch (e) {
        this.$emit("failToLoadData");
      }
    },
    resizeWidget() {
      this.wallboard.resize = !this.wallboard.resize;
      this.$emit("resizeWidget");
    },
    deleteWidget() {
      this.$emit("deleteWidget");
    },
    reloadWidgetData() {
      this.loadWidgetData();
    },
    searchInViewTable(value, search) {
      return value != null && search != null && typeof value === "string" && value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
