<template>
  <v-navigation-drawer
    id="app-drawer"
    :key="'leftmenu_' + rerender"
    v-model="leftDrawer"
    :mini-variant="leftDrawerMinimized"
    app
    mobile-breakpoint="991"
    width="250"
    class="drawerLeftMenu"
    :color="$vuetify.theme.dark ? '#263238 !important' : '#293d52 !important'"
    @transitionend="onMenuStatusChange"
  >
    <router-link to="/">
      <v-row v-if="getLeftDrawerStatus" justify="center" align="center" class="my-1">
        <v-img contain class="mt-1" height="55" max-width="60" :src="require('../../assets/img/logo_white.png')" />
      </v-row>

      <v-row v-else align="center" class="pl-6 my-1">
        <v-img contain class="mt-1" height="55" max-width="169" :src="require('../../assets/img/logo_full_white.png')" />
      </v-row>
    </router-link>
    <perfect-scrollbar ref="ps" :options="$store.getters.getDefaultScrollProps" style="height: 88vh">
      <v-container class="pt-0 px-0 mb-5" @click="updateScrollbar()">
        <v-list nav dense dark :color="$vuetify.theme.dark ? '#263238 !important' : '#293d52 !important'">
          <!-- Menu SETTING -->
          <v-list-group v-if="hasRequiredRole($store.getters.getRoles.SUPERADMIN)" v-model="menuState.setting" dark @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-tune-vertical</v-icon>
                  </v-list-item-icon>
                </template>
                <span>System</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                System
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ROOT)" class="tile" to="/settings">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Settings
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ROOT)" class="tile" to="/loggers">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Loggers
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tile" to="/companies">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Companies
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ROOT)" class="tile" to="/license">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  License
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ROOT)" class="tile" to="/maintenance">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Maintenance
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tile" to="/systemUsersOverview">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Users
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ROOT)" class="tile" to="/RecordingIssuesManagement">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Recordings analyzer
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu DESIGN -->
          <v-list-group v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR)" v-model="menuState.design" @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-forum</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Design</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Design
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlySemanticEngines')" class="tile" to="/semanticEngines">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Semantic Engines
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyFlows')" class="tile" to="/flowConfigurations">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Flow Configurations
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyFlows')" class="tile" to="/bots">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Bots
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- menu Human Agents-->
          <v-list-group
            v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER) && $store.getters.isIntegrationEnabled('wcs')"
            v-model="menuState.wcs"
            @click="expandMenuIfMinimized"
          >
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-face-agent</v-icon>
                  </v-list-item-icon>
                </template>
                <span>ConvyAI Agents</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                ConvyAI Agents
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item class="tile" to="/queues">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Queues
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tile" to="/profiles">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Profiles
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tile" to="/agents">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Agents
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tile" to="/shortcodes">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Shortcodes
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" class="tile" to="/permissions">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Permission sets
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" class="tile" to="/handoverparameters">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Handover parameters
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRole($store.getters.getRoles.CONTACTCENTERMANAGER)" class="tile" to="/contactSurveys">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Surveys
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu PUBLISH -->
          <v-list-group
            v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyWebConnectors')"
            v-model="menuState.publish"
            @click="expandMenuIfMinimized"
          >
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-cloud</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Publish</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Publish
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item class="tile" to="/connectors">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Connectors
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredPermission('AccessRainbowIntegration') && $store.getters.isIntegrationEnabled('rainbow')"
                class="tile"
                to="/rainbowIntegration"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Rainbow
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredPermission('AccessECEIntegration') && $store.getters.isIntegrationEnabled('ciscoECE')"
                class="tile"
                to="/ciscoECEIntegration"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Cisco ECE
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredPermission('AccessLivePersonIntegration') && $store.getters.isIntegrationEnabled('livePerson')"
                class="tile"
                to="/livePersonIntegration"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Live Person
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Knowledge Base-->
          <v-list-group
            v-if="hasRequiredRole($store.getters.getRoles.ADMIN) && $store.getters.isIntegrationEnabled('knowledgeBase')"
            v-model="menuState.knowledgeBase"
            @click="expandMenuIfMinimized"
          >
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-database-search-outline</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Knowledge Base</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Knowledge Base
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item class="tile" to="/kwbSettings">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Settings
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item class="tile" to="/indexing">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Indexing
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item class="tile" to="/searchDocument">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Document Management
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu TOOLS -->
          <v-list-group v-if="hasRequiredRole($store.getters.getRoles.SUPERVISOR)" v-model="menuState.tools" @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-tools</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Tools</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Tools
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'FullAccessFlows')" class="tile" to="/propagate">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Propagate
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyKeyboards')" class="tile" to="/keyboards">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Keyboards
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyInputProcessors')" class="tile" to="/inputProcessors">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Input Processors
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyContentEditor')" class="tile" to="/contentEditor">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Content Editor
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.SUPERVISOR, 'ReadOnlyWatchbot')" class="tile" to="/watchbot">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Watchbot
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyImportExport')" class="tile" to="/export">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Export
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyImportExport')" class="tile" to="/import">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Import
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyImportExport')" class="tile" to="/customerInterface">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Customer Interface
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.ADMIN, 'ReadOnlyImportExport')" class="tile" to="/agentDesktop">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Agent Desktop
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredRoleAndPermission($store.getters.getRoles.SUPERVISOR, 'ReadOnlyFlows')" class="tile" to="/flowAnalyzer">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Flow Analyzer
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu ANALYTICS -->
          <v-list-group v-model="menuState.analytics" @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Analytics</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Analytics
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyDashboard')" class="tile" to="/">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Dashboard
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyDashboard')" class="tile" to="/wallboard">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Wallboard
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="multiCompanyUsers && hasRequiredPermission('AccessCompaniesOverview')" class="tile" to="/companiesOverview">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Companies Overview
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredRoleAndPermission($store.getters.getRoles.SUPERVISOR, 'ReadOnlyScheduledReports')"
                class="tile"
                to="/scheduledReports"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Scheduled reports
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu REPORT -->
          <v-list-group
            v-if="
              hasRequiredPermission('AccessCharts') ||
                hasRequiredPermission('AccessDialogDetail') ||
                hasRequiredPermission('AccessSemanticStatistics') ||
                hasRequiredPermission('AccessFlowNavigationStatistics') ||
                hasRequiredPermission('AccessSemanticConfidence') ||
                hasRequiredPermission('AccessSurvey')
            "
            v-model="menuState.report"
            @click="expandMenuIfMinimized"
          >
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-file-document</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Report</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Report
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item
                v-if="
                  hasRequiredPermission('AccessRegistry') &&
                    !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])
                "
                class="tile"
                to="/registry"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Registry
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('AccessDialogDetail')" class="tile" to="/dialogDetails">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Dialog Details
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  hasRequiredPermission('AccessSemanticStatistics') &&
                    !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])
                "
                class="tile"
                to="/semanticStatistics"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Semantic Statistics
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  hasRequiredPermission(
                    'AccessFlowNavigationStatistics' &&
                      !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER]),
                  )
                "
                class="tile"
                to="/flowNavigationStatistics"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Flow Navigation Statistics
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  hasRequiredPermission('AccessFlowNavigationStatistics') &&
                    !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])
                "
                class="tile"
                to="/botNavigationStatistics"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Bot Navigation Statistics
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  hasRequiredPermission('AccessSemanticConfidence') &&
                    !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])
                "
                class="tile"
                to="/semanticConfidence"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Semantic Confidence
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  hasRequiredPermission('AccessSurvey') &&
                    !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGER, $store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])
                "
                class="tile"
                to="/surveys"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Surveys
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('AccessDialogDetail')" class="tile" to="/contactCenterStatistics">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Contact Center Statistics
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredPermission('AccessDialogDetail') && !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])"
                class="tile"
                to="/agentsSurveys"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Agents Surveys
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasRequiredPermission('AccessDialogDetail') && !hasRequiredRoleExcept([$store.getters.getRoles.CONTACTCENTERMANAGEROUTSOURCER])"
                class="tile"
                to="/contactResults"
              >
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Contact Results
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu ADVANCED -->
          <v-list-group v-if="hasRequiredRole($store.getters.getRoles.ADMIN)" v-model="menuState.advanced" @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-rocket</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Advanced</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Advanced
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyUsers')" class="tile" to="/users">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Users
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('ReadOnlyemailBoxes')" class="tile" to="/emailBoxes">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Email Boxes
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRequiredPermission('AccessActivity')" class="tile" to="/activity">
                <v-list-item-icon />
                <v-list-item-title class="mx-n3">
                  Activity
                </v-list-item-title>
              </v-list-item>
              <v-list-group sub-group no-action>
                <template #activator>
                  <v-list-item-content class="mx-n3">
                    <v-list-item-title>Security</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item class="tile" to="/publicVariables">
                  <v-list-item-title class="mx-n6">
                    Public Variables
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="tile" to="/variablesToStore">
                  <v-list-item-title class="mx-n6">
                    Variables to Store
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-item-group>
          </v-list-group>

          <!-- Menu APPS -->
          <v-list-group v-if="atLeastOneAppIsAvailable" v-model="menuState.apps" @click="expandMenuIfMinimized">
            <template #activator>
              <v-tooltip right nudge-right="20" :disabled="!leftDrawerMinimized">
                <template #activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>mdi-folder-cog-outline</v-icon>
                  </v-list-item-icon>
                </template>
                <span>Apps</span>
              </v-tooltip>
              <v-list-item-title class="mx-n4">
                Apps
              </v-list-item-title>
            </template>
            <v-list-item-group>
              <template v-for="app in getApps">
                <v-list-item
                  v-if="app.enabled && (hasRequiredRole($store.getters.getRoles.ADMIN) || isRoleAmong(app.allowRoles))"
                  :key="'app_menu_' + app.id"
                  class="tile"
                  @click="selectApp(app)"
                >
                  <v-list-item-icon :key="'app_menu_title_' + app.id" />
                  <v-list-item-title class="mx-n3">
                    {{ app.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </v-container>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import EventBus from "../../event-bus";
import checkRoleAndPermission from "../../helpers/checkRoleAndPermission";

export default {
  name: "LeftMenu",
  components: {},
  data: function () {
    return {
      rerender: 0,
      leftDrawer: true,
      leftDrawerMinimized: true,
      menuState: {
        setting: false,
        design: false,
        wcs: false,
        publish: false,
        analytics: false,
        report: false,
        advanced: false,
        tools: false,
        apps: false,
      },
    };
  },
  computed: {
    atLeastOneAppIsAvailable() {
      if (this.$store.state.loginResponse.apps && this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany]) {
        const apps = this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany];
        let countAvailableApp = 0;
        if (apps) {
          for (let app of apps) {
            if (this.hasRequiredRole(this.$store.getters.getRoles.ADMIN) || this.isRoleAmong(app.allowRoles)) {
              if (app.enabled) {
                countAvailableApp++;
              }
            }
          }
        }
        return apps && countAvailableApp > 0;
      }
      return false;
    },
    getApps() {
      if (this.$store.state.loginResponse.apps && this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany]) {
        return this.$store.state.loginResponse.apps[this.$store.getters.getSelectedCompany];
      }
      return [];
    },
    getLeftDrawerStatus() {
      return this.leftDrawerMinimized;
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
    multiCompanyUsers() {
      if (
        this.$store.state.company &&
        this.$store.state.company.list &&
        (this.$store.state.company.list.length > 1 || this.$store.getters.getUserRole === this.$store.state.enums.roles.ROOT)
      ) {
        // il controllo superadmin ha senso?
        // sicuramente ha + company
        // in caso di una sola company ha senso far vedere la voce nel menu anche per superadmin?
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.leftDrawer = this.$store.getters.getSetting("leftDrawer");
    this.leftDrawerMinimized = this.$store.getters.getSetting("leftDrawerMinimized");
    EventBus.$on(this.$store.getters.getEvents.TOGGLE_LEFT_DRAWER, this.toggleLeftDrawer);
    EventBus.$on(this.$store.getters.getEvents.COMPANY_CHANGED, this.onChangeCompany);
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.TOGGLE_LEFT_DRAWER, this.toggleLeftDrawer);
    EventBus.$off(this.$store.getters.getEvents.COMPANY_CHANGED, this.onChangeCompany);
  },
  methods: {
    ...checkRoleAndPermission,
    isRoleAmong(roles) {
      return roles.indexOf(this.$store.getters.getUserRole) > -1;
    },
    async selectApp(app) {
      await this.$store.dispatch("SelectApp", app);
      await this.$router.push("/apps").catch(() => {});
    },
    onMenuStatusChange() {
      this.$store.dispatch("AddSetting", {
        leftDrawer: this.leftDrawer,
        leftDrawerMinimized: this.leftDrawerMinimized,
      });
    },
    onChangeCompany() {
      //Serve perchè al cambio company a volte non compaiono/scompaiono i bottoni a del menu per feature non abilitate (LP, registry, chart)
      this.rerender++;
    },
    toggleLeftDrawer() {
      this.leftDrawerMinimized = !this.leftDrawerMinimized;
      //Questo IF serve perchè sotto una certa dimensione (mobile) il drawer diventa a comparsa
      //Quindi oltre alla minimizzazione bisogna farlo comparire
      if (!this.leftDrawer) {
        this.leftDrawer = true;
        this.leftDrawerMinimized = true;
      }
      if (this.leftDrawerMinimized) {
        for (let menu in this.menuState) {
          this.menuState[menu] = false;
        }
      }
    },
    expandMenuIfMinimized() {
      if (this.leftDrawerMinimized) {
        EventBus.$emit(this.$store.getters.getEvents.TOGGLE_LEFT_DRAWER);
      }
    },
    updateScrollbar() {
      EventBus.$emit(this.$store.getters.getEvents.UPDATE_MAIN_SCROLLBAR);
      setTimeout(() => {
        if (this.$refs.ps) {
          this.$refs.ps.update();
        }
      }, 500);
    },
  },
};
</script>
<style>
.drawerLeftMenu .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
.drawerLeftMenu .v-list-item__title {
  line-height: 1.1rem !important;
}
.theme--light nav.v-navigation-drawer--open.theme--light.backgroundPova .v-navigation-drawer__content .v-list-item__icon .theme--light.v-icon {
  color: white !important;
}
.v-list-item-group .v-list-item--active {
  background-color: #fefefe15;
  margin-right: 5px;
}
.v-list-item-group .v-list-item:hover {
  margin-right: 5px;
}
</style>
