<template>
  <v-container fluid>
    <v-row class="mx-0 px-0 my-2" style="height: 30px !important">
      <v-btn v-if="showDetailFlag" small color="primary" @click="showDetailFlag = false">
        <v-icon small left>
          mdi-keyboard-backspace
        </v-icon>back
      </v-btn>
    </v-row>
    <v-row class="my-2">
      <v-col cols="7" class="text-right">
        <v-menu ref="menu" top>
          <template #activator="{ on: menu }">
            <v-tooltip color="primary" bottom>
              <template #activator="{ on: tooltip }">
                <v-btn :disabled="dataset && dataset.length == 0" small color="primary" dark v-on="{ ...tooltip, ...menu }">
                  <v-icon small left>
                    mdi-download
                  </v-icon> Download
                </v-btn>
              </template>
              <span>Choose the format and download dataset</span>
            </v-tooltip>
          </template>

          <v-list>
            <v-list-item @click="exportXlsx">
              <v-list-item-title>
                <v-icon left>
                  mdi-file-excel-outline
                </v-icon>XLSX
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportCsv">
              <v-list-item-title>
                <v-icon left>
                  mdi-file-delimited-outline
                </v-icon>CSV
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="searchString"
          outlined
          dense
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          style="margin-top: -3.5px"
          :autocomplete="$store.getters.disableAutocomplete"
        />
      </v-col>
    </v-row>
    <v-data-table
      v-if="!showDetailFlag"
      must-sort
      :headers="headers"
      :items="dataset"
      class="elevation-1"
      :search="searchString"
      sort-by="tot"
      sort-desc
      :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
    >
      <template slot="no-data">
        <v-alert :value="true" color="warning" dark icon="warning" class="ma-4">
          Not enough data to render this table!
        </v-alert>
      </template>
      <template #[`item.label`]="{ item }">
        <v-tooltip color="primary" bottom :disabled="item.label && item.label.length >= 20 ? false : true">
          <template #activator="{ on }">
            <div class="text-truncate" :style="$vuetify.breakpoint.sm ? 'width:50px;max-width: 50px;' : 'width:100px;max-width: 100px;'" v-on="on">
              {{ item.engine ? item.label : "N/A" }}
            </div>
          </template>
          <span>{{ item.engine ? item.label : "" }}</span>
        </v-tooltip>
      </template>
      <template #[`item.intent`]="{ item }">
        <v-tooltip color="primary" bottom :disabled="item.intent && item.intent.length >= 20 ? false : true">
          <template #activator="{ on }">
            <div class="text-truncate" :style="$vuetify.breakpoint.sm ? 'width:50px;max-width: 50px;' : 'width:100px;max-width: 100px;'" v-on="on">
              {{ item.intent ? item.intent : "N/A" }}
            </div>
          </template>
          <span>{{ item.intent ? item.intent : "" }}</span>
        </v-tooltip>
      </template>
      <template #[`item.engine`]="{ item }">
        <v-tooltip color="primary" bottom :disabled="item.engine && item.engine.length >= 20 ? false : true">
          <template #activator="{ on }">
            <div class="text-truncate" :style="$vuetify.breakpoint.sm ? 'width:50px;max-width: 50px;' : 'width:100px;max-width: 100px;'" v-on="on">
              {{ item.engine ? item.engine : "N/A" }}
            </div>
          </template>
          <span>{{ item.engine ? item.engine : "N/A" }}</span>
        </v-tooltip>
      </template>
      <template #[`item.tot`]="{ item }">
        <div class="text-right pr-1">
          {{ item.tot }}
        </div>
      </template>
      <template #[`item.totPercentage`]="{ item }">
        <div class="text-right pr-1">
          {{ item.totPercentage ? item.totPercentage + "%" : "N/A" }}
        </div>
      </template>
      <template #[`item.semanticScoreAVG`]="{ item }">
        <div class="text-truncate pr-4">
          {{ item.semanticScoreAVG ? item.semanticScoreAVG + "%" : "N/A" }}
        </div>
      </template>
      <template #[`item.semanticTot`]="{ item }">
        <div class="text-right pr-1">
          {{ item.semanticTot ? item.semanticTot : "N/A" }}
        </div>
      </template>
      <template #[`item.semanticTotPercentage`]="{ item }">
        <div class="text-right pr-1">
          {{ item.semanticTotPercentage ? item.semanticTotPercentage + "%" : "N/A" }}
        </div>
      </template>
      <template #[`item.detail`]="{ item }">
        <v-btn v-if="item.semanticTot > 0" text :loading="isLoading" icon @click="semanticStatisticsDetail(item)">
          <v-icon color="primary">
            mdi-page-next-outline
          </v-icon>
        </v-btn>
      </template>
      <span>View Details</span>
    </v-data-table>
    <v-data-table
      v-if="showDetailFlag"
      must-sort
      :headers="headersDetail"
      :items="semanticDetail"
      class="elevation-1"
      :search="searchString"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 25, 50],
      }"
    >
      <template #[`item.intent_score`]="{ item }">
        {{ getItemScore(item) }}
      </template>
      <template #[`item.detail`]="{ item }">
        <v-tooltip v-if="item.tot != 0" color="primary" left>
          <template #activator="{ on }">
            <v-btn text icon :loading="isLoadingDetail" v-on="on" @click="showDialogDetail(item)">
              <v-icon color="primary">
                mdi-page-next-outline
              </v-icon>
            </v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <template #[`item.navigated_at`]="{ item }">
        <v-row no-gutters>
          {{ item.navigated_at | formatVerboseDateTime }}
        </v-row>
      </template>
      <template #[`item.promote`]="{ item }">
        <v-row v-if="item.engineType == 'COGITOML'" no-gutters>
          <v-tooltip color="primary" left>
            <template #activator="{ on }">
              <v-btn text icon v-on="on" @click="promoteIntent(item)">
                <v-icon>mdi-rocket</v-icon>
              </v-btn>
            </template>
            <span>Promote</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDetailModel" class="reallyBigModal">
      <v-card class="reallyBigModal" style="overflow: hidden !important">
        <v-card-text class="pa-0 ma-0 reallyBigModal">
          <v-text-field id="dialogIDtocopy" v-model="itemDetailSelected.dialog_id" class="tocopy-class" />
          <EuContactModal
            v-if="dialogDetailModel"
            type="convy"
            :contact="itemDetailSelected | formatContact"
            @eucontactmodalclose="closeDialogShowDetail"
            @eucopydialog="copyDialogId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="promoteDialog" max-width="850">
      <v-card>
        <v-card-title class="title">
          Edit or completely change a sentence, then associate it with an intent to promote it
        </v-card-title>
        <v-card-text>
          <v-divider class="primary mb-5" />
          <v-row class="mx-2">
            <v-text-field
              v-model.trim="userSays"
              prepend-inner-icon="mdi-format-quote-close"
              label="Sentence to promote"
              hint="This sentence will be added to the selected intent"
              :autocomplete="$store.getters.disableAutocomplete"
            />
          </v-row>
          <v-row class="mx-2">
            <v-autocomplete v-model="intentAssociated" label="Available Intents" :items="getIntentAssociatedLinst" :rules="[requiredField]" />
          </v-row>
          <v-row class="mx-2">
            <a @click="addNewIntentWithSentence">Create a NEW intent with this sentences</a>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" depressed @click="promoteIntentPost">
            Promote
          </v-btn>
          <v-btn color="secondary" depressed @click="promoteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import exportFile from "../../helpers/exportFile";
import moment from "moment";
import merge from "deepmerge";
import sanitizeHistoryContent from "../../helpers/sanitizeHistoryContent";
import { EuContactModal } from "@eudata/eu-contact-modal";
const Qs = require("qs");
import fieladValidators from "../../helpers/fieldValidators";
import EventBus from "../../event-bus";

/*
IMPORTANT: la snackbar qui non deve essere messa perchè essendo figlio di un altro componente
tutte le snackbar sollevate dal figlio non sono visibili. C'è l'evento apposta "showSnackbar"
utilizzato dal padre per gestire la visualizzazione della snackbar
 */

export default {
  name: "SemanticStatisticsDatatable",
  components: { EuContactModal },
  props: ["dataset", "startDate", "endDate"],
  data() {
    return {
      searchString: "",
      isLoadingData: false,
      isLoadingDetail: false,
      isLoading: false,
      dialogDetailModel: false,
      showDetailFlag: false,
      headers: [
        { text: "Label", value: "label", class: "pr-0" },
        { text: "Intent", value: "intent", class: "pr-0" },
        { text: "Engine", value: "engine", class: "pr-0" },
        {
          text: "Occurrences",
          value: "tot",
          class: "pa-0",
          align: "right",
        },
        {
          text: "Percentage",
          value: "totPercentage",
          class: "pa-0",
          align: "right",
        },
        {
          text: "Confidence AVG",
          value: "semanticScoreAVG",
          class: "pa-0",
          align: "right",
        },
        {
          text: "Semantic (#)",
          value: "semanticTot",
          class: "pa-0",
          align: "right",
        },
        {
          text: "Semantic (%)",
          value: "semanticTotPercentage",
          class: "pa-0",
          align: "right",
        },
        { text: "", value: "detail", align: "center", sortable: false },
      ],
      semanticDetail: [],
      headersDetail: [
        { text: "Text", value: "input_text" },
        { text: "Score", value: "intent_score" },
        { text: "Engine", value: "engine", sortable: false },
        { text: "Hook", value: "path", sortable: false },
        { text: "Timestamp", value: "navigated_at", sortable: false },
        { text: "", value: "detail", sortable: false, align: "center" },
        { text: "", value: "promote", sortable: false, align: "center" },
      ],
      itemDetailSelected: {},
      promoteDialog: false,
      userSays: "",
      intentAssociated: "",
      intentAssociatedList: [],
      tmpEngine: "",
      refreshTimer: null,
    };
  },
  computed: {
    getIntentAssociatedLinst() {
      var list = this.intentAssociatedList;
      //Sorting per un campo
      list = list.sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return list;
    },
  },
  mounted() {
    EventBus.$on(this.$store.getters.getEvents.RESTORE_SEMANTIC_STATISTICS_DATATABLE, this.restoreDatatable);
  },
  beforeDestroy() {
    EventBus.$off(this.$store.getters.getEvents.RESTORE_SEMANTIC_STATISTICS_DATATABLE, this.restoreDatatable);
    this.stopRefreshTimer();
  },
  methods: {
    ...fieladValidators,
    ...exportFile,
    getItemScore(item) {
      return item.intent_score ? item.intent_score.toString().replace(".00", "") : "100";
    },
    restoreDatatable() {
      this.showDetailFlag = false;
    },
    exportXlsx() {
      if (this.showDetailFlag) {
        this.exportSemanticStatistcsDetailXlsx(this.semanticDetail);
      } else {
        this.exportSemanticStatistcsXlsx(this.dataset);
      }
    },
    exportCsv() {
      if (this.showDetailFlag) {
        this.exportSemanticStatistcsDetailCsv(this.semanticDetail);
      } else {
        this.exportSemanticStatistcsCsv(this.dataset);
      }
    },
    getDateToShow(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    semanticStatisticsDetail(item) {
      this.isLoading = true;
      let params = {
        start_date: this.startDate,
        end_date: this.endDate,
        intent: item.intent,
        range: "0-100",
        engine: item.engine,
      };
      this.$httpAuth
        .get("/report/semantic-confidence-detail", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          this.semanticDetail = result.data;
          this.showDetailFlag = true;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$emit("showSnackbar", {
            type: "FAIL_MESSAGE",
            message: "Error Search Data: " + err.message,
          });
        });
    },
    showDialogDetail(item) {
      this.isLoadingDetail = true;
      let params = {
        type: "DIALOG_DETAIL_INFO_V2",
        dialog_id: item.dialog_id,
      };
      this.$httpAuth
        .get("/report/dialog-details-info", {
          params: params,
          paramsSerializer: function (params) {
            return Qs.stringify(params);
          },
        })
        .then((result) => {
          let response = merge({}, item);
          try {
            if (result.data.rawHistory) {
              response.content = result.data.rawHistory;
            } else {
              response.content = JSON.parse(response.content) || [];
            }
          } catch (err) {
            response.content = [];
          }
          if (result.data.result && result.data.result.length > 0) response = merge(response, result.data.result[0]);

          for (let i = 0; i < response.content.length; i++) {
            for (let j = 0; j < result.data.history.length; j++) {
              if (
                (result.data.history[j].message && result.data.history[j].message === response.content[i].message && response.content[i].who?.toLowerCase() !== "convyai") ||
                result.data.history[j].originalMessage === response.content[i].message
              ) {
                if (!response.content[i].analyze) {
                  response.content[i].analyze = result.data.history[j].analyze ? result.data.history[j].analyze : null;
                }
                if (result.data.history[j].attachment) {
                  //vuol dire che mi è stato mandato un base64
                  if (result.data.history[j].attachment.skipConversion) {
                    //allora devo ricostruire il source per il base64
                    result.data.history[j].attachment.file_content =
                      "data:" + result.data.history[j].attachment.mime_type + ";base64," + result.data.history[j].attachment.file_content;
                  }
                  response.content[i].attachment = result.data.history[j].attachment;
                }
                if (result.data.history[j].attributes) {
                  if (!response.content[i].attributes) {
                    response.content[i].attributes = {};
                  }
                  response.content[i].attributes = merge(response.content[i].attributes, result.data.history[j].attributes);
                  //Evito di duplicare i campi
                  if (response.content[i].attributes.formField) {
                    response.content[i].attributes.formField = result.data.history[j].attributes.formField;
                  }
                  //Verifico che gli array siano tutti unici
                  if (response.content[i].attributes.to) {
                    response.content[i].attributes.to = result.data.history[j].attributes.to;
                  }
                  if (response.content[i].attributes.cc) {
                    response.content[i].attributes.cc = result.data.history[j].attributes.cc;
                  }
                  if (response.content[i].attributes.bcc) {
                    response.content[i].attributes.bcc = result.data.history[j].attributes.bcc;
                  }
                  if (response.content[i].attributes.attachments) {
                    response.content[i].attributes.attachments = result.data.history[j].attributes.attachments;
                  }
                }
                result.data.history.splice(j, 1);
              } else if (result.data.history[j].attachment && result.data.history[j].attachment.file_name === response.content[i].message) {
                response.content[i].attachment = result.data.history[j].attachment;
                result.data.history.splice(j, 1);
              } else if (response.content[i].type === "share_document") {
                //Gestione documenti condivisi
                const documentName = response.content[i].message;
                const findResult = result.data.history.find((msg) => {
                  return msg.message && msg.message.indexOf(documentName) !== -1;
                });
                if (findResult) {
                  response.content[i].attachment = findResult.attachment;
                  result.data.history.splice(j, 1);
                }
              }
            }
          }
          response.data = result.data.data;
          response.result = result.data.result;
          response.calls = result.data.calls;
          this.itemDetailSelected = sanitizeHistoryContent.sanitizeAttachment(response, this.$store.getters.getSelectedCompany);
          this.dialogDetailModel = true;
          if (item.state === "PROGRESS") {
            this.stopRefreshTimer();
            this.refreshTimer = setTimeout(this.showDialogDetail.bind(this, item), 30000);
          }
        })
        .catch((err) => {
          this.$emit("showSnackbar", {
            type: "FAIL_MESSAGE",
            message: "Error: " + err.message,
          });
        })
        .finally(() => {
          this.isLoadingDetail = false;
        });
    },
    stopRefreshTimer() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
      }
      this.refreshTimer = null;
    },
    promoteIntent(item) {
      this.$http
        .get("../admin/v2/semantic_engine_v2/" + this.$store.getters.getSelectedCompany + "/" + item.engine)
        .then((response) => {
          this.userSays = item.input_text;
          this.intentAssociated = response.data.engine.engines[item.engine].intents[item.intentLabel];
          this.intentAssociatedList = Object.values(response.data.engine.engines[item.engine].intents);
          this.tmpIntentName = item.intentLabel;
          this.tmpIntent = response.data.engine.engines[item.engine].intentsAttributes[item.intentLabel];
          this.tmpEngine = item.engine;
          this.promoteDialog = true;
        })
        .catch((err) => {
          this.$emit("showSnackbar", {
            type: "FAIL_MESSAGE",
            message: "Error: " + err.message,
          });
        });
    },
    promoteIntentPost() {
      var newUtterances = this.tmpIntent.utterances;
      if (newUtterances && newUtterances.indexOf(this.userSays.trim()) < 0) {
        newUtterances.push(this.userSays.trim());
      } else {
        this.$emit("showSnackbar", {
          type: "FAIL_MESSAGE",
          message: "Utterance already Exists",
        });
        return;
      }
      let requestOptions = {
        boostedterm: this.tmpIntent.boostedterms,
        easyConf: false,
        enabled: this.tmpIntent.enabled,
        name: this.tmpIntentName,
        response: this.tmpIntent.response,
        utterances: newUtterances,
        value: this.intentAssociated,
      };
      this.$http
        .post("../admin/v2/semantic_engine_v2/" + this.$store.getters.getSelectedCompany + "/" + this.tmpEngine + "/updateIntent", {
          requestOptions: requestOptions,
        })
        .then(() => {
          this.promoteDialog = false;
          this.userSays = "";
          this.intentAssociated = "";
          this.intentAssociatedList = [];
          this.tmpIntent = {};
          this.tmpIntentName = "";
          this.tmpEngine = "";
        })
        .catch((err) => {
          this.$emit("showSnackbar", {
            type: "FAIL_MESSAGE",
            message: "Error POST: " + err.message,
          });
        });
    },
    addNewIntentWithSentence() {
      this.$store.dispatch("AddSetting", {
        cogitoPromote: {
          createIntent: true,
          userSays: this.userSays,
        },
      });
      window.open("../#/SemanticEngineIntentsAndEntities?engineName=" + this.tmpEngine + "&engineType=COGITOML", "_blank");
    },
    closeDialogShowDetail() {
      this.stopRefreshTimer();
      this.dialogDetailModel = false;
    },
    copyDialogId() {
      try {
        let input = document.getElementById("dialogIDtocopy");
        input.select();
        document.execCommand("copy");
        this.$emit("showSnackbar", {
          type: "SUCCESS_MESSAGE",
          message: "DialogId Copied to Clipboard!",
        });
      } catch (err) {
        this.$emit("showSnackbar", {
          type: "FAIL_MESSAGE",
          message: "Error Copy to Clipboard!",
        });
      }
    },
  },
};
</script>
