<template>
  <v-container fluid>
    <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteConfirm" @dialogDeny="showConfirm = false" />
    <v-row :key="rerender" class="py-4 px-6" align="center" justify="center">
      <v-form v-model="form.isValid" class="fill-width">
        <v-row align="center">
          <v-col cols="12" md="6" :class="getFormColMargin">
            <v-autocomplete
              v-model="editedConnector.bot_id"
              label="Change Publication"
              :items="getBotList"
              item-text="name"
              item-value="bot_id"
              :return-object="false"
              :rules="[requiredField]"
            >
              <template #prepend-inner>
                <v-avatar v-if="isAvatarAvailable(editedConnector)" size="25" class="mr-2" tile>
                  <img alt="Avatar" :src="getBotAvatarFromPublish(editedConnector)">
                </v-avatar>
                <v-icon v-else class="mr-2">
                  mdi-alert
                </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="connector.type === 'email' && connector.uuid" cols="6" sm="6" :class="getFormColMargin">
            <v-text-field
              v-model="connector.uuid"
              readonly
              disabled
              label="UUID"
              prepend-inner-icon="mdi-code-braces"
              :rules="[requiredField]"
            />
          </v-col>
          <v-col v-if="connector.type === 'webapi'" cols="6" md="6" class="text-center" :class="getFormColMargin">
            <v-dialog v-model="snippetDialog" width="700">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="primary"
                  small
                  dark
                  :disabled="!isPublished()"
                  v-on="on"
                >
                  <v-icon left small>
                    code
                  </v-icon>
                  <span>Generate html snippet</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title :class="$vuetify.theme.dark ? 'blue-grey darken-3' : 'grey lighten-3'">
                  <v-avatar v-if="isAvatarAvailable(editedConnector)" size="25" class="mr-4" tile>
                    <img alt="Avatar" :src="getBotAvatarFromPublish(editedConnector)">
                  </v-avatar>
                  Generate html snippet for {{ getBotName(editedConnector) }}
                </v-card-title>

                <v-card-text>
                  <v-row align="center" justify="start" class="px-2 py-4" no-gutters>
                    <v-col cols="12" class="px-0 ma-0">
                      <label>Select the customer interface and the version to use with this connectors</label>
                    </v-col>
                    <v-col cols="8">
                      <v-select v-model="customerInterfaceSelected" :items="customerInterfacesList" item-text="name" return-object />
                    </v-col>
                    <v-col cols="4">
                      <v-row class="justify-end">
                        <v-switch v-model="guiVersion" label="version 2021" />
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <label>Choose bot's language:</label>
                    </v-col>
                    <v-col cols="12" class="px-0 pt-2">
                      <v-btn-toggle
                        v-model="languageGui"
                        mandatory
                        tile
                        color="primary"
                        group
                        class="px-0 mx-n2"
                      >
                        <v-btn value="English" @click="setCustomLanguage('reset')">
                          English
                        </v-btn>

                        <v-btn value="Italian" @click="setCustomLanguage('reset')">
                          Italian
                        </v-btn>
                        <v-menu
                          v-model="menuLanguage"
                          class="pa-0 ma-0"
                          top
                          :close-on-content-click="false"
                          style="width: 200px; height: 180px; overflow: hidden"
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" :value="customLanguage.value" v-on="on">
                              {{ customLanguage.text }}
                            </v-btn>
                          </template>
                          <v-card flat class="ma-0 pa-0" style="max-width: 200px">
                            <v-row no-gutters class="ma-0 pa-0" style="width: 100%">
                              <v-col cols="12" class="ma-0 pa-0">
                                <v-text-field
                                  id="language"
                                  v-model="searchLanguage"
                                  class="mt-2"
                                  dense
                                  flat
                                  label="Search"
                                  prepend-inner-icon="search"
                                  solo
                                  clearable
                                  @click:clear="clearSearch"
                                />
                              </v-col>
                              <v-col cols="12" class="ma-0 pa-0 mt-n8">
                                <v-list style="height: 100%; height: 170px; max-height: 170px; overflow: auto">
                                  <v-list-item v-for="(item, index) in filtredLanguages" :key="index" style="cursor: pointer !important">
                                    <v-list-item-title class="px-3" @click="setCustomLanguage(item)">
                                      {{ item.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-menu>
                      </v-btn-toggle>
                    </v-col>
                    <v-row v-if="guiVersion" no-gutters>
                      <v-col cols="12">
                        <label>Choose bot's behavior:</label>
                      </v-col>
                      <v-col cols="5">
                        <v-tooltip top max-width="260">
                          <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-switch v-model="autostartOption" label="Autostart conversation" color="primary" />
                            </div>
                          </template>
                          <span>Automatically start the conversation when the script is loaded in the page</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4">
                        <span v-show="editedConnector.params.autostart">
                          <v-tooltip top max-width="260">
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                v-model.number="autostartDelay"
                                label="Autostart delay (ms)"
                                type="number"
                                :rules="[requiredField, positiveNumber, greaterThan500]"
                                min="500"
                                max="36535"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <span>Autostart conversation delay expressed in milliseconds</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                      <v-col cols="5">
                        <v-tooltip top max-width="260">
                          <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-switch v-model="editedConnector.params.hideEngagementButton" label="Hide engagement button" color="primary" />
                            </div>
                          </template>
                          <span>Hide the engagement button</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4">
                        <v-tooltip top max-width="260">
                          <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-switch v-model="editedConnector.params.fullPage" label="Fullpage bot" color="primary" />
                            </div>
                          </template>
                          <span>The bot interface take up the entire page size</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="3">
                        <v-tooltip top max-width="260">
                          <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-switch v-model="editedConnector.params.autoinclude" label="Include on load" color="primary" />
                            </div>
                          </template>
                          <span>Include the bot GUI automatically on page load otherwise will be include when the script is loaded</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    v-clipboard="() => copyScriptCode()"
                    v-clipboard:success="clipboardSuccessHandler"
                    class="mb-4"
                    color="success"
                    @click="snippetDialog = false"
                  >
                    Generate html snippet!
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <EmailConnectorConf v-if="connector.type === 'email'" :editedConnector="editedConnector" />
          <TelegramConf v-if="connector.type === 'telegram'" :editedConnector="editedConnector" />
          <TwitterConf v-if="connector.type === 'twitter'" :editedConnector="editedConnector" />
          <FacebookConf v-else-if="connector.type === 'facebookmessenger'" :editedConnector="editedConnector" />
          <WhatsAppInfoBipConf v-else-if="connector.type === 'whatsapp'" :editedConnector="editedConnector" />
          <!--<AppleBusinessChatConf v-else-if="connector.type === 'applebusinesschat'" :editedConnector="editedConnector" />-->
          <AmazonAlexaConf v-else-if="connector.type === 'amazonalexa'" :editedConnector="editedConnector" />
          <GoogleAssistantConf v-else-if="connector.type === 'googlehome'" :editedConnector="editedConnector" />
          <!--<WebexTeamsConf v-else-if="connector.type === 'webexmeeting'" :editedConnector="editedConnector" />-->
          <WebConf v-else-if="connector.type === 'webapi'" :editedConnector="editedConnector" />
          <GoogleBusinessMessages
            v-else-if="editedConnector.type === 'googlebusinessmessages'"
            :editedConnector="editedConnector"
            :isEdit="true"
            @fileUploaded="uploadForm($event)"
          />
        </v-row>
        <!--gestione parametri campagna outbound  v-if="hasOutboundParameterSectionAvailable(connector)"-->
        <v-row v-if="hasOutboundParameterSectionAvailable(connector)">
          <OutboundParameters
            :parameters="editedConnector"
            :profiles="profiles"
            :bots="bots"
            :whatsappTemplates="whatsappTemplates"
            :connectorType="connector.type"
            :connectors="connectors"
          />
        </v-row>
        <!--gestione parametri typing connettore -->
        <v-row v-if="hasAdditionalParameterSectionAvailable(connector)">
          <AdditionalParameters :parameters="editedConnector" />
        </v-row>

        <v-row v-if="editedConnector.type === 'webapi'">
          <InitialForm :parameters="editedConnector" />
        </v-row>
      </v-form>
    </v-row>
    <!--riga dei bottoni -->
    <v-row>
      <v-col
        :key="rerender"
        cols="12"
        sm="12"
        md="12"
        lg="4"
        class="d-flex"
      >
        <v-btn :class="getButtonMargin" color="success" :disabled="checkBtnUpdate" :loading="form.isLoading" @click="updateConnector">
          <v-icon left>
            mdi-floppy
          </v-icon>
          Update
        </v-btn>
        <v-btn
          right
          :class="getButtonMargin"
          color="primary"
          :loading="form.isLoading"
          :disabled="!isPublished()"
          @click="unpublishConnector"
        >
          <v-icon left>
            mdi-cloud-off-outline
          </v-icon>
          Unpublish
        </v-btn>
        <v-btn :class="getButtonMargin" color="error" :loading="form.isLoading" @click="deleteConnector">
          <v-icon left>
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import EventBus from "../../event-bus";
import merge from "deepmerge";
import TelegramConf from "./connectors/TelegramConf";
import FacebookConf from "./connectors/FacebookConf";
import WhatsAppInfoBipConf from "./connectors/WhatsAppInfoBipConf";
import AmazonAlexaConf from "./connectors/AmazonAlexaConf";
import GoogleAssistantConf from "./connectors/GoogleAssistantConf";
import WebConf from "./connectors/WebConf";
import TwitterConf from "./connectors/TwitterConf";
import AdditionalParameters from "./AdditionalParameters";
import OutboundParameters from "./OutboundParameters";
import InitialForm from "./InitialForm";
import EmailConnectorConf from "./connectors/EmailConnectorConf";
import GoogleBusinessMessages from "./connectors/GoogleBusinessMessages";

export default {
  name: "ConnectorForm",
  components: {
    ConfirmDialog,
    TelegramConf,
    FacebookConf,
    WhatsAppInfoBipConf,
    AmazonAlexaConf,
    GoogleAssistantConf,
    WebConf,
    TwitterConf,
    AdditionalParameters,
    OutboundParameters,
    InitialForm,
    EmailConnectorConf,
    GoogleBusinessMessages,
  },
  props: ["connector", "connectors", "bots", "customerInterfacesList", "profiles", "whatsappTemplates"],
  data() {
    return {
      guiVersion: true,
      snippetDialog: false,
      languageGui: "English",
      company: this.$store.getters.getSelectedCompany,
      showConfirm: false,
      deleteMessage: "Connector " + this.connector.name + " will be deleted!",
      testEmailConnection: {
        incoming: false,
        outgoing: false,
      },
      form: {
        isValid: false,
        isLoading: false,
      },
      autostartOption: false,
      autostartDelay: 500,
      editedConnector: {
        details: {},
        params: {
          spoolingQueueInterval: 2500,
          millisecondsPerCharacter: 50,
          maxTypingTimeInMilliseconds: 2500,
          minTypingTimeInMilliseconds: 500,
          typingActivationDelay: 150,
          requireTyping: true,
          fixedSendingTimeout: 1000,
          requireSplit: false,
          maxSplitLength: 120,
          autostart: false,
          autostartDelay: 500,
          hideEngagementButton: false,
          fullPage: false,
          autoinclude: true,
          saveAllAttachments: false,
        },
        outboundParams: {
          enabled: false,
          campaignName: "",
          outboundBot: "",
          enabledProfiles: [],
          outboundWhatsappTemplate: {},
        },
        form: [],
        name: "",
        enabled: true,
        webhookUrl: "",
        bot_id: "",
        identifierFieldName: "",
      },

      guiType: [
        {
          label: "CustomerGUI 2021 - English",
          value: "en_2021",
        },
        {
          label: "CustomerGUI 2021 - Italian",
          value: "it_2021",
        },
        {
          label: "CustomerGUI - English",
          value: "en_2020",
        },
        {
          label: "CustomerGUI - Italian",
          value: "it_2020",
        },
      ],
      botExists: true,
      customerInterfaceSelected: "",
      searchLanguage: "",
      languages: [],
      menuLanguage: false,
      customLanguage: {
        text: "Custom",
        value: "custom",
      },
      fileIsUploaded: false,
      rerender: 0,
    };
  },
  computed: {
    ...spacing,
    checkBtnUpdate() {
      this.form.isValid;
      if (this.connector.type === "email") {
        return !this.form.isValid || this.testEmailConnection.incoming || this.testEmailConnection.outgoing;
      }
      if (this.connector.type === "googlebusinessmessages") {
        return !this.form.isValid || !this.fileIsUploaded;
      } else {
        return !this.form.isValid;
      }
    },
    getBotList() {
      let botList = Object.values(this.bots);
      botList = botList?.filter((b) => !b?.isOutbound);
      return botList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    filtredLanguages() {
      this.searchLanguage;
      if (this.searchLanguage == "") {
        return this.languages;
      } else {
        let string = this.searchLanguage.toLowerCase();
        let filtred = [];
        this.languages.filter(function (lang) {
          if (lang.text.toLowerCase().indexOf(string) > -1 || lang.value.toLowerCase().indexOf(string) > -1) {
            filtred.push(lang);
          }
        });
        return filtred;
      }
    },
    profileListItems() {
      let result = [];
      result = this.profiles.map((profile) => {
        return { text: profile.name, value: profile.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
  },
  watch: {
    autostartOption(val) {
      this.editedConnector.params.autostart = val;
    },
    autostartDelay(val) {
      this.editedConnector.params.autostartDelay = val;
    },
  },
  mounted() {
    this.editedConnector = merge(this.editedConnector, this.connector);
    if (this.editedConnector.type === "email") {
      this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling
        ? this.editedConnector.details.incoming.spooling / 60000
        : 1;
    }
    if (this.editedConnector.type === "googlebusinessmessages" && this.editedConnector?.details?.file?.name) {
      this.fileIsUploaded = true;
    }
    this.autostartOption = this.editedConnector.params?.autostart || false;

    // controllo che il bot associato esista ancora
    !this.bots[this.connector.bot_id] ? (this.botExists = false) : "";
    if (!this.botExists) {
      this.editedConnector.bot_id = "";
    }
    this.customerInterfacesList.unshift({ name: "Default_interface", type: "default" });
    this.customerInterfaceSelected = { name: "Default_interface", type: "default" };

    for (let language of this.$store.getters.getGoogleSpeechSupportedLanguage) {
      let lang = language.text;
      if (lang.indexOf("(") > -1) {
        lang = lang.substr(0, lang.indexOf("("));
      }
      if (lang.indexOf(",") > -1) {
        lang = lang.substr(0, lang.indexOf(","));
      }
      lang = lang.trim();
      if (lang != "English" && lang != "Italian") {
        this.languages.push({
          text: lang,
          value: language.value,
        });
      }
    }
  },
  methods: {
    ...fieldValidators,
    clearSearch() {
      this.searchLanguage = "";
    },
    getBotName(name) {
      let list = Object.values(this.bots);
      if (list) {
        for (let bot of list) {
          if (bot.bot_id == name.bot_id) {
            return bot.name;
          }
        }
      }
      return "";
    },
    hasAdditionalParameterSectionAvailable(connector) {
      return connector.type !== "googlehome" && connector.type !== "amazonalexa" && connector.type !== "email";
    },
    hasOutboundParameterSectionAvailable(connector) {
      return this.$store.getters.isIntegrationEnabled("outbound") && (connector.type === "whatsapp" || connector.type === "telegram");
    },
    isAvatarAvailable(connector) {
      const bot = this.bots[connector.bot_id];
      return !!bot && bot.icon;
    },
    getBotAvatarFromPublish(connector) {
      if (connector) {
        const bot = this.bots[connector.bot_id];
        if (bot) {
          return this.$store.getters.getSystemBotIcon(bot.icon, "mini", bot.isVisualIvr) || this.$store.state.enums.botTemplates[0].images[0].image.mini;
        }
      }
      return null;
    },
    clipboardSuccessHandler() {
      EventBus.$emit(this.$store.getters.getEvents.HTML_SNIPPET_COPIED, "");
    },
    getRandom() {
      let text = "";
      const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 8; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    parsePublicUrl(path) {
      let tempPath = path.replace("http://", "").replace("https://", "");
      let proxy = tempPath.indexOf("/") > -1 ? tempPath.substring(tempPath.indexOf("/")) : "";
      path = path.endsWith("/") ? path.substring(0, path.length - 1) : path;
      proxy = proxy.endsWith("/") ? proxy.substring(0, proxy.length - 1) : proxy;
      return {
        path: path,
        proxy: proxy,
      };
    },
    copyScriptCode() {
      let lang = null;
      switch (this.languageGui) {
        case "English":
          lang = "en";
          break;
        case "Italian":
          lang = "it";
          break;
        default:
          lang = this.customLanguage.value.split("-")[0];
          break;
      }
      //resetto la lingua di default
      this.customLanguage = {
        text: "Custom",
        value: "custom",
      };
      const version = this.guiVersion ? "2021" : "2020";
      const bot = this.bots[this.connector.bot_id];
      if (bot) {
        let publicURL = this.$store.getters.getProperty("publicUrl") || "";
        if (publicURL.endsWith("/")) {
          publicURL = publicURL.substring(0, publicURL.length - 1);
        }
        let proxy = this.parsePublicUrl(publicURL).proxy;
        let rnd = this.getRandom();
        let gui = this.customerInterfaceSelected.name;
        let script = null;
        switch (version) {
          case "2020":
            script = this.$store.state.enums.webConnectorScript.replace("<path>", publicURL).replace(/<rnd>/gi, rnd);
            script = script.replace("<lang>", lang).replace("<script>", "includeUnycoPopover");
            script = script.replace("<bot>", bot.flow).replace("<version>", bot.version);
            script = script.replace("<token>", this.$store.state.company.settings.legacyTokens[4]).replace("<relative_path>", proxy);
            if (this.customerInterfaceSelected.name !== "Default_interface") {
              script = script.replace("CustomerGuiAdvanced", "companies/" + this.company + "/" + this.customerInterfaceSelected.name);
            }
            return script;
          case "2021":
            script = this.$store.state.enums.webConnectorScript2021.replace("<path>", publicURL).replace(/<rnd>/gi, rnd);
            script = script.replace("<lang>", lang);
            script = script.replace("<publicationId>", this.connector.uuid);
            script = script.replace("<ui>", this.hashCode(gui));
            // script = script.replace("<autostartdelay>", this.editedConnector.params.autostart ? this.editedConnector.params.autostartDelay : "");
            if (this.editedConnector.params.autostart) {
              const autoStartSring = `&autostartdelay=${this.editedConnector.params.autostartDelay}&autostart=true`;
              script = script.slice(0, script.length - 11) + autoStartSring + script.slice(script.length - 11);
            }
            if (this.editedConnector.params.hideEngagementButton)
              script = script.slice(0, script.length - 11) + "&hidebutton=true" + script.slice(script.length - 11);
            if (this.editedConnector.params.fullPage) script = script.slice(0, script.length - 11) + "&fullpage=true" + script.slice(script.length - 11);
            if (!this.editedConnector.params.autoinclude) script = script.slice(0, script.length - 11) + "&autoinclude=true" + script.slice(script.length - 11);
            return script;
        }
      } else {
        //il bot è stato eliminato, ma il connettore esiste ancora
        return "";
      }
    },
    hashCode(str) {
      let hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    deleteConnector() {
      this.showConfirm = true;
    },
    async onDeleteConfirm() {
      this.form.isLoading = true;
      this.showConfirm = false;
      try {
        await this.$http.delete("/social-connector/" + this.connector.uuid);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_CONNECTOR, this.connector);
      } catch (e) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_CONNECTOR_FAIL, this.connector);
      }
      this.form.isLoading = false;
    },
    async updateConnector() {
      if (this.form.isValid) {
        this.form.isLoading = true;
        this.editedConnector.enabled = true;
        if (this.editedConnector.type == "email") {
          this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling * 60000;
        }
        if (this.editedConnector.type == "googlebusinessmessages") {
          let obj = {
            name: this.editedConnector.details.file.name,
            json: this.editedConnector.details.file.json,
          };
          this.editedConnector.details.file = obj;
        }
        try {
          //devo castare a int i params durante l'update
          this.editedConnector.params.millisecondsPerCharacter = parseInt(this.editedConnector.params.millisecondsPerCharacter);
          this.editedConnector.params.minTypingTimeInMilliseconds = parseInt(this.editedConnector.params.minTypingTimeInMilliseconds);
          this.editedConnector.params.maxTypingTimeInMilliseconds = parseInt(this.editedConnector.params.maxTypingTimeInMilliseconds);
          const response = await this.$http.put("/social-connector/" + this.editedConnector.uuid, this.editedConnector);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_CONNECTOR, response.data);
        } catch (e) {
          if (this.editedConnector.type == "email") {
            this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling / 60000;
          }
          EventBus.$emit(this.$store.getters.getEvents.EDIT_CONNECTOR_FAIL, this.editedConnector);
        } finally {
          this.form.isLoading = false;
        }
      }
    },
    async unpublishConnector() {
      if (this.form.isValid) {
        this.form.isLoading = true;
        this.editedConnector.enabled = false;
        if (this.editedConnector.type === "email") {
          this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling * 60000;
        }
        try {
          const response = await this.$http.put("/social-connector/" + this.editedConnector.uuid, this.editedConnector);
          EventBus.$emit(this.$store.getters.getEvents.EDIT_CONNECTOR, response.data);
        } catch (e) {
          if (this.editedConnector.type == "email") {
            this.editedConnector.details.incoming.spooling = this.editedConnector.details.incoming.spooling / 60000;
          }
          EventBus.$emit(this.$store.getters.getEvents.EDIT_CONNECTOR_FAIL, this.editedConnector);
        } finally {
          this.form.isLoading = false;
        }
      }
    },
    isPublished() {
      return this.connector.enabled;
    },
    checkEmailConnector(res) {
      this.testEmailConnection[Object.keys(res)[0]] = !Object.values(res)[0];
    },
    setCustomLanguage(lang) {
      if (lang == "reset") {
        this.customLanguage = {
          text: "Custom",
          value: "custom",
        };
      } else {
        this.customLanguage.text = lang.text;
        this.customLanguage.value = lang.value;
      }
      this.menuLanguage = false;
    },
    uploadForm(val) {
      this.fileIsUploaded = val.form;
      if (val.json) {
        this.editedConnector.details.file.json = val.json;
      } else {
        this.editedConnector.details.file = null;
        this.fileIsUploaded = false;
      }
      this.rerender++;
    },
  },
};
</script>
<style>
.theme--light #language .v-input__slot {
  background-color: #bdbaba;
}
.theme--dark #language .v-input__slot {
  background-color: #263238;
}
</style>
