<template>
  <v-container fluid>
    <ResultSnackbar ref="resultSnackbar" />
    <ConfirmDialog :showConfirm="showConfirm" message="Save Server Settings" @dialogConfirm="sendRequest" @dialogDeny="showConfirm = false" />
    <v-row :class="getMainRowMarginNoBottom">
      <v-card class="mainAdvanceServerSettingsCard" width="100%">
        <v-card-title>Advanced Server Settings</v-card-title>
        <div>
          <!-- <v-textarea
            dense
            v-model="settings"
            :loading="textareaLoading"
            full-width
            no-resize
            solo
            class="pa-0 ma-0 textareaAdvanceServerSettings"
          ></v-textarea> -->
          <JsonEditorVue
            v-if="isLoaded"
            v-model="settings"
            :mainMenuBar="false"
            mode="text"
            style="height: calc(100vh - 260px) !important"
            class="ma-3"
            :class="isDarkMode ? 'jse-theme-dark' : ''"
          />
        </div>
        <v-card-actions class="mt-n5">
          <!-- Buttons -->
          <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
            <v-btn class="mt-3 mr-3" :disabled="!isValidJson" color="success" @click.prevent="saveServerConfiguration">
              <v-icon left>
                mdi-floppy
              </v-icon>Save
            </v-btn>
            <v-btn class="mt-3" color="primary" @click.prevent="$router.push('settings')">
              <v-icon left>
                mdi-arrow-left
              </v-icon>Back
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/other/ConfirmationDialog";
import ResultSnackbar from "../../components/other/ResultSnackbar";
import spacing from "../../helpers/spacing";
import EventBus from "../../event-bus";

export default {
  components: {
    ConfirmDialog,
    ResultSnackbar,
  },
  data: () => ({
    settings: {},
    showConfirm: false,
    textareaLoading: false,
    isLoaded: false,
  }),
  computed: {
    ...spacing,
    isDarkMode() {
      this.forceRerender;
      return this.$vuetify.theme.dark;
    },
    isValidJson() {
      try {
        JSON.parse(this.settings);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  async mounted() {
    try {
      const response = await this.$http.get("/settings");
      // this.settings = JSON.stringify(response.data, null, 4);
      this.settings = response.data;
      this.isLoaded = true;
    } finally {
      EventBus.$emit(this.$store.getters.getEvents.LOADING, false);
    }
  },
  methods: {
    saveServerConfiguration() {
      this.showConfirm = true;
    },
    async sendRequest() {
      try {
        this.showConfirm = false;
        this.textareaLoading = true;
        await this.$http.post("/settings", JSON.parse(this.settings));
        this.$refs.resultSnackbar.showSuccess("Server configuration updated!");
      } catch (err) {
        this.$refs.resultSnackbar.showError("Fail to update server configuration: " + err.message);
      } finally {
        this.textareaLoading = false;
      }
    },
  },
};
</script>
<style>
.mainAdvanceServerSettingsCard {
  height: calc(100vh - 110px) !important;
}
.textareaAdvanceServerSettings textarea {
  height: calc(100vh - 260px) !important;
}
</style>
