var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-switch',{staticClass:"mb-2",attrs:{"color":"primary","label":"Flow Enabled"},model:{value:(_vm.configuration.general.flow_enabled),callback:function ($$v) {_vm.$set(_vm.configuration.general, "flow_enabled", $$v)},expression:"configuration.general.flow_enabled"}}),_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.general.completedMessage,"customLabel":_vm.labels.completedMessage,"errorMessage":null,"required":false,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.completedMessage,
          _vm.configuration.general.completedMessage
        )}}}),_c('v-text-field',{staticClass:"mx-0 mt-1 mb-2 pa-0",attrs:{"label":"Max handover duration (in secs)","type":"number","min":"1","rules":[_vm.greaterThanZero]},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.configuration.general.max_mute_timeout),callback:function ($$v) {_vm.$set(_vm.configuration.general, "max_mute_timeout", $$v)},expression:"configuration.general.max_mute_timeout"}}),_c('v-text-field',{staticClass:"mx-0 mt-1 mb-2 pa-0",attrs:{"label":"End conversation mute timeout (in secs)","type":"number","min":"1","rules":[_vm.greaterThanZero]},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.configuration.general.mute_timeout),callback:function ($$v) {_vm.$set(_vm.configuration.general, "mute_timeout", $$v)},expression:"configuration.general.mute_timeout"}}),_c('v-text-field',{staticClass:"mx-0 mt-1 mb-2 pa-0",attrs:{"label":"Aggregation timeout (in secs)","type":"number","min":"0","rules":[_vm.positiveNumber]},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.configuration.general.aggregation_timeout),callback:function ($$v) {_vm.$set(_vm.configuration.general, "aggregation_timeout", $$v)},expression:"configuration.general.aggregation_timeout"}}),_c('v-autocomplete',{staticClass:"mx-0 mt-1 mb-2 pa-0",attrs:{"label":"Timezone","items":_vm.$store.getters.getTimeZones,"item-text":"label","item-value":"value"},model:{value:(_vm.configuration.general.timezone),callback:function ($$v) {_vm.$set(_vm.configuration.general, "timezone", $$v)},expression:"configuration.general.timezone"}}),_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.general.max_input_chars_message,"customLabel":_vm.labels.maxCharacters,"errorMessage":null,"required":true,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.maxCharacters,
          _vm.configuration.general.max_input_chars_message
        )}}}),_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.general.not_acceptable_incoming_message,"customLabel":_vm.labels.notAcceptableMessage,"errorMessage":null,"required":true,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.notAcceptableMessage,
          _vm.configuration.general.not_acceptable_incoming_message
        )}}}),_c('multiLanguageItem',{attrs:{"itemObj":_vm.configuration.general.license_cap_reached_message,"customLabel":_vm.labels.courtesyMessage,"errorMessage":null,"required":true,"itemName":"Message"},on:{"openOverlay":function($event){return _vm.showMultiLanguage(
          _vm.labels.courtesyMessage,
          _vm.configuration.general.license_cap_reached_message
        )}}}),_c('v-text-field',{staticClass:"mx-0 mt-1 mb-2 pa-0",attrs:{"label":"Number of input chars before sending the warning message","type":"number","min":"1","rules":[_vm.greaterThanZero]},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.configuration.general.max_input_chars),callback:function ($$v) {_vm.$set(_vm.configuration.general, "max_input_chars", $$v)},expression:"configuration.general.max_input_chars"}}),(_vm.configuration.general.post_close_conversation)?_c('v-checkbox',{attrs:{"label":"Enable technical flow when conversation is closed"},model:{value:(_vm.configuration.general.post_close_conversation.enabled),callback:function ($$v) {_vm.$set(_vm.configuration.general.post_close_conversation, "enabled", $$v)},expression:"configuration.general.post_close_conversation.enabled"}}):_vm._e(),(
        _vm.configuration.general.post_close_conversation &&
          _vm.configuration.general.post_close_conversation.enabled
      )?_c('FlowJumpConfigurations',{attrs:{"settings":_vm.configuration.general.post_close_conversation,"flowTargets":_vm.flowTargets,"customIndent":"ml-8"}}):_vm._e()],1),_c('v-overlay',{staticClass:"overlayMultilanguage",attrs:{"absolute":"","opacity":"1","value":_vm.overlay,"color":"overlayMultiLanguage"}},[_c('multilanguageConfigurationDialog',{attrs:{"itemName":_vm.itemName,"itemObj":_vm.itemObj},on:{"back":function($event){_vm.overlay = !$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }