<template>
  <v-form ref="createAttachedVariableForm" v-model="form.isValid" :class="getCreationFormMargin">
    <v-container fluid>
      <ConfirmDialog :showConfirm="showConfirm" :message="deleteMessage" @dialogConfirm="onDeleteAttachedVariableConfirm" @dialogDeny="showConfirm = false" />
      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="6" :class="getFormColMargin">
          <v-text-field
            v-if="!isEdit"
            v-model.trim="editedAttachedVariable.label"
            label="Handover parameters group name"
            :rules="[
              requiredField,
              charsGreaterThanAndLessThan(editedAttachedVariable.label, 3, 64),
              validateAttachedVariableName,
              isAttachedVariableUnique(attachedVariablesList, editedAttachedVariable.label),
            ]"
            prepend-inner-icon="mdi-code-braces"
            :autocomplete="$store.getters.disableAutocomplete"
          />
          <v-text-field
            v-else
            v-model.trim="editedAttachedVariable.label"
            label="Handover parameters group name"
            prepend-inner-icon="mdi-code-braces"
            :autocomplete="$store.getters.disableAutocomplete"
            disabled
          />
        </v-col>
      </v-row>

      <v-row :class="getFormRowMargin">
        <v-col cols="12" sm="12" :class="getFormColMargin" class="mb-6">
          <v-data-table
            must-sort
            :headers="headers"
            :items="editedAttachedVariable.content"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            fixed-header
          >
            <template #header.delete="{}">
              <v-btn small color="primary" :disabled="isCCMUserWithRestrictPermission" @click="addNewItem">
                <v-icon left>
                  mdi-plus
                </v-icon>Add
              </v-btn>
            </template>
            <template #body="{ items }">
              <draggable v-model="editedAttachedVariable.content" tag="tbody" handle=".sortHandle">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="hidden-sm-and-down text-start">
                    <v-btn small text style="cursor: move" icon class="sortHandle ma-0 pa-0">
                      <v-icon>mdi-cursor-move</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-left text-truncate">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">{{ item.label }}</span>
                      </template>
                      <span>{{ item.label }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-left text-truncate">
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">{{ item.variable }}</span>
                      </template>
                      <span>{{ item.variable }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <div class="d-flex justify-space-between">
                      <v-icon size="24" :disabled="isCCMUserWithRestrictPermission" @click="editItem(item, index)">
                        edit
                      </v-icon>

                      <v-icon size="24" :disabled="isCCMUserWithRestrictPermission" @click="deleteItem(index)">
                        delete
                      </v-icon>
                    </div>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-0 pt-0" :class="getFormColMargin">
          <v-autocomplete
            v-model="editedAttachedVariable.availability"
            :items="queueListItems"
            multiple
            small-chips
            :label="getQueuesLabel()"
            :rules="[checkUserPermission]"
            prepend-inner-icon="mdi-expand-all"
            validate-on-blur
            :hint="getQueuesHint()"
            persistent-hint
            :disabled="isCCMUserWithRestrictPermission"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- Buttons -->
        <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
          <v-btn
            :class="getButtonMargin"
            color="success"
            :loading="form.submitLoading"
            :disabled="!form.isValid || form.deleteLoading || isCCMUserWithRestrictPermission || editedAttachedVariable.content.length === 0"
            @click.prevent="saveAttachedVariable"
          >
            <v-icon left>
              mdi-floppy
            </v-icon>
            <span v-if="!isEdit">Save</span>
            <span v-else>Update</span>
          </v-btn>
          <v-btn
            :class="getButtonMargin"
            color="error"
            :loading="form.deleteLoading"
            :disabled="(isEdit && form.submitLoading) || isCCMUserWithRestrictPermission"
            @click.prevent="deleteAttachedVariable"
          >
            <template v-if="isEdit">
              <v-icon left>
                mdi-delete
              </v-icon>
              <span>Delete</span>
            </template>
            <template v-else>
              <v-icon left>
                mdi-close
              </v-icon>
              <span>Cancel</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="showAddModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add Attached Variable</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="editDialogForm" v-model="isNewItemValid">
              <v-text-field
                v-model.trim="newItem.label"
                label="Variable display name"
                :rules="[requiredField, validateAttachedVariableName, charsGreaterThanAndLessThan(newItem.label, 3, 64)]"
                :autocomplete="$store.getters.disableAutocomplete"
              />
              <v-text-field
                v-model.trim="newItem.variable"
                label="Variable value"
                :rules="[requiredField, isVariableField]"
                :autocomplete="$store.getters.disableAutocomplete"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters>
              <v-col :cols="!editingItem ? 4 : 6" class="px-2">
                <v-btn
                  block
                  outlined
                  color="success"
                  :disabled="!isNewItemValid || isCCMUserWithRestrictPermission"
                  @click="saveNewItem(false)"
                >
                  <v-icon left>
                    mdi-floppy
                  </v-icon>Save
                </v-btn>
              </v-col>
              <v-col v-if="!editingItem" cols="4" class="px-2">
                <v-btn
                  block
                  outlined
                  color="primary"
                  :disabled="!isNewItemValid || isCCMUserWithRestrictPermission"
                  @click="saveNewItem(true)"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>Add More
                </v-btn>
              </v-col>
              <v-col :cols="!editingItem ? 4 : 6" class="px-2">
                <v-btn block outlined color="error" @click="closeDialog">
                  <v-icon left>
                    mdi-close
                  </v-icon>Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import spacing from "../../helpers/spacing";
import fieldValidators from "../../helpers/fieldValidators";
import EventBus from "@/event-bus";
import ConfirmDialog from "@/components/other/ConfirmationDialog";
import merge from "deepmerge";
import draggable from "vuedraggable";

export default {
  name: "AttachedVariableForm",
  components: {
    ConfirmDialog,
    draggable,
  },
  props: ["attachedVariableSetting", "attachedVariablesList", "queuesList"],
  data() {
    return {
      headers: [
        {
          text: "",
          value: "drag",
          sortable: false,
          class: "hidden-sm-and-down drag",
        },
        {
          text: "Label",
          value: "label",
          sortable: false,
        },
        { text: "Variable", value: "variable", sortable: false },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "100px",
        },
      ],
      showConfirm: false,
      deleteMessage: "AttachedVariable " + this.attachedVariableSetting.label + " will be deleted!",
      isEdit: this.attachedVariableSetting.label !== "",
      form: {
        isValid: false,
        submitLoading: false,
        deleteLoading: false,
      },
      editedAttachedVariable: {
        eri: null,
        label: this.attachedVariableSetting.label,
        content: this.attachedVariableSetting.content,
        availability: this.attachedVariableSetting.availability,
      },
      showAddModal: false,
      isNewItemValid: false,
      newItem: {
        label: "",
        variable: "",
      },
      editingItem: false,
      editIndex: null,
      isCCMUserWithRestrictPermission: false,
    };
  },
  computed: {
    ...spacing,
    queueListItems() {
      return this.queuesList.map((queue) => {
        return { text: queue.name, value: queue.eri };
      });
    },
  },
  mounted() {
    this.editedAttachedVariable = merge(this.editedAttachedVariable, this.attachedVariableSetting);
    this.editedAttachedVariable.availability = this.attachedVariableSetting.availability || []; //perchè altrimenti il merge mi duplica;
    this.editedAttachedVariable.content = this.attachedVariableSetting.content || []; //perchè altrimenti il merge mi duplica;
    if (this.$refs.createAttachedVariableForm) this.$refs.createAttachedVariableForm.resetValidation();
    const isCCM = this.$store.getters.isCCM;
    if (!this.isEdit && isCCM) {
      const owned_profiles = this.$store.getters.getUserResources.owned_profiles;
      if (owned_profiles.length > 0) {
        //aggiungo gli eri delle code per non farlo creare global
        this.queuesList.forEach((queue) => {
          this.editedAttachedVariable.availability.push(queue.eri);
        });
      }
    }
    this.isCCMUserWithRestrictPermission = this.isCCMuser();
  },
  methods: {
    ...fieldValidators,
    getQueuesLabel() {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        if (isCCM) {
          const owned_profiles = resources.owned_profiles;
          if (owned_profiles.length > 0) {
            return "Handover parameters available in these queues";
          }
        }
      }
      return this.editedAttachedVariable.availability && this.editedAttachedVariable.availability.length > 0
        ? "Handover parameters available in these queues"
        : "Handover parameters available in all queues";
    },
    getQueuesHint() {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        if (isCCM) {
          const owned_profiles = resources.owned_profiles;
          if (owned_profiles.length > 0) {
            return "You can add the queues to make available this handover parameters only to the enabled queues";
          }
        }
      }
      return "You can add the queues to make available this handover parameters only to the enabled queues, otherwise by leaving this field blank the handover parameters will be available to all the queues.";
    },
    checkUserPermission(value) {
      const isCCM = this.$store.getters.isCCM;
      const resources = this.$store.getters.getUserResources;
      if (resources) {
        const owned_profiles = resources.owned_profiles;
        if (isCCM) {
          if (owned_profiles.length > 0) {
            if (value.length === 0) {
              return "Select at least one or more queues";
            }
          }
        }
      }
      return true;
    },
    deleteItem(index) {
      this.editedAttachedVariable.content.splice(index, 1);
    },
    addNewItem() {
      this.newItem.label = "";
      this.newItem.variable = "";
      if (this.$refs["editDialogForm"]) {
        this.$refs["editDialogForm"].resetValidation();
      }
      this.showAddModal = true;
    },
    closeDialog() {
      this.editingItem = false;
      this.editIndex = null;
      this.showAddModal = false;
    },
    saveNewItem(addMore) {
      if (this.editingItem) {
        this.editedAttachedVariable.content[this.editIndex].label = this.newItem.label;
        this.editedAttachedVariable.content[this.editIndex].variable = this.newItem.variable;
        this.editingItem = false;
        this.editIndex = null;
      } else {
        this.editedAttachedVariable.content.push(Object.assign({}, this.newItem));
      }
      this.showAddModal = false;
      this.newItem.label = "";
      this.newItem.variable = "";
      this.$refs.editDialogForm.reset();
      if (addMore) {
        this.showAddModal = true;
      }
    },
    editItem(item, index) {
      this.editIndex = index;
      this.editingItem = true;
      this.newItem.label = item.label;
      this.newItem.variable = item.variable;
      this.showAddModal = true;
    },
    async saveAttachedVariable() {
      if (this.form.isValid) {
        try {
          this.form.isSubmitted = true;
          this.form.submitLoading = true;
          this.editedAttachedVariable.availability = this.editedAttachedVariable.availability.filter(
            (queueERI) => this.queuesList.find((q) => q.eri === queueERI) != null,
          );
          const isCCM = this.$store.getters.isCCM;
          if (!this.isEdit && isCCM) {
            const owned_profiles = this.$store.getters.getUserResources.owned_profiles;
            if (owned_profiles.length > 0) {
              //aggiungo gli eri delle code per non farlo creare global
              this.queuesList.forEach((queue) => {
                if (this.editedAttachedVariable.availability.indexOf(queue.eri) === -1) {
                  this.editedAttachedVariable.availability.push(queue.eri);
                }
              });
            }
          }
          const result = await this.$http.post(`/human-agents/attached-variable`, this.editedAttachedVariable);
          this.editedAttachedVariable = result.data;
          EventBus.$emit(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES, {
            editConfig: this.editedAttachedVariable,
            message: "AttachedVariable " + this.editedAttachedVariable.label + " updated",
          });
        } catch (e) {
          EventBus.$emit(this.$store.getters.getEvents.EDIT_ATTACHED_VARIABLES_FAIL, {
            editConfig: this.editedAttachedVariable,
            message: `Error update handover parameter ${this.editedAttachedVariable.label} ${e.response.data.error}`,
          });
        } finally {
          this.form.isSubmitted = false;
          this.form.submitLoading = false;
        }
      }
    },
    deleteAttachedVariable() {
      if (this.attachedVariableSetting.label) {
        this.showConfirm = true;
      } else {
        EventBus.$emit(this.$store.getters.getEvents.CANCEL_ATTACHED_VARIABLES_CREATION);
      }
    },
    async onDeleteAttachedVariableConfirm() {
      this.showConfirm = true;
      this.form.deleteLoading = true;
      try {
        await this.$http.delete(`/human-agents/attached-variable/${this.editedAttachedVariable.label}`);
        EventBus.$emit(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES, {
          label: this.editedAttachedVariable.label,
          message: `Attached Data ${this.editedAttachedVariable.label} Deleted!`,
        });
        this.showConfirm = false;
      } catch (err) {
        EventBus.$emit(this.$store.getters.getEvents.DELETE_ATTACHED_VARIABLES_FAIL, {
          message: `Fail to delete handover parameter ${this.editedAttachedVariable.label}`,
        });
      } finally {
        this.form.deleteLoading = false;
      }
    },
    isCCMuser() {
      const user = this.$store.getters.getUser;
      //disabilito se è un ccm, l'handover parameter è global e il ccm non ha visibilità su tutti i profili
      if (this.isEdit && this.$store.getters.isCCM && this.editedAttachedVariable.availability.length == 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          return true;
        }
      }
      //questo controlla che il ccm, se ha una lista di profili deve poter controllare tutte le code associate, altrimenti non può editare
      if (this.isEdit && this.$store.getters.isCCM && this.editedAttachedVariable.availability.length > 0) {
        if (
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany] &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles &&
          user.roles[this.$store.getters.getProducts.CONVYAI].resources["eudata:convyai:" + this.$store.getters.getSelectedCompany].owned_profiles.length > 0
        ) {
          const shortCodeQueues = this.editedAttachedVariable.availability;
          const availableQueueERIs = this.queuesList.map((q) => q.eri);
          for (const queueERI of shortCodeQueues) {
            if (!availableQueueERIs.includes(queueERI)) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
};
</script>
