<template>
  <div>
    <ResultSnackbar ref="resultSnackbar" />
    <EuLoginPage
      v-if="loginForm"
      :defaultUsername="logininfo.username"
      :defaultSelectedCompany="logininfo.selectedCompany"
      @loginSuccess="loginSuccess"
      @loginFail="loginFail"
      @recoverPasswordSuccess="recoverPasswordSuccess"
      @recoverPasswordFail="recoverPasswordFail"
    />
    <Wizard v-else @wizardCompleted="wizardCompleted" />
  </div>
</template>

<script>
import EventBus from "../event-bus";
import Wizard from "../components/login/WizardConfiguration/Wizard";
import { EuLoginPage } from "@eudata/eu-login-page";
import ResultSnackbar from "../components/other/ResultSnackbar";

export default {
  name: "LoginPage",
  components: {
    Wizard,
    EuLoginPage,
    ResultSnackbar,
  },
  data() {
    return {
      loginForm: true,
      logininfo: {
        username: this.$store.state.settings["username"] || "",
        selectedCompany: this.$store.state.settings["selectedCompany"] || "",
      },
    };
  },
  async mounted() {
    EventBus.$emit(this.$store.getters.getEvents.SCROLLBAR_TO_TOP);
    try {
      await this.$httpNoAuth.get("/auth/is-initialized");
      this.loginForm = true;
    } catch (e) {
      //Mostro il wizard solo se è il backend a rispondere con il codice prestabilito
      if (e.response.status === 412) {
        this.loginForm = false;
      }
    }
  },
  methods: {
    wizardCompleted() {
      this.loginForm = true;
    },
    async loginSuccess(loginResult) {
      if (loginResult && typeof loginResult === "string") {
        this.$refs.resultSnackbar.showSuccess(loginResult);
      } else {
        await this.$store.dispatch("AddSetting", {
          username: loginResult.params.customerProfile.user.authentication.credentials.username,
          selectedCompany: loginResult.params.customerProfile.selectedCompany,
        });
        await this.$store.dispatch("UpdateLoginParameters", loginResult);
        EventBus.$emit(this.$store.getters.getEvents.LOGIN_SUCCESS);
      }
    },
    loginFail(msg) {
      this.$refs.resultSnackbar.showError(msg);
    },
    recoverPasswordSuccess(msg) {
      this.$refs.resultSnackbar.showSuccess(msg);
    },
    recoverPasswordFail(msg) {
      this.$refs.resultSnackbar.showError(msg);
    },
  },
};
</script>
