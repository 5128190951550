<template class="mt-2">
  <v-card flat :class="checkHeight ? 'heightCard' : ''">
    <v-card-text>
      <v-text-field
        v-model="configuration.error.number_of_failures"
        class="mx-0 mt-1 mb-2 pa-0"
        label="Number of consecutive failure"
        type="number"
        min="1"
        :rules="[greaterThanZero]"
        @keypress="onlyNumber"
      />
      <multiLanguageItem
        :itemObj="configuration.error.error_message"
        :customLabel="labels.errorMessage"
        :required="false"
        itemName="Message"
        @openOverlay="showMultiLanguage(labels.errorMessage, configuration.error.error_message, (checkHeight = true))"
      />
      <v-switch
        v-model="configuration.error.error_complex_node_enabled"
        color="primary"
        class="no-margin no-padding mt-4 mb-0 pb-0"
        label="Advanced node handling"
        @change="onAdvancedErrorHandling"
      />
      <div v-if="configuration.error.error_complex_node_enabled">
        <v-subheader class="mx-0 px-0 subtitle text-darken-5">
          Choose the action
        </v-subheader>
        <v-radio-group v-model="advancedErrorRadioGroup" row @change="onChangeAdvancedErrorType">
          <v-radio v-for="n in advancedErrorList" :key="n" :label="n" :value="`errorTab${n}`" color="primary" />
        </v-radio-group>

        <div v-if="configuration.error.error_complex_node_enabled && advancedErrorRadioGroup === 'errorTabHandover'">
          <v-autocomplete
            v-model="configuration.error.advancedErrorModeHandoverType"
            class="mx-0 mt-1 mb-2 pa-0"
            label="Handover"
            :items="handoverTypeList"
          />

          <template v-if="configuration.error.advancedErrorModeHandoverType == 'ConvyAI Agents'">
            <v-autocomplete
              v-model="configuration.error.humanAgentsHandoverDetails.queue"
              class="mx-0 mt-1 mb-2 pa-0"
              label="Queue"
              :items="getQueuesList"
              :rules="[requiredField]"
              @change="onChangeQueue"
            />
            <p v-if="configuration.error.humanAgentsHandoverDetails.queue" class="ml-5 caption">
              Define preferred agent:
            </p>
            <v-btn-toggle
              v-if="configuration.error.humanAgentsHandoverDetails.queue"
              v-model="configuration.error.humanAgentsHandoverDetails.preferredAgentType"
              class="ml-5"
              :class="configuration.error.humanAgentsHandoverDetails.preferredAgentType === 0 ? 'mb-5' : ''"
              dense
              active-class="primary white--text"
              mandatory
              @change="onChangePreferredAgentType"
            >
              <v-btn small>
                none
              </v-btn>
              <v-btn small>
                select agent
              </v-btn>
              <v-btn small>
                variable
              </v-btn>
            </v-btn-toggle>

            <v-autocomplete
              v-if="configuration.error.humanAgentsHandoverDetails.preferredAgentType === 1"
              v-model="configuration.error.humanAgentsHandoverDetails.preferredAgentValue"
              class="ml-5"
              label="Preferred Agent"
              :items="getPreferredAgent"
              :rules="[requiredField]"
            />
            <v-text-field
              v-if="configuration.error.humanAgentsHandoverDetails.preferredAgentType === 2"
              v-model="configuration.error.humanAgentsHandoverDetails.preferredAgentValue"
              class="ml-5"
              label="Preferred Agent Variable"
              :rules="[requiredField, isVariableField]"
            />

            <multiLanguageItem
              class="mt-2"
              :itemObj="configuration.error.advancedErrorModeHandoverMessage"
              :customLabel="labels.handoverMessage"
              :required="false"
              itemName="Message"
              @openOverlay="showMultiLanguage(labels.handoverMessage, configuration.error.advancedErrorModeHandoverMessage, (checkHeight = true))"
            />
          </template>

          <template v-else>
            <v-textarea
              v-model.trim="configuration.error.advancedErrorModeHandoverTarget"
              class="mx-0 mt-1 mb-2 pa-0"
              label="Target"
              rows="1"
              auto-grow
              :autocomplete="$store.getters.disableAutocomplete"
            />
            <multiLanguageItem
              :itemObj="configuration.error.advancedErrorModeHandoverMessage"
              :customLabel="labels.handoverMessage"
              :required="false"
              itemName="Message"
              @openOverlay="showMultiLanguage(labels.handoverMessage, configuration.error.advancedErrorModeHandoverMessage, (checkHeight = true))"
            />
            <multiLanguageItem
              v-if="configuration.error.advancedErrorModeHandoverType && configuration.error.advancedErrorModeHandoverType == 'Rainbow'"
              :itemObj="configuration.error.advancedErrorModeHandoverTargetBubbleName"
              :customLabel="labels.bubbleName"
              :required="true"
              itemName="Bubble name"
              @openOverlay="showMultiLanguage(labels.bubbleName, configuration.error.advancedErrorModeHandoverTargetBubbleName, (checkHeight = true))"
            />
            <multiLanguageItem
              v-if="configuration.error.advancedErrorModeHandoverType && configuration.error.advancedErrorModeHandoverType == 'Rainbow'"
              :itemObj="configuration.error.advancedErrorModeHandoverTargetBubbleIntroduction"
              :customLabel="labels.bubbleIntroduction"
              :required="true"
              itemName="Message"
              @openOverlay="
                showMultiLanguage(labels.bubbleIntroduction, configuration.error.advancedErrorModeHandoverTargetBubbleIntroduction, (checkHeight = true))
              "
            />
          </template>
        </div>

        <div v-else-if="configuration.error.error_complex_node_enabled && advancedErrorRadioGroup == 'errorTabSurvey'">
          <v-textarea
            v-model.trim="configuration.error.advancedErrorModeSurveyName"
            class="mx-0 mt-1 mb-2 pa-0"
            label="Name"
            rows="1"
            auto-grow
            :autocomplete="$store.getters.disableAutocomplete"
            :rules="[validateSurveyName]"
          />
          <multiLanguageItem
            :itemObj="configuration.error.advancedErrorModeSurveyScore"
            :customLabel="labels.scoreQuestion"
            :required="false"
            itemName="Message"
            :advancedValidation="[
              checkSurvey(
                configuration.error.error_complex_node_enabled && advancedErrorRadioGroup === 'errorTabSurvey',
                configuration.error.advancedErrorModeSurveyScore,
                configuration.error.advancedErrorModeSurveyFeedback,
              ),
            ]"
            @openOverlay="showMultiLanguage(labels.scoreQuestion, configuration.error.advancedErrorModeSurveyScore, (checkHeight = true))"
          />
          <multiLanguageItem
            :itemObj="configuration.error.advancedErrorModeSurveyFeedback"
            :customLabel="labels.scoreFeedback"
            :required="false"
            itemName="Message"
            :advancedValidation="[
              checkSurvey(
                configuration.error.error_complex_node_enabled && advancedErrorRadioGroup === 'errorTabSurvey',
                configuration.error.advancedErrorModeSurveyScore,
                configuration.error.advancedErrorModeSurveyFeedback,
              ),
            ]"
            @openOverlay="
              showMultiLanguage(labels.bubbleIntroduction, configuration.error.advancedErrorModeHandoverTargetBubbleIntroduction, (checkHeight = true))
            "
          />
        </div>
        <div v-else-if="configuration.error.error_complex_node_enabled && advancedErrorRadioGroup == 'errorTabFlow'">
          <!-- scelta flusso, versione e hook -->
          <FlowJumpConfigurations :settings="configuration.error" :flowTargets="flowTargets" />
        </div>
      </div>
    </v-card-text>
    <v-overlay absolute opacity="1" :value="overlay" color="overlayMultiLanguage" class="overlayMultilanguage">
      <multilanguageConfigurationDialog
        :itemName="itemName"
        :itemObj="itemObj"
        @back="(overlay = !$event), (checkHeight = false)"
      />
    </v-overlay>
  </v-card>
</template>
<script>
import fieldValidators from "../../../helpers/fieldValidators";
import multilanguageConfigurationDialog from "./MultilanguageConfigurationiDialog";
import multiLanguageItem from "../../other/multiLanguageItem";
import FlowJumpConfigurations from "@/components/other/FlowJumpConfigurations";
export default {
  name: "Errors",
  components: {
    multilanguageConfigurationDialog,
    multiLanguageItem,
    FlowJumpConfigurations,
  },
  props: ["configuration", "flowTargets", "humanAgentsQueues", "humanAgentsProfiles", "humanAgentsAgents"],
  data() {
    return {
      itemObj: null,
      itemName: null,
      overlay: false,
      labels: {
        errorMessage: "Error message",
        scoreQuestion: "Score question",
        scoreFeedback: "Score feedback",
        handoverMessage: "Message",
        rainbowMessage: "Message",
        bubbleName: "Bubble Name",
        bubbleIntroduction: "Bubble Introduction Name",
      },
      advancedErrorList: this.$store.getters.getAdvancedErrorList,
      handoverTypeList: [],
      checkHeight: false,
    };
  },
  computed: {
    complexAdvancedError: {
      get() {
        return this.configuration.error.error_complex_node_enabled;
      },
      set(val) {
        this.configuration.error.error_complex_node_enabled = val;
      },
    },
    advancedErrorRadioGroup: {
      get() {
        return this.configuration.error.advancedErrorMode;
      },
      set(val) {
        this.configuration.error.advancedErrorMode = val;
      },
    },
    getQueuesList() {
      let result = [];
      result = this.humanAgentsQueues.map((queue) => {
        return { text: queue.name, value: queue.eri };
      });
      return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
    },
    getPreferredAgent() {
      let result = [];
      const queue = this.humanAgentsQueues.find((queue) => queue.eri === this.configuration.error.humanAgentsHandoverDetails.queue);
      if (queue) {
        const profiles = this.humanAgentsProfiles.filter((profile) => profile.routing.queues.indexOf(queue.eri) > -1);
        if (profiles && profiles.length > 0) {
          let allAgentProfiles = [];
          for (const profile of profiles) {
            const agents = this.humanAgentsAgents.filter(
              (agent) =>
                agent.roles[this.$store.getters.getProducts.CONVYAI].resources[
                  `${this.$store.getters.getProducts.CONVYAI}:${this.$store.getters.getSelectedCompany}`
                ].profile === profile.eri,
            );
            allAgentProfiles = [...allAgentProfiles, ...agents];
          }
          result = allAgentProfiles.map((agent) => {
            return { text: agent.authentication.credentials.username, value: agent.eri };
          });
          return result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
        }
      }
      return result;
    },
  },
  async mounted() {
    this.handoverTypeList = JSON.parse(JSON.stringify(this.$store.getters.getHandoverTypeList));
    if (this.$store.state.company.settings.integrations) {
      if (!this.$store.state.company.settings.integrations.ciscoECE) {
        this.handoverTypeList = this.handoverTypeList.filter((handover) => handover !== "CiscoECE");
      }
      if (!this.$store.state.company.settings.integrations.livePerson) {
        this.handoverTypeList = this.handoverTypeList.filter((handover) => handover !== "LivePerson");
      }
      if (!this.$store.state.company.settings.integrations.wcs) {
        this.handoverTypeList = this.handoverTypeList.filter((handover) => handover !== "ConvyAI Agents");
      }
      if (!this.$store.state.company.settings.integrations.webexTeams) {
        this.handoverTypeList = this.handoverTypeList.filter((handover) => handover !== "WebexTeams");
      }
      if (!this.$store.state.company.settings.integrations.rainbow) {
        this.handoverTypeList = this.handoverTypeList.filter((handover) => handover !== "Rainbow");
      }
    }
  },
  methods: {
    ...fieldValidators,
    onChangeQueue() {
      this.configuration.error.humanAgentsHandoverDetails.preferredAgentValue = "";
    },
    onChangePreferredAgentType() {
      this.configuration.error.humanAgentsHandoverDetails.preferredAgentValue = "";
    },
    showMultiLanguage(label, data) {
      this.itemName = label;
      this.itemObj = data;
      this.overlay = !this.overlay;
      this.$emit("openOverlay");
    },
    onAdvancedErrorHandling(e) {
      this.complexAdvancedError = e;
    },
    onChangeAdvancedErrorType(e) {
      this.advancedErrorRadioGroup = e;
    },
  },
};
</script>
<style scoped>
.heightCard {
  height: 680px !important;
}
</style>
